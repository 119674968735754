// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  gridWrapper: {
    background: theme.palette.common.white,
    boxShadow: 'unset',
    '& .MuiButtonBase-root': {
      padding: '0px',
      marginRight: '8px',
    },
    '& .MuiTableCell-root': {
      padding: '10px 15px',
      '& div': {
        textTransform: 'capitalize',
      },
    },
    '& .MuiTable-root': {
      minWidth: '100% !important',
      tableLayout: 'auto',
    },
    '& .MuiTableCell-head': {
      border: 'none',
      fontSize: '14px',
      fontWeight: 600,
    },
    '& .MuiTableRow-head:first-child': {
      background: theme.palette.grey[900],
      padding: '16px',
      '& .MuiTableCell-root': {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 600,
        padding: '16px',
        paddingLeft: '26px',
      },
    },
  },
}));
