import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

function WarehouseFormButton({
  btnText,
  onSubmitBtnClick,
  onCancelBtnClick,
  formik,
}) {
  const toast = useRef(null);

  if (!formik?.values?.latitude || !formik?.values?.longitude) {
    toast?.current?.clear();
    toast?.current?.show({
      severity: 'warn',
      summary: 'Warning',
      detail:
        'Could not identify the geolocation, please drag marker to some other place.',
    });
  }
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 flex">
          <Button
            size="small"
            type="button"
            outlined
            onClick={onCancelBtnClick}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            size="small"
            type="button"
            severity="primary"
            onClick={onSubmitBtnClick}
            disabled={
              formik?.values?.city &&
              formik?.values?.state &&
              formik?.values?.addressLineOne &&
              formik?.values?.zipcode &&
              formik?.values?.latitude &&
              formik?.values?.longitude
                ? false
                : true
            }
          >
            {btnText}
          </Button>
        </div>
      </div>
    </>
  );
}

export default WarehouseFormButton;
