import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { AutoComplete } from 'primereact/autocomplete';

import { CONFIG, libraries } from '../../../constants';

import PFInputText from './PFInputText';

const PFGoogleMap = () => {
  const inputRef = useRef(null);
  const [address, setAddress] = useState('');
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState({
    lat: 36.11310790984111,
    lng: -115.17653312308538,
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('onClick', handleClickOutside);

    return () => {
      document.removeEventListener('onClick', handleClickOutside);
    };
  }, []);

  const findCategoryType = (addressComponents, type) => {
    return addressComponents.find(address => address.types.includes(type));
  };

  const transformAddressFromGeocode = addressComponents => {
    const address = {
      addressLineOne: findCategoryType(addressComponents, 'street_number')
        ?.long_name,
      addressLineTwo: findCategoryType(addressComponents, 'route')?.long_name,
      city: findCategoryType(addressComponents, 'locality')?.long_name,
      state: findCategoryType(addressComponents, 'administrative_area_level_1')
        ?.long_name,
      stateCode: findCategoryType(
        addressComponents,
        'administrative_area_level_1'
      )?.short_name,
      zipcode: findCategoryType(addressComponents, 'postal_code')?.long_name,
    };

    return address;
  };

  const populateGeocodeResponse = response => {
    const { addressLineOne, addressLineTwo, city, zipcode, state, stateCode } =
      transformAddressFromGeocode(response.results[0].address_components);
    const latitude =
      typeof response.results[0].geometry.location.lat === 'function'
        ? response.results[0].geometry.location.lat()
        : response.results[0].geometry.location.lat;
    const longitude =
      typeof response.results[0].geometry.location.lng === 'function'
        ? response.results[0].geometry.location.lng()
        : response.results[0].geometry.location.lng;

    setSelectedAddress({
      city: city || '',
      state: state || '',
      stateCode: stateCode || '',
      addressLineOne: addressLineOne || '',
      addressLineTwo: addressLineTwo || '',
      zipcode: zipcode || '',
      latitude: latitude || '',
      longitude: longitude || '',
    });
  };

  const handleChange = address => {
    setAddress(address);
    setShowDropdown(true);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        getLatLng(results[0]).then(latLng => {
          const coordinates = {
            lat: latLng.lat,
            lng: latLng.lng,
          };
          setPlaces(coordinates);
          setCenter(coordinates);
          populateGeocodeResponse({ results });
          setAddress(address);
        });
        setShowDropdown(false);
      })
      .catch(error => console.error('Error', error));
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: CONFIG.GOOGLE_MAP_KEY,
    libraries,
  });

  const layout = props => (
    <div className="w-full" ref={inputRef}>
      {isLoaded && (
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={{ componentRestrictions: { country: 'us' } }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <span className="p-float-label p-input-icon-right w-full">
                <i className="pi pi-google" />
                <PFInputText
                  {...getInputProps({
                    className: 'location-search-input mb-1 w-12',
                  })}
                  {...props}
                />

                <label htmlFor="ac">Search Places</label>
              </span>

              <div
                className="autocomplete-dropdown-container p-autocomplete-items-wrapper"
                style={{
                  position: 'absolute',
                  zIndex: '1001',
                  minWidth: '222px',
                  transformOrigin: 'center bottom',
                  overflow: 'auto',
                  height: 'fitCcontent',
                  border: suggestions?.length === 0 && 'none',
                }}
              >
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      key={index + suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      className="py-1 px-2"
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  );

  return {
    gMapFieldLayout: layout,
    address: selectedAddress,
  };
};

export default PFGoogleMap;
