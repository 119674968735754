import {
  Grid,
  CircularProgress,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ORDER_BY } from '../constant/constant';

const OrderBySection = ({ action, widgetFormik, table_configuration }) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          fullWidth
          key={widgetFormik.values.order_by.orderField}
          options={table_configuration}
          getOptionLabel={option => option && option.alice}
          renderInput={params => (
            <TextField {...params} label="Field" required />
          )}
          disabled={action === 'view' ? true : false}
          value={table_configuration.find(
            item => item.measureName == widgetFormik.values.order_by.orderField
          )}
          onChange={(event, value) => {
            widgetFormik.values.order_by.orderField = value?.measureName || '';
            widgetFormik.setFieldValue(
              'order_by',
              widgetFormik.values.order_by
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          fullWidth
          groupBy={option => option.firstLetter}
          options={ORDER_BY}
          getOptionLabel={option => option && option.value}
          renderInput={params => (
            <TextField {...params} label="Order By" required />
          )}
          disabled={action === 'view' ? true : false}
          value={ORDER_BY.find(
            item => item.name == widgetFormik.values.order_by.orderBy
          )}
          onChange={(event, value) => {
            widgetFormik.values.order_by.orderBy = value?.name || '';
            widgetFormik.setFieldValue(
              'order_by',
              widgetFormik.values.order_by
            );
          }}
        />
      </Grid>
    </>
  );
};

export default OrderBySection;
