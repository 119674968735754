import React from 'react';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  Label,
} from 'recharts';

// **** External Utilities ****
import { CircularProgress } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';

const numberFormatter = item => item;
const dateFormatter = item => item;

const colors = ['#7DB3FF', '#49457B', '#FF7C78'];

const xAxisFormatter = item => {
  return item;
};

const CartesianChart = ({
  resultSet,
  children,
  ChartComponent,
  height,
  widgetMetaData,
}) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <ChartComponent margin={{ left: -10 }} data={resultSet.chartPivot()}>
          <XAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={xAxisFormatter}
            dataKey="x"
            minTickGap={10}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={numberFormatter}
          />
          <CartesianGrid vertical={false} />
          {children}
          <Legend />
          <Tooltip labelFormatter={dateFormatter} formatter={numberFormatter} />
        </ChartComponent>
      </ResponsiveContainer>
    </>
  );
};

const ChartsList = {
  line: ({ resultSet, height }) => (
    <CartesianChart
      resultSet={resultSet}
      height={height}
      ChartComponent={LineChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ data01 }) => (
    <ResponsiveContainer width="95%" height={200}>
      <BarChart data={data01}>
        <XAxis dataKey="project_status" />

        <YAxis />
        <Tooltip labelFormatter={name => `${name} Projects Count`} />
        <Legend label="Project Status" />
        <Bar
          dataKey="project_count"
          barSize={30}
          fill="#0074D9"
          name="Project Status"
        />
      </BarChart>
    </ResponsiveContainer>
  ),
  area: ({ resultSet, height }) => (
    <CartesianChart
      resultSet={resultSet}
      height={height}
      ChartComponent={AreaChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ data01 }) => {
    if (!data01.filter(data => data.value != 0).length) {
      data01.push({ name: 'no value', value: 1 });
    }
    return (
      <ResponsiveContainer className="container" width="100%" height={150}>
        <PieChart>
          <Pie
            data={data01}
            dataKey="value"
            nameKey="name"
            fill="#8884d8"
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="80%"
            paddingAngle={5}
            startAngle={-270}
          >
            {data01.map((entry, index) => {
              if (index === 0) {
                return <Cell key={`cell-${index}`} fill="#f3f6f9" />;
              }
              return <Cell key={`cell-${index}`} fill="#F18F1C" />;
            })}
            <Label
              value={data01[1].value}
              position="center"
              fill="#F18F1C"
              style={{ fontWeight: 600, fontSize: '16px' }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  },
  table: ({ resultSet }) => (
    <TableContainer style={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" id="stickytable">
        <TableHead>
          <TableRow>
            {resultSet.tableColumns().map((series, i) => (
              <TableCell align="center" key={i}>
                {series.shortTitle}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {resultSet.tablePivot().length ? (
            resultSet.tablePivot().map((row, index) => (
              <TableRow key={index}>
                {resultSet.tableColumns().map((series, i) => {
                  return (
                    <TableCell align="center" key={i}>
                      {' '}
                      {row[`${series.key}`]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="15" align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ),
};

const MemoizedChartsList = Object.keys(ChartsList)
  .map(key => ({
    [key]: React.memo(ChartsList[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = Component => data01 =>
  (data01 && <Component data01={data01} />) || '';

//*************************************************/
//**************** WidgetRenderer  ****************
//*************************************************/
const WidgetRenderer = ({ data, measures, dimensions, chartType }) => {
  let component;
  if (chartType) {
    component = MemoizedChartsList[chartType];
  }

  let data01;
  if (chartType === 'pie') {
    data01 = [measures, dimensions];
  } else {
    data01 = data;
  }
  return chartType
    ? (component && renderChart(component)(data01)) || null
    : null;
};

WidgetRenderer.defaultProps = {
  //widgetMetaData: {},
  chartHeight: 300,
};

export default WidgetRenderer;
