import { Grid, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// **** Styles/Images/Icons ****
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { Skeleton } from 'primereact/skeleton';

import {
  REACT_APP_LOGIN_AUTH,
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REFRESH_GRANT_TYPE,
  REACT_APP_YOUTUBE_URL,
  REACT_APP_DW_BETA,
} from '../src/constants/envConstants';

import { useStyles } from './App.styles';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import './components/styles/style.css';
import './components/ResponsiveStyles/ResponsiveStyle.css';
import Navbar from './components/shared/Navbar/Navbar';
import Topbar from './components/shared/Topbar/Topbar';
import { Routes } from './Routes';
import { generateToken } from './services/auth.service';
import useToken from './hooks/useToken';
import { authURL } from './config/variables';
import { getClientDetails } from './components/Admin/Configuration/RestrictAccessbyRole.service';
import { setMomentTimezone, lockGlobalBar } from './utils/Helpers';
import globalContext from './contexts/globalContext';
import { AuthRoutes } from './components/Admin/Auth/Routes/AuthRoutes';
import { ReturnToLogin } from './components/Admin/Auth/Routes/ReturnToLogin';
import LoginSkeleton from './components/Skeleton/LoginSkeleton';
import ApiService from './services/api.service';
import ErrorBoundaryComponent from './ErrorBoundaryComponent';

const drawerWidth = 220;

const App = () => {
  const history = useHistory();
  const { accessToken, setToken, expireToken } = useToken();
  const classes = useStyles({ drawerWidth });
  const [loading, setLoading] = useState(true);
  const [slideDrawer, setSlideDrawer] = useState(
    parseInt(localStorage.getItem('sidebar-layout')) == 0 ? true : false
  );
  const [cubejsAuthorizationToken, setCubejsAuthorizationToken] =
    useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const { permissionList, isLoading } = useSelector(
    state => state.permissionsList
  );

  const DrawerWidths = current => {
    setSlideDrawer(current => {
      window.sideDrawer = !current;
      return !current;
    });
  };

  const clientDetails = JSON.parse(localStorage.getItem('client_details'));

  useEffect(() => {
    localStorage.setItem(
      'sidebar-visibility',
      !slideDrawer ? 'visible' : 'hidden'
    );
    window.dispatchEvent(new Event('storage'));

    const Data = 'cubejsAuthorizationToken';
  }, [slideDrawer]);

  useEffect(() => {
    if (accessToken === null || accessToken === '') {
      if (code) {
        generateToken(code, setToken);
        if (state && state !== 'undefined') {
          history.push(decodeURIComponent(state));
        } else {
          window.location = `${document.location.origin}/`;
        }
      }
    }
  }, []);

  const fetchToken = async () => {
    const generatedTokenResponse = await axios.post(
      REACT_APP_LOGIN_AUTH + '/regenerate-token',
      {
        client_id: localStorage.getItem('client_id'),
        user_id: localStorage.getItem('user_id'),
      }
    );
    if (
      generatedTokenResponse?.data &&
      generatedTokenResponse?.data?.access_token
    ) {
      setCubejsAuthorizationToken(true);
      localStorage.setItem(
        'cubejsAuthorizationToken',
        'Bearer ' + generatedTokenResponse.data.access_token
      );
    } else {
      fetchToken();
    }
  };

  useEffect(() => {
    if (localStorage.getItem('client_id')) {
      fetchToken();
    }
  }, [localStorage.getItem('client_id')]);

  axios.interceptors.request.use(async config => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      if (accessToken && refreshToken) {
        const user = jwt_decode(accessToken);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
        if (isExpired) {
          expireToken();
        }

        if (!isExpired) return config;
        if (config?.data?.grant_type !== REACT_APP_AUTH_REFRESH_GRANT_TYPE) {
          delete axios.defaults.headers.common['Authorization'];
          const response = await axios.post(`${authURL}/token`, {
            refresh_token: refreshToken,
            client_secret: REACT_APP_AUTH_CLIENT_SECRET,
            client_id: REACT_APP_AUTH_CLIENT_ID,
            grant_type: REACT_APP_AUTH_REFRESH_GRANT_TYPE,
          });

          if (
            response?.status === 200 &&
            response.data.refresh_token &&
            response.data.access_token
          ) {
            setToken(response.data);
            if (
              !config?.url.includes(`${authURL}/token`) &&
              !config?.url.includes(REACT_APP_YOUTUBE_URL) &&
              !config?.url.includes(REACT_APP_CUBE_BASEURL)
            ) {
              config.headers['Authorization'] =
                'Bearer ' + response.data.access_token;
              axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
              ('Token parsing called');
              setTimeout(() => {
                axios.headers.client_id = localStorage.getItem('client_id');
                window.location.reload();
              }, 2000);
            }
          }
        }
      }
      return config;
    } catch (err) {
      expireToken();
    }
  });

  useEffect(() => {
    axios.interceptors.request.use(async config => {
      try {
        if (
          ![
            `${authURL}/token`,
            `${authURL}/get-all-user-tenants`,
            REACT_APP_YOUTUBE_URL,
          ].includes(config?.url)
        ) {
          axios.defaults.headers.common['client_id'] =
            localStorage.getItem('client_id');
        }
        return config;
      } catch (err) {
        console.error('interceptor', err);
      }
    });
  }, [localStorage.getItem('client_id')]);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response &&
        error.response.data &&
        (error.response.data?.name === 'TokenExpiredError' ||
          error.response.data?.message === 'jwt expired')
      ) {
        expireToken();
      }
      return Promise.reject(error);
    }
  );

  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const setClientDetails = async () => {
    setLoading(true);
    const response = await getClientDetails();
    if (response) {
      const isTentativeSchedulingEnabled =
        response?.tenant_permissions[0]?.included_permissions?.includes(
          'ENABLE_TENTATIVE_SCHEDULING'
        );
      localStorage.setItem(
        'isTentativeSchedulingEnabled',
        isTentativeSchedulingEnabled
      );
      localStorage.setItem('client_timezone', response.client_timezone);
      setMomentTimezone(response.client_timezone);
      localStorage.setItem('client_details', JSON.stringify(response));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (accessToken) {
      if (axios.defaults.headers.common['client_id']) {
        setClientDetails();
      }
    }
  }, [accessToken, permissionList]);

  if (code) {
    if (!loading) {
      const client_id = localStorage.getItem('client_id');
      if (client_id) {
        const allowedClients = REACT_APP_DW_BETA?.split(',') || [];
        const checkNewClient = allowedClients.includes(client_id);
        if (checkNewClient) {
          history.push('/dashboard');
        }
      }
    } else {
      return <LoginSkeleton />;
    }
  }
  if (accessToken) {
    if (history.location.pathname === '/') {
      history.push('/dashboard');
    }
  }
  const locationUrl = window.location.hostname;

  const globalBarStyle = useMemo(() => {
    return lockGlobalBar();
  }, [clientDetails]);

  const dynamicHeight = useMemo(() => {
    return globalBarStyle
      ? window.cxcScheduleAlert || window.marqueeText
        ? 'margin-top-110'
        : 'mt-8'
      : window.cxcScheduleAlert || window.marqueeText
      ? 'mt-7'
      : 'mt-0';
  }, [globalBarStyle, window.cxcScheduleAlert, window.marqueeText]);

  return accessToken ? (
    <globalContext.Provider value={jwt_decode(accessToken)}>
      <Topbar DrawerWidths={DrawerWidths} slideDrawer={slideDrawer} />
      <div
        style={{ paddingLeft: slideDrawer ? '2rem' : '16rem' }}
        className={`grid grid-nogutter pt-5 pr-5 pb-5 transition-ease-in-out transition-duration-500 light-grey-bg-100 ${dynamicHeight}`}
      >
        <ErrorBoundaryComponent>
          {cubejsAuthorizationToken && <Routes />}{' '}
        </ErrorBoundaryComponent>
      </div>
    </globalContext.Provider>
  ) : (
    <ErrorBoundaryComponent>
      <Switch>
        {AuthRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={true}
            component={route.component}
          />
        ))}
        <Route path="*" exact={true} component={ReturnToLogin} />
      </Switch>
    </ErrorBoundaryComponent>
  );
};

export default App;
