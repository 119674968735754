// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { Grid, TextField, InputLabel, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createNewTeamReview,
  editTeamReview,
  getTeamReviewTypes,
  deleteTeamReview,
} from './UserManagement.service';

// **** Styles *****
import { useStyles } from './UserManagement.styles';

const TeamReviewsColumns = [
  {
    id: 'type',
    value: 'Type',
  },
  {
    id: 'description',
    value: 'Description',
  },
  {
    id: 'files',
    value: 'File',
  },
];

const TeamReviews = ({ teamReviews, userId, setReloadTeamReviewsList }) => {
  const classes = useStyles();
  const fileInputRef = React.useRef();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Team Review',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [teamReviewTypes, setTeamReviewTypes] = React.useState([]);

  const [imgErrors, setImgErrors] = React.useState(false);
  const ImageValidation = type => {
    if (type.includes('svg')) {
      setImgErrors(true);
      fileInputRef.current.value = '';
      return false;
    } else {
      setImgErrors(false);
      return true;
    }
  };

  let TeamReviewData = [];

  React.useEffect(() => {
    getTeamReviewTypes(setTeamReviewTypes);
  }, []);

  // **** Data Modification to Display *****
  if (teamReviews?.length) {
    TeamReviewData = teamReviews?.map((review, index) => {
      return {
        team_review_id: { value: index },
        type: {
          id: review.type,
          value: teamReviewTypes.find(
            val => val.team_review_types_id === review.type
          )?.team_review_types,
        },
        description: { value: review.description },
        files: {
          data: review.files,
          value: review?.files?.map((file, index) => (
            <div key={index}>
              <a
                href={file?.url}
                target="_blank"
                rel="noreferrer"
                download={file?.name}
                className={classes.docName}
              >
                {file?.name}
              </a>
            </div>
          )),
        },
      };
    });
  }

  //View Team Review
  const viewEditTeamReview = (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Team Review') || 'Edit Team Review',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };

  const confirmDeleteDocument = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete Team Review?',
      header: 'Delete Document',
    });
  };

  const onConfirmDialog = () => {
    const remainingDocumentData = TeamReviewData.filter((val, index) => {
      return rowIndex !== val.team_review_id?.value;
    }).map(val => {
      return {
        files: val.files.data,
        description: val.description.value,
        type: val.type.id,
      };
    });

    deleteTeamReview(
      userId,
      remainingDocumentData,
      setLoading,
      setAlert,
      setReloadTeamReviewsList,
      setConfirmDialog
    );
  };

  //Emergency Contact ValidationSchema
  const TeamReviewValidationSchema = Yup.object().shape({
    type: Yup.number().required('Required'),
    description: Yup.string().trim().required('Required'),
  });
  // **** Formik Form Values ****
  const teamReviewformik = useFormik({
    initialValues: {
      type: (formAction !== 'add' && TeamReviewData[rowIndex]?.type.id) || null,
      description:
        (formAction !== 'add' && TeamReviewData[rowIndex]?.description.value) ||
        '',
      files:
        (formAction !== 'add' && TeamReviewData[rowIndex]?.files?.data?.[0]) ||
        '',
    },
    onSubmit: values => {
      if (formAction === 'add') {
        console.log(teamReviews, values);
        //const addNewReviewData = [...teamReviews, values];

        createNewTeamReview(
          userId,
          teamReviews,
          values,
          setLoading,
          setIsOpen,
          setReloadTeamReviewsList,
          setAlert
        );
      } else {
        editTeamReview(
          userId,
          rowIndex,
          TeamReviewData,
          teamReviewformik.values,
          setLoading,
          setIsOpen,
          setReloadTeamReviewsList,
          setAlert
        );
      }
    },
    validationSchema: TeamReviewValidationSchema,
    enableReinitialize: true,
  });
  const getTeamReviewViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Autocomplete
            name="type"
            options={teamReviewTypes}
            onBlur={teamReviewformik?.handleBlur}
            onChange={(event, value) => {
              teamReviewformik.setFieldValue(
                'type',
                value.team_review_types_id
              );
            }}
            getOptionLabel={option =>
              option && option.team_review_types.toString()
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Type"
                required={formAction !== 'view'}
                InputLabelProps={{ shrink: true }}
              />
            )}
            value={
              teamReviewTypes.filter(
                item =>
                  item.team_review_types_id === teamReviewformik?.values?.type
              )[0] || ''
            }
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item>
          <TextField
            id="description"
            label="Description"
            name="description"
            onChange={teamReviewformik.handleChange}
            onBlur={teamReviewformik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            inputProps={{ maxlength: 100 }}
            disabled={formAction === 'view'}
            required={formAction !== 'view'}
            value={teamReviewformik?.values?.description}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <InputLabel shrink id="fielupload-label" className={classes.label}>
            File Upload
          </InputLabel>
          {
            formAction !== 'add' && (
              //teamReviewformik.values.file.map((file, index) => (
              <div>
                <a
                  href={teamReviewformik.values?.files?.url}
                  target="_blank"
                  rel="noreferrer"
                  download={teamReviewformik.values?.files?.name}
                  className={classes.docName}
                >
                  {teamReviewformik.values?.files?.name}
                </a>
              </div>
            )
            //  ))
          }
          {formAction !== 'view' && (
            <input
              type="file"
              id="files"
              name="files"
              labelId="fielupload-label"
              onBlur={teamReviewformik?.handleBlur}
              onClick={event => {
                event.target.value = null;
              }}
              ref={fileInputRef}
              onChange={(event, value) => {
                if (
                  event.target.files &&
                  event.target.files[0] &&
                  ImageValidation(event.target.files[0].type)
                ) {
                  teamReviewformik.setFieldValue(
                    'files',
                    event?.currentTarget?.files[0]
                  );
                }
              }}
              className={classes.inputfileupload}
            />
          )}
        </Grid>
        {imgErrors && (
          <Typography variant="caption" component="p" color="error">
            System does not accept svg files.
          </Typography>
        )}
      </Grid>
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <PFTableLoader />
          </Grid>
        </Grid>
      )) ||
        (TeamReviewData && (
          <Grid container spacing={4} direction="column">
            <Grid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              classes={{ root: classes.tableWrapper }}
            >
              <GenericTable
                title="Team Reviews"
                columnData={TeamReviewsColumns}
                rowData={TeamReviewData}
                showActions={{ view: true, edit: true, delete: true }}
                headerLinks={[
                  {
                    label: 'Add Item',
                    handler: () => {
                      setFormAction('add');
                      teamReviewformik.resetForm();
                      setDialogSettings(prevState => ({
                        ...prevState,
                        showButton2: true,
                        title: 'Add Team Review',
                      }));
                      setIsOpen(true);
                    },
                  },
                ]}
                handleView={index => viewEditTeamReview('view', index)}
                handleEdit={index => viewEditTeamReview('edit', index)}
                handleDelete={index => confirmDeleteDocument(index)}
              />
            </Grid>
            <GenericDialog
              fullwidth
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
                teamReviewformik?.handleReset();
              }}
              handleSave={teamReviewformik.handleSubmit}
              dialogSettings={dialogSettings}
              disabledButton2={
                !teamReviewformik?.dirty || !teamReviewformik?.isValid
              }
              disabledButton1={false}
            >
              <form>{getTeamReviewViewEditForm()}</form>
            </GenericDialog>
            {/* Confirmation dialog for delete */}
            <GenericConfirmationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              onConfirmDialog={onConfirmDialog}
            />
          </Grid>
        ))}
    </>
  );
};

export default TeamReviews;
