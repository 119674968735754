import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  project: {},
};

const crmInvoiceItemsSlice = createSlice({
  name: 'opportunitySourceMaster',
  initialState: initialState,
  reducers: {
    setCRMInvoiceItems(state, { payload }) {
      state.project = payload;
    },
  },
});

export const { setCRMInvoiceItems } = crmInvoiceItemsSlice.actions;

export const invoiceItems = crmInvoiceItemsSlice.reducer;
