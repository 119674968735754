import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  installersList: [],
  isSelectDialogOpen: false,
  newEventInfo: {},
  viewChangeFlag: false,
  initialInstaller: {},
  mode: null,
};

const installerViewCalendarSlice = createSlice({
  name: 'installerViewCalendar',
  initialState: initialState,
  reducers: {
    setInstallerViewCalendar(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.installersList = payload.hasOwnProperty('installersList')
        ? payload.installersList
        : state.installersList;
      state.viewChangeFlag = payload.hasOwnProperty('viewChangeFlag')
        ? payload.viewChangeFlag
        : state.viewChangeFlag;
      state.isSelectDialogOpen = payload.hasOwnProperty('isSelectDialogOpen')
        ? payload.isSelectDialogOpen
        : state.isSelectDialogOpen;
      state.newEventInfo = payload.hasOwnProperty('newEventInfo')
        ? payload.newEventInfo
        : state.newEventInfo;
      state.initialInstaller = payload.hasOwnProperty('initialInstaller')
        ? payload.initialInstaller
        : state.initialInstaller;
      state.mode = payload.hasOwnProperty('mode') ? payload.mode : state.mode;
    },
  },
});

export const { setInstallerViewCalendar } = installerViewCalendarSlice.actions;

export const installerViewCalendarReducer = installerViewCalendarSlice.reducer;
