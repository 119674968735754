import * as Yup from 'yup';

export const resetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required(
      'Password length must be minimum 8 characters and at least one lowercase, one uppercase, one number, and one special character.'
    )
    .min(
      8,
      'Password length must be minimum 8 characters and at least one lowercase, one uppercase, one number, and one special character.'
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*_])[A-Za-z\d@#$%^&+=!*_]{8,}$/,
      'Password length must be minimum 8 characters and at least one lowercase, one uppercase, one number, and one special character.'
    ),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Password and confirm password field should be same.'
    )
    .required('Password and confirm password field should be same.'),
});

export const loginValidation = Yup.object().shape({
  email: Yup.string()
    .required('Email field is required.')
    .email('Email must be a valid email.')
    .matches(/^(?!.*@[^,]*,)/),
  password: Yup.string().required('Password field is required.'),
});

export const forgetPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .required('Email field is required.')
    .email('Email must be a valid email.')
    .matches(/^(?!.*@[^,]*,)/),
});
