import axios from 'axios';

import warrantyInfoConstants from './WarrantyInfoConstant';

export const getWarrantyInfo = async projectId => {
  try {
    const response = await axios.get(
      `${warrantyInfoConstants.baseUrl}/${warrantyInfoConstants.getWarrantyInfoEndpoint(projectId)}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const saveWarrantyInfo = async (projectId, data) => {
  try {
    const response = await axios.post(
      `${warrantyInfoConstants.baseUrl}/${warrantyInfoConstants.getWarrantyInfoEndpoint(
        projectId
      )}`,
      data
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
