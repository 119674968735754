import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, CircularProgress } from '@material-ui/core';

import GenericTable from '../../shared/GenericTable/GenericTable';

import {
  fetchConfigurationEntities,
  deleteConfigurationEntities,
} from './FormFieldsConfiguration.service';

const ListFormConfiguration = ({
  setEditConfiguration,
  setAlert,
  reloadFieldConfigurationList,
  setReloadFieldConfigurationList,
}) => {
  const [loading, setLoading] = useState(false);
  const [getConfigurationEntities, setConfigurationEntities] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [getDeleteConfigurationObject, setDeleteConfigurationObject] =
    React.useState({});

  const handleClickOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleClose = () => {
    setConfirmDialogOpen(false);
  };
  useEffect(() => {
    fetchConfigurationEntities(
      'projects',
      setConfigurationEntities,
      setLoading,
      setAlert
    );
    setReloadFieldConfigurationList(false);
  }, [reloadFieldConfigurationList]);

  const columnData = [
    {
      id: 'name',
      value: 'Name',
    },
    /*
    {
      id: 'slug',
      value: 'Slug',
    },
    */
    {
      id: 'type',
      value: 'Type',
    },
    {
      id: 'min_length',
      value: 'Minimum Length',
    },
    {
      id: 'max_length',
      value: 'Maximum Length',
    },
    {
      id: 'help_text',
      value: 'Help Text',
    },
    {
      id: 'default',
      value: 'Default Value',
    },
    {
      id: 'regex',
      value: 'Regex',
    },
    {
      id: 'is_required',
      value: 'Is Required?',
    },
    {
      id: 'is_hidden',
      value: 'Is Hidden?',
    },
  ];
  let rowData = [];
  if (
    getConfigurationEntities?.[0]?.config?.field_groups?.[0]?.fields?.length
  ) {
    getConfigurationEntities[0].config.field_groups[0].fields.map(response => {
      let configurationData = [];
      configurationData['name'] = {
        value: response?.name || '',
      };
      configurationData['slug'] = {
        value: response?.slug || '',
      };
      configurationData['type'] = {
        value: response?.type || '',
      };
      configurationData['min_length'] = {
        value: response?.min_length || '',
      };
      configurationData['max_length'] = {
        value: response?.max_length || '',
      };
      configurationData['help_text'] = {
        value: response?.help_text || '',
      };
      configurationData['default'] = {
        value: response?.default || '',
      };
      configurationData['regex'] = {
        value: response?.regex || '',
      };
      configurationData['is_required'] = {
        value: response?.is_required ? 'Yes' : 'No',
      };
      configurationData['is_hidden'] = {
        value: response?.is_hidden ? 'Yes' : 'No',
      };
      rowData.push(configurationData);
    });
  }
  return (
    <>
      <Grid container direction="row" alignItems="center">
        {loading ? (
          <Grid container item xs={6} justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : rowData.length ? (
          <>
            <Dialog
              open={confirmDialogOpen}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle
                style={{ cursor: 'move' }}
                id="draggable-dialog-title"
              >
                Delete Configuration
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this configuration?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    deleteConfigurationEntities(
                      'projects',
                      getDeleteConfigurationObject,
                      setLoading,
                      setAlert,
                      setReloadFieldConfigurationList
                    )
                  }
                  color="primary"
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <GenericTable
              title="Payment Requests"
              columnData={columnData}
              rowData={rowData}
              showActions={{ view: false, edit: true, delete: true }}
              handleView={index => {
                console.log(index);
              }}
              handleEdit={index => setEditConfiguration(rowData[index])}
              handleDelete={index => {
                setDeleteConfigurationObject(rowData[index]);
                handleClickOpen();
              }}
            />
          </>
        ) : null}
      </Grid>
    </>
  );
};
export default ListFormConfiguration;
