import { URL_CONSTANTS } from '../../../constants/urlConstants';

const warrantyInfoConstants = {
  baseUrl: URL_CONSTANTS.API.BASE_URL,
  getWarrantyInfoEndpoint: projectId => {
    return `projects/${projectId}/warranty`;
  },
  saveWarrantyInfoEndpoint: projectId => {
    return `projects/${projectId}/warranty`;
  },
};
export default warrantyInfoConstants;
