import { LEAD_RESPONSE_REASON } from '../../../../constants';

import TestedLeadNegative from './TestedLeadNegative';
import TestedLeadPositive from './TestedLeadPositive';
import PresumedLead from './PresumedLead';
import LrrpDocumentation from './LrrpDocumentation';

const Index = ({ option, LrrpConfigurationFormik, setBtnDisables }) => {
  switch (option) {
    case LEAD_RESPONSE_REASON[1]:
      return (
        <TestedLeadNegative
          LrrpConfigurationFormik={LrrpConfigurationFormik}
          setBtnDisables={setBtnDisables}
        />
      );
      break;

    case LEAD_RESPONSE_REASON[2]:
      return (
        <TestedLeadPositive
          LrrpConfigurationFormik={LrrpConfigurationFormik}
          setBtnDisables={setBtnDisables}
        />
      );
      break;

    case LEAD_RESPONSE_REASON[5]:
      return (
        <PresumedLead
          LrrpConfigurationFormik={LrrpConfigurationFormik}
          setBtnDisables={setBtnDisables}
        />
      );
      break;
    case LEAD_RESPONSE_REASON[7]:
      return (
        <LrrpDocumentation
          LrrpConfigurationFormik={LrrpConfigurationFormik}
          setBtnDisables={setBtnDisables}
        />
      );
      break;
    default:
      return null;
      break;
  }
};
export default Index;
