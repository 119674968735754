// **** External Utilities ****
import axios from 'axios';

import apiService from '../../../services/api.service';
import { redirectPage, checkPermission } from '../../../utils/Helpers';
import { getQuoteById, putTurnOnTime } from '../../CRM/Quotes/helpers/Quotes';
import {
  createLaborItem,
  createMerchandiseItem,
} from '../ProjectItemsNew/ProjectItemsService';
import { CONFIG, SOURCE_SYSTEMS } from '../../../constants';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import permissions from '../../../config/permissions';
import { ERROR_CODES } from '../../../constants/errorCode.constant';

export const getSMSNotifications = async (
  setSMSLoading,
  setNotificationsList,
  offset,
  limit,
  setSmsCount
) => {
  try {
    setSMSLoading(true);
    let smsDetailsRequest = {};
    if (checkPermission(permissions.pullDownMenu.view)) {
      smsDetailsRequest = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/projects/reports/sms?offset=${offset}&limit=${limit}`
      );
    }

    setSmsCount(smsDetailsRequest?.data?.count);
    if (smsDetailsRequest?.data) {
      const newNotificationList = [];
      if (smsDetailsRequest?.data) {
        newNotificationList.push(...smsDetailsRequest.data?.smsList);
      }
      setNotificationsList(newNotificationList);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setSMSLoading(false);
  }
};

export const getEmailNotifications = async (
  setEmailLoading,
  setEmailList,
  offset,
  limit,
  setEmailCount
) => {
  try {
    setEmailLoading(true);
    let response = {};
    if (checkPermission(permissions.pullDownMenu.view)) {
      response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/email/manage/user?offset=${offset}&limit=${limit}`
      );
    }

    setEmailCount(response?.data?.count);
    if (response?.data && response?.data?.items?.length) {
      const newEmailList = [];
      if (response?.data?.items) {
        newEmailList.push(...response.data.items);
      }
      setEmailList(newEmailList);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setEmailLoading(false);
  }
};
export const getStoreNotifications = async (
  setStoreLoading,
  setStoreNotificationsList,
  offset,
  limit,
  setStoreCount
) => {
  try {
    setStoreLoading(true);
    let response = {};
    if (checkPermission(permissions.pullDownMenu.view)) {
      response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/projects/notes/store?offset=${offset}&limit=${limit}`
      );
    }

    setStoreCount(response?.data?.count);
    if (response?.data && response?.data?.storeItems?.length) {
      const newStoreList = [];
      if (response?.data?.storeItems) {
        newStoreList.push(...response.data.storeItems);
      }
      setStoreNotificationsList(newStoreList);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setStoreLoading(false);
  }
};

export const getInstallerNotifications = async (
  setInstallerLoading,
  setInstallerNotificationsList,
  offset,
  limit,
  installerNotificationsList,
  setShowInstallerLoadMore,
  setTotalInstallerNotificationCount
) => {
  try {
    setInstallerLoading(true);
    let response = {};
    if (checkPermission(permissions.pullDownMenu.view)) {
      response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/projects/notes/installer?offset=${offset}&limit=${limit}`
      );
    }

    setTotalInstallerNotificationCount(response?.data?.count);

    if (response?.data && response?.data?.installerItems?.length) {
      const newInstallerList = [];
      if (response?.data?.installerItems) {
        newInstallerList.push(...response.data.installerItems);
      }

      setInstallerNotificationsList(newInstallerList);
      if (response?.data?.installerItems.length < limit) {
        setShowInstallerLoadMore(false);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setInstallerLoading(false);
  }
};

export const storeReviewRead = async (noteId, type = 'review', userId) => {
  try {
    await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/notes/store/${noteId}/${type}`,
      {
        userId,
        type: 'store',
      }
    );
  } catch (error) {
    console.log('Review Store Error', error);
  }
};

export const installerReviewRead = async (noteId, type = 'review', userId) => {
  try {
    await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/notes/installer/${noteId}/${type}`,
      {
        userId,
        type: 'store',
      }
    );
  } catch (error) {
    console.log('Review Installer Error', error);
  }
};
// Get Project Details by Id
export const getProjectById = async (
  projectId,
  setLoading,
  setAlert,
  setProjectDetailsData,
  setStoreDetails,
  setCustomerDetails,
  setReportStatusDetails,
  setAllUserList,
  unAuthorizedAccessHandler = () => {}
) => {
  try {
    setLoading(true);
    const projectDetailsRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`
    );

    const storeDetailsRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/reports/project-store-details/${projectId}`
    );
    const responses = await Promise.all([
      projectDetailsRequest,
      storeDetailsRequest,
    ]);

    if (responses[0]?.data?.customer_id) {
      getCustomerDetails(
        responses[0]?.data?.customer_id,
        setLoading,
        setCustomerDetails,
        ''
      );
    }
    setReportStatusDetails({
      name: responses[0]?.data?.status?.status,
      status_id: responses[0]?.data?.status?.status_id,
    });
    setProjectDetailsData(responses[0]?.data);
    setStoreDetails(responses[1]?.data);
    if (
      responses[0]?.data?.project_additional_fields?.related_measurer_id
        ?.first_name
    ) {
      const response = await searchUserList(
        responses[0]?.data?.project_additional_fields?.related_measurer_id
          ?.first_name
      );
      if (response) {
        setAllUserList(response);
      }
    }
  } catch (error) {
    if (error.response.status == ERROR_CODES.FORBIDDEN) {
      unAuthorizedAccessHandler();
    } else {
      setAlert('error', 'Error in Fetching Project Details.');
    }
  } finally {
    setLoading(false);
  }
};

// Get Project Type dropdown options
export const getUserProfile = async setUserProfile => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/profile`
    );
    setUserProfile(response?.data);
    localStorage.setItem(
      'logged_in_user',
      `${response?.data?.first_name} ${response?.data?.last_name}`
    );
    localStorage.setItem('installer_id', response?.data?.installer_id);
    localStorage.setItem('user_id', response?.data?.user_id);
    localStorage.setItem('role_name', response?.data?.role_name);
    localStorage.setItem('role_id', response?.data?.role_id);
    localStorage.setItem('is_installer', response?.data?.is_installer);
  } catch (error) {
    console.log(error);
  }
};

// Get Project Type dropdown options
export const searchProjects = async (
  searchStringVal,
  searchParameter,
  redirectionMethod,
  history,
  setOptions,
  searchSuggession,
  setIsHardRefresh,
  setGlobalSearchLoader,
  show
) => {
  const pathName = window.location.pathname;
  const segments = pathName.split('/');
  let redirectPath = null;
  if (segments[1] === 'crm') {
    redirectPath = `/crm/${segments[2]}/${segments[3]}/project/view/${
      segments[segments.length - 1]
    }`;
  }
  let url;
  let query;
  let additionalParams = '';
  setGlobalSearchLoader && setGlobalSearchLoader(true);
  switch (searchParameter) {
    case 'project_number':
      url = 'projects/search-by-project-number';
      query = 'project_number';
      break;
    case 'po_number':
      url = 'projects/search-by-po-number';
      query = 'po_number';
      break;
    case 'client':
      url = 'customers/report/search-by-name';
      query = 'searchString';
      additionalParams += '&offset=0&limit=20';
      break;
    case 'phone':
      url = 'customers/report/search-by-phone-number';
      query = 'searchString';
      break;
    case 'address':
      url = 'projects/search-by-address';
      query = 'k';
      break;
  }

  if (searchStringVal) {
    try {
      setGlobalSearchLoader && setGlobalSearchLoader(true);
      const response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/${url}?${query}=${searchStringVal}${additionalParams}`
      );
      if (response?.data && response?.data?.length && !searchSuggession) {
        if (
          searchParameter === 'project_number' ||
          searchParameter === 'po_number'
        ) {
          let projectId = null;
          if (response.data.length === 1) {
            projectId = response.data[0].project_id;
          } else {
            projectId = response.data.find(
              record =>
                parseInt(searchStringVal) == parseInt(record.project_number)
            )?.project_id;
          }
          if (projectId) {
            const redirection = redirectPath
              ? redirectPath
              : `/project/view/${projectId}`;
            redirectPage(
              `${redirection}/#tab=scheduleInfo?searchType=${searchParameter}&searchValue=${searchStringVal}`,
              redirectionMethod
            );
          } else {
            show && show();
          }
        }
      }
      let data = response.data;
      if (
        !['project_number', 'po_number', 'address'].includes(searchParameter)
      ) {
        data = data.items;
      }
      setOptions(data);
      if (setIsHardRefresh) setIsHardRefresh(Math.random());
      setGlobalSearchLoader && setGlobalSearchLoader(false);
    } catch (error) {
      setGlobalSearchLoader && setGlobalSearchLoader(false);
    }
  }
};

export const searchCustomers = async searchString => {
  try {
    if (searchString) {
      const response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/customers?searchString=${searchString}&offset=0&limit=10`
      );
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};

// Get Project Type, Category, Source, Customers dropdown options
export const getDropdownValues = async (
  setLoading,
  setProjectTypeOptions,
  setSourceOptions,
  setCustomerOptions,
  setCategoryOptions,
  setStoreOptions,
  setInitialCustomerOptions,
  setReferralSourceOptions
) => {
  try {
    setLoading(true);

    const projectTypeRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-type`
    );
    const categoryRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-category`
    );
    const sourceRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/source-system`
    );
    const customerRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/customers`
    );
    const storeRequest = axios.get(`${URL_CONSTANTS.API.BASE_URL}/stores`);
    const referralSourceName = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/referral-source`
    );

    const responses = await Promise.all([
      projectTypeRequest,
      categoryRequest,
      sourceRequest,
      customerRequest,
      storeRequest,
      referralSourceName,
    ]);

    setProjectTypeOptions(responses[0]?.data);
    setCategoryOptions(responses[1]?.data);
    setSourceOptions(responses[2]?.data);
    setCustomerOptions(responses[3]?.data);
    setStoreOptions(responses[4]?.data);
    setInitialCustomerOptions(responses[3]?.data);
    setReferralSourceOptions(responses[5]?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
// Get Store Details by store id
export const getStoreDetailsByStoreId = async (
  storeId,
  setLoading,
  setStoreDetails
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}`
    );
    setStoreDetails(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const addNewCategory = async (
  categoryName,
  setNewCategoryLoading,
  setNewCategoryError,
  setCategoryPopupOpen,
  setCategoryOptions
) => {
  try {
    setNewCategoryLoading(true);
    const response = await apiService.post(
      `/projects/master/project-category`,
      {
        category: categoryName,
      }
    );
    if (response) {
      setCategoryOptions(response);
      setCategoryPopupOpen(false);
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 422) {
      setNewCategoryError(
        'This category already exists, please try again with a different name.'
      );
    } else {
      setNewCategoryError(
        'Something went wrong, please try again after sometime.'
      );
    }
  } finally {
    setNewCategoryLoading(false);
  }
};

// Get Customer's Address
export const getCustomerAddress = async (
  billingAddressId,
  setCustomerAddress
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/address/${billingAddressId}`
    );
    setCustomerAddress(response?.data);
  } catch (error) {
    console.log(error);
  }
};

// Get Status dropdown options
export const getStatusOptions = async (
  statusType,
  setLoading,
  setStatusOptions
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=${statusType}`
    );
    let statusOptions = response.data;
    const isTentativeSchedulingEnabled =
      localStorage.getItem('isTentativeSchedulingEnabled') === 'true'
        ? true
        : false;
    if (!isTentativeSchedulingEnabled) {
      statusOptions = statusOptions?.filter(
        item => item.status !== 'Tentatively Scheduled'
      );
    }
    setStatusOptions(statusOptions);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Update Project Details
export const updateProject = async (
  projectId,
  updateObj,
  history,
  setReloadForm,
  setLoading,
  setAlert,
  redirectBasePath
) => {
  try {
    let updateRequestObj = {
      total_sale_amount: updateObj?.total_sale_amount || null,
      confirmation_status_id: updateObj?.confirmation_status?.status_id,
      source_system_id: updateObj?.source_system?.source_system_id,
      customer_id: updateObj?.customer?.customer_id,
      store_id: updateObj?.store_id,
      po_number: updateObj?.po_number,
      quote_number: updateObj?.quote_number,
      work_order_number: updateObj?.work_order_number,
      date_sold: updateObj?.date_sold,
      status_id: updateObj?.status?.status_id,
      project_desc: updateObj?.project_desc?.trim() || null,
      project_type_id: updateObj?.project_type?.project_type_id,
      project_category_id: updateObj?.project_category_id,
      source_category_id: updateObj?.source_category_id,
      reschedule_reason_id: updateObj?.reschedule_reason?.reason_id,
      date_scheduled_start: updateObj?.date_scheduled_start,
      date_scheduled_end: updateObj?.date_scheduled_end,
      completion_date: updateObj?.completion_date,
      windows: updateObj?.windows,
      rts_follow_up_date: updateObj?.rts_follow_up_date,
      pick_up_date: updateObj?.pick_up_date,
      pick_up_location_id: updateObj?.pick_up_location_id,
      open_in_provider_portal: updateObj?.open_in_provider_portal,
      epa_add_lead_safe_practices_item:
        updateObj?.epa_add_lead_safe_practices_item,
      epa_lead_response_reason_id: updateObj?.epa_lead_response_reason_id,
      epa_override_reason_code_id: updateObj?.epa_override_reason_code_id,
      epa_is_renovator_certified: updateObj?.epa_is_renovator_certified,
      epa_is_lead_assessment_docs_submitted:
        updateObj?.epa_is_lead_assessment_docs_submitted,
      epa_lead_assessment_required: updateObj?.epa_lead_assessment_required,
      epa_lead_remediation: updateObj?.epa_lead_remediation,
      epa_job_id: updateObj?.epa_job_id,
      epa_lead_test_result_id: updateObj?.epa_lead_test_result_id,
      epa_remove_override: updateObj?.epa_remove_override,
      epa_permit_required: updateObj?.epa_permit_required,
      epa_permit_number: updateObj?.epa_permit_number,
      installation_address: [{ ...updateObj?.installation_address }],
      installer_reference: updateObj?.installer_reference,
      meta: updateObj?.meta || {},
      scheduled_date_811: updateObj?.scheduled_date_811 || null,
      is_pull_permit_required: updateObj?.is_pull_permit_required ? 1 : 0,
      hd_category: updateObj?.hd_category || null,
      tier: updateObj?.tier || null,
      est_job_duration: updateObj?.est_job_duration?.toString() || null,
      pickup_required: updateObj?.pickup_required,
      products: updateObj?.products === '' ? null : updateObj?.products,
      year_built: updateObj?.year_built,
      pick_up_location_address_id:
        updateObj?.pick_up_location_address_id || null,
      manage_as_project: updateObj?.manage_as_project,
      move_up_on_schedule:
        updateObj?.move_up_on_schedule === 1 ? true : false || false,
      referral_source_id: updateObj?.referral_source_id || null,
      scheduling_reason: updateObj?.scheduling_reason?.label
        ? updateObj?.scheduling_reason?.label
        : updateObj?.scheduling_reason || null,
      schedule_pending_reason: updateObj?.schedule_pending_reason?.label
        ? updateObj?.schedule_pending_reason?.label
        : updateObj?.schedule_pending_reason || null,
      related_measurer: updateObj?.related_measurer || null,
      eta_date: updateObj?.eta_date || null,
      ll_invoice_status: updateObj?.ll_invoice_status || null,
      ll_invoice_sent_date: updateObj?.ll_invoice_sent_date || null,
      ll_pgi_invoice_date: updateObj?.ll_pgi_invoice_date || null,
      estimated_labour_amount: updateObj?.estimated_labour_amount || null,
      cushion_payout: updateObj?.cushion_payout || null,
      wo_triage_id: updateObj?.wo_triage_id || null,
      wo_original_install_date: updateObj?.wo_original_install_date || null,
      wo_wfp_eta: updateObj?.wo_wfp_eta || null,
      wo_original_cause_id: updateObj?.wo_original_cause_id || null,
      wo_explaination: updateObj?.wo_explaination || null,
      wo_reason_id: updateObj?.wo_reason_id || null,
      vendor_id: updateObj?.vendor_id || null,
      sos_po_vendor: updateObj?.sos_po_vendor || null,
      digital_detail_url:
        updateObj?.project_additional_fields?.digital_detail_url || null,
      project_color_config:
        typeof updateObj?.project_color_config === 'number'
          ? updateObj.project_color_config
          : null,
      workroom_id: updateObj?.workroom_id || null,
    };
    if (updateObj?.quoteId) {
      updateObj = { ...updateObj, quote_id: updateObj?.quoteId };
    }
    if (
      updateObj?.source_system?.source_system_id === SOURCE_SYSTEMS.LOWES &&
      updateObj?.project_type?.project_type_id === 2
    ) {
      updateRequestObj.dd_confirm = updateObj?.dd_confirm;
      updateRequestObj.dd_confirm_follow_up = updateObj?.dd_confirm_follow_up;
    }

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      updateRequestObj
    );
    if (response) {
      if (response?.data?.status) {
        history.push(
          `${redirectBasePath}?reportName=${updateObj?.status?.status}`
        );
        setReloadForm(true);
        setAlert(
          'success',
          'Project has been updated successfully.',
          false,
          true
        );
      } else {
        setAlert('error', response?.data?.message);
      }
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
    setLoading(false);
  }
};

// Get Customer Details like phone,email
export const getCustomerDetails = async (
  customerId,
  setLoading,
  setCustomerDetails,
  setFieldValue,
  setInstallationAddress
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/customers/${customerId}`
    );

    setCustomerDetails(response?.data);
    if (response?.data[0]?.billing_address_id) {
      const installationResponse = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/system/address/${response?.data[0]?.billing_address_id}`
      );
      if (setFieldValue) {
        setFieldValue('customer', response?.data[0]);
        setInstallationAddress(installationResponse?.data);
        setFieldValue('installation_address', installationResponse?.data);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getStoreDetails = async (
  projectId,
  setLoading,
  setStoreDetails
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/reports/project-store-details/${projectId}`
    );
    setStoreDetails(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const updateProjectStatus = async (
  projectId,
  status,
  setReloadForm,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-button-action/${status}`
    );
    response && setReloadForm(true);
  } catch (error) {
    console.log(error);
  }
};
export const updateWorkOrder = async (
  projectId,
  status,
  setReloadForm,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-button-action/${status}`
    );
    response && setReloadForm(true);
  } catch (error) {
    console.log(error);
  }
};

export const updateProjectContract = async (
  projectId,
  status,
  setReloadForm,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/status`,
      { status: status }
    );
    response && setReloadForm(true);
  } catch (error) {
    console.log(error);
  }
};
// Create New Project
export const addProject = async (
  addProjectObj,
  history,
  reportName,
  setLoading,
  setAlert,
  opportunityId
) => {
  try {
    setLoading(true);
    let addNewProject = {
      //Project Info fields
      total_sale_amount: addProjectObj?.total_sale_amount || null,
      source_system_id: addProjectObj?.source_system?.source_system_id,
      source_status_id: 27,
      confirmation_status_id: addProjectObj?.confirmation_status?.status_id,
      customer_id: addProjectObj?.customer?.customer_id,
      store_id: addProjectObj?.store_id,
      po_number: addProjectObj?.po_number || null,
      project_number: addProjectObj?.project_number || null,
      date_sold: addProjectObj?.date_sold || new Date(),
      status_id: addProjectObj?.status?.status_id || null,
      project_desc: addProjectObj?.project_desc?.trim() || null,
      project_type_id: addProjectObj?.project_type?.project_type_id,
      project_category_id: addProjectObj?.project_category_id,
      source_category_id: addProjectObj?.source_category_id,
      manage_as_project: addProjectObj?.manage_as_project,
      referral_source_id: addProjectObj?.referral_source_id || null,
      related_measurer: addProjectObj?.related_measurer || null,
      ll_invoice_status: addProjectObj?.ll_invoice_status || null,
      ll_invoice_sent_date: addProjectObj?.ll_invoice_sent_date || null,
      estimated_labour_amount: addProjectObj?.estimated_labour_amount || null,
      cushion_payout: addProjectObj?.cushion_payout || null,
      digital_detail_url:
        addProjectObj?.project_additional_fields?.digital_detail_url || null,
      workroom_id: addProjectObj?.workroom_id || null,

      //Schedule Info fields
      reschedule_reason_id: addProjectObj?.reschedule_reason?.reason_id || null,
      date_scheduled_start: addProjectObj?.date_scheduled_start || null,
      date_scheduled_end: addProjectObj?.date_scheduled_end || null,
      completion_date: addProjectObj?.completion_date || null,
      windows: addProjectObj?.windows || null,
      rts_follow_up_date: addProjectObj?.rts_follow_up_date || null,
      pick_up_date: addProjectObj?.pick_up_date || null,
      pick_up_location_id: addProjectObj?.pick_up_location_id,
      installation_address: [addProjectObj?.installation_address],
      open_in_provider_portal: addProjectObj?.open_in_provider_portal || null,
      installer_reference: addProjectObj?.installer_reference,
      meta: addProjectObj?.meta || {},
      scheduled_date_811: addProjectObj?.scheduled_date_811 || null,
      is_pull_permit_required: addProjectObj?.is_pull_permit_required ? 1 : 0,
      hd_category: addProjectObj?.hd_category || null,
      tier: addProjectObj?.tier || null,
      est_job_duration: addProjectObj?.est_job_duration?.toString() || null,
      year_built: addProjectObj?.year_built || null,
      pickup_required: addProjectObj?.pickup_required || null,
      products: addProjectObj?.products === '' ? null : addProjectObj?.products,
      pick_up_location_address_id:
        addProjectObj?.pick_up_location_address_id || null,
      move_up_on_schedule:
        addProjectObj?.move_up_on_schedule === 1 ? true : false || false,
      scheduling_reason: addProjectObj?.scheduling_reason?.label
        ? addProjectObj?.scheduling_reason?.label
        : addProjectObj?.scheduling_reason || null,
      schedule_pending_reason: addProjectObj?.schedule_pending_reason?.label
        ? addProjectObj?.schedule_pending_reason?.label
        : addProjectObj?.schedule_pending_reason || null,
      eta_date: addProjectObj?.eta_date || null,
      vendor_id: addProjectObj?.vendor_id || null,
      sos_po_vendor: addProjectObj?.sos_po_vendor || null,
      project_color_config:
        typeof addProjectObj?.project_color_config === 'number'
          ? addProjectObj.project_color_config
          : null,
    };
    if (addProjectObj?.quoteId) {
      addNewProject = {
        ...addNewProject,
        quote_id: addProjectObj.quoteId,
        opportunity_number: addProjectObj.opportunity_number,
        quote_number: addProjectObj.quote_number,
        opportunity_id: opportunityId || null,
      };
    }
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects`,
      addNewProject
    );
    if (response && response?.data) {
      if (response?.data?.status === false) {
        setAlert('error', response?.data?.message, false, true);
      } else {
        if (addProjectObj?.quoteId) {
          const putTurnOnTimeMS = putTurnOnTime(
            opportunityId,
            addProjectObj?.quoteId,
            response?.data?.project_id
          );
          const quoteDetails = getQuoteById(
            response.data?.client_id,
            opportunityId,
            addProjectObj?.quoteId
          );
          quoteDetails
            .then(quote => {
              if (response?.data?.project_id) {
                const lineItems = quote?.data?.data?.lineItems?.items || [];
                if (lineItems?.length > 0) {
                  lineItems.map(async lineItem => {
                    if (lineItem?.itemTypeId == 2) {
                      const postObject = {
                        item_id: lineItem?.itemId,
                        item_quantity: lineItem?.quantity,
                        cost_ea: lineItem?.unitPrice,
                        edited_labor_cost_ea: 0,
                        quote_id: addProjectObj?.quoteId,
                        discount: lineItem?.discount,
                        discount_type: lineItem?.discountType,
                        tax: lineItem?.tax,
                        sell_price: lineItem?.unitPrice,
                      };
                      const itemDetails = await apiService.get(
                        `/system/items/${lineItem?.itemId}`
                      );

                      if (
                        itemDetails?.revenue_price &&
                        parseFloat(`${itemDetails?.revenue_price}`) > 0
                      ) {
                        postObject.cost_ea = itemDetails.revenue_price;
                      }

                      createLaborItem(response.data?.project_id, postObject);
                    } else {
                      const postObject = {
                        item_id: lineItem?.itemId,
                        item_quantity: lineItem?.quantity,
                        cost_ea: lineItem?.unitPrice,
                        sell_price: lineItem?.unitPrice,
                        quote_id: addProjectObj?.quoteId,
                        discount: lineItem?.discount,
                        discount_type: lineItem?.discountType,
                        tax: lineItem?.tax,
                        edited_labor_cost_ea: 0,
                      };
                      const itemDetails = await apiService.get(
                        `/system/items/${lineItem?.itemId}`
                      );
                      if (
                        itemDetails?.revenue_price &&
                        parseFloat(`${itemDetails?.revenue_price}`) > 0
                      ) {
                        postObject.cost_ea = itemDetails.revenue_price;
                      }
                      createMerchandiseItem(
                        response.data?.project_id,
                        postObject
                      );
                    }
                  });
                }
              }
              if (opportunityId) {
                history.push(
                  `/crm/${addNewProject?.customer_id}/${opportunityId}/project/view/${
                    response.data?.project_id
                  }?reportName=${reportName || addProjectObj?.status?.status}`
                );
              } else {
                history.push(
                  `/project/view/${response.data?.project_id}?reportName=${
                    reportName || addProjectObj?.status?.status
                  }`
                );
              }
            })
            .catch(error => {
              if (error?.response?.status === 404) {
                return history.push('/page-not-found');
              }
              console.log('error', error?.response);
            });
        } else {
          history.push(
            `/project/view/${response.data?.project_id}?reportName=${
              reportName || addProjectObj?.status?.status
            }`
          );
        }
        setAlert(
          'success',
          'New Project has been added successfully',
          false,
          true
        );
      }
    }
  } catch (error) {
    setAlert('error', 'Error in Adding New Project', false, true);
  } finally {
    setLoading(false);
  }
};

export const storeUpdateReadStatus = (noteId, type, userId) => {
  axios.patch(`${URL_CONSTANTS.API.BASE_URL}/projects/notes/store`, {
    noteId,
    type,
    userId,
  });
};

export const installerUpdateReadStatus = (noteId, type, userId) => {
  axios.patch(`${URL_CONSTANTS.API.BASE_URL}/projects/notes/installer`, {
    noteId,
    type,
    userId,
  });
};

//Add Notes
export const addBustedReason = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setIsOpen
) => {
  setIsOpen(false);
  try {
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/project-button-action/busted`,
      values
    );
    response.data && setReloadList(true);
    setAlert('success', 'Busted Reason Added Successfully.', false, true);
  } catch (error) {
    setAlert('error', 'Error in Busted Reason.', false, true);
  } finally {
    setLoading(false);
  }
};

//Ready To Schedule Quick Action
export const readyToScheduleQuickAction = async (projectId, values) => {
  try {
    const response = await axios.patch(
      `${CONFIG?.API_BASE_URL}/projects/${projectId}/project-button-action/ready-to-schedule`,
      values
    );
    return { status: true, data: response };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

// Search User's
export const searchUserList = async searchString => {
  try {
    if (searchString) {
      const searchResponse = await apiService.get(
        `/auth/user/report/list?searchString=${searchString}&limit=20&offset=0`
      );
      return searchResponse?.items;
    } else {
      const userList = await apiService.get(
        `/auth/user/report/list?limit=20&offset=0`
      );
      return userList?.items;
    }
  } catch (error) {
    console.error(error);
  }
};

//Repair Order
export const repairOrderRecord = async (
  projectId,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.patch(
      `${CONFIG?.API_BASE_URL}/projects/${projectId}/project-button-action/update-project-by-type`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

//Project Action Buttons
export const actionButton = async (projectId, actionType) => {
  try {
    const response = await apiService.postWithDiffBaseUrl(
      `/projects/${projectId}/action/${actionType}`,
      '',
      CONFIG?.API_PROJECT_URL
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

//GetProjectColors
export const getColorConfigList = async () => {
  try {
    const response = await apiService.get(`/admin-config/color-config/list`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
//Get Workroom
export const getWorkRoom = async value => {
  try {
    const response = await apiService.get(
      `/system/workroom/list?searchString=${value}&limit=100&offset=0`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getGroupName = async value => {
  try {
    const response = await apiService.get(`/system/parent-category/list`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const groupTypes = async (value = null) => {
  try {
    const groupType = await apiService.get(`/master/group-name/${value}`);
    return groupType;
  } catch (error) {
    return error;
  }
};
