import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';
import { Settings } from '@material-ui/icons';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

import PFInputText from '../../shared/PFPrime/PFInputText';
import { PF_ADMIN } from '../../../constants';
import TableLoader from '../../shared/Loader/TableLoader';
import PageHeader from '../../shared/PageHeader/PageHeader';

import {
  getAllClientData,
  getClientDataBySearch,
} from './service/TenantConfig.service';
import EditCompanyConfig from './EditCompanyConfig';

const CompanyConfig = () => {
  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Configurations',
    },
  ];
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [update, setUpdate] = useState(true);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const clientId = localStorage.getItem('client_id');

  const fetchData = async () => {
    try {
      const response = await getAllClientData(first, rows);
      const responseData = response?.data;
      setClientData(
        responseData?.rows?.map(item => ({
          client_id: item.client_id,
          client_name: item.client_name,
          status: item.active,
        }))
      );
      setTotalRecords(responseData?.count);
      setLoading(false);
    } catch (error) {
      setError('Error fetching data from the API');
      setLoading(false);
    } finally {
      setIsDataLoading(false);
    }
  };

  const onPageChange = event => {
    const offset = event.page * event.rows;
    const limit = event.rows;
    setFirst(offset);
    setRows(limit);
    setUpdate(true);
  };

  useEffect(() => {
    if (clientId === PF_ADMIN && update === true) {
      fetchData();
      setUpdate(false);
    }
  }, [update, first, rows]);

  const clientNameTemplate = rowData => {
    if (rowData?.client_name) {
      return rowData?.client_name;
    } else {
      return null;
    }
  };
  const clientIdTemplate = rowData => {
    if (rowData?.client_id) {
      return rowData?.client_id;
    } else {
      return null;
    }
  };

  const ManageTemplate = rowData => {
    return (
      <div>
        <Button
          icon="pi pi-arrow-up-right"
          text
          className="p-1 w-2rem "
          onClick={() =>
            window.open(
              `/admin/company-config/edit/${rowData.client_id}`,
              '_blank'
            )
          }
        ></Button>
      </div>
    );
  };

  const columns = [
    {
      header: 'Identifiers',
      field: 'client_id',
      body: clientIdTemplate,
      style: { width: '230px' },
    },

    {
      header: 'Client Name',
      body: clientNameTemplate,
      field: 'client_name',
    },
    {
      header: 'Action',
      body: ManageTemplate,
      field: 'Module',
      style: { width: '300px' },
    },
  ];

  const filterClientName = async searchText => {
    setSearchTerm(searchText);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(async () => {
      try {
        if (searchText?.length < 3) {
          await fetchData();
          return;
        }

        const searchResponse = await getClientDataBySearch(searchText);
        if (searchResponse?.data) {
          setClientData(
            searchResponse?.data?.rows?.map(item => ({
              client_id: item.client_id,
              client_name: item.client_name,
              status: item.active,
            }))
          );
          setTotalRecords(searchResponse?.data?.count);
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);

    setDebounceTimeout(newDebounceTimeout);
  };

  return (
    <>
      <div className="grid w-12 flex justify-content-between align-items-center pt-2 overlay-x-hidden">
        <div className="flex w-full justify-content-between pb-2">
          <PageHeader
            pageTitle="Company Configurations"
            breadCrumbArray={accessBreadcrumb}
          />
          {clientId === PF_ADMIN ? (
            <div className="mb-2">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <PFInputText
                  i="pi pi-search"
                  type="text"
                  value={searchTerm}
                  onChange={async e => await filterClientName(e.target.value)}
                  placeholder="Search by Client Name"
                />
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {clientId === PF_ADMIN ? (
        <div className="m-2 w-full">
          <Card className="w-full">
            {isDataLoading ? (
              <TableLoader columnCount={5} noOfRow={5} />
            ) : (
              <>
                <DataTable
                  size="small"
                  value={clientData}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  className="mt-3 w-full p-datatable-striped"
                >
                  {columns.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field ? col.field : ''}
                      header={col.header ? col.header : ''}
                      body={col.body ? col.body : () => {}}
                      pt={{
                        headerCell: { className: 'bg-primary' },
                      }}
                      className={col.className ? col.className : ''}
                      style={col.style ? col.style : {}}
                    />
                  ))}
                </DataTable>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                ></Paginator>
              </>
            )}
          </Card>
        </div>
      ) : (
        <EditCompanyConfig />
      )}
    </>
  );
};
export default CompanyConfig;
