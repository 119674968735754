import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export default function PFDialog({
  show,
  hide,
  BodyComponent,
  header,
  className,
  headerStyle,
  contentStyle,
  contentClassName,
  headerClassName,
  style,
  footer = null,
  pt = {},
  ...rest
}) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(show);
  }, [show]);

  return (
    <Dialog
      header={header || ''}
      visible={visible || false}
      style={{ ...(style || { width: '50vw' }) }}
      onHide={() => hide(false)}
      headerStyle={{ zoom: '80%', ...(headerStyle || '') }}
      className={`${className || ''}`}
      contentStyle={{ ...(contentStyle || '') }}
      contentClassName={`${contentClassName || ''}`}
      headerClassName={`${headerClassName || ''}`}
      footer={footer}
      pt={pt}
      {...rest}
    >
      {BodyComponent}
    </Dialog>
  );
}
