export const removeSpaces = str => {
  if (!str) return '';
  return str.replace(/\s+/g, '');
};

export const isValidHex = hex => {
  if (!hex) return false;
  const hexPattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
  return hexPattern.test(hex);
};
