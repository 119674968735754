import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

import WarehouseFormButton from './WarehouseFormButton';

const AddWarehouseForm = ({
  formik,
  onFormSubmitHandler,
  onCancelBtnClickHandler,
  btnText,
}) => {
  const isFormFieldInvalid = name =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = name => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error error text-xs text-red-500">
        {formik.errors[name]}
      </small>
    ) : null;
  };

  return (
    <>
      <div className="formgrid grid mx-2 mt-3">
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="name"
              id="name"
              className="p-inputtext p-component w-12"
              value={formik.values.name}
              onChange={e => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            <label htmlFor="name" className="text-sm mb-0">
              Name <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('name')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="addressLineOne"
              id="addressLineOne"
              className="w-12"
              value={formik.values.addressLineOne}
              onChange={e => {
                formik.setFieldValue('addressLineOne', e.target.value);
              }}
            />
            <label htmlFor="addressLineOne" className="text-sm mb-0">
              Address Line One <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('addressLineOne')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="manager"
              id="manager"
              className="w-12"
              value={formik.values.manager}
              onChange={e => {
                formik.setFieldValue('manager', e.target.value);
              }}
            />
            <label htmlFor="manager" className="text-sm mb-0">
              Manager
            </label>
          </span>
          {getFormErrorMessage('manager')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="addressLineTwo"
              id="addressLineTwo"
              className="w-12"
              value={formik.values.addressLineTwo}
              onChange={e => {
                formik.setFieldValue('addressLineTwo', e.target.value);
              }}
            />
            <label htmlFor="addressLineTwo" className="text-sm mb-0">
              Address Line Two
            </label>
          </span>
          {getFormErrorMessage('addressLineTwo')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="warehouseCode"
              id="warehouseCode"
              className="w-12"
              value={formik.values.warehouseCode}
              onChange={e => {
                formik.setFieldValue('warehouseCode', e.target.value);
              }}
            />
            <label htmlFor="warehouseCode" className="text-sm mb-0">
              Warehouse Code <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('warehouseCode')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="city"
              id="city"
              className="w-12"
              value={formik.values.city}
              onChange={e => {
                formik.setFieldValue('city', e.target.value);
              }}
            />
            <label htmlFor="city" className="text-sm mb-0">
              City <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('city')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputMask
              autoComplete="off"
              name="phoneNumber"
              id="phoneNumber"
              className="w-12"
              value={formik.values.phoneNumber}
              onChange={e => {
                formik.setFieldValue('phoneNumber', e.target.value);
              }}
              mask="(999) 999-9999"
            />
            <label htmlFor="phoneNumber" className="text-sm mb-0">
              Phone Number <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('phoneNumber')}
        </div>

        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="state"
              id="state"
              className="w-12"
              value={formik.values.state}
              onChange={e => {
                formik.setFieldValue('state', e.target.value);
              }}
            />
            <label htmlFor="state" className="text-sm mb-0">
              State <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('state')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="emailAddress"
              id="emailAddress"
              className="w-12"
              value={formik.values.emailAddress}
              onChange={e => {
                formik.setFieldValue('emailAddress', e.target.value);
              }}
            />
            <label htmlFor="emailAddress" className="text-sm mb-0">
              Email Id <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('emailAddress')}
        </div>
        <div className="field col-12 md:col-6 lg:col-6">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="zipcode"
              id="zipcode"
              className="w-12"
              value={formik.values.zipcode}
              onChange={e => {
                formik.setFieldValue('zipcode', e.target.value);
              }}
            />
            <label htmlFor="zipcode" className="text-sm mb-0">
              Zip Code <span className="text-red-500">*</span>
            </label>
          </span>
          {getFormErrorMessage('zipcode')}
        </div>
        <div className="field col-12 md:col-12 lg:col-12">
          <div className="flex justify-content-end">
            <WarehouseFormButton
              onCancelBtnClick={onCancelBtnClickHandler}
              onSubmitBtnClick={onFormSubmitHandler}
              btnText={btnText}
              formik={formik}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWarehouseForm;
