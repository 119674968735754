import React from 'react';
import { Card } from 'primereact/card';

import PFInputText from '../../PFPrime/PFInputText';
export default function SenderInfo({
  isView,
  isEdit,
  quoteDetails,
  tenantDetails,
}) {
  return (
    <Card className="mt-3 w-full border-200 border-solid border-1">
      <div htmlFor="Client Info">
        <div className="w-12 text-1xl font-bold p-1">Sender Details</div>
        <div className="grid flex flex-row flex-wrap w-full m-0 mt-2">
          <div className="formgroup-inline w-full">
            <div className="quote-expiry-date px-1 align-items-center justify-content-center w-3">
              <span className=" pb-1 pr-1">
                <PFInputText
                  id="senderName"
                  name="senderName"
                  type="text"
                  value={
                    isView || isEdit
                      ? quoteDetails?.senderDetails?.name
                      : tenantDetails?.sender_details?.name
                  }
                  disabled={true}
                  placeholder="Name"
                />
              </span>
            </div>
            <div className="quote-expiry-date px-1 align-items-center justify-content-center w-3">
              <span className=" pb-1 pr-1">
                <PFInputText
                  id="email"
                  name="email"
                  type="text"
                  value={
                    isView || isEdit
                      ? quoteDetails?.senderDetails?.email
                      : tenantDetails?.sender_details?.email
                  }
                  disabled={true}
                  placeholder="Email"
                />
              </span>
            </div>
            <div className="quote-followup-date px-1 align-items-center justify-content-center w-3">
              <span className="">
                <PFInputText
                  inputId="phone"
                  name="phone"
                  value={
                    isView || isEdit
                      ? quoteDetails?.senderDetails?.phone
                      : tenantDetails?.sender_details?.phone
                  }
                  disabled={true}
                  placeholder="Phone Number"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
