import {
  Grid,
  CircularProgress,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const BasicSection = ({
  action,
  WidgetTypeOptions,
  widgetFormik,
  setHidePreview,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Basic Details</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <ConfirmDialog />
        <Autocomplete
          id="chartType"
          fullWidth
          options={WidgetTypeOptions}
          getOptionLabel={option => option && option.value}
          renderInput={params => (
            <TextField {...params} label="Type" required />
          )}
          disabled={action === 'view' ? true : false}
          value={
            WidgetTypeOptions.find(
              item => item.key == widgetFormik.values?.widget_type
            ) || {}
          }
          onChange={(event, value) => {
            if (['card', 'gauge'].includes(value?.key)) {
              widgetFormik.setFieldValue('chart_configuration', {
                ...widgetFormik.values.chart_configuration,
                groupBy: [],
                orderByField: '',
              });
            }
            if (
              value?.key === 'table-report' &&
              widgetFormik.touched.chart_configuration
            ) {
              confirmDialog({
                message:
                  'There are some chart configuration changes made do you want to discard them?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () => {
                  widgetFormik.setFieldValue('widget_type', value?.key || '');
                  widgetFormik.setFieldValue('chart_configuration', {
                    dimension: '',
                    measure: '',
                    measureSummarizedBy: '',
                    measureTarget: 100,
                    groupBy: [],
                    orderByField: '',
                    orderBy: 'asc',
                  });
                },
                reject: () => {},
              });
            } else {
              widgetFormik.setFieldValue('custom_properties', {
                selectedChartTable: 'Project',
                selectedReportTable: 'Project',
                reportTablePrimaryKey: 'Project.project_id',
                chartMeta: null,
                buttonInfoUrl: '',
                buttonColor: '',
                buttonType: '',
                openIn: { name: 'Same Window', code: 'same-tab' },
                linkWidget: '',
                widgetProperty: '',
                buttonWidgetType: 'tabularWidget',
              });
              widgetFormik.setFieldValue('widget_type', value?.key || '');
            }
            setHidePreview(true);
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          label="Name"
          id="widget_name"
          value={widgetFormik.values?.widget_name}
          onChange={widgetFormik.handleChange}
          required
          disabled={action === 'view' ? true : false}
          inputProps={{
            maxLength: 150,
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Description"
          id="widget_description"
          value={widgetFormik.values?.widget_description}
          onChange={widgetFormik.handleChange}
          disabled={action === 'view' ? true : false}
          inputProps={{
            maxLength: 150,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BasicSection;
