import React, { useEffect, useState, useRef } from 'react';
import {
  TabView,
  TabPanel,
  DataTable,
  Column,
  Toast,
  Message,
} from 'primereact';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { formatDateDefault } from '../../../../utils/Helpers';
import TableSkeleton from '../../../Skeleton/TableSkeleton';
import Apiservice from '../../../../services/api.service';
import { DISTANCE_UNIT_OPTIONS } from '../../../../constants';

import InstallerPanel from './InstallerPanel';

const InstallerTabs = ({ selectedItem, dates, widgetId, isArchiveTab }) => {
  const [tabs, setTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [refereshTabs, setRefereshTabs] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchDate, setSearchDate] = useState(dates);
  const [oldSearchMessage, setOldSearchMessage] = useState(null);
  let autoFetchTimer = null;
  const fetchTabs = async () => {
    try {
      let apiEndPoint = `/routing/history/${selectedItem?.route_id}/${formatDateDefault(
        new Date(searchDate),
        '-'
      )}`;
      if (isArchiveTab) {
        apiEndPoint = `/routing/archive/${formatDateDefault(new Date(searchDate), '-')}`;
      }
      const response = await Apiservice.get(apiEndPoint);
      setOldSearchMessage(response.isOldRecordExist);
      if (response?.list && response.list.length) {
        setTabs(response.list);
        const checkInprogress = response.list?.find(
          record => record?.routing_status === 'INPROGRESS'
        );
        if (checkInprogress) {
          autoFetchTimer = setTimeout(() => {
            fetchTabs();
            setRefereshTabs(!refereshTabs);
          }, 5000);
        }
      } else {
        setTabs([]);
      }
    } catch (e) {
      setTabs([]);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: '3000',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    setSearchDate(dates);
    setIsLoading(true);
    await fetchTabs();
    setIsLoading(false);
    setActiveIndex(0);

    return () => {
      clearTimeout(autoFetchTimer);
    };
  }, [dates]);

  const approveAllRoute = async () => {
    setIsLoading(false);
    try {
      const response = await Apiservice.get(
        `/routing/history/${selectedItem?.route_id}/${formatDateDefault(new Date(dates), '-')}`
      );
      if (response && response.length) {
        console.log(response);
      }
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: '3000',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isShowStatusIndicator = status => {
    if (status == null) return true;
    return ['PENDING', 'INPROGRESS', 'COMPLETED'].includes(status);
  };

  const totalTravelTime = rowData => {
    return rowData?.total_travel_time == '' ||
      rowData?.total_travel_time == 'null' ||
      rowData?.total_travel_time === null ||
      rowData?.total_travel_time === 'NaN'
      ? null
      : rowData?.total_travel_time + ' minutes';
  };

  const totalServiceTime = rowData => {
    return rowData?.total_service_time == '' ||
      rowData?.total_service_time == 'null' ||
      rowData?.total_service_time === null ||
      rowData?.total_service_time === 'NaN'
      ? null
      : rowData?.total_service_time + ' minutes';
  };

  const totalDistance = rowData => {
    return rowData?.total_distance == '' ||
      rowData?.total_distance == 'null' ||
      rowData?.total_distance === null ||
      rowData?.total_distance === 'NaN'
      ? null
      : rowData?.total_distance +
          ' ' +
          (rowData?.distance_unit
            ? DISTANCE_UNIT_OPTIONS.find(
                record => record.id == rowData?.distance_unit
              )?.label
            : '');
  };
  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <Dialog
            header="Approve all Technicians"
            visible={visible}
            style={{ width: '50vw' }}
            onHide={() => setVisible(false)}
          >
            <DataTable
              className="my-3"
              value={tabs}
              tableStyle={{ minWidth: '20rem' }}
            >
              <Column field="installer_name" header="Technician Name" />
              <Column field="total_projects" header="Total Projects" />
            </DataTable>
            <div className="text-right">
              <Button
                className="my-2"
                size="small"
                onClick={() => approveAllRoute()}
              >
                Approve All
              </Button>
            </div>
          </Dialog>
          {oldSearchMessage && (
            <Message
              className="border-primary w-full justify-content-start mb-1"
              severity="warn"
              text={oldSearchMessage}
            />
          )}
          <TabView
            activeIndex={activeIndex}
            onTabChange={e => setActiveIndex(e.index)}
          >
            <TabPanel header={'All'} className="all-tab">
              {/* <div className="text-right">
                 <Button className="my-2" size="small" onClick={() => setVisible(true)}>
                   Approve All
                 </Button>
               </div> */}
              <DataTable
                className="my-3"
                value={tabs}
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column field="installer_name" header="Technician Name" />
                <Column field="start_time" header="Start Time" />
                <Column field="end_time" header="End Time" />
                <Column field="total_clients" header="Total Clients" />
                <Column field="total_projects" header="Total Projects" />
                <Column
                  field="total_distance"
                  header="Total Distance"
                  body={totalDistance}
                />
                <Column
                  field="total_service_time"
                  header="Total Service Time"
                  body={totalServiceTime}
                />
                <Column
                  field="total_travel_time"
                  header="Total Travel Time"
                  body={totalTravelTime}
                />
              </DataTable>
            </TabPanel>

            {tabs?.map(tabItem => (
              <TabPanel
                key={tabItem?.user_id}
                header={
                  <>
                    {tabItem?.installer_name}
                    {isShowStatusIndicator(tabItem?.routing_status) ? (
                      <Badge
                        className="ml-1"
                        severity={
                          tabItem?.routing_status === null
                            ? 'warning'
                            : tabItem?.routing_status === 'INPROGRESS'
                              ? 'info'
                              : 'success'
                        }
                        pt={{
                          root: {
                            style: { minWidth: '0.8rem', height: '0.8rem' },
                          },
                        }}
                      />
                    ) : tabItem?.routing_status == 'APPROVED' ? (
                      <i className="pi pi-check-circle text-teal-500 ml-2 text-sm" />
                    ) : (
                      <i className="pi pi-times-circle text-red-500 ml-2 text-sm" />
                    )}
                  </>
                }
              >
                <InstallerPanel
                  tabItem={tabItem}
                  routeId={selectedItem?.route_id}
                  widgetId={widgetId}
                  dates={dates}
                  refereshTabs={refereshTabs}
                  fetchTabs={fetchTabs}
                  isArchiveTab={isArchiveTab}
                />
              </TabPanel>
            ))}
          </TabView>
        </>
      )}
      <Toast ref={toast} />
    </>
  );
};

export default InstallerTabs;
