// **** Utilities ****
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    bodyWrapper: {
      padding: '30px 24px 24px',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: props => ({
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
      },
      flexGrow: 1,
      paddingLeft: '175px',

      transition: theme.transitions.create(['margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    contentShift: {
      paddingLeft: '175px',
      transition: theme.transitions.create(['width', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // [theme.breakpoints.down('sm')]: {
      //   padding: '0 !important',
      // },
    },
  })
);
