import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  addSlotInDay,
  deleteSlotBySlotId,
  getSlotsByDay,
  updateSlotBySlotId,
} from '../service/CxSchedulingConfigService';
import { isStartTimeLessThanEndTime } from '../../../../utils/Helpers';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import { getWorkHours } from '../service/BusinessHourConfigService';
import PFButton from '../../../shared/PFPrime/PFButton';

const AddEditCxSchedulingSlotDialog = ({
  clientId,
  isVisible,
  setIsVisible,
  slotDetail,
  setCxSchedulingSlots,
}) => {
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const [currentSlots, setCurrentSlot] = useState(slotDetail);
  const [slots, setSlots] = useState([]);
  const toast = useRef(null);
  const validation = Yup.object().shape({
    slot_name: Yup.string().nullable(true),
    start_time: Yup.date().nullable(true),
    end_time: Yup.date().nullable(true),
    workHours: Yup.array().optional(),
  });

  const startAndEndTimeValidation = (startTime, endTime) => {
    // to check start time is not selected
    if (Array.isArray(slots) && slots.length && startTime && endTime) {
      let stLessThanAll = true,
        etGreaterThanAll = true,
        isCompared = false;
      const st = dayjs(startTime).format('HH:mm:00');
      const et = dayjs(endTime).format('HH:mm:00');
      for (const eachSlot of slots) {
        if (currentSlots?.slot_id != eachSlot?.slot_id) {
          isCompared = true;
          if (isStartTimeLessThanEndTime(eachSlot.start_time, st)) {
            stLessThanAll = false;
            break;
          }
          if (isStartTimeLessThanEndTime(et, eachSlot.end_time)) {
            etGreaterThanAll = false;
            break;
          }
        }
      }
      if (isCompared && stLessThanAll && etGreaterThanAll) {
        formik.setErrors({
          exist: 'Slot time already exist',
        });
        return false;
      }
    }
    return true;
  };
  const validateInput = (timeType, value) => {
    if ('slot_name' === timeType) {
      if (!value || (typeof value === 'string' && !value.trim())) {
        formik.setErrors({
          slot_name: 'Required',
        });
        return false;
      } else return true;
    } else {
      if (!value) {
        formik.setErrors({
          [timeType]: 'Required',
        });
        return false;
      } else if (new Date(value).toDateString() === 'Invalid Date') {
        formik.setErrors({
          [timeType]: 'Invalid Date',
        });
        return false;
      } else if ('start_time' === timeType) {
        const formattedStartTime = dayjs(value).format('HH:mm:00');
        if (
          Array.isArray(formik.values.workHours) &&
          formik.values.workHours.length
        ) {
          const time = formik.values.workHours.find(
            eachDay => eachDay.day === currentSlots?.day
          );
          if (time && time.start_time) {
            if (
              isStartTimeLessThanEndTime(formattedStartTime, time.start_time)
            ) {
              formik.setErrors({
                start_time: `Start Time cannot be less than the Start Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
            if (time && time.end_time) {
              if (
                isStartTimeLessThanEndTime(time.end_time, formattedStartTime)
              ) {
                formik.setErrors({
                  start_time: `Start Time cannot be greater than the End Time of ${currentSlots?.day} Work Hour`,
                });
                return false;
              }
            }
          }
        }
        if (formik?.values?.end_time) {
          const et = dayjs(formik?.values?.end_time).format('HH:mm:00');
          if (isStartTimeLessThanEndTime(et, formattedStartTime)) {
            formik.setErrors({
              start_time: 'Start Time must be less than End Time',
            });
            return false;
          }
        }
        if (Array.isArray(slots) && slots.length) {
          for (const eachSlot of slots) {
            // when selected start time comes between existing slot time
            if (
              currentSlots?.slot_id !== eachSlot?.slot_id &&
              (eachSlot.start_time === formattedStartTime ||
                isStartTimeLessThanEndTime(
                  eachSlot.start_time,
                  formattedStartTime
                )) &&
              (formattedStartTime === eachSlot.end_time ||
                isStartTimeLessThanEndTime(
                  formattedStartTime,
                  eachSlot.end_time
                ))
            ) {
              formik.setErrors({
                start_time: `Start Time is coming between existing slot time of ${eachSlot.slot_name}`,
              });
              return false;
            }
          }
        }
      } else if ('end_time' === timeType) {
        const formattedEndTime = dayjs(value).format('HH:mm:00');
        if (
          Array.isArray(formik.values.workHours) &&
          formik.values.workHours.length
        ) {
          const time = formik.values.workHours.find(
            eachDay => eachDay.day === currentSlots?.day
          );
          if (time && time.end_time) {
            if (isStartTimeLessThanEndTime(time.end_time, formattedEndTime)) {
              formik.setErrors({
                end_time: `End Time cannot be greater than the End Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
            if (time && time.start_time) {
              if (
                isStartTimeLessThanEndTime(formattedEndTime, time.start_time)
              ) {
                formik.setErrors({
                  end_time: `End Time cannot be less than the Start Time of ${currentSlots?.day} work hour`,
                });
                return false;
              }
            }
          }
        }
        if (formik?.values?.start_time) {
          const st = dayjs(formik?.values?.start_time).format('HH:mm:00');
          if (isStartTimeLessThanEndTime(formattedEndTime, st)) {
            formik.setErrors({
              end_time: 'End Time must be greater than Start Time',
            });
            return false;
          }
        }
        if (Array.isArray(slots) && slots.length) {
          for (const eachSlot of slots) {
            // when selected end time comes between existing slot time
            if (
              currentSlots?.slot_id !== eachSlot?.slot_id &&
              (eachSlot.start_time === formattedEndTime ||
                isStartTimeLessThanEndTime(
                  eachSlot.start_time,
                  formattedEndTime
                )) &&
              (formattedEndTime === eachSlot.end_time ||
                isStartTimeLessThanEndTime(formattedEndTime, eachSlot.end_time))
            ) {
              formik.setErrors({
                end_time: `End Time is coming between existing slot time of ${eachSlot.slot_name}`,
              });
              return false;
            }
          }
        }
      }
    }
    return true;
  };
  const handleSubmit = async values => {
    try {
      if (
        !validateInput('slot_name', values.slot_name) ||
        !validateInput('start_time', values.start_time) ||
        !validateInput('end_time', values.end_time) ||
        !startAndEndTimeValidation(values.start_time, values.end_time)
      ) {
        return;
      }
      const payload = {
        slot_name: values.slot_name,
        start_time: dayjs(values.start_time).format('HH:mm:00'),
        end_time: dayjs(values.end_time).format('HH:mm:00'),
      };
      setLoading(true);
      const apiResponse = await (currentSlots?.slot_id
        ? updateSlotBySlotId(currentSlots?.slot_id, payload)
        : addSlotInDay(clientId, currentSlots?.day, payload));
      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record added Successfully',
          life: 1500,
        });
        if (!currentSlots?.slot_id) {
          setCxSchedulingSlots(prev => {
            const updatedCxSchedulingSlots = [];
            prev.forEach(eachDay => {
              if (eachDay.day === currentSlots.day) {
                updatedCxSchedulingSlots.push({
                  ...eachDay,
                  slots_count: eachDay.slots_count + 1,
                });
              } else {
                updatedCxSchedulingSlots.push(eachDay);
              }
            });
            return updatedCxSchedulingSlots;
          });
        }
        formik.resetForm();
        setCurrentSlot(slotDetail);
        await callGetSlotsByDay();
      } else {
        toast.current.show({
          severity: 'error',
          summary:
            apiResponse.message || 'Error occurred while updating record',
          life: 3000,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: currentSlots,
    validationSchema: validation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const handleHide = () => {
    setIsVisible(false);
  };
  const handleEdit = data => {
    setCurrentSlot({
      ...data,
      start_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.start_time}`
      ),
      end_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.end_time}`
      ),
    });
  };
  const handleDelete = async data => {
    setLoading(true);
    try {
      const apiResponse = await deleteSlotBySlotId(data?.slot_id);
      if (apiResponse?.status) {
        const remainingSlots = slots.filter(
          each => each.slot_id != data.slot_id
        );
        setSlots(remainingSlots);
        setCxSchedulingSlots(prev => {
          const updatedCxSchedulingSlots = [];
          prev.forEach(eachDay => {
            if (eachDay.day === data.day) {
              updatedCxSchedulingSlots.push({
                ...eachDay,
                slots_count: remainingSlots.length,
              });
            } else {
              updatedCxSchedulingSlots.push(eachDay);
            }
          });
          return updatedCxSchedulingSlots;
        });
        if (currentSlots?.slot_id === data.slot_id) {
          setCurrentSlot(slotDetail);
        }
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record deleted Successfully',
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary:
            apiResponse.message || 'Error occurred while deleting record',
          life: 1500,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const callGetSlotsByDay = async (isInitial = false) => {
    setLoading(true);
    try {
      const apiResponse = await getSlotsByDay(clientId, currentSlots?.day);
      if (
        apiResponse?.status &&
        Array.isArray(apiResponse?.data) &&
        apiResponse.data.length
      ) {
        setSlots(apiResponse?.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      !isInitial && setLoading(false);
    }
  };
  const callGetWorkHours = async (isInitial = false) => {
    setLoading(true);
    try {
      const apiResponse = await getWorkHours(clientId);
      if (apiResponse?.status && Array.isArray(apiResponse.data)) {
        setCurrentSlot(prev => ({ ...prev, workHours: apiResponse.data }));
        const time = apiResponse.data.find(
          eachDay => eachDay?.day === currentSlots?.day
        );
        let startTime, endTime;
        if (time && time?.start_time) {
          startTime = new Date(
            `${dayjs(new Date()).format(`MM-DD-YYYY ${time?.start_time}`)}`
          );
        }
        if (time && time?.end_time) {
          endTime = new Date(
            `${dayjs(new Date()).format(`MM-DD-YYYY ${time?.end_time}`)}`
          );
        }
        if (
          startTime &&
          endTime &&
          currentSlots &&
          (!('start_time' in currentSlots) || !('end_time' in currentSlots))
        ) {
          setCurrentSlot(prev => ({
            ...prev,
            start_time: startTime,
            end_time: endTime,
          }));
        }
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      !isInitial && setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId && currentSlots?.day) {
      Promise.all([callGetSlotsByDay(true), callGetWorkHours(true)])
        .then()
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientId, currentSlots?.day]);

  const actionTemplate = slot => {
    return (
      <div className="flex justify-content-left align-items-center">
        {loading ? (
          <Skeleton className="w-full" height="2rem" />
        ) : (
          <>
            <PFButton
              severity=" "
              icon="pi pi-pencil"
              size="small"
              tooltip="Edit"
              tooltipOptions={{ position: 'top' }}
              rounded={true}
              text={true}
              onClick={() => handleEdit(slot)}
            />
            <PFButton
              severity=" "
              icon="pi pi-trash"
              size="small"
              tooltip="Remove"
              tooltipOptions={{ position: 'top' }}
              className="text-red-700"
              rounded={true}
              text={true}
              onClick={() => handleDelete(slot)}
            />
          </>
        )}
      </div>
    );
  };
  const startTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.start_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.start_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  const slotNameTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : (
      slot?.slot_name
    );
  };
  const endTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.end_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.end_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  return (
    <Dialog
      header={`${currentSlots?.slot_id ? 'Edit' : 'Add'} Slot`}
      visible={isVisible}
      onHide={handleHide}
      style={{
        overflow: 'auto',
      }}
      className="w-10 lg:w-8"
    >
      <Toast ref={toast} />
      <BlockUI
        blocked={loading}
        className="opacity-30 w-full"
        pt={{ mask: { className: 'gray-bg-300' } }}
      >
        <div className="w-full flex">
          <div className="w-full flex flex-column gap-3 flex-wrap mt-2 max-w-35rem">
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                className="w-full"
                id="day"
                name="day"
                value={currentSlots?.day}
                disabled
              />
              <label htmlFor="day">Day</label>
            </div>
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                autoComplete="off"
                id="slot_name"
                name="slot_name"
                value={formik.values?.slot_name || ''}
                onChange={e =>
                  formik.setFieldValue('slot_name', e.target.value)
                }
                // onBlur={() => validateInput('slot_name', formik.values.slot_name)}
                className={`w-full ${formik.errors.slot_name ? 'border-red-300' : ''}`}
                aria-describedby="slot_name_error"
              />
              <label htmlFor="slot_name">
                Slot Name<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.slot_name && (
                <span
                  id="slot_name_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.slot_name}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="start_time"
                name="start_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.start_time}
                onChange={e => formik.setFieldValue('start_time', e.value)}
                // onBlur={() => validateInput('start_time', formik.values.start_time)}
                aria-describedby="start_time_error"
                className="w-full"
                inputClassName={
                  formik.errors.start_time ? 'border-red-300' : ''
                }
              />
              <label htmlFor="start_time">
                Start Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.start_time && (
                <span
                  id="start_time_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.start_time}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="end_time"
                name="end_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.end_time}
                onChange={e => formik.setFieldValue('end_time', e.value)}
                // onBlur={() => validateInput('end_time', formik.values.end_time)}
                aria-describedby="end_time_error"
                className="w-full"
                inputClassName={formik.errors.end_time ? 'border-red-300' : ''}
              />
              <label htmlFor="end_time">
                End Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.end_time && (
                <span id="end_time_error" className="text-red-400 text-xs pl-2">
                  {formik.errors.end_time}
                </span>
              )}
            </div>
            {formik.errors.exist && (
              <span id="exist_error" className="w-full text-red-400 text-xs">
                {formik.errors.exist}
              </span>
            )}
            <div className="w-full flex justify-content-end gap-3">
              {currentSlots?.slot_id && (
                <PFButton
                  label="Cancel"
                  size="small"
                  outlined
                  onClick={() => setCurrentSlot(slotDetail)}
                  disabled={loading}
                />
              )}
              <PFButton
                label="Save"
                size="small"
                onClick={formik.handleSubmit}
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  loading ||
                  !formik.values.slot_name ||
                  !formik.values.start_time ||
                  !formik.values.end_time
                }
              />
            </div>
          </div>
          <Divider layout="vertical" />
          <DataTable
            value={Array.isArray(slots) ? slots : []}
            emptyMessage="No slots found"
            className="w-full"
            size="small"
            scrollHeight="flex"
            scrollable
            tableStyle={{
              minWidth: 500,
            }}
          >
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="action"
              header="Action"
              body={actionTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="slot_name"
              header="Slot Name"
              body={slotNameTemplate}
              style={{ maxWidth: 70, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="start_time"
              header="Start Time"
              body={startTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="end_time"
              header="End Time"
              body={endTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
          </DataTable>
        </div>
      </BlockUI>
    </Dialog>
  );
};

export default AddEditCxSchedulingSlotDialog;
