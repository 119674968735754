import { Typography, Grid } from '@material-ui/core';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import _ from 'lodash';

import { random_rgba } from '../../../../utils/Helpers';
import { storeDashboardAnalysis } from '../service/widget.service';
ChartJS.register(ArcElement, Tooltip, Legend, Colors);

const PieChartComponent = ({
  cubeResponse,
  annotation,
  dashboardDetails,
  dashboardLogDetails,
}) => {
  if (!cubeResponse || !cubeResponse.length) {
    return (
      <Grid container xs={12} item justifyContent="center">
        <img
          src="/images/pie-chart.png"
          style={{ width: '140px', height: '120px' }}
          alt="Pie"
        />
      </Grid>
    );
  }

  const labels = [];
  const values = [];
  if (cubeResponse && cubeResponse.length) {
    const copiedResponse = _.cloneDeep(cubeResponse);
    for (const item of copiedResponse) {
      for (const k of Object.keys(item)) {
        if (
          k?.includes('avg') ||
          k?.includes('sum') ||
          k?.includes('count') ||
          k?.includes('min') ||
          k?.includes('max')
        ) {
          values.push(item[k]);
          delete item[k];
          labels.push(
            Object.keys(item)
              ?.filter(f => f !== undefined)
              ?.map(record => {
                let formatter = null;
                Object.keys(item)?.forEach(i => {
                  if (annotation?.[i]?.meta?.format) {
                    formatter = eval(annotation[i].meta.format);
                  }
                });

                return formatter ? formatter(item[record]) : item[record];
              })
              ?.join(' | ')
          );
        }
      }
    }
  }
  const data = {
    labels: labels,
    responsive: true,

    datasets: [
      {
        label: '',
        data: values,
        borderWidth: 1,
        fill: false,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      colors: {
        enabled: true,
      },
    },
  };
  if (!values?.length || values?.[0] == 0) {
    return (
      <Grid container xs={12} item justifyContent="center">
        <img
          src="/images/pie-chart.png"
          style={{ width: '180px', height: '150px' }}
          alt="Pie"
        />
      </Grid>
    );
  }
  if (dashboardDetails && dashboardLogDetails) {
    storeDashboardAnalysis({
      ...dashboardLogDetails,
      ...dashboardDetails,
      executionTime: new Date().getTime() - dashboardDetails.start_time,
      end_time: new Date().getTime(),
    });
  }
  return <Pie data={data} options={options} className="m-auto pie-chart" />;
};

export default PieChartComponent;
