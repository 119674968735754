import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { LEAD_RESPONSE_REASON } from '../../../constants';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { Alert } from '../../shared/Alerts/Alert';
import ApiService from '../../../services/api.service';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  formikInitialValues,
  formikMapping,
  lrrpConfigFormikValidationSchema,
} from './LrrpConfiguration.constant';
import { useStyles } from './LrrpConfiguration.style';
import LrrpConfigurationForm from './LrrpConfigurationComponents';

const LrrpConfiguration = ({
  onDataChange,
  formik,
  responseData,
  setBtnDisables,
}) => {
  const lrrpBreadcrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: 'LRRP',
    },
  ];
  const { alert, setAlert, clearAlert } = useAlerts();
  const [option, setOption] = useState('');
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const LrrpConfigurationFormik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: async values => {
      setLoading(true);
      await ApiService.post('/system/lrrp-configuration', values);
      setAlert('success', 'Configuration Updated successfully', false, true);
      setLoading(false);
    },
    enableReinitialize: true,
    validationSchema: lrrpConfigFormikValidationSchema,
  });

  useEffect(async () => {
    formikMapping(LrrpConfigurationFormik, formik);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (onDataChange) {
      onDataChange(LrrpConfigurationFormik?.values);
    }
  }, [LrrpConfigurationFormik.values, onDataChange]);

  return (
    <>
      {alert?.exists && (
        <Grid item>
          <Alert />
        </Grid>
      )}
      {loading ? (
        <Grid
          container
          item
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid container xs={12} item justifyContent="center">
            <PFTableLoader />
          </Grid>
        </Grid>
      ) : (
        <Paper className="shadow-none">
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <TextField
                id="provider_number"
                label="Provider Number"
                name="provider_number"
                value={LrrpConfigurationFormik.values.provider_number}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'provider_number',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                label="Firm Name"
                name="name"
                value={LrrpConfigurationFormik.values.firm_name}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'firm_name',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                label="Firm Certification Number"
                name="name"
                value={LrrpConfigurationFormik.values.firm_certification_number}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'firm_certification_number',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                label="Renovator Name"
                name="Renovator Name"
                value={LrrpConfigurationFormik.values.renovator_name}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'renovator_name',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            spacing={2}
            style={{ paddingTop: '10px' }}
          >
            <Grid item xs={12} md={4}>
              <TextField
                id="name"
                label="Renovator Certification Number"
                name="Renovator Certification Number"
                value={
                  LrrpConfigurationFormik.values.renovator_certification_number
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'renovator_certification_number',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label>Fixed Renovator Details: </label>
              <Select
                label="LRRP Activity Auto Complete"
                value={LrrpConfigurationFormik.values.fixed_renovator_details}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'fixed_renovator_details',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>LRRP Activity Auto Complete : </label>
              <Select
                label="LRRP Activity Auto Complete"
                value={
                  LrrpConfigurationFormik.values.lrrp_activity_autocomplete
                }
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'lrrp_activity_autocomplete',
                    event.target.value
                  );
                  setBtnDisables(false);
                }}
              >
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            spacing={2}
            style={{ marginTop: '12px' }}
          >
            <Grid item xs={12} lg={2}>
              <label>Lead Response Reason </label>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Select
                label="Lead Response Reason"
                value={LrrpConfigurationFormik.values.lead_response_reason}
                onChange={event => {
                  LrrpConfigurationFormik.setFieldValue(
                    'lead_response_reason',
                    event.target.value
                  );
                  setBtnDisables(false);
                  setOption(event.target.value);
                }}
              >
                {LEAD_RESPONSE_REASON.map(reason => (
                  <MenuItem value={reason}>{reason}</MenuItem>
                ))}
              </Select>
            </Grid>
            <LrrpConfigurationForm
              option={LrrpConfigurationFormik.values.lead_response_reason}
              LrrpConfigurationFormik={LrrpConfigurationFormik}
              setBtnDisables={setBtnDisables}
            />
            <Grid item xs={3}></Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            style={{ padding: '10px' }}
          ></Grid>
        </Paper>
      )}
    </>
  );
};

export default LrrpConfiguration;
