import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setQuoteStatus as statusAction } from '../redux/slices/quoteStatusMaster.slice';
import { CONFIG } from '../constants';

const useQuoteStatus = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(state => state?.masters?.quoteStatusReducer);
  const [quoteStatus, setQuoteStatus] = useState(status);

  const getQuoteStatus = async () => {
    try {
      if (quoteStatus.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_CRM_URL}/api/master-data/status/quote`
        );
        setQuoteStatus(response?.data);
        dispatch(statusAction(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuoteStatus();
  }, []);

  return { quoteStatus };
};

export default useQuoteStatus;
