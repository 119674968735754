// **** External Utilities ****
import axios from 'axios';
import moment from 'moment';
import parseISO from 'date-fns/parseISO';

import { formatDate } from '../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
//Additional charges API calls
export const getAdditionalCharges = async (
  projectId,
  setAdditionalCharges,
  setLoading,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/payment-request/additional-charge`
    );

    //mapping response
    const additionalChargesData = response?.data?.items?.map(charge => {
      return {
        additionalChargeId: { value: charge.project_additional_charge_id },
        dateCreated: {
          value:
            charge?.pr_date &&
            moment(parseISO(charge?.pr_date?.slice(0, 10))).format(
              'MM-DD-YYYY'
            ),
        },
        dateCreatedValue: {
          value: charge?.pr_date,
        },
        prNumber: { value: charge.pr_number },
        dollarAmount: {
          value: charge.dollar_amount ? `$${charge.dollar_amount}` : '',
        },
        storeAssociate: { value: charge.store_associate },
        prType: { value: charge.pr_type },
        notes: { value: charge.pr_ims_note },
        createdBy: { value: charge.created_by_fullname },
      };
    });
    setAdditionalCharges(additionalChargesData);
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};

export const createAdditionalCharge = async (
  projectId,
  createObj,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/payment-request/additional-charge`,
      {
        pr_type_id: parseInt(createObj.prType),
        pr_date: createObj.dateCreated,
        store_associate: createObj.storeAssociate,
        pr_number: createObj.prNumber,
        pr_ims_note: createObj.notes,
        dollar_amount: createObj.dollarAmount,
      }
    );
    response.data && setReloadList(true);
    setAlert('success', 'Additional Charge Created successfully.');
  } catch (error) {
    setLoading(false);
    setAlert('error', error?.response?.data?.error, false, true);
  }
};

export const updateAdditionalCharge = async (
  projectId,
  updateObj,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/payment-request/additional-charge/${updateObj.additionalChargeId}`,
      {
        pr_type_id: parseInt(updateObj.prType),
        pr_date: updateObj.dateCreated,
        store_associate: updateObj.storeAssociate,
        pr_number: updateObj.prNumber,
        pr_ims_note: updateObj.notes,
        dollar_amount: updateObj.dollarAmount,
      }
    );
    response.data && setReloadList(true);
    setAlert('success', 'Additional charge Updated successfully');
  } catch (error) {
    setLoading(false);
    setAlert('error', 'Error while Editing Additional Charge.', false, true);
  }
};

export const deleteAdditionalChargeById = async (
  projectId,
  additionalChargeId,
  setLoading,
  setReloadList,
  setAlert
) => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/payment-request/additional-charge/${additionalChargeId}`
    );
    response.data && setReloadList(true);
    setAlert('success', 'Additional charge deleted successfully');
  } catch (error) {
    setLoading(false);
    setAlert('error', 'Error while Deleting Additional Charge.', false, true);
  }
};

//Expenses API calls
export const getExpenses = async (
  projectId,
  setExpenses,
  setLoading,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/expenses`
    );

    //mapping response
    const expensesData = response?.data?.items?.map(expense => {
      return {
        projectExpenseId: { value: expense.project_expense_id },
        expenseSource: { value: expense.expense_source },
        expenseSourceName: { value: expense.expense_source.source_name },
        expenseSourceId: { value: expense.expense_source.expense_source_id },
        description: { value: expense.description },
        dollarAmount: { value: `$${expense.dollar_amount}` },
        notes: { value: expense.notes },
        createBy: {
          value: `${
            expense?.expense_created_user?.first_name
              ? expense?.expense_created_user?.first_name
              : ''
          } ${
            expense?.expense_created_user?.last_name
              ? expense?.expense_created_user?.last_name
              : ''
          }`,
        },
      };
    });
    setExpenses(expensesData);
  } catch (error) {
    setAlert(
      'error',
      'Permission for Expenses are not available.',
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};
export const createExpense = async (
  projectId,
  createObj,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/expenses`,
      {
        expense_source_id: parseInt(createObj.expenseSource),
        dollar_amount: Number(createObj.dollarAmount),
        description: createObj.description,
        notes: createObj.notes,
      }
    );
    response.data && setReloadList(true);
    setAlert('success', 'Expense Created successfully.');
  } catch (error) {
    setAlert('error', 'Error while creating expense.', false, true);
  }
};

export const updateExpense = async (
  projectId,
  updateObj,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/expenses/${updateObj.projectExpenseId}`,
      {
        expense_source_id: parseInt(updateObj.expenseSource),
        dollar_amount: updateObj.dollarAmount,
        description: updateObj.description,
        notes: updateObj.notes,
      }
    );
    response.data && setReloadList(true);
    setAlert('success', 'Expense Updated successfully');
  } catch (error) {
    setAlert('error', 'Error while editing expense.', false, true);
  }
};

export const deleteExpenseById = async (
  projectId,
  expenseSourceId,
  setLoading,
  setReloadList,
  setAlert
) => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/expenses/${expenseSourceId}`
    );
    response.data && setReloadList(true);
    setAlert('success', 'Expense deleted successfully');
  } catch (error) {
    setAlert('error', 'Error while deleting expense.', false, true);
  }
};

//get pr-types
export const getPrTypes = async (setPrTypes, setAlert) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/pr-type`
    );
    setPrTypes(response?.data);
  } catch (error) {
    setAlert('error', 'Error while fetching PR Types', false, true);
  }
};

//get Expense source

export const getExpenseSources = async (setExpenseSources, setAlert) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/expense-source`
    );
    setExpenseSources(response?.data);
  } catch (error) {
    setAlert('error', 'Error while fetching Expense Sources', false, true);
  }
};
