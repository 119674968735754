import { useHistory } from 'react-router-dom';

import Login from '../Login/Login';

export const ReturnToLogin = () => {
  const history = useHistory();

  history.push('/');

  return null;
};
