import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { convertDateToLocaleString, momentTz } from '../../../utils/Helpers';
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

import {
  getClientList,
  createBulletin,
  getBulletinList,
  updateBulletin,
  deleteBulletin,
  getTypesList,
} from './Bulletin.service';

const BulletinBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    text: 'Bulletin',
  },
];

const CATEGORY_LIST = [
  {
    key: 'upcoming-feature',
    label: 'Upcoming Feature',
  },
  {
    key: 'known-issue',
    label: 'Known Issue',
  },
  {
    key: 'downtime',
    label: 'Downtime',
  },
  {
    key: 'maintenance',
    label: 'Maintenance',
  },
];
const formikValidation = Yup.object().shape({
  message: Yup.string().trim().required('Required'),
  start_date: Yup.date().required('Start date is required'),
  end_date: Yup.date()
    .required('End date is required')
    .min(
      Yup.ref('start_date'),
      'End date must be later than or equal to the start date'
    ),
  display_on_home_page: Yup.boolean(),
  display_on_top_bar: Yup.boolean(),
  is_important: Yup.boolean(),
  is_all_tenants: Yup.boolean(),
  tenants: Yup.array(),
  category: Yup.string(),
  url: Yup.string(),
});
const FORMIK_INITIAL_VALUES = {
  message: '',
  start_date: null,
  end_date: null,
  display_on_home_page: false,
  display_on_top_bar: false,
  is_important: false,
  is_all_tenants: true,
  tenants: [],
  category: '',
  url: '',
};
const Bulletin = () => {
  const [formAction, setFormAction] = useState('add');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [action, setAction] = useState('');
  const [rowIndex, setRowIndex] = useState();
  const [bulletinInfo, setBulletinInfo] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [bulletinList, setBulletinList] = useState([]);
  const [bulletinCount, setBulletinCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState({
    limit: 10,
    offset: 0,
    sortKey: 'created_at',
    sortDirection: 'DESC',
  });

  const [dialogSettings, setDialogSettings] = useState({
    title: 'Add Bulletin',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });

  const bulletinFormik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    onSubmit: async values => {
      const payload = {
        ...values,
        start_date: values?.start_date
          ? momentTz(`${values?.start_date}:00`)
          : null,
        end_date: values?.end_date ? momentTz(`${values?.end_date}:00`) : null,
      };
      setFormLoading(true);
      if (bulletinInfo) {
        await updateBulletin(bulletinInfo.bulletin_id, payload);
      } else {
        await createBulletin(payload);
      }
      await fetchBulletinData();
      setIsOpen(false);
      setFormLoading(false);
      setBulletinInfo(null);
    },
    validationSchema: formikValidation,
    enableReinitialize: true,
  });

  const fetchBulletinData = async () => {
    let newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    const bulletinListResponse = await getBulletinList(newQueryString);
    setBulletinCount(bulletinListResponse?.count || 0);
    setBulletinList(
      bulletinListResponse?.list?.map(record => ({
        message: record.message,
        start_date: record.start_date,
        end_date: record.end_date,
        start_date_formated: momentTz(record.start_date).format(
          'MM-DD-YYYY hh:mm A'
        ),
        end_date_formated: momentTz(record.end_date).format(
          'MM-DD-YYYY hh:mm A'
        ),
        display_on_home_page: record.display_on_home_page,
        display_on_top_bar: record.display_on_top_bar,
        is_all_tenants: record.is_all_tenants == '1' ? true : false,
        tenants: record.tenants,
        is_important: record.is_important,
        category: record.category,
        bulletin_id: record.bulletin_id,
        url: record.url,
      }))
    );
  };

  useEffect(async () => {
    setLoading(true);
    await fetchBulletinData();
    setLoading(false);
  }, [searchQuery]);

  useEffect(async () => {
    const [response, typesResponse] = await Promise.all([
      getClientList(),
      getTypesList('bulletin-category'),
    ]);
    setClientList(response ? response : []);
    setTypeList(
      typesResponse?.map(item => ({ key: item.type_id, label: item.label })) ||
        []
    );
  }, []);

  const viewEditBulletin = (action, index) => {
    setFormAction(action);
    setAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Bulletin Info') || 'Edit Bulletin Info',
    }));
    setRowIndex(index);
    setIsOpen(true);
    setBulletinInfo(bulletinList[index]);
    bulletinFormik.setValues({
      ...bulletinList[index],
      start_date: convertDateToLocaleString(bulletinList[index]?.start_date),
      end_date: convertDateToLocaleString(bulletinList[index]?.end_date),
    });
  };

  const confirmDeleteBulletin = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete bulletin?',
      header: 'Delete Bulletin',
    });
  };

  const onConfirmDialog = async () => {
    setLoading(true);
    const bulletin_id = bulletinList[rowIndex]?.bulletin_id;
    if (bulletin_id) {
      await deleteBulletin(bulletin_id);
    }
    setLoading(false);
    fetchBulletinData();
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
      title: '',
      header: '',
    });
  };

  const getBulletinViewEditForm = () => {
    return (
      <>
        {formLoading && (
          <Grid item xs={6} container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              id="message"
              label="Message"
              name="message"
              onChange={bulletinFormik.handleChange}
              onBlur={bulletinFormik.message}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disabled={formAction === 'view'}
              required={formAction !== 'view'}
              value={bulletinFormik?.values?.message}
              InputLabelProps={{ shrink: true }}
              error={
                bulletinFormik.touched.message && bulletinFormik.errors.message
              }
              helperText={
                bulletinFormik.touched.message && bulletinFormik.errors.message
              }
            />
          </Grid>
          <Grid item>
            <TextField
              id="url"
              label="URL"
              name="url"
              onChange={bulletinFormik.handleChange}
              onBlur={bulletinFormik.url}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disabled={formAction === 'view'}
              value={bulletinFormik?.values?.url}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="start_date"
              label="Start Date & Time"
              type="datetime-local"
              autoOk
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 900, // 15 min
              }}
              value={bulletinFormik?.values?.start_date}
              onChange={(date, value) => {
                bulletinFormik.setFieldTouched('start_date', true);
                bulletinFormik.setFieldValue(
                  'start_date',
                  `${date.target.value}`
                );
              }}
              disabled={formAction === 'view' ? true : false}
              onBlur={bulletinFormik.handleBlur}
              error={
                bulletinFormik.touched.start_date &&
                bulletinFormik.errors.start_date
              }
              helperText={
                bulletinFormik.touched.start_date &&
                bulletinFormik.errors.start_date
              }
            />
          </Grid>
          <Grid item>
            <TextField
              id="end_date"
              label="End Date & Time"
              type="datetime-local"
              autoOk
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 900, // 15 min
              }}
              value={bulletinFormik?.values?.end_date}
              onChange={(date, value) => {
                bulletinFormik.setFieldTouched('end_date', true);
                bulletinFormik.setFieldValue(
                  'end_date',
                  `${date.target.value}`
                );
              }}
              disabled={formAction === 'view' ? true : false}
              onBlur={bulletinFormik.handleBlur}
              error={
                bulletinFormik.touched.end_date &&
                bulletinFormik.errors.end_date
              }
              helperText={
                bulletinFormik.touched.end_date &&
                bulletinFormik.errors.end_date
              }
            />
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <FormControlLabel
                name="is_custom"
                control={
                  <Checkbox
                    color="primary"
                    value={bulletinFormik?.values?.display_on_home_page || null}
                    onChange={(event, value) => {
                      bulletinFormik.setFieldValue(
                        'display_on_home_page',
                        value
                      );
                    }}
                    checked={
                      bulletinFormik?.values?.display_on_home_page == 1
                        ? true
                        : false
                    }
                    disabled={formAction === 'view'}
                  />
                }
                label="Display on Home Page"
                labelPlacement="right"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <FormControlLabel
                name="is_custom"
                control={
                  <Checkbox
                    color="primary"
                    value={bulletinFormik?.values?.display_on_top_bar || null}
                    onChange={(event, value) => {
                      bulletinFormik.setFieldValue('display_on_top_bar', value);
                    }}
                    checked={
                      bulletinFormik?.values?.display_on_top_bar == 1
                        ? true
                        : false
                    }
                    disabled={formAction === 'view'}
                  />
                }
                label="Display on Top Bar"
                labelPlacement="right"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <FormControlLabel
                name="is_custom"
                control={
                  <Checkbox
                    color="primary"
                    value={bulletinFormik?.values?.is_all_tenants || null}
                    onChange={(event, value) => {
                      bulletinFormik.setFieldValue('is_all_tenants', value);
                      bulletinFormik?.setFieldValue('tenants', []);
                    }}
                    checked={
                      bulletinFormik?.values?.is_all_tenants == 1 ? true : false
                    }
                    disabled={formAction === 'view'}
                  />
                }
                label="For all tenants"
                labelPlacement="right"
              />
            </Grid>
          </Grid>
          {!bulletinFormik?.values?.is_all_tenants && (
            <Grid item>
              <label>Tenants : </label>
              <Autocomplete
                fullWidth
                multiple
                options={clientList}
                disabled={formAction === 'view'}
                value={bulletinFormik?.values?.tenants}
                getOptionLabel={option => option && option.client_name}
                renderInput={params => (
                  <TextField {...params} label="Select Tenants" />
                )}
                onChange={(event, value) => {
                  bulletinFormik?.setFieldValue('tenants', value);
                }}
              />
            </Grid>
          )}

          <Grid item>
            <FormControlLabel
              name="is_custom"
              control={
                <Checkbox
                  color="primary"
                  value={bulletinFormik?.values?.is_important}
                  onChange={(event, value) => {
                    bulletinFormik.setFieldValue('is_important', value);
                  }}
                  checked={
                    bulletinFormik?.values?.is_important == 1 ? true : false
                  }
                  disabled={formAction === 'view'}
                />
              }
              label="Important"
              labelPlacement="right"
            />
          </Grid>
          <Grid item>
            <label>Category : </label>
            <Select
              label="Select Category"
              value={bulletinFormik.values.category}
              onChange={event =>
                bulletinFormik.setFieldValue('category', event.target.value)
              }
              disabled={formAction === 'view'}
            >
              {typeList.map(item => (
                <MenuItem value={item.key}>{item.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </>
    );
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchString: searchString,
      }));
    }, 500);
  };

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '160px', maxWidth: '160px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span>
              <span>
                <Tooltip title="View">
                  <IconButton
                    // classes={{ root: classes.actionIcons }}
                    onClick={index => viewEditBulletin('view', dataIndex)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={index => viewEditBulletin('edit', dataIndex)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={index => confirmDeleteBulletin(dataIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </span>
          );
        },
      },
    },
    {
      name: 'message',
      label: 'Message',
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          typeList.find(record => record.key == value)?.label,
      },
    },
    {
      name: 'start_date_formated',
      label: 'Start Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => value,
      },
    },
    {
      name: 'end_date_formated',
      label: 'End Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => value,
      },
    },
    {
      name: 'is_important',
      label: 'Is Important',
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? 'Yes' : 'No',
      },
    },
  ];
  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: bulletinCount || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !bulletinList?.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      handleSearch('');
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
  };
  return (
    <>
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <PageHeader
            pageTitle={'Bulletin'}
            breadCrumbArray={BulletinBreadcrumb}
          />
        </Grid>
        <Grid item>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setBulletinInfo(null);
                setFormAction('add');
                bulletinFormik.resetForm();
                setDialogSettings(prevState => ({
                  ...prevState,
                  showButton2: true,
                  title: 'Add Bulletin',
                }));
                setIsOpen(true);
              }}
            >
              Add Bulletin
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        {loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        <Grid item>
          <Grid item>
            <GenericDataTable
              title="Bulletin List"
              columns={dataTableColumns}
              data={bulletinList}
              options={dataTableOptions}
            />
          </Grid>
        </Grid>
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
            bulletinFormik?.handleReset();
          }}
          handleSave={bulletinFormik.handleSubmit}
          dialogSettings={dialogSettings}
          disabledButton2={!bulletinFormik?.dirty || !bulletinFormik?.isValid}
        >
          <form>{getBulletinViewEditForm()}</form>
        </GenericDialog>

        <GenericConfirmationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          onConfirmDialog={onConfirmDialog}
        />
      </Grid>
    </>
  );
};
export default Bulletin;
