import seedrandom from 'seedrandom';

export const eventColorGenerator = seed => {
  // Initialize the seedrandom generator with the provided seed
  const rng = new seedrandom(seed.toString());

  // Generate random values for red, green, and blue components with bias towards higher values
  const r = Math.floor(rng() * 128) + 128; // Bias towards higher values
  const g = Math.floor(rng() * 128) + 128; // Bias towards higher values
  const b = Math.floor(rng() * 128) + 128; // Bias towards higher values

  // Adjust overall brightness (you can experiment with this value)
  const brightness = 0.75;
  const adjustedR = Math.floor(r * brightness);
  const adjustedG = Math.floor(g * brightness);
  const adjustedB = Math.floor(b * brightness);

  // Convert the RGB values to a hex color code
  const hexColor = `#${adjustedR.toString(16).padStart(2, '0')}${adjustedG
    .toString(16)
    .padStart(2, '0')}${adjustedB.toString(16).padStart(2, '0')}`;

  return hexColor;
};
