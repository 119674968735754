import { Dialog } from 'primereact/dialog';
import React from 'react';

const DescriptionDialog = ({
  overlayContent,
  dialogHeader,
  dialogVisible,
  setDialogVisible,
}) => {
  return (
    <Dialog
      header={dialogHeader}
      visible={dialogVisible}
      style={{ width: '50vw', height: '60vh' }}
      onHide={() => setDialogVisible(false)}
      draggable={false}
    >
      <div dangerouslySetInnerHTML={{ __html: overlayContent || '' }} />
    </Dialog>
  );
};

export default DescriptionDialog;
