import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const BlockLoader = () => (
  <div className="fixed surface-900 w-full h-full top-0 left-0 bottom-0 right-0 z-index-999 opacity-70 flex justify-content-center align-items-center">
    <ProgressSpinner />
  </div>
);

export default BlockLoader;
