import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { setUserContactForm } from '../../../redux/slices/user-form-contact.slice';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { hasSpecialCharacters, containsNumbers } from '../../../utils/Helpers';
import TableLoader from '../../shared/Loader/TableLoader';

import {
  editEmergencyContact,
  getUserContactData,
} from './services/user-form-contact.service';
import { domHandlerCall } from './common';

const emptyContactRow = {
  name: '',
  phone: '',
  relationship: '',
};

const UserFormContact = () => {
  const { isLoading, relationsList, contactData } = useSelector(
    state => state.userFormContact
  );
  const { isFormDataChanged, mode, userId } = useSelector(
    state => state.userForm
  );
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const fetchInitiationData = async () => {
    dispatch(setUserContactForm({ isLoading: true }));
    try {
      const initializationData = !!userId && (await getUserContactData(userId));
      const { relationsList, contactInfo } = initializationData;
      const mappedContactData = contactInfo.map(row => {
        return {
          name: row?.name,
          phone: row?.phone,
          relationship: relationsList.find(
            item => item?.relationship_type_id == row?.relationship
          ),
        };
      });
      dispatch(
        setUserContactForm({
          relationsList,
          contactData: mappedContactData,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setUserContactForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const handleDelete = async rowIndex => {
    let editedContact = structuredClone(contactData);
    editedContact.splice(rowIndex, 1);
    dispatch(
      setUserContactForm({ contactData: editedContact, isLoading: true })
    );
    let editContactResponse;
    try {
      const payload = mapTableDataForPayload(editedContact);
      editContactResponse = await editEmergencyContact(userId, payload);
      if (editContactResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted Row',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserContactForm({ isLoading: false }));
    }
  };

  const handleDeleteCancel = rowIndex => {
    const editedContact = structuredClone(contactData);
    editedContact.splice(rowIndex, 1);
    const rowData = contactData[rowIndex];
    const isRowEmpty =
      !rowData?.name && !rowData?.phone && !rowData?.relationship;

    if (isRowEmpty) {
      dispatch(
        setUserContactForm({ contactData: editedContact, isLoading: false })
      );
      disableEditButton(false);
    } else {
      dispatch(setUserContactForm({ isDeleteDialogOpen: false }));
      disableEditButton(false);
    }
  };

  const handleOnRowEditCancel = async e => {
    dispatch(
      setUserContactForm({
        isDeleteDialogOpen: true,
      })
    );
    confirmDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(e?.index),
      reject: () => handleDeleteCancel(e?.index),
      onHide: () => handleDeleteCancel(e?.index),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };

  const RelationshipTemplate = rowData => {
    return <span>{rowData?.relationship?.relation_type}</span>;
  };

  const nameEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const phoneEditor = options => {
    return (
      <InputMask
        mask="(999) 999-9999"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const relationshipEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="relation_type"
        options={relationsList}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Relation"
      />
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserContactForm({
        contactData: [emptyContactRow, ...contactData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };

  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  const mapTableDataForPayload = tableData => {
    const mappedPayload = tableData
      .map(row => {
        if (!row?.name || !row?.phone || !row?.relationship) {
          return null;
        } else {
          return {
            name: row.name,
            phone: row.phone,
            relationship: row.relationship?.relationship_type_id,
          };
        }
      })
      .filter(i => i !== null);
    return mappedPayload;
  };

  const onRowEditComplete = async e => {
    let editedContact = structuredClone(contactData);
    let { newData, index } = e;
    editedContact[index] = newData;
    dispatch(
      setUserContactForm({ contactData: editedContact, isLoading: true })
    );
    const payload = mapTableDataForPayload(editedContact);
    let editContactResponse;
    try {
      editContactResponse = await editEmergencyContact(userId, payload);
      if (editContactResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Updated Contacts',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserContactForm({ isLoading: false }));
    }
    disableEditButton(false);
    setIsDisableAddButton(false);
  };

  const handleRowValidation = (e, f) => {
    const validationError = [];
    if (!e?.name || hasSpecialCharacters(e?.name) || containsNumbers(e?.name)) {
      validationError.push('Name');
    }
    if (!e?.phone) {
      validationError.push('Phone');
    }
    if (!e.relationship?.relation_type) {
      validationError.push('Relationship');
    }
    const isValid = !validationError.length > 0;
    if (!isValid) {
      toast.current.show({
        severity: 'error',
        summary: `${validationError.join(',')} invalid`,
        life: 2000,
      });
    }
    return isValid;
  };

  const PhoneTemplate = rowData => {
    return <span>{rowData?.phone}</span>;
  };

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonLoader columnCount={20} columnWidth="24%" mTopBottom={4} />
        </>
      ) : (
        <div className="my-3">
          <div className="flex flex-row justify-content-between align-items-center pb-3">
            <h4>Emergency Contact Info</h4>
            <Button
              label="Add Contact"
              size="small"
              severity="primary"
              onClick={handleAddNewRow}
              disabled={isDisableAddButton}
            />
          </div>
          <DataTable
            value={contactData}
            editMode="row"
            rowEditValidator={handleRowValidation}
            onRowEditComplete={onRowEditComplete}
            onRowEditCancel={handleOnRowEditCancel}
            onRowEditInit={prop => {
              disableEditButton(true);
            }}
          >
            <Column
              field="name"
              header="Name"
              editor={options => nameEditor(options)}
            />
            <Column
              field="phone"
              header="Phone"
              editor={options => phoneEditor(options)}
              body={PhoneTemplate}
            />
            <Column
              field="relationship"
              header="Relationship"
              body={RelationshipTemplate}
              editor={options => relationshipEditor(options)}
            />
            <Column
              rowEditor
              headerStyle={{ width: '10%', minWidth: '7rem' }}
              bodyStyle={{ textAlign: 'center' }}
            ></Column>
          </DataTable>
        </div>
      )}
      <Toast ref={toast} />
    </>
  );
};

export default UserFormContact;
