import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';

import PFInputText from '../../PFPrime/PFInputText';
import PFCalendar from '../../PFPrime/PFCalendar';
import PFDropdown from '../../PFPrime/PFDropdown';
import PFInputNumber from '../../PFPrime/PFInputNumber';
import PFFormikError from '../../PFPrime/PFFormikError';

import { depositTypeModes, paymentTerms } from './QuoteAmount.model';

export default function QuoteAmount({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  clientDetails,
  quoteDetails,
  isView,
  isEdit,
}) {
  const amount = values?.netTotal ? parseFloat(values?.netTotal) : null;
  const [depositPercent, setDepositPercent] = useState(values?.depositPercent);
  const [payableAmount, setPayableAmount] = useState(values?.payableAmount);
  useEffect(() => {
    if (amount) {
      const convertDepositPercent = +parseFloat(depositPercent || 0).toFixed(2);
      setFieldValue('depositPercent', convertDepositPercent);
      const percentValue = +(
        Math.round(amount * convertDepositPercent) / 100
      ).toFixed(2);
      percentValue && setPayableAmount(percentValue);
    }
  }, [depositPercent, amount]);
  useEffect(() => {
    if (amount) {
      const convertPayableAmount = +parseFloat(payableAmount || 0).toFixed(2);
      setFieldValue('payableAmount', payableAmount);
      setFieldValue(
        'amountToBePaidAfterService',
        amount - convertPayableAmount
      );
    }
  }, [payableAmount, amount]);
  return (
    <Card className="mt-3 w-full border-200 border-solid border-1">
      <div htmlFor="Quote Amount">
        <div className="w-12 text-1xl font-bold p-1">Amount</div>
        <div className="grid flex flex-row flex-wrap w-full m-0 mt-2">
          <div className="formgroup-inline w-full">
            <div className="quote-totalPrice align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputNumber
                  inputId="totalPrice"
                  name="totalPrice"
                  value={values?.netTotal || ''}
                  minFractionDigits={2}
                  mode="currency"
                  currency="USD"
                  disabled={true}
                />
                <label htmlFor="totalPrice">Total Price</label>
              </span>
              <div style={{ color: 'red' }} className="px-1 errorStyle">
                {errors?.totalPrice || null}
              </div>
            </div>
            <div className="quote-depositTypeModes align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFDropdown
                  value={values?.depositType || 'Amount'}
                  onChange={(name, value) => {
                    if (value === 'Percentage') {
                      setDepositPercent('');
                      setPayableAmount('');
                      setFieldValue('amountToBePaidAfterService', '');
                    }
                    setFieldValue(name, value);
                  }}
                  options={depositTypeModes}
                  optionLabel="name"
                  placeholder="Select Deposit Type"
                  optionValue="name"
                  name="depositType"
                  disabled={!values?.netTotal || isView}
                />
              </span>
              <PFFormikError
                touched={touched}
                errors={errors}
                field={'depositType'}
              />
            </div>
            {values?.depositType === 'Percentage' && (
              <div className="quote-depositPercent align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFInputNumber
                    inputId="depositPercent"
                    name="depositPercent"
                    value={depositPercent || ''}
                    onChange={(e, v) => setDepositPercent(v)}
                    minFractionDigits={2}
                    min={0}
                    max={100}
                    disabled={!values?.netTotal || isView}
                    suffix="%"
                  />
                  <label htmlFor="depositPercent">Deposit Percentage</label>
                </span>
                <div style={{ color: 'red' }} className="px-1 errorStyle">
                  {errors?.depositPercent || null}
                </div>
              </div>
            )}
            <div className="quote-payableAmount align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputNumber
                  inputId="payableAmount"
                  name="payableAmount"
                  value={payableAmount}
                  onChange={(name, value) =>
                    !values?.netTotal || values?.depositType === 'Percentage'
                      ? null
                      : setPayableAmount(value)
                  }
                  minFractionDigits={2}
                  max={values?.netTotal || 0}
                  min={0}
                  mode="currency"
                  currency="USD"
                  disabled={
                    !values?.netTotal ||
                    values?.depositType === 'Percentage' ||
                    isView
                  }
                />
                <label htmlFor="payableAmount">Payable Amount</label>
              </span>
              <div style={{ color: 'red' }} className="px-1 errorStyle">
                {errors?.payableAmount || null}
              </div>
            </div>
            <div className="grid flex flex-row flex-wrap w-full m-0 py-1">
              <div className="quote-amountToBePaidAfterService align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFInputNumber
                    inputId="amountToBePaidAfterService"
                    name="amountToBePaidAfterService"
                    value={values?.amountToBePaidAfterService || ''}
                    minFractionDigits={2}
                    mode="currency"
                    currency="USD"
                    disabled={true}
                  />
                  <label htmlFor="amountToBePaidAfterService">
                    Amount to be paid after service
                  </label>
                </span>
                <div style={{ color: 'red' }} className="px-1 errorStyle">
                  {errors?.amountToBePaidAfterService || null}
                </div>
              </div>
              <div className="quote-recurrence align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFDropdown
                    value={values?.paymentTerms || ''}
                    onChange={setFieldValue}
                    options={paymentTerms}
                    optionLabel="name"
                    placeholder="Payment Terms"
                    optionValue="name"
                    name="paymentTerms"
                    disabled={!values?.netTotal || isView}
                  />
                  <label htmlFor="paymentTerms">
                    Payment Terms
                    <span
                      style={!isView ? { color: 'red' } : { color: 'none' }}
                    >
                      *
                    </span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field={'paymentTerms'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
