import * as Yup from 'yup';

export const leadSourceInit = {
  leadBy: '',
  commisionType: '',
  commisionPercent: '',
  amount: '',
};

export const leadSourceValidation = {
  leadBy: Yup.string(),
  commisionType: Yup.string(),
  commisionPercent: Yup.mixed(),
  amount: Yup.mixed(),
};
