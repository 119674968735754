import React from 'react';
import { Dropdown } from 'primereact/dropdown';

import { PrimeReactIcons } from '../../../constants/primereactConstants';

export default function IconsDropdownComponent({
  id,
  name,
  value,
  onChange,
  isRequired,
  label,
  ...rest
}) {
  const selectedIconTemplate = (option, props) => {
    if (option) {
      return <IconOptionTemplate {...option} />;
    }

    return <span>{props.placeholder}</span>;
  };

  const IconOptionTemplate = option => {
    return (
      <div className="flex align-items-center gap-3">
        <i
          className={`text-lg text-color-secondary text-color-secondary ${option?.name}`}
        ></i>
        <span>{option?.label}</span>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          'No country selected.'
        )}
      </div>
    );
  };
  if (value === undefined || typeof onChange !== 'function')
    return <span className="text-red-400">value and onChange is required</span>;
  return (
    <div className="p-float-label flex justify-content-center align-items-center">
      <Dropdown
        id={id || 'icon-dropdown'}
        name={name || 'icon-dropdown'}
        className="w-full height-40"
        value={value}
        optionValue="name"
        optionLabel="label"
        onChange={onChange}
        options={PrimeReactIcons}
        valueTemplate={selectedIconTemplate}
        itemTemplate={IconOptionTemplate}
        // placeholder="Select an icon"
        // optionLabel="name"
        // panelFooterTemplate={panelFooterTemplate}
        // dropdownIcon={(opts) => {
        //   return opts.iconProps['data-pr-overlay-visible'] ? (
        //     <ChevronRightIcon {...opts.iconProps} />
        //   ) : (
        //     <ChevronDownIcon {...opts.iconProps} />
        //   );
        // }}
        // filter
        {...(rest?.other || {})}
      />
      <label htmlFor="icon-dropdown">
        {label || 'Select an icon'}{' '}
        {isRequired ? <span style={{ color: 'red' }}>*</span> : <></>}
      </label>
    </div>
  );
}
