import React from 'react';

import apiSerive from '../../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../../constants/urlConstants';
import { SchedulerCustomFilters } from '../../../../../constants';

//Get All OPtions
const getStoreOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(URL_CONSTANTS.SCHEDULER_FILTERS.getStoreList, value)
    );

    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
const getCategoryOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getCategoryList,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getProjectTypeOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getProjectTypeList,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getProjectStatusOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getProjectStatusList,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getSourceStatusOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getSourceStatusList,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getDistrictOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getDistrictList,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
const getWorkroomOptions = async value => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getWorkroomList,
        value
      )
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

//On Filter Search
export const suggestStore = async (event = null, setStoreOptions) => {
  const searchResponse = await getStoreOptions(
    event?.filter ? event?.filter : event || ''
  );
  setStoreOptions(prevOptions => {
    const allStore = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allStore?.filter(options => {
      const typeId = options?.store_number;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestCategory = async (event = null, setCategoryOptions) => {
  const searchResponse = await getCategoryOptions(
    event?.filter ? event?.filter : event || ''
  );
  setCategoryOptions(prevOptions => {
    const allCategory = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allCategory?.filter(options => {
      const typeId = options?.project_category_id;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestProjectType = async (
  event = null,
  setProjectTypeOptions
) => {
  const searchResponse = await getProjectTypeOptions(
    event?.filter ? event?.filter : event || ''
  );
  setProjectTypeOptions(prevOptions => {
    const allProjectType = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allProjectType.filter(options => {
      const typeId = options?.project_type_id;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestProjectStatus = async (
  event = null,
  setProjectStatusOptions
) => {
  const searchResponse = await getProjectStatusOptions(
    event?.filter ? event?.filter : event || ''
  );
  setProjectStatusOptions(prevOptions => {
    const allProjectStatus = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allProjectStatus?.filter(options => {
      const typeId = options?.status_id;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestSourceStatus = async (
  event = null,
  setSourceStatusOptions
) => {
  const searchResponse = await getSourceStatusOptions(
    event?.filter ? event?.filter : event || ''
  );
  setSourceStatusOptions(prevOptions => {
    const allSourceStatus = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allSourceStatus?.filter(options => {
      const typeId = options?.status_id;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestDistrict = async (event = null, setDistrictOptions) => {
  const searchResponse = await getDistrictOptions(
    event?.filter ? event?.filter : event || ''
  );
  setDistrictOptions(prevOptions => {
    const allDistrict = [
      ...prevOptions,
      ...(Array.isArray(searchResponse) ? searchResponse : []),
    ];
    const uniqueIds = {};
    return allDistrict?.filter(options => {
      const typeId = options?.district;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestWorkRoom = async (event = null, setWorkroomOptions) => {
  const searchUserTypeResponse = await getWorkroomOptions(
    event?.filter ? event?.filter : event || ''
  );
  setWorkroomOptions(prevOptions => {
    const allWorkRoom = [
      ...prevOptions,
      ...(Array.isArray(searchUserTypeResponse) ? searchUserTypeResponse : []),
    ];
    const uniqueIds = {};
    return allWorkRoom?.filter(options => {
      const typeId = options?.type_id;
      if (uniqueIds[typeId]) {
        return false;
      }
      uniqueIds[typeId] = true;
      return true;
    });
  });
};
export const suggestCustomerOptions = async (
  searchValue,
  setCustomerOptions
) => {
  try {
    const response = await apiSerive.get(
      apiSerive.formatUrl(
        URL_CONSTANTS.SCHEDULER_FILTERS.getCustomerList,
        searchValue
      )
    );
    if (response) {
      setCustomerOptions(prevOptions => {
        const allCustomers = [
          ...prevOptions,
          ...(Array.isArray(response) ? response : []),
        ];
        const uniqueIds = {};
        return allCustomers?.filter(options => {
          const customerId = options?.customer_id;
          if (uniqueIds[customerId]) {
            return false;
          }
          uniqueIds[customerId] = true;
          return true;
        });
      });
    }
  } catch (error) {
    console.error(error);
  }
};

//Save Filtes
export const saveFilter = async (
  value,
  setReloadList,
  toast,
  setVisible,
  filterType,
  userId,
  setLoading
) => {
  try {
    setLoading(true);
    let response;
    if (filterType == SchedulerCustomFilters.ADMIN) {
      response = await apiSerive.post(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.saveAdminLevelFilter
        ),
        value
      );
    } else if (filterType == SchedulerCustomFilters.USER) {
      response = await apiSerive.post(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.saveUserLevelFilter,
          userId
        ),
        value
      );
    }
    if (response && response?.status) {
      toast.current?.show({
        severity: 'success',
        summary: `Filter added successfuly`,
      });
      setLoading(false);
      setVisible(false);
      setReloadList(true);
    } else {
      setLoading(false);
      setReloadList(false);
      toast.current?.show({
        severity: 'error',
        summary: response?.data ? `${response?.data}` : 'Unable to add record.',
      });
    }
  } catch (error) {
    setLoading(false);
    setReloadList(false);
    toast.current?.show({
      severity: 'error',
      summary: 'Unable to add record.',
    });
    console.error(error);
  } finally {
    setLoading(false);
    setReloadList(false);
  }
};
export const updateFilter = async (
  id,
  value,
  setReloadList,
  toast,
  setVisible,
  filterType,
  userId,
  setLoading
) => {
  try {
    setLoading(true);
    let response;
    if (filterType == SchedulerCustomFilters.ADMIN) {
      response = await apiSerive.put(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.editAdminLevelFilter,
          id
        ),
        value
      );
    } else if (filterType == SchedulerCustomFilters.USER) {
      response = await apiSerive.put(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.editUserLevelFilter,
          id,
          userId
        ),
        value
      );
    }
    if (response && response?.status) {
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: `Filter updated successfuly`,
      });
      setVisible(false);
      setReloadList(true);
    } else {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: response?.data
          ? `${response?.data}`
          : 'Unable to udpate record.',
      });
      setReloadList(false);
    }
  } catch (error) {
    setLoading(false);
    setReloadList(false);
    toast.current?.show({
      severity: 'error',
      summary: 'Unable to update record.',
    });
    console.error(error);
  } finally {
    setLoading(false);
    setReloadList(false);
  }
};

//Helpers
export const selectedLabel = (values, key) => {
  const count = values?.length;
  return `${count} ${key}${count > 1 ? 's' : ''} selected`;
};

export const createPayloadFromFormikValues = values => {
  const payload = {
    filter_name: values?.filter_name,
    filters: {},
    is_active: values?.isActive ? 1 : 0,
  };

  if (values.store?.length > 0) {
    payload.filters.store = values.store.join(',');
  }
  if (values.projectCategory?.length > 0) {
    payload.filters.projectCategory = values.projectCategory.join(',');
  }
  if (values.projectType?.length > 0) {
    payload.filters.projectType = values.projectType.join(',');
  }
  if (values.status?.length > 0) {
    payload.filters.status = values.status.join(',');
  }
  if (values.sourceStatus?.length > 0) {
    payload.filters.sourceStatus = values.sourceStatus.join(',');
  }
  if (values.district?.length > 0) {
    payload.filters.district = values.district.map(item => `${item}`).join(',');
  }
  if (values.workroom?.length > 0) {
    payload.filters.workroom = values.workroom.join(',');
  }
  if (values.customer?.length > 0) {
    payload.filters.customer = values.customer.join(',');
  }

  return payload;
};

export const isAtLeastOneFieldSelected = formik => {
  const {
    store,
    projectCategory,
    projectType,
    status,
    sourceStatus,
    district,
    workroom,
  } = formik.values;
  return (
    (store?.length ?? 0) > 0 ||
    (projectCategory?.length ?? 0) > 0 ||
    (projectType?.length ?? 0) > 0 ||
    (status?.length ?? 0) > 0 ||
    (sourceStatus?.length ?? 0) > 0 ||
    (district?.length ?? 0) > 0 ||
    (workroom?.length ?? 0) > 0
  );
};

export const processFilterData = (selectedRowData, options, setters) => {
  let filterValues = {};

  if (selectedRowData?.filters && selectedRowData?.filters.length > 0) {
    selectedRowData.filters.forEach(item => {
      const filterType = item?.filterType?.type_name;
      const filterValueArray = item?.filter_value?.split(',');

      switch (filterType) {
        case 'store':
          filterValues.store = filterValueArray.map(value => parseInt(value));
          setters.setStoreOptions(prevOptions => [
            ...prevOptions,
            ...options.storeOptions,
            ...selectedRowData?.selectedFilter?.storeList,
          ]);
          break;

        case 'projectCategory':
          filterValues.projectCategory = filterValueArray.map(value =>
            parseInt(value)
          );
          setters.setCategoryOptions(prevOptions => [
            ...prevOptions,
            ...options.categoryOptions,
            ...selectedRowData?.selectedFilter?.projectCategoryList,
          ]);
          break;

        case 'projectType':
          filterValues.projectType = filterValueArray.map(value =>
            parseInt(value)
          );
          setters.setProjectTypeOptions(prevOptions => [
            ...prevOptions,
            ...options.projectTypeOptions,
            ...selectedRowData?.selectedFilter?.projectTypeList,
          ]);
          break;

        case 'status':
          filterValues.status = filterValueArray.map(value => parseInt(value));
          setters.setProjectStatusOptions(prevOptions => [
            ...prevOptions,
            ...options.projectStatusOptions,
            ...selectedRowData?.selectedFilter?.statusList,
          ]);
          break;

        case 'sourceStatus':
          filterValues.sourceStatus = filterValueArray.map(value =>
            parseInt(value)
          );
          setters.setSourceStatusOptions(prevOptions => [
            ...prevOptions,
            ...options.sourceStatusOptions,
            ...selectedRowData?.selectedFilter?.sourceStatusList,
          ]);
          break;

        case 'district':
          filterValues.district = filterValueArray;
          setters.setDistrictOptions(prevOptions => [
            ...prevOptions,
            ...options.districtOptions,
            ...selectedRowData?.selectedFilter?.districtList,
          ]);
          break;

        case 'workroom':
          filterValues.workroom = filterValueArray.map(value =>
            parseInt(value)
          );
          setters.setWorkroomOptions(prevOptions => [
            ...prevOptions,
            ...options.workRoomOptions,
            ...selectedRowData?.selectedFilter?.workroomList,
          ]);
          break;

        case 'customer':
          filterValues.customer = filterValueArray.map(value =>
            parseInt(value)
          );
          setters.setCustomerOptions(prevOptions => [
            ...prevOptions,
            ...options.customerOptions,
            ...selectedRowData?.selectedFilter?.customerList,
          ]);
          break;

        default:
          break;
      }
    });
  }

  return filterValues;
};
