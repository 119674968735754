import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import {
  getChargebackListByUser,
  userGridColumns,
} from '../user-chargeback-helper';
import usePFDataTable from '../../../shared/PFPrime/PFDatatable.jsx';
import PFButton from '../../../shared/PFPrime/PFButton';
import { addEditChargeback } from '../../../ProjectManagement/Chargeback/services/ChargebackService';
import TableLoader from '../../../shared/Loader/TableLoader';

const UserChargeBackView = () => {
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [value, setValue] = useState('');
  const { user: user_id } = useParams();
  let history = useHistory();
  const toast = useRef(null);
  const {
    layout: UserTable,
    data: userTableData,
    columns,
    totalRecords,
    rows,
    page,
    sortBy,
    orderBy,
  } = usePFDataTable();
  const userAction = props => {
    if (props.actionName === 'deleteChargeBack') {
      setValue(props.chargebackId);
      setConfirmDelete(true);
    }
  };

  const deleteChargeback = async chargebackId => {
    setLoading(true);
    let editedResponse;
    let body = { summary: { chargeback_id: chargebackId } };
    try {
      editedResponse = await addEditChargeback(body, chargebackId, true);
      if (editedResponse?.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: editedResponse?.message,
          life: 2000,
        });
        setConfirmDelete(false);
        userChargebackDetails();
      } else {
        toast.current.show({
          severity: 'error',
          summary: editedResponse?.message,
          life: 2000,
        });
      }
    } catch (err) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const userChargebackDetails = () => {
    setLoading(true);
    getChargebackListByUser(user_id, rows, page).then(response => {
      userTableData(response?.data?.result);
      totalRecords(response?.data?.count);
      columns(userGridColumns(history, userAction));
      setLoading(false);
    });
  };

  useEffect(() => {
    userChargebackDetails();
  }, [page, rows, user_id]);

  return (
    <>
      <div className="pt-3 pb-3">
        <Toast ref={toast} />
        <ConfirmDialog />
        {loading ? (
          <TableLoader columnCount={12} noOfRow={3} mTopBottom={0} />
        ) : (
          <UserTable />
        )}
      </div>
      <Dialog
        header="Delete Confirmation"
        visible={confirmDelete}
        style={{ width: '500px' }}
        onHide={() => setConfirmDelete(false)}
        footer={
          <div>
            <PFButton
              label="No"
              text
              outlined
              onClick={() => setConfirmDelete(false)}
            />
            <PFButton
              label="Delete"
              onClick={() => {
                deleteChargeback(value);
              }}
            />
          </div>
        }
        draggable={false}
      >
        <>
          <i className="pi pi-info-circle mx-2" />
          <span>Please confirm if you would like to delete this record?</span>
        </>
      </Dialog>
    </>
  );
};

export default UserChargeBackView;
