import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Toast, Card, BlockUI, Tag, Message } from 'primereact';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import ApiService from '../../../../services/api.service';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { formatDateTime } from '../../../../utils/Helpers';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import TableLoader from '../../../shared/Loader/TableLoader';

import TransactionIDSection from './TransactionIDSection';

const PAYMENT_TYPES = {
  FULL_PAYMENT: 'Full Payment',
  PARTIAL_PAYMENT: 'Partial Payment',
};
const InvoicePayments = ({
  invoice_id,
  invoicePaymentStatus,
  invoicePendingAmount,
  customerDetails,
}) => {
  const [loading, setLoading] = useState(true);
  const [isTenantOnBoardingCompleted, setIsTenantOnBoardingCompleted] =
    useState(false);
  const [isOnBoardingAPIInProgress, setIsOnBoardingAPIInProgress] =
    useState(false);

  const [paymentsList, setPaymentsList] = useState([]);

  const toast = useRef(null);

  const initialValues = {
    payment_message: PAYMENT_TYPES.FULL_PAYMENT,
    payment_amount: invoicePendingAmount,
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.getAllPayment}/${invoice_id}`
      );
      setPaymentsList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    }
  };
  const checkOnboardingStatus = async () => {
    try {
      setIsOnBoardingAPIInProgress(true);
      const response = await axios.get(
        `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.paymentConfig}/${localStorage.getItem(
          'client_id'
        )}`
      );
      if (
        response?.data?.data?.onboarding_status_id &&
        response?.data?.data?.onboarding_status_id == 342
      ) {
        fetchData();
        setIsTenantOnBoardingCompleted(true);
      }
      setIsOnBoardingAPIInProgress(false);
    } catch (error) {
      setIsOnBoardingAPIInProgress(false);
    }
  };
  useEffect(() => {
    checkOnboardingStatus();
  }, []);

  const paymentsFormFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      payment_message: Yup.string().trim().required('required'),
      payment_amount: Yup.number()
        .moreThan(0, 'Must be greater than 0')
        .required('Required'),
    }),
    onSubmit: async values => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.sendPaymentMail}`,
          {
            invoice_id,
            request_message: values.payment_message,
            requested_amount: values.payment_amount,
          }
        );
        toast?.current?.show({
          severity: 'success',
          summary: 'Payment link sent',
          life: 2000,
        });

        paymentsFormFormik.resetForm({ values: initialValues });
        fetchData();
      } catch (error) {
        setLoading(false);
        toast?.current?.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
      }
    },
  });
  const getPaymentStatus = status => {
    const STATUS = {
      Pending: <Tag severity="warning" value={status}></Tag>,
      Paid: <Tag severity="success" value={status}></Tag>,
      Failed: <Tag severity="danger" value={status}></Tag>,
    };
    return STATUS[status];
  };
  const tableColum = [
    {
      field: 'request_message',
      header: 'Type',
      sortable: true,
      filter: false,
    },
    {
      field: 'requested_amount',
      header: 'Amount',
      sortable: true,
      filter: false,
      body: params => `$ ${params.requested_amount}`,
    },
    {
      field: 'created_at',
      header: 'Date',
      sortable: true,
      filter: false,

      body: params => formatDateTime(params.created_at),
    },
    {
      field: 'transaction_number',
      header: 'Transaction Number',
      sortable: true,
      filter: false,
      body: params => {
        if (params.transaction_number)
          return <TransactionIDSection params={params} />;
        return (
          <div>
            <i
              className="pi pi-info-circle mr-2"
              style={{ color: 'var(--primary-color)' }}
            ></i>
            {'Customer yet to review'}
          </div>
        );
      },
    },

    {
      field: 'payment_status',
      header: 'Status',
      sortable: true,
      filter: false,
      body: params => getPaymentStatus(params.payment_status),
    },
  ];

  return isTenantOnBoardingCompleted ? (
    <BlockUI blocked={loading} className="w-12">
      <ConfirmPopup />
      <Card style={{ boxShadow: 'none' }}>
        {invoicePaymentStatus !== 'Paid' && (
          <div className="grid">
            <div className="col-12 md:col-6 lg:col-5">
              <span className="p-float-label">
                <PFDropdown
                  options={[
                    {
                      value: PAYMENT_TYPES.FULL_PAYMENT,
                    },
                    {
                      value: PAYMENT_TYPES.PARTIAL_PAYMENT,
                    },
                  ]}
                  optionLabel="value"
                  optionValue="value"
                  value={paymentsFormFormik.values.payment_message}
                  onChange={(name, value) => {
                    paymentsFormFormik.setFieldValue('payment_message', value);
                    if (value == PAYMENT_TYPES.FULL_PAYMENT) {
                      paymentsFormFormik.setFieldValue(
                        'payment_amount',
                        invoicePendingAmount
                      );
                    }
                  }}
                  name="payment_message"
                />
                <label htmlFor="payment_message">
                  Payment Type <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              <PFFormikError
                touched={paymentsFormFormik.touched}
                errors={paymentsFormFormik.errors}
                field="payment_message"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <span className="p-float-label">
                <PFInputNumber
                  value={paymentsFormFormik.values.payment_amount}
                  name="payment_amount"
                  onChange={(name, value) =>
                    paymentsFormFormik.setFieldValue(
                      'payment_amount',
                      value || 0
                    )
                  }
                  prefix={'$'}
                  disabled={
                    paymentsFormFormik.values.payment_message ===
                    PAYMENT_TYPES.FULL_PAYMENT
                      ? true
                      : false
                  }
                  minFractionDigits={2}
                  max={invoicePendingAmount}
                />
                <label htmlFor="payment_amount">
                  Amount <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              <PFFormikError
                touched={paymentsFormFormik.touched}
                errors={paymentsFormFormik.errors}
                field="payment_amount"
              />
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <PFButton
                label="Send payment request"
                onClick={event => {
                  customerDetails?.customer_emails?.length
                    ? paymentsFormFormik.handleSubmit()
                    : confirmPopup({
                        target: event.currentTarget,
                        message:
                          'Kindly update the Customer profile with the Email ID to send this Payment Link',
                        icon: 'pi pi-info-circle',
                        footer: <></>,
                        pt: {
                          content: { className: 'py-2' },
                        },
                      });
                }}
                className="btn-mail-send w-full"
                disabled={loading}
                icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
              />
            </div>
          </div>
        )}
        <Toast ref={toast} />
      </Card>
      <PFDataTable
        columns={tableColum}
        data={paymentsList}
        dataKey="id"
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 50]}
        tableStyle={{ minWidth: '50rem' }}
      />
    </BlockUI>
  ) : isOnBoardingAPIInProgress ? (
    <>
      <SkeletonLoader columnCount={1} columnWidth="100%" />
      <TableLoader columnCount={3} noOfRow={4} mTopBottom={0} />
    </>
  ) : (
    <Card style={{ boxShadow: 'none' }} className="mt-4 text-center">
      <Message
        severity="error"
        text="Please contact ProjectsForce Support team to complete your stripe onboarding and start sending payment request to customer"
      />
    </Card>
  );
};

export default InvoicePayments;
