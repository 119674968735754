import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import useQuoteStatus from '../../../../hooks/useQuoteStatus';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';

const ItemResult = ({
  aggregate,
  setExpiryDate,
  setStatusId,
  expiryDate,
  statusId,
  quoteId,
  quoteCreateAt,
}) => {
  const { quoteStatus } = useQuoteStatus();
  const { customerId, opportunityId, mode } = useParams();
  const { totalQuoteAmount, totalDiscountAmount, totalTaxAmount } = aggregate;
  const [selectedQuoteStatus, setSelectedQuoteStatus] = useState('');
  const [validUntil, setValidUntil] = useState('');

  const onQuoteStatus = (name, value) => {
    setSelectedQuoteStatus(value);
  };

  useEffect(() => {
    if (mode === 'create') {
      if (quoteStatus?.length > 0) {
        const newStatusId = quoteStatus.find(
          item => item.status.toLowerCase() === 'new'
        )?.status_id;
        setSelectedQuoteStatus(newStatusId);
      }
    }
  }, [quoteStatus]);

  const onValidUntil = e => {
    setValidUntil(e.value);
  };

  const modifiedDate = (days = 0) => {
    const extend = days * 24;
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + extend * 60 * 60 * 1000);
    return newDate;
  };

  useEffect(() => {
    setExpiryDate(validUntil);
  }, [validUntil]);

  useEffect(() => {
    selectedQuoteStatus && setStatusId(selectedQuoteStatus);
  }, [selectedQuoteStatus]);

  useEffect(() => {
    if (expiryDate && !validUntil) {
      const date = new Date(expiryDate);
      setValidUntil(date);
    }
  }, [expiryDate]);

  useEffect(() => {
    if (statusId && !selectedQuoteStatus) {
      setSelectedQuoteStatus(statusId);
    }
  }, [statusId]);

  return (
    <div htmlFor="Item Result">
      <div className="grid">
        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="quoteId"
                  name="quote_id"
                  type="text"
                  value={quoteId}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Quote ID</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFDropdown
                options={quoteStatus}
                optionLabel="status"
                optionValue="status_id"
                name="statusId"
                filter={true}
                value={selectedQuoteStatus}
                onChange={onQuoteStatus}
                disabled={mode === 'edit' ? false : true}
              />
              <label htmlFor="statusId">
                Quote Status <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="createdOn"
                  name="created_on"
                  type="text"
                  value={
                    quoteCreateAt &&
                    moment(quoteCreateAt, 'YYYY-MM-DD hh:mm A').format(
                      'MM-DD-YYYY'
                    )
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Created On</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFCalendar
                id="validUntil"
                name="validUntil"
                value={validUntil}
                onChange={onValidUntil}
                minDate={modifiedDate(0)}
                disabled={mode === 'view' ? true : false}
              />
              <label htmlFor="clientFirstName">
                Valid Until <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalDiscountAmount"
                name="totalDiscountAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalDiscountAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Discount</label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalTaxAmount"
                name="totalTaxAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalTaxAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Tax</label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalQuoteAmount"
                name="totalQuoteAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalQuoteAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Quote Amount </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemResult;
