import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isDeleteDialogOpen: false,
  statesList: [],
  addressData: [],
  deleteRowIndex: null,
  addressIdToDelete: null,
};

const userFormAddressSlice = createSlice({
  name: 'userFormAddress',
  initialState: initialState,
  reducers: {
    setUserAddressForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.statesList = payload.hasOwnProperty('statesList')
        ? payload.statesList
        : state.statesList;
      state.addressData = payload.hasOwnProperty('addressData')
        ? payload.addressData
        : state.addressData;
      state.isDeleteDialogOpen = payload.hasOwnProperty('isDeleteDialogOpen')
        ? payload.isDeleteDialogOpen
        : state.isDeleteDialogOpen;
      state.deleteRowIndex = payload.hasOwnProperty('deleteRowIndex')
        ? payload.deleteRowIndex
        : state.deleteRowIndex;
      state.addressIdToDelete = payload.hasOwnProperty('addressIdToDelete')
        ? payload.addressIdToDelete
        : state.addressIdToDelete;
    },
  },
});

export const { setUserAddressForm } = userFormAddressSlice.actions;

export const userFormAddressReducer = userFormAddressSlice.reducer;
