import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputMask } from 'primereact/inputmask';
import { Message } from 'primereact/message';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
// import { CustomerService } from './service/CustomerService';
import './style.css';
import { Sidebar } from 'primereact/sidebar';
// import { apiServer } from "../apis/apis";

const PFCalendar = props => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    props?.onChange && props?.onChange(date);
  }, [date]);

  const dateTemplate = date => {
    if (
      date.day >= props?.restrictedDuration?.From &&
      date.day <= props?.restrictedDuration?.To
    ) {
      return (
        <strong style={{ textDecoration: 'line-through' }}>{date.day}</strong>
      );
    }

    return date.day;
  };
  return (
    <div className={props?.className} style={props?.style}>
      <span className="p-float-label" style={{ width: '100% !important' }}>
        <Calendar
          value={date}
          onChange={e => setDate(e.value)}
          dateTemplate={props?.restrictedDuration ? dateTemplate : ''}
          dateFormat={props?.dateFormat || 'dd/mm/yy'}
          style={{ width: '100% !important' }}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </div>
  );
};

const PFDropdown = props => {
  // apiServer();
  const [selectedOption, setSelectedOption] = useState(null);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    props?.onChange && props?.onChange(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setFilter(props?.filter);
  }, [props?.filter]);
  return (
    <div className={props?.className} style={props.style}>
      <span className="p-float-label" style={{ width: '100% !important' }}>
        <Dropdown
          value={selectedOption || {}}
          onChange={e => setSelectedOption(e.value)}
          options={props?.options || []}
          optionLabel={props?.optionLabel || ''}
          placeholder={props?.placeholder || ''}
          filter={filter}
          className={props?.selectClassName || ''}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </div>
  );
};

const PFMultiSelect = props => {
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    props?.onChange(selectedOption);
  }, [selectedOption]);

  return (
    <div className={props?.className} style={props.style}>
      <MultiSelect
        value={selectedOption}
        onChange={e => setSelectedOption(e.value)}
        options={props?.options || []}
        optionLabel={props?.optionLabel || ''}
        display="chip"
        placeholder={props?.placeholder || ''}
        maxSelectedLabels={3}
        className={props?.selectClassName || ''}
      />
    </div>
  );
};

const PFTabManu = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    props?.onChange(props?.manu[activeIndex]);
  }, [activeIndex]);

  return (
    <div className={props?.className} style={props.style}>
      <TabMenu
        model={props?.manu || []}
        activeIndex={activeIndex}
        onTabChange={e => setActiveIndex(e.index)}
      />
    </div>
  );
};

const PFTextBox = props => {
  const [value, setValue] = useState(props?.value || '');

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <div className={props?.className} style={props.style}>
      <span className="p-float-label">
        <InputText
          value={value}
          onChange={e => setValue(e.target.value)}
          readOnly={props?.readOnly || false}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </div>
  );
};

const PFButton = props => {
  const [onClickEvent, setOnClickEvent] = useState(null);
  return (
    <div className={props?.className || ''}>
      <Button
        className={props?.btnClass || ''}
        label={props?.label || ''}
        icon={props?.icon || ''}
        onClick={props?.onClick}
        severity={props?.severity || 'primary'}
      />
    </div>
  );
};

const PFSidebarHeader = headerText => {
  return headerText ? (
    <div className="grid w-12 text-white m-0 text-center justify-content-center">
      <h2 className="m-0">{headerText}</h2>
    </div>
  ) : (
    <></>
  );
};

const PFDialog = props => {
  return (
    <div className={props?.className || ''} style={props?.style}>
      <Dialog
        header={props?.header || ''}
        visible={props?.visible}
        style={props?.dialogStyle || ''}
        onHide={props?.onHide}
        className={props?.dialogClass}
      >
        <>
          <props.dialogBody />
        </>
      </Dialog>
    </div>
  );
};

const PFSideBar = ({
  children,
  visible,
  className,
  headerText = '',
  onHide,
  style,
  ...rest
}) => {
  return (
    <div className={className || ''} style={style || {}}>
      <Sidebar
        icons={PFSidebarHeader(headerText)}
        className="p-0"
        fullScreen
        visible={visible}
        onHide={onHide}
        {...rest}
      >
        <>{children}</>
      </Sidebar>
    </div>
  );
};

const PFfileUpload = props => {
  return (
    <div className={props?.className || ''} style={props?.style}>
      <FileUpload
        name="demo[]"
        url={'/api/upload'}
        accept="image/*"
        maxFileSize={1000000}
        emptyTemplate={
          <p className="m-0">Drag and drop files to here to upload.</p>
        }
      />
    </div>
  );
};

const PFMessage = props => {
  return (
    <div className={props?.className || ''} style={props?.style}>
      <Message
        style={{
          border: 'solid #696cff',
          borderWidth: '0 0 0 6px',
          color: '#696cff',
        }}
        className="border-primary w-full justify-content-start"
        severity="info"
        content={props?.message || ''}
      />
    </div>
  );
};

const PFDataTable = props => {
  const [customers, setCustomers] = useState([
    { name: 'ABC', type: 'abc', product_name: 'ABC' },
  ]);

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  const textEditor = options => {
    return (
      <InputText
        type="text"
        value={options.name}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  return (
    <div className={props?.className || ''} style={props?.style}>
      <DataTable
        value={customers}
        paginator={props?.paginator || false}
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '50rem' }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
      >
        <Column field="date" header="Date"></Column>
        <Column field="type" header="Adjustment Type"></Column>
        <Column field="product_name" header="Product Name"></Column>
        <Column field="location" header="Location"></Column>
        <Column field="quantity_to_adjust" header="Quantity to Adjust"></Column>
        <Column
          field="quantity_cost_of_adjustment"
          header="Total Cost of Adjustment"
        ></Column>
      </DataTable>
    </div>
  );
};

const PFDataTableEdit = props => {
  const [products, setProducts] = useState([
    {
      date: '10/08/2023',
      adjustment_type: 'code',
      location: 'werehouse 1',
      quantity_to_adjust: 250,
      total_cost_of_adjustment: 25,
    },
  ]);
  const [statuses] = useState(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);
  const [adjustmentType] = useState([
    'Add',
    'Lost',
    'Damaged',
    'Dispatched',
    'Ordered',
    'Allocated',
  ]);
  const [location] = useState(['werehouse 1', 'werehouse 2', 'werehouse 3']);

  // useEffect(() => {
  //   ProductService.getProductsMini().then((data) => setProducts(data));
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getSeverity = (value) => {
  //   switch (value) {
  //     case 'INSTOCK':
  //       return 'success';

  //     case 'LOWSTOCK':
  //       return 'warning';

  //     case 'OUTOFSTOCK':
  //       return 'danger';

  //     default:
  //       return null;
  //   }
  // };

  const onRowEditComplete = e => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const dateEditor = options => {
    return (
      <Calendar
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        dateTemplate={props?.restrictedDuration ? dateTemplate : ''}
        dateFormat={props?.dateFormat || 'dd/mm/yy'}
        style={{ width: '100% !important' }}
      />
    );
  };

  const statusEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Status"
        itemTemplate={option => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const adjustmentTypeEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={adjustmentType}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const locationEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={location}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const priceEditor = options => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={e => options.editorCallback(e.value)}
        // mode="currency"
        // currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = rowData => {
    return <span value={rowData.inventoryStatus}></span>;
  };

  const adjustmentTypeBodyTemplate = rowData => {
    return <div>{rowData.adjustment_type}</div>;
  };

  const locationBodyTemplate = rowData => {
    return <div>{rowData.location}</div>;
  };

  const priceBodyTemplate = rowData => {
    return <div>{rowData.total_cost_of_adjustment}</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };

  return (
    <div className="card p-fluid">
      <DataTable
        value={products}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        tableStyle={{ minWidth: '50rem' }}
      >
        <Column
          field="date"
          header="Date"
          editor={options => dateEditor(options)}
          style={{ width: '20%' }}
        ></Column>
        <Column
          field="adjustment_type"
          header="Adjustment Type"
          body={adjustmentTypeBodyTemplate}
          editor={options => adjustmentTypeEditor(options)}
          style={{ width: '20%' }}
        ></Column>

        <Column
          field="location"
          header="Location"
          body={locationBodyTemplate}
          editor={options => locationEditor(options)}
          style={{ width: '20%' }}
        ></Column>
        <Column
          field="quantity_to_adjust"
          header="Quantity to adjust"
          body={priceBodyTemplate}
          editor={options => priceEditor(options)}
          style={{ width: '18%' }}
        ></Column>
        <Column
          field="total_cost_of_adjustment"
          header="Total cost of adjustment($)"
          body={priceBodyTemplate}
          editor={options => priceEditor(options)}
          style={{ width: '30%' }}
        ></Column>

        <Column
          rowEditor
          headerStyle={{ width: '10%', minWidth: '8rem' }}
          bodyStyle={{ textAlign: 'center' }}
        ></Column>
      </DataTable>
    </div>
  );
};

const PFDataTableEdit1 = props => {
  const [products, setProducts] = useState([
    {
      date: '10/08/2023',
      adjustment_type: 'Sold',
      location: 'Wilson',
      stock_in_hand: 250,
      quantity_to_adjust: 250,
      total_cost_of_adjustment: 25,
    },
  ]);
  const [statuses] = useState(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);
  const [adjustmentType] = useState([
    'Add',
    'Lost',
    'Damaged',
    'Dispatched',
    'Ordered',
    'Allocated',
  ]);
  const [location] = useState(['werehouse 1', 'werehouse 2', 'werehouse 3']);

  // useEffect(() => {
  //   ProductService.getProductsMini().then((data) => setProducts(data));
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getSeverity = (value) => {
  //   switch (value) {
  //     case 'INSTOCK':
  //       return 'success';

  //     case 'LOWSTOCK':
  //       return 'warning';

  //     case 'OUTOFSTOCK':
  //       return 'danger';

  //     default:
  //       return null;
  //   }
  // };

  const onRowEditComplete = e => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const dateEditor = options => {
    return (
      <Calendar
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        dateTemplate={props?.restrictedDuration ? dateTemplate : ''}
        dateFormat={props?.dateFormat || 'dd/mm/yy'}
        style={{ width: '100% !important' }}
      />
    );
  };

  const statusEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Status"
        itemTemplate={option => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const adjustmentTypeEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={adjustmentType}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const locationEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={location}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const priceEditor = options => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={e => options.editorCallback(e.value)}
        // mode="currency"
        // currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = rowData => {
    return <span value={rowData.inventoryStatus}></span>;
  };

  const adjustmentTypeBodyTemplate = rowData => {
    return <Tag value={rowData.adjustment_type} severity="info" />;
  };

  const locationBodyTemplate = rowData => {
    return <div>{rowData.location}</div>;
  };

  const priceBodyTemplate = rowData => {
    return <div>200</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };

  const priceBodyTemplate1 = rowData => {
    return <div>5</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };

  const priceBodyTemplate2 = rowData => {
    return <div>3</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };

  return (
    <div className="card p-fluid">
      <DataTable
        value={products}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        tableStyle={{ minWidth: '50rem' }}
      >
        <Column
          field="date"
          header="Date"
          editor={options => dateEditor(options)}
          style={{ minWidth: '200px' }}
        ></Column>
        <Column
          field="adjustment_type"
          header="Adjustment Type"
          body={adjustmentTypeBodyTemplate}
          editor={options => adjustmentTypeEditor(options)}
          style={{ width: '20%' }}
        ></Column>

        <Column
          field="location"
          header="Location"
          body={locationBodyTemplate}
          editor={options => locationEditor(options)}
          style={{ width: '20%' }}
        ></Column>
        <Column
          field="stock_in_hand"
          header="Stock in Hand"
          body={priceBodyTemplate1}
          editor={options => priceEditor(options)}
          style={{ width: '20%' }}
        ></Column>

        <Column
          field="quantity_to_adjust"
          header="Quantity to adjust"
          body={priceBodyTemplate2}
          editor={options => priceEditor(options)}
          style={{ width: '18%' }}
        ></Column>
        <Column
          field="total_cost_of_adjustment"
          header="Total cost of adjustment($)"
          body={priceBodyTemplate}
          editor={options => priceEditor(options)}
          style={{ width: '30%' }}
        ></Column>

        <Column
          rowEditor
          headerStyle={{ width: '10%', minWidth: '8rem' }}
          bodyStyle={{ textAlign: 'center' }}
        ></Column>
      </DataTable>
    </div>
  );
};

const PFDataTableEdit2 = props => {
  const [products, setProducts] = useState([
    {
      date: '10/08/2023',
      adjustment_type: 'code',
      location: 'werehouse 1',
      quantity_to_adjust: 250,
      total_cost_of_adjustment: 250,
    },
  ]);
  const [statuses] = useState(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);
  const [adjustmentType] = useState([
    'Add',
    'Lost',
    'Damaged',
    'Dispatched',
    'Ordered',
    'Allocated',
  ]);
  const [location] = useState(['werehouse 1', 'werehouse 2', 'werehouse 3']);

  // useEffect(() => {
  //   ProductService.getProductsMini().then((data) => setProducts(data));
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getSeverity = (value) => {
  //   switch (value) {
  //     case 'INSTOCK':
  //       return 'success';

  //     case 'LOWSTOCK':
  //       return 'warning';

  //     case 'OUTOFSTOCK':
  //       return 'danger';

  //     default:
  //       return null;
  //   }
  // };

  const onRowEditComplete = e => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const dateEditor = options => {
    return (
      <Calendar
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        dateTemplate={props?.restrictedDuration ? dateTemplate : ''}
        dateFormat={props?.dateFormat || 'dd/mm/yy'}
        style={{ width: '100% !important' }}
      />
    );
  };

  const statusEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Status"
        itemTemplate={option => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const adjustmentTypeEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={adjustmentType}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const locationEditor = options => {
    return (
      <Dropdown
        value={options.value}
        options={location}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
        itemTemplate={option => {
          return <div>{option}</div>;
        }}
      />
    );
  };

  const priceEditor = options => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={e => options.editorCallback(e.value)}
        // mode="currency"
        // currency="USD"
        locale="en-US"
      />
    );
  };

  const statusBodyTemplate = rowData => {
    return <span value={rowData.inventoryStatus}></span>;
  };

  const adjustmentTypeBodyTemplate = rowData => {
    return <div>{rowData.adjustment_type}</div>;
  };

  const locationBodyTemplate = rowData => {
    return <div>{rowData.location}</div>;
  };

  const priceBodyTemplate = rowData => {
    return <div>5</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };
  const priceBodyTemplate1 = rowData => {
    return <div>5</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };
  const priceBodyTemplate2 = rowData => {
    return <div>$250</div>;
    // return new Intl.NumberFormat('en-US').format(rowData.price);
  };

  return (
    <div className="card p-fluid">
      <DataTable
        value={products}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        tableStyle={{ minWidth: '50rem' }}
      >
        <Column
          field="date"
          header="Date"
          editor={options => dateEditor(options)}
          style={{ width: '20%' }}
        ></Column>
        <Column
          field="adjustment_type"
          header="From Werehouse"
          body={'Kelson'}
          editor={options => adjustmentTypeEditor(options)}
          style={{ width: '20%' }}
        ></Column>

        <Column
          field="location"
          header="To Werehouse"
          body={'Midway'}
          editor={options => locationEditor(options)}
          style={{ width: '20%' }}
        ></Column>
        <Column
          field="quantity_to_adjust"
          header="Available Quantity"
          body={priceBodyTemplate}
          editor={options => priceEditor(options)}
          style={{ width: '18%' }}
        ></Column>
        <Column
          field="quantity_to_adjust"
          header="Quantity"
          body={priceBodyTemplate1}
          editor={options => priceEditor(options)}
          style={{ width: '18%' }}
        ></Column>
        <Column
          field="total_cost_of_adjustment"
          header="Total Cost"
          body={priceBodyTemplate2}
          editor={options => priceEditor(options)}
          style={{ width: '30%' }}
        ></Column>

        <Column
          rowEditor
          headerStyle={{ width: '10%', minWidth: '8rem' }}
          bodyStyle={{ textAlign: 'center' }}
        ></Column>
      </DataTable>
    </div>
  );
};

const PFTabView = props => {
  return (
    <div className="card" style={{ width: '100%' }}>
      <TabView>
        <TabPanel header="Adjustment">
          <div className="c-btn-width">
            <PFButton
              btnClass="float-right"
              className="card flex mb-1"
              label="Add"
              icon="pi pi-plus-circle"
              onClick={() => {}}
            />
          </div>
          <div>
            <PFDataTableEdit1 />
          </div>
        </TabPanel>
        <TabPanel header="Transfers">
          <div className="c-btn-width">
            <PFButton
              className="card flex mb-1"
              label="Add"
              icon="pi pi-plus-circle"
              onClick={() => {}}
            />
          </div>
          <PFDataTableEdit2 />
        </TabPanel>
        <TabPanel header="Audit"></TabPanel>
      </TabView>
    </div>
  );
};

export {
  PFButton,
  PFDialog,
  PFTextBox,
  PFCalendar,
  PFDropdown,
  PFfileUpload,
  PFMessage,
  PFDataTable,
  PFDataTableEdit,
  PFTabView,
  PFSideBar,
};
