import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TableLoader = ({ columnCount, noOfRow, mTopBottom }) => {
  const numberOfSkeletons = Array.from(
    Array(columnCount || 20),
    (x, i) => i + 1
  );

  const items = Array.from({ length: noOfRow }, (v, i) => i);

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  return (
    <div
      className={`w-12 h-full flex flex-wrap justify-content-between mt-${mTopBottom || 0}`}
    >
      <DataTable value={items} className="p-datatable-striped">
        {numberOfSkeletons.map(skeleton => (
          <Column body={bodyTemplate}></Column>
        ))}
      </DataTable>
    </div>
  );
};

export default TableLoader;
