import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function PFDataTable({
  data,
  columns,
  paginator,
  loading,
  lazy,
  rows,
  first,
  rowsPerPageOptions,
  tableStyle,
  className,
  onPage,
  totalRecords,
  handleDelete,
  onSort,
  dataTableParams,
  header,
  filters,
  onFilter,
  columnStyle,
  rowExpansionTemplate,
  expandedRows,
  onRowToggle,
  sortField,
  sortOrder,
  stripedRows,
  ...rest
}) {
  return (
    <DataTable
      loading={loading || false}
      value={data || []}
      paginator={paginator || false}
      lazy={lazy || false}
      rows={rows || 0}
      first={first || 0}
      rowsPerPageOptions={rowsPerPageOptions || ''}
      tableStyle={tableStyle || null}
      className={`w-12 ${className ? className : ''}`}
      onPage={onPage || null}
      totalRecords={totalRecords || 0}
      handleDelete={handleDelete}
      onSort={onSort}
      filters={filters}
      onFilter={onFilter}
      {...dataTableParams}
      rowExpansionTemplate={rowExpansionTemplate}
      expandedRows={expandedRows}
      onRowToggle={onRowToggle}
      sortField={sortField}
      sortOrder={sortOrder}
      stripedRows={stripedRows || true}
      size="small"
      {...rest}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    >
      {columns?.map((col, index) => {
        const {
          field,
          header,
          sortable,
          filter,
          body,
          filterElement,
          filterField,
          filterPlaceholder,
          filterClear,
          filterApply,
          showFilterMatchModes,
          style,
          showFilterMenu,
          expander,
          rowEditor,
          editor,
          hidden,
          frozen,
          show,
          onCellEditComplete,
          colSpan,
          pt,
        } = col;
        return (
          <Column
            key={index}
            field={field || null}
            header={header || null}
            sortable={sortable || false}
            filter={filter || null}
            body={body || null}
            filterElement={filterElement || null}
            filterField={filterField || null}
            filterPlaceholder={filterPlaceholder || null}
            filterClear={filterClear || null}
            filterApply={filterApply || null}
            showFilterMatchModes={showFilterMatchModes || null}
            style={columnStyle ? columnStyle : style || ''}
            showFilterMenu={showFilterMenu || true}
            expander={expander || null}
            rowEditor={rowEditor || null}
            editor={editor || null}
            hidden={hidden || undefined}
            frozen={frozen || false}
            onCellEditComplete={onCellEditComplete || null}
            colSpan={colSpan || false}
            pt={pt || null}
          ></Column>
        );
      })}
    </DataTable>
  );
}
