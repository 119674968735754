import { makeStyles } from '@material-ui/core/styles';

import { StatusColors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWrapper: {
    maxWidth: '77%',
    flexBasis: '77%',
  },
  container: {
    maxHeight: 440,
  },
  containerHeader: {
    height: '50px',
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '22px',
    paddingBottom: '14px',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
  },
  containerContent: {
    display: 'flex',
    padding: '24px',
    minHeight: '530px',
  },
  formWrapper: {
    width: '100%',
  },

  indicator: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.common.white,
    bottom: '8px',
    '& > span': {
      maxWidth: 80,
      width: '100%',
    },
  },
  menu: {
    marginTop: '35px',
    marginLeft: '10px',
    background: theme.palette.common.white,
    boxShadow: 'unset',
  },
  menuItem: {
    '& .MuiMenuItem-root': {
      fontSize: '13px',
    },
  },
  menuHeaderIcon: {
    fill: theme.palette.common.white,
    marginRight: '10px',
  },
  menuVertIcon: {
    fill: theme.palette.common.white,
    cursor: 'pointer',
  },
  moreIconRoot: {
    height: '100%',
    marginRight: '10px',
  },
  paperRoot: {
    minHeight: '250px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '12px',
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  statusWrapper: {
    maxWidth: '50%',
    flexBasis: '50%',
  },
  statusButton: props => ({
    backgroundColor:
      (props.status === 'Cancelled' && StatusColors.PF_LIGHT_RED) ||
      (props.status === 'Completed' && StatusColors.PF_LIGHT_GREEN) ||
      StatusColors.PF_LIGHT_YELLOW,
    color:
      (props.status === 'Cancelled' && StatusColors.PF_RED) ||
      (props.status === 'Completed' && StatusColors.PF_GREEN) ||
      StatusColors.PF_YELLOW,
    fontWeight: 600,
    padding: '2px 6px',
    '&:hover': {
      backgroundColor:
        (props.status === 'Cancelled' && StatusColors.PF_LIGHT_RED) ||
        (props.status === 'Completed' && StatusColors.PF_LIGHT_GREEN) ||
        StatusColors.PF_LIGHT_YELLOW,
    },
  }),
  leadBadge: {
    backgroundColor: StatusColors.PF_LIGHT_RED,
    color: StatusColors.PF_RED,
    fontWeight: 600,
    padding: '2px 6px',
    '&:hover': {
      backgroundColor: StatusColors.PF_LIGHT_YELLOW,
    },
  },
  scrollButtons: {
    paddingTop: '4px',
    '& .MuiSvgIcon-fontSizeSmall': {
      width: '25px',
      height: '25px',
      fill: theme.palette.common.white,
    },
  },
  tabRoot: {
    borderBottom: `1px Solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
  tabTextColor: {
    color: theme.palette.common.white,
    left: 10,
  },
  tableContainer: {
    maxHeight: 440,
  },
  tabsPaper: {
    flexGrow: 1,
    width: '100%',
    minHeight: '50px',
  },
  tableFirstColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 9,
  },
  tableFirstColumnBody: {
    position: 'sticky',
    left: 0,
    zIndex: 6,
    background: 'white',
    border: '1px solid grey',
  },
  tabPanel: {
    padding: '20px',
  },
  changeOrder: {
    backgroundColor: StatusColors.PF_LIGHT_YELLOW,
    color: StatusColors.PF_YELLOW,
    fontWeight: 600,
    padding: '2px 6px',
    '&:hover': {
      backgroundColor: StatusColors.PF_LIGHT_YELLOW,
    },
  },
}));
