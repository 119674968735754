export const TAGS = [
  { id: 1, label: 'Opportunity' },
  { id: 2, label: 'Quote' },
  { id: 3, label: 'Invoice' },
  { id: 4, label: 'Receipt' },
];

export const SEND_RECEIVED = [
  { id: 1, label: 'Send' },
  { id: 2, label: 'Received' },
];

export const COMMUNICATION_TYPE = {
  opportunity: 'opportunityId',
  quote: 'quoteId',
  invoice: 'invoiceId',
  payment: 'paymentId',
};
