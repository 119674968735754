// **** React Imports ****
import React, { useState } from 'react';

// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import useToken from '../../hooks/useToken';
import PageHeader from '../shared/PageHeader/PageHeader';

import { getProjectForceVideos } from './GetStarted.service';

// **** Custom Components ****

const DeleteInstaller = ({ match }) => {
  const { accessToken } = useToken();
  let clientsBreadcrumb = [
    {
      text: 'Get Started',
      link: '/getstarted',
    },
  ];

  const useStyles = makeStyles(theme => ({
    root: {
      '& .video-container': {
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%',
        height: '0',
      },
      '& .video': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        border: '0',
        borderRadius: '20px',
        padding: '12px',
      },
    },
  }));
  const classes = useStyles();
  const [, setLoading] = useState();
  const [getVideos, setVideos] = useState([]);
  React.useEffect(() => {
    getProjectForceVideos(setLoading, setVideos, match.params.videoIdid);
  }, []);
  if (getVideos.length) {
    clientsBreadcrumb = [
      ...clientsBreadcrumb,
      {
        text: getVideos[0]?.snippet?.title,
      },
    ];
  }
  return accessToken && getVideos.length ? (
    <>
      <Grid container spacing={2} direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="How it works"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={10} sm={10} className={'video-container'}>
            <iframe
              src={`https://www.youtube.com/embed/${getVideos[0]?.id}`}
              title={getVideos[0]?.snippet?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={'video'}
            ></iframe>
          </Grid>
          <Grid item xs={10} sm={10}>
            <Typography gutterBottom variant="h3" component="h2">
              {getVideos[0]?.snippet?.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {getVideos[0]?.snippet?.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <>Please Log-in to access the application</>
  );
};

export default DeleteInstaller;
