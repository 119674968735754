import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  smallbox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '43%',
    },
    [theme.breakpoints.up('md')]: {
      width: '44%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '44%',
    },
  },
  largebox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '90%',
    },
  },
  receiveLabel: {
    marginLeft: '0px',
  },
  switch: {
    alignSelf: 'flex-start',
  },
  closeButton: {
    width: '10px',
    height: '10px',
    padding: 0,
    alignSelf: 'center',
  },
}));
