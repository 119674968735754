import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '12px',
  },
  container: {
    maxHeight: '440px',
  },
  tableContainer: {
    maxHeight: '440px',
  },
  tableFirstColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 9,
  },
  tableFirstColumnBody: {
    position: 'sticky',
    left: 0,
    zIndex: 6,
    background: 'white',
    border: '1px solid grey',
  },
  checkbox: {
    transform: 'scale(1.75)',
    padding: 0,
  },
  cardFooter: {
    padding: '10px 20px',
    justifyContent: 'flex-end',
  },
  //widgets
  widgetContainer: {
    height: '400px',
    width: '300px',
    border: '1px solid #E5E8EB',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    margin: '10px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  signaturebox: {
    width: '200px',
    height: '50px',
    border: '1px dashed skyblue',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tablewidgetContainer: {
    height: '250px',
    width: '400px',
    border: '1px solid #E5E8EB',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    position: 'absolute',
    margin: 0,
    zIndex: 1000,
  },
  sourcetable: {
    display: 'flex',
    flexDirection: 'column',
  },
  action: {
    margin: 0,
    alignSelf: 'center',
  },
  widgetText: {
    padding: '15px 20px',
    //width: "100%",
    fontSize: '10px',
    borderBottom: '1px solid #E5E8EB',
  },
  inputfileupload: {
    marginTop: '20px',
  },

  dragText: {
    border: '1px solid #E5E8EB',
    height: '50px',
    width: '50px',
  },
  //document

  documentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    position: 'relative',
    //overflow: "scroll",
  },
  iframeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
