import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar, Toast } from 'primereact';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';

import ExportWidgetTableData from '../../../Admin/Dashboards/components/ExportWidgetTableData';
import WidgetTableRenderer from '../../../../components/Admin/Widget/components/WidgetTableRenderer';
import {
  getTableQuery,
  routingWidgetDefaultCondition,
} from '../../../Admin/Widget/helpers/query';
import {
  getQueryResult,
  getFilterDetails,
} from '../../../Admin/Widget/service/widget.service';
import Apiservice from '../../../../services/api.service';
import {
  getLoginUserId,
  formatDateDefault,
  removeLinkSession,
} from '../../../../utils/Helpers';
import { getRedirectUrl } from '../../../Admin/Dashboards/Widgets/WidgetRoutes';

const ReadyToRouteJobs = ({ widget, widgetId, setActiveIndex }) => {
  let location = useLocation();
  const toast = useRef(null);
  const [clearFilters, setClearFilters] = useState(false);
  const [homeFilters, setHomeFilters] = useState(
    location.state?.homeFilters || []
  );
  const [homeFilterDateDimension, setHomeFilterDateDimension] = useState(
    location.state?.homeFilterDateDimension || []
  );
  const [applyFilters, setApplyFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [labelDataLoading, setLabelDataLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    filters: null,
  });
  const [paginator, setPaginator] = useState({
    first: 0,
    currentPage: 0,
    perPage: 100,
  });
  const [orderBy, setOrderBy] = useState({
    columns: '',
    direction: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [filterDetails, setFilterDetails] = useState(null);
  const [addViewEditDashboardKey, setAddViewEditDashboardKey] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [createRouteLoading, setCreateRouteLoading] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (isPageLoaded) {
      Promise.all([
        fetchTableData(tablePagination, filterDetails, orderBy, paginator),
      ]).then(() => setLoading(false));
    }
  }, [addViewEditDashboardKey]);

  useEffect(() => {
    const fetchData = async () => {
      const filterDetailsResponse = await getFilterDetails();
      setFilterDetails(filterDetailsResponse);
      Promise.all([
        fetchTableData(tablePagination, filterDetailsResponse),
      ]).then(() => {
        setIsPageLoaded(true);
        setLoading(false);
      });
    };
    fetchData();
  }, [tablePagination, applyFilters, clearFilters, reload]);

  useEffect(() => {
    if (labelDataLoading) {
      removeLinkSession();
    }
  }, [labelDataLoading]);
  useEffect(() => {
    return () => {
      removeLinkSession();
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      Promise.all([
        fetchTableData(tablePagination, filterDetails, orderBy, paginator),
      ]).then(() => setLoading(false));
    }
  }, [orderBy, paginator]);

  const getQuery = filterDetailsResponse => {
    const tableQuery = getTableQuery(widget);
    if ('user-filter' == widget.widget_filter_type && filterDetailsResponse) {
      tableQuery.filters.push(filterDetailsResponse.userFilterCondition);
    } else if (
      'company-filter' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      tableQuery.filters.push(filterDetailsResponse.companyFilterCondition);
    } else if (
      'assigned-to-self' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      tableQuery.filters.push({
        member: 'UserMeta.user_id',
        values: [getLoginUserId()],
        operator: 'equals',
      });
    } else if (
      'user-and-assigned-to-self' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      const userAndAssignToSelfFilter = {
        or: [
          {
            member: 'UserMeta.user_id',
            values: [getLoginUserId()],
            operator: 'equals',
          },
        ],
      };
      if (filterDetailsResponse?.userFilterCondition) {
        userAndAssignToSelfFilter.or.push(
          filterDetailsResponse.userFilterCondition
        );
      }
      tableQuery.filters.push(userAndAssignToSelfFilter);
    }

    if (widget?.custom_properties?.reportTablePrimaryKey) {
      tableQuery.dimensions.push(
        widget.custom_properties.reportTablePrimaryKey
      );
      tableQuery.dimensions.push(
        widget.custom_properties.reportTableSecondaryKey
      );
    }

    if (homeFilterDateDimension && homeFilterDateDimension.length) {
      homeFilterDateDimension.forEach(timeRecord => {
        tableQuery?.timeDimensions?.push({
          dimension: timeRecord.dimension,
          dateRange: timeRecord.dateRange,
        });
      });
    }

    if (homeFilters && homeFilters.length) {
      tableQuery.filters.push(...homeFilters);
    }
    return tableQuery;
  };

  const items = Array.from({ length: 8 }, (v, i) => i);

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  const fetchTableData = async (
    params = tablePagination,
    filterDetailsResponse = filterDetails,
    orderByConfiguration = orderBy,
    paginatorConfiguration = paginator
  ) => {
    setLabelDataLoading(true);
    removeLinkSession();
    const tableQuery = getQuery(filterDetailsResponse);
    tableQuery.limit = paginatorConfiguration.perPage;
    tableQuery.offset =
      paginatorConfiguration.currentPage * paginatorConfiguration.perPage;
    if (orderByConfiguration?.columns && orderByConfiguration?.direction) {
      tableQuery.order = [
        [
          orderByConfiguration?.columns,
          orderByConfiguration?.direction > 0 ? 'asc' : 'desc',
        ],
      ];
    }

    if (params.filters && Object.keys(params.filters)?.length) {
      const tempFilters = [];
      const tempTimeDimension = [];
      Object.keys(params.filters).forEach(key => {
        if (chartData?.annotation?.dimensions?.[key]?.type == 'time') {
          if (params?.filters?.[key]) {
            params.filters?.[key].constraints.forEach(filterKey => {
              if (filterKey?.value && filterKey.value?.length) {
                tempTimeDimension.push({
                  dimension: key,
                  dateRange: [
                    moment(filterKey.value[0]).format('Y-M-D'),
                    moment(filterKey.value[1]).format('Y-M-D'),
                  ],
                });
              }
            });
          }
        } else if (params.filters?.[key]) {
          params.filters?.[key].constraints.forEach(filterKey => {
            if (filterKey.value) {
              tempFilters.push({
                member: key,
                values: [filterKey.value?.toString()],
                operator: filterKey.matchMode,
              });
            }
          });
        }
      });
      if (tempFilters.length) {
        tableQuery.filters.push(...tempFilters);
      }
      if (tempTimeDimension) {
        tableQuery.timeDimensions.push(...tempTimeDimension);
      }
    }
    if (widget?.route_widget) {
      tableQuery.filters.push(routingWidgetDefaultCondition());
    }
    tableQuery.filters.push({
      member: 'Project.is_routed',
      values: ['0'],
      operator: 'equals',
    });
    tableQuery.total = true;
    const response = await getQueryResult(tableQuery);
    if (response.error) {
      fetchTableData(params, filterDetailsResponse, orderBy, paginator);
    } else {
      setChartData(response);
      setLabelDataLoading(false);
      setTotalCount(response.total || 0);
    }
  };
  const onTableChangeHandler = params => {
    setTablePagination(params);
  };

  if (!widget) {
    return <Redirect to="/dashboard" />;
  }

  let excelData = [];
  const headings = widget.table_configuration.map(record => record.alice);
  excelData.push(headings);

  chartData?.data?.forEach(record => {
    const excelRecord = [];
    widget.table_configuration.forEach(key => {
      const formatter = chartData?.annotation?.dimensions[key.measureName]?.meta
        ?.format
        ? eval(chartData?.annotation?.dimensions[key.measureName]?.meta?.format)
        : undefined;
      if (formatter) {
        excelRecord.push(formatter(record[key.measureName]));
      } else {
        excelRecord.push(record[key.measureName]);
      }
    });
    excelData.push(excelRecord);
  });

  const tableConfiguration = [...widget.table_configuration];
  tableConfiguration.unshift({
    alice: '',
    measureName: '',
    columnTypeAction: true,
    options: {
      sort: false,
      filter: false,

      customBodyRenderLite: (dataIndex, rowIndex) => {
        let primaryKeyColumn = '';
        let secondaryKeyColumn = '';
        Object.keys(chartData?.annotation?.dimensions)?.forEach(key => {
          if (chartData?.annotation.dimensions[key]?.meta?.primaryKey) {
            primaryKeyColumn = key;
          }
          if (chartData?.annotation.dimensions[key]?.meta?.secondaryKey) {
            secondaryKeyColumn = key;
          }
        });
        let redirectDetails = null;
        if (primaryKeyColumn) {
          const tableName = primaryKeyColumn?.split('.')[0];
          const id = dataIndex[primaryKeyColumn];
          const secondaryObject = {
            secondaryTable: secondaryKeyColumn?.split('.')[0],
            secondaryId: dataIndex[secondaryKeyColumn],
          };
          redirectDetails = getRedirectUrl(tableName, id, { secondaryObject });
        } else {
          return null;
        }
        if (!redirectDetails) return null;

        let viewJson = JSON.parse(sessionStorage.getItem('viewLinks')) || [];
        viewJson.push(redirectDetails?.viewUrl);
        viewJson = [...new Set(viewJson)];
        sessionStorage.setItem('viewLinks', JSON.stringify(viewJson));

        let editJson = JSON.parse(sessionStorage.getItem('editLinks')) || [];
        editJson.push(redirectDetails?.editUrl);
        editJson = [...new Set(editJson)];
        sessionStorage.setItem('editLinks', JSON.stringify(editJson));
        return (
          <div className="flex  justify-content-center gap-2 m-2">
            {redirectDetails?.viewUrl && (
              <Link to={redirectDetails.viewUrl} className="text-color-900">
                <i className="pi pi-eye"></i>
              </Link>
            )}
            {redirectDetails?.editUrl && (
              <Link to={redirectDetails.editUrl} className="text-color-900">
                <i className="pi pi-pencil"></i>
              </Link>
            )}
          </div>
        );
      },
    },
  });

  const setRowProps = (row, dataIndex, rowIndex) => {
    return {
      onMouseEnter: e => handleRowHover(e, row, rowIndex),
      onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
      onDoubleClick: () => {
        if (widget?.custom_properties?.selectedChartTable) {
          const redirectDetails = getRedirectUrl(
            widget?.custom_properties?.selectedChartTable,
            chartData.data[rowIndex][
              widget?.custom_properties?.reportTablePrimaryKey
            ],
            chartData?.data[rowIndex][
              widget?.custom_properties?.reportTableSecondaryKey
            ]
          );
          history.push(redirectDetails.viewUrl);
        }
      },
    };
  };

  const handleClearAll = () => {
    setHomeFilters([]);
    setClearFilters(true);
    setApplyFilters(false);
  };

  const triggerCreateRoute = async e => {
    e.preventDefault();
    let validToSubmit = true;
    if (!startDate) {
      validToSubmit = false;
      setIsStartDateValid(false);
    }
    if (!endDate) {
      validToSubmit = false;
      setIsEndDateValid(false);
    }
    if (validToSubmit) {
      try {
        const tableQuery = getQuery(filterDetails);
        tableQuery.limit = totalCount;
        tableQuery.offset = 0;
        if (
          tablePagination.filters &&
          Object.keys(tablePagination.filters)?.length
        ) {
          const tempFilters = [];
          const tempTimeDimension = [];
          Object.keys(tablePagination.filters).forEach(key => {
            if (chartData?.annotation?.dimensions?.[key]?.type == 'time') {
              if (tablePagination?.filters?.[key]) {
                tablePagination.filters?.[key].constraints.forEach(
                  filterKey => {
                    if (filterKey?.value && filterKey.value?.length) {
                      tempTimeDimension.push({
                        dimension: key,
                        dateRange: [
                          moment(filterKey.value[0]).format('Y-M-D'),
                          moment(filterKey.value[1]).format('Y-M-D'),
                        ],
                      });
                    }
                  }
                );
              }
            } else if (tablePagination.filters?.[key]) {
              tablePagination.filters?.[key].constraints.forEach(filterKey => {
                if (filterKey.value) {
                  tempFilters.push({
                    member: key,
                    values: [filterKey.value?.toString()],
                    operator: filterKey.matchMode,
                  });
                }
              });
            }
          });
          if (tempFilters.length) {
            tableQuery.filters.push(...tempFilters);
          }
          if (tempTimeDimension) {
            tableQuery.timeDimensions.push(...tempTimeDimension);
          }
        }
        setCreateRouteLoading(true);
        if (widget?.route_widget) {
          tableQuery.filters.push(routingWidgetDefaultCondition());
        }
        tableQuery.filters.push({
          member: 'Project.is_routed',
          values: ['0'],
          operator: 'equals',
        });
        const response = await getQueryResult({
          ...tableQuery,
          dimensions: ['Project.project_id'],
        });
        const payload = {
          widgetId: widgetId,
          routeStartDate: formatDateDefault(startDate),
          routeEndDate: formatDateDefault(endDate),
          projectIds: response.data.map(record => record['Project.project_id']),
        };
        await Apiservice.post('/routing', payload);
        setCreateRouteLoading(false);
        setActiveIndex(1);
      } catch (e) {
        setCreateRouteLoading(false);
        toast.current.show({
          severity: 'error',
          detail: 'Something went wrong',
          life: 3000,
        });
      }
    }
  };

  return loading ? (
    <DataTable value={items} className="p-datatable-striped">
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
    </DataTable>
  ) : (
    <div className="grid w-12 py-3 m-0">
      <div className="w-12 my-2 px-2">
        <div className="w-12">
          <div className="grid">
            <div className="col-12 lg:col-12 xl:col-offset-4 xl:col-8">
              <div className="grid justify-content-end">
                <div className="col-12 lg:col-6 xl:col-4 py-0 mb-2 pl-0 pl:xl-3 pr-0">
                  <span className="p-float-label">
                    <Calendar
                      value={startDate}
                      onChange={e => {
                        setStartDate(e.value);
                        if (!e.value) {
                          setIsStartDateValid(false);
                        } else {
                          setIsStartDateValid(true);
                        }
                      }}
                      showIcon
                      maxDate={endDate}
                      inputId="start_date"
                      className={!isStartDateValid ? 'p-invalid' : ''}
                    />
                    <label htmlFor="start_date" className="text-sm">
                      Start Date
                    </label>
                  </span>
                </div>
                <div className="col-12 lg:col-6 xl:col-4 py-0 mb-2 pl-0 lg:pl-2 pr-0">
                  <span className="p-float-label">
                    <Calendar
                      value={endDate}
                      onChange={e => {
                        setEndDate(e.value);
                        if (!e.value) {
                          setIsEndDateValid(false);
                        } else {
                          setIsEndDateValid(true);
                        }
                      }}
                      showIcon
                      minDate={startDate}
                      inputId="end_date"
                      className={!isEndDateValid ? 'p-invalid' : ''}
                    />
                    <label htmlFor="end_date" className="text-sm">
                      End Date
                    </label>
                  </span>
                </div>
                <div className="col-auto ml-2 mb-2">
                  <Button
                    severity="primary"
                    tooltipOptions={{ position: 'top' }}
                    size="small"
                    onClick={triggerCreateRoute}
                    disabled={createRouteLoading}
                  >
                    {createRouteLoading && (
                      <i className="pi pi-spin pi-spinner"></i>
                    )}
                    Get Recommendations
                  </Button>
                </div>
              </div>
            </div>

            <div className="flex align-items-end justify-content-end w-12">
              <div className="col-auto ml-2 mb-2">
                <Button
                  severity="primary"
                  size="small"
                  onClick={() => handleClearAll()}
                  tooltip="Do you want to clear the previously applied filters"
                  tooltipOptions={{ position: 'top' }}
                  outlined
                  icon="pi pi-filter-slash"
                >
                  <span className="pl-2">Clear</span>
                </Button>
              </div>
              <div className="col-auto ml-2 mb-2">
                <ExportWidgetTableData
                  exportData={excelData}
                  filename={`${widget.widget_name}_${paginator.currentPage + 1}_${moment().format(
                    'Y_M_D'
                  )}`}
                />
              </div>
              <div className="col-auto ml-2 mb-2">
                <Button
                  severity="primary"
                  size="small"
                  outlined
                  rounded
                  onClick={() => setReload(!reload)}
                  tooltipOptions={{ position: 'top' }}
                  icon="pi pi-refresh"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid m-0">
        <div
          style={
            labelDataLoading ? { opacity: '0.4', pointerEvents: 'none' } : {}
          }
        >
          <WidgetTableRenderer
            table_configuration={tableConfiguration}
            data={chartData?.data || []}
            tablePagination={tablePagination}
            onTableChangeHandler={onTableChangeHandler}
            setRowProps={setRowProps}
            annotation={chartData?.annotation?.dimensions}
            totalCount={totalCount}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            paginator={paginator}
            setPaginator={setPaginator}
          />
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};

export default ReadyToRouteJobs;
