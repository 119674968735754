import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

import PFButton from '../../../shared/PFPrime/PFButton';

const ApplyToAllDuration = ({ handleApplyToAll }) => {
  const [durationValue, setDurationValue] = useState();
  return (
    <div className="flex align-items-center w-full">
      <div className="p-inputgroup flex-1">
        <span className="p-float-label flex align-items-center">
          <InputNumber
            className="w-full p-inputtext-sm"
            inputClassName="border-noround-right"
            value={durationValue}
            onChange={e => setDurationValue(e.value)}
            min={0}
            id="in_minutes"
            name="in_minutes"
          />
          <label htmlFor="in_minutes">in minutes</label>
        </span>
        <PFButton
          icon="pi pi-check"
          iconPos="left"
          tooltip="Apply to all"
          tooltipOptions={{ position: 'top' }}
          onClick={() => handleApplyToAll(durationValue)}
        />
      </div>
    </div>
  );
};

export default ApplyToAllDuration;
