// **** React Imports ****
import React from 'react';

// **** Utilities ****
import {
  Grid,
  TextField,
  CircularProgress,
  Box,
  InputLabel,
  Button,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';

// **** Custom Components ****
import { parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';

import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import { getStatusOptions } from '../ProjectInfo/ProjectDetails.service';
import permissions from '../../../config/permissions';

import {
  getBillingData,
  createNewPaymentRequest,
  getPaymentType,
  deletePayment,
  editPaymentRequest,
  getQuickBooksData,
} from './Billing.service';

// **** Styles *****
import { useStyles } from './Billing.styles.js';

const MAX_VAL = 100000000;
const withValueLimit = ({ floatValue }) => {
  if (floatValue) {
    return floatValue < MAX_VAL;
  }
  return true;
};
const paymentRequestsColumns = [
  {
    id: 'payment_number',
    value: 'Payment Number',
  },
  {
    id: 'payment_status',
    value: 'Payment Status',
  },
  {
    id: 'payment_type',
    value: 'Payment Type',
  },
  {
    id: 'originating_po',
    value: 'Originating Labor PO',
  },
  {
    id: 'check_date',
    value: 'Check Date',
  },
  {
    id: 'check_number',
    value: 'Check Number',
  },
  {
    id: 'check_amount',
    value: 'Check Amount',
  },
  {
    id: 'requested_amount',
    value: 'Requested Amount',
  },
];

const vendorDebitColumns = [
  {
    id: 'fm_vd_notebox',
    value: 'FM VD Notebox',
  },
  {
    id: 'vd_closed_open',
    value: 'VD Closed/ Open',
  },
  {
    id: 'vd_status',
    value: 'VD Status',
  },
  {
    id: 'customer_name',
    value: 'Customer Name',
  },
  {
    id: 'originating_labor_po',
    value: 'Originating Labor PO',
  },
  {
    id: 'store_number',
    value: 'Store Number',
  },
  {
    id: 'status',
    value: 'Status',
  },
  {
    id: 'sub_status',
    value: 'Sub status',
  },
  {
    id: 'associate_pr_number',
    value: 'Associate PR #',
  },
  {
    id: 'associate_work_order',
    value: 'Associate Work Order #',
  },
  {
    id: 'description_of_issue',
    value: 'Description of Issue',
  },
  {
    id: 'debit_amount',
    value: 'Debit Amount',
  },
  {
    id: 'precentage_total',
    value: '% of Total',
  },
];

const Billing = ({ formik }) => {
  const classes = useStyles();
  const [billingResponse, setBillingResponse] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Payment Request',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [reloadPaymentList, setReloadPaymentList] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [paymentTypeOptions, setPaymentTypeOptions] = React.useState([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = React.useState([]);
  const { setAlert } = useAlerts();
  let paymentRequestsDisplayList = [],
    vendorDebitsDisplayList = [];

  React.useEffect(() => {
    if (formik?.values?.project_id || reloadPaymentList) {
      getBillingData(
        formik?.values?.project_id,
        setLoading,
        setBillingResponse
      );
      setReloadPaymentList(false);
    }
  }, [formik?.values?.project_id, reloadPaymentList]);
  React.useEffect(() => {
    getPaymentType(setPaymentTypeOptions);
    getStatusOptions('payment', setLoading, setPaymentStatusOptions);
  }, []);

  // **** Data Modification to Display *****
  if (billingResponse?.items?.length) {
    paymentRequestsDisplayList = billingResponse?.items.map(val => {
      return {
        payment_request_id: {
          value: val.payment_request_id,
        },
        payment_number: {
          value: val.payment_number,
        },
        payment_status_id: {
          value: val.payment_status_id,
        },
        payment_status: {
          value: val.status?.status,
        },
        payment_type: {
          value: val.payment_type,
        },
        originating_po: {
          value: val.originating_po,
        },
        check_date: {
          value:
            (val.check_date &&
              moment(val.check_date?.slice(0, 10)).format('MM-DD-YYYY')) ||
            null,
        },
        check_number: {
          value: val.check_number,
        },
        check_amount: {
          value:
            val.check_amount !== null
              ? '$' + parseFloat(val.check_amount).toFixed(2) || ''
              : '',
        },
        requested_amount: {
          value:
            val.requested_amount !== null
              ? '$' + parseFloat(val.requested_amount).toFixed(2) || ''
              : '',
        },
      };
    });
  }

  if (billingResponse?.vendor_debit_requests?.items?.length) {
    paymentRequestsDisplayList =
      billingResponse?.vendor_debit_requests?.items.map(val => {
        return {
          date_created: {
            value: moment(val.created_at).format('MM-DD-YYYY hh:mm A'),
          },
          date_activity_created: {
            value: moment(val.activity_created_date).format('YYYY-MM-DD'),
          },
          activity_type: {
            value: val.activity_type,
          },
          activity_due_date: {
            value: moment(val.activity_due_date).format('YYYY-MM-DD'),
          },
          job_number: {
            value: moment(val.created_at).format('YYYY-MM-DD'),
          },
          status: {
            value: val.activity_status,
          },
        };
      });
  }
  const viewEditPaymentRequest = (action, index) => {
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Payment Request') || 'Edit Payment Request',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };
  const deletePaymentRequest = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete Payment Request ?',
      header: 'Delete Payment Request',
    });
  };
  const onConfirmDialog = () => {
    deletePayment(
      formik?.values?.project_id,
      paymentRequestsDisplayList[rowIndex]?.payment_request_id?.value,
      setLoading,
      setAlert,
      setReloadPaymentList,
      setConfirmDialog
    );
  };
  //additionalCharge ValidationSchema
  const PaymentValidationSchema = Yup.object().shape({
    payment_number: Yup.string().required(),
    payment_status_id: Yup.number().required(),
    payment_type: Yup.string().trim().required(),
    originating_po: Yup.number()
      .typeError('Originating PO must be a number')
      .min(1)
      .positive()
      .integer()
      .nullable(true),
    check_number: Yup.number()
      .typeError('Check Number must be a number')
      .min(1)
      .positive()
      .integer()
      .nullable(true),
    check_amount: Yup.number()
      .typeError('Check Amount must be a number')
      .min(1)
      .max(
        99999999.99,
        'Check Amount must be less than or equal to 99999999.99'
      )
      .nullable(true),
    requested_amount: Yup.number()
      .typeError('Requested Amount must be a number.')
      .min(1)
      .max(
        99999999.99,
        'Check Amount must be less than or equal to 99999999.99'
      )
      .required('Requested Amount is a required field.'),
  });

  // **** Formik Form Values ****
  const paymentFormik = useFormik({
    initialValues: {
      payment_number:
        (formAction !== 'add' &&
          paymentRequestsDisplayList[rowIndex]?.payment_number.value) ||
        null,
      payment_status_id:
        (formAction !== 'add' &&
          paymentRequestsDisplayList[rowIndex]?.payment_status_id.value) ||
        '',
      payment_type:
        (formAction !== 'add' &&
          paymentRequestsDisplayList[rowIndex]?.payment_type.value) ||
        '',
      originating_po:
        (formAction !== 'add' &&
          paymentRequestsDisplayList[rowIndex]?.originating_po.value) ||
        null ||
        null,
      check_date:
        (formAction !== 'add' &&
          ((paymentRequestsDisplayList[rowIndex]?.check_date.value &&
            moment(
              paymentRequestsDisplayList[rowIndex]?.check_date.value
            ).format('YYYY-MM-DD')) ||
            null)) ||
        null,
      check_number:
        (formAction !== 'add' &&
          (paymentRequestsDisplayList[rowIndex]?.check_number.value || null)) ||
        null,
      check_amount:
        (formAction !== 'add' &&
          (Number(
            paymentRequestsDisplayList[rowIndex]?.check_amount.value.replace(
              '$',
              ''
            )
          ) ||
            null)) ||
        null,
      requested_amount:
        (formAction !== 'add' &&
          Number(
            paymentRequestsDisplayList[
              rowIndex
            ]?.requested_amount.value.replace('$', '')
          )) ||
        '',
    },
    onSubmit: values => {
      if (formAction === 'add') {
        createNewPaymentRequest(
          formik?.values?.project_id,
          values,
          setLoading,
          setIsOpen,
          setReloadPaymentList,
          setAlert
        );
      } else {
        editPaymentRequest(
          formik?.values?.project_id,
          paymentRequestsDisplayList[rowIndex]?.payment_request_id?.value,
          values,
          setLoading,
          setIsOpen,
          setReloadPaymentList,
          setAlert
        );
      }
    },
    validationSchema: PaymentValidationSchema,
    enableReinitialize: true,
  });

  const getBillingViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <TextField
            id="payment_number"
            label="Payment Number"
            name="payment_number"
            inputProps={{ maxLength: 15 }}
            onChange={paymentFormik.handleChange}
            onBlur={paymentFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            required={formAction !== 'view'}
            disabled={formAction === 'view'}
            value={paymentFormik?.values?.payment_number}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            name="payment_status_id"
            options={paymentStatusOptions || []}
            onBlur={paymentFormik?.handleBlur}
            onChange={(event, value) => {
              if (value) {
                paymentFormik.setFieldValue(
                  'payment_status_id',
                  value?.status_id
                );
              } else {
                paymentFormik.setFieldValue('payment_status_id', '');
              }
            }}
            getOptionLabel={option => option && option.status}
            renderInput={params => (
              <TextField
                {...params}
                label="Payment Status"
                required={formAction !== 'view'}
                InputLabelProps={{ shrink: true }}
              />
            )}
            value={
              paymentStatusOptions.find(
                paymentStatus =>
                  paymentStatus.status_id ===
                  paymentFormik?.values?.payment_status_id
              ) || ''
            }
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            name="payment_type"
            required={formAction !== 'view'}
            options={paymentTypeOptions || []}
            onBlur={paymentFormik?.handleBlur}
            onChange={(event, value) =>
              paymentFormik.setFieldValue('payment_type', value?.payment_type)
            }
            getOptionLabel={option => option && option.payment_type}
            renderInput={params => (
              <TextField
                {...params}
                label="Payment Type"
                InputLabelProps={{ shrink: true }}
                required={formAction !== 'view'}
              />
            )}
            value={
              paymentTypeOptions.find(
                paymentType =>
                  paymentType.payment_type ===
                  paymentFormik?.values?.payment_type
              ) || ''
            }
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <NumberFormat
            id="originating_po"
            label="Originating Labor PO"
            name="originating_po"
            inputProps={{ maxLength: 15 }}
            allowNegative={false}
            decimalScale={0}
            customInput={TextField}
            inputmode="numeric"
            onChange={paymentFormik.handleChange}
            onBlur={paymentFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={paymentFormik?.values?.originating_po}
            InputLabelProps={{ shrink: true }}
            error={
              paymentFormik.touched.originating_po &&
              paymentFormik.errors.originating_po
            }
            helperText={
              paymentFormik.touched.originating_po &&
              paymentFormik.errors.originating_po &&
              'Originating PO must be a number'
            }
          />
        </Grid>
        <Grid item xs={12} className="mui-calendar">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="check_date"
              name="check_date"
              variant="inline"
              inputProps={{ autoComplete: 'off' }}
              label="Check Date"
              invalidDateMessage="Invalid Date"
              format="MM-dd-yyyy"
              value={
                (paymentFormik?.values?.check_date &&
                  paymentFormik?.values?.check_date?.split('T')?.length &&
                  parseISO(paymentFormik?.values?.check_date?.slice(0, 10))) ||
                null
              }
              onChange={(event, value) => {
                paymentFormik.setFieldValue(
                  'check_date',
                  `${moment(event).format('YYYY-MM-DD')}T00:00:00.000Z`
                );
              }}
              onBlur={paymentFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <NumberFormat
            id="check_number"
            label="Check Number"
            name="check_number"
            onChange={paymentFormik.handleChange}
            onBlur={paymentFormik.handleBlur}
            inputProps={{ maxLength: 15 }}
            allowNegative={false}
            decimalScale={0}
            customInput={TextField}
            inputmode="numeric"
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={paymentFormik?.values?.check_number}
            InputLabelProps={{ shrink: true }}
            error={
              paymentFormik.touched.check_number &&
              paymentFormik.errors.check_number
            }
            helperText={
              paymentFormik.touched.check_number &&
              paymentFormik.errors.check_number &&
              'Check Number must be a number'
            }
          />
        </Grid>
        <Grid item>
          <InputLabel shrink htmlFor="check_amount">
            Check Amount
          </InputLabel>
          <NumberFormat
            id="check_amount"
            name="check_amount"
            onChange={event => {
              const value = event.target.value;
              const check_amount_value = parseFloat(value.replace('$', ''));
              paymentFormik.setFieldValue('check_amount', check_amount_value);
              paymentFormik.handleChange;
            }}
            onBlur={event => {
              const value = event.target.value;
              const check_amount_value = parseFloat(value.replace('$', ''));
              paymentFormik.setFieldValue('check_amount', check_amount_value);
              paymentFormik.handleBlur;
            }}
            inputProps={{ maxLength: 15 }}
            allowNegative={false}
            prefix="$"
            decimalScale={2}
            customInput={TextField}
            inputmode="numeric"
            disabled={formAction === 'view'}
            value={paymentFormik?.values?.check_amount}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            isAllowed={withValueLimit}
            error={
              paymentFormik.touched.check_amount &&
              paymentFormik.errors.check_amount
            }
            helperText={
              paymentFormik.touched.check_amount &&
              paymentFormik.errors.check_amount
            }
          />
        </Grid>

        <Grid item>
          <InputLabel
            shrink
            htmlFor="requested-amount"
            required={formAction != 'view'}
          >
            Requested Amount
          </InputLabel>
          <NumberFormat
            id="requested-amount"
            name="requested_amount"
            onChange={event => {
              const value = event.target.value;
              const requested_amount_value = parseFloat(value.replace('$', ''));
              paymentFormik.setFieldValue(
                'requested_amount',
                requested_amount_value
              );
              paymentFormik.handleChange;
            }}
            isAllowed={withValueLimit}
            onBlur={event => {
              const value = event.target.value;
              const requested_amount_value = parseFloat(value.replace('$', ''));
              paymentFormik.setFieldValue(
                'requested_amount',
                requested_amount_value
              );
              paymentFormik.handleBlur;
            }}
            inputProps={{ maxLength: 15 }}
            allowNegative={false}
            prefix="$"
            decimalScale={2}
            customInput={TextField}
            inputmode="numeric"
            disabled={formAction === 'view'}
            value={paymentFormik?.values?.requested_amount || null}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            error={paymentFormik.errors.requested_amount}
            helperText={paymentFormik.errors.requested_amount}
            required
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )) || (
        <Grid container spacing={2} direction="column">
          <Grid item></Grid>
          <Grid item>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {/* Check Date */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Check Date"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    paymentRequestsDisplayList[0]?.check_number?.value
                      ? paymentRequestsDisplayList[0]?.check_date?.value
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              {/* Check Number */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Check Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    paymentRequestsDisplayList[0]?.check_number?.value
                      ? paymentRequestsDisplayList[0]?.check_number?.value
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              {/* Check Amount */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Check Amount"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    paymentRequestsDisplayList[0]?.check_number?.value
                      ? paymentRequestsDisplayList[0]?.check_amount?.value
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              {/* Total Labor Amount */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Total Labor Amount"
                  onChange={event => {}}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    Array.isArray(billingResponse?.items) &&
                    billingResponse?.items.length &&
                    billingResponse?.items[0]?.project?.total_labor_amount
                      ? `$ ${billingResponse?.items[0]?.project?.total_labor_amount}`
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Bill Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={billingResponse?.quickbook?.bill_number || ''}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Bill Transaction ID"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={billingResponse?.quickbook?.bill_txn_id || ''}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                />
              </Box>
              {/* QuickBooks Invoice Number */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Invoice Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    billingResponse &&
                    billingResponse?.quickbook?.quickbooks_invoice_number
                      ? `${billingResponse?.quickbook?.quickbooks_invoice_number}`
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              {/* QuickBooks Invoice Transaction ID */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Invoice Transaction ID"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    billingResponse &&
                    billingResponse?.quickbook
                      ?.quickbooks_invoice_transaction_id
                      ? `${billingResponse?.quickbook?.quickbooks_invoice_transaction_id}`
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              {/* Quickbooks Payment Transaction ID */}
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  label="Payment Transaction ID"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    billingResponse &&
                    billingResponse?.quickbook
                      ?.quickbooks_payment_transaction_id
                      ? `${billingResponse?.quickbook?.quickbooks_payment_transaction_id}`
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box className={classes.buttonBox} m={1}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    getQuickBooksData(
                      formik?.values?.project_id,
                      setLoading,
                      setAlert,
                      setReloadPaymentList,
                      setConfirmDialog
                    );
                    setReloadPaymentList(false);
                  }}
                  disabled={
                    billingResponse?.quickbook
                      ?.quickbooks_payment_transaction_id != null
                  }
                >
                  Generate Payment
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item classes={{ root: classes.tableWrapper }}>
            <GenericTable
              title="Payment Requests"
              columnData={paymentRequestsColumns}
              rowData={paymentRequestsDisplayList}
              showActions={{ view: true, edit: true, delete: true }}
              headerLinks={[
                {
                  add: permissions?.viewEditProject?.tabBillingAdd,
                  label: 'Add Item',
                  handler: () => {
                    setFormAction('add');
                    paymentFormik.resetForm();
                    setDialogSettings(prevState => ({
                      ...prevState,
                      title: 'Add Payment Request',
                    }));
                    setIsOpen(true);
                  },
                },
              ]}
              handleView={index => viewEditPaymentRequest('view', index)}
              handleEdit={index => viewEditPaymentRequest('edit', index)}
              handleDelete={index => deletePaymentRequest(index)}
              permissions={{
                view: permissions?.viewEditProject?.tabBilling,
                edit: permissions?.viewEditProject?.tabBillingModify,
                delete: permissions?.viewEditProject?.tabBillingDelete,
              }}
            />
          </Grid>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              paymentFormik?.handleReset();
            }}
            handleSave={paymentFormik.handleSubmit}
            dialogSettings={dialogSettings}
            disabledButton2={!paymentFormik?.dirty || !paymentFormik?.isValid}
            disabledButton1={false}
          >
            <form>{getBillingViewEditForm()}</form>
          </GenericDialog>
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />

          <Grid item classes={{ root: classes.tableWrapper }}>
            <GenericTable
              title="Vendor Debits"
              columnData={vendorDebitColumns}
              rowData={vendorDebitsDisplayList}
              showActions={{ view: false, edit: false }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Billing;
