import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const createCategory = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `/api/retainage/category/create`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createType = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `/api/retainage/type/create`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchCategory = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/retainage/category/list`,
      query,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchType = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/retainage/type/list`,
      query,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createRetainage = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage/create`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const editRetainage = async (userId, body) => {
  try {
    const response = await ApiService.putWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRetainage = async userId => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage`,
      null,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchPaymentCategory = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/retainage/payout/category/list`,
      query,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createPaymentCategory = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `/api/retainage/payout/category/create`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createRetainagePayout = async (userId, retainageId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage/${retainageId}/payout/create`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};
export const updateRetainagePayout = async (
  userId,
  retainageId,
  payoutId,
  body
) => {
  try {
    const response = await ApiService.putWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage/${retainageId}/payout/${payoutId}`,
      body,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRetainagePayout = async (
  userId,
  retainageId,
  urlParams = ''
) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.RETAINAGE.get}/${userId}/retainage/${retainageId}/payout/list`,
      urlParams,
      URL_CONSTANTS.RETAINAGE.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
