import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  credentialsData: [],
  credentialsTypeList: [],
  insuranceTypeList: [],
  isFileUploading: false,
};

const userFormCredentialsSlice = createSlice({
  name: 'userFormCredentials',
  initialState: initialState,
  reducers: {
    setUserCredentialsForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.credentialsData = payload.hasOwnProperty('credentialsData')
        ? payload.credentialsData
        : state.credentialsData;
      state.credentialsTypeList = payload.hasOwnProperty('credentialsTypeList')
        ? payload.credentialsTypeList
        : state.credentialsTypeList;
      state.insuranceTypeList = payload.hasOwnProperty('insuranceTypeList')
        ? payload.insuranceTypeList
        : state.insuranceTypeList;
      state.isFileUploading = payload.hasOwnProperty('isFileUploading')
        ? payload.isFileUploading
        : state.isFileUploading;
    },
  },
});

export const { setUserCredentialsForm } = userFormCredentialsSlice.actions;

export const userFormCredentialsReducer = userFormCredentialsSlice.reducer;
