import React from 'react';
import { DataTable, Column, Skeleton } from 'primereact';

const TableSkeleton = () => {
  const bodyTemplate = () => <Skeleton />;
  const items = Array.from({ length: 8 }, (v, i) => i);
  return (
    <DataTable value={items} className="p-datatable-striped">
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
      <Column style={{ width: '25%' }} body={bodyTemplate}></Column>
    </DataTable>
  );
};

export default TableSkeleton;
