import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const InstallerOnLeaveDialog = ({
  isOpen,
  isBlockedTimeDialog,
  handleCancel,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Do you want to add technician?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isBlockedTimeDialog
            ? `This slot is already blocked for the technician, Do you still want to assign the job?`
            : `Selected Technician has applied for leave between the selected Scheduled Date and Project
          end date.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCancel()}
          color="primary"
          variant="outlined"
        >
          Disagree
        </Button>
        <Button
          onClick={() => handleSubmit()}
          color="primary"
          variant="contained"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InstallerOnLeaveDialog;
