export const PrimeReactIcons = [
  // { name: 'pi pi-address-book', label: 'address-book' },
  { name: 'pi pi-align-center', label: 'align-center' },
  { name: 'pi pi-align-justify', label: 'align-justify' },
  { name: 'pi pi-align-left', label: 'align-left' },
  { name: 'pi pi-align-right', label: 'align-right' },
  { name: 'pi pi-amazon', label: 'amazon' },
  { name: 'pi pi-android', label: 'android' },
  { name: 'pi pi-angle-double-down', label: 'angle-double-down' },
  { name: 'pi pi-angle-double-left', label: 'angle-double-left' },
  { name: 'pi pi-angle-double-right', label: 'angle-double-right' },
  { name: 'pi pi-angle-double-up', label: 'angle-double-up' },
  { name: 'pi pi-angle-down', label: 'angle-down' },
  { name: 'pi pi-angle-left', label: 'angle-left' },
  { name: 'pi pi-angle-right', label: 'angle-right' },
  { name: 'pi pi-angle-up', label: 'angle-up' },
  { name: 'pi pi-apple', label: 'apple' },
  { name: 'pi pi-arrow-circle-down', label: 'arrow-circle-down' },
  { name: 'pi pi-arrow-circle-left', label: 'arrow-circle-left' },
  { name: 'pi pi-arrow-circle-right', label: 'arrow-circle-right' },
  { name: 'pi pi-arrow-circle-up', label: 'arrow-circle-up' },
  { name: 'pi pi-arrow-down', label: 'arrow-down' },
  { name: 'pi pi-arrow-down-left', label: 'arrow-down-left' },
  // { name: 'pi pi-arrow-down-left-and-arrow-up-right-to-center', label: 'arrow-down-left-and-arrow-up-right-to-center' },
  { name: 'pi pi-arrow-down-right', label: 'arrow-down-right' },
  { name: 'pi pi-arrow-left', label: 'arrow-left' },
  { name: 'pi pi-arrow-right', label: 'arrow-right' },
  { name: 'pi pi-arrow-right-arrow-left', label: 'arrow-right-arrow-left' },
  { name: 'pi pi-arrow-up', label: 'arrow-up' },
  { name: 'pi pi-arrow-up-left', label: 'arrow-up-left' },
  { name: 'pi pi-arrow-up-right', label: 'arrow-up-right' },
  // { name: 'pi pi-arrow-up-right-and-arrow-down-left-from-center', label: 'arrow-up-right-and-arrow-down-left-from-center' },
  { name: 'pi pi-arrows-alt', label: 'arrows-alt' },
  { name: 'pi pi-arrows-h', label: 'arrows-h' },
  { name: 'pi pi-arrows-v', label: 'arrows-v' },
  // { name: 'pi pi-asterisk', label: 'asterisk' },
  { name: 'pi pi-at', label: 'at' },
  { name: 'pi pi-backward', label: 'backward' },
  { name: 'pi pi-ban', label: 'ban' },
  // { name: 'pi pi-barcode', label: 'barcode' },
  { name: 'pi pi-bars', label: 'bars' },
  { name: 'pi pi-bell', label: 'Bell' },
  // { name: 'pi pi-bell-slash', label: 'bell-slash' },
  { name: 'pi pi-bitcoin', label: 'bitcoin' },
  { name: 'pi pi-bolt', label: 'Bolt' },
  { name: 'pi pi-book', label: 'book' },
  { name: 'pi pi-bookmark', label: 'bookmark' },
  { name: 'pi pi-bookmark-fill', label: 'bookmark-fill' },
  { name: 'pi pi-box', label: 'box' },
  { name: 'pi pi-briefcase', label: 'Briefcase' },
  { name: 'pi pi-building', label: 'building' },
  // { name: 'pi pi-building-columns', label: 'building-columns' },
  // { name: 'pi pi-bullseye', label: 'bullseye' },
  { name: 'pi pi-calculator', label: 'calculator' },
  { name: 'pi pi-calendar', label: 'Calendar' },
  // { name: 'pi pi-calendar-clock', label: 'calendar-clock' },
  { name: 'pi pi-calendar-minus', label: 'Calendar Minus' },
  { name: 'pi pi-calendar-plus', label: 'Calendar Plus' },
  { name: 'pi pi-calendar-times', label: 'Calendar Times' },
  { name: 'pi pi-camera', label: 'camera' },
  { name: 'pi pi-car', label: 'car' },
  { name: 'pi pi-caret-down', label: 'caret-down' },
  { name: 'pi pi-caret-left', label: 'caret-left' },
  { name: 'pi pi-caret-right', label: 'caret-right' },
  { name: 'pi pi-caret-up', label: 'caret-up' },
  // { name: 'pi pi-cart-arrow-down', label: 'cart-arrow-down' },
  // { name: 'pi pi-cart-minus', label: 'cart-minus' },
  { name: 'pi pi-cart-plus', label: 'cart-plus' },
  { name: 'pi pi-chart-bar', label: 'chart-bar' },
  { name: 'pi pi-chart-line', label: 'chart-line' },
  { name: 'pi pi-chart-pie', label: 'Chart Pie' },
  // { name: 'pi pi-chart-scatter', label: 'chart-scatter' },
  { name: 'pi pi-check', label: 'check' },
  { name: 'pi pi-check-circle', label: 'check-circle' },
  { name: 'pi pi-check-square', label: 'check-square' },
  { name: 'pi pi-chevron-circle-down', label: 'chevron-circle-down' },
  { name: 'pi pi-chevron-circle-left', label: 'chevron-circle-left' },
  { name: 'pi pi-chevron-circle-right', label: 'chevron-circle-right' },
  { name: 'pi pi-chevron-circle-up', label: 'chevron-circle-up' },
  { name: 'pi pi-chevron-down', label: 'chevron-down' },
  { name: 'pi pi-chevron-left', label: 'chevron-left' },
  { name: 'pi pi-chevron-right', label: 'chevron-right' },
  { name: 'pi pi-chevron-up', label: 'chevron-up' },
  { name: 'pi pi-circle', label: 'circle' },
  { name: 'pi pi-circle-fill', label: 'circle-fill' },
  // { name: 'pi pi-clipboard', label: 'clipboard' },
  { name: 'pi pi-clock', label: 'Clock' },
  { name: 'pi pi-clone', label: 'clone' },
  { name: 'pi pi-cloud', label: 'cloud' },
  { name: 'pi pi-cloud-download', label: 'cloud-download' },
  { name: 'pi pi-cloud-upload', label: 'cloud-upload' },
  { name: 'pi pi-code', label: 'code' },
  { name: 'pi pi-cog', label: 'cog' },
  { name: 'pi pi-comment', label: 'comment' },
  { name: 'pi pi-comments', label: 'comments' },
  { name: 'pi pi-compass', label: 'compass' },
  { name: 'pi pi-copy', label: 'copy' },
  { name: 'pi pi-credit-card', label: 'credit-card' },
  // { name: 'pi pi-crown', label: 'crown' },
  { name: 'pi pi-database', label: 'database' },
  { name: 'pi pi-delete-left', label: 'delete-left' },
  { name: 'pi pi-desktop', label: 'desktop' },
  { name: 'pi pi-directions', label: 'directions' },
  { name: 'pi pi-directions-alt', label: 'directions-alt' },
  { name: 'pi pi-discord', label: 'discord' },
  { name: 'pi pi-dollar', label: 'dollar' },
  { name: 'pi pi-download', label: 'download' },
  { name: 'pi pi-eject', label: 'eject' },
  { name: 'pi pi-ellipsis-h', label: 'ellipsis-h' },
  { name: 'pi pi-ellipsis-v', label: 'ellipsis-v' },
  { name: 'pi pi-envelope', label: 'Envelope' },
  // { name: 'pi pi-equals', label: 'equals' },
  { name: 'pi pi-eraser', label: 'eraser' },
  // { name: 'pi pi-ethereum', label: 'ethereum' },
  { name: 'pi pi-euro', label: 'euro' },
  { name: 'pi pi-exclamation-circle', label: 'exclamation-circle' },
  { name: 'pi pi-exclamation-triangle', label: 'exclamation-triangle' },
  // { name: 'pi pi-expand', label: 'expand' },
  { name: 'pi pi-external-link', label: 'external-link' },
  { name: 'pi pi-eye', label: 'eye' },
  { name: 'pi pi-eye-slash', label: 'eye-slash' },
  // { name: 'pi pi-face-smile', label: 'face-smile' },
  { name: 'pi pi-facebook', label: 'facebook' },
  { name: 'pi pi-fast-backward', label: 'fast-backward' },
  { name: 'pi pi-fast-forward', label: 'fast-forward' },
  { name: 'pi pi-file', label: 'file' },
  // { name: 'pi pi-file-arrow-up', label: 'file-arrow-up' },
  // { name: 'pi pi-file-check', label: 'file-check' },
  { name: 'pi pi-file-edit', label: 'file-edit' },
  { name: 'pi pi-file-excel', label: 'file-excel' },
  { name: 'pi pi-file-export', label: 'file-export' },
  { name: 'pi pi-file-import', label: 'file-import' },
  { name: 'pi pi-file-pdf', label: 'file-pdf' },
  // { name: 'pi pi-file-plus', label: 'file-plus' },
  { name: 'pi pi-file-word', label: 'file-word' },
  { name: 'pi pi-filter', label: 'filter' },
  { name: 'pi pi-filter-fill', label: 'filter-fill' },
  { name: 'pi pi-filter-slash', label: 'filter-slash' },
  { name: 'pi pi-flag', label: 'Flag' },
  { name: 'pi pi-flag-fill', label: 'flag-fill' },
  { name: 'pi pi-folder', label: 'folder' },
  { name: 'pi pi-folder-open', label: 'folder-open' },
  // { name: 'pi pi-folder-plus', label: 'folder-plus' },
  { name: 'pi pi-forward', label: 'forward' },
  // { name: 'pi pi-gauge', label: 'gauge' },
  { name: 'pi pi-gift', label: 'gift' },
  { name: 'pi pi-github', label: 'github' },
  { name: 'pi pi-globe', label: 'globe' },
  { name: 'pi pi-google', label: 'google' },
  // { name: 'pi pi-graduation-cap', label: 'graduation-cap' },
  // { name: 'pi pi-hammer', label: 'hammer' },
  { name: 'pi pi-hashtag', label: 'hashtag' },
  // { name: 'pi pi-headphones', label: 'headphones' },
  { name: 'pi pi-heart', label: 'heart' },
  { name: 'pi pi-heart-fill', label: 'heart-fill' },
  { name: 'pi pi-history', label: 'history' },
  { name: 'pi pi-home', label: 'home' },
  { name: 'pi pi-hourglass', label: 'hourglass' },
  { name: 'pi pi-id-card', label: 'id-card' },
  { name: 'pi pi-image', label: 'image' },
  { name: 'pi pi-images', label: 'images' },
  { name: 'pi pi-inbox', label: 'inbox' },
  // { name: 'pi pi-indian-rupee', label: 'indian-rupee' },
  { name: 'pi pi-info', label: 'info' },
  { name: 'pi pi-info-circle', label: 'Info Circle' },
  { name: 'pi pi-instagram', label: 'instagram' },
  { name: 'pi pi-key', label: 'key' },
  { name: 'pi pi-language', label: 'language' },
  // { name: 'pi pi-lightbulb', label: 'lightbulb' },
  { name: 'pi pi-link', label: 'link' },
  { name: 'pi pi-linkedin', label: 'linkedin' },
  { name: 'pi pi-list', label: 'list' },
  // { name: 'pi pi-list-check', label: 'list-check' },
  { name: 'pi pi-lock', label: 'Lock' },
  { name: 'pi pi-lock-open', label: 'lock-open' },
  { name: 'pi pi-map', label: 'Map' },
  { name: 'pi pi-map-marker', label: 'map-marker' },
  // { name: 'pi pi-mars', label: 'mars' },
  { name: 'pi pi-megaphone', label: 'megaphone' },
  // { name: 'pi pi-microchip', label: 'microchip' },
  // { name: 'pi pi-microchip-ai', label: 'microchip-ai' },
  { name: 'pi pi-microphone', label: 'microphone' },
  { name: 'pi pi-microsoft', label: 'microsoft' },
  { name: 'pi pi-minus', label: 'minus' },
  { name: 'pi pi-minus-circle', label: 'minus-circle' },
  { name: 'pi pi-mobile', label: 'mobile' },
  { name: 'pi pi-money-bill', label: 'money-bill' },
  { name: 'pi pi-moon', label: 'moon' },
  { name: 'pi pi-objects-column', label: 'objects-column' },
  { name: 'pi pi-palette', label: 'palette' },
  { name: 'pi pi-paperclip', label: 'paperclip' },
  { name: 'pi pi-pause', label: 'pause' },
  // { name: 'pi pi-pause-circle', label: 'pause-circle' },
  { name: 'pi pi-paypal', label: 'paypal' },
  // { name: 'pi pi-pen-to-square', label: 'pen-to-square' },
  { name: 'pi pi-pencil', label: 'pencil' },
  { name: 'pi pi-percentage', label: 'percentage' },
  { name: 'pi pi-phone', label: 'Phone' },
  // { name: 'pi pi-pinterest', label: 'pinterest' },
  { name: 'pi pi-play', label: 'play' },
  // { name: 'pi pi-play-circle', label: 'play-circle' },
  { name: 'pi pi-plus', label: 'plus' },
  { name: 'pi pi-plus-circle', label: 'plus-circle' },
  { name: 'pi pi-pound', label: 'pound' },
  { name: 'pi pi-power-off', label: 'power-off' },
  { name: 'pi pi-prime', label: 'prime' },
  { name: 'pi pi-print', label: 'print' },
  { name: 'pi pi-qrcode', label: 'qrcode' },
  { name: 'pi pi-question', label: 'question' },
  { name: 'pi pi-question-circle', label: 'question-circle' },
  // { name: 'pi pi-receipt', label: 'receipt' },
  { name: 'pi pi-reddit', label: 'reddit' },
  { name: 'pi pi-refresh', label: 'refresh' },
  { name: 'pi pi-replay', label: 'replay' },
  { name: 'pi pi-reply', label: 'reply' },
  { name: 'pi pi-save', label: 'save' },
  { name: 'pi pi-search', label: 'search' },
  { name: 'pi pi-search-minus', label: 'search-minus' },
  { name: 'pi pi-search-plus', label: 'search-plus' },
  { name: 'pi pi-send', label: 'send' },
  { name: 'pi pi-server', label: 'server' },
  { name: 'pi pi-share-alt', label: 'share-alt' },
  { name: 'pi pi-shield', label: 'shield' },
  // { name: 'pi pi-shop', label: 'shop' },
  { name: 'pi pi-shopping-bag', label: 'shopping-bag' },
  { name: 'pi pi-shopping-cart', label: 'shopping-cart' },
  { name: 'pi pi-sign-in', label: 'sign-in' },
  { name: 'pi pi-sign-out', label: 'sign-out' },
  { name: 'pi pi-sitemap', label: 'sitemap' },
  { name: 'pi pi-slack', label: 'slack' },
  { name: 'pi pi-sliders-h', label: 'sliders-h' },
  { name: 'pi pi-sliders-v', label: 'sliders-v' },
  { name: 'pi pi-sort', label: 'sort' },
  { name: 'pi pi-sort-alpha-down', label: 'sort-alpha-down' },
  { name: 'pi pi-sort-alpha-down-alt', label: 'sort-alpha-down-alt' },
  { name: 'pi pi-sort-alpha-up', label: 'sort-alpha-up' },
  { name: 'pi pi-sort-alpha-up-alt', label: 'sort-alpha-up-alt' },
  { name: 'pi pi-sort-alt', label: 'sort-alt' },
  { name: 'pi pi-sort-alt-slash', label: 'sort-alt-slash' },
  { name: 'pi pi-sort-amount-down', label: 'sort-amount-down' },
  { name: 'pi pi-sort-amount-down-alt', label: 'sort-amount-down-alt' },
  { name: 'pi pi-sort-amount-up', label: 'sort-amount-up' },
  { name: 'pi pi-sort-amount-up-alt', label: 'sort-amount-up-alt' },
  // { name: 'pi pi-sort-down', label: 'sort-down' },
  // { name: 'pi pi-sort-down-fill', label: 'sort-down-fill' },
  { name: 'pi pi-sort-numeric-down', label: 'sort-numeric-down' },
  { name: 'pi pi-sort-numeric-down-alt', label: 'sort-numeric-down-alt' },
  { name: 'pi pi-sort-numeric-up', label: 'sort-numeric-up' },
  { name: 'pi pi-sort-numeric-up-alt', label: 'sort-numeric-up-alt' },
  { name: 'pi pi-sort-up', label: 'sort-up' },
  // { name: 'pi pi-sort-up-fill', label: 'sort-up-fill' },
  // { name: 'pi pi-sparkles', label: 'sparkles' },
  { name: 'pi pi-spinner', label: 'spinner' },
  // { name: 'pi pi-spinner-dotted', label: 'spinner-dotted' },
  { name: 'pi pi-star', label: 'Star' },
  { name: 'pi pi-star-fill', label: 'star-fill' },
  // { name: 'pi pi-star-half', label: 'star-half' },
  // { name: 'pi pi-star-half-fill', label: 'star-half-fill' },
  { name: 'pi pi-step-backward', label: 'step-backward' },
  { name: 'pi pi-step-backward-alt', label: 'step-backward-alt' },
  { name: 'pi pi-step-forward', label: 'step-forward' },
  { name: 'pi pi-step-forward-alt', label: 'step-forward-alt' },
  { name: 'pi pi-stop', label: 'stop' },
  { name: 'pi pi-stop-circle', label: 'stop-circle' },
  { name: 'pi pi-stopwatch', label: 'Stopwatch' },
  { name: 'pi pi-sun', label: 'sun' },
  { name: 'pi pi-sync', label: 'sync' },
  { name: 'pi pi-table', label: 'table' },
  { name: 'pi pi-tablet', label: 'tablet' },
  { name: 'pi pi-tag', label: 'tag' },
  { name: 'pi pi-tags', label: 'tags' },
  { name: 'pi pi-telegram', label: 'telegram' },
  { name: 'pi pi-th-large', label: 'th-large' },
  { name: 'pi pi-thumbs-down', label: 'thumbs-down' },
  { name: 'pi pi-thumbs-down-fill', label: 'thumbs-down-fill' },
  { name: 'pi pi-thumbs-up', label: 'thumbs-up' },
  { name: 'pi pi-thumbs-up-fill', label: 'thumbs-up-fill' },
  // { name: 'pi pi-thumbtack', label: 'thumbtack' },
  { name: 'pi pi-ticket', label: 'ticket' },
  // { name: 'pi pi-tiktok', label: 'tiktok' },
  { name: 'pi pi-times', label: 'times' },
  { name: 'pi pi-times-circle', label: 'times-circle' },
  { name: 'pi pi-trash', label: 'trash' },
  // { name: 'pi pi-trophy', label: 'trophy' },
  { name: 'pi pi-truck', label: 'Truck' },
  // { name: 'pi pi-turkish-lira', label: 'turkish-lira' },
  // { name: 'pi pi-twitch', label: 'twitch' },
  { name: 'pi pi-twitter', label: 'twitter' },
  { name: 'pi pi-undo', label: 'undo' },
  { name: 'pi pi-unlock', label: 'unlock' },
  { name: 'pi pi-upload', label: 'upload' },
  { name: 'pi pi-user', label: 'user' },
  { name: 'pi pi-user-edit', label: 'user-edit' },
  { name: 'pi pi-user-minus', label: 'user-minus' },
  { name: 'pi pi-user-plus', label: 'user-plus' },
  { name: 'pi pi-users', label: 'users' },
  // { name: 'pi pi-venus', label: 'venus' },
  { name: 'pi pi-verified', label: 'verified' },
  { name: 'pi pi-video', label: 'video' },
  { name: 'pi pi-vimeo', label: 'vimeo' },
  { name: 'pi pi-volume-down', label: 'volume-down' },
  { name: 'pi pi-volume-off', label: 'volume-off' },
  { name: 'pi pi-volume-up', label: 'volume-up' },
  { name: 'pi pi-wallet', label: 'wallet' },
  // { name: 'pi pi-warehouse', label: 'warehouse' },
  // { name: 'pi pi-wave-pulse', label: 'wave-pulse' },
  { name: 'pi pi-whatsapp', label: 'whatsapp' },
  { name: 'pi pi-wifi', label: 'wifi' },
  { name: 'pi pi-window-maximize', label: 'window-maximize' },
  { name: 'pi pi-window-minimize', label: 'window-minimize' },
  { name: 'pi pi-wrench', label: 'Wrench' },
  { name: 'pi pi-youtube', label: 'youtube' },
];
