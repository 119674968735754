import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

const _BASE_URL = URL_CONSTANTS.INVENTORY.baseUrl;
export const apiService = {
  get: async url => {
    const response = await axios.get(_BASE_URL + url);
    return response.data;
  },

  post: async (url, payload) => {
    const response = await axios.post(_BASE_URL + url, payload);
    return response.data;
  },

  patch: async (url, id, payload) => {
    const response = await axios.patch(_BASE_URL + url, payload);
    return response.data;
  },
};
