import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Dropdown, Button } from 'primereact';
import { Skeleton } from 'primereact/skeleton';
import dayjs from 'dayjs';
import _ from 'lodash';

import { domHandlerCall } from '../user-form/common';
import {
  convertTo12HourFormat,
  createDateFromTimeString,
  getTimeFromDate,
  isStartTimeLessThanEndTime,
  timeDifference,
  updateArrayWithMatchingDays,
} from '../../../utils/Helpers';
import { WORK_HOURS } from '../../../constants';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDropDown from '../../shared/PFPrime/PFDropdown';

import { getUserAddresses } from './services/user-form-address.service';
import {
  addWorkHours,
  getWorkHours,
  editUserAvailability,
  getUserAvailability,
} from './services/user-form-availability.service';
import { timezonesData } from './Constants';

const UserFormAvailability = () => {
  const { userId } = useSelector(state => state.userForm);
  const [loading, setLoading] = useState(false);
  const [workHours, setWorkHours] = useState(WORK_HOURS);
  const [selectedDays, setSelectedDays] = useState(WORK_HOURS.slice(0, 6));
  const [startLocation, setStartLocation] = useState([]);
  const [endLocation, setEndLocation] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [userTimezone, setUserTimezone] = useState('');
  const [sourceLocation, setSourceLocation] = useState([]);
  const [destinationLocation, setDestinationLocation] = useState([]);

  const toast = useRef(null);

  const timeEditor = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => options.editorCallback(getTimeFromDate(e.target.value))}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const timeEditorBreakStart = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => {
          options.rowData.break_duration == '';
          options.editorCallback(getTimeFromDate(e.target.value));
        }}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const timeEditorBreakEnd = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => {
          options.editorCallback(getTimeFromDate(e.target.value));
        }}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const DayTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{rowData?.day}</span>
    );
  };

  const StartTimeTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.start_time)}</span>
    );
  };

  const EndTimeTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.end_time)}</span>
    );
  };

  const BreakStartTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.break_start_time)}</span>
    );
  };

  const BreakEndTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.break_end_time)}</span>
    );
  };

  const allowedDurationTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <Tag
        value={timeDifference(
          rowData?.break_end_time,
          rowData?.break_start_time
        )}
        severity="info"
      />
    );
  };

  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
    }, 300);
  };

  const handleSelectionChange = e => {
    const newSelectedDays = updateArrayWithMatchingDays(e.value, workHours);
    setSelectedDays(newSelectedDays);
  };

  const onRowEditComplete = async e => {
    let editedBusinessHour = structuredClone(workHours);
    let { newData, index } = e;
    editedBusinessHour[index] = newData;
    const updatedSelectedDays = updateArrayWithMatchingDays(
      structuredClone(selectedDays),
      editedBusinessHour
    );
    setWorkHours(editedBusinessHour);
    setSelectedDays(updatedSelectedDays);
    disableEditButton(false);
  };

  const handleRowValidation = (e, f) => {
    let error = null;
    if (!e?.start_time) {
      error = 'Invalid Start Time';
    } else if (!e?.end_time) {
      error = 'Invalid End Time';
    } else if (!e?.break_start_time) {
      error = 'Invalid Break Start Time';
    } else if (!e?.break_end_time) {
      error = 'Invalid Break End Time';
    } else if (isStartTimeLessThanEndTime(e?.end_time, e?.start_time)) {
      error = 'Start Time cannot be greater than End Time';
    } else if (
      isStartTimeLessThanEndTime(e?.break_end_time, e?.break_start_time)
    ) {
      error = 'Break Start Time cannot be greater than Break End Time';
    } else if (isStartTimeLessThanEndTime(e?.break_start_time, e?.start_time)) {
      error = 'Break Start Time cannot be less than or equal to Start Time';
    } else if (isStartTimeLessThanEndTime(e?.end_time, e?.break_end_time)) {
      error = 'End Time cannot be less than or equal to Break End Time';
    } else {
      const shiftStartTime = dayjs(e?.start_time, 'HH:mm');
      const shiftEndTime = dayjs(e?.end_time, 'HH:mm');
      const breakStartTime = dayjs(e?.break_start_time, 'HH:mm');
      const breakEndTime = dayjs(e?.break_end_time, 'HH:mm');

      if (!breakStartTime.isBetween(shiftStartTime, shiftEndTime)) {
        error = 'Break Start Time should be between Start Time and End Time';
      }
      if (!breakEndTime.isBetween(shiftStartTime, shiftEndTime)) {
        error = 'Break End time should be between Start Time and End Time';
      }
    }
    if (error) {
      toast.current.show({
        severity: 'error',
        summary: error,
        life: 3000,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (isNew = false) => {
    if (Array.isArray(workHours) && workHours.length) {
      setLoading(true);
      try {
        const payload = { selected_days: isNew ? workHours : [] };
        if (!isNew) {
          const selectionMap = {};
          if (Array.isArray(selectedDays) && selectedDays.length) {
            selectedDays.forEach(eachDay => {
              selectionMap[eachDay.day] = eachDay;
            });
          }
          workHours.forEach(eachHour => {
            const commonValues = _.pick(eachHour, [
              'day',
              'start_time',
              'end_time',
              'break_start_time',
              'break_end_time',
            ]);
            if (selectionMap[eachHour.day]) {
              payload.selected_days.push({
                ...commonValues,
                is_working: true,
              });
            } else payload.selected_days.push(commonValues);
          });
        }
        const apiResponse = await addWorkHours(userId, payload);

        if (apiResponse && Array.isArray(apiResponse) && apiResponse.length) {
          toast.current.show({
            severity: 'success',
            summary: 'Record saved Successfully',
            life: 1500,
          });
          setSelectedDays(apiResponse.filter(eachDay => eachDay.is_working));

          setWorkHours(apiResponse);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error occurred while saving records',
            life: 1500,
          });
        }
      } catch (ex) {
        toast.current.show({
          severity: 'error',
          detail:
            (ex.response && ex.response.message) || 'Something Went Wrong',
          life: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const callGetWorkHours = async () => {
    setLoading(true);
    try {
      const apiResponse = await getWorkHours(userId);
      if (apiResponse?.status) {
        if (
          Array.isArray(apiResponse.data?.userWorkDetails) &&
          apiResponse?.data?.userWorkDetails?.length
        ) {
          setSelectedDays(
            apiResponse.data.userWorkDetails.filter(
              eachDay => eachDay.is_working
            )
          );
          setWorkHours(apiResponse.data.userWorkDetails);
        }
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const getAddress = async () => {
    const addressesList = await getUserAddresses(userId);
    const userAddresses = addressesList?.userAddresses || [];

    const mappedAddressData = userAddresses?.map(row => {
      return {
        address_id: row?.address_id,
        name: row?.address_name,
      };
    });
    setStartLocation(mappedAddressData);
    setEndLocation(mappedAddressData);
  };

  useEffect(() => {
    const formattedTimeZones = timezonesData.reduce((accumulator, current) => {
      const utcTimeZone = current.utc.map(utc => {
        return {
          label: `${utc} (${current.offset >= 0 ? '+' : ''} ${current.offset}:00) ${current.abbr}`,
          value: utc,
        };
      });
      accumulator.push(...utcTimeZone);
      return accumulator;
    }, []);
    setTimeZones(formattedTimeZones);
  }, []);

  const getLocationDetails = async () => {
    const response = await getUserAvailability(userId);
    setSourceLocation(response?.start_location);
    setDestinationLocation(response?.end_location);
    setUserTimezone(response?.user_timezone);
  };

  useEffect(() => {
    getLocationDetails();
    // if (clientId) {
    callGetWorkHours();
    getAddress();
    // }
  }, []);

  const saveUserAvailability = async Data => {
    const payload = {
      availability: Data,
      start_location: sourceLocation,
      end_location: destinationLocation,
      user_timezone: userTimezone,
    };

    let editedResponse;
    try {
      await handleSubmit();
      editedResponse = await editUserAvailability(userId, payload);

      // if (editedResponse?.status === 200) {
      //   toast.current.show({
      //     severity: 'success',
      //     summary: 'Availability updated successfully',
      //     life: 2000,
      //   });
      // }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return (
    <div className="mt-3 availability-table">
      <div className="flex flex-row justify-content-between align-items-center mb-3">
        <div className="grid w-full mt-3">
          <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
            <span className="p-float-label">
              <PFDropDown
                key="coverageTypeList"
                className="mr-3 w-full"
                value={startLocation.find(
                  item => item.address_id === sourceLocation
                )}
                options={startLocation}
                optionLabel="name"
                name="start_location"
                style={{ height: '40px' }}
                onChange={(name, event) => {
                  setSourceLocation(event?.address_id);
                }}
              />
              <label htmlFor="client_timezone">Start Location</label>
            </span>
          </div>
          <div className="col-12 md:col-4 lg:col-3  py-0">
            <span className="p-float-label">
              <PFDropDown
                key="coverageTypeList"
                className="mr-3 w-full"
                value={endLocation.find(
                  item => item.address_id === destinationLocation
                )}
                options={endLocation}
                optionLabel="name"
                name="end_location"
                style={{ height: '40px' }}
                onChange={(name, event) => {
                  setDestinationLocation(event?.address_id);
                }}
              />
              <label htmlFor="client_timezone">End Location</label>
            </span>
          </div>
          <div className="col-12 md:col-4 lg:col-3  py-0">
            <span className="p-float-label">
              <Dropdown
                key="coverageTypeList"
                className="mr-3 w-full"
                value={userTimezone}
                options={timeZones}
                filter={true}
                style={{ height: '40px' }}
                onChange={event => {
                  setUserTimezone(event?.value);
                }}
              />
              <label htmlFor="client_timezone">Time Zone</label>
            </span>
          </div>
        </div>

        <Button
          label="Save"
          severity="primary"
          size="small"
          onClick={() => saveUserAvailability(selectedDays)}
        />
      </div>
      <DataTable
        value={Array.isArray(workHours) ? workHours : []}
        dataKey="day"
        selectionMode="checkbox"
        className="w-full"
        onSelectionChange={handleSelectionChange}
        selection={selectedDays}
        editMode="row"
        onRowEditComplete={onRowEditComplete}
        rowEditValidator={handleRowValidation}
        onRowEditCancel={prop => {
          disableEditButton(false);
        }}
        onRowEditInit={prop => {
          disableEditButton(true);
        }}
        showSelectAll={false}
      >
        <Column
          rowEditor={true}
          headerStyle={{ width: '10%', minWidth: '7rem' }}
          bodyStyle={{ textAlign: 'center' }}
        />

        <Column
          header="Working"
          selectionMode="multiple"
          headerStyle={{ width: '3rem' }}
          // body={(rowData) => (loading ? <Skeleton className="w-full" height="3rem" /> : rowData)}
        />
        <Column field="day" header="Day" body={DayTemplate} />
        <Column
          field="start_time"
          header="Start Time"
          editor={options => timeEditor(options)}
          body={StartTimeTemplate}
        />
        <Column
          field="end_time"
          header="End Time"
          editor={options => timeEditor(options)}
          body={EndTimeTemplate}
        />
        <Column
          field="break_start_time"
          header="Break Start"
          editor={options => timeEditorBreakStart(options)}
          body={BreakStartTemplate}
        />
        <Column
          field="break_end_time"
          header="Break End"
          editor={options => timeEditorBreakEnd(options)}
          body={BreakEndTemplate}
        />
        <Column header="Break Duration" body={allowedDurationTemplate} />
      </DataTable>
      <Toast ref={toast}></Toast>
    </div>
  );
};

export default UserFormAvailability;
