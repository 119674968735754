export const NotFound = props => {
  return (
    <div className="text-700 text-center mt-7 w-full">
      <h2>Page not found</h2>
      <div className="text-700">
        Oops! Looks like you've entered an incorrect URL. Please use the sidebar
        to access any other page.
      </div>
      <div className="text-700">
        Write an email to{' '}
        <a href="mailto:support@projectsforce.com">support@projectsforce.com</a>{' '}
        if the issue still persists.
      </div>
    </div>
  );
};
