import { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useSelector } from 'react-redux';

import PageHeader from '../../../shared/PageHeader/PageHeader';

import ReadyToRouteJobs from './ReadyToRouteJobs';
import History from './History';
import Archive from './Archive';

import './RoutingStyle.css';
const Routing = () => {
  const { activeWidget } = useSelector(state => state.pageHeader);
  const state = structuredClone(activeWidget);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="w-12">
      <PageHeader pageTitle={`Routing`} breadCrumbArray={[]} />
      <TabView
        className="w-12 route-optimazation p-3 bg-white"
        activeIndex={activeIndex}
        onTabChange={e => setActiveIndex(e.index)}
      >
        <TabPanel header="Ready to Route" className="p-0">
          <ReadyToRouteJobs
            widget={state.widgetTemplate}
            state={state}
            widgetId={state?.widgetTemplate?.widget_template_id}
            setActiveIndex={setActiveIndex}
          />
        </TabPanel>
        <TabPanel header="History">
          <History state={state} />
        </TabPanel>
        <TabPanel header="Approved Routes">
          <Archive
            widget={state.widgetTemplate}
            state={state}
            widgetId={state?.widgetTemplate?.widget_template_id}
            setActiveIndex={setActiveIndex}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Routing;
