// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createNewEmergencyContact,
  editEmergencyContact,
  deleteEmergencyContact,
  getRelations,
} from './UserManagement.service';

// **** Styles *****
import { useStyles } from './UserManagement.styles';

const emergencyContactsColumns = [
  {
    id: 'emergency_contact_name',
    value: 'Name',
  },
  {
    id: 'phone',
    value: 'Phone Number',
  },
  {
    id: 'relationship',
    value: 'Relationship',
  },
];

const EmergencyContactInfo = ({
  emergencyContactInfo,
  userId,
  setReloadEmergencyContactInfoList,
}) => {
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Emergency Contact',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [relationsList, setRelationsList] = React.useState([]);
  let emergencyContactInfoData = [];

  React.useEffect(() => {
    if (!relationsList.length) getRelations(setRelationsList);
  }, []);

  // **** Data Modification to Display *****
  if (emergencyContactInfo?.length) {
    // emergencyContactInfo = [
    //   ...emergencyContactInfo,
    //   {
    //     name: 'Mama Jain',
    //     phone: '123456',
    //     relationship: 2,
    //   },
    // ];

    emergencyContactInfoData = emergencyContactInfo?.map(
      (contactInfo, index) => {
        return {
          emergency_contact_id: { value: index },
          emergency_contact_name: { value: contactInfo.name.trim() || null },
          phone: { value: contactInfo.phone },
          relationship: {
            id: contactInfo.relationship,
            value: relationsList.find(
              val => val.relationship_type_id === contactInfo.relationship
            )?.relation_type,
          },
        };
      }
    );
  }

  //View Emergency Contact
  const viewEditEmergencyContact = (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Emergency Contact') ||
        'Edit Emergency Contact',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };
  const confirmDeleteEmergencyContact = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete emergency contact?',
      header: 'Delete Emergency Contact',
    });
  };
  const onConfirmDialog = () => {
    console.log('emergencyContactInfoData..', emergencyContactInfoData);
    const remainingEmergencyData = emergencyContactInfoData
      .filter((val, index) => {
        console.log('index..', rowIndex, val.emergency_contact_id?.value);
        return rowIndex !== val.emergency_contact_id?.value;
      })
      .map(val => {
        return {
          name: val.emergency_contact_name.value,
          phone: val.phone.value,
          relationship: val.relationship.id,
        };
      });
    console.log('remainingEmergencyData..', remainingEmergencyData);
    deleteEmergencyContact(
      userId,
      remainingEmergencyData,
      setLoading,
      setAlert,
      setReloadEmergencyContactInfoList,
      setConfirmDialog
    );
  };

  //Emergency Contact ValidationSchema
  const emergencyContactValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[a-zA-ZÀ-ÿ-.' ]*$/, 'Please enter a valid Name.'),
    phone: Yup.string().trim().required('Required'),
    relationship: Yup.number().required('Required'),
  });
  // **** Formik Form Values ****
  const emergencyContactFormik = useFormik({
    initialValues: {
      name:
        (formAction !== 'add' &&
          emergencyContactInfoData[rowIndex]?.emergency_contact_name.value) ||
        null,
      phone:
        (formAction !== 'add' &&
          emergencyContactInfoData[rowIndex]?.phone.value) ||
        '',
      relationship:
        (formAction !== 'add' &&
          emergencyContactInfoData[rowIndex]?.relationship.id) ||
        '',
    },
    onSubmit: values => {
      if (formAction === 'add') {
        console.log('emergencyContactInfoData..', emergencyContactInfoData);
        console.log('emergencyContactInfo..', emergencyContactInfo);

        console.log('emergencyContactFormik..', emergencyContactFormik.values);

        // const addNewEmergencyData = emergencyContactInfoData
        //   .filter((val, index) => {
        //     console.log('index..', rowIndex, val.emergency_contact_id?.value);
        //     return rowIndex !== val.emergency_contact_id?.value;
        //   })
        //   .map((val) => {
        //     return {
        //       name: val.emergency_contact_name.value,
        //       phone: val.phone.value,
        //       relationship: 1, //val.relationship.value,
        //     };
        //   });

        const contactInfo = emergencyContactInfo ? emergencyContactInfo : [];
        const addNewEmergencyData = [
          ...contactInfo,
          {
            ...values,
            name: values.name.trim() || null,
          },
        ];
        createNewEmergencyContact(
          userId,
          addNewEmergencyData,
          setLoading,
          setIsOpen,
          setReloadEmergencyContactInfoList,
          setAlert
        );
      } else {
        const updatedEmergencyData = emergencyContactInfoData
          // .filter((val, index) => {
          //   console.log('index..', rowIndex, val.emergency_contact_id?.value);
          //   return rowIndex !== val.emergency_contact_id?.value;
          // })
          .map(val => {
            return rowIndex !== val.emergency_contact_id?.value
              ? {
                  name: val.emergency_contact_name.value.trim() || null,
                  phone: val.phone.value,
                  relationship: val.relationship.id,
                }
              : {
                  name: emergencyContactFormik.values.name.trim() || null,
                  phone: emergencyContactFormik.values.phone,
                  relationship: emergencyContactFormik.values.relationship,
                };
          });

        editEmergencyContact(
          userId,
          updatedEmergencyData,
          values,
          setLoading,
          setIsOpen,
          setReloadEmergencyContactInfoList,
          setAlert
        );
      }
    },
    validationSchema: emergencyContactValidationSchema,
    enableReinitialize: true,
  });
  const getEmergencyContactViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <TextField
            id="name"
            label="Name"
            name="name"
            onChange={emergencyContactFormik.handleChange}
            onBlur={emergencyContactFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            inputProps={{ maxlength: 30 }}
            disabled={formAction === 'view'}
            required={formAction !== 'view'}
            value={emergencyContactFormik?.values?.name}
            InputLabelProps={{ shrink: true }}
            error={
              emergencyContactFormik.touched.name &&
              emergencyContactFormik.errors.name
            }
            helperText={
              emergencyContactFormik.touched.name &&
              emergencyContactFormik.errors.name
            }
          />
        </Grid>

        <Grid item>
          <InputMask
            mask="(999) 999-9999"
            value={emergencyContactFormik?.values?.phone || ''}
            onChange={emergencyContactFormik.handleChange}
            onBlur={emergencyContactFormik.handleBlur}
            maskChar=" "
            placeholder=""
            disabled={formAction === 'view'}
            InputProps={{
              readOnly: formAction === 'view',
            }}
          >
            {() => (
              <TextField
                name={`phone`}
                label="Phone Number"
                required={formAction !== 'view'}
                disabled={formAction === 'view'}
                InputLabelProps={{ shrink: true }}
                error={
                  emergencyContactFormik.touched.phone &&
                  emergencyContactFormik.errors.phone
                }
                helperText={
                  emergencyContactFormik.touched.phone &&
                  emergencyContactFormik.errors.phone
                }
              />
            )}
          </InputMask>
        </Grid>
        <Grid item>
          <Autocomplete
            name="relationship"
            options={relationsList}
            onBlur={emergencyContactFormik?.handleBlur}
            onChange={(event, value) => {
              emergencyContactFormik.setFieldValue(
                'relationship',
                value.relationship_type_id
              );
            }}
            getOptionLabel={option => option && option.relation_type.toString()}
            renderInput={params => (
              <TextField
                {...params}
                label="Relationship"
                required={formAction !== 'view'}
                InputLabelProps={{ shrink: true }}
              />
            )}
            value={
              relationsList?.filter(
                item =>
                  item.relationship_type_id ===
                  emergencyContactFormik?.values?.relationship
              )[0] || ''
            }
            disabled={formAction === 'view'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <PFTableLoader />
          </Grid>
        </Grid>
      )) || (
        <Grid container spacing={2} direction="column">
          <Grid item classes={{ root: classes.tableWrapper }}>
            <GenericTable
              title="Emergency Contact Info"
              columnData={emergencyContactsColumns}
              rowData={emergencyContactInfoData}
              showActions={{ view: true, edit: true, delete: true }}
              headerLinks={[
                {
                  label: 'Add Item',
                  handler: () => {
                    setFormAction('add');
                    emergencyContactFormik.resetForm();
                    setDialogSettings(prevState => ({
                      ...prevState,
                      showButton2: true,
                      title: 'Add Emergency Contact',
                    }));
                    setIsOpen(true);
                  },
                },
              ]}
              handleView={index => viewEditEmergencyContact('view', index)}
              handleEdit={index => viewEditEmergencyContact('edit', index)}
              handleDelete={index => confirmDeleteEmergencyContact(index)}
            />
          </Grid>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              emergencyContactFormik?.handleReset();
            }}
            handleSave={emergencyContactFormik.handleSubmit}
            dialogSettings={dialogSettings}
            disabledButton2={
              !emergencyContactFormik?.dirty || !emergencyContactFormik?.isValid
            }
            disabledButton1={false}
          >
            <form>{getEmergencyContactViewEditForm()}</form>
          </GenericDialog>
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />
        </Grid>
      )}
    </>
  );
};

export default EmergencyContactInfo;
