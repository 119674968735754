import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { setUserDocumentsForm } from '../../../redux/slices/user-form-documents.slice';
import TableLoader from '../../shared/Loader/TableLoader';

import { uploadUserFile } from './services/user-form-service';
import { domHandlerCall } from './common';
import {
  editUserDocuments,
  getUserDocumentsData,
} from './services/user-form-documents.service';

const emptyRow = {
  file: '',
  description: '',
  notes: '',
};

const mapTableDataForPayload = tableData => {
  const mappedPayload = tableData
    .map(row => {
      if (!row?.notes || !row?.description) {
        return null;
      } else {
        return {
          notes: row?.notes,
          description: row?.description,
          file: row?.file,
        };
      }
    })
    .filter(i => i !== null);
  return mappedPayload;
};

const UserFormDocuments = () => {
  const { isLoading, isFileUploading, documentsData } = useSelector(
    state => state.userFormDocuments
  );
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [newUserDocument, setNewUserDocument] = useState(null);
  const [fileUploaderRowIndex, setFileUploaderRowIndex] = useState();
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);

  const fetchInitiationData = async () => {
    dispatch(setUserDocumentsForm({ isLoading: true }));
    try {
      const initializationData =
        !!userId && (await getUserDocumentsData(userId));
      const { documents } = initializationData;
      dispatch(
        setUserDocumentsForm({
          documentsData: documents,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const handleDelete = async rowIndex => {
    let editedData = structuredClone(documentsData);
    editedData.splice(rowIndex, 1);
    setNewUserDocument(null);
    dispatch(
      setUserDocumentsForm({ documentsData: editedData, isLoading: true })
    );
    const payload = mapTableDataForPayload(editedData);
    let deleteResponse;
    try {
      deleteResponse = await editUserDocuments(userId, payload);
      if (deleteResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted row',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
    }
  };

  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async e => {
    confirmDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(e?.index),
      reject: handleDeleteCancel,
      onHide: handleDeleteCancel,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };

  const FileTemplate = rowData => {
    return (
      <a
        href={rowData?.file?.url}
        target="_blank"
        rel="noreferrer"
        download={rowData?.file?.name}
      >
        {rowData?.file?.name}
      </a>
    );
  };

  const nameEditor = options => {
    return (
      <InputText
        type="text"
        className="p-inputtext-sm"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleUploaderClick = e => {
    setIsUploaderVisible(true);
  };

  const handleUserDocumentUpload = async e => {
    try {
      dispatch(setUserDocumentsForm({ isFileUploading: true }));
      setIsUploaderVisible(false);
      if (e?.files && e?.files?.length > 0) {
        setNewUserDocument(e.files[0]);
        const fileUpload = await uploadUserFile(
          userId,
          'documents',
          e.files[0]
        );
        if (fileUpload?.name && fileUpload?.url) {
          let editedDocuments = structuredClone(documentsData);
          const rowData = editedDocuments[fileUploaderRowIndex];
          rowData['file'] = fileUpload;
          editedDocuments[fileUploaderRowIndex] = rowData;
          dispatch(setUserDocumentsForm({ documentsData: editedDocuments }));
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isFileUploading: false }));
    }
  };

  const fileUploadEditor = options => {
    setFileUploaderRowIndex(options?.rowIndex);
    return (
      <>
        <a
          href={documentsData[options?.rowIndex]?.file?.url}
          target="_blank"
          rel="noreferrer"
          download={documentsData[options?.rowIndex]?.file?.name}
        >
          {documentsData[options?.rowIndex]?.file?.name}
        </a>
        <i
          className="ml-2 pi pi-upload cursor-pointer"
          onClick={handleUploaderClick}
        ></i>
        {isFileUploading && (
          <ProgressSpinner
            style={{ width: '30px', height: '30px' }}
            strokeWidth="6"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
      </>
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserDocumentsForm({
        documentsData: [emptyRow, ...documentsData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };
  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  const onRowEditComplete = async e => {
    let editedDocuments = structuredClone(documentsData);
    let { data, newData, index } = e;
    editedDocuments[index] = {
      file: data?.file,
      notes: newData?.notes,
      description: newData?.description,
    };
    dispatch(
      setUserDocumentsForm({ documentsData: editedDocuments, isLoading: true })
    );
    let editedResponse;
    try {
      const payload = mapTableDataForPayload(editedDocuments);
      editedResponse = await editUserDocuments(userId, payload);
      if (editedResponse?.status === 200) {
        fetchInitiationData();
        toast.current.show({
          severity: 'success',
          summary: 'Updated Documents',
          life: 2000,
        });
        setNewUserDocument(null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserDocumentsForm({ isLoading: false }));
      disableEditButton(false);
      setIsDisableAddButton(false);
    }
  };

  const handleRowValidation = (e, f) => {
    const validationError = [];
    if (!newUserDocument) {
      validationError.push('File');
    }
    if (!e?.description) {
      validationError.push('Description');
    }
    if (!e?.notes) {
      validationError.push('Notes');
    }

    if (validationError.length > 0) {
      toast.current.show({
        severity: 'error',
        summary: `${validationError.join(',')} required`,
        life: 2000,
      });
      return false;
    }
    return true;
  };

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonLoader columnCount={20} columnWidth="24%" mTopBottom={4} />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-content-between align-items-center py-3">
            <h4>Documents</h4>
            <Button
              label="Add Documents"
              severity="primary"
              size="small"
              onClick={handleAddNewRow}
              disabled={isDisableAddButton}
            />
          </div>
          <DataTable
            value={documentsData}
            editMode="row"
            onRowEditComplete={onRowEditComplete}
            rowEditValidator={handleRowValidation}
            onRowEditCancel={handleOnRowEditCancel}
            onRowEditInit={e => {
              setNewUserDocument(e?.data?.file);
              disableEditButton(true);
            }}
          >
            <Column
              field="file"
              header="File"
              body={FileTemplate}
              editor={options => fileUploadEditor(options)}
            />
            <Column
              field="description"
              header="Description"
              editor={options => nameEditor(options)}
            />
            <Column
              field="notes"
              header="Notes"
              editor={options => nameEditor(options)}
            />
            <Column
              rowEditor
              headerStyle={{ width: '15%', minWidth: '7rem' }}
              bodyStyle={{ textAlign: 'center' }}
            ></Column>
          </DataTable>
          <Dialog
            header="Upload Document"
            visible={isUploaderVisible}
            style={{ width: '50vw' }}
            onHide={() => setIsUploaderVisible(false)}
          >
            <FileUploadTemplate
              onFileUpload={handleUserDocumentUpload}
              acceptedFormat="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </Dialog>
        </>
      )}
      <Toast ref={toast}></Toast>
    </>
  );
};

export default UserFormDocuments;
