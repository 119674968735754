import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';

import { setInstallerViewCalendar } from '../../redux/slices/installer-view-calendar.slice';
import {
  deleteInstallerBlockTime,
  updateInstallerBlockTime,
} from '../Personnel/AvailabilityUpdates/AvailablityUpdates.service';

import { addInstallerUnavailablilty } from './new-scheduler/service/new-scheduler.service';

const InstallerViewBlockCalendar = ({
  calenderRefreshHandler,
  setIsBlockTime,
}) => {
  const dispatch = useDispatch();

  const {
    isSelectDialogOpen,
    newEventInfo,
    installersList,
    viewChangeFlag,
    initialInstaller,
    mode,
  } = useSelector(state => state.installerViewCalendar);

  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleFormValidate = values => {
    let error = {};
    if (values?.start && values?.end && values?.end < values?.start) {
      error.end = 'End Time must be greater than Start Time.';
    }
    return error;
  };

  const blockInstallerValidationSchema = Yup.object().shape({
    installer: Yup.object()
      .shape({
        installerUserId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        id: Yup.number().required('Required'),
      })
      .required('Select a technician'),
    start: Yup.string().required('Required'),
    end: Yup.string().required('Required'),
    note: Yup.string().required('Note is required.'),
  });
  const initialInstallerId = () =>
    installersList?.find(item => item?.id == initialInstaller);
  const formik = useFormik({
    initialValues: {
      installer: initialInstallerId(),
      start: newEventInfo?.start,
      end: newEventInfo?.end,
      note: newEventInfo?.note ? newEventInfo?.note : '',
    },
    validationSchema: blockInstallerValidationSchema,
    validate: handleFormValidate,
    onSubmit: async values => {
      try {
        setIsLoading(true);
        const addPayload = {
          user_id: values?.installer?.installerUserId,
          time_off_start_date: values?.start?.toISOString(),
          time_off_end_date: values?.end?.toISOString(),
          time_off_note: values?.note,
          is_blocked_time: 1,
        };
        const editPayload = {
          time_off_start_date: values?.start?.toISOString(),
          time_off_end_date: values?.end?.toISOString(),
          time_off_note: values?.note,
        };
        let response;
        if (mode === 'edit') {
          response = await updateInstallerBlockTime(
            values?.installer?.id,
            newEventInfo?.timeOffRequestId,
            editPayload
          );
        } else {
          response = await addInstallerUnavailablilty(
            values?.installer?.id,
            addPayload
          );
        }
        if (response?.status === 200 && response?.data?.status !== false) {
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail:
              mode === 'edit'
                ? 'Event updated successfully'
                : 'New Event created successfully',
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response?.data?.message
              ? response?.data?.message
              : 'Event did not save',
            life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        dispatch(
          setInstallerViewCalendar({
            isSelectDialogOpen: false,
            viewChangeFlag: !viewChangeFlag,
            mode: null,
          })
        );
        calenderRefreshHandler();
      }
    },
    enableReinitialize: true,
  });

  const handleDialogOnHide = () => {
    formik.resetForm();
    dispatch(
      setInstallerViewCalendar({
        isSelectDialogOpen: false,
        newEventInfo: {},
        initialInstaller: {},
        mode: null,
      })
    );
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteInstallerBlockTime(
        initialInstaller,
        newEventInfo?.timeOffRequestId
      );
      if (response?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Blocked time deleted successfully',
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Blocked time did not delete',
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: false,
          viewChangeFlag: !viewChangeFlag,
          mode: null,
        })
      );
      calenderRefreshHandler();
    }
  };

  useEffect(() => {
    isSelectDialogOpen ? setIsBlockTime(true) : setIsBlockTime(false);
  }, [isSelectDialogOpen]);

  return (
    <>
      <Dialog
        header="Block Technician Time"
        visible={isSelectDialogOpen}
        onHide={handleDialogOnHide}
        className="w-11 lg:w-6 xl:w-5"
      >
        {isLoading ? (
          <div className="w-full h-full flex justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="flex flex-column gap-2">
            <div className="flex flex-row">
              {mode === 'edit' ? (
                <>
                  <label htmlFor="installer" className="w-3 align-self-center">
                    {`Technician`}
                  </label>
                  <div>{`${formik.values?.installer?.name}`}</div>
                </>
              ) : (
                <>
                  <label htmlFor="installer" className="w-3 align-self-center">
                    Select Technician
                  </label>
                  <Dropdown
                    id="installer"
                    value={formik.values.installer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={installersList}
                    optionLabel="name"
                    className="w-6"
                    disabled={mode === 'edit' ? true : false}
                    filter={true}
                  />
                </>
              )}
            </div>
            <div className="flex flex-row">
              <label htmlFor="start" className="w-3 align-self-center">
                Start
              </label>
              <Calendar
                id="start"
                className="w-6"
                value={formik.values.start}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showTime
                hourFormat="12"
              />
            </div>
            <div className="flex flex-column">
              <div className="flex flex-row">
                <label htmlFor="end" className="w-3 align-self-center">
                  End
                </label>
                <Calendar
                  id="end"
                  className="w-6"
                  value={formik.values.end}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showTime
                  hourFormat="12"
                />
              </div>
              {formik.errors?.end && (
                <div className="mt-1 text-red-600">{formik.errors?.end}</div>
              )}
            </div>
            <div className="flex flex-column">
              <div className="flex flex-row">
                <label htmlFor="note" className="w-3 align-self-center">
                  Note
                </label>
                <InputTextarea
                  id="note"
                  name="note"
                  value={formik?.values?.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-6"
                />
              </div>
              {formik.touched?.note && formik.errors?.note && (
                <div className="mt-1 text-red-600">{formik.errors?.note}</div>
              )}
            </div>
            <div className="flex flex-row mt-4 justify-content-between">
              <Button
                label="Submit"
                security="primary"
                size="small"
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid}
              />
              {mode === 'edit' && (
                <Button
                  label="Delete"
                  severity="danger"
                  size="small"
                  onClick={handleDelete}
                />
              )}
            </div>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default InstallerViewBlockCalendar;
