import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons';
import { Image } from 'primereact';

import { useStyles } from './Docs.styles.js';

export default function RelatedMeasurementPhotosCard({
  docDetails,
  handleOfflineAvailability,
  relatedOfflineDocumentsMode = false,
  offlineDocuments,
  installerVisible,
  selectedJob,
}) {
  let documentName = '';
  try {
    if (docDetails) {
      if (docDetails.documetTypeId == 2)
        documentName = docDetails?.templateName;
      else documentName = docDetails?.documentName;
    }
  } catch (error) {}

  const classes = useStyles();

  const cardHTML = !docDetails ? null : (
    <Grid item classes={{ root: classes.storeTypePhotGrid }}>
      <Card classes={{ root: classes.photoCards }}>
        <CardActionArea
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseLeave={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CardContent classes={{ root: classes.docDetails }}>
            <Image
              alt={docDetails?.documentName}
              src={docDetails?.documentUrl}
              imageStyle={{ verticalAlign: 'middle', margin: '0 8px 0 0' }}
              preview={docDetails?.documentUrl ? true : false}
              imageClassName={`${classes.photo} ${classes.avatarImg}`}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return docDetails ? (
    <Grid container classes={{ root: classes.singleDocContainer }}>
      {cardHTML}
      <Grid item container justifyContent="center">
        {(relatedOfflineDocumentsMode
          ? selectedJob?.project_type == 'Measurement' && installerVisible
          : installerVisible) && (
          <Tooltip
            title={
              relatedOfflineDocumentsMode &&
              offlineDocuments?.filter(
                doc => doc.isRelatedProjectDoucmentOffine
              ).length >= 2
                ? 'Only 2 photos could be saved offline'
                : 'Offline visible'
            }
          >
            <Grid className={classes.iconLeft}>
              <IconButton
                disabled={
                  relatedOfflineDocumentsMode &&
                  offlineDocuments?.filter(
                    doc => doc.isRelatedProjectDoucmentOffine
                  ).length >= 2 &&
                  !(
                    offlineDocuments?.filter(
                      doc =>
                        docDetails.documentId === doc.documentId &&
                        doc.isRelatedProjectDoucmentOffine
                    )?.length > 0
                  )
                }
              >
                <CloudDownloadIcon
                  onClick={event => {
                    handleOfflineAvailability(
                      docDetails?.documentId,
                      relatedOfflineDocumentsMode
                    );
                  }}
                  fontSize="large"
                  color={
                    relatedOfflineDocumentsMode
                      ? offlineDocuments?.filter(
                          doc =>
                            doc.documentId === docDetails?.documentId &&
                            doc.isRelatedProjectDoucmentOffine
                        ).length > 0
                        ? 'primary'
                        : ''
                      : offlineDocuments?.filter(
                            doc =>
                              doc.documentId === docDetails?.documentId &&
                              doc.isProjectDoucmentOffine
                          ).length > 0
                        ? 'primary'
                        : ''
                  }
                  disabled={
                    relatedOfflineDocumentsMode &&
                    offlineDocuments?.filter(
                      doc => doc.isRelatedProjectDoucmentOffine
                    ).length >= 2
                  }
                />
              </IconButton>
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Card classes={{ root: classes.add }}>
        <CardContent classes={{ root: classes.addIcon }}>
          <AddIcon fontSize="large" className={classes.plusIcon} />
        </CardContent>
      </Card>
    </Grid>
  );
}
