import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setOpportunitySource as sourceAction } from '../redux/slices/opportunitySourceMaster.slice';
import { CONFIG } from '../constants';

const useOpportunitySources = () => {
  const dispatch = useDispatch();
  const { sources } = useSelector(
    state => state?.masters?.opportunitySourceReducer
  );
  const [opportunitySources, setOpportunitySources] = useState(sources);

  const getOpportunitySources = async () => {
    try {
      if (opportunitySources.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_CRM_URL}/api/opportunity/source`
        );
        setOpportunitySources(response?.data);
        dispatch(sourceAction(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOpportunitySources();
  }, []);

  return { opportunitySources };
};

export default useOpportunitySources;
