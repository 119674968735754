// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { CircularProgress, Grid, Modal, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Services *****

// **** Styles/Images/Icons ****
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';

import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Custom Components ****
import { Alert } from '../../shared/Alerts/Alert';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import { PRIMARY_COLOR } from '../../../constants';

import { useStyles } from './AddViewEditDashboard.styles';
import { updateWidgets } from './Dashboards.service';

const ConfigureDashboardWidget = ({
  dialogOpen,
  setDialogOpen,
  configureWidget,
  layout,
  setDashboardData,
  setWidgetRendererData,
  setWidgetQuery,
  setUpdatedLayout,
}) => {
  const [loading, setLoading] = React.useState(false);
  // To show the message
  const { alert, setAlert, clearAlert } = useAlerts();
  const [showPicker, setShowPicker] = React.useState(false);
  const [showTextPicker, setShowTextPicker] = React.useState(false);
  const configureDialogSettings = {
    title: (
      <Grid container alignItems="center">
        <Grid item>Configure the Widget</Grid>
        {loading && (
          <Grid container xs={6} item justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  };

  const classes = useStyles();
  // **** Form Validation Schema ****
  const ConfigureDashboardSchema = Yup.object().shape({
    name: Yup.string().trim().required('Required'),
  });

  const configureDashboardFormik = useFormik({
    initialValues: {
      widget_template: configureWidget?.widget_template,
      widget_id: configureWidget?.widget_id,
      dashboard_id: configureWidget?.dashboard_id,
      index: configureWidget?.index,
      chart_type: configureWidget?.chart_type,
      name: configureWidget?.name || null,
      description: configureWidget?.description || null,
      custom_properties:
        configureWidget?.custom_properties &&
        Object?.keys(configureWidget?.custom_properties)?.length
          ? configureWidget?.custom_properties
          : configureWidget?.widget_template?.custom_properties || {},
    },
    validationSchema: ConfigureDashboardSchema,
    onSubmit: values => {
      const updateWidgetDetailsLayout = layout.filter(
        lay => lay.i === configureWidget?.index.toString()
      );

      updateWidgets(
        false,
        updateWidgetDetailsLayout,
        values,
        history,
        setLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery,
        setUpdatedLayout,
        setDialogOpen,
        setAlert
      );
    },
    enableReinitialize: true,
  });

  return (
    <>
      {/* Configure Dialog*/}
      <GenericDialog
        fullwidth
        isOpen={dialogOpen}
        handleClose={() => {
          setDialogOpen(false);
          clearAlert();
        }}
        disabledButton2={
          !configureDashboardFormik.dirty || !configureDashboardFormik.isValid
        }
        dialogSettings={configureDialogSettings}
        dialogWidth="sm"
        handleSave={() => {
          configureDashboardFormik?.handleSubmit();
        }}
      >
        <form>
          <Grid container spacing={2} direction="column">
            {alert?.exists && (
              <Grid item>
                <Alert />
              </Grid>
            )}
            <Grid item>
              <TextField
                name="name"
                label="Name of the Widget"
                onChange={configureDashboardFormik.handleChange}
                value={configureDashboardFormik?.values?.name}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            <Grid item>
              <TextField
                name="description"
                label="Description of the Widget"
                onChange={configureDashboardFormik.handleChange}
                value={configureDashboardFormik?.values?.description}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </form>
      </GenericDialog>
    </>
  );
};

export default ConfigureDashboardWidget;
