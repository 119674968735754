import { useEffect, useState } from 'react';
import { Sidebar } from 'primereact';
import { Grid, CircularProgress } from '@material-ui/core';

import {
  getTableQuery,
  prepareQueryWithFilters,
} from '../../Widget/helpers/query';
import { getQueryResult } from '../../Widget/service/widget.service';
import WidgetTableRenderer from '../../Widget/components/WidgetTableRenderer';

const OverlayReport = ({
  query,
  overlayReportVisible,
  setOverlayReportVisible,
  applyFilters,
  setOverlayReportQuery,
}) => {
  const [cubeResponse, setQubeResponse] = useState(null);
  const [totalCount, setTotalCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState({
    first: 0,
    currentPage: 0,
    perPage: 10,
    order_by: {
      columns: '',
      direction: 1,
    },
    filters: null,
  });
  const formattedQuery = getTableQuery(query);

  const fetchTableData = async (params = tablePagination) => {
    const tableQuery = prepareQueryWithFilters({
      tablePagination: params,
      query,
      formattedQuery,
      applyFilters,
      cubeResponse,
    });
    tableQuery.total = true;
    const response = await getQueryResult(tableQuery);
    setQubeResponse(response);
    setTotalCount(response?.total);
  };

  useEffect(() => {
    Promise.all([fetchTableData(tablePagination)]).then(() =>
      setLoading(false)
    );
  }, []);

  const onTableChangeHandler = params => {
    setTablePagination(params);
    fetchTableData(params);
  };

  return (
    <Sidebar
      visible={overlayReportVisible}
      onHide={() => {
        setOverlayReportVisible(false);
        setOverlayReportQuery(null);
      }}
      fullScreen
    >
      <h2>{query?.widget_name}</h2>
      {loading && (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
      {cubeResponse && (
        <WidgetTableRenderer
          table_configuration={query.table_configuration}
          data={cubeResponse?.data}
          tablePagination={tablePagination}
          onTableChangeHandler={onTableChangeHandler}
          annotation={cubeResponse?.annotation?.dimensions}
          totalCount={totalCount}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      )}
    </Sidebar>
  );
};
export default OverlayReport;
