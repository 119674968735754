import React from 'react';
import { Button } from 'primereact/button';

import PageHeader from '../../../../shared/PageHeader/PageHeader';
import { checkPermission } from '../../../../../utils/Helpers';
import permissions from '../../../../../config/permissions';

const userManagementBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    text: 'User Management',
  },
];

const Header = ({ history }) => {
  return (
    <div className="flex flex-col md:flex-row justify-content-between mb-4">
      <div className="flex">
        <PageHeader
          pageTitle="User Management"
          breadCrumbArray={userManagementBreadcrumb}
        />
      </div>

      <div className="flex  align-items-center justify-content-end space-x-2 mt-2 md:mt-0 md:w-3/4">
        {checkPermission(
          permissions?.userManagement?.userManagementManageRolesView
        ) && (
          <Button
            severity="primary"
            outlined
            size="small"
            onClick={() => history.push(`/admin/rolemanagement`)}
            label="Manage Roles"
            className="mr-2"
          />
        )}
        {checkPermission(permissions?.userManagement?.userManagementAdd) && (
          <Button
            severity="primary"
            variant="contained"
            size="small"
            onClick={() => history.push(`/admin/usermanagement/user-form/add`)}
            label="Add New"
          />
        )}
      </div>
    </div>
  );
};

export default Header;
