import React, { useState, useEffect, useRef } from 'react';
import { Tag } from 'primereact/tag';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';

import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFInputText from '../../shared/PFPrime/PFInputText';
import apiSerive from '../../../services/api.service';
import {
  suggestCategory,
  suggestStore,
  suggestDistrict,
  suggestProjectStatus,
  suggestProjectType,
  suggestSourceStatus,
  suggestWorkRoom,
  selectedLabel,
  suggestCustomerOptions,
  createPayloadFromFormikValues,
  isAtLeastOneFieldSelected,
} from '../../Admin/NewConfiguration/SchedularReportConfig/components/AddEditReport.model';
import { getLoginUserId } from '../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { SchedulerCustomFilters } from '../../../constants';

import AddCutomFilter from './AddCustomFilter';
import SaveCutomFilter from './SaveCustomFilter';

const CustomFilter = ({
  defaultFilters,
  handleDefaultFilters,
  setSelectedFilters,
  fetchDefaultFilterList,
}) => {
  const [selectedDefaultFilterId, setSelectedDefaultFilterId] = useState(null);
  const [customFilterDailogVisible, setCustomFilterDailogVisible] =
    useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [projectStatusOptions, setProjectStatusOptions] = useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workRoomOptions, setWorkroomOptions] = useState([]);
  const [addCustomFilterVisible, setAddCustomFilterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownError, setDropdownError] = useState(false);
  const [deafultFilterId, setDefaultFilterId] = useState(null);
  const toast = useRef();

  //Save the Filter
  const saveUserFilter = async value => {
    try {
      setLoading(true);
      const userId = getLoginUserId();
      const response = await apiSerive.post(
        apiSerive.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.saveUserLevelFilter,
          userId
        ),
        value
      );
      if (response && response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'User Filter save successfully.',
        });
        setLoading(false);
        setAddCustomFilterVisible(false);
        setCustomFilterDailogVisible(false);
        fetchDefaultFilterList();
        customFilterFormik.handleReset();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to add record.',
        });
        setLoading(false);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        detail: 'Unable to add record.',
      });
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultFilters && defaultFilters?.length > 0) {
      const defaultFilter = defaultFilters?.find(
        filter => filter?.is_default === 1
      );

      if (defaultFilter) {
        setSelectedDefaultFilterId(defaultFilter.filter_id);
        handleDefaultFilters(defaultFilter);
        setDefaultFilterId(defaultFilter);
      }
    }
  }, [defaultFilters]);
  useEffect(() => {
    suggestCategory('', setCategoryOptions);
    suggestStore('', setStoreOptions);
    suggestProjectType('', setProjectTypeOptions);
    suggestProjectStatus('', setProjectStatusOptions);
    suggestSourceStatus('', setSourceStatusOptions);
    suggestDistrict('', setDistrictOptions);
    suggestWorkRoom('', setWorkroomOptions);
  }, []);
  const handleDefaultFilterChange = value => {
    let selectedFilter = defaultFilters?.find(item => value === item.filter_id);
    handleDefaultFilters(selectedFilter);
  };
  const handleDefaultFilterReset = value => {
    if (deafultFilterId) {
      handleDefaultFilters(deafultFilterId);
      setSelectedDefaultFilterId(deafultFilterId?.filter_id);
    } else {
      handleDefaultFilters(deafultFilterId);
      setSelectedDefaultFilterId(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      customerId: [],
      storeNumber: [],
      projectCategoryId: [],
      statusId: [],
      imsStatus: [],
      projectTypeId: [],
      districtId: [],
      workroomId: [],
    },
    enableReinitialize: true,
    onSubmit: values => {
      setSelectedFilters(values);
      setCustomFilterDailogVisible(false);
      customFilterFormik.handleReset();
    },
  });

  const customFilterFormikVaildation = Yup.object().shape({
    filter_name: Yup.string().required('Required'),
  });
  const customFilterFormik = useFormik({
    initialValues: {
      filter_name: '',
      isActive: false,
    },
    validationSchema: customFilterFormikVaildation,
    enableReinitialize: true,
    onSubmit: values => {
      const payload = createPayloadFromFormikValues(values);
      if (!isAtLeastOneFieldSelected(customFilterFormik)) {
        setDropdownError(true);
        return;
      } else {
        setDropdownError(false);
        saveUserFilter(payload);
      }
    },
  });
  const assignFormikValues = () => {
    customFilterFormik.setValues({
      ...customFilterFormik.values,
      store: formik.values.storeNumber,
      projectCategory: formik.values.projectCategoryId,
      status: formik.values.statusId,
      sourceStatus: formik.values.imsStatus,
      projectType: formik.values.projectTypeId,
      district: formik.values.districtId,
      workroom: formik.values.workroomId,
      customer: formik.values.customerId,
    });
  };
  const defaultFiltersTemplate = options => {
    return (
      <span className="flex align-items-center">
        <span>{options?.filter_name}</span>
        {options?.userFilterInfo ? (
          <span className="pl-2 text-xs">
            <Tag value="Custom"></Tag>
          </span>
        ) : (
          ''
        )}
        {options && options?.is_default ? (
          <span className="pl-2 text-xs">
            <Tag severity="success" value="Default"></Tag>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  };

  const suggestionsProps = {
    suggestCategory,
    suggestStore,
    suggestDistrict,
    suggestProjectStatus,
    suggestProjectType,
    suggestSourceStatus,
    suggestWorkRoom,
    suggestCustomerOptions,
  };

  const optionsProps = {
    customerOptions,
    storeOptions,
    categoryOptions,
    projectTypeOptions,
    projectStatusOptions,
    sourceStatusOptions,
    districtOptions,
    workRoomOptions,
    setCustomerOptions,
    setStoreOptions,
    setCategoryOptions,
    setProjectTypeOptions,
    setProjectStatusOptions,
    setSourceStatusOptions,
    setDistrictOptions,
    setWorkroomOptions,
  };

  return (
    <>
      <div className="col-12 flex p-0">
        <Toast ref={toast} />
        <div className="col-7">
          <span className="p-float-label w-full">
            <PFDropdown
              value={selectedDefaultFilterId}
              onChange={(name, value) => {
                setSelectedDefaultFilterId(value);
                if (value == SchedulerCustomFilters.CUSTOMTYPE) {
                  setCustomFilterDailogVisible(true);
                }
              }}
              options={defaultFilters}
              itemTemplate={defaultFiltersTemplate}
              optionLabel="filter_name"
              optionValue="filter_id"
              placeholder="Select a filter"
              name="default_filters"
              className="w-full"
            />
            <label htmlFor="default_filters">Filters</label>
          </span>
        </div>
        <div className="col-5 flex justify-content-evenly">
          {selectedDefaultFilterId == SchedulerCustomFilters.CUSTOMTYPE && (
            <PFButton
              onClick={() => {
                setAddCustomFilterVisible(true);
                assignFormikValues();
              }}
              text={true}
              size={'small'}
              label="Save"
              outlined
            ></PFButton>
          )}

          {selectedDefaultFilterId != SchedulerCustomFilters.CUSTOMTYPE && (
            <PFButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleDefaultFilterChange(selectedDefaultFilterId);
              }}
            >
              Apply
            </PFButton>
          )}

          <PFButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleDefaultFilterReset(selectedDefaultFilterId);
            }}
            outlined
          >
            Reset
          </PFButton>
        </div>
      </div>
      {customFilterDailogVisible && (
        <AddCutomFilter
          customFilterDailogVisible={customFilterDailogVisible}
          setCustomFilterDailogVisible={setCustomFilterDailogVisible}
          formik={formik}
          suggestionsProps={suggestionsProps}
          optionsProps={optionsProps}
          selectedLabel={selectedLabel}
          customFilterFormik={customFilterFormik}
          loading={loading}
          dropdownError={dropdownError}
          setDropdownError={setDropdownError}
          assignFormikValues={assignFormikValues}
        />
      )}
      {addCustomFilterVisible && (
        <SaveCutomFilter
          addCustomFilterVisible={addCustomFilterVisible}
          setAddCustomFilterVisible={setAddCustomFilterVisible}
          customFilterFormik={customFilterFormik}
          loading={loading}
          dropdownError={dropdownError}
          setDropdownError={setDropdownError}
        />
      )}
    </>
  );
};
export default CustomFilter;
