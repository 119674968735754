import { URL_CONSTANTS } from '../../../constants/urlConstants';

const projectItemsConstants = {
  baseUrl: URL_CONSTANTS.API.BASE_URL,

  getProjectInstallersEndpoint: projectId => {
    return `projects/${projectId}/project-installer`;
  },

  getSystemStatusEndpoint: (statusTypeValue = 'ProjectItem') => {
    return `system/status?statusType=${statusTypeValue}`;
  },

  getCreateMerchandiseEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item/create-merchandise-item`;
  },
  getMerchandiseItemsEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item/merchandise-items`;
  },
  getDeleteProjectItemEndpoint: (projectItemId, projectId) => {
    return `projects/${projectId}/v1/project-item/${projectItemId}/delete`;
  },
  getUpdateMerchandiseEndpoint: (projectItemId, projectId) => {
    return `projects/${projectId}/v1/project-item/${projectItemId}/update`;
  },

  getCreateLaborEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item/create-labor-item`;
  },
  getLaborItemsEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item/labor-items`;
  },
  getUpdateLaborItemEndpoint: (projectItemId, projectId) => {
    return `projects/${projectId}/v1/project-item/${projectItemId}/update`;
  },
  getUpdateLaborItemVisibilityEndpoint: (projectItemId, projectId) => {
    return `projects/${projectId}/v1/project-item/${projectItemId}/update-visibility`;
  },
  getAssignProjectItemUserEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item-user/add`;
  },

  getUpdateItemUserEndpoint: (projectItemUserId, projectId) => {
    return `projects/${projectId}/v1/project-item-user/${projectItemUserId}/update`;
  },
  getDeleteItemUserEndpoint: (projectItemUserId, projectId) => {
    return `projects/${projectId}/v1/project-item-user/${projectItemUserId}/delete`;
  },

  getProjectLaborCostEndpoint: projectId => {
    return `projects/${projectId}/v1/calculate-labor-cost/project-labor-cost`;
  },
  getProjectItemLaborCostEndpoint: (projectItemId, projectId) => {
    return `projects/${projectId}/v1/calculate-labor-cost/project-item/${projectItemId}`;
  },
  getProjectCalculationsEndpoint: projectId => {
    return `projects/${projectId}/v1/calculate-labor-cost/get-project-calculations`;
  },
  getAllProjectItemUpdateEndpoint: projectId => {
    return `projects/${projectId}/v1/project-item/project-all-item-update`;
  },
};
export default projectItemsConstants;
