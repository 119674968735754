import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Chip, Tag, Tooltip, ProgressSpinner } from 'primereact';

import PageHeader from '../../shared/PageHeader/PageHeader';
import ApiService from '../../../services/api.service';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import { VIEW_DASHBOARD_PERFORMANCE_USERS } from '../../../constants';
import PFButton from '../../shared/PFPrime/PFButton';

const DASHBOARD_PERFORMANCE_BREADCRUMB = [
  {
    text: 'Dashboard Performance',
  },
];
const RETRY_SEVERITY = {
  0: 'primary',
  1: 'warning',
  2: 'warning',
  3: 'danger',
};
const DashboardPerformanceAnalysisContainer = () => {
  const [searchString, setSearchString] = useState({
    offset: 0,
    limit: 10,
    page: 1,
  });
  const [first, setFirst] = useState(0);
  const [performanceData, setPerformanceData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDisabledRefreshButton, setIsDisabledRefreshButton] = useState(true);
  const fetchData = async () => {
    setIsDisabledRefreshButton(true);
    try {
      const response = await ApiService.get(
        `/dashboard/dashboard-analysis?offset=${searchString.offset}&limit=${searchString.limit}`
      );
      setPerformanceData(response.list);
      setTotalCount(response.count);
      setLoading(false);
      setTimeout(() => setIsDisabledRefreshButton(false), 60000);
    } catch (error) {
      console.error(error);
      setTimeout(() => setIsDisabledRefreshButton(false), 60000);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchString]);

  const tableColum = [
    {
      field: 'tenant_name',
      header: 'Tenant Name',
      body: record => {
        return (
          <>
            <span
              className="dashboardId"
              data-pr-tooltip={`ID : ${record.client_id}`}
            >
              {record.tenant_name}
            </span>
            <Tooltip target=".dashboardId" mouseTrack mouseTrackLeft={10} />
          </>
        );
      },
    },
    {
      field: 'dashboard_name',
      header: 'Dashboard Name',
      body: record => {
        return (
          <>
            <span
              className="dashboardId"
              data-pr-tooltip={`ID : ${record.dashboard_id}`}
            >
              {record.dashboard_name}
            </span>
            <Tooltip target=".dashboardId" mouseTrack mouseTrackLeft={10} />
          </>
        );
      },
    },
    {
      field: 'widget_name',
      header: 'Widget Name',
      body: record => {
        return (
          <>
            <span
              className="dashboardId"
              data-pr-tooltip={`ID : ${record.widget_id}`}
            >
              {record.widget_name}
            </span>
            <Tooltip target=".dashboardId" mouseTrack mouseTrackLeft={10} />
          </>
        );
      },
    },
    {
      field: 'widget_type',
      header: 'Widget Type',
    },

    {
      field: 'highest_execution_time',
      header: 'Highest Execution',
      body: record => {
        return (
          <div>
            <div className="text-900 font-bold inline-block mr-3 text-red-600 pb-2">
              {record.highest_execution_time}
            </div>
            <div className="text-500 text-sm">
              {record.highest_execution_at}
            </div>
          </div>
        );
      },
    },
    {
      field: 'lowest_execution_time',
      header: 'Lowest Execution',
      body: record => {
        return (
          <div>
            <div className="text-900 font-bold inline-block mr-3 text-green-600 pb-2">
              {record.lowest_execution_time}
            </div>
            <div className="text-500 text-sm">{record.lowest_execution_at}</div>
          </div>
        );
      },
    },
    {
      field: 'last_execution_time',
      header: 'Last Execution',
      body: record => {
        return (
          <div>
            <div className="text-900 font-bold inline-block mr-3 text-blue-600 pb-2">
              {record.last_execution_time}
            </div>
            <div className="text-500 text-sm">{record.last_execution_at}</div>
          </div>
        );
      },
    },
    {
      field: 'user_name',
      header: 'User Name',
      body: record => {
        return (
          <>
            <span
              className="dashboardId"
              data-pr-tooltip={`ID : ${record.user_id}`}
            >
              {record.user_name}
            </span>
            <Tooltip target=".dashboardId" mouseTrack mouseTrackLeft={10} />
          </>
        );
      },
    },
    {
      field: 'widget_retry_count',
      header: 'Retry',
      body: record => {
        return (
          <Tag
            value={record.widget_retry_count}
            severity={
              RETRY_SEVERITY[record.widget_retry_count] || RETRY_SEVERITY[3]
            }
          />
        );
      },
    },
    {
      field: 'widget_status',
      header: 'Status',
      body: record => {
        return record.widget_status == 'Initial' ? (
          <Tag value={record.widget_status} />
        ) : (
          <Tag value={record.widget_status} severity="success" />
        );
      },
    },
  ];

  const applyFilter = props => {};

  if (
    !VIEW_DASHBOARD_PERFORMANCE_USERS.includes(localStorage.getItem('user_id'))
  ) {
    return <Redirect to="/dashboard" />;
  }

  if (loading)
    return (
      <ProgressSpinner
        style={{ width: '50px', height: '50px' }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    );

  return (
    <div className="card w-12">
      <PageHeader
        pageTitle="Dashboard Performance"
        breadCrumbArray={DASHBOARD_PERFORMANCE_BREADCRUMB}
      />
      <div className="mb-2 align-items-end justify-content-end flex">
        <PFButton
          label="Refresh"
          onClick={fetchData}
          disabled={isDisabledRefreshButton}
        />
      </div>

      <div className="mt-2">
        <PFDataTable
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          columns={tableColum}
          data={performanceData}
          stripedRows
          dataKey="id"
          rowsPerPageOptions={[5, 10, 50]}
          tableStyle={{ minWidth: '50rem' }}
          stripedRows={true}
          editMode={'row'}
          rows={searchString?.limit}
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          lazy={true}
          totalRecords={totalCount}
          first={first}
          onPage={event => {
            setSearchString(prev => ({
              ...prev,
              offset: event?.first,
              limit: event?.rows,
            }));
            setFirst(event?.first);
          }}
        />
      </div>
    </div>
  );
};

export default DashboardPerformanceAnalysisContainer;
