import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserAddressData = async userId => {
  try {
    const stateRequest = ApiService.get(`/system/state`);
    const userDetailsRequest = ApiService.get(`/auth/user/manage/${userId}`);

    const [statesList, userDetailsResponse] = await Promise.all([
      stateRequest,
      userDetailsRequest,
    ]);

    return {
      statesList: statesList,
      addressInfo: userDetailsResponse?.addresses || [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const editUserAddress = async (userId, addressInfo) => {
  try {
    let response;
    if (!addressInfo?.address_id) {
      // add new addres
      response = await axios.post(
        `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/address/${userId}`,
        addressInfo
      );
    } else {
      response = await axios.put(
        // edit address
        `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/address/${userId}`,
        addressInfo
      );
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserAddress = async addressId => {
  try {
    if (addressId) {
      const deleteuserResponse = await axios.delete(
        `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/address/${addressId}`
      );
      return deleteuserResponse;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserAddresses = async userId => {
  try {
    const userDetailsResponse = await ApiService.get(
      `/auth/user/manage/${userId}`
    );
    return {
      userAddresses: userDetailsResponse?.addresses || [],
    };
  } catch (error) {
    console.error(error);
  }
};
