import React from 'react';
import { Card } from 'primereact/card';

import { formatCurrency } from '../../../../utils/Helpers';

const MeasureSquareSummary = ({ quoteDetails }) => {
  return (
    <Card style={{ boxShadow: 'none' }}>
      <div htmlFor="Lead Details">
        <div className="grid">
          <div className="text-1xl font-bold px-3 py-2 col-12 md:col-12 lg:col-12 flex">
            <h3>Lead Details</h3>
          </div>
        </div>
        <div className="border-bottom-1 border-200  my-2">
          <div className="grid">
            <div className="px-3 py-2 col-3 md:col-3 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Total Retail ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.quoteAmount
                  ? formatCurrency(quoteDetails?.quoteAmount)
                  : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-3 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Total Cost ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.line_item_cost
                  ? formatCurrency(quoteDetails?.line_item_cost)
                  : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-3 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Tax Rate</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.tax_rate ? quoteDetails?.tax_rate : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-3 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center ">
              <div>Total Tax ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.quoteTotalItemTax
                  ? formatCurrency(quoteDetails?.quoteTotalItemTax)
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-1 border-200  my-2">
          <div className="grid">
            <div className="px-3 py-2 col-3 md:col-6 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Total Discount ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.quoteDiscount
                  ? formatCurrency(quoteDetails?.quoteDiscount)
                  : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-6 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Freight ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.freight_amount
                  ? formatCurrency(quoteDetails?.freight_amount)
                  : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-6 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Deposit ($)</div>
              <div className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.deposit_amount
                  ? formatCurrency(quoteDetails?.deposit_amount)
                  : '-'}
              </div>
            </div>
            <div className="px-3 py-2 col-3 md:col-6 lg:col-3 sm:col-3 my-0 xl:text-sm text-align-center">
              <div>Total Sales Tax ($)</div>
              <p className="text-primary my-0 xl:text-sm text-align-center">
                {quoteDetails?.quoteTotalAmount
                  ? formatCurrency(quoteDetails?.quoteTotalAmount)
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MeasureSquareSummary;
