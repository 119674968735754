import axios from 'axios';
import ApiService from '../../services/api.service';
import { CONFIG } from '../../constants';
import { URL_CONSTANTS } from '../../constants/urlConstants';

export const setWarehouseColumns = () => {
  return [
    {
      field: 'name',
      header: 'Name',
      sortable: true,
    },
    {
      field: 'address',
      header: 'Address',
      style: { width: '40%' },
    },
    {
      field: 'manager',
      header: 'Manager',
    },
    {
      field: 'warehouseCode',
      header: 'Warehouse Code',
      sortable: true,
    },
    {
      field: 'phoneNumber',
      header: 'Phone',
      style: { width: '20%' },
    },
    {
      field: 'emailAddress',
      header: 'Email address',
    },
  ];
};

export const fetchWarehouseInventoryList = async payload => {
  try {
    const data = await ApiService.getWithDiffBaseUrl(
      '/warehouses/get-product-inventory/' + payload,
      undefined,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    return data;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};

export const fetchWarehouseList = async payload => {
  try {
    const data = await ApiService.getWithDiffBaseUrl(
      '/warehouses/get-all',
      payload,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    return data;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};

export const createNewWarehouse = async wareHouseDetails => {
  try {
    const data = await ApiService.postWithDiffBaseUrl(
      '/warehouses/create',
      { wareHouseInput: wareHouseDetails, bins: wareHouseDetails.bins },
      URL_CONSTANTS.INVENTORY.baseUrl
    );

    return data;
  } catch (error) {
    console.error('Error Occurred');
  }
  return null;
};

export const updateNewWarehouse = async wareHouseDetails => {
  try {
    const response = await ApiService.patchWithDiffBaseUrl(
      '/warehouses/update/' + wareHouseDetails?._id,
      { wareHouseInput: wareHouseDetails, bins: wareHouseDetails.bins },
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    return response;
  } catch (error) {
    console.error('Error Occurred');
  }
  return null;
};

export const updateWarehouseListTableView = (
  updatedWarehouse,
  warehouseList
) => {
  const clonedWarehouselist = [...warehouseList];
  var foundIndex = clonedWarehouselist.findIndex(
    x => x._id == updatedWarehouse._id
  );
  clonedWarehouselist[foundIndex] = updatedWarehouse;
  return clonedWarehouselist;
};

export const deleteWarehouse = async wareHouseDetails => {
  try {
    const response = await ApiService.deleteWithDiffBaseUrl(
      '/warehouses/delete/' + wareHouseDetails?._id,
      { wareHouseInput: wareHouseDetails },
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    return response;
  } catch (error) {
    console.error('Error Occurred Deletewarehouse ', error);
  }
  return null;
};

export const fetchInventoryByWarehouse = async wareHouseId => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      '/warehouses/get-inventory/' + wareHouseId,
      undefined,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    return response;
  } catch (error) {
    console.error('Error Occurred Get Inventory By Warehouse ', error);
  }
  return null;
};

export const apiBinListing = async (payload) => {
  try {
    let url = `${CONFIG.API_INVENTORY_URL}/warehouse/${payload?.id}/bin/list`;
    const data = await axios.get(url, { params: payload?.params || {} });
    return data?.data || [];
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};

export const binCreateService = async (payload, warehouseId) => {
  try {
    const data = await axios.post(
      `${CONFIG.API_INVENTORY_URL}/warehouse/${warehouseId}/bin`,
      payload
    );
    return data?.data || [];
  } catch (e) {
    console.log('WarehouseService', 'binCreateService', e && e.message ? e.message : e);
    return e && e.message ? e.message : e;
  }
};

export const binUpdateService = async (payload, warehouseId, binId) => {
  try {
    const data = await axios.patch(
      `${CONFIG.API_INVENTORY_URL}/warehouse/${warehouseId}/bin/${binId}`,
      payload
    );
    return data?.data || [];
  } catch (e) {
    console.log('WarehouseService', 'binUpdateService', e && e.message ? e.message : e);
    return e && e.message ? e.message : e;
  }
};

export const binDeleteService = async (payload) => {
  try {
    const data = await axios.delete(
      `${CONFIG.API_INVENTORY_URL}/warehouse/${payload?.id}/bin/${payload?._id}`
    );
    return data?.data || [];
  } catch (e) {
    console.log('WarehouseService', 'binUpdateService', e && e.message ? e.message : e);
    return e && e.message ? e.message : e;
  }
};
