import React, { useState } from 'react';
import { InputMask } from 'primereact/inputmask';

export default function PFInputMask({
  id,
  name,
  className,
  value,
  onChange,
  mask,
  placeholder,
  disabled,
  rest,
}) {
  return (
    <InputMask
      id={id || ''}
      value={value || ''}
      name={name || ''}
      onChange={e => onChange(name, e.target.value)}
      mask={mask || ''}
      placeholder={placeholder || ''}
      className={`w-full ${className || ''}`}
      disabled={disabled || false}
      {...rest}
    />
  );
}
