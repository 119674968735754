import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import Inventory from '../Inventory/Inventory.view';
import PFTableLoader from '../../shared/Loader/PFTableLoader.js';
import PFDataTable from '../../shared/PFPrime/PFDataTable.js';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import PFButton from '../../shared/PFPrime/PFButton.js';

import { getMerchandiseItemsList } from './Items.service.js';
import { useStyles } from './Items.styles.js';
import AddEditMerchandiseItemDialog from './AddEditMerchandiseItemDialog.js';

const ProductTableNew = React.memo(({ itemId }) => {
  const toast = useRef(null);

  const classes = useStyles();
  const [, setLoadingTable] = useState(false);
  const [merchandiseSearchQuery, setMerchandiseSearchQuery] = useState({
    limit: 10,
    offset: 0,
  });
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [inventoryItem, setInventoryItem] = useState({});
  const [reloadGrid, setReloadGrid] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [
    isAddMerchandiseItemDialogVisible,
    setIsAddMerchandiseItemDialogVisible,
  ] = useState(false);
  const [action, setAction] = useState('add');
  const [rowData, setRowData] = useState('');
  const [reloadMasterItems, setReloadMasterItems] = useState(false);
  const [merchItemsList, setMerchandiseItemsList] = useState([]);
  const [merchandiseLoading, setMerchandiseLoading] = useState(true);
  const [eventRow, setEventRow] = useState(null);
  const [eventFirst, setEventFirst] = useState(null);

  useEffect(() => {
    setReloadMasterItems(reloadGrid);
    setReloadGrid(false);
  }, [reloadGrid]);

  useEffect(() => {
    getMerchandiseItemsList(
      merchandiseSearchQuery,
      setMerchandiseLoading,
      setMerchandiseItemsList
    );
  }, []);

  useEffect(() => {
    if (eventRow || eventFirst) {
      getMerchandiseItemsList(
        merchandiseSearchQuery,
        setMerchandiseLoading,
        setMerchandiseItemsList
      );
    } else {
      getMerchandiseItemsList(
        merchandiseSearchQuery,
        () => {},
        setMerchandiseItemsList
      );
    }
  }, [eventRow, eventFirst, merchandiseSearchQuery]);

  useEffect(() => {
    if (reloadMasterItems) {
      getMerchandiseItemsList(
        merchandiseSearchQuery,
        setMerchandiseLoading,
        setMerchandiseItemsList
      );
      setReloadMasterItems(false);
    }
  }, [reloadMasterItems]);

  //*** Merchandise Items - Action Handlers ***/
  const viewEditMerchandiseItems = (action, index, rowData) => {
    if (merchItemsList.items[index]?.inventory_managment) {
      setInventoryItem(merchItemsList.items[index]);
      setInventoryOpen(true);
    } else {
      setInventoryItem({});
      setInventoryOpen(false);
      setRowData(rowData);
      setAction(action);
      setIsAddMerchandiseItemDialogVisible(true);
    }
  };
  const manufacturerTemplate = rowData => {
    return rowData?.manufacturer?.value || '';
  };

  const vendorTemplate = rowData => {
    return rowData?.vendor?.value || '';
  };

  // Merchandise Generic Table
  const merchandiseDataTableColumns = [
    {
      field: '',
      sortable: false,
      style: { width: '75px' },
      body: rowData => {
        const dataIndex = rowData?.index;
        return (
          <span id={`merchandise-icons-${dataIndex}`} className="ml-3">
            {checkPermission(permissions?.items?.editMerchandiseItem) &&
              merchItemsList.items[dataIndex]?.user?.username !== 'system' && (
                <span className="cursor-pointer">
                  <i
                    className="pi pi-pencil text-color-900"
                    classes={{ root: classes.actionIcons }}
                    onClick={() =>
                      viewEditMerchandiseItems('edit', dataIndex, rowData)
                    }
                  ></i>
                </span>
              )}
          </span>
        );
      },
    },
    {
      field: 'image_url',
      header: 'Image',
      sortable: false,
      style: { width: '100px' },
    },
    {
      field: 'item_number',
      header: 'Item #',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'item_desc',
      header: 'Item Description',
      sortable: true,
      style: { width: '35%', whiteSpace: 'normal', wordBreak: 'break-word' },
    },
    {
      header: 'Manufacturer',
      body: manufacturerTemplate,
      field: 'manufacturer',
      sortable: true,
      style: { width: '150px' },
    },
    {
      header: 'Vendor',
      body: vendorTemplate,
      field: 'vendor',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'retail_price',
      header: 'Retail Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'revenue_price',
      header: 'Revenue Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'cost_price',
      header: 'Cost Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'inventory_managment',
      header: 'Has Inventory',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'express_ship',
      header: 'Has Express Ship',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'exclude_from_commission',
      header: 'Exclude From Commission',
      sortable: true,
      style: { width: '100px' },
    },
  ];

  let merchandiseDataArray = [];
  if (merchItemsList?.items?.length) {
    merchandiseDataArray = merchItemsList?.items?.map((val, index) => {
      return {
        index: index,
        item: val?.item_id || '',
        item_number: val?.item_number || '',
        item_desc: val?.item_desc || '',
        retail_price: val.retail_price || 'N/A',
        revenue_price: val.revenue_price || 'N/A',
        cost_price: val.cost_price || 'N/A',
        image_url: (
          <Image
            width="40"
            height="30"
            alt="Item"
            src={
              val?.image_url ||
              'https://placehold.jp/14/d1d5db/666/80x40.png?text=No Image'
            }
            preview={val?.image_url}
            indicatorIcon={<i className="pi pi-search"></i>}
            pt={{
              image: {
                className: 'border-round w-4rem h-2rem',
                style: { objectFit: 'cover' },
              },
            }}
          />
        ),
        image_url_value: val?.image_url || '',
        inventory_managment: val.inventory_managment ? 'Yes' : 'No',
        express_ship: val?.express_ship ? 'Yes' : 'No',
        manufacturer: val?.meta_type_manufacturer,
        vendor: val?.meta_type_vendor,
        exclude_from_commission: val?.exclude_from_commission ? 'Yes' : 'No',
      };
    });
  }

  const onColumnSortChange = (changedColumn, direction) => {
    setLoadingTable(true);
    setMerchandiseSearchQuery(prevState => ({
      ...prevState,
      sortKey: changedColumn,
      sortDirection: direction === 1 ? 'asc' : 'desc',
    }));
  };

  const onChangeRowsPerPage = event => {
    setEventRow(event?.rows);
    setEventFirst(event?.first);
    setLoadingTable(true);
    setMerchandiseSearchQuery(prevState => ({
      ...prevState,
      limit: event?.rows,
      offset: event?.first,
    }));
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    setEventRow(null);
    setEventFirst(null);
    const query = searchInputVal?.trim();
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoadingTable(true);
      setMerchandiseSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }, 500);
  };
  // Merchandise Generic Table Ends Here.

  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between">
          <h3 className="text-900 pt-2 text-lg">Product Items</h3>
          <div className="flex gap-2">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                value={globalFilterValue}
                onChange={e => {
                  setGlobalFilterValue(e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder="Keyword Search"
              />
            </span>
            {checkPermission(permissions?.items?.addLaborItem) && (
              <PFButton
                size="small"
                label="Add Product Item"
                classes={{ root: classes.buttonRoot }}
                onClick={() => {
                  setIsAddMerchandiseItemDialogVisible(true), setAction('add');
                }}
                disabled={
                  !checkPermission(permissions?.items?.addMerchandiseItem)
                }
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const dataTableParams = { header: renderHeader() };

  return (
    <>
      <Toast ref={toast} />
      {merchandiseLoading ? (
        <div className="justify-content-center">
          {merchandiseLoading && <PFTableLoader />}
        </div>
      ) : (
        <>
          {inventoryOpen && (
            <Inventory
              className="ml-1"
              inventoryOpen={inventoryOpen}
              setInventoryOpen={setInventoryOpen}
              inventoryItem={inventoryItem}
              itemsReload={setReloadGrid}
              showAlert={showAlert}
            />
          )}

          <div>
            <PFDataTable
              columns={merchandiseDataTableColumns}
              data={merchandiseDataArray}
              paginator={true}
              dataKey="item_id"
              rows={merchandiseSearchQuery?.limit}
              rowsPerPageOptions={[10, 20, 50, 100]}
              tableStyle={{ minWidth: '80rem' }}
              dataTableParams={dataTableParams}
              onSort={event => {
                onColumnSortChange(event?.sortField, event?.sortOrder);
              }}
              sortField={merchandiseSearchQuery?.sortKey}
              sortOrder={
                merchandiseSearchQuery?.sortDirection === 'asc' ? 1 : -1
              }
              totalRecords={merchItemsList?.count || 0}
              lazy={true}
              first={merchandiseSearchQuery?.offset}
              onPage={onChangeRowsPerPage}
              pt={{
                wrapper: {
                  style: { height: '500px' },
                },
              }}
            />
          </div>
        </>
      )}
      {isAddMerchandiseItemDialogVisible && (
        <AddEditMerchandiseItemDialog
          visible={isAddMerchandiseItemDialogVisible}
          setIsAddMerchandiseItemDialogVisible={
            setIsAddMerchandiseItemDialogVisible
          }
          itemId={itemId}
          itemsReload={setReloadMasterItems}
          setMerchandiseLoading={setMerchandiseLoading}
          action={action}
          rowData={rowData}
          showAlert={showAlert}
        />
      )}
    </>
  );
});

export default ProductTableNew;
