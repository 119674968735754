import { Card } from 'primereact';

import { convertHexToRgb } from '../../../../utils/Helpers';
import { PRIMARY_COLOR } from '../../../../constants';
import { storeDashboardAnalysis } from '../service/widget.service';

const CardComponent = ({
  formikData,
  cubeResponse,
  showButtonPreview,
  annotation,
  widgetId,
  dashboardDetails,
  dashboardLogDetails,
}) => {
  let colorValue = convertHexToRgb(
    formikData?.custom_properties?.buttonColor || PRIMARY_COLOR
  );
  let formatter;
  let value;
  if (cubeResponse?.length) {
    let formatFunc = Object.keys(cubeResponse?.[0])?.[0];
    if (annotation?.[formatFunc]?.meta?.format) {
      formatter = eval(annotation?.[formatFunc]?.meta?.format);
    }
    if (formatter) {
      value = formatter(Object.values(cubeResponse?.[0])?.[0]);
    } else {
      value = Object.values(cubeResponse?.[0])?.[0] || 0;
    }
  }
  if (!showButtonPreview) {
    let element = document.getElementById(widgetId);
    if (element) {
      element.style.border = 'none';
      element.style.background = `linear-gradient(to right, rgba(${colorValue[0]},${colorValue[1]},${colorValue[2]},1), rgba(${colorValue[0]},${colorValue[1]},${colorValue[2]},0.4))`;
    }
  }

  if (dashboardDetails && dashboardLogDetails) {
    storeDashboardAnalysis({
      ...dashboardLogDetails,
      ...dashboardDetails,
      executionTime: new Date().getTime() - dashboardDetails.start_time,
      end_time: new Date().getTime(),
    });
  }
  return showButtonPreview ? (
    <Card
      className="h-10rem w-20rem"
      pt={{
        root: {
          style: {
            backgroundImage: `linear-gradient(to right, rgba(${colorValue[0]},${colorValue[1]},${colorValue[2]},1), rgba(${colorValue[0]},${colorValue[1]},${colorValue[2]},0.4))`,
            borderRadius: '6px',
            color: formikData?.custom_properties?.buttonTextColor || '#ffff',
          },
        },
      }}
    >
      <div className="flex grid justify-content-between ">
        <div className="col-12">
          <span className="text-lg font-bold capitalize ">
            {formikData.widget_name}
          </span>
          <div className="font-bold text-lg flex mt-1">{value}</div>
        </div>
      </div>
    </Card>
  ) : (
    <div
      className="font-bold text-lg flex"
      style={{
        color: formikData?.custom_properties?.buttonTextColor || '#ffff',
      }}
    >
      {value}
    </div>
  );
};
export default CardComponent;
