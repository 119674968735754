// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Notes Data
export const getBillingData = async (
  projectId,
  setLoading,
  setBillingResponse
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing`
    );
    setBillingResponse(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//Create Payment Request
export const createNewPaymentRequest = async (
  projectId,
  values,
  setLoading,
  setIsOpen,
  setReloadPaymentList,
  setAlert
) => {
  try {
    setLoading(true);
    const createObj = {
      check_amount: values.check_amount || null,
      check_date: values.check_date || null,
      check_number: values.check_number || null,
      originating_po: values.originating_po || null,
      payment_number: values.payment_number,
      payment_status_id: values.payment_status_id,
      payment_type: values.payment_type,
      requested_amount: values.requested_amount,
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing`,
      createObj
    );
    if (response) {
      if (response?.data?.status) {
        setReloadPaymentList(true);
        setAlert('success', 'Payment Request added successfully', false, true);
      } else {
        setReloadPaymentList(true);
        setAlert('error', response?.data?.message, false, true);
      }
    }
  } catch (error) {
    setAlert('error', 'Error in Payment Request creation', false, true);
  } finally {
    setIsOpen(false);
  }
};

//Delete Payment Request
export const deletePayment = async (
  projectId,
  paymentRequestId,
  setLoading,
  setAlert,
  setReloadPaymentList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing/${paymentRequestId}`
    );
    if (response) {
      setReloadPaymentList(true);
    }
  } catch (error) {
    console.log(error);
  }
};

//Delete Payment Request
export const editPaymentRequest = async (
  projectId,
  paymentRequestId,
  values,
  setLoading,
  setIsOpen,
  setReloadPaymentList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const editObj = {
      check_amount: values.check_amount || null,
      check_date: values.check_date || null,
      check_number: values.check_number || null,
      originating_po: values.originating_po || null,
      payment_number: values.payment_number,
      payment_status_id: values.payment_status_id,
      payment_type: values.payment_type,
      requested_amount: values.requested_amount,
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/billing/${paymentRequestId}`,
      editObj
    );
    if (response) {
      if (response?.data?.status) {
        setReloadPaymentList(true);
        setAlert(
          'success',
          'Payment Request updated successfully',
          false,
          true
        );
      } else {
      }
    }
  } catch (error) {
    setReloadPaymentList(true);
    if (error?.response?.data?.error === 'uk_t_payment_req must be unique') {
      setAlert('error', 'Project number must be unique.', false, true);
    } else {
      setAlert('error', error?.response?.data?.error, false, true);
    }
  }
};

// Get Payment Type
export const getPaymentType = async setPaymentTypeOptions => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/payment-type`
    );
    setPaymentTypeOptions(response?.data);
  } catch (error) {
    console.log(error);
  }
};

//get QuickBooks Data
export const getQuickBooksData = async (
  projectId,
  setLoading,
  setAlert,
  setReloadPaymentList,
  setConfirmDialog
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/rule/payment`
    );
    if (response) {
      setReloadPaymentList(true);
      setAlert('success', 'Payment Data updated successfully.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error while fetching payment data', false, true);
    setLoading(false);
  }
};
