// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get permissions List
export const getPermissionsList = async (
  roleId,
  setLoading,
  setPermissionsData
) => {
  try {
    setLoading(true);

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/${roleId}/permissions/v1`
    );

    setPermissionsData(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Save role permissions
export const saveRolePermissions = async (
  roleId,
  checkedViewPermissions,
  newExistingPermissionArray,
  setLoading,
  setAlert,
  setReloadList,
  textBoxField
) => {
  try {
    setLoading(true);

    const menuPermission = Object.keys(checkedViewPermissions).filter(
      key => checkedViewPermissions[key] === true
    );

    let mergedPermissions;

    if (newExistingPermissionArray?.length && menuPermission?.length) {
      mergedPermissions = [...menuPermission, ...newExistingPermissionArray];
    } else {
      mergedPermissions = menuPermission?.length
        ? menuPermission
        : newExistingPermissionArray;
    }

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/${roleId}/permissions/v1`,
      {
        menuPermission: mergedPermissions?.length
          ? mergedPermissions.toString()
          : null,
        customMenuPermission: [textBoxField],
      }
    );
    if (response?.data?.length) {
      setAlert('success', 'Permissions updated successfully');
      setReloadList(true);
    }
  } catch (error) {
    setAlert('error', 'Error in permissions update');
  } finally {
    setLoading(false);
  }
};
