import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import Inventory from '../Inventory/Inventory.view';
import PFTableLoader from '../../shared/Loader/PFTableLoader.js';
import PFDataTable from '../../shared/PFPrime/PFDataTable.js';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import PFButton from '../../shared/PFPrime/PFButton.js';

import AddEditLaborItemDialog from './AddEditLaborItemDialog.js';
import { getLaborItemsList } from './Items.service.js';
import { useStyles } from './Items.styles.js';

const LaborTableNew = React.memo(({ itemId }) => {
  const classes = useStyles();
  const [, setLoadingTable] = useState(false);
  const [laborSearchQuery, setLaborSearchQuery] = useState({
    limit: 10,
    offset: 0,
  });
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [inventoryItem, setInventoryItem] = useState({});
  const [reloadGrid, setReloadGrid] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isAddLaborItemDialogVisible, setIsAddLaborItemDialogVisible] =
    useState(false);
  const [action, setAction] = useState('add');
  const [rowData, setRowData] = useState('');
  const [laborLoading, setLaborLoading] = useState(true);
  const [reloadLaborItems, setReloadLaborItems] = useState(false);
  const [eventRow, setEventRow] = useState(null);
  const [eventFirst, setEventFirst] = useState(null);
  const [laborItemsList, setLaborItemsList] = useState([]);

  const toast = useRef(null);

  useEffect(() => {
    setReloadLaborItems(reloadGrid);
    setReloadGrid(false);
  }, [reloadGrid]);

  useEffect(() => {
    getLaborItemsList(laborSearchQuery, setLaborLoading, setLaborItemsList);
  }, []);

  useEffect(() => {
    if (eventRow || eventFirst) {
      getLaborItemsList(laborSearchQuery, setLaborLoading, setLaborItemsList);
    } else {
      getLaborItemsList(laborSearchQuery, () => {}, setLaborItemsList);
    }
  }, [eventRow, eventFirst, laborSearchQuery]);

  useEffect(() => {
    if (reloadLaborItems) {
      getLaborItemsList(laborSearchQuery, setLaborLoading, setLaborItemsList);
      setReloadLaborItems(false);
    }
  }, [reloadLaborItems]);

  const viewEditLaborItems = (action, index, rowData) => {
    if (
      laborItemsList.items[index]?.inventory_managment ||
      laborItemsList.items[index]?.capacity
    ) {
      setInventoryItem(laborItemsList.items[index]);
      setInventoryOpen(true);
    } else {
      setInventoryItem({});
      setInventoryOpen(false);
      setRowData(rowData);
      setAction(action);
      setIsAddLaborItemDialogVisible(true);
    }
  };

  // Labor Generic Table
  const dataTableColumns = [
    {
      field: '',
      sortable: false,
      style: { width: '75px' },
      body: rowData => {
        const dataIndex = rowData?.index;
        return (
          <span id={`labor-icons-${dataIndex}`} className="ml-3">
            {checkPermission(permissions?.items?.editLaborItem) &&
              laborItemsList.items[dataIndex]?.user?.username !== 'system' && (
                <span className="cursor-pointer">
                  <i
                    className="pi pi-pencil text-color-900"
                    classes={{ root: classes.actionIcons }}
                    onClick={() =>
                      viewEditLaborItems('edit', dataIndex, rowData)
                    }
                  ></i>
                </span>
              )}
          </span>
        );
      },
    },
    {
      field: 'item_number',
      header: 'Item #',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'item_desc',
      header: 'Item Description',
      sortable: true,
      style: { width: '35%', whiteSpace: 'normal', wordBreak: 'break-word' },
    },
    {
      field: 'retail_price',
      header: 'Retail Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'revenue_price',
      header: 'Revenue Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'inventory_managment',
      header: 'Has Inventory',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'exclude_from_commission',
      header: 'Excluded From Commission',
      sortable: true,
      style: { width: '200px' },
    },
    {
      field: 'exclude_from_revenue',
      header: 'Excluded From Revenue',
      sortable: true,
      style: { width: '200px' },
    },
    {
      field: 'is_sum_for_total_quantity',
      header: 'Sum For Total Quantity',
      sortable: true,
      style: { width: '200px' },
    },
  ];
  let dataArray = [];
  if (laborItemsList?.items?.length) {
    dataArray = laborItemsList?.items?.map((val, index) => {
      return {
        index: index,
        item: val?.item_id || '',
        item_number: val?.item_number || '',
        item_desc: val?.item_desc || '',
        retail_price: val.retail_price || 'N/A',
        revenue_price: val.revenue_price || 'N/A',
        cost_price: val.cost_price || 'N/A',
        inventory_managment: val.inventory_managment ? 'Yes' : 'No',
        manufacturer: val?.meta_type_manufacturer,
        vendor: val?.meta_type_vendor,
        exclude_from_commission: val?.exclude_from_commission ? 'Yes' : 'No',
        exclude_from_revenue: val?.exclude_from_revenue ? 'Yes' : 'No',
        is_sum_for_total_quantity: val?.is_sum_for_total_quantity
          ? 'Yes'
          : 'No',
      };
    });
  }

  const onChangeRowsPerPage = event => {
    setEventRow(event?.rows);
    setEventFirst(event?.first);
    setLoadingTable(true);
    setLaborSearchQuery(prevState => ({
      ...prevState,
      limit: event?.rows,
      offset: event?.first,
    }));
  };

  const onColumnSortChange = (changedColumn, direction) => {
    setLoadingTable(true);
    setLaborSearchQuery(prevState => ({
      ...prevState,
      sortKey: changedColumn,
      sortDirection: direction === 1 ? 'asc' : 'desc',
    }));
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    setEventRow(null);
    setEventFirst(null);
    const query = searchInputVal?.trim();
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoadingTable(true);
      setLaborSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }, 500);
  };

  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between">
          <h3 className="text-900 pt-2 text-lg">Labor Items</h3>
          <div className="flex gap-2">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                value={globalFilterValue}
                onChange={e => {
                  setGlobalFilterValue(e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder="Keyword Search"
              />
            </span>
            {checkPermission(permissions?.items?.addLaborItem) && (
              <PFButton
                size="small"
                label="Add Labor Item"
                classes={{ root: classes.buttonRoot }}
                onClick={() => {
                  setIsAddLaborItemDialogVisible(true), setAction('add');
                }}
                disabled={!checkPermission(permissions?.items?.addLaborItem)}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const dataTableParams = { header: renderHeader() };

  // Label Generic Table Ends Here.
  return (
    <div className="inventory-container">
      <Toast
        ref={toast}
        appendTo={null}
        pt={{
          root: {
            className: 'toast-index',
          },
        }}
      />
      {laborLoading ? (
        <div className="justify-content-center">
          {laborLoading && <PFTableLoader />}
        </div>
      ) : (
        <>
          {inventoryOpen && (
            <Inventory
              className="ml-1"
              inventoryOpen={inventoryOpen}
              setInventoryOpen={setInventoryOpen}
              inventoryItem={inventoryItem}
              itemsReload={setReloadGrid}
              showAlert={showAlert}
            />
          )}
          <div>
            <PFDataTable
              columns={dataTableColumns}
              data={dataArray}
              paginator={true}
              dataKey="item_id"
              rows={laborSearchQuery?.limit}
              rowsPerPageOptions={[10, 20, 50, 100]}
              tableStyle={{ minWidth: '80rem' }}
              dataTableParams={dataTableParams}
              onSort={event => {
                onColumnSortChange(event?.sortField, event?.sortOrder);
              }}
              sortField={laborSearchQuery?.sortKey}
              sortOrder={laborSearchQuery?.sortDirection === 'asc' ? 1 : -1}
              totalRecords={laborItemsList?.count || 0}
              lazy={true}
              first={laborSearchQuery?.offset}
              onPage={onChangeRowsPerPage}
              pt={{
                wrapper: {
                  style: { height: '500px' },
                },
              }}
            />
          </div>
        </>
      )}
      {isAddLaborItemDialogVisible && (
        <AddEditLaborItemDialog
          visible={isAddLaborItemDialogVisible}
          setIsAddLaborItemDialogVisible={setIsAddLaborItemDialogVisible}
          itemId={itemId}
          itemsReload={setReloadLaborItems}
          setLaborLoading={setLaborLoading}
          action={action}
          rowData={rowData}
          showAlert={showAlert}
        />
      )}
    </div>
  );
});

export default LaborTableNew;
