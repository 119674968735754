import { makeStyles } from '@material-ui/core/styles';

import { StatusColors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  activeLabel: { marginLeft: 0 },
  addressBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '46%',
    },
    [theme.breakpoints.up('md')]: {
      width: '46%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%',
    },
  },
  boxMinHeight: {
    minHeight: '50px',
    margin: '15px 8px',
  },
  textUnderline: {
    textDecoration: 'underline',
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  paddingTop: {
    paddingTop: '25px',
  },
  selectBoxClass: {
    top: '80px !important',
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '17%',
    },
  },
  boxColumnEmail: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%',
    },
  },
  closeIcon: {
    alignSelf: 'flex-start',
    padding: '5px',
    backgroundColor: theme.palette.text.light,
  },
  checkboxLabelPlacement: {
    marginLeft: '0px',
  },
  checkboxLabel: {
    color: theme.palette.grey[600],
    lineHeight: '14px',
    fontSize: '12px',
  },
  displayImage: {
    height: 'auto !important',
    maxHeight: '100px !important',
    width: 'auto !important',
    maxWidth: '100px !important',
    margin: theme.spacing(0.5),
  },
  docName: {
    textDecoration: 'none',
  },
  photoName: {
    textDecoration: 'none',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  fieldsMinHeight: {
    minHeight: '50px',
    margin: '15px 0px',
  },
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    color: '#0074D9',
    right: 0,
  },
  fileInputButton: {
    width: '40px',
    height: '30px',
    marginLeft: '10px',
  },
  iconRoot: {
    color: theme.palette.primary.main,
  },
  imageInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    color: theme.palette.text.primary,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  photoDeleteIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    top: 0,
  },
  photoSection: {
    width: '15%',
  },
  photoSectionWrapper: {
    minHeight: '150px',
  },
  largeSection: {
    width: '76%',
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  rolesColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '46%',
    },
    [theme.breakpoints.up('md')]: {
      width: '46%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '17%',
    },
  },
  switch: {
    alignSelf: 'flex-start',
    marginLeft: '-10px',
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  statusColorAcknowledged: { color: StatusColors.PF_GREEN },
  statusColorCancelled: { color: StatusColors.PF_RED },
  selectStyle: {
    maxWidth: '200px',
  },
}));
