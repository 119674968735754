import { Skeleton } from 'primereact/skeleton';

const LoginSkeleton = () => {
  return (
    <div className="flex" style={{ height: '100vh' }}>
      <div className="w-3 p-3" style={{ height: '100vh' }}>
        <Skeleton className="mb-2 w-12 h-100" height="90%"></Skeleton>
      </div>
      <div className="w-9 p-3" style={{ height: '100vh' }}>
        <Skeleton height="3rem" className="mb-2"></Skeleton>
        <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
        <Skeleton height="3rem" className="mb-6 w-6"></Skeleton>

        <Skeleton height="3rem" className="mb-2"></Skeleton>
        <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
        <Skeleton height="3rem" className="mb-6 w-6"></Skeleton>

        <Skeleton height="3rem" className="mb-2"></Skeleton>
        <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
        <Skeleton height="3rem" className="mb-6 w-6"></Skeleton>
      </div>
    </div>
  );
};

export default LoginSkeleton;
