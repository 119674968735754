import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: [],
};

const opportunityStatusMasterSlice = createSlice({
  name: 'opportunityStatusMaster',
  initialState: initialState,
  reducers: {
    setOpportunityStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setOpportunityStatus } = opportunityStatusMasterSlice.actions;

export const opportunityStatusReducer = opportunityStatusMasterSlice.reducer;
