import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import PFInputSwitch from '../../../shared/PFPrime/PFInputSwitch';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import apiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

import { paymentConfigSubmit } from './helpers/PaymentsConfigurationEdit';

const PaymentsConfigurationEdit = () => {
  const location = useLocation();
  const [tenantData, setTenantData] = useState({});
  const {
    client_name,
    pf_share,
    pf_share_type,
    pf_share_value,
    stripe_share,
    onboarding_status_id,
  } = tenantData;
  const { client_id } = location.state.data;

  const fetchTenantConfiguration = async () => {
    try {
      const response = await apiService.getWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.paymentConfig}/${client_id}`,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );
      if (response) {
        setTenantData(response.data);
      }
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    }
  };
  useEffect(() => {
    fetchTenantConfiguration();
  }, []);

  const toCapitalizedCase = str => {
    return str?.toLowerCase()?.replace(/\b\w/g, match => match?.toUpperCase());
  };

  const breadCrumb = [
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      link: '/payments-config',
      text: 'Payments Configuration',
    },
    {
      text: toCapitalizedCase(client_name),
    },
  ];

  const TYPE_OPTIONS = [
    { id: 1, name: 'Amount', icon: 'pi pi-dollar' },
    { id: 2, name: 'Percentage', icon: 'pi pi-percentage' },
  ];

  const [blocked, setBlocked] = useState(false);
  const history = useHistory();

  const toast = useRef(null);

  const submitAction = paymentConfigSubmit(
    {
      pf_share: pf_share === '1' ? false : true,
      pf_share_value: pf_share_value || '',
      pf_share_type: pf_share_type,
      stripe_share: stripe_share === '1' ? false : true,
      client_id: client_id,
      client_name: client_name,
      onboarding_status_id: onboarding_status_id,
    },
    setBlocked,
    toast,
    history,
    fetchTenantConfiguration
  );
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    submitAction;

  const selectedType = TYPE_OPTIONS.find(
    record => record.id == values?.pf_share_type
  );

  const sendOnboardingMail = async (clientId, isResend) => {
    try {
      toast?.current?.show({
        severity: 'success',
        summary: isResend
          ? 'Sending onboarding link'
          : 'Tenant onboarding initiated',
        life: 2000,
      });
      const apiUrl = isResend
        ? `${URL_CONSTANTS.CRM.resendOnboardMail}/${clientId}`
        : `${URL_CONSTANTS.CRM.sendOnboardMail}/${clientId}`;

      const response = await apiService.getWithDiffBaseUrl(
        apiUrl,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );
      fetchTenantConfiguration();
    } catch (error) {
      if (typeof error?.response?.data === 'string') {
        toast?.current?.show({
          severity: 'error',
          summary: error.response.data,
          life: 2000,
        });
      } else {
        toast?.current?.show({
          severity: 'error',
          summary: 'Something went wrong!',
          life: 2000,
        });
      }
    }
  };

  const fetchTenantOnboardingStatus = async clientId => {
    const response = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CRM.checkOnboardingStatus}/${clientId}`,
      null,
      URL_CONSTANTS.CRM.baseUrl
    );
    if (!response.status) {
      toast?.current?.show({
        severity: 'info',
        summary: 'No updates found.',
        life: 2000,
      });
    } else {
      fetchTenantConfiguration();
    }
  };

  const handelResendOnboardMail = (clientId, clientName) => {
    confirmDialog({
      message: 'Do you want to resend onboarding mail to ' + clientName,
      header: 'Confirmation',
      icon: 'pi pi-info-circle text-lg',
      acceptClassName: 'p-button-primary',
      accept: () => sendOnboardingMail(clientId, true),
      reject: () => {},
      rejectLabel: 'Cancel',
      acceptLabel: 'Resend',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
    });
  };

  const checkTenantOnboardingStatus = (clientId, clientName) => {
    confirmDialog({
      message: 'Do you want check onboarding status of ' + clientName,
      header: 'Confirmation',
      icon: 'pi pi-info-circle text-lg',
      acceptClassName: 'p-button-primary',
      accept: () => fetchTenantOnboardingStatus(clientId),
      reject: () => {},
      rejectLabel: 'Cancel',
      acceptLabel: 'Yes',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
    });
  };

  const handelSendOnboardMail = (clientId, clientName) => {
    confirmDialog({
      message:
        'Are you sure to initiate the onboarding process for this tenant ?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle text-lg',
      acceptClassName: 'p-button-primary',
      accept: () => sendOnboardingMail(clientId),
      reject: () => {},
      rejectLabel: 'Cancel',
      acceptLabel: 'Continue',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
    });
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid w-full grid-nogutter">
          <div className="col-8 lg:col-8">
            <PageHeader
              pageTitle={`${toCapitalizedCase(client_name || '')} Fee Configuration`}
              breadCrumbArray={breadCrumb}
            />
          </div>
          <div className="col-4 lg:col-4 lg:text-right">
            <Button
              label={blocked ? 'Saving' : 'Save'}
              icon={blocked && 'pi pi-spin pi-spinner'}
              size="small"
              onClick={handleSubmit}
              disabled={
                !(submitAction?.dirty && submitAction?.isValid) || blocked
              }
              className=" ml-2"
            />
          </div>
        </div>
        <BlockUI blocked={blocked} className="w-12">
          <Card className="w-12 mt-4">
            <div>
              <label className="text-600 text-sm">Platform Fee</label>
              <div className="grid mt-2">
                <div className="col-3 mt-2 flex align-items-center">
                  <PFInputSwitch
                    name="pf-Inclusive"
                    onChange={e => setFieldValue('pf_share', e.value)}
                    checked={values?.pf_share ? true : false}
                    className="align-items-center mx-2"
                  />
                  <label className="text-600 text-sm ml-3">
                    Charge to customer
                  </label>
                </div>

                <div className="col-3">
                  <div className="p-inputgroup">
                    <div className="flex-auto">
                      <PFDropdown
                        optionLabel="name"
                        placeholder="Select Type"
                        options={TYPE_OPTIONS}
                        value={selectedType}
                        onChange={(name, value) => {
                          setFieldValue('pf_share_type', value.id);
                        }}
                        name="pf_share_type"
                        className={
                          touched?.pf_share_type &&
                          errors?.pf_share_type &&
                          'error'
                        }
                      />
                    </div>
                    <PFFormikError
                      touched={touched}
                      errors={errors}
                      field="pf_share_type"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <span className="p-inputgroup-addon height-40">
                        <i className={selectedType?.icon}></i>
                      </span>
                      <PFInputNumber
                        value={values?.pf_share_value || ''}
                        placeholder={
                          selectedType ? selectedType?.name : 'value'
                        }
                        id="pf-share"
                        onChange={(name, value) =>
                          setFieldValue('pf_share_value', value || '')
                        }
                        minFractionDigits={2}
                        max={selectedType?.id === 2 && 100}
                        min={1}
                        name="pf_share_value"
                        className={
                          touched?.pf_share_value &&
                          errors?.pf_share_value &&
                          'error border-noround'
                        }
                        pt={{
                          input: {
                            root: {
                              className: 'border-noround-left',
                            },
                          },
                        }}
                      />
                    </span>
                  </div>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="pf_share_value"
                  />
                </div>
              </div>
            </div>
            <div className="border-top-1 border-black-alpha-20 pt-4 mt-3">
              <label className="text-600 text-sm">Stripe Fee</label>
              <div className="grid mt-2">
                <div className="col-3 mt-2 flex align-items-center">
                  <PFInputSwitch
                    name="pf-Inclusive"
                    onChange={e => setFieldValue('stripe_share', e.value)}
                    checked={values?.stripe_share ? true : false}
                    className="align-items-center mx-2"
                  />
                  <label className="text-600 text-sm ml-3">
                    Charge to customer
                  </label>
                </div>
                <div className="col-6">
                  {onboarding_status_id === 341 ? (
                    <div className="flex align-items-center gap-2">
                      <PFButton
                        label="Resend Onboarding link"
                        className="m2"
                        onClick={() =>
                          handelResendOnboardMail(client_id, client_name)
                        }
                        severity="primary"
                        outlined
                      />
                      <PFButton
                        label="Check Onboarding status"
                        className="m2"
                        onClick={() =>
                          checkTenantOnboardingStatus(client_id, client_name)
                        }
                        severity="primary"
                        outlined
                      />
                    </div>
                  ) : onboarding_status_id == 340 ? (
                    <PFButton
                      label="Start Onboarding"
                      text
                      onClick={() =>
                        handelSendOnboardMail(client_id, client_name)
                      }
                      severity="primary"
                      outlined
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Card>
        </BlockUI>
      </form>
    </>
  );
};

export default PaymentsConfigurationEdit;
