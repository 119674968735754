import React from 'react';

import CustomerInfo from './components/CustomerInfo';

const CustomerContainer = () => {
  return (
    <div className="ml-2 pr-1 grid w-full crm flex">
      <CustomerInfo />
    </div>
  );
};

export default CustomerContainer;
