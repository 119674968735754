import axios from 'axios';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

export const getClientList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BULLETIN}/api/bulletin/client-list`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBulletinList = async newQueryString => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BULLETIN}/api/bulletin?${newQueryString}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createBulletin = async payload => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BULLETIN}/api/bulletin`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBulletin = async (id, payload) => {
  try {
    const response = await axios.put(
      `${URL_CONSTANTS.API.BULLETIN}/api/bulletin/${id}`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulletin = async id => {
  try {
    await axios.delete(`${URL_CONSTANTS.API.BULLETIN}/api/bulletin/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getActiveBulletin = async (homepageOrTopBar, date) => {
  try {
    const response = await apiService.getWithDiffBaseUrl(
      `/api/bulletin/active-bulletin/${homepageOrTopBar}/${date}`,
      undefined,
      process.env.REACT_APP_BULLETIN_BASEURL
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getTypesList = async category => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BULLETIN_BASEURL}/api/bulletin/category/${category}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
