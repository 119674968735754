import { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact';
import axios from 'axios';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import PFAutoComplete from '../../../shared/PFPrime/PFAutoComplete';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import { formatDateTime } from '../../../../utils/Helpers';
import ApiService from '../../../../services/api.service';
import { TAGS } from '../constants/constants';
import PFMultiSelect from '../../../shared/PFPrime/PFMultiSelect';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
const initialValues = {
  messageInput: '',
  scheduleDate: null,
  selectedTags: [],
};
const SMSForm = ({
  customerId,
  setReload,
  reload,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
}) => {
  const [filteredTags, setFilteredTags] = useState(TAGS);
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const smsFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      messageInput: Yup.string().trim().required('required'),
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${URL_CONSTANTS.CRM.baseUrl}${URL_CONSTANTS.CRM.communication.sms.create}`,
          {
            messageInput: values.messageInput,
            scheduleDate: values.scheduleDate || new Date(),
            selectedTags: values.selectedTags,
            customerId,
            opportunityId: opportunityId || undefined,
            quoteId: quoteId || undefined,
            invoiceId: invoiceId || undefined,
            paymentId: paymentId || undefined,
          }
        );
        if (response.status != 201) {
          toast?.current?.show({
            severity: 'error',
            summary: 'Failed to add sms',
            life: 2000,
          });
        } else {
          setReload(!reload);
          smsFormik.resetForm({ values: initialValues });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast?.current?.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
      }
    },
  });

  return (
    <>
      <div className="grid ">
        <div className="col-2 md:col-2 lg:col-2">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFCalendar
                inputId="schedule-date-time"
                value={smsFormik.values.scheduleDate}
                onChange={e =>
                  smsFormik?.setFieldValue('scheduleDate', e.value)
                }
                showTime
                hourFormat="12"
                className="sms-scheduler-calender"
                appendTo={null}
                minDate={new Date()}
              />
              <label htmlFor="note">Schedule</label>
            </span>
          </div>
        </div>

        <div className="col-10 md:col-10 lg:col-10">
          <div className="flex p-inputgroup flex-1 w-full">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  value={smsFormik.values.messageInput}
                  name="messageInput"
                  onChange={smsFormik.handleChange}
                  className={'border-noround border-round-left'}
                  pt={{
                    input: {
                      root: {
                        className: 'border-noround-left',
                      },
                    },
                  }}
                />
                <label htmlFor="note">
                  Enter Your Message <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
            </div>
            <PFButton
              label="Send"
              onClick={() => smsFormik.handleSubmit()}
              className="btn-mail-send"
              disabled={loading}
              icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
            />
          </div>
          <PFFormikError
            touched={smsFormik.touched}
            errors={smsFormik.errors}
            field="messageInput"
          />
        </div>
      </div>

      <Toast ref={toast} />
    </>
  );
};

export default SMSForm;
