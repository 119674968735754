export const generateUrlParams = params => {
  const keys = Object.keys(params);
  let url = '';
  keys.forEach((key, index) => {
    if (params[key]) {
      if (index != 0) {
        url += '&';
      }
      url += `${key}=${params[key]}`;
    }
  });
  return url;
};
