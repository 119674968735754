import { WidgetUrl } from './Constants/Constants';

export const getRedirectUrl = (tableName, id, additionalDetails) => {
  switch (tableName) {
    case 'Project':
      return {
        viewUrl: WidgetUrl.project.view(id),
        editUrl: WidgetUrl.project.edit(id),
        task: {
          openTaskPopup: true,
          taskProjectId: id,
          tableName: tableName,
        },
      };
      break;

    case 'Tasks':
      return {
        task: {
          openTaskPopup: true,
          taskProjectId: id,
          tableName: tableName,
        },
      };
      break;
    case 'Quotes':
      return {
        viewUrl: WidgetUrl.quote.view(
          additionalDetails.customerId,
          additionalDetails.opportunityId,
          id
        ),
        editUrl: WidgetUrl.quote.edit(
          additionalDetails.customerId,
          additionalDetails.opportunityId,
          id
        ),
      };
      break;
    case 'Chargebacks':
      if (
        additionalDetails?.secondaryObject?.secondaryTable &&
        additionalDetails?.secondaryObject?.secondaryId
      ) {
        return {
          viewUrl: WidgetUrl.chargeback.projectChargebackViewUrl(
            additionalDetails?.secondaryObject?.secondaryId
          ),
          editUrl: WidgetUrl.chargeback.projectChargebackEditUrl(
            additionalDetails?.secondaryObject?.secondaryId
          ),
        };
      } else {
        return {
          viewUrl: WidgetUrl.chargeback.chargebackViewUrl(id),
          editUrl: WidgetUrl.chargeback.chargebackEditUrl(id),
        };
      }

      break;

    default:
      return null;
  }
};
