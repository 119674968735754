import React from 'react';
import { useParams } from 'react-router';
/*Prime Imports*/
import { AutoComplete } from 'primereact/autocomplete';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';

/*Custom Imports*/
import moment from 'moment';
import { Tag } from 'primereact/tag';
import dayjs from 'dayjs';

import { getProjects } from '../services/ChargebackService';

const ProjectSummary = ({ formik }) => {
  const [searchString, setSearchString] = React.useState();
  const [options, setOptions] = React.useState([]);
  const { projectId } = useParams();
  // Helper to search project
  const suggestProject = async event => {
    if (event?.query?.length >= 2) {
      const data = await getProjects(event?.query);
      setOptions(data);
    }
  };

  const itemTemplate = item => {
    return (
      <div className="text-xs border-top-none border-left-none border-right-none border-cyan-50 py-0">
        <div className="flex justify-content-between align-items-center">
          {item?.project_number && (
            <p className="my-0 font-semibold project_number-name pt-1 pr-2">
              {item?.project_number}
            </p>
          )}

          {item?.project_type?.project_type ? (
            <div className="text-center">
              <Tag
                severity="success"
                className="justify-content-center font-light"
              >
                {item?.project_type?.project_type}
              </Tag>
            </div>
          ) : (
            ''
          )}
        </div>
        {item?.po_number && (
          <p className="my-0 font-semibold project_number-name pt-1 pr-2">
            PO# {item?.po_number}
          </p>
        )}
        {item?.customer?.first_name ? (
          <p className="my-1 first_name">
            {item?.customer?.first_name} {item?.customer?.last_name}
          </p>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <Card pt={{ content: { className: 'p-0' }, body: { className: 'p-2' } }}>
      <div className="grid">
        <div className="text-1xl font-bold px-3 py-2 col-12 md:col-12 lg:col-12">
          Project Summary
        </div>
        <div className="grid grid-nogutter col-12">
          <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
            <span className="p-float-label">
              <AutoComplete
                inputClassName="p-inputtext-sm"
                className="w-12"
                suggestions={options || []}
                completeMethod={suggestProject}
                field="project_number"
                value={formik?.values?.project || null}
                forceSelection={true}
                loadingIcon={true}
                onChange={e => {
                  formik?.setFieldValue('project', e.target.value);
                  formik?.setFieldValue(
                    'project_id',
                    e.target.value?.project_id
                  );
                }}
                delay={500}
                disabled={projectId}
                onBlur={formik?.handleBlur}
                itemTemplate={itemTemplate}
              />
              <label htmlFor="projectNo">Project#</label>
            </span>
          </div>
          {formik?.values?.project?.project_id && (
            <>
              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="po"
                    name="po"
                    className="p-inputtext-sm w-full"
                    value={formik?.values?.project?.po_number || 'NA'}
                    disabled
                  />
                  <label htmlFor="po">PO#</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="dateOfInstall"
                    name="dateOfInstall"
                    className="p-inputtext-sm w-full"
                    disabled
                    value={
                      formik?.values?.project?.date_scheduled_start
                        ? dayjs(
                            formik?.values?.project?.date_scheduled_start
                          ).format('MM-DD-YYYY')
                        : 'NA'
                    }
                  />
                  <label htmlFor="dateOfInstall">Date Of Installation</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="store"
                    name="store"
                    className="p-inputtext-sm w-full"
                    value={formik?.values?.project?.store?.store_number}
                    disabled
                  />
                  <label htmlFor="store">Store#</label>
                </span>
              </div>
              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="storeName"
                    name="storeName"
                    className="p-inputtext-sm w-full"
                    value={formik?.values?.project?.store?.store_name}
                    disabled
                  />
                  <label htmlFor="storeName">Store Name</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="clientName"
                    name="clientName"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.customer?.first_name
                        ? `${formik?.values?.project?.customer?.first_name} ${formik?.values?.project?.customer?.last_name}`
                        : ''
                    }
                    disabled
                  />
                  <label htmlFor="clientName">Client Name</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="type"
                    name="type"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.project_type?.project_type || ''
                    }
                    disabled
                  />
                  <label htmlFor="type">Type</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="category"
                    name="category"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.project_category?.category || ''
                    }
                    disabled
                  />
                  <label htmlFor="category">Category</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="assignTech"
                    name="assignTech"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.project_installer?.length
                        ? `${formik?.values?.project?.project_installer?.[0]?.installer_detail?.meta?.first_name}${formik?.values?.project?.project_installer?.[0]?.installer_detail?.meta?.last_name}`
                        : 'NA'
                    }
                    disabled
                  />
                  <label htmlFor="assignTech">Assigned Technician</label>
                </span>
              </div>

              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="techEmail"
                    name="techEmail"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.project_installer?.length
                        ? formik?.values?.project?.project_installer?.[0]
                            ?.installer_detail?.meta?.email
                        : 'NA'
                    }
                    disabled
                  />
                  <label htmlFor="techEmail">Technician Email Address</label>
                </span>
              </div>
              <div className="sm:col-12 md:col-4 lg:col-3 xl:col-3 p-2">
                <span className="p-float-label">
                  <InputText
                    id="techContact"
                    name="techContact"
                    className="p-inputtext-sm w-full"
                    value={
                      formik?.values?.project?.project_installer?.length
                        ? formik?.values?.project?.project_installer?.[0]
                            ?.installer_detail?.meta?.contact_no
                        : 'NA'
                    }
                    disabled
                  />
                  <label htmlFor="techContact">
                    Technician Contact Details
                  </label>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectSummary;
