import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Message } from 'primereact/message';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import axios from 'axios';

import apiService from '../../../../services/api.service';
import { CONFIG } from '../../../../constants';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const DocumentsViewCard = ({ documentInfo }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Card className="ml-2" style={{ width: '250px', height: '250px' }}>
      <div>{documentInfo?.quote_document_name}</div>
      <Document
        file={documentInfo?.file_path}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          height={166}
        />
      </Document>
    </Card>
  );
};

const Documents = ({ quoteId }) => {
  const [loading, setLoading] = useState(true);
  const [quoteDocs, setQuoteDocs] = useState([]);

  useEffect(() => {
    const quoteDocuments = apiService.getWithDiffBaseUrl(
      `/api/measure-square/quote-documents?quoteId=${quoteId}`,
      undefined,
      CONFIG?.API_CRM_URL
    );
    quoteDocuments
      .then(response => {
        if (response.status) {
          setLoading(false);
          setQuoteDocs(response?.data || []);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="grid py-3">
      {loading ? (
        <div className="col-12 md:col-12 lg:col-12">
          <div className="flex align-items-center justify-content-center">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {quoteDocs.length > 0 ? (
            quoteDocs?.map((doc, index) => (
              <div className="col-12 md:col-4 lg:col-2">
                <div className="flex align-items-center justify-content-left">
                  <a
                    href={doc?.file_path || '#'}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DocumentsViewCard documentInfo={doc} />
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 md:col-12 lg:col-12">
              <div className="flex align-items-center justify-content-left">
                <Message
                  severity="info"
                  text="Document Not Found"
                  className="border-primary w-full justify-content-start "
                  style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                  }}
                  pt={{
                    root: {
                      className: 'surface-ground',
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Documents;
