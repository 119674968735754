import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const MapContainer = ({ lat, long, isLoaded }) => {
  const mapStyles = {
    height: '300px',
    width: '100%',
  };

  const defaultCenter = {
    lat: lat,
    lng: long,
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={16}
        center={defaultCenter}
        options={{ streetview: false, streetViewControl: false }}
      >
        <Marker position={defaultCenter} />
      </GoogleMap>
    )
  );
};
export default MapContainer;
