import React from 'react';

// **** Utilities ****
import { Grid, Typography, Paper } from '@material-ui/core';
import { ArrowBackIos, GetApp } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import parse from 'html-react-parser';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  getMailDetails,
  getMailAttachementDetails,
} from './EmailNotifications.service';
import { useStyles } from './EmailNotifications.styles';

const EmailDetailView = React.memo(({ messageId, setShowPanel, setAlert }) => {
  const classes = useStyles();
  const [getMessageData, setMessageData] = React.useState(null);
  const [apiRunning, setApiRunning] = React.useState(false);
  const [attachementApiRunning, setAttachementApiRunning] =
    React.useState(false);
  React.useEffect(() => {
    getMailDetails(messageId, setMessageData, setApiRunning, setAlert);
  }, [messageId]);
  return apiRunning || !getMessageData ? (
    <Grid container justifyContent="center">
      {apiRunning && <PFTableLoader />}
    </Grid>
  ) : (
    <>
      {getMessageData?.items?.map((emailChainData, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography
              className={`${classes.detailEmailBoxHeaderFirst} ${classes.heading}`}
            >
              <Paper
                onClick={() => {
                  setShowPanel('listView');
                }}
              >
                <ArrowBackIos />
              </Paper>
              <div>
                {emailChainData?.from
                  ? emailChainData?.from?.map((email, index) => {
                      return (
                        <span key={index}>
                          <Typography variant="subtitle1" component="strong">
                            {email.name || ''}
                          </Typography>
                          <Typography component="span">
                            {' '}
                            &lt;{email.address || ''}&gt;
                          </Typography>
                        </span>
                      );
                    })
                  : null}

                <br />

                <Typography component="span">
                  To:
                  {emailChainData?.to
                    ? emailChainData?.to?.map((email, index) => {
                        return (
                          <span
                            key={index}
                          >{`${email.name || ''} <${email.address || ''}> `}</span>
                        );
                      })
                    : null}
                </Typography>
              </div>
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.secondaryHeading}
            >
              {emailChainData?.date
                ? moment(emailChainData?.date).format('MM-DD-YYYY hh:mm A')
                : null}
              {emailChainData?.reviewed && (
                <span className={classes.listReviewed}>
                  <br /> Reviewed
                </span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} className={classes.detailEmailBox}>
              <Grid container item className={classes.detailEmailBoxContent}>
                <Grid item xs={12}>
                  <Typography variant="h2" component="h2">
                    {emailChainData?.subject || null}
                  </Typography>
                  {emailChainData?.reviewed && (
                    <Typography
                      className={classes.reviewedBySection}
                      variant="body2"
                      component="div"
                    >
                      <span className={classes.listReviewed}>
                        Reviewed by{' '}
                        {emailChainData?.reviewed_by_user?.first_name || null}{' '}
                        {emailChainData?.reviewed_by_user?.last_name || null}
                      </span>
                      On{' '}
                      {emailChainData?.reviewed_at
                        ? moment(emailChainData?.reviewed_at).format(
                            'MM-DD-YYYY hh:mm A'
                          )
                        : null}
                    </Typography>
                  )}

                  <Typography variant="body2">
                    {emailChainData?.body?.html
                      ? parse(emailChainData?.body?.html)
                      : null}
                  </Typography>
                  {emailChainData?.attachments?.length
                    ? emailChainData?.attachments.map((row, index) => (
                        <Typography
                          className={classes.downloadAttachement}
                          key={index}
                          variant="body2"
                          component="span"
                          onClick={() => {
                            !attachementApiRunning &&
                              getMailAttachementDetails(
                                messageId,
                                index,
                                row?.fileName,
                                setAttachementApiRunning,
                                setAlert
                              );
                          }}
                        >
                          <LinkIcon />
                          {row?.fileName}
                          <GetApp />
                        </Typography>
                      ))
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
});
export default EmailDetailView;
