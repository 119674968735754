// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import apiService from '../../../services/api.service';

// Get Issues Data
export const getIssuesData = async (
  projectId,
  setLoading,
  setIssuesResponse
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/issues`
    );
    setIssuesResponse(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//Get Originating From
export const getOriginatingFromList = async setOriginatingFromList => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/originating-from`
    );
    setOriginatingFromList(response?.data);
  } catch (error) {
    console.log(error);
  }
};

//Get Category List
export const getCategoryList = async setCategoryList => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/issue-category`
    );
    setCategoryList(response?.data);
  } catch (error) {
    console.log(error);
  }
};

//Add Issue
export const createIssue = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/issues`,
      values
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Issue added successfully.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in creating Issue.', false, true);
    setLoading(false);
  }
};

//Update Issue
export const updateIssue = async (
  projectId,
  issueId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setIsOpen
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/issues/${issueId}`,
      values
    );
    if (response) {
      setAlert('success', 'Issue updated successfully.', false, true);

      setReloadList(true);
    }
  } catch (error) {
    setAlert('error', 'Error in updating Issue.', false, true);
    setLoading(false);
  }
};

//Delete Issue
export const deleteIssue = async (
  projectId,
  issueId,
  setLoading,
  setAlert,
  setReloadList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/issues/${issueId}`
    );

    if (response.data) {
      setAlert('success', 'Issue deleted successfully.', false, true);
      setReloadList(true);
    }
  } catch (error) {
    setAlert('error', 'Error in Deleting Issue', false, true);
    setLoading(false);
  }
};

export const getSourceStatusListOptions = async () => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(URL_CONSTANTS.ISSUES.getSourceStatus)
    );
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
