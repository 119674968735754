// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import useToken from '../../hooks/useToken';
// **** Custom Components ****
import PageHeader from '../shared/PageHeader/PageHeader';

const CreateScheduling = () => {
  const { accessToken } = useToken();
  const clientsBreadcrumb = [
    {
      text: 'Get Started',
      link: '/getstarted',
    },
    {
      text: 'How to Schedule a Project?',
    },
  ];
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      '&.MuiPaper-root': { border: 'none', borderRadius: '20px' },
    },
  }));
  const classes = useStyles();
  return accessToken ? (
    <>
      <Grid container spacing={2} direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="How it works"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={10}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="How to Schedule a Project?"
                  image="/images/get_started/Scheduling.png"
                  height="355"
                  title="How to Schedule a Project?"
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="h2">
                    How to Schedule a Project?
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    In project management, a schedule is a listing of a
                    project's milestones, activities, and deliverables. Usually
                    dependencies and resources are defined for each task, then
                    start and finish dates are estimated from the resource
                    allocation, budget, task duration, and scheduled events. For
                    Schedluling a Project, Please navigate to the Project and
                    Select Scheduling and you can see the Project details.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <>Please Log-in to access the application</>
  );
};

export default CreateScheduling;
