import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

export default function PFTextArea({
  id,
  value,
  onChange,
  rows,
  cols,
  className,
  disabled,
  pt,
}) {
  return (
    <InputTextarea
      id={id || ''}
      value={value || ''}
      onChange={onChange || (() => null)}
      rows={rows || 5}
      cols={cols || 100}
      className={`w-full ${className || ''}`}
      disabled={disabled || false}
      pt={pt || null}
    />
  );
}
