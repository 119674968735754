import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

import { addServiceTimeById } from '../service/ServiceTimeConfigService';
import PFButton from '../../../shared/PFPrime/PFButton';

const DurationTemplate = ({ clientId, row, toast }) => {
  if (!clientId) return <></>;
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [durationValue, setDurationValue] = useState(
    Number.isInteger(row?.duration_value) ? row?.duration_value : 0
  );
  const [durationType, setDurationType] = useState(
    row?.duration_type || 'minute'
  );
  const callAddServiceTimeById = async () => {
    setLoading(true);
    try {
      const payload = {
        duration_value: durationValue || 0,
        duration_type: 'minute',
      };
      const response = await addServiceTimeById(
        clientId,
        row?.config_id,
        payload
      );
      if (response?.status && response?.data) {
        setDurationValue(response?.data?.duration_value);
        setDurationType(response?.data?.duration_type);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message
            ? `${row?.type.project_type} ${row?.category.category}: ${response?.message}`
            : `${row?.type.project_type} ${row?.category.category}: Service time added successfully`,
          life: 3000,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDurationChange = async () => {
    if (durationType) {
      await callAddServiceTimeById();
    }
    setIsEditEnabled(false);
  };
  return (
    <span className="flex align-items-center w-full">
      {isEditEnabled ? (
        <div className="p-inputgroup flex-1">
          <span className="p-float-label flex align-items-center">
            <InputNumber
              autoFocus
              className="w-full p-inputtext-sm"
              inputClassName="border-noround-right"
              value={durationValue}
              onChange={e => setDurationValue(e.value)}
              // onBlur={(e) => setIsEditEnabled(false)}
              disabled={loading}
              min={0}
              id="in_minutes"
              name="in_minutes"
            />
            <label htmlFor="in_minutes">in minutes</label>
          </span>
          <PFButton
            icon={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-check'}`}
            iconPos="left"
            tooltip="Save"
            tooltipOptions={{ position: 'top' }}
            onClick={handleDurationChange}
            disabled={loading || row?.duration_value == durationValue}
          />
        </div>
      ) : (
        <div
          className={`pl-2 pt-1 align-items-center w-7rem border-1 border-200 hover:border-500 border-round h-2rem cursor-text ${
            loading ? 'pointer-events-none' : ''
          }`}
          onClick={() => setIsEditEnabled(true)}
          onFocus={() => setIsEditEnabled(true)}
          disabled={loading}
        >
          {durationValue && durationType ? (
            <>
              {durationValue} {durationType}
              {durationValue && durationValue > 1 ? 's' : ''}
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </span>
  );
};
export default DurationTemplate;
