import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { PFTabView } from '../pf-prime/Prime';

import ProductDetails from './ProductDetails.view';
import WarehouseDetails from './Warehouse.view';
import { panels as panel } from './InventoryDialogBody.model';
import SkeletonLoader from './Loader/skeleton';
const InventoryDialogBody = props => {
  let { itemInfo } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  const [panels, setPanels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPanels(panel);
  }, []);

  useEffect(() => {
    _.isEmpty(itemInfo) ? setLoading(true) : setLoading(false);
  }, [itemInfo]);

  return (
    <>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="grid p-0 mb-3">
            <div className="col-12 lg:col-6">
              <ProductDetails />
            </div>
            <div className="col-12 lg:col-6">
              <WarehouseDetails />
            </div>
          </div>

          <div className="grid">
            <div className="col-12">
              <PFTabView panels={panels} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InventoryDialogBody;
