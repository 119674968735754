import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';

import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import useProjectCategory from '../../../../hooks/useProjectCategory';
import useProjectType from '../../../../hooks/useProjectType';
import { CONFIG } from '../../../../constants';
import useOpportunitySources from '../../../../hooks/useOpportunitySources';
import useOpportunityStatus from '../../../../hooks/useOpportunityStatus';
import { isMeasureSquareEnabled } from '../../../../utils/Helpers';
import { getGroupName } from '../helpers/Opportunity';

const OpportunityInfo = ({
  handleChange,
  values,
  errors,
  setFieldValue,
  touched,
}) => {
  const queryParams = useParams();
  const { mode } = queryParams;
  const { projectTypes } = useProjectType();
  const { projectCategory } = useProjectCategory();
  const { opportunitySources } = useOpportunitySources();
  const { opportunityStatus } = useOpportunityStatus();
  const [leadOwner, setLeadOwner] = useState([]);
  const [groupNameOptions, setGroupNameOptions] = useState([]);
  const [sourceCategoryOptions, setSourceCategoryOptions] = useState([]);
  const clientDetails = localStorage.getItem('client_details');
  const { group_source_category_opted } = clientDetails
    ? JSON.parse(clientDetails)
    : {};
  const getSourceGroupNameList = async () => {
    try {
      const response = await getGroupName();
      if (response && response?.data) {
        setGroupNameOptions(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSourceGroupNameList();
  }, []);
  useEffect(() => {
    if (values.categoryId && groupNameOptions?.length > 0) {
      const groupId = groupNameOptions?.find(category => {
        return category?.project_category_id === values?.categoryId;
      });
      if (groupId?.parentCategory && groupId?.parentCategory?.length > 0) {
        setSourceCategoryOptions(groupId?.parentCategory);
      } else {
        setSourceCategoryOptions([groupId]);
      }
    }
  }, [groupNameOptions, values?.categoryId]);

  useEffect(() => {
    if (mode === 'create') {
      if (opportunityStatus?.length > 0) {
        const openStatusId = opportunityStatus.find(
          item => item.status.toLowerCase() === 'open'
        )?.status_id;
        setFieldValue('statusId', openStatusId);
      }
    }
  }, [opportunityStatus]);

  const getLeadSource = async ({ name, userId }) => {
    try {
      let url = `${CONFIG.API_BASE_URL}/auth/user/report/list`;
      if (name) {
        url = `${url}?searchString=${name}`;
      }
      if (userId) {
        url = `${url}?created_user_id=${userId}`;
      }

      const leadSource = await axios.get(url);
      if (leadSource?.status === 200) {
        setLeadOwner(leadSource?.data?.items);
        return leadSource?.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const leadSource = getLeadSource({ userId: values?.leadOwnerUserId });
  }, [values?.leadOwnerUserId]);

  const modifiedDate = (days = 0) => {
    const extend = days * 24;
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + extend * 60 * 60 * 1000);
    return newDate;
  };
  let newCategoryOptions = [...sourceCategoryOptions];
  return (
    <div className="w-full crm">
      <Card style={{ boxShadow: 'none' }}>
        <div htmlFor="General Information">
          <div className="grid">
            <div className="text-1xl font-bold col-12 md:col-12 lg:col-12">
              <h3>General Information</h3>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={projectTypes}
                    optionLabel="project_type"
                    optionValue="project_type_id"
                    name="typeId"
                    filter={true}
                    value={values?.typeId || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.typeId && errors?.typeId
                        ? 'error p-inputtext-sm'
                        : 'p-inputtext-sm'
                    }
                    appendTo={'self'}
                    disabled={
                      mode === 'view' ||
                      isMeasureSquareEnabled(values?.from_external_source)
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="typeId">
                    Lead Type <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>

                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="typeId"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={groupNameOptions}
                    optionLabel="category"
                    optionValue="project_category_id"
                    name="categoryId"
                    filter={true}
                    value={values?.categoryId || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.categoryId && errors?.categoryId
                        ? 'error p-inputtext-sm'
                        : 'p-inputtext-sm'
                    }
                    appendTo={'self'}
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="categoryId">
                    Lead Category <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>

                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="categoryId"
                />
              </div>
            </div>
            {group_source_category_opted ? (
              <div className="col-12 md:col-6 lg:col-3">
                <div className="w-full">
                  <span className="p-float-label">
                    <PFDropdown
                      options={newCategoryOptions?.filter(
                        data =>
                          data?.category &&
                          data?.category.trim() &&
                          data?.category !== ''
                      )}
                      optionLabel="category"
                      optionValue="project_category_id"
                      name="source_category_id"
                      filter={true}
                      value={values?.source_category_id || ''}
                      onChange={setFieldValue}
                      className={
                        touched?.source_category_id &&
                        errors?.source_category_id
                          ? 'error p-inputtext-sm'
                          : 'p-inputtext-sm'
                      }
                      appendTo={'self'}
                      disabled={mode === 'view' ? true : false}
                    />
                    <label htmlFor="categoryId">
                      Lead Source Category{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="source_category_id"
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={opportunitySources}
                    optionLabel="name"
                    optionValue="source_id"
                    name="sourceId"
                    filter={true}
                    value={values?.sourceId || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.sourceId && errors?.sourceId
                        ? 'error p-inputtext-sm'
                        : 'p-inputtext-sm'
                    }
                    appendTo={'self'}
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="sourceId">
                    Lead Source <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>

                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="sourceId"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    id="referralSource"
                    name="referralSource"
                    type="text"
                    value={values?.referralSource || ''}
                    onChange={handleChange}
                    className={
                      touched?.referralSource && errors?.referralSource
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="referralSource">Referral Source</label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="referralSource"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={leadOwner}
                    optionLabel="user_name"
                    optionValue="user_id"
                    name="leadOwnerUserId"
                    filter={true}
                    value={values?.leadOwnerUserId || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.leadOwnerUserId && errors?.leadOwnerUserId
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                    onKeyPress={e => getLeadSource({ name: e.target.value })}
                  />

                  <label htmlFor="leadOwnerUserId">
                    Lead Owner <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="leadOwnerUserId"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFDropdown
                    options={opportunityStatus}
                    optionLabel="status"
                    optionValue="status_id"
                    name="statusId"
                    filter={true}
                    value={values?.statusId || ''}
                    onChange={setFieldValue}
                    className={
                      touched?.statusId && errors?.statusId ? 'error' : ''
                    }
                    disabled={mode !== 'edit' ? true : false}
                  />
                  <label htmlFor="statusId">
                    Status <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="statusId"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-6">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    id="description"
                    name="description"
                    type="text"
                    value={values?.description || ''}
                    onChange={handleChange}
                    className={
                      touched?.description && errors?.description ? 'error' : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="description">Description</label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="description"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="w-full">
                <span className="p-float-label">
                  <PFCalendar
                    id="follow_up_date"
                    name="follow_up_date"
                    value={values?.follow_up_date}
                    onChange={handleChange}
                    minDate={modifiedDate(0)}
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="follow_up_date">Follow Up Date</label>
                </span>
              </div>
            </div>

            {isMeasureSquareEnabled(values?.from_external_source) &&
              values?.sourceId === 13 && (
                <div className="col-12 md:col-6 lg:col-3">
                  <div className="w-full">
                    <span className="p-float-label">
                      <PFInputText
                        id="sourceStatusMS"
                        name="sourceStatusMS"
                        type="text"
                        value={values?.sourceStatusMS || 'Not Available'}
                        className={
                          touched?.description && errors?.description
                            ? 'error'
                            : ''
                        }
                        disabled={true}
                      />
                      <label htmlFor="description">Source Status</label>
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OpportunityInfo;
