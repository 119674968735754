import React, { useState, useEffect, version } from 'react';
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';

import PFTabView from '../../../shared/PFPrime/PFTabView';
import NotesComponent from '../../Communications/components/NotesComponent';
import EmailComponent from '../../Communications/components/EmailComponent';
import SMSComponent from '../../Communications/components/SMSComponent';
import ProjectsListing from '../../Projects/components/ProjectsListing';
import QuoteListing from '../../Quotes/components/QuoteListing';

import Items from './Items';
import PaymentTerms from './PaymentTerms';
import TermsConditions from './TermsConditions';
import Documents from './Documents';

const QuoteTabs = ({
  setLaborItems,
  setMerchandiseItems,
  laborItems,
  merchandiseItems,
  customerId,
  opportunityId,
  quoteId,
  communicationType,
  communicationId,
  primaryEmailAddress,
  invoiceId,
  paymentId,
  mode,
  setFormPayload,
  aggregate,
  formPayload,
  mainQuoteId,
  isVersionActive,
  isMeasureSquareEnabled,
}) => {
  const isCommunicationAvailable = mode == 'view';
  const { quoteListLayout: QuoteListLayout, apiCallQuoteList } = QuoteListing(
    false,
    true,
    quoteId,
    mainQuoteId
  );
  const CustomerOperationsModel = {
    tabs: [
      { header: 'Labor', key: 'Labor' },
      { header: 'Product', key: 'Merchandise', disabled: false },
      ...(isCommunicationAvailable
        ? [{ header: 'Projects', key: 'Projects', disabled: false }]
        : []),
      ...(isMeasureSquareEnabled
        ? [{ header: 'Documents', key: 'documents', disabled: false }]
        : []),

      { header: 'Payment Terms', key: 'PaymentTerms', disabled: false },
      { header: 'Terms & Conditions', key: 'TermsConditions', disabled: false },
      ...(isCommunicationAvailable
        ? [{ header: 'Versions', key: 'versions', disabled: false }]
        : []),

      ...(isCommunicationAvailable
        ? [{ header: 'Notes', key: 'notes', disabled: false }]
        : []),
      ...(isCommunicationAvailable
        ? [{ header: 'SMS', key: 'SMS', disabled: false }]
        : []),
      ...(isCommunicationAvailable
        ? [{ header: 'Email', key: 'email', disabled: false }]
        : []),
    ],
    components: {
      ...(isCommunicationAvailable && {
        Projects: (
          <div style={{ overflow: 'auto', minHeight: '500px' }}>
            <ProjectsListing
              quoteId={quoteId}
              opportunityId={opportunityId}
              quoteNumber={false}
              opportunityNumber={false}
            />
          </div>
        ),
      }),
      documents: <Documents quoteId={quoteId} />,
      Labor: (
        <Items
          reference="Labor"
          setItems={setLaborItems}
          laborItems={laborItems}
          isMeasureSquareEnabled={isMeasureSquareEnabled}
        />
      ),
      Merchandise: (
        <Items
          reference="Merchandise"
          setItems={setMerchandiseItems}
          merchandiseItems={merchandiseItems}
          isMeasureSquareEnabled={isMeasureSquareEnabled}
        />
      ),

      PaymentTerms: (
        <PaymentTerms
          setFormPayload={setFormPayload}
          aggregate={aggregate}
          formPayload={formPayload}
          isVersionActive={isVersionActive}
        />
      ),
      TermsConditions: (
        <TermsConditions
          setFormPayload={setFormPayload}
          formPayload={formPayload}
          isVersionActive={isVersionActive}
        />
      ),
      ...(isCommunicationAvailable && {
        versions: (
          <div className="pt-2">
            <QuoteListLayout
              isGlobalLevel={true}
              showOldQuotes={true}
              quoteId={quoteId}
            />
          </div>
        ),
      }),

      ...(isCommunicationAvailable && {
        notes: (
          <div style={{ overflow: 'auto', minHeight: '500px' }}>
            <NotesComponent
              customerId={customerId}
              communicationType={communicationType}
              communicationId={communicationId}
              opportunityId={opportunityId}
              quoteId={quoteId}
              invoiceId={invoiceId}
              paymentId={paymentId}
              isVersionActive={isVersionActive}
            />
          </div>
        ),
      }),
      ...(isCommunicationAvailable && {
        SMS: (
          <div style={{ overflow: 'auto', minHeight: '500px' }}>
            <SMSComponent
              customerId={customerId}
              communicationType={communicationType}
              communicationId={communicationId}
              opportunityId={opportunityId}
              quoteId={quoteId}
              invoiceId={invoiceId}
              paymentId={paymentId}
              isVersionActive={isVersionActive}
            />
          </div>
        ),
      }),
      ...(isCommunicationAvailable && {
        email: (
          <div style={{ overflow: 'auto', minHeight: '500px' }}>
            <EmailComponent
              customerId={customerId}
              communicationType={communicationType}
              communicationId={communicationId}
              opportunityId={opportunityId}
              quoteId={quoteId}
              invoiceId={invoiceId}
              paymentId={paymentId}
              primaryEmailAddress={primaryEmailAddress}
              isVersionActive={isVersionActive}
            />
          </div>
        ),
      }),
    },
  };

  return (
    <div className="w-full pt-3 crm p-0">
      <PFTabView model={CustomerOperationsModel} />
    </div>
  );
};

export default QuoteTabs;
