import { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import PFButton from '../PFPrime/PFButton';

import {
  validateBusinessWorkHourAndHoliday,
  validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode,
  validateTechnicianAvailableOnDate,
  validateTechnicianCapacity,
  validateAndAssignInstaller,
  validateCalculatedTechnicianServiceAvailableTime,
  validateAvailableTechnicianWithConsecutiveBreakTime,
  validateTechnicianAfterTimeOff,
  validateCustomerPortalDefaultRotationPercentage,
} from './service';

const OPERATION_TAGS = {
  0: (
    <i
      className="pi pi-check-circle"
      style={{ opacity: 0.3, fontSize: '2rem' }}
    ></i>
  ),
  1: <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>,
  2: (
    <i
      className="pi pi-check-circle"
      style={{ color: 'green', fontSize: '2rem' }}
    ></i>
  ),
  3: (
    <i
      className="pi pi-times-circle"
      style={{ color: 'red', fontSize: '2rem' }}
    ></i>
  ),
};
const DEFAULT_SEQUENCE = {
  validateBusinessWorkHourAndHoliday: {
    status: 0,
    message: '',
    label: 'Validating Scheduling Request with business work hours',
  },
  validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode: {
    status: 0,
    message: '',
    label: 'Searching for eligible Technicians',
  },
  validateTechnicianAvailableOnDate: {
    status: 0,
    message: '',
    label: 'Validating Technicians Availability',
  },
  validateTechnicianCapacity: {
    status: 0,
    message: '',
    label: 'Validating Technicians Capacity',
  },
  validateAndAssignInstaller: {
    status: 0,
    message: '',
    label: 'Finalizing the best technician for this job',
  },
};
const CxSchedulerValidationDialog = ({
  cxSchedulerValidationDialogVisibility,
  setCxSchedulerValidationDialogVisibility,
  selectedProject,
}) => {
  if (
    !selectedProject?.project_project_number ||
    !selectedProject?.execution_id
  )
    return <></>;
  const toast = useRef(null);
  const [operations, setOperations] = useState(DEFAULT_SEQUENCE);

  const initProcess = async (
    processName = 'validateBusinessWorkHourAndHoliday',
    call
  ) => {
    let callName;
    try {
      if ('validateBusinessWorkHourAndHoliday' === processName) {
        setOperations(prev => ({
          ...prev,
          validateBusinessWorkHourAndHoliday: {
            ...prev.validateBusinessWorkHourAndHoliday,
            status: 1,
          },
        }));
        await validateBusinessWorkHourAndHoliday(
          selectedProject.project_project_id,
          selectedProject.execution_id
        );
        processName =
          'validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode';
      }
      if (
        'validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode' ===
        processName
      ) {
        setOperations(prev => ({
          ...prev,
          validateBusinessWorkHourAndHoliday: {
            ...prev.validateBusinessWorkHourAndHoliday,
            status: 2,
          },
          validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode:
            {
              ...prev.validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode,
              status: 1,
            },
        }));
        await validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode(
          selectedProject.project_project_id,
          selectedProject.execution_id
        );
        processName = 'validateTechnicianAvailableOnDate';
      }
      if ('validateTechnicianAvailableOnDate' === processName) {
        setOperations(prev => ({
          ...prev,
          validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode:
            {
              ...prev.validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode,
              status: 2,
            },
          validateTechnicianAvailableOnDate: {
            ...prev.validateTechnicianAvailableOnDate,
            status: 1,
          },
        }));
        let func;
        if (call) {
          switch (call) {
            case 'validateTechnicianAvailableOnDate':
              func = [
                validateTechnicianAvailableOnDate,
                validateCalculatedTechnicianServiceAvailableTime,
                validateAvailableTechnicianWithConsecutiveBreakTime,
                validateTechnicianAfterTimeOff,
              ];
              break;
            case 'validateCalculatedTechnicianServiceAvailableTime':
              func = [
                validateCalculatedTechnicianServiceAvailableTime,
                validateAvailableTechnicianWithConsecutiveBreakTime,
                validateTechnicianAfterTimeOff,
              ];
              break;
            case 'validateAvailableTechnicianWithConsecutiveBreakTime':
              func = [
                validateAvailableTechnicianWithConsecutiveBreakTime,
                validateTechnicianAfterTimeOff,
              ];
              break;
            case 'validateTechnicianAfterTimeOff':
              func = [validateTechnicianAfterTimeOff];
              break;
          }
          call = 'validateTechnicianCapacity';
        } else {
          func = [
            validateTechnicianAvailableOnDate,
            validateCalculatedTechnicianServiceAvailableTime,
            validateAvailableTechnicianWithConsecutiveBreakTime,
            validateTechnicianAfterTimeOff,
          ];
        }
        if (Array.isArray(func) && func.length) {
          for (const eachFunc of func) {
            callName = eachFunc.name;
            await eachFunc(
              selectedProject.project_project_id,
              selectedProject.execution_id
            );
          }
        }
        processName = 'validateTechnicianCapacity';
      }
      if ('validateTechnicianCapacity' === processName) {
        setOperations(prev => ({
          ...prev,
          validateTechnicianAvailableOnDate: {
            ...prev.validateTechnicianAvailableOnDate,
            status: 2,
          },
          validateTechnicianCapacity: {
            ...prev.validateTechnicianCapacity,
            status: 1,
          },
        }));
        let func;
        if (call) {
          switch (call) {
            case 'validateTechnicianCapacity':
              func = [
                validateTechnicianCapacity,
                validateCustomerPortalDefaultRotationPercentage,
              ];
              break;
            case 'validateCustomerPortalDefaultRotationPercentage':
              func = [validateCustomerPortalDefaultRotationPercentage];
              break;
          }
          call = 'validateAndAssignInstaller';
        } else {
          func = [
            validateTechnicianCapacity,
            validateCustomerPortalDefaultRotationPercentage,
          ];
        }
        if (Array.isArray(func) && func.length) {
          for (const eachFunc of func) {
            callName = eachFunc.name;
            await eachFunc(
              selectedProject.project_project_id,
              selectedProject.execution_id
            );
          }
        }
        processName = 'validateAndAssignInstaller';
      }
      if ('validateAndAssignInstaller' === processName) {
        setOperations(prev => ({
          ...prev,
          validateTechnicianCapacity: {
            ...prev.validateTechnicianCapacity,
            status: 2,
          },
          validateAndAssignInstaller: {
            ...prev.validateAndAssignInstaller,
            status: 1,
          },
        }));
        processName = 'validateAndAssignInstaller';
        const assignApiResponse = await validateAndAssignInstaller(
          selectedProject.project_project_id,
          selectedProject.execution_id
        );
        if (assignApiResponse?.status) {
          setOperations(prev => ({
            ...prev,
            validateAndAssignInstaller: {
              ...prev.validateAndAssignInstaller,
              status: 2,
            },
          }));
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail:
              assignApiResponse?.message || 'Project reassigned successfully.',
            life: 3000,
          });
          setTimeout(() => {
            setCxSchedulerValidationDialogVisibility();
          }, 500);
        }
      }
    } catch (ex) {
      try {
        if (
          'Network Error' === ex?.message ||
          [503, 504].includes(ex?.response?.status)
        ) {
          await initProcess(processName, callName);
        } else {
          if (processName && DEFAULT_SEQUENCE[processName]) {
            setOperations(prev => ({
              ...prev,
              [processName]: {
                ...prev[processName],
                status: 3,
                message:
                  ex?.response?.data?.message ||
                  'Something went wrong. Please try again',
                code: ex?.response?.status || 0,
                call: ex?.response?.data?.call,
              },
            }));
          }
          toast?.current?.show &&
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail:
                ex?.response?.data?.message ||
                'Something went wrong. Please try again',
              life: 3000,
            });
        }
      } catch (ex) {}
    }
  };
  useEffect(() => {
    if (selectedProject?.project_project_number) initProcess();
  }, [selectedProject?.project_project_number]);

  const retry = () => {
    for (const key in operations) {
      if (operations[key].status === 3) {
        initProcess(key, operations[key]?.call);
        break;
      }
    }
  };
  const headerElement = (
    <div className="align-items-center flex flex-wrap gap-2">
      <span>Searching another technician for:</span>
      <span className="font-normal">
        {selectedProject?.project_project_number}
      </span>
    </div>
  );

  return (
    <Dialog
      id="cxSchedulerValidationDialog"
      visible={cxSchedulerValidationDialogVisibility}
      onHide={setCxSchedulerValidationDialogVisibility}
      header={headerElement}
      style={{
        overflow: 'auto',
      }}
      className="w-6 lg:w-5"
    >
      <Toast ref={toast} />
      <div className="flex-left flex w-full p-2">
        <ul style={{ listStyleType: 'none' }}>
          {Object.keys(operations).map(eachCall => (
            <li>
              <div
                className={`align-items-center h-3rem flex gap-4
                ${operations[eachCall].status === 3 ? 'mt-4 mb-4' : ''}`}
              >
                <div>{OPERATION_TAGS[operations[eachCall].status]}</div>
                <div>
                  {operations[eachCall].status === 3 ? (
                    <div className="flex-column flex">
                      <div className="w-full text-red-500">
                        {operations[eachCall].label}
                      </div>
                      <div className="flex w-full flex-wrap gap-1">
                        <span className="text-red-500 text-sm">
                          {operations[eachCall].message}
                        </span>
                        {operations[eachCall].code === 500 ? (
                          <PFButton
                            label="Retry"
                            size="small"
                            onClick={retry}
                          />
                        ) : (
                          <PFButton
                            label="Go To Project"
                            size="small"
                            text
                            severity=" "
                            className="p-0"
                            onClick={() =>
                              window.open(
                                `/project/view/${selectedProject.project_project_id}`
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <span
                      {...(operations[eachCall].status === 0
                        ? {
                            className: 'text-300',
                          }
                        : {})}
                    >
                      {operations[eachCall].label}
                    </span>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};
export default CxSchedulerValidationDialog;
