import React from 'react';
/*Prime React Imports*/
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { itemKeys } from './components/LineItems.model';

export const QuotePreview = ({ quoteDetails, clientDetails }) => {
  const Columns = itemKeys.filter(({ key }) => key !== 'action');
  return (
    <>
      <div className="flex">
        <p className="font-bold mr-3">
          {' '}
          <img src={quoteDetails?.tenantDetail?.logo} alt="" />
        </p>
      </div>
      <div className="flex flex-row">
        <div className="flex-1 flex-column">
          <div className="flex pb-2 font-bold">{quoteDetails?.companyName}</div>
          <div className="flex pb-2">{quoteDetails?.clientName}</div>
          <div className="flex text-sm">
            {' '}
            {quoteDetails?.clientDetails?.address?.address1}
            {quoteDetails?.clientDetails?.address?.address2 ? (
              <>
                <br />
                {quoteDetails?.clientDetails?.address?.address2}
              </>
            ) : (
              <></>
            )}
            <br />
            {quoteDetails?.clientDetails?.address?.city}
            <br />
            {quoteDetails?.clientDetails?.address?.state}
            <br />
            {quoteDetails?.clientDetails?.address?.zipcode}
          </div>
          <div className="flex text-sm">
            {' '}
            {quoteDetails?.clientDetails?.customer_emails?.[0]?.email}
          </div>
          <div className="flex text-sm">
            {quoteDetails?.clientDetails?.primary_phone}
          </div>
        </div>
        <div className="flex flex-row justify-content-end text-sm">
          <div className="flex  flex-column align-items-end pr-1">
            <div className="flex">Quote No:</div>
            <div className="flex">Date:</div>
            <div className="flex">Expiry Date:</div>
            <div className="flex">Recurrence:</div>
            <div className="flex">Payment Duration:</div>
          </div>
          <div className="flex flex-column">
            <div className="flex font-bold">{quoteDetails?.quoteId}</div>
            <div className="flex">{quoteDetails?.createDate}</div>
            <div className="flex">{quoteDetails?.expiryDate}</div>
            <div className="flex">{quoteDetails?.recurrence}</div>
            <div className="flex">{quoteDetails?.amount?.paymentTerms}</div>
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="w-12 w-full">
          <table className="w-full lineItemTable">
            <thead>
              <tr>
                {Columns.map(({ key, value }) => (
                  <th key={key} className="text-sm">
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {quoteDetails?.lineItems?.items?.length > 0 ? (
                quoteDetails?.lineItems?.items.map((row, index) => (
                  <tr
                    key={index}
                    className="text-center  border-bottom-1 border-primary-100"
                  >
                    {Columns.map(
                      ({ key, prefix = '', postfix = '', func = null }) => (
                        <td key={key}>
                          {row[key] ? prefix : ''}
                          {func ? func(row[key]) : row[key] || '-'}
                          {row[key] ? postfix : ''}
                        </td>
                      )
                    )}
                  </tr>
                ))
              ) : (
                <tr className="border-bottom-1 border-primary-100">
                  <td colSpan="10 align-items-center text-center">
                    No Items found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-wrap justify-content-end text-sm">
        <div className="flex  flex-column align-items-end pr-1">
          <div className="flex pb-1">Sub Total:</div>
          <div className="flex pb-1">Tax:</div>
          <div className="flex pb-1">Discount:</div>
          <div className="flex pb-1">Total:</div>
          <div className="flex pb-1">Required Deposit:</div>
          <div className="flex pb-1">Remaining Amount to be paid:</div>
        </div>
        <div className="flex flex-column">
          <div className="flex pb-1">
            {quoteDetails?.lineItems?.subTotal
              ? `$${quoteDetails.lineItems.subTotal.toFixed(2)}`
              : '-'}
          </div>
          <div className="flex pb-1">
            {quoteDetails?.lineItems?.tax
              ? `${quoteDetails.lineItems.tax}%`
              : '-'}
          </div>
          <div className="flex pb-1">
            {quoteDetails?.lineItems?.discount
              ? `${quoteDetails.lineItems.discount}%`
              : '-'}
          </div>
          <div className="flex pb-1">
            {quoteDetails?.lineItems?.total
              ? `$${quoteDetails.lineItems.total.toFixed(2)}`
              : '-'}
          </div>
          <div className="flex pb-1">
            {quoteDetails?.amount?.payableAmount
              ? `$${quoteDetails.amount.payableAmount.toFixed(2)}`
              : '-'}
          </div>
          <div className="flex pb-1">
            {quoteDetails?.amount?.amountToBePaidAfterService
              ? `$${quoteDetails.amount.amountToBePaidAfterService.toFixed(2)}`
              : '-'}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-column border-bottom-1 border-400 text-sm">
        {' '}
        <div className="flex font-bold">{quoteDetails?.tenantDetail?.client_name}</div>
        <div className="flex">{quoteDetails?.tenantDetail?.email}</div>
        <div className="flex">{quoteDetails?.tenantDetail?.phone_number}</div>
      </div> */}
      <div className="flex flex-column text-sm">
        <div className="flex font-bold">
          {quoteDetails?.senderDetails?.name}
        </div>
        <div className="flex">{quoteDetails?.senderDetails?.email}</div>
        <div className="flex">{quoteDetails?.senderDetails?.phone}</div>
      </div>
      <div className="flex flex-column text-sm border-bottom-1 border-400">
        <div className="flex">
          <p className="font-bold">Terms and Conditions</p>
        </div>
        <div className="flex">{quoteDetails?.termAndConditions}</div>
      </div>
    </>
  );
};
