import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  },
  input: {
    color: theme.palette.text.primary,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
