import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';

import PFColorPicker from '../../../shared/PFPrime/PFColorPicker';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import apiSerive from '../../../../services/api.service';

const EditColorDialog = ({
  visible,
  setVisible,
  editColor,
  setLoading,
  setReloadList,
  toast,
}) => {
  const updateColorCode = async value => {
    try {
      const response = await apiSerive.patch(
        `/admin-config/color-config/${value?.color_config_id}`,
        {
          color_code: value?.color_code?.startsWith('#')
            ? value?.color_code
            : `#${value?.color_code}`,
          color_name: value?.color_name,
        }
      );
      if (response && response?.status) {
        toast.current?.show({
          severity: 'success',
          summary: `${response?.message}`,
        });
        setReloadList(true);
        setVisible(false);
      } else {
        toast.current?.show({
          severity: 'error',
          summary: `${response?.message}`,
        });
      }
    } catch (error) {
      console.error(error);
      setReloadList(false);
      toast.current?.show({
        severity: 'error',
        summary: `Unable to update this color config`,
      });
    } finally {
      setReloadList(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      color_config_id: editColor?.color_config_id,
      color_code: editColor?.color_code,
      color_name: editColor?.color_name,
    },
    onSubmit: values => {
      updateColorCode(values);
    },
    enableReinitialize: true,
  });
  return (
    <>
      <Dialog
        header="Edit Color Code"
        visible={visible}
        style={{ width: '40vw' }}
        onHide={() => {
          setVisible(false);
          formik.reset();
        }}
      >
        <div className="card w-12">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2"
          >
            <div className="grid mt-2">
              <div className="grid col-12">
                <div className="col-12 md:col-5 lg:col-5">
                  <PFColorPicker
                    id="color_code"
                    inline
                    value={formik?.values?.color_code}
                    onChange={e => {
                      formik.setFieldValue('color_code', e?.value);
                    }}
                  />
                </div>
                <div className="col-12 md:col-7 lg:col-7  flex flex-column flex-wrap align-items-center justify-content-center">
                  <div className="col-12 mb-3 py-0">
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          background: formik?.values?.color_code?.startsWith(
                            '#'
                          )
                            ? `${formik?.values?.color_code}`
                            : `#${formik?.values?.color_code}`,
                        }}
                      ></span>
                      <span className="p-float-label">
                        <PFInputText
                          id="color_code"
                          className="w-12"
                          value={formik?.values?.color_code}
                          onChange={e => {
                            formik.setFieldValue(
                              'color_code',
                              e?.target?.value
                            );
                          }}
                          maxLength={10}
                        />
                        <label htmlFor="tenant_id">Color Code</label>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 mb-3  py-0">
                    <span className="p-float-label">
                      <PFInputText
                        id="color_name"
                        className="w-12"
                        value={formik?.values?.color_name}
                        onChange={e => {
                          formik.setFieldValue('color_name', e?.target?.value);
                        }}
                        maxLength={70}
                      />
                      <label htmlFor="tenant_id">Color Name</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-12 px-0 text-right">
                <PFButton
                  id="shade"
                  type="submit"
                  label="Update"
                  className="ml-2"
                  size="small"
                  disabled={
                    !formik?.values?.color_code ||
                    !formik?.values?.color_name ||
                    !formik.dirty
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default EditColorDialog;
