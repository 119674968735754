// **** External Utilities ****
import axios from 'axios';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

export const getLaborProfilesList = async (
  searchQuery,
  setLoading,
  setLaborProfilesListData,
  setCount
) => {
  try {
    const newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/installers/reports/labor-profile-list?${newQueryString}`
    );
    setLaborProfilesListData(response?.data?.items);
    setCount(response?.data?.count);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Profile Items Details by installet Id
export const getlaborProfileItemsDataByInstallerId = async (
  installerId,
  setLoading,
  setAlert,
  setLaborProfileItemsData
) => {
  try {
    setLoading(true);
    await axios
      .get(
        `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/labor-profile-items`
      )
      .then(response => {
        setLaborProfileItemsData(response?.data);
      });
  } catch (error) {
    setAlert('error', 'Error in Fetching Labor Profile items Data');
  } finally {
    setLoading(false);
  }
};

export const addNewLaborProfile = async (
  createObj,
  history,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${createObj.installer_id}/labor-profile`,
      {
        default_percent_paid: createObj.default_percent_paid.replace('%', ''),
      }
    );
    if (response.status === 200) {
      history.push(`/laborprofiles/view/${response.data?.installer_id}`);
      setAlert('success', 'Labor Profile Created successfully.');
    }
  } catch (error) {
    setAlert('error', 'Error while creating Labor Profile.', false, true);
  } finally {
    setLoading(false);
  }
};
export const updateLaborProfile = async (
  installerId,
  laborProfileId,
  updateObj,
  history,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/labor-profile/${laborProfileId}`,
      {
        default_percent_paid: updateObj.default_percent_paid.replace('%', ''),
      }
    );
    if (response.status === 200) {
      history.push(`/laborprofiles/view/${response.data?.installer_id}`);
      setAlert('success', 'Labor Profile updated successfully.');
    }
  } catch (error) {
    setAlert('error', 'Error while updating Labor Profile.', false, true);
  } finally {
    setLoading(false);
  }
};

export const deleteLaborProfileItemById = async (
  laborProfile,
  setLoading,
  setReloadList,
  setAlert
) => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${laborProfile.installer_id}/labor-profile/${laborProfile.installer_labor_profile_id}`
    );
    if (response.status === 200) {
      setReloadList(true);
      setAlert('success', 'Labor profile deleted successfully');
    }
  } catch (error) {
    setAlert('error', 'Error while deleting Labor profile.', false, true);
  }
};

export const cloneLaborProfile = async (
  cloneData,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${cloneData.installerId}/labor-profile/${cloneData.laborProfileid}/clone`
    );
    if (response.status === 200) {
      setReloadList(true);
      setAlert('success', 'Labor Profile cloned successfully.');
    }
  } catch (error) {
    setAlert('error', 'Error while cloning Labor Profile.', false, true);
  }
};

// Get Active Installers
export const getActiveInstallers = async (
  setActiveInstallersListData,
  setAlert
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers-without-labor-profile`
    );
    response?.data && setActiveInstallersListData(response?.data);
  } catch (error) {
    setAlert('error', 'Error while fetching active Installers', false, true);
  }
};
//Create Labor Profile Item
export const createNewLaborProfileItem = async (
  installerId,
  values,
  setLoading,
  setIsOpen,
  setReloadLaborProfileItemsList,
  setAlert
) => {
  try {
    setLoading(true);
    const creatObj = {
      store_ids: values.store_ids,
      item_id: values.item_id,
      cost:
        values?.cost || values?.cost === 0
          ? parseFloat(values?.cost?.replace('$', ''))
          : null,
      percent:
        values?.percent || values?.percent === 0
          ? parseFloat(values?.percent?.replace('%', ''))
          : null,
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/labor-profile-items`,
      creatObj
    );
    if (response.status === 200) {
      setReloadLaborProfileItemsList(true);
      setAlert('success', 'Labor Profile Item added successfully', false, true);
    }
  } catch (error) {
    setLoading(false);
    setAlert('error', error?.response?.data?.error);
  } finally {
    setIsOpen(false);
  }
};

//Edit Labor Profile Item
export const editLaborProfileItem = async (
  installerId,
  laborProfileItemId,
  editObj,
  setLoading,
  setIsOpen,
  setReloadLaborProfileItemsList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const updateObj = {
      store_ids: editObj?.store_ids,
      item_id: editObj?.item_id,
      cost:
        editObj?.cost || editObj?.cost === 0
          ? parseFloat(editObj?.cost?.replace('$', ''))
          : null,
      percent:
        editObj?.percent || editObj?.percent === 0
          ? parseFloat(editObj?.percent?.replace('%', ''))
          : null,
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/labor-profile-items/${laborProfileItemId}`,
      updateObj
    );
    if (response?.status === 200) {
      setReloadLaborProfileItemsList(true);
      setAlert(
        'success',
        'Labor Profile Item Updated successfully',
        false,
        true
      );
    } else {
      throw 'Error in Labor Profile item updation';
    }
  } catch (error) {
    setLoading(false);
    setReloadLaborProfileItemsList(true);
    setAlert('error', error?.response?.data?.error, false, true);
  }
};

//Delete Labor Profile Item
export const deleteLaborProfileItem = async (
  installerId,
  laborProfileItemId,
  setLoading,
  setAlert,
  setReloadLaborProfileItemsList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/labor-profile-items/${laborProfileItemId}`
    );
    if (response) {
      setReloadLaborProfileItemsList(true);
      setAlert(
        'success',
        'Labor Profile Item Deleted successfully',
        false,
        true
      );
    }
  } catch (error) {
    setAlert('error', 'Error in Labor Profile item deletion', false, true);
  }
};

// Get Stores
export const getStores = async setStoresList => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/reports`
    );
    setStoresList(response?.data?.items);
  } catch (error) {
    console.log(error);
  }
};
// Get Items
export const getItems = async (
  setItemsList,
  setItemsLoading,
  setCurrentOffset,
  itemsList = [],
  offset = 0
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/items`
    );
    if (response.data) {
      setCurrentOffset(offset + 1);
      setItemsList([...itemsList, ...response.data]);
      setItemsLoading(true);
    }
  } catch (error) {
    console.log(error);
  }
};

//To fetch Installers list
export const getInstallers = async setRoleOptions => {
  try {
    const response = await apiService.get('/auth/role/installer-roles');
    response?.data && setRoleOptions(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getWorkroom = async setWorkRoomOptions => {
  try {
    const response = await apiService.get('/system/workroom');
    response && setWorkRoomOptions(response);
  } catch (error) {
    console.error(error);
  }
};

export const searchTechnicialList = async (
  roleId,
  workroomIds,
  technicianName,
  setSearchTechnicianData,
  hasLabor,
  setLaborloading
) => {
  try {
    setLaborloading(true);
    let response;

    const hasLaborProfile = hasLabor === 'Yes' ? 1 : 0;

    if (technicianName && technicianName?.length > 2) {
      response = await apiService.get(
        `/auth/user/report/search-technicians?name=${technicianName}&has_labor_profile=${hasLaborProfile}`
      );
    } else {
      setSearchTechnicianData([]);
    }
    if (roleId && !isNaN(roleId)) {
      response = await apiService.get(
        `/auth/user/report/search-technicians?roleId=${roleId}&has_labor_profile=${hasLaborProfile}`
      );
    } else {
      setSearchTechnicianData([]);
    }
    if (workroomIds) {
      response = await apiService.get(
        `/auth/user/report/search-technicians?workroomIds=${workroomIds}&has_labor_profile=${hasLaborProfile}`
      );
    } else {
      setSearchTechnicianData([]);
    }
    if (response) {
      setSearchTechnicianData(response?.data);
      setLaborloading(false);
    } else {
      setLaborloading(false);
    }
  } catch (error) {
    setLaborloading(false);

    console.error(error);
  }
};

export const getHasNoLaborProfile = async (
  hasLabor,
  setLaborloading,
  setSearchTechnicianData
) => {
  const hasLaborProfile = hasLabor === 'Yes' ? 1 : 0;
  setLaborloading(true);

  try {
    const response = await apiService.get(
      `/auth/user/report/search-technicians?has_labor_profile=${hasLaborProfile}`
    );
    if (response?.data) {
      setSearchTechnicianData(response?.data);
      setLaborloading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

export const cloneLaborItem = async payload => {
  try {
    const response = await apiService.post(
      `/installers/clone-labor-profile`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
