// **** React Imports ****
import React, { useRef } from 'react';

// **** Utilities ****
import { Grid, TextField, Button, Typography, Paper } from '@material-ui/core';
import { Close, AttachFile } from '@material-ui/icons';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import TextEditor from '../../shared/TextEditor/TextEditor';

// **** Styles *****
import { useStyles } from './EmailNotifications.styles';

import 'react-multi-email/style.css';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import { sendEmail } from './EmailNotifications.service';

const ComposeEmail = React.memo(
  ({
    project_id,
    project_number,
    storeDetails,
    setShowPanel,
    setAlert,
    customerDetails,
  }) => {
    const classes = useStyles();
    const inputFileUpload = useRef(null);
    const [apiRunning, setApiRunning] = React.useState(false);
    const [toEmails, setToEmails] = React.useState(
      storeDetails[0]?.pse_email
        ? [storeDetails[0]?.pse_email]
        : customerDetails[0]?.customer_emails[0]?.email
          ? [customerDetails[0]?.customer_emails[0]?.email]
          : []
    );
    const [toEmailsError, setToEmailsError] = React.useState(null);
    const [subject, setSubject] = React.useState(
      `Regarding the ${project_number}`
    );
    const [subjectError, setSubjectError] = React.useState(null);
    const [messageBody, setMessageBody] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [imgErrors, setImgErrors] = React.useState(false);
    const ImageValidation = type => {
      if (type.includes('svg')) {
        setImgErrors(true);
        return false;
      } else {
        setImgErrors(false);
        return true;
      }
    };

    const handleSubmit = () => {
      let errors = 0;
      // Check For To Email Not Empty.
      if (!Array.isArray(toEmails) || toEmails.length === 0) {
        setToEmailsError('Please enter any one email address.');
        errors = 1;
      } else {
        setToEmailsError(null);
      }

      // Check For Subject Not Empty.
      if (!subject || subject.length === 0) {
        setSubjectError('Subject Field must not be empty.');
        errors = 1;
      } else {
        setSubjectError(null);
      }
      if (!errors) {
        const messageData = new FormData();
        toEmails && messageData.append('to', toEmails.toString());
        subject && messageData.append('subject', subject);
        messageBody && messageData.append('body', messageBody);
        Array.isArray(files) &&
          files.length &&
          files.map(response => {
            response?.name &&
              response?.file &&
              messageData.append('file', response?.file, response?.name);
          });
        sendEmail(
          project_id,
          messageData,
          setApiRunning,
          setShowPanel,
          setAlert
        );
      }
    };
    return (
      <Grid container spacing={2} className={classes.createEmailBox}>
        {apiRunning && (
          <Grid container justifyContent="center">
            <PFTableLoader />
          </Grid>
        )}
        <Grid
          pt={12}
          pb={12}
          container
          item
          className={classes.createEmailBoxHeader}
        >
          <Grid item xs={11} className={classes.createEmailBoxHeaderFirst}>
            <Typography variant="h3" component="h3" className="my-3">
              New Message
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            className={`${classes.createEmailBoxHeaderLast} mt-3 pt-1`}
          >
            <Paper
              onClick={() => {
                setShowPanel('listView');
              }}
            >
              <Close />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} className="pt-1">
          <ReactMultiEmail
            placeholder="To"
            emails={toEmails}
            onChange={_emails => {
              setToEmails(_emails);
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          {toEmailsError && (
            <div className={classes.error}>{toEmailsError}</div>
          )}
          <TextField
            required
            name="subject"
            id="subject"
            placeholder="Subject"
            defaultValue={subject}
            onChange={event => {
              const value = event.target.value;
              setSubject(value);
            }}
            className="mui-subject"
          />
          {subjectError && <div className={classes.error}>{subjectError}</div>}
          <TextEditor setValue={setMessageBody} value={messageBody} />
        </Grid>
        <Grid item xs={12} className={classes.createEmailBoxFooter}>
          <Paper
            className="mr-2 p-0"
            onClick={() => {
              inputFileUpload.current.click();
            }}
          >
            <Button>
              <AttachFile />
            </Button>
          </Paper>
          <Button
            disabled={apiRunning}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSubmit}
          >
            Send
          </Button>
          <input
            type="file"
            id="emailFileUpload"
            name="emailFileUpload"
            className={classes.fileInput}
            ref={inputFileUpload}
            onClick={event => {
              event.target.value = null;
            }}
            onChange={event => {
              if (
                event.target.files &&
                event.target.files[0] &&
                ImageValidation(event.target.files[0].type)
              ) {
                setFiles([
                  ...files,
                  {
                    name: event.target.files[0].name,
                    file: event.target.files[0],
                  },
                ]);
              }
            }}
          />

          {files && (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  {files.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        <Paper
                          className={classes.deletFileIcon}
                          onClick={() => {
                            const newFilesArray = [...files];
                            newFilesArray.splice(index, 1);
                            setFiles(newFilesArray);
                          }}
                        >
                          <Close />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {imgErrors && (
          <Typography variant="caption" component="p" color="error">
            System does not accept svg files.
          </Typography>
        )}
      </Grid>
    );
  }
);

export default ComposeEmail;
