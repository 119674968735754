import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import _ from 'lodash';

import { PFDataTableEdit, PFButton } from '../pf-prime/Prime';

import {
  transferData,
  addNewRow,
  updateRowData,
  displayDelayed,
  getTansfers,
  resetCount,
  editDataTableRow,
} from './InventoryTransfer.model';
import { transferClearState } from './reducers/InventoryTransferSlice';
import TableLoader from './Loader/TableLoader';

const InventoryTransfer = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  let { itemInfo } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  let { listItems: transferItems } = useSelector(
    state => state.inventoryManagment?.Transfer
  );

  const [data, setData] = useState({});
  const [rowInserted, setRowInserted] = useState(false);
  const [updateRow, setUpdateRow] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [deletedRow, setDeletedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataTableloading, setDataTableloading] = useState(false);
  const [onCancel, setOnCancel] = useState(false);
  const [transferError, setTransferError] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(transferClearState());
      setData({});
      setRowInserted(false);
      setUpdateRow(null);
      setIsDisabled(false);
      setDeletedRow(null);
      setLoading(true);
      setDataTableloading(false);
      setOnCancel(false);
      resetCount();
    };
  }, []);

  useEffect(() => {
    itemInfo?._id && getTansfers(itemInfo?._id, dispatch, setDataTableloading);
    if (itemInfo?.unit.length === 0) {
      setIsDisabled(true);
    }
  }, [itemInfo]);

  useEffect(() => {
    if (onCancel) {
      itemInfo?._id &&
        getTansfers(itemInfo?._id, dispatch, setDataTableloading);
      setOnCancel(false);
    }
  }, [onCancel]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(Math.random());
  useEffect(() => {
    setData(
      transferData(
        setUpdateRow,
        setIsDisabled,
        setDeletedRow,
        setOnCancel,
        setSelectedWarehouse,
        selectedWarehouse
      )
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    setSelectedWarehouse(null);
    if (
      data?.listItems &&
      data?.listItems?.length === 0 &&
      transferItems?.length > 0
    ) {
      const newSet = {
        ...data,
        listItems: _.cloneDeep(transferItems).reverse(),
      };
      setData(newSet);
    }
  }, [data, transferItems]);

  useEffect(() => {
    setSelectedWarehouse(null);
    setData(s => ({ ...s, listItems: _.cloneDeep(transferItems).reverse() }));
  }, [transferItems]);

  useEffect(() => {
    setSelectedWarehouse(null);
    setDataTableloading(true);
    updateRow &&
      updateRowData(
        updateRow,
        data,
        setData,
        'U',
        dispatch,
        toast,
        setDataTableloading,
        setTransferError
      );
  }, [updateRow]);

  useEffect(() => {
    setSelectedWarehouse(null);
    setDataTableloading(true);
    deletedRow &&
      updateRowData(
        deletedRow,
        data,
        setData,
        'D',
        dispatch,
        toast,
        setDataTableloading
      );
  }, [deletedRow]);

  useEffect(() => {
    rowInserted && displayDelayed(rowInserted, setRowInserted);
  }, [rowInserted]);

  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      <div style={{ minHeight: '70vh' }} className="inventory">
        <div className="col-12 md:col-6 lg:col-11 p-input-icon-right mb-3 m-0 p-0 ">
          <h2>Inventory Transfer</h2>
        </div>
        <div className="col-12 md:col-6 lg:col-1 p-input-icon-right mb-3 m-0 p-0">
          <div className="flex justify-content-end">
            <PFButton
              icon="pi pi-plus"
              severity={'primary'}
              onClick={() => addNewRow(data, setData, setRowInserted, itemInfo)}
              className="mb-1"
              disabled={isDisabled}
            />
          </div>
        </div>
        <div>
          {loading ? (
            <TableLoader columnCount={5} noOfRow={4} mTopBottom={0} />
          ) : (
            <PFDataTableEdit
              data={data}
              setIsDisabled={setIsDisabled}
              paginator={false}
              rows={10}
              loading={dataTableloading}
              transferError={transferError}
              setTransferError={setTransferError}
              editDataTableRow={editDataTableRow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryTransfer;
