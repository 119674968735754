import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import PFInputText from '../../PFPrime/PFInputText';
import PFButton from '../../PFPrime/PFButton';
import PFAutoComplete from '../../../shared/PFPrime/PFAutoComplete.jsx';
import ApiService from '../../../../services/api.service';
import { homeDepotOrder, SOURCE_SYSTEMS } from '../../../../constants';
import PFCheckbox from '../../PFPrime/PFCheckbox';

const PullOrder = ({ dailogVisible, setDailogVisible }) => {
  const { HD_ORDER, SF_AND_I_ORDER, HD_LEAD, BLINDS_ORDER } = homeDepotOrder;
  const history = useHistory();
  const toast = useRef(null);
  const storeRef = useRef(null);

  const [storeSearchResults, setStoreSearchResults] = useState([]);

  const [poNumber, setPoNumber] = useState('');
  const [storeValue, setStoreValue] = useState('');
  const [orderMark, setOrderMark] = useState(null);
  const [hdLead, setHdLead] = useState('');
  const [blindsOrder, setBlindsOrder] = useState('');
  const [mvendor, setMvendor] = useState('');

  const searchStore = async search => {
    try {
      const response = await ApiService.get(
        `/stores/reports?limit=10&offset=0&search_string=${search}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const suggestStoreNumber = async event => {
    const response = await searchStore(event?.query || '');
    if (response && response?.items) {
      setStoreSearchResults(response?.items);
    }
  };
  const setStore = event => {
    setStoreValue(event?.target?.value);
  };

  const pullOrders = async () => {
    try {
      let payload = {};
      payload.source_system_id = SOURCE_SYSTEMS.HOME_DEPOT;
      payload.order_mark = orderMark || HD_ORDER;
      if (
        (orderMark === HD_ORDER ||
          orderMark === SF_AND_I_ORDER ||
          !orderMark) &&
        typeof storeValue === 'object'
      ) {
        payload.po_number = poNumber;
        payload.store_number =
          storeValue && storeValue?.store_number
            ? `${storeValue.store_number}`
            : storeValue;
      }
      if (orderMark === HD_LEAD) {
        payload.hd_lead = hdLead;
        payload.mvendor = mvendor;
      }
      if (orderMark === BLINDS_ORDER) {
        payload.blinds_order = blindsOrder;
      }

      const response = await ApiService.post(
        `/rule-engine/pull-project-from-source`,
        payload
      );

      if (response && response?.status) {
        setDailogVisible(false);
        setPoNumber('');

        setStoreValue('');
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${response?.message}`,
          life: 4000,
        });
        setTimeout(() => {
          history.push(`/admin/ruleenginelogs/${poNumber}`);
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
      });
    }
  };
  const handleSubmit = () => {
    pullOrders();
  };

  const filterStores = event => {
    const query = event?.query;
    const filteredStores = storeList?.filter(store =>
      store?.store_number.toString().startsWith(query.toLowerCase())
    );

    setSuggestions(filteredStores);
  };

  const onStoreSelect = event => {
    setSelectedStore(event?.value);
  };

  const itemTemplate = store => {
    return <div>{store?.store_number}</div>;
  };

  const resetForm = () => {
    setPoNumber('');
    setStoreValue('');
    setHdLead('');
    setBlindsOrder('');
  };

  const formValidation = () => {
    let res = true;
    if (orderMark === HD_ORDER || orderMark === SF_AND_I_ORDER || !orderMark) {
      res = !poNumber?.length || !storeValue?.store_number;
    }
    if (orderMark === HD_LEAD) {
      res = !hdLead || !mvendor;
    }
    if (orderMark === BLINDS_ORDER) {
      res = !blindsOrder;
    }
    return res;
  };

  useEffect(() => {
    resetForm();
  }, [orderMark]);

  return (
    <>
      <div>
        <Toast ref={toast} />
        <Dialog
          header="Pull Home Depot Project"
          visible={dailogVisible}
          style={{ width: '45vw' }}
          onHide={() => {
            setDailogVisible(false);
            resetForm();
          }}
        >
          <div className="grid py-3">
            <div className="col-3 md:col-3 lg:col-3 mb-3 py-0">
              <PFCheckbox
                name="pull_project_home_depot"
                value={HD_ORDER}
                onChange={e => setOrderMark(e.target.value)}
                checked={orderMark === HD_ORDER || !orderMark}
              />
              <label htmlFor={'pull_project_home_depot'} className="ml-2">
                {'HD Order'}
              </label>
            </div>
            <div className="col-3 md:col-3 lg:col-3 mb-3 py-0">
              <PFCheckbox
                name="pull_project_home_depot"
                value={SF_AND_I_ORDER}
                onChange={e => setOrderMark(e.target.value)}
                checked={orderMark === SF_AND_I_ORDER}
              />
              <label htmlFor={'pull_project_home_depot'} className="ml-2">
                {'SF & I Order'}
              </label>
            </div>
            <div className="col-3 md:col-3 lg:col-3 mb-3 py-0">
              <PFCheckbox
                name="pull_project_home_depot"
                value={HD_LEAD}
                onChange={e => setOrderMark(e.target.value)}
                checked={orderMark === HD_LEAD}
              />
              <label htmlFor={'pull_project_home_depot'} className="ml-2">
                {'HD Lead'}
              </label>
            </div>
            <div className="col-3 md:col-3 lg:col-3 mb-3 py-0">
              <PFCheckbox
                name="pull_project_home_depot"
                value={BLINDS_ORDER}
                onChange={e => setOrderMark(e.target.value)}
                checked={orderMark === BLINDS_ORDER}
              />
              <label htmlFor={'pull_project_home_depot'} className="ml-2">
                {'Blinds Order'}
              </label>
            </div>
            {(orderMark === HD_ORDER ||
              !orderMark ||
              orderMark === SF_AND_I_ORDER) && (
              <>
                <div className="col-12 md:col-6 lg:col-6 mb-3 py-0">
                  <span className="p-float-label w-full">
                    <PFInputText
                      className="w-full"
                      id="po_number"
                      value={poNumber}
                      onChange={e => {
                        setPoNumber(e?.target?.value);
                      }}
                      maxLength={75}
                    />
                    <label htmlFor="po_number"> PO Number</label>
                  </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6 mb-3 py-0">
                  <span className="p-float-label w-full">
                    <PFAutoComplete
                      ref={storeRef}
                      id="store_number"
                      className="w-full p-inputtext-sm"
                      suggestions={storeSearchResults}
                      field="store_number"
                      completeMethod={event => {
                        suggestStoreNumber(event);
                      }}
                      value={storeValue}
                      onChange={event => {
                        setStore(event);
                      }}
                      delay={500}
                    />

                    <label htmlFor="store_number">Store Number</label>
                  </span>
                </div>
              </>
            )}

            {orderMark === HD_LEAD && (
              <>
                <div className="col-6 md:col-6 lg:col-6 mb-3 py-0">
                  <span className="p-float-label w-full">
                    <PFInputText
                      className="w-full"
                      id="lead_number"
                      value={hdLead}
                      onChange={e => {
                        setHdLead(e?.target?.value);
                      }}
                      maxLength={10}
                    />
                    <label htmlFor="lead_number"> Lead #</label>
                  </span>
                </div>
                <div className="col-6 md:col-6 lg:col-6 mb-3 py-0">
                  <span className="p-float-label w-full">
                    <PFInputText
                      className="w-full"
                      id="mvendor"
                      value={mvendor}
                      onChange={e => {
                        setMvendor(e?.target?.value);
                      }}
                      maxLength={50}
                    />
                    <label htmlFor="MVendor">MVendor</label>
                  </span>
                </div>
              </>
            )}

            {orderMark === BLINDS_ORDER && (
              <>
                <div className="col-12 md:col-12 lg:col-12 mb-3 py-0">
                  <span className="p-float-label w-full">
                    <PFInputText
                      className="w-full"
                      id="order_id"
                      value={blindsOrder}
                      onChange={e => {
                        setBlindsOrder(e?.target?.value);
                      }}
                      maxLength={50}
                    />
                    <label htmlFor="order_id"> Order ID</label>
                  </span>
                </div>
              </>
            )}

            <div className="col-12 mt-3 py-0 flex justify-content-end">
              <span className="w-full text-right">
                <PFButton
                  label={`${orderMark === HD_LEAD ? 'Pull Lead' : 'Pull Order'}`}
                  onClick={handleSubmit}
                  disabled={formValidation()}
                ></PFButton>
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default PullOrder;
