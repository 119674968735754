/* eslint-disable spellcheck/spell-checker */
export const citiesData = [
  { city: 'Abbeville', state: 'AL', state_name: 'Alabama' },
  { city: 'Adamsville', state: 'AL', state_name: 'Alabama' },
  { city: 'Alabaster', state: 'AL', state_name: 'Alabama' },
  { city: 'Albertville', state: 'AL', state_name: 'Alabama' },
  { city: 'Alexander City', state: 'AL', state_name: 'Alabama' },
  { city: 'Alexandria', state: 'AL', state_name: 'Alabama' },
  { city: 'Aliceville', state: 'AL', state_name: 'Alabama' },
  { city: 'Andalusia', state: 'AL', state_name: 'Alabama' },
  { city: 'Anniston', state: 'AL', state_name: 'Alabama' },
  { city: 'Arab', state: 'AL', state_name: 'Alabama' },
  { city: 'Argo', state: 'AL', state_name: 'Alabama' },
  { city: 'Ashford', state: 'AL', state_name: 'Alabama' },
  { city: 'Ashland', state: 'AL', state_name: 'Alabama' },
  { city: 'Ashville', state: 'AL', state_name: 'Alabama' },
  { city: 'Athens', state: 'AL', state_name: 'Alabama' },
  { city: 'Atmore', state: 'AL', state_name: 'Alabama' },
  { city: 'Attalla', state: 'AL', state_name: 'Alabama' },
  { city: 'Auburn', state: 'AL', state_name: 'Alabama' },
  { city: 'Autauga County', state: 'AL', state_name: 'Alabama' },
  { city: 'Baldwin County', state: 'AL', state_name: 'Alabama' },
  { city: 'Ballplay', state: 'AL', state_name: 'Alabama' },
  { city: 'Barbour County', state: 'AL', state_name: 'Alabama' },
  { city: 'Bay Minette', state: 'AL', state_name: 'Alabama' },
  { city: 'Bayou La Batre', state: 'AL', state_name: 'Alabama' },
  { city: 'Bear Creek', state: 'AL', state_name: 'Alabama' },
  { city: 'Berry', state: 'AL', state_name: 'Alabama' },
  { city: 'Bessemer', state: 'AL', state_name: 'Alabama' },
  { city: 'Bibb County', state: 'AL', state_name: 'Alabama' },
  { city: 'Birmingham', state: 'AL', state_name: 'Alabama' },
  { city: 'Blount County', state: 'AL', state_name: 'Alabama' },
  { city: 'Blountsville', state: 'AL', state_name: 'Alabama' },
  { city: 'Blue Ridge', state: 'AL', state_name: 'Alabama' },
  { city: 'Boaz', state: 'AL', state_name: 'Alabama' },
  { city: 'Brent', state: 'AL', state_name: 'Alabama' },
  { city: 'Brewton', state: 'AL', state_name: 'Alabama' },
  { city: 'Bridgeport', state: 'AL', state_name: 'Alabama' },
  { city: 'Brighton', state: 'AL', state_name: 'Alabama' },
  { city: 'Brook Highland', state: 'AL', state_name: 'Alabama' },
  { city: 'Brookside', state: 'AL', state_name: 'Alabama' },
  { city: 'Brookwood', state: 'AL', state_name: 'Alabama' },
  { city: 'Brundidge', state: 'AL', state_name: 'Alabama' },
  { city: 'Bullock County', state: 'AL', state_name: 'Alabama' },
  { city: 'Butler', state: 'AL', state_name: 'Alabama' },
  { city: 'Butler County', state: 'AL', state_name: 'Alabama' },
  { city: 'Bynum', state: 'AL', state_name: 'Alabama' },
  { city: 'Cahaba Heights', state: 'AL', state_name: 'Alabama' },
  { city: 'Calera', state: 'AL', state_name: 'Alabama' },
  { city: 'Calhoun County', state: 'AL', state_name: 'Alabama' },
  { city: 'Camden', state: 'AL', state_name: 'Alabama' },
  { city: 'Carbon Hill', state: 'AL', state_name: 'Alabama' },
  { city: 'Carlisle-Rockledge', state: 'AL', state_name: 'Alabama' },
  { city: 'Carrollton', state: 'AL', state_name: 'Alabama' },
  { city: 'Cedar Bluff', state: 'AL', state_name: 'Alabama' },
  { city: 'Center Point', state: 'AL', state_name: 'Alabama' },
  { city: 'Centre', state: 'AL', state_name: 'Alabama' },
  { city: 'Centreville', state: 'AL', state_name: 'Alabama' },
  { city: 'Chalkville', state: 'AL', state_name: 'Alabama' },
  { city: 'Chambers County', state: 'AL', state_name: 'Alabama' },
  { city: 'Chatom', state: 'AL', state_name: 'Alabama' },
  { city: 'Chelsea', state: 'AL', state_name: 'Alabama' },
  { city: 'Cherokee', state: 'AL', state_name: 'Alabama' },
  { city: 'Cherokee County', state: 'AL', state_name: 'Alabama' },
  { city: 'Chickasaw', state: 'AL', state_name: 'Alabama' },
  { city: 'Childersburg', state: 'AL', state_name: 'Alabama' },
  { city: 'Chilton County', state: 'AL', state_name: 'Alabama' },
  { city: 'Choccolocco', state: 'AL', state_name: 'Alabama' },
  { city: 'Choctaw County', state: 'AL', state_name: 'Alabama' },
  { city: 'Citronelle', state: 'AL', state_name: 'Alabama' },
  { city: 'Clanton', state: 'AL', state_name: 'Alabama' },
  { city: 'Clarke County', state: 'AL', state_name: 'Alabama' },
  { city: 'Clay', state: 'AL', state_name: 'Alabama' },
  { city: 'Clay County', state: 'AL', state_name: 'Alabama' },
  { city: 'Clayton', state: 'AL', state_name: 'Alabama' },
  { city: 'Cleburne County', state: 'AL', state_name: 'Alabama' },
  { city: 'Cleveland', state: 'AL', state_name: 'Alabama' },
  { city: 'Clio', state: 'AL', state_name: 'Alabama' },
  { city: 'Creola', state: 'AL', state_name: 'Alabama' },
  { city: 'Crossville', state: 'AL', state_name: 'Alabama' },
  { city: 'Cullman', state: 'AL', state_name: 'Alabama' },
  { city: 'Cullman County', state: 'AL', state_name: 'Alabama' },
  { city: 'Dadeville', state: 'AL', state_name: 'Alabama' },
  { city: 'Dale County', state: 'AL', state_name: 'Alabama' },
  { city: 'Daleville', state: 'AL', state_name: 'Alabama' },
  { city: 'Dallas County', state: 'AL', state_name: 'Alabama' },
  { city: 'Danville', state: 'AL', state_name: 'Alabama' },
  { city: 'Daphne', state: 'AL', state_name: 'Alabama' },
  { city: 'Dauphin Island', state: 'AL', state_name: 'Alabama' },
  { city: 'Deatsville', state: 'AL', state_name: 'Alabama' },
  { city: 'Decatur', state: 'AL', state_name: 'Alabama' },
  { city: 'DeKalb County', state: 'AL', state_name: 'Alabama' },
  { city: 'Demopolis', state: 'AL', state_name: 'Alabama' },
  { city: 'Dixiana', state: 'AL', state_name: 'Alabama' },
  { city: 'Dora', state: 'AL', state_name: 'Alabama' },
  { city: 'Dothan', state: 'AL', state_name: 'Alabama' },
  { city: 'Double Springs', state: 'AL', state_name: 'Alabama' },
  { city: 'East Brewton', state: 'AL', state_name: 'Alabama' },
  { city: 'East Florence', state: 'AL', state_name: 'Alabama' },
  { city: 'Eclectic', state: 'AL', state_name: 'Alabama' },
  { city: 'Elba', state: 'AL', state_name: 'Alabama' },
  { city: 'Elberta', state: 'AL', state_name: 'Alabama' },
  { city: 'Elmore', state: 'AL', state_name: 'Alabama' },
  { city: 'Elmore County', state: 'AL', state_name: 'Alabama' },
  { city: 'Emerald Mountain', state: 'AL', state_name: 'Alabama' },
  { city: 'Enterprise', state: 'AL', state_name: 'Alabama' },
  { city: 'Escambia County', state: 'AL', state_name: 'Alabama' },
  { city: 'Etowah County', state: 'AL', state_name: 'Alabama' },
  { city: 'Eufaula', state: 'AL', state_name: 'Alabama' },
  { city: 'Eutaw', state: 'AL', state_name: 'Alabama' },
  { city: 'Evergreen', state: 'AL', state_name: 'Alabama' },
  { city: 'Fairfield', state: 'AL', state_name: 'Alabama' },
  { city: 'Fairhope', state: 'AL', state_name: 'Alabama' },
  { city: 'Falkville', state: 'AL', state_name: 'Alabama' },
  { city: 'Fayette', state: 'AL', state_name: 'Alabama' },
  { city: 'Fayette County', state: 'AL', state_name: 'Alabama' },
  { city: 'Fayetteville', state: 'AL', state_name: 'Alabama' },
  { city: 'Flint City', state: 'AL', state_name: 'Alabama' },
  { city: 'Flomaton', state: 'AL', state_name: 'Alabama' },
  { city: 'Florala', state: 'AL', state_name: 'Alabama' },
  { city: 'Florence', state: 'AL', state_name: 'Alabama' },
  { city: 'Foley', state: 'AL', state_name: 'Alabama' },
  { city: 'Forestdale', state: 'AL', state_name: 'Alabama' },
  { city: 'Fort Deposit', state: 'AL', state_name: 'Alabama' },
  { city: 'Fort Payne', state: 'AL', state_name: 'Alabama' },
  { city: 'Fort Rucker', state: 'AL', state_name: 'Alabama' },
  { city: 'Franklin County', state: 'AL', state_name: 'Alabama' },
  { city: 'Frisco City', state: 'AL', state_name: 'Alabama' },
  { city: 'Fultondale', state: 'AL', state_name: 'Alabama' },
  { city: 'Fyffe', state: 'AL', state_name: 'Alabama' },
  { city: 'Gadsden', state: 'AL', state_name: 'Alabama' },
  { city: 'Gardendale', state: 'AL', state_name: 'Alabama' },
  { city: 'Geneva', state: 'AL', state_name: 'Alabama' },
  { city: 'Geneva County', state: 'AL', state_name: 'Alabama' },
  { city: 'Georgiana', state: 'AL', state_name: 'Alabama' },
  { city: 'Glencoe', state: 'AL', state_name: 'Alabama' },
  { city: 'Good Hope', state: 'AL', state_name: 'Alabama' },
  { city: 'Goodwater', state: 'AL', state_name: 'Alabama' },
  { city: 'Gordo', state: 'AL', state_name: 'Alabama' },
  { city: 'Grand Bay', state: 'AL', state_name: 'Alabama' },
  { city: 'Grayson Valley', state: 'AL', state_name: 'Alabama' },
  { city: 'Graysville', state: 'AL', state_name: 'Alabama' },
  { city: 'Greene County', state: 'AL', state_name: 'Alabama' },
  { city: 'Greensboro', state: 'AL', state_name: 'Alabama' },
  { city: 'Greenville', state: 'AL', state_name: 'Alabama' },
  { city: 'Grove Hill', state: 'AL', state_name: 'Alabama' },
  { city: 'Guin', state: 'AL', state_name: 'Alabama' },
  { city: 'Gulf Shores', state: 'AL', state_name: 'Alabama' },
  { city: 'Guntersville', state: 'AL', state_name: 'Alabama' },
  { city: 'Hackleburg', state: 'AL', state_name: 'Alabama' },
  { city: 'Hale County', state: 'AL', state_name: 'Alabama' },
  { city: 'Haleyville', state: 'AL', state_name: 'Alabama' },
  { city: 'Hamilton', state: 'AL', state_name: 'Alabama' },
  { city: 'Hanceville', state: 'AL', state_name: 'Alabama' },
  { city: 'Harpersville', state: 'AL', state_name: 'Alabama' },
  { city: 'Hartford', state: 'AL', state_name: 'Alabama' },
  { city: 'Hartselle', state: 'AL', state_name: 'Alabama' },
  { city: 'Harvest', state: 'AL', state_name: 'Alabama' },
  { city: 'Hayden', state: 'AL', state_name: 'Alabama' },
  { city: 'Hayneville', state: 'AL', state_name: 'Alabama' },
  { city: 'Hazel Green', state: 'AL', state_name: 'Alabama' },
  { city: 'Headland', state: 'AL', state_name: 'Alabama' },
  { city: 'Heflin', state: 'AL', state_name: 'Alabama' },
  { city: 'Helena', state: 'AL', state_name: 'Alabama' },
  { city: 'Henagar', state: 'AL', state_name: 'Alabama' },
  { city: 'Henry County', state: 'AL', state_name: 'Alabama' },
  { city: 'Highland Lakes', state: 'AL', state_name: 'Alabama' },
  { city: 'Hokes Bluff', state: 'AL', state_name: 'Alabama' },
  { city: 'Holt', state: 'AL', state_name: 'Alabama' },
  { city: 'Holtville', state: 'AL', state_name: 'Alabama' },
  { city: 'Homewood', state: 'AL', state_name: 'Alabama' },
  { city: 'Hoover', state: 'AL', state_name: 'Alabama' },
  { city: 'Houston County', state: 'AL', state_name: 'Alabama' },
  { city: 'Hueytown', state: 'AL', state_name: 'Alabama' },
  { city: 'Huguley', state: 'AL', state_name: 'Alabama' },
  { city: 'Huntsville', state: 'AL', state_name: 'Alabama' },
  { city: 'Indian Springs Village', state: 'AL', state_name: 'Alabama' },
  { city: 'Inverness', state: 'AL', state_name: 'Alabama' },
  { city: 'Irondale', state: 'AL', state_name: 'Alabama' },
  { city: 'Jackson', state: 'AL', state_name: 'Alabama' },
  { city: 'Jackson County', state: 'AL', state_name: 'Alabama' },
  { city: 'Jacksonville', state: 'AL', state_name: 'Alabama' },
  { city: 'Jasper', state: 'AL', state_name: 'Alabama' },
  { city: 'Jefferson County', state: 'AL', state_name: 'Alabama' },
  { city: 'Jemison', state: 'AL', state_name: 'Alabama' },
  { city: 'Kimberly', state: 'AL', state_name: 'Alabama' },
  { city: 'Kinsey', state: 'AL', state_name: 'Alabama' },
  { city: 'Ladonia', state: 'AL', state_name: 'Alabama' },
  { city: 'Lafayette', state: 'AL', state_name: 'Alabama' },
  { city: 'Lake Purdy', state: 'AL', state_name: 'Alabama' },
  { city: 'Lake View', state: 'AL', state_name: 'Alabama' },
  { city: 'Lamar County', state: 'AL', state_name: 'Alabama' },
  { city: 'Lanett', state: 'AL', state_name: 'Alabama' },
  { city: 'Lauderdale County', state: 'AL', state_name: 'Alabama' },
  { city: 'Lawrence County', state: 'AL', state_name: 'Alabama' },
  { city: 'Lee County', state: 'AL', state_name: 'Alabama' },
  { city: 'Leeds', state: 'AL', state_name: 'Alabama' },
  { city: 'Leesburg', state: 'AL', state_name: 'Alabama' },
  { city: 'Level Plains', state: 'AL', state_name: 'Alabama' },
  { city: 'Limestone County', state: 'AL', state_name: 'Alabama' },
  { city: 'Lincoln', state: 'AL', state_name: 'Alabama' },
  { city: 'Linden', state: 'AL', state_name: 'Alabama' },
  { city: 'Lineville', state: 'AL', state_name: 'Alabama' },
  { city: 'Lipscomb', state: 'AL', state_name: 'Alabama' },
  { city: 'Livingston', state: 'AL', state_name: 'Alabama' },
  { city: 'Locust Fork', state: 'AL', state_name: 'Alabama' },
  { city: 'Lowndes County', state: 'AL', state_name: 'Alabama' },
  { city: 'Loxley', state: 'AL', state_name: 'Alabama' },
  { city: 'Luverne', state: 'AL', state_name: 'Alabama' },
  { city: 'Macon County', state: 'AL', state_name: 'Alabama' },
  { city: 'Madison', state: 'AL', state_name: 'Alabama' },
  { city: 'Madison County', state: 'AL', state_name: 'Alabama' },
  { city: 'Malvern', state: 'AL', state_name: 'Alabama' },
  { city: 'Marbury', state: 'AL', state_name: 'Alabama' },
  { city: 'Marengo County', state: 'AL', state_name: 'Alabama' },
  { city: 'Margaret', state: 'AL', state_name: 'Alabama' },
  { city: 'Marion', state: 'AL', state_name: 'Alabama' },
  { city: 'Marion County', state: 'AL', state_name: 'Alabama' },
  { city: 'Marshall County', state: 'AL', state_name: 'Alabama' },
  { city: 'Meadowbrook', state: 'AL', state_name: 'Alabama' },
  { city: 'Meridianville', state: 'AL', state_name: 'Alabama' },
  { city: 'Midfield', state: 'AL', state_name: 'Alabama' },
  { city: 'Midland City', state: 'AL', state_name: 'Alabama' },
  { city: 'Mignon', state: 'AL', state_name: 'Alabama' },
  { city: 'Millbrook', state: 'AL', state_name: 'Alabama' },
  { city: 'Minor', state: 'AL', state_name: 'Alabama' },
  { city: 'Mobile', state: 'AL', state_name: 'Alabama' },
  { city: 'Mobile County', state: 'AL', state_name: 'Alabama' },
  { city: 'Monroe County', state: 'AL', state_name: 'Alabama' },
  { city: 'Monroeville', state: 'AL', state_name: 'Alabama' },
  { city: 'Montevallo', state: 'AL', state_name: 'Alabama' },
  { city: 'Montgomery', state: 'AL', state_name: 'Alabama' },
  { city: 'Montgomery County', state: 'AL', state_name: 'Alabama' },
  { city: 'Moody', state: 'AL', state_name: 'Alabama' },
  { city: 'Moores Mill', state: 'AL', state_name: 'Alabama' },
  { city: 'Morgan County', state: 'AL', state_name: 'Alabama' },
  { city: 'Morris', state: 'AL', state_name: 'Alabama' },
  { city: 'Moulton', state: 'AL', state_name: 'Alabama' },
  { city: 'Moundville', state: 'AL', state_name: 'Alabama' },
  { city: 'Mount Olive', state: 'AL', state_name: 'Alabama' },
  { city: 'Mount Vernon', state: 'AL', state_name: 'Alabama' },
  { city: 'Mountain Brook', state: 'AL', state_name: 'Alabama' },
  { city: 'Munford', state: 'AL', state_name: 'Alabama' },
  { city: 'Muscle Shoals', state: 'AL', state_name: 'Alabama' },
  { city: 'New Brockton', state: 'AL', state_name: 'Alabama' },
  { city: 'New Hope', state: 'AL', state_name: 'Alabama' },
  { city: 'New Market', state: 'AL', state_name: 'Alabama' },
  { city: 'Newton', state: 'AL', state_name: 'Alabama' },
  { city: 'North Bibb', state: 'AL', state_name: 'Alabama' },
  { city: 'Punaluu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Pupukea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Royal Kunia', state: 'HI', state_name: 'Hawaii' },
  { city: 'Schofield Barracks', state: 'HI', state_name: 'Hawaii' },
  { city: 'Village Park', state: 'HI', state_name: 'Hawaii' },
  { city: 'Volcano', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wahiawā', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waialua', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waianae', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waihee-Waiehu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waikapū', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waikoloa', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailua', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailua Homesteads', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailuku', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimalu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimanalo', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimānalo Beach', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wainaku', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipahu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipi‘o Acres', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipio', state: 'HI', state_name: 'Hawaii' },
  { city: 'Whitmore Village', state: 'HI', state_name: 'Hawaii' },
  { city: 'Adams County', state: 'IL', state_name: 'Illinois' },
  { city: 'Addison', state: 'IL', state_name: 'Illinois' },
  { city: 'Albany Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Albers', state: 'IL', state_name: 'Illinois' },
  { city: 'Albion', state: 'IL', state_name: 'Illinois' },
  { city: 'Aledo', state: 'IL', state_name: 'Illinois' },
  { city: 'Alexander County', state: 'IL', state_name: 'Illinois' },
  { city: 'Algonquin', state: 'IL', state_name: 'Illinois' },
  { city: 'Alorton', state: 'IL', state_name: 'Illinois' },
  { city: 'Alsip', state: 'IL', state_name: 'Illinois' },
  { city: 'Altamont', state: 'IL', state_name: 'Illinois' },
  { city: 'Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'Amboy', state: 'IL', state_name: 'Illinois' },
  { city: 'Andalusia', state: 'IL', state_name: 'Illinois' },
  { city: 'Anna', state: 'IL', state_name: 'Illinois' },
  { city: 'Antioch', state: 'IL', state_name: 'Illinois' },
  { city: 'Arcola', state: 'IL', state_name: 'Illinois' },
  { city: 'Arlington Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Arthur', state: 'IL', state_name: 'Illinois' },
  { city: 'Ashburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Ashland', state: 'IL', state_name: 'Illinois' },
  { city: 'Assumption', state: 'IL', state_name: 'Illinois' },
  { city: 'Astoria', state: 'IL', state_name: 'Illinois' },
  { city: 'Athens', state: 'IL', state_name: 'Illinois' },
  { city: 'Atlanta', state: 'IL', state_name: 'Illinois' },
  { city: 'Atwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Auburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Auburn Gresham', state: 'IL', state_name: 'Illinois' },
  { city: 'Aurora', state: 'IL', state_name: 'Illinois' },
  { city: 'Aviston', state: 'IL', state_name: 'Illinois' },
  { city: 'Avondale', state: 'IL', state_name: 'Illinois' },
  { city: 'Bannockburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Barrington Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Barry', state: 'IL', state_name: 'Illinois' },
  { city: 'Bartlett', state: 'IL', state_name: 'Illinois' },
  { city: 'Bartonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Batavia', state: 'IL', state_name: 'Illinois' },
  { city: 'Beach Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Beardstown', state: 'IL', state_name: 'Illinois' },
  { city: 'Beckemeyer', state: 'IL', state_name: 'Illinois' },
  { city: 'Beecher', state: 'IL', state_name: 'Illinois' },
  { city: 'Belleville', state: 'IL', state_name: 'Illinois' },
  { city: 'Bellevue', state: 'IL', state_name: 'Illinois' },
  { city: 'Bellwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Belmont Cragin', state: 'IL', state_name: 'Illinois' },
  { city: 'Belvidere', state: 'IL', state_name: 'Illinois' },
  { city: 'Bement', state: 'IL', state_name: 'Illinois' },
  { city: 'Benld', state: 'IL', state_name: 'Illinois' },
  { city: 'Bensenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Benton', state: 'IL', state_name: 'Illinois' },
  { city: 'Berkeley', state: 'IL', state_name: 'Illinois' },
  { city: 'Berwyn', state: 'IL', state_name: 'Illinois' },
  { city: 'Bethalto', state: 'IL', state_name: 'Illinois' },
  { city: 'Bethany', state: 'IL', state_name: 'Illinois' },
  { city: 'Big Rock', state: 'IL', state_name: 'Illinois' },
  { city: 'Bloomingdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Bloomington', state: 'IL', state_name: 'Illinois' },
  { city: 'Blue Island', state: 'IL', state_name: 'Illinois' },
  { city: 'Blue Mound', state: 'IL', state_name: 'Illinois' },
  { city: 'Bolingbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Bond County', state: 'IL', state_name: 'Illinois' },
  { city: 'Boone County', state: 'IL', state_name: 'Illinois' },
  { city: 'Boulder Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Bourbonnais', state: 'IL', state_name: 'Illinois' },
  { city: 'Bradley', state: 'IL', state_name: 'Illinois' },
  { city: 'Braidwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Breese', state: 'IL', state_name: 'Illinois' },
  { city: 'Bridgeport', state: 'IL', state_name: 'Illinois' },
  { city: 'Bridgeview', state: 'IL', state_name: 'Illinois' },
  { city: 'Brighton', state: 'IL', state_name: 'Illinois' },
  { city: 'Brighton Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Broadview', state: 'IL', state_name: 'Illinois' },
  { city: 'Brookfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Brown County', state: 'IL', state_name: 'Illinois' },
  { city: 'Buffalo Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Bull Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Bunker Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Burbank', state: 'IL', state_name: 'Illinois' },
  { city: 'Bureau County', state: 'IL', state_name: 'Illinois' },
  { city: 'Burnham', state: 'IL', state_name: 'Illinois' },
  { city: 'Burr Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Bushnell', state: 'IL', state_name: 'Illinois' },
  { city: 'Byron', state: 'IL', state_name: 'Illinois' },
  { city: 'Cahokia', state: 'IL', state_name: 'Illinois' },
  { city: 'Cairo', state: 'IL', state_name: 'Illinois' },
  { city: 'Calhoun County', state: 'IL', state_name: 'Illinois' },
  { city: 'Calumet City', state: 'IL', state_name: 'Illinois' },
  { city: 'Calumet Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Cambria', state: 'IL', state_name: 'Illinois' },
  { city: 'Cambridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Camp Point', state: 'IL', state_name: 'Illinois' },
  { city: 'Canton', state: 'IL', state_name: 'Illinois' },
  { city: 'Capron', state: 'IL', state_name: 'Illinois' },
  { city: 'Carbon Cliff', state: 'IL', state_name: 'Illinois' },
  { city: 'Carbondale', state: 'IL', state_name: 'Illinois' },
  { city: 'Carlinville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carlyle', state: 'IL', state_name: 'Illinois' },
  { city: 'Carmi', state: 'IL', state_name: 'Illinois' },
  { city: 'Carol Stream', state: 'IL', state_name: 'Illinois' },
  { city: 'Carpentersville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carrier Mills', state: 'IL', state_name: 'Illinois' },
  { city: 'Carroll County', state: 'IL', state_name: 'Illinois' },
  { city: 'Carrollton', state: 'IL', state_name: 'Illinois' },
  { city: 'Carterville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carthage', state: 'IL', state_name: 'Illinois' },
  { city: 'Cary', state: 'IL', state_name: 'Illinois' },
  { city: 'Casey', state: 'IL', state_name: 'Illinois' },
  { city: 'Caseyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Cass County', state: 'IL', state_name: 'Illinois' },
  { city: 'Catlin', state: 'IL', state_name: 'Illinois' },
  { city: 'Central City', state: 'IL', state_name: 'Illinois' },
  { city: 'Centralia', state: 'IL', state_name: 'Illinois' },
  { city: 'Centreville', state: 'IL', state_name: 'Illinois' },
  { city: 'Cerro Gordo', state: 'IL', state_name: 'Illinois' },
  { city: 'Champaign', state: 'IL', state_name: 'Illinois' },
  { city: 'Champaign County', state: 'IL', state_name: 'Illinois' },
  { city: 'Channahon', state: 'IL', state_name: 'Illinois' },
  { city: 'Channel Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Charleston', state: 'IL', state_name: 'Illinois' },
  { city: 'Chatham', state: 'IL', state_name: 'Illinois' },
  { city: 'Chatsworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Chebanse', state: 'IL', state_name: 'Illinois' },
  { city: 'Chenoa', state: 'IL', state_name: 'Illinois' },
  { city: 'Cherry Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Chester', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Loop', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Chillicothe', state: 'IL', state_name: 'Illinois' },
  { city: 'Chrisman', state: 'IL', state_name: 'Illinois' },
  { city: 'Christian County', state: 'IL', state_name: 'Illinois' },
  { city: 'Christopher', state: 'IL', state_name: 'Illinois' },
  { city: 'Cicero', state: 'IL', state_name: 'Illinois' },
  { city: 'Clarendon Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Clark County', state: 'IL', state_name: 'Illinois' },
  { city: 'Clay County', state: 'IL', state_name: 'Illinois' },
  { city: 'Clifton', state: 'IL', state_name: 'Illinois' },
  { city: 'Clinton', state: 'IL', state_name: 'Illinois' },
  { city: 'Clinton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Coal City', state: 'IL', state_name: 'Illinois' },
  { city: 'Coal Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Cobden', state: 'IL', state_name: 'Illinois' },
  { city: 'Colchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Coles County', state: 'IL', state_name: 'Illinois' },
  { city: 'Colfax', state: 'IL', state_name: 'Illinois' },
  { city: 'Collinsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Colona', state: 'IL', state_name: 'Illinois' },
  { city: 'Columbia', state: 'IL', state_name: 'Illinois' },
  { city: 'Cook County', state: 'IL', state_name: 'Illinois' },
  { city: 'Cortland', state: 'IL', state_name: 'Illinois' },
  { city: 'Country Club Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Countryside', state: 'IL', state_name: 'Illinois' },
  { city: 'Crainville', state: 'IL', state_name: 'Illinois' },
  { city: 'Crawford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Crest Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Crestwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Crete', state: 'IL', state_name: 'Illinois' },
  { city: 'Creve Coeur', state: 'IL', state_name: 'Illinois' },
  { city: 'Crystal Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Crystal Lawns', state: 'IL', state_name: 'Illinois' },
  { city: 'Cuba', state: 'IL', state_name: 'Illinois' },
  { city: 'Cumberland County', state: 'IL', state_name: 'Illinois' },
  { city: 'Danvers', state: 'IL', state_name: 'Illinois' },
  { city: 'Danville', state: 'IL', state_name: 'Illinois' },
  { city: 'Darien', state: 'IL', state_name: 'Illinois' },
  { city: 'Davis Junction', state: 'IL', state_name: 'Illinois' },
  { city: 'De Soto', state: 'IL', state_name: 'Illinois' },
  { city: 'De Witt County', state: 'IL', state_name: 'Illinois' },
  { city: 'Decatur', state: 'IL', state_name: 'Illinois' },
  { city: 'Deer Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Deerfield', state: 'IL', state_name: 'Illinois' },
  { city: 'DeKalb', state: 'IL', state_name: 'Illinois' },
  { city: 'DeKalb County', state: 'IL', state_name: 'Illinois' },
  { city: 'Delavan', state: 'IL', state_name: 'Illinois' },
  { city: 'Depue', state: 'IL', state_name: 'Illinois' },
  { city: 'Des Plaines', state: 'IL', state_name: 'Illinois' },
  { city: 'Diamond', state: 'IL', state_name: 'Illinois' },
  { city: 'Divernon', state: 'IL', state_name: 'Illinois' },
  { city: 'Dixmoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Dixon', state: 'IL', state_name: 'Illinois' },
  { city: 'Dolton', state: 'IL', state_name: 'Illinois' },
  { city: 'Douglas', state: 'IL', state_name: 'Illinois' },
  { city: 'Douglas County', state: 'IL', state_name: 'Illinois' },
  { city: 'Downers Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Du Quoin', state: 'IL', state_name: 'Illinois' },
  { city: 'Dunlap', state: 'IL', state_name: 'Illinois' },
  { city: 'DuPage County', state: 'IL', state_name: 'Illinois' },
  { city: 'Dupo', state: 'IL', state_name: 'Illinois' },
  { city: 'Durand', state: 'IL', state_name: 'Illinois' },
  { city: 'Dwight', state: 'IL', state_name: 'Illinois' },
  { city: 'Earlville', state: 'IL', state_name: 'Illinois' },
  { city: 'East Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'East Dubuque', state: 'IL', state_name: 'Illinois' },
  { city: 'East Dundee', state: 'IL', state_name: 'Illinois' },
  { city: 'East Garfield Park', state: 'IL', state_name: 'Illinois' },
  { city: 'East Hazel Crest', state: 'IL', state_name: 'Illinois' },
  { city: 'East Moline', state: 'IL', state_name: 'Illinois' },
  { city: 'East Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'East Saint Louis', state: 'IL', state_name: 'Illinois' },
  { city: 'Edgar County', state: 'IL', state_name: 'Illinois' },
  { city: 'Edgewater', state: 'IL', state_name: 'Illinois' },
  { city: 'Edinburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Edwards County', state: 'IL', state_name: 'Illinois' },
  { city: 'Edwardsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Effingham', state: 'IL', state_name: 'Illinois' },
  { city: 'Effingham County', state: 'IL', state_name: 'Illinois' },
  { city: 'El Paso', state: 'IL', state_name: 'Illinois' },
  { city: 'Elburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Eldorado', state: 'IL', state_name: 'Illinois' },
  { city: 'Elgin', state: 'IL', state_name: 'Illinois' },
  { city: 'Elizabethtown', state: 'IL', state_name: 'Illinois' },
  { city: 'Elk Grove Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmhurst', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmwood Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Elwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Energy', state: 'IL', state_name: 'Illinois' },
  { city: 'Englewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Erie', state: 'IL', state_name: 'Illinois' },
  { city: 'Eureka', state: 'IL', state_name: 'Illinois' },
  { city: 'Evanston', state: 'IL', state_name: 'Illinois' },
  { city: 'Evergreen Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairbury', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairmont', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairmont City', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairview Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Farmer City', state: 'IL', state_name: 'Illinois' },
  { city: 'Farmington', state: 'IL', state_name: 'Illinois' },
  { city: 'Fayette County', state: 'IL', state_name: 'Illinois' },
  { city: 'Fisher', state: 'IL', state_name: 'Illinois' },
  { city: 'Flanagan', state: 'IL', state_name: 'Illinois' },
  { city: 'Flora', state: 'IL', state_name: 'Illinois' },
  { city: 'Flossmoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Ford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Ford Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Forest Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Forest Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Forrest', state: 'IL', state_name: 'Illinois' },
  { city: 'Forreston', state: 'IL', state_name: 'Illinois' },
  { city: 'Forsyth', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox Lake Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox River Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Frankfort', state: 'IL', state_name: 'Illinois' },
  { city: 'Frankfort Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Franklin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Franklin Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Freeburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Freeport', state: 'IL', state_name: 'Illinois' },
  { city: 'Fulton', state: 'IL', state_name: 'Illinois' },
  { city: 'Fulton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Gage Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Gages Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Galena', state: 'IL', state_name: 'Illinois' },
  { city: 'Galesburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Gallatin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Galva', state: 'IL', state_name: 'Illinois' },
  { city: 'Gardner', state: 'IL', state_name: 'Illinois' },
  { city: 'Geneseo', state: 'IL', state_name: 'Illinois' },
  { city: 'Geneva', state: 'IL', state_name: 'Illinois' },
  { city: 'Genoa', state: 'IL', state_name: 'Illinois' },
  { city: 'Georgetown', state: 'IL', state_name: 'Illinois' },
  { city: 'Germantown', state: 'IL', state_name: 'Illinois' },
  { city: 'Germantown Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Gibson City', state: 'IL', state_name: 'Illinois' },
  { city: 'Gifford', state: 'IL', state_name: 'Illinois' },
  { city: 'Gilberts', state: 'IL', state_name: 'Illinois' },
  { city: 'Gillespie', state: 'IL', state_name: 'Illinois' },
  { city: 'Gilman', state: 'IL', state_name: 'Illinois' },
  { city: 'Girard', state: 'IL', state_name: 'Illinois' },
  { city: 'Glasford', state: 'IL', state_name: 'Illinois' },
  { city: 'Glen Carbon', state: 'IL', state_name: 'Illinois' },
  { city: 'Glen Ellyn', state: 'IL', state_name: 'Illinois' },
  { city: 'Glencoe', state: 'IL', state_name: 'Illinois' },
  { city: 'Glendale Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Glenview', state: 'IL', state_name: 'Illinois' },
  { city: 'Glenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Godfrey', state: 'IL', state_name: 'Illinois' },
  { city: 'Golconda', state: 'IL', state_name: 'Illinois' },
  { city: 'Goodings Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Goreville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grand Boulevard', state: 'IL', state_name: 'Illinois' },
  { city: 'Grandview', state: 'IL', state_name: 'Illinois' },
  { city: 'Grandwood Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Granite City', state: 'IL', state_name: 'Illinois' },
  { city: 'Grant Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Granville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grayslake', state: 'IL', state_name: 'Illinois' },
  { city: 'Grayville', state: 'IL', state_name: 'Illinois' },
  { city: 'Greater Grand Crossing', state: 'IL', state_name: 'Illinois' },
  { city: 'Green Oaks', state: 'IL', state_name: 'Illinois' },
  { city: 'Green Rock', state: 'IL', state_name: 'Illinois' },
  { city: 'Greene County', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenup', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Gridley', state: 'IL', state_name: 'Illinois' },
  { city: 'Griggsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grundy County', state: 'IL', state_name: 'Illinois' },
  { city: 'Gurnee', state: 'IL', state_name: 'Illinois' },
  { city: 'Hainesville', state: 'IL', state_name: 'Illinois' },
  { city: 'Hamilton', state: 'IL', state_name: 'Illinois' },
  { city: 'Hamilton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hampshire', state: 'IL', state_name: 'Illinois' },
  { city: 'Hampton', state: 'IL', state_name: 'Illinois' },
  { city: 'Hancock County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hanna City', state: 'IL', state_name: 'Illinois' },
  { city: 'Hanover Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Hardin', state: 'IL', state_name: 'Illinois' },
  { city: 'Hardin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Harrisburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Harristown', state: 'IL', state_name: 'Illinois' },
  { city: 'Hartford', state: 'IL', state_name: 'Illinois' },
  { city: 'Harvard', state: 'IL', state_name: 'Illinois' },
  { city: 'Harvey', state: 'IL', state_name: 'Illinois' },
  { city: 'Harwood Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Havana', state: 'IL', state_name: 'Illinois' },
  { city: 'Hawthorn Woods', state: 'IL', state_name: 'Illinois' },
  { city: 'Hazel Crest', state: 'IL', state_name: 'Illinois' },
  { city: 'Hebron', state: 'IL', state_name: 'Illinois' },
  { city: 'Henderson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hennepin', state: 'IL', state_name: 'Illinois' },
  { city: 'Henry', state: 'IL', state_name: 'Illinois' },
  { city: 'Henry County', state: 'IL', state_name: 'Illinois' },
  { city: 'Heritage Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Herrin', state: 'IL', state_name: 'Illinois' },
  { city: 'Herscher', state: 'IL', state_name: 'Illinois' },
  { city: 'Heyworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Hickory Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Highland', state: 'IL', state_name: 'Illinois' },
  { city: 'Highland Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Highwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillcrest', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillsboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillside', state: 'IL', state_name: 'Illinois' },
  { city: 'Hinckley', state: 'IL', state_name: 'Illinois' },
  { city: 'Hinsdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Hodgkins', state: 'IL', state_name: 'Illinois' },
  { city: 'Hoffman Estates', state: 'IL', state_name: 'Illinois' },
  { city: 'Holiday Shores', state: 'IL', state_name: 'Illinois' },
  { city: 'Homer', state: 'IL', state_name: 'Illinois' },
  { city: 'Homer Glen', state: 'IL', state_name: 'Illinois' },
  { city: 'Hometown', state: 'IL', state_name: 'Illinois' },
  { city: 'Homewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Hoopeston', state: 'IL', state_name: 'Illinois' },
  { city: 'Hudson', state: 'IL', state_name: 'Illinois' },
  { city: 'Huntley', state: 'IL', state_name: 'Illinois' },
  { city: 'Hyde Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Ina', state: 'IL', state_name: 'Illinois' },
  { city: 'Indian Head Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Ingalls Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Inverness', state: 'IL', state_name: 'Illinois' },
  { city: 'Iroquois County', state: 'IL', state_name: 'Illinois' },
  { city: 'Irving Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Island Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Itasca', state: 'IL', state_name: 'Illinois' },
  { city: 'Jackson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jacksonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Jasper County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jefferson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jerome', state: 'IL', state_name: 'Illinois' },
  { city: 'Jersey County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jerseyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Jo Daviess County', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnsburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnston City', state: 'IL', state_name: 'Illinois' },
  { city: 'Joliet', state: 'IL', state_name: 'Illinois' },
  { city: 'Jonesboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Justice', state: 'IL', state_name: 'Illinois' },
  { city: 'Kane County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kankakee', state: 'IL', state_name: 'Illinois' },
  { city: 'Kankakee County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kendall County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kenilworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Kenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Kewanee', state: 'IL', state_name: 'Illinois' },
  { city: 'Kildeer', state: 'IL', state_name: 'Illinois' },
  { city: 'Kincaid', state: 'IL', state_name: 'Illinois' },
  { city: 'Kingston', state: 'IL', state_name: 'Illinois' },
  { city: 'Kirkland', state: 'IL', state_name: 'Illinois' },
  { city: 'Knollwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Knox County', state: 'IL', state_name: 'Illinois' },
  { city: 'Knoxville', state: 'IL', state_name: 'Illinois' },
  { city: 'La Grange', state: 'IL', state_name: 'Illinois' },
  { city: 'La Grange Park', state: 'IL', state_name: 'Illinois' },
  { city: 'La Harpe', state: 'IL', state_name: 'Illinois' },
  { city: 'La Salle', state: 'IL', state_name: 'Illinois' },
  { city: 'Lacon', state: 'IL', state_name: 'Illinois' },
  { city: 'Ladd', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Bluff', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Camelot', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Catherine', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Holiday', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake in the Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake of the Woods', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Summerset', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Villa', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Zurich', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakemoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakewood Shores', state: 'IL', state_name: 'Illinois' },
  { city: 'Lanark', state: 'IL', state_name: 'Illinois' },
  { city: 'Lansing', state: 'IL', state_name: 'Illinois' },
  { city: 'LaSalle County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lawrence County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lawrenceville', state: 'IL', state_name: 'Illinois' },
  { city: 'Le Roy', state: 'IL', state_name: 'Illinois' },
  { city: 'Lebanon', state: 'IL', state_name: 'Illinois' },
  { city: 'Lee County', state: 'IL', state_name: 'Illinois' },
  { city: 'Leland Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Lemont', state: 'IL', state_name: 'Illinois' },
  { city: 'Lena', state: 'IL', state_name: 'Illinois' },
  { city: 'Lewistown', state: 'IL', state_name: 'Illinois' },
  { city: 'Lexington', state: 'IL', state_name: 'Illinois' },
  { city: 'Libertyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Lily Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Limestone', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincolnshire', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincolnwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lindenhurst', state: 'IL', state_name: 'Illinois' },
  { city: 'Lisle', state: 'IL', state_name: 'Illinois' },
  { city: 'Litchfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Livingston County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lockport', state: 'IL', state_name: 'Illinois' },
  { city: 'Logan County', state: 'IL', state_name: 'Illinois' },
  { city: 'Logan Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Lombard', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Creek', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Louisville', state: 'IL', state_name: 'Illinois' },
  { city: 'Loves Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Lovington', state: 'IL', state_name: 'Illinois' },
  { city: 'Lower West Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Lynwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lyons', state: 'IL', state_name: 'Illinois' },
  { city: 'Machesney Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Mackinaw', state: 'IL', state_name: 'Illinois' },
  { city: 'Macomb', state: 'IL', state_name: 'Illinois' },
  { city: 'Macon', state: 'IL', state_name: 'Illinois' },
  { city: 'Macon County', state: 'IL', state_name: 'Illinois' },
  { city: 'Macoupin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Madison', state: 'IL', state_name: 'Illinois' },
  { city: 'Madison County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mahomet', state: 'IL', state_name: 'Illinois' },
  { city: 'Malta', state: 'IL', state_name: 'Illinois' },
  { city: 'Manhattan', state: 'IL', state_name: 'Illinois' },
  { city: 'Manito', state: 'IL', state_name: 'Illinois' },
  { city: 'Manteno', state: 'IL', state_name: 'Illinois' },
  { city: 'Maple Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Marengo', state: 'IL', state_name: 'Illinois' },
  { city: 'Marion', state: 'IL', state_name: 'Illinois' },
  { city: 'Marion County', state: 'IL', state_name: 'Illinois' },
  { city: 'Marissa', state: 'IL', state_name: 'Illinois' },
  { city: 'Markham', state: 'IL', state_name: 'Illinois' },
  { city: 'Maroa', state: 'IL', state_name: 'Illinois' },
  { city: 'Marquette Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Marseilles', state: 'IL', state_name: 'Illinois' },
  { city: 'Marshall', state: 'IL', state_name: 'Illinois' },
  { city: 'Marshall County', state: 'IL', state_name: 'Illinois' },
  { city: 'Martinsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Maryville', state: 'IL', state_name: 'Illinois' },
  { city: 'Mascoutah', state: 'IL', state_name: 'Illinois' },
  { city: 'Mason City', state: 'IL', state_name: 'Illinois' },
  { city: 'Mason County', state: 'IL', state_name: 'Illinois' },
  { city: 'Massac County', state: 'IL', state_name: 'Illinois' },
  { city: 'Matteson', state: 'IL', state_name: 'Illinois' },
  { city: 'Mattoon', state: 'IL', state_name: 'Illinois' },
  { city: 'Maywood', state: 'IL', state_name: 'Illinois' },
  { city: 'McCullom Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'McDonough County', state: 'IL', state_name: 'Illinois' },
  { city: 'McHenry', state: 'IL', state_name: 'Illinois' },
  { city: 'McHenry County', state: 'IL', state_name: 'Illinois' },
  { city: 'McKinley Park', state: 'IL', state_name: 'Illinois' },
  { city: 'McLean County', state: 'IL', state_name: 'Illinois' },
  { city: 'McLeansboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Melrose Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Menard County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mendota', state: 'IL', state_name: 'Illinois' },
  { city: 'Mercer County', state: 'IL', state_name: 'Illinois' },
  { city: 'Meredosia', state: 'IL', state_name: 'Illinois' },
  { city: 'Merrionette Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Metamora', state: 'IL', state_name: 'Illinois' },
  { city: 'Metropolis', state: 'IL', state_name: 'Illinois' },
  { city: 'Midlothian', state: 'IL', state_name: 'Illinois' },
  { city: 'Milan', state: 'IL', state_name: 'Illinois' },
  { city: 'Milford', state: 'IL', state_name: 'Illinois' },
  { city: 'Millstadt', state: 'IL', state_name: 'Illinois' },
  { city: 'Minier', state: 'IL', state_name: 'Illinois' },
  { city: 'Minonk', state: 'IL', state_name: 'Illinois' },
  { city: 'Minooka', state: 'IL', state_name: 'Illinois' },
  { city: 'Mitchell', state: 'IL', state_name: 'Illinois' },
  { city: 'Mokena', state: 'IL', state_name: 'Illinois' },
  { city: 'Moline', state: 'IL', state_name: 'Illinois' },
  { city: 'Momence', state: 'IL', state_name: 'Illinois' },
  { city: 'Monee', state: 'IL', state_name: 'Illinois' },
  { city: 'Monmouth', state: 'IL', state_name: 'Illinois' },
  { city: 'Monroe County', state: 'IL', state_name: 'Illinois' },
  { city: 'Montgomery', state: 'IL', state_name: 'Illinois' },
  { city: 'Montgomery County', state: 'IL', state_name: 'Illinois' },
  { city: 'Monticello', state: 'IL', state_name: 'Illinois' },
  { city: 'Morgan County', state: 'IL', state_name: 'Illinois' },
  { city: 'Morgan Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Morris', state: 'IL', state_name: 'Illinois' },
  { city: 'Morrison', state: 'IL', state_name: 'Illinois' },
  { city: 'Morrisonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Morton', state: 'IL', state_name: 'Illinois' },
  { city: 'Morton Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Moultrie County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mound City', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Carmel', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Carroll', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Greenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Morris', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Olive', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Prospect', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Pulaski', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Sterling', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Vernon', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Zion', state: 'IL', state_name: 'Illinois' },
  { city: 'Moweaqua', state: 'IL', state_name: 'Illinois' },
  { city: 'Mundelein', state: 'IL', state_name: 'Illinois' },
  { city: 'Murphysboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Naperville', state: 'IL', state_name: 'Illinois' },
  { city: 'Nashville', state: 'IL', state_name: 'Illinois' },
  { city: 'Nauvoo', state: 'IL', state_name: 'Illinois' },
  { city: 'Near North Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Near South Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Neoga', state: 'IL', state_name: 'Illinois' },
  { city: 'New Athens', state: 'IL', state_name: 'Illinois' },
  { city: 'New Baden', state: 'IL', state_name: 'Illinois' },
  { city: 'New Berlin', state: 'IL', state_name: 'Illinois' },
  { city: 'New City', state: 'IL', state_name: 'Illinois' },
  { city: 'New Lenox', state: 'IL', state_name: 'Illinois' },
  { city: 'Newark', state: 'IL', state_name: 'Illinois' },
  { city: 'Newton', state: 'IL', state_name: 'Illinois' },
  { city: 'Niles', state: 'IL', state_name: 'Illinois' },
  { city: 'Nokomis', state: 'IL', state_name: 'Illinois' },
  { city: 'Normal', state: 'IL', state_name: 'Illinois' },
  { city: 'Norridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Norris City', state: 'IL', state_name: 'Illinois' },
  { city: 'North Aurora', state: 'IL', state_name: 'Illinois' },
  { city: 'North Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'North Center', state: 'IL', state_name: 'Illinois' },
  { city: 'North Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'North Lawndale', state: 'IL', state_name: 'Illinois' },
  { city: 'North Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'North Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'North Riverside', state: 'IL', state_name: 'Illinois' },
  { city: 'Northbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Northfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Northlake', state: 'IL', state_name: 'Illinois' },
  { city: "O'Fallon", state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Brook', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakbrook Terrace', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakwood Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Oblong', state: 'IL', state_name: 'Illinois' },
  { city: 'Odin', state: 'IL', state_name: 'Illinois' },
  { city: 'Ogle County', state: 'IL', state_name: 'Illinois' },
  { city: 'Oglesby', state: 'IL', state_name: 'Illinois' },
  { city: 'Okawville', state: 'IL', state_name: 'Illinois' },
  { city: 'Olney', state: 'IL', state_name: 'Illinois' },
  { city: 'Olympia Fields', state: 'IL', state_name: 'Illinois' },
  { city: 'Onarga', state: 'IL', state_name: 'Illinois' },
  { city: 'Oquawka', state: 'IL', state_name: 'Illinois' },
  { city: 'Oregon', state: 'IL', state_name: 'Illinois' },
  { city: 'Orion', state: 'IL', state_name: 'Illinois' },
  { city: 'Orland Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Orland Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Oswego', state: 'IL', state_name: 'Illinois' },
  { city: 'Ottawa', state: 'IL', state_name: 'Illinois' },
  { city: 'Palatine', state: 'IL', state_name: 'Illinois' },
  { city: 'Palestine', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Pana', state: 'IL', state_name: 'Illinois' },
  { city: 'Paris', state: 'IL', state_name: 'Illinois' },
  { city: 'Park City', state: 'IL', state_name: 'Illinois' },
  { city: 'Park Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Park Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Pawnee', state: 'IL', state_name: 'Illinois' },
  { city: 'Paxton', state: 'IL', state_name: 'Illinois' },
  { city: 'Payson', state: 'IL', state_name: 'Illinois' },
  { city: 'Pecatonica', state: 'IL', state_name: 'Illinois' },
  { city: 'Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria County', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Peotone', state: 'IL', state_name: 'Illinois' },
  { city: 'Perry County', state: 'IL', state_name: 'Illinois' },
  { city: 'Peru', state: 'IL', state_name: 'Illinois' },
  { city: 'Petersburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Philo', state: 'IL', state_name: 'Illinois' },
  { city: 'Phoenix', state: 'IL', state_name: 'Illinois' },
  { city: 'Piatt County', state: 'IL', state_name: 'Illinois' },
  { city: 'Pike County', state: 'IL', state_name: 'Illinois' },
  { city: 'Pinckneyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Pingree Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Pistakee Highlands', state: 'IL', state_name: 'Illinois' },
  { city: 'Pittsfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Plainfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Plano', state: 'IL', state_name: 'Illinois' },
  { city: 'Polo', state: 'IL', state_name: 'Illinois' },
  { city: 'Pontiac', state: 'IL', state_name: 'Illinois' },
  { city: 'Pontoon Beach', state: 'IL', state_name: 'Illinois' },
  { city: 'Pope County', state: 'IL', state_name: 'Illinois' },
  { city: 'Poplar Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Port Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Port Byron', state: 'IL', state_name: 'Illinois' },
  { city: 'Portage Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Posen', state: 'IL', state_name: 'Illinois' },
  { city: 'Prairie Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Prestbury', state: 'IL', state_name: 'Illinois' },
  { city: 'Preston Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Princeton', state: 'IL', state_name: 'Illinois' },
  { city: 'Princeville', state: 'IL', state_name: 'Illinois' },
  { city: 'Prophetstown', state: 'IL', state_name: 'Illinois' },
  { city: 'Prospect Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Pulaski County', state: 'IL', state_name: 'Illinois' },
  { city: 'Putnam County', state: 'IL', state_name: 'Illinois' },
  { city: 'Quincy', state: 'IL', state_name: 'Illinois' },
  { city: 'Ramsey', state: 'IL', state_name: 'Illinois' },
  { city: 'Randolph County', state: 'IL', state_name: 'Illinois' },
  { city: 'Rantoul', state: 'IL', state_name: 'Illinois' },
  { city: 'Red Bud', state: 'IL', state_name: 'Illinois' },
  { city: 'Richland County', state: 'IL', state_name: 'Illinois' },
  { city: 'Richmond', state: 'IL', state_name: 'Illinois' },
  { city: 'Richton Park', state: 'IL', state_name: 'Illinois' },
  { city: 'River Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'River Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverside', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverton', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverwoods', state: 'IL', state_name: 'Illinois' },
  { city: 'Roanoke', state: 'IL', state_name: 'Illinois' },
  { city: 'Robbins', state: 'IL', state_name: 'Illinois' },
  { city: 'Robinson', state: 'IL', state_name: 'Illinois' },
  { city: 'Rochelle', state: 'IL', state_name: 'Illinois' },
  { city: 'Rochester', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Falls', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Island', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Island County', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockford', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockton', state: 'IL', state_name: 'Illinois' },
  { city: 'Rogers Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Rolling Meadows', state: 'IL', state_name: 'Illinois' },
  { city: 'Rome', state: 'IL', state_name: 'Illinois' },
  { city: 'Romeoville', state: 'IL', state_name: 'Illinois' },
  { city: 'Roodhouse', state: 'IL', state_name: 'Illinois' },
  { city: 'Roscoe', state: 'IL', state_name: 'Illinois' },
  { city: 'Roselle', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosemont', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosewood Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosiclare', state: 'IL', state_name: 'Illinois' },
  { city: 'Rossville', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Beach', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Roxana', state: 'IL', state_name: 'Illinois' },
  { city: 'Royalton', state: 'IL', state_name: 'Illinois' },
  { city: 'Rushville', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Anne', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Charles', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Clair County', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Elmo', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Jacob', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Joseph', state: 'IL', state_name: 'Illinois' },
  { city: 'Salem', state: 'IL', state_name: 'Illinois' },
  { city: 'Saline County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sandoval', state: 'IL', state_name: 'Illinois' },
  { city: 'Sandwich', state: 'IL', state_name: 'Illinois' },
  { city: 'Sangamon County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sauk Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Savanna', state: 'IL', state_name: 'Illinois' },
  { city: 'Savoy', state: 'IL', state_name: 'Illinois' },
  { city: 'Schaumburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Schiller Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Schuyler County', state: 'IL', state_name: 'Illinois' },
  { city: 'Scott Air Force Base', state: 'IL', state_name: 'Illinois' },
  { city: 'Scott County', state: 'IL', state_name: 'Illinois' },
  { city: 'Seneca', state: 'IL', state_name: 'Illinois' },
  { city: 'Sesser', state: 'IL', state_name: 'Illinois' },
  { city: 'Shawneetown', state: 'IL', state_name: 'Illinois' },
  { city: 'Shelby County', state: 'IL', state_name: 'Illinois' },
  { city: 'Shelbyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Sheldon', state: 'IL', state_name: 'Illinois' },
  { city: 'Sheridan', state: 'IL', state_name: 'Illinois' },
  { city: 'Sherman', state: 'IL', state_name: 'Illinois' },
  { city: 'Shiloh', state: 'IL', state_name: 'Illinois' },
  { city: 'Shorewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Sidney', state: 'IL', state_name: 'Illinois' },
  { city: 'Silvis', state: 'IL', state_name: 'Illinois' },
  { city: 'Skokie', state: 'IL', state_name: 'Illinois' },
  { city: 'Sleepy Hollow', state: 'IL', state_name: 'Illinois' },
  { city: 'Smithton', state: 'IL', state_name: 'Illinois' },
  { city: 'Somonauk', state: 'IL', state_name: 'Illinois' },
  { city: 'South Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'South Beloit', state: 'IL', state_name: 'Illinois' },
  { city: 'South Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'South Chicago Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'South Elgin', state: 'IL', state_name: 'Illinois' },
  { city: 'South Holland', state: 'IL', state_name: 'Illinois' },
  { city: 'South Jacksonville', state: 'IL', state_name: 'Illinois' },
  { city: 'South Lawndale', state: 'IL', state_name: 'Illinois' },
  { city: 'South Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'South Roxana', state: 'IL', state_name: 'Illinois' },
  { city: 'South Shore', state: 'IL', state_name: 'Illinois' },
  { city: 'Southern View', state: 'IL', state_name: 'Illinois' },
  { city: 'Sparta', state: 'IL', state_name: 'Illinois' },
  { city: 'Spring Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Spring Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Springfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Stark County', state: 'IL', state_name: 'Illinois' },
  { city: 'Staunton', state: 'IL', state_name: 'Illinois' },
  { city: 'Steeleville', state: 'IL', state_name: 'Illinois' },
  { city: 'Steger', state: 'IL', state_name: 'Illinois' },
  { city: 'Stephenson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sterling', state: 'IL', state_name: 'Illinois' },
  { city: 'Stickney', state: 'IL', state_name: 'Illinois' },
  { city: 'Stillman Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Stockton', state: 'IL', state_name: 'Illinois' },
  { city: 'Stone Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Streamwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Streator', state: 'IL', state_name: 'Illinois' },
  { city: 'Sugar Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Sullivan', state: 'IL', state_name: 'Illinois' },
  { city: 'Summit', state: 'IL', state_name: 'Illinois' },
  { city: 'Sumner', state: 'IL', state_name: 'Illinois' },
  { city: 'Swansea', state: 'IL', state_name: 'Illinois' },
  { city: 'Sycamore', state: 'IL', state_name: 'Illinois' },
  { city: 'Taylorville', state: 'IL', state_name: 'Illinois' },
  { city: 'Tazewell County', state: 'IL', state_name: 'Illinois' },
  { city: 'Teutopolis', state: 'IL', state_name: 'Illinois' },
  { city: 'The Galena Territory', state: 'IL', state_name: 'Illinois' },
  { city: 'Third Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Thomasboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Thornton', state: 'IL', state_name: 'Illinois' },
  { city: 'Tilton', state: 'IL', state_name: 'Illinois' },
  { city: 'Tinley Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Toledo', state: 'IL', state_name: 'Illinois' },
  { city: 'Tolono', state: 'IL', state_name: 'Illinois' },
  { city: 'Toluca', state: 'IL', state_name: 'Illinois' },
  { city: 'Toulon', state: 'IL', state_name: 'Illinois' },
  { city: 'Tower Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Tremont', state: 'IL', state_name: 'Illinois' },
  { city: 'Trenton', state: 'IL', state_name: 'Illinois' },
  { city: 'Troy', state: 'IL', state_name: 'Illinois' },
  { city: 'Tuscola', state: 'IL', state_name: 'Illinois' },
  { city: 'Twin Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Union County', state: 'IL', state_name: 'Illinois' },
  { city: 'University Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Upper Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'Uptown', state: 'IL', state_name: 'Illinois' },
  { city: 'Urbana', state: 'IL', state_name: 'Illinois' },
  { city: 'Valmeyer', state: 'IL', state_name: 'Illinois' },
  { city: 'Vandalia', state: 'IL', state_name: 'Illinois' },
  { city: 'Venetian Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Venice', state: 'IL', state_name: 'Illinois' },
  { city: 'Vermilion County', state: 'IL', state_name: 'Illinois' },
  { city: 'Vernon Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Vienna', state: 'IL', state_name: 'Illinois' },
  { city: 'Villa Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Villa Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Village of Campton Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Virden', state: 'IL', state_name: 'Illinois' },
  { city: 'Virginia', state: 'IL', state_name: 'Illinois' },
  { city: 'Volo', state: 'IL', state_name: 'Illinois' },
  { city: 'Wabash County', state: 'IL', state_name: 'Illinois' },
  { city: 'Wadsworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Walnut', state: 'IL', state_name: 'Illinois' },
  { city: 'Wamac', state: 'IL', state_name: 'Illinois' },
  { city: 'Warren', state: 'IL', state_name: 'Illinois' },
  { city: 'Warren County', state: 'IL', state_name: 'Illinois' },
  { city: 'Warrensburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Warrenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Warsaw', state: 'IL', state_name: 'Illinois' },
  { city: 'Wasco', state: 'IL', state_name: 'Illinois' },
  { city: 'Washburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington County', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Waterloo', state: 'IL', state_name: 'Illinois' },
  { city: 'Waterman', state: 'IL', state_name: 'Illinois' },
  { city: 'Watseka', state: 'IL', state_name: 'Illinois' },
  { city: 'Wauconda', state: 'IL', state_name: 'Illinois' },
  { city: 'Waukegan', state: 'IL', state_name: 'Illinois' },
  { city: 'Waverly', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne City', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne County', state: 'IL', state_name: 'Illinois' },
  { city: 'West Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'West Dundee', state: 'IL', state_name: 'Illinois' },
  { city: 'West Elsdon', state: 'IL', state_name: 'Illinois' },
  { city: 'West Englewood', state: 'IL', state_name: 'Illinois' },
  { city: 'West Frankfort', state: 'IL', state_name: 'Illinois' },
  { city: 'West Garfield Park', state: 'IL', state_name: 'Illinois' },
  { city: 'West Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'West Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'West Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'West Town', state: 'IL', state_name: 'Illinois' },
  { city: 'Westchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Western Springs', state: 'IL', state_name: 'Illinois' },
  { city: 'Westmont', state: 'IL', state_name: 'Illinois' },
  { city: 'Westville', state: 'IL', state_name: 'Illinois' },
  { city: 'Wheaton', state: 'IL', state_name: 'Illinois' },
  { city: 'Wheeling', state: 'IL', state_name: 'Illinois' },
  { city: 'White County', state: 'IL', state_name: 'Illinois' },
  { city: 'White Hall', state: 'IL', state_name: 'Illinois' },
  { city: 'Whiteside County', state: 'IL', state_name: 'Illinois' },
  { city: 'Will County', state: 'IL', state_name: 'Illinois' },
  { city: 'Williamson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Williamsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Willow Springs', state: 'IL', state_name: 'Illinois' },
  { city: 'Willowbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Wilmette', state: 'IL', state_name: 'Illinois' },
  { city: 'Wilmington', state: 'IL', state_name: 'Illinois' },
  { city: 'Winchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Windsor', state: 'IL', state_name: 'Illinois' },
  { city: 'Winfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnebago', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnebago County', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnetka', state: 'IL', state_name: 'Illinois' },
  { city: 'Winthrop Harbor', state: 'IL', state_name: 'Illinois' },
  { city: 'Wonder Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Wood Dale', state: 'IL', state_name: 'Illinois' },
  { city: 'Wood River', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodlawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodstock', state: 'IL', state_name: 'Illinois' },
  { city: 'Worden', state: 'IL', state_name: 'Illinois' },
  { city: 'Worth', state: 'IL', state_name: 'Illinois' },
  { city: 'Wyoming', state: 'IL', state_name: 'Illinois' },
  { city: 'Yorkville', state: 'IL', state_name: 'Illinois' },
  { city: 'Zeigler', state: 'IL', state_name: 'Illinois' },
  { city: 'Zion', state: 'IL', state_name: 'Illinois' },
  { city: 'Aberdeen', state: 'IN', state_name: 'Indiana' },
  { city: 'Adams County', state: 'IN', state_name: 'Indiana' },
  { city: 'Akron', state: 'IN', state_name: 'Indiana' },
  { city: 'Albany', state: 'IN', state_name: 'Indiana' },
  { city: 'Albion', state: 'IN', state_name: 'Indiana' },
  { city: 'Alexandria', state: 'IN', state_name: 'Indiana' },
  { city: 'Allen County', state: 'IN', state_name: 'Indiana' },
  { city: 'Anderson', state: 'IN', state_name: 'Indiana' },
  { city: 'Andrews', state: 'IN', state_name: 'Indiana' },
  { city: 'Angola', state: 'IN', state_name: 'Indiana' },
  { city: 'Arcadia', state: 'IN', state_name: 'Indiana' },
  { city: 'Argos', state: 'IN', state_name: 'Indiana' },
  { city: 'Attica', state: 'IN', state_name: 'Indiana' },
  { city: 'Auburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Aurora', state: 'IN', state_name: 'Indiana' },
  { city: 'Austin', state: 'IN', state_name: 'Indiana' },
  { city: 'Avilla', state: 'IN', state_name: 'Indiana' },
  { city: 'Avon', state: 'IN', state_name: 'Indiana' },
  { city: 'Bargersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Bartholomew County', state: 'IN', state_name: 'Indiana' },
  { city: 'Bass Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Batesville', state: 'IN', state_name: 'Indiana' },
  { city: 'Battle Ground', state: 'IN', state_name: 'Indiana' },
  { city: 'Bedford', state: 'IN', state_name: 'Indiana' },
  { city: 'Beech Grove', state: 'IN', state_name: 'Indiana' },
  { city: 'Benton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Berne', state: 'IN', state_name: 'Indiana' },
  { city: 'Bicknell', state: 'IN', state_name: 'Indiana' },
  { city: 'Blackford County', state: 'IN', state_name: 'Indiana' },
  { city: 'Bloomfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Bloomington', state: 'IN', state_name: 'Indiana' },
  { city: 'Bluffton', state: 'IN', state_name: 'Indiana' },
  { city: 'Boone County', state: 'IN', state_name: 'Indiana' },
  { city: 'Boonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Bourbon', state: 'IN', state_name: 'Indiana' },
  { city: 'Brazil', state: 'IN', state_name: 'Indiana' },
  { city: 'Bremen', state: 'IN', state_name: 'Indiana' },
  { city: 'Bright', state: 'IN', state_name: 'Indiana' },
  { city: 'Bristol', state: 'IN', state_name: 'Indiana' },
  { city: 'Broad Ripple', state: 'IN', state_name: 'Indiana' },
  { city: 'Brooklyn', state: 'IN', state_name: 'Indiana' },
  { city: 'Brookston', state: 'IN', state_name: 'Indiana' },
  { city: 'Brookville', state: 'IN', state_name: 'Indiana' },
  { city: 'Brown County', state: 'IN', state_name: 'Indiana' },
  { city: 'Brownsburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Brownstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Burns Harbor', state: 'IN', state_name: 'Indiana' },
  { city: 'Butler', state: 'IN', state_name: 'Indiana' },
  { city: 'Cambridge City', state: 'IN', state_name: 'Indiana' },
  { city: 'Cannelton', state: 'IN', state_name: 'Indiana' },
  { city: 'Carmel', state: 'IN', state_name: 'Indiana' },
  { city: 'Carroll County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cass County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cayuga', state: 'IN', state_name: 'Indiana' },
  { city: 'Cedar Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Centerville', state: 'IN', state_name: 'Indiana' },
  { city: 'Chandler', state: 'IN', state_name: 'Indiana' },
  { city: 'Charlestown', state: 'IN', state_name: 'Indiana' },
  { city: 'Chesterfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Chesterton', state: 'IN', state_name: 'Indiana' },
  { city: 'Churubusco', state: 'IN', state_name: 'Indiana' },
  { city: 'Cicero', state: 'IN', state_name: 'Indiana' },
  { city: 'Clark County', state: 'IN', state_name: 'Indiana' },
  { city: 'Clarksville', state: 'IN', state_name: 'Indiana' },
  { city: 'Clay County', state: 'IN', state_name: 'Indiana' },
  { city: 'Clermont', state: 'IN', state_name: 'Indiana' },
  { city: 'Clinton', state: 'IN', state_name: 'Indiana' },
  { city: 'Clinton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cloverdale', state: 'IN', state_name: 'Indiana' },
  { city: 'Columbia City', state: 'IN', state_name: 'Indiana' },
  { city: 'Columbus', state: 'IN', state_name: 'Indiana' },
  { city: 'Connersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Converse', state: 'IN', state_name: 'Indiana' },
  { city: 'Cordry Sweetwater Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Corydon', state: 'IN', state_name: 'Indiana' },
  { city: 'Country Squire Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Covington', state: 'IN', state_name: 'Indiana' },
  { city: 'Crawford County', state: 'IN', state_name: 'Indiana' },
  { city: 'Crawfordsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Crothersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Crown Point', state: 'IN', state_name: 'Indiana' },
  { city: 'Culver', state: 'IN', state_name: 'Indiana' },
  { city: 'Cumberland', state: 'IN', state_name: 'Indiana' },
  { city: 'Dale', state: 'IN', state_name: 'Indiana' },
  { city: 'Daleville', state: 'IN', state_name: 'Indiana' },
  { city: 'Danville', state: 'IN', state_name: 'Indiana' },
  { city: 'Darmstadt', state: 'IN', state_name: 'Indiana' },
  { city: 'Daviess County', state: 'IN', state_name: 'Indiana' },
  { city: 'Dayton', state: 'IN', state_name: 'Indiana' },
  { city: 'Dearborn County', state: 'IN', state_name: 'Indiana' },
  { city: 'Decatur', state: 'IN', state_name: 'Indiana' },
  { city: 'Decatur County', state: 'IN', state_name: 'Indiana' },
  { city: 'DeKalb County', state: 'IN', state_name: 'Indiana' },
  { city: 'Delaware County', state: 'IN', state_name: 'Indiana' },
  { city: 'Delphi', state: 'IN', state_name: 'Indiana' },
  { city: 'DeMotte', state: 'IN', state_name: 'Indiana' },
  { city: 'Dillsboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Dubois County', state: 'IN', state_name: 'Indiana' },
  { city: 'Dunkirk', state: 'IN', state_name: 'Indiana' },
  { city: 'Dunlap', state: 'IN', state_name: 'Indiana' },
  { city: 'Dyer', state: 'IN', state_name: 'Indiana' },
  { city: 'East Chicago', state: 'IN', state_name: 'Indiana' },
  { city: 'Eaton', state: 'IN', state_name: 'Indiana' },
  { city: 'Edgewood', state: 'IN', state_name: 'Indiana' },
  { city: 'Edinburgh', state: 'IN', state_name: 'Indiana' },
  { city: 'Elkhart', state: 'IN', state_name: 'Indiana' },
  { city: 'Elkhart County', state: 'IN', state_name: 'Indiana' },
  { city: 'Ellettsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Elwood', state: 'IN', state_name: 'Indiana' },
  { city: 'English', state: 'IN', state_name: 'Indiana' },
  { city: 'Evansville', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairfield Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairmount', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairview Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Farmersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Farmland', state: 'IN', state_name: 'Indiana' },
  { city: 'Fayette County', state: 'IN', state_name: 'Indiana' },
  { city: 'Ferdinand', state: 'IN', state_name: 'Indiana' },
  { city: 'Fish Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Fishers', state: 'IN', state_name: 'Indiana' },
  { city: 'Flora', state: 'IN', state_name: 'Indiana' },
  { city: 'Floyd County', state: 'IN', state_name: 'Indiana' },
  { city: 'Fort Branch', state: 'IN', state_name: 'Indiana' },
  { city: 'Fort Wayne', state: 'IN', state_name: 'Indiana' },
  { city: 'Fortville', state: 'IN', state_name: 'Indiana' },
  { city: 'Fountain County', state: 'IN', state_name: 'Indiana' },
  { city: 'Fowler', state: 'IN', state_name: 'Indiana' },
  { city: 'Frankfort', state: 'IN', state_name: 'Indiana' },
  { city: 'Franklin', state: 'IN', state_name: 'Indiana' },
  { city: 'Franklin County', state: 'IN', state_name: 'Indiana' },
  { city: 'Frankton', state: 'IN', state_name: 'Indiana' },
  { city: 'Fremont', state: 'IN', state_name: 'Indiana' },
  { city: 'French Lick', state: 'IN', state_name: 'Indiana' },
  { city: 'Fulton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Galena', state: 'IN', state_name: 'Indiana' },
  { city: 'Galveston', state: 'IN', state_name: 'Indiana' },
  { city: 'Garrett', state: 'IN', state_name: 'Indiana' },
  { city: 'Gary', state: 'IN', state_name: 'Indiana' },
  { city: 'Gas City', state: 'IN', state_name: 'Indiana' },
  { city: 'Geneva', state: 'IN', state_name: 'Indiana' },
  { city: 'Georgetown', state: 'IN', state_name: 'Indiana' },
  { city: 'Gibson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Goodland', state: 'IN', state_name: 'Indiana' },
  { city: 'Goshen', state: 'IN', state_name: 'Indiana' },
  { city: 'Grabill', state: 'IN', state_name: 'Indiana' },
  { city: 'Granger', state: 'IN', state_name: 'Indiana' },
  { city: 'Grant County', state: 'IN', state_name: 'Indiana' },
  { city: 'Greencastle', state: 'IN', state_name: 'Indiana' },
  { city: 'Greendale', state: 'IN', state_name: 'Indiana' },
  { city: 'Greene County', state: 'IN', state_name: 'Indiana' },
  { city: 'Greenfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Greensburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Greentown', state: 'IN', state_name: 'Indiana' },
  { city: 'Greenwood', state: 'IN', state_name: 'Indiana' },
  { city: 'Griffith', state: 'IN', state_name: 'Indiana' },
  { city: 'Grissom Air Force Base', state: 'IN', state_name: 'Indiana' },
  { city: 'Gulivoire Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Hagerstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Hamilton', state: 'IN', state_name: 'Indiana' },
  { city: 'Hamilton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hammond', state: 'IN', state_name: 'Indiana' },
  { city: 'Hancock County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hanover', state: 'IN', state_name: 'Indiana' },
  { city: 'Harlan', state: 'IN', state_name: 'Indiana' },
  { city: 'Harrison County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hartford City', state: 'IN', state_name: 'Indiana' },
  { city: 'Haubstadt', state: 'IN', state_name: 'Indiana' },
  { city: 'Hebron', state: 'IN', state_name: 'Indiana' },
  { city: 'Hendricks County', state: 'IN', state_name: 'Indiana' },
  { city: 'Henry County', state: 'IN', state_name: 'Indiana' },
  { city: 'Henryville', state: 'IN', state_name: 'Indiana' },
  { city: 'Heritage Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Hidden Valley', state: 'IN', state_name: 'Indiana' },
  { city: 'Highland', state: 'IN', state_name: 'Indiana' },
  { city: 'Hobart', state: 'IN', state_name: 'Indiana' },
  { city: 'Hope', state: 'IN', state_name: 'Indiana' },
  { city: 'Howard County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hudson Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntertown', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntingburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntington', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntington County', state: 'IN', state_name: 'Indiana' },
  { city: 'Indian Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Indianapolis', state: 'IN', state_name: 'Indiana' },
  { city: 'Ingalls', state: 'IN', state_name: 'Indiana' },
  { city: 'Jackson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasper', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasper County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jay County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jefferson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jeffersonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Jennings County', state: 'IN', state_name: 'Indiana' },
  { city: 'Johnson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jonesboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Kendallville', state: 'IN', state_name: 'Indiana' },
  { city: 'Kentland', state: 'IN', state_name: 'Indiana' },
  { city: 'Kingsford Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Knightstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Knox', state: 'IN', state_name: 'Indiana' },
  { city: 'Knox County', state: 'IN', state_name: 'Indiana' },
  { city: 'Kokomo', state: 'IN', state_name: 'Indiana' },
  { city: 'Koontz Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Kosciusko County', state: 'IN', state_name: 'Indiana' },
  { city: 'Kouts', state: 'IN', state_name: 'Indiana' },
  { city: 'La Porte', state: 'IN', state_name: 'Indiana' },
  { city: 'Lafayette', state: 'IN', state_name: 'Indiana' },
  { city: 'Lagrange', state: 'IN', state_name: 'Indiana' },
  { city: 'LaGrange County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake Dalecarlia', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake Station', state: 'IN', state_name: 'Indiana' },
  { city: 'Lakes of the Four Seasons', state: 'IN', state_name: 'Indiana' },
  { city: 'Lapel', state: 'IN', state_name: 'Indiana' },
  { city: 'LaPorte', state: 'IN', state_name: 'Indiana' },
  { city: 'LaPorte County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrence', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrence County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrenceburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Lebanon', state: 'IN', state_name: 'Indiana' },
  { city: 'Leo-Cedarville', state: 'IN', state_name: 'Indiana' },
  { city: 'Liberty', state: 'IN', state_name: 'Indiana' },
  { city: 'Ligonier', state: 'IN', state_name: 'Indiana' },
  { city: 'Linton', state: 'IN', state_name: 'Indiana' },
  { city: 'Logansport', state: 'IN', state_name: 'Indiana' },
  { city: 'Long Beach', state: 'IN', state_name: 'Indiana' },
  { city: 'Loogootee', state: 'IN', state_name: 'Indiana' },
  { city: 'Lowell', state: 'IN', state_name: 'Indiana' },
  { city: 'Lynn', state: 'IN', state_name: 'Indiana' },
  { city: 'Madison', state: 'IN', state_name: 'Indiana' },
  { city: 'Madison County', state: 'IN', state_name: 'Indiana' },
  { city: 'Marion', state: 'IN', state_name: 'Indiana' },
  { city: 'Marion County', state: 'IN', state_name: 'Indiana' },
  { city: 'Markle', state: 'IN', state_name: 'Indiana' },
  { city: 'Marshall County', state: 'IN', state_name: 'Indiana' },
  { city: 'Martin County', state: 'IN', state_name: 'Indiana' },
  { city: 'Martinsville', state: 'IN', state_name: 'Indiana' },
  { city: 'McCordsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Melody Hill', state: 'IN', state_name: 'Indiana' },
  { city: 'Meridian Hills', state: 'IN', state_name: 'Indiana' },
  { city: 'Merrillville', state: 'IN', state_name: 'Indiana' },
  { city: 'Miami County', state: 'IN', state_name: 'Indiana' },
  { city: 'Michigan City', state: 'IN', state_name: 'Indiana' },
  { city: 'Middlebury', state: 'IN', state_name: 'Indiana' },
  { city: 'Middletown', state: 'IN', state_name: 'Indiana' },
  { city: 'Milan', state: 'IN', state_name: 'Indiana' },
  { city: 'Milford', state: 'IN', state_name: 'Indiana' },
  { city: 'Mishawaka', state: 'IN', state_name: 'Indiana' },
  { city: 'Mitchell', state: 'IN', state_name: 'Indiana' },
  { city: 'Monon', state: 'IN', state_name: 'Indiana' },
  { city: 'Monroe County', state: 'IN', state_name: 'Indiana' },
  { city: 'Monroeville', state: 'IN', state_name: 'Indiana' },
  { city: 'Monrovia', state: 'IN', state_name: 'Indiana' },
  { city: 'Montgomery County', state: 'IN', state_name: 'Indiana' },
  { city: 'Monticello', state: 'IN', state_name: 'Indiana' },
  { city: 'Montpelier', state: 'IN', state_name: 'Indiana' },
  { city: 'Mooresville', state: 'IN', state_name: 'Indiana' },
  { city: 'Morgan County', state: 'IN', state_name: 'Indiana' },
  { city: 'Morocco', state: 'IN', state_name: 'Indiana' },
  { city: 'Morristown', state: 'IN', state_name: 'Indiana' },
  { city: 'Mount Vernon', state: 'IN', state_name: 'Indiana' },
  { city: 'Mulberry', state: 'IN', state_name: 'Indiana' },
  { city: 'Muncie', state: 'IN', state_name: 'Indiana' },
  { city: 'Munster', state: 'IN', state_name: 'Indiana' },
  { city: 'Nappanee', state: 'IN', state_name: 'Indiana' },
  { city: 'Nashville', state: 'IN', state_name: 'Indiana' },
  { city: 'New Albany', state: 'IN', state_name: 'Indiana' },
  { city: 'New Carlisle', state: 'IN', state_name: 'Indiana' },
  { city: 'New Castle', state: 'IN', state_name: 'Indiana' },
  { city: 'New Chicago', state: 'IN', state_name: 'Indiana' },
  { city: 'New Haven', state: 'IN', state_name: 'Indiana' },
  { city: 'New Palestine', state: 'IN', state_name: 'Indiana' },
  { city: 'New Paris', state: 'IN', state_name: 'Indiana' },
  { city: 'New Pekin', state: 'IN', state_name: 'Indiana' },
  { city: 'New Whiteland', state: 'IN', state_name: 'Indiana' },
  { city: 'Newburgh', state: 'IN', state_name: 'Indiana' },
  { city: 'Newport', state: 'IN', state_name: 'Indiana' },
  { city: 'Newton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Noble County', state: 'IN', state_name: 'Indiana' },
  { city: 'Noblesville', state: 'IN', state_name: 'Indiana' },
  { city: 'North Judson', state: 'IN', state_name: 'Indiana' },
  { city: 'North Liberty', state: 'IN', state_name: 'Indiana' },
  { city: 'North Madison', state: 'IN', state_name: 'Indiana' },
  { city: 'North Manchester', state: 'IN', state_name: 'Indiana' },
  { city: 'North Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'North Vernon', state: 'IN', state_name: 'Indiana' },
  { city: 'North Webster', state: 'IN', state_name: 'Indiana' },
  { city: 'Notre Dame', state: 'IN', state_name: 'Indiana' },
  { city: 'Oak Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Oakland City', state: 'IN', state_name: 'Indiana' },
  { city: 'Odon', state: 'IN', state_name: 'Indiana' },
  { city: 'Ogden Dunes', state: 'IN', state_name: 'Indiana' },
  { city: 'Ohio County', state: 'IN', state_name: 'Indiana' },
  { city: 'Oolitic', state: 'IN', state_name: 'Indiana' },
  { city: 'Orange County', state: 'IN', state_name: 'Indiana' },
  { city: 'Orleans', state: 'IN', state_name: 'Indiana' },
  { city: 'Osceola', state: 'IN', state_name: 'Indiana' },
  { city: 'Osgood', state: 'IN', state_name: 'Indiana' },
  { city: 'Ossian', state: 'IN', state_name: 'Indiana' },
  { city: 'Otterbein', state: 'IN', state_name: 'Indiana' },
  { city: 'Owen County', state: 'IN', state_name: 'Indiana' },
  { city: 'Owensville', state: 'IN', state_name: 'Indiana' },
  { city: 'Oxford', state: 'IN', state_name: 'Indiana' },
  { city: 'Paoli', state: 'IN', state_name: 'Indiana' },
  { city: 'Parke County', state: 'IN', state_name: 'Indiana' },
  { city: 'Parker City', state: 'IN', state_name: 'Indiana' },
  { city: 'Pendleton', state: 'IN', state_name: 'Indiana' },
  { city: 'Perry County', state: 'IN', state_name: 'Indiana' },
  { city: 'Peru', state: 'IN', state_name: 'Indiana' },
  { city: 'Petersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Pierceton', state: 'IN', state_name: 'Indiana' },
  { city: 'Pike County', state: 'IN', state_name: 'Indiana' },
  { city: 'Pittsboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Plainfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Plymouth', state: 'IN', state_name: 'Indiana' },
  { city: 'Portage', state: 'IN', state_name: 'Indiana' },
  { city: 'Porter', state: 'IN', state_name: 'Indiana' },
  { city: 'Porter County', state: 'IN', state_name: 'Indiana' },
  { city: 'Portland', state: 'IN', state_name: 'Indiana' },
  { city: 'Posey County', state: 'IN', state_name: 'Indiana' },
  { city: 'Poseyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Princes Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Princeton', state: 'IN', state_name: 'Indiana' },
  { city: 'Pulaski County', state: 'IN', state_name: 'Indiana' },
  { city: 'Putnam County', state: 'IN', state_name: 'Indiana' },
  { city: 'Randolph County', state: 'IN', state_name: 'Indiana' },
  { city: 'Redkey', state: 'IN', state_name: 'Indiana' },
  { city: 'Remington', state: 'IN', state_name: 'Indiana' },
  { city: 'Rensselaer', state: 'IN', state_name: 'Indiana' },
  { city: 'Richmond', state: 'IN', state_name: 'Indiana' },
  { city: 'Ripley County', state: 'IN', state_name: 'Indiana' },
  { city: 'Rising Sun', state: 'IN', state_name: 'Indiana' },
  { city: 'Roanoke', state: 'IN', state_name: 'Indiana' },
  { city: 'Rochester', state: 'IN', state_name: 'Indiana' },
  { city: 'Rockport', state: 'IN', state_name: 'Indiana' },
  { city: 'Rockville', state: 'IN', state_name: 'Indiana' },
  { city: 'Rome City', state: 'IN', state_name: 'Indiana' },
  { city: 'Roselawn', state: 'IN', state_name: 'Indiana' },
  { city: 'Rossville', state: 'IN', state_name: 'Indiana' },
  { city: 'Rush County', state: 'IN', state_name: 'Indiana' },
  { city: 'Rushville', state: 'IN', state_name: 'Indiana' },
  { city: 'Russiaville', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint John', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint Joseph County', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint Paul', state: 'IN', state_name: 'Indiana' },
  { city: 'Salem', state: 'IN', state_name: 'Indiana' },
  { city: 'Santa Claus', state: 'IN', state_name: 'Indiana' },
  { city: 'Schererville', state: 'IN', state_name: 'Indiana' },
  { city: 'Scott County', state: 'IN', state_name: 'Indiana' },
  { city: 'Scottsburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Seelyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Sellersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Seymour', state: 'IN', state_name: 'Indiana' },
  { city: 'Shadeland', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelby County', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelbyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Sheridan', state: 'IN', state_name: 'Indiana' },
  { city: 'Shoals', state: 'IN', state_name: 'Indiana' },
  { city: 'Shorewood Forest', state: 'IN', state_name: 'Indiana' },
  { city: 'Simonton Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Smithville-Sanders', state: 'IN', state_name: 'Indiana' },
  { city: 'South Bend', state: 'IN', state_name: 'Indiana' },
  { city: 'South Haven', state: 'IN', state_name: 'Indiana' },
  { city: 'South Whitley', state: 'IN', state_name: 'Indiana' },
  { city: 'Southport', state: 'IN', state_name: 'Indiana' },
  { city: 'Speedway', state: 'IN', state_name: 'Indiana' },
  { city: 'Spencer', state: 'IN', state_name: 'Indiana' },
  { city: 'Spencer County', state: 'IN', state_name: 'Indiana' },
  { city: 'Starke County', state: 'IN', state_name: 'Indiana' },
  { city: 'Steuben County', state: 'IN', state_name: 'Indiana' },
  { city: 'Sullivan', state: 'IN', state_name: 'Indiana' },
  { city: 'Sullivan County', state: 'IN', state_name: 'Indiana' },
  { city: 'Sunman', state: 'IN', state_name: 'Indiana' },
  { city: 'Sweetser', state: 'IN', state_name: 'Indiana' },
  { city: 'Switzerland County', state: 'IN', state_name: 'Indiana' },
  { city: 'Syracuse', state: 'IN', state_name: 'Indiana' },
  { city: 'Tell City', state: 'IN', state_name: 'Indiana' },
  { city: 'Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'Thorntown', state: 'IN', state_name: 'Indiana' },
  { city: 'Tippecanoe County', state: 'IN', state_name: 'Indiana' },
  { city: 'Tipton', state: 'IN', state_name: 'Indiana' },
  { city: 'Tipton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Topeka', state: 'IN', state_name: 'Indiana' },
  { city: 'Trafalgar', state: 'IN', state_name: 'Indiana' },
  { city: 'Trail Creek', state: 'IN', state_name: 'Indiana' },
  { city: 'Tri-Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Union City', state: 'IN', state_name: 'Indiana' },
  { city: 'Union County', state: 'IN', state_name: 'Indiana' },
  { city: 'Upland', state: 'IN', state_name: 'Indiana' },
  { city: 'Valparaiso', state: 'IN', state_name: 'Indiana' },
  { city: 'Vanderburgh County', state: 'IN', state_name: 'Indiana' },
  { city: 'Veedersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Vermillion County', state: 'IN', state_name: 'Indiana' },
  { city: 'Versailles', state: 'IN', state_name: 'Indiana' },
  { city: 'Vevay', state: 'IN', state_name: 'Indiana' },
  { city: 'Vigo County', state: 'IN', state_name: 'Indiana' },
  { city: 'Vincennes', state: 'IN', state_name: 'Indiana' },
  { city: 'Wabash', state: 'IN', state_name: 'Indiana' },
  { city: 'Wabash County', state: 'IN', state_name: 'Indiana' },
  { city: 'Wakarusa', state: 'IN', state_name: 'Indiana' },
  { city: 'Walkerton', state: 'IN', state_name: 'Indiana' },
  { city: 'Walton', state: 'IN', state_name: 'Indiana' },
  { city: 'Wanatah', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren County', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Warrick County', state: 'IN', state_name: 'Indiana' },
  { city: 'Warsaw', state: 'IN', state_name: 'Indiana' },
  { city: 'Washington', state: 'IN', state_name: 'Indiana' },
  { city: 'Washington County', state: 'IN', state_name: 'Indiana' },
  { city: 'Waterloo', state: 'IN', state_name: 'Indiana' },
  { city: 'Wayne County', state: 'IN', state_name: 'Indiana' },
  { city: 'Wells County', state: 'IN', state_name: 'Indiana' },
  { city: 'West Lafayette', state: 'IN', state_name: 'Indiana' },
  { city: 'West Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'Westfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Westport', state: 'IN', state_name: 'Indiana' },
  { city: 'Westville', state: 'IN', state_name: 'Indiana' },
  { city: 'White County', state: 'IN', state_name: 'Indiana' },
  { city: 'Whiteland', state: 'IN', state_name: 'Indiana' },
  { city: 'Whitestown', state: 'IN', state_name: 'Indiana' },
  { city: 'Whiting', state: 'IN', state_name: 'Indiana' },
  { city: 'Whitley County', state: 'IN', state_name: 'Indiana' },
  { city: 'Williamsport', state: 'IN', state_name: 'Indiana' },
  { city: 'Winamac', state: 'IN', state_name: 'Indiana' },
  { city: 'Winchester', state: 'IN', state_name: 'Indiana' },
  { city: 'Winfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Winona Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Wolcottville', state: 'IN', state_name: 'Indiana' },
  { city: 'Woodburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Worthington', state: 'IN', state_name: 'Indiana' },
  { city: 'Yorktown', state: 'IN', state_name: 'Indiana' },
  { city: 'Zionsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Ackley', state: 'IA', state_name: 'Iowa' },
  { city: 'Adair County', state: 'IA', state_name: 'Iowa' },
  { city: 'Adams County', state: 'IA', state_name: 'Iowa' },
  { city: 'Adel', state: 'IA', state_name: 'Iowa' },
  { city: 'Akron', state: 'IA', state_name: 'Iowa' },
  { city: 'Albia', state: 'IA', state_name: 'Iowa' },
  { city: 'Algona', state: 'IA', state_name: 'Iowa' },
  { city: 'Allamakee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Allison', state: 'IA', state_name: 'Iowa' },
  { city: 'Alta', state: 'IA', state_name: 'Iowa' },
  { city: 'Alton', state: 'IA', state_name: 'Iowa' },
  { city: 'Altoona', state: 'IA', state_name: 'Iowa' },
  { city: 'Ames', state: 'IA', state_name: 'Iowa' },
  { city: 'Anamosa', state: 'IA', state_name: 'Iowa' },
  { city: 'Ankeny', state: 'IA', state_name: 'Iowa' },
  { city: 'Aplington', state: 'IA', state_name: 'Iowa' },
  { city: 'Appanoose County', state: 'IA', state_name: 'Iowa' },
  { city: 'Arnolds Park', state: 'IA', state_name: 'Iowa' },
  { city: 'Asbury', state: 'IA', state_name: 'Iowa' },
  { city: 'Atkins', state: 'IA', state_name: 'Iowa' },
  { city: 'Atlantic', state: 'IA', state_name: 'Iowa' },
  { city: 'Audubon', state: 'IA', state_name: 'Iowa' },
  { city: 'Audubon County', state: 'IA', state_name: 'Iowa' },
  { city: 'Avoca', state: 'IA', state_name: 'Iowa' },
  { city: 'Baxter', state: 'IA', state_name: 'Iowa' },
  { city: 'Bedford', state: 'IA', state_name: 'Iowa' },
  { city: 'Belle Plaine', state: 'IA', state_name: 'Iowa' },
  { city: 'Bellevue', state: 'IA', state_name: 'Iowa' },
  { city: 'Belmond', state: 'IA', state_name: 'Iowa' },
  { city: 'Benton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bettendorf', state: 'IA', state_name: 'Iowa' },
  { city: 'Black Hawk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bloomfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Blue Grass', state: 'IA', state_name: 'Iowa' },
  { city: 'Bondurant', state: 'IA', state_name: 'Iowa' },
  { city: 'Boone', state: 'IA', state_name: 'Iowa' },
  { city: 'Boone County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bremer County', state: 'IA', state_name: 'Iowa' },
  { city: 'Britt', state: 'IA', state_name: 'Iowa' },
  { city: 'Brooklyn', state: 'IA', state_name: 'Iowa' },
  { city: 'Buchanan County', state: 'IA', state_name: 'Iowa' },
  { city: 'Buena Vista County', state: 'IA', state_name: 'Iowa' },
  { city: 'Buffalo', state: 'IA', state_name: 'Iowa' },
  { city: 'Buffalo (historical)', state: 'IA', state_name: 'Iowa' },
  { city: 'Burlington', state: 'IA', state_name: 'Iowa' },
  { city: 'Butler County', state: 'IA', state_name: 'Iowa' },
  { city: 'Calhoun County', state: 'IA', state_name: 'Iowa' },
  { city: 'Camanche', state: 'IA', state_name: 'Iowa' },
  { city: 'Carlisle', state: 'IA', state_name: 'Iowa' },
  { city: 'Carroll', state: 'IA', state_name: 'Iowa' },
  { city: 'Carroll County', state: 'IA', state_name: 'Iowa' },
  { city: 'Carter Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Cascade', state: 'IA', state_name: 'Iowa' },
  { city: 'Cass County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar Falls', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Center Point', state: 'IA', state_name: 'Iowa' },
  { city: 'Centerville', state: 'IA', state_name: 'Iowa' },
  { city: 'Central City', state: 'IA', state_name: 'Iowa' },
  { city: 'Cerro Gordo County', state: 'IA', state_name: 'Iowa' },
  { city: 'Chariton', state: 'IA', state_name: 'Iowa' },
  { city: 'Charles City', state: 'IA', state_name: 'Iowa' },
  { city: 'Cherokee', state: 'IA', state_name: 'Iowa' },
  { city: 'Cherokee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Chickasaw County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarinda', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarion', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarke County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarksville', state: 'IA', state_name: 'Iowa' },
  { city: 'Clay County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clayton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clear Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Clinton', state: 'IA', state_name: 'Iowa' },
  { city: 'Clinton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clive', state: 'IA', state_name: 'Iowa' },
  { city: 'Colfax', state: 'IA', state_name: 'Iowa' },
  { city: 'Columbus Junction', state: 'IA', state_name: 'Iowa' },
  { city: 'Conrad', state: 'IA', state_name: 'Iowa' },
  { city: 'Coon Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Coralville', state: 'IA', state_name: 'Iowa' },
  { city: 'Corning', state: 'IA', state_name: 'Iowa' },
  { city: 'Corydon', state: 'IA', state_name: 'Iowa' },
  { city: 'Council Bluffs', state: 'IA', state_name: 'Iowa' },
  { city: 'Crawford County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cresco', state: 'IA', state_name: 'Iowa' },
  { city: 'Creston', state: 'IA', state_name: 'Iowa' },
  { city: 'Dakota City', state: 'IA', state_name: 'Iowa' },
  { city: 'Dallas Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Dallas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Davenport', state: 'IA', state_name: 'Iowa' },
  { city: 'Davis County', state: 'IA', state_name: 'Iowa' },
  { city: 'De Soto', state: 'IA', state_name: 'Iowa' },
  { city: 'De Witt', state: 'IA', state_name: 'Iowa' },
  { city: 'Decatur County', state: 'IA', state_name: 'Iowa' },
  { city: 'Decorah', state: 'IA', state_name: 'Iowa' },
  { city: 'Delaware County', state: 'IA', state_name: 'Iowa' },
  { city: 'Denison', state: 'IA', state_name: 'Iowa' },
  { city: 'Denver', state: 'IA', state_name: 'Iowa' },
  { city: 'Des Moines', state: 'IA', state_name: 'Iowa' },
  { city: 'Des Moines County', state: 'IA', state_name: 'Iowa' },
  { city: 'Dickinson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Dike', state: 'IA', state_name: 'Iowa' },
  { city: 'Dubuque', state: 'IA', state_name: 'Iowa' },
  { city: 'Dubuque County', state: 'IA', state_name: 'Iowa' },
  { city: 'Durant', state: 'IA', state_name: 'Iowa' },
  { city: 'Dyersville', state: 'IA', state_name: 'Iowa' },
  { city: 'Dysart', state: 'IA', state_name: 'Iowa' },
  { city: 'Eagle Grove', state: 'IA', state_name: 'Iowa' },
  { city: 'Earlham', state: 'IA', state_name: 'Iowa' },
  { city: 'Eddyville', state: 'IA', state_name: 'Iowa' },
  { city: 'Eldora', state: 'IA', state_name: 'Iowa' },
  { city: 'Eldridge', state: 'IA', state_name: 'Iowa' },
  { city: 'Elk Run Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Elkader', state: 'IA', state_name: 'Iowa' },
  { city: 'Ely', state: 'IA', state_name: 'Iowa' },
  { city: 'Emmet County', state: 'IA', state_name: 'Iowa' },
  { city: 'Emmetsburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Epworth', state: 'IA', state_name: 'Iowa' },
  { city: 'Estherville', state: 'IA', state_name: 'Iowa' },
  { city: 'Evansdale', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairbank', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairfax', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Farley', state: 'IA', state_name: 'Iowa' },
  { city: 'Fayette', state: 'IA', state_name: 'Iowa' },
  { city: 'Fayette County', state: 'IA', state_name: 'Iowa' },
  { city: 'Floyd County', state: 'IA', state_name: 'Iowa' },
  { city: 'Forest City', state: 'IA', state_name: 'Iowa' },
  { city: 'Fort Dodge', state: 'IA', state_name: 'Iowa' },
  { city: 'Fort Madison', state: 'IA', state_name: 'Iowa' },
  { city: 'Franklin County', state: 'IA', state_name: 'Iowa' },
  { city: 'Fremont County', state: 'IA', state_name: 'Iowa' },
  { city: 'Garner', state: 'IA', state_name: 'Iowa' },
  { city: 'George', state: 'IA', state_name: 'Iowa' },
  { city: 'Gilbert', state: 'IA', state_name: 'Iowa' },
  { city: 'Glenwood', state: 'IA', state_name: 'Iowa' },
  { city: 'Glidden', state: 'IA', state_name: 'Iowa' },
  { city: 'Granger', state: 'IA', state_name: 'Iowa' },
  { city: 'Greene', state: 'IA', state_name: 'Iowa' },
  { city: 'Greene County', state: 'IA', state_name: 'Iowa' },
  { city: 'Greenfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Grimes', state: 'IA', state_name: 'Iowa' },
  { city: 'Grinnell', state: 'IA', state_name: 'Iowa' },
  { city: 'Grundy Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Grundy County', state: 'IA', state_name: 'Iowa' },
  { city: 'Guthrie Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Guthrie County', state: 'IA', state_name: 'Iowa' },
  { city: 'Guttenberg', state: 'IA', state_name: 'Iowa' },
  { city: 'Hamburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Hamilton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hampton', state: 'IA', state_name: 'Iowa' },
  { city: 'Hancock County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hardin County', state: 'IA', state_name: 'Iowa' },
  { city: 'Harlan', state: 'IA', state_name: 'Iowa' },
  { city: 'Harrison County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hartley', state: 'IA', state_name: 'Iowa' },
  { city: 'Hawarden', state: 'IA', state_name: 'Iowa' },
  { city: 'Henry County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hiawatha', state: 'IA', state_name: 'Iowa' },
  { city: 'Holstein', state: 'IA', state_name: 'Iowa' },
  { city: 'Howard County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hudson', state: 'IA', state_name: 'Iowa' },
  { city: 'Hull', state: 'IA', state_name: 'Iowa' },
  { city: 'Humboldt', state: 'IA', state_name: 'Iowa' },
  { city: 'Humboldt County', state: 'IA', state_name: 'Iowa' },
  { city: 'Huxley', state: 'IA', state_name: 'Iowa' },
  { city: 'Independence', state: 'IA', state_name: 'Iowa' },
  { city: 'Indianola', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa City', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa County', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa Falls', state: 'IA', state_name: 'Iowa' },
  { city: 'Jackson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jasper County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jefferson', state: 'IA', state_name: 'Iowa' },
  { city: 'Jefferson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jesup', state: 'IA', state_name: 'Iowa' },
  { city: 'Jewell', state: 'IA', state_name: 'Iowa' },
  { city: 'Johnson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Johnston', state: 'IA', state_name: 'Iowa' },
  { city: 'Jones County', state: 'IA', state_name: 'Iowa' },
  { city: 'Kalona', state: 'IA', state_name: 'Iowa' },
  { city: 'Keokuk', state: 'IA', state_name: 'Iowa' },
  { city: 'Keokuk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Keosauqua', state: 'IA', state_name: 'Iowa' },
  { city: 'Kingsley', state: 'IA', state_name: 'Iowa' },
  { city: 'Knoxville', state: 'IA', state_name: 'Iowa' },
  { city: 'Kossuth County', state: 'IA', state_name: 'Iowa' },
  { city: 'La Porte City', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake City', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Mills', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Panorama', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Park', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake View', state: 'IA', state_name: 'Iowa' },
  { city: 'Lamoni', state: 'IA', state_name: 'Iowa' },
  { city: 'Laurens', state: 'IA', state_name: 'Iowa' },
  { city: 'Le Claire', state: 'IA', state_name: 'Iowa' },
  { city: 'Le Mars', state: 'IA', state_name: 'Iowa' },
  { city: 'Lee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lenox', state: 'IA', state_name: 'Iowa' },
  { city: 'Leon', state: 'IA', state_name: 'Iowa' },
  { city: 'Linn County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lisbon', state: 'IA', state_name: 'Iowa' },
  { city: 'Logan', state: 'IA', state_name: 'Iowa' },
  { city: 'Lone Tree', state: 'IA', state_name: 'Iowa' },
  { city: 'Louisa County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lucas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lyon County', state: 'IA', state_name: 'Iowa' },
  { city: 'Madison County', state: 'IA', state_name: 'Iowa' },
  { city: 'Madrid', state: 'IA', state_name: 'Iowa' },
  { city: 'Maharishi Vedic City', state: 'IA', state_name: 'Iowa' },
  { city: 'Mahaska County', state: 'IA', state_name: 'Iowa' },
  { city: 'Malvern', state: 'IA', state_name: 'Iowa' },
  { city: 'Manchester', state: 'IA', state_name: 'Iowa' },
  { city: 'Manly', state: 'IA', state_name: 'Iowa' },
  { city: 'Manning', state: 'IA', state_name: 'Iowa' },
  { city: 'Manson', state: 'IA', state_name: 'Iowa' },
  { city: 'Mapleton', state: 'IA', state_name: 'Iowa' },
  { city: 'Maquoketa', state: 'IA', state_name: 'Iowa' },
  { city: 'Marcus', state: 'IA', state_name: 'Iowa' },
  { city: 'Marengo', state: 'IA', state_name: 'Iowa' },
  { city: 'Marion', state: 'IA', state_name: 'Iowa' },
  { city: 'Marion County', state: 'IA', state_name: 'Iowa' },
  { city: 'Marshall County', state: 'IA', state_name: 'Iowa' },
  { city: 'Marshalltown', state: 'IA', state_name: 'Iowa' },
  { city: 'Mason City', state: 'IA', state_name: 'Iowa' },
  { city: 'Mechanicsville', state: 'IA', state_name: 'Iowa' },
  { city: 'Mediapolis', state: 'IA', state_name: 'Iowa' },
  { city: 'Melcher-Dallas', state: 'IA', state_name: 'Iowa' },
  { city: 'Milford', state: 'IA', state_name: 'Iowa' },
  { city: 'Mills County', state: 'IA', state_name: 'Iowa' },
  { city: 'Missouri Valley', state: 'IA', state_name: 'Iowa' },
  { city: 'Mitchell County', state: 'IA', state_name: 'Iowa' },
  { city: 'Mitchellville', state: 'IA', state_name: 'Iowa' },
  { city: 'Monona', state: 'IA', state_name: 'Iowa' },
  { city: 'Monona County', state: 'IA', state_name: 'Iowa' },
  { city: 'Monroe', state: 'IA', state_name: 'Iowa' },
  { city: 'Monroe County', state: 'IA', state_name: 'Iowa' },
  { city: 'Montezuma', state: 'IA', state_name: 'Iowa' },
  { city: 'Montgomery County', state: 'IA', state_name: 'Iowa' },
  { city: 'Monticello', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Ayr', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Pleasant', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Vernon', state: 'IA', state_name: 'Iowa' },
  { city: 'Moville', state: 'IA', state_name: 'Iowa' },
  { city: 'Muscatine', state: 'IA', state_name: 'Iowa' },
  { city: 'Muscatine County', state: 'IA', state_name: 'Iowa' },
  { city: 'Nashua', state: 'IA', state_name: 'Iowa' },
  { city: 'Nevada', state: 'IA', state_name: 'Iowa' },
  { city: 'New Hampton', state: 'IA', state_name: 'Iowa' },
  { city: 'New London', state: 'IA', state_name: 'Iowa' },
  { city: 'New Sharon', state: 'IA', state_name: 'Iowa' },
  { city: 'Newton', state: 'IA', state_name: 'Iowa' },
  { city: 'Nora Springs', state: 'IA', state_name: 'Iowa' },
  { city: 'North English', state: 'IA', state_name: 'Iowa' },
  { city: 'North Liberty', state: 'IA', state_name: 'Iowa' },
  { city: 'Northwood', state: 'IA', state_name: 'Iowa' },
  { city: 'Norwalk', state: 'IA', state_name: 'Iowa' },
  { city: "O'Brien County", state: 'IA', state_name: 'Iowa' },
  { city: 'Oakland', state: 'IA', state_name: 'Iowa' },
  { city: 'Oelwein', state: 'IA', state_name: 'Iowa' },
  { city: 'Ogden', state: 'IA', state_name: 'Iowa' },
  { city: 'Onawa', state: 'IA', state_name: 'Iowa' },
  { city: 'Orange City', state: 'IA', state_name: 'Iowa' },
  { city: 'Osage', state: 'IA', state_name: 'Iowa' },
  { city: 'Osceola', state: 'IA', state_name: 'Iowa' },
  { city: 'Osceola County', state: 'IA', state_name: 'Iowa' },
  { city: 'Oskaloosa', state: 'IA', state_name: 'Iowa' },
  { city: 'Ottumwa', state: 'IA', state_name: 'Iowa' },
  { city: 'Page County', state: 'IA', state_name: 'Iowa' },
  { city: 'Palo', state: 'IA', state_name: 'Iowa' },
  { city: 'Palo Alto County', state: 'IA', state_name: 'Iowa' },
  { city: 'Panora', state: 'IA', state_name: 'Iowa' },
  { city: 'Park View', state: 'IA', state_name: 'Iowa' },
  { city: 'Parkersburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Paullina', state: 'IA', state_name: 'Iowa' },
  { city: 'Pella', state: 'IA', state_name: 'Iowa' },
  { city: 'Peosta', state: 'IA', state_name: 'Iowa' },
  { city: 'Perry', state: 'IA', state_name: 'Iowa' },
  { city: 'Pleasant Hill', state: 'IA', state_name: 'Iowa' },
  { city: 'Pleasantville', state: 'IA', state_name: 'Iowa' },
  { city: 'Plymouth County', state: 'IA', state_name: 'Iowa' },
  { city: 'Pocahontas', state: 'IA', state_name: 'Iowa' },
  { city: 'Pocahontas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Polk City', state: 'IA', state_name: 'Iowa' },
  { city: 'Polk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Postville', state: 'IA', state_name: 'Iowa' },
  { city: 'Pottawattamie County', state: 'IA', state_name: 'Iowa' },
  { city: 'Poweshiek County', state: 'IA', state_name: 'Iowa' },
  { city: 'Prairie City', state: 'IA', state_name: 'Iowa' },
  { city: 'Preston', state: 'IA', state_name: 'Iowa' },
  { city: 'Primghar', state: 'IA', state_name: 'Iowa' },
  { city: 'Red Oak', state: 'IA', state_name: 'Iowa' },
  { city: 'Reinbeck', state: 'IA', state_name: 'Iowa' },
  { city: 'Remsen', state: 'IA', state_name: 'Iowa' },
  { city: 'Ringgold County', state: 'IA', state_name: 'Iowa' },
  { city: 'Riverside', state: 'IA', state_name: 'Iowa' },
  { city: 'Robins', state: 'IA', state_name: 'Iowa' },
  { city: 'Rock Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Rock Valley', state: 'IA', state_name: 'Iowa' },
  { city: 'Rockwell', state: 'IA', state_name: 'Iowa' },
  { city: 'Rockwell City', state: 'IA', state_name: 'Iowa' },
  { city: 'Roland', state: 'IA', state_name: 'Iowa' },
  { city: 'Sac City', state: 'IA', state_name: 'Iowa' },
  { city: 'Sac County', state: 'IA', state_name: 'Iowa' },
  { city: 'Saint Ansgar', state: 'IA', state_name: 'Iowa' },
  { city: 'Sanborn', state: 'IA', state_name: 'Iowa' },
  { city: 'Saylorville', state: 'IA', state_name: 'Iowa' },
  { city: 'Scott County', state: 'IA', state_name: 'Iowa' },
  { city: 'Sergeant Bluff', state: 'IA', state_name: 'Iowa' },
  { city: 'Sheffield', state: 'IA', state_name: 'Iowa' },
  { city: 'Shelby County', state: 'IA', state_name: 'Iowa' },
  { city: 'Sheldon', state: 'IA', state_name: 'Iowa' },
  { city: 'Shell Rock', state: 'IA', state_name: 'Iowa' },
  { city: 'Shenandoah', state: 'IA', state_name: 'Iowa' },
  { city: 'Sibley', state: 'IA', state_name: 'Iowa' },
  { city: 'Sidney', state: 'IA', state_name: 'Iowa' },
  { city: 'Sigourney', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux City', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux County', state: 'IA', state_name: 'Iowa' },
  { city: 'Slater', state: 'IA', state_name: 'Iowa' },
  { city: 'Solon', state: 'IA', state_name: 'Iowa' },
  { city: 'Spencer', state: 'IA', state_name: 'Iowa' },
  { city: 'Spirit Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Springville', state: 'IA', state_name: 'Iowa' },
  { city: 'State Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Storm Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Story City', state: 'IA', state_name: 'Iowa' },
  { city: 'Story County', state: 'IA', state_name: 'Iowa' },
  { city: 'Strawberry Point', state: 'IA', state_name: 'Iowa' },
  { city: 'Stuart', state: 'IA', state_name: 'Iowa' },
  { city: 'Sumner', state: 'IA', state_name: 'Iowa' },
  { city: 'Tama', state: 'IA', state_name: 'Iowa' },
  { city: 'Tama County', state: 'IA', state_name: 'Iowa' },
  { city: 'Taylor County', state: 'IA', state_name: 'Iowa' },
  { city: 'Tiffin', state: 'IA', state_name: 'Iowa' },
  { city: 'Tipton', state: 'IA', state_name: 'Iowa' },
  { city: 'Toledo', state: 'IA', state_name: 'Iowa' },
  { city: 'Traer', state: 'IA', state_name: 'Iowa' },
  { city: 'Tripoli', state: 'IA', state_name: 'Iowa' },
  { city: 'Union County', state: 'IA', state_name: 'Iowa' },
  { city: 'University Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Urbana', state: 'IA', state_name: 'Iowa' },
  { city: 'Urbandale', state: 'IA', state_name: 'Iowa' },
  { city: 'Van Buren County', state: 'IA', state_name: 'Iowa' },
  { city: 'Van Meter', state: 'IA', state_name: 'Iowa' },
  { city: 'Villisca', state: 'IA', state_name: 'Iowa' },
  { city: 'Vinton', state: 'IA', state_name: 'Iowa' },
  { city: 'Walcott', state: 'IA', state_name: 'Iowa' },
  { city: 'Walford', state: 'IA', state_name: 'Iowa' },
  { city: 'Wapello', state: 'IA', state_name: 'Iowa' },
  { city: 'Wapello County', state: 'IA', state_name: 'Iowa' },
  { city: 'Warren County', state: 'IA', state_name: 'Iowa' },
  { city: 'Washington', state: 'IA', state_name: 'Iowa' },
  { city: 'Washington County', state: 'IA', state_name: 'Iowa' },
  { city: 'Waterloo', state: 'IA', state_name: 'Iowa' },
  { city: 'Waukee', state: 'IA', state_name: 'Iowa' },
  { city: 'Waukon', state: 'IA', state_name: 'Iowa' },
  { city: 'Waverly', state: 'IA', state_name: 'Iowa' },
  { city: 'Wayne County', state: 'IA', state_name: 'Iowa' },
  { city: 'Webster City', state: 'IA', state_name: 'Iowa' },
  { city: 'Webster County', state: 'IA', state_name: 'Iowa' },
  { city: 'Wellman', state: 'IA', state_name: 'Iowa' },
  { city: 'West Branch', state: 'IA', state_name: 'Iowa' },
  { city: 'West Burlington', state: 'IA', state_name: 'Iowa' },
  { city: 'West Des Moines', state: 'IA', state_name: 'Iowa' },
  { city: 'West Liberty', state: 'IA', state_name: 'Iowa' },
  { city: 'West Union', state: 'IA', state_name: 'Iowa' },
  { city: 'Williamsburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Wilton', state: 'IA', state_name: 'Iowa' },
  { city: 'Windsor Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Winfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Winnebago County', state: 'IA', state_name: 'Iowa' },
  { city: 'Winneshiek County', state: 'IA', state_name: 'Iowa' },
  { city: 'Winterset', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodbine', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodbury County', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodward', state: 'IA', state_name: 'Iowa' },
  { city: 'Worth County', state: 'IA', state_name: 'Iowa' },
  { city: 'Wright County', state: 'IA', state_name: 'Iowa' },
  { city: 'Abilene', state: 'KS', state_name: 'Kansas' },
  { city: 'Allen County', state: 'KS', state_name: 'Kansas' },
  { city: 'Alma', state: 'KS', state_name: 'Kansas' },
  { city: 'Altamont', state: 'KS', state_name: 'Kansas' },
  { city: 'Anderson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Andover', state: 'KS', state_name: 'Kansas' },
  { city: 'Anthony', state: 'KS', state_name: 'Kansas' },
  { city: 'Arkansas City', state: 'KS', state_name: 'Kansas' },
  { city: 'Arma', state: 'KS', state_name: 'Kansas' },
  { city: 'Ashland', state: 'KS', state_name: 'Kansas' },
  { city: 'Atchison', state: 'KS', state_name: 'Kansas' },
  { city: 'Atchison County', state: 'KS', state_name: 'Kansas' },
  { city: 'Atwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Auburn', state: 'KS', state_name: 'Kansas' },
  { city: 'Augusta', state: 'KS', state_name: 'Kansas' },
  { city: 'Baldwin City', state: 'KS', state_name: 'Kansas' },
  { city: 'Barber County', state: 'KS', state_name: 'Kansas' },
  { city: 'Barton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Basehor', state: 'KS', state_name: 'Kansas' },
  { city: 'Baxter Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Bellaire', state: 'KS', state_name: 'Kansas' },
  { city: 'Belle Plaine', state: 'KS', state_name: 'Kansas' },
  { city: 'Belleville', state: 'KS', state_name: 'Kansas' },
  { city: 'Beloit', state: 'KS', state_name: 'Kansas' },
  { city: 'Bonner Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Bourbon County', state: 'KS', state_name: 'Kansas' },
  { city: 'Brown County', state: 'KS', state_name: 'Kansas' },
  { city: 'Buhler', state: 'KS', state_name: 'Kansas' },
  { city: 'Burlington', state: 'KS', state_name: 'Kansas' },
  { city: 'Butler County', state: 'KS', state_name: 'Kansas' },
  { city: 'Caldwell', state: 'KS', state_name: 'Kansas' },
  { city: 'Caney', state: 'KS', state_name: 'Kansas' },
  { city: 'Carbondale', state: 'KS', state_name: 'Kansas' },
  { city: 'Chanute', state: 'KS', state_name: 'Kansas' },
  { city: 'Chapman', state: 'KS', state_name: 'Kansas' },
  { city: 'Chase County', state: 'KS', state_name: 'Kansas' },
  { city: 'Chautauqua County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cheney', state: 'KS', state_name: 'Kansas' },
  { city: 'Cherokee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cherryvale', state: 'KS', state_name: 'Kansas' },
  { city: 'Chetopa', state: 'KS', state_name: 'Kansas' },
  { city: 'Cheyenne County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cimarron', state: 'KS', state_name: 'Kansas' },
  { city: 'Clark County', state: 'KS', state_name: 'Kansas' },
  { city: 'Clay Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Clay County', state: 'KS', state_name: 'Kansas' },
  { city: 'Clearwater', state: 'KS', state_name: 'Kansas' },
  { city: 'Cloud County', state: 'KS', state_name: 'Kansas' },
  { city: 'Coffey County', state: 'KS', state_name: 'Kansas' },
  { city: 'Coffeyville', state: 'KS', state_name: 'Kansas' },
  { city: 'Colby', state: 'KS', state_name: 'Kansas' },
  { city: 'Coldwater', state: 'KS', state_name: 'Kansas' },
  { city: 'Columbus', state: 'KS', state_name: 'Kansas' },
  { city: 'Colwich', state: 'KS', state_name: 'Kansas' },
  { city: 'Comanche County', state: 'KS', state_name: 'Kansas' },
  { city: 'Concordia', state: 'KS', state_name: 'Kansas' },
  { city: 'Conway Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Cottonwood Falls', state: 'KS', state_name: 'Kansas' },
  { city: 'Council Grove', state: 'KS', state_name: 'Kansas' },
  { city: 'Cowley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Crawford County', state: 'KS', state_name: 'Kansas' },
  { city: 'De Soto', state: 'KS', state_name: 'Kansas' },
  { city: 'Decatur County', state: 'KS', state_name: 'Kansas' },
  { city: 'Derby', state: 'KS', state_name: 'Kansas' },
  { city: 'Dickinson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Dighton', state: 'KS', state_name: 'Kansas' },
  { city: 'Dodge City', state: 'KS', state_name: 'Kansas' },
  { city: 'Doniphan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Douglas County', state: 'KS', state_name: 'Kansas' },
  { city: 'Douglass', state: 'KS', state_name: 'Kansas' },
  { city: 'Edgerton', state: 'KS', state_name: 'Kansas' },
  { city: 'Edwards County', state: 'KS', state_name: 'Kansas' },
  { city: 'Edwardsville', state: 'KS', state_name: 'Kansas' },
  { city: 'El Dorado', state: 'KS', state_name: 'Kansas' },
  { city: 'Elk County', state: 'KS', state_name: 'Kansas' },
  { city: 'Elkhart', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellinwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellis', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellis County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellsworth', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellsworth County', state: 'KS', state_name: 'Kansas' },
  { city: 'Elwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Emporia', state: 'KS', state_name: 'Kansas' },
  { city: 'Erie', state: 'KS', state_name: 'Kansas' },
  { city: 'Eudora', state: 'KS', state_name: 'Kansas' },
  { city: 'Eureka', state: 'KS', state_name: 'Kansas' },
  { city: 'Fairway', state: 'KS', state_name: 'Kansas' },
  { city: 'Finney County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ford County', state: 'KS', state_name: 'Kansas' },
  { city: 'Fort Riley North', state: 'KS', state_name: 'Kansas' },
  { city: 'Fort Scott', state: 'KS', state_name: 'Kansas' },
  { city: 'Franklin County', state: 'KS', state_name: 'Kansas' },
  { city: 'Fredonia', state: 'KS', state_name: 'Kansas' },
  { city: 'Frontenac', state: 'KS', state_name: 'Kansas' },
  { city: 'Galena', state: 'KS', state_name: 'Kansas' },
  { city: 'Garden City', state: 'KS', state_name: 'Kansas' },
  { city: 'Gardner', state: 'KS', state_name: 'Kansas' },
  { city: 'Garnett', state: 'KS', state_name: 'Kansas' },
  { city: 'Geary County', state: 'KS', state_name: 'Kansas' },
  { city: 'Girard', state: 'KS', state_name: 'Kansas' },
  { city: 'Goddard', state: 'KS', state_name: 'Kansas' },
  { city: 'Goodland', state: 'KS', state_name: 'Kansas' },
  { city: 'Gove', state: 'KS', state_name: 'Kansas' },
  { city: 'Gove County', state: 'KS', state_name: 'Kansas' },
  { city: 'Graham County', state: 'KS', state_name: 'Kansas' },
  { city: 'Grandview Plaza', state: 'KS', state_name: 'Kansas' },
  { city: 'Grant County', state: 'KS', state_name: 'Kansas' },
  { city: 'Gray County', state: 'KS', state_name: 'Kansas' },
  { city: 'Great Bend', state: 'KS', state_name: 'Kansas' },
  { city: 'Greeley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Greensburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Greenwood County', state: 'KS', state_name: 'Kansas' },
  { city: 'Halstead', state: 'KS', state_name: 'Kansas' },
  { city: 'Hamilton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Harper', state: 'KS', state_name: 'Kansas' },
  { city: 'Harper County', state: 'KS', state_name: 'Kansas' },
  { city: 'Harvey County', state: 'KS', state_name: 'Kansas' },
  { city: 'Haskell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Haven', state: 'KS', state_name: 'Kansas' },
  { city: 'Hays', state: 'KS', state_name: 'Kansas' },
  { city: 'Haysville', state: 'KS', state_name: 'Kansas' },
  { city: 'Herington', state: 'KS', state_name: 'Kansas' },
  { city: 'Hesston', state: 'KS', state_name: 'Kansas' },
  { city: 'Hiawatha', state: 'KS', state_name: 'Kansas' },
  { city: 'Highland', state: 'KS', state_name: 'Kansas' },
  { city: 'Hill City', state: 'KS', state_name: 'Kansas' },
  { city: 'Hillsboro', state: 'KS', state_name: 'Kansas' },
  { city: 'Hodgeman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Hoisington', state: 'KS', state_name: 'Kansas' },
  { city: 'Holcomb', state: 'KS', state_name: 'Kansas' },
  { city: 'Holton', state: 'KS', state_name: 'Kansas' },
  { city: 'Horton', state: 'KS', state_name: 'Kansas' },
  { city: 'Howard', state: 'KS', state_name: 'Kansas' },
  { city: 'Hoxie', state: 'KS', state_name: 'Kansas' },
  { city: 'Hugoton', state: 'KS', state_name: 'Kansas' },
  { city: 'Humboldt', state: 'KS', state_name: 'Kansas' },
  { city: 'Hutchinson', state: 'KS', state_name: 'Kansas' },
  { city: 'Independence', state: 'KS', state_name: 'Kansas' },
  { city: 'Inman', state: 'KS', state_name: 'Kansas' },
  { city: 'Iola', state: 'KS', state_name: 'Kansas' },
  { city: 'Jackson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Jefferson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Jetmore', state: 'KS', state_name: 'Kansas' },
  { city: 'Jewell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Johnson', state: 'KS', state_name: 'Kansas' },
  { city: 'Johnson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Junction City', state: 'KS', state_name: 'Kansas' },
  { city: 'Kansas City', state: 'KS', state_name: 'Kansas' },
  { city: 'Kearny County', state: 'KS', state_name: 'Kansas' },
  { city: 'Kechi', state: 'KS', state_name: 'Kansas' },
  { city: 'Kingman', state: 'KS', state_name: 'Kansas' },
  { city: 'Kingman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Kinsley', state: 'KS', state_name: 'Kansas' },
  { city: 'Kiowa', state: 'KS', state_name: 'Kansas' },
  { city: 'Kiowa County', state: 'KS', state_name: 'Kansas' },
  { city: 'La Crosse', state: 'KS', state_name: 'Kansas' },
  { city: 'La Cygne', state: 'KS', state_name: 'Kansas' },
  { city: 'Labette County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lakin', state: 'KS', state_name: 'Kansas' },
  { city: 'Lane County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lansing', state: 'KS', state_name: 'Kansas' },
  { city: 'Larned', state: 'KS', state_name: 'Kansas' },
  { city: 'Lawrence', state: 'KS', state_name: 'Kansas' },
  { city: 'Leavenworth', state: 'KS', state_name: 'Kansas' },
  { city: 'Leavenworth County', state: 'KS', state_name: 'Kansas' },
  { city: 'Leawood', state: 'KS', state_name: 'Kansas' },
  { city: 'Lenexa', state: 'KS', state_name: 'Kansas' },
  { city: 'Leoti', state: 'KS', state_name: 'Kansas' },
  { city: 'Liberal', state: 'KS', state_name: 'Kansas' },
  { city: 'Lincoln', state: 'KS', state_name: 'Kansas' },
  { city: 'Lincoln County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lindsborg', state: 'KS', state_name: 'Kansas' },
  { city: 'Linn County', state: 'KS', state_name: 'Kansas' },
  { city: 'Logan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Louisburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyndon', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyon County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyons', state: 'KS', state_name: 'Kansas' },
  { city: 'Maize', state: 'KS', state_name: 'Kansas' },
  { city: 'Manhattan', state: 'KS', state_name: 'Kansas' },
  { city: 'Mankato', state: 'KS', state_name: 'Kansas' },
  { city: 'Marion', state: 'KS', state_name: 'Kansas' },
  { city: 'Marion County', state: 'KS', state_name: 'Kansas' },
  { city: 'Marshall County', state: 'KS', state_name: 'Kansas' },
  { city: 'Marysville', state: 'KS', state_name: 'Kansas' },
  { city: 'McConnell AFB', state: 'KS', state_name: 'Kansas' },
  { city: 'McPherson', state: 'KS', state_name: 'Kansas' },
  { city: 'McPherson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Meade', state: 'KS', state_name: 'Kansas' },
  { city: 'Meade County', state: 'KS', state_name: 'Kansas' },
  { city: 'Medicine Lodge', state: 'KS', state_name: 'Kansas' },
  { city: 'Merriam', state: 'KS', state_name: 'Kansas' },
  { city: 'Miami County', state: 'KS', state_name: 'Kansas' },
  { city: 'Minneapolis', state: 'KS', state_name: 'Kansas' },
  { city: 'Mission', state: 'KS', state_name: 'Kansas' },
  { city: 'Mission Hills', state: 'KS', state_name: 'Kansas' },
  { city: 'Mitchell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Montgomery County', state: 'KS', state_name: 'Kansas' },
  { city: 'Morris County', state: 'KS', state_name: 'Kansas' },
  { city: 'Morton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Mound City', state: 'KS', state_name: 'Kansas' },
  { city: 'Moundridge', state: 'KS', state_name: 'Kansas' },
  { city: 'Mulvane', state: 'KS', state_name: 'Kansas' },
  { city: 'Nemaha County', state: 'KS', state_name: 'Kansas' },
  { city: 'Neodesha', state: 'KS', state_name: 'Kansas' },
  { city: 'Neosho County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ness City', state: 'KS', state_name: 'Kansas' },
  { city: 'Ness County', state: 'KS', state_name: 'Kansas' },
  { city: 'New Century KS', state: 'KS', state_name: 'Kansas' },
  { city: 'Newton', state: 'KS', state_name: 'Kansas' },
  { city: 'Nickerson', state: 'KS', state_name: 'Kansas' },
  { city: 'North Newton', state: 'KS', state_name: 'Kansas' },
  { city: 'Norton', state: 'KS', state_name: 'Kansas' },
  { city: 'Norton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Oakley', state: 'KS', state_name: 'Kansas' },
  { city: 'Oberlin', state: 'KS', state_name: 'Kansas' },
  { city: 'Ogden', state: 'KS', state_name: 'Kansas' },
  { city: 'Olathe', state: 'KS', state_name: 'Kansas' },
  { city: 'Osage City', state: 'KS', state_name: 'Kansas' },
  { city: 'Osage County', state: 'KS', state_name: 'Kansas' },
  { city: 'Osawatomie', state: 'KS', state_name: 'Kansas' },
  { city: 'Osborne', state: 'KS', state_name: 'Kansas' },
  { city: 'Osborne County', state: 'KS', state_name: 'Kansas' },
  { city: 'Oskaloosa', state: 'KS', state_name: 'Kansas' },
  { city: 'Oswego', state: 'KS', state_name: 'Kansas' },
  { city: 'Ottawa', state: 'KS', state_name: 'Kansas' },
  { city: 'Ottawa County', state: 'KS', state_name: 'Kansas' },
  { city: 'Overbrook', state: 'KS', state_name: 'Kansas' },
  { city: 'Overland Park', state: 'KS', state_name: 'Kansas' },
  { city: 'Oxford', state: 'KS', state_name: 'Kansas' },
  { city: 'Paola', state: 'KS', state_name: 'Kansas' },
  { city: 'Park City', state: 'KS', state_name: 'Kansas' },
  { city: 'Parsons', state: 'KS', state_name: 'Kansas' },
  { city: 'Pawnee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Peabody', state: 'KS', state_name: 'Kansas' },
  { city: 'Phillips County', state: 'KS', state_name: 'Kansas' },
  { city: 'Phillipsburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Pittsburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Plains', state: 'KS', state_name: 'Kansas' },
  { city: 'Plainville', state: 'KS', state_name: 'Kansas' },
  { city: 'Pleasanton', state: 'KS', state_name: 'Kansas' },
  { city: 'Pottawatomie County', state: 'KS', state_name: 'Kansas' },
  { city: 'Prairie Village', state: 'KS', state_name: 'Kansas' },
  { city: 'Pratt', state: 'KS', state_name: 'Kansas' },
  { city: 'Pratt County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rawlins County', state: 'KS', state_name: 'Kansas' },
  { city: 'Reno County', state: 'KS', state_name: 'Kansas' },
  { city: 'Republic County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rice County', state: 'KS', state_name: 'Kansas' },
  { city: 'Riley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Roeland Park', state: 'KS', state_name: 'Kansas' },
  { city: 'Rooks County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rose Hill', state: 'KS', state_name: 'Kansas' },
  { city: 'Rossville', state: 'KS', state_name: 'Kansas' },
  { city: 'Rush County', state: 'KS', state_name: 'Kansas' },
  { city: 'Russell', state: 'KS', state_name: 'Kansas' },
  { city: 'Russell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sabetha', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint Francis', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint John', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint Marys', state: 'KS', state_name: 'Kansas' },
  { city: 'Salina', state: 'KS', state_name: 'Kansas' },
  { city: 'Saline County', state: 'KS', state_name: 'Kansas' },
  { city: 'Satanta', state: 'KS', state_name: 'Kansas' },
  { city: 'Scott City', state: 'KS', state_name: 'Kansas' },
  { city: 'Scott County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedan', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedgwick', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedgwick County', state: 'KS', state_name: 'Kansas' },
  { city: 'Seneca', state: 'KS', state_name: 'Kansas' },
  { city: 'Seward County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sharon Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Shawnee', state: 'KS', state_name: 'Kansas' },
  { city: 'Shawnee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sheridan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sherman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Silver Lake', state: 'KS', state_name: 'Kansas' },
  { city: 'Smith Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Smith County', state: 'KS', state_name: 'Kansas' },
  { city: 'Solomon', state: 'KS', state_name: 'Kansas' },
  { city: 'South Hutchinson', state: 'KS', state_name: 'Kansas' },
  { city: 'Spring Hill', state: 'KS', state_name: 'Kansas' },
  { city: 'Stafford County', state: 'KS', state_name: 'Kansas' },
  { city: 'Stanton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sterling', state: 'KS', state_name: 'Kansas' },
  { city: 'Stevens County', state: 'KS', state_name: 'Kansas' },
  { city: 'Stockton', state: 'KS', state_name: 'Kansas' },
  { city: 'Sublette', state: 'KS', state_name: 'Kansas' },
  { city: 'Sumner County', state: 'KS', state_name: 'Kansas' },
  { city: 'Syracuse', state: 'KS', state_name: 'Kansas' },
  { city: 'Thomas County', state: 'KS', state_name: 'Kansas' },
  { city: 'Tonganoxie', state: 'KS', state_name: 'Kansas' },
  { city: 'Topeka', state: 'KS', state_name: 'Kansas' },
  { city: 'Towanda', state: 'KS', state_name: 'Kansas' },
  { city: 'Trego County', state: 'KS', state_name: 'Kansas' },
  { city: 'Tribune', state: 'KS', state_name: 'Kansas' },
  { city: 'Troy', state: 'KS', state_name: 'Kansas' },
  { city: 'Ulysses', state: 'KS', state_name: 'Kansas' },
  { city: 'Valley Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Valley Falls', state: 'KS', state_name: 'Kansas' },
  { city: 'Victoria', state: 'KS', state_name: 'Kansas' },
  { city: 'Wabaunsee County', state: 'KS', state_name: 'Kansas' },
  { city: 'WaKeeney', state: 'KS', state_name: 'Kansas' },
  { city: 'Wallace County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wamego', state: 'KS', state_name: 'Kansas' },
  { city: 'Washington', state: 'KS', state_name: 'Kansas' },
  { city: 'Washington County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wathena', state: 'KS', state_name: 'Kansas' },
  { city: 'Wellington', state: 'KS', state_name: 'Kansas' },
  { city: 'Wellsville', state: 'KS', state_name: 'Kansas' },
  { city: 'Westmoreland', state: 'KS', state_name: 'Kansas' },
  { city: 'Westwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Wichita', state: 'KS', state_name: 'Kansas' },
  { city: 'Wichita County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wilson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Winfield', state: 'KS', state_name: 'Kansas' },
  { city: 'Woodson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wyandotte County', state: 'KS', state_name: 'Kansas' },
  { city: 'Yates Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Adair County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Albany', state: 'KY', state_name: 'Kentucky' },
  { city: 'Alexandria', state: 'KY', state_name: 'Kentucky' },
  { city: 'Allen County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Anchorage', state: 'KY', state_name: 'Kentucky' },
  { city: 'Anderson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Annville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ashland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Auburn', state: 'KY', state_name: 'Kentucky' },
  { city: 'Audubon Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Augusta', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ballard County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barbourmeade', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barbourville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bardstown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bardwell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barren County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bath County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beattyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beaver Dam', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bedford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beechwood Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bellevue', state: 'KY', state_name: 'Kentucky' },
  { city: 'Benton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Berea', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bloomfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boone County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Booneville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bourbon County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bowling Green', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boyd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boyle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bracken County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brandenburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breathitt County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breckinridge Center', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breckinridge County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brodhead', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brooks', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brooksville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brownsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Buckner', state: 'KY', state_name: 'Kentucky' },
  { city: 'Buechel', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bullitt County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Burkesville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Burlington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Butler County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cadiz', state: 'KY', state_name: 'Kentucky' },
  { city: 'Caldwell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calhoun', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calloway County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calvert City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Camargo', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campbell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campbellsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carlisle', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carlisle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carroll County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carrollton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carter County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Casey County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Catlettsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cave City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Central City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Christian County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clark County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Claryville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clinton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clinton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cloverport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Coal Run Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cold Spring', state: 'KY', state_name: 'Kentucky' },
  { city: 'Coldstream', state: 'KY', state_name: 'Kentucky' },
  { city: 'Columbia', state: 'KY', state_name: 'Kentucky' },
  { city: 'Corbin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Covington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crescent Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crestview Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crestwood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crittenden', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crittenden County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cumberland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cumberland County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cynthiana', state: 'KY', state_name: 'Kentucky' },
  { city: 'Danville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Daviess County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dawson Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dayton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dixon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Doe Valley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Douglass Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dry Ridge', state: 'KY', state_name: 'Kentucky' },
  { city: 'Earlington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Eddyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edgewood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edmonson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edmonton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elizabethtown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elk Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elkfork', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elkton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elliott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elsmere', state: 'KY', state_name: 'Kentucky' },
  { city: 'Eminence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Erlanger', state: 'KY', state_name: 'Kentucky' },
  { city: 'Estill County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fairdale', state: 'KY', state_name: 'Kentucky' },
  { city: 'Falmouth', state: 'KY', state_name: 'Kentucky' },
  { city: 'Farley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fayette County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fern Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Flatwoods', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fleming County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Flemingsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Florence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Floyd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Campbell North', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Knox', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Mitchell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Thomas', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Wright', state: 'KY', state_name: 'Kentucky' },
  { city: 'Francisville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Frankfort', state: 'KY', state_name: 'Kentucky' },
  { city: 'Franklin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Franklin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Frenchburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fulton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fulton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Gallatin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Garrard County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Georgetown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Glasgow', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grant County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Graves County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Graymoor-Devondale', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grayson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grayson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Green County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greensburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenup', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenup County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Guthrie', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hancock County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hardin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hardinsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harlan', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harlan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harrison County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harrodsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hart County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hartford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hawesville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hazard', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hebron', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hebron Estates', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henderson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henderson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hendron', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henry County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Heritage Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hickman', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hickman County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Highland Heights', state: 'KY', state_name: 'Kentucky' },
  { city: 'Highview', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hillview', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hindman', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hodgenville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hopkins County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hopkinsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Horse Cave', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hurstbourne', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hurstbourne Acres', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hyden', state: 'KY', state_name: 'Kentucky' },
  { city: 'Independence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Indian Hills', state: 'KY', state_name: 'Kentucky' },
  {
    city: 'Indian Hills Cherokee Section',
    state: 'KY',
    state_name: 'Kentucky',
  },
  { city: 'Inez', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ironville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Irvine', state: 'KY', state_name: 'Kentucky' },
  { city: 'Irvington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jackson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jackson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jamestown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jefferson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jeffersontown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jeffersonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jenkins', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jessamine County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Johnson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Junction City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Kenton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knottsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knox County', state: 'KY', state_name: 'Kentucky' },
  { city: 'La Center', state: 'KY', state_name: 'Kentucky' },
  { city: 'La Grange', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lakeside Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lancaster', state: 'KY', state_name: 'Kentucky' },
  { city: 'Larue County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Laurel County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lawrence County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lawrenceburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lebanon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lebanon Junction', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ledbetter', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lee County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Leitchfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Leslie County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Letcher County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lewis County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lewisport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lexington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lexington-Fayette', state: 'KY', state_name: 'Kentucky' },
  { city: 'Liberty', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lincoln County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Livermore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Livingston County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Logan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'London', state: 'KY', state_name: 'Kentucky' },
  { city: 'Louisa', state: 'KY', state_name: 'Kentucky' },
  { city: 'Louisville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ludlow', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lyndon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lyon County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Madison County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Madisonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Magoffin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Manchester', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marion', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marion County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marshall County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Martin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mason County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Masonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Massac', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mayfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Maysville', state: 'KY', state_name: 'Kentucky' },
  { city: 'McCracken County', state: 'KY', state_name: 'Kentucky' },
  { city: 'McCreary County', state: 'KY', state_name: 'Kentucky' },
  { city: 'McKee', state: 'KY', state_name: 'Kentucky' },
  { city: 'McLean County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Meade County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Meads', state: 'KY', state_name: 'Kentucky' },
  { city: 'Menifee County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mercer County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Metcalfe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Middlesboro', state: 'KY', state_name: 'Kentucky' },
  { city: 'Middletown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Midway', state: 'KY', state_name: 'Kentucky' },
  { city: 'Monroe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Montgomery County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Monticello', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morehead', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morgan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morganfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morgantown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Olivet', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Sterling', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Vernon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Washington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Muhlenberg County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Munfordville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Murray', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nelson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'New Castle', state: 'KY', state_name: 'Kentucky' },
  { city: 'Newburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Newport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nicholas County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nicholasville', state: 'KY', state_name: 'Kentucky' },
  { city: 'North Corbin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Northfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nortonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oak Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oakbrook', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ohio County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Okolona', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oldham County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Olive Hill', state: 'KY', state_name: 'Kentucky' },
  { city: 'Orchard Grass Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owen County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owensboro', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owenton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owingsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owsley County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paducah', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paintsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paris', state: 'KY', state_name: 'Kentucky' },
  { city: 'Park Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pendleton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Perry County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pewee Valley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pike County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pikeville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pine Knot', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pineville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pioneer Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Plano', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pleasure Ridge Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Powell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Prestonsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Princeton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Prospect', state: 'KY', state_name: 'Kentucky' },
  { city: 'Providence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pulaski County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Raceland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Radcliff', state: 'KY', state_name: 'Kentucky' },
  { city: 'Reidland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Richmond', state: 'KY', state_name: 'Kentucky' },
  { city: 'Robertson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Rockcastle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Rowan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russellville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ryland Heights', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Dennis', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Matthews', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Regis Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Salyersville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sandy Hook', state: 'KY', state_name: 'Kentucky' },
  { city: 'Scott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Scottsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sebree', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shelby County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shelbyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shepherdsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shively', state: 'KY', state_name: 'Kentucky' },
  { city: 'Silver Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Simpson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Simpsonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Smithland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Somerset', state: 'KY', state_name: 'Kentucky' },
  { city: 'South Shore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Southgate', state: 'KY', state_name: 'Kentucky' },
  { city: 'Spencer County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Springfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stanford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stanton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stearns', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sturgis', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylor County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylor Mill', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylorsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Todd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Tompkinsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Trigg County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Trimble County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Union', state: 'KY', state_name: 'Kentucky' },
  { city: 'Union County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Valley Station', state: 'KY', state_name: 'Kentucky' },
  { city: 'Van Lear', state: 'KY', state_name: 'Kentucky' },
  { city: 'Vanceburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Verona', state: 'KY', state_name: 'Kentucky' },
  { city: 'Versailles', state: 'KY', state_name: 'Kentucky' },
  { city: 'Villa Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Vine Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Walton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Warren County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Warsaw', state: 'KY', state_name: 'Kentucky' },
  { city: 'Washington County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Watterson Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wayne County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Webster County', state: 'KY', state_name: 'Kentucky' },
  { city: 'West Buechel', state: 'KY', state_name: 'Kentucky' },
  { city: 'West Liberty', state: 'KY', state_name: 'Kentucky' },
  { city: 'Westwood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitesburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitley City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitley County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wickliffe', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wilder', state: 'KY', state_name: 'Kentucky' },
  { city: 'Williamsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Williamstown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wilmore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Winchester', state: 'KY', state_name: 'Kentucky' },
  { city: 'Windy Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wolfe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Woodford County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Worthington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Worthington Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wurtland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Abbeville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Abita Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'Acadia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Addis', state: 'LA', state_name: 'Louisiana' },
  { city: 'Albany', state: 'LA', state_name: 'Louisiana' },
  { city: 'Alexandria', state: 'LA', state_name: 'Louisiana' },
  { city: 'Allen Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ama', state: 'LA', state_name: 'Louisiana' },
  { city: 'Amelia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Amite', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arabi', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arcadia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arnaudville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ascension Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Assumption Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Avondale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Avoyelles Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baker', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baldwin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ball', state: 'LA', state_name: 'Louisiana' },
  { city: 'Banks Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'Barataria', state: 'LA', state_name: 'Louisiana' },
  { city: 'Basile', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bastrop', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baton Rouge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bawcomville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Cane', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Gauche', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Vista', state: 'LA', state_name: 'Louisiana' },
  { city: 'Beauregard Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Belle Chasse', state: 'LA', state_name: 'Louisiana' },
  { city: 'Belle Rose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Benton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bernice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Berwick', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bienville Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Blanchard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bogalusa', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bossier City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bossier Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bourg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Boutte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Breaux Bridge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bridge City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Broussard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brownsfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brownsville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brusly', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bunkie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Caddo Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cade', state: 'LA', state_name: 'Louisiana' },
  { city: 'Calcasieu Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Caldwell Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cameron', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cameron Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Campti', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carencro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carlyss', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Catahoula', state: 'LA', state_name: 'Louisiana' },
  { city: 'Catahoula Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cecilia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Central', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chackbay', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chalmette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Charenton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chauvin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Church Point', state: 'LA', state_name: 'Louisiana' },
  { city: 'Claiborne', state: 'LA', state_name: 'Louisiana' },
  { city: 'Claiborne Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Clarks', state: 'LA', state_name: 'Louisiana' },
  { city: 'Clinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Colfax', state: 'LA', state_name: 'Louisiana' },
  { city: 'Columbia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Concordia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Convent', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cottonport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Coushatta', state: 'LA', state_name: 'Louisiana' },
  { city: 'Covington', state: 'LA', state_name: 'Louisiana' },
  { city: 'Crowley', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cullen', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cut Off', state: 'LA', state_name: 'Louisiana' },
  { city: 'De Soto Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Delcambre', state: 'LA', state_name: 'Louisiana' },
  { city: 'Delhi', state: 'LA', state_name: 'Louisiana' },
  { city: 'Denham Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'DeQuincy', state: 'LA', state_name: 'Louisiana' },
  { city: 'DeRidder', state: 'LA', state_name: 'Louisiana' },
  { city: 'Des Allemands', state: 'LA', state_name: 'Louisiana' },
  { city: 'Destrehan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Deville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Donaldsonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Dulac', state: 'LA', state_name: 'Louisiana' },
  { city: 'Duson', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Baton Rouge Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Carroll Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Feliciana Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eastwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eden Isle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Edgard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Elmwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Elton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Erath', state: 'LA', state_name: 'Louisiana' },
  { city: 'Erwinville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Estelle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eunice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Evangeline Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Farmerville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ferriday', state: 'LA', state_name: 'Louisiana' },
  { city: 'Fort Polk North', state: 'LA', state_name: 'Louisiana' },
  { city: 'Fort Polk South', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklin Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'French Settlement', state: 'LA', state_name: 'Louisiana' },
  { city: 'Galliano', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gardere', state: 'LA', state_name: 'Louisiana' },
  { city: 'Garyville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Glenmora', state: 'LA', state_name: 'Louisiana' },
  { city: 'Golden Meadow', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gonzales', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grambling', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gramercy', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grand Isle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grand Point', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grant Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gray', state: 'LA', state_name: 'Louisiana' },
  { city: 'Greensburg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Greenwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gretna', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gueydan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hackberry', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hahnville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hammond', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harahan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harrisonburg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harvey', state: 'LA', state_name: 'Louisiana' },
  { city: 'Haughton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Haynesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Henderson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Homer', state: 'LA', state_name: 'Louisiana' },
  { city: 'Houma', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iberia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iberville Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Independence', state: 'LA', state_name: 'Louisiana' },
  { city: 'Inniswold', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iota', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iowa', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jackson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jackson Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jean Lafitte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jeanerette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson Davis Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jena', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jennings', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jonesboro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jonesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kaplan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kenner', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kentwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Killian', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kinder', state: 'LA', state_name: 'Louisiana' },
  { city: 'Krotz Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'La Salle Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Labadieville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lacombe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafayette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafayette Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafourche Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Arthur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Charles', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Providence', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lakeshore', state: 'LA', state_name: 'Louisiana' },
  { city: 'Laplace', state: 'LA', state_name: 'Louisiana' },
  { city: 'Larose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lawtell', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lecompte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Leesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Leonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lincoln Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livingston', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livingston Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livonia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lockport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lockport Heights', state: 'LA', state_name: 'Louisiana' },
  { city: 'Logansport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Luling', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lutcher', state: 'LA', state_name: 'Louisiana' },
  { city: 'Madison Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mamou', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mandeville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mansfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mansura', state: 'LA', state_name: 'Louisiana' },
  { city: 'Many', state: 'LA', state_name: 'Louisiana' },
  { city: 'Maringouin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Marksville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Marrero', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mathews', state: 'LA', state_name: 'Louisiana' },
  { city: 'Maurice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Melville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Meraux', state: 'LA', state_name: 'Louisiana' },
  { city: 'Merrydale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Merryville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Metairie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Metairie Terrace', state: 'LA', state_name: 'Louisiana' },
  { city: 'Midway', state: 'LA', state_name: 'Louisiana' },
  { city: 'Milton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Minden', state: 'LA', state_name: 'Louisiana' },
  { city: 'Minorca', state: 'LA', state_name: 'Louisiana' },
  { city: 'Monroe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Montegut', state: 'LA', state_name: 'Louisiana' },
  { city: 'Monticello', state: 'LA', state_name: 'Louisiana' },
  { city: 'Montz', state: 'LA', state_name: 'Louisiana' },
  { city: 'Morehouse Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Morgan City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Moss Bluff', state: 'LA', state_name: 'Louisiana' },
  { city: 'Napoleonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natalbany', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natchitoches', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natchitoches Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Iberia', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Llano', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Orleans', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Roads', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Sarpy', state: 'LA', state_name: 'Louisiana' },
  { city: 'Newellton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Norco', state: 'LA', state_name: 'Louisiana' },
  { city: 'North Vacherie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oak Grove', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oak Hills Place', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oakdale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oberlin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Old Jefferson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Olla', state: 'LA', state_name: 'Louisiana' },
  { city: 'Opelousas', state: 'LA', state_name: 'Louisiana' },
  { city: 'Orleans Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ossun', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ouachita Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Paradis', state: 'LA', state_name: 'Louisiana' },
  { city: 'Patterson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Paulina', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pearl River', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pierre Part', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pine Prairie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pineville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Plaquemine', state: 'LA', state_name: 'Louisiana' },
  { city: 'Plaquemines Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pointe Coupee Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ponchatoula', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Allen', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Barre', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Sulphur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Poydras', state: 'LA', state_name: 'Louisiana' },
  { city: 'Prairieville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Presquille', state: 'LA', state_name: 'Louisiana' },
  { city: 'Prien', state: 'LA', state_name: 'Louisiana' },
  { city: 'Raceland', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rapides Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rayne', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rayville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Red Chute', state: 'LA', state_name: 'Louisiana' },
  { city: 'Red River Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Reserve', state: 'LA', state_name: 'Louisiana' },
  { city: 'Richland Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Richwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ringgold', state: 'LA', state_name: 'Louisiana' },
  { city: 'River Ridge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Roseland', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rosepine', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ruston', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sabine Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Bernard Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Charles Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Francisville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Gabriel', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Helena Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint James Parish', state: 'LA', state_name: 'Louisiana' },
  {
    city: 'Saint John the Baptist Parish',
    state: 'LA',
    state_name: 'Louisiana',
  },
  { city: 'Saint Joseph', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Landry Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Martin Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Martinville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Mary Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Rose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Tammany Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Schriever', state: 'LA', state_name: 'Louisiana' },
  { city: 'Scott', state: 'LA', state_name: 'Louisiana' },
  { city: 'Shenandoah', state: 'LA', state_name: 'Louisiana' },
  { city: 'Shreveport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sibley', state: 'LA', state_name: 'Louisiana' },
  { city: 'Simmesport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Slidell', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sorrento', state: 'LA', state_name: 'Louisiana' },
  { city: 'South Vacherie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Springhill', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sterlington', state: 'LA', state_name: 'Louisiana' },
  { city: 'Stonewall', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sulphur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sunset', state: 'LA', state_name: 'Louisiana' },
  { city: 'Supreme', state: 'LA', state_name: 'Louisiana' },
  { city: 'Swartz', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tallulah', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tangipahoa Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tensas Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Terrebonne Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Terrytown', state: 'LA', state_name: 'Louisiana' },
  { city: 'Thibodaux', state: 'LA', state_name: 'Louisiana' },
  { city: 'Timberlane', state: 'LA', state_name: 'Louisiana' },
  { city: 'Union Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Urania', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vermilion Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vernon Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vidalia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vienna Bend', state: 'LA', state_name: 'Louisiana' },
  { city: 'Village Saint George', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ville Platte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Violet', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vivian', state: 'LA', state_name: 'Louisiana' },
  { city: 'Waggaman', state: 'LA', state_name: 'Louisiana' },
  { city: 'Walker', state: 'LA', state_name: 'Louisiana' },
  { city: 'Washington Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Watson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Webster Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Welsh', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Baton Rouge Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Carroll Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Feliciana Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Ferriday', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Monroe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westlake', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westminster', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westwego', state: 'LA', state_name: 'Louisiana' },
  { city: 'White Castle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winn Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winnfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winnsboro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Woodmere', state: 'LA', state_name: 'Louisiana' },
  { city: 'Woodworth', state: 'LA', state_name: 'Louisiana' },
  { city: 'Youngsville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Zachary', state: 'LA', state_name: 'Louisiana' },
  { city: 'Zwolle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Acton', state: 'ME', state_name: 'Maine' },
  { city: 'Addison', state: 'ME', state_name: 'Maine' },
  { city: 'Albion', state: 'ME', state_name: 'Maine' },
  { city: 'Alfred', state: 'ME', state_name: 'Maine' },
  { city: 'Androscoggin County', state: 'ME', state_name: 'Maine' },
  { city: 'Appleton', state: 'ME', state_name: 'Maine' },
  { city: 'Aroostook County', state: 'ME', state_name: 'Maine' },
  { city: 'Arundel', state: 'ME', state_name: 'Maine' },
  { city: 'Auburn', state: 'ME', state_name: 'Maine' },
  { city: 'Augusta', state: 'ME', state_name: 'Maine' },
  { city: 'Bangor', state: 'ME', state_name: 'Maine' },
  { city: 'Bar Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Bath', state: 'ME', state_name: 'Maine' },
  { city: 'Belfast', state: 'ME', state_name: 'Maine' },
  { city: 'Belgrade', state: 'ME', state_name: 'Maine' },
  { city: 'Benton', state: 'ME', state_name: 'Maine' },
  { city: 'Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'Bethel', state: 'ME', state_name: 'Maine' },
  { city: 'Biddeford', state: 'ME', state_name: 'Maine' },
  { city: 'Boothbay', state: 'ME', state_name: 'Maine' },
  { city: 'Boothbay Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Bradford', state: 'ME', state_name: 'Maine' },
  { city: 'Bradley', state: 'ME', state_name: 'Maine' },
  { city: 'Brewer', state: 'ME', state_name: 'Maine' },
  { city: 'Bridgton', state: 'ME', state_name: 'Maine' },
  { city: 'Bristol', state: 'ME', state_name: 'Maine' },
  { city: 'Brooks', state: 'ME', state_name: 'Maine' },
  { city: 'Brownfield', state: 'ME', state_name: 'Maine' },
  { city: 'Brownville', state: 'ME', state_name: 'Maine' },
  { city: 'Brunswick', state: 'ME', state_name: 'Maine' },
  { city: 'Buckfield', state: 'ME', state_name: 'Maine' },
  { city: 'Bucksport', state: 'ME', state_name: 'Maine' },
  { city: 'Burnham', state: 'ME', state_name: 'Maine' },
  { city: 'Buxton', state: 'ME', state_name: 'Maine' },
  { city: 'Calais', state: 'ME', state_name: 'Maine' },
  { city: 'Camden', state: 'ME', state_name: 'Maine' },
  { city: 'Canaan', state: 'ME', state_name: 'Maine' },
  { city: 'Canton', state: 'ME', state_name: 'Maine' },
  { city: 'Cape Neddick', state: 'ME', state_name: 'Maine' },
  { city: 'Caribou', state: 'ME', state_name: 'Maine' },
  { city: 'Carmel', state: 'ME', state_name: 'Maine' },
  { city: 'Castine', state: 'ME', state_name: 'Maine' },
  { city: 'Charleston', state: 'ME', state_name: 'Maine' },
  { city: 'Chelsea', state: 'ME', state_name: 'Maine' },
  { city: 'Cherryfield', state: 'ME', state_name: 'Maine' },
  { city: 'Chesterville', state: 'ME', state_name: 'Maine' },
  { city: 'China', state: 'ME', state_name: 'Maine' },
  { city: 'Chisholm', state: 'ME', state_name: 'Maine' },
  { city: 'Clinton', state: 'ME', state_name: 'Maine' },
  { city: 'Corinna', state: 'ME', state_name: 'Maine' },
  { city: 'Cornish', state: 'ME', state_name: 'Maine' },
  { city: 'Cornville', state: 'ME', state_name: 'Maine' },
  { city: 'Cumberland Center', state: 'ME', state_name: 'Maine' },
  { city: 'Cumberland County', state: 'ME', state_name: 'Maine' },
  { city: 'Cushing', state: 'ME', state_name: 'Maine' },
  { city: 'Damariscotta', state: 'ME', state_name: 'Maine' },
  { city: 'Dayton', state: 'ME', state_name: 'Maine' },
  { city: 'Dedham', state: 'ME', state_name: 'Maine' },
  { city: 'Deer Isle', state: 'ME', state_name: 'Maine' },
  { city: 'Denmark', state: 'ME', state_name: 'Maine' },
  { city: 'Dexter', state: 'ME', state_name: 'Maine' },
  { city: 'Dixfield', state: 'ME', state_name: 'Maine' },
  { city: 'Dixmont', state: 'ME', state_name: 'Maine' },
  { city: 'Dover-Foxcroft', state: 'ME', state_name: 'Maine' },
  { city: 'East Machias', state: 'ME', state_name: 'Maine' },
  { city: 'East Millinocket', state: 'ME', state_name: 'Maine' },
  { city: 'Easton', state: 'ME', state_name: 'Maine' },
  { city: 'Eastport', state: 'ME', state_name: 'Maine' },
  { city: 'Eddington', state: 'ME', state_name: 'Maine' },
  { city: 'Edgecomb', state: 'ME', state_name: 'Maine' },
  { city: 'Eliot', state: 'ME', state_name: 'Maine' },
  { city: 'Ellsworth', state: 'ME', state_name: 'Maine' },
  { city: 'Enfield', state: 'ME', state_name: 'Maine' },
  { city: 'Etna', state: 'ME', state_name: 'Maine' },
  { city: 'Fairfield', state: 'ME', state_name: 'Maine' },
  { city: 'Falmouth', state: 'ME', state_name: 'Maine' },
  { city: 'Falmouth Foreside', state: 'ME', state_name: 'Maine' },
  { city: 'Farmingdale', state: 'ME', state_name: 'Maine' },
  { city: 'Farmington', state: 'ME', state_name: 'Maine' },
  { city: 'Fayette', state: 'ME', state_name: 'Maine' },
  { city: 'Fort Fairfield', state: 'ME', state_name: 'Maine' },
  { city: 'Fort Kent', state: 'ME', state_name: 'Maine' },
  { city: 'Frankfort', state: 'ME', state_name: 'Maine' },
  { city: 'Franklin', state: 'ME', state_name: 'Maine' },
  { city: 'Franklin County', state: 'ME', state_name: 'Maine' },
  { city: 'Freeport', state: 'ME', state_name: 'Maine' },
  { city: 'Frenchville', state: 'ME', state_name: 'Maine' },
  { city: 'Friendship', state: 'ME', state_name: 'Maine' },
  { city: 'Fryeburg', state: 'ME', state_name: 'Maine' },
  { city: 'Gardiner', state: 'ME', state_name: 'Maine' },
  { city: 'Garland', state: 'ME', state_name: 'Maine' },
  { city: 'Gorham', state: 'ME', state_name: 'Maine' },
  { city: 'Gouldsboro', state: 'ME', state_name: 'Maine' },
  { city: 'Greenbush', state: 'ME', state_name: 'Maine' },
  { city: 'Greene', state: 'ME', state_name: 'Maine' },
  { city: 'Greenville', state: 'ME', state_name: 'Maine' },
  { city: 'Hallowell', state: 'ME', state_name: 'Maine' },
  { city: 'Hampden', state: 'ME', state_name: 'Maine' },
  { city: 'Hancock', state: 'ME', state_name: 'Maine' },
  { city: 'Hancock County', state: 'ME', state_name: 'Maine' },
  { city: 'Harpswell Center', state: 'ME', state_name: 'Maine' },
  { city: 'Harrison', state: 'ME', state_name: 'Maine' },
  { city: 'Hartford', state: 'ME', state_name: 'Maine' },
  { city: 'Hebron', state: 'ME', state_name: 'Maine' },
  { city: 'Hermon', state: 'ME', state_name: 'Maine' },
  { city: 'Hiram', state: 'ME', state_name: 'Maine' },
  { city: 'Hodgdon', state: 'ME', state_name: 'Maine' },
  { city: 'Holden', state: 'ME', state_name: 'Maine' },
  { city: 'Hollis Center', state: 'ME', state_name: 'Maine' },
  { city: 'Hope', state: 'ME', state_name: 'Maine' },
  { city: 'Houlton', state: 'ME', state_name: 'Maine' },
  { city: 'Howland', state: 'ME', state_name: 'Maine' },
  { city: 'Hudson', state: 'ME', state_name: 'Maine' },
  { city: 'Jay', state: 'ME', state_name: 'Maine' },
  { city: 'Jefferson', state: 'ME', state_name: 'Maine' },
  { city: 'Jonesport', state: 'ME', state_name: 'Maine' },
  { city: 'Kenduskeag', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebec County', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebunk', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebunkport', state: 'ME', state_name: 'Maine' },
  { city: 'Kingfield', state: 'ME', state_name: 'Maine' },
  { city: 'Kittery', state: 'ME', state_name: 'Maine' },
  { city: 'Kittery Point', state: 'ME', state_name: 'Maine' },
  { city: 'Knox County', state: 'ME', state_name: 'Maine' },
  { city: 'Lake Arrowhead', state: 'ME', state_name: 'Maine' },
  { city: 'Lebanon', state: 'ME', state_name: 'Maine' },
  { city: 'Leeds', state: 'ME', state_name: 'Maine' },
  { city: 'Levant', state: 'ME', state_name: 'Maine' },
  { city: 'Lewiston', state: 'ME', state_name: 'Maine' },
  { city: 'Limerick', state: 'ME', state_name: 'Maine' },
  { city: 'Limestone', state: 'ME', state_name: 'Maine' },
  { city: 'Limington', state: 'ME', state_name: 'Maine' },
  { city: 'Lincoln', state: 'ME', state_name: 'Maine' },
  { city: 'Lincoln County', state: 'ME', state_name: 'Maine' },
  { city: 'Lincolnville', state: 'ME', state_name: 'Maine' },
  { city: 'Lisbon', state: 'ME', state_name: 'Maine' },
  { city: 'Lisbon Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Livermore', state: 'ME', state_name: 'Maine' },
  { city: 'Livermore Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Lovell', state: 'ME', state_name: 'Maine' },
  { city: 'Machias', state: 'ME', state_name: 'Maine' },
  { city: 'Machiasport', state: 'ME', state_name: 'Maine' },
  { city: 'Madawaska', state: 'ME', state_name: 'Maine' },
  { city: 'Madison', state: 'ME', state_name: 'Maine' },
  { city: 'Manchester', state: 'ME', state_name: 'Maine' },
  { city: 'Mechanic Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Medway', state: 'ME', state_name: 'Maine' },
  { city: 'Mexico', state: 'ME', state_name: 'Maine' },
  { city: 'Milbridge', state: 'ME', state_name: 'Maine' },
  { city: 'Milford', state: 'ME', state_name: 'Maine' },
  { city: 'Millinocket', state: 'ME', state_name: 'Maine' },
  { city: 'Milo', state: 'ME', state_name: 'Maine' },
  { city: 'Minot', state: 'ME', state_name: 'Maine' },
  { city: 'Monmouth', state: 'ME', state_name: 'Maine' },
  { city: 'Mount Vernon', state: 'ME', state_name: 'Maine' },
  { city: 'New Gloucester', state: 'ME', state_name: 'Maine' },
  { city: 'New Sharon', state: 'ME', state_name: 'Maine' },
  { city: 'Newfield', state: 'ME', state_name: 'Maine' },
  { city: 'Newport', state: 'ME', state_name: 'Maine' },
  { city: 'Nobleboro', state: 'ME', state_name: 'Maine' },
  { city: 'Norridgewock', state: 'ME', state_name: 'Maine' },
  { city: 'North Bath', state: 'ME', state_name: 'Maine' },
  { city: 'North Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'North Windham', state: 'ME', state_name: 'Maine' },
  { city: 'Northport', state: 'ME', state_name: 'Maine' },
  { city: 'Norway', state: 'ME', state_name: 'Maine' },
  { city: 'Oakland', state: 'ME', state_name: 'Maine' },
  { city: 'Ogunquit', state: 'ME', state_name: 'Maine' },
  { city: 'Old Orchard Beach', state: 'ME', state_name: 'Maine' },
  { city: 'Old Town', state: 'ME', state_name: 'Maine' },
  { city: 'Orland', state: 'ME', state_name: 'Maine' },
  { city: 'Orono', state: 'ME', state_name: 'Maine' },
  { city: 'Orrington', state: 'ME', state_name: 'Maine' },
  { city: 'Owls Head', state: 'ME', state_name: 'Maine' },
  { city: 'Oxford', state: 'ME', state_name: 'Maine' },
  { city: 'Oxford County', state: 'ME', state_name: 'Maine' },
  { city: 'Palermo', state: 'ME', state_name: 'Maine' },
  { city: 'Palmyra', state: 'ME', state_name: 'Maine' },
  { city: 'Paris', state: 'ME', state_name: 'Maine' },
  { city: 'Parsonsfield', state: 'ME', state_name: 'Maine' },
  { city: 'Patten', state: 'ME', state_name: 'Maine' },
  { city: 'Penobscot', state: 'ME', state_name: 'Maine' },
  { city: 'Penobscot County', state: 'ME', state_name: 'Maine' },
  { city: 'Peru', state: 'ME', state_name: 'Maine' },
  { city: 'Phillips', state: 'ME', state_name: 'Maine' },
  { city: 'Phippsburg', state: 'ME', state_name: 'Maine' },
  { city: 'Piscataquis County', state: 'ME', state_name: 'Maine' },
  { city: 'Pittsfield', state: 'ME', state_name: 'Maine' },
  { city: 'Pittston', state: 'ME', state_name: 'Maine' },
  { city: 'Plymouth', state: 'ME', state_name: 'Maine' },
  { city: 'Poland', state: 'ME', state_name: 'Maine' },
  { city: 'Porter', state: 'ME', state_name: 'Maine' },
  { city: 'Portland', state: 'ME', state_name: 'Maine' },
  { city: 'Presque Isle', state: 'ME', state_name: 'Maine' },
  { city: 'Randolph', state: 'ME', state_name: 'Maine' },
  { city: 'Raymond', state: 'ME', state_name: 'Maine' },
  { city: 'Readfield', state: 'ME', state_name: 'Maine' },
  { city: 'Richmond', state: 'ME', state_name: 'Maine' },
  { city: 'Rockland', state: 'ME', state_name: 'Maine' },
  { city: 'Rockport', state: 'ME', state_name: 'Maine' },
  { city: 'Rome', state: 'ME', state_name: 'Maine' },
  { city: 'Rumford', state: 'ME', state_name: 'Maine' },
  { city: 'Sabattus', state: 'ME', state_name: 'Maine' },
  { city: 'Saco', state: 'ME', state_name: 'Maine' },
  { city: 'Sagadahoc County', state: 'ME', state_name: 'Maine' },
  { city: 'Saint Albans', state: 'ME', state_name: 'Maine' },
  { city: 'Saint George', state: 'ME', state_name: 'Maine' },
  { city: 'Sanford', state: 'ME', state_name: 'Maine' },
  { city: 'Sangerville', state: 'ME', state_name: 'Maine' },
  { city: 'Scarborough', state: 'ME', state_name: 'Maine' },
  { city: 'Searsmont', state: 'ME', state_name: 'Maine' },
  { city: 'Sedgwick', state: 'ME', state_name: 'Maine' },
  { city: 'Shapleigh', state: 'ME', state_name: 'Maine' },
  { city: 'Sidney', state: 'ME', state_name: 'Maine' },
  { city: 'Skowhegan', state: 'ME', state_name: 'Maine' },
  { city: 'Somerset County', state: 'ME', state_name: 'Maine' },
  { city: 'South Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'South Eliot', state: 'ME', state_name: 'Maine' },
  { city: 'South Paris', state: 'ME', state_name: 'Maine' },
  { city: 'South Portland', state: 'ME', state_name: 'Maine' },
  { city: 'South Portland Gardens', state: 'ME', state_name: 'Maine' },
  { city: 'South Sanford', state: 'ME', state_name: 'Maine' },
  { city: 'South Thomaston', state: 'ME', state_name: 'Maine' },
  { city: 'South Windham', state: 'ME', state_name: 'Maine' },
  { city: 'Springvale', state: 'ME', state_name: 'Maine' },
  { city: 'Steep Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Stetson', state: 'ME', state_name: 'Maine' },
  { city: 'Steuben', state: 'ME', state_name: 'Maine' },
  { city: 'Stockton Springs', state: 'ME', state_name: 'Maine' },
  { city: 'Stonington', state: 'ME', state_name: 'Maine' },
  { city: 'Strong', state: 'ME', state_name: 'Maine' },
  { city: 'Sullivan', state: 'ME', state_name: 'Maine' },
  { city: 'Surry', state: 'ME', state_name: 'Maine' },
  { city: 'Swanville', state: 'ME', state_name: 'Maine' },
  { city: 'Thomaston', state: 'ME', state_name: 'Maine' },
  { city: 'Topsham', state: 'ME', state_name: 'Maine' },
  { city: 'Tremont', state: 'ME', state_name: 'Maine' },
  { city: 'Trenton', state: 'ME', state_name: 'Maine' },
  { city: 'Troy', state: 'ME', state_name: 'Maine' },
  { city: 'Turner', state: 'ME', state_name: 'Maine' },
  { city: 'Union', state: 'ME', state_name: 'Maine' },
  { city: 'Van Buren', state: 'ME', state_name: 'Maine' },
  { city: 'Vassalboro', state: 'ME', state_name: 'Maine' },
  { city: 'Veazie', state: 'ME', state_name: 'Maine' },
  { city: 'Vinalhaven', state: 'ME', state_name: 'Maine' },
  { city: 'Waldo County', state: 'ME', state_name: 'Maine' },
  { city: 'Waldoboro', state: 'ME', state_name: 'Maine' },
  { city: 'Warren', state: 'ME', state_name: 'Maine' },
  { city: 'Washington', state: 'ME', state_name: 'Maine' },
  { city: 'Washington County', state: 'ME', state_name: 'Maine' },
  { city: 'Waterboro', state: 'ME', state_name: 'Maine' },
  { city: 'Waterville', state: 'ME', state_name: 'Maine' },
  { city: 'Wayne', state: 'ME', state_name: 'Maine' },
  { city: 'Wells Beach Station', state: 'ME', state_name: 'Maine' },
  { city: 'West Kennebunk', state: 'ME', state_name: 'Maine' },
  { city: 'West Paris', state: 'ME', state_name: 'Maine' },
  { city: 'West Scarborough', state: 'ME', state_name: 'Maine' },
  { city: 'Westbrook', state: 'ME', state_name: 'Maine' },
  { city: 'Whitefield', state: 'ME', state_name: 'Maine' },
  { city: 'Wilton', state: 'ME', state_name: 'Maine' },
  { city: 'Windsor', state: 'ME', state_name: 'Maine' },
  { city: 'Winslow', state: 'ME', state_name: 'Maine' },
  { city: 'Winterport', state: 'ME', state_name: 'Maine' },
  { city: 'Winthrop', state: 'ME', state_name: 'Maine' },
  { city: 'Wiscasset', state: 'ME', state_name: 'Maine' },
  { city: 'Woodstock', state: 'ME', state_name: 'Maine' },
  { city: 'Woolwich', state: 'ME', state_name: 'Maine' },
  { city: 'Yarmouth', state: 'ME', state_name: 'Maine' },
  { city: 'York Beach', state: 'ME', state_name: 'Maine' },
  { city: 'York County', state: 'ME', state_name: 'Maine' },
  { city: 'York Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Aberdeen', state: 'MD', state_name: 'Maryland' },
  { city: 'Aberdeen Proving Ground', state: 'MD', state_name: 'Maryland' },
  { city: 'Accokeek', state: 'MD', state_name: 'Maryland' },
  { city: 'Adamstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Adelphi', state: 'MD', state_name: 'Maryland' },
  { city: 'Algonquin', state: 'MD', state_name: 'Maryland' },
  { city: 'Allegany County', state: 'MD', state_name: 'Maryland' },
  { city: 'Andrews AFB', state: 'MD', state_name: 'Maryland' },
  { city: 'Annapolis', state: 'MD', state_name: 'Maryland' },
  { city: 'Anne Arundel County', state: 'MD', state_name: 'Maryland' },
  { city: 'Arbutus', state: 'MD', state_name: 'Maryland' },
  { city: 'Arden on the Severn', state: 'MD', state_name: 'Maryland' },
  { city: 'Arlington', state: 'MD', state_name: 'Maryland' },
  { city: 'Arnold', state: 'MD', state_name: 'Maryland' },
  { city: 'Ashton-Sandy Spring', state: 'MD', state_name: 'Maryland' },
  { city: 'Aspen Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Baden', state: 'MD', state_name: 'Maryland' },
  { city: 'Ballenger Creek', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore County', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore Highlands', state: 'MD', state_name: 'Maryland' },
  { city: 'Bartonsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air North', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air South', state: 'MD', state_name: 'Maryland' },
  { city: 'Beltsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Bennsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Berlin', state: 'MD', state_name: 'Maryland' },
  { city: 'Berwyn Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Bethesda', state: 'MD', state_name: 'Maryland' },
  { city: 'Bladensburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Boonsboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowie', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowleys Quarters', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowling Green', state: 'MD', state_name: 'Maryland' },
  { city: 'Braddock Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Brandywine', state: 'MD', state_name: 'Maryland' },
  { city: 'Brentwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Brock Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Brooklyn Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Brookmont', state: 'MD', state_name: 'Maryland' },
  { city: 'Brunswick', state: 'MD', state_name: 'Maryland' },
  { city: 'Bryans Road', state: 'MD', state_name: 'Maryland' },
  { city: 'Buckeystown', state: 'MD', state_name: 'Maryland' },
  { city: 'Burtonsville', state: 'MD', state_name: 'Maryland' },
  { city: "Butcher's Hill", state: 'MD', state_name: 'Maryland' },
  { city: 'Cabin John', state: 'MD', state_name: 'Maryland' },
  { city: 'California', state: 'MD', state_name: 'Maryland' },
  { city: 'Calvert County', state: 'MD', state_name: 'Maryland' },
  { city: 'Calverton', state: 'MD', state_name: 'Maryland' },
  { city: 'Cambridge', state: 'MD', state_name: 'Maryland' },
  { city: 'Camp Springs', state: 'MD', state_name: 'Maryland' },
  { city: 'Cape Saint Claire', state: 'MD', state_name: 'Maryland' },
  { city: 'Capitol Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Carney', state: 'MD', state_name: 'Maryland' },
  { city: 'Caroline County', state: 'MD', state_name: 'Maryland' },
  { city: 'Carroll County', state: 'MD', state_name: 'Maryland' },
  { city: 'Catonsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Cavetown', state: 'MD', state_name: 'Maryland' },
  { city: 'Cecil County', state: 'MD', state_name: 'Maryland' },
  { city: 'Centreville', state: 'MD', state_name: 'Maryland' },
  { city: 'Charles County', state: 'MD', state_name: 'Maryland' },
  { city: 'Charles Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Charlestown', state: 'MD', state_name: 'Maryland' },
  { city: 'Charlotte Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Chesapeake Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Chesapeake Ranch Estates', state: 'MD', state_name: 'Maryland' },
  { city: 'Chester', state: 'MD', state_name: 'Maryland' },
  { city: 'Chestertown', state: 'MD', state_name: 'Maryland' },
  { city: 'Cheverly', state: 'MD', state_name: 'Maryland' },
  { city: 'Chevy Chase', state: 'MD', state_name: 'Maryland' },
  { city: 'Chevy Chase Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Chillum', state: 'MD', state_name: 'Maryland' },
  { city: 'City of Baltimore', state: 'MD', state_name: 'Maryland' },
  { city: 'Clarksburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Clinton', state: 'MD', state_name: 'Maryland' },
  { city: 'Clover Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Cloverly', state: 'MD', state_name: 'Maryland' },
  { city: 'Cobb Island', state: 'MD', state_name: 'Maryland' },
  { city: 'Cockeysville', state: 'MD', state_name: 'Maryland' },
  { city: 'Colesville', state: 'MD', state_name: 'Maryland' },
  { city: 'College Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Colmar Manor', state: 'MD', state_name: 'Maryland' },
  { city: 'Columbia', state: 'MD', state_name: 'Maryland' },
  { city: 'Coral Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Cottage City', state: 'MD', state_name: 'Maryland' },
  { city: 'Cresaptown', state: 'MD', state_name: 'Maryland' },
  { city: 'Crisfield', state: 'MD', state_name: 'Maryland' },
  { city: 'Crofton', state: 'MD', state_name: 'Maryland' },
  { city: 'Croom', state: 'MD', state_name: 'Maryland' },
  { city: 'Crownsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Cumberland', state: 'MD', state_name: 'Maryland' },
  { city: 'Damascus', state: 'MD', state_name: 'Maryland' },
  { city: 'Darnestown', state: 'MD', state_name: 'Maryland' },
  { city: 'Davidsonville', state: 'MD', state_name: 'Maryland' },
  { city: 'Deale', state: 'MD', state_name: 'Maryland' },
  { city: 'Denton', state: 'MD', state_name: 'Maryland' },
  { city: 'Derwood', state: 'MD', state_name: 'Maryland' },
  { city: 'District Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Dorchester County', state: 'MD', state_name: 'Maryland' },
  { city: 'Drum Point', state: 'MD', state_name: 'Maryland' },
  { city: 'Dundalk', state: 'MD', state_name: 'Maryland' },
  { city: 'Dunkirk', state: 'MD', state_name: 'Maryland' },
  { city: 'Dunkirk Town Center', state: 'MD', state_name: 'Maryland' },
  { city: 'East Riverdale', state: 'MD', state_name: 'Maryland' },
  { city: 'Easton', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgemere', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgewater', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgewood', state: 'MD', state_name: 'Maryland' },
  { city: 'Edmonston', state: 'MD', state_name: 'Maryland' },
  { city: 'Eldersburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Elkridge', state: 'MD', state_name: 'Maryland' },
  { city: 'Elkton', state: 'MD', state_name: 'Maryland' },
  { city: 'Ellicott City', state: 'MD', state_name: 'Maryland' },
  { city: 'Emmitsburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Essex', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairland', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairmount Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Fallston', state: 'MD', state_name: 'Maryland' },
  { city: 'Federalsburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Ferndale', state: 'MD', state_name: 'Maryland' },
  { city: 'Forest Glen', state: 'MD', state_name: 'Maryland' },
  { city: 'Forest Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Forestville', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort George G Mead Junction', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort Meade', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort Washington', state: 'MD', state_name: 'Maryland' },
  { city: 'Fountainhead-Orchard Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Four Corners', state: 'MD', state_name: 'Maryland' },
  { city: 'Frederick', state: 'MD', state_name: 'Maryland' },
  { city: 'Frederick County', state: 'MD', state_name: 'Maryland' },
  { city: 'Friendly', state: 'MD', state_name: 'Maryland' },
  { city: 'Friendship Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Frostburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Fruitland', state: 'MD', state_name: 'Maryland' },
  { city: 'Fulton', state: 'MD', state_name: 'Maryland' },
  { city: 'Gaithersburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Gambrills', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrett County', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrett Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrison', state: 'MD', state_name: 'Maryland' },
  { city: 'Germantown', state: 'MD', state_name: 'Maryland' },
  { city: 'Glassmanor', state: 'MD', state_name: 'Maryland' },
  { city: 'Glen Burnie', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenarden', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenmont', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenn Dale', state: 'MD', state_name: 'Maryland' },
  { city: 'Goddard', state: 'MD', state_name: 'Maryland' },
  { city: 'Golden Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Grasonville', state: 'MD', state_name: 'Maryland' },
  { city: 'Greater Upper Marlboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Green Haven', state: 'MD', state_name: 'Maryland' },
  { city: 'Green Valley', state: 'MD', state_name: 'Maryland' },
  { city: 'Greenbelt', state: 'MD', state_name: 'Maryland' },
  { city: 'Greensboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Hagerstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Halfway', state: 'MD', state_name: 'Maryland' },
  { city: 'Hampstead', state: 'MD', state_name: 'Maryland' },
  { city: 'Hampton', state: 'MD', state_name: 'Maryland' },
  { city: 'Hancock', state: 'MD', state_name: 'Maryland' },
  { city: 'Hanover', state: 'MD', state_name: 'Maryland' },
  { city: 'Harford County', state: 'MD', state_name: 'Maryland' },
  { city: 'Havre de Grace', state: 'MD', state_name: 'Maryland' },
  { city: 'Hebron', state: 'MD', state_name: 'Maryland' },
  { city: 'Herald Harbor', state: 'MD', state_name: 'Maryland' },
  { city: 'Highfield-Cascade', state: 'MD', state_name: 'Maryland' },
  { city: 'Highland', state: 'MD', state_name: 'Maryland' },
  { city: 'Hillandale', state: 'MD', state_name: 'Maryland' },
  { city: 'Hillcrest Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Hillsmere Shores', state: 'MD', state_name: 'Maryland' },
  { city: 'Howard County', state: 'MD', state_name: 'Maryland' },
  { city: 'Hughesville', state: 'MD', state_name: 'Maryland' },
  { city: 'Hunt Valley', state: 'MD', state_name: 'Maryland' },
  { city: 'Huntingtown', state: 'MD', state_name: 'Maryland' },
  { city: 'Huntingtown Town Center', state: 'MD', state_name: 'Maryland' },
  { city: 'Hurlock', state: 'MD', state_name: 'Maryland' },
  { city: 'Hyattsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Ilchester', state: 'MD', state_name: 'Maryland' },
  { city: 'Indian Head', state: 'MD', state_name: 'Maryland' },
  { city: 'Irvington', state: 'MD', state_name: 'Maryland' },
  { city: 'Jarrettsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Jefferson', state: 'MD', state_name: 'Maryland' },
  { city: 'Jessup', state: 'MD', state_name: 'Maryland' },
  { city: 'Joppatowne', state: 'MD', state_name: 'Maryland' },
  { city: 'Keedysville', state: 'MD', state_name: 'Maryland' },
  { city: 'Kemp Mill', state: 'MD', state_name: 'Maryland' },
  { city: 'Kensington', state: 'MD', state_name: 'Maryland' },
  { city: 'Kent County', state: 'MD', state_name: 'Maryland' },
  { city: 'Kettering', state: 'MD', state_name: 'Maryland' },
  { city: 'Kingstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Kingsville', state: 'MD', state_name: 'Maryland' },
  { city: 'La Plata', state: 'MD', state_name: 'Maryland' },
  { city: 'La Vale', state: 'MD', state_name: 'Maryland' },
  { city: 'Lake Arbor', state: 'MD', state_name: 'Maryland' },
  { city: 'Lake Shore', state: 'MD', state_name: 'Maryland' },
  { city: 'Landover', state: 'MD', state_name: 'Maryland' },
  { city: 'Landover Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Langley Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Lanham', state: 'MD', state_name: 'Maryland' },
  { city: 'Lanham-Seabrook', state: 'MD', state_name: 'Maryland' },
  { city: 'Lansdowne', state: 'MD', state_name: 'Maryland' },
  { city: 'Largo', state: 'MD', state_name: 'Maryland' },
  { city: 'Laurel', state: 'MD', state_name: 'Maryland' },
  { city: 'Layhill', state: 'MD', state_name: 'Maryland' },
  { city: 'Leisure World', state: 'MD', state_name: 'Maryland' },
  { city: 'Leonardtown', state: 'MD', state_name: 'Maryland' },
  { city: 'Lexington Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Linganore', state: 'MD', state_name: 'Maryland' },
  { city: 'Linthicum', state: 'MD', state_name: 'Maryland' },
  { city: 'Lochearn', state: 'MD', state_name: 'Maryland' },
  { city: 'Lonaconing', state: 'MD', state_name: 'Maryland' },
  { city: 'Londontowne', state: 'MD', state_name: 'Maryland' },
  { city: 'Long Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Lusby', state: 'MD', state_name: 'Maryland' },
  { city: 'Lutherville', state: 'MD', state_name: 'Maryland' },
  { city: 'Lutherville-Timonium', state: 'MD', state_name: 'Maryland' },
  { city: 'Manchester', state: 'MD', state_name: 'Maryland' },
  { city: 'Marlboro Meadows', state: 'MD', state_name: 'Maryland' },
  { city: 'Marlboro Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Marlow Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Marlton', state: 'MD', state_name: 'Maryland' },
  { city: 'Maryland City', state: 'MD', state_name: 'Maryland' },
  { city: 'Maugansville', state: 'MD', state_name: 'Maryland' },
  { city: 'Mayo', state: 'MD', state_name: 'Maryland' },
  { city: 'Mays Chapel', state: 'MD', state_name: 'Maryland' },
  { city: 'Mechanicsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Mellwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Middle River', state: 'MD', state_name: 'Maryland' },
  { city: 'Middletown', state: 'MD', state_name: 'Maryland' },
  { city: 'Milford Mill', state: 'MD', state_name: 'Maryland' },
  { city: 'Mitchellville', state: 'MD', state_name: 'Maryland' },
  { city: 'Montgomery County', state: 'MD', state_name: 'Maryland' },
  { city: 'Montgomery Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Morningside', state: 'MD', state_name: 'Maryland' },
  { city: 'Mount Airy', state: 'MD', state_name: 'Maryland' },
  { city: 'Mount Rainier', state: 'MD', state_name: 'Maryland' },
  { city: 'Mountain Lake Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Myersville', state: 'MD', state_name: 'Maryland' },
  { city: 'National Harbor', state: 'MD', state_name: 'Maryland' },
  { city: 'Naval Academy', state: 'MD', state_name: 'Maryland' },
  { city: 'New Carrollton', state: 'MD', state_name: 'Maryland' },
  { city: 'New Windsor', state: 'MD', state_name: 'Maryland' },
  { city: 'North Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'North Bel Air', state: 'MD', state_name: 'Maryland' },
  { city: 'North Bethesda', state: 'MD', state_name: 'Maryland' },
  { city: 'North East', state: 'MD', state_name: 'Maryland' },
  { city: 'North Kensington', state: 'MD', state_name: 'Maryland' },
  { city: 'North Laurel', state: 'MD', state_name: 'Maryland' },
  { city: 'North Potomac', state: 'MD', state_name: 'Maryland' },
  { city: 'Oakland', state: 'MD', state_name: 'Maryland' },
  { city: 'Ocean City', state: 'MD', state_name: 'Maryland' },
  { city: 'Ocean Pines', state: 'MD', state_name: 'Maryland' },
  { city: 'Odenton', state: 'MD', state_name: 'Maryland' },
  { city: 'Olney', state: 'MD', state_name: 'Maryland' },
  { city: 'Overlea', state: 'MD', state_name: 'Maryland' },
  { city: 'Owings', state: 'MD', state_name: 'Maryland' },
  { city: 'Owings Mills', state: 'MD', state_name: 'Maryland' },
  { city: 'Oxon Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Oxon Hill-Glassmanor', state: 'MD', state_name: 'Maryland' },
  { city: 'Paramount-Long Meadow', state: 'MD', state_name: 'Maryland' },
  { city: 'Parkville', state: 'MD', state_name: 'Maryland' },
  { city: 'Parole', state: 'MD', state_name: 'Maryland' },
  { city: 'Pasadena', state: 'MD', state_name: 'Maryland' },
  { city: 'Peppermill Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Perry Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Perryman', state: 'MD', state_name: 'Maryland' },
  { city: 'Perryville', state: 'MD', state_name: 'Maryland' },
  { city: 'Pikesville', state: 'MD', state_name: 'Maryland' },
  { city: 'Pittsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Pleasant Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Pocomoke City', state: 'MD', state_name: 'Maryland' },
  { city: 'Point of Rocks', state: 'MD', state_name: 'Maryland' },
  { city: 'Poolesville', state: 'MD', state_name: 'Maryland' },
  { city: 'Potomac', state: 'MD', state_name: 'Maryland' },
  { city: 'Potomac Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Potomac Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Prince Frederick', state: 'MD', state_name: 'Maryland' },
  { city: "Prince George's County", state: 'MD', state_name: 'Maryland' },
  { city: 'Princess Anne', state: 'MD', state_name: 'Maryland' },
  { city: 'Pumphrey', state: 'MD', state_name: 'Maryland' },
  { city: 'Queen Anne', state: 'MD', state_name: 'Maryland' },
  { city: "Queen Anne's County", state: 'MD', state_name: 'Maryland' },
  { city: 'Queenland', state: 'MD', state_name: 'Maryland' },
  { city: 'Randallstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Redland', state: 'MD', state_name: 'Maryland' },
  { city: 'Reisterstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Ridgely', state: 'MD', state_name: 'Maryland' },
  { city: 'Rising Sun', state: 'MD', state_name: 'Maryland' },
  { city: 'Riva', state: 'MD', state_name: 'Maryland' },
  { city: 'Riverdale Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Riverside', state: 'MD', state_name: 'Maryland' },
  { city: 'Riviera Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Robinwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Rock Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Rockville', state: 'MD', state_name: 'Maryland' },
  { city: 'Rosaryville', state: 'MD', state_name: 'Maryland' },
  { city: 'Rosedale', state: 'MD', state_name: 'Maryland' },
  { city: 'Rossmoor', state: 'MD', state_name: 'Maryland' },
  { city: 'Rossville', state: 'MD', state_name: 'Maryland' },
  { city: 'Saint Charles', state: 'MD', state_name: 'Maryland' },
  { city: 'Saint James', state: 'MD', state_name: 'Maryland' },
  { city: "Saint Mary's County", state: 'MD', state_name: 'Maryland' },
  { city: 'Saint Michaels', state: 'MD', state_name: 'Maryland' },
  { city: 'Salisbury', state: 'MD', state_name: 'Maryland' },
  { city: 'Savage', state: 'MD', state_name: 'Maryland' },
  { city: 'Scaggsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Seabrook', state: 'MD', state_name: 'Maryland' },
  { city: 'Seat Pleasant', state: 'MD', state_name: 'Maryland' },
  { city: 'Selby-on-the-Bay', state: 'MD', state_name: 'Maryland' },
  { city: 'Severn', state: 'MD', state_name: 'Maryland' },
  { city: 'Severna Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Shady Side', state: 'MD', state_name: 'Maryland' },
  { city: 'Silver Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Silver Spring', state: 'MD', state_name: 'Maryland' },
  { city: 'Smithsburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Snow Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Solomons', state: 'MD', state_name: 'Maryland' },
  { city: 'Somerset', state: 'MD', state_name: 'Maryland' },
  { city: 'Somerset County', state: 'MD', state_name: 'Maryland' },
  { city: 'South Bel Air', state: 'MD', state_name: 'Maryland' },
  { city: 'South Gate', state: 'MD', state_name: 'Maryland' },
  { city: 'South Kensington', state: 'MD', state_name: 'Maryland' },
  { city: 'South Laurel', state: 'MD', state_name: 'Maryland' },
  { city: 'Spencerville', state: 'MD', state_name: 'Maryland' },
  { city: 'Spring Ridge', state: 'MD', state_name: 'Maryland' },
  { city: 'Springdale', state: 'MD', state_name: 'Maryland' },
  { city: 'Stevensville', state: 'MD', state_name: 'Maryland' },
  { city: 'Suitland', state: 'MD', state_name: 'Maryland' },
  { city: 'Suitland-Silver Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Summerfield', state: 'MD', state_name: 'Maryland' },
  { city: 'Sykesville', state: 'MD', state_name: 'Maryland' },
  { city: 'Takoma Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Talbot County', state: 'MD', state_name: 'Maryland' },
  { city: 'Taneytown', state: 'MD', state_name: 'Maryland' },
  { city: 'Temple Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Thurmont', state: 'MD', state_name: 'Maryland' },
  { city: 'Timonium', state: 'MD', state_name: 'Maryland' },
  { city: 'Towson', state: 'MD', state_name: 'Maryland' },
  { city: 'Trappe', state: 'MD', state_name: 'Maryland' },
  { city: 'Travilah', state: 'MD', state_name: 'Maryland' },
  { city: 'University Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Upper Marlboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Urbana', state: 'MD', state_name: 'Maryland' },
  { city: 'Waldorf', state: 'MD', state_name: 'Maryland' },
  { city: 'Walker Mill', state: 'MD', state_name: 'Maryland' },
  { city: 'Walkersville', state: 'MD', state_name: 'Maryland' },
  { city: 'Washington County', state: 'MD', state_name: 'Maryland' },
  { city: 'West Elkridge', state: 'MD', state_name: 'Maryland' },
  { city: 'West Laurel', state: 'MD', state_name: 'Maryland' },
  { city: 'West Ocean City', state: 'MD', state_name: 'Maryland' },
  { city: 'Westernport', state: 'MD', state_name: 'Maryland' },
  { city: 'Westminster', state: 'MD', state_name: 'Maryland' },
  { city: 'Westphalia', state: 'MD', state_name: 'Maryland' },
  { city: 'Wheaton', state: 'MD', state_name: 'Maryland' },
  { city: 'White Marsh', state: 'MD', state_name: 'Maryland' },
  { city: 'White Oak', state: 'MD', state_name: 'Maryland' },
  { city: 'Wicomico County', state: 'MD', state_name: 'Maryland' },
  { city: 'Williamsport', state: 'MD', state_name: 'Maryland' },
  { city: 'Wilson-Conococheague', state: 'MD', state_name: 'Maryland' },
  { city: 'Woodlawn', state: 'MD', state_name: 'Maryland' },
  { city: 'Woodmore', state: 'MD', state_name: 'Maryland' },
  { city: 'Woodsboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Worcester County', state: 'MD', state_name: 'Maryland' },
  { city: 'Abington', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Acton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Acushnet', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Acushnet Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Adams', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Agawam', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Amesbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Amherst', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Amherst Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Andover', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Arlington', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ashburnham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ashby', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ashfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ashland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Assonet', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Athol', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Attleboro', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Auburn', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Avon', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ayer', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Baldwinville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Barnstable', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Barnstable County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Barre', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Becket', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bedford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Belchertown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bellingham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Belmont', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Berkley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Berkshire County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Berlin', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bernardston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Beverly', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Beverly Cove', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Billerica', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Blackstone', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bliss Corner', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bolton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bondsville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Boston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bourne', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Boxborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Boxford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Boylston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Braintree', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Brewster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bridgewater', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Brimfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Bristol County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Brockton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Brookline', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Buckland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Burlington', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Buzzards Bay', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Cambridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Canton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Carlisle', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Carver', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Centerville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Charlemont', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Charlton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Chatham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Chelmsford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Chelsea', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Chesterfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Chicopee', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Clinton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Cochituate', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Cohasset', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Colrain', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Concord', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Conway', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Cordaville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Cotuit', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dalton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Danvers', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dartmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dedham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dennis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dennis Port', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Devens', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dighton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Douglas', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dover', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dracut', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dudley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dukes County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Dunstable', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Duxbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Bridgewater', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Brookfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Dennis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Douglas', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Falmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Harwich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Longmeadow', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Pepperell', state: 'MA', state_name: 'Massachusetts' },
  { city: 'East Sandwich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Eastham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Easthampton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Easton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Edgartown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Erving', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Essex', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Essex County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Everett', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Fairhaven', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Fall River', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Falmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Fiskdale', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Fitchburg', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Forestdale', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Foxborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Framingham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Framingham Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Franklin', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Franklin County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Freetown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Gardner', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Gill', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Gloucester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Grafton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Granby', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Granville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Great Barrington', state: 'MA', state_name: 'Massachusetts' },
  {
    city: 'Green Harbor-Cedar Crest',
    state: 'MA',
    state_name: 'Massachusetts',
  },
  { city: 'Greenfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Groton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Groveland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hadley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Halifax', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hamilton Worcester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hampden', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hampden County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hampshire County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hanover', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hanson', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hardwick', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Harvard', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Harwich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Harwich Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Harwich Port', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hatfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Haverhill', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Head of Westport', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hingham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hinsdale', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Holbrook', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Holden', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Holland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Holliston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Holyoke', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hopedale', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hopkinton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Housatonic', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hubbardston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hudson', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hull', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Hyannis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ipswich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Jamaica Plain', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Kingston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lancaster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lanesborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lawrence', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lee', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Leicester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lenox', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Leominster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Leverett', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lexington', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lincoln', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Littleton Common', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Longmeadow', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lowell', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ludlow', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lunenburg', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lynn', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Lynnfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Malden', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Manchester-by-the-Sea', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mansfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mansfield Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marblehead', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marion', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marion Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marlborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marshfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marshfield Hills', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Marstons Mills', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mashpee', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mattapoisett', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mattapoisett Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Maynard', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Medfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Medford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Medway', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Melrose', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Mendon', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Merrimac', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Methuen', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Middleborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Middleborough Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Middlesex County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Middleton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Milford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Millbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Millers Falls', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Millis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Millis-Clicquot', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Millville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Milton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Monson', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Monson Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Montague', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Monument Beach', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Nahant', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Nantucket', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Nantucket County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Natick', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Needham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'New Bedford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'New Marlborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Newburyport', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Newton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norfolk', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norfolk County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Adams', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Amherst', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Andover', state: 'MA', state_name: 'Massachusetts' },
  {
    city: 'North Attleborough Center',
    state: 'MA',
    state_name: 'Massachusetts',
  },
  { city: 'North Brookfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Chicopee', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Eastham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Falmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Lakeville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Pembroke', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Plymouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Reading', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Scituate', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Seekonk', state: 'MA', state_name: 'Massachusetts' },
  { city: 'North Westport', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Northampton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Northborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Northbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Northfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Northwest Harwich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norton Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norwell', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Norwood', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Oak Bluffs', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Oakham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ocean Bluff-Brant Rock', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ocean Grove', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Onset', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Orange', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Orleans', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Osterville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Otis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Oxford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Palmer', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Paxton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Peabody', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Pelham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Pepperell', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Phillipston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Pinehurst', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Pittsfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Plainville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Plymouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Plymouth County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Plympton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Pocasset', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Princeton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Provincetown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Quincy', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Randolph', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Raynham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Raynham Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Reading', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rehoboth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Revere', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Richmond', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rochester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rockland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rockport', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rowley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Royalston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Rutland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sagamore', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Salem', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Salisbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sandwich', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Saugus', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Scituate', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Seekonk', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sharon', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sheffield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Shelburne', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Shelburne Falls', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sherborn', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Shirley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Shrewsbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Shutesbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Smith Mills', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Somerset', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Somerville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Amherst', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Ashburnham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Boston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Deerfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Dennis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Duxbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Hadley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Lancaster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Peabody', state: 'MA', state_name: 'Massachusetts' },
  { city: 'South Yarmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Southampton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Southborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Southbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Southwick', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Spencer', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Springfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sterling', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Stockbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Stoneham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Stoughton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Stow', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sturbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sudbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Suffolk County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sunderland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Sutton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Swampscott', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Swansea', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Taunton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Teaticket', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Templeton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Tewksbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Three Rivers', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Topsfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Townsend', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Truro', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Turners Falls', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Tyngsboro', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Upton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Uxbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Vineyard Haven', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wakefield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wales', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Walpole', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Waltham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Ware', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wareham Center', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Warren', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Watertown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wayland', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Webster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wellesley', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wellfleet', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wendell', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wenham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Barnstable', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Boylston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Bridgewater', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Brookfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Chatham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Concord', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Dennis', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Falmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Newbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Springfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Stockbridge', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Tisbury', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Wareham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'West Yarmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westborough', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westfield', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westford', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westhampton', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westminster', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Weston', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Westwood', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Weweantic', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Weymouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Whately', state: 'MA', state_name: 'Massachusetts' },
  { city: 'White Island Shores', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Whitinsville', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Whitman', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wilbraham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Williamsburg', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Williamstown', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wilmington', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Winchendon', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Winchester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Winthrop', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Woburn', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Worcester', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Worcester County', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Wrentham', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Yarmouth', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Yarmouth Port', state: 'MA', state_name: 'Massachusetts' },
  { city: 'Adrian', state: 'MI', state_name: 'Michigan' },
  { city: 'Albion', state: 'MI', state_name: 'Michigan' },
  { city: 'Alcona County', state: 'MI', state_name: 'Michigan' },
  { city: 'Alger County', state: 'MI', state_name: 'Michigan' },
  { city: 'Algonac', state: 'MI', state_name: 'Michigan' },
  { city: 'Allegan', state: 'MI', state_name: 'Michigan' },
  { city: 'Allegan County', state: 'MI', state_name: 'Michigan' },
  { city: 'Allen Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Allendale', state: 'MI', state_name: 'Michigan' },
  { city: 'Alma', state: 'MI', state_name: 'Michigan' },
  { city: 'Almont', state: 'MI', state_name: 'Michigan' },
  { city: 'Alpena', state: 'MI', state_name: 'Michigan' },
  { city: 'Alpena County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ann Arbor', state: 'MI', state_name: 'Michigan' },
  { city: 'Antrim County', state: 'MI', state_name: 'Michigan' },
  { city: 'Arenac County', state: 'MI', state_name: 'Michigan' },
  { city: 'Argentine', state: 'MI', state_name: 'Michigan' },
  { city: 'Armada', state: 'MI', state_name: 'Michigan' },
  { city: 'Athens', state: 'MI', state_name: 'Michigan' },
  { city: 'Atlanta', state: 'MI', state_name: 'Michigan' },
  { city: 'Au Sable', state: 'MI', state_name: 'Michigan' },
  { city: 'Auburn', state: 'MI', state_name: 'Michigan' },
  { city: 'Auburn Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Bad Axe', state: 'MI', state_name: 'Michigan' },
  { city: 'Baldwin', state: 'MI', state_name: 'Michigan' },
  { city: 'Bangor', state: 'MI', state_name: 'Michigan' },
  { city: 'Baraga', state: 'MI', state_name: 'Michigan' },
  { city: 'Baraga County', state: 'MI', state_name: 'Michigan' },
  { city: 'Barnes Lake-Millers Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Barry County', state: 'MI', state_name: 'Michigan' },
  { city: 'Bath', state: 'MI', state_name: 'Michigan' },
  { city: 'Battle Creek', state: 'MI', state_name: 'Michigan' },
  { city: 'Bay City', state: 'MI', state_name: 'Michigan' },
  { city: 'Bay County', state: 'MI', state_name: 'Michigan' },
  { city: 'Bay Harbor', state: 'MI', state_name: 'Michigan' },
  { city: 'Beaverton', state: 'MI', state_name: 'Michigan' },
  { city: 'Beecher', state: 'MI', state_name: 'Michigan' },
  { city: 'Beechwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Belding', state: 'MI', state_name: 'Michigan' },
  { city: 'Bellaire', state: 'MI', state_name: 'Michigan' },
  { city: 'Belleville', state: 'MI', state_name: 'Michigan' },
  { city: 'Bellevue', state: 'MI', state_name: 'Michigan' },
  { city: 'Benton Harbor', state: 'MI', state_name: 'Michigan' },
  { city: 'Benton Heights', state: 'MI', state_name: 'Michigan' },
  { city: 'Benzie County', state: 'MI', state_name: 'Michigan' },
  { city: 'Berkley', state: 'MI', state_name: 'Michigan' },
  { city: 'Berrien County', state: 'MI', state_name: 'Michigan' },
  { city: 'Berrien Springs', state: 'MI', state_name: 'Michigan' },
  { city: 'Bessemer', state: 'MI', state_name: 'Michigan' },
  { city: 'Beulah', state: 'MI', state_name: 'Michigan' },
  { city: 'Beverly Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Big Rapids', state: 'MI', state_name: 'Michigan' },
  { city: 'Bingham Farms', state: 'MI', state_name: 'Michigan' },
  { city: 'Birch Run', state: 'MI', state_name: 'Michigan' },
  { city: 'Birmingham', state: 'MI', state_name: 'Michigan' },
  { city: 'Blissfield', state: 'MI', state_name: 'Michigan' },
  { city: 'Bloomfield Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Boyne City', state: 'MI', state_name: 'Michigan' },
  { city: 'Branch County', state: 'MI', state_name: 'Michigan' },
  { city: 'Breckenridge', state: 'MI', state_name: 'Michigan' },
  { city: 'Bridgeport', state: 'MI', state_name: 'Michigan' },
  { city: 'Bridgman', state: 'MI', state_name: 'Michigan' },
  { city: 'Brighton', state: 'MI', state_name: 'Michigan' },
  { city: 'Bronson', state: 'MI', state_name: 'Michigan' },
  { city: 'Brooklyn', state: 'MI', state_name: 'Michigan' },
  { city: 'Brown City', state: 'MI', state_name: 'Michigan' },
  { city: 'Brownlee Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Buchanan', state: 'MI', state_name: 'Michigan' },
  { city: 'Buena Vista', state: 'MI', state_name: 'Michigan' },
  { city: 'Burt', state: 'MI', state_name: 'Michigan' },
  { city: 'Burton', state: 'MI', state_name: 'Michigan' },
  { city: 'Byron Center', state: 'MI', state_name: 'Michigan' },
  { city: 'Cadillac', state: 'MI', state_name: 'Michigan' },
  { city: 'Caledonia', state: 'MI', state_name: 'Michigan' },
  { city: 'Calhoun County', state: 'MI', state_name: 'Michigan' },
  { city: 'Canadian Lakes', state: 'MI', state_name: 'Michigan' },
  { city: 'Canton', state: 'MI', state_name: 'Michigan' },
  { city: 'Capac', state: 'MI', state_name: 'Michigan' },
  { city: 'Carleton', state: 'MI', state_name: 'Michigan' },
  { city: 'Caro', state: 'MI', state_name: 'Michigan' },
  { city: 'Carrollton', state: 'MI', state_name: 'Michigan' },
  { city: 'Carson City', state: 'MI', state_name: 'Michigan' },
  { city: 'Cass City', state: 'MI', state_name: 'Michigan' },
  { city: 'Cass County', state: 'MI', state_name: 'Michigan' },
  { city: 'Cassopolis', state: 'MI', state_name: 'Michigan' },
  { city: 'Cedar Springs', state: 'MI', state_name: 'Michigan' },
  { city: 'Center Line', state: 'MI', state_name: 'Michigan' },
  { city: 'Centreville', state: 'MI', state_name: 'Michigan' },
  { city: 'Charlevoix', state: 'MI', state_name: 'Michigan' },
  { city: 'Charlevoix County', state: 'MI', state_name: 'Michigan' },
  { city: 'Charlotte', state: 'MI', state_name: 'Michigan' },
  { city: 'Cheboygan', state: 'MI', state_name: 'Michigan' },
  { city: 'Cheboygan County', state: 'MI', state_name: 'Michigan' },
  { city: 'Chelsea', state: 'MI', state_name: 'Michigan' },
  { city: 'Chesaning', state: 'MI', state_name: 'Michigan' },
  { city: 'Chippewa County', state: 'MI', state_name: 'Michigan' },
  { city: 'Clare', state: 'MI', state_name: 'Michigan' },
  { city: 'Clare County', state: 'MI', state_name: 'Michigan' },
  { city: 'Clarkston', state: 'MI', state_name: 'Michigan' },
  { city: 'Clawson', state: 'MI', state_name: 'Michigan' },
  { city: 'Clinton', state: 'MI', state_name: 'Michigan' },
  { city: 'Clinton County', state: 'MI', state_name: 'Michigan' },
  { city: 'Clinton Township', state: 'MI', state_name: 'Michigan' },
  { city: 'Clio', state: 'MI', state_name: 'Michigan' },
  { city: 'Coldwater', state: 'MI', state_name: 'Michigan' },
  { city: 'Coleman', state: 'MI', state_name: 'Michigan' },
  { city: 'Coloma', state: 'MI', state_name: 'Michigan' },
  { city: 'Colon', state: 'MI', state_name: 'Michigan' },
  { city: 'Comstock Northwest', state: 'MI', state_name: 'Michigan' },
  { city: 'Comstock Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Concord', state: 'MI', state_name: 'Michigan' },
  { city: 'Constantine', state: 'MI', state_name: 'Michigan' },
  { city: 'Coopersville', state: 'MI', state_name: 'Michigan' },
  { city: 'Corunna', state: 'MI', state_name: 'Michigan' },
  { city: 'Crawford County', state: 'MI', state_name: 'Michigan' },
  { city: 'Croswell', state: 'MI', state_name: 'Michigan' },
  { city: 'Crystal Falls', state: 'MI', state_name: 'Michigan' },
  { city: 'Cutlerville', state: 'MI', state_name: 'Michigan' },
  { city: 'Davison', state: 'MI', state_name: 'Michigan' },
  { city: 'Dearborn', state: 'MI', state_name: 'Michigan' },
  { city: 'Dearborn Heights', state: 'MI', state_name: 'Michigan' },
  { city: 'Decatur', state: 'MI', state_name: 'Michigan' },
  { city: 'Delta County', state: 'MI', state_name: 'Michigan' },
  { city: 'Detroit', state: 'MI', state_name: 'Michigan' },
  { city: 'Detroit Beach', state: 'MI', state_name: 'Michigan' },
  { city: 'DeWitt', state: 'MI', state_name: 'Michigan' },
  { city: 'Dexter', state: 'MI', state_name: 'Michigan' },
  { city: 'Dickinson County', state: 'MI', state_name: 'Michigan' },
  { city: 'Dimondale', state: 'MI', state_name: 'Michigan' },
  { city: 'Dollar Bay', state: 'MI', state_name: 'Michigan' },
  { city: 'Douglas', state: 'MI', state_name: 'Michigan' },
  { city: 'Dowagiac', state: 'MI', state_name: 'Michigan' },
  { city: 'Dundee', state: 'MI', state_name: 'Michigan' },
  { city: 'Durand', state: 'MI', state_name: 'Michigan' },
  { city: 'Eagle River', state: 'MI', state_name: 'Michigan' },
  { city: 'East Grand Rapids', state: 'MI', state_name: 'Michigan' },
  { city: 'East Jordan', state: 'MI', state_name: 'Michigan' },
  { city: 'East Lansing', state: 'MI', state_name: 'Michigan' },
  { city: 'East Tawas', state: 'MI', state_name: 'Michigan' },
  { city: 'Eastpointe', state: 'MI', state_name: 'Michigan' },
  { city: 'Eastwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Eaton County', state: 'MI', state_name: 'Michigan' },
  { city: 'Eaton Rapids', state: 'MI', state_name: 'Michigan' },
  { city: 'Ecorse', state: 'MI', state_name: 'Michigan' },
  { city: 'Edgemont Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Edmore', state: 'MI', state_name: 'Michigan' },
  { city: 'Edwardsburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Elk Rapids', state: 'MI', state_name: 'Michigan' },
  { city: 'Emmet County', state: 'MI', state_name: 'Michigan' },
  { city: 'Escanaba', state: 'MI', state_name: 'Michigan' },
  { city: 'Essexville', state: 'MI', state_name: 'Michigan' },
  { city: 'Evart', state: 'MI', state_name: 'Michigan' },
  { city: 'Fair Plain', state: 'MI', state_name: 'Michigan' },
  { city: 'Farmington', state: 'MI', state_name: 'Michigan' },
  { city: 'Farmington Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Fennville', state: 'MI', state_name: 'Michigan' },
  { city: 'Fenton', state: 'MI', state_name: 'Michigan' },
  { city: 'Ferndale', state: 'MI', state_name: 'Michigan' },
  { city: 'Ferrysburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Flat Rock', state: 'MI', state_name: 'Michigan' },
  { city: 'Flint', state: 'MI', state_name: 'Michigan' },
  { city: 'Flushing', state: 'MI', state_name: 'Michigan' },
  { city: 'Forest Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Fowler', state: 'MI', state_name: 'Michigan' },
  { city: 'Fowlerville', state: 'MI', state_name: 'Michigan' },
  { city: 'Frankenmuth', state: 'MI', state_name: 'Michigan' },
  { city: 'Frankfort', state: 'MI', state_name: 'Michigan' },
  { city: 'Franklin', state: 'MI', state_name: 'Michigan' },
  { city: 'Fraser', state: 'MI', state_name: 'Michigan' },
  { city: 'Freeland', state: 'MI', state_name: 'Michigan' },
  { city: 'Fremont', state: 'MI', state_name: 'Michigan' },
  { city: 'Fruitport', state: 'MI', state_name: 'Michigan' },
  { city: 'Galesburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Garden City', state: 'MI', state_name: 'Michigan' },
  { city: 'Gaylord', state: 'MI', state_name: 'Michigan' },
  { city: 'Genesee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Gibraltar', state: 'MI', state_name: 'Michigan' },
  { city: 'Gladstone', state: 'MI', state_name: 'Michigan' },
  { city: 'Gladwin', state: 'MI', state_name: 'Michigan' },
  { city: 'Gladwin County', state: 'MI', state_name: 'Michigan' },
  { city: 'Gogebic County', state: 'MI', state_name: 'Michigan' },
  { city: 'Goodrich', state: 'MI', state_name: 'Michigan' },
  { city: 'Grand Blanc', state: 'MI', state_name: 'Michigan' },
  { city: 'Grand Haven', state: 'MI', state_name: 'Michigan' },
  { city: 'Grand Ledge', state: 'MI', state_name: 'Michigan' },
  { city: 'Grand Rapids', state: 'MI', state_name: 'Michigan' },
  { city: 'Grand Traverse County', state: 'MI', state_name: 'Michigan' },
  { city: 'Grandville', state: 'MI', state_name: 'Michigan' },
  { city: 'Grass Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Gratiot County', state: 'MI', state_name: 'Michigan' },
  { city: 'Grayling', state: 'MI', state_name: 'Michigan' },
  { city: 'Greenville', state: 'MI', state_name: 'Michigan' },
  { city: 'Greilickville', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Ile', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Pointe', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Pointe Farms', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Pointe Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Pointe Shores', state: 'MI', state_name: 'Michigan' },
  { city: 'Grosse Pointe Woods', state: 'MI', state_name: 'Michigan' },
  { city: 'Gwinn', state: 'MI', state_name: 'Michigan' },
  { city: 'Hamtramck', state: 'MI', state_name: 'Michigan' },
  { city: 'Hancock', state: 'MI', state_name: 'Michigan' },
  { city: 'Harbor Beach', state: 'MI', state_name: 'Michigan' },
  { city: 'Harbor Springs', state: 'MI', state_name: 'Michigan' },
  { city: 'Harper Woods', state: 'MI', state_name: 'Michigan' },
  { city: 'Harrison', state: 'MI', state_name: 'Michigan' },
  { city: 'Harrisville', state: 'MI', state_name: 'Michigan' },
  { city: 'Hart', state: 'MI', state_name: 'Michigan' },
  { city: 'Hartford', state: 'MI', state_name: 'Michigan' },
  { city: 'Harvey', state: 'MI', state_name: 'Michigan' },
  { city: 'Haslett', state: 'MI', state_name: 'Michigan' },
  { city: 'Hastings', state: 'MI', state_name: 'Michigan' },
  { city: 'Hazel Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Hemlock', state: 'MI', state_name: 'Michigan' },
  { city: 'Highland Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Hillsdale', state: 'MI', state_name: 'Michigan' },
  { city: 'Hillsdale County', state: 'MI', state_name: 'Michigan' },
  { city: 'Holland', state: 'MI', state_name: 'Michigan' },
  { city: 'Holly', state: 'MI', state_name: 'Michigan' },
  { city: 'Holt', state: 'MI', state_name: 'Michigan' },
  { city: 'Homer', state: 'MI', state_name: 'Michigan' },
  { city: 'Houghton', state: 'MI', state_name: 'Michigan' },
  { city: 'Houghton County', state: 'MI', state_name: 'Michigan' },
  { city: 'Houghton Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Howard City', state: 'MI', state_name: 'Michigan' },
  { city: 'Howell', state: 'MI', state_name: 'Michigan' },
  { city: 'Hubbard Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Hudson', state: 'MI', state_name: 'Michigan' },
  { city: 'Hudsonville', state: 'MI', state_name: 'Michigan' },
  { city: 'Huntington Woods', state: 'MI', state_name: 'Michigan' },
  { city: 'Huron County', state: 'MI', state_name: 'Michigan' },
  { city: 'Imlay City', state: 'MI', state_name: 'Michigan' },
  { city: 'Indian River', state: 'MI', state_name: 'Michigan' },
  { city: 'Ingham County', state: 'MI', state_name: 'Michigan' },
  { city: 'Inkster', state: 'MI', state_name: 'Michigan' },
  { city: 'Ionia', state: 'MI', state_name: 'Michigan' },
  { city: 'Ionia County', state: 'MI', state_name: 'Michigan' },
  { city: 'Iosco County', state: 'MI', state_name: 'Michigan' },
  { city: 'Iron County', state: 'MI', state_name: 'Michigan' },
  { city: 'Iron Mountain', state: 'MI', state_name: 'Michigan' },
  { city: 'Iron River', state: 'MI', state_name: 'Michigan' },
  { city: 'Ironwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Isabella County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ishpeming', state: 'MI', state_name: 'Michigan' },
  { city: 'Ithaca', state: 'MI', state_name: 'Michigan' },
  { city: 'Jackson', state: 'MI', state_name: 'Michigan' },
  { city: 'Jackson County', state: 'MI', state_name: 'Michigan' },
  { city: 'Jenison', state: 'MI', state_name: 'Michigan' },
  { city: 'Jonesville', state: 'MI', state_name: 'Michigan' },
  { city: 'K. I. Sawyer Air Force Base', state: 'MI', state_name: 'Michigan' },
  { city: 'Kalamazoo', state: 'MI', state_name: 'Michigan' },
  { city: 'Kalamazoo County', state: 'MI', state_name: 'Michigan' },
  { city: 'Kalkaska', state: 'MI', state_name: 'Michigan' },
  { city: 'Kalkaska County', state: 'MI', state_name: 'Michigan' },
  { city: 'Keego Harbor', state: 'MI', state_name: 'Michigan' },
  { city: 'Kent City', state: 'MI', state_name: 'Michigan' },
  { city: 'Kent County', state: 'MI', state_name: 'Michigan' },
  { city: 'Kentwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Keweenaw County', state: 'MI', state_name: 'Michigan' },
  { city: 'Kilmanagh', state: 'MI', state_name: 'Michigan' },
  { city: 'Kingsford', state: 'MI', state_name: 'Michigan' },
  { city: 'Kingsley', state: 'MI', state_name: 'Michigan' },
  { city: "L'Anse", state: 'MI', state_name: 'Michigan' },
  { city: 'Laingsburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake City', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake County', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake Fenton', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake Isabella', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake Michigan Beach', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake Odessa', state: 'MI', state_name: 'Michigan' },
  { city: 'Lake Orion', state: 'MI', state_name: 'Michigan' },
  { city: 'Lakeview', state: 'MI', state_name: 'Michigan' },
  { city: 'Lakewood Club', state: 'MI', state_name: 'Michigan' },
  { city: 'Lambertville', state: 'MI', state_name: 'Michigan' },
  { city: 'Lansing', state: 'MI', state_name: 'Michigan' },
  { city: 'Lapeer', state: 'MI', state_name: 'Michigan' },
  { city: 'Lapeer County', state: 'MI', state_name: 'Michigan' },
  { city: 'Lathrup Village', state: 'MI', state_name: 'Michigan' },
  { city: 'Laurium', state: 'MI', state_name: 'Michigan' },
  { city: 'Lawton', state: 'MI', state_name: 'Michigan' },
  { city: 'Leelanau County', state: 'MI', state_name: 'Michigan' },
  { city: 'Leland', state: 'MI', state_name: 'Michigan' },
  { city: 'Lenawee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Leslie', state: 'MI', state_name: 'Michigan' },
  { city: 'Level Park-Oak Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Lewiston', state: 'MI', state_name: 'Michigan' },
  { city: 'Lexington', state: 'MI', state_name: 'Michigan' },
  { city: 'Lincoln Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Linden', state: 'MI', state_name: 'Michigan' },
  { city: 'Litchfield', state: 'MI', state_name: 'Michigan' },
  { city: 'Livingston County', state: 'MI', state_name: 'Michigan' },
  { city: 'Livonia', state: 'MI', state_name: 'Michigan' },
  { city: 'Lowell', state: 'MI', state_name: 'Michigan' },
  { city: 'Luce County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ludington', state: 'MI', state_name: 'Michigan' },
  { city: 'Luna Pier', state: 'MI', state_name: 'Michigan' },
  { city: 'Mackinac County', state: 'MI', state_name: 'Michigan' },
  { city: 'Macomb County', state: 'MI', state_name: 'Michigan' },
  { city: 'Madison Heights', state: 'MI', state_name: 'Michigan' },
  { city: 'Mancelona', state: 'MI', state_name: 'Michigan' },
  { city: 'Manchester', state: 'MI', state_name: 'Michigan' },
  { city: 'Manistee', state: 'MI', state_name: 'Michigan' },
  { city: 'Manistee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Manistique', state: 'MI', state_name: 'Michigan' },
  { city: 'Manitou Beach-Devils Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Manton', state: 'MI', state_name: 'Michigan' },
  { city: 'Marcellus', state: 'MI', state_name: 'Michigan' },
  { city: 'Marine City', state: 'MI', state_name: 'Michigan' },
  { city: 'Marlette', state: 'MI', state_name: 'Michigan' },
  { city: 'Marquette', state: 'MI', state_name: 'Michigan' },
  { city: 'Marquette County', state: 'MI', state_name: 'Michigan' },
  { city: 'Marshall', state: 'MI', state_name: 'Michigan' },
  { city: 'Marysville', state: 'MI', state_name: 'Michigan' },
  { city: 'Mason', state: 'MI', state_name: 'Michigan' },
  { city: 'Mason County', state: 'MI', state_name: 'Michigan' },
  { city: 'Mattawan', state: 'MI', state_name: 'Michigan' },
  { city: 'Mecosta County', state: 'MI', state_name: 'Michigan' },
  { city: 'Melvindale', state: 'MI', state_name: 'Michigan' },
  { city: 'Memphis', state: 'MI', state_name: 'Michigan' },
  { city: 'Menominee', state: 'MI', state_name: 'Michigan' },
  { city: 'Menominee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Michigan Center', state: 'MI', state_name: 'Michigan' },
  { city: 'Middleville', state: 'MI', state_name: 'Michigan' },
  { city: 'Midland', state: 'MI', state_name: 'Michigan' },
  { city: 'Midland County', state: 'MI', state_name: 'Michigan' },
  { city: 'Milan', state: 'MI', state_name: 'Michigan' },
  { city: 'Milford', state: 'MI', state_name: 'Michigan' },
  { city: 'Millington', state: 'MI', state_name: 'Michigan' },
  { city: 'Mio', state: 'MI', state_name: 'Michigan' },
  { city: 'Missaukee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Monroe', state: 'MI', state_name: 'Michigan' },
  { city: 'Monroe County', state: 'MI', state_name: 'Michigan' },
  { city: 'Montague', state: 'MI', state_name: 'Michigan' },
  { city: 'Montcalm County', state: 'MI', state_name: 'Michigan' },
  { city: 'Montmorency County', state: 'MI', state_name: 'Michigan' },
  { city: 'Montrose', state: 'MI', state_name: 'Michigan' },
  { city: 'Morenci', state: 'MI', state_name: 'Michigan' },
  { city: 'Mount Clemens', state: 'MI', state_name: 'Michigan' },
  { city: 'Mount Morris', state: 'MI', state_name: 'Michigan' },
  { city: 'Mount Pleasant', state: 'MI', state_name: 'Michigan' },
  { city: 'Munising', state: 'MI', state_name: 'Michigan' },
  { city: 'Muskegon', state: 'MI', state_name: 'Michigan' },
  { city: 'Muskegon County', state: 'MI', state_name: 'Michigan' },
  { city: 'Muskegon Heights', state: 'MI', state_name: 'Michigan' },
  { city: 'Napoleon', state: 'MI', state_name: 'Michigan' },
  { city: 'Nashville', state: 'MI', state_name: 'Michigan' },
  { city: 'Negaunee', state: 'MI', state_name: 'Michigan' },
  { city: 'New Baltimore', state: 'MI', state_name: 'Michigan' },
  { city: 'New Buffalo', state: 'MI', state_name: 'Michigan' },
  { city: 'New Haven', state: 'MI', state_name: 'Michigan' },
  { city: 'Newaygo', state: 'MI', state_name: 'Michigan' },
  { city: 'Newaygo County', state: 'MI', state_name: 'Michigan' },
  { city: 'Newberry', state: 'MI', state_name: 'Michigan' },
  { city: 'Niles', state: 'MI', state_name: 'Michigan' },
  { city: 'North Branch', state: 'MI', state_name: 'Michigan' },
  { city: 'North Muskegon', state: 'MI', state_name: 'Michigan' },
  { city: 'Northview', state: 'MI', state_name: 'Michigan' },
  { city: 'Northville', state: 'MI', state_name: 'Michigan' },
  { city: 'Norton Shores', state: 'MI', state_name: 'Michigan' },
  { city: 'Norway', state: 'MI', state_name: 'Michigan' },
  { city: 'Novi', state: 'MI', state_name: 'Michigan' },
  { city: 'Oak Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Oakland County', state: 'MI', state_name: 'Michigan' },
  { city: 'Oceana County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ogemaw County', state: 'MI', state_name: 'Michigan' },
  { city: 'Okemos', state: 'MI', state_name: 'Michigan' },
  { city: 'Olivet', state: 'MI', state_name: 'Michigan' },
  { city: 'Ontonagon', state: 'MI', state_name: 'Michigan' },
  { city: 'Ontonagon County', state: 'MI', state_name: 'Michigan' },
  { city: 'Orchard Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Ortonville', state: 'MI', state_name: 'Michigan' },
  { city: 'Osceola County', state: 'MI', state_name: 'Michigan' },
  { city: 'Oscoda County', state: 'MI', state_name: 'Michigan' },
  { city: 'Otsego', state: 'MI', state_name: 'Michigan' },
  { city: 'Otsego County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ottawa County', state: 'MI', state_name: 'Michigan' },
  { city: 'Ovid', state: 'MI', state_name: 'Michigan' },
  { city: 'Owosso', state: 'MI', state_name: 'Michigan' },
  { city: 'Oxford', state: 'MI', state_name: 'Michigan' },
  { city: 'Parchment', state: 'MI', state_name: 'Michigan' },
  { city: 'Paw Paw', state: 'MI', state_name: 'Michigan' },
  { city: 'Paw Paw Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Pearl Beach', state: 'MI', state_name: 'Michigan' },
  { city: 'Perry', state: 'MI', state_name: 'Michigan' },
  { city: 'Petersburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Petoskey', state: 'MI', state_name: 'Michigan' },
  { city: 'Pigeon', state: 'MI', state_name: 'Michigan' },
  { city: 'Pinckney', state: 'MI', state_name: 'Michigan' },
  { city: 'Pinconning', state: 'MI', state_name: 'Michigan' },
  { city: 'Plainwell', state: 'MI', state_name: 'Michigan' },
  { city: 'Pleasant Ridge', state: 'MI', state_name: 'Michigan' },
  { city: 'Plymouth', state: 'MI', state_name: 'Michigan' },
  { city: 'Pontiac', state: 'MI', state_name: 'Michigan' },
  { city: 'Port Huron', state: 'MI', state_name: 'Michigan' },
  { city: 'Portage', state: 'MI', state_name: 'Michigan' },
  { city: 'Portland', state: 'MI', state_name: 'Michigan' },
  { city: 'Potterville', state: 'MI', state_name: 'Michigan' },
  { city: 'Presque Isle County', state: 'MI', state_name: 'Michigan' },
  { city: 'Prudenville', state: 'MI', state_name: 'Michigan' },
  { city: 'Quincy', state: 'MI', state_name: 'Michigan' },
  { city: 'Quinnesec', state: 'MI', state_name: 'Michigan' },
  { city: 'Rapid City', state: 'MI', state_name: 'Michigan' },
  { city: 'Ravenna', state: 'MI', state_name: 'Michigan' },
  { city: 'Reading', state: 'MI', state_name: 'Michigan' },
  { city: 'Redford', state: 'MI', state_name: 'Michigan' },
  { city: 'Reed City', state: 'MI', state_name: 'Michigan' },
  { city: 'Reese', state: 'MI', state_name: 'Michigan' },
  { city: 'Richmond', state: 'MI', state_name: 'Michigan' },
  { city: 'River Rouge', state: 'MI', state_name: 'Michigan' },
  { city: 'Riverview', state: 'MI', state_name: 'Michigan' },
  { city: 'Rochester', state: 'MI', state_name: 'Michigan' },
  { city: 'Rochester Hills', state: 'MI', state_name: 'Michigan' },
  { city: 'Rockford', state: 'MI', state_name: 'Michigan' },
  { city: 'Rockwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Rogers City', state: 'MI', state_name: 'Michigan' },
  { city: 'Romeo', state: 'MI', state_name: 'Michigan' },
  { city: 'Romulus', state: 'MI', state_name: 'Michigan' },
  { city: 'Roosevelt Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Roscommon', state: 'MI', state_name: 'Michigan' },
  { city: 'Roscommon County', state: 'MI', state_name: 'Michigan' },
  { city: 'Roseville', state: 'MI', state_name: 'Michigan' },
  { city: 'Royal Oak', state: 'MI', state_name: 'Michigan' },
  { city: 'Saginaw', state: 'MI', state_name: 'Michigan' },
  { city: 'Saginaw County', state: 'MI', state_name: 'Michigan' },
  { city: 'Saginaw Township North', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Charles', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Clair', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Clair County', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Clair Shores', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Helen', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Ignace', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Johns', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Joseph', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Joseph County', state: 'MI', state_name: 'Michigan' },
  { city: 'Saint Louis', state: 'MI', state_name: 'Michigan' },
  { city: 'Saline', state: 'MI', state_name: 'Michigan' },
  { city: 'Sand Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Sandusky', state: 'MI', state_name: 'Michigan' },
  { city: 'Sanilac County', state: 'MI', state_name: 'Michigan' },
  { city: 'Saranac', state: 'MI', state_name: 'Michigan' },
  { city: 'Sault Ste. Marie', state: 'MI', state_name: 'Michigan' },
  { city: 'Schoolcraft', state: 'MI', state_name: 'Michigan' },
  { city: 'Schoolcraft County', state: 'MI', state_name: 'Michigan' },
  { city: 'Scottville', state: 'MI', state_name: 'Michigan' },
  { city: 'Sebewaing', state: 'MI', state_name: 'Michigan' },
  { city: 'Shelby', state: 'MI', state_name: 'Michigan' },
  { city: 'Shepherd', state: 'MI', state_name: 'Michigan' },
  { city: 'Shiawassee County', state: 'MI', state_name: 'Michigan' },
  { city: 'Shields', state: 'MI', state_name: 'Michigan' },
  {
    city: 'Shorewood-Tower Hills-Harbert',
    state: 'MI',
    state_name: 'Michigan',
  },
  { city: 'Skidway Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'South Gull Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'South Haven', state: 'MI', state_name: 'Michigan' },
  { city: 'South Lyon', state: 'MI', state_name: 'Michigan' },
  { city: 'South Monroe', state: 'MI', state_name: 'Michigan' },
  { city: 'South Rockwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Southfield', state: 'MI', state_name: 'Michigan' },
  { city: 'Southgate', state: 'MI', state_name: 'Michigan' },
  { city: 'Sparta', state: 'MI', state_name: 'Michigan' },
  { city: 'Spring Arbor', state: 'MI', state_name: 'Michigan' },
  { city: 'Spring Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Springfield', state: 'MI', state_name: 'Michigan' },
  { city: 'Stambaugh Iron River', state: 'MI', state_name: 'Michigan' },
  { city: 'Standish', state: 'MI', state_name: 'Michigan' },
  { city: 'Stanton', state: 'MI', state_name: 'Michigan' },
  { city: 'Sterling Heights', state: 'MI', state_name: 'Michigan' },
  { city: 'Stevensville', state: 'MI', state_name: 'Michigan' },
  { city: 'Stockbridge', state: 'MI', state_name: 'Michigan' },
  { city: 'Stony Point', state: 'MI', state_name: 'Michigan' },
  { city: 'Sturgis', state: 'MI', state_name: 'Michigan' },
  { city: 'Swartz Creek', state: 'MI', state_name: 'Michigan' },
  { city: 'Sylvan Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Tawas City', state: 'MI', state_name: 'Michigan' },
  { city: 'Taylor', state: 'MI', state_name: 'Michigan' },
  { city: 'Tecumseh', state: 'MI', state_name: 'Michigan' },
  { city: 'Temperance', state: 'MI', state_name: 'Michigan' },
  { city: 'Three Oaks', state: 'MI', state_name: 'Michigan' },
  { city: 'Three Rivers', state: 'MI', state_name: 'Michigan' },
  { city: 'Traverse City', state: 'MI', state_name: 'Michigan' },
  { city: 'Trenton', state: 'MI', state_name: 'Michigan' },
  { city: 'Trowbridge Park', state: 'MI', state_name: 'Michigan' },
  { city: 'Troy', state: 'MI', state_name: 'Michigan' },
  { city: 'Tuscola County', state: 'MI', state_name: 'Michigan' },
  { city: 'Twin Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Union City', state: 'MI', state_name: 'Michigan' },
  { city: 'Utica', state: 'MI', state_name: 'Michigan' },
  { city: 'Van Buren County', state: 'MI', state_name: 'Michigan' },
  { city: 'Vandercook Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Vassar', state: 'MI', state_name: 'Michigan' },
  { city: 'Vicksburg', state: 'MI', state_name: 'Michigan' },
  { city: 'Wacousta', state: 'MI', state_name: 'Michigan' },
  { city: 'Wakefield', state: 'MI', state_name: 'Michigan' },
  { city: 'Walker', state: 'MI', state_name: 'Michigan' },
  { city: 'Walled Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Warren', state: 'MI', state_name: 'Michigan' },
  { city: 'Washtenaw County', state: 'MI', state_name: 'Michigan' },
  { city: 'Waterford', state: 'MI', state_name: 'Michigan' },
  { city: 'Watervliet', state: 'MI', state_name: 'Michigan' },
  { city: 'Waverly', state: 'MI', state_name: 'Michigan' },
  { city: 'Wayland', state: 'MI', state_name: 'Michigan' },
  { city: 'Wayne', state: 'MI', state_name: 'Michigan' },
  { city: 'Wayne County', state: 'MI', state_name: 'Michigan' },
  { city: 'Webberville', state: 'MI', state_name: 'Michigan' },
  { city: 'West Bloomfield Township', state: 'MI', state_name: 'Michigan' },
  { city: 'West Branch', state: 'MI', state_name: 'Michigan' },
  { city: 'West Ishpeming', state: 'MI', state_name: 'Michigan' },
  { city: 'West Monroe', state: 'MI', state_name: 'Michigan' },
  { city: 'Westland', state: 'MI', state_name: 'Michigan' },
  { city: 'Westwood', state: 'MI', state_name: 'Michigan' },
  { city: 'Wexford County', state: 'MI', state_name: 'Michigan' },
  { city: 'White Cloud', state: 'MI', state_name: 'Michigan' },
  { city: 'White Pigeon', state: 'MI', state_name: 'Michigan' },
  { city: 'Whitehall', state: 'MI', state_name: 'Michigan' },
  { city: 'Whitmore Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Williamston', state: 'MI', state_name: 'Michigan' },
  { city: 'Wixom', state: 'MI', state_name: 'Michigan' },
  { city: 'Wolf Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Wolverine Lake', state: 'MI', state_name: 'Michigan' },
  { city: 'Woodhaven', state: 'MI', state_name: 'Michigan' },
  { city: 'Woodland Beach', state: 'MI', state_name: 'Michigan' },
  { city: 'Wyandotte', state: 'MI', state_name: 'Michigan' },
  { city: 'Wyoming', state: 'MI', state_name: 'Michigan' },
  { city: 'Yale', state: 'MI', state_name: 'Michigan' },
  { city: 'Ypsilanti', state: 'MI', state_name: 'Michigan' },
  { city: 'Zeeland', state: 'MI', state_name: 'Michigan' },
  { city: 'Zilwaukee', state: 'MI', state_name: 'Michigan' },
  { city: 'Ada', state: 'MN', state_name: 'Minnesota' },
  { city: 'Adrian', state: 'MN', state_name: 'Minnesota' },
  { city: 'Afton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Aitkin', state: 'MN', state_name: 'Minnesota' },
  { city: 'Aitkin County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Albany', state: 'MN', state_name: 'Minnesota' },
  { city: 'Albert Lea', state: 'MN', state_name: 'Minnesota' },
  { city: 'Albertville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Alexandria', state: 'MN', state_name: 'Minnesota' },
  { city: 'Andover', state: 'MN', state_name: 'Minnesota' },
  { city: 'Annandale', state: 'MN', state_name: 'Minnesota' },
  { city: 'Anoka', state: 'MN', state_name: 'Minnesota' },
  { city: 'Anoka County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Apple Valley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Appleton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Arden Hills', state: 'MN', state_name: 'Minnesota' },
  { city: 'Arlington', state: 'MN', state_name: 'Minnesota' },
  { city: 'Arnold', state: 'MN', state_name: 'Minnesota' },
  { city: 'Atwater', state: 'MN', state_name: 'Minnesota' },
  { city: 'Aurora', state: 'MN', state_name: 'Minnesota' },
  { city: 'Austin', state: 'MN', state_name: 'Minnesota' },
  { city: 'Avon', state: 'MN', state_name: 'Minnesota' },
  { city: 'Babbitt', state: 'MN', state_name: 'Minnesota' },
  { city: 'Bagley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Barnesville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Baudette', state: 'MN', state_name: 'Minnesota' },
  { city: 'Baxter', state: 'MN', state_name: 'Minnesota' },
  { city: 'Bayport', state: 'MN', state_name: 'Minnesota' },
  { city: 'Becker', state: 'MN', state_name: 'Minnesota' },
  { city: 'Becker County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Belle Plaine', state: 'MN', state_name: 'Minnesota' },
  { city: 'Beltrami County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Bemidji', state: 'MN', state_name: 'Minnesota' },
  { city: 'Benson', state: 'MN', state_name: 'Minnesota' },
  { city: 'Benton County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Big Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Big Stone County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Birchwood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Blaine', state: 'MN', state_name: 'Minnesota' },
  { city: 'Blooming Prairie', state: 'MN', state_name: 'Minnesota' },
  { city: 'Bloomington', state: 'MN', state_name: 'Minnesota' },
  { city: 'Blue Earth', state: 'MN', state_name: 'Minnesota' },
  { city: 'Blue Earth County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Braham', state: 'MN', state_name: 'Minnesota' },
  { city: 'Brainerd', state: 'MN', state_name: 'Minnesota' },
  { city: 'Branch', state: 'MN', state_name: 'Minnesota' },
  { city: 'Breckenridge', state: 'MN', state_name: 'Minnesota' },
  { city: 'Breezy Point', state: 'MN', state_name: 'Minnesota' },
  { city: 'Brooklyn Center', state: 'MN', state_name: 'Minnesota' },
  { city: 'Brooklyn Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Brown County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Buffalo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Burnsville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Byron', state: 'MN', state_name: 'Minnesota' },
  { city: 'Caledonia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cambridge', state: 'MN', state_name: 'Minnesota' },
  { city: 'Canby', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cannon Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Carlton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Carlton County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Carver', state: 'MN', state_name: 'Minnesota' },
  { city: 'Carver County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cass County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Center City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Centerville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Champlin', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chanhassen', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chaska', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chatfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chippewa County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chisago City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chisago County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Chisholm', state: 'MN', state_name: 'Minnesota' },
  { city: 'Circle Pines', state: 'MN', state_name: 'Minnesota' },
  { city: 'Clara City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Clay County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Clearwater', state: 'MN', state_name: 'Minnesota' },
  { city: 'Clearwater County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cloquet', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cohasset', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cokato', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cold Spring', state: 'MN', state_name: 'Minnesota' },
  { city: 'Coleraine', state: 'MN', state_name: 'Minnesota' },
  { city: 'Collegeville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cologne', state: 'MN', state_name: 'Minnesota' },
  { city: 'Columbia Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Columbus', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cook County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Coon Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'Corcoran', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cottage Grove', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cottonwood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cottonwood County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Crookston', state: 'MN', state_name: 'Minnesota' },
  { city: 'Crosby', state: 'MN', state_name: 'Minnesota' },
  { city: 'Cross Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Crow Wing County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Crystal', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dakota County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dassel', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dawson', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dayton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Deephaven', state: 'MN', state_name: 'Minnesota' },
  { city: 'Delano', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dellwood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Detroit Lakes', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dilworth', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dodge Center', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dodge County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Douglas County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Duluth', state: 'MN', state_name: 'Minnesota' },
  { city: 'Dundas', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eagan', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eagle Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'East Bethel', state: 'MN', state_name: 'Minnesota' },
  { city: 'East Grand Forks', state: 'MN', state_name: 'Minnesota' },
  { city: 'East Gull Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eden Prairie', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eden Valley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Edgerton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Edina', state: 'MN', state_name: 'Minnesota' },
  { city: 'Elbow Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Elgin', state: 'MN', state_name: 'Minnesota' },
  { city: 'Elk River', state: 'MN', state_name: 'Minnesota' },
  { city: 'Elko New Market', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ely', state: 'MN', state_name: 'Minnesota' },
  { city: 'Esko', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eveleth', state: 'MN', state_name: 'Minnesota' },
  { city: 'Excelsior', state: 'MN', state_name: 'Minnesota' },
  { city: 'Eyota', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fairfax', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fairmont', state: 'MN', state_name: 'Minnesota' },
  { city: 'Falcon Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Faribault', state: 'MN', state_name: 'Minnesota' },
  { city: 'Faribault County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Farmington', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fergus Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fillmore County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Foley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Forest Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fosston', state: 'MN', state_name: 'Minnesota' },
  { city: 'Frazee', state: 'MN', state_name: 'Minnesota' },
  { city: 'Freeborn County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fridley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Fulda', state: 'MN', state_name: 'Minnesota' },
  { city: 'Gaylord', state: 'MN', state_name: 'Minnesota' },
  { city: 'Gilbert', state: 'MN', state_name: 'Minnesota' },
  { city: 'Glencoe', state: 'MN', state_name: 'Minnesota' },
  { city: 'Glenwood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Glyndon', state: 'MN', state_name: 'Minnesota' },
  { city: 'Golden Valley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Goodhue', state: 'MN', state_name: 'Minnesota' },
  { city: 'Goodhue County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Goodview', state: 'MN', state_name: 'Minnesota' },
  { city: 'Grand Marais', state: 'MN', state_name: 'Minnesota' },
  { city: 'Grand Meadow', state: 'MN', state_name: 'Minnesota' },
  { city: 'Grand Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'Granite Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Grant', state: 'MN', state_name: 'Minnesota' },
  { city: 'Grant County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Greenfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hallock', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ham Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hanover', state: 'MN', state_name: 'Minnesota' },
  { city: 'Harris', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hastings', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hawley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hayfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hector', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hennepin County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hermantown', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hibbing', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hinckley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hopkins', state: 'MN', state_name: 'Minnesota' },
  { city: 'Houston County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Howard Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hoyt Lakes', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hubbard County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hugo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Hutchinson', state: 'MN', state_name: 'Minnesota' },
  { city: 'Independence', state: 'MN', state_name: 'Minnesota' },
  { city: 'International Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Inver Grove Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Isanti', state: 'MN', state_name: 'Minnesota' },
  { city: 'Isanti County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Itasca County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ivanhoe', state: 'MN', state_name: 'Minnesota' },
  { city: 'Jackson', state: 'MN', state_name: 'Minnesota' },
  { city: 'Jackson County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Janesville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Jordan', state: 'MN', state_name: 'Minnesota' },
  { city: 'Kanabec County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Kandiyohi County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Kasson', state: 'MN', state_name: 'Minnesota' },
  { city: 'Keewatin', state: 'MN', state_name: 'Minnesota' },
  { city: 'Kenyon', state: 'MN', state_name: 'Minnesota' },
  { city: 'Kittson County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Koochiching County', state: 'MN', state_name: 'Minnesota' },
  { city: 'La Crescent', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lac qui Parle County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake Crystal', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake Elmo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake of the Woods County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake Saint Croix Beach', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lake Shore', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lakefield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lakeland', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lakeville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lauderdale', state: 'MN', state_name: 'Minnesota' },
  { city: 'Le Center', state: 'MN', state_name: 'Minnesota' },
  { city: 'Le Sueur', state: 'MN', state_name: 'Minnesota' },
  { city: 'Le Sueur County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lester Prairie', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lewiston', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lexington', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lincoln County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lindstrom', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lino Lakes', state: 'MN', state_name: 'Minnesota' },
  { city: 'Litchfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Little Canada', state: 'MN', state_name: 'Minnesota' },
  { city: 'Little Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Little Rock', state: 'MN', state_name: 'Minnesota' },
  { city: 'Long Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Long Prairie', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lonsdale', state: 'MN', state_name: 'Minnesota' },
  { city: 'Luverne', state: 'MN', state_name: 'Minnesota' },
  { city: 'Lyon County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Madelia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Madison', state: 'MN', state_name: 'Minnesota' },
  { city: 'Madison Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mahnomen', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mahnomen County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mahtomedi', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mankato', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mantorville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Maple Grove', state: 'MN', state_name: 'Minnesota' },
  { city: 'Maple Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Maple Plain', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mapleton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Maplewood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Marshall', state: 'MN', state_name: 'Minnesota' },
  { city: 'Marshall County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Martin County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mayer', state: 'MN', state_name: 'Minnesota' },
  { city: 'McLeod County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Medford', state: 'MN', state_name: 'Minnesota' },
  { city: 'Medina', state: 'MN', state_name: 'Minnesota' },
  { city: 'Meeker County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Melrose', state: 'MN', state_name: 'Minnesota' },
  { city: 'Menahga', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mendota Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Milaca', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mille Lacs County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Minneapolis', state: 'MN', state_name: 'Minnesota' },
  { city: 'Minneota', state: 'MN', state_name: 'Minnesota' },
  { city: 'Minnetonka', state: 'MN', state_name: 'Minnesota' },
  { city: 'Minnetonka Mills', state: 'MN', state_name: 'Minnesota' },
  { city: 'Minnetrista', state: 'MN', state_name: 'Minnesota' },
  { city: 'Montevideo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Montgomery', state: 'MN', state_name: 'Minnesota' },
  { city: 'Monticello', state: 'MN', state_name: 'Minnesota' },
  { city: 'Montrose', state: 'MN', state_name: 'Minnesota' },
  { city: 'Moorhead', state: 'MN', state_name: 'Minnesota' },
  { city: 'Moose Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mora', state: 'MN', state_name: 'Minnesota' },
  { city: 'Morris', state: 'MN', state_name: 'Minnesota' },
  { city: 'Morrison County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mound', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mounds View', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mountain Iron', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mountain Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Mower County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Murray County', state: 'MN', state_name: 'Minnesota' },
  { city: 'New Brighton', state: 'MN', state_name: 'Minnesota' },
  { city: 'New Hope', state: 'MN', state_name: 'Minnesota' },
  { city: 'New London', state: 'MN', state_name: 'Minnesota' },
  { city: 'New Prague', state: 'MN', state_name: 'Minnesota' },
  { city: 'New Richland', state: 'MN', state_name: 'Minnesota' },
  { city: 'New Ulm', state: 'MN', state_name: 'Minnesota' },
  { city: 'New York Mills', state: 'MN', state_name: 'Minnesota' },
  { city: 'Newport', state: 'MN', state_name: 'Minnesota' },
  { city: 'Nicollet', state: 'MN', state_name: 'Minnesota' },
  { city: 'Nicollet County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Nisswa', state: 'MN', state_name: 'Minnesota' },
  { city: 'Nobles County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Norman County', state: 'MN', state_name: 'Minnesota' },
  { city: 'North Branch', state: 'MN', state_name: 'Minnesota' },
  { city: 'North Mankato', state: 'MN', state_name: 'Minnesota' },
  { city: 'North Oaks', state: 'MN', state_name: 'Minnesota' },
  { city: 'North Saint Paul', state: 'MN', state_name: 'Minnesota' },
  { city: 'Northfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Norwood (historical)', state: 'MN', state_name: 'Minnesota' },
  { city: 'Norwood Young America', state: 'MN', state_name: 'Minnesota' },
  { city: 'Nowthen', state: 'MN', state_name: 'Minnesota' },
  { city: 'Oak Grove', state: 'MN', state_name: 'Minnesota' },
  { city: 'Oak Park Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Oakdale', state: 'MN', state_name: 'Minnesota' },
  { city: 'Oakport', state: 'MN', state_name: 'Minnesota' },
  { city: 'Olivia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Olmsted County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Orono', state: 'MN', state_name: 'Minnesota' },
  { city: 'Oronoco', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ortonville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Osakis', state: 'MN', state_name: 'Minnesota' },
  { city: 'Osseo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Otsego', state: 'MN', state_name: 'Minnesota' },
  { city: 'Otter Tail County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Owatonna', state: 'MN', state_name: 'Minnesota' },
  { city: 'Park Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'Parkers Prairie', state: 'MN', state_name: 'Minnesota' },
  { city: 'Parkville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Paynesville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pelican Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pennington County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pequot Lakes', state: 'MN', state_name: 'Minnesota' },
  { city: 'Perham', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pierz', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pine City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pine County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pine Island', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pipestone', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pipestone County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Plainview', state: 'MN', state_name: 'Minnesota' },
  { city: 'Plymouth', state: 'MN', state_name: 'Minnesota' },
  { city: 'Polk County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Pope County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Preston', state: 'MN', state_name: 'Minnesota' },
  { city: 'Princeton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Prior Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Proctor', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ramsey', state: 'MN', state_name: 'Minnesota' },
  { city: 'Ramsey County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Red Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Red Lake County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Red Lake Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Red Wing', state: 'MN', state_name: 'Minnesota' },
  { city: 'Redby', state: 'MN', state_name: 'Minnesota' },
  { city: 'Redwood County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Redwood Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Renville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Renville County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rice', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rice County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Richfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Richmond', state: 'MN', state_name: 'Minnesota' },
  { city: 'Robbinsdale', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rochester', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rock County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rock Creek', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rockford', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rockville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rogers', state: 'MN', state_name: 'Minnesota' },
  { city: 'Roseau', state: 'MN', state_name: 'Minnesota' },
  { city: 'Roseau County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rosemount', state: 'MN', state_name: 'Minnesota' },
  { city: 'Roseville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Royalton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rush City', state: 'MN', state_name: 'Minnesota' },
  { city: 'Rushford', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Anthony', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Augusta', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Bonifacius', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Charles', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Cloud', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Francis', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint James', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Joseph', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Louis County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Louis Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Michael', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Paul', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Paul Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Saint Peter', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sandstone', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sartell', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sauk Centre', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sauk Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'Savage', state: 'MN', state_name: 'Minnesota' },
  { city: 'Scandia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Scott County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Shafer', state: 'MN', state_name: 'Minnesota' },
  { city: 'Shakopee', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sherburn', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sherburne County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Shoreview', state: 'MN', state_name: 'Minnesota' },
  { city: 'Shorewood', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sibley County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Silver Bay', state: 'MN', state_name: 'Minnesota' },
  { city: 'Slayton', state: 'MN', state_name: 'Minnesota' },
  { city: 'Sleepy Eye', state: 'MN', state_name: 'Minnesota' },
  { city: 'South Saint Paul', state: 'MN', state_name: 'Minnesota' },
  { city: 'Spicer', state: 'MN', state_name: 'Minnesota' },
  { city: 'Spring Grove', state: 'MN', state_name: 'Minnesota' },
  { city: 'Spring Lake Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Spring Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Spring Valley', state: 'MN', state_name: 'Minnesota' },
  { city: 'Springfield', state: 'MN', state_name: 'Minnesota' },
  { city: 'Stacy', state: 'MN', state_name: 'Minnesota' },
  { city: 'Staples', state: 'MN', state_name: 'Minnesota' },
  { city: 'Starbuck', state: 'MN', state_name: 'Minnesota' },
  { city: 'Stearns County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Steele County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Stevens County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Stewartville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Stillwater', state: 'MN', state_name: 'Minnesota' },
  { city: 'Swift County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Taylors Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Thief River Falls', state: 'MN', state_name: 'Minnesota' },
  { city: 'Todd County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Tonka Bay', state: 'MN', state_name: 'Minnesota' },
  { city: 'Tracy', state: 'MN', state_name: 'Minnesota' },
  { city: 'Traverse County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Truman', state: 'MN', state_name: 'Minnesota' },
  { city: 'Two Harbors', state: 'MN', state_name: 'Minnesota' },
  { city: 'Tyler', state: 'MN', state_name: 'Minnesota' },
  { city: 'Vadnais Heights', state: 'MN', state_name: 'Minnesota' },
  { city: 'Victoria', state: 'MN', state_name: 'Minnesota' },
  { city: 'Vineland', state: 'MN', state_name: 'Minnesota' },
  { city: 'Virginia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wabasha', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wabasha County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waconia', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wadena', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wadena County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waite Park', state: 'MN', state_name: 'Minnesota' },
  { city: 'Walker', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wanamingo', state: 'MN', state_name: 'Minnesota' },
  { city: 'Warren', state: 'MN', state_name: 'Minnesota' },
  { city: 'Warroad', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waseca', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waseca County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Washington County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Watertown', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waterville', state: 'MN', state_name: 'Minnesota' },
  { city: 'Watonwan County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Waverly', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wayzata', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wells', state: 'MN', state_name: 'Minnesota' },
  { city: 'West Coon Rapids', state: 'MN', state_name: 'Minnesota' },
  { city: 'West Saint Paul', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wheaton', state: 'MN', state_name: 'Minnesota' },
  { city: 'White Bear Lake', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wilkin County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Willmar', state: 'MN', state_name: 'Minnesota' },
  { city: 'Windom', state: 'MN', state_name: 'Minnesota' },
  { city: 'Winnebago', state: 'MN', state_name: 'Minnesota' },
  { city: 'Winona', state: 'MN', state_name: 'Minnesota' },
  { city: 'Winona County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Winsted', state: 'MN', state_name: 'Minnesota' },
  { city: 'Winthrop', state: 'MN', state_name: 'Minnesota' },
  { city: 'Woodbury', state: 'MN', state_name: 'Minnesota' },
  { city: 'Worthington', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wright County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Wyoming', state: 'MN', state_name: 'Minnesota' },
  { city: 'Yellow Medicine County', state: 'MN', state_name: 'Minnesota' },
  { city: 'Young America (historical)', state: 'MN', state_name: 'Minnesota' },
  { city: 'Zimmerman', state: 'MN', state_name: 'Minnesota' },
  { city: 'Zumbrota', state: 'MN', state_name: 'Minnesota' },
  { city: 'Aberdeen', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ackerman', state: 'MS', state_name: 'Mississippi' },
  { city: 'Adams County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Alcorn County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Amite County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Amory', state: 'MS', state_name: 'Mississippi' },
  { city: 'Arnold Line', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ashland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Attala County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Baldwyn', state: 'MS', state_name: 'Mississippi' },
  { city: 'Batesville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Bay Saint Louis', state: 'MS', state_name: 'Mississippi' },
  { city: 'Bay Springs', state: 'MS', state_name: 'Mississippi' },
  { city: 'Beechwood', state: 'MS', state_name: 'Mississippi' },
  { city: 'Belmont', state: 'MS', state_name: 'Mississippi' },
  { city: 'Belzoni', state: 'MS', state_name: 'Mississippi' },
  { city: 'Benton County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Biloxi', state: 'MS', state_name: 'Mississippi' },
  { city: 'Bolivar County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Booneville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Brandon', state: 'MS', state_name: 'Mississippi' },
  { city: 'Brookhaven', state: 'MS', state_name: 'Mississippi' },
  { city: 'Brooksville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Bruce', state: 'MS', state_name: 'Mississippi' },
  { city: 'Bude', state: 'MS', state_name: 'Mississippi' },
  { city: 'Byhalia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Byram', state: 'MS', state_name: 'Mississippi' },
  { city: 'Caledonia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Calhoun City', state: 'MS', state_name: 'Mississippi' },
  { city: 'Calhoun County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Canton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Carriere', state: 'MS', state_name: 'Mississippi' },
  { city: 'Carroll County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Carrollton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Carthage', state: 'MS', state_name: 'Mississippi' },
  { city: 'Centreville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Charleston', state: 'MS', state_name: 'Mississippi' },
  { city: 'Chickasaw County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Choctaw County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Claiborne County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Clarke County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Clarksdale', state: 'MS', state_name: 'Mississippi' },
  { city: 'Clay County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Cleary', state: 'MS', state_name: 'Mississippi' },
  { city: 'Cleveland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Clinton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Coahoma County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Coldwater', state: 'MS', state_name: 'Mississippi' },
  { city: 'Collins', state: 'MS', state_name: 'Mississippi' },
  { city: 'Collinsville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Columbia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Columbus', state: 'MS', state_name: 'Mississippi' },
  { city: 'Columbus Air Force Base', state: 'MS', state_name: 'Mississippi' },
  { city: 'Como', state: 'MS', state_name: 'Mississippi' },
  { city: 'Conehatta', state: 'MS', state_name: 'Mississippi' },
  { city: 'Copiah County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Corinth', state: 'MS', state_name: 'Mississippi' },
  { city: 'Covington County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Crystal Springs', state: 'MS', state_name: 'Mississippi' },
  { city: "D'Iberville", state: 'MS', state_name: 'Mississippi' },
  { city: 'De Kalb', state: 'MS', state_name: 'Mississippi' },
  { city: 'De Lisle', state: 'MS', state_name: 'Mississippi' },
  { city: 'De Soto County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Decatur', state: 'MS', state_name: 'Mississippi' },
  { city: 'Derma', state: 'MS', state_name: 'Mississippi' },
  { city: 'Diamondhead', state: 'MS', state_name: 'Mississippi' },
  { city: 'Drew', state: 'MS', state_name: 'Mississippi' },
  { city: 'Duck Hill', state: 'MS', state_name: 'Mississippi' },
  { city: 'Durant', state: 'MS', state_name: 'Mississippi' },
  { city: 'Edwards', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ellisville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Escatawpa', state: 'MS', state_name: 'Mississippi' },
  { city: 'Eupora', state: 'MS', state_name: 'Mississippi' },
  { city: 'Farmington', state: 'MS', state_name: 'Mississippi' },
  { city: 'Fayette', state: 'MS', state_name: 'Mississippi' },
  { city: 'Flora', state: 'MS', state_name: 'Mississippi' },
  { city: 'Florence', state: 'MS', state_name: 'Mississippi' },
  { city: 'Flowood', state: 'MS', state_name: 'Mississippi' },
  { city: 'Forest', state: 'MS', state_name: 'Mississippi' },
  { city: 'Forrest County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Franklin County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Friars Point', state: 'MS', state_name: 'Mississippi' },
  { city: 'Fulton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Gautier', state: 'MS', state_name: 'Mississippi' },
  { city: 'George County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Glendale', state: 'MS', state_name: 'Mississippi' },
  { city: 'Goodman', state: 'MS', state_name: 'Mississippi' },
  { city: 'Greene County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Greenville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Greenwood', state: 'MS', state_name: 'Mississippi' },
  { city: 'Grenada', state: 'MS', state_name: 'Mississippi' },
  { city: 'Grenada County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Gulf Hills', state: 'MS', state_name: 'Mississippi' },
  { city: 'Gulf Park Estates', state: 'MS', state_name: 'Mississippi' },
  { city: 'Gulfport', state: 'MS', state_name: 'Mississippi' },
  { city: 'Guntown', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hancock County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Harrison County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hattiesburg', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hazlehurst', state: 'MS', state_name: 'Mississippi' },
  { city: 'Helena', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hernando', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hickory Hills', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hide-A-Way Lake', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hillsboro', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hinds County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hollandale', state: 'MS', state_name: 'Mississippi' },
  { city: 'Holly Springs', state: 'MS', state_name: 'Mississippi' },
  { city: 'Holmes County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Horn Lake', state: 'MS', state_name: 'Mississippi' },
  { city: 'Houston', state: 'MS', state_name: 'Mississippi' },
  { city: 'Humphreys County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Hurley', state: 'MS', state_name: 'Mississippi' },
  { city: 'Indianola', state: 'MS', state_name: 'Mississippi' },
  { city: 'Issaquena County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Itawamba County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Itta Bena', state: 'MS', state_name: 'Mississippi' },
  { city: 'Iuka', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jackson', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jackson County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jasper County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jefferson County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jefferson Davis County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jones County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Jonestown', state: 'MS', state_name: 'Mississippi' },
  { city: 'Kearney Park', state: 'MS', state_name: 'Mississippi' },
  { city: 'Kemper County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Kiln', state: 'MS', state_name: 'Mississippi' },
  { city: 'Kosciusko', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lafayette County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lamar County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lambert', state: 'MS', state_name: 'Mississippi' },
  { city: 'Latimer', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lauderdale County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Laurel', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lawrence County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Leake County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Leakesville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lee County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Leflore County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Leland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lexington', state: 'MS', state_name: 'Mississippi' },
  { city: 'Liberty', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lincoln County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Long Beach', state: 'MS', state_name: 'Mississippi' },
  { city: 'Louisville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lowndes County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lucedale', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lumberton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lyman', state: 'MS', state_name: 'Mississippi' },
  { city: 'Lynchburg', state: 'MS', state_name: 'Mississippi' },
  { city: 'Macon', state: 'MS', state_name: 'Mississippi' },
  { city: 'Madison', state: 'MS', state_name: 'Mississippi' },
  { city: 'Madison County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Magee', state: 'MS', state_name: 'Mississippi' },
  { city: 'Magnolia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Mantachie', state: 'MS', state_name: 'Mississippi' },
  { city: 'Marion', state: 'MS', state_name: 'Mississippi' },
  { city: 'Marion County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Marks', state: 'MS', state_name: 'Mississippi' },
  { city: 'Marshall County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Mayersville', state: 'MS', state_name: 'Mississippi' },
  { city: 'McComb', state: 'MS', state_name: 'Mississippi' },
  { city: 'Meadville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Mendenhall', state: 'MS', state_name: 'Mississippi' },
  { city: 'Meridian', state: 'MS', state_name: 'Mississippi' },
  { city: 'Meridian Station', state: 'MS', state_name: 'Mississippi' },
  { city: 'Metcalfe', state: 'MS', state_name: 'Mississippi' },
  { city: 'Mikoma', state: 'MS', state_name: 'Mississippi' },
  { city: 'Monroe County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Montgomery County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Monticello', state: 'MS', state_name: 'Mississippi' },
  { city: 'Moorhead', state: 'MS', state_name: 'Mississippi' },
  { city: 'Morgantown', state: 'MS', state_name: 'Mississippi' },
  { city: 'Morton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Moss Point', state: 'MS', state_name: 'Mississippi' },
  { city: 'Mound Bayou', state: 'MS', state_name: 'Mississippi' },
  { city: 'Natchez', state: 'MS', state_name: 'Mississippi' },
  { city: 'Nellieburg', state: 'MS', state_name: 'Mississippi' },
  { city: 'Neshoba County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Nettleton', state: 'MS', state_name: 'Mississippi' },
  { city: 'New Albany', state: 'MS', state_name: 'Mississippi' },
  { city: 'New Augusta', state: 'MS', state_name: 'Mississippi' },
  { city: 'New Hope', state: 'MS', state_name: 'Mississippi' },
  { city: 'Newton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Newton County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Nicholson', state: 'MS', state_name: 'Mississippi' },
  { city: 'North Tunica', state: 'MS', state_name: 'Mississippi' },
  { city: 'Noxubee County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ocean Springs', state: 'MS', state_name: 'Mississippi' },
  { city: 'Okolona', state: 'MS', state_name: 'Mississippi' },
  { city: 'Oktibbeha County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Olive Branch', state: 'MS', state_name: 'Mississippi' },
  { city: 'Oxford', state: 'MS', state_name: 'Mississippi' },
  { city: 'Panola County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pascagoula', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pass Christian', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pearl', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pearl River', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pearl River County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pearlington', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pelahatchie', state: 'MS', state_name: 'Mississippi' },
  { city: 'Perry County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Petal', state: 'MS', state_name: 'Mississippi' },
  { city: 'Philadelphia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Picayune', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pickens', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pike County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pittsboro', state: 'MS', state_name: 'Mississippi' },
  { city: 'Plantersville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pontotoc', state: 'MS', state_name: 'Mississippi' },
  { city: 'Pontotoc County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Poplarville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Port Gibson', state: 'MS', state_name: 'Mississippi' },
  { city: 'Prentiss', state: 'MS', state_name: 'Mississippi' },
  { city: 'Prentiss County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Purvis', state: 'MS', state_name: 'Mississippi' },
  { city: 'Quitman', state: 'MS', state_name: 'Mississippi' },
  { city: 'Quitman County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Raleigh', state: 'MS', state_name: 'Mississippi' },
  { city: 'Rankin County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Rawls Springs', state: 'MS', state_name: 'Mississippi' },
  { city: 'Raymond', state: 'MS', state_name: 'Mississippi' },
  { city: 'Richland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Richton', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ridgeland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ripley', state: 'MS', state_name: 'Mississippi' },
  { city: 'Rolling Fork', state: 'MS', state_name: 'Mississippi' },
  { city: 'Rosedale', state: 'MS', state_name: 'Mississippi' },
  { city: 'Ruleville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Saint Martin', state: 'MS', state_name: 'Mississippi' },
  { city: 'Saltillo', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sardis', state: 'MS', state_name: 'Mississippi' },
  { city: 'Saucier', state: 'MS', state_name: 'Mississippi' },
  { city: 'Scott County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Senatobia', state: 'MS', state_name: 'Mississippi' },
  { city: 'Shannon', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sharkey County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sharon', state: 'MS', state_name: 'Mississippi' },
  { city: 'Shaw', state: 'MS', state_name: 'Mississippi' },
  { city: 'Shelby', state: 'MS', state_name: 'Mississippi' },
  { city: 'Simpson County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Smith County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Southaven', state: 'MS', state_name: 'Mississippi' },
  { city: 'Starkville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Stone County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Stonewall', state: 'MS', state_name: 'Mississippi' },
  { city: 'Summit', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sumrall', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sunflower', state: 'MS', state_name: 'Mississippi' },
  { city: 'Sunflower County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tallahatchie County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tate County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Taylorsville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tchula', state: 'MS', state_name: 'Mississippi' },
  { city: 'Terry', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tippah County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tishomingo County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tunica', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tunica County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tunica Resorts', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tupelo', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tutwiler', state: 'MS', state_name: 'Mississippi' },
  { city: 'Tylertown', state: 'MS', state_name: 'Mississippi' },
  { city: 'Union', state: 'MS', state_name: 'Mississippi' },
  { city: 'Union County', state: 'MS', state_name: 'Mississippi' },
  { city: 'University', state: 'MS', state_name: 'Mississippi' },
  { city: 'Vancleave', state: 'MS', state_name: 'Mississippi' },
  { city: 'Vardaman', state: 'MS', state_name: 'Mississippi' },
  { city: 'Verona', state: 'MS', state_name: 'Mississippi' },
  { city: 'Vicksburg', state: 'MS', state_name: 'Mississippi' },
  { city: 'Wade', state: 'MS', state_name: 'Mississippi' },
  { city: 'Walls', state: 'MS', state_name: 'Mississippi' },
  { city: 'Walnut Grove', state: 'MS', state_name: 'Mississippi' },
  { city: 'Walthall', state: 'MS', state_name: 'Mississippi' },
  { city: 'Walthall County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Warren County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Washington County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Water Valley', state: 'MS', state_name: 'Mississippi' },
  { city: 'Waveland', state: 'MS', state_name: 'Mississippi' },
  { city: 'Wayne County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Waynesboro', state: 'MS', state_name: 'Mississippi' },
  { city: 'Webster County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Wesson', state: 'MS', state_name: 'Mississippi' },
  { city: 'West Gulfport', state: 'MS', state_name: 'Mississippi' },
  { city: 'West Hattiesburg', state: 'MS', state_name: 'Mississippi' },
  { city: 'West Point', state: 'MS', state_name: 'Mississippi' },
  { city: 'Wiggins', state: 'MS', state_name: 'Mississippi' },
  { city: 'Wilkinson County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Winona', state: 'MS', state_name: 'Mississippi' },
  { city: 'Winston County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Woodville', state: 'MS', state_name: 'Mississippi' },
  { city: 'Yalobusha County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Yazoo City', state: 'MS', state_name: 'Mississippi' },
  { city: 'Yazoo County', state: 'MS', state_name: 'Mississippi' },
  { city: 'Adair County', state: 'MO', state_name: 'Missouri' },
  { city: 'Adrian', state: 'MO', state_name: 'Missouri' },
  { city: 'Advance', state: 'MO', state_name: 'Missouri' },
  { city: 'Affton', state: 'MO', state_name: 'Missouri' },
  { city: 'Albany', state: 'MO', state_name: 'Missouri' },
  { city: 'Alton', state: 'MO', state_name: 'Missouri' },
  { city: 'Anderson', state: 'MO', state_name: 'Missouri' },
  { city: 'Andrew County', state: 'MO', state_name: 'Missouri' },
  { city: 'Appleton City', state: 'MO', state_name: 'Missouri' },
  { city: 'Archie', state: 'MO', state_name: 'Missouri' },
  { city: 'Arnold', state: 'MO', state_name: 'Missouri' },
  { city: 'Ash Grove', state: 'MO', state_name: 'Missouri' },
  { city: 'Ashland', state: 'MO', state_name: 'Missouri' },
  { city: 'Atchison County', state: 'MO', state_name: 'Missouri' },
  { city: 'Audrain County', state: 'MO', state_name: 'Missouri' },
  { city: 'Aurora', state: 'MO', state_name: 'Missouri' },
  { city: 'Ava', state: 'MO', state_name: 'Missouri' },
  { city: 'Ballwin', state: 'MO', state_name: 'Missouri' },
  { city: 'Barnhart', state: 'MO', state_name: 'Missouri' },
  { city: 'Barry County', state: 'MO', state_name: 'Missouri' },
  { city: 'Barton County', state: 'MO', state_name: 'Missouri' },
  { city: 'Bates County', state: 'MO', state_name: 'Missouri' },
  { city: 'Battlefield', state: 'MO', state_name: 'Missouri' },
  { city: 'Bel-Nor', state: 'MO', state_name: 'Missouri' },
  { city: 'Bel-Ridge', state: 'MO', state_name: 'Missouri' },
  { city: 'Belle', state: 'MO', state_name: 'Missouri' },
  { city: 'Bellefontaine Neighbors', state: 'MO', state_name: 'Missouri' },
  { city: 'Belton', state: 'MO', state_name: 'Missouri' },
  { city: 'Benton', state: 'MO', state_name: 'Missouri' },
  { city: 'Benton County', state: 'MO', state_name: 'Missouri' },
  { city: 'Berkeley', state: 'MO', state_name: 'Missouri' },
  { city: 'Bernie', state: 'MO', state_name: 'Missouri' },
  { city: 'Bethany', state: 'MO', state_name: 'Missouri' },
  { city: 'Billings', state: 'MO', state_name: 'Missouri' },
  { city: 'Bismarck', state: 'MO', state_name: 'Missouri' },
  { city: 'Black Jack', state: 'MO', state_name: 'Missouri' },
  { city: 'Bloomfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Blue Springs', state: 'MO', state_name: 'Missouri' },
  { city: 'Bolivar', state: 'MO', state_name: 'Missouri' },
  { city: 'Bollinger County', state: 'MO', state_name: 'Missouri' },
  { city: 'Bonne Terre', state: 'MO', state_name: 'Missouri' },
  { city: 'Boone County', state: 'MO', state_name: 'Missouri' },
  { city: 'Boonville', state: 'MO', state_name: 'Missouri' },
  { city: 'Bourbon', state: 'MO', state_name: 'Missouri' },
  { city: 'Bowling Green', state: 'MO', state_name: 'Missouri' },
  { city: 'Branson', state: 'MO', state_name: 'Missouri' },
  { city: 'Breckenridge Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Brentwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Bridgeton', state: 'MO', state_name: 'Missouri' },
  { city: 'Brookfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Buchanan County', state: 'MO', state_name: 'Missouri' },
  { city: 'Buckner', state: 'MO', state_name: 'Missouri' },
  { city: 'Buffalo', state: 'MO', state_name: 'Missouri' },
  { city: 'Butler', state: 'MO', state_name: 'Missouri' },
  { city: 'Butler County', state: 'MO', state_name: 'Missouri' },
  { city: 'Byrnes Mill', state: 'MO', state_name: 'Missouri' },
  { city: 'Cabool', state: 'MO', state_name: 'Missouri' },
  { city: 'Caldwell County', state: 'MO', state_name: 'Missouri' },
  { city: 'California', state: 'MO', state_name: 'Missouri' },
  { city: 'Callaway County', state: 'MO', state_name: 'Missouri' },
  { city: 'Calverton Park', state: 'MO', state_name: 'Missouri' },
  { city: 'Camden County', state: 'MO', state_name: 'Missouri' },
  { city: 'Camdenton', state: 'MO', state_name: 'Missouri' },
  { city: 'Cameron', state: 'MO', state_name: 'Missouri' },
  { city: 'Campbell', state: 'MO', state_name: 'Missouri' },
  { city: 'Canton', state: 'MO', state_name: 'Missouri' },
  { city: 'Cape Girardeau', state: 'MO', state_name: 'Missouri' },
  { city: 'Cape Girardeau County', state: 'MO', state_name: 'Missouri' },
  { city: 'Carl Junction', state: 'MO', state_name: 'Missouri' },
  { city: 'Carroll County', state: 'MO', state_name: 'Missouri' },
  { city: 'Carrollton', state: 'MO', state_name: 'Missouri' },
  { city: 'Carter County', state: 'MO', state_name: 'Missouri' },
  { city: 'Carterville', state: 'MO', state_name: 'Missouri' },
  { city: 'Carthage', state: 'MO', state_name: 'Missouri' },
  { city: 'Caruthersville', state: 'MO', state_name: 'Missouri' },
  { city: 'Cass County', state: 'MO', state_name: 'Missouri' },
  { city: 'Cassville', state: 'MO', state_name: 'Missouri' },
  { city: 'Castle Point', state: 'MO', state_name: 'Missouri' },
  { city: 'Cedar County', state: 'MO', state_name: 'Missouri' },
  { city: 'Cedar Hill', state: 'MO', state_name: 'Missouri' },
  { city: 'Centerville', state: 'MO', state_name: 'Missouri' },
  { city: 'Centralia', state: 'MO', state_name: 'Missouri' },
  { city: 'Chaffee', state: 'MO', state_name: 'Missouri' },
  { city: 'Chariton County', state: 'MO', state_name: 'Missouri' },
  { city: 'Charlack', state: 'MO', state_name: 'Missouri' },
  { city: 'Charleston', state: 'MO', state_name: 'Missouri' },
  { city: 'Chesterfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Chillicothe', state: 'MO', state_name: 'Missouri' },
  { city: 'Christian County', state: 'MO', state_name: 'Missouri' },
  { city: 'City of Saint Louis', state: 'MO', state_name: 'Missouri' },
  { city: 'Clark County', state: 'MO', state_name: 'Missouri' },
  { city: 'Clarkson Valley', state: 'MO', state_name: 'Missouri' },
  { city: 'Clarkton', state: 'MO', state_name: 'Missouri' },
  { city: 'Clay County', state: 'MO', state_name: 'Missouri' },
  { city: 'Claycomo', state: 'MO', state_name: 'Missouri' },
  { city: 'Clayton', state: 'MO', state_name: 'Missouri' },
  { city: 'Clever', state: 'MO', state_name: 'Missouri' },
  { city: 'Clinton', state: 'MO', state_name: 'Missouri' },
  { city: 'Clinton County', state: 'MO', state_name: 'Missouri' },
  { city: 'Cole Camp', state: 'MO', state_name: 'Missouri' },
  { city: 'Cole County', state: 'MO', state_name: 'Missouri' },
  { city: 'Columbia', state: 'MO', state_name: 'Missouri' },
  { city: 'Concord', state: 'MO', state_name: 'Missouri' },
  { city: 'Concordia', state: 'MO', state_name: 'Missouri' },
  { city: 'Cool Valley', state: 'MO', state_name: 'Missouri' },
  { city: 'Cooper County', state: 'MO', state_name: 'Missouri' },
  { city: 'Cottleville', state: 'MO', state_name: 'Missouri' },
  { city: 'Country Club Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Country Club Village', state: 'MO', state_name: 'Missouri' },
  { city: 'Crane', state: 'MO', state_name: 'Missouri' },
  { city: 'Crawford County', state: 'MO', state_name: 'Missouri' },
  { city: 'Crestwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Creve Coeur', state: 'MO', state_name: 'Missouri' },
  { city: 'Crocker', state: 'MO', state_name: 'Missouri' },
  { city: 'Crystal City', state: 'MO', state_name: 'Missouri' },
  { city: 'Cuba', state: 'MO', state_name: 'Missouri' },
  { city: 'Dade County', state: 'MO', state_name: 'Missouri' },
  { city: 'Dallas County', state: 'MO', state_name: 'Missouri' },
  { city: 'Dardenne Prairie', state: 'MO', state_name: 'Missouri' },
  { city: 'Daviess County', state: 'MO', state_name: 'Missouri' },
  { city: 'De Soto', state: 'MO', state_name: 'Missouri' },
  { city: 'DeKalb County', state: 'MO', state_name: 'Missouri' },
  { city: 'Dellwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Dent County', state: 'MO', state_name: 'Missouri' },
  { city: 'Des Peres', state: 'MO', state_name: 'Missouri' },
  { city: 'Desloge', state: 'MO', state_name: 'Missouri' },
  { city: 'Dexter', state: 'MO', state_name: 'Missouri' },
  { city: 'Dixon', state: 'MO', state_name: 'Missouri' },
  { city: 'Doniphan', state: 'MO', state_name: 'Missouri' },
  { city: 'Douglas County', state: 'MO', state_name: 'Missouri' },
  { city: 'Duenweg', state: 'MO', state_name: 'Missouri' },
  { city: 'Dunklin County', state: 'MO', state_name: 'Missouri' },
  { city: 'Duquesne', state: 'MO', state_name: 'Missouri' },
  { city: 'East Independence', state: 'MO', state_name: 'Missouri' },
  { city: 'East Prairie', state: 'MO', state_name: 'Missouri' },
  { city: 'Edina', state: 'MO', state_name: 'Missouri' },
  { city: 'El Dorado Springs', state: 'MO', state_name: 'Missouri' },
  { city: 'Eldon', state: 'MO', state_name: 'Missouri' },
  { city: 'Ellisville', state: 'MO', state_name: 'Missouri' },
  { city: 'Elsberry', state: 'MO', state_name: 'Missouri' },
  { city: 'Elvins', state: 'MO', state_name: 'Missouri' },
  { city: 'Eminence', state: 'MO', state_name: 'Missouri' },
  { city: 'Esther', state: 'MO', state_name: 'Missouri' },
  { city: 'Eureka', state: 'MO', state_name: 'Missouri' },
  { city: 'Excelsior Springs', state: 'MO', state_name: 'Missouri' },
  { city: 'Fair Grove', state: 'MO', state_name: 'Missouri' },
  { city: 'Farmington', state: 'MO', state_name: 'Missouri' },
  { city: 'Fayette', state: 'MO', state_name: 'Missouri' },
  { city: 'Fenton', state: 'MO', state_name: 'Missouri' },
  { city: 'Ferguson', state: 'MO', state_name: 'Missouri' },
  { city: 'Festus', state: 'MO', state_name: 'Missouri' },
  { city: 'Flat River', state: 'MO', state_name: 'Missouri' },
  { city: 'Florissant', state: 'MO', state_name: 'Missouri' },
  { city: 'Forsyth', state: 'MO', state_name: 'Missouri' },
  { city: 'Fort Leonard Wood', state: 'MO', state_name: 'Missouri' },
  { city: 'Four Seasons', state: 'MO', state_name: 'Missouri' },
  { city: 'Franklin County', state: 'MO', state_name: 'Missouri' },
  { city: 'Fredericktown', state: 'MO', state_name: 'Missouri' },
  { city: 'Frontenac', state: 'MO', state_name: 'Missouri' },
  { city: 'Fulton', state: 'MO', state_name: 'Missouri' },
  { city: 'Gainesville', state: 'MO', state_name: 'Missouri' },
  { city: 'Galena', state: 'MO', state_name: 'Missouri' },
  { city: 'Gallatin', state: 'MO', state_name: 'Missouri' },
  { city: 'Garden City', state: 'MO', state_name: 'Missouri' },
  { city: 'Gasconade County', state: 'MO', state_name: 'Missouri' },
  { city: 'Gentry County', state: 'MO', state_name: 'Missouri' },
  { city: 'Gerald', state: 'MO', state_name: 'Missouri' },
  { city: 'Gideon', state: 'MO', state_name: 'Missouri' },
  { city: 'Gladstone', state: 'MO', state_name: 'Missouri' },
  { city: 'Glasgow', state: 'MO', state_name: 'Missouri' },
  { city: 'Glasgow Village', state: 'MO', state_name: 'Missouri' },
  { city: 'Glendale', state: 'MO', state_name: 'Missouri' },
  { city: 'Goodman', state: 'MO', state_name: 'Missouri' },
  { city: 'Gower', state: 'MO', state_name: 'Missouri' },
  { city: 'Grain Valley', state: 'MO', state_name: 'Missouri' },
  { city: 'Granby', state: 'MO', state_name: 'Missouri' },
  { city: 'Grandview', state: 'MO', state_name: 'Missouri' },
  { city: 'Grant City', state: 'MO', state_name: 'Missouri' },
  { city: 'Gray Summit', state: 'MO', state_name: 'Missouri' },
  { city: 'Green Park', state: 'MO', state_name: 'Missouri' },
  { city: 'Greene County', state: 'MO', state_name: 'Missouri' },
  { city: 'Greenfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Greenville', state: 'MO', state_name: 'Missouri' },
  { city: 'Greenwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Grundy County', state: 'MO', state_name: 'Missouri' },
  { city: 'Hallsville', state: 'MO', state_name: 'Missouri' },
  { city: 'Hamilton', state: 'MO', state_name: 'Missouri' },
  { city: 'Hanley Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Hannibal', state: 'MO', state_name: 'Missouri' },
  { city: 'Harrison County', state: 'MO', state_name: 'Missouri' },
  { city: 'Harrisonville', state: 'MO', state_name: 'Missouri' },
  { city: 'Hartville', state: 'MO', state_name: 'Missouri' },
  { city: 'Hayti', state: 'MO', state_name: 'Missouri' },
  { city: 'Hazelwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Henry County', state: 'MO', state_name: 'Missouri' },
  { city: 'Herculaneum', state: 'MO', state_name: 'Missouri' },
  { city: 'Hermann', state: 'MO', state_name: 'Missouri' },
  { city: 'Hermitage', state: 'MO', state_name: 'Missouri' },
  { city: 'Hickory County', state: 'MO', state_name: 'Missouri' },
  { city: 'Higginsville', state: 'MO', state_name: 'Missouri' },
  { city: 'High Ridge', state: 'MO', state_name: 'Missouri' },
  { city: 'Hillsboro', state: 'MO', state_name: 'Missouri' },
  { city: 'Hillsdale', state: 'MO', state_name: 'Missouri' },
  { city: 'Holden', state: 'MO', state_name: 'Missouri' },
  { city: 'Hollister', state: 'MO', state_name: 'Missouri' },
  { city: 'Holt County', state: 'MO', state_name: 'Missouri' },
  { city: 'Holts Summit', state: 'MO', state_name: 'Missouri' },
  { city: 'Houston', state: 'MO', state_name: 'Missouri' },
  { city: 'Howard County', state: 'MO', state_name: 'Missouri' },
  { city: 'Howell County', state: 'MO', state_name: 'Missouri' },
  { city: 'Humansville', state: 'MO', state_name: 'Missouri' },
  { city: 'Huntsville', state: 'MO', state_name: 'Missouri' },
  { city: 'Imperial', state: 'MO', state_name: 'Missouri' },
  { city: 'Independence', state: 'MO', state_name: 'Missouri' },
  { city: 'Iron County', state: 'MO', state_name: 'Missouri' },
  { city: 'Ironton', state: 'MO', state_name: 'Missouri' },
  { city: 'Jackson', state: 'MO', state_name: 'Missouri' },
  { city: 'Jackson County', state: 'MO', state_name: 'Missouri' },
  { city: 'Jasper County', state: 'MO', state_name: 'Missouri' },
  { city: 'Jefferson City', state: 'MO', state_name: 'Missouri' },
  { city: 'Jefferson County', state: 'MO', state_name: 'Missouri' },
  { city: 'Jennings', state: 'MO', state_name: 'Missouri' },
  { city: 'Johnson County', state: 'MO', state_name: 'Missouri' },
  { city: 'Joplin', state: 'MO', state_name: 'Missouri' },
  { city: 'Kahoka', state: 'MO', state_name: 'Missouri' },
  { city: 'Kansas City', state: 'MO', state_name: 'Missouri' },
  { city: 'Kearney', state: 'MO', state_name: 'Missouri' },
  { city: 'Kennett', state: 'MO', state_name: 'Missouri' },
  { city: 'Keytesville', state: 'MO', state_name: 'Missouri' },
  { city: 'Kimberling City', state: 'MO', state_name: 'Missouri' },
  { city: 'King City', state: 'MO', state_name: 'Missouri' },
  { city: 'Kingston', state: 'MO', state_name: 'Missouri' },
  { city: 'Kirksville', state: 'MO', state_name: 'Missouri' },
  { city: 'Kirkwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Kissee Mills', state: 'MO', state_name: 'Missouri' },
  { city: 'Knob Noster', state: 'MO', state_name: 'Missouri' },
  { city: 'Knox County', state: 'MO', state_name: 'Missouri' },
  { city: 'La Monte', state: 'MO', state_name: 'Missouri' },
  { city: 'La Plata', state: 'MO', state_name: 'Missouri' },
  { city: 'LaBarque Creek', state: 'MO', state_name: 'Missouri' },
  { city: 'Laclede County', state: 'MO', state_name: 'Missouri' },
  { city: 'Ladue', state: 'MO', state_name: 'Missouri' },
  { city: 'Lafayette County', state: 'MO', state_name: 'Missouri' },
  { city: 'Lake Lotawana', state: 'MO', state_name: 'Missouri' },
  { city: 'Lake Ozark', state: 'MO', state_name: 'Missouri' },
  { city: 'Lake Saint Louis', state: 'MO', state_name: 'Missouri' },
  { city: 'Lake Winnebago', state: 'MO', state_name: 'Missouri' },
  { city: 'Lakeshire', state: 'MO', state_name: 'Missouri' },
  { city: 'Lamar', state: 'MO', state_name: 'Missouri' },
  { city: 'Lancaster', state: 'MO', state_name: 'Missouri' },
  { city: 'Lathrop', state: 'MO', state_name: 'Missouri' },
  { city: 'Lawrence County', state: 'MO', state_name: 'Missouri' },
  { city: 'Lawson', state: 'MO', state_name: 'Missouri' },
  { city: 'Leadwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Lebanon', state: 'MO', state_name: 'Missouri' },
  { city: "Lee's Summit", state: 'MO', state_name: 'Missouri' },
  { city: 'Lemay', state: 'MO', state_name: 'Missouri' },
  { city: 'Lewis County', state: 'MO', state_name: 'Missouri' },
  { city: 'Lexington', state: 'MO', state_name: 'Missouri' },
  { city: 'Liberty', state: 'MO', state_name: 'Missouri' },
  { city: 'Licking', state: 'MO', state_name: 'Missouri' },
  { city: 'Lilbourn', state: 'MO', state_name: 'Missouri' },
  { city: 'Lincoln', state: 'MO', state_name: 'Missouri' },
  { city: 'Lincoln County', state: 'MO', state_name: 'Missouri' },
  { city: 'Linn', state: 'MO', state_name: 'Missouri' },
  { city: 'Linn County', state: 'MO', state_name: 'Missouri' },
  { city: 'Linneus', state: 'MO', state_name: 'Missouri' },
  { city: 'Livingston County', state: 'MO', state_name: 'Missouri' },
  { city: 'Lone Jack', state: 'MO', state_name: 'Missouri' },
  { city: 'Louisiana', state: 'MO', state_name: 'Missouri' },
  { city: 'Macon', state: 'MO', state_name: 'Missouri' },
  { city: 'Macon County', state: 'MO', state_name: 'Missouri' },
  { city: 'Madison County', state: 'MO', state_name: 'Missouri' },
  { city: 'Malden', state: 'MO', state_name: 'Missouri' },
  { city: 'Manchester', state: 'MO', state_name: 'Missouri' },
  { city: 'Mansfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Maplewood', state: 'MO', state_name: 'Missouri' },
  { city: 'Marble Hill', state: 'MO', state_name: 'Missouri' },
  { city: 'Marceline', state: 'MO', state_name: 'Missouri' },
  { city: 'Maries County', state: 'MO', state_name: 'Missouri' },
  { city: 'Marion County', state: 'MO', state_name: 'Missouri' },
  { city: 'Marionville', state: 'MO', state_name: 'Missouri' },
  { city: 'Marlborough', state: 'MO', state_name: 'Missouri' },
  { city: 'Marshall', state: 'MO', state_name: 'Missouri' },
  { city: 'Marshfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Marthasville', state: 'MO', state_name: 'Missouri' },
  { city: 'Maryland Heights', state: 'MO', state_name: 'Missouri' },
  { city: 'Maryville', state: 'MO', state_name: 'Missouri' },
  { city: 'Maysville', state: 'MO', state_name: 'Missouri' },
  { city: 'McDonald County', state: 'MO', state_name: 'Missouri' },
  { city: 'Mehlville', state: 'MO', state_name: 'Missouri' },
  { city: 'Memphis', state: 'MO', state_name: 'Missouri' },
  { city: 'Mercer County', state: 'MO', state_name: 'Missouri' },
  { city: 'Merriam Woods', state: 'MO', state_name: 'Missouri' },
  { city: 'Mexico', state: 'MO', state_name: 'Missouri' },
  { city: 'Milan', state: 'MO', state_name: 'Missouri' },
  { city: 'Miller County', state: 'MO', state_name: 'Missouri' },
  { city: 'Mississippi County', state: 'MO', state_name: 'Missouri' },
  { city: 'Moberly', state: 'MO', state_name: 'Missouri' },
  { city: 'Moline Acres', state: 'MO', state_name: 'Missouri' },
  { city: 'Monett', state: 'MO', state_name: 'Missouri' },
  { city: 'Moniteau County', state: 'MO', state_name: 'Missouri' },
  { city: 'Monroe City', state: 'MO', state_name: 'Missouri' },
  { city: 'Monroe County', state: 'MO', state_name: 'Missouri' },
  { city: 'Montgomery City', state: 'MO', state_name: 'Missouri' },
  { city: 'Montgomery County', state: 'MO', state_name: 'Missouri' },
  { city: 'Monticello', state: 'MO', state_name: 'Missouri' },
  { city: 'Morgan County', state: 'MO', state_name: 'Missouri' },
  { city: 'Moscow Mills', state: 'MO', state_name: 'Missouri' },
  { city: 'Mound City', state: 'MO', state_name: 'Missouri' },
  { city: 'Mount Vernon', state: 'MO', state_name: 'Missouri' },
  { city: 'Mountain Grove', state: 'MO', state_name: 'Missouri' },
  { city: 'Mountain View', state: 'MO', state_name: 'Missouri' },
  { city: 'Murphy', state: 'MO', state_name: 'Missouri' },
  { city: 'Neosho', state: 'MO', state_name: 'Missouri' },
  { city: 'Nevada', state: 'MO', state_name: 'Missouri' },
  { city: 'New Franklin', state: 'MO', state_name: 'Missouri' },
  { city: 'New Haven', state: 'MO', state_name: 'Missouri' },
  { city: 'New London', state: 'MO', state_name: 'Missouri' },
  { city: 'New Madrid', state: 'MO', state_name: 'Missouri' },
  { city: 'New Madrid County', state: 'MO', state_name: 'Missouri' },
  { city: 'Newton County', state: 'MO', state_name: 'Missouri' },
  { city: 'Nixa', state: 'MO', state_name: 'Missouri' },
  { city: 'Nodaway County', state: 'MO', state_name: 'Missouri' },
  { city: 'Noel', state: 'MO', state_name: 'Missouri' },
  { city: 'Normandy', state: 'MO', state_name: 'Missouri' },
  { city: 'North Kansas City', state: 'MO', state_name: 'Missouri' },
  { city: 'Northwoods', state: 'MO', state_name: 'Missouri' },
  { city: "O'Fallon", state: 'MO', state_name: 'Missouri' },
  { city: 'Oak Grove', state: 'MO', state_name: 'Missouri' },
  { city: 'Oakland', state: 'MO', state_name: 'Missouri' },
  { city: 'Oakville', state: 'MO', state_name: 'Missouri' },
  { city: 'Odessa', state: 'MO', state_name: 'Missouri' },
  { city: 'Old Jamestown', state: 'MO', state_name: 'Missouri' },
  { city: 'Olivette', state: 'MO', state_name: 'Missouri' },
  { city: 'Oran', state: 'MO', state_name: 'Missouri' },
  { city: 'Oregon', state: 'MO', state_name: 'Missouri' },
  { city: 'Oregon County', state: 'MO', state_name: 'Missouri' },
  { city: 'Oronogo', state: 'MO', state_name: 'Missouri' },
  { city: 'Osage Beach', state: 'MO', state_name: 'Missouri' },
  { city: 'Osage County', state: 'MO', state_name: 'Missouri' },
  { city: 'Osceola', state: 'MO', state_name: 'Missouri' },
  { city: 'Overland', state: 'MO', state_name: 'Missouri' },
  { city: 'Owensville', state: 'MO', state_name: 'Missouri' },
  { city: 'Ozark', state: 'MO', state_name: 'Missouri' },
  { city: 'Ozark County', state: 'MO', state_name: 'Missouri' },
  { city: 'Pacific', state: 'MO', state_name: 'Missouri' },
  { city: 'Pagedale', state: 'MO', state_name: 'Missouri' },
  { city: 'Palmyra', state: 'MO', state_name: 'Missouri' },
  { city: 'Paris', state: 'MO', state_name: 'Missouri' },
  { city: 'Park Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Parkville', state: 'MO', state_name: 'Missouri' },
  { city: 'Peculiar', state: 'MO', state_name: 'Missouri' },
  { city: 'Pemiscot County', state: 'MO', state_name: 'Missouri' },
  { city: 'Perry County', state: 'MO', state_name: 'Missouri' },
  { city: 'Perryville', state: 'MO', state_name: 'Missouri' },
  { city: 'Pettis County', state: 'MO', state_name: 'Missouri' },
  { city: 'Pevely', state: 'MO', state_name: 'Missouri' },
  { city: 'Phelps County', state: 'MO', state_name: 'Missouri' },
  { city: 'Piedmont', state: 'MO', state_name: 'Missouri' },
  { city: 'Pierce City', state: 'MO', state_name: 'Missouri' },
  { city: 'Pike County', state: 'MO', state_name: 'Missouri' },
  { city: 'Pine Lawn', state: 'MO', state_name: 'Missouri' },
  { city: 'Pineville', state: 'MO', state_name: 'Missouri' },
  { city: 'Platte City', state: 'MO', state_name: 'Missouri' },
  { city: 'Platte County', state: 'MO', state_name: 'Missouri' },
  { city: 'Plattsburg', state: 'MO', state_name: 'Missouri' },
  { city: 'Pleasant Hill', state: 'MO', state_name: 'Missouri' },
  { city: 'Pleasant Valley', state: 'MO', state_name: 'Missouri' },
  { city: 'Polk County', state: 'MO', state_name: 'Missouri' },
  { city: 'Poplar Bluff', state: 'MO', state_name: 'Missouri' },
  { city: 'Portageville', state: 'MO', state_name: 'Missouri' },
  { city: 'Potosi', state: 'MO', state_name: 'Missouri' },
  { city: 'Princeton', state: 'MO', state_name: 'Missouri' },
  { city: 'Pulaski County', state: 'MO', state_name: 'Missouri' },
  { city: 'Purdy', state: 'MO', state_name: 'Missouri' },
  { city: 'Putnam County', state: 'MO', state_name: 'Missouri' },
  { city: 'Ralls County', state: 'MO', state_name: 'Missouri' },
  { city: 'Randolph County', state: 'MO', state_name: 'Missouri' },
  { city: 'Ray County', state: 'MO', state_name: 'Missouri' },
  { city: 'Raymore', state: 'MO', state_name: 'Missouri' },
  { city: 'Raytown', state: 'MO', state_name: 'Missouri' },
  { city: 'Republic', state: 'MO', state_name: 'Missouri' },
  { city: 'Reynolds County', state: 'MO', state_name: 'Missouri' },
  { city: 'Rich Hill', state: 'MO', state_name: 'Missouri' },
  { city: 'Richland', state: 'MO', state_name: 'Missouri' },
  { city: 'Richmond', state: 'MO', state_name: 'Missouri' },
  { city: 'Richmond Heights', state: 'MO', state_name: 'Missouri' },
  { city: 'Ripley County', state: 'MO', state_name: 'Missouri' },
  { city: 'Riverside', state: 'MO', state_name: 'Missouri' },
  { city: 'Riverview', state: 'MO', state_name: 'Missouri' },
  { city: 'Rock Hill', state: 'MO', state_name: 'Missouri' },
  { city: 'Rock Port', state: 'MO', state_name: 'Missouri' },
  { city: 'Rogersville', state: 'MO', state_name: 'Missouri' },
  { city: 'Rolla', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Ann', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Charles', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Charles County', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Clair', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Clair County', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Francois County', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint George', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint James', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint John', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Johns', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Joseph', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Louis County', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Martins', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Paul', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Peters', state: 'MO', state_name: 'Missouri' },
  { city: 'Saint Robert', state: 'MO', state_name: 'Missouri' },
  { city: 'Sainte Genevieve', state: 'MO', state_name: 'Missouri' },
  { city: 'Sainte Genevieve County', state: 'MO', state_name: 'Missouri' },
  { city: 'Salem', state: 'MO', state_name: 'Missouri' },
  { city: 'Saline County', state: 'MO', state_name: 'Missouri' },
  { city: 'Salisbury', state: 'MO', state_name: 'Missouri' },
  { city: 'Sappington', state: 'MO', state_name: 'Missouri' },
  { city: 'Sarcoxie', state: 'MO', state_name: 'Missouri' },
  { city: 'Savannah', state: 'MO', state_name: 'Missouri' },
  { city: 'Schuyler County', state: 'MO', state_name: 'Missouri' },
  { city: 'Scotland County', state: 'MO', state_name: 'Missouri' },
  { city: 'Scott City', state: 'MO', state_name: 'Missouri' },
  { city: 'Scott County', state: 'MO', state_name: 'Missouri' },
  { city: 'Sedalia', state: 'MO', state_name: 'Missouri' },
  { city: 'Senath', state: 'MO', state_name: 'Missouri' },
  { city: 'Seneca', state: 'MO', state_name: 'Missouri' },
  { city: 'Seymour', state: 'MO', state_name: 'Missouri' },
  { city: 'Shannon County', state: 'MO', state_name: 'Missouri' },
  { city: 'Shelbina', state: 'MO', state_name: 'Missouri' },
  { city: 'Shelby County', state: 'MO', state_name: 'Missouri' },
  { city: 'Shelbyville', state: 'MO', state_name: 'Missouri' },
  { city: 'Shell Knob', state: 'MO', state_name: 'Missouri' },
  { city: 'Shrewsbury', state: 'MO', state_name: 'Missouri' },
  { city: 'Sikeston', state: 'MO', state_name: 'Missouri' },
  { city: 'Slater', state: 'MO', state_name: 'Missouri' },
  { city: 'Smithville', state: 'MO', state_name: 'Missouri' },
  { city: 'Spanish Lake', state: 'MO', state_name: 'Missouri' },
  { city: 'Sparta', state: 'MO', state_name: 'Missouri' },
  { city: 'Springfield', state: 'MO', state_name: 'Missouri' },
  { city: 'St. Louis', state: 'MO', state_name: 'Missouri' },
  { city: 'Stanberry', state: 'MO', state_name: 'Missouri' },
  { city: 'Steele', state: 'MO', state_name: 'Missouri' },
  { city: 'Steelville', state: 'MO', state_name: 'Missouri' },
  { city: 'Stockton', state: 'MO', state_name: 'Missouri' },
  { city: 'Stoddard County', state: 'MO', state_name: 'Missouri' },
  { city: 'Stone County', state: 'MO', state_name: 'Missouri' },
  { city: 'Stover', state: 'MO', state_name: 'Missouri' },
  { city: 'Strafford', state: 'MO', state_name: 'Missouri' },
  { city: 'Sugar Creek', state: 'MO', state_name: 'Missouri' },
  { city: 'Sullivan', state: 'MO', state_name: 'Missouri' },
  { city: 'Sullivan County', state: 'MO', state_name: 'Missouri' },
  { city: 'Sunset Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Sweet Springs', state: 'MO', state_name: 'Missouri' },
  { city: 'Taney County', state: 'MO', state_name: 'Missouri' },
  { city: 'Taos', state: 'MO', state_name: 'Missouri' },
  { city: 'Tarkio', state: 'MO', state_name: 'Missouri' },
  { city: 'Terre du Lac', state: 'MO', state_name: 'Missouri' },
  { city: 'Terre Haute', state: 'MO', state_name: 'Missouri' },
  { city: 'Texas County', state: 'MO', state_name: 'Missouri' },
  { city: 'Thayer', state: 'MO', state_name: 'Missouri' },
  { city: 'Tipton', state: 'MO', state_name: 'Missouri' },
  { city: 'Town and Country', state: 'MO', state_name: 'Missouri' },
  { city: 'Trenton', state: 'MO', state_name: 'Missouri' },
  { city: 'Troy', state: 'MO', state_name: 'Missouri' },
  { city: 'Tuscumbia', state: 'MO', state_name: 'Missouri' },
  { city: 'Union', state: 'MO', state_name: 'Missouri' },
  { city: 'Unionville', state: 'MO', state_name: 'Missouri' },
  { city: 'University City', state: 'MO', state_name: 'Missouri' },
  { city: 'Valley Park', state: 'MO', state_name: 'Missouri' },
  { city: 'Van Buren', state: 'MO', state_name: 'Missouri' },
  { city: 'Vandalia', state: 'MO', state_name: 'Missouri' },
  { city: 'Velda Village', state: 'MO', state_name: 'Missouri' },
  { city: 'Velda Village Hills', state: 'MO', state_name: 'Missouri' },
  { city: 'Vernon County', state: 'MO', state_name: 'Missouri' },
  { city: 'Versailles', state: 'MO', state_name: 'Missouri' },
  { city: 'Vienna', state: 'MO', state_name: 'Missouri' },
  { city: 'Villa Ridge', state: 'MO', state_name: 'Missouri' },
  { city: 'Vinita Park', state: 'MO', state_name: 'Missouri' },
  { city: 'Wardsville', state: 'MO', state_name: 'Missouri' },
  { city: 'Warren County', state: 'MO', state_name: 'Missouri' },
  { city: 'Warrensburg', state: 'MO', state_name: 'Missouri' },
  { city: 'Warrenton', state: 'MO', state_name: 'Missouri' },
  { city: 'Warsaw', state: 'MO', state_name: 'Missouri' },
  { city: 'Warson Woods', state: 'MO', state_name: 'Missouri' },
  { city: 'Washington', state: 'MO', state_name: 'Missouri' },
  { city: 'Washington County', state: 'MO', state_name: 'Missouri' },
  { city: 'Wayne County', state: 'MO', state_name: 'Missouri' },
  { city: 'Waynesville', state: 'MO', state_name: 'Missouri' },
  { city: 'Weatherby Lake', state: 'MO', state_name: 'Missouri' },
  { city: 'Webb City', state: 'MO', state_name: 'Missouri' },
  { city: 'Webster County', state: 'MO', state_name: 'Missouri' },
  { city: 'Webster Groves', state: 'MO', state_name: 'Missouri' },
  { city: 'Weldon Spring', state: 'MO', state_name: 'Missouri' },
  { city: 'Wellston', state: 'MO', state_name: 'Missouri' },
  { city: 'Wellsville', state: 'MO', state_name: 'Missouri' },
  { city: 'Wentzville', state: 'MO', state_name: 'Missouri' },
  { city: 'West Plains', state: 'MO', state_name: 'Missouri' },
  { city: 'Weston', state: 'MO', state_name: 'Missouri' },
  { city: 'Whiteman Air Force Base', state: 'MO', state_name: 'Missouri' },
  { city: 'Wildwood', state: 'MO', state_name: 'Missouri' },
  { city: 'Willard', state: 'MO', state_name: 'Missouri' },
  { city: 'Willow Springs', state: 'MO', state_name: 'Missouri' },
  { city: 'Winchester', state: 'MO', state_name: 'Missouri' },
  { city: 'Windsor', state: 'MO', state_name: 'Missouri' },
  { city: 'Winfield', state: 'MO', state_name: 'Missouri' },
  { city: 'Winona', state: 'MO', state_name: 'Missouri' },
  { city: 'Woodson Terrace', state: 'MO', state_name: 'Missouri' },
  { city: 'Worth County', state: 'MO', state_name: 'Missouri' },
  { city: 'Wright City', state: 'MO', state_name: 'Missouri' },
  { city: 'Wright County', state: 'MO', state_name: 'Missouri' },
  { city: 'Absarokee', state: 'MT', state_name: 'Montana' },
  { city: 'Anaconda', state: 'MT', state_name: 'Montana' },
  { city: 'Baker', state: 'MT', state_name: 'Montana' },
  { city: 'Waterloo', state: 'NY', state_name: 'New York' },
  { city: 'Watertown', state: 'NY', state_name: 'New York' },
  { city: 'Waterville', state: 'NY', state_name: 'New York' },
  { city: 'Watervliet', state: 'NY', state_name: 'New York' },
  { city: 'Watkins Glen', state: 'NY', state_name: 'New York' },
  { city: 'Waverly', state: 'NY', state_name: 'New York' },
  { city: 'Wawarsing', state: 'NY', state_name: 'New York' },
  { city: 'Wayland', state: 'NY', state_name: 'New York' },
  { city: 'Wayne County', state: 'NY', state_name: 'New York' },
  { city: 'Webster', state: 'NY', state_name: 'New York' },
  { city: 'Weedsport', state: 'NY', state_name: 'New York' },
  { city: 'Wellsville', state: 'NY', state_name: 'New York' },
  { city: 'Wesley Hills', state: 'NY', state_name: 'New York' },
  { city: 'West Albany', state: 'NY', state_name: 'New York' },
  { city: 'West Babylon', state: 'NY', state_name: 'New York' },
  { city: 'West Bay Shore', state: 'NY', state_name: 'New York' },
  { city: 'West Carthage', state: 'NY', state_name: 'New York' },
  { city: 'West Elmira', state: 'NY', state_name: 'New York' },
  { city: 'West End', state: 'NY', state_name: 'New York' },
  { city: 'West Glens Falls', state: 'NY', state_name: 'New York' },
  { city: 'West Haverstraw', state: 'NY', state_name: 'New York' },
  { city: 'West Hempstead', state: 'NY', state_name: 'New York' },
  { city: 'West Hills', state: 'NY', state_name: 'New York' },
  { city: 'West Hurley', state: 'NY', state_name: 'New York' },
  { city: 'West Islip', state: 'NY', state_name: 'New York' },
  { city: 'West Nyack', state: 'NY', state_name: 'New York' },
  { city: 'West Point', state: 'NY', state_name: 'New York' },
  { city: 'West Sand Lake', state: 'NY', state_name: 'New York' },
  { city: 'West Sayville', state: 'NY', state_name: 'New York' },
  { city: 'West Seneca', state: 'NY', state_name: 'New York' },
  { city: 'Westbury', state: 'NY', state_name: 'New York' },
  { city: 'Westchester County', state: 'NY', state_name: 'New York' },
  { city: 'Westerleigh', state: 'NY', state_name: 'New York' },
  { city: 'Westfield', state: 'NY', state_name: 'New York' },
  { city: 'Westhampton', state: 'NY', state_name: 'New York' },
  { city: 'Westhampton Beach', state: 'NY', state_name: 'New York' },
  { city: 'Westmere', state: 'NY', state_name: 'New York' },
  { city: 'Weston Mills', state: 'NY', state_name: 'New York' },
  { city: 'Westvale', state: 'NY', state_name: 'New York' },
  { city: 'Wheatley Heights', state: 'NY', state_name: 'New York' },
  { city: 'White Plains', state: 'NY', state_name: 'New York' },
  { city: 'Whitehall', state: 'NY', state_name: 'New York' },
  { city: 'Whitesboro', state: 'NY', state_name: 'New York' },
  { city: 'Whitestone', state: 'NY', state_name: 'New York' },
  { city: 'Williamsburg', state: 'NY', state_name: 'New York' },
  { city: 'Williamson', state: 'NY', state_name: 'New York' },
  { city: 'Williamsville', state: 'NY', state_name: 'New York' },
  { city: 'Williston Park', state: 'NY', state_name: 'New York' },
  { city: 'Willowbrook', state: 'NY', state_name: 'New York' },
  { city: 'Wilson', state: 'NY', state_name: 'New York' },
  { city: 'Wolcott', state: 'NY', state_name: 'New York' },
  { city: 'Woodbury', state: 'NY', state_name: 'New York' },
  { city: 'Woodhaven', state: 'NY', state_name: 'New York' },
  { city: 'Woodlawn', state: 'NY', state_name: 'New York' },
  { city: 'Woodmere', state: 'NY', state_name: 'New York' },
  { city: 'Woodrow', state: 'NY', state_name: 'New York' },
  { city: 'Woodside', state: 'NY', state_name: 'New York' },
  { city: 'Woodstock', state: 'NY', state_name: 'New York' },
  { city: 'Worcester', state: 'NY', state_name: 'New York' },
  { city: 'Wurtsboro', state: 'NY', state_name: 'New York' },
  { city: 'Wyandanch', state: 'NY', state_name: 'New York' },
  { city: 'Wykagyl', state: 'NY', state_name: 'New York' },
  { city: 'Wynantskill', state: 'NY', state_name: 'New York' },
  { city: 'Wyoming County', state: 'NY', state_name: 'New York' },
  { city: 'Yaphank', state: 'NY', state_name: 'New York' },
  { city: 'Yates County', state: 'NY', state_name: 'New York' },
  { city: 'Yonkers', state: 'NY', state_name: 'New York' },
  { city: 'Yorkshire', state: 'NY', state_name: 'New York' },
  { city: 'Yorktown Heights', state: 'NY', state_name: 'New York' },
  { city: 'Yorkville', state: 'NY', state_name: 'New York' },
  { city: 'Youngstown', state: 'NY', state_name: 'New York' },
  { city: 'Zena', state: 'NY', state_name: 'New York' },
  { city: 'Aberdeen', state: 'NC', state_name: 'North Carolina' },
  { city: 'Advance', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ahoskie', state: 'NC', state_name: 'North Carolina' },
  { city: 'Alamance County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Albemarle', state: 'NC', state_name: 'North Carolina' },
  { city: 'Alexander County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Alleghany County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Andrews', state: 'NC', state_name: 'North Carolina' },
  { city: 'Angier', state: 'NC', state_name: 'North Carolina' },
  { city: 'Anson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Apex', state: 'NC', state_name: 'North Carolina' },
  { city: 'Archdale', state: 'NC', state_name: 'North Carolina' },
  { city: 'Archer Lodge', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ashe County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Asheboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Asheville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Atlantic Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'Avery County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Avery Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ayden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Badin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bakersville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Balfour', state: 'NC', state_name: 'North Carolina' },
  { city: 'Banner Elk', state: 'NC', state_name: 'North Carolina' },
  { city: 'Barker Heights', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bayboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bayshore', state: 'NC', state_name: 'North Carolina' },
  { city: 'Beaufort', state: 'NC', state_name: 'North Carolina' },
  { city: 'Beaufort County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Belhaven', state: 'NC', state_name: 'North Carolina' },
  { city: 'Belmont', state: 'NC', state_name: 'North Carolina' },
  { city: 'Belville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Benson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bent Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bermuda Run', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bertie County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bessemer City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bethel', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bethlehem', state: 'NC', state_name: 'North Carolina' },
  { city: 'Beulaville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Biltmore Forest', state: 'NC', state_name: 'North Carolina' },
  { city: 'Biscoe', state: 'NC', state_name: 'North Carolina' },
  { city: 'Black Mountain', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bladen County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bladenboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Blowing Rock', state: 'NC', state_name: 'North Carolina' },
  { city: 'Boiling Spring Lakes', state: 'NC', state_name: 'North Carolina' },
  { city: 'Boiling Springs', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bolivia', state: 'NC', state_name: 'North Carolina' },
  { city: 'Boone', state: 'NC', state_name: 'North Carolina' },
  { city: 'Boonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Brevard', state: 'NC', state_name: 'North Carolina' },
  { city: 'Brices Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Broad Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Broadway', state: 'NC', state_name: 'North Carolina' },
  { city: 'Brogden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Brunswick', state: 'NC', state_name: 'North Carolina' },
  { city: 'Brunswick County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Bryson City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Buies Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Buncombe County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Burgaw', state: 'NC', state_name: 'North Carolina' },
  { city: 'Burke County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Burlington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Burnsville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Butner', state: 'NC', state_name: 'North Carolina' },
  { city: 'Buxton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cabarrus County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cajahs Mountain', state: 'NC', state_name: 'North Carolina' },
  { city: 'Calabash', state: 'NC', state_name: 'North Carolina' },
  { city: 'Caldwell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Camden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Camden County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Canton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cape Carteret', state: 'NC', state_name: 'North Carolina' },
  { city: 'Carolina Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'Carolina Shores', state: 'NC', state_name: 'North Carolina' },
  { city: 'Carrboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Carteret County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Carthage', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cary', state: 'NC', state_name: 'North Carolina' },
  { city: 'Castle Hayne', state: 'NC', state_name: 'North Carolina' },
  { city: 'Caswell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Catawba County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cedar Point', state: 'NC', state_name: 'North Carolina' },
  { city: 'Chadbourn', state: 'NC', state_name: 'North Carolina' },
  { city: 'Chapel Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Charlotte', state: 'NC', state_name: 'North Carolina' },
  { city: 'Chatham County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cherokee', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cherokee County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cherryville', state: 'NC', state_name: 'North Carolina' },
  { city: 'China Grove', state: 'NC', state_name: 'North Carolina' },
  { city: 'Chowan County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Claremont', state: 'NC', state_name: 'North Carolina' },
  { city: 'Clay County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Clayton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Clemmons', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cleveland County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Clinton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Clyde', state: 'NC', state_name: 'North Carolina' },
  { city: 'Coats', state: 'NC', state_name: 'North Carolina' },
  { city: 'Columbia', state: 'NC', state_name: 'North Carolina' },
  { city: 'Columbus', state: 'NC', state_name: 'North Carolina' },
  { city: 'Columbus County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Concord', state: 'NC', state_name: 'North Carolina' },
  { city: 'Connelly Springs', state: 'NC', state_name: 'North Carolina' },
  { city: 'Conover', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cordova', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cornelius', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cove Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cramerton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Craven County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Creedmoor', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cricket', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cullowhee', state: 'NC', state_name: 'North Carolina' },
  { city: 'Cumberland County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Currituck', state: 'NC', state_name: 'North Carolina' },
  { city: 'Currituck County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Dallas', state: 'NC', state_name: 'North Carolina' },
  { city: 'Dana', state: 'NC', state_name: 'North Carolina' },
  { city: 'Danbury', state: 'NC', state_name: 'North Carolina' },
  { city: 'Dare County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Davidson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Davidson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Davie County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Denton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Denver', state: 'NC', state_name: 'North Carolina' },
  { city: 'Dobson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Drexel', state: 'NC', state_name: 'North Carolina' },
  { city: 'Dunn', state: 'NC', state_name: 'North Carolina' },
  { city: 'Duplin County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Durham', state: 'NC', state_name: 'North Carolina' },
  { city: 'Durham County', state: 'NC', state_name: 'North Carolina' },
  { city: 'East Flat Rock', state: 'NC', state_name: 'North Carolina' },
  { city: 'East Rockingham', state: 'NC', state_name: 'North Carolina' },
  { city: 'East Spencer', state: 'NC', state_name: 'North Carolina' },
  { city: 'Eastover', state: 'NC', state_name: 'North Carolina' },
  { city: 'Eden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Edenton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Edgecombe County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Edneyville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elizabeth City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elizabethtown', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elkin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ellerbe', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elm City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elon', state: 'NC', state_name: 'North Carolina' },
  { city: 'Elroy', state: 'NC', state_name: 'North Carolina' },
  { city: 'Emerald Isle', state: 'NC', state_name: 'North Carolina' },
  { city: 'Enfield', state: 'NC', state_name: 'North Carolina' },
  { city: 'Enochville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Erwin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Etowah', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fairfield Harbour', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fairmont', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fairplains', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fairview', state: 'NC', state_name: 'North Carolina' },
  { city: 'Farmville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fayetteville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fearrington Village', state: 'NC', state_name: 'North Carolina' },
  { city: 'Flat Rock', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fletcher', state: 'NC', state_name: 'North Carolina' },
  { city: 'Forest City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Forest Oaks', state: 'NC', state_name: 'North Carolina' },
  { city: 'Forsyth County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fort Bragg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Foscoe', state: 'NC', state_name: 'North Carolina' },
  { city: 'Four Oaks', state: 'NC', state_name: 'North Carolina' },
  { city: 'Franklin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Franklin County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Franklinton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Franklinville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fremont', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fruitland', state: 'NC', state_name: 'North Carolina' },
  { city: 'Fuquay-Varina', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gamewell', state: 'NC', state_name: 'North Carolina' },
  { city: 'Garner', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gaston', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gaston County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gastonia', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gates County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gatesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gibsonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Glen Alpine', state: 'NC', state_name: 'North Carolina' },
  { city: 'Glen Raven', state: 'NC', state_name: 'North Carolina' },
  { city: 'Goldsboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Gorman', state: 'NC', state_name: 'North Carolina' },
  { city: 'Graham', state: 'NC', state_name: 'North Carolina' },
  { city: 'Graham County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Granite Falls', state: 'NC', state_name: 'North Carolina' },
  { city: 'Granite Quarry', state: 'NC', state_name: 'North Carolina' },
  { city: 'Granville County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Green Level', state: 'NC', state_name: 'North Carolina' },
  { city: 'Greene County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Greensboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Greenville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Grifton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Guilford County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Half Moon', state: 'NC', state_name: 'North Carolina' },
  { city: 'Halifax', state: 'NC', state_name: 'North Carolina' },
  { city: 'Halifax County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hamlet', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hampstead', state: 'NC', state_name: 'North Carolina' },
  { city: 'Harkers Island', state: 'NC', state_name: 'North Carolina' },
  { city: 'Harnett County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Harrisburg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Havelock', state: 'NC', state_name: 'North Carolina' },
  { city: 'Haw River', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hayesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hays', state: 'NC', state_name: 'North Carolina' },
  { city: 'Haywood County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hazelwood', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hemby Bridge', state: 'NC', state_name: 'North Carolina' },
  { city: 'Henderson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Henderson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hendersonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hertford', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hertford County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hickory', state: 'NC', state_name: 'North Carolina' },
  { city: 'High Point', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hildebran', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hillsborough', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hoke County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Holly Ridge', state: 'NC', state_name: 'North Carolina' },
  { city: 'Holly Springs', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hoopers Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hope Mills', state: 'NC', state_name: 'North Carolina' },
  { city: 'Horse Shoe', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hudson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Huntersville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Hyde County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Icard', state: 'NC', state_name: 'North Carolina' },
  { city: 'Indian Trail', state: 'NC', state_name: 'North Carolina' },
  { city: 'Iredell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jackson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jackson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jacksonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'James City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jamestown', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jefferson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Johnston County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jones County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Jonesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kannapolis', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kenansville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kenly', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kernersville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kill Devil Hills', state: 'NC', state_name: 'North Carolina' },
  { city: 'King', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kings Grant', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kings Mountain', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kinston', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kitty Hawk', state: 'NC', state_name: 'North Carolina' },
  { city: 'Knightdale', state: 'NC', state_name: 'North Carolina' },
  { city: 'Kure Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'La Grange', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lake Junaluska', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lake Lure', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lake Norman of Catawba', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lake Park', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lake Waccamaw', state: 'NC', state_name: 'North Carolina' },
  { city: 'Landis', state: 'NC', state_name: 'North Carolina' },
  { city: 'Laurel Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Laurel Park', state: 'NC', state_name: 'North Carolina' },
  { city: 'Laurinburg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lee County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Leland', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lenoir', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lenoir County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lewisville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lexington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Liberty', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lillington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lincoln County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lincolnton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Locust', state: 'NC', state_name: 'North Carolina' },
  { city: 'Long Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'Longview', state: 'NC', state_name: 'North Carolina' },
  { city: 'Louisburg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lowell', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lowesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lucama', state: 'NC', state_name: 'North Carolina' },
  { city: 'Lumberton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Macon County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Madison', state: 'NC', state_name: 'North Carolina' },
  { city: 'Madison County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Maggie Valley', state: 'NC', state_name: 'North Carolina' },
  { city: 'Maiden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Manteo', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mar-Mac', state: 'NC', state_name: 'North Carolina' },
  { city: 'Marion', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mars Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Marshall', state: 'NC', state_name: 'North Carolina' },
  { city: 'Marshville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Martin County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Marvin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Masonboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Matthews', state: 'NC', state_name: 'North Carolina' },
  { city: 'Maury', state: 'NC', state_name: 'North Carolina' },
  { city: 'Maxton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mayodan', state: 'NC', state_name: 'North Carolina' },
  { city: 'Maysville', state: 'NC', state_name: 'North Carolina' },
  { city: 'McDowell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'McLeansville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mebane', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mecklenburg County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Midland', state: 'NC', state_name: 'North Carolina' },
  { city: 'Midway', state: 'NC', state_name: 'North Carolina' },
  { city: 'Millers Creek', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mills River', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mineral Springs', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mint Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mitchell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mocksville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Monroe', state: 'NC', state_name: 'North Carolina' },
  { city: 'Montgomery County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Moore County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mooresville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Moravian Falls', state: 'NC', state_name: 'North Carolina' },
  { city: 'Morehead City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Morganton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Morrisville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mount Airy', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mount Gilead', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mount Holly', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mount Olive', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mount Pleasant', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mountain Home', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mountain View', state: 'NC', state_name: 'North Carolina' },
  { city: 'Moyock', state: 'NC', state_name: 'North Carolina' },
  { city: 'Mulberry', state: 'NC', state_name: 'North Carolina' },
  { city: 'Murfreesboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Murphy', state: 'NC', state_name: 'North Carolina' },
  { city: 'Murraysville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Myrtle Grove', state: 'NC', state_name: 'North Carolina' },
  { city: 'Nags Head', state: 'NC', state_name: 'North Carolina' },
  { city: 'Nash County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Nashville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Navassa', state: 'NC', state_name: 'North Carolina' },
  { city: 'Neuse Forest', state: 'NC', state_name: 'North Carolina' },
  { city: 'New Bern', state: 'NC', state_name: 'North Carolina' },
  { city: 'New Hanover County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Newland', state: 'NC', state_name: 'North Carolina' },
  { city: 'Newport', state: 'NC', state_name: 'North Carolina' },
  { city: 'Newton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Norlina', state: 'NC', state_name: 'North Carolina' },
  { city: 'North Wilkesboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Northampton County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Northchase', state: 'NC', state_name: 'North Carolina' },
  { city: 'Northlakes', state: 'NC', state_name: 'North Carolina' },
  { city: 'Norwood', state: 'NC', state_name: 'North Carolina' },
  { city: 'Oak Island', state: 'NC', state_name: 'North Carolina' },
  { city: 'Oak Ridge', state: 'NC', state_name: 'North Carolina' },
  { city: 'Oakboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ogden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Onslow County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Orange County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Oxford', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pamlico County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pasquotank County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pembroke', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pender County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Perquimans County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Person County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pilot Mountain', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pine Knoll Shores', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pine Level', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pinebluff', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pinehurst', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pinetops', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pineville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Piney Green', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pitt County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pittsboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Plain View', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pleasant Garden', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pleasant Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Plymouth', state: 'NC', state_name: 'North Carolina' },
  { city: 'Polk County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Polkton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Princeton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Princeville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Pumpkin Center', state: 'NC', state_name: 'North Carolina' },
  { city: 'Raeford', state: 'NC', state_name: 'North Carolina' },
  { city: 'Raleigh', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ramseur', state: 'NC', state_name: 'North Carolina' },
  { city: 'Randleman', state: 'NC', state_name: 'North Carolina' },
  { city: 'Randolph County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Ranlo', state: 'NC', state_name: 'North Carolina' },
  { city: 'Red Oak', state: 'NC', state_name: 'North Carolina' },
  { city: 'Red Springs', state: 'NC', state_name: 'North Carolina' },
  { city: 'Reidsville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rhodhiss', state: 'NC', state_name: 'North Carolina' },
  { city: 'Richlands', state: 'NC', state_name: 'North Carolina' },
  { city: 'Richmond County', state: 'NC', state_name: 'North Carolina' },
  { city: 'River Bend', state: 'NC', state_name: 'North Carolina' },
  { city: 'River Road', state: 'NC', state_name: 'North Carolina' },
  { city: 'Roanoke Rapids', state: 'NC', state_name: 'North Carolina' },
  { city: 'Robbins', state: 'NC', state_name: 'North Carolina' },
  { city: 'Robbinsville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Robersonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Robeson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rockfish', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rockingham', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rockingham County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rockwell', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rocky Mount', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rocky Point', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rolesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rose Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Roseboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rowan County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rowland', state: 'NC', state_name: 'North Carolina' },
  { city: 'Roxboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Royal Pines', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rural Hall', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rutherford College', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rutherford County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Rutherfordton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Saint James', state: 'NC', state_name: 'North Carolina' },
  { city: 'Saint Pauls', state: 'NC', state_name: 'North Carolina' },
  { city: 'Saint Stephens', state: 'NC', state_name: 'North Carolina' },
  { city: 'Salem', state: 'NC', state_name: 'North Carolina' },
  { city: 'Salisbury', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sampson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sanford', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sawmills', state: 'NC', state_name: 'North Carolina' },
  { city: 'Saxapahaw', state: 'NC', state_name: 'North Carolina' },
  { city: 'Scotland County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Scotland Neck', state: 'NC', state_name: 'North Carolina' },
  { city: 'Scotts Mill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sea Breeze', state: 'NC', state_name: 'North Carolina' },
  { city: 'Seagate', state: 'NC', state_name: 'North Carolina' },
  { city: 'Selma', state: 'NC', state_name: 'North Carolina' },
  { city: 'Seven Lakes', state: 'NC', state_name: 'North Carolina' },
  { city: 'Shallotte', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sharpsburg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Shelby', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sherrills Ford', state: 'NC', state_name: 'North Carolina' },
  { city: 'Siler City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Silver Lake', state: 'NC', state_name: 'North Carolina' },
  { city: 'Skippers Corner', state: 'NC', state_name: 'North Carolina' },
  { city: 'Smithfield', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sneads Ferry', state: 'NC', state_name: 'North Carolina' },
  { city: 'Snow Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'South Gastonia', state: 'NC', state_name: 'North Carolina' },
  { city: 'South Henderson', state: 'NC', state_name: 'North Carolina' },
  { city: 'South Rosemary', state: 'NC', state_name: 'North Carolina' },
  { city: 'Southern Pines', state: 'NC', state_name: 'North Carolina' },
  { city: 'Southern Shores', state: 'NC', state_name: 'North Carolina' },
  { city: 'Southmont', state: 'NC', state_name: 'North Carolina' },
  { city: 'Southport', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sparta', state: 'NC', state_name: 'North Carolina' },
  { city: 'Spencer', state: 'NC', state_name: 'North Carolina' },
  { city: 'Spindale', state: 'NC', state_name: 'North Carolina' },
  { city: 'Spring Hope', state: 'NC', state_name: 'North Carolina' },
  { city: 'Spring Lake', state: 'NC', state_name: 'North Carolina' },
  { city: 'Spruce Pine', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stallings', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stanfield', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stanley', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stanly County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Statesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stedman', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stokes County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stokesdale', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stoneville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Stony Point', state: 'NC', state_name: 'North Carolina' },
  { city: 'Summerfield', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sunset Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'Surf City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Surry County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Swain County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Swannanoa', state: 'NC', state_name: 'North Carolina' },
  { city: 'Swanquarter', state: 'NC', state_name: 'North Carolina' },
  { city: 'Swansboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Swepsonville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Sylva', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tabor City', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tarboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Taylorsville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Thomasville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Thurmond', state: 'NC', state_name: 'North Carolina' },
  { city: 'Toast', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tobaccoville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Transylvania County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Trent Woods', state: 'NC', state_name: 'North Carolina' },
  { city: 'Trenton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Trinity', state: 'NC', state_name: 'North Carolina' },
  { city: 'Troutman', state: 'NC', state_name: 'North Carolina' },
  { city: 'Troy', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tryon', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tyro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Tyrrell County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Union County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Unionville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Valdese', state: 'NC', state_name: 'North Carolina' },
  { city: 'Valley Hill', state: 'NC', state_name: 'North Carolina' },
  { city: 'Vance County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Vander', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wadesboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wake County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wake Forest', state: 'NC', state_name: 'North Carolina' },
  { city: 'Walkertown', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wallace', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wallburg', state: 'NC', state_name: 'North Carolina' },
  { city: 'Walnut Cove', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wanchese', state: 'NC', state_name: 'North Carolina' },
  { city: 'Warren County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Warrenton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Warsaw', state: 'NC', state_name: 'North Carolina' },
  { city: 'Washington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Washington County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Watauga County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Waxhaw', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wayne County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Waynesville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Weaverville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Weddington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Welcome', state: 'NC', state_name: 'North Carolina' },
  { city: 'Weldon', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wendell', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wentworth', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wesley Chapel', state: 'NC', state_name: 'North Carolina' },
  { city: 'West Canton', state: 'NC', state_name: 'North Carolina' },
  { city: 'West Jefferson', state: 'NC', state_name: 'North Carolina' },
  { city: 'West Marion', state: 'NC', state_name: 'North Carolina' },
  { city: 'West Raleigh', state: 'NC', state_name: 'North Carolina' },
  { city: 'Westport', state: 'NC', state_name: 'North Carolina' },
  { city: 'Whispering Pines', state: 'NC', state_name: 'North Carolina' },
  { city: 'White Plains', state: 'NC', state_name: 'North Carolina' },
  { city: 'Whiteville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilkes County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilkesboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Williamston', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilmington', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilson', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilson County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wilsons Mills', state: 'NC', state_name: 'North Carolina' },
  { city: 'Windsor', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wingate', state: 'NC', state_name: 'North Carolina' },
  { city: 'Winston-Salem', state: 'NC', state_name: 'North Carolina' },
  { city: 'Winterville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Winton', state: 'NC', state_name: 'North Carolina' },
  { city: 'Woodfin', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wrightsboro', state: 'NC', state_name: 'North Carolina' },
  { city: 'Wrightsville Beach', state: 'NC', state_name: 'North Carolina' },
  { city: 'Yadkin County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Yadkinville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Yancey County', state: 'NC', state_name: 'North Carolina' },
  { city: 'Yanceyville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Youngsville', state: 'NC', state_name: 'North Carolina' },
  { city: 'Zebulon', state: 'NC', state_name: 'North Carolina' },
  { city: 'Adams County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Amidon', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ashley', state: 'ND', state_name: 'North Dakota' },
  { city: 'Barnes County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Beach', state: 'ND', state_name: 'North Dakota' },
  { city: 'Belcourt', state: 'ND', state_name: 'North Dakota' },
  { city: 'Belfield', state: 'ND', state_name: 'North Dakota' },
  { city: 'Benson County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Beulah', state: 'ND', state_name: 'North Dakota' },
  { city: 'Billings County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bismarck', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bottineau', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bottineau County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bowbells', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bowman', state: 'ND', state_name: 'North Dakota' },
  { city: 'Bowman County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Burke County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Burleigh County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Burlington', state: 'ND', state_name: 'North Dakota' },
  { city: 'Cando', state: 'ND', state_name: 'North Dakota' },
  { city: 'Carrington', state: 'ND', state_name: 'North Dakota' },
  { city: 'Carson', state: 'ND', state_name: 'North Dakota' },
  { city: 'Cass County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Casselton', state: 'ND', state_name: 'North Dakota' },
  { city: 'Cavalier', state: 'ND', state_name: 'North Dakota' },
  { city: 'Cavalier County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Center', state: 'ND', state_name: 'North Dakota' },
  { city: 'Cooperstown', state: 'ND', state_name: 'North Dakota' },
  { city: 'Crosby', state: 'ND', state_name: 'North Dakota' },
  { city: 'Devils Lake', state: 'ND', state_name: 'North Dakota' },
  { city: 'Dickey County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Dickinson', state: 'ND', state_name: 'North Dakota' },
  { city: 'Divide County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Dunn County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Eddy County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ellendale', state: 'ND', state_name: 'North Dakota' },
  { city: 'Emmons County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Fargo', state: 'ND', state_name: 'North Dakota' },
  { city: 'Fessenden', state: 'ND', state_name: 'North Dakota' },
  { city: 'Finley', state: 'ND', state_name: 'North Dakota' },
  { city: 'Forman', state: 'ND', state_name: 'North Dakota' },
  { city: 'Fort Totten', state: 'ND', state_name: 'North Dakota' },
  { city: 'Fort Yates', state: 'ND', state_name: 'North Dakota' },
  { city: 'Foster County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Garrison', state: 'ND', state_name: 'North Dakota' },
  { city: 'Golden Valley County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Grafton', state: 'ND', state_name: 'North Dakota' },
  { city: 'Grand Forks', state: 'ND', state_name: 'North Dakota' },
  {
    city: 'Grand Forks Air Force Base',
    state: 'ND',
    state_name: 'North Dakota',
  },
  { city: 'Grand Forks County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Grant County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Griggs County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Harvey', state: 'ND', state_name: 'North Dakota' },
  { city: 'Hazen', state: 'ND', state_name: 'North Dakota' },
  { city: 'Hettinger', state: 'ND', state_name: 'North Dakota' },
  { city: 'Hettinger County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Hillsboro', state: 'ND', state_name: 'North Dakota' },
  { city: 'Horace', state: 'ND', state_name: 'North Dakota' },
  { city: 'Jamestown', state: 'ND', state_name: 'North Dakota' },
  { city: 'Kenmare', state: 'ND', state_name: 'North Dakota' },
  { city: 'Kidder County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Killdeer', state: 'ND', state_name: 'North Dakota' },
  { city: 'Lakota', state: 'ND', state_name: 'North Dakota' },
  { city: 'LaMoure County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Langdon', state: 'ND', state_name: 'North Dakota' },
  { city: 'Larimore', state: 'ND', state_name: 'North Dakota' },
  { city: 'Lincoln', state: 'ND', state_name: 'North Dakota' },
  { city: 'Linton', state: 'ND', state_name: 'North Dakota' },
  { city: 'Lisbon', state: 'ND', state_name: 'North Dakota' },
  { city: 'Logan County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mandan', state: 'ND', state_name: 'North Dakota' },
  { city: 'Manning', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mayville', state: 'ND', state_name: 'North Dakota' },
  { city: 'McClusky', state: 'ND', state_name: 'North Dakota' },
  { city: 'McHenry County', state: 'ND', state_name: 'North Dakota' },
  { city: 'McIntosh County', state: 'ND', state_name: 'North Dakota' },
  { city: 'McKenzie County', state: 'ND', state_name: 'North Dakota' },
  { city: 'McLean County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Medora', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mercer County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Minnewaukan', state: 'ND', state_name: 'North Dakota' },
  { city: 'Minot', state: 'ND', state_name: 'North Dakota' },
  { city: 'Minot Air Force Base', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mohall', state: 'ND', state_name: 'North Dakota' },
  { city: 'Morton County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mott', state: 'ND', state_name: 'North Dakota' },
  { city: 'Mountrail County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Napoleon', state: 'ND', state_name: 'North Dakota' },
  { city: 'Nelson County', state: 'ND', state_name: 'North Dakota' },
  { city: 'New Rockford', state: 'ND', state_name: 'North Dakota' },
  { city: 'New Town', state: 'ND', state_name: 'North Dakota' },
  { city: 'Oakes', state: 'ND', state_name: 'North Dakota' },
  { city: 'Oliver County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Park River', state: 'ND', state_name: 'North Dakota' },
  { city: 'Parshall', state: 'ND', state_name: 'North Dakota' },
  { city: 'Pembina County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Pierce County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ramsey County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ransom County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Renville County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Richland County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Rolette County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Rolla', state: 'ND', state_name: 'North Dakota' },
  { city: 'Rugby', state: 'ND', state_name: 'North Dakota' },
  { city: 'Sargent County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Sheldon', state: 'ND', state_name: 'North Dakota' },
  { city: 'Shell Valley', state: 'ND', state_name: 'North Dakota' },
  { city: 'Sheridan County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Sioux County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Slope County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Stanley', state: 'ND', state_name: 'North Dakota' },
  { city: 'Stanton', state: 'ND', state_name: 'North Dakota' },
  { city: 'Stark County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Steele', state: 'ND', state_name: 'North Dakota' },
  { city: 'Steele County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Stutsman County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Surrey', state: 'ND', state_name: 'North Dakota' },
  { city: 'Thompson', state: 'ND', state_name: 'North Dakota' },
  { city: 'Tioga', state: 'ND', state_name: 'North Dakota' },
  { city: 'Towner', state: 'ND', state_name: 'North Dakota' },
  { city: 'Towner County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Traill County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Valley City', state: 'ND', state_name: 'North Dakota' },
  { city: 'Velva', state: 'ND', state_name: 'North Dakota' },
  { city: 'Wahpeton', state: 'ND', state_name: 'North Dakota' },
  { city: 'Walsh County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ward County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Washburn', state: 'ND', state_name: 'North Dakota' },
  { city: 'Watford City', state: 'ND', state_name: 'North Dakota' },
  { city: 'Wells County', state: 'ND', state_name: 'North Dakota' },
  { city: 'West Fargo', state: 'ND', state_name: 'North Dakota' },
  { city: 'Williams County', state: 'ND', state_name: 'North Dakota' },
  { city: 'Williston', state: 'ND', state_name: 'North Dakota' },
  { city: 'Ada', state: 'OH', state_name: 'Ohio' },
  { city: 'Adams County', state: 'OH', state_name: 'Ohio' },
  { city: 'Akron', state: 'OH', state_name: 'Ohio' },
  { city: 'Allen County', state: 'OH', state_name: 'Ohio' },
  { city: 'Alliance', state: 'OH', state_name: 'Ohio' },
  { city: 'Amberley', state: 'OH', state_name: 'Ohio' },
  { city: 'Amelia', state: 'OH', state_name: 'Ohio' },
  { city: 'Amherst', state: 'OH', state_name: 'Ohio' },
  { city: 'Andover', state: 'OH', state_name: 'Ohio' },
  { city: 'Anna', state: 'OH', state_name: 'Ohio' },
  { city: 'Ansonia', state: 'OH', state_name: 'Ohio' },
  { city: 'Antwerp', state: 'OH', state_name: 'Ohio' },
  { city: 'Apple Creek', state: 'OH', state_name: 'Ohio' },
  { city: 'Apple Valley', state: 'OH', state_name: 'Ohio' },
  { city: 'Arcanum', state: 'OH', state_name: 'Ohio' },
  { city: 'Archbold', state: 'OH', state_name: 'Ohio' },
  { city: 'Arlington', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashland', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashland County', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashley', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashtabula', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashtabula County', state: 'OH', state_name: 'Ohio' },
  { city: 'Ashville', state: 'OH', state_name: 'Ohio' },
  { city: 'Athens', state: 'OH', state_name: 'Ohio' },
  { city: 'Athens County', state: 'OH', state_name: 'Ohio' },
  { city: 'Auglaize County', state: 'OH', state_name: 'Ohio' },
  { city: 'Aurora', state: 'OH', state_name: 'Ohio' },
  { city: 'Austintown', state: 'OH', state_name: 'Ohio' },
  { city: 'Avon', state: 'OH', state_name: 'Ohio' },
  { city: 'Avon Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Avon Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Bainbridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Ballville', state: 'OH', state_name: 'Ohio' },
  { city: 'Baltimore', state: 'OH', state_name: 'Ohio' },
  { city: 'Barberton', state: 'OH', state_name: 'Ohio' },
  { city: 'Barnesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Batavia', state: 'OH', state_name: 'Ohio' },
  { city: 'Bay Village', state: 'OH', state_name: 'Ohio' },
  { city: 'Beach City', state: 'OH', state_name: 'Ohio' },
  { city: 'Beachwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Beavercreek', state: 'OH', state_name: 'Ohio' },
  { city: 'Beckett Ridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Bedford', state: 'OH', state_name: 'Ohio' },
  { city: 'Bedford Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Beechwood Trails', state: 'OH', state_name: 'Ohio' },
  { city: 'Bellaire', state: 'OH', state_name: 'Ohio' },
  { city: 'Bellbrook', state: 'OH', state_name: 'Ohio' },
  { city: 'Bellefontaine', state: 'OH', state_name: 'Ohio' },
  { city: 'Bellevue', state: 'OH', state_name: 'Ohio' },
  { city: 'Bellville', state: 'OH', state_name: 'Ohio' },
  { city: 'Belmont County', state: 'OH', state_name: 'Ohio' },
  { city: 'Belpre', state: 'OH', state_name: 'Ohio' },
  { city: 'Berea', state: 'OH', state_name: 'Ohio' },
  { city: 'Bethel', state: 'OH', state_name: 'Ohio' },
  { city: 'Bethesda', state: 'OH', state_name: 'Ohio' },
  { city: 'Beverly', state: 'OH', state_name: 'Ohio' },
  { city: 'Bexley', state: 'OH', state_name: 'Ohio' },
  { city: 'Blacklick Estates', state: 'OH', state_name: 'Ohio' },
  { city: 'Blanchester', state: 'OH', state_name: 'Ohio' },
  { city: 'Blue Ash', state: 'OH', state_name: 'Ohio' },
  { city: 'Bluffton', state: 'OH', state_name: 'Ohio' },
  { city: 'Boardman', state: 'OH', state_name: 'Ohio' },
  { city: 'Bolindale', state: 'OH', state_name: 'Ohio' },
  { city: 'Boston Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Botkins', state: 'OH', state_name: 'Ohio' },
  { city: 'Bowling Green', state: 'OH', state_name: 'Ohio' },
  { city: 'Bradford', state: 'OH', state_name: 'Ohio' },
  { city: 'Bradner', state: 'OH', state_name: 'Ohio' },
  { city: 'Bratenahl', state: 'OH', state_name: 'Ohio' },
  { city: 'Brecksville', state: 'OH', state_name: 'Ohio' },
  { city: 'Bremen', state: 'OH', state_name: 'Ohio' },
  { city: 'Brewster', state: 'OH', state_name: 'Ohio' },
  { city: 'Bridgeport', state: 'OH', state_name: 'Ohio' },
  { city: 'Bridgetown', state: 'OH', state_name: 'Ohio' },
  { city: 'Brilliant', state: 'OH', state_name: 'Ohio' },
  { city: 'Brimfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Broadview Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Brook Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Brookfield Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Brooklyn', state: 'OH', state_name: 'Ohio' },
  { city: 'Brooklyn Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Brookville', state: 'OH', state_name: 'Ohio' },
  { city: 'Brown County', state: 'OH', state_name: 'Ohio' },
  { city: 'Brunswick', state: 'OH', state_name: 'Ohio' },
  { city: 'Bryan', state: 'OH', state_name: 'Ohio' },
  { city: 'Buckeye Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Bucyrus', state: 'OH', state_name: 'Ohio' },
  { city: 'Burlington', state: 'OH', state_name: 'Ohio' },
  { city: 'Burton', state: 'OH', state_name: 'Ohio' },
  { city: 'Butler County', state: 'OH', state_name: 'Ohio' },
  { city: 'Byesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Cadiz', state: 'OH', state_name: 'Ohio' },
  { city: 'Calcutta', state: 'OH', state_name: 'Ohio' },
  { city: 'Caldwell', state: 'OH', state_name: 'Ohio' },
  { city: 'Cambridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Camden', state: 'OH', state_name: 'Ohio' },
  { city: 'Campbell', state: 'OH', state_name: 'Ohio' },
  { city: 'Canal Fulton', state: 'OH', state_name: 'Ohio' },
  { city: 'Canal Winchester', state: 'OH', state_name: 'Ohio' },
  { city: 'Canfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Canton', state: 'OH', state_name: 'Ohio' },
  { city: 'Cardington', state: 'OH', state_name: 'Ohio' },
  { city: 'Carey', state: 'OH', state_name: 'Ohio' },
  { city: 'Carlisle', state: 'OH', state_name: 'Ohio' },
  { city: 'Carroll County', state: 'OH', state_name: 'Ohio' },
  { city: 'Carrollton', state: 'OH', state_name: 'Ohio' },
  { city: 'Cedarville', state: 'OH', state_name: 'Ohio' },
  { city: 'Celina', state: 'OH', state_name: 'Ohio' },
  { city: 'Centerburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Centerville', state: 'OH', state_name: 'Ohio' },
  { city: 'Chagrin Falls', state: 'OH', state_name: 'Ohio' },
  { city: 'Champaign County', state: 'OH', state_name: 'Ohio' },
  { city: 'Champion Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Chardon', state: 'OH', state_name: 'Ohio' },
  { city: 'Chauncey', state: 'OH', state_name: 'Ohio' },
  { city: 'Cherry Grove', state: 'OH', state_name: 'Ohio' },
  { city: 'Chesterland', state: 'OH', state_name: 'Ohio' },
  { city: 'Cheviot', state: 'OH', state_name: 'Ohio' },
  { city: 'Chillicothe', state: 'OH', state_name: 'Ohio' },
  { city: 'Choctaw Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Churchill', state: 'OH', state_name: 'Ohio' },
  { city: 'Cincinnati', state: 'OH', state_name: 'Ohio' },
  { city: 'Circleville', state: 'OH', state_name: 'Ohio' },
  { city: 'Clark County', state: 'OH', state_name: 'Ohio' },
  { city: 'Clark-Fulton', state: 'OH', state_name: 'Ohio' },
  { city: 'Clayton', state: 'OH', state_name: 'Ohio' },
  { city: 'Clermont County', state: 'OH', state_name: 'Ohio' },
  { city: 'Cleveland', state: 'OH', state_name: 'Ohio' },
  { city: 'Cleveland Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Cleves', state: 'OH', state_name: 'Ohio' },
  { city: 'Clinton', state: 'OH', state_name: 'Ohio' },
  { city: 'Clinton County', state: 'OH', state_name: 'Ohio' },
  { city: 'Clyde', state: 'OH', state_name: 'Ohio' },
  { city: 'Coal Grove', state: 'OH', state_name: 'Ohio' },
  { city: 'Coldwater', state: 'OH', state_name: 'Ohio' },
  { city: 'Collinwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Columbiana', state: 'OH', state_name: 'Ohio' },
  { city: 'Columbiana County', state: 'OH', state_name: 'Ohio' },
  { city: 'Columbus', state: 'OH', state_name: 'Ohio' },
  { city: 'Columbus Grove', state: 'OH', state_name: 'Ohio' },
  { city: 'Commercial Point', state: 'OH', state_name: 'Ohio' },
  { city: 'Conneaut', state: 'OH', state_name: 'Ohio' },
  { city: 'Continental', state: 'OH', state_name: 'Ohio' },
  { city: 'Convoy', state: 'OH', state_name: 'Ohio' },
  { city: 'Copley', state: 'OH', state_name: 'Ohio' },
  { city: 'Cortland', state: 'OH', state_name: 'Ohio' },
  { city: 'Coshocton', state: 'OH', state_name: 'Ohio' },
  { city: 'Coshocton County', state: 'OH', state_name: 'Ohio' },
  { city: 'Covedale', state: 'OH', state_name: 'Ohio' },
  { city: 'Covington', state: 'OH', state_name: 'Ohio' },
  { city: 'Craig Beach', state: 'OH', state_name: 'Ohio' },
  { city: 'Crawford County', state: 'OH', state_name: 'Ohio' },
  { city: 'Crestline', state: 'OH', state_name: 'Ohio' },
  { city: 'Creston', state: 'OH', state_name: 'Ohio' },
  { city: 'Cridersville', state: 'OH', state_name: 'Ohio' },
  { city: 'Crooksville', state: 'OH', state_name: 'Ohio' },
  { city: 'Crystal Lakes', state: 'OH', state_name: 'Ohio' },
  { city: 'Curtice', state: 'OH', state_name: 'Ohio' },
  { city: 'Cuyahoga County', state: 'OH', state_name: 'Ohio' },
  { city: 'Cuyahoga Falls', state: 'OH', state_name: 'Ohio' },
  { city: 'Dalton', state: 'OH', state_name: 'Ohio' },
  { city: 'Danville', state: 'OH', state_name: 'Ohio' },
  { city: 'Darke County', state: 'OH', state_name: 'Ohio' },
  { city: 'Day Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Dayton', state: 'OH', state_name: 'Ohio' },
  { city: 'De Graff', state: 'OH', state_name: 'Ohio' },
  { city: 'Deer Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Defiance', state: 'OH', state_name: 'Ohio' },
  { city: 'Defiance County', state: 'OH', state_name: 'Ohio' },
  { city: 'Delaware', state: 'OH', state_name: 'Ohio' },
  { city: 'Delaware County', state: 'OH', state_name: 'Ohio' },
  { city: 'Delhi Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Delphos', state: 'OH', state_name: 'Ohio' },
  { city: 'Delta', state: 'OH', state_name: 'Ohio' },
  { city: 'Dennison', state: 'OH', state_name: 'Ohio' },
  { city: 'Dent', state: 'OH', state_name: 'Ohio' },
  { city: 'Deshler', state: 'OH', state_name: 'Ohio' },
  { city: 'Detroit-Shoreway', state: 'OH', state_name: 'Ohio' },
  { city: 'Devola', state: 'OH', state_name: 'Ohio' },
  { city: 'Dillonvale', state: 'OH', state_name: 'Ohio' },
  { city: 'Dover', state: 'OH', state_name: 'Ohio' },
  { city: 'Doylestown', state: 'OH', state_name: 'Ohio' },
  { city: 'Dresden', state: 'OH', state_name: 'Ohio' },
  { city: 'Drexel', state: 'OH', state_name: 'Ohio' },
  { city: 'Dry Ridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Dry Run', state: 'OH', state_name: 'Ohio' },
  { city: 'Dublin', state: 'OH', state_name: 'Ohio' },
  { city: 'Dunlap', state: 'OH', state_name: 'Ohio' },
  { city: 'East Canton', state: 'OH', state_name: 'Ohio' },
  { city: 'East Cleveland', state: 'OH', state_name: 'Ohio' },
  { city: 'East Liverpool', state: 'OH', state_name: 'Ohio' },
  { city: 'East Palestine', state: 'OH', state_name: 'Ohio' },
  { city: 'Eastlake', state: 'OH', state_name: 'Ohio' },
  { city: 'Eaton', state: 'OH', state_name: 'Ohio' },
  { city: 'Eaton Estates', state: 'OH', state_name: 'Ohio' },
  { city: 'Edgerton', state: 'OH', state_name: 'Ohio' },
  { city: 'Edgewood', state: 'OH', state_name: 'Ohio' },
  { city: 'Elida', state: 'OH', state_name: 'Ohio' },
  { city: 'Elmore', state: 'OH', state_name: 'Ohio' },
  { city: 'Elmwood Place', state: 'OH', state_name: 'Ohio' },
  { city: 'Elyria', state: 'OH', state_name: 'Ohio' },
  { city: 'Englewood', state: 'OH', state_name: 'Ohio' },
  { city: 'Enon', state: 'OH', state_name: 'Ohio' },
  { city: 'Erie County', state: 'OH', state_name: 'Ohio' },
  { city: 'Etna', state: 'OH', state_name: 'Ohio' },
  { city: 'Euclid', state: 'OH', state_name: 'Ohio' },
  { city: 'Evendale', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairborn', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairfax', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairfield Beach', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairfield County', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairlawn', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairport Harbor', state: 'OH', state_name: 'Ohio' },
  { city: 'Fairview Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Farmersville', state: 'OH', state_name: 'Ohio' },
  { city: 'Fayette', state: 'OH', state_name: 'Ohio' },
  { city: 'Fayette County', state: 'OH', state_name: 'Ohio' },
  { city: 'Findlay', state: 'OH', state_name: 'Ohio' },
  { city: 'Finneytown', state: 'OH', state_name: 'Ohio' },
  { city: 'Five Points', state: 'OH', state_name: 'Ohio' },
  { city: 'Forest', state: 'OH', state_name: 'Ohio' },
  { city: 'Forest Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Forestville', state: 'OH', state_name: 'Ohio' },
  { city: 'Fort Loramie', state: 'OH', state_name: 'Ohio' },
  { city: 'Fort McKinley', state: 'OH', state_name: 'Ohio' },
  { city: 'Fort Recovery', state: 'OH', state_name: 'Ohio' },
  { city: 'Fort Shawnee', state: 'OH', state_name: 'Ohio' },
  { city: 'Fostoria', state: 'OH', state_name: 'Ohio' },
  { city: 'Frankfort', state: 'OH', state_name: 'Ohio' },
  { city: 'Franklin', state: 'OH', state_name: 'Ohio' },
  { city: 'Franklin County', state: 'OH', state_name: 'Ohio' },
  { city: 'Franklin Furnace', state: 'OH', state_name: 'Ohio' },
  { city: 'Frazeysburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Fredericktown', state: 'OH', state_name: 'Ohio' },
  { city: 'Fremont', state: 'OH', state_name: 'Ohio' },
  { city: 'Fruit Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'Fulton County', state: 'OH', state_name: 'Ohio' },
  { city: 'Gahanna', state: 'OH', state_name: 'Ohio' },
  { city: 'Galion', state: 'OH', state_name: 'Ohio' },
  { city: 'Gallia County', state: 'OH', state_name: 'Ohio' },
  { city: 'Gallipolis', state: 'OH', state_name: 'Ohio' },
  { city: 'Gambier', state: 'OH', state_name: 'Ohio' },
  { city: 'Garfield Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Garrettsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Gates Mills', state: 'OH', state_name: 'Ohio' },
  { city: 'Geauga County', state: 'OH', state_name: 'Ohio' },
  { city: 'Geneva', state: 'OH', state_name: 'Ohio' },
  { city: 'Geneva-on-the-Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Genoa', state: 'OH', state_name: 'Ohio' },
  { city: 'Georgetown', state: 'OH', state_name: 'Ohio' },
  { city: 'Germantown', state: 'OH', state_name: 'Ohio' },
  { city: 'Gibsonburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Girard', state: 'OH', state_name: 'Ohio' },
  { city: 'Glandorf', state: 'OH', state_name: 'Ohio' },
  { city: 'Glendale', state: 'OH', state_name: 'Ohio' },
  { city: 'Glenmoor', state: 'OH', state_name: 'Ohio' },
  { city: 'Glenville', state: 'OH', state_name: 'Ohio' },
  { city: 'Glouster', state: 'OH', state_name: 'Ohio' },
  { city: 'Gnadenhutten', state: 'OH', state_name: 'Ohio' },
  { city: 'Golf Manor', state: 'OH', state_name: 'Ohio' },
  { city: 'Goshen', state: 'OH', state_name: 'Ohio' },
  { city: 'Grafton', state: 'OH', state_name: 'Ohio' },
  { city: 'Grandview', state: 'OH', state_name: 'Ohio' },
  { city: 'Grandview Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Granville', state: 'OH', state_name: 'Ohio' },
  { city: 'Granville South', state: 'OH', state_name: 'Ohio' },
  { city: 'Green', state: 'OH', state_name: 'Ohio' },
  { city: 'Green Meadows', state: 'OH', state_name: 'Ohio' },
  { city: 'Green Springs', state: 'OH', state_name: 'Ohio' },
  { city: 'Greene County', state: 'OH', state_name: 'Ohio' },
  { city: 'Greenfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Greenhills', state: 'OH', state_name: 'Ohio' },
  { city: 'Greensburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Greentown', state: 'OH', state_name: 'Ohio' },
  { city: 'Greenville', state: 'OH', state_name: 'Ohio' },
  { city: 'Greenwich', state: 'OH', state_name: 'Ohio' },
  { city: 'Groesbeck', state: 'OH', state_name: 'Ohio' },
  { city: 'Grove City', state: 'OH', state_name: 'Ohio' },
  { city: 'Groveport', state: 'OH', state_name: 'Ohio' },
  { city: 'Guernsey County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hamilton', state: 'OH', state_name: 'Ohio' },
  { city: 'Hamilton County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hancock County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hanover', state: 'OH', state_name: 'Ohio' },
  { city: 'Harbor Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Hardin County', state: 'OH', state_name: 'Ohio' },
  { city: 'Harrison', state: 'OH', state_name: 'Ohio' },
  { city: 'Harrison County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hartville', state: 'OH', state_name: 'Ohio' },
  { city: 'Haskins', state: 'OH', state_name: 'Ohio' },
  { city: 'Heath', state: 'OH', state_name: 'Ohio' },
  { city: 'Hebron', state: 'OH', state_name: 'Ohio' },
  { city: 'Henry County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hicksville', state: 'OH', state_name: 'Ohio' },
  { city: 'Highland County', state: 'OH', state_name: 'Ohio' },
  { city: 'Highland Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Highpoint', state: 'OH', state_name: 'Ohio' },
  { city: 'Hilliard', state: 'OH', state_name: 'Ohio' },
  { city: 'Hillsboro', state: 'OH', state_name: 'Ohio' },
  { city: 'Hiram', state: 'OH', state_name: 'Ohio' },
  { city: 'Hocking County', state: 'OH', state_name: 'Ohio' },
  { city: 'Holgate', state: 'OH', state_name: 'Ohio' },
  { city: 'Holiday Valley', state: 'OH', state_name: 'Ohio' },
  { city: 'Holland', state: 'OH', state_name: 'Ohio' },
  { city: 'Holmes County', state: 'OH', state_name: 'Ohio' },
  { city: 'Hough', state: 'OH', state_name: 'Ohio' },
  { city: 'Howland Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Hubbard', state: 'OH', state_name: 'Ohio' },
  { city: 'Huber Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Huber Ridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Hudson', state: 'OH', state_name: 'Ohio' },
  { city: 'Hunter', state: 'OH', state_name: 'Ohio' },
  { city: 'Huron', state: 'OH', state_name: 'Ohio' },
  { city: 'Huron County', state: 'OH', state_name: 'Ohio' },
  { city: 'Independence', state: 'OH', state_name: 'Ohio' },
  { city: 'Ironton', state: 'OH', state_name: 'Ohio' },
  { city: 'Jackson', state: 'OH', state_name: 'Ohio' },
  { city: 'Jackson Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Jackson County', state: 'OH', state_name: 'Ohio' },
  { city: 'Jamestown', state: 'OH', state_name: 'Ohio' },
  { city: 'Jefferson', state: 'OH', state_name: 'Ohio' },
  { city: 'Jefferson County', state: 'OH', state_name: 'Ohio' },
  { city: 'Jeffersonville', state: 'OH', state_name: 'Ohio' },
  { city: 'Johnstown', state: 'OH', state_name: 'Ohio' },
  { city: 'Kalida', state: 'OH', state_name: 'Ohio' },
  { city: 'Kent', state: 'OH', state_name: 'Ohio' },
  { city: 'Kenton', state: 'OH', state_name: 'Ohio' },
  { city: 'Kenwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Kettering', state: 'OH', state_name: 'Ohio' },
  { city: 'Kings Mills', state: 'OH', state_name: 'Ohio' },
  { city: 'Kingston', state: 'OH', state_name: 'Ohio' },
  { city: 'Kirtland', state: 'OH', state_name: 'Ohio' },
  { city: 'Knox County', state: 'OH', state_name: 'Ohio' },
  { city: 'La Croft', state: 'OH', state_name: 'Ohio' },
  { city: 'Lagrange', state: 'OH', state_name: 'Ohio' },
  { city: 'Lake County', state: 'OH', state_name: 'Ohio' },
  { city: 'Lake Darby', state: 'OH', state_name: 'Ohio' },
  { city: 'Lake Lakengren', state: 'OH', state_name: 'Ohio' },
  { city: 'Lake Mohawk', state: 'OH', state_name: 'Ohio' },
  { city: 'Lakemore', state: 'OH', state_name: 'Ohio' },
  { city: 'Lakeview', state: 'OH', state_name: 'Ohio' },
  { city: 'Lakewood', state: 'OH', state_name: 'Ohio' },
  { city: 'Lancaster', state: 'OH', state_name: 'Ohio' },
  { city: 'Landen', state: 'OH', state_name: 'Ohio' },
  { city: 'Lawrence County', state: 'OH', state_name: 'Ohio' },
  { city: 'Leavittsburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Lebanon', state: 'OH', state_name: 'Ohio' },
  { city: 'Leesburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Leetonia', state: 'OH', state_name: 'Ohio' },
  { city: 'Leipsic', state: 'OH', state_name: 'Ohio' },
  { city: 'Lewis Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Lewisburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Lexington', state: 'OH', state_name: 'Ohio' },
  { city: 'Liberty Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Licking County', state: 'OH', state_name: 'Ohio' },
  { city: 'Lima', state: 'OH', state_name: 'Ohio' },
  { city: 'Lincoln Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Lincoln Village', state: 'OH', state_name: 'Ohio' },
  { city: 'Lisbon', state: 'OH', state_name: 'Ohio' },
  { city: 'Lithopolis', state: 'OH', state_name: 'Ohio' },
  { city: 'Lockland', state: 'OH', state_name: 'Ohio' },
  { city: 'Lodi', state: 'OH', state_name: 'Ohio' },
  { city: 'Logan', state: 'OH', state_name: 'Ohio' },
  { city: 'Logan County', state: 'OH', state_name: 'Ohio' },
  { city: 'Logan Elm Village', state: 'OH', state_name: 'Ohio' },
  { city: 'London', state: 'OH', state_name: 'Ohio' },
  { city: 'Lorain', state: 'OH', state_name: 'Ohio' },
  { city: 'Lorain County', state: 'OH', state_name: 'Ohio' },
  { city: 'Lordstown', state: 'OH', state_name: 'Ohio' },
  { city: 'Loudonville', state: 'OH', state_name: 'Ohio' },
  { city: 'Louisville', state: 'OH', state_name: 'Ohio' },
  { city: 'Loveland', state: 'OH', state_name: 'Ohio' },
  { city: 'Loveland Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Lowellville', state: 'OH', state_name: 'Ohio' },
  { city: 'Lucas County', state: 'OH', state_name: 'Ohio' },
  { city: 'Lucasville', state: 'OH', state_name: 'Ohio' },
  { city: 'Luckey', state: 'OH', state_name: 'Ohio' },
  { city: 'Lynchburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Lyndhurst', state: 'OH', state_name: 'Ohio' },
  { city: 'Macedonia', state: 'OH', state_name: 'Ohio' },
  { city: 'Mack', state: 'OH', state_name: 'Ohio' },
  { city: 'Madeira', state: 'OH', state_name: 'Ohio' },
  { city: 'Madison', state: 'OH', state_name: 'Ohio' },
  { city: 'Madison County', state: 'OH', state_name: 'Ohio' },
  { city: 'Mahoning County', state: 'OH', state_name: 'Ohio' },
  { city: 'Malvern', state: 'OH', state_name: 'Ohio' },
  { city: 'Manchester', state: 'OH', state_name: 'Ohio' },
  { city: 'Mansfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Mantua', state: 'OH', state_name: 'Ohio' },
  { city: 'Maple Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Mariemont', state: 'OH', state_name: 'Ohio' },
  { city: 'Marietta', state: 'OH', state_name: 'Ohio' },
  { city: 'Marion', state: 'OH', state_name: 'Ohio' },
  { city: 'Marion County', state: 'OH', state_name: 'Ohio' },
  { city: 'Martins Ferry', state: 'OH', state_name: 'Ohio' },
  { city: 'Marysville', state: 'OH', state_name: 'Ohio' },
  { city: 'Mason', state: 'OH', state_name: 'Ohio' },
  { city: 'Massillon', state: 'OH', state_name: 'Ohio' },
  { city: 'Masury', state: 'OH', state_name: 'Ohio' },
  { city: 'Maumee', state: 'OH', state_name: 'Ohio' },
  { city: 'Mayfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Mayfield Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'McArthur', state: 'OH', state_name: 'Ohio' },
  { city: 'McComb', state: 'OH', state_name: 'Ohio' },
  { city: 'McConnelsville', state: 'OH', state_name: 'Ohio' },
  { city: 'McDonald', state: 'OH', state_name: 'Ohio' },
  { city: 'McKinley Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Mechanicsburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Medina', state: 'OH', state_name: 'Ohio' },
  { city: 'Medina County', state: 'OH', state_name: 'Ohio' },
  { city: 'Meigs County', state: 'OH', state_name: 'Ohio' },
  { city: 'Mentor', state: 'OH', state_name: 'Ohio' },
  { city: 'Mentor-on-the-Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Mercer County', state: 'OH', state_name: 'Ohio' },
  { city: 'Miami County', state: 'OH', state_name: 'Ohio' },
  { city: 'Miami Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Miamisburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Miamitown', state: 'OH', state_name: 'Ohio' },
  { city: 'Middleburg Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Middlefield', state: 'OH', state_name: 'Ohio' },
  { city: 'Middleport', state: 'OH', state_name: 'Ohio' },
  { city: 'Middletown', state: 'OH', state_name: 'Ohio' },
  { city: 'Milan', state: 'OH', state_name: 'Ohio' },
  { city: 'Milford', state: 'OH', state_name: 'Ohio' },
  { city: 'Millbury', state: 'OH', state_name: 'Ohio' },
  { city: 'Millersburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Millersport', state: 'OH', state_name: 'Ohio' },
  { city: 'Mineral Ridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Minerva', state: 'OH', state_name: 'Ohio' },
  { city: 'Minerva Park', state: 'OH', state_name: 'Ohio' },
  { city: 'Mingo Junction', state: 'OH', state_name: 'Ohio' },
  { city: 'Minster', state: 'OH', state_name: 'Ohio' },
  { city: 'Mogadore', state: 'OH', state_name: 'Ohio' },
  { city: 'Monfort Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Monroe', state: 'OH', state_name: 'Ohio' },
  { city: 'Monroe County', state: 'OH', state_name: 'Ohio' },
  { city: 'Monroeville', state: 'OH', state_name: 'Ohio' },
  { city: 'Montgomery', state: 'OH', state_name: 'Ohio' },
  { city: 'Montgomery County', state: 'OH', state_name: 'Ohio' },
  { city: 'Montpelier', state: 'OH', state_name: 'Ohio' },
  { city: 'Montrose-Ghent', state: 'OH', state_name: 'Ohio' },
  { city: 'Moraine', state: 'OH', state_name: 'Ohio' },
  { city: 'Moreland Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Morgan County', state: 'OH', state_name: 'Ohio' },
  { city: 'Morgandale', state: 'OH', state_name: 'Ohio' },
  { city: 'Morrow', state: 'OH', state_name: 'Ohio' },
  { city: 'Morrow County', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Carmel', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Gilead', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Healthy', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Healthy Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Orab', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Repose', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Sterling', state: 'OH', state_name: 'Ohio' },
  { city: 'Mount Vernon', state: 'OH', state_name: 'Ohio' },
  { city: 'Mulberry', state: 'OH', state_name: 'Ohio' },
  { city: 'Munroe Falls', state: 'OH', state_name: 'Ohio' },
  { city: 'Muskingum County', state: 'OH', state_name: 'Ohio' },
  { city: 'Napoleon', state: 'OH', state_name: 'Ohio' },
  { city: 'Navarre', state: 'OH', state_name: 'Ohio' },
  { city: 'Nelsonville', state: 'OH', state_name: 'Ohio' },
  { city: 'New Albany', state: 'OH', state_name: 'Ohio' },
  { city: 'New Boston', state: 'OH', state_name: 'Ohio' },
  { city: 'New Bremen', state: 'OH', state_name: 'Ohio' },
  { city: 'New Burlington', state: 'OH', state_name: 'Ohio' },
  { city: 'New California', state: 'OH', state_name: 'Ohio' },
  { city: 'New Carlisle', state: 'OH', state_name: 'Ohio' },
  { city: 'New Concord', state: 'OH', state_name: 'Ohio' },
  { city: 'New Franklin', state: 'OH', state_name: 'Ohio' },
  { city: 'New Lebanon', state: 'OH', state_name: 'Ohio' },
  { city: 'New Lexington', state: 'OH', state_name: 'Ohio' },
  { city: 'New London', state: 'OH', state_name: 'Ohio' },
  { city: 'New Matamoras', state: 'OH', state_name: 'Ohio' },
  { city: 'New Miami', state: 'OH', state_name: 'Ohio' },
  { city: 'New Middletown', state: 'OH', state_name: 'Ohio' },
  { city: 'New Paris', state: 'OH', state_name: 'Ohio' },
  { city: 'New Philadelphia', state: 'OH', state_name: 'Ohio' },
  { city: 'New Richmond', state: 'OH', state_name: 'Ohio' },
  { city: 'New Vienna', state: 'OH', state_name: 'Ohio' },
  { city: 'New Waterford', state: 'OH', state_name: 'Ohio' },
  { city: 'Newark', state: 'OH', state_name: 'Ohio' },
  { city: 'Newburgh Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Newcomerstown', state: 'OH', state_name: 'Ohio' },
  { city: 'Newport', state: 'OH', state_name: 'Ohio' },
  { city: 'Newton Falls', state: 'OH', state_name: 'Ohio' },
  { city: 'Newtown', state: 'OH', state_name: 'Ohio' },
  { city: 'Niles', state: 'OH', state_name: 'Ohio' },
  { city: 'Noble County', state: 'OH', state_name: 'Ohio' },
  { city: 'North Baltimore', state: 'OH', state_name: 'Ohio' },
  { city: 'North Canton', state: 'OH', state_name: 'Ohio' },
  { city: 'North College Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'North Fork Village', state: 'OH', state_name: 'Ohio' },
  { city: 'North Kingsville', state: 'OH', state_name: 'Ohio' },
  { city: 'North Lewisburg', state: 'OH', state_name: 'Ohio' },
  { city: 'North Madison', state: 'OH', state_name: 'Ohio' },
  { city: 'North Olmsted', state: 'OH', state_name: 'Ohio' },
  { city: 'North Randall', state: 'OH', state_name: 'Ohio' },
  { city: 'North Ridgeville', state: 'OH', state_name: 'Ohio' },
  { city: 'North Royalton', state: 'OH', state_name: 'Ohio' },
  { city: 'North Zanesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Northbrook', state: 'OH', state_name: 'Ohio' },
  { city: 'Northfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Northgate', state: 'OH', state_name: 'Ohio' },
  { city: 'Northridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Northwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Norton', state: 'OH', state_name: 'Ohio' },
  { city: 'Norwalk', state: 'OH', state_name: 'Ohio' },
  { city: 'Norwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Oak Harbor', state: 'OH', state_name: 'Ohio' },
  { city: 'Oak Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'Oakwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Oberlin', state: 'OH', state_name: 'Ohio' },
  { city: 'Obetz', state: 'OH', state_name: 'Ohio' },
  { city: 'Olmsted Falls', state: 'OH', state_name: 'Ohio' },
  { city: 'Ontario', state: 'OH', state_name: 'Ohio' },
  { city: 'Orange', state: 'OH', state_name: 'Ohio' },
  { city: 'Oregon', state: 'OH', state_name: 'Ohio' },
  { city: 'Orrville', state: 'OH', state_name: 'Ohio' },
  { city: 'Orwell', state: 'OH', state_name: 'Ohio' },
  { city: 'Ottawa', state: 'OH', state_name: 'Ohio' },
  { city: 'Ottawa County', state: 'OH', state_name: 'Ohio' },
  { city: 'Ottawa Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Oxford', state: 'OH', state_name: 'Ohio' },
  { city: 'Painesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Pandora', state: 'OH', state_name: 'Ohio' },
  { city: 'Park Layne', state: 'OH', state_name: 'Ohio' },
  { city: 'Parma', state: 'OH', state_name: 'Ohio' },
  { city: 'Parma Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Pataskala', state: 'OH', state_name: 'Ohio' },
  { city: 'Paulding', state: 'OH', state_name: 'Ohio' },
  { city: 'Paulding County', state: 'OH', state_name: 'Ohio' },
  { city: 'Payne', state: 'OH', state_name: 'Ohio' },
  { city: 'Peebles', state: 'OH', state_name: 'Ohio' },
  { city: 'Pemberville', state: 'OH', state_name: 'Ohio' },
  { city: 'Pepper Pike', state: 'OH', state_name: 'Ohio' },
  { city: 'Perry', state: 'OH', state_name: 'Ohio' },
  { city: 'Perry County', state: 'OH', state_name: 'Ohio' },
  { city: 'Perry Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Perrysburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Pickaway County', state: 'OH', state_name: 'Ohio' },
  { city: 'Pickerington', state: 'OH', state_name: 'Ohio' },
  { city: 'Pike County', state: 'OH', state_name: 'Ohio' },
  { city: 'Piketon', state: 'OH', state_name: 'Ohio' },
  { city: 'Pioneer', state: 'OH', state_name: 'Ohio' },
  { city: 'Piqua', state: 'OH', state_name: 'Ohio' },
  { city: 'Plain City', state: 'OH', state_name: 'Ohio' },
  { city: 'Pleasant Grove', state: 'OH', state_name: 'Ohio' },
  { city: 'Pleasant Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'Pleasant Run', state: 'OH', state_name: 'Ohio' },
  { city: 'Pleasant Run Farm', state: 'OH', state_name: 'Ohio' },
  { city: 'Plymouth', state: 'OH', state_name: 'Ohio' },
  { city: 'Poland', state: 'OH', state_name: 'Ohio' },
  { city: 'Pomeroy', state: 'OH', state_name: 'Ohio' },
  { city: 'Port Clinton', state: 'OH', state_name: 'Ohio' },
  { city: 'Portage County', state: 'OH', state_name: 'Ohio' },
  { city: 'Portage Lakes', state: 'OH', state_name: 'Ohio' },
  { city: 'Portsmouth', state: 'OH', state_name: 'Ohio' },
  { city: 'Powell', state: 'OH', state_name: 'Ohio' },
  { city: 'Powhatan Point', state: 'OH', state_name: 'Ohio' },
  { city: 'Preble County', state: 'OH', state_name: 'Ohio' },
  { city: 'Prospect', state: 'OH', state_name: 'Ohio' },
  { city: 'Putnam County', state: 'OH', state_name: 'Ohio' },
  { city: 'Ravenna', state: 'OH', state_name: 'Ohio' },
  { city: 'Reading', state: 'OH', state_name: 'Ohio' },
  { city: 'Reminderville', state: 'OH', state_name: 'Ohio' },
  { city: 'Reno', state: 'OH', state_name: 'Ohio' },
  { city: 'Reynoldsburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Richfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Richland County', state: 'OH', state_name: 'Ohio' },
  { city: 'Richmond Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Richville', state: 'OH', state_name: 'Ohio' },
  { city: 'Richwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Ripley', state: 'OH', state_name: 'Ohio' },
  { city: 'Rittman', state: 'OH', state_name: 'Ohio' },
  { city: 'Riverside', state: 'OH', state_name: 'Ohio' },
  { city: 'Roaming Shores', state: 'OH', state_name: 'Ohio' },
  { city: 'Rockford', state: 'OH', state_name: 'Ohio' },
  { city: 'Rocky River', state: 'OH', state_name: 'Ohio' },
  { city: 'Rosemount', state: 'OH', state_name: 'Ohio' },
  { city: 'Roseville', state: 'OH', state_name: 'Ohio' },
  { city: 'Ross', state: 'OH', state_name: 'Ohio' },
  { city: 'Ross County', state: 'OH', state_name: 'Ohio' },
  { city: 'Rossford', state: 'OH', state_name: 'Ohio' },
  { city: 'Rossmoyne', state: 'OH', state_name: 'Ohio' },
  { city: 'Russells Point', state: 'OH', state_name: 'Ohio' },
  { city: 'Sabina', state: 'OH', state_name: 'Ohio' },
  { city: 'Saint Bernard', state: 'OH', state_name: 'Ohio' },
  { city: 'Saint Clairsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Saint Henry', state: 'OH', state_name: 'Ohio' },
  { city: 'Saint Marys', state: 'OH', state_name: 'Ohio' },
  { city: 'Saint Paris', state: 'OH', state_name: 'Ohio' },
  { city: 'Salem', state: 'OH', state_name: 'Ohio' },
  { city: 'Salem Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Salineville', state: 'OH', state_name: 'Ohio' },
  { city: 'Sandusky', state: 'OH', state_name: 'Ohio' },
  { city: 'Sandusky County', state: 'OH', state_name: 'Ohio' },
  { city: 'Sawyerwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Scioto County', state: 'OH', state_name: 'Ohio' },
  { city: 'Sciotodale', state: 'OH', state_name: 'Ohio' },
  { city: 'Sebring', state: 'OH', state_name: 'Ohio' },
  { city: 'Seneca County', state: 'OH', state_name: 'Ohio' },
  { city: 'Seven Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Seville', state: 'OH', state_name: 'Ohio' },
  { city: 'Shadyside', state: 'OH', state_name: 'Ohio' },
  { city: 'Shaker Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Sharonville', state: 'OH', state_name: 'Ohio' },
  { city: 'Shawnee Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Sheffield', state: 'OH', state_name: 'Ohio' },
  { city: 'Sheffield Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Shelby', state: 'OH', state_name: 'Ohio' },
  { city: 'Shelby County', state: 'OH', state_name: 'Ohio' },
  { city: 'Sherwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Shiloh', state: 'OH', state_name: 'Ohio' },
  { city: 'Shreve', state: 'OH', state_name: 'Ohio' },
  { city: 'Sidney', state: 'OH', state_name: 'Ohio' },
  { city: 'Silver Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Silverton', state: 'OH', state_name: 'Ohio' },
  { city: 'Sixteen Mile Stand', state: 'OH', state_name: 'Ohio' },
  { city: 'Skyline Acres', state: 'OH', state_name: 'Ohio' },
  { city: 'Smithville', state: 'OH', state_name: 'Ohio' },
  { city: 'Solon', state: 'OH', state_name: 'Ohio' },
  { city: 'Somerset', state: 'OH', state_name: 'Ohio' },
  { city: 'South Amherst', state: 'OH', state_name: 'Ohio' },
  { city: 'South Bloomfield', state: 'OH', state_name: 'Ohio' },
  { city: 'South Canal', state: 'OH', state_name: 'Ohio' },
  { city: 'South Charleston', state: 'OH', state_name: 'Ohio' },
  { city: 'South Euclid', state: 'OH', state_name: 'Ohio' },
  { city: 'South Lebanon', state: 'OH', state_name: 'Ohio' },
  { city: 'South Point', state: 'OH', state_name: 'Ohio' },
  { city: 'South Russell', state: 'OH', state_name: 'Ohio' },
  { city: 'South Zanesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Spencerville', state: 'OH', state_name: 'Ohio' },
  { city: 'Springboro', state: 'OH', state_name: 'Ohio' },
  { city: 'Springdale', state: 'OH', state_name: 'Ohio' },
  { city: 'Springfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Stark County', state: 'OH', state_name: 'Ohio' },
  { city: 'Steubenville', state: 'OH', state_name: 'Ohio' },
  { city: 'Stony Prairie', state: 'OH', state_name: 'Ohio' },
  { city: 'Stow', state: 'OH', state_name: 'Ohio' },
  { city: 'Strasburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Streetsboro', state: 'OH', state_name: 'Ohio' },
  { city: 'Strongsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Struthers', state: 'OH', state_name: 'Ohio' },
  { city: 'Stryker', state: 'OH', state_name: 'Ohio' },
  { city: 'Sugarcreek', state: 'OH', state_name: 'Ohio' },
  { city: 'Sugarcreek Police Dept', state: 'OH', state_name: 'Ohio' },
  { city: 'Summerside', state: 'OH', state_name: 'Ohio' },
  { city: 'Summit County', state: 'OH', state_name: 'Ohio' },
  { city: 'Sunbury', state: 'OH', state_name: 'Ohio' },
  { city: 'Swanton', state: 'OH', state_name: 'Ohio' },
  { city: 'Sylvania', state: 'OH', state_name: 'Ohio' },
  { city: 'Tallmadge', state: 'OH', state_name: 'Ohio' },
  { city: 'Terrace Park', state: 'OH', state_name: 'Ohio' },
  { city: 'The Plains', state: 'OH', state_name: 'Ohio' },
  { city: 'The Village of Indian Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'Thornport', state: 'OH', state_name: 'Ohio' },
  { city: 'Tiffin', state: 'OH', state_name: 'Ohio' },
  { city: 'Tiltonsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Tipp City', state: 'OH', state_name: 'Ohio' },
  { city: 'Toledo', state: 'OH', state_name: 'Ohio' },
  { city: 'Toronto', state: 'OH', state_name: 'Ohio' },
  { city: 'Trenton', state: 'OH', state_name: 'Ohio' },
  { city: 'Trotwood', state: 'OH', state_name: 'Ohio' },
  { city: 'Troy', state: 'OH', state_name: 'Ohio' },
  { city: 'Trumbull County', state: 'OH', state_name: 'Ohio' },
  { city: 'Turpin Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Tuscarawas', state: 'OH', state_name: 'Ohio' },
  { city: 'Tuscarawas County', state: 'OH', state_name: 'Ohio' },
  { city: 'Twinsburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Uhrichsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Union', state: 'OH', state_name: 'Ohio' },
  { city: 'Union City', state: 'OH', state_name: 'Ohio' },
  { city: 'Union County', state: 'OH', state_name: 'Ohio' },
  { city: 'Uniontown', state: 'OH', state_name: 'Ohio' },
  { city: 'University Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Upper Arlington', state: 'OH', state_name: 'Ohio' },
  { city: 'Upper Sandusky', state: 'OH', state_name: 'Ohio' },
  { city: 'Urbana', state: 'OH', state_name: 'Ohio' },
  { city: 'Urbancrest', state: 'OH', state_name: 'Ohio' },
  { city: 'Utica', state: 'OH', state_name: 'Ohio' },
  { city: 'Valley View', state: 'OH', state_name: 'Ohio' },
  { city: 'Van Wert', state: 'OH', state_name: 'Ohio' },
  { city: 'Van Wert County', state: 'OH', state_name: 'Ohio' },
  { city: 'Vandalia', state: 'OH', state_name: 'Ohio' },
  { city: 'Vermilion', state: 'OH', state_name: 'Ohio' },
  { city: 'Vermilion-on-the-Lake', state: 'OH', state_name: 'Ohio' },
  { city: 'Versailles', state: 'OH', state_name: 'Ohio' },
  { city: 'Vinton County', state: 'OH', state_name: 'Ohio' },
  { city: 'Wadsworth', state: 'OH', state_name: 'Ohio' },
  { city: 'Wakeman', state: 'OH', state_name: 'Ohio' },
  { city: 'Walbridge', state: 'OH', state_name: 'Ohio' },
  { city: 'Walton Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Wapakoneta', state: 'OH', state_name: 'Ohio' },
  { city: 'Warren', state: 'OH', state_name: 'Ohio' },
  { city: 'Warren County', state: 'OH', state_name: 'Ohio' },
  { city: 'Warrensville Heights', state: 'OH', state_name: 'Ohio' },
  { city: 'Washington County', state: 'OH', state_name: 'Ohio' },
  { city: 'Washington Court House', state: 'OH', state_name: 'Ohio' },
  { city: 'Waterville', state: 'OH', state_name: 'Ohio' },
  { city: 'Wauseon', state: 'OH', state_name: 'Ohio' },
  { city: 'Waverly', state: 'OH', state_name: 'Ohio' },
  { city: 'Wayne County', state: 'OH', state_name: 'Ohio' },
  { city: 'Waynesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Wellington', state: 'OH', state_name: 'Ohio' },
  { city: 'Wellston', state: 'OH', state_name: 'Ohio' },
  { city: 'Wellsville', state: 'OH', state_name: 'Ohio' },
  { city: 'West Alexandria', state: 'OH', state_name: 'Ohio' },
  { city: 'West Carrollton City', state: 'OH', state_name: 'Ohio' },
  { city: 'West Hill', state: 'OH', state_name: 'Ohio' },
  { city: 'West Jefferson', state: 'OH', state_name: 'Ohio' },
  { city: 'West Lafayette', state: 'OH', state_name: 'Ohio' },
  { city: 'West Liberty', state: 'OH', state_name: 'Ohio' },
  { city: 'West Milton', state: 'OH', state_name: 'Ohio' },
  { city: 'West Portsmouth', state: 'OH', state_name: 'Ohio' },
  { city: 'West Salem', state: 'OH', state_name: 'Ohio' },
  { city: 'West Union', state: 'OH', state_name: 'Ohio' },
  { city: 'West Unity', state: 'OH', state_name: 'Ohio' },
  { city: 'Westerville', state: 'OH', state_name: 'Ohio' },
  { city: 'Westfield Center', state: 'OH', state_name: 'Ohio' },
  { city: 'Westlake', state: 'OH', state_name: 'Ohio' },
  { city: 'Weston', state: 'OH', state_name: 'Ohio' },
  { city: 'Wetherington', state: 'OH', state_name: 'Ohio' },
  { city: 'Wheelersburg', state: 'OH', state_name: 'Ohio' },
  { city: 'White Oak', state: 'OH', state_name: 'Ohio' },
  { city: 'Whitehall', state: 'OH', state_name: 'Ohio' },
  { city: 'Whitehouse', state: 'OH', state_name: 'Ohio' },
  { city: 'Wickliffe', state: 'OH', state_name: 'Ohio' },
  { city: 'Wilberforce', state: 'OH', state_name: 'Ohio' },
  { city: 'Willard', state: 'OH', state_name: 'Ohio' },
  { city: 'Williams County', state: 'OH', state_name: 'Ohio' },
  { city: 'Williamsburg', state: 'OH', state_name: 'Ohio' },
  { city: 'Williamsport', state: 'OH', state_name: 'Ohio' },
  { city: 'Willoughby', state: 'OH', state_name: 'Ohio' },
  { city: 'Willoughby Hills', state: 'OH', state_name: 'Ohio' },
  { city: 'Willowick', state: 'OH', state_name: 'Ohio' },
  { city: 'Wilmington', state: 'OH', state_name: 'Ohio' },
  { city: 'Winchester', state: 'OH', state_name: 'Ohio' },
  { city: 'Windham', state: 'OH', state_name: 'Ohio' },
  { city: 'Wintersville', state: 'OH', state_name: 'Ohio' },
  { city: 'Withamsville', state: 'OH', state_name: 'Ohio' },
  { city: 'Wolfhurst', state: 'OH', state_name: 'Ohio' },
  { city: 'Wood County', state: 'OH', state_name: 'Ohio' },
  { city: 'Woodlawn', state: 'OH', state_name: 'Ohio' },
  { city: 'Woodsfield', state: 'OH', state_name: 'Ohio' },
  { city: 'Woodville', state: 'OH', state_name: 'Ohio' },
  { city: 'Wooster', state: 'OH', state_name: 'Ohio' },
  { city: 'Worthington', state: 'OH', state_name: 'Ohio' },
  { city: 'Wright-Patterson AFB', state: 'OH', state_name: 'Ohio' },
  { city: 'Wyandot County', state: 'OH', state_name: 'Ohio' },
  { city: 'Wyoming', state: 'OH', state_name: 'Ohio' },
  { city: 'Xenia', state: 'OH', state_name: 'Ohio' },
  { city: 'Yellow Springs', state: 'OH', state_name: 'Ohio' },
  { city: 'Yorkville', state: 'OH', state_name: 'Ohio' },
  { city: 'Youngstown', state: 'OH', state_name: 'Ohio' },
  { city: 'Zanesville', state: 'OH', state_name: 'Ohio' },
  { city: 'Ada', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Adair County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Afton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Alfalfa County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Altus', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Alva', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Anadarko', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Antlers', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Apache', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Arapaho', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ardmore', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Arkoma', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Arnett', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Atoka', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Atoka County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Barnsdall', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bartlesville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Beaver', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Beaver County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Beckham County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Beggs', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bethany', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bethel Acres', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bixby', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Blackwell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Blaine County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Blanchard', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Boise City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Boley', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bray', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bristow', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Broken Arrow', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Broken Bow', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bryan County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Buffalo', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Burns Flat', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Bushyhead', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Byng', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cache', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Caddo', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Caddo County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Calera', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Canadian County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Carnegie', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Carter County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Catoosa', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Central High', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Chandler', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Checotah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Chelsea', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cherokee', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cherokee County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cheyenne', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Chickasha', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Choctaw', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Choctaw County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Chouteau', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cimarron County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Claremore', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cleora', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cleveland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cleveland County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Clinton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Coal County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Coalgate', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Colbert', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Collinsville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Comanche', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Comanche County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Commerce', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Copeland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cordell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cotton County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Coweta', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Craig County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Creek County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Crescent', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cushing', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Custer County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Cyril', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Davis', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Del City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Delaware County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Dewey', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Dewey County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Dickson', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Drumright', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Duncan', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Durant', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Edmond', state: 'OK', state_name: 'Oklahoma' },
  { city: 'El Reno', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Elgin', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Elk City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ellis County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Enid', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Erick', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Eufaula', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Fairfax', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Fairland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Fairview', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Fletcher', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Forest Park', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Fort Gibson', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Frederick', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Garfield County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Garvin County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Geary', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Geronimo', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Glenpool', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Goldsby', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Goodwell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Grady County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Granite', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Grant County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Greer County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Grove', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Guthrie', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Guymon', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hall Park', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Harmon County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Harper County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Harrah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hartshorne', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Haskell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Haskell County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Healdton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Heavener', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Helena', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hennessey', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Henryetta', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hinton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hobart', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Holdenville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hollis', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hominy', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hooker', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hughes County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Hugo', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Inola', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Jackson County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Jay', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Jefferson County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Jenks', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Johnston County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Jones', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Justice', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kay County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kellyville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kenwood', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kiefer', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kingfisher', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kingfisher County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kingston', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Kiowa County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Konawa', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Krebs', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Langston', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Latimer County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Laverne', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Lawton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Le Flore County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Lexington', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Lincoln County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Lindsay', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Locust Grove', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Logan County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Lone Grove', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Longtown', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Love County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Luther', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Madill', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Major County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mangum', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mannford', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Marietta', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Marlow', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Marshall County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Maud', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mayes County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Maysville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McAlester', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McClain County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McCord', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McCurtain County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McIntosh County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'McLoud', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Medford', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Meeker', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Meridian', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Miami', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Midwest City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Minco', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Moore', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mooreland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Morris', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mounds', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Muldrow', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Murray County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Muskogee', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Muskogee County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Mustang', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Newcastle', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Newkirk', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Nichols Hills', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Nicoma Park', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ninnekah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Noble', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Noble County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Norman', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Nowata', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Nowata County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oakhurst', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oakland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oilton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okarche', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okeene', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okemah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okfuskee County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oklahoma City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oklahoma County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okmulgee', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Okmulgee County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Oologah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Osage County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ottawa County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Owasso', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Panama', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Park Hill', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pauls Valley', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pawhuska', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pawnee', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pawnee County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Payne County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Perkins', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Perry', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Piedmont', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pink', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pittsburg County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pocola', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ponca City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pontotoc County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Poteau', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pottawatomie County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Prague', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pryor', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pryor Creek', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Purcell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Pushmataha County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Quinton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Ringling', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Roger Mills County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Rogers County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Roland', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Rush Springs', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Salina', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sallisaw', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sand Springs', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sapulpa', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sayre', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Seminole', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Seminole County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sequoyah County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Shattuck', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Shawnee', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Skiatook', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Slaughterville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Snyder', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Spencer', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sperry', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Spiro', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stephens County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stigler', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stillwater', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stilwell', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stratford', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Stroud', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Sulphur', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tahlequah', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Talihina', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Taloga', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tecumseh', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Texanna', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Texas County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'The Village', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Thomas', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tillman County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tishomingo', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tonkawa', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tulsa', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tulsa County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Turley', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Tuttle', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Union City', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Verdigris', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Vian', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Vinita', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wagoner', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wagoner County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Walters', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Warner', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Warr Acres', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Washington County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Washita County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Watonga', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Waukomis', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Waurika', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Weatherford', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Westville', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wetumka', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wewoka', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wilburton', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wilson', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wister', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Woods County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Woodward', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Woodward County', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Wynnewood', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Yale', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Yukon', state: 'OK', state_name: 'Oklahoma' },
  { city: 'Albany', state: 'OR', state_name: 'Oregon' },
  { city: 'Aloha', state: 'OR', state_name: 'Oregon' },
  { city: 'Altamont', state: 'OR', state_name: 'Oregon' },
  { city: 'Amity', state: 'OR', state_name: 'Oregon' },
  { city: 'Ashland', state: 'OR', state_name: 'Oregon' },
  { city: 'Astoria', state: 'OR', state_name: 'Oregon' },
  { city: 'Athena', state: 'OR', state_name: 'Oregon' },
  { city: 'Aumsville', state: 'OR', state_name: 'Oregon' },
  { city: 'Baker City', state: 'OR', state_name: 'Oregon' },
  { city: 'Baker County', state: 'OR', state_name: 'Oregon' },
  { city: 'Bandon', state: 'OR', state_name: 'Oregon' },
  { city: 'Banks', state: 'OR', state_name: 'Oregon' },
  { city: 'Barview', state: 'OR', state_name: 'Oregon' },
  { city: 'Bay City', state: 'OR', state_name: 'Oregon' },
  { city: 'Beavercreek', state: 'OR', state_name: 'Oregon' },
  { city: 'Beaverton', state: 'OR', state_name: 'Oregon' },
  { city: 'Bend', state: 'OR', state_name: 'Oregon' },
  { city: 'Benton County', state: 'OR', state_name: 'Oregon' },
  { city: 'Bethany', state: 'OR', state_name: 'Oregon' },
  { city: 'Boardman', state: 'OR', state_name: 'Oregon' },
  { city: 'Brookings', state: 'OR', state_name: 'Oregon' },
  { city: 'Brownsville', state: 'OR', state_name: 'Oregon' },
  { city: 'Bunker Hill', state: 'OR', state_name: 'Oregon' },
  { city: 'Burns', state: 'OR', state_name: 'Oregon' },
  { city: 'Canby', state: 'OR', state_name: 'Oregon' },
  { city: 'Cannon Beach', state: 'OR', state_name: 'Oregon' },
  { city: 'Canyon City', state: 'OR', state_name: 'Oregon' },
  { city: 'Canyonville', state: 'OR', state_name: 'Oregon' },
  { city: 'Carlton', state: 'OR', state_name: 'Oregon' },
  { city: 'Cascade Locks', state: 'OR', state_name: 'Oregon' },
  { city: 'Cave Junction', state: 'OR', state_name: 'Oregon' },
  { city: 'Cedar Hills', state: 'OR', state_name: 'Oregon' },
  { city: 'Cedar Mill', state: 'OR', state_name: 'Oregon' },
  { city: 'Central Point', state: 'OR', state_name: 'Oregon' },
  { city: 'Chenoweth', state: 'OR', state_name: 'Oregon' },
  { city: 'Clackamas', state: 'OR', state_name: 'Oregon' },
  { city: 'Clackamas County', state: 'OR', state_name: 'Oregon' },
  { city: 'Clatskanie', state: 'OR', state_name: 'Oregon' },
  { city: 'Clatsop County', state: 'OR', state_name: 'Oregon' },
  { city: 'Coburg', state: 'OR', state_name: 'Oregon' },
  { city: 'Columbia City', state: 'OR', state_name: 'Oregon' },
  { city: 'Columbia County', state: 'OR', state_name: 'Oregon' },
  { city: 'Condon', state: 'OR', state_name: 'Oregon' },
  { city: 'Coos Bay', state: 'OR', state_name: 'Oregon' },
  { city: 'Coos County', state: 'OR', state_name: 'Oregon' },
  { city: 'Coquille', state: 'OR', state_name: 'Oregon' },
  { city: 'Cornelius', state: 'OR', state_name: 'Oregon' },
  { city: 'Corvallis', state: 'OR', state_name: 'Oregon' },
  { city: 'Cottage Grove', state: 'OR', state_name: 'Oregon' },
  { city: 'Creswell', state: 'OR', state_name: 'Oregon' },
  { city: 'Crook County', state: 'OR', state_name: 'Oregon' },
  { city: 'Culp Creek', state: 'OR', state_name: 'Oregon' },
  { city: 'Culver', state: 'OR', state_name: 'Oregon' },
  { city: 'Curry County', state: 'OR', state_name: 'Oregon' },
  { city: 'Dallas', state: 'OR', state_name: 'Oregon' },
  { city: 'Damascus', state: 'OR', state_name: 'Oregon' },
  { city: 'Dayton', state: 'OR', state_name: 'Oregon' },
  { city: 'Depoe Bay', state: 'OR', state_name: 'Oregon' },
  { city: 'Deschutes County', state: 'OR', state_name: 'Oregon' },
  { city: 'Deschutes River Woods', state: 'OR', state_name: 'Oregon' },
  { city: 'Donald', state: 'OR', state_name: 'Oregon' },
  { city: 'Douglas County', state: 'OR', state_name: 'Oregon' },
  { city: 'Drain', state: 'OR', state_name: 'Oregon' },
  { city: 'Dundee', state: 'OR', state_name: 'Oregon' },
  { city: 'Dunes City', state: 'OR', state_name: 'Oregon' },
  { city: 'Durham', state: 'OR', state_name: 'Oregon' },
  { city: 'Eagle Point', state: 'OR', state_name: 'Oregon' },
  { city: 'Elgin', state: 'OR', state_name: 'Oregon' },
  { city: 'Enterprise', state: 'OR', state_name: 'Oregon' },
  { city: 'Estacada', state: 'OR', state_name: 'Oregon' },
  { city: 'Eugene', state: 'OR', state_name: 'Oregon' },
  { city: 'Fairview', state: 'OR', state_name: 'Oregon' },
  { city: 'Florence', state: 'OR', state_name: 'Oregon' },
  { city: 'Forest Grove', state: 'OR', state_name: 'Oregon' },
  { city: 'Fossil', state: 'OR', state_name: 'Oregon' },
  { city: 'Four Corners', state: 'OR', state_name: 'Oregon' },
  { city: 'Fruitdale', state: 'OR', state_name: 'Oregon' },
  { city: 'Garden Home-Whitford', state: 'OR', state_name: 'Oregon' },
  { city: 'Gearhart', state: 'OR', state_name: 'Oregon' },
  { city: 'Gervais', state: 'OR', state_name: 'Oregon' },
  { city: 'Gilliam County', state: 'OR', state_name: 'Oregon' },
  { city: 'Gladstone', state: 'OR', state_name: 'Oregon' },
  { city: 'Glide', state: 'OR', state_name: 'Oregon' },
  { city: 'Gold Beach', state: 'OR', state_name: 'Oregon' },
  { city: 'Gold Hill', state: 'OR', state_name: 'Oregon' },
  { city: 'Grand Ronde', state: 'OR', state_name: 'Oregon' },
  { city: 'Grant County', state: 'OR', state_name: 'Oregon' },
  { city: 'Grants Pass', state: 'OR', state_name: 'Oregon' },
  { city: 'Green', state: 'OR', state_name: 'Oregon' },
  { city: 'Gresham', state: 'OR', state_name: 'Oregon' },
  { city: 'Happy Valley', state: 'OR', state_name: 'Oregon' },
  { city: 'Harbor', state: 'OR', state_name: 'Oregon' },
  { city: 'Harney County', state: 'OR', state_name: 'Oregon' },
  { city: 'Harrisburg', state: 'OR', state_name: 'Oregon' },
  { city: 'Hayesville', state: 'OR', state_name: 'Oregon' },
  { city: 'Heppner', state: 'OR', state_name: 'Oregon' },
  { city: 'Hermiston', state: 'OR', state_name: 'Oregon' },
  { city: 'Hillsboro', state: 'OR', state_name: 'Oregon' },
  { city: 'Hines', state: 'OR', state_name: 'Oregon' },
  { city: 'Hood River', state: 'OR', state_name: 'Oregon' },
  { city: 'Hood River County', state: 'OR', state_name: 'Oregon' },
  { city: 'Hubbard', state: 'OR', state_name: 'Oregon' },
  { city: 'Independence', state: 'OR', state_name: 'Oregon' },
  { city: 'Irrigon', state: 'OR', state_name: 'Oregon' },
  { city: 'Island City', state: 'OR', state_name: 'Oregon' },
  { city: 'Jackson County', state: 'OR', state_name: 'Oregon' },
  { city: 'Jacksonville', state: 'OR', state_name: 'Oregon' },
  { city: 'Jefferson', state: 'OR', state_name: 'Oregon' },
  { city: 'Jefferson County', state: 'OR', state_name: 'Oregon' },
  { city: 'Jennings Lodge', state: 'OR', state_name: 'Oregon' },
  { city: 'John Day', state: 'OR', state_name: 'Oregon' },
  { city: 'Joseph', state: 'OR', state_name: 'Oregon' },
  { city: 'Josephine County', state: 'OR', state_name: 'Oregon' },
  { city: 'Junction City', state: 'OR', state_name: 'Oregon' },
  { city: 'Keizer', state: 'OR', state_name: 'Oregon' },
  { city: 'Kenton', state: 'OR', state_name: 'Oregon' },
  { city: 'King City', state: 'OR', state_name: 'Oregon' },
  { city: 'Klamath County', state: 'OR', state_name: 'Oregon' },
  { city: 'Klamath Falls', state: 'OR', state_name: 'Oregon' },
  { city: 'La Grande', state: 'OR', state_name: 'Oregon' },
  { city: 'La Pine', state: 'OR', state_name: 'Oregon' },
  { city: 'Lafayette', state: 'OR', state_name: 'Oregon' },
  { city: 'Lake County', state: 'OR', state_name: 'Oregon' },
  { city: 'Lake Oswego', state: 'OR', state_name: 'Oregon' },
  { city: 'Lakeside', state: 'OR', state_name: 'Oregon' },
  { city: 'Lakeview', state: 'OR', state_name: 'Oregon' },
  { city: 'Lane County', state: 'OR', state_name: 'Oregon' },
  { city: 'Lebanon', state: 'OR', state_name: 'Oregon' },
  { city: 'Lents', state: 'OR', state_name: 'Oregon' },
  { city: 'Lincoln Beach', state: 'OR', state_name: 'Oregon' },
  { city: 'Lincoln City', state: 'OR', state_name: 'Oregon' },
  { city: 'Lincoln County', state: 'OR', state_name: 'Oregon' },
  { city: 'Linn County', state: 'OR', state_name: 'Oregon' },
  { city: 'Lowell', state: 'OR', state_name: 'Oregon' },
  { city: 'Lyons', state: 'OR', state_name: 'Oregon' },
  { city: 'Madras', state: 'OR', state_name: 'Oregon' },
  { city: 'Malheur County', state: 'OR', state_name: 'Oregon' },
  { city: 'Marion County', state: 'OR', state_name: 'Oregon' },
  { city: 'McMinnville', state: 'OR', state_name: 'Oregon' },
  { city: 'Medford', state: 'OR', state_name: 'Oregon' },
  { city: 'Merlin', state: 'OR', state_name: 'Oregon' },
  { city: 'Metzger', state: 'OR', state_name: 'Oregon' },
  { city: 'Mill City', state: 'OR', state_name: 'Oregon' },
  { city: 'Millersburg', state: 'OR', state_name: 'Oregon' },
  { city: 'Milton-Freewater', state: 'OR', state_name: 'Oregon' },
  { city: 'Milwaukie', state: 'OR', state_name: 'Oregon' },
  { city: 'Mission', state: 'OR', state_name: 'Oregon' },
  { city: 'Molalla', state: 'OR', state_name: 'Oregon' },
  { city: 'Monmouth', state: 'OR', state_name: 'Oregon' },
  { city: 'Moro', state: 'OR', state_name: 'Oregon' },
  { city: 'Morrow County', state: 'OR', state_name: 'Oregon' },
  { city: 'Mount Angel', state: 'OR', state_name: 'Oregon' },
  { city: 'Mount Hood Village', state: 'OR', state_name: 'Oregon' },
  { city: 'Mulino', state: 'OR', state_name: 'Oregon' },
  { city: 'Multnomah County', state: 'OR', state_name: 'Oregon' },
  { city: 'Myrtle Creek', state: 'OR', state_name: 'Oregon' },
  { city: 'Myrtle Point', state: 'OR', state_name: 'Oregon' },
  { city: 'New Hope', state: 'OR', state_name: 'Oregon' },
  { city: 'Newberg', state: 'OR', state_name: 'Oregon' },
  { city: 'Newport', state: 'OR', state_name: 'Oregon' },
  { city: 'North Bend', state: 'OR', state_name: 'Oregon' },
  { city: 'North Plains', state: 'OR', state_name: 'Oregon' },
  { city: 'North Portland', state: 'OR', state_name: 'Oregon' },
  { city: 'Nyssa', state: 'OR', state_name: 'Oregon' },
  { city: 'Oak Grove', state: 'OR', state_name: 'Oregon' },
  { city: 'Oak Hills', state: 'OR', state_name: 'Oregon' },
  { city: 'Oakridge', state: 'OR', state_name: 'Oregon' },
  { city: 'Oatfield', state: 'OR', state_name: 'Oregon' },
  { city: 'Odell', state: 'OR', state_name: 'Oregon' },
  { city: 'Ontario', state: 'OR', state_name: 'Oregon' },
  { city: 'Oregon City', state: 'OR', state_name: 'Oregon' },
  { city: 'Pacific City', state: 'OR', state_name: 'Oregon' },
  { city: 'Pendleton', state: 'OR', state_name: 'Oregon' },
  { city: 'Philomath', state: 'OR', state_name: 'Oregon' },
  { city: 'Phoenix', state: 'OR', state_name: 'Oregon' },
  { city: 'Pilot Rock', state: 'OR', state_name: 'Oregon' },
  { city: 'Polk County', state: 'OR', state_name: 'Oregon' },
  { city: 'Port Orford', state: 'OR', state_name: 'Oregon' },
  { city: 'Portland', state: 'OR', state_name: 'Oregon' },
  { city: 'Prineville', state: 'OR', state_name: 'Oregon' },
  { city: 'Rainier', state: 'OR', state_name: 'Oregon' },
  { city: 'Raleigh Hills', state: 'OR', state_name: 'Oregon' },
  { city: 'Redmond', state: 'OR', state_name: 'Oregon' },
  { city: 'Redwood', state: 'OR', state_name: 'Oregon' },
  { city: 'Reedsport', state: 'OR', state_name: 'Oregon' },
  { city: 'Riddle', state: 'OR', state_name: 'Oregon' },
  { city: 'Rockaway Beach', state: 'OR', state_name: 'Oregon' },
  { city: 'Rockcreek', state: 'OR', state_name: 'Oregon' },
  { city: 'Rogue River', state: 'OR', state_name: 'Oregon' },
  { city: 'Rose Lodge', state: 'OR', state_name: 'Oregon' },
  { city: 'Roseburg', state: 'OR', state_name: 'Oregon' },
  { city: 'Roseburg North', state: 'OR', state_name: 'Oregon' },
  { city: 'Saint Helens', state: 'OR', state_name: 'Oregon' },
  { city: 'Salem', state: 'OR', state_name: 'Oregon' },
  { city: 'Sandy', state: 'OR', state_name: 'Oregon' },
  { city: 'Scappoose', state: 'OR', state_name: 'Oregon' },
  { city: 'Seaside', state: 'OR', state_name: 'Oregon' },
  { city: 'Shady Cove', state: 'OR', state_name: 'Oregon' },
  { city: 'Sheridan', state: 'OR', state_name: 'Oregon' },
  { city: 'Sherman County', state: 'OR', state_name: 'Oregon' },
  { city: 'Sherwood', state: 'OR', state_name: 'Oregon' },
  { city: 'Siletz', state: 'OR', state_name: 'Oregon' },
  { city: 'Silverton', state: 'OR', state_name: 'Oregon' },
  { city: 'Sisters', state: 'OR', state_name: 'Oregon' },
  { city: 'South Lebanon', state: 'OR', state_name: 'Oregon' },
  { city: 'Springfield', state: 'OR', state_name: 'Oregon' },
  { city: 'Stafford', state: 'OR', state_name: 'Oregon' },
  { city: 'Stanfield', state: 'OR', state_name: 'Oregon' },
  { city: 'Stayton', state: 'OR', state_name: 'Oregon' },
  { city: 'Sublimity', state: 'OR', state_name: 'Oregon' },
  { city: 'Sunriver', state: 'OR', state_name: 'Oregon' },
  { city: 'Sutherlin', state: 'OR', state_name: 'Oregon' },
  { city: 'Sweet Home', state: 'OR', state_name: 'Oregon' },
  { city: 'Talent', state: 'OR', state_name: 'Oregon' },
  { city: 'Tangent', state: 'OR', state_name: 'Oregon' },
  { city: 'Terrebonne', state: 'OR', state_name: 'Oregon' },
  { city: 'The Dalles', state: 'OR', state_name: 'Oregon' },
  { city: 'Three Rivers', state: 'OR', state_name: 'Oregon' },
  { city: 'Tigard', state: 'OR', state_name: 'Oregon' },
  { city: 'Tillamook', state: 'OR', state_name: 'Oregon' },
  { city: 'Tillamook County', state: 'OR', state_name: 'Oregon' },
  { city: 'Toledo', state: 'OR', state_name: 'Oregon' },
  { city: 'Tri-City', state: 'OR', state_name: 'Oregon' },
  { city: 'Troutdale', state: 'OR', state_name: 'Oregon' },
  { city: 'Tualatin', state: 'OR', state_name: 'Oregon' },
  { city: 'Turner', state: 'OR', state_name: 'Oregon' },
  { city: 'Umatilla', state: 'OR', state_name: 'Oregon' },
  { city: 'Umatilla County', state: 'OR', state_name: 'Oregon' },
  { city: 'Union', state: 'OR', state_name: 'Oregon' },
  { city: 'Union County', state: 'OR', state_name: 'Oregon' },
  { city: 'Vale', state: 'OR', state_name: 'Oregon' },
  { city: 'Veneta', state: 'OR', state_name: 'Oregon' },
  { city: 'Vernonia', state: 'OR', state_name: 'Oregon' },
  { city: 'Waldport', state: 'OR', state_name: 'Oregon' },
  { city: 'Wallowa County', state: 'OR', state_name: 'Oregon' },
  { city: 'Warm Springs', state: 'OR', state_name: 'Oregon' },
  { city: 'Warren', state: 'OR', state_name: 'Oregon' },
  { city: 'Warrenton', state: 'OR', state_name: 'Oregon' },
  { city: 'Wasco County', state: 'OR', state_name: 'Oregon' },
  { city: 'Washington County', state: 'OR', state_name: 'Oregon' },
  { city: 'West Haven', state: 'OR', state_name: 'Oregon' },
  { city: 'West Haven-Sylvan', state: 'OR', state_name: 'Oregon' },
  { city: 'West Linn', state: 'OR', state_name: 'Oregon' },
  { city: 'West Slope', state: 'OR', state_name: 'Oregon' },
  { city: 'Wheeler County', state: 'OR', state_name: 'Oregon' },
  { city: 'White City', state: 'OR', state_name: 'Oregon' },
  { city: 'Willamina', state: 'OR', state_name: 'Oregon' },
  { city: 'Williams', state: 'OR', state_name: 'Oregon' },
  { city: 'Wilsonville', state: 'OR', state_name: 'Oregon' },
  { city: 'Winston', state: 'OR', state_name: 'Oregon' },
  { city: 'Wood Village', state: 'OR', state_name: 'Oregon' },
  { city: 'Woodburn', state: 'OR', state_name: 'Oregon' },
  { city: 'Yamhill', state: 'OR', state_name: 'Oregon' },
  { city: 'Yamhill County', state: 'OR', state_name: 'Oregon' },
  { city: 'Yoncalla', state: 'OR', state_name: 'Oregon' },
  { city: 'Abbottstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Adams County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Adamstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Akron', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Albion', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Alburtis', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Aldan', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Aliquippa', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Allegheny County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Alleghenyville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Allentown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Allison Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Almedia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Altoona', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ambler', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ambridge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Amity Gardens', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ancient Oaks', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Annville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Apollo', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Archbald', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ardmore', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Arlington Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Armstrong County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Arnold', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ashland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ashley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Aspinwall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Atglen', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Athens', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Audubon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avalon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avis', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avoca', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avondale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Avonia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Back Mountain', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Baden', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Baidland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bainbridge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bakerstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bala-Cynwyd', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Baldwin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bally', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bangor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Barnesboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bath', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bear Rocks', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Beaver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Beaver County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Beaver Falls', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Beaverdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bedford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bedford County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bedminster', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Beech Mountain Lakes', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Belfast', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bell Acres', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Belle Vernon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bellefonte', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Belleville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bellevue', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bellwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Belmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ben Avon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bentleyville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Berks County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Berlin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Berwick', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Berwyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bessemer', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bethel Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bethlehem', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Big Bass Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Big Beaver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Biglerville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Birchwood Lakes', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Birdsboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Black Lick', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blair County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blairsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blakely', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blandon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blawnox', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bloomfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bloomsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blossburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blue Ball', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Blue Bell', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Boalsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Boiling Springs', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bonneauville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Boothwyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Boswell', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bowmansville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Boyertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brackenridge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Braddock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Braddock Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bradford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bradford County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bradford Woods', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Breinigsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brentwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bressler', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brickerville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bridgeport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bridgeville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bristol', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brittany Farms-Highlands', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brockway', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brodheadsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brookhaven', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brookville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Broomall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brownstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Brownsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Browntown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bryn Athyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bryn Mawr', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Bucks County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Burgettstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Burnham', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Butler', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Butler County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'California', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Caln', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Calumet', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cambria County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cambridge Springs', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cameron County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Camp Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Campbelltown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Canonsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Canton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carbon County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carbondale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carlisle', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carnegie', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carnot-Moon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Carroll Valley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Castanea', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Castle Shannon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Catasauqua', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Catawissa', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cecil-Bishop', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cementon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Center City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Centerville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Central City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Centre County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Centre Hall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cetronia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chalfont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chambersburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Charleroi', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cherryville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chester', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chester County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chester Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chester Springs', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chesterbrook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cheswick', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chevy Chase Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chicora', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Chinchilla', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Christiana', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Church Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Churchill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Churchville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clairton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clarion', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clarion County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clarks Green', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clarks Summit', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clay', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Claysburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clearfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clearfield County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cleona', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clifton Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clinton County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Clymer', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coaldale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coatesville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cochranton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Collegeville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Collingdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Collinsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Colonial Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Colony Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Columbia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Columbia County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Colwyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conashaugh Lakes', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conemaugh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conestoga', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conneaut Lakeshore', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Connellsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conshohocken', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conway', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Conyngham', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coopersburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coplay', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coraopolis', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cornwall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cornwells Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Corry', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Coudersport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Crafton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cranberry Township', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Crawford County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cresson', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cressona', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Croydon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Cumberland County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Curtisville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Curwensville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dallas', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dallastown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dalton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Danville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Darby', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dauphin County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Davidsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Delaware County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Delmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Denver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Derry', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Devon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dewart', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dickson City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dillsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Donora', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dormont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dorneyville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dover', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Downingtown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Doylestown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dravosburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dresher', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Drexel Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dublin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'DuBois', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Duboistown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dunbar', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Duncannon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Duncansville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dunmore', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dunnstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Dupont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Duquesne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Duryea', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Eagleview', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Eagleville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Earlston', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Bangor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Berlin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Berwick', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Conemaugh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Earl', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Greenville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Lansdowne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East McKeesport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Petersburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Pittsburgh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Stroudsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Uniontown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East Washington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'East York', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Eastlawn Gardens', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Easton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ebensburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Economy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Eddington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Eddystone', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Edgewood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Edgeworth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Edinboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Edwardsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Effort', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Egypt', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elim', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elizabeth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elizabethtown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elizabethville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elk County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elkland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ellport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ellwood City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elverson', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Elysburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Emerald Lakes', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Emigsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Emmaus', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Emporium', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Emsworth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Enhaut', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Enlow', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Enola', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ephrata', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Erie', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Erie County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Espy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Etna', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Evans City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Evansburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Everett', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Exeter', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Exton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Factoryville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairchance', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairhope', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairless Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairview', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fairview-Ferndale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Falls Creek', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Farrell', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Faxon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fayette County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fayetteville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Feasterville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fellsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ferndale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fernway', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fivepointville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fleetwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Flemington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Flourtown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Flying Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Folcroft', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Folsom', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ford City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Forest City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Forest County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Forest Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fort Washington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Forty Fort', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Foster Brook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fountain Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fox Chapel', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fox Chase', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fox Run', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Frackville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Franklin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Franklin County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Franklin Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fredericksburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Freedom', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Freeland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Freemansburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Freeport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Friedens', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fullerton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Fulton County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Galeton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gallitzin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gap', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Garden View', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gastonville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Geistown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Georgetown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gettysburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gibsonia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gilbertsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Girard', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Girardville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glassport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glen Lyon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glen Rock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glenolden', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glenshaw', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Glenside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Gold Key Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Grantley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Green Tree', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greencastle', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greene County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greenfields', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greenock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greensburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greenville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Greenwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Grill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Grove City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Guilford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Guilford Siding', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Halfway House', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hallam', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hallstead', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hamburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hanover', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Harleigh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Harleysville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Harrisburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Harveys Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hasson Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hastings', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hatboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hatfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hawley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hazleton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hebron', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Heidelberg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hellertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hemlock Farms', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hermitage', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hershey', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Highland Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Highspire', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hilldale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hiller', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hokendauqua', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hollidaysburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Homeacre-Lyndora', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Homer City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Homestead', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hometown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Honesdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Honey Brook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hopwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Horsham', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Houserville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Houston', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hudson', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hughestown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hughesville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hummels Wharf', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hummelstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Huntingdon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Huntingdon County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hyde', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Hyde Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Imperial', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Indian Mountain Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Indiana', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Indiana County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Industry', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ingram', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Inkerman', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Intercourse', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Irwin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ivyland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jacksonwald', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jacobus', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jeannette', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jefferson County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jefferson Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jenkintown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jermyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jerome', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jersey Shore', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jessup', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jim Thorpe', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Johnsonburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Johnstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Jonestown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Juniata County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kane', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kenhorst', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kenilworth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kenmar', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kennett Square', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'King of Prussia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kingston', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kittanning', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Knox', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kulpmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kulpsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Kutztown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lackawanna County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lafayette Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Laflin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lake City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lake Heritage', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lake Latonka', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lake Meade', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lake Wynonah', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lakemont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lampeter', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lancaster', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lancaster County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Landisville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Langhorne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Langhorne Manor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lansdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lansdowne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lansford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Laporte', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Larksville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Latrobe', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Laureldale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Laurys Station', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lawnton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lawrence County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lawrence Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lawson Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lebanon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lebanon County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lebanon South', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Leechburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Leesport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Leetsdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lehigh County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lehighton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Leith-Hatfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lemont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lemoyne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lenape Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Leola', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Level Green', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Levittown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lewisburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lewistown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Liberty', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Light Street', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ligonier', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lima', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Limerick', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lincoln', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lincoln Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Linglestown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Linntown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Linwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lionville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lititz', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Littlestown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lock Haven', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Loganville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lorane', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Loretto', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lower Allen', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lower Burrell', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Loyalhanna', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Luzerne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Luzerne County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lycoming County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lykens', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Lynnwood-Pricedale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Macungie', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mahanoy City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Malvern', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Manchester', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Manheim', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Manor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mansfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Maple Glen', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marcus Hook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marianne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marienville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marietta', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mars', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marshallton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Martinsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Marysville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Masontown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Matamoras', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mayfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Maytown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McAdoo', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McConnellsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McConnellstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McDonald', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McGovern', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McKean County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McKees Rocks', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McKeesport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McMurray', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'McSherrystown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Meadowood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Meadville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mechanicsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mechanicsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Media', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mercer', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mercer County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mercersburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Meridian', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Meyersdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Middleburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Middletown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Midland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Midway', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mifflin County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mifflinburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mifflintown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mifflinville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Milesburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Milford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mill Hall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Millbourne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Millersburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Millersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Millvale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Milroy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Milton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Minersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mohnton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Monaca', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Monessen', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Monongahela', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Monroe County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Monroeville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mont Alto', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montgomery', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montgomery County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montgomeryville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montour County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montoursville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Montrose', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Moosic', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Morningside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Morrisville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Morton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Moscow', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Carmel', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Cobb', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Holly Springs', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Joy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Lebanon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Oliver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Penn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Pleasant', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Pocono', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Union', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mount Wolf', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mountain Top', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mountainhome', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mountville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Muhlenberg Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Muncy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Mundys Corner', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Munhall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Murrysville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Muse', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Myerstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nanticoke', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nanty Glo', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Narberth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nazareth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nescopeck', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nesquehoning', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Beaver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Berlinville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Bloomfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Brighton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Britain', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Castle', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Castle Northwest', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Columbia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Cumberland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Eagle', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Freedom', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Holland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Hope', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Kensington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Oxford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Philadelphia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Stanton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'New Wilmington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Newmanstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Newport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Newtown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Newtown Grant', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Newville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Nixon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Norristown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Apollo', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Belle Vernon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Braddock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Catasauqua', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Charleroi', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North East', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Versailles', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Wales', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North Warren', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'North York', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northampton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northampton County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northern Cambria', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northumberland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northumberland County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Northwest Harborcreek', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Norwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oak Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oakdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oakland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oakmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oakwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ohioville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oil City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Old Forge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Old Orchard', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oliver', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Olyphant', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Orchard Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oreland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Orwigsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Osceola Mills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Oxford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Palmdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Palmer Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Palmerton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Palmyra', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Palo Alto', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Paoli', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Paradise', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Park Forest Village', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Parkesburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Parkside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Parkville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Patton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Paxtang', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Paxtonia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pen Argyl', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penbrook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penn Estates', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penn Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penn Wynne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penndel', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pennsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pennside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pennsport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pennville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Penryn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Perkasie', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Perry County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Perryopolis', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Philadelphia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Philadelphia County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Philipsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Phoenixville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pike County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pine Grove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pine Grove Mills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pine Ridge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pitcairn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pittsburgh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pittston', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Plains', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pleasant Gap', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pleasant Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pleasant Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Plum', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Plumsteadville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Plymouth', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Plymouth Meeting', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pocono Pines', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pocono Ranch Lands', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Point Marion', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Port Allegany', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Port Carbon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Port Vue', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Portage', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Potter County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pottsgrove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pottstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pottsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Progress', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Prospect', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Prospect Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Punxsutawney', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Pymatuning Central', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Quakertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Quarryville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Radnor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rankin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Raubsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reading', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reamstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Red Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Red Lion', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reiffton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reinholds', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rennerdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Renovo', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Republic', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reynolds Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Reynoldsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rheems', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Richboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Richland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Richlandtown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ridgway', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Ridley Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'River View Park', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Riverside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Roaring Spring', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Robesonia', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rochester', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rockledge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Roseto', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rothsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Royalton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Royersford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Russell', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Russellton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Rutherford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saint Clair', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saint Lawrence', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saint Marys', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Salix', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Salunga', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sanatoga', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sand Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sandy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saw Creek', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saxonburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Saylorsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sayre', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schlusser', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schnecksville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schoeneck', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schuylkill County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schuylkill Haven', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Schwenksville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Scotland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Scottdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Scranton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Selinsgrove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sellersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Seneca', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Seven Fields', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sewickley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shamokin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shamokin Dam', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shanor-Northvue', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sharon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sharon Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sharpsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sharpsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shavertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sheffield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shenandoah', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shenandoah Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shillington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shiloh', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shinglehouse', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shippensburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shiremanstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shoemakersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Shrewsbury', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sierra View', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Simpson', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sinking Spring', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Skippack', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Skyline View', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Slatington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Slippery Rock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Smethport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Snyder County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Somerset', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Somerset County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Souderton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Coatesville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Connellsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Greensburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Park Township', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Pottstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Temple', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Uniontown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Waverly', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'South Williamsport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Southmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Southwest Greensburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spangler', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Speers', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spinnerstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spring City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spring Grove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spring House', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spring Mount', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spring Ridge', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Springdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Springfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Spry', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'State College', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'State Line', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Steelton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stewartstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stiles', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stoneboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stony Creek Mills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stonybrook', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stormstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stowe', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Strasburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Stroudsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sturgeon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sugarcreek', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sullivan County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Summit Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sun Valley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sunbury', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sunrise Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Susquehanna', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Susquehanna County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Susquehanna Trails', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Swarthmore', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Swartzville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Swissvale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Swoyersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Sykesville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tacony', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tamaqua', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tannersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tarentum', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tatamy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Taylor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Telford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Temple', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Terre Hill', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'The Hideout', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Thompsonville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Thorndale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Throop', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tinicum', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tioga County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tionesta', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tipton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Titusville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Toftrees', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Topton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Toughkenamon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Towamensing Trails', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Towanda', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tower City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trafford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trainer', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trappe', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Treasure Lake', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tremont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trevorton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trevose', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trexlertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trooper', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Troy', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Trucksville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tullytown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tunkhannock', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Turtle Creek', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Tyrone', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Union City', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Union County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Uniontown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Upland', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Upper Saint Clair', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Valley Green', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Valley View', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Vandergrift', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Venango County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Verona', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Versailles', state: 'PA', state_name: 'Pennsylvania' },
  {
    city: 'Village Green-Green Ridge',
    state: 'PA',
    state_name: 'Pennsylvania',
  },
  { city: 'Village Shires', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Vinco', state: 'PA', state_name: 'Pennsylvania' },
  {
    city: 'Wallenpaupack Lake Estates',
    state: 'PA',
    state_name: 'Pennsylvania',
  },
  { city: 'Walnutport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Warminster Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Warren', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Warren County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Washington', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Washington County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Waterford', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Watsontown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Waymart', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wayne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wayne County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wayne Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Waynesboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Waynesburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Weatherly', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Weigelstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Weissport East', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wellsboro', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wernersville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wescosville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wesleyville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Chester', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Conshohocken', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Easton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Fairview', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Grove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Hamburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Hazleton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Homestead', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Kittanning', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Lawn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Leechburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Mayfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Mifflin', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Newton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Norriton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Pittston', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Reading', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West View', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Wyoming', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West Wyomissing', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'West York', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Westfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Westmont', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Westmoreland County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wharton', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Whitaker', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'White Haven', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'White Oak', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Whitehall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Whitehall Township', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Whitfield', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Whitman', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wickerham Manor-Fisher', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wilkes-Barre', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wilkinsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Williamsburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Williamsport', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Williamstown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Willow Grove', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Willow Street', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wilmerding', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wilson', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wind Gap', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Windber', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Windsor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wolfdale', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Womelsdorf', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Woodbourne', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Woodland Heights', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Woodlyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Woodside', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wormleysburg', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Woxall', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wrightsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyncote', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyndmoor', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyoming', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyoming County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyomissing', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Wyomissing Hills', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Yardley', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Yeadon', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Yeagertown', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Yoe', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'York', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'York County', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Yorklyn', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Youngsville', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Youngwood', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Zelienople', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Zion', state: 'PA', state_name: 'Pennsylvania' },
  { city: 'Adjuntas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aguada', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aguadilla', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aguas Buenas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aguas Claras', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aguilita', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Aibonito', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Añasco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Animas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Antón Ruiz', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Arecibo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Arroyo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bairoa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bajadero', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bajandas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Barahona', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Barceloneta', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Barranquitas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bartolo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bayamon', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Benitez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Betances', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Boqueron', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Boquerón Cabo Rojo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Brenas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Buena Vista', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Bufalo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Caban', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cabo Rojo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cabo Rojo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cacao', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Caguas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Campanilla', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Campo Rico', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Camuy', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Candelaria', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Candelaria Arenas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Candelero Arriba', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Canóvanas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Capitanejo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Carolina', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Carrizales', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cataño', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cayey', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cayuco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ceiba', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ceiba Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Celada', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Central Aguirre', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ciales', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ciales Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Cidra', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Coamo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Coco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Comerío', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Comerío Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Comunas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Coquí', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Corazón', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Corcovado', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Corozal', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Corozal Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Coto Laurel', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Coto Norte', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Culebra', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Culebra barrio-pueblo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Daguao', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Dorado', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Dorado Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'El Mangó', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'El Negro', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'El Ojo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Emajagua', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Esperanza', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Espino', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Estancias de Florida', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Fajardo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Fajardo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Florida', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Franquez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Fuig', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'G. L. Garcia', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Galateo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Garrochales', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guánica', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guánica Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guayabal', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guayama', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guayama Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guayanilla', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Guaynabo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Gurabo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Gurabo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'H. Rivera Colon', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hatillo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hatillo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hato Arriba', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hato Candal', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hormigueros', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Hormigueros Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Humacao', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Imbery', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Indios', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ingenio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Isabel Segunda', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Isabela', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Jagual', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Jauca', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Jayuya', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Jobos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Juana Díaz', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Juana Díaz Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Juncal', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Juncos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Alianza', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Dolores', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Fermina', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Luisa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Parguera', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Playa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'La Plena', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lajas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lajas Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lamboglia', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lares', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Marias', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Marías Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Ochenta', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Ollas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Piedras', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Las Piedras Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Levittown', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Liborio Negron Torres', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lluveras', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Loíza', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Lomas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Los Llanos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Luis Lloréns Torres', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Luis M. Cintron', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Luquillo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Luquillo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Luyando', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Magas Arriba', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Manatí', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Maria Antonia', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Mariano Colón', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Maricao', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Maricao Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Martorell', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Marueño', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Maunabo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Maunabo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Mayagüez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Miranda', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Moca', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Moca Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Monserrate', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Monte Grande', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Mora', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Morovis', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Mucarabones', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Naguabo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Naguabo Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Naranjito', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Naranjito Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Olimpo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Orocovis', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Orocovis Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Pájaros', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Pajonal', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Palmarejo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Palmas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Palmer', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Palo Seco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Palomas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Parcelas La Milagrosa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Parcelas Nuevas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Parcelas Peñuelas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Pastos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Patillas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Patillas Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Peña Pobre', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Peñuelas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Piedra Gorda', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Playa Fortuna', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Playita', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Playita Cortada', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Pole Ojea', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ponce', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Potala Pastillo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Pueblito del Rio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Puerto Real', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Punta Santiago', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Quebrada', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Quebradillas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Quebradillas Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rafael Capo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rafael Gonzalez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rafael Hernandez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ramos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rincón', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rincón Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Río Blanco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Río Cañas Abajo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Río Grande', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Río Grande Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rio Lajas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Río Piedras', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Rosa Sanchez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana Eneas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana Grande', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana Grande Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana Hoyos', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sabana Seca', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Salinas', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Antonio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Germán Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Isidro', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San José', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Juan', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Lorenzo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'San Sebastián', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santa Barbara', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santa Clara', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santa Isabel', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santa Isabel Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santo Domingo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Santurce', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Stella', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Suárez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Sumidero', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Tallaboa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Tallaboa Alta', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Tiburones', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Tierras Nuevas Poniente', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Toa Alta', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Toa Alta Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Toa Baja', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Trujillo Alto', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Trujillo Alto Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Utuado', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Utuado barrio-pueblo', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vázquez', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vega Alta', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vega Alta Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vega Baja', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vieques', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Vieques Municipality', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Villalba', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Villalba Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Yabucoa', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Yabucoa Municipio', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Yauco', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Yaurel', state: 'PR', state_name: 'Puerto Rico' },
  { city: 'Ashaway', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Barrington', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Bradford', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Bristol', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Bristol County', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Central Falls', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Charlestown', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Chepachet', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Coventry', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Cranston', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Cumberland', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Cumberland Hill', state: 'RI', state_name: 'Rhode Island' },
  { city: 'East Greenwich', state: 'RI', state_name: 'Rhode Island' },
  { city: 'East Providence', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Exeter', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Foster', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Greenville', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Harrisville', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Hope Valley', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Hopkinton', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Jamestown', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Johnston', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Kent County', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Kingston', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Lincoln', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Melville', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Middletown', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Narragansett', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Narragansett Pier', state: 'RI', state_name: 'Rhode Island' },
  { city: 'New Shoreham', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Newport', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Newport County', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Newport East', state: 'RI', state_name: 'Rhode Island' },
  { city: 'North Kingstown', state: 'RI', state_name: 'Rhode Island' },
  { city: 'North Providence', state: 'RI', state_name: 'Rhode Island' },
  { city: 'North Scituate', state: 'RI', state_name: 'Rhode Island' },
  { city: 'North Smithfield', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Pascoag', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Pawtucket', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Portsmouth', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Providence', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Providence County', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Smithfield', state: 'RI', state_name: 'Rhode Island' },
  { city: 'South Kingstown', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Tiverton', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Valley Falls', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Wakefield-Peacedale', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Warren', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Warwick', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Washington County', state: 'RI', state_name: 'Rhode Island' },
  { city: 'West Greenwich', state: 'RI', state_name: 'Rhode Island' },
  { city: 'West Warwick', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Westerly', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Woonsocket', state: 'RI', state_name: 'Rhode Island' },
  { city: 'Abbeville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Abbeville County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Aiken', state: 'SC', state_name: 'South Carolina' },
  { city: 'Aiken County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Allendale', state: 'SC', state_name: 'South Carolina' },
  { city: 'Allendale County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Anderson', state: 'SC', state_name: 'South Carolina' },
  { city: 'Anderson County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Andrews', state: 'SC', state_name: 'South Carolina' },
  { city: 'Arcadia', state: 'SC', state_name: 'South Carolina' },
  { city: 'Arial', state: 'SC', state_name: 'South Carolina' },
  { city: 'Awendaw', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bamberg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bamberg County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Barnwell', state: 'SC', state_name: 'South Carolina' },
  { city: 'Barnwell County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Batesburg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Batesburg-Leesville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Beaufort', state: 'SC', state_name: 'South Carolina' },
  { city: 'Beaufort County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Belton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Belvedere', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bennettsville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Berea', state: 'SC', state_name: 'South Carolina' },
  { city: 'Berkeley County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bishopville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Blacksburg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Blackville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bluffton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Blythewood', state: 'SC', state_name: 'South Carolina' },
  { city: 'Boiling Springs', state: 'SC', state_name: 'South Carolina' },
  { city: 'Bonneau Beach', state: 'SC', state_name: 'South Carolina' },
  { city: 'Brookdale', state: 'SC', state_name: 'South Carolina' },
  { city: 'Buffalo', state: 'SC', state_name: 'South Carolina' },
  { city: 'Burnettown', state: 'SC', state_name: 'South Carolina' },
  { city: 'Burton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Calhoun County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Calhoun Falls', state: 'SC', state_name: 'South Carolina' },
  { city: 'Camden', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cane Savannah', state: 'SC', state_name: 'South Carolina' },
  { city: 'Catawba', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cayce', state: 'SC', state_name: 'South Carolina' },
  { city: 'Centerville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Central', state: 'SC', state_name: 'South Carolina' },
  { city: 'Chapin', state: 'SC', state_name: 'South Carolina' },
  { city: 'Charleston', state: 'SC', state_name: 'South Carolina' },
  { city: 'Charleston County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cheraw', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cherokee County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cherryvale', state: 'SC', state_name: 'South Carolina' },
  { city: 'Chester', state: 'SC', state_name: 'South Carolina' },
  { city: 'Chester County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Chesterfield', state: 'SC', state_name: 'South Carolina' },
  { city: 'Chesterfield County', state: 'SC', state_name: 'South Carolina' },
  { city: 'City View', state: 'SC', state_name: 'South Carolina' },
  { city: 'Clarendon County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Clearwater', state: 'SC', state_name: 'South Carolina' },
  { city: 'Clemson', state: 'SC', state_name: 'South Carolina' },
  { city: 'Clinton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Clover', state: 'SC', state_name: 'South Carolina' },
  { city: 'Colleton County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Columbia', state: 'SC', state_name: 'South Carolina' },
  { city: 'Conway', state: 'SC', state_name: 'South Carolina' },
  { city: 'Cowpens', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dalzell', state: 'SC', state_name: 'South Carolina' },
  { city: 'Darlington', state: 'SC', state_name: 'South Carolina' },
  { city: 'Darlington County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Denmark', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dentsville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dillon', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dillon County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dorchester County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Due West', state: 'SC', state_name: 'South Carolina' },
  { city: 'Duncan', state: 'SC', state_name: 'South Carolina' },
  { city: 'Dunean', state: 'SC', state_name: 'South Carolina' },
  { city: 'Easley', state: 'SC', state_name: 'South Carolina' },
  { city: 'East Gaffney', state: 'SC', state_name: 'South Carolina' },
  { city: 'East Sumter', state: 'SC', state_name: 'South Carolina' },
  { city: 'Edgefield', state: 'SC', state_name: 'South Carolina' },
  { city: 'Edgefield County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Edisto', state: 'SC', state_name: 'South Carolina' },
  { city: 'Elgin', state: 'SC', state_name: 'South Carolina' },
  { city: 'Estill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Eureka Mill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Fairfax', state: 'SC', state_name: 'South Carolina' },
  { city: 'Fairfield County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Fairforest', state: 'SC', state_name: 'South Carolina' },
  { city: 'Five Forks', state: 'SC', state_name: 'South Carolina' },
  { city: 'Florence', state: 'SC', state_name: 'South Carolina' },
  { city: 'Florence County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Folly Beach', state: 'SC', state_name: 'South Carolina' },
  { city: 'Forest Acres', state: 'SC', state_name: 'South Carolina' },
  { city: 'Forestbrook', state: 'SC', state_name: 'South Carolina' },
  { city: 'Fort Mill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Fountain Inn', state: 'SC', state_name: 'South Carolina' },
  { city: 'Gadsden', state: 'SC', state_name: 'South Carolina' },
  { city: 'Gaffney', state: 'SC', state_name: 'South Carolina' },
  { city: 'Gantt', state: 'SC', state_name: 'South Carolina' },
  { city: 'Garden City', state: 'SC', state_name: 'South Carolina' },
  { city: 'Gaston', state: 'SC', state_name: 'South Carolina' },
  { city: 'Georgetown', state: 'SC', state_name: 'South Carolina' },
  { city: 'Georgetown County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Gloverville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Golden Grove', state: 'SC', state_name: 'South Carolina' },
  { city: 'Goose Creek', state: 'SC', state_name: 'South Carolina' },
  { city: 'Graniteville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Great Falls', state: 'SC', state_name: 'South Carolina' },
  { city: 'Greenville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Greenville County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Greenwood', state: 'SC', state_name: 'South Carolina' },
  { city: 'Greenwood County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Greer', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hampton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hampton County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hanahan', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hardeeville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hartsville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hilton Head', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hilton Head Island', state: 'SC', state_name: 'South Carolina' },
  { city: 'Holly Hill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hollywood', state: 'SC', state_name: 'South Carolina' },
  { city: 'Homeland Park', state: 'SC', state_name: 'South Carolina' },
  { city: 'Honea Path', state: 'SC', state_name: 'South Carolina' },
  { city: 'Hopkins', state: 'SC', state_name: 'South Carolina' },
  { city: 'Horry County', state: 'SC', state_name: 'South Carolina' },
  { city: 'India Hook', state: 'SC', state_name: 'South Carolina' },
  { city: 'Inman', state: 'SC', state_name: 'South Carolina' },
  { city: 'Inman Mills', state: 'SC', state_name: 'South Carolina' },
  { city: 'Irmo', state: 'SC', state_name: 'South Carolina' },
  { city: 'Irwin', state: 'SC', state_name: 'South Carolina' },
  { city: 'Isle of Palms', state: 'SC', state_name: 'South Carolina' },
  { city: 'Iva', state: 'SC', state_name: 'South Carolina' },
  { city: 'Jackson', state: 'SC', state_name: 'South Carolina' },
  { city: 'James Island', state: 'SC', state_name: 'South Carolina' },
  { city: 'Jasper County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Joanna', state: 'SC', state_name: 'South Carolina' },
  { city: 'Johnsonville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Johnston', state: 'SC', state_name: 'South Carolina' },
  { city: 'Judson', state: 'SC', state_name: 'South Carolina' },
  { city: 'Kershaw', state: 'SC', state_name: 'South Carolina' },
  { city: 'Kershaw County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Kiawah Island', state: 'SC', state_name: 'South Carolina' },
  { city: 'Kingstree', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ladson', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lake City', state: 'SC', state_name: 'South Carolina' },
  {
    city: 'Lake Murray of Richland',
    state: 'SC',
    state_name: 'South Carolina',
  },
  { city: 'Lake Secession', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lake Wylie', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lakewood', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lancaster', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lancaster County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lancaster Mill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Landrum', state: 'SC', state_name: 'South Carolina' },
  { city: 'Langley', state: 'SC', state_name: 'South Carolina' },
  { city: 'Latta', state: 'SC', state_name: 'South Carolina' },
  { city: 'Laurel Bay', state: 'SC', state_name: 'South Carolina' },
  { city: 'Laurens', state: 'SC', state_name: 'South Carolina' },
  { city: 'Laurens County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lee County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Leesville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lesslie', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lexington', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lexington County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Liberty', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lincolnville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Little River', state: 'SC', state_name: 'South Carolina' },
  { city: 'Loris', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lugoff', state: 'SC', state_name: 'South Carolina' },
  { city: 'Lyman', state: 'SC', state_name: 'South Carolina' },
  { city: 'Manning', state: 'SC', state_name: 'South Carolina' },
  { city: 'Marion', state: 'SC', state_name: 'South Carolina' },
  { city: 'Marion County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Marlboro County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Mauldin', state: 'SC', state_name: 'South Carolina' },
  { city: 'Mayo', state: 'SC', state_name: 'South Carolina' },
  { city: 'McColl', state: 'SC', state_name: 'South Carolina' },
  { city: 'McCormick', state: 'SC', state_name: 'South Carolina' },
  { city: 'McCormick County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Meggett', state: 'SC', state_name: 'South Carolina' },
  { city: 'Monarch Mill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Moncks Corner', state: 'SC', state_name: 'South Carolina' },
  { city: 'Mount Pleasant', state: 'SC', state_name: 'South Carolina' },
  { city: 'Mullins', state: 'SC', state_name: 'South Carolina' },
  { city: 'Murphys Estates', state: 'SC', state_name: 'South Carolina' },
  { city: 'Murrells Inlet', state: 'SC', state_name: 'South Carolina' },
  { city: 'Myrtle Beach', state: 'SC', state_name: 'South Carolina' },
  { city: 'New Ellenton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Newberry', state: 'SC', state_name: 'South Carolina' },
  { city: 'Newberry County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Newport', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ninety Six', state: 'SC', state_name: 'South Carolina' },
  { city: 'North Augusta', state: 'SC', state_name: 'South Carolina' },
  { city: 'North Charleston', state: 'SC', state_name: 'South Carolina' },
  { city: 'North Hartsville', state: 'SC', state_name: 'South Carolina' },
  { city: 'North Myrtle Beach', state: 'SC', state_name: 'South Carolina' },
  { city: 'Northlake', state: 'SC', state_name: 'South Carolina' },
  { city: 'Oak Grove', state: 'SC', state_name: 'South Carolina' },
  { city: 'Oakland', state: 'SC', state_name: 'South Carolina' },
  { city: 'Oconee County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Orangeburg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Orangeburg County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pacolet', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pageland', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pamplico', state: 'SC', state_name: 'South Carolina' },
  { city: 'Parker', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pendleton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pickens', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pickens County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Piedmont', state: 'SC', state_name: 'South Carolina' },
  { city: 'Pineridge', state: 'SC', state_name: 'South Carolina' },
  { city: 'Port Royal', state: 'SC', state_name: 'South Carolina' },
  { city: 'Powdersville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Privateer', state: 'SC', state_name: 'South Carolina' },
  { city: 'Prosperity', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ravenel', state: 'SC', state_name: 'South Carolina' },
  { city: 'Red Bank', state: 'SC', state_name: 'South Carolina' },
  { city: 'Red Hill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Richland County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ridgeland', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ridgeville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Rock Hill', state: 'SC', state_name: 'South Carolina' },
  { city: 'Roebuck', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saint Andrews', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saint George', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saint Matthews', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saint Stephen', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saluda', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saluda County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Sangaree', state: 'SC', state_name: 'South Carolina' },
  { city: 'Sans Souci', state: 'SC', state_name: 'South Carolina' },
  { city: 'Saxon', state: 'SC', state_name: 'South Carolina' },
  { city: 'Seabrook Island', state: 'SC', state_name: 'South Carolina' },
  { city: 'Seneca', state: 'SC', state_name: 'South Carolina' },
  { city: 'Seven Oaks', state: 'SC', state_name: 'South Carolina' },
  { city: 'Shell Point', state: 'SC', state_name: 'South Carolina' },
  { city: 'Simpsonville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Slater-Marietta', state: 'SC', state_name: 'South Carolina' },
  { city: 'Socastee', state: 'SC', state_name: 'South Carolina' },
  { city: 'South Congaree', state: 'SC', state_name: 'South Carolina' },
  { city: 'South Sumter', state: 'SC', state_name: 'South Carolina' },
  { city: 'Southern Shops', state: 'SC', state_name: 'South Carolina' },
  { city: 'Spartanburg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Spartanburg County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Springdale', state: 'SC', state_name: 'South Carolina' },
  { city: 'Stateburg', state: 'SC', state_name: 'South Carolina' },
  { city: 'Sullivans Island', state: 'SC', state_name: 'South Carolina' },
  { city: 'Summerville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Sumter', state: 'SC', state_name: 'South Carolina' },
  { city: 'Sumter County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Surfside Beach', state: 'SC', state_name: 'South Carolina' },
  { city: 'Taylors', state: 'SC', state_name: 'South Carolina' },
  { city: 'Tega Cay', state: 'SC', state_name: 'South Carolina' },
  { city: 'Tigerville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Timmonsville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Travelers Rest', state: 'SC', state_name: 'South Carolina' },
  { city: 'Union', state: 'SC', state_name: 'South Carolina' },
  { city: 'Union County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Utica', state: 'SC', state_name: 'South Carolina' },
  { city: 'Valley Falls', state: 'SC', state_name: 'South Carolina' },
  { city: 'Varnville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Wade Hampton', state: 'SC', state_name: 'South Carolina' },
  { city: 'Walhalla', state: 'SC', state_name: 'South Carolina' },
  { city: 'Walterboro', state: 'SC', state_name: 'South Carolina' },
  { city: 'Ware Shoals', state: 'SC', state_name: 'South Carolina' },
  { city: 'Warrenville', state: 'SC', state_name: 'South Carolina' },
  { city: 'Watts Mills', state: 'SC', state_name: 'South Carolina' },
  { city: 'Wedgefield', state: 'SC', state_name: 'South Carolina' },
  { city: 'Wedgewood', state: 'SC', state_name: 'South Carolina' },
  { city: 'Welcome', state: 'SC', state_name: 'South Carolina' },
  { city: 'Wellford', state: 'SC', state_name: 'South Carolina' },
  { city: 'West Columbia', state: 'SC', state_name: 'South Carolina' },
  { city: 'Westminster', state: 'SC', state_name: 'South Carolina' },
  { city: 'Whitmire', state: 'SC', state_name: 'South Carolina' },
  { city: 'Wilkinson Heights', state: 'SC', state_name: 'South Carolina' },
  { city: 'Williamsburg County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Williamston', state: 'SC', state_name: 'South Carolina' },
  { city: 'Williston', state: 'SC', state_name: 'South Carolina' },
  { city: 'Winnsboro', state: 'SC', state_name: 'South Carolina' },
  { city: 'Winnsboro Mills', state: 'SC', state_name: 'South Carolina' },
  { city: 'Woodfield', state: 'SC', state_name: 'South Carolina' },
  { city: 'Woodruff', state: 'SC', state_name: 'South Carolina' },
  { city: 'York', state: 'SC', state_name: 'South Carolina' },
  { city: 'York County', state: 'SC', state_name: 'South Carolina' },
  { city: 'Aberdeen', state: 'SD', state_name: 'South Dakota' },
  { city: 'Alexandria', state: 'SD', state_name: 'South Dakota' },
  { city: 'Armour', state: 'SD', state_name: 'South Dakota' },
  { city: 'Aurora County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Baltic', state: 'SD', state_name: 'South Dakota' },
  { city: 'Beadle County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Belle Fourche', state: 'SD', state_name: 'South Dakota' },
  { city: 'Bennett County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Beresford', state: 'SD', state_name: 'South Dakota' },
  { city: 'Bison', state: 'SD', state_name: 'South Dakota' },
  { city: 'Blackhawk', state: 'SD', state_name: 'South Dakota' },
  { city: 'Bon Homme County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Box Elder', state: 'SD', state_name: 'South Dakota' },
  { city: 'Brandon', state: 'SD', state_name: 'South Dakota' },
  { city: 'Britton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Brookings', state: 'SD', state_name: 'South Dakota' },
  { city: 'Brookings County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Brown County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Brule County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Buffalo', state: 'SD', state_name: 'South Dakota' },
  { city: 'Buffalo County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Burke', state: 'SD', state_name: 'South Dakota' },
  { city: 'Butte County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Campbell County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Canton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Chamberlain', state: 'SD', state_name: 'South Dakota' },
  { city: 'Charles Mix County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Clark', state: 'SD', state_name: 'South Dakota' },
  { city: 'Clark County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Clay County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Clear Lake', state: 'SD', state_name: 'South Dakota' },
  { city: 'Codington County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Colonial Pine Hills', state: 'SD', state_name: 'South Dakota' },
  { city: 'Corson County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Crooks', state: 'SD', state_name: 'South Dakota' },
  { city: 'Custer', state: 'SD', state_name: 'South Dakota' },
  { city: 'Custer County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Dakota Dunes', state: 'SD', state_name: 'South Dakota' },
  { city: 'Davison County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Day County', state: 'SD', state_name: 'South Dakota' },
  { city: 'De Smet', state: 'SD', state_name: 'South Dakota' },
  { city: 'Deadwood', state: 'SD', state_name: 'South Dakota' },
  { city: 'Dell Rapids', state: 'SD', state_name: 'South Dakota' },
  { city: 'Deuel County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Dewey County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Douglas County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Dupree', state: 'SD', state_name: 'South Dakota' },
  { city: 'Eagle Butte', state: 'SD', state_name: 'South Dakota' },
  { city: 'Edmunds County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Elk Point', state: 'SD', state_name: 'South Dakota' },
  { city: 'Fall River County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Faulk County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Faulkton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Flandreau', state: 'SD', state_name: 'South Dakota' },
  { city: 'Fort Pierre', state: 'SD', state_name: 'South Dakota' },
  { city: 'Fort Thompson', state: 'SD', state_name: 'South Dakota' },
  { city: 'Freeman', state: 'SD', state_name: 'South Dakota' },
  { city: 'Garretson', state: 'SD', state_name: 'South Dakota' },
  { city: 'Gettysburg', state: 'SD', state_name: 'South Dakota' },
  { city: 'Grant County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Gregory', state: 'SD', state_name: 'South Dakota' },
  { city: 'Gregory County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Groton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Haakon County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hamlin County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hand County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hanson County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Harding County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Harrisburg', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hartford', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hayti', state: 'SD', state_name: 'South Dakota' },
  { city: 'Highmore', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hot Springs', state: 'SD', state_name: 'South Dakota' },
  { city: 'Howard', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hughes County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Huron', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hutchinson County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Hyde County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Ipswich', state: 'SD', state_name: 'South Dakota' },
  { city: 'Jackson County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Jerauld County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Jones County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Kadoka', state: 'SD', state_name: 'South Dakota' },
  { city: 'Kennebec', state: 'SD', state_name: 'South Dakota' },
  { city: 'Kingsbury County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lake Andes', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lake County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lawrence County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lead', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lemmon', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lennox', state: 'SD', state_name: 'South Dakota' },
  { city: 'Leola', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lincoln County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Lyman County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Madison', state: 'SD', state_name: 'South Dakota' },
  { city: 'Marshall County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Martin', state: 'SD', state_name: 'South Dakota' },
  { city: 'McCook County', state: 'SD', state_name: 'South Dakota' },
  { city: 'McIntosh', state: 'SD', state_name: 'South Dakota' },
  { city: 'McPherson County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Meade County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Mellette County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Milbank', state: 'SD', state_name: 'South Dakota' },
  { city: 'Miller', state: 'SD', state_name: 'South Dakota' },
  { city: 'Miner County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Minnehaha County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Mission', state: 'SD', state_name: 'South Dakota' },
  { city: 'Mitchell', state: 'SD', state_name: 'South Dakota' },
  { city: 'Mobridge', state: 'SD', state_name: 'South Dakota' },
  { city: 'Moody County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Mound City', state: 'SD', state_name: 'South Dakota' },
  { city: 'Murdo', state: 'SD', state_name: 'South Dakota' },
  { city: 'North Eagle Butte', state: 'SD', state_name: 'South Dakota' },
  { city: 'North Sioux City', state: 'SD', state_name: 'South Dakota' },
  { city: 'North Spearfish', state: 'SD', state_name: 'South Dakota' },
  { city: 'Oglala', state: 'SD', state_name: 'South Dakota' },
  { city: 'Oglala Lakota County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Olivet', state: 'SD', state_name: 'South Dakota' },
  { city: 'Onida', state: 'SD', state_name: 'South Dakota' },
  { city: 'Parker', state: 'SD', state_name: 'South Dakota' },
  { city: 'Parkston', state: 'SD', state_name: 'South Dakota' },
  { city: 'Pennington County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Perkins County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Philip', state: 'SD', state_name: 'South Dakota' },
  { city: 'Pierre', state: 'SD', state_name: 'South Dakota' },
  { city: 'Pine Ridge', state: 'SD', state_name: 'South Dakota' },
  { city: 'Plankinton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Platte', state: 'SD', state_name: 'South Dakota' },
  { city: 'Porcupine', state: 'SD', state_name: 'South Dakota' },
  { city: 'Potter County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Rapid City', state: 'SD', state_name: 'South Dakota' },
  { city: 'Rapid Valley', state: 'SD', state_name: 'South Dakota' },
  { city: 'Redfield', state: 'SD', state_name: 'South Dakota' },
  { city: 'Roberts County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Rosebud', state: 'SD', state_name: 'South Dakota' },
  { city: 'Salem', state: 'SD', state_name: 'South Dakota' },
  { city: 'Sanborn County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Selby', state: 'SD', state_name: 'South Dakota' },
  { city: 'Sioux Falls', state: 'SD', state_name: 'South Dakota' },
  { city: 'Sisseton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Spearfish', state: 'SD', state_name: 'South Dakota' },
  { city: 'Spink County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Springfield', state: 'SD', state_name: 'South Dakota' },
  { city: 'Stanley County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Sturgis', state: 'SD', state_name: 'South Dakota' },
  { city: 'Sully County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Summerset', state: 'SD', state_name: 'South Dakota' },
  { city: 'Tea', state: 'SD', state_name: 'South Dakota' },
  { city: 'Timber Lake', state: 'SD', state_name: 'South Dakota' },
  { city: 'Todd County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Tripp County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Turner County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Tyndall', state: 'SD', state_name: 'South Dakota' },
  { city: 'Union County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Vermillion', state: 'SD', state_name: 'South Dakota' },
  { city: 'Volga', state: 'SD', state_name: 'South Dakota' },
  { city: 'Wagner', state: 'SD', state_name: 'South Dakota' },
  { city: 'Walworth County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Watertown', state: 'SD', state_name: 'South Dakota' },
  { city: 'Webster', state: 'SD', state_name: 'South Dakota' },
  { city: 'Wessington Springs', state: 'SD', state_name: 'South Dakota' },
  { city: 'White River', state: 'SD', state_name: 'South Dakota' },
  { city: 'Winner', state: 'SD', state_name: 'South Dakota' },
  { city: 'Woonsocket', state: 'SD', state_name: 'South Dakota' },
  { city: 'Yankton', state: 'SD', state_name: 'South Dakota' },
  { city: 'Yankton County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Ziebach County', state: 'SD', state_name: 'South Dakota' },
  { city: 'Adamsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Alamo', state: 'TN', state_name: 'Tennessee' },
  { city: 'Alcoa', state: 'TN', state_name: 'Tennessee' },
  { city: 'Algood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Altamont', state: 'TN', state_name: 'Tennessee' },
  { city: 'Anderson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Apison', state: 'TN', state_name: 'Tennessee' },
  { city: 'Ardmore', state: 'TN', state_name: 'Tennessee' },
  { city: 'Arlington', state: 'TN', state_name: 'Tennessee' },
  { city: 'Ashland City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Athens', state: 'TN', state_name: 'Tennessee' },
  { city: 'Atoka', state: 'TN', state_name: 'Tennessee' },
  { city: 'Banner Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bartlett', state: 'TN', state_name: 'Tennessee' },
  { city: 'Baxter', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bean Station', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bedford County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Belle Meade', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bells', state: 'TN', state_name: 'Tennessee' },
  { city: 'Benton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Benton County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Blaine', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bledsoe County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bloomingdale', state: 'TN', state_name: 'Tennessee' },
  { city: 'Blount County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Blountville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bluff City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bolivar', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bon Aqua Junction', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bradford', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bradley County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Brentwood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Brentwood Estates', state: 'TN', state_name: 'Tennessee' },
  { city: 'Brighton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bristol', state: 'TN', state_name: 'Tennessee' },
  { city: 'Brownsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Bruceton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Burns', state: 'TN', state_name: 'Tennessee' },
  { city: 'Byrdstown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Camden', state: 'TN', state_name: 'Tennessee' },
  { city: 'Campbell County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cannon County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Carroll County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Carter County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Carthage', state: 'TN', state_name: 'Tennessee' },
  { city: 'Caryville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Celina', state: 'TN', state_name: 'Tennessee' },
  { city: 'Centerville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Central', state: 'TN', state_name: 'Tennessee' },
  { city: 'Chapel Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Charlotte', state: 'TN', state_name: 'Tennessee' },
  { city: 'Chattanooga', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cheatham County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Chester County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Christiana', state: 'TN', state_name: 'Tennessee' },
  { city: 'Church Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Claiborne County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Clarksville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Clay County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cleveland', state: 'TN', state_name: 'Tennessee' },
  { city: 'Clifton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Clinton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Coalfield', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cocke County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Coffee County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Collegedale', state: 'TN', state_name: 'Tennessee' },
  { city: 'Collierville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Colonial Heights', state: 'TN', state_name: 'Tennessee' },
  { city: 'Columbia', state: 'TN', state_name: 'Tennessee' },
  { city: 'Condon', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cookeville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Coopertown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cornersville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Covington', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cowan', state: 'TN', state_name: 'Tennessee' },
  { city: 'Crockett County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cross Plains', state: 'TN', state_name: 'Tennessee' },
  { city: 'Crossville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Crump', state: 'TN', state_name: 'Tennessee' },
  { city: 'Cumberland County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dandridge', state: 'TN', state_name: 'Tennessee' },
  { city: 'Davidson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dayton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Decatur', state: 'TN', state_name: 'Tennessee' },
  { city: 'Decatur County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Decaturville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Decherd', state: 'TN', state_name: 'Tennessee' },
  { city: 'DeKalb County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dickson', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dickson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dodson Branch', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dover', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dresden', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dunlap', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dyer', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dyer County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Dyersburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Eagleton Village', state: 'TN', state_name: 'Tennessee' },
  { city: 'East Brainerd', state: 'TN', state_name: 'Tennessee' },
  { city: 'East Chattanooga', state: 'TN', state_name: 'Tennessee' },
  { city: 'East Cleveland', state: 'TN', state_name: 'Tennessee' },
  { city: 'East Ridge', state: 'TN', state_name: 'Tennessee' },
  { city: 'Elizabethton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Englewood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Erin', state: 'TN', state_name: 'Tennessee' },
  { city: 'Erwin', state: 'TN', state_name: 'Tennessee' },
  { city: 'Estill Springs', state: 'TN', state_name: 'Tennessee' },
  { city: 'Etowah', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fairfield Glade', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fairmount', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fairview', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fall Branch', state: 'TN', state_name: 'Tennessee' },
  { city: 'Falling Water', state: 'TN', state_name: 'Tennessee' },
  { city: 'Farragut', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fayette County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fayetteville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fentress County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Fincastle', state: 'TN', state_name: 'Tennessee' },
  { city: 'Forest Hills', state: 'TN', state_name: 'Tennessee' },
  { city: 'Franklin', state: 'TN', state_name: 'Tennessee' },
  { city: 'Franklin County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gainesboro', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gallatin', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gatlinburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Germantown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gibson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Giles County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gleason', state: 'TN', state_name: 'Tennessee' },
  { city: 'Goodlettsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gordonsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Grainger County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gray', state: 'TN', state_name: 'Tennessee' },
  { city: 'Graysville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Green Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Greenback', state: 'TN', state_name: 'Tennessee' },
  { city: 'Greenbrier', state: 'TN', state_name: 'Tennessee' },
  { city: 'Greene County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Greeneville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Greenfield', state: 'TN', state_name: 'Tennessee' },
  { city: 'Grimsley', state: 'TN', state_name: 'Tennessee' },
  { city: 'Gruetli-Laager', state: 'TN', state_name: 'Tennessee' },
  { city: 'Grundy County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Halls', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hamblen County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hamilton County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hancock County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hardeman County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hardin County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Harriman', state: 'TN', state_name: 'Tennessee' },
  { city: 'Harrison', state: 'TN', state_name: 'Tennessee' },
  { city: 'Harrogate', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hartsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hawkins County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Haywood County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Henderson', state: 'TN', state_name: 'Tennessee' },
  { city: 'Henderson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hendersonville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Henry County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hickman County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hickory Withe', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hohenwald', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hopewell', state: 'TN', state_name: 'Tennessee' },
  { city: 'Houston County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Humboldt', state: 'TN', state_name: 'Tennessee' },
  { city: 'Humphreys County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Hunter', state: 'TN', state_name: 'Tennessee' },
  { city: 'Huntingdon', state: 'TN', state_name: 'Tennessee' },
  { city: 'Huntsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jacksboro', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jackson', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jackson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jamestown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jasper', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jefferson City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jefferson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jellico', state: 'TN', state_name: 'Tennessee' },
  { city: 'Johnson City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Johnson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Johnsonville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Jonesborough', state: 'TN', state_name: 'Tennessee' },
  { city: 'Kenton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Kimball', state: 'TN', state_name: 'Tennessee' },
  { city: 'Kingsport', state: 'TN', state_name: 'Tennessee' },
  { city: 'Kingston', state: 'TN', state_name: 'Tennessee' },
  { city: 'Kingston Springs', state: 'TN', state_name: 'Tennessee' },
  { city: 'Knox County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Knoxville', state: 'TN', state_name: 'Tennessee' },
  { city: 'La Vergne', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lafayette', state: 'TN', state_name: 'Tennessee' },
  { city: 'LaFollette', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lake County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lake Tansi', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lakeland', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lakesite', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lakewood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lauderdale County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lawrence County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lawrenceburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lebanon', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lenoir City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lewis County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lewisburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lexington', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lincoln County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Linden', state: 'TN', state_name: 'Tennessee' },
  { city: 'Livingston', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lone Oak', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lookout Mountain', state: 'TN', state_name: 'Tennessee' },
  { city: 'Loretto', state: 'TN', state_name: 'Tennessee' },
  { city: 'Loudon', state: 'TN', state_name: 'Tennessee' },
  { city: 'Loudon County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Louisville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Luttrell', state: 'TN', state_name: 'Tennessee' },
  { city: 'Lynchburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Macon County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Madison County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Madisonville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Manchester', state: 'TN', state_name: 'Tennessee' },
  { city: 'Marion County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Marshall County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Martin', state: 'TN', state_name: 'Tennessee' },
  { city: 'Maryville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mascot', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mason', state: 'TN', state_name: 'Tennessee' },
  { city: 'Maury County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Maynardville', state: 'TN', state_name: 'Tennessee' },
  { city: 'McEwen', state: 'TN', state_name: 'Tennessee' },
  { city: 'McKenzie', state: 'TN', state_name: 'Tennessee' },
  { city: 'McMinn County', state: 'TN', state_name: 'Tennessee' },
  { city: 'McMinnville', state: 'TN', state_name: 'Tennessee' },
  { city: 'McNairy County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Medina', state: 'TN', state_name: 'Tennessee' },
  { city: 'Meigs County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Memphis', state: 'TN', state_name: 'Tennessee' },
  { city: 'Middle Valley', state: 'TN', state_name: 'Tennessee' },
  { city: 'Midtown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Midway', state: 'TN', state_name: 'Tennessee' },
  { city: 'Milan', state: 'TN', state_name: 'Tennessee' },
  { city: 'Millersville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Millington', state: 'TN', state_name: 'Tennessee' },
  { city: 'Monroe County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Monteagle', state: 'TN', state_name: 'Tennessee' },
  { city: 'Monterey', state: 'TN', state_name: 'Tennessee' },
  { city: 'Montgomery County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Moore County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Morgan County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Morristown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mosheim', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mount Carmel', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mount Juliet', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mount Pleasant', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mountain City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Mowbray Mountain', state: 'TN', state_name: 'Tennessee' },
  { city: 'Munford', state: 'TN', state_name: 'Tennessee' },
  { city: 'Murfreesboro', state: 'TN', state_name: 'Tennessee' },
  { city: 'Nashville', state: 'TN', state_name: 'Tennessee' },
  { city: 'New Hope', state: 'TN', state_name: 'Tennessee' },
  { city: 'New Johnsonville', state: 'TN', state_name: 'Tennessee' },
  { city: 'New Market', state: 'TN', state_name: 'Tennessee' },
  { city: 'New South Memphis', state: 'TN', state_name: 'Tennessee' },
  { city: 'New Tazewell', state: 'TN', state_name: 'Tennessee' },
  { city: 'New Union', state: 'TN', state_name: 'Tennessee' },
  { city: 'Newbern', state: 'TN', state_name: 'Tennessee' },
  { city: 'Newport', state: 'TN', state_name: 'Tennessee' },
  { city: 'Nolensville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Norris', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oak Grove', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oak Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oak Ridge', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oakland', state: 'TN', state_name: 'Tennessee' },
  { city: 'Obion', state: 'TN', state_name: 'Tennessee' },
  { city: 'Obion County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oliver Springs', state: 'TN', state_name: 'Tennessee' },
  { city: 'Olivet', state: 'TN', state_name: 'Tennessee' },
  { city: 'Oneida', state: 'TN', state_name: 'Tennessee' },
  { city: 'Overton County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Paris', state: 'TN', state_name: 'Tennessee' },
  { city: 'Park City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Parsons', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pegram', state: 'TN', state_name: 'Tennessee' },
  { city: 'Perry County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pickett County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pigeon Forge', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pikeville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pine Crest', state: 'TN', state_name: 'Tennessee' },
  { city: 'Piperton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Plainview', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pleasant View', state: 'TN', state_name: 'Tennessee' },
  { city: 'Polk County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Portland', state: 'TN', state_name: 'Tennessee' },
  { city: 'Powells Crossroads', state: 'TN', state_name: 'Tennessee' },
  { city: 'Pulaski', state: 'TN', state_name: 'Tennessee' },
  { city: 'Putnam County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Red Bank', state: 'TN', state_name: 'Tennessee' },
  { city: 'Red Boiling Springs', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rhea County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Ridgely', state: 'TN', state_name: 'Tennessee' },
  { city: 'Ridgetop', state: 'TN', state_name: 'Tennessee' },
  { city: 'Ripley', state: 'TN', state_name: 'Tennessee' },
  { city: 'Roan Mountain', state: 'TN', state_name: 'Tennessee' },
  { city: 'Roane County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Robertson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rockwood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rocky Top', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rogersville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rural Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rutherford', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rutherford County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Rutledge', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sale Creek', state: 'TN', state_name: 'Tennessee' },
  { city: 'Savannah', state: 'TN', state_name: 'Tennessee' },
  { city: 'Scott County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Selmer', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sequatchie County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sevier County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sevierville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sewanee', state: 'TN', state_name: 'Tennessee' },
  { city: 'Seymour', state: 'TN', state_name: 'Tennessee' },
  { city: 'Shackle Island', state: 'TN', state_name: 'Tennessee' },
  { city: 'Shelby County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Shelbyville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Signal Mountain', state: 'TN', state_name: 'Tennessee' },
  { city: 'Smith County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Smithville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Smyrna', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sneedville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Soddy-Daisy', state: 'TN', state_name: 'Tennessee' },
  { city: 'Somerville', state: 'TN', state_name: 'Tennessee' },
  { city: 'South Carthage', state: 'TN', state_name: 'Tennessee' },
  { city: 'South Cleveland', state: 'TN', state_name: 'Tennessee' },
  { city: 'South Fulton', state: 'TN', state_name: 'Tennessee' },
  { city: 'South Pittsburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sparta', state: 'TN', state_name: 'Tennessee' },
  { city: 'Spencer', state: 'TN', state_name: 'Tennessee' },
  { city: 'Spring City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Spring Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Springfield', state: 'TN', state_name: 'Tennessee' },
  { city: 'Spurgeon', state: 'TN', state_name: 'Tennessee' },
  { city: 'Stewart County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sullivan County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sumner County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Surgoinsville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Sweetwater', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tazewell', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tellico Village', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tennessee Ridge', state: 'TN', state_name: 'Tennessee' },
  { city: "Thompson's Station", state: 'TN', state_name: 'Tennessee' },
  { city: 'Three Way', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tipton County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tiptonville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tracy City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Trenton', state: 'TN', state_name: 'Tennessee' },
  { city: 'Trousdale County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Troy', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tullahoma', state: 'TN', state_name: 'Tennessee' },
  { city: 'Tusculum', state: 'TN', state_name: 'Tennessee' },
  { city: 'Unicoi', state: 'TN', state_name: 'Tennessee' },
  { city: 'Unicoi County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Union City', state: 'TN', state_name: 'Tennessee' },
  { city: 'Union County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Unionville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Van Buren County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Vonore', state: 'TN', state_name: 'Tennessee' },
  { city: 'Walden', state: 'TN', state_name: 'Tennessee' },
  { city: 'Walnut Hill', state: 'TN', state_name: 'Tennessee' },
  { city: 'Warren County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Wartburg', state: 'TN', state_name: 'Tennessee' },
  { city: 'Washington County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Watertown', state: 'TN', state_name: 'Tennessee' },
  { city: 'Waverly', state: 'TN', state_name: 'Tennessee' },
  { city: 'Wayne County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Waynesboro', state: 'TN', state_name: 'Tennessee' },
  { city: 'Weakley County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Westmoreland', state: 'TN', state_name: 'Tennessee' },
  { city: 'White Bluff', state: 'TN', state_name: 'Tennessee' },
  { city: 'White County', state: 'TN', state_name: 'Tennessee' },
  { city: 'White House', state: 'TN', state_name: 'Tennessee' },
  { city: 'White Pine', state: 'TN', state_name: 'Tennessee' },
  { city: 'Whiteville', state: 'TN', state_name: 'Tennessee' },
  { city: 'Whitwell', state: 'TN', state_name: 'Tennessee' },
  { city: 'Wildwood', state: 'TN', state_name: 'Tennessee' },
  { city: 'Wildwood Lake', state: 'TN', state_name: 'Tennessee' },
  { city: 'Williamson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Wilson County', state: 'TN', state_name: 'Tennessee' },
  { city: 'Winchester', state: 'TN', state_name: 'Tennessee' },
  { city: 'Woodbury', state: 'TN', state_name: 'Tennessee' },
  { city: 'Abernathy', state: 'TX', state_name: 'Texas' },
  { city: 'Abilene', state: 'TX', state_name: 'Texas' },
  { city: 'Abram', state: 'TX', state_name: 'Texas' },
  { city: 'Addison', state: 'TX', state_name: 'Texas' },
  { city: 'Agua Dulce', state: 'TX', state_name: 'Texas' },
  { city: 'Alamo', state: 'TX', state_name: 'Texas' },
  { city: 'Alamo Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Albany', state: 'TX', state_name: 'Texas' },
  { city: 'Aldine', state: 'TX', state_name: 'Texas' },
  { city: 'Aledo', state: 'TX', state_name: 'Texas' },
  { city: 'Alice', state: 'TX', state_name: 'Texas' },
  { city: 'Alief', state: 'TX', state_name: 'Texas' },
  { city: 'Allen', state: 'TX', state_name: 'Texas' },
  { city: 'Alpine', state: 'TX', state_name: 'Texas' },
  { city: 'Alto', state: 'TX', state_name: 'Texas' },
  { city: 'Alton', state: 'TX', state_name: 'Texas' },
  { city: 'Alton North (historical)', state: 'TX', state_name: 'Texas' },
  { city: 'Alvarado', state: 'TX', state_name: 'Texas' },
  { city: 'Alvin', state: 'TX', state_name: 'Texas' },
  { city: 'Alvord', state: 'TX', state_name: 'Texas' },
  { city: 'Amarillo', state: 'TX', state_name: 'Texas' },
  { city: 'Ames', state: 'TX', state_name: 'Texas' },
  { city: 'Anahuac', state: 'TX', state_name: 'Texas' },
  { city: 'Anderson', state: 'TX', state_name: 'Texas' },
  { city: 'Anderson County', state: 'TX', state_name: 'Texas' },
  { city: 'Anderson Mill', state: 'TX', state_name: 'Texas' },
  { city: 'Andrews', state: 'TX', state_name: 'Texas' },
  { city: 'Andrews County', state: 'TX', state_name: 'Texas' },
  { city: 'Angelina County', state: 'TX', state_name: 'Texas' },
  { city: 'Angleton', state: 'TX', state_name: 'Texas' },
  { city: 'Anna', state: 'TX', state_name: 'Texas' },
  { city: 'Annetta', state: 'TX', state_name: 'Texas' },
  { city: 'Anson', state: 'TX', state_name: 'Texas' },
  { city: 'Anthony', state: 'TX', state_name: 'Texas' },
  { city: 'Anton', state: 'TX', state_name: 'Texas' },
  { city: 'Aransas County', state: 'TX', state_name: 'Texas' },
  { city: 'Aransas Pass', state: 'TX', state_name: 'Texas' },
  { city: 'Archer City', state: 'TX', state_name: 'Texas' },
  { city: 'Archer County', state: 'TX', state_name: 'Texas' },
  { city: 'Arcola', state: 'TX', state_name: 'Texas' },
  { city: 'Argyle', state: 'TX', state_name: 'Texas' },
  { city: 'Arlington', state: 'TX', state_name: 'Texas' },
  { city: 'Armstrong County', state: 'TX', state_name: 'Texas' },
  { city: 'Asherton', state: 'TX', state_name: 'Texas' },
  { city: 'Aspermont', state: 'TX', state_name: 'Texas' },
  { city: 'Atascocita', state: 'TX', state_name: 'Texas' },
  { city: 'Atascosa County', state: 'TX', state_name: 'Texas' },
  { city: 'Athens', state: 'TX', state_name: 'Texas' },
  { city: 'Atlanta', state: 'TX', state_name: 'Texas' },
  { city: 'Aubrey', state: 'TX', state_name: 'Texas' },
  { city: 'Aurora', state: 'TX', state_name: 'Texas' },
  { city: 'Austin', state: 'TX', state_name: 'Texas' },
  { city: 'Austin County', state: 'TX', state_name: 'Texas' },
  { city: 'Azle', state: 'TX', state_name: 'Texas' },
  { city: 'Bacliff', state: 'TX', state_name: 'Texas' },
  { city: 'Bailey County', state: 'TX', state_name: 'Texas' },
  { city: 'Baird', state: 'TX', state_name: 'Texas' },
  { city: 'Balch Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Balcones Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Ballinger', state: 'TX', state_name: 'Texas' },
  { city: 'Bandera', state: 'TX', state_name: 'Texas' },
  { city: 'Bandera County', state: 'TX', state_name: 'Texas' },
  { city: 'Bangs', state: 'TX', state_name: 'Texas' },
  { city: 'Barrett', state: 'TX', state_name: 'Texas' },
  { city: 'Bartlett', state: 'TX', state_name: 'Texas' },
  { city: 'Barton Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Bartonville', state: 'TX', state_name: 'Texas' },
  { city: 'Bastrop', state: 'TX', state_name: 'Texas' },
  { city: 'Bastrop County', state: 'TX', state_name: 'Texas' },
  { city: 'Batesville', state: 'TX', state_name: 'Texas' },
  { city: 'Bay City', state: 'TX', state_name: 'Texas' },
  { city: 'Baylor County', state: 'TX', state_name: 'Texas' },
  { city: 'Bayou Vista', state: 'TX', state_name: 'Texas' },
  { city: 'Baytown', state: 'TX', state_name: 'Texas' },
  { city: 'Beach City', state: 'TX', state_name: 'Texas' },
  { city: 'Beaumont', state: 'TX', state_name: 'Texas' },
  { city: 'Bedford', state: 'TX', state_name: 'Texas' },
  { city: 'Bee Cave', state: 'TX', state_name: 'Texas' },
  { city: 'Bee County', state: 'TX', state_name: 'Texas' },
  { city: 'Beeville', state: 'TX', state_name: 'Texas' },
  { city: 'Bell County', state: 'TX', state_name: 'Texas' },
  { city: 'Bellaire', state: 'TX', state_name: 'Texas' },
  { city: 'Bellmead', state: 'TX', state_name: 'Texas' },
  { city: 'Bells', state: 'TX', state_name: 'Texas' },
  { city: 'Bellville', state: 'TX', state_name: 'Texas' },
  { city: 'Belton', state: 'TX', state_name: 'Texas' },
  { city: 'Benavides', state: 'TX', state_name: 'Texas' },
  { city: 'Benbrook', state: 'TX', state_name: 'Texas' },
  { city: 'Benjamin', state: 'TX', state_name: 'Texas' },
  { city: 'Berryville', state: 'TX', state_name: 'Texas' },
  { city: 'Bertram', state: 'TX', state_name: 'Texas' },
  { city: 'Beverly', state: 'TX', state_name: 'Texas' },
  { city: 'Beverly Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Bevil Oaks', state: 'TX', state_name: 'Texas' },
  { city: 'Bexar County', state: 'TX', state_name: 'Texas' },
  { city: 'Big Lake', state: 'TX', state_name: 'Texas' },
  { city: 'Big Sandy', state: 'TX', state_name: 'Texas' },
  { city: 'Big Spring', state: 'TX', state_name: 'Texas' },
  { city: 'Bishop', state: 'TX', state_name: 'Texas' },
  { city: 'Blanco', state: 'TX', state_name: 'Texas' },
  { city: 'Blanco County', state: 'TX', state_name: 'Texas' },
  { city: 'Bloomington', state: 'TX', state_name: 'Texas' },
  { city: 'Blossom', state: 'TX', state_name: 'Texas' },
  { city: 'Blue Mound', state: 'TX', state_name: 'Texas' },
  { city: 'Boerne', state: 'TX', state_name: 'Texas' },
  { city: 'Bogata', state: 'TX', state_name: 'Texas' },
  { city: 'Boling', state: 'TX', state_name: 'Texas' },
  { city: 'Bolivar Peninsula', state: 'TX', state_name: 'Texas' },
  { city: 'Bonham', state: 'TX', state_name: 'Texas' },
  { city: 'Booker', state: 'TX', state_name: 'Texas' },
  { city: 'Borden County', state: 'TX', state_name: 'Texas' },
  { city: 'Borger', state: 'TX', state_name: 'Texas' },
  { city: 'Bosque County', state: 'TX', state_name: 'Texas' },
  { city: 'Bovina', state: 'TX', state_name: 'Texas' },
  { city: 'Bowie', state: 'TX', state_name: 'Texas' },
  { city: 'Bowie County', state: 'TX', state_name: 'Texas' },
  { city: 'Boyd', state: 'TX', state_name: 'Texas' },
  { city: 'Brackettville', state: 'TX', state_name: 'Texas' },
  { city: 'Brady', state: 'TX', state_name: 'Texas' },
  { city: 'Brazoria', state: 'TX', state_name: 'Texas' },
  { city: 'Brazoria County', state: 'TX', state_name: 'Texas' },
  { city: 'Brazos County', state: 'TX', state_name: 'Texas' },
  { city: 'Breckenridge', state: 'TX', state_name: 'Texas' },
  { city: 'Brenham', state: 'TX', state_name: 'Texas' },
  { city: 'Brewster County', state: 'TX', state_name: 'Texas' },
  { city: 'Briar', state: 'TX', state_name: 'Texas' },
  { city: 'Briarcliff', state: 'TX', state_name: 'Texas' },
  { city: 'Bridge City', state: 'TX', state_name: 'Texas' },
  { city: 'Bridgeport', state: 'TX', state_name: 'Texas' },
  { city: 'Briscoe County', state: 'TX', state_name: 'Texas' },
  { city: 'Brooks County', state: 'TX', state_name: 'Texas' },
  { city: 'Brookshire', state: 'TX', state_name: 'Texas' },
  { city: 'Brookside Village', state: 'TX', state_name: 'Texas' },
  { city: 'Brown County', state: 'TX', state_name: 'Texas' },
  { city: 'Brownfield', state: 'TX', state_name: 'Texas' },
  { city: 'Brownsboro', state: 'TX', state_name: 'Texas' },
  { city: 'Brownsville', state: 'TX', state_name: 'Texas' },
  { city: 'Brownwood', state: 'TX', state_name: 'Texas' },
  { city: 'Bruceville-Eddy', state: 'TX', state_name: 'Texas' },
  { city: 'Brushy Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Bryan', state: 'TX', state_name: 'Texas' },
  { city: 'Buchanan Dam', state: 'TX', state_name: 'Texas' },
  { city: 'Buda', state: 'TX', state_name: 'Texas' },
  { city: 'Buffalo', state: 'TX', state_name: 'Texas' },
  { city: 'Bullard', state: 'TX', state_name: 'Texas' },
  { city: 'Bulverde', state: 'TX', state_name: 'Texas' },
  { city: 'Buna', state: 'TX', state_name: 'Texas' },
  { city: 'Bunker Hill Village', state: 'TX', state_name: 'Texas' },
  { city: 'Burkburnett', state: 'TX', state_name: 'Texas' },
  { city: 'Burleson', state: 'TX', state_name: 'Texas' },
  { city: 'Burleson County', state: 'TX', state_name: 'Texas' },
  { city: 'Burnet', state: 'TX', state_name: 'Texas' },
  { city: 'Burnet County', state: 'TX', state_name: 'Texas' },
  { city: 'Bushland', state: 'TX', state_name: 'Texas' },
  { city: 'Cactus', state: 'TX', state_name: 'Texas' },
  { city: 'Caddo Mills', state: 'TX', state_name: 'Texas' },
  { city: 'Caldwell', state: 'TX', state_name: 'Texas' },
  { city: 'Caldwell County', state: 'TX', state_name: 'Texas' },
  { city: 'Calhoun County', state: 'TX', state_name: 'Texas' },
  { city: 'Callahan County', state: 'TX', state_name: 'Texas' },
  { city: 'Calvert', state: 'TX', state_name: 'Texas' },
  { city: 'Cameron', state: 'TX', state_name: 'Texas' },
  { city: 'Cameron County', state: 'TX', state_name: 'Texas' },
  { city: 'Cameron Park', state: 'TX', state_name: 'Texas' },
  { city: 'Cameron Park Colonia', state: 'TX', state_name: 'Texas' },
  { city: 'Camp County', state: 'TX', state_name: 'Texas' },
  { city: 'Camp Swift', state: 'TX', state_name: 'Texas' },
  { city: 'Canadian', state: 'TX', state_name: 'Texas' },
  { city: 'Canton', state: 'TX', state_name: 'Texas' },
  { city: 'Canutillo', state: 'TX', state_name: 'Texas' },
  { city: 'Canyon', state: 'TX', state_name: 'Texas' },
  { city: 'Canyon Lake', state: 'TX', state_name: 'Texas' },
  { city: 'Carrizo Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Carrollton', state: 'TX', state_name: 'Texas' },
  { city: 'Carson County', state: 'TX', state_name: 'Texas' },
  { city: 'Carthage', state: 'TX', state_name: 'Texas' },
  { city: 'Cass County', state: 'TX', state_name: 'Texas' },
  { city: 'Castle Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Castro County', state: 'TX', state_name: 'Texas' },
  { city: 'Castroville', state: 'TX', state_name: 'Texas' },
  { city: 'Cedar Hill', state: 'TX', state_name: 'Texas' },
  { city: 'Cedar Park', state: 'TX', state_name: 'Texas' },
  { city: 'Celina', state: 'TX', state_name: 'Texas' },
  { city: 'Center', state: 'TX', state_name: 'Texas' },
  { city: 'Centerville', state: 'TX', state_name: 'Texas' },
  { city: 'Central Gardens', state: 'TX', state_name: 'Texas' },
  { city: 'César Chávez', state: 'TX', state_name: 'Texas' },
  { city: 'Chambers County', state: 'TX', state_name: 'Texas' },
  { city: 'Chandler', state: 'TX', state_name: 'Texas' },
  { city: 'Channelview', state: 'TX', state_name: 'Texas' },
  { city: 'Channing', state: 'TX', state_name: 'Texas' },
  { city: 'Charlotte', state: 'TX', state_name: 'Texas' },
  { city: 'Cherokee County', state: 'TX', state_name: 'Texas' },
  { city: 'Chico', state: 'TX', state_name: 'Texas' },
  { city: 'Childress', state: 'TX', state_name: 'Texas' },
  { city: 'Childress County', state: 'TX', state_name: 'Texas' },
  { city: 'China', state: 'TX', state_name: 'Texas' },
  { city: 'China Grove', state: 'TX', state_name: 'Texas' },
  { city: 'Cibolo', state: 'TX', state_name: 'Texas' },
  { city: 'Cienegas Terrace', state: 'TX', state_name: 'Texas' },
  { city: 'Cinco Ranch', state: 'TX', state_name: 'Texas' },
  { city: 'Circle D-KC Estates', state: 'TX', state_name: 'Texas' },
  { city: 'Cisco', state: 'TX', state_name: 'Texas' },
  { city: 'Citrus City', state: 'TX', state_name: 'Texas' },
  { city: 'Clarendon', state: 'TX', state_name: 'Texas' },
  { city: 'Clarksville', state: 'TX', state_name: 'Texas' },
  { city: 'Claude', state: 'TX', state_name: 'Texas' },
  { city: 'Clay County', state: 'TX', state_name: 'Texas' },
  { city: 'Clear Lake Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Cleburne', state: 'TX', state_name: 'Texas' },
  { city: 'Cleveland', state: 'TX', state_name: 'Texas' },
  { city: 'Clifton', state: 'TX', state_name: 'Texas' },
  { city: 'Clint', state: 'TX', state_name: 'Texas' },
  { city: 'Cloverleaf', state: 'TX', state_name: 'Texas' },
  { city: 'Clute', state: 'TX', state_name: 'Texas' },
  { city: 'Clyde', state: 'TX', state_name: 'Texas' },
  { city: 'Cochran County', state: 'TX', state_name: 'Texas' },
  { city: 'Cockrell Hill', state: 'TX', state_name: 'Texas' },
  { city: 'Coke County', state: 'TX', state_name: 'Texas' },
  { city: 'Coldspring', state: 'TX', state_name: 'Texas' },
  { city: 'Coleman', state: 'TX', state_name: 'Texas' },
  { city: 'Coleman County', state: 'TX', state_name: 'Texas' },
  { city: 'College Station', state: 'TX', state_name: 'Texas' },
  { city: 'Colleyville', state: 'TX', state_name: 'Texas' },
  { city: 'Collin County', state: 'TX', state_name: 'Texas' },
  { city: 'Collingsworth County', state: 'TX', state_name: 'Texas' },
  { city: 'Collinsville', state: 'TX', state_name: 'Texas' },
  { city: 'Colorado City', state: 'TX', state_name: 'Texas' },
  { city: 'Colorado County', state: 'TX', state_name: 'Texas' },
  { city: 'Columbus', state: 'TX', state_name: 'Texas' },
  { city: 'Comal County', state: 'TX', state_name: 'Texas' },
  { city: 'Comanche', state: 'TX', state_name: 'Texas' },
  { city: 'Comanche County', state: 'TX', state_name: 'Texas' },
  { city: 'Combes', state: 'TX', state_name: 'Texas' },
  { city: 'Combine', state: 'TX', state_name: 'Texas' },
  { city: 'Comfort', state: 'TX', state_name: 'Texas' },
  { city: 'Commerce', state: 'TX', state_name: 'Texas' },
  { city: 'Concho County', state: 'TX', state_name: 'Texas' },
  { city: 'Conroe', state: 'TX', state_name: 'Texas' },
  { city: 'Converse', state: 'TX', state_name: 'Texas' },
  { city: 'Cooke County', state: 'TX', state_name: 'Texas' },
  { city: 'Cooper', state: 'TX', state_name: 'Texas' },
  { city: 'Coppell', state: 'TX', state_name: 'Texas' },
  { city: 'Copper Canyon', state: 'TX', state_name: 'Texas' },
  { city: 'Copperas Cove', state: 'TX', state_name: 'Texas' },
  { city: 'Corinth', state: 'TX', state_name: 'Texas' },
  { city: 'Corpus Christi', state: 'TX', state_name: 'Texas' },
  { city: 'Corrigan', state: 'TX', state_name: 'Texas' },
  { city: 'Corsicana', state: 'TX', state_name: 'Texas' },
  { city: 'Coryell County', state: 'TX', state_name: 'Texas' },
  { city: 'Cottle County', state: 'TX', state_name: 'Texas' },
  { city: 'Cottonwood Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Cotulla', state: 'TX', state_name: 'Texas' },
  { city: 'Crandall', state: 'TX', state_name: 'Texas' },
  { city: 'Crane', state: 'TX', state_name: 'Texas' },
  { city: 'Crane County', state: 'TX', state_name: 'Texas' },
  { city: 'Crockett', state: 'TX', state_name: 'Texas' },
  { city: 'Crockett County', state: 'TX', state_name: 'Texas' },
  { city: 'Crosby', state: 'TX', state_name: 'Texas' },
  { city: 'Crosby County', state: 'TX', state_name: 'Texas' },
  { city: 'Crosbyton', state: 'TX', state_name: 'Texas' },
  { city: 'Cross Mountain', state: 'TX', state_name: 'Texas' },
  { city: 'Crowell', state: 'TX', state_name: 'Texas' },
  { city: 'Crowley', state: 'TX', state_name: 'Texas' },
  { city: 'Crystal City', state: 'TX', state_name: 'Texas' },
  { city: 'Cuero', state: 'TX', state_name: 'Texas' },
  { city: 'Culberson County', state: 'TX', state_name: 'Texas' },
  { city: 'Cut and Shoot', state: 'TX', state_name: 'Texas' },
  { city: 'Cypress', state: 'TX', state_name: 'Texas' },
  { city: 'Daingerfield', state: 'TX', state_name: 'Texas' },
  { city: 'Dalhart', state: 'TX', state_name: 'Texas' },
  { city: 'Dallam County', state: 'TX', state_name: 'Texas' },
  { city: 'Dallas', state: 'TX', state_name: 'Texas' },
  { city: 'Dallas County', state: 'TX', state_name: 'Texas' },
  { city: 'Dalworthington Gardens', state: 'TX', state_name: 'Texas' },
  { city: 'Danbury', state: 'TX', state_name: 'Texas' },
  { city: 'Dawson County', state: 'TX', state_name: 'Texas' },
  { city: 'Dayton', state: 'TX', state_name: 'Texas' },
  { city: 'De Kalb', state: 'TX', state_name: 'Texas' },
  { city: 'De Leon', state: 'TX', state_name: 'Texas' },
  { city: 'Deaf Smith County', state: 'TX', state_name: 'Texas' },
  { city: 'Decatur', state: 'TX', state_name: 'Texas' },
  { city: 'DeCordova', state: 'TX', state_name: 'Texas' },
  { city: 'Deer Park', state: 'TX', state_name: 'Texas' },
  { city: 'Del Rio', state: 'TX', state_name: 'Texas' },
  { city: 'Delta County', state: 'TX', state_name: 'Texas' },
  { city: 'Denison', state: 'TX', state_name: 'Texas' },
  { city: 'Denton', state: 'TX', state_name: 'Texas' },
  { city: 'Denton County', state: 'TX', state_name: 'Texas' },
  { city: 'Denver City', state: 'TX', state_name: 'Texas' },
  { city: 'DeSoto', state: 'TX', state_name: 'Texas' },
  { city: 'Devine', state: 'TX', state_name: 'Texas' },
  { city: 'Deweyville', state: 'TX', state_name: 'Texas' },
  { city: 'DeWitt County', state: 'TX', state_name: 'Texas' },
  { city: 'Diboll', state: 'TX', state_name: 'Texas' },
  { city: 'Dickens', state: 'TX', state_name: 'Texas' },
  { city: 'Dickens County', state: 'TX', state_name: 'Texas' },
  { city: 'Dickinson', state: 'TX', state_name: 'Texas' },
  { city: 'Dilley', state: 'TX', state_name: 'Texas' },
  { city: 'Dimmit County', state: 'TX', state_name: 'Texas' },
  { city: 'Dimmitt', state: 'TX', state_name: 'Texas' },
  { city: 'Doffing', state: 'TX', state_name: 'Texas' },
  { city: 'Donley County', state: 'TX', state_name: 'Texas' },
  { city: 'Donna', state: 'TX', state_name: 'Texas' },
  { city: 'Doolittle', state: 'TX', state_name: 'Texas' },
  { city: 'Double Oak', state: 'TX', state_name: 'Texas' },
  { city: 'Dripping Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Dublin', state: 'TX', state_name: 'Texas' },
  { city: 'Dumas', state: 'TX', state_name: 'Texas' },
  { city: 'Duncanville', state: 'TX', state_name: 'Texas' },
  { city: 'Duval County', state: 'TX', state_name: 'Texas' },
  { city: 'Eagle Lake', state: 'TX', state_name: 'Texas' },
  { city: 'Eagle Mountain', state: 'TX', state_name: 'Texas' },
  { city: 'Eagle Pass', state: 'TX', state_name: 'Texas' },
  { city: 'Early', state: 'TX', state_name: 'Texas' },
  { city: 'Earth', state: 'TX', state_name: 'Texas' },
  { city: 'East Bernard', state: 'TX', state_name: 'Texas' },
  { city: 'Eastland', state: 'TX', state_name: 'Texas' },
  { city: 'Eastland County', state: 'TX', state_name: 'Texas' },
  { city: 'Ector County', state: 'TX', state_name: 'Texas' },
  { city: 'Edcouch', state: 'TX', state_name: 'Texas' },
  { city: 'Eden', state: 'TX', state_name: 'Texas' },
  { city: 'Edgecliff Village', state: 'TX', state_name: 'Texas' },
  { city: 'Edgewood', state: 'TX', state_name: 'Texas' },
  { city: 'Edinburg', state: 'TX', state_name: 'Texas' },
  { city: 'Edna', state: 'TX', state_name: 'Texas' },
  { city: 'Edwards County', state: 'TX', state_name: 'Texas' },
  { city: 'Eidson Road', state: 'TX', state_name: 'Texas' },
  { city: 'El Campo', state: 'TX', state_name: 'Texas' },
  { city: 'El Cenizo', state: 'TX', state_name: 'Texas' },
  { city: 'El Lago', state: 'TX', state_name: 'Texas' },
  { city: 'El Paso', state: 'TX', state_name: 'Texas' },
  { city: 'El Paso County', state: 'TX', state_name: 'Texas' },
  { city: 'Eldorado', state: 'TX', state_name: 'Texas' },
  { city: 'Electra', state: 'TX', state_name: 'Texas' },
  { city: 'Elgin', state: 'TX', state_name: 'Texas' },
  { city: 'Elkhart', state: 'TX', state_name: 'Texas' },
  { city: 'Ellis County', state: 'TX', state_name: 'Texas' },
  { city: 'Elm Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Elmendorf', state: 'TX', state_name: 'Texas' },
  { city: 'Elsa', state: 'TX', state_name: 'Texas' },
  { city: 'Emory', state: 'TX', state_name: 'Texas' },
  { city: 'Encantada-Ranchito-El Calaboz', state: 'TX', state_name: 'Texas' },
  { city: 'Ennis', state: 'TX', state_name: 'Texas' },
  { city: 'Erath County', state: 'TX', state_name: 'Texas' },
  { city: 'Escobares', state: 'TX', state_name: 'Texas' },
  { city: 'Euless', state: 'TX', state_name: 'Texas' },
  { city: 'Evadale', state: 'TX', state_name: 'Texas' },
  { city: 'Everman', state: 'TX', state_name: 'Texas' },
  { city: 'Fabens', state: 'TX', state_name: 'Texas' },
  { city: 'Fair Oaks Ranch', state: 'TX', state_name: 'Texas' },
  { city: 'Fairchilds', state: 'TX', state_name: 'Texas' },
  { city: 'Fairfield', state: 'TX', state_name: 'Texas' },
  { city: 'Fairview', state: 'TX', state_name: 'Texas' },
  { city: 'Falcon Lake Estates', state: 'TX', state_name: 'Texas' },
  { city: 'Falfurrias', state: 'TX', state_name: 'Texas' },
  { city: 'Falls County', state: 'TX', state_name: 'Texas' },
  { city: 'Fannett', state: 'TX', state_name: 'Texas' },
  { city: 'Fannin County', state: 'TX', state_name: 'Texas' },
  { city: 'Farmers Branch', state: 'TX', state_name: 'Texas' },
  { city: 'Farmersville', state: 'TX', state_name: 'Texas' },
  { city: 'Farwell', state: 'TX', state_name: 'Texas' },
  { city: 'Fate', state: 'TX', state_name: 'Texas' },
  { city: 'Fayette County', state: 'TX', state_name: 'Texas' },
  { city: 'Ferris', state: 'TX', state_name: 'Texas' },
  { city: 'Fifth Street', state: 'TX', state_name: 'Texas' },
  { city: 'Fisher County', state: 'TX', state_name: 'Texas' },
  { city: 'Flatonia', state: 'TX', state_name: 'Texas' },
  { city: 'Florence', state: 'TX', state_name: 'Texas' },
  { city: 'Floresville', state: 'TX', state_name: 'Texas' },
  { city: 'Flower Mound', state: 'TX', state_name: 'Texas' },
  { city: 'Floyd County', state: 'TX', state_name: 'Texas' },
  { city: 'Floydada', state: 'TX', state_name: 'Texas' },
  { city: 'Foard County', state: 'TX', state_name: 'Texas' },
  { city: 'Forest Hill', state: 'TX', state_name: 'Texas' },
  { city: 'Forney', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Bend County', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Bliss', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Clark Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Davis', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Hancock', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Hood', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Stockton', state: 'TX', state_name: 'Texas' },
  { city: 'Fort Worth', state: 'TX', state_name: 'Texas' },
  { city: 'Four Corners', state: 'TX', state_name: 'Texas' },
  { city: 'Franklin', state: 'TX', state_name: 'Texas' },
  { city: 'Franklin County', state: 'TX', state_name: 'Texas' },
  { city: 'Frankston', state: 'TX', state_name: 'Texas' },
  { city: 'Fredericksburg', state: 'TX', state_name: 'Texas' },
  { city: 'Freeport', state: 'TX', state_name: 'Texas' },
  { city: 'Freer', state: 'TX', state_name: 'Texas' },
  { city: 'Freestone County', state: 'TX', state_name: 'Texas' },
  { city: 'Fresno', state: 'TX', state_name: 'Texas' },
  { city: 'Friendswood', state: 'TX', state_name: 'Texas' },
  { city: 'Frio County', state: 'TX', state_name: 'Texas' },
  { city: 'Friona', state: 'TX', state_name: 'Texas' },
  { city: 'Frisco', state: 'TX', state_name: 'Texas' },
  { city: 'Fritch', state: 'TX', state_name: 'Texas' },
  { city: 'Fulshear', state: 'TX', state_name: 'Texas' },
  { city: 'Fulton', state: 'TX', state_name: 'Texas' },
  { city: 'Gail', state: 'TX', state_name: 'Texas' },
  { city: 'Gaines County', state: 'TX', state_name: 'Texas' },
  { city: 'Gainesville', state: 'TX', state_name: 'Texas' },
  { city: 'Galena Park', state: 'TX', state_name: 'Texas' },
  { city: 'Galveston', state: 'TX', state_name: 'Texas' },
  { city: 'Galveston County', state: 'TX', state_name: 'Texas' },
  { city: 'Ganado', state: 'TX', state_name: 'Texas' },
  { city: 'Garden City', state: 'TX', state_name: 'Texas' },
  { city: 'Garden Ridge', state: 'TX', state_name: 'Texas' },
  { city: 'Gardendale', state: 'TX', state_name: 'Texas' },
  { city: 'Garfield', state: 'TX', state_name: 'Texas' },
  { city: 'Garland', state: 'TX', state_name: 'Texas' },
  { city: 'Garza County', state: 'TX', state_name: 'Texas' },
  { city: 'Gatesville', state: 'TX', state_name: 'Texas' },
  { city: 'George West', state: 'TX', state_name: 'Texas' },
  { city: 'Georgetown', state: 'TX', state_name: 'Texas' },
  { city: 'Geronimo', state: 'TX', state_name: 'Texas' },
  { city: 'Gholson', state: 'TX', state_name: 'Texas' },
  { city: 'Giddings', state: 'TX', state_name: 'Texas' },
  { city: 'Gillespie County', state: 'TX', state_name: 'Texas' },
  { city: 'Gilmer', state: 'TX', state_name: 'Texas' },
  { city: 'Gladewater', state: 'TX', state_name: 'Texas' },
  { city: 'Glasscock County', state: 'TX', state_name: 'Texas' },
  { city: 'Glen Rose', state: 'TX', state_name: 'Texas' },
  { city: 'Glenn Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Godley', state: 'TX', state_name: 'Texas' },
  { city: 'Goldthwaite', state: 'TX', state_name: 'Texas' },
  { city: 'Goliad', state: 'TX', state_name: 'Texas' },
  { city: 'Goliad County', state: 'TX', state_name: 'Texas' },
  { city: 'Gonzales', state: 'TX', state_name: 'Texas' },
  { city: 'Gonzales County', state: 'TX', state_name: 'Texas' },
  { city: 'Gorman', state: 'TX', state_name: 'Texas' },
  { city: 'Graham', state: 'TX', state_name: 'Texas' },
  { city: 'Granbury', state: 'TX', state_name: 'Texas' },
  { city: 'Grand Prairie', state: 'TX', state_name: 'Texas' },
  { city: 'Grand Saline', state: 'TX', state_name: 'Texas' },
  { city: 'Grandview', state: 'TX', state_name: 'Texas' },
  { city: 'Granger', state: 'TX', state_name: 'Texas' },
  { city: 'Granite Shoals', state: 'TX', state_name: 'Texas' },
  { city: 'Grape Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Grapeland', state: 'TX', state_name: 'Texas' },
  { city: 'Grapevine', state: 'TX', state_name: 'Texas' },
  { city: 'Gray County', state: 'TX', state_name: 'Texas' },
  { city: 'Grayson County', state: 'TX', state_name: 'Texas' },
  { city: 'Greatwood', state: 'TX', state_name: 'Texas' },
  { city: 'Green Valley Farms', state: 'TX', state_name: 'Texas' },
  { city: 'Greenville', state: 'TX', state_name: 'Texas' },
  { city: 'Gregg County', state: 'TX', state_name: 'Texas' },
  { city: 'Gregory', state: 'TX', state_name: 'Texas' },
  { city: 'Grimes County', state: 'TX', state_name: 'Texas' },
  { city: 'Groesbeck', state: 'TX', state_name: 'Texas' },
  { city: 'Groves', state: 'TX', state_name: 'Texas' },
  { city: 'Groveton', state: 'TX', state_name: 'Texas' },
  { city: 'Gruver', state: 'TX', state_name: 'Texas' },
  { city: 'Guadalupe County', state: 'TX', state_name: 'Texas' },
  { city: 'Gun Barrel City', state: 'TX', state_name: 'Texas' },
  { city: 'Gunter', state: 'TX', state_name: 'Texas' },
  { city: 'Guthrie', state: 'TX', state_name: 'Texas' },
  { city: 'Hackberry', state: 'TX', state_name: 'Texas' },
  { city: 'Hale Center', state: 'TX', state_name: 'Texas' },
  { city: 'Hale County', state: 'TX', state_name: 'Texas' },
  { city: 'Hall County', state: 'TX', state_name: 'Texas' },
  { city: 'Hallettsville', state: 'TX', state_name: 'Texas' },
  { city: 'Hallsville', state: 'TX', state_name: 'Texas' },
  { city: 'Haltom City', state: 'TX', state_name: 'Texas' },
  { city: 'Hamilton', state: 'TX', state_name: 'Texas' },
  { city: 'Hamilton County', state: 'TX', state_name: 'Texas' },
  { city: 'Hamlin', state: 'TX', state_name: 'Texas' },
  { city: 'Hansford County', state: 'TX', state_name: 'Texas' },
  { city: 'Hardeman County', state: 'TX', state_name: 'Texas' },
  { city: 'Hardin County', state: 'TX', state_name: 'Texas' },
  { city: 'Harker Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Harlingen', state: 'TX', state_name: 'Texas' },
  { city: 'Harper', state: 'TX', state_name: 'Texas' },
  { city: 'Harris County', state: 'TX', state_name: 'Texas' },
  { city: 'Harrison County', state: 'TX', state_name: 'Texas' },
  { city: 'Hart', state: 'TX', state_name: 'Texas' },
  { city: 'Hartley County', state: 'TX', state_name: 'Texas' },
  { city: 'Haskell', state: 'TX', state_name: 'Texas' },
  { city: 'Haskell County', state: 'TX', state_name: 'Texas' },
  { city: 'Haslet', state: 'TX', state_name: 'Texas' },
  { city: 'Hawkins', state: 'TX', state_name: 'Texas' },
  { city: 'Hays County', state: 'TX', state_name: 'Texas' },
  { city: 'Hearne', state: 'TX', state_name: 'Texas' },
  { city: 'Heath', state: 'TX', state_name: 'Texas' },
  { city: 'Hebbronville', state: 'TX', state_name: 'Texas' },
  { city: 'Hedwig Village', state: 'TX', state_name: 'Texas' },
  { city: 'Heidelberg', state: 'TX', state_name: 'Texas' },
  { city: 'Helotes', state: 'TX', state_name: 'Texas' },
  { city: 'Hemphill', state: 'TX', state_name: 'Texas' },
  { city: 'Hemphill County', state: 'TX', state_name: 'Texas' },
  { city: 'Hempstead', state: 'TX', state_name: 'Texas' },
  { city: 'Henderson', state: 'TX', state_name: 'Texas' },
  { city: 'Henderson County', state: 'TX', state_name: 'Texas' },
  { city: 'Henrietta', state: 'TX', state_name: 'Texas' },
  { city: 'Hereford', state: 'TX', state_name: 'Texas' },
  { city: 'Hewitt', state: 'TX', state_name: 'Texas' },
  { city: 'Hickory Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Hico', state: 'TX', state_name: 'Texas' },
  { city: 'Hidalgo', state: 'TX', state_name: 'Texas' },
  { city: 'Hidalgo County', state: 'TX', state_name: 'Texas' },
  { city: 'Hideaway', state: 'TX', state_name: 'Texas' },
  { city: 'Highland Park', state: 'TX', state_name: 'Texas' },
  { city: 'Highland Village', state: 'TX', state_name: 'Texas' },
  { city: 'Highlands', state: 'TX', state_name: 'Texas' },
  { city: 'Hill Country Village', state: 'TX', state_name: 'Texas' },
  { city: 'Hill County', state: 'TX', state_name: 'Texas' },
  { city: 'Hillsboro', state: 'TX', state_name: 'Texas' },
  { city: 'Hilltop Lakes', state: 'TX', state_name: 'Texas' },
  { city: 'Hitchcock', state: 'TX', state_name: 'Texas' },
  { city: 'Hockley County', state: 'TX', state_name: 'Texas' },
  { city: 'Holiday Lakes', state: 'TX', state_name: 'Texas' },
  { city: 'Holland', state: 'TX', state_name: 'Texas' },
  { city: 'Holliday', state: 'TX', state_name: 'Texas' },
  { city: 'Holly Lake Ranch', state: 'TX', state_name: 'Texas' },
  { city: 'Hollywood Park', state: 'TX', state_name: 'Texas' },
  { city: 'Homestead Meadows North', state: 'TX', state_name: 'Texas' },
  { city: 'Homestead Meadows South', state: 'TX', state_name: 'Texas' },
  { city: 'Hondo', state: 'TX', state_name: 'Texas' },
  { city: 'Honey Grove', state: 'TX', state_name: 'Texas' },
  { city: 'Hood County', state: 'TX', state_name: 'Texas' },
  { city: 'Hooks', state: 'TX', state_name: 'Texas' },
  { city: 'Hopkins County', state: 'TX', state_name: 'Texas' },
  { city: 'Horizon City', state: 'TX', state_name: 'Texas' },
  { city: 'Hornsby Bend', state: 'TX', state_name: 'Texas' },
  { city: 'Horseshoe Bay', state: 'TX', state_name: 'Texas' },
  { city: 'Houston', state: 'TX', state_name: 'Texas' },
  { city: 'Houston County', state: 'TX', state_name: 'Texas' },
  { city: 'Howard County', state: 'TX', state_name: 'Texas' },
  { city: 'Howe', state: 'TX', state_name: 'Texas' },
  { city: 'Hubbard', state: 'TX', state_name: 'Texas' },
  { city: 'Hudson', state: 'TX', state_name: 'Texas' },
  { city: 'Hudson Bend', state: 'TX', state_name: 'Texas' },
  { city: 'Hudson Oaks', state: 'TX', state_name: 'Texas' },
  { city: 'Hudspeth County', state: 'TX', state_name: 'Texas' },
  { city: 'Hughes Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Humble', state: 'TX', state_name: 'Texas' },
  { city: 'Hunt County', state: 'TX', state_name: 'Texas' },
  { city: 'Hunters Creek Village', state: 'TX', state_name: 'Texas' },
  { city: 'Huntington', state: 'TX', state_name: 'Texas' },
  { city: 'Huntsville', state: 'TX', state_name: 'Texas' },
  { city: 'Hurst', state: 'TX', state_name: 'Texas' },
  { city: 'Hutchins', state: 'TX', state_name: 'Texas' },
  { city: 'Hutchinson County', state: 'TX', state_name: 'Texas' },
  { city: 'Hutto', state: 'TX', state_name: 'Texas' },
  { city: 'Idalou', state: 'TX', state_name: 'Texas' },
  { city: 'Indian Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Inez', state: 'TX', state_name: 'Texas' },
  { city: 'Ingleside', state: 'TX', state_name: 'Texas' },
  { city: 'Ingram', state: 'TX', state_name: 'Texas' },
  { city: 'Iowa Colony', state: 'TX', state_name: 'Texas' },
  { city: 'Iowa Park', state: 'TX', state_name: 'Texas' },
  { city: 'Iraan', state: 'TX', state_name: 'Texas' },
  { city: 'Irion County', state: 'TX', state_name: 'Texas' },
  { city: 'Irving', state: 'TX', state_name: 'Texas' },
  { city: 'Italy', state: 'TX', state_name: 'Texas' },
  { city: 'Itasca', state: 'TX', state_name: 'Texas' },
  { city: 'Jacinto City', state: 'TX', state_name: 'Texas' },
  { city: 'Jack County', state: 'TX', state_name: 'Texas' },
  { city: 'Jacksboro', state: 'TX', state_name: 'Texas' },
  { city: 'Jackson County', state: 'TX', state_name: 'Texas' },
  { city: 'Jacksonville', state: 'TX', state_name: 'Texas' },
  { city: 'Jamaica Beach', state: 'TX', state_name: 'Texas' },
  { city: 'Jarrell', state: 'TX', state_name: 'Texas' },
  { city: 'Jasper', state: 'TX', state_name: 'Texas' },
  { city: 'Jasper County', state: 'TX', state_name: 'Texas' },
  { city: 'Jayton', state: 'TX', state_name: 'Texas' },
  { city: 'Jeff Davis County', state: 'TX', state_name: 'Texas' },
  { city: 'Jefferson', state: 'TX', state_name: 'Texas' },
  { city: 'Jefferson County', state: 'TX', state_name: 'Texas' },
  { city: 'Jersey Village', state: 'TX', state_name: 'Texas' },
  { city: 'Jewett', state: 'TX', state_name: 'Texas' },
  { city: 'Jim Hogg County', state: 'TX', state_name: 'Texas' },
  { city: 'Jim Wells County', state: 'TX', state_name: 'Texas' },
  { city: 'Johnson City', state: 'TX', state_name: 'Texas' },
  { city: 'Johnson County', state: 'TX', state_name: 'Texas' },
  { city: 'Jollyville', state: 'TX', state_name: 'Texas' },
  { city: 'Jones County', state: 'TX', state_name: 'Texas' },
  { city: 'Jones Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Jonestown', state: 'TX', state_name: 'Texas' },
  { city: 'Josephine', state: 'TX', state_name: 'Texas' },
  { city: 'Joshua', state: 'TX', state_name: 'Texas' },
  { city: 'Jourdanton', state: 'TX', state_name: 'Texas' },
  { city: 'Junction', state: 'TX', state_name: 'Texas' },
  { city: 'Justin', state: 'TX', state_name: 'Texas' },
  { city: 'Karnes City', state: 'TX', state_name: 'Texas' },
  { city: 'Karnes County', state: 'TX', state_name: 'Texas' },
  { city: 'Katy', state: 'TX', state_name: 'Texas' },
  { city: 'Kaufman', state: 'TX', state_name: 'Texas' },
  { city: 'Kaufman County', state: 'TX', state_name: 'Texas' },
  { city: 'Keene', state: 'TX', state_name: 'Texas' },
  { city: 'Keller', state: 'TX', state_name: 'Texas' },
  { city: 'Kemah', state: 'TX', state_name: 'Texas' },
  { city: 'Kemp', state: 'TX', state_name: 'Texas' },
  { city: 'Kempner', state: 'TX', state_name: 'Texas' },
  { city: 'Kendall County', state: 'TX', state_name: 'Texas' },
  { city: 'Kenedy', state: 'TX', state_name: 'Texas' },
  { city: 'Kenedy County', state: 'TX', state_name: 'Texas' },
  { city: 'Kennedale', state: 'TX', state_name: 'Texas' },
  { city: 'Kent County', state: 'TX', state_name: 'Texas' },
  { city: 'Kerens', state: 'TX', state_name: 'Texas' },
  { city: 'Kermit', state: 'TX', state_name: 'Texas' },
  { city: 'Kerr County', state: 'TX', state_name: 'Texas' },
  { city: 'Kerrville', state: 'TX', state_name: 'Texas' },
  { city: 'Kilgore', state: 'TX', state_name: 'Texas' },
  { city: 'Killeen', state: 'TX', state_name: 'Texas' },
  { city: 'Kimble County', state: 'TX', state_name: 'Texas' },
  { city: 'King County', state: 'TX', state_name: 'Texas' },
  { city: 'Kingsland', state: 'TX', state_name: 'Texas' },
  { city: 'Kingsville', state: 'TX', state_name: 'Texas' },
  { city: 'Kingwood Area', state: 'TX', state_name: 'Texas' },
  { city: 'Kinney County', state: 'TX', state_name: 'Texas' },
  { city: 'Kirby', state: 'TX', state_name: 'Texas' },
  { city: 'Kirbyville', state: 'TX', state_name: 'Texas' },
  { city: 'Kleberg County', state: 'TX', state_name: 'Texas' },
  { city: 'Knox City', state: 'TX', state_name: 'Texas' },
  { city: 'Knox County', state: 'TX', state_name: 'Texas' },
  { city: 'Kountze', state: 'TX', state_name: 'Texas' },
  { city: 'Krugerville', state: 'TX', state_name: 'Texas' },
  { city: 'Krum', state: 'TX', state_name: 'Texas' },
  { city: 'Kyle', state: 'TX', state_name: 'Texas' },
  { city: 'La Blanca', state: 'TX', state_name: 'Texas' },
  { city: 'La Coste', state: 'TX', state_name: 'Texas' },
  { city: 'La Feria', state: 'TX', state_name: 'Texas' },
  { city: 'La Grange', state: 'TX', state_name: 'Texas' },
  { city: 'La Grulla', state: 'TX', state_name: 'Texas' },
  { city: 'La Homa', state: 'TX', state_name: 'Texas' },
  { city: 'La Joya', state: 'TX', state_name: 'Texas' },
  { city: 'La Marque', state: 'TX', state_name: 'Texas' },
  { city: 'La Paloma', state: 'TX', state_name: 'Texas' },
  { city: 'La Porte', state: 'TX', state_name: 'Texas' },
  { city: 'La Pryor', state: 'TX', state_name: 'Texas' },
  { city: 'La Salle County', state: 'TX', state_name: 'Texas' },
  { city: 'La Vernia', state: 'TX', state_name: 'Texas' },
  { city: 'La Villa', state: 'TX', state_name: 'Texas' },
  { city: 'Lackland Air Force Base', state: 'TX', state_name: 'Texas' },
  { city: 'Lacy-Lakeview', state: 'TX', state_name: 'Texas' },
  { city: 'Lago Vista', state: 'TX', state_name: 'Texas' },
  { city: 'Laguna Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Laguna Park', state: 'TX', state_name: 'Texas' },
  { city: 'Laguna Vista', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Brownwood', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Dallas', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Dunlap', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Jackson', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Kiowa', state: 'TX', state_name: 'Texas' },
  { city: 'Lake Worth', state: 'TX', state_name: 'Texas' },
  { city: 'Lakehills', state: 'TX', state_name: 'Texas' },
  { city: 'Lakeside', state: 'TX', state_name: 'Texas' },
  { city: 'Lakeway', state: 'TX', state_name: 'Texas' },
  { city: 'Lamar County', state: 'TX', state_name: 'Texas' },
  { city: 'Lamb County', state: 'TX', state_name: 'Texas' },
  { city: 'Lamesa', state: 'TX', state_name: 'Texas' },
  { city: 'Lampasas', state: 'TX', state_name: 'Texas' },
  { city: 'Lampasas County', state: 'TX', state_name: 'Texas' },
  { city: 'Lancaster', state: 'TX', state_name: 'Texas' },
  { city: 'Lantana', state: 'TX', state_name: 'Texas' },
  { city: 'Laredo', state: 'TX', state_name: 'Texas' },
  { city: 'Las Lomas', state: 'TX', state_name: 'Texas' },
  { city: 'Las Palmas II', state: 'TX', state_name: 'Texas' },
  { city: 'Las Quintas Fronterizas', state: 'TX', state_name: 'Texas' },
  { city: 'Las Quintas Fronterizas Colonia', state: 'TX', state_name: 'Texas' },
  { city: 'Lasara', state: 'TX', state_name: 'Texas' },
  { city: 'Laughlin Air Force Base', state: 'TX', state_name: 'Texas' },
  { city: 'Laureles', state: 'TX', state_name: 'Texas' },
  { city: 'Lavaca County', state: 'TX', state_name: 'Texas' },
  { city: 'Lavon', state: 'TX', state_name: 'Texas' },
  { city: 'League City', state: 'TX', state_name: 'Texas' },
  { city: 'Leakey', state: 'TX', state_name: 'Texas' },
  { city: 'Leander', state: 'TX', state_name: 'Texas' },
  { city: 'Lee County', state: 'TX', state_name: 'Texas' },
  { city: 'Leon County', state: 'TX', state_name: 'Texas' },
  { city: 'Leon Valley', state: 'TX', state_name: 'Texas' },
  { city: 'Leonard', state: 'TX', state_name: 'Texas' },
  { city: 'Levelland', state: 'TX', state_name: 'Texas' },
  { city: 'Lewisville', state: 'TX', state_name: 'Texas' },
  { city: 'Lexington', state: 'TX', state_name: 'Texas' },
  { city: 'Liberty', state: 'TX', state_name: 'Texas' },
  { city: 'Liberty City', state: 'TX', state_name: 'Texas' },
  { city: 'Liberty County', state: 'TX', state_name: 'Texas' },
  { city: 'Liberty Hill', state: 'TX', state_name: 'Texas' },
  { city: 'Limestone County', state: 'TX', state_name: 'Texas' },
  { city: 'Lindale', state: 'TX', state_name: 'Texas' },
  { city: 'Linden', state: 'TX', state_name: 'Texas' },
  { city: 'Lindsay', state: 'TX', state_name: 'Texas' },
  { city: 'Lipscomb County', state: 'TX', state_name: 'Texas' },
  { city: 'Little Elm', state: 'TX', state_name: 'Texas' },
  { city: 'Little River-Academy', state: 'TX', state_name: 'Texas' },
  { city: 'Littlefield', state: 'TX', state_name: 'Texas' },
  { city: 'Live Oak', state: 'TX', state_name: 'Texas' },
  { city: 'Live Oak County', state: 'TX', state_name: 'Texas' },
  { city: 'Livingston', state: 'TX', state_name: 'Texas' },
  { city: 'Llano', state: 'TX', state_name: 'Texas' },
  { city: 'Llano County', state: 'TX', state_name: 'Texas' },
  { city: 'Llano Grande', state: 'TX', state_name: 'Texas' },
  { city: 'Lockhart', state: 'TX', state_name: 'Texas' },
  { city: 'Lockney', state: 'TX', state_name: 'Texas' },
  { city: 'Lone Star', state: 'TX', state_name: 'Texas' },
  { city: 'Longview', state: 'TX', state_name: 'Texas' },
  { city: 'Lopezville', state: 'TX', state_name: 'Texas' },
  { city: 'Lorena', state: 'TX', state_name: 'Texas' },
  { city: 'Lorenzo', state: 'TX', state_name: 'Texas' },
  { city: 'Los Fresnos', state: 'TX', state_name: 'Texas' },
  { city: 'Los Indios', state: 'TX', state_name: 'Texas' },
  { city: 'Lost Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Loving County', state: 'TX', state_name: 'Texas' },
  { city: 'Lowry Crossing', state: 'TX', state_name: 'Texas' },
  { city: 'Lubbock', state: 'TX', state_name: 'Texas' },
  { city: 'Lubbock County', state: 'TX', state_name: 'Texas' },
  { city: 'Lucas', state: 'TX', state_name: 'Texas' },
  { city: 'Lufkin', state: 'TX', state_name: 'Texas' },
  { city: 'Luling', state: 'TX', state_name: 'Texas' },
  { city: 'Lumberton', state: 'TX', state_name: 'Texas' },
  { city: 'Lyford', state: 'TX', state_name: 'Texas' },
  { city: 'Lynn County', state: 'TX', state_name: 'Texas' },
  { city: 'Lytle', state: 'TX', state_name: 'Texas' },
  { city: 'Mabank', state: 'TX', state_name: 'Texas' },
  { city: 'Madison County', state: 'TX', state_name: 'Texas' },
  { city: 'Madisonville', state: 'TX', state_name: 'Texas' },
  { city: 'Magnolia', state: 'TX', state_name: 'Texas' },
  { city: 'Malakoff', state: 'TX', state_name: 'Texas' },
  { city: 'Manchaca', state: 'TX', state_name: 'Texas' },
  { city: 'Manor', state: 'TX', state_name: 'Texas' },
  { city: 'Mansfield', state: 'TX', state_name: 'Texas' },
  { city: 'Manvel', state: 'TX', state_name: 'Texas' },
  { city: 'Marble Falls', state: 'TX', state_name: 'Texas' },
  { city: 'Marfa', state: 'TX', state_name: 'Texas' },
  { city: 'Marion', state: 'TX', state_name: 'Texas' },
  { city: 'Marion County', state: 'TX', state_name: 'Texas' },
  { city: 'Markham', state: 'TX', state_name: 'Texas' },
  { city: 'Marlin', state: 'TX', state_name: 'Texas' },
  { city: 'Marshall', state: 'TX', state_name: 'Texas' },
  { city: 'Mart', state: 'TX', state_name: 'Texas' },
  { city: 'Martin County', state: 'TX', state_name: 'Texas' },
  { city: 'Martindale', state: 'TX', state_name: 'Texas' },
  { city: 'Mason', state: 'TX', state_name: 'Texas' },
  { city: 'Mason County', state: 'TX', state_name: 'Texas' },
  { city: 'Matador', state: 'TX', state_name: 'Texas' },
  { city: 'Matagorda County', state: 'TX', state_name: 'Texas' },
  { city: 'Mathis', state: 'TX', state_name: 'Texas' },
  { city: 'Maud', state: 'TX', state_name: 'Texas' },
  { city: 'Mauriceville', state: 'TX', state_name: 'Texas' },
  { city: 'Maverick County', state: 'TX', state_name: 'Texas' },
  { city: 'McAllen', state: 'TX', state_name: 'Texas' },
  { city: 'McCamey', state: 'TX', state_name: 'Texas' },
  { city: 'McCulloch County', state: 'TX', state_name: 'Texas' },
  { city: 'McGregor', state: 'TX', state_name: 'Texas' },
  { city: 'McKinney', state: 'TX', state_name: 'Texas' },
  { city: 'McLendon-Chisholm', state: 'TX', state_name: 'Texas' },
  { city: 'McLennan County', state: 'TX', state_name: 'Texas' },
  { city: 'McMullen County', state: 'TX', state_name: 'Texas' },
  { city: 'McQueeney', state: 'TX', state_name: 'Texas' },
  { city: 'Meadowlakes', state: 'TX', state_name: 'Texas' },
  { city: 'Meadows Place', state: 'TX', state_name: 'Texas' },
  { city: 'Medina', state: 'TX', state_name: 'Texas' },
  { city: 'Medina County', state: 'TX', state_name: 'Texas' },
  { city: 'Melissa', state: 'TX', state_name: 'Texas' },
  { city: 'Memphis', state: 'TX', state_name: 'Texas' },
  { city: 'Menard', state: 'TX', state_name: 'Texas' },
  { city: 'Menard County', state: 'TX', state_name: 'Texas' },
  { city: 'Mentone', state: 'TX', state_name: 'Texas' },
  { city: 'Mercedes', state: 'TX', state_name: 'Texas' },
  { city: 'Meridian', state: 'TX', state_name: 'Texas' },
  { city: 'Merkel', state: 'TX', state_name: 'Texas' },
  { city: 'Mertzon', state: 'TX', state_name: 'Texas' },
  { city: 'Mesquite', state: 'TX', state_name: 'Texas' },
  { city: 'Mexia', state: 'TX', state_name: 'Texas' },
  { city: 'Miami', state: 'TX', state_name: 'Texas' },
  { city: 'Midland', state: 'TX', state_name: 'Texas' },
  { city: 'Midland County', state: 'TX', state_name: 'Texas' },
  { city: 'Midlothian', state: 'TX', state_name: 'Texas' },
  { city: 'Midway North', state: 'TX', state_name: 'Texas' },
  { city: 'Midway South', state: 'TX', state_name: 'Texas' },
  { city: 'Mila Doce', state: 'TX', state_name: 'Texas' },
  { city: 'Milam', state: 'TX', state_name: 'Texas' },
  { city: 'Milam County', state: 'TX', state_name: 'Texas' },
  { city: 'Mills County', state: 'TX', state_name: 'Texas' },
  { city: 'Mineola', state: 'TX', state_name: 'Texas' },
  { city: 'Mineral Wells', state: 'TX', state_name: 'Texas' },
  { city: 'Mission', state: 'TX', state_name: 'Texas' },
  { city: 'Mission Bend', state: 'TX', state_name: 'Texas' },
  { city: 'Missouri City', state: 'TX', state_name: 'Texas' },
  { city: 'Mitchell County', state: 'TX', state_name: 'Texas' },
  { city: 'Monahans', state: 'TX', state_name: 'Texas' },
  { city: 'Mont Belvieu', state: 'TX', state_name: 'Texas' },
  { city: 'Montague', state: 'TX', state_name: 'Texas' },
  { city: 'Montague County', state: 'TX', state_name: 'Texas' },
  { city: 'Monte Alto', state: 'TX', state_name: 'Texas' },
  { city: 'Montgomery County', state: 'TX', state_name: 'Texas' },
  { city: 'Moody', state: 'TX', state_name: 'Texas' },
  { city: 'Moore County', state: 'TX', state_name: 'Texas' },
  { city: 'Morgans Point Resort', state: 'TX', state_name: 'Texas' },
  { city: 'Morris County', state: 'TX', state_name: 'Texas' },
  { city: 'Morton', state: 'TX', state_name: 'Texas' },
  { city: 'Motley County', state: 'TX', state_name: 'Texas' },
  { city: 'Mount Pleasant', state: 'TX', state_name: 'Texas' },
  { city: 'Mount Vernon', state: 'TX', state_name: 'Texas' },
  { city: 'Muenster', state: 'TX', state_name: 'Texas' },
  { city: 'Muleshoe', state: 'TX', state_name: 'Texas' },
  { city: 'Munday', state: 'TX', state_name: 'Texas' },
  { city: 'Muniz', state: 'TX', state_name: 'Texas' },
  { city: 'Murillo Colonia', state: 'TX', state_name: 'Texas' },
  { city: 'Murphy', state: 'TX', state_name: 'Texas' },
  { city: 'Nacogdoches', state: 'TX', state_name: 'Texas' },
  { city: 'Nacogdoches County', state: 'TX', state_name: 'Texas' },
  { city: 'Naples', state: 'TX', state_name: 'Texas' },
  { city: 'Nash', state: 'TX', state_name: 'Texas' },
  { city: 'Nassau Bay', state: 'TX', state_name: 'Texas' },
  { city: 'Natalia', state: 'TX', state_name: 'Texas' },
  { city: 'Navarro County', state: 'TX', state_name: 'Texas' },
  { city: 'Navasota', state: 'TX', state_name: 'Texas' },
  { city: 'Nederland', state: 'TX', state_name: 'Texas' },
  { city: 'Needville', state: 'TX', state_name: 'Texas' },
  { city: 'Nevada', state: 'TX', state_name: 'Texas' },
  { city: 'New Boston', state: 'TX', state_name: 'Texas' },
  { city: 'New Braunfels', state: 'TX', state_name: 'Texas' },
  { city: 'New Summerfield', state: 'TX', state_name: 'Texas' },
  { city: 'New Territory', state: 'TX', state_name: 'Texas' },
  { city: 'New Waverly', state: 'TX', state_name: 'Texas' },
  { city: 'Newark', state: 'TX', state_name: 'Texas' },
  { city: 'Newton', state: 'TX', state_name: 'Texas' },
  { city: 'Newton County', state: 'TX', state_name: 'Texas' },
  { city: 'Nixon', state: 'TX', state_name: 'Texas' },
  { city: 'Nocona', state: 'TX', state_name: 'Texas' },
  { city: 'Nolan County', state: 'TX', state_name: 'Texas' },
  { city: 'Nolanville', state: 'TX', state_name: 'Texas' },
  { city: 'North Alamo', state: 'TX', state_name: 'Texas' },
  { city: 'North Richland Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Northcliff', state: 'TX', state_name: 'Texas' },
  { city: 'Northcrest', state: 'TX', state_name: 'Texas' },
  { city: 'Northlake', state: 'TX', state_name: 'Texas' },
  { city: 'Nueces County', state: 'TX', state_name: 'Texas' },
  { city: 'Nurillo', state: 'TX', state_name: 'Texas' },
  { city: 'Oak Cliff Place', state: 'TX', state_name: 'Texas' },
  { city: 'Oak Leaf', state: 'TX', state_name: 'Texas' },
  { city: 'Oak Point', state: 'TX', state_name: 'Texas' },
  { city: 'Oak Ridge North', state: 'TX', state_name: 'Texas' },
  { city: 'Oak Trail Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Ochiltree County', state: 'TX', state_name: 'Texas' },
  { city: 'Odem', state: 'TX', state_name: 'Texas' },
  { city: 'Odessa', state: 'TX', state_name: 'Texas' },
  { city: 'Old River-Winfree', state: 'TX', state_name: 'Texas' },
  { city: 'Oldham County', state: 'TX', state_name: 'Texas' },
  { city: 'Olivarez', state: 'TX', state_name: 'Texas' },
  { city: 'Olmito', state: 'TX', state_name: 'Texas' },
  { city: 'Olmos Park', state: 'TX', state_name: 'Texas' },
  { city: 'Olney', state: 'TX', state_name: 'Texas' },
  { city: 'Olton', state: 'TX', state_name: 'Texas' },
  { city: 'Onalaska', state: 'TX', state_name: 'Texas' },
  { city: 'Onion Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Orange', state: 'TX', state_name: 'Texas' },
  { city: 'Orange County', state: 'TX', state_name: 'Texas' },
  { city: 'Orange Grove', state: 'TX', state_name: 'Texas' },
  { city: 'Ore City', state: 'TX', state_name: 'Texas' },
  { city: 'Overton', state: 'TX', state_name: 'Texas' },
  { city: 'Ovilla', state: 'TX', state_name: 'Texas' },
  { city: 'Oyster Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Ozona', state: 'TX', state_name: 'Texas' },
  { city: 'Paducah', state: 'TX', state_name: 'Texas' },
  { city: 'Paint Rock', state: 'TX', state_name: 'Texas' },
  { city: 'Palacios', state: 'TX', state_name: 'Texas' },
  { city: 'Palestine', state: 'TX', state_name: 'Texas' },
  { city: 'Palm Valley', state: 'TX', state_name: 'Texas' },
  { city: 'Palmer', state: 'TX', state_name: 'Texas' },
  { city: 'Palmhurst', state: 'TX', state_name: 'Texas' },
  { city: 'Palmview', state: 'TX', state_name: 'Texas' },
  { city: 'Palmview South', state: 'TX', state_name: 'Texas' },
  { city: 'Palo Pinto', state: 'TX', state_name: 'Texas' },
  { city: 'Palo Pinto County', state: 'TX', state_name: 'Texas' },
  { city: 'Paloma Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Paloma Creek South', state: 'TX', state_name: 'Texas' },
  { city: 'Pampa', state: 'TX', state_name: 'Texas' },
  { city: 'Panhandle', state: 'TX', state_name: 'Texas' },
  { city: 'Panola County', state: 'TX', state_name: 'Texas' },
  { city: 'Panorama Village', state: 'TX', state_name: 'Texas' },
  { city: 'Pantego', state: 'TX', state_name: 'Texas' },
  { city: 'Paris', state: 'TX', state_name: 'Texas' },
  { city: 'Parker', state: 'TX', state_name: 'Texas' },
  { city: 'Parker County', state: 'TX', state_name: 'Texas' },
  { city: 'Parmer County', state: 'TX', state_name: 'Texas' },
  { city: 'Pasadena', state: 'TX', state_name: 'Texas' },
  { city: 'Patton Village', state: 'TX', state_name: 'Texas' },
  { city: 'Pearland', state: 'TX', state_name: 'Texas' },
  { city: 'Pearsall', state: 'TX', state_name: 'Texas' },
  { city: 'Pecan Acres', state: 'TX', state_name: 'Texas' },
  { city: 'Pecan Grove', state: 'TX', state_name: 'Texas' },
  { city: 'Pecan Plantation', state: 'TX', state_name: 'Texas' },
  { city: 'Pecos', state: 'TX', state_name: 'Texas' },
  { city: 'Pecos County', state: 'TX', state_name: 'Texas' },
  { city: 'Pelican Bay', state: 'TX', state_name: 'Texas' },
  { city: 'Penitas', state: 'TX', state_name: 'Texas' },
  { city: 'Perezville', state: 'TX', state_name: 'Texas' },
  { city: 'Perryton', state: 'TX', state_name: 'Texas' },
  { city: 'Petersburg', state: 'TX', state_name: 'Texas' },
  { city: 'Pflugerville', state: 'TX', state_name: 'Texas' },
  { city: 'Pharr', state: 'TX', state_name: 'Texas' },
  { city: 'Pilot Point', state: 'TX', state_name: 'Texas' },
  { city: 'Pine Island', state: 'TX', state_name: 'Texas' },
  { city: 'Pinehurst', state: 'TX', state_name: 'Texas' },
  { city: 'Pinewood Estates', state: 'TX', state_name: 'Texas' },
  { city: 'Piney Point Village', state: 'TX', state_name: 'Texas' },
  { city: 'Pittsburg', state: 'TX', state_name: 'Texas' },
  { city: 'Plains', state: 'TX', state_name: 'Texas' },
  { city: 'Plainview', state: 'TX', state_name: 'Texas' },
  { city: 'Plano', state: 'TX', state_name: 'Texas' },
  { city: 'Pleak', state: 'TX', state_name: 'Texas' },
  { city: 'Pleasanton', state: 'TX', state_name: 'Texas' },
  { city: 'Plum Grove', state: 'TX', state_name: 'Texas' },
  { city: 'Polk County', state: 'TX', state_name: 'Texas' },
  { city: 'Ponder', state: 'TX', state_name: 'Texas' },
  { city: 'Port Aransas', state: 'TX', state_name: 'Texas' },
  { city: 'Port Arthur', state: 'TX', state_name: 'Texas' },
  { city: 'Port Isabel', state: 'TX', state_name: 'Texas' },
  { city: 'Port Lavaca', state: 'TX', state_name: 'Texas' },
  { city: 'Port Neches', state: 'TX', state_name: 'Texas' },
  { city: "Port O'Connor", state: 'TX', state_name: 'Texas' },
  { city: 'Porter Heights', state: 'TX', state_name: 'Texas' },
  { city: 'Portland', state: 'TX', state_name: 'Texas' },
  { city: 'Post', state: 'TX', state_name: 'Texas' },
  { city: 'Poteet', state: 'TX', state_name: 'Texas' },
  { city: 'Poth', state: 'TX', state_name: 'Texas' },
  { city: 'Potosi', state: 'TX', state_name: 'Texas' },
  { city: 'Potter County', state: 'TX', state_name: 'Texas' },
  { city: 'Pottsboro', state: 'TX', state_name: 'Texas' },
  { city: 'Powderly', state: 'TX', state_name: 'Texas' },
  { city: 'Prairie View', state: 'TX', state_name: 'Texas' },
  { city: 'Premont', state: 'TX', state_name: 'Texas' },
  { city: 'Presidio', state: 'TX', state_name: 'Texas' },
  { city: 'Presidio County', state: 'TX', state_name: 'Texas' },
  { city: 'Preston', state: 'TX', state_name: 'Texas' },
  { city: 'Primera', state: 'TX', state_name: 'Texas' },
  { city: 'Princeton', state: 'TX', state_name: 'Texas' },
  { city: 'Progreso', state: 'TX', state_name: 'Texas' },
  { city: 'Prosper', state: 'TX', state_name: 'Texas' },
  { city: 'Providence', state: 'TX', state_name: 'Texas' },
  { city: 'Quail Creek', state: 'TX', state_name: 'Texas' },
  { city: 'Quanah', state: 'TX', state_name: 'Texas' },
  { city: 'Queen City', state: 'TX', state_name: 'Texas' },
  { city: 'Quinlan', state: 'TX', state_name: 'Texas' },
  { city: 'Quitman', state: 'TX', state_name: 'Texas' },
  { city: 'Rains County', state: 'TX', state_name: 'Texas' },
  { city: 'Ralls', state: 'TX', state_name: 'Texas' },
  { city: 'Rancho Alegre', state: 'TX', state_name: 'Texas' },
  { city: 'Rancho Viejo', state: 'TX', state_name: 'Texas' },
  { city: 'Randall County', state: 'TX', state_name: 'Texas' },
  { city: 'Ranger', state: 'TX', state_name: 'Texas' },
  { city: 'Rankin', state: 'TX', state_name: 'Texas' },
  { city: 'Ransom Canyon', state: 'TX', state_name: 'Texas' },
  { city: 'Raymondville', state: 'TX', state_name: 'Texas' },
  { city: 'Reagan County', state: 'TX', state_name: 'Texas' },
  { city: 'Real County', state: 'TX', state_name: 'Texas' },
  { city: 'Red Lick', state: 'TX', state_name: 'Texas' },
  { city: 'Red Oak', state: 'TX', state_name: 'Texas' },
  { city: 'Red River County', state: 'TX', state_name: 'Texas' },
  { city: 'Redland', state: 'TX', state_name: 'Texas' },
  { city: 'Redwater', state: 'TX', state_name: 'Texas' },
  { city: 'Redwood', state: 'TX', state_name: 'Texas' },
  { city: 'Reeves County', state: 'TX', state_name: 'Texas' },
  { city: 'Refugio', state: 'TX', state_name: 'Texas' },
  { city: 'Refugio County', state: 'TX', state_name: 'Texas' },
  { city: 'Rendon', state: 'TX', state_name: 'Texas' },
  { city: 'Reno', state: 'TX', state_name: 'Texas' },
  { city: 'Rhome', state: 'TX', state_name: 'Texas' },
  { city: 'Ricardo', state: 'TX', state_name: 'Texas' },
  { city: 'Richardson', state: 'TX', state_name: 'Texas' },
  { city: 'Richland Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Richmond', state: 'TX', state_name: 'Texas' },
  { city: 'Richwood', state: 'TX', state_name: 'Texas' },
  { city: 'Riesel', state: 'TX', state_name: 'Texas' },
  { city: 'Rio Bravo', state: 'TX', state_name: 'Texas' },
  { city: 'Rio Grande City', state: 'TX', state_name: 'Texas' },
  { city: 'Rio Hondo', state: 'TX', state_name: 'Texas' },
  { city: 'River Oaks', state: 'TX', state_name: 'Texas' },
  { city: 'Roanoke', state: 'TX', state_name: 'Texas' },
  { city: 'Robert Lee', state: 'TX', state_name: 'Texas' },
  { city: 'Roberts County', state: 'TX', state_name: 'Texas' },
  { city: 'Robertson County', state: 'TX', state_name: 'Texas' },
  { city: 'Robinson', state: 'TX', state_name: 'Texas' },
  { city: 'Robstown', state: 'TX', state_name: 'Texas' },
  { city: 'Roby', state: 'TX', state_name: 'Texas' },
  { city: 'Rockdale', state: 'TX', state_name: 'Texas' },
  { city: 'Rockport', state: 'TX', state_name: 'Texas' },
  { city: 'Rocksprings', state: 'TX', state_name: 'Texas' },
  { city: 'Rockwall', state: 'TX', state_name: 'Texas' },
  { city: 'Rockwall County', state: 'TX', state_name: 'Texas' },
  { city: 'Rogers', state: 'TX', state_name: 'Texas' },
  { city: 'Rollingwood', state: 'TX', state_name: 'Texas' },
  { city: 'Roma', state: 'TX', state_name: 'Texas' },
  { city: 'Roma-Los Saenz', state: 'TX', state_name: 'Texas' },
  { city: 'Roman Forest', state: 'TX', state_name: 'Texas' },
  { city: 'Roscoe', state: 'TX', state_name: 'Texas' },
  { city: 'Rosebud', state: 'TX', state_name: 'Texas' },
  { city: 'Rosenberg', state: 'TX', state_name: 'Texas' },
  { city: 'Rosharon', state: 'TX', state_name: 'Texas' },
  { city: 'Rosita North', state: 'TX', state_name: 'Texas' },
  { city: 'Rosita South', state: 'TX', state_name: 'Texas' },
  { city: 'Rotan', state: 'TX', state_name: 'Texas' },
  { city: 'Round Rock', state: 'TX', state_name: 'Texas' },
  { city: 'Rowlett', state: 'TX', state_name: 'Texas' },
  { city: 'Royse City', state: 'TX', state_name: 'Texas' },
  { city: 'Runaway Bay', state: 'TX', state_name: 'Texas' },
  { city: 'Runge', state: 'TX', state_name: 'Texas' },
  { city: 'Runnels County', state: 'TX', state_name: 'Texas' },
  { city: 'Rusk', state: 'TX', state_name: 'Texas' },
  { city: 'Rusk County', state: 'TX', state_name: 'Texas' },
  { city: 'Sabinal', state: 'TX', state_name: 'Texas' },
  { city: 'Sabine County', state: 'TX', state_name: 'Texas' },
  { city: 'Sachse', state: 'TX', state_name: 'Texas' },
  { city: 'Saginaw', state: 'TX', state_name: 'Texas' },
  { city: 'Saint Hedwig', state: 'TX', state_name: 'Texas' },
  { city: 'Saint Jo', state: 'TX', state_name: 'Texas' },
  { city: 'Saint Paul', state: 'TX', state_name: 'Texas' },
  { city: 'Salado', state: 'TX', state_name: 'Texas' },
  { city: 'Sam Rayburn', state: 'TX', state_name: 'Texas' },
  { city: 'San Angelo', state: 'TX', state_name: 'Texas' },
  { city: 'San Antonio', state: 'TX', state_name: 'Texas' },
  { city: 'San Augustine', state: 'TX', state_name: 'Texas' },
  { city: 'San Augustine County', state: 'TX', state_name: 'Texas' },
  { city: 'San Benito', state: 'TX', state_name: 'Texas' },
  { city: 'San Carlos', state: 'TX', state_name: 'Texas' },
  { city: 'San Diego', state: 'TX', state_name: 'Texas' },
  { city: 'San Elizario', state: 'TX', state_name: 'Texas' },
  { city: 'San Jacinto County', state: 'TX', state_name: 'Texas' },
  { city: 'San Juan', state: 'TX', state_name: 'Texas' },
  { city: 'San Leon', state: 'TX', state_name: 'Texas' },
  { city: 'San Marcos', state: 'TX', state_name: 'Texas' },
  { city: 'San Patricio County', state: 'TX', state_name: 'Texas' },
  { city: 'San Saba', state: 'TX', state_name: 'Texas' },
  { city: 'San Saba County', state: 'TX', state_name: 'Texas' },
  { city: 'Sanderson', state: 'TX', state_name: 'Texas' },
  { city: 'Sanger', state: 'TX', state_name: 'Texas' },
  { city: 'Sansom Park', state: 'TX', state_name: 'Texas' },
  { city: 'Santa Anna', state: 'TX', state_name: 'Texas' },
  { city: 'Santa Fe', state: 'TX', state_name: 'Texas' },
  { city: 'Santa Rosa', state: 'TX', state_name: 'Texas' },
  { city: 'Sarita', state: 'TX', state_name: 'Texas' },
  { city: 'Savannah', state: 'TX', state_name: 'Texas' },
  { city: 'Scenic Oaks', state: 'TX', state_name: 'Texas' },
  { city: 'Schertz', state: 'TX', state_name: 'Texas' },
  { city: 'Schleicher County', state: 'TX', state_name: 'Texas' },
  { city: 'Schulenburg', state: 'TX', state_name: 'Texas' },
  { city: 'Scissors', state: 'TX', state_name: 'Texas' },
  { city: 'Scurry County', state: 'TX', state_name: 'Texas' },
  { city: 'Seabrook', state: 'TX', state_name: 'Texas' },
  { city: 'Seadrift', state: 'TX', state_name: 'Texas' },
  { city: 'Seagoville', state: 'TX', state_name: 'Texas' },
  { city: 'Seagraves', state: 'TX', state_name: 'Texas' },
  { city: 'Sealy', state: 'TX', state_name: 'Texas' },
  { city: 'Sebastian', state: 'TX', state_name: 'Texas' },
  { city: 'Seguin', state: 'TX', state_name: 'Texas' },
  { city: 'Selma', state: 'TX', state_name: 'Texas' },
  { city: 'Seminole', state: 'TX', state_name: 'Texas' },
  { city: 'Serenada', state: 'TX', state_name: 'Texas' },
  { city: 'Seth Ward', state: 'TX', state_name: 'Texas' },
  { city: 'Seven Points', state: 'TX', state_name: 'Texas' },
  { city: 'Seymour', state: 'TX', state_name: 'Texas' },
  { city: 'Shackelford County', state: 'TX', state_name: 'Texas' },
  { city: 'Shady Hollow', state: 'TX', state_name: 'Texas' },
  { city: 'Shady Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Shallowater', state: 'TX', state_name: 'Texas' },
  { city: 'Shamrock', state: 'TX', state_name: 'Texas' },
  { city: 'Shavano Park', state: 'TX', state_name: 'Texas' },
  { city: 'Shelby County', state: 'TX', state_name: 'Texas' },
  { city: 'Sheldon', state: 'TX', state_name: 'Texas' },
  { city: 'Shenandoah', state: 'TX', state_name: 'Texas' },
  { city: 'Shepherd', state: 'TX', state_name: 'Texas' },
  { city: 'Sherman', state: 'TX', state_name: 'Texas' },
  { city: 'Sherman County', state: 'TX', state_name: 'Texas' },
  { city: 'Sherwood Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Shiner', state: 'TX', state_name: 'Texas' },
  { city: 'Shoreacres', state: 'TX', state_name: 'Texas' },
  { city: 'Sienna Plantation', state: 'TX', state_name: 'Texas' },
  { city: 'Sierra Blanca', state: 'TX', state_name: 'Texas' },
  { city: 'Siesta Acres', state: 'TX', state_name: 'Texas' },
  { city: 'Siesta Shores', state: 'TX', state_name: 'Texas' },
  { city: 'Silsbee', state: 'TX', state_name: 'Texas' },
  { city: 'Silverton', state: 'TX', state_name: 'Texas' },
  { city: 'Sinton', state: 'TX', state_name: 'Texas' },
  { city: 'Slaton', state: 'TX', state_name: 'Texas' },
  { city: 'Smith County', state: 'TX', state_name: 'Texas' },
  { city: 'Smithville', state: 'TX', state_name: 'Texas' },
  { city: 'Snyder', state: 'TX', state_name: 'Texas' },
  { city: 'Socorro', state: 'TX', state_name: 'Texas' },
  {
    city: 'Socorro Mission Number 1 Colonia',
    state: 'TX',
    state_name: 'Texas',
  },
  { city: 'Somerset', state: 'TX', state_name: 'Texas' },
  { city: 'Somervell County', state: 'TX', state_name: 'Texas' },
  { city: 'Somerville', state: 'TX', state_name: 'Texas' },
  { city: 'Sonora', state: 'TX', state_name: 'Texas' },
  { city: 'Sour Lake', state: 'TX', state_name: 'Texas' },
  { city: 'South Alamo', state: 'TX', state_name: 'Texas' },
  { city: 'South Houston', state: 'TX', state_name: 'Texas' },
  { city: 'South Padre Island', state: 'TX', state_name: 'Texas' },
  { city: 'South Point', state: 'TX', state_name: 'Texas' },
  { city: 'Southlake', state: 'TX', state_name: 'Texas' },
  { city: 'Southmayd', state: 'TX', state_name: 'Texas' },
  { city: 'Southside Place', state: 'TX', state_name: 'Texas' },
  { city: 'Sparks', state: 'TX', state_name: 'Texas' },
  { city: 'Spearman', state: 'TX', state_name: 'Texas' },
  { city: 'Splendora', state: 'TX', state_name: 'Texas' },
  { city: 'Spring', state: 'TX', state_name: 'Texas' },
  { city: 'Spring Valley', state: 'TX', state_name: 'Texas' },
  { city: 'Springtown', state: 'TX', state_name: 'Texas' },
  { city: 'Spur', state: 'TX', state_name: 'Texas' },
  { city: 'Stafford', state: 'TX', state_name: 'Texas' },
  { city: 'Stamford', state: 'TX', state_name: 'Texas' },
  { city: 'Stanton', state: 'TX', state_name: 'Texas' },
  { city: 'Starr County', state: 'TX', state_name: 'Texas' },
  { city: 'Stephens County', state: 'TX', state_name: 'Texas' },
  { city: 'Stephenville', state: 'TX', state_name: 'Texas' },
  { city: 'Sterling City', state: 'TX', state_name: 'Texas' },
  { city: 'Sterling County', state: 'TX', state_name: 'Texas' },
  { city: 'Stinnett', state: 'TX', state_name: 'Texas' },
  { city: 'Stockdale', state: 'TX', state_name: 'Texas' },
  { city: 'Stonewall County', state: 'TX', state_name: 'Texas' },
  { city: 'Stowell', state: 'TX', state_name: 'Texas' },
  { city: 'Stratford', state: 'TX', state_name: 'Texas' },
  { city: 'Sugar Land', state: 'TX', state_name: 'Texas' },
  { city: 'Sullivan City', state: 'TX', state_name: 'Texas' },
  { city: 'Sulphur Springs', state: 'TX', state_name: 'Texas' },
  { city: 'Sundown', state: 'TX', state_name: 'Texas' },
  { city: 'Sunnyvale', state: 'TX', state_name: 'Texas' },
  { city: 'Sunray', state: 'TX', state_name: 'Texas' },
  { city: 'Sutton County', state: 'TX', state_name: 'Texas' },
  { city: 'Sweeny', state: 'TX', state_name: 'Texas' },
  { city: 'Sweetwater', state: 'TX', state_name: 'Texas' },
  { city: 'Swisher County', state: 'TX', state_name: 'Texas' },
  { city: 'Taft', state: 'TX', state_name: 'Texas' },
  { city: 'Taft Southwest (historical)', state: 'TX', state_name: 'Texas' },
  { city: 'Tahoka', state: 'TX', state_name: 'Texas' },
  { city: 'Talty', state: 'TX', state_name: 'Texas' },
  { city: 'Tarrant County', state: 'TX', state_name: 'Texas' },
  { city: 'Tatum', state: 'TX', state_name: 'Texas' },
  { city: 'Taylor', state: 'TX', state_name: 'Texas' },
  { city: 'Taylor County', state: 'TX', state_name: 'Texas' },
  { city: 'Taylor Lake Village', state: 'TX', state_name: 'Texas' },
  { city: 'Teague', state: 'TX', state_name: 'Texas' },
  { city: 'Temple', state: 'TX', state_name: 'Texas' },
  { city: 'Tenaha', state: 'TX', state_name: 'Texas' },
  { city: 'Terrell', state: 'TX', state_name: 'Texas' },
  { city: 'Terrell County', state: 'TX', state_name: 'Texas' },
  { city: 'Terrell Hills', state: 'TX', state_name: 'Texas' },
  { city: 'Terry County', state: 'TX', state_name: 'Texas' },
  { city: 'Texarkana', state: 'TX', state_name: 'Texas' },
  { city: 'Texas City', state: 'TX', state_name: 'Texas' },
  { city: 'The Colony', state: 'TX', state_name: 'Texas' },
  { city: 'The Hills', state: 'TX', state_name: 'Texas' },
  { city: 'The Woodlands', state: 'TX', state_name: 'Texas' },
  { city: 'Thorndale', state: 'TX', state_name: 'Texas' },
  { city: 'Three Rivers', state: 'TX', state_name: 'Texas' },
  { city: 'Throckmorton', state: 'TX', state_name: 'Texas' },
  { city: 'Throckmorton County', state: 'TX', state_name: 'Texas' },
  { city: 'Tiki Island', state: 'TX', state_name: 'Texas' },
  { city: 'Tilden', state: 'TX', state_name: 'Texas' },
  { city: 'Timberwood Park', state: 'TX', state_name: 'Texas' },
  { city: 'Timpson', state: 'TX', state_name: 'Texas' },
  { city: 'Titus County', state: 'TX', state_name: 'Texas' },
  { city: 'Tom Bean', state: 'TX', state_name: 'Texas' },
  { city: 'Tom Green County', state: 'TX', state_name: 'Texas' },
  { city: 'Tomball', state: 'TX', state_name: 'Texas' },
  { city: 'Tool', state: 'TX', state_name: 'Texas' },
  { city: 'Tornillo', state: 'TX', state_name: 'Texas' },
  { city: 'Travis County', state: 'TX', state_name: 'Texas' },
  { city: 'Travis Ranch', state: 'TX', state_name: 'Texas' },
  { city: 'Trinity', state: 'TX', state_name: 'Texas' },
  { city: 'Trinity County', state: 'TX', state_name: 'Texas' },
  { city: 'Trophy Club', state: 'TX', state_name: 'Texas' },
  { city: 'Troup', state: 'TX', state_name: 'Texas' },
  { city: 'Troy', state: 'TX', state_name: 'Texas' },
  { city: 'Tulia', state: 'TX', state_name: 'Texas' },
  { city: 'Tye', state: 'TX', state_name: 'Texas' },
  { city: 'Tyler', state: 'TX', state_name: 'Texas' },
  { city: 'Tyler County', state: 'TX', state_name: 'Texas' },
  { city: 'Uhland', state: 'TX', state_name: 'Texas' },
  { city: 'Universal City', state: 'TX', state_name: 'Texas' },
  { city: 'University Park', state: 'TX', state_name: 'Texas' },
  { city: 'Upshur County', state: 'TX', state_name: 'Texas' },
  { city: 'Upton County', state: 'TX', state_name: 'Texas' },
  { city: 'Uvalde', state: 'TX', state_name: 'Texas' },
  { city: 'Uvalde County', state: 'TX', state_name: 'Texas' },
  { city: 'Uvalde Estates', state: 'TX', state_name: 'Texas' },
  { city: 'Val Verde County', state: 'TX', state_name: 'Texas' },
  { city: 'Val Verde Park', state: 'TX', state_name: 'Texas' },
  { city: 'Valley Mills', state: 'TX', state_name: 'Texas' },
  { city: 'Van', state: 'TX', state_name: 'Texas' },
  { city: 'Van Alstyne', state: 'TX', state_name: 'Texas' },
  { city: 'Van Horn', state: 'TX', state_name: 'Texas' },
  { city: 'Van Vleck', state: 'TX', state_name: 'Texas' },
  { city: 'Van Zandt County', state: 'TX', state_name: 'Texas' },
  { city: 'Vega', state: 'TX', state_name: 'Texas' },
  { city: 'Venus', state: 'TX', state_name: 'Texas' },
  { city: 'Vernon', state: 'TX', state_name: 'Texas' },
  { city: 'Victoria', state: 'TX', state_name: 'Texas' },
  { city: 'Victoria County', state: 'TX', state_name: 'Texas' },
  { city: 'Vidor', state: 'TX', state_name: 'Texas' },
  { city: 'Vinton', state: 'TX', state_name: 'Texas' },
  { city: 'Von Ormy', state: 'TX', state_name: 'Texas' },
  { city: 'Waco', state: 'TX', state_name: 'Texas' },
  { city: 'Wake Village', state: 'TX', state_name: 'Texas' },
  { city: 'Walker County', state: 'TX', state_name: 'Texas' },
  { city: 'Waller', state: 'TX', state_name: 'Texas' },
  { city: 'Waller County', state: 'TX', state_name: 'Texas' },
  { city: 'Wallis', state: 'TX', state_name: 'Texas' },
  { city: 'Ward County', state: 'TX', state_name: 'Texas' },
  { city: 'Washington County', state: 'TX', state_name: 'Texas' },
  { city: 'Waskom', state: 'TX', state_name: 'Texas' },
  { city: 'Watauga', state: 'TX', state_name: 'Texas' },
  { city: 'Waxahachie', state: 'TX', state_name: 'Texas' },
  { city: 'Weatherford', state: 'TX', state_name: 'Texas' },
  { city: 'Webb County', state: 'TX', state_name: 'Texas' },
  { city: 'Webster', state: 'TX', state_name: 'Texas' },
  { city: 'Weimar', state: 'TX', state_name: 'Texas' },
  { city: 'Wellington', state: 'TX', state_name: 'Texas' },
  { city: 'Wells Branch', state: 'TX', state_name: 'Texas' },
  { city: 'Weslaco', state: 'TX', state_name: 'Texas' },
  { city: 'West', state: 'TX', state_name: 'Texas' },
  { city: 'West Columbia', state: 'TX', state_name: 'Texas' },
  { city: 'West Lake Hills', state: 'TX', state_name: 'Texas' },
  { city: 'West Livingston', state: 'TX', state_name: 'Texas' },
  { city: 'West Odessa', state: 'TX', state_name: 'Texas' },
  { city: 'West Orange', state: 'TX', state_name: 'Texas' },
  { city: 'West Sharyland', state: 'TX', state_name: 'Texas' },
  { city: 'West Tawakoni', state: 'TX', state_name: 'Texas' },
  { city: 'West University Place', state: 'TX', state_name: 'Texas' },
  { city: 'Western Lake', state: 'TX', state_name: 'Texas' },
  { city: 'Westlake', state: 'TX', state_name: 'Texas' },
  { city: 'Weston Lakes', state: 'TX', state_name: 'Texas' },
  { city: 'Westway', state: 'TX', state_name: 'Texas' },
  { city: 'Westworth', state: 'TX', state_name: 'Texas' },
  { city: 'Wharton', state: 'TX', state_name: 'Texas' },
  { city: 'Wharton County', state: 'TX', state_name: 'Texas' },
  { city: 'Wheeler', state: 'TX', state_name: 'Texas' },
  { city: 'Wheeler County', state: 'TX', state_name: 'Texas' },
  { city: 'White Oak', state: 'TX', state_name: 'Texas' },
  { city: 'White Settlement', state: 'TX', state_name: 'Texas' },
  { city: 'Whitehouse', state: 'TX', state_name: 'Texas' },
  { city: 'Whitesboro', state: 'TX', state_name: 'Texas' },
  { city: 'Whitewright', state: 'TX', state_name: 'Texas' },
  { city: 'Whitney', state: 'TX', state_name: 'Texas' },
  { city: 'Wichita County', state: 'TX', state_name: 'Texas' },
  { city: 'Wichita Falls', state: 'TX', state_name: 'Texas' },
  { city: 'Wilbarger County', state: 'TX', state_name: 'Texas' },
  { city: 'Wild Peach Village', state: 'TX', state_name: 'Texas' },
  { city: 'Wildwood', state: 'TX', state_name: 'Texas' },
  { city: 'Willacy County', state: 'TX', state_name: 'Texas' },
  { city: 'Williamson County', state: 'TX', state_name: 'Texas' },
  { city: 'Willis', state: 'TX', state_name: 'Texas' },
  { city: 'Willow Park', state: 'TX', state_name: 'Texas' },
  { city: 'Wills Point', state: 'TX', state_name: 'Texas' },
  { city: 'Wilmer', state: 'TX', state_name: 'Texas' },
  { city: 'Wilson County', state: 'TX', state_name: 'Texas' },
  { city: 'Wimberley', state: 'TX', state_name: 'Texas' },
  { city: 'Windcrest', state: 'TX', state_name: 'Texas' },
  { city: 'Windemere', state: 'TX', state_name: 'Texas' },
  { city: 'Wink', state: 'TX', state_name: 'Texas' },
  { city: 'Winkler County', state: 'TX', state_name: 'Texas' },
  { city: 'Winnie', state: 'TX', state_name: 'Texas' },
  { city: 'Winnsboro', state: 'TX', state_name: 'Texas' },
  { city: 'Winters', state: 'TX', state_name: 'Texas' },
  { city: 'Wise County', state: 'TX', state_name: 'Texas' },
  { city: 'Wolfe City', state: 'TX', state_name: 'Texas' },
  { city: 'Wolfforth', state: 'TX', state_name: 'Texas' },
  { city: 'Wood County', state: 'TX', state_name: 'Texas' },
  { city: 'Woodbranch', state: 'TX', state_name: 'Texas' },
  { city: 'Woodcreek', state: 'TX', state_name: 'Texas' },
  { city: 'Woodsboro', state: 'TX', state_name: 'Texas' },
  { city: 'Woodville', state: 'TX', state_name: 'Texas' },
  { city: 'Woodway', state: 'TX', state_name: 'Texas' },
  { city: 'Wortham', state: 'TX', state_name: 'Texas' },
  { city: 'Wyldwood', state: 'TX', state_name: 'Texas' },
  { city: 'Wylie', state: 'TX', state_name: 'Texas' },
  { city: 'Yoakum', state: 'TX', state_name: 'Texas' },
  { city: 'Yoakum County', state: 'TX', state_name: 'Texas' },
  { city: 'Yorktown', state: 'TX', state_name: 'Texas' },
  { city: 'Young County', state: 'TX', state_name: 'Texas' },
  { city: 'Zapata', state: 'TX', state_name: 'Texas' },
  { city: 'Zapata County', state: 'TX', state_name: 'Texas' },
  { city: 'Zavala County', state: 'TX', state_name: 'Texas' },
  { city: 'Alpine', state: 'UT', state_name: 'Utah' },
  { city: 'American Fork', state: 'UT', state_name: 'Utah' },
  { city: 'Aurora', state: 'UT', state_name: 'Utah' },
  { city: 'Ballard', state: 'UT', state_name: 'Utah' },
  { city: 'Beaver', state: 'UT', state_name: 'Utah' },
  { city: 'Beaver County', state: 'UT', state_name: 'Utah' },
  { city: 'Benjamin', state: 'UT', state_name: 'Utah' },
  { city: 'Benson', state: 'UT', state_name: 'Utah' },
  { city: 'Blanding', state: 'UT', state_name: 'Utah' },
  { city: 'Bluffdale', state: 'UT', state_name: 'Utah' },
  { city: 'Bountiful', state: 'UT', state_name: 'Utah' },
  { city: 'Box Elder County', state: 'UT', state_name: 'Utah' },
  { city: 'Brigham City', state: 'UT', state_name: 'Utah' },
  { city: 'Cache County', state: 'UT', state_name: 'Utah' },
  { city: 'Canyon Rim', state: 'UT', state_name: 'Utah' },
  { city: 'Carbon County', state: 'UT', state_name: 'Utah' },
  { city: 'Carbonville', state: 'UT', state_name: 'Utah' },
  { city: 'Castle Dale', state: 'UT', state_name: 'Utah' },
  { city: 'Cedar City', state: 'UT', state_name: 'Utah' },
  { city: 'Cedar Hills', state: 'UT', state_name: 'Utah' },
  { city: 'Centerfield', state: 'UT', state_name: 'Utah' },
  { city: 'Centerville', state: 'UT', state_name: 'Utah' },
  { city: 'Clearfield', state: 'UT', state_name: 'Utah' },
  { city: 'Clinton', state: 'UT', state_name: 'Utah' },
  { city: 'Coalville', state: 'UT', state_name: 'Utah' },
  { city: 'Cottonwood Heights', state: 'UT', state_name: 'Utah' },
  { city: 'Daggett County', state: 'UT', state_name: 'Utah' },
  { city: 'Daniel', state: 'UT', state_name: 'Utah' },
  { city: 'Davis County', state: 'UT', state_name: 'Utah' },
  { city: 'Delta', state: 'UT', state_name: 'Utah' },
  { city: 'Draper', state: 'UT', state_name: 'Utah' },
  { city: 'Duchesne', state: 'UT', state_name: 'Utah' },
  { city: 'Duchesne County', state: 'UT', state_name: 'Utah' },
  { city: 'Eagle Mountain', state: 'UT', state_name: 'Utah' },
  { city: 'East Carbon City', state: 'UT', state_name: 'Utah' },
  { city: 'East Millcreek', state: 'UT', state_name: 'Utah' },
  { city: 'Elk Ridge', state: 'UT', state_name: 'Utah' },
  { city: 'Elwood', state: 'UT', state_name: 'Utah' },
  { city: 'Emery County', state: 'UT', state_name: 'Utah' },
  { city: 'Enoch', state: 'UT', state_name: 'Utah' },
  { city: 'Enterprise', state: 'UT', state_name: 'Utah' },
  { city: 'Ephraim', state: 'UT', state_name: 'Utah' },
  { city: 'Erda', state: 'UT', state_name: 'Utah' },
  { city: 'Fairview', state: 'UT', state_name: 'Utah' },
  { city: 'Farmington', state: 'UT', state_name: 'Utah' },
  { city: 'Farr West', state: 'UT', state_name: 'Utah' },
  { city: 'Ferron', state: 'UT', state_name: 'Utah' },
  { city: 'Fillmore', state: 'UT', state_name: 'Utah' },
  { city: 'Fountain Green', state: 'UT', state_name: 'Utah' },
  { city: 'Francis', state: 'UT', state_name: 'Utah' },
  { city: 'Fruit Heights', state: 'UT', state_name: 'Utah' },
  { city: 'Garfield County', state: 'UT', state_name: 'Utah' },
  { city: 'Garland', state: 'UT', state_name: 'Utah' },
  { city: 'Genola', state: 'UT', state_name: 'Utah' },
  { city: 'Grand County', state: 'UT', state_name: 'Utah' },
  { city: 'Granite', state: 'UT', state_name: 'Utah' },
  { city: 'Grantsville', state: 'UT', state_name: 'Utah' },
  { city: 'Gunnison', state: 'UT', state_name: 'Utah' },
  { city: 'Harrisville', state: 'UT', state_name: 'Utah' },
  { city: 'Heber City', state: 'UT', state_name: 'Utah' },
  { city: 'Helper', state: 'UT', state_name: 'Utah' },
  { city: 'Herriman', state: 'UT', state_name: 'Utah' },
  { city: 'Highland', state: 'UT', state_name: 'Utah' },
  { city: 'Hildale', state: 'UT', state_name: 'Utah' },
  { city: 'Hill Air Force Base', state: 'UT', state_name: 'Utah' },
  { city: 'Holladay', state: 'UT', state_name: 'Utah' },
  { city: 'Honeyville', state: 'UT', state_name: 'Utah' },
  { city: 'Hooper', state: 'UT', state_name: 'Utah' },
  { city: 'Huntington', state: 'UT', state_name: 'Utah' },
  { city: 'Hurricane', state: 'UT', state_name: 'Utah' },
  { city: 'Hyde Park', state: 'UT', state_name: 'Utah' },
  { city: 'Hyrum', state: 'UT', state_name: 'Utah' },
  { city: 'Iron County', state: 'UT', state_name: 'Utah' },
  { city: 'Ivins', state: 'UT', state_name: 'Utah' },
  { city: 'Juab County', state: 'UT', state_name: 'Utah' },
  { city: 'Junction', state: 'UT', state_name: 'Utah' },
  { city: 'Kamas', state: 'UT', state_name: 'Utah' },
  { city: 'Kanab', state: 'UT', state_name: 'Utah' },
  { city: 'Kane County', state: 'UT', state_name: 'Utah' },
  { city: 'Kaysville', state: 'UT', state_name: 'Utah' },
  { city: 'Kearns', state: 'UT', state_name: 'Utah' },
  { city: 'LaVerkin', state: 'UT', state_name: 'Utah' },
  { city: 'Layton', state: 'UT', state_name: 'Utah' },
  { city: 'Lehi', state: 'UT', state_name: 'Utah' },
  { city: 'Lewiston', state: 'UT', state_name: 'Utah' },
  { city: 'Liberty', state: 'UT', state_name: 'Utah' },
  { city: 'Lindon', state: 'UT', state_name: 'Utah' },
  { city: 'Little Cottonwood Creek Valley', state: 'UT', state_name: 'Utah' },
  { city: 'Loa', state: 'UT', state_name: 'Utah' },
  { city: 'Logan', state: 'UT', state_name: 'Utah' },
  { city: 'Maeser', state: 'UT', state_name: 'Utah' },
  { city: 'Magna', state: 'UT', state_name: 'Utah' },
  { city: 'Manila', state: 'UT', state_name: 'Utah' },
  { city: 'Manti', state: 'UT', state_name: 'Utah' },
  { city: 'Mapleton', state: 'UT', state_name: 'Utah' },
  { city: 'Marriott-Slaterville', state: 'UT', state_name: 'Utah' },
  { city: 'Mendon', state: 'UT', state_name: 'Utah' },
  { city: 'Midvale', state: 'UT', state_name: 'Utah' },
  { city: 'Midway', state: 'UT', state_name: 'Utah' },
  { city: 'Milford', state: 'UT', state_name: 'Utah' },
  { city: 'Millard County', state: 'UT', state_name: 'Utah' },
  { city: 'Millcreek', state: 'UT', state_name: 'Utah' },
  { city: 'Millville', state: 'UT', state_name: 'Utah' },
  { city: 'Moab', state: 'UT', state_name: 'Utah' },
  { city: 'Mona', state: 'UT', state_name: 'Utah' },
  { city: 'Monroe', state: 'UT', state_name: 'Utah' },
  { city: 'Monticello', state: 'UT', state_name: 'Utah' },
  { city: 'Morgan', state: 'UT', state_name: 'Utah' },
  { city: 'Morgan County', state: 'UT', state_name: 'Utah' },
  { city: 'Moroni', state: 'UT', state_name: 'Utah' },
  { city: 'Mount Olympus', state: 'UT', state_name: 'Utah' },
  { city: 'Mount Pleasant', state: 'UT', state_name: 'Utah' },
  { city: 'Mountain Green', state: 'UT', state_name: 'Utah' },
  { city: 'Murray', state: 'UT', state_name: 'Utah' },
  { city: 'Naples', state: 'UT', state_name: 'Utah' },
  { city: 'Nephi', state: 'UT', state_name: 'Utah' },
  { city: 'Nibley', state: 'UT', state_name: 'Utah' },
  { city: 'North Logan', state: 'UT', state_name: 'Utah' },
  { city: 'North Ogden', state: 'UT', state_name: 'Utah' },
  { city: 'North Salt Lake', state: 'UT', state_name: 'Utah' },
  { city: 'Oakley', state: 'UT', state_name: 'Utah' },
  { city: 'Ogden', state: 'UT', state_name: 'Utah' },
  { city: 'Oquirrh', state: 'UT', state_name: 'Utah' },
  { city: 'Orangeville', state: 'UT', state_name: 'Utah' },
  { city: 'Orem', state: 'UT', state_name: 'Utah' },
  { city: 'Panguitch', state: 'UT', state_name: 'Utah' },
  { city: 'Park City', state: 'UT', state_name: 'Utah' },
  { city: 'Parowan', state: 'UT', state_name: 'Utah' },
  { city: 'Payson', state: 'UT', state_name: 'Utah' },
  { city: 'Perry', state: 'UT', state_name: 'Utah' },
  { city: 'Piute County', state: 'UT', state_name: 'Utah' },
  { city: 'Plain City', state: 'UT', state_name: 'Utah' },
  { city: 'Pleasant Grove', state: 'UT', state_name: 'Utah' },
  { city: 'Pleasant View', state: 'UT', state_name: 'Utah' },
  { city: 'Price', state: 'UT', state_name: 'Utah' },
  { city: 'Providence', state: 'UT', state_name: 'Utah' },
  { city: 'Provo', state: 'UT', state_name: 'Utah' },
  { city: 'Randolph', state: 'UT', state_name: 'Utah' },
  { city: 'Rich County', state: 'UT', state_name: 'Utah' },
  { city: 'Richfield', state: 'UT', state_name: 'Utah' },
  { city: 'Richmond', state: 'UT', state_name: 'Utah' },
  { city: 'River Heights', state: 'UT', state_name: 'Utah' },
  { city: 'Riverdale', state: 'UT', state_name: 'Utah' },
  { city: 'Riverton', state: 'UT', state_name: 'Utah' },
  { city: 'Roosevelt', state: 'UT', state_name: 'Utah' },
  { city: 'Roy', state: 'UT', state_name: 'Utah' },
  { city: 'Saint George', state: 'UT', state_name: 'Utah' },
  { city: 'Salem', state: 'UT', state_name: 'Utah' },
  { city: 'Salina', state: 'UT', state_name: 'Utah' },
  { city: 'Salt Lake City', state: 'UT', state_name: 'Utah' },
  { city: 'Salt Lake County', state: 'UT', state_name: 'Utah' },
  { city: 'San Juan County', state: 'UT', state_name: 'Utah' },
  { city: 'Sandy', state: 'UT', state_name: 'Utah' },
  { city: 'Sandy Hills', state: 'UT', state_name: 'Utah' },
  { city: 'Sanpete County', state: 'UT', state_name: 'Utah' },
  { city: 'Santa Clara', state: 'UT', state_name: 'Utah' },
  { city: 'Santaquin', state: 'UT', state_name: 'Utah' },
  { city: 'Saratoga Springs', state: 'UT', state_name: 'Utah' },
  { city: 'Sevier County', state: 'UT', state_name: 'Utah' },
  { city: 'Silver Summit', state: 'UT', state_name: 'Utah' },
  { city: 'Smithfield', state: 'UT', state_name: 'Utah' },
  { city: 'Snyderville', state: 'UT', state_name: 'Utah' },
  { city: 'South Jordan', state: 'UT', state_name: 'Utah' },
  { city: 'South Jordan Heights', state: 'UT', state_name: 'Utah' },
  { city: 'South Ogden', state: 'UT', state_name: 'Utah' },
  { city: 'South Salt Lake', state: 'UT', state_name: 'Utah' },
  { city: 'South Weber', state: 'UT', state_name: 'Utah' },
  { city: 'South Willard', state: 'UT', state_name: 'Utah' },
  { city: 'Spanish Fork', state: 'UT', state_name: 'Utah' },
  { city: 'Spring City', state: 'UT', state_name: 'Utah' },
  { city: 'Spring Glen', state: 'UT', state_name: 'Utah' },
  { city: 'Springville', state: 'UT', state_name: 'Utah' },
  { city: 'Stansbury park', state: 'UT', state_name: 'Utah' },
  { city: 'Summit County', state: 'UT', state_name: 'Utah' },
  { city: 'Summit Park', state: 'UT', state_name: 'Utah' },
  { city: 'Sunset', state: 'UT', state_name: 'Utah' },
  { city: 'Syracuse', state: 'UT', state_name: 'Utah' },
  { city: 'Taylorsville', state: 'UT', state_name: 'Utah' },
  { city: 'Tooele', state: 'UT', state_name: 'Utah' },
  { city: 'Tooele County', state: 'UT', state_name: 'Utah' },
  { city: 'Toquerville', state: 'UT', state_name: 'Utah' },
  { city: 'Tremonton', state: 'UT', state_name: 'Utah' },
  { city: 'Uintah', state: 'UT', state_name: 'Utah' },
  { city: 'Uintah County', state: 'UT', state_name: 'Utah' },
  { city: 'Utah County', state: 'UT', state_name: 'Utah' },
  { city: 'Vernal', state: 'UT', state_name: 'Utah' },
  { city: 'Vineyard', state: 'UT', state_name: 'Utah' },
  { city: 'Wasatch County', state: 'UT', state_name: 'Utah' },
  { city: 'Washington', state: 'UT', state_name: 'Utah' },
  { city: 'Washington County', state: 'UT', state_name: 'Utah' },
  { city: 'Washington Terrace', state: 'UT', state_name: 'Utah' },
  { city: 'Wayne County', state: 'UT', state_name: 'Utah' },
  { city: 'Weber County', state: 'UT', state_name: 'Utah' },
  { city: 'Wellington', state: 'UT', state_name: 'Utah' },
  { city: 'Wellsville', state: 'UT', state_name: 'Utah' },
  { city: 'Wendover', state: 'UT', state_name: 'Utah' },
  { city: 'West Bountiful', state: 'UT', state_name: 'Utah' },
  { city: 'West Haven', state: 'UT', state_name: 'Utah' },
  { city: 'West Jordan', state: 'UT', state_name: 'Utah' },
  { city: 'West Mountain', state: 'UT', state_name: 'Utah' },
  { city: 'West Point', state: 'UT', state_name: 'Utah' },
  { city: 'West Valley City', state: 'UT', state_name: 'Utah' },
  { city: 'White City', state: 'UT', state_name: 'Utah' },
  { city: 'Willard', state: 'UT', state_name: 'Utah' },
  { city: 'Wolf Creek', state: 'UT', state_name: 'Utah' },
  { city: 'Woodland Hills', state: 'UT', state_name: 'Utah' },
  { city: 'Woods Cross', state: 'UT', state_name: 'Utah' },
  { city: 'Addison', state: 'VT', state_name: 'Vermont' },
  { city: 'Addison County', state: 'VT', state_name: 'Vermont' },
  { city: 'Arlington', state: 'VT', state_name: 'Vermont' },
  { city: 'Barre', state: 'VT', state_name: 'Vermont' },
  { city: 'Bellows Falls', state: 'VT', state_name: 'Vermont' },
  { city: 'Bennington', state: 'VT', state_name: 'Vermont' },
  { city: 'Bennington County', state: 'VT', state_name: 'Vermont' },
  { city: 'Brandon', state: 'VT', state_name: 'Vermont' },
  { city: 'Brattleboro', state: 'VT', state_name: 'Vermont' },
  { city: 'Bridport', state: 'VT', state_name: 'Vermont' },
  { city: 'Bristol', state: 'VT', state_name: 'Vermont' },
  { city: 'Burlington', state: 'VT', state_name: 'Vermont' },
  { city: 'Caledonia County', state: 'VT', state_name: 'Vermont' },
  { city: 'Castleton', state: 'VT', state_name: 'Vermont' },
  { city: 'Charlotte', state: 'VT', state_name: 'Vermont' },
  { city: 'Chelsea', state: 'VT', state_name: 'Vermont' },
  { city: 'Chester', state: 'VT', state_name: 'Vermont' },
  { city: 'Chittenden', state: 'VT', state_name: 'Vermont' },
  { city: 'Chittenden County', state: 'VT', state_name: 'Vermont' },
  { city: 'Clarendon', state: 'VT', state_name: 'Vermont' },
  { city: 'Colchester', state: 'VT', state_name: 'Vermont' },
  { city: 'Danby', state: 'VT', state_name: 'Vermont' },
  { city: 'Dover', state: 'VT', state_name: 'Vermont' },
  { city: 'Enosburg Falls', state: 'VT', state_name: 'Vermont' },
  { city: 'Essex County', state: 'VT', state_name: 'Vermont' },
  { city: 'Essex Junction', state: 'VT', state_name: 'Vermont' },
  { city: 'Fair Haven', state: 'VT', state_name: 'Vermont' },
  { city: 'Ferrisburgh', state: 'VT', state_name: 'Vermont' },
  { city: 'Franklin County', state: 'VT', state_name: 'Vermont' },
  { city: 'Grand Isle County', state: 'VT', state_name: 'Vermont' },
  { city: 'Guildhall', state: 'VT', state_name: 'Vermont' },
  { city: 'Hardwick', state: 'VT', state_name: 'Vermont' },
  { city: 'Hartford', state: 'VT', state_name: 'Vermont' },
  { city: 'Hinesburg', state: 'VT', state_name: 'Vermont' },
  { city: 'Hyde Park', state: 'VT', state_name: 'Vermont' },
  { city: 'Jamaica', state: 'VT', state_name: 'Vermont' },
  { city: 'Jericho', state: 'VT', state_name: 'Vermont' },
  { city: 'Johnson', state: 'VT', state_name: 'Vermont' },
  { city: 'Lamoille County', state: 'VT', state_name: 'Vermont' },
  { city: 'Leicester', state: 'VT', state_name: 'Vermont' },
  { city: 'Lincoln', state: 'VT', state_name: 'Vermont' },
  { city: 'Londonderry', state: 'VT', state_name: 'Vermont' },
  { city: 'Lunenburg', state: 'VT', state_name: 'Vermont' },
  { city: 'Lyndon', state: 'VT', state_name: 'Vermont' },
  { city: 'Lyndonville', state: 'VT', state_name: 'Vermont' },
  { city: 'Manchester Center', state: 'VT', state_name: 'Vermont' },
  { city: 'Mendon', state: 'VT', state_name: 'Vermont' },
  { city: 'Middlebury (village)', state: 'VT', state_name: 'Vermont' },
  { city: 'Milton', state: 'VT', state_name: 'Vermont' },
  { city: 'Montgomery', state: 'VT', state_name: 'Vermont' },
  { city: 'Montpelier', state: 'VT', state_name: 'Vermont' },
  { city: 'Moretown', state: 'VT', state_name: 'Vermont' },
  { city: 'Morristown', state: 'VT', state_name: 'Vermont' },
  { city: 'Morrisville', state: 'VT', state_name: 'Vermont' },
  { city: 'Mount Holly', state: 'VT', state_name: 'Vermont' },
  { city: 'Newfane', state: 'VT', state_name: 'Vermont' },
  { city: 'Newport', state: 'VT', state_name: 'Vermont' },
  { city: 'North Bennington', state: 'VT', state_name: 'Vermont' },
  { city: 'North Hero', state: 'VT', state_name: 'Vermont' },
  { city: 'Northfield', state: 'VT', state_name: 'Vermont' },
  { city: 'Orange County', state: 'VT', state_name: 'Vermont' },
  { city: 'Orleans County', state: 'VT', state_name: 'Vermont' },
  { city: 'Pawlet', state: 'VT', state_name: 'Vermont' },
  { city: 'Poultney', state: 'VT', state_name: 'Vermont' },
  { city: 'Pownal', state: 'VT', state_name: 'Vermont' },
  { city: 'Randolph', state: 'VT', state_name: 'Vermont' },
  { city: 'Richford', state: 'VT', state_name: 'Vermont' },
  { city: 'Rockingham', state: 'VT', state_name: 'Vermont' },
  { city: 'Rutland', state: 'VT', state_name: 'Vermont' },
  { city: 'Rutland County', state: 'VT', state_name: 'Vermont' },
  { city: 'Saint Albans', state: 'VT', state_name: 'Vermont' },
  { city: 'Saint Johnsbury', state: 'VT', state_name: 'Vermont' },
  { city: 'Salisbury', state: 'VT', state_name: 'Vermont' },
  { city: 'South Barre', state: 'VT', state_name: 'Vermont' },
  { city: 'South Burlington', state: 'VT', state_name: 'Vermont' },
  { city: 'Springfield', state: 'VT', state_name: 'Vermont' },
  { city: 'St Johnsbury', state: 'VT', state_name: 'Vermont' },
  { city: 'Starksboro', state: 'VT', state_name: 'Vermont' },
  { city: 'Stowe', state: 'VT', state_name: 'Vermont' },
  { city: 'Swanton', state: 'VT', state_name: 'Vermont' },
  { city: 'Townshend', state: 'VT', state_name: 'Vermont' },
  { city: 'Vergennes', state: 'VT', state_name: 'Vermont' },
  { city: 'Washington', state: 'VT', state_name: 'Vermont' },
  { city: 'Washington County', state: 'VT', state_name: 'Vermont' },
  { city: 'Waterbury', state: 'VT', state_name: 'Vermont' },
  { city: 'West Brattleboro', state: 'VT', state_name: 'Vermont' },
  { city: 'West Rutland', state: 'VT', state_name: 'Vermont' },
  { city: 'White River Junction', state: 'VT', state_name: 'Vermont' },
  { city: 'Wilder', state: 'VT', state_name: 'Vermont' },
  { city: 'Williamstown', state: 'VT', state_name: 'Vermont' },
  { city: 'Williston', state: 'VT', state_name: 'Vermont' },
  { city: 'Windham County', state: 'VT', state_name: 'Vermont' },
  { city: 'Windsor', state: 'VT', state_name: 'Vermont' },
  { city: 'Windsor County', state: 'VT', state_name: 'Vermont' },
  { city: 'Winooski', state: 'VT', state_name: 'Vermont' },
  { city: 'Woodstock', state: 'VT', state_name: 'Vermont' },
  { city: 'Abingdon', state: 'VA', state_name: 'Virginia' },
  { city: 'Accomac', state: 'VA', state_name: 'Virginia' },
  { city: 'Accomack County', state: 'VA', state_name: 'Virginia' },
  { city: 'Adwolf', state: 'VA', state_name: 'Virginia' },
  { city: 'Albemarle County', state: 'VA', state_name: 'Virginia' },
  { city: 'Alexandria', state: 'VA', state_name: 'Virginia' },
  { city: 'Alleghany County', state: 'VA', state_name: 'Virginia' },
  { city: 'Altavista', state: 'VA', state_name: 'Virginia' },
  { city: 'Amelia County', state: 'VA', state_name: 'Virginia' },
  { city: 'Amelia Court House', state: 'VA', state_name: 'Virginia' },
  { city: 'Amherst', state: 'VA', state_name: 'Virginia' },
  { city: 'Amherst County', state: 'VA', state_name: 'Virginia' },
  { city: 'Annandale', state: 'VA', state_name: 'Virginia' },
  { city: 'Appalachia', state: 'VA', state_name: 'Virginia' },
  { city: 'Apple Mountain Lake', state: 'VA', state_name: 'Virginia' },
  { city: 'Appomattox', state: 'VA', state_name: 'Virginia' },
  { city: 'Appomattox County', state: 'VA', state_name: 'Virginia' },
  { city: 'Aquia Harbour', state: 'VA', state_name: 'Virginia' },
  { city: 'Arlington', state: 'VA', state_name: 'Virginia' },
  { city: 'Arlington County', state: 'VA', state_name: 'Virginia' },
  { city: 'Ashburn', state: 'VA', state_name: 'Virginia' },
  { city: 'Ashland', state: 'VA', state_name: 'Virginia' },
  { city: 'Atkins', state: 'VA', state_name: 'Virginia' },
  { city: 'Augusta County', state: 'VA', state_name: 'Virginia' },
  { city: 'Baileys Crossroads', state: 'VA', state_name: 'Virginia' },
  { city: 'Bassett', state: 'VA', state_name: 'Virginia' },
  { city: 'Basye', state: 'VA', state_name: 'Virginia' },
  { city: 'Bath County', state: 'VA', state_name: 'Virginia' },
  { city: 'Bealeton', state: 'VA', state_name: 'Virginia' },
  { city: 'Bedford', state: 'VA', state_name: 'Virginia' },
  { city: 'Bedford County', state: 'VA', state_name: 'Virginia' },
  { city: 'Belle Haven', state: 'VA', state_name: 'Virginia' },
  { city: 'Bellwood', state: 'VA', state_name: 'Virginia' },
  { city: 'Belmont', state: 'VA', state_name: 'Virginia' },
  { city: 'Belmont Estates', state: 'VA', state_name: 'Virginia' },
  { city: 'Bensley', state: 'VA', state_name: 'Virginia' },
  { city: 'Berryville', state: 'VA', state_name: 'Virginia' },
  { city: 'Big Stone Gap', state: 'VA', state_name: 'Virginia' },
  { city: 'Blacksburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Blackstone', state: 'VA', state_name: 'Virginia' },
  { city: 'Bland', state: 'VA', state_name: 'Virginia' },
  { city: 'Bland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Blue Ridge', state: 'VA', state_name: 'Virginia' },
  { city: 'Bluefield', state: 'VA', state_name: 'Virginia' },
  { city: 'Bon Air', state: 'VA', state_name: 'Virginia' },
  { city: "Boswell's Corner", state: 'VA', state_name: 'Virginia' },
  { city: 'Botetourt County', state: 'VA', state_name: 'Virginia' },
  { city: 'Bowling Green', state: 'VA', state_name: 'Virginia' },
  { city: 'Boydton', state: 'VA', state_name: 'Virginia' },
  { city: 'Bracey', state: 'VA', state_name: 'Virginia' },
  { city: 'Brambleton', state: 'VA', state_name: 'Virginia' },
  { city: 'Brandermill', state: 'VA', state_name: 'Virginia' },
  { city: 'Bridgewater', state: 'VA', state_name: 'Virginia' },
  { city: 'Brightwood', state: 'VA', state_name: 'Virginia' },
  { city: 'Bristol', state: 'VA', state_name: 'Virginia' },
  { city: 'Broadlands', state: 'VA', state_name: 'Virginia' },
  { city: 'Broadway', state: 'VA', state_name: 'Virginia' },
  { city: 'Brookneal', state: 'VA', state_name: 'Virginia' },
  { city: 'Brunswick County', state: 'VA', state_name: 'Virginia' },
  { city: 'Buchanan', state: 'VA', state_name: 'Virginia' },
  { city: 'Buchanan County', state: 'VA', state_name: 'Virginia' },
  { city: 'Buckhall', state: 'VA', state_name: 'Virginia' },
  { city: 'Buckingham', state: 'VA', state_name: 'Virginia' },
  { city: 'Buckingham County', state: 'VA', state_name: 'Virginia' },
  { city: 'Buena Vista', state: 'VA', state_name: 'Virginia' },
  { city: 'Bull Run', state: 'VA', state_name: 'Virginia' },
  { city: 'Bull Run Mountain Estates', state: 'VA', state_name: 'Virginia' },
  { city: 'Burke', state: 'VA', state_name: 'Virginia' },
  { city: 'Campbell County', state: 'VA', state_name: 'Virginia' },
  { city: 'Cana', state: 'VA', state_name: 'Virginia' },
  { city: 'Cape Charles', state: 'VA', state_name: 'Virginia' },
  { city: 'Captains Cove', state: 'VA', state_name: 'Virginia' },
  { city: 'Caroline County', state: 'VA', state_name: 'Virginia' },
  { city: 'Carroll County', state: 'VA', state_name: 'Virginia' },
  { city: 'Carrollton', state: 'VA', state_name: 'Virginia' },
  { city: 'Castlewood', state: 'VA', state_name: 'Virginia' },
  { city: 'Cave Spring', state: 'VA', state_name: 'Virginia' },
  { city: 'Cedar Bluff', state: 'VA', state_name: 'Virginia' },
  { city: 'Central Garage', state: 'VA', state_name: 'Virginia' },
  { city: 'Centreville', state: 'VA', state_name: 'Virginia' },
  { city: 'Chamberlayne', state: 'VA', state_name: 'Virginia' },
  { city: 'Chantilly', state: 'VA', state_name: 'Virginia' },
  { city: 'Charles City', state: 'VA', state_name: 'Virginia' },
  { city: 'Charles City County', state: 'VA', state_name: 'Virginia' },
  { city: 'Charlotte County', state: 'VA', state_name: 'Virginia' },
  { city: 'Charlotte Court House', state: 'VA', state_name: 'Virginia' },
  { city: 'Charlottesville', state: 'VA', state_name: 'Virginia' },
  { city: 'Chase City', state: 'VA', state_name: 'Virginia' },
  { city: 'Chatham', state: 'VA', state_name: 'Virginia' },
  { city: 'Chatmoss', state: 'VA', state_name: 'Virginia' },
  { city: 'Cherry Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Chesapeake', state: 'VA', state_name: 'Virginia' },
  { city: 'Chester', state: 'VA', state_name: 'Virginia' },
  { city: 'Chesterfield County', state: 'VA', state_name: 'Virginia' },
  { city: 'Chesterfield Court House', state: 'VA', state_name: 'Virginia' },
  { city: 'Chilhowie', state: 'VA', state_name: 'Virginia' },
  { city: 'Chincoteague', state: 'VA', state_name: 'Virginia' },
  { city: 'Christiansburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Alexandria', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Bedford', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Bristol', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Buena Vista', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Charlottesville', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Chesapeake', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Colonial Heights', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Covington', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Danville', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Emporia', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Fairfax', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Falls Church', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Franklin', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Fredericksburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Galax', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Hampton', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Harrisonburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Hopewell', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Lexington', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Lynchburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Manassas', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Manassas Park', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Martinsville', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Newport News', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Norfolk', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Norton', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Petersburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Poquoson', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Portsmouth', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Radford', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Richmond', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Roanoke', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Salem', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Staunton', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Suffolk', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Virginia Beach', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Waynesboro', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Williamsburg', state: 'VA', state_name: 'Virginia' },
  { city: 'City of Winchester', state: 'VA', state_name: 'Virginia' },
  { city: 'Clarke County', state: 'VA', state_name: 'Virginia' },
  { city: 'Clarksville', state: 'VA', state_name: 'Virginia' },
  { city: 'Claypool Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Clifton Forge', state: 'VA', state_name: 'Virginia' },
  { city: 'Clintwood', state: 'VA', state_name: 'Virginia' },
  { city: 'Cloverdale', state: 'VA', state_name: 'Virginia' },
  { city: 'Coeburn', state: 'VA', state_name: 'Virginia' },
  { city: 'Collinsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Colonial Beach', state: 'VA', state_name: 'Virginia' },
  { city: 'Colonial Heights', state: 'VA', state_name: 'Virginia' },
  { city: 'Concord', state: 'VA', state_name: 'Virginia' },
  { city: 'Countryside', state: 'VA', state_name: 'Virginia' },
  { city: 'Courtland', state: 'VA', state_name: 'Virginia' },
  { city: 'Covington', state: 'VA', state_name: 'Virginia' },
  { city: 'Craig County', state: 'VA', state_name: 'Virginia' },
  { city: 'Crewe', state: 'VA', state_name: 'Virginia' },
  { city: 'Crimora', state: 'VA', state_name: 'Virginia' },
  { city: 'Crozet', state: 'VA', state_name: 'Virginia' },
  { city: 'Culpeper', state: 'VA', state_name: 'Virginia' },
  { city: 'Culpeper County', state: 'VA', state_name: 'Virginia' },
  { city: 'Cumberland', state: 'VA', state_name: 'Virginia' },
  { city: 'Cumberland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Dahlgren', state: 'VA', state_name: 'Virginia' },
  { city: 'Dale City', state: 'VA', state_name: 'Virginia' },
  { city: 'Daleville', state: 'VA', state_name: 'Virginia' },
  { city: 'Danville', state: 'VA', state_name: 'Virginia' },
  { city: 'Dayton', state: 'VA', state_name: 'Virginia' },
  { city: 'Deltaville', state: 'VA', state_name: 'Virginia' },
  { city: 'Dickenson County', state: 'VA', state_name: 'Virginia' },
  { city: 'Dinwiddie County', state: 'VA', state_name: 'Virginia' },
  { city: 'Dooms', state: 'VA', state_name: 'Virginia' },
  { city: 'Dranesville', state: 'VA', state_name: 'Virginia' },
  { city: 'Dryden', state: 'VA', state_name: 'Virginia' },
  { city: 'Dublin', state: 'VA', state_name: 'Virginia' },
  { city: 'Dulles Town Center', state: 'VA', state_name: 'Virginia' },
  { city: 'Dumbarton', state: 'VA', state_name: 'Virginia' },
  { city: 'Dumfries', state: 'VA', state_name: 'Virginia' },
  { city: 'Dunn Loring', state: 'VA', state_name: 'Virginia' },
  { city: 'East Hampton', state: 'VA', state_name: 'Virginia' },
  { city: 'East Highland Park', state: 'VA', state_name: 'Virginia' },
  { city: 'East Lexington', state: 'VA', state_name: 'Virginia' },
  { city: 'Eastville', state: 'VA', state_name: 'Virginia' },
  { city: 'Edinburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Elkton', state: 'VA', state_name: 'Virginia' },
  { city: 'Emory', state: 'VA', state_name: 'Virginia' },
  { city: 'Emporia', state: 'VA', state_name: 'Virginia' },
  { city: 'Enon', state: 'VA', state_name: 'Virginia' },
  { city: 'Essex County', state: 'VA', state_name: 'Virginia' },
  { city: 'Ettrick', state: 'VA', state_name: 'Virginia' },
  { city: 'Exmore', state: 'VA', state_name: 'Virginia' },
  { city: 'Fairfax', state: 'VA', state_name: 'Virginia' },
  { city: 'Fairfax County', state: 'VA', state_name: 'Virginia' },
  { city: 'Fairfax Station', state: 'VA', state_name: 'Virginia' },
  { city: 'Fairlawn', state: 'VA', state_name: 'Virginia' },
  { city: 'Falls Church', state: 'VA', state_name: 'Virginia' },
  { city: 'Falmouth', state: 'VA', state_name: 'Virginia' },
  { city: 'Farmville', state: 'VA', state_name: 'Virginia' },
  { city: 'Fauquier County', state: 'VA', state_name: 'Virginia' },
  { city: 'Ferrum', state: 'VA', state_name: 'Virginia' },
  { city: 'Fincastle', state: 'VA', state_name: 'Virginia' },
  { city: 'Fishersville', state: 'VA', state_name: 'Virginia' },
  { city: 'Floris', state: 'VA', state_name: 'Virginia' },
  { city: 'Floyd', state: 'VA', state_name: 'Virginia' },
  { city: 'Floyd County', state: 'VA', state_name: 'Virginia' },
  { city: 'Fluvanna County', state: 'VA', state_name: 'Virginia' },
  { city: 'Forest', state: 'VA', state_name: 'Virginia' },
  { city: 'Fort Belvoir', state: 'VA', state_name: 'Virginia' },
  { city: 'Fort Hunt', state: 'VA', state_name: 'Virginia' },
  { city: 'Fort Lee', state: 'VA', state_name: 'Virginia' },
  { city: 'Franconia', state: 'VA', state_name: 'Virginia' },
  { city: 'Franklin', state: 'VA', state_name: 'Virginia' },
  { city: 'Franklin County', state: 'VA', state_name: 'Virginia' },
  { city: 'Frederick County', state: 'VA', state_name: 'Virginia' },
  { city: 'Fredericksburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Front Royal', state: 'VA', state_name: 'Virginia' },
  { city: 'Gainesville', state: 'VA', state_name: 'Virginia' },
  { city: 'Galax', state: 'VA', state_name: 'Virginia' },
  { city: 'Gate City', state: 'VA', state_name: 'Virginia' },
  { city: 'Giles County', state: 'VA', state_name: 'Virginia' },
  { city: 'Glade Spring', state: 'VA', state_name: 'Virginia' },
  { city: 'Glasgow', state: 'VA', state_name: 'Virginia' },
  { city: 'Glen Allen', state: 'VA', state_name: 'Virginia' },
  { city: 'Gloucester County', state: 'VA', state_name: 'Virginia' },
  { city: 'Gloucester Courthouse', state: 'VA', state_name: 'Virginia' },
  { city: 'Gloucester Point', state: 'VA', state_name: 'Virginia' },
  { city: 'Goochland', state: 'VA', state_name: 'Virginia' },
  { city: 'Goochland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Gordonsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Grayson County', state: 'VA', state_name: 'Virginia' },
  { city: 'Great Falls', state: 'VA', state_name: 'Virginia' },
  { city: 'Greenbriar', state: 'VA', state_name: 'Virginia' },
  { city: 'Greene County', state: 'VA', state_name: 'Virginia' },
  { city: 'Greensville County', state: 'VA', state_name: 'Virginia' },
  { city: 'Gretna', state: 'VA', state_name: 'Virginia' },
  { city: 'Grottoes', state: 'VA', state_name: 'Virginia' },
  { city: 'Groveton', state: 'VA', state_name: 'Virginia' },
  { city: 'Grundy', state: 'VA', state_name: 'Virginia' },
  { city: 'Halifax', state: 'VA', state_name: 'Virginia' },
  { city: 'Halifax County', state: 'VA', state_name: 'Virginia' },
  { city: 'Hampden Sydney', state: 'VA', state_name: 'Virginia' },
  { city: 'Hampton', state: 'VA', state_name: 'Virginia' },
  { city: 'Hanover', state: 'VA', state_name: 'Virginia' },
  { city: 'Hanover County', state: 'VA', state_name: 'Virginia' },
  { city: 'Harrisonburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Hayfield', state: 'VA', state_name: 'Virginia' },
  { city: 'Haymarket', state: 'VA', state_name: 'Virginia' },
  { city: 'Heathsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Henrico County', state: 'VA', state_name: 'Virginia' },
  { city: 'Henry County', state: 'VA', state_name: 'Virginia' },
  { city: 'Henry Fork', state: 'VA', state_name: 'Virginia' },
  { city: 'Herndon', state: 'VA', state_name: 'Virginia' },
  { city: 'Highland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Highland Springs', state: 'VA', state_name: 'Virginia' },
  { city: 'Hillsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Hollins', state: 'VA', state_name: 'Virginia' },
  { city: 'Hollymead', state: 'VA', state_name: 'Virginia' },
  { city: 'Honaker', state: 'VA', state_name: 'Virginia' },
  { city: 'Hopewell', state: 'VA', state_name: 'Virginia' },
  { city: 'Horse Pasture', state: 'VA', state_name: 'Virginia' },
  { city: 'Huntington', state: 'VA', state_name: 'Virginia' },
  { city: 'Hurt', state: 'VA', state_name: 'Virginia' },
  { city: 'Hybla Valley', state: 'VA', state_name: 'Virginia' },
  { city: 'Idylwood', state: 'VA', state_name: 'Virginia' },
  { city: 'Independence', state: 'VA', state_name: 'Virginia' },
  { city: 'Independent Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Isle of Wight County', state: 'VA', state_name: 'Virginia' },
  { city: 'James City County', state: 'VA', state_name: 'Virginia' },
  { city: 'Jefferson', state: 'VA', state_name: 'Virginia' },
  { city: 'Jolivue', state: 'VA', state_name: 'Virginia' },
  { city: 'Jonesville', state: 'VA', state_name: 'Virginia' },
  { city: 'Kenbridge', state: 'VA', state_name: 'Virginia' },
  { city: 'Kilmarnock', state: 'VA', state_name: 'Virginia' },
  { city: 'King and Queen County', state: 'VA', state_name: 'Virginia' },
  { city: 'King and Queen Court House', state: 'VA', state_name: 'Virginia' },
  { city: 'King George', state: 'VA', state_name: 'Virginia' },
  { city: 'King George County', state: 'VA', state_name: 'Virginia' },
  { city: 'King William', state: 'VA', state_name: 'Virginia' },
  { city: 'King William County', state: 'VA', state_name: 'Virginia' },
  { city: 'Kings Park', state: 'VA', state_name: 'Virginia' },
  { city: 'Kings Park West', state: 'VA', state_name: 'Virginia' },
  { city: 'Lake Barcroft', state: 'VA', state_name: 'Virginia' },
  { city: 'Lake Monticello', state: 'VA', state_name: 'Virginia' },
  { city: 'Lake Ridge', state: 'VA', state_name: 'Virginia' },
  { city: 'Lakeside', state: 'VA', state_name: 'Virginia' },
  { city: 'Lancaster County', state: 'VA', state_name: 'Virginia' },
  { city: 'Laurel', state: 'VA', state_name: 'Virginia' },
  { city: 'Laurel Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Lawrenceville', state: 'VA', state_name: 'Virginia' },
  { city: 'Laymantown', state: 'VA', state_name: 'Virginia' },
  { city: 'Lebanon', state: 'VA', state_name: 'Virginia' },
  { city: 'Lee County', state: 'VA', state_name: 'Virginia' },
  { city: 'Leesburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Lexington', state: 'VA', state_name: 'Virginia' },
  { city: 'Lincolnia', state: 'VA', state_name: 'Virginia' },
  { city: 'Linton Hall', state: 'VA', state_name: 'Virginia' },
  { city: 'Loch Lomond', state: 'VA', state_name: 'Virginia' },
  { city: 'Lorton', state: 'VA', state_name: 'Virginia' },
  { city: 'Loudoun County', state: 'VA', state_name: 'Virginia' },
  { city: 'Loudoun Valley Estates', state: 'VA', state_name: 'Virginia' },
  { city: 'Louisa', state: 'VA', state_name: 'Virginia' },
  { city: 'Louisa County', state: 'VA', state_name: 'Virginia' },
  { city: 'Lovettsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Lovingston', state: 'VA', state_name: 'Virginia' },
  { city: 'Lowes Island', state: 'VA', state_name: 'Virginia' },
  { city: 'Lunenburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Lunenburg County', state: 'VA', state_name: 'Virginia' },
  { city: 'Luray', state: 'VA', state_name: 'Virginia' },
  { city: 'Lynchburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Lyndhurst', state: 'VA', state_name: 'Virginia' },
  { city: 'Madison', state: 'VA', state_name: 'Virginia' },
  { city: 'Madison County', state: 'VA', state_name: 'Virginia' },
  { city: 'Madison Heights', state: 'VA', state_name: 'Virginia' },
  { city: 'Manassas', state: 'VA', state_name: 'Virginia' },
  { city: 'Manassas Park', state: 'VA', state_name: 'Virginia' },
  { city: 'Mantua', state: 'VA', state_name: 'Virginia' },
  { city: 'Marion', state: 'VA', state_name: 'Virginia' },
  { city: 'Marshall', state: 'VA', state_name: 'Virginia' },
  { city: 'Martinsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Massanetta Springs', state: 'VA', state_name: 'Virginia' },
  { city: 'Massanutten', state: 'VA', state_name: 'Virginia' },
  { city: 'Mathews', state: 'VA', state_name: 'Virginia' },
  { city: 'Mathews County', state: 'VA', state_name: 'Virginia' },
  { city: 'Matoaca', state: 'VA', state_name: 'Virginia' },
  { city: 'McLean', state: 'VA', state_name: 'Virginia' },
  { city: 'Meadowbrook', state: 'VA', state_name: 'Virginia' },
  { city: 'Mechanicsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Mecklenburg County', state: 'VA', state_name: 'Virginia' },
  { city: 'Merrifield', state: 'VA', state_name: 'Virginia' },
  { city: 'Merrimac', state: 'VA', state_name: 'Virginia' },
  { city: 'Middlesex County', state: 'VA', state_name: 'Virginia' },
  { city: 'Middletown', state: 'VA', state_name: 'Virginia' },
  { city: 'Montclair', state: 'VA', state_name: 'Virginia' },
  { city: 'Monterey', state: 'VA', state_name: 'Virginia' },
  { city: 'Montgomery County', state: 'VA', state_name: 'Virginia' },
  { city: 'Montrose', state: 'VA', state_name: 'Virginia' },
  { city: 'Montross', state: 'VA', state_name: 'Virginia' },
  { city: 'Motley', state: 'VA', state_name: 'Virginia' },
  { city: 'Mount Hermon', state: 'VA', state_name: 'Virginia' },
  { city: 'Mount Jackson', state: 'VA', state_name: 'Virginia' },
  { city: 'Mount Vernon', state: 'VA', state_name: 'Virginia' },
  { city: 'Mountain Road', state: 'VA', state_name: 'Virginia' },
  { city: 'Narrows', state: 'VA', state_name: 'Virginia' },
  { city: 'Nellysford', state: 'VA', state_name: 'Virginia' },
  { city: 'Nelson County', state: 'VA', state_name: 'Virginia' },
  { city: 'New Baltimore', state: 'VA', state_name: 'Virginia' },
  { city: 'New Castle', state: 'VA', state_name: 'Virginia' },
  { city: 'New Kent', state: 'VA', state_name: 'Virginia' },
  { city: 'New Kent County', state: 'VA', state_name: 'Virginia' },
  { city: 'New Market', state: 'VA', state_name: 'Virginia' },
  { city: 'Newington', state: 'VA', state_name: 'Virginia' },
  { city: 'Newport News', state: 'VA', state_name: 'Virginia' },
  { city: 'Nokesville', state: 'VA', state_name: 'Virginia' },
  { city: 'Norfolk', state: 'VA', state_name: 'Virginia' },
  { city: 'North Shore', state: 'VA', state_name: 'Virginia' },
  { city: 'North Springfield', state: 'VA', state_name: 'Virginia' },
  { city: 'Northampton County', state: 'VA', state_name: 'Virginia' },
  { city: 'Northumberland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Norton', state: 'VA', state_name: 'Virginia' },
  { city: 'Nottoway County', state: 'VA', state_name: 'Virginia' },
  { city: 'Oak Grove', state: 'VA', state_name: 'Virginia' },
  { city: 'Oak Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Oakton', state: 'VA', state_name: 'Virginia' },
  { city: 'Occoquan', state: 'VA', state_name: 'Virginia' },
  { city: 'Onancock', state: 'VA', state_name: 'Virginia' },
  { city: 'Orange', state: 'VA', state_name: 'Virginia' },
  { city: 'Orange County', state: 'VA', state_name: 'Virginia' },
  { city: 'Page County', state: 'VA', state_name: 'Virginia' },
  { city: 'Palmyra', state: 'VA', state_name: 'Virginia' },
  { city: 'Pannill Fork', state: 'VA', state_name: 'Virginia' },
  { city: 'Pantops', state: 'VA', state_name: 'Virginia' },
  { city: 'Passapatanzy', state: 'VA', state_name: 'Virginia' },
  { city: 'Patrick County', state: 'VA', state_name: 'Virginia' },
  { city: 'Patrick Springs', state: 'VA', state_name: 'Virginia' },
  { city: 'Pearisburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Pembroke', state: 'VA', state_name: 'Virginia' },
  { city: 'Pennington Gap', state: 'VA', state_name: 'Virginia' },
  { city: 'Petersburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Pimmit Hills', state: 'VA', state_name: 'Virginia' },
  { city: 'Pittsylvania County', state: 'VA', state_name: 'Virginia' },
  { city: 'Plum Creek', state: 'VA', state_name: 'Virginia' },
  { city: 'Poquoson', state: 'VA', state_name: 'Virginia' },
  { city: 'Portsmouth', state: 'VA', state_name: 'Virginia' },
  { city: 'Portsmouth Heights', state: 'VA', state_name: 'Virginia' },
  { city: 'Potomac Mills', state: 'VA', state_name: 'Virginia' },
  { city: 'Powhatan County', state: 'VA', state_name: 'Virginia' },
  { city: 'Prices Fork', state: 'VA', state_name: 'Virginia' },
  { city: 'Prince Edward County', state: 'VA', state_name: 'Virginia' },
  { city: 'Prince George', state: 'VA', state_name: 'Virginia' },
  { city: 'Prince George County', state: 'VA', state_name: 'Virginia' },
  { city: 'Prince William County', state: 'VA', state_name: 'Virginia' },
  { city: 'Pulaski', state: 'VA', state_name: 'Virginia' },
  { city: 'Pulaski County', state: 'VA', state_name: 'Virginia' },
  { city: 'Purcellville', state: 'VA', state_name: 'Virginia' },
  { city: 'Quantico Station', state: 'VA', state_name: 'Virginia' },
  { city: 'Radford', state: 'VA', state_name: 'Virginia' },
  { city: 'Rappahannock County', state: 'VA', state_name: 'Virginia' },
  { city: 'Raven', state: 'VA', state_name: 'Virginia' },
  { city: 'Ravensworth', state: 'VA', state_name: 'Virginia' },
  { city: 'Reston', state: 'VA', state_name: 'Virginia' },
  { city: 'Richlands', state: 'VA', state_name: 'Virginia' },
  { city: 'Richmond', state: 'VA', state_name: 'Virginia' },
  { city: 'Richmond County', state: 'VA', state_name: 'Virginia' },
  { city: 'Roanoke', state: 'VA', state_name: 'Virginia' },
  { city: 'Roanoke County', state: 'VA', state_name: 'Virginia' },
  { city: 'Rockbridge County', state: 'VA', state_name: 'Virginia' },
  { city: 'Rockingham County', state: 'VA', state_name: 'Virginia' },
  { city: 'Rocky Mount', state: 'VA', state_name: 'Virginia' },
  { city: 'Rose Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'Rosslyn', state: 'VA', state_name: 'Virginia' },
  { city: 'Ruckersville', state: 'VA', state_name: 'Virginia' },
  { city: 'Rural Retreat', state: 'VA', state_name: 'Virginia' },
  { city: 'Rushmere', state: 'VA', state_name: 'Virginia' },
  { city: 'Russell County', state: 'VA', state_name: 'Virginia' },
  { city: 'Rustburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Salem', state: 'VA', state_name: 'Virginia' },
  { city: 'Saltville', state: 'VA', state_name: 'Virginia' },
  { city: 'Saluda', state: 'VA', state_name: 'Virginia' },
  { city: 'Sandston', state: 'VA', state_name: 'Virginia' },
  { city: 'Scott County', state: 'VA', state_name: 'Virginia' },
  { city: 'Seven Corners', state: 'VA', state_name: 'Virginia' },
  { city: 'Shawnee Land', state: 'VA', state_name: 'Virginia' },
  { city: 'Shawsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Shenandoah', state: 'VA', state_name: 'Virginia' },
  { city: 'Shenandoah County', state: 'VA', state_name: 'Virginia' },
  { city: 'Shenandoah Farms', state: 'VA', state_name: 'Virginia' },
  { city: 'Short Pump', state: 'VA', state_name: 'Virginia' },
  { city: 'Smithfield', state: 'VA', state_name: 'Virginia' },
  { city: 'Smyth County', state: 'VA', state_name: 'Virginia' },
  { city: 'South Boston', state: 'VA', state_name: 'Virginia' },
  { city: 'South Hill', state: 'VA', state_name: 'Virginia' },
  { city: 'South Riding', state: 'VA', state_name: 'Virginia' },
  { city: 'South Suffolk', state: 'VA', state_name: 'Virginia' },
  { city: 'Southampton County', state: 'VA', state_name: 'Virginia' },
  { city: 'Southern Gateway', state: 'VA', state_name: 'Virginia' },
  { city: 'Spotsylvania County', state: 'VA', state_name: 'Virginia' },
  { city: 'Spotsylvania Courthouse', state: 'VA', state_name: 'Virginia' },
  { city: 'Springfield', state: 'VA', state_name: 'Virginia' },
  { city: 'Springville', state: 'VA', state_name: 'Virginia' },
  { city: 'Stafford', state: 'VA', state_name: 'Virginia' },
  { city: 'Stafford County', state: 'VA', state_name: 'Virginia' },
  { city: 'Stanardsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Stanley', state: 'VA', state_name: 'Virginia' },
  { city: 'Stanleytown', state: 'VA', state_name: 'Virginia' },
  { city: 'Staunton', state: 'VA', state_name: 'Virginia' },
  { city: 'Stephens City', state: 'VA', state_name: 'Virginia' },
  { city: 'Sterling', state: 'VA', state_name: 'Virginia' },
  { city: 'Strasburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Stuart', state: 'VA', state_name: 'Virginia' },
  { city: 'Stuarts Draft', state: 'VA', state_name: 'Virginia' },
  { city: 'Sudley', state: 'VA', state_name: 'Virginia' },
  { city: 'Suffolk', state: 'VA', state_name: 'Virginia' },
  { city: 'Sugarland Run', state: 'VA', state_name: 'Virginia' },
  { city: 'Surry', state: 'VA', state_name: 'Virginia' },
  { city: 'Surry County', state: 'VA', state_name: 'Virginia' },
  { city: 'Sussex', state: 'VA', state_name: 'Virginia' },
  { city: 'Sussex County', state: 'VA', state_name: 'Virginia' },
  { city: 'Tappahannock', state: 'VA', state_name: 'Virginia' },
  { city: 'Tazewell', state: 'VA', state_name: 'Virginia' },
  { city: 'Tazewell County', state: 'VA', state_name: 'Virginia' },
  { city: 'Timberlake', state: 'VA', state_name: 'Virginia' },
  { city: 'Timberville', state: 'VA', state_name: 'Virginia' },
  { city: 'Triangle', state: 'VA', state_name: 'Virginia' },
  { city: 'Tuckahoe', state: 'VA', state_name: 'Virginia' },
  { city: 'Twin Lakes', state: 'VA', state_name: 'Virginia' },
  { city: 'Tysons Corner', state: 'VA', state_name: 'Virginia' },
  { city: 'Union Hall', state: 'VA', state_name: 'Virginia' },
  { city: 'University Center', state: 'VA', state_name: 'Virginia' },
  { city: 'Verona', state: 'VA', state_name: 'Virginia' },
  { city: 'Victoria', state: 'VA', state_name: 'Virginia' },
  { city: 'Vienna', state: 'VA', state_name: 'Virginia' },
  { city: 'Vinton', state: 'VA', state_name: 'Virginia' },
  { city: 'Virginia Beach', state: 'VA', state_name: 'Virginia' },
  { city: 'Warm Springs', state: 'VA', state_name: 'Virginia' },
  { city: 'Warren County', state: 'VA', state_name: 'Virginia' },
  { city: 'Warrenton', state: 'VA', state_name: 'Virginia' },
  { city: 'Warsaw', state: 'VA', state_name: 'Virginia' },
  { city: 'Washington', state: 'VA', state_name: 'Virginia' },
  { city: 'Washington County', state: 'VA', state_name: 'Virginia' },
  { city: 'Wattsville', state: 'VA', state_name: 'Virginia' },
  { city: 'Waverly', state: 'VA', state_name: 'Virginia' },
  { city: 'Waynesboro', state: 'VA', state_name: 'Virginia' },
  { city: 'Weber City', state: 'VA', state_name: 'Virginia' },
  { city: 'West Falls Church', state: 'VA', state_name: 'Virginia' },
  { city: 'West Gate', state: 'VA', state_name: 'Virginia' },
  { city: 'West Lynchburg', state: 'VA', state_name: 'Virginia' },
  { city: 'West Point', state: 'VA', state_name: 'Virginia' },
  { city: 'West Springfield', state: 'VA', state_name: 'Virginia' },
  { city: 'Westmoreland County', state: 'VA', state_name: 'Virginia' },
  { city: 'Weyers Cave', state: 'VA', state_name: 'Virginia' },
  { city: 'Williamsburg', state: 'VA', state_name: 'Virginia' },
  { city: 'Winchester', state: 'VA', state_name: 'Virginia' },
  { city: 'Windsor', state: 'VA', state_name: 'Virginia' },
  { city: 'Wise', state: 'VA', state_name: 'Virginia' },
  { city: 'Wise County', state: 'VA', state_name: 'Virginia' },
  { city: 'Wolf Trap', state: 'VA', state_name: 'Virginia' },
  { city: 'Woodbridge', state: 'VA', state_name: 'Virginia' },
  { city: 'Woodburn', state: 'VA', state_name: 'Virginia' },
  { city: 'Woodlake', state: 'VA', state_name: 'Virginia' },
  { city: 'Woodlawn', state: 'VA', state_name: 'Virginia' },
  { city: 'Woodstock', state: 'VA', state_name: 'Virginia' },
  { city: 'Wyndham', state: 'VA', state_name: 'Virginia' },
  { city: 'Wythe County', state: 'VA', state_name: 'Virginia' },
  { city: 'Wytheville', state: 'VA', state_name: 'Virginia' },
  { city: 'York County', state: 'VA', state_name: 'Virginia' },
  { city: 'Yorkshire', state: 'VA', state_name: 'Virginia' },
  { city: 'Yorktown', state: 'VA', state_name: 'Virginia' },
  { city: 'Aberdeen', state: 'WA', state_name: 'Washington' },
  { city: 'Adams County', state: 'WA', state_name: 'Washington' },
  { city: 'Ahtanum', state: 'WA', state_name: 'Washington' },
  { city: 'Airway Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Alderton', state: 'WA', state_name: 'Washington' },
  { city: 'Alderwood Manor', state: 'WA', state_name: 'Washington' },
  { city: 'Algona', state: 'WA', state_name: 'Washington' },
  { city: 'Allyn', state: 'WA', state_name: 'Washington' },
  { city: 'Amboy', state: 'WA', state_name: 'Washington' },
  { city: 'Ames Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Anacortes', state: 'WA', state_name: 'Washington' },
  { city: 'Arlington', state: 'WA', state_name: 'Washington' },
  { city: 'Arlington Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Artondale', state: 'WA', state_name: 'Washington' },
  { city: 'Asotin', state: 'WA', state_name: 'Washington' },
  { city: 'Asotin County', state: 'WA', state_name: 'Washington' },
  { city: 'Auburn', state: 'WA', state_name: 'Washington' },
  { city: 'Ault Field', state: 'WA', state_name: 'Washington' },
  { city: 'Bainbridge Island', state: 'WA', state_name: 'Washington' },
  { city: 'Bangor Trident Base', state: 'WA', state_name: 'Washington' },
  { city: 'Barberton', state: 'WA', state_name: 'Washington' },
  { city: 'Basin City', state: 'WA', state_name: 'Washington' },
  { city: 'Battle Ground', state: 'WA', state_name: 'Washington' },
  { city: 'Belfair', state: 'WA', state_name: 'Washington' },
  { city: 'Bellevue', state: 'WA', state_name: 'Washington' },
  { city: 'Bellingham', state: 'WA', state_name: 'Washington' },
  { city: 'Benton City', state: 'WA', state_name: 'Washington' },
  { city: 'Benton County', state: 'WA', state_name: 'Washington' },
  { city: 'Bethel', state: 'WA', state_name: 'Washington' },
  { city: 'Big Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Birch Bay', state: 'WA', state_name: 'Washington' },
  { city: 'Black Diamond', state: 'WA', state_name: 'Washington' },
  { city: 'Blaine', state: 'WA', state_name: 'Washington' },
  { city: 'Bonney Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Bothell', state: 'WA', state_name: 'Washington' },
  { city: 'Bothell East', state: 'WA', state_name: 'Washington' },
  { city: 'Bothell West', state: 'WA', state_name: 'Washington' },
  { city: 'Boulevard Park', state: 'WA', state_name: 'Washington' },
  { city: 'Bremerton', state: 'WA', state_name: 'Washington' },
  { city: 'Brewster', state: 'WA', state_name: 'Washington' },
  { city: 'Pukalani', state: 'HI', state_name: 'Hawaii' },
  { city: 'Punaluu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Pupukea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Royal Kunia', state: 'HI', state_name: 'Hawaii' },
  { city: 'Schofield Barracks', state: 'HI', state_name: 'Hawaii' },
  { city: 'Village Park', state: 'HI', state_name: 'Hawaii' },
  { city: 'Volcano', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wahiawā', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waialua', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waianae', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waihee-Waiehu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waikapū', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waikoloa', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailua', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailua Homesteads', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wailuku', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimalu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimanalo', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimānalo Beach', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waimea', state: 'HI', state_name: 'Hawaii' },
  { city: 'Wainaku', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipahu', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipi‘o Acres', state: 'HI', state_name: 'Hawaii' },
  { city: 'Waipio', state: 'HI', state_name: 'Hawaii' },
  { city: 'Whitmore Village', state: 'HI', state_name: 'Hawaii' },

  { city: 'Adams County', state: 'IL', state_name: 'Illinois' },
  { city: 'Addison', state: 'IL', state_name: 'Illinois' },
  { city: 'Albany Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Albers', state: 'IL', state_name: 'Illinois' },
  { city: 'Albion', state: 'IL', state_name: 'Illinois' },
  { city: 'Aledo', state: 'IL', state_name: 'Illinois' },
  { city: 'Alexander County', state: 'IL', state_name: 'Illinois' },
  { city: 'Algonquin', state: 'IL', state_name: 'Illinois' },
  { city: 'Alorton', state: 'IL', state_name: 'Illinois' },
  { city: 'Alsip', state: 'IL', state_name: 'Illinois' },
  { city: 'Altamont', state: 'IL', state_name: 'Illinois' },
  { city: 'Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'Amboy', state: 'IL', state_name: 'Illinois' },
  { city: 'Andalusia', state: 'IL', state_name: 'Illinois' },
  { city: 'Anna', state: 'IL', state_name: 'Illinois' },
  { city: 'Antioch', state: 'IL', state_name: 'Illinois' },
  { city: 'Arcola', state: 'IL', state_name: 'Illinois' },
  { city: 'Arlington Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Arthur', state: 'IL', state_name: 'Illinois' },
  { city: 'Ashburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Ashland', state: 'IL', state_name: 'Illinois' },
  { city: 'Assumption', state: 'IL', state_name: 'Illinois' },
  { city: 'Astoria', state: 'IL', state_name: 'Illinois' },
  { city: 'Athens', state: 'IL', state_name: 'Illinois' },
  { city: 'Atlanta', state: 'IL', state_name: 'Illinois' },
  { city: 'Atwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Auburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Auburn Gresham', state: 'IL', state_name: 'Illinois' },
  { city: 'Aurora', state: 'IL', state_name: 'Illinois' },
  { city: 'Aviston', state: 'IL', state_name: 'Illinois' },
  { city: 'Avondale', state: 'IL', state_name: 'Illinois' },
  { city: 'Bannockburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Barrington Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Barry', state: 'IL', state_name: 'Illinois' },
  { city: 'Bartlett', state: 'IL', state_name: 'Illinois' },
  { city: 'Bartonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Batavia', state: 'IL', state_name: 'Illinois' },
  { city: 'Beach Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Beardstown', state: 'IL', state_name: 'Illinois' },
  { city: 'Beckemeyer', state: 'IL', state_name: 'Illinois' },
  { city: 'Beecher', state: 'IL', state_name: 'Illinois' },
  { city: 'Belleville', state: 'IL', state_name: 'Illinois' },
  { city: 'Bellevue', state: 'IL', state_name: 'Illinois' },
  { city: 'Bellwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Belmont Cragin', state: 'IL', state_name: 'Illinois' },
  { city: 'Belvidere', state: 'IL', state_name: 'Illinois' },
  { city: 'Bement', state: 'IL', state_name: 'Illinois' },
  { city: 'Benld', state: 'IL', state_name: 'Illinois' },
  { city: 'Bensenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Benton', state: 'IL', state_name: 'Illinois' },
  { city: 'Berkeley', state: 'IL', state_name: 'Illinois' },
  { city: 'Berwyn', state: 'IL', state_name: 'Illinois' },
  { city: 'Bethalto', state: 'IL', state_name: 'Illinois' },
  { city: 'Bethany', state: 'IL', state_name: 'Illinois' },
  { city: 'Big Rock', state: 'IL', state_name: 'Illinois' },
  { city: 'Bloomingdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Bloomington', state: 'IL', state_name: 'Illinois' },
  { city: 'Blue Island', state: 'IL', state_name: 'Illinois' },
  { city: 'Blue Mound', state: 'IL', state_name: 'Illinois' },
  { city: 'Bolingbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Bond County', state: 'IL', state_name: 'Illinois' },
  { city: 'Boone County', state: 'IL', state_name: 'Illinois' },
  { city: 'Boulder Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Bourbonnais', state: 'IL', state_name: 'Illinois' },
  { city: 'Bradley', state: 'IL', state_name: 'Illinois' },
  { city: 'Braidwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Breese', state: 'IL', state_name: 'Illinois' },
  { city: 'Bridgeport', state: 'IL', state_name: 'Illinois' },
  { city: 'Bridgeview', state: 'IL', state_name: 'Illinois' },
  { city: 'Brighton', state: 'IL', state_name: 'Illinois' },
  { city: 'Brighton Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Broadview', state: 'IL', state_name: 'Illinois' },
  { city: 'Brookfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Brown County', state: 'IL', state_name: 'Illinois' },
  { city: 'Buffalo Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Bull Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Bunker Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Burbank', state: 'IL', state_name: 'Illinois' },
  { city: 'Bureau County', state: 'IL', state_name: 'Illinois' },
  { city: 'Burnham', state: 'IL', state_name: 'Illinois' },
  { city: 'Burr Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Bushnell', state: 'IL', state_name: 'Illinois' },
  { city: 'Byron', state: 'IL', state_name: 'Illinois' },
  { city: 'Cahokia', state: 'IL', state_name: 'Illinois' },
  { city: 'Cairo', state: 'IL', state_name: 'Illinois' },
  { city: 'Calhoun County', state: 'IL', state_name: 'Illinois' },
  { city: 'Calumet City', state: 'IL', state_name: 'Illinois' },
  { city: 'Calumet Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Cambria', state: 'IL', state_name: 'Illinois' },
  { city: 'Cambridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Camp Point', state: 'IL', state_name: 'Illinois' },
  { city: 'Canton', state: 'IL', state_name: 'Illinois' },
  { city: 'Capron', state: 'IL', state_name: 'Illinois' },
  { city: 'Carbon Cliff', state: 'IL', state_name: 'Illinois' },
  { city: 'Carbondale', state: 'IL', state_name: 'Illinois' },
  { city: 'Carlinville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carlyle', state: 'IL', state_name: 'Illinois' },
  { city: 'Carmi', state: 'IL', state_name: 'Illinois' },
  { city: 'Carol Stream', state: 'IL', state_name: 'Illinois' },
  { city: 'Carpentersville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carrier Mills', state: 'IL', state_name: 'Illinois' },
  { city: 'Carroll County', state: 'IL', state_name: 'Illinois' },
  { city: 'Carrollton', state: 'IL', state_name: 'Illinois' },
  { city: 'Carterville', state: 'IL', state_name: 'Illinois' },
  { city: 'Carthage', state: 'IL', state_name: 'Illinois' },
  { city: 'Cary', state: 'IL', state_name: 'Illinois' },
  { city: 'Casey', state: 'IL', state_name: 'Illinois' },
  { city: 'Caseyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Cass County', state: 'IL', state_name: 'Illinois' },
  { city: 'Catlin', state: 'IL', state_name: 'Illinois' },
  { city: 'Central City', state: 'IL', state_name: 'Illinois' },
  { city: 'Centralia', state: 'IL', state_name: 'Illinois' },
  { city: 'Centreville', state: 'IL', state_name: 'Illinois' },
  { city: 'Cerro Gordo', state: 'IL', state_name: 'Illinois' },
  { city: 'Champaign', state: 'IL', state_name: 'Illinois' },
  { city: 'Champaign County', state: 'IL', state_name: 'Illinois' },
  { city: 'Channahon', state: 'IL', state_name: 'Illinois' },
  { city: 'Channel Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Charleston', state: 'IL', state_name: 'Illinois' },
  { city: 'Chatham', state: 'IL', state_name: 'Illinois' },
  { city: 'Chatsworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Chebanse', state: 'IL', state_name: 'Illinois' },
  { city: 'Chenoa', state: 'IL', state_name: 'Illinois' },
  { city: 'Cherry Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Chester', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Loop', state: 'IL', state_name: 'Illinois' },
  { city: 'Chicago Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Chillicothe', state: 'IL', state_name: 'Illinois' },
  { city: 'Chrisman', state: 'IL', state_name: 'Illinois' },
  { city: 'Christian County', state: 'IL', state_name: 'Illinois' },
  { city: 'Christopher', state: 'IL', state_name: 'Illinois' },
  { city: 'Cicero', state: 'IL', state_name: 'Illinois' },
  { city: 'Clarendon Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Clark County', state: 'IL', state_name: 'Illinois' },
  { city: 'Clay County', state: 'IL', state_name: 'Illinois' },
  { city: 'Clifton', state: 'IL', state_name: 'Illinois' },
  { city: 'Clinton', state: 'IL', state_name: 'Illinois' },
  { city: 'Clinton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Coal City', state: 'IL', state_name: 'Illinois' },
  { city: 'Coal Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Cobden', state: 'IL', state_name: 'Illinois' },
  { city: 'Colchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Coles County', state: 'IL', state_name: 'Illinois' },
  { city: 'Colfax', state: 'IL', state_name: 'Illinois' },
  { city: 'Collinsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Colona', state: 'IL', state_name: 'Illinois' },
  { city: 'Columbia', state: 'IL', state_name: 'Illinois' },
  { city: 'Cook County', state: 'IL', state_name: 'Illinois' },
  { city: 'Cortland', state: 'IL', state_name: 'Illinois' },
  { city: 'Country Club Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Countryside', state: 'IL', state_name: 'Illinois' },
  { city: 'Crainville', state: 'IL', state_name: 'Illinois' },
  { city: 'Crawford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Crest Hill', state: 'IL', state_name: 'Illinois' },
  { city: 'Crestwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Crete', state: 'IL', state_name: 'Illinois' },
  { city: 'Creve Coeur', state: 'IL', state_name: 'Illinois' },
  { city: 'Crystal Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Crystal Lawns', state: 'IL', state_name: 'Illinois' },
  { city: 'Cuba', state: 'IL', state_name: 'Illinois' },
  { city: 'Cumberland County', state: 'IL', state_name: 'Illinois' },
  { city: 'Danvers', state: 'IL', state_name: 'Illinois' },
  { city: 'Danville', state: 'IL', state_name: 'Illinois' },
  { city: 'Darien', state: 'IL', state_name: 'Illinois' },
  { city: 'Davis Junction', state: 'IL', state_name: 'Illinois' },
  { city: 'De Soto', state: 'IL', state_name: 'Illinois' },
  { city: 'De Witt County', state: 'IL', state_name: 'Illinois' },
  { city: 'Decatur', state: 'IL', state_name: 'Illinois' },
  { city: 'Deer Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Deerfield', state: 'IL', state_name: 'Illinois' },
  { city: 'DeKalb', state: 'IL', state_name: 'Illinois' },
  { city: 'DeKalb County', state: 'IL', state_name: 'Illinois' },
  { city: 'Delavan', state: 'IL', state_name: 'Illinois' },
  { city: 'Depue', state: 'IL', state_name: 'Illinois' },
  { city: 'Des Plaines', state: 'IL', state_name: 'Illinois' },
  { city: 'Diamond', state: 'IL', state_name: 'Illinois' },
  { city: 'Divernon', state: 'IL', state_name: 'Illinois' },
  { city: 'Dixmoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Dixon', state: 'IL', state_name: 'Illinois' },
  { city: 'Dolton', state: 'IL', state_name: 'Illinois' },
  { city: 'Douglas', state: 'IL', state_name: 'Illinois' },
  { city: 'Douglas County', state: 'IL', state_name: 'Illinois' },
  { city: 'Downers Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Du Quoin', state: 'IL', state_name: 'Illinois' },
  { city: 'Dunlap', state: 'IL', state_name: 'Illinois' },
  { city: 'DuPage County', state: 'IL', state_name: 'Illinois' },
  { city: 'Dupo', state: 'IL', state_name: 'Illinois' },
  { city: 'Durand', state: 'IL', state_name: 'Illinois' },
  { city: 'Dwight', state: 'IL', state_name: 'Illinois' },
  { city: 'Earlville', state: 'IL', state_name: 'Illinois' },
  { city: 'East Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'East Dubuque', state: 'IL', state_name: 'Illinois' },
  { city: 'East Dundee', state: 'IL', state_name: 'Illinois' },
  { city: 'East Garfield Park', state: 'IL', state_name: 'Illinois' },
  { city: 'East Hazel Crest', state: 'IL', state_name: 'Illinois' },
  { city: 'East Moline', state: 'IL', state_name: 'Illinois' },
  { city: 'East Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'East Saint Louis', state: 'IL', state_name: 'Illinois' },
  { city: 'Edgar County', state: 'IL', state_name: 'Illinois' },
  { city: 'Edgewater', state: 'IL', state_name: 'Illinois' },
  { city: 'Edinburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Edwards County', state: 'IL', state_name: 'Illinois' },
  { city: 'Edwardsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Effingham', state: 'IL', state_name: 'Illinois' },
  { city: 'Effingham County', state: 'IL', state_name: 'Illinois' },
  { city: 'El Paso', state: 'IL', state_name: 'Illinois' },
  { city: 'Elburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Eldorado', state: 'IL', state_name: 'Illinois' },
  { city: 'Elgin', state: 'IL', state_name: 'Illinois' },
  { city: 'Elizabethtown', state: 'IL', state_name: 'Illinois' },
  { city: 'Elk Grove Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmhurst', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Elmwood Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Elwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Energy', state: 'IL', state_name: 'Illinois' },
  { city: 'Englewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Erie', state: 'IL', state_name: 'Illinois' },
  { city: 'Eureka', state: 'IL', state_name: 'Illinois' },
  { city: 'Evanston', state: 'IL', state_name: 'Illinois' },
  { city: 'Evergreen Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairbury', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairmont', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairmont City', state: 'IL', state_name: 'Illinois' },
  { city: 'Fairview Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Farmer City', state: 'IL', state_name: 'Illinois' },
  { city: 'Farmington', state: 'IL', state_name: 'Illinois' },
  { city: 'Fayette County', state: 'IL', state_name: 'Illinois' },
  { city: 'Fisher', state: 'IL', state_name: 'Illinois' },
  { city: 'Flanagan', state: 'IL', state_name: 'Illinois' },
  { city: 'Flora', state: 'IL', state_name: 'Illinois' },
  { city: 'Flossmoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Ford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Ford Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Forest Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Forest Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Forrest', state: 'IL', state_name: 'Illinois' },
  { city: 'Forreston', state: 'IL', state_name: 'Illinois' },
  { city: 'Forsyth', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox Lake Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Fox River Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Frankfort', state: 'IL', state_name: 'Illinois' },
  { city: 'Frankfort Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Franklin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Franklin Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Freeburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Freeport', state: 'IL', state_name: 'Illinois' },
  { city: 'Fulton', state: 'IL', state_name: 'Illinois' },
  { city: 'Fulton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Gage Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Gages Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Galena', state: 'IL', state_name: 'Illinois' },
  { city: 'Galesburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Gallatin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Galva', state: 'IL', state_name: 'Illinois' },
  { city: 'Gardner', state: 'IL', state_name: 'Illinois' },
  { city: 'Geneseo', state: 'IL', state_name: 'Illinois' },
  { city: 'Geneva', state: 'IL', state_name: 'Illinois' },
  { city: 'Genoa', state: 'IL', state_name: 'Illinois' },
  { city: 'Georgetown', state: 'IL', state_name: 'Illinois' },
  { city: 'Germantown', state: 'IL', state_name: 'Illinois' },
  { city: 'Germantown Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Gibson City', state: 'IL', state_name: 'Illinois' },
  { city: 'Gifford', state: 'IL', state_name: 'Illinois' },
  { city: 'Gilberts', state: 'IL', state_name: 'Illinois' },
  { city: 'Gillespie', state: 'IL', state_name: 'Illinois' },
  { city: 'Gilman', state: 'IL', state_name: 'Illinois' },
  { city: 'Girard', state: 'IL', state_name: 'Illinois' },
  { city: 'Glasford', state: 'IL', state_name: 'Illinois' },
  { city: 'Glen Carbon', state: 'IL', state_name: 'Illinois' },
  { city: 'Glen Ellyn', state: 'IL', state_name: 'Illinois' },
  { city: 'Glencoe', state: 'IL', state_name: 'Illinois' },
  { city: 'Glendale Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Glenview', state: 'IL', state_name: 'Illinois' },
  { city: 'Glenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Godfrey', state: 'IL', state_name: 'Illinois' },
  { city: 'Golconda', state: 'IL', state_name: 'Illinois' },
  { city: 'Goodings Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Goreville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grand Boulevard', state: 'IL', state_name: 'Illinois' },
  { city: 'Grandview', state: 'IL', state_name: 'Illinois' },
  { city: 'Grandwood Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Granite City', state: 'IL', state_name: 'Illinois' },
  { city: 'Grant Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Granville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grayslake', state: 'IL', state_name: 'Illinois' },
  { city: 'Grayville', state: 'IL', state_name: 'Illinois' },
  { city: 'Greater Grand Crossing', state: 'IL', state_name: 'Illinois' },
  { city: 'Green Oaks', state: 'IL', state_name: 'Illinois' },
  { city: 'Green Rock', state: 'IL', state_name: 'Illinois' },
  { city: 'Greene County', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenup', state: 'IL', state_name: 'Illinois' },
  { city: 'Greenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Gridley', state: 'IL', state_name: 'Illinois' },
  { city: 'Griggsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Grundy County', state: 'IL', state_name: 'Illinois' },
  { city: 'Gurnee', state: 'IL', state_name: 'Illinois' },
  { city: 'Hainesville', state: 'IL', state_name: 'Illinois' },
  { city: 'Hamilton', state: 'IL', state_name: 'Illinois' },
  { city: 'Hamilton County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hampshire', state: 'IL', state_name: 'Illinois' },
  { city: 'Hampton', state: 'IL', state_name: 'Illinois' },
  { city: 'Hancock County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hanna City', state: 'IL', state_name: 'Illinois' },
  { city: 'Hanover Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Hardin', state: 'IL', state_name: 'Illinois' },
  { city: 'Hardin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Harrisburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Harristown', state: 'IL', state_name: 'Illinois' },
  { city: 'Hartford', state: 'IL', state_name: 'Illinois' },
  { city: 'Harvard', state: 'IL', state_name: 'Illinois' },
  { city: 'Harvey', state: 'IL', state_name: 'Illinois' },
  { city: 'Harwood Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Havana', state: 'IL', state_name: 'Illinois' },
  { city: 'Hawthorn Woods', state: 'IL', state_name: 'Illinois' },
  { city: 'Hazel Crest', state: 'IL', state_name: 'Illinois' },
  { city: 'Hebron', state: 'IL', state_name: 'Illinois' },
  { city: 'Henderson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Hennepin', state: 'IL', state_name: 'Illinois' },
  { city: 'Henry', state: 'IL', state_name: 'Illinois' },
  { city: 'Henry County', state: 'IL', state_name: 'Illinois' },
  { city: 'Heritage Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Herrin', state: 'IL', state_name: 'Illinois' },
  { city: 'Herscher', state: 'IL', state_name: 'Illinois' },
  { city: 'Heyworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Hickory Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Highland', state: 'IL', state_name: 'Illinois' },
  { city: 'Highland Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Highwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillcrest', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillsboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Hillside', state: 'IL', state_name: 'Illinois' },
  { city: 'Hinckley', state: 'IL', state_name: 'Illinois' },
  { city: 'Hinsdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Hodgkins', state: 'IL', state_name: 'Illinois' },
  { city: 'Hoffman Estates', state: 'IL', state_name: 'Illinois' },
  { city: 'Holiday Shores', state: 'IL', state_name: 'Illinois' },
  { city: 'Homer', state: 'IL', state_name: 'Illinois' },
  { city: 'Homer Glen', state: 'IL', state_name: 'Illinois' },
  { city: 'Hometown', state: 'IL', state_name: 'Illinois' },
  { city: 'Homewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Hoopeston', state: 'IL', state_name: 'Illinois' },
  { city: 'Hudson', state: 'IL', state_name: 'Illinois' },
  { city: 'Huntley', state: 'IL', state_name: 'Illinois' },
  { city: 'Hyde Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Ina', state: 'IL', state_name: 'Illinois' },
  { city: 'Indian Head Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Ingalls Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Inverness', state: 'IL', state_name: 'Illinois' },
  { city: 'Iroquois County', state: 'IL', state_name: 'Illinois' },
  { city: 'Irving Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Island Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Itasca', state: 'IL', state_name: 'Illinois' },
  { city: 'Jackson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jacksonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Jasper County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jefferson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jerome', state: 'IL', state_name: 'Illinois' },
  { city: 'Jersey County', state: 'IL', state_name: 'Illinois' },
  { city: 'Jerseyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Jo Daviess County', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnsburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Johnston City', state: 'IL', state_name: 'Illinois' },
  { city: 'Joliet', state: 'IL', state_name: 'Illinois' },
  { city: 'Jonesboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Justice', state: 'IL', state_name: 'Illinois' },
  { city: 'Kane County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kankakee', state: 'IL', state_name: 'Illinois' },
  { city: 'Kankakee County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kendall County', state: 'IL', state_name: 'Illinois' },
  { city: 'Kenilworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Kenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Kewanee', state: 'IL', state_name: 'Illinois' },
  { city: 'Kildeer', state: 'IL', state_name: 'Illinois' },
  { city: 'Kincaid', state: 'IL', state_name: 'Illinois' },
  { city: 'Kingston', state: 'IL', state_name: 'Illinois' },
  { city: 'Kirkland', state: 'IL', state_name: 'Illinois' },
  { city: 'Knollwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Knox County', state: 'IL', state_name: 'Illinois' },
  { city: 'Knoxville', state: 'IL', state_name: 'Illinois' },
  { city: 'La Grange', state: 'IL', state_name: 'Illinois' },
  { city: 'La Grange Park', state: 'IL', state_name: 'Illinois' },
  { city: 'La Harpe', state: 'IL', state_name: 'Illinois' },
  { city: 'La Salle', state: 'IL', state_name: 'Illinois' },
  { city: 'Lacon', state: 'IL', state_name: 'Illinois' },
  { city: 'Ladd', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Bluff', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Camelot', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Catherine', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Holiday', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake in the Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake of the Woods', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Summerset', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Villa', state: 'IL', state_name: 'Illinois' },
  { city: 'Lake Zurich', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakemoor', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lakewood Shores', state: 'IL', state_name: 'Illinois' },
  { city: 'Lanark', state: 'IL', state_name: 'Illinois' },
  { city: 'Lansing', state: 'IL', state_name: 'Illinois' },
  { city: 'LaSalle County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lawrence County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lawrenceville', state: 'IL', state_name: 'Illinois' },
  { city: 'Le Roy', state: 'IL', state_name: 'Illinois' },
  { city: 'Lebanon', state: 'IL', state_name: 'Illinois' },
  { city: 'Lee County', state: 'IL', state_name: 'Illinois' },
  { city: 'Leland Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Lemont', state: 'IL', state_name: 'Illinois' },
  { city: 'Lena', state: 'IL', state_name: 'Illinois' },
  { city: 'Lewistown', state: 'IL', state_name: 'Illinois' },
  { city: 'Lexington', state: 'IL', state_name: 'Illinois' },
  { city: 'Libertyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Lily Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Limestone', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincoln Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincolnshire', state: 'IL', state_name: 'Illinois' },
  { city: 'Lincolnwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lindenhurst', state: 'IL', state_name: 'Illinois' },
  { city: 'Lisle', state: 'IL', state_name: 'Illinois' },
  { city: 'Litchfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Livingston County', state: 'IL', state_name: 'Illinois' },
  { city: 'Lockport', state: 'IL', state_name: 'Illinois' },
  { city: 'Logan County', state: 'IL', state_name: 'Illinois' },
  { city: 'Logan Square', state: 'IL', state_name: 'Illinois' },
  { city: 'Lombard', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Creek', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Long Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Louisville', state: 'IL', state_name: 'Illinois' },
  { city: 'Loves Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Lovington', state: 'IL', state_name: 'Illinois' },
  { city: 'Lower West Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Lynwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Lyons', state: 'IL', state_name: 'Illinois' },
  { city: 'Machesney Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Mackinaw', state: 'IL', state_name: 'Illinois' },
  { city: 'Macomb', state: 'IL', state_name: 'Illinois' },
  { city: 'Macon', state: 'IL', state_name: 'Illinois' },
  { city: 'Macon County', state: 'IL', state_name: 'Illinois' },
  { city: 'Macoupin County', state: 'IL', state_name: 'Illinois' },
  { city: 'Madison', state: 'IL', state_name: 'Illinois' },
  { city: 'Madison County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mahomet', state: 'IL', state_name: 'Illinois' },
  { city: 'Malta', state: 'IL', state_name: 'Illinois' },
  { city: 'Manhattan', state: 'IL', state_name: 'Illinois' },
  { city: 'Manito', state: 'IL', state_name: 'Illinois' },
  { city: 'Manteno', state: 'IL', state_name: 'Illinois' },
  { city: 'Maple Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Marengo', state: 'IL', state_name: 'Illinois' },
  { city: 'Marion', state: 'IL', state_name: 'Illinois' },
  { city: 'Marion County', state: 'IL', state_name: 'Illinois' },
  { city: 'Marissa', state: 'IL', state_name: 'Illinois' },
  { city: 'Markham', state: 'IL', state_name: 'Illinois' },
  { city: 'Maroa', state: 'IL', state_name: 'Illinois' },
  { city: 'Marquette Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Marseilles', state: 'IL', state_name: 'Illinois' },
  { city: 'Marshall', state: 'IL', state_name: 'Illinois' },
  { city: 'Marshall County', state: 'IL', state_name: 'Illinois' },
  { city: 'Martinsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Maryville', state: 'IL', state_name: 'Illinois' },
  { city: 'Mascoutah', state: 'IL', state_name: 'Illinois' },
  { city: 'Mason City', state: 'IL', state_name: 'Illinois' },
  { city: 'Mason County', state: 'IL', state_name: 'Illinois' },
  { city: 'Massac County', state: 'IL', state_name: 'Illinois' },
  { city: 'Matteson', state: 'IL', state_name: 'Illinois' },
  { city: 'Mattoon', state: 'IL', state_name: 'Illinois' },
  { city: 'Maywood', state: 'IL', state_name: 'Illinois' },
  { city: 'McCullom Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'McDonough County', state: 'IL', state_name: 'Illinois' },
  { city: 'McHenry', state: 'IL', state_name: 'Illinois' },
  { city: 'McHenry County', state: 'IL', state_name: 'Illinois' },
  { city: 'McKinley Park', state: 'IL', state_name: 'Illinois' },
  { city: 'McLean County', state: 'IL', state_name: 'Illinois' },
  { city: 'McLeansboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Melrose Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Menard County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mendota', state: 'IL', state_name: 'Illinois' },
  { city: 'Mercer County', state: 'IL', state_name: 'Illinois' },
  { city: 'Meredosia', state: 'IL', state_name: 'Illinois' },
  { city: 'Merrionette Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Metamora', state: 'IL', state_name: 'Illinois' },
  { city: 'Metropolis', state: 'IL', state_name: 'Illinois' },
  { city: 'Midlothian', state: 'IL', state_name: 'Illinois' },
  { city: 'Milan', state: 'IL', state_name: 'Illinois' },
  { city: 'Milford', state: 'IL', state_name: 'Illinois' },
  { city: 'Millstadt', state: 'IL', state_name: 'Illinois' },
  { city: 'Minier', state: 'IL', state_name: 'Illinois' },
  { city: 'Minonk', state: 'IL', state_name: 'Illinois' },
  { city: 'Minooka', state: 'IL', state_name: 'Illinois' },
  { city: 'Mitchell', state: 'IL', state_name: 'Illinois' },
  { city: 'Mokena', state: 'IL', state_name: 'Illinois' },
  { city: 'Moline', state: 'IL', state_name: 'Illinois' },
  { city: 'Momence', state: 'IL', state_name: 'Illinois' },
  { city: 'Monee', state: 'IL', state_name: 'Illinois' },
  { city: 'Monmouth', state: 'IL', state_name: 'Illinois' },
  { city: 'Monroe County', state: 'IL', state_name: 'Illinois' },
  { city: 'Montgomery', state: 'IL', state_name: 'Illinois' },
  { city: 'Montgomery County', state: 'IL', state_name: 'Illinois' },
  { city: 'Monticello', state: 'IL', state_name: 'Illinois' },
  { city: 'Morgan County', state: 'IL', state_name: 'Illinois' },
  { city: 'Morgan Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Morris', state: 'IL', state_name: 'Illinois' },
  { city: 'Morrison', state: 'IL', state_name: 'Illinois' },
  { city: 'Morrisonville', state: 'IL', state_name: 'Illinois' },
  { city: 'Morton', state: 'IL', state_name: 'Illinois' },
  { city: 'Morton Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Moultrie County', state: 'IL', state_name: 'Illinois' },
  { city: 'Mound City', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Carmel', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Carroll', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Greenwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Morris', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Olive', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Prospect', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Pulaski', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Sterling', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Vernon', state: 'IL', state_name: 'Illinois' },
  { city: 'Mount Zion', state: 'IL', state_name: 'Illinois' },
  { city: 'Moweaqua', state: 'IL', state_name: 'Illinois' },
  { city: 'Mundelein', state: 'IL', state_name: 'Illinois' },
  { city: 'Murphysboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Naperville', state: 'IL', state_name: 'Illinois' },
  { city: 'Nashville', state: 'IL', state_name: 'Illinois' },
  { city: 'Nauvoo', state: 'IL', state_name: 'Illinois' },
  { city: 'Near North Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Near South Side', state: 'IL', state_name: 'Illinois' },
  { city: 'Neoga', state: 'IL', state_name: 'Illinois' },
  { city: 'New Athens', state: 'IL', state_name: 'Illinois' },
  { city: 'New Baden', state: 'IL', state_name: 'Illinois' },
  { city: 'New Berlin', state: 'IL', state_name: 'Illinois' },
  { city: 'New City', state: 'IL', state_name: 'Illinois' },
  { city: 'New Lenox', state: 'IL', state_name: 'Illinois' },
  { city: 'Newark', state: 'IL', state_name: 'Illinois' },
  { city: 'Newton', state: 'IL', state_name: 'Illinois' },
  { city: 'Niles', state: 'IL', state_name: 'Illinois' },
  { city: 'Nokomis', state: 'IL', state_name: 'Illinois' },
  { city: 'Normal', state: 'IL', state_name: 'Illinois' },
  { city: 'Norridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Norris City', state: 'IL', state_name: 'Illinois' },
  { city: 'North Aurora', state: 'IL', state_name: 'Illinois' },
  { city: 'North Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'North Center', state: 'IL', state_name: 'Illinois' },
  { city: 'North Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'North Lawndale', state: 'IL', state_name: 'Illinois' },
  { city: 'North Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'North Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'North Riverside', state: 'IL', state_name: 'Illinois' },
  { city: 'Northbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Northfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Northlake', state: 'IL', state_name: 'Illinois' },
  { city: "O'Fallon", state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Brook', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Oak Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakbrook Terrace', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Oakwood Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Oblong', state: 'IL', state_name: 'Illinois' },
  { city: 'Odin', state: 'IL', state_name: 'Illinois' },
  { city: 'Ogle County', state: 'IL', state_name: 'Illinois' },
  { city: 'Oglesby', state: 'IL', state_name: 'Illinois' },
  { city: 'Okawville', state: 'IL', state_name: 'Illinois' },
  { city: 'Olney', state: 'IL', state_name: 'Illinois' },
  { city: 'Olympia Fields', state: 'IL', state_name: 'Illinois' },
  { city: 'Onarga', state: 'IL', state_name: 'Illinois' },
  { city: 'Oquawka', state: 'IL', state_name: 'Illinois' },
  { city: 'Oregon', state: 'IL', state_name: 'Illinois' },
  { city: 'Orion', state: 'IL', state_name: 'Illinois' },
  { city: 'Orland Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Orland Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Oswego', state: 'IL', state_name: 'Illinois' },
  { city: 'Ottawa', state: 'IL', state_name: 'Illinois' },
  { city: 'Palatine', state: 'IL', state_name: 'Illinois' },
  { city: 'Palestine', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Palos Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Pana', state: 'IL', state_name: 'Illinois' },
  { city: 'Paris', state: 'IL', state_name: 'Illinois' },
  { city: 'Park City', state: 'IL', state_name: 'Illinois' },
  { city: 'Park Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'Park Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Pawnee', state: 'IL', state_name: 'Illinois' },
  { city: 'Paxton', state: 'IL', state_name: 'Illinois' },
  { city: 'Payson', state: 'IL', state_name: 'Illinois' },
  { city: 'Pecatonica', state: 'IL', state_name: 'Illinois' },
  { city: 'Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria County', state: 'IL', state_name: 'Illinois' },
  { city: 'Peoria Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Peotone', state: 'IL', state_name: 'Illinois' },
  { city: 'Perry County', state: 'IL', state_name: 'Illinois' },
  { city: 'Peru', state: 'IL', state_name: 'Illinois' },
  { city: 'Petersburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Philo', state: 'IL', state_name: 'Illinois' },
  { city: 'Phoenix', state: 'IL', state_name: 'Illinois' },
  { city: 'Piatt County', state: 'IL', state_name: 'Illinois' },
  { city: 'Pike County', state: 'IL', state_name: 'Illinois' },
  { city: 'Pinckneyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Pingree Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Pistakee Highlands', state: 'IL', state_name: 'Illinois' },
  { city: 'Pittsfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Plainfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Plano', state: 'IL', state_name: 'Illinois' },
  { city: 'Polo', state: 'IL', state_name: 'Illinois' },
  { city: 'Pontiac', state: 'IL', state_name: 'Illinois' },
  { city: 'Pontoon Beach', state: 'IL', state_name: 'Illinois' },
  { city: 'Pope County', state: 'IL', state_name: 'Illinois' },
  { city: 'Poplar Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Port Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'Port Byron', state: 'IL', state_name: 'Illinois' },
  { city: 'Portage Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Posen', state: 'IL', state_name: 'Illinois' },
  { city: 'Prairie Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Prestbury', state: 'IL', state_name: 'Illinois' },
  { city: 'Preston Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Princeton', state: 'IL', state_name: 'Illinois' },
  { city: 'Princeville', state: 'IL', state_name: 'Illinois' },
  { city: 'Prophetstown', state: 'IL', state_name: 'Illinois' },
  { city: 'Prospect Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Pulaski County', state: 'IL', state_name: 'Illinois' },
  { city: 'Putnam County', state: 'IL', state_name: 'Illinois' },
  { city: 'Quincy', state: 'IL', state_name: 'Illinois' },
  { city: 'Ramsey', state: 'IL', state_name: 'Illinois' },
  { city: 'Randolph County', state: 'IL', state_name: 'Illinois' },
  { city: 'Rantoul', state: 'IL', state_name: 'Illinois' },
  { city: 'Red Bud', state: 'IL', state_name: 'Illinois' },
  { city: 'Richland County', state: 'IL', state_name: 'Illinois' },
  { city: 'Richmond', state: 'IL', state_name: 'Illinois' },
  { city: 'Richton Park', state: 'IL', state_name: 'Illinois' },
  { city: 'River Forest', state: 'IL', state_name: 'Illinois' },
  { city: 'River Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverside', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverton', state: 'IL', state_name: 'Illinois' },
  { city: 'Riverwoods', state: 'IL', state_name: 'Illinois' },
  { city: 'Roanoke', state: 'IL', state_name: 'Illinois' },
  { city: 'Robbins', state: 'IL', state_name: 'Illinois' },
  { city: 'Robinson', state: 'IL', state_name: 'Illinois' },
  { city: 'Rochelle', state: 'IL', state_name: 'Illinois' },
  { city: 'Rochester', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Falls', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Island', state: 'IL', state_name: 'Illinois' },
  { city: 'Rock Island County', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockdale', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockford', state: 'IL', state_name: 'Illinois' },
  { city: 'Rockton', state: 'IL', state_name: 'Illinois' },
  { city: 'Rogers Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Rolling Meadows', state: 'IL', state_name: 'Illinois' },
  { city: 'Rome', state: 'IL', state_name: 'Illinois' },
  { city: 'Romeoville', state: 'IL', state_name: 'Illinois' },
  { city: 'Roodhouse', state: 'IL', state_name: 'Illinois' },
  { city: 'Roscoe', state: 'IL', state_name: 'Illinois' },
  { city: 'Roselle', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosemont', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosewood Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Rosiclare', state: 'IL', state_name: 'Illinois' },
  { city: 'Rossville', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Beach', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'Round Lake Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Roxana', state: 'IL', state_name: 'Illinois' },
  { city: 'Royalton', state: 'IL', state_name: 'Illinois' },
  { city: 'Rushville', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Anne', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Charles', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Clair County', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Elmo', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Jacob', state: 'IL', state_name: 'Illinois' },
  { city: 'Saint Joseph', state: 'IL', state_name: 'Illinois' },
  { city: 'Salem', state: 'IL', state_name: 'Illinois' },
  { city: 'Saline County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sandoval', state: 'IL', state_name: 'Illinois' },
  { city: 'Sandwich', state: 'IL', state_name: 'Illinois' },
  { city: 'Sangamon County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sauk Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Savanna', state: 'IL', state_name: 'Illinois' },
  { city: 'Savoy', state: 'IL', state_name: 'Illinois' },
  { city: 'Schaumburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Schiller Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Schuyler County', state: 'IL', state_name: 'Illinois' },
  { city: 'Scott Air Force Base', state: 'IL', state_name: 'Illinois' },
  { city: 'Scott County', state: 'IL', state_name: 'Illinois' },
  { city: 'Seneca', state: 'IL', state_name: 'Illinois' },
  { city: 'Sesser', state: 'IL', state_name: 'Illinois' },
  { city: 'Shawneetown', state: 'IL', state_name: 'Illinois' },
  { city: 'Shelby County', state: 'IL', state_name: 'Illinois' },
  { city: 'Shelbyville', state: 'IL', state_name: 'Illinois' },
  { city: 'Sheldon', state: 'IL', state_name: 'Illinois' },
  { city: 'Sheridan', state: 'IL', state_name: 'Illinois' },
  { city: 'Sherman', state: 'IL', state_name: 'Illinois' },
  { city: 'Shiloh', state: 'IL', state_name: 'Illinois' },
  { city: 'Shorewood', state: 'IL', state_name: 'Illinois' },
  { city: 'Sidney', state: 'IL', state_name: 'Illinois' },
  { city: 'Silvis', state: 'IL', state_name: 'Illinois' },
  { city: 'Skokie', state: 'IL', state_name: 'Illinois' },
  { city: 'Sleepy Hollow', state: 'IL', state_name: 'Illinois' },
  { city: 'Smithton', state: 'IL', state_name: 'Illinois' },
  { city: 'Somonauk', state: 'IL', state_name: 'Illinois' },
  { city: 'South Barrington', state: 'IL', state_name: 'Illinois' },
  { city: 'South Beloit', state: 'IL', state_name: 'Illinois' },
  { city: 'South Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'South Chicago Heights', state: 'IL', state_name: 'Illinois' },
  { city: 'South Elgin', state: 'IL', state_name: 'Illinois' },
  { city: 'South Holland', state: 'IL', state_name: 'Illinois' },
  { city: 'South Jacksonville', state: 'IL', state_name: 'Illinois' },
  { city: 'South Lawndale', state: 'IL', state_name: 'Illinois' },
  { city: 'South Pekin', state: 'IL', state_name: 'Illinois' },
  { city: 'South Roxana', state: 'IL', state_name: 'Illinois' },
  { city: 'South Shore', state: 'IL', state_name: 'Illinois' },
  { city: 'Southern View', state: 'IL', state_name: 'Illinois' },
  { city: 'Sparta', state: 'IL', state_name: 'Illinois' },
  { city: 'Spring Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Spring Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Springfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Stark County', state: 'IL', state_name: 'Illinois' },
  { city: 'Staunton', state: 'IL', state_name: 'Illinois' },
  { city: 'Steeleville', state: 'IL', state_name: 'Illinois' },
  { city: 'Steger', state: 'IL', state_name: 'Illinois' },
  { city: 'Stephenson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Sterling', state: 'IL', state_name: 'Illinois' },
  { city: 'Stickney', state: 'IL', state_name: 'Illinois' },
  { city: 'Stillman Valley', state: 'IL', state_name: 'Illinois' },
  { city: 'Stockton', state: 'IL', state_name: 'Illinois' },
  { city: 'Stone Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Streamwood', state: 'IL', state_name: 'Illinois' },
  { city: 'Streator', state: 'IL', state_name: 'Illinois' },
  { city: 'Sugar Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Sullivan', state: 'IL', state_name: 'Illinois' },
  { city: 'Summit', state: 'IL', state_name: 'Illinois' },
  { city: 'Sumner', state: 'IL', state_name: 'Illinois' },
  { city: 'Swansea', state: 'IL', state_name: 'Illinois' },
  { city: 'Sycamore', state: 'IL', state_name: 'Illinois' },
  { city: 'Taylorville', state: 'IL', state_name: 'Illinois' },
  { city: 'Tazewell County', state: 'IL', state_name: 'Illinois' },
  { city: 'Teutopolis', state: 'IL', state_name: 'Illinois' },
  { city: 'The Galena Territory', state: 'IL', state_name: 'Illinois' },
  { city: 'Third Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Thomasboro', state: 'IL', state_name: 'Illinois' },
  { city: 'Thornton', state: 'IL', state_name: 'Illinois' },
  { city: 'Tilton', state: 'IL', state_name: 'Illinois' },
  { city: 'Tinley Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Toledo', state: 'IL', state_name: 'Illinois' },
  { city: 'Tolono', state: 'IL', state_name: 'Illinois' },
  { city: 'Toluca', state: 'IL', state_name: 'Illinois' },
  { city: 'Toulon', state: 'IL', state_name: 'Illinois' },
  { city: 'Tower Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Tremont', state: 'IL', state_name: 'Illinois' },
  { city: 'Trenton', state: 'IL', state_name: 'Illinois' },
  { city: 'Troy', state: 'IL', state_name: 'Illinois' },
  { city: 'Tuscola', state: 'IL', state_name: 'Illinois' },
  { city: 'Twin Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Union County', state: 'IL', state_name: 'Illinois' },
  { city: 'University Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Upper Alton', state: 'IL', state_name: 'Illinois' },
  { city: 'Uptown', state: 'IL', state_name: 'Illinois' },
  { city: 'Urbana', state: 'IL', state_name: 'Illinois' },
  { city: 'Valmeyer', state: 'IL', state_name: 'Illinois' },
  { city: 'Vandalia', state: 'IL', state_name: 'Illinois' },
  { city: 'Venetian Village', state: 'IL', state_name: 'Illinois' },
  { city: 'Venice', state: 'IL', state_name: 'Illinois' },
  { city: 'Vermilion County', state: 'IL', state_name: 'Illinois' },
  { city: 'Vernon Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Vienna', state: 'IL', state_name: 'Illinois' },
  { city: 'Villa Grove', state: 'IL', state_name: 'Illinois' },
  { city: 'Villa Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Village of Campton Hills', state: 'IL', state_name: 'Illinois' },
  { city: 'Virden', state: 'IL', state_name: 'Illinois' },
  { city: 'Virginia', state: 'IL', state_name: 'Illinois' },
  { city: 'Volo', state: 'IL', state_name: 'Illinois' },
  { city: 'Wabash County', state: 'IL', state_name: 'Illinois' },
  { city: 'Wadsworth', state: 'IL', state_name: 'Illinois' },
  { city: 'Walnut', state: 'IL', state_name: 'Illinois' },
  { city: 'Wamac', state: 'IL', state_name: 'Illinois' },
  { city: 'Warren', state: 'IL', state_name: 'Illinois' },
  { city: 'Warren County', state: 'IL', state_name: 'Illinois' },
  { city: 'Warrensburg', state: 'IL', state_name: 'Illinois' },
  { city: 'Warrenville', state: 'IL', state_name: 'Illinois' },
  { city: 'Warsaw', state: 'IL', state_name: 'Illinois' },
  { city: 'Wasco', state: 'IL', state_name: 'Illinois' },
  { city: 'Washburn', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington County', state: 'IL', state_name: 'Illinois' },
  { city: 'Washington Park', state: 'IL', state_name: 'Illinois' },
  { city: 'Waterloo', state: 'IL', state_name: 'Illinois' },
  { city: 'Waterman', state: 'IL', state_name: 'Illinois' },
  { city: 'Watseka', state: 'IL', state_name: 'Illinois' },
  { city: 'Wauconda', state: 'IL', state_name: 'Illinois' },
  { city: 'Waukegan', state: 'IL', state_name: 'Illinois' },
  { city: 'Waverly', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne City', state: 'IL', state_name: 'Illinois' },
  { city: 'Wayne County', state: 'IL', state_name: 'Illinois' },
  { city: 'West Chicago', state: 'IL', state_name: 'Illinois' },
  { city: 'West Dundee', state: 'IL', state_name: 'Illinois' },
  { city: 'West Elsdon', state: 'IL', state_name: 'Illinois' },
  { city: 'West Englewood', state: 'IL', state_name: 'Illinois' },
  { city: 'West Frankfort', state: 'IL', state_name: 'Illinois' },
  { city: 'West Garfield Park', state: 'IL', state_name: 'Illinois' },
  { city: 'West Lawn', state: 'IL', state_name: 'Illinois' },
  { city: 'West Peoria', state: 'IL', state_name: 'Illinois' },
  { city: 'West Ridge', state: 'IL', state_name: 'Illinois' },
  { city: 'West Town', state: 'IL', state_name: 'Illinois' },
  { city: 'Westchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Western Springs', state: 'IL', state_name: 'Illinois' },
  { city: 'Westmont', state: 'IL', state_name: 'Illinois' },
  { city: 'Westville', state: 'IL', state_name: 'Illinois' },
  { city: 'Wheaton', state: 'IL', state_name: 'Illinois' },
  { city: 'Wheeling', state: 'IL', state_name: 'Illinois' },
  { city: 'White County', state: 'IL', state_name: 'Illinois' },
  { city: 'White Hall', state: 'IL', state_name: 'Illinois' },
  { city: 'Whiteside County', state: 'IL', state_name: 'Illinois' },
  { city: 'Will County', state: 'IL', state_name: 'Illinois' },
  { city: 'Williamson County', state: 'IL', state_name: 'Illinois' },
  { city: 'Williamsville', state: 'IL', state_name: 'Illinois' },
  { city: 'Willow Springs', state: 'IL', state_name: 'Illinois' },
  { city: 'Willowbrook', state: 'IL', state_name: 'Illinois' },
  { city: 'Wilmette', state: 'IL', state_name: 'Illinois' },
  { city: 'Wilmington', state: 'IL', state_name: 'Illinois' },
  { city: 'Winchester', state: 'IL', state_name: 'Illinois' },
  { city: 'Windsor', state: 'IL', state_name: 'Illinois' },
  { city: 'Winfield', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnebago', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnebago County', state: 'IL', state_name: 'Illinois' },
  { city: 'Winnetka', state: 'IL', state_name: 'Illinois' },
  { city: 'Winthrop Harbor', state: 'IL', state_name: 'Illinois' },
  { city: 'Wonder Lake', state: 'IL', state_name: 'Illinois' },
  { city: 'Wood Dale', state: 'IL', state_name: 'Illinois' },
  { city: 'Wood River', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodford County', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodlawn', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodridge', state: 'IL', state_name: 'Illinois' },
  { city: 'Woodstock', state: 'IL', state_name: 'Illinois' },
  { city: 'Worden', state: 'IL', state_name: 'Illinois' },
  { city: 'Worth', state: 'IL', state_name: 'Illinois' },
  { city: 'Wyoming', state: 'IL', state_name: 'Illinois' },
  { city: 'Yorkville', state: 'IL', state_name: 'Illinois' },
  { city: 'Zeigler', state: 'IL', state_name: 'Illinois' },
  { city: 'Zion', state: 'IL', state_name: 'Illinois' },
  { city: 'Aberdeen', state: 'IN', state_name: 'Indiana' },
  { city: 'Adams County', state: 'IN', state_name: 'Indiana' },
  { city: 'Akron', state: 'IN', state_name: 'Indiana' },
  { city: 'Albany', state: 'IN', state_name: 'Indiana' },
  { city: 'Albion', state: 'IN', state_name: 'Indiana' },
  { city: 'Alexandria', state: 'IN', state_name: 'Indiana' },
  { city: 'Allen County', state: 'IN', state_name: 'Indiana' },
  { city: 'Anderson', state: 'IN', state_name: 'Indiana' },
  { city: 'Andrews', state: 'IN', state_name: 'Indiana' },
  { city: 'Angola', state: 'IN', state_name: 'Indiana' },
  { city: 'Arcadia', state: 'IN', state_name: 'Indiana' },
  { city: 'Argos', state: 'IN', state_name: 'Indiana' },
  { city: 'Attica', state: 'IN', state_name: 'Indiana' },
  { city: 'Auburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Aurora', state: 'IN', state_name: 'Indiana' },
  { city: 'Austin', state: 'IN', state_name: 'Indiana' },
  { city: 'Avilla', state: 'IN', state_name: 'Indiana' },
  { city: 'Avon', state: 'IN', state_name: 'Indiana' },
  { city: 'Bargersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Bartholomew County', state: 'IN', state_name: 'Indiana' },
  { city: 'Bass Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Batesville', state: 'IN', state_name: 'Indiana' },
  { city: 'Battle Ground', state: 'IN', state_name: 'Indiana' },
  { city: 'Bedford', state: 'IN', state_name: 'Indiana' },
  { city: 'Beech Grove', state: 'IN', state_name: 'Indiana' },
  { city: 'Benton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Berne', state: 'IN', state_name: 'Indiana' },
  { city: 'Bicknell', state: 'IN', state_name: 'Indiana' },
  { city: 'Blackford County', state: 'IN', state_name: 'Indiana' },
  { city: 'Bloomfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Bloomington', state: 'IN', state_name: 'Indiana' },
  { city: 'Bluffton', state: 'IN', state_name: 'Indiana' },
  { city: 'Boone County', state: 'IN', state_name: 'Indiana' },
  { city: 'Boonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Bourbon', state: 'IN', state_name: 'Indiana' },
  { city: 'Brazil', state: 'IN', state_name: 'Indiana' },
  { city: 'Bremen', state: 'IN', state_name: 'Indiana' },
  { city: 'Bright', state: 'IN', state_name: 'Indiana' },
  { city: 'Bristol', state: 'IN', state_name: 'Indiana' },
  { city: 'Broad Ripple', state: 'IN', state_name: 'Indiana' },
  { city: 'Brooklyn', state: 'IN', state_name: 'Indiana' },
  { city: 'Brookston', state: 'IN', state_name: 'Indiana' },
  { city: 'Brookville', state: 'IN', state_name: 'Indiana' },
  { city: 'Brown County', state: 'IN', state_name: 'Indiana' },
  { city: 'Brownsburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Brownstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Burns Harbor', state: 'IN', state_name: 'Indiana' },
  { city: 'Butler', state: 'IN', state_name: 'Indiana' },
  { city: 'Cambridge City', state: 'IN', state_name: 'Indiana' },
  { city: 'Cannelton', state: 'IN', state_name: 'Indiana' },
  { city: 'Carmel', state: 'IN', state_name: 'Indiana' },
  { city: 'Carroll County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cass County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cayuga', state: 'IN', state_name: 'Indiana' },
  { city: 'Cedar Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Centerville', state: 'IN', state_name: 'Indiana' },
  { city: 'Chandler', state: 'IN', state_name: 'Indiana' },
  { city: 'Charlestown', state: 'IN', state_name: 'Indiana' },
  { city: 'Chesterfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Chesterton', state: 'IN', state_name: 'Indiana' },
  { city: 'Churubusco', state: 'IN', state_name: 'Indiana' },
  { city: 'Cicero', state: 'IN', state_name: 'Indiana' },
  { city: 'Clark County', state: 'IN', state_name: 'Indiana' },
  { city: 'Clarksville', state: 'IN', state_name: 'Indiana' },
  { city: 'Clay County', state: 'IN', state_name: 'Indiana' },
  { city: 'Clermont', state: 'IN', state_name: 'Indiana' },
  { city: 'Clinton', state: 'IN', state_name: 'Indiana' },
  { city: 'Clinton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Cloverdale', state: 'IN', state_name: 'Indiana' },
  { city: 'Columbia City', state: 'IN', state_name: 'Indiana' },
  { city: 'Columbus', state: 'IN', state_name: 'Indiana' },
  { city: 'Connersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Converse', state: 'IN', state_name: 'Indiana' },
  { city: 'Cordry Sweetwater Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Corydon', state: 'IN', state_name: 'Indiana' },
  { city: 'Country Squire Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Covington', state: 'IN', state_name: 'Indiana' },
  { city: 'Crawford County', state: 'IN', state_name: 'Indiana' },
  { city: 'Crawfordsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Crothersville', state: 'IN', state_name: 'Indiana' },
  { city: 'Crown Point', state: 'IN', state_name: 'Indiana' },
  { city: 'Culver', state: 'IN', state_name: 'Indiana' },
  { city: 'Cumberland', state: 'IN', state_name: 'Indiana' },
  { city: 'Dale', state: 'IN', state_name: 'Indiana' },
  { city: 'Daleville', state: 'IN', state_name: 'Indiana' },
  { city: 'Danville', state: 'IN', state_name: 'Indiana' },
  { city: 'Darmstadt', state: 'IN', state_name: 'Indiana' },
  { city: 'Daviess County', state: 'IN', state_name: 'Indiana' },
  { city: 'Dayton', state: 'IN', state_name: 'Indiana' },
  { city: 'Dearborn County', state: 'IN', state_name: 'Indiana' },
  { city: 'Decatur', state: 'IN', state_name: 'Indiana' },
  { city: 'Decatur County', state: 'IN', state_name: 'Indiana' },
  { city: 'DeKalb County', state: 'IN', state_name: 'Indiana' },
  { city: 'Delaware County', state: 'IN', state_name: 'Indiana' },
  { city: 'Delphi', state: 'IN', state_name: 'Indiana' },
  { city: 'DeMotte', state: 'IN', state_name: 'Indiana' },
  { city: 'Dillsboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Dubois County', state: 'IN', state_name: 'Indiana' },
  { city: 'Dunkirk', state: 'IN', state_name: 'Indiana' },
  { city: 'Dunlap', state: 'IN', state_name: 'Indiana' },
  { city: 'Dyer', state: 'IN', state_name: 'Indiana' },
  { city: 'East Chicago', state: 'IN', state_name: 'Indiana' },
  { city: 'Eaton', state: 'IN', state_name: 'Indiana' },
  { city: 'Edgewood', state: 'IN', state_name: 'Indiana' },
  { city: 'Edinburgh', state: 'IN', state_name: 'Indiana' },
  { city: 'Elkhart', state: 'IN', state_name: 'Indiana' },
  { city: 'Elkhart County', state: 'IN', state_name: 'Indiana' },
  { city: 'Ellettsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Elwood', state: 'IN', state_name: 'Indiana' },
  { city: 'English', state: 'IN', state_name: 'Indiana' },
  { city: 'Evansville', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairfield Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairmount', state: 'IN', state_name: 'Indiana' },
  { city: 'Fairview Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Farmersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Farmland', state: 'IN', state_name: 'Indiana' },
  { city: 'Fayette County', state: 'IN', state_name: 'Indiana' },
  { city: 'Ferdinand', state: 'IN', state_name: 'Indiana' },
  { city: 'Fish Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Fishers', state: 'IN', state_name: 'Indiana' },
  { city: 'Flora', state: 'IN', state_name: 'Indiana' },
  { city: 'Floyd County', state: 'IN', state_name: 'Indiana' },
  { city: 'Fort Branch', state: 'IN', state_name: 'Indiana' },
  { city: 'Fort Wayne', state: 'IN', state_name: 'Indiana' },
  { city: 'Fortville', state: 'IN', state_name: 'Indiana' },
  { city: 'Fountain County', state: 'IN', state_name: 'Indiana' },
  { city: 'Fowler', state: 'IN', state_name: 'Indiana' },
  { city: 'Frankfort', state: 'IN', state_name: 'Indiana' },
  { city: 'Franklin', state: 'IN', state_name: 'Indiana' },
  { city: 'Franklin County', state: 'IN', state_name: 'Indiana' },
  { city: 'Frankton', state: 'IN', state_name: 'Indiana' },
  { city: 'Fremont', state: 'IN', state_name: 'Indiana' },
  { city: 'French Lick', state: 'IN', state_name: 'Indiana' },
  { city: 'Fulton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Galena', state: 'IN', state_name: 'Indiana' },
  { city: 'Galveston', state: 'IN', state_name: 'Indiana' },
  { city: 'Garrett', state: 'IN', state_name: 'Indiana' },
  { city: 'Gary', state: 'IN', state_name: 'Indiana' },
  { city: 'Gas City', state: 'IN', state_name: 'Indiana' },
  { city: 'Geneva', state: 'IN', state_name: 'Indiana' },
  { city: 'Georgetown', state: 'IN', state_name: 'Indiana' },
  { city: 'Gibson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Goodland', state: 'IN', state_name: 'Indiana' },
  { city: 'Goshen', state: 'IN', state_name: 'Indiana' },
  { city: 'Grabill', state: 'IN', state_name: 'Indiana' },
  { city: 'Granger', state: 'IN', state_name: 'Indiana' },
  { city: 'Grant County', state: 'IN', state_name: 'Indiana' },
  { city: 'Greencastle', state: 'IN', state_name: 'Indiana' },
  { city: 'Greendale', state: 'IN', state_name: 'Indiana' },
  { city: 'Greene County', state: 'IN', state_name: 'Indiana' },
  { city: 'Greenfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Greensburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Greentown', state: 'IN', state_name: 'Indiana' },
  { city: 'Greenwood', state: 'IN', state_name: 'Indiana' },
  { city: 'Griffith', state: 'IN', state_name: 'Indiana' },
  { city: 'Grissom Air Force Base', state: 'IN', state_name: 'Indiana' },
  { city: 'Gulivoire Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Hagerstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Hamilton', state: 'IN', state_name: 'Indiana' },
  { city: 'Hamilton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hammond', state: 'IN', state_name: 'Indiana' },
  { city: 'Hancock County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hanover', state: 'IN', state_name: 'Indiana' },
  { city: 'Harlan', state: 'IN', state_name: 'Indiana' },
  { city: 'Harrison County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hartford City', state: 'IN', state_name: 'Indiana' },
  { city: 'Haubstadt', state: 'IN', state_name: 'Indiana' },
  { city: 'Hebron', state: 'IN', state_name: 'Indiana' },
  { city: 'Hendricks County', state: 'IN', state_name: 'Indiana' },
  { city: 'Henry County', state: 'IN', state_name: 'Indiana' },
  { city: 'Henryville', state: 'IN', state_name: 'Indiana' },
  { city: 'Heritage Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Hidden Valley', state: 'IN', state_name: 'Indiana' },
  { city: 'Highland', state: 'IN', state_name: 'Indiana' },
  { city: 'Hobart', state: 'IN', state_name: 'Indiana' },
  { city: 'Hope', state: 'IN', state_name: 'Indiana' },
  { city: 'Howard County', state: 'IN', state_name: 'Indiana' },
  { city: 'Hudson Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntertown', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntingburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntington', state: 'IN', state_name: 'Indiana' },
  { city: 'Huntington County', state: 'IN', state_name: 'Indiana' },
  { city: 'Indian Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Indianapolis', state: 'IN', state_name: 'Indiana' },
  { city: 'Ingalls', state: 'IN', state_name: 'Indiana' },
  { city: 'Jackson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasper', state: 'IN', state_name: 'Indiana' },
  { city: 'Jasper County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jay County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jefferson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jeffersonville', state: 'IN', state_name: 'Indiana' },
  { city: 'Jennings County', state: 'IN', state_name: 'Indiana' },
  { city: 'Johnson County', state: 'IN', state_name: 'Indiana' },
  { city: 'Jonesboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Kendallville', state: 'IN', state_name: 'Indiana' },
  { city: 'Kentland', state: 'IN', state_name: 'Indiana' },
  { city: 'Kingsford Heights', state: 'IN', state_name: 'Indiana' },
  { city: 'Knightstown', state: 'IN', state_name: 'Indiana' },
  { city: 'Knox', state: 'IN', state_name: 'Indiana' },
  { city: 'Knox County', state: 'IN', state_name: 'Indiana' },
  { city: 'Kokomo', state: 'IN', state_name: 'Indiana' },
  { city: 'Koontz Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Kosciusko County', state: 'IN', state_name: 'Indiana' },
  { city: 'Kouts', state: 'IN', state_name: 'Indiana' },
  { city: 'La Porte', state: 'IN', state_name: 'Indiana' },
  { city: 'Lafayette', state: 'IN', state_name: 'Indiana' },
  { city: 'Lagrange', state: 'IN', state_name: 'Indiana' },
  { city: 'LaGrange County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake Dalecarlia', state: 'IN', state_name: 'Indiana' },
  { city: 'Lake Station', state: 'IN', state_name: 'Indiana' },
  { city: 'Lakes of the Four Seasons', state: 'IN', state_name: 'Indiana' },
  { city: 'Lapel', state: 'IN', state_name: 'Indiana' },
  { city: 'LaPorte', state: 'IN', state_name: 'Indiana' },
  { city: 'LaPorte County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrence', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrence County', state: 'IN', state_name: 'Indiana' },
  { city: 'Lawrenceburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Lebanon', state: 'IN', state_name: 'Indiana' },
  { city: 'Leo-Cedarville', state: 'IN', state_name: 'Indiana' },
  { city: 'Liberty', state: 'IN', state_name: 'Indiana' },
  { city: 'Ligonier', state: 'IN', state_name: 'Indiana' },
  { city: 'Linton', state: 'IN', state_name: 'Indiana' },
  { city: 'Logansport', state: 'IN', state_name: 'Indiana' },
  { city: 'Long Beach', state: 'IN', state_name: 'Indiana' },
  { city: 'Loogootee', state: 'IN', state_name: 'Indiana' },
  { city: 'Lowell', state: 'IN', state_name: 'Indiana' },
  { city: 'Lynn', state: 'IN', state_name: 'Indiana' },
  { city: 'Madison', state: 'IN', state_name: 'Indiana' },
  { city: 'Madison County', state: 'IN', state_name: 'Indiana' },
  { city: 'Marion', state: 'IN', state_name: 'Indiana' },
  { city: 'Marion County', state: 'IN', state_name: 'Indiana' },
  { city: 'Markle', state: 'IN', state_name: 'Indiana' },
  { city: 'Marshall County', state: 'IN', state_name: 'Indiana' },
  { city: 'Martin County', state: 'IN', state_name: 'Indiana' },
  { city: 'Martinsville', state: 'IN', state_name: 'Indiana' },
  { city: 'McCordsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Melody Hill', state: 'IN', state_name: 'Indiana' },
  { city: 'Meridian Hills', state: 'IN', state_name: 'Indiana' },
  { city: 'Merrillville', state: 'IN', state_name: 'Indiana' },
  { city: 'Miami County', state: 'IN', state_name: 'Indiana' },
  { city: 'Michigan City', state: 'IN', state_name: 'Indiana' },
  { city: 'Middlebury', state: 'IN', state_name: 'Indiana' },
  { city: 'Middletown', state: 'IN', state_name: 'Indiana' },
  { city: 'Milan', state: 'IN', state_name: 'Indiana' },
  { city: 'Milford', state: 'IN', state_name: 'Indiana' },
  { city: 'Mishawaka', state: 'IN', state_name: 'Indiana' },
  { city: 'Mitchell', state: 'IN', state_name: 'Indiana' },
  { city: 'Monon', state: 'IN', state_name: 'Indiana' },
  { city: 'Monroe County', state: 'IN', state_name: 'Indiana' },
  { city: 'Monroeville', state: 'IN', state_name: 'Indiana' },
  { city: 'Monrovia', state: 'IN', state_name: 'Indiana' },
  { city: 'Montgomery County', state: 'IN', state_name: 'Indiana' },
  { city: 'Monticello', state: 'IN', state_name: 'Indiana' },
  { city: 'Montpelier', state: 'IN', state_name: 'Indiana' },
  { city: 'Mooresville', state: 'IN', state_name: 'Indiana' },
  { city: 'Morgan County', state: 'IN', state_name: 'Indiana' },
  { city: 'Morocco', state: 'IN', state_name: 'Indiana' },
  { city: 'Morristown', state: 'IN', state_name: 'Indiana' },
  { city: 'Mount Vernon', state: 'IN', state_name: 'Indiana' },
  { city: 'Mulberry', state: 'IN', state_name: 'Indiana' },
  { city: 'Muncie', state: 'IN', state_name: 'Indiana' },
  { city: 'Munster', state: 'IN', state_name: 'Indiana' },
  { city: 'Nappanee', state: 'IN', state_name: 'Indiana' },
  { city: 'Nashville', state: 'IN', state_name: 'Indiana' },
  { city: 'New Albany', state: 'IN', state_name: 'Indiana' },
  { city: 'New Carlisle', state: 'IN', state_name: 'Indiana' },
  { city: 'New Castle', state: 'IN', state_name: 'Indiana' },
  { city: 'New Chicago', state: 'IN', state_name: 'Indiana' },
  { city: 'New Haven', state: 'IN', state_name: 'Indiana' },
  { city: 'New Palestine', state: 'IN', state_name: 'Indiana' },
  { city: 'New Paris', state: 'IN', state_name: 'Indiana' },
  { city: 'New Pekin', state: 'IN', state_name: 'Indiana' },
  { city: 'New Whiteland', state: 'IN', state_name: 'Indiana' },
  { city: 'Newburgh', state: 'IN', state_name: 'Indiana' },
  { city: 'Newport', state: 'IN', state_name: 'Indiana' },
  { city: 'Newton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Noble County', state: 'IN', state_name: 'Indiana' },
  { city: 'Noblesville', state: 'IN', state_name: 'Indiana' },
  { city: 'North Judson', state: 'IN', state_name: 'Indiana' },
  { city: 'North Liberty', state: 'IN', state_name: 'Indiana' },
  { city: 'North Madison', state: 'IN', state_name: 'Indiana' },
  { city: 'North Manchester', state: 'IN', state_name: 'Indiana' },
  { city: 'North Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'North Vernon', state: 'IN', state_name: 'Indiana' },
  { city: 'North Webster', state: 'IN', state_name: 'Indiana' },
  { city: 'Notre Dame', state: 'IN', state_name: 'Indiana' },
  { city: 'Oak Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Oakland City', state: 'IN', state_name: 'Indiana' },
  { city: 'Odon', state: 'IN', state_name: 'Indiana' },
  { city: 'Ogden Dunes', state: 'IN', state_name: 'Indiana' },
  { city: 'Ohio County', state: 'IN', state_name: 'Indiana' },
  { city: 'Oolitic', state: 'IN', state_name: 'Indiana' },
  { city: 'Orange County', state: 'IN', state_name: 'Indiana' },
  { city: 'Orleans', state: 'IN', state_name: 'Indiana' },
  { city: 'Osceola', state: 'IN', state_name: 'Indiana' },
  { city: 'Osgood', state: 'IN', state_name: 'Indiana' },
  { city: 'Ossian', state: 'IN', state_name: 'Indiana' },
  { city: 'Otterbein', state: 'IN', state_name: 'Indiana' },
  { city: 'Owen County', state: 'IN', state_name: 'Indiana' },
  { city: 'Owensville', state: 'IN', state_name: 'Indiana' },
  { city: 'Oxford', state: 'IN', state_name: 'Indiana' },
  { city: 'Paoli', state: 'IN', state_name: 'Indiana' },
  { city: 'Parke County', state: 'IN', state_name: 'Indiana' },
  { city: 'Parker City', state: 'IN', state_name: 'Indiana' },
  { city: 'Pendleton', state: 'IN', state_name: 'Indiana' },
  { city: 'Perry County', state: 'IN', state_name: 'Indiana' },
  { city: 'Peru', state: 'IN', state_name: 'Indiana' },
  { city: 'Petersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Pierceton', state: 'IN', state_name: 'Indiana' },
  { city: 'Pike County', state: 'IN', state_name: 'Indiana' },
  { city: 'Pittsboro', state: 'IN', state_name: 'Indiana' },
  { city: 'Plainfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Plymouth', state: 'IN', state_name: 'Indiana' },
  { city: 'Portage', state: 'IN', state_name: 'Indiana' },
  { city: 'Porter', state: 'IN', state_name: 'Indiana' },
  { city: 'Porter County', state: 'IN', state_name: 'Indiana' },
  { city: 'Portland', state: 'IN', state_name: 'Indiana' },
  { city: 'Posey County', state: 'IN', state_name: 'Indiana' },
  { city: 'Poseyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Princes Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Princeton', state: 'IN', state_name: 'Indiana' },
  { city: 'Pulaski County', state: 'IN', state_name: 'Indiana' },
  { city: 'Putnam County', state: 'IN', state_name: 'Indiana' },
  { city: 'Randolph County', state: 'IN', state_name: 'Indiana' },
  { city: 'Redkey', state: 'IN', state_name: 'Indiana' },
  { city: 'Remington', state: 'IN', state_name: 'Indiana' },
  { city: 'Rensselaer', state: 'IN', state_name: 'Indiana' },
  { city: 'Richmond', state: 'IN', state_name: 'Indiana' },
  { city: 'Ripley County', state: 'IN', state_name: 'Indiana' },
  { city: 'Rising Sun', state: 'IN', state_name: 'Indiana' },
  { city: 'Roanoke', state: 'IN', state_name: 'Indiana' },
  { city: 'Rochester', state: 'IN', state_name: 'Indiana' },
  { city: 'Rockport', state: 'IN', state_name: 'Indiana' },
  { city: 'Rockville', state: 'IN', state_name: 'Indiana' },
  { city: 'Rome City', state: 'IN', state_name: 'Indiana' },
  { city: 'Roselawn', state: 'IN', state_name: 'Indiana' },
  { city: 'Rossville', state: 'IN', state_name: 'Indiana' },
  { city: 'Rush County', state: 'IN', state_name: 'Indiana' },
  { city: 'Rushville', state: 'IN', state_name: 'Indiana' },
  { city: 'Russiaville', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint John', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint Joseph County', state: 'IN', state_name: 'Indiana' },
  { city: 'Saint Paul', state: 'IN', state_name: 'Indiana' },
  { city: 'Salem', state: 'IN', state_name: 'Indiana' },
  { city: 'Santa Claus', state: 'IN', state_name: 'Indiana' },
  { city: 'Schererville', state: 'IN', state_name: 'Indiana' },
  { city: 'Scott County', state: 'IN', state_name: 'Indiana' },
  { city: 'Scottsburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Seelyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Sellersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Seymour', state: 'IN', state_name: 'Indiana' },
  { city: 'Shadeland', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelby County', state: 'IN', state_name: 'Indiana' },
  { city: 'Shelbyville', state: 'IN', state_name: 'Indiana' },
  { city: 'Sheridan', state: 'IN', state_name: 'Indiana' },
  { city: 'Shoals', state: 'IN', state_name: 'Indiana' },
  { city: 'Shorewood Forest', state: 'IN', state_name: 'Indiana' },
  { city: 'Simonton Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Smithville-Sanders', state: 'IN', state_name: 'Indiana' },
  { city: 'South Bend', state: 'IN', state_name: 'Indiana' },
  { city: 'South Haven', state: 'IN', state_name: 'Indiana' },
  { city: 'South Whitley', state: 'IN', state_name: 'Indiana' },
  { city: 'Southport', state: 'IN', state_name: 'Indiana' },
  { city: 'Speedway', state: 'IN', state_name: 'Indiana' },
  { city: 'Spencer', state: 'IN', state_name: 'Indiana' },
  { city: 'Spencer County', state: 'IN', state_name: 'Indiana' },
  { city: 'Starke County', state: 'IN', state_name: 'Indiana' },
  { city: 'Steuben County', state: 'IN', state_name: 'Indiana' },
  { city: 'Sullivan', state: 'IN', state_name: 'Indiana' },
  { city: 'Sullivan County', state: 'IN', state_name: 'Indiana' },
  { city: 'Sunman', state: 'IN', state_name: 'Indiana' },
  { city: 'Sweetser', state: 'IN', state_name: 'Indiana' },
  { city: 'Switzerland County', state: 'IN', state_name: 'Indiana' },
  { city: 'Syracuse', state: 'IN', state_name: 'Indiana' },
  { city: 'Tell City', state: 'IN', state_name: 'Indiana' },
  { city: 'Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'Thorntown', state: 'IN', state_name: 'Indiana' },
  { city: 'Tippecanoe County', state: 'IN', state_name: 'Indiana' },
  { city: 'Tipton', state: 'IN', state_name: 'Indiana' },
  { city: 'Tipton County', state: 'IN', state_name: 'Indiana' },
  { city: 'Topeka', state: 'IN', state_name: 'Indiana' },
  { city: 'Trafalgar', state: 'IN', state_name: 'Indiana' },
  { city: 'Trail Creek', state: 'IN', state_name: 'Indiana' },
  { city: 'Tri-Lakes', state: 'IN', state_name: 'Indiana' },
  { city: 'Union City', state: 'IN', state_name: 'Indiana' },
  { city: 'Union County', state: 'IN', state_name: 'Indiana' },
  { city: 'Upland', state: 'IN', state_name: 'Indiana' },
  { city: 'Valparaiso', state: 'IN', state_name: 'Indiana' },
  { city: 'Vanderburgh County', state: 'IN', state_name: 'Indiana' },
  { city: 'Veedersburg', state: 'IN', state_name: 'Indiana' },
  { city: 'Vermillion County', state: 'IN', state_name: 'Indiana' },
  { city: 'Versailles', state: 'IN', state_name: 'Indiana' },
  { city: 'Vevay', state: 'IN', state_name: 'Indiana' },
  { city: 'Vigo County', state: 'IN', state_name: 'Indiana' },
  { city: 'Vincennes', state: 'IN', state_name: 'Indiana' },
  { city: 'Wabash', state: 'IN', state_name: 'Indiana' },
  { city: 'Wabash County', state: 'IN', state_name: 'Indiana' },
  { city: 'Wakarusa', state: 'IN', state_name: 'Indiana' },
  { city: 'Walkerton', state: 'IN', state_name: 'Indiana' },
  { city: 'Walton', state: 'IN', state_name: 'Indiana' },
  { city: 'Wanatah', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren County', state: 'IN', state_name: 'Indiana' },
  { city: 'Warren Park', state: 'IN', state_name: 'Indiana' },
  { city: 'Warrick County', state: 'IN', state_name: 'Indiana' },
  { city: 'Warsaw', state: 'IN', state_name: 'Indiana' },
  { city: 'Washington', state: 'IN', state_name: 'Indiana' },
  { city: 'Washington County', state: 'IN', state_name: 'Indiana' },
  { city: 'Waterloo', state: 'IN', state_name: 'Indiana' },
  { city: 'Wayne County', state: 'IN', state_name: 'Indiana' },
  { city: 'Wells County', state: 'IN', state_name: 'Indiana' },
  { city: 'West Lafayette', state: 'IN', state_name: 'Indiana' },
  { city: 'West Terre Haute', state: 'IN', state_name: 'Indiana' },
  { city: 'Westfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Westport', state: 'IN', state_name: 'Indiana' },
  { city: 'Westville', state: 'IN', state_name: 'Indiana' },
  { city: 'White County', state: 'IN', state_name: 'Indiana' },
  { city: 'Whiteland', state: 'IN', state_name: 'Indiana' },
  { city: 'Whitestown', state: 'IN', state_name: 'Indiana' },
  { city: 'Whiting', state: 'IN', state_name: 'Indiana' },
  { city: 'Whitley County', state: 'IN', state_name: 'Indiana' },
  { city: 'Williamsport', state: 'IN', state_name: 'Indiana' },
  { city: 'Winamac', state: 'IN', state_name: 'Indiana' },
  { city: 'Winchester', state: 'IN', state_name: 'Indiana' },
  { city: 'Winfield', state: 'IN', state_name: 'Indiana' },
  { city: 'Winona Lake', state: 'IN', state_name: 'Indiana' },
  { city: 'Wolcottville', state: 'IN', state_name: 'Indiana' },
  { city: 'Woodburn', state: 'IN', state_name: 'Indiana' },
  { city: 'Worthington', state: 'IN', state_name: 'Indiana' },
  { city: 'Yorktown', state: 'IN', state_name: 'Indiana' },
  { city: 'Zionsville', state: 'IN', state_name: 'Indiana' },
  { city: 'Ackley', state: 'IA', state_name: 'Iowa' },
  { city: 'Adair County', state: 'IA', state_name: 'Iowa' },
  { city: 'Adams County', state: 'IA', state_name: 'Iowa' },
  { city: 'Adel', state: 'IA', state_name: 'Iowa' },
  { city: 'Akron', state: 'IA', state_name: 'Iowa' },
  { city: 'Albia', state: 'IA', state_name: 'Iowa' },
  { city: 'Algona', state: 'IA', state_name: 'Iowa' },
  { city: 'Allamakee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Allison', state: 'IA', state_name: 'Iowa' },
  { city: 'Alta', state: 'IA', state_name: 'Iowa' },
  { city: 'Alton', state: 'IA', state_name: 'Iowa' },
  { city: 'Altoona', state: 'IA', state_name: 'Iowa' },
  { city: 'Ames', state: 'IA', state_name: 'Iowa' },
  { city: 'Anamosa', state: 'IA', state_name: 'Iowa' },
  { city: 'Ankeny', state: 'IA', state_name: 'Iowa' },
  { city: 'Aplington', state: 'IA', state_name: 'Iowa' },
  { city: 'Appanoose County', state: 'IA', state_name: 'Iowa' },
  { city: 'Arnolds Park', state: 'IA', state_name: 'Iowa' },
  { city: 'Asbury', state: 'IA', state_name: 'Iowa' },
  { city: 'Atkins', state: 'IA', state_name: 'Iowa' },
  { city: 'Atlantic', state: 'IA', state_name: 'Iowa' },
  { city: 'Audubon', state: 'IA', state_name: 'Iowa' },
  { city: 'Audubon County', state: 'IA', state_name: 'Iowa' },
  { city: 'Avoca', state: 'IA', state_name: 'Iowa' },
  { city: 'Baxter', state: 'IA', state_name: 'Iowa' },
  { city: 'Bedford', state: 'IA', state_name: 'Iowa' },
  { city: 'Belle Plaine', state: 'IA', state_name: 'Iowa' },
  { city: 'Bellevue', state: 'IA', state_name: 'Iowa' },
  { city: 'Belmond', state: 'IA', state_name: 'Iowa' },
  { city: 'Benton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bettendorf', state: 'IA', state_name: 'Iowa' },
  { city: 'Black Hawk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bloomfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Blue Grass', state: 'IA', state_name: 'Iowa' },
  { city: 'Bondurant', state: 'IA', state_name: 'Iowa' },
  { city: 'Boone', state: 'IA', state_name: 'Iowa' },
  { city: 'Boone County', state: 'IA', state_name: 'Iowa' },
  { city: 'Bremer County', state: 'IA', state_name: 'Iowa' },
  { city: 'Britt', state: 'IA', state_name: 'Iowa' },
  { city: 'Brooklyn', state: 'IA', state_name: 'Iowa' },
  { city: 'Buchanan County', state: 'IA', state_name: 'Iowa' },
  { city: 'Buena Vista County', state: 'IA', state_name: 'Iowa' },
  { city: 'Buffalo', state: 'IA', state_name: 'Iowa' },
  { city: 'Buffalo (historical)', state: 'IA', state_name: 'Iowa' },
  { city: 'Burlington', state: 'IA', state_name: 'Iowa' },
  { city: 'Butler County', state: 'IA', state_name: 'Iowa' },
  { city: 'Calhoun County', state: 'IA', state_name: 'Iowa' },
  { city: 'Camanche', state: 'IA', state_name: 'Iowa' },
  { city: 'Carlisle', state: 'IA', state_name: 'Iowa' },
  { city: 'Carroll', state: 'IA', state_name: 'Iowa' },
  { city: 'Carroll County', state: 'IA', state_name: 'Iowa' },
  { city: 'Carter Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Cascade', state: 'IA', state_name: 'Iowa' },
  { city: 'Cass County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar Falls', state: 'IA', state_name: 'Iowa' },
  { city: 'Cedar Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Center Point', state: 'IA', state_name: 'Iowa' },
  { city: 'Centerville', state: 'IA', state_name: 'Iowa' },
  { city: 'Central City', state: 'IA', state_name: 'Iowa' },
  { city: 'Cerro Gordo County', state: 'IA', state_name: 'Iowa' },
  { city: 'Chariton', state: 'IA', state_name: 'Iowa' },
  { city: 'Charles City', state: 'IA', state_name: 'Iowa' },
  { city: 'Cherokee', state: 'IA', state_name: 'Iowa' },
  { city: 'Cherokee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Chickasaw County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarinda', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarion', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarke County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clarksville', state: 'IA', state_name: 'Iowa' },
  { city: 'Clay County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clayton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clear Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Clinton', state: 'IA', state_name: 'Iowa' },
  { city: 'Clinton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Clive', state: 'IA', state_name: 'Iowa' },
  { city: 'Colfax', state: 'IA', state_name: 'Iowa' },
  { city: 'Columbus Junction', state: 'IA', state_name: 'Iowa' },
  { city: 'Conrad', state: 'IA', state_name: 'Iowa' },
  { city: 'Coon Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Coralville', state: 'IA', state_name: 'Iowa' },
  { city: 'Corning', state: 'IA', state_name: 'Iowa' },
  { city: 'Corydon', state: 'IA', state_name: 'Iowa' },
  { city: 'Council Bluffs', state: 'IA', state_name: 'Iowa' },
  { city: 'Crawford County', state: 'IA', state_name: 'Iowa' },
  { city: 'Cresco', state: 'IA', state_name: 'Iowa' },
  { city: 'Creston', state: 'IA', state_name: 'Iowa' },
  { city: 'Dakota City', state: 'IA', state_name: 'Iowa' },
  { city: 'Dallas Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Dallas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Davenport', state: 'IA', state_name: 'Iowa' },
  { city: 'Davis County', state: 'IA', state_name: 'Iowa' },
  { city: 'De Soto', state: 'IA', state_name: 'Iowa' },
  { city: 'De Witt', state: 'IA', state_name: 'Iowa' },
  { city: 'Decatur County', state: 'IA', state_name: 'Iowa' },
  { city: 'Decorah', state: 'IA', state_name: 'Iowa' },
  { city: 'Delaware County', state: 'IA', state_name: 'Iowa' },
  { city: 'Denison', state: 'IA', state_name: 'Iowa' },
  { city: 'Denver', state: 'IA', state_name: 'Iowa' },
  { city: 'Des Moines', state: 'IA', state_name: 'Iowa' },
  { city: 'Des Moines County', state: 'IA', state_name: 'Iowa' },
  { city: 'Dickinson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Dike', state: 'IA', state_name: 'Iowa' },
  { city: 'Dubuque', state: 'IA', state_name: 'Iowa' },
  { city: 'Dubuque County', state: 'IA', state_name: 'Iowa' },
  { city: 'Durant', state: 'IA', state_name: 'Iowa' },
  { city: 'Dyersville', state: 'IA', state_name: 'Iowa' },
  { city: 'Dysart', state: 'IA', state_name: 'Iowa' },
  { city: 'Eagle Grove', state: 'IA', state_name: 'Iowa' },
  { city: 'Earlham', state: 'IA', state_name: 'Iowa' },
  { city: 'Eddyville', state: 'IA', state_name: 'Iowa' },
  { city: 'Eldora', state: 'IA', state_name: 'Iowa' },
  { city: 'Eldridge', state: 'IA', state_name: 'Iowa' },
  { city: 'Elk Run Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Elkader', state: 'IA', state_name: 'Iowa' },
  { city: 'Ely', state: 'IA', state_name: 'Iowa' },
  { city: 'Emmet County', state: 'IA', state_name: 'Iowa' },
  { city: 'Emmetsburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Epworth', state: 'IA', state_name: 'Iowa' },
  { city: 'Estherville', state: 'IA', state_name: 'Iowa' },
  { city: 'Evansdale', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairbank', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairfax', state: 'IA', state_name: 'Iowa' },
  { city: 'Fairfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Farley', state: 'IA', state_name: 'Iowa' },
  { city: 'Fayette', state: 'IA', state_name: 'Iowa' },
  { city: 'Fayette County', state: 'IA', state_name: 'Iowa' },
  { city: 'Floyd County', state: 'IA', state_name: 'Iowa' },
  { city: 'Forest City', state: 'IA', state_name: 'Iowa' },
  { city: 'Fort Dodge', state: 'IA', state_name: 'Iowa' },
  { city: 'Fort Madison', state: 'IA', state_name: 'Iowa' },
  { city: 'Franklin County', state: 'IA', state_name: 'Iowa' },
  { city: 'Fremont County', state: 'IA', state_name: 'Iowa' },
  { city: 'Garner', state: 'IA', state_name: 'Iowa' },
  { city: 'George', state: 'IA', state_name: 'Iowa' },
  { city: 'Gilbert', state: 'IA', state_name: 'Iowa' },
  { city: 'Glenwood', state: 'IA', state_name: 'Iowa' },
  { city: 'Glidden', state: 'IA', state_name: 'Iowa' },
  { city: 'Granger', state: 'IA', state_name: 'Iowa' },
  { city: 'Greene', state: 'IA', state_name: 'Iowa' },
  { city: 'Greene County', state: 'IA', state_name: 'Iowa' },
  { city: 'Greenfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Grimes', state: 'IA', state_name: 'Iowa' },
  { city: 'Grinnell', state: 'IA', state_name: 'Iowa' },
  { city: 'Grundy Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Grundy County', state: 'IA', state_name: 'Iowa' },
  { city: 'Guthrie Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Guthrie County', state: 'IA', state_name: 'Iowa' },
  { city: 'Guttenberg', state: 'IA', state_name: 'Iowa' },
  { city: 'Hamburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Hamilton County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hampton', state: 'IA', state_name: 'Iowa' },
  { city: 'Hancock County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hardin County', state: 'IA', state_name: 'Iowa' },
  { city: 'Harlan', state: 'IA', state_name: 'Iowa' },
  { city: 'Harrison County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hartley', state: 'IA', state_name: 'Iowa' },
  { city: 'Hawarden', state: 'IA', state_name: 'Iowa' },
  { city: 'Henry County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hiawatha', state: 'IA', state_name: 'Iowa' },
  { city: 'Holstein', state: 'IA', state_name: 'Iowa' },
  { city: 'Howard County', state: 'IA', state_name: 'Iowa' },
  { city: 'Hudson', state: 'IA', state_name: 'Iowa' },
  { city: 'Hull', state: 'IA', state_name: 'Iowa' },
  { city: 'Humboldt', state: 'IA', state_name: 'Iowa' },
  { city: 'Humboldt County', state: 'IA', state_name: 'Iowa' },
  { city: 'Huxley', state: 'IA', state_name: 'Iowa' },
  { city: 'Ida County', state: 'IA', state_name: 'Iowa' },
  { city: 'Ida Grove', state: 'IA', state_name: 'Iowa' },
  { city: 'Independence', state: 'IA', state_name: 'Iowa' },
  { city: 'Indianola', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa City', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa County', state: 'IA', state_name: 'Iowa' },
  { city: 'Iowa Falls', state: 'IA', state_name: 'Iowa' },
  { city: 'Jackson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jasper County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jefferson', state: 'IA', state_name: 'Iowa' },
  { city: 'Jefferson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Jesup', state: 'IA', state_name: 'Iowa' },
  { city: 'Jewell', state: 'IA', state_name: 'Iowa' },
  { city: 'Johnson County', state: 'IA', state_name: 'Iowa' },
  { city: 'Johnston', state: 'IA', state_name: 'Iowa' },
  { city: 'Jones County', state: 'IA', state_name: 'Iowa' },
  { city: 'Kalona', state: 'IA', state_name: 'Iowa' },
  { city: 'Keokuk', state: 'IA', state_name: 'Iowa' },
  { city: 'Keokuk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Keosauqua', state: 'IA', state_name: 'Iowa' },
  { city: 'Kingsley', state: 'IA', state_name: 'Iowa' },
  { city: 'Knoxville', state: 'IA', state_name: 'Iowa' },
  { city: 'Kossuth County', state: 'IA', state_name: 'Iowa' },
  { city: 'La Porte City', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake City', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Mills', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Panorama', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake Park', state: 'IA', state_name: 'Iowa' },
  { city: 'Lake View', state: 'IA', state_name: 'Iowa' },
  { city: 'Lamoni', state: 'IA', state_name: 'Iowa' },
  { city: 'Laurens', state: 'IA', state_name: 'Iowa' },
  { city: 'Le Claire', state: 'IA', state_name: 'Iowa' },
  { city: 'Le Mars', state: 'IA', state_name: 'Iowa' },
  { city: 'Lee County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lenox', state: 'IA', state_name: 'Iowa' },
  { city: 'Leon', state: 'IA', state_name: 'Iowa' },
  { city: 'Linn County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lisbon', state: 'IA', state_name: 'Iowa' },
  { city: 'Logan', state: 'IA', state_name: 'Iowa' },
  { city: 'Lone Tree', state: 'IA', state_name: 'Iowa' },
  { city: 'Louisa County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lucas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Lyon County', state: 'IA', state_name: 'Iowa' },
  { city: 'Madison County', state: 'IA', state_name: 'Iowa' },
  { city: 'Madrid', state: 'IA', state_name: 'Iowa' },
  { city: 'Maharishi Vedic City', state: 'IA', state_name: 'Iowa' },
  { city: 'Mahaska County', state: 'IA', state_name: 'Iowa' },
  { city: 'Malvern', state: 'IA', state_name: 'Iowa' },
  { city: 'Manchester', state: 'IA', state_name: 'Iowa' },
  { city: 'Manly', state: 'IA', state_name: 'Iowa' },
  { city: 'Manning', state: 'IA', state_name: 'Iowa' },
  { city: 'Manson', state: 'IA', state_name: 'Iowa' },
  { city: 'Mapleton', state: 'IA', state_name: 'Iowa' },
  { city: 'Maquoketa', state: 'IA', state_name: 'Iowa' },
  { city: 'Marcus', state: 'IA', state_name: 'Iowa' },
  { city: 'Marengo', state: 'IA', state_name: 'Iowa' },
  { city: 'Marion', state: 'IA', state_name: 'Iowa' },
  { city: 'Marion County', state: 'IA', state_name: 'Iowa' },
  { city: 'Marshall County', state: 'IA', state_name: 'Iowa' },
  { city: 'Marshalltown', state: 'IA', state_name: 'Iowa' },
  { city: 'Mason City', state: 'IA', state_name: 'Iowa' },
  { city: 'Mechanicsville', state: 'IA', state_name: 'Iowa' },
  { city: 'Mediapolis', state: 'IA', state_name: 'Iowa' },
  { city: 'Melcher-Dallas', state: 'IA', state_name: 'Iowa' },
  { city: 'Milford', state: 'IA', state_name: 'Iowa' },
  { city: 'Mills County', state: 'IA', state_name: 'Iowa' },
  { city: 'Missouri Valley', state: 'IA', state_name: 'Iowa' },
  { city: 'Mitchell County', state: 'IA', state_name: 'Iowa' },
  { city: 'Mitchellville', state: 'IA', state_name: 'Iowa' },
  { city: 'Monona', state: 'IA', state_name: 'Iowa' },
  { city: 'Monona County', state: 'IA', state_name: 'Iowa' },
  { city: 'Monroe', state: 'IA', state_name: 'Iowa' },
  { city: 'Monroe County', state: 'IA', state_name: 'Iowa' },
  { city: 'Montezuma', state: 'IA', state_name: 'Iowa' },
  { city: 'Montgomery County', state: 'IA', state_name: 'Iowa' },
  { city: 'Monticello', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Ayr', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Pleasant', state: 'IA', state_name: 'Iowa' },
  { city: 'Mount Vernon', state: 'IA', state_name: 'Iowa' },
  { city: 'Moville', state: 'IA', state_name: 'Iowa' },
  { city: 'Muscatine', state: 'IA', state_name: 'Iowa' },
  { city: 'Muscatine County', state: 'IA', state_name: 'Iowa' },
  { city: 'Nashua', state: 'IA', state_name: 'Iowa' },
  { city: 'Nevada', state: 'IA', state_name: 'Iowa' },
  { city: 'New Hampton', state: 'IA', state_name: 'Iowa' },
  { city: 'New London', state: 'IA', state_name: 'Iowa' },
  { city: 'New Sharon', state: 'IA', state_name: 'Iowa' },
  { city: 'Newton', state: 'IA', state_name: 'Iowa' },
  { city: 'Nora Springs', state: 'IA', state_name: 'Iowa' },
  { city: 'North English', state: 'IA', state_name: 'Iowa' },
  { city: 'North Liberty', state: 'IA', state_name: 'Iowa' },
  { city: 'Northwood', state: 'IA', state_name: 'Iowa' },
  { city: 'Norwalk', state: 'IA', state_name: 'Iowa' },
  { city: "O'Brien County", state: 'IA', state_name: 'Iowa' },
  { city: 'Oakland', state: 'IA', state_name: 'Iowa' },
  { city: 'Oelwein', state: 'IA', state_name: 'Iowa' },
  { city: 'Ogden', state: 'IA', state_name: 'Iowa' },
  { city: 'Onawa', state: 'IA', state_name: 'Iowa' },
  { city: 'Orange City', state: 'IA', state_name: 'Iowa' },
  { city: 'Osage', state: 'IA', state_name: 'Iowa' },
  { city: 'Osceola', state: 'IA', state_name: 'Iowa' },
  { city: 'Osceola County', state: 'IA', state_name: 'Iowa' },
  { city: 'Oskaloosa', state: 'IA', state_name: 'Iowa' },
  { city: 'Ottumwa', state: 'IA', state_name: 'Iowa' },
  { city: 'Page County', state: 'IA', state_name: 'Iowa' },
  { city: 'Palo', state: 'IA', state_name: 'Iowa' },
  { city: 'Palo Alto County', state: 'IA', state_name: 'Iowa' },
  { city: 'Panora', state: 'IA', state_name: 'Iowa' },
  { city: 'Park View', state: 'IA', state_name: 'Iowa' },
  { city: 'Parkersburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Paullina', state: 'IA', state_name: 'Iowa' },
  { city: 'Pella', state: 'IA', state_name: 'Iowa' },
  { city: 'Peosta', state: 'IA', state_name: 'Iowa' },
  { city: 'Perry', state: 'IA', state_name: 'Iowa' },
  { city: 'Pleasant Hill', state: 'IA', state_name: 'Iowa' },
  { city: 'Pleasantville', state: 'IA', state_name: 'Iowa' },
  { city: 'Plymouth County', state: 'IA', state_name: 'Iowa' },
  { city: 'Pocahontas', state: 'IA', state_name: 'Iowa' },
  { city: 'Pocahontas County', state: 'IA', state_name: 'Iowa' },
  { city: 'Polk City', state: 'IA', state_name: 'Iowa' },
  { city: 'Polk County', state: 'IA', state_name: 'Iowa' },
  { city: 'Postville', state: 'IA', state_name: 'Iowa' },
  { city: 'Pottawattamie County', state: 'IA', state_name: 'Iowa' },
  { city: 'Poweshiek County', state: 'IA', state_name: 'Iowa' },
  { city: 'Prairie City', state: 'IA', state_name: 'Iowa' },
  { city: 'Preston', state: 'IA', state_name: 'Iowa' },
  { city: 'Primghar', state: 'IA', state_name: 'Iowa' },
  { city: 'Red Oak', state: 'IA', state_name: 'Iowa' },
  { city: 'Reinbeck', state: 'IA', state_name: 'Iowa' },
  { city: 'Remsen', state: 'IA', state_name: 'Iowa' },
  { city: 'Ringgold County', state: 'IA', state_name: 'Iowa' },
  { city: 'Riverside', state: 'IA', state_name: 'Iowa' },
  { city: 'Robins', state: 'IA', state_name: 'Iowa' },
  { city: 'Rock Rapids', state: 'IA', state_name: 'Iowa' },
  { city: 'Rock Valley', state: 'IA', state_name: 'Iowa' },
  { city: 'Rockwell', state: 'IA', state_name: 'Iowa' },
  { city: 'Rockwell City', state: 'IA', state_name: 'Iowa' },
  { city: 'Roland', state: 'IA', state_name: 'Iowa' },
  { city: 'Sac City', state: 'IA', state_name: 'Iowa' },
  { city: 'Sac County', state: 'IA', state_name: 'Iowa' },
  { city: 'Saint Ansgar', state: 'IA', state_name: 'Iowa' },
  { city: 'Sanborn', state: 'IA', state_name: 'Iowa' },
  { city: 'Saylorville', state: 'IA', state_name: 'Iowa' },
  { city: 'Scott County', state: 'IA', state_name: 'Iowa' },
  { city: 'Sergeant Bluff', state: 'IA', state_name: 'Iowa' },
  { city: 'Sheffield', state: 'IA', state_name: 'Iowa' },
  { city: 'Shelby County', state: 'IA', state_name: 'Iowa' },
  { city: 'Sheldon', state: 'IA', state_name: 'Iowa' },
  { city: 'Shell Rock', state: 'IA', state_name: 'Iowa' },
  { city: 'Shenandoah', state: 'IA', state_name: 'Iowa' },
  { city: 'Sibley', state: 'IA', state_name: 'Iowa' },
  { city: 'Sidney', state: 'IA', state_name: 'Iowa' },
  { city: 'Sigourney', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux City', state: 'IA', state_name: 'Iowa' },
  { city: 'Sioux County', state: 'IA', state_name: 'Iowa' },
  { city: 'Slater', state: 'IA', state_name: 'Iowa' },
  { city: 'Solon', state: 'IA', state_name: 'Iowa' },
  { city: 'Spencer', state: 'IA', state_name: 'Iowa' },
  { city: 'Spirit Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Springville', state: 'IA', state_name: 'Iowa' },
  { city: 'State Center', state: 'IA', state_name: 'Iowa' },
  { city: 'Storm Lake', state: 'IA', state_name: 'Iowa' },
  { city: 'Story City', state: 'IA', state_name: 'Iowa' },
  { city: 'Story County', state: 'IA', state_name: 'Iowa' },
  { city: 'Strawberry Point', state: 'IA', state_name: 'Iowa' },
  { city: 'Stuart', state: 'IA', state_name: 'Iowa' },
  { city: 'Sumner', state: 'IA', state_name: 'Iowa' },
  { city: 'Tama', state: 'IA', state_name: 'Iowa' },
  { city: 'Tama County', state: 'IA', state_name: 'Iowa' },
  { city: 'Taylor County', state: 'IA', state_name: 'Iowa' },
  { city: 'Tiffin', state: 'IA', state_name: 'Iowa' },
  { city: 'Tipton', state: 'IA', state_name: 'Iowa' },
  { city: 'Toledo', state: 'IA', state_name: 'Iowa' },
  { city: 'Traer', state: 'IA', state_name: 'Iowa' },
  { city: 'Tripoli', state: 'IA', state_name: 'Iowa' },
  { city: 'Union County', state: 'IA', state_name: 'Iowa' },
  { city: 'University Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Urbana', state: 'IA', state_name: 'Iowa' },
  { city: 'Urbandale', state: 'IA', state_name: 'Iowa' },
  { city: 'Van Buren County', state: 'IA', state_name: 'Iowa' },
  { city: 'Van Meter', state: 'IA', state_name: 'Iowa' },
  { city: 'Villisca', state: 'IA', state_name: 'Iowa' },
  { city: 'Vinton', state: 'IA', state_name: 'Iowa' },
  { city: 'Walcott', state: 'IA', state_name: 'Iowa' },
  { city: 'Walford', state: 'IA', state_name: 'Iowa' },
  { city: 'Wapello', state: 'IA', state_name: 'Iowa' },
  { city: 'Wapello County', state: 'IA', state_name: 'Iowa' },
  { city: 'Warren County', state: 'IA', state_name: 'Iowa' },
  { city: 'Washington', state: 'IA', state_name: 'Iowa' },
  { city: 'Washington County', state: 'IA', state_name: 'Iowa' },
  { city: 'Waterloo', state: 'IA', state_name: 'Iowa' },
  { city: 'Waukee', state: 'IA', state_name: 'Iowa' },
  { city: 'Waukon', state: 'IA', state_name: 'Iowa' },
  { city: 'Waverly', state: 'IA', state_name: 'Iowa' },
  { city: 'Wayne County', state: 'IA', state_name: 'Iowa' },
  { city: 'Webster City', state: 'IA', state_name: 'Iowa' },
  { city: 'Webster County', state: 'IA', state_name: 'Iowa' },
  { city: 'Wellman', state: 'IA', state_name: 'Iowa' },
  { city: 'West Branch', state: 'IA', state_name: 'Iowa' },
  { city: 'West Burlington', state: 'IA', state_name: 'Iowa' },
  { city: 'West Des Moines', state: 'IA', state_name: 'Iowa' },
  { city: 'West Liberty', state: 'IA', state_name: 'Iowa' },
  { city: 'West Union', state: 'IA', state_name: 'Iowa' },
  { city: 'Williamsburg', state: 'IA', state_name: 'Iowa' },
  { city: 'Wilton', state: 'IA', state_name: 'Iowa' },
  { city: 'Windsor Heights', state: 'IA', state_name: 'Iowa' },
  { city: 'Winfield', state: 'IA', state_name: 'Iowa' },
  { city: 'Winnebago County', state: 'IA', state_name: 'Iowa' },
  { city: 'Winneshiek County', state: 'IA', state_name: 'Iowa' },
  { city: 'Winterset', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodbine', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodbury County', state: 'IA', state_name: 'Iowa' },
  { city: 'Woodward', state: 'IA', state_name: 'Iowa' },
  { city: 'Worth County', state: 'IA', state_name: 'Iowa' },
  { city: 'Wright County', state: 'IA', state_name: 'Iowa' },
  { city: 'Abilene', state: 'KS', state_name: 'Kansas' },
  { city: 'Allen County', state: 'KS', state_name: 'Kansas' },
  { city: 'Alma', state: 'KS', state_name: 'Kansas' },
  { city: 'Altamont', state: 'KS', state_name: 'Kansas' },
  { city: 'Anderson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Andover', state: 'KS', state_name: 'Kansas' },
  { city: 'Anthony', state: 'KS', state_name: 'Kansas' },
  { city: 'Arkansas City', state: 'KS', state_name: 'Kansas' },
  { city: 'Arma', state: 'KS', state_name: 'Kansas' },
  { city: 'Ashland', state: 'KS', state_name: 'Kansas' },
  { city: 'Atchison', state: 'KS', state_name: 'Kansas' },
  { city: 'Atchison County', state: 'KS', state_name: 'Kansas' },
  { city: 'Atwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Auburn', state: 'KS', state_name: 'Kansas' },
  { city: 'Augusta', state: 'KS', state_name: 'Kansas' },
  { city: 'Baldwin City', state: 'KS', state_name: 'Kansas' },
  { city: 'Barber County', state: 'KS', state_name: 'Kansas' },
  { city: 'Barton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Basehor', state: 'KS', state_name: 'Kansas' },
  { city: 'Baxter Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Bellaire', state: 'KS', state_name: 'Kansas' },
  { city: 'Belle Plaine', state: 'KS', state_name: 'Kansas' },
  { city: 'Belleville', state: 'KS', state_name: 'Kansas' },
  { city: 'Beloit', state: 'KS', state_name: 'Kansas' },
  { city: 'Bonner Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Bourbon County', state: 'KS', state_name: 'Kansas' },
  { city: 'Brown County', state: 'KS', state_name: 'Kansas' },
  { city: 'Buhler', state: 'KS', state_name: 'Kansas' },
  { city: 'Burlington', state: 'KS', state_name: 'Kansas' },
  { city: 'Butler County', state: 'KS', state_name: 'Kansas' },
  { city: 'Caldwell', state: 'KS', state_name: 'Kansas' },
  { city: 'Caney', state: 'KS', state_name: 'Kansas' },
  { city: 'Carbondale', state: 'KS', state_name: 'Kansas' },
  { city: 'Chanute', state: 'KS', state_name: 'Kansas' },
  { city: 'Chapman', state: 'KS', state_name: 'Kansas' },
  { city: 'Chase County', state: 'KS', state_name: 'Kansas' },
  { city: 'Chautauqua County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cheney', state: 'KS', state_name: 'Kansas' },
  { city: 'Cherokee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cherryvale', state: 'KS', state_name: 'Kansas' },
  { city: 'Chetopa', state: 'KS', state_name: 'Kansas' },
  { city: 'Cheyenne County', state: 'KS', state_name: 'Kansas' },
  { city: 'Cimarron', state: 'KS', state_name: 'Kansas' },
  { city: 'Clark County', state: 'KS', state_name: 'Kansas' },
  { city: 'Clay Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Clay County', state: 'KS', state_name: 'Kansas' },
  { city: 'Clearwater', state: 'KS', state_name: 'Kansas' },
  { city: 'Cloud County', state: 'KS', state_name: 'Kansas' },
  { city: 'Coffey County', state: 'KS', state_name: 'Kansas' },
  { city: 'Coffeyville', state: 'KS', state_name: 'Kansas' },
  { city: 'Colby', state: 'KS', state_name: 'Kansas' },
  { city: 'Coldwater', state: 'KS', state_name: 'Kansas' },
  { city: 'Columbus', state: 'KS', state_name: 'Kansas' },
  { city: 'Colwich', state: 'KS', state_name: 'Kansas' },
  { city: 'Comanche County', state: 'KS', state_name: 'Kansas' },
  { city: 'Concordia', state: 'KS', state_name: 'Kansas' },
  { city: 'Conway Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Cottonwood Falls', state: 'KS', state_name: 'Kansas' },
  { city: 'Council Grove', state: 'KS', state_name: 'Kansas' },
  { city: 'Cowley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Crawford County', state: 'KS', state_name: 'Kansas' },
  { city: 'De Soto', state: 'KS', state_name: 'Kansas' },
  { city: 'Decatur County', state: 'KS', state_name: 'Kansas' },
  { city: 'Derby', state: 'KS', state_name: 'Kansas' },
  { city: 'Dickinson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Dighton', state: 'KS', state_name: 'Kansas' },
  { city: 'Dodge City', state: 'KS', state_name: 'Kansas' },
  { city: 'Doniphan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Douglas County', state: 'KS', state_name: 'Kansas' },
  { city: 'Douglass', state: 'KS', state_name: 'Kansas' },
  { city: 'Edgerton', state: 'KS', state_name: 'Kansas' },
  { city: 'Edwards County', state: 'KS', state_name: 'Kansas' },
  { city: 'Edwardsville', state: 'KS', state_name: 'Kansas' },
  { city: 'El Dorado', state: 'KS', state_name: 'Kansas' },
  { city: 'Elk County', state: 'KS', state_name: 'Kansas' },
  { city: 'Elkhart', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellinwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellis', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellis County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellsworth', state: 'KS', state_name: 'Kansas' },
  { city: 'Ellsworth County', state: 'KS', state_name: 'Kansas' },
  { city: 'Elwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Emporia', state: 'KS', state_name: 'Kansas' },
  { city: 'Erie', state: 'KS', state_name: 'Kansas' },
  { city: 'Eudora', state: 'KS', state_name: 'Kansas' },
  { city: 'Eureka', state: 'KS', state_name: 'Kansas' },
  { city: 'Fairway', state: 'KS', state_name: 'Kansas' },
  { city: 'Finney County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ford County', state: 'KS', state_name: 'Kansas' },
  { city: 'Fort Riley North', state: 'KS', state_name: 'Kansas' },
  { city: 'Fort Scott', state: 'KS', state_name: 'Kansas' },
  { city: 'Franklin County', state: 'KS', state_name: 'Kansas' },
  { city: 'Fredonia', state: 'KS', state_name: 'Kansas' },
  { city: 'Frontenac', state: 'KS', state_name: 'Kansas' },
  { city: 'Galena', state: 'KS', state_name: 'Kansas' },
  { city: 'Garden City', state: 'KS', state_name: 'Kansas' },
  { city: 'Gardner', state: 'KS', state_name: 'Kansas' },
  { city: 'Garnett', state: 'KS', state_name: 'Kansas' },
  { city: 'Geary County', state: 'KS', state_name: 'Kansas' },
  { city: 'Girard', state: 'KS', state_name: 'Kansas' },
  { city: 'Goddard', state: 'KS', state_name: 'Kansas' },
  { city: 'Goodland', state: 'KS', state_name: 'Kansas' },
  { city: 'Gove', state: 'KS', state_name: 'Kansas' },
  { city: 'Gove County', state: 'KS', state_name: 'Kansas' },
  { city: 'Graham County', state: 'KS', state_name: 'Kansas' },
  { city: 'Grandview Plaza', state: 'KS', state_name: 'Kansas' },
  { city: 'Grant County', state: 'KS', state_name: 'Kansas' },
  { city: 'Gray County', state: 'KS', state_name: 'Kansas' },
  { city: 'Great Bend', state: 'KS', state_name: 'Kansas' },
  { city: 'Greeley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Greensburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Greenwood County', state: 'KS', state_name: 'Kansas' },
  { city: 'Halstead', state: 'KS', state_name: 'Kansas' },
  { city: 'Hamilton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Harper', state: 'KS', state_name: 'Kansas' },
  { city: 'Harper County', state: 'KS', state_name: 'Kansas' },
  { city: 'Harvey County', state: 'KS', state_name: 'Kansas' },
  { city: 'Haskell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Haven', state: 'KS', state_name: 'Kansas' },
  { city: 'Hays', state: 'KS', state_name: 'Kansas' },
  { city: 'Haysville', state: 'KS', state_name: 'Kansas' },
  { city: 'Herington', state: 'KS', state_name: 'Kansas' },
  { city: 'Hesston', state: 'KS', state_name: 'Kansas' },
  { city: 'Hiawatha', state: 'KS', state_name: 'Kansas' },
  { city: 'Highland', state: 'KS', state_name: 'Kansas' },
  { city: 'Hill City', state: 'KS', state_name: 'Kansas' },
  { city: 'Hillsboro', state: 'KS', state_name: 'Kansas' },
  { city: 'Hodgeman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Hoisington', state: 'KS', state_name: 'Kansas' },
  { city: 'Holcomb', state: 'KS', state_name: 'Kansas' },
  { city: 'Holton', state: 'KS', state_name: 'Kansas' },
  { city: 'Horton', state: 'KS', state_name: 'Kansas' },
  { city: 'Howard', state: 'KS', state_name: 'Kansas' },
  { city: 'Hoxie', state: 'KS', state_name: 'Kansas' },
  { city: 'Hugoton', state: 'KS', state_name: 'Kansas' },
  { city: 'Humboldt', state: 'KS', state_name: 'Kansas' },
  { city: 'Hutchinson', state: 'KS', state_name: 'Kansas' },
  { city: 'Independence', state: 'KS', state_name: 'Kansas' },
  { city: 'Inman', state: 'KS', state_name: 'Kansas' },
  { city: 'Iola', state: 'KS', state_name: 'Kansas' },
  { city: 'Jackson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Jefferson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Jetmore', state: 'KS', state_name: 'Kansas' },
  { city: 'Jewell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Johnson', state: 'KS', state_name: 'Kansas' },
  { city: 'Johnson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Junction City', state: 'KS', state_name: 'Kansas' },
  { city: 'Kansas City', state: 'KS', state_name: 'Kansas' },
  { city: 'Kearny County', state: 'KS', state_name: 'Kansas' },
  { city: 'Kechi', state: 'KS', state_name: 'Kansas' },
  { city: 'Kingman', state: 'KS', state_name: 'Kansas' },
  { city: 'Kingman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Kinsley', state: 'KS', state_name: 'Kansas' },
  { city: 'Kiowa', state: 'KS', state_name: 'Kansas' },
  { city: 'Kiowa County', state: 'KS', state_name: 'Kansas' },
  { city: 'La Crosse', state: 'KS', state_name: 'Kansas' },
  { city: 'La Cygne', state: 'KS', state_name: 'Kansas' },
  { city: 'Labette County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lakin', state: 'KS', state_name: 'Kansas' },
  { city: 'Lane County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lansing', state: 'KS', state_name: 'Kansas' },
  { city: 'Larned', state: 'KS', state_name: 'Kansas' },
  { city: 'Lawrence', state: 'KS', state_name: 'Kansas' },
  { city: 'Leavenworth', state: 'KS', state_name: 'Kansas' },
  { city: 'Leavenworth County', state: 'KS', state_name: 'Kansas' },
  { city: 'Leawood', state: 'KS', state_name: 'Kansas' },
  { city: 'Lenexa', state: 'KS', state_name: 'Kansas' },
  { city: 'Leoti', state: 'KS', state_name: 'Kansas' },
  { city: 'Liberal', state: 'KS', state_name: 'Kansas' },
  { city: 'Lincoln', state: 'KS', state_name: 'Kansas' },
  { city: 'Lincoln County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lindsborg', state: 'KS', state_name: 'Kansas' },
  { city: 'Linn County', state: 'KS', state_name: 'Kansas' },
  { city: 'Logan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Louisburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyndon', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyon County', state: 'KS', state_name: 'Kansas' },
  { city: 'Lyons', state: 'KS', state_name: 'Kansas' },
  { city: 'Maize', state: 'KS', state_name: 'Kansas' },
  { city: 'Manhattan', state: 'KS', state_name: 'Kansas' },
  { city: 'Mankato', state: 'KS', state_name: 'Kansas' },
  { city: 'Marion', state: 'KS', state_name: 'Kansas' },
  { city: 'Marion County', state: 'KS', state_name: 'Kansas' },
  { city: 'Marshall County', state: 'KS', state_name: 'Kansas' },
  { city: 'Marysville', state: 'KS', state_name: 'Kansas' },
  { city: 'McConnell AFB', state: 'KS', state_name: 'Kansas' },
  { city: 'McPherson', state: 'KS', state_name: 'Kansas' },
  { city: 'McPherson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Meade', state: 'KS', state_name: 'Kansas' },
  { city: 'Meade County', state: 'KS', state_name: 'Kansas' },
  { city: 'Medicine Lodge', state: 'KS', state_name: 'Kansas' },
  { city: 'Merriam', state: 'KS', state_name: 'Kansas' },
  { city: 'Miami County', state: 'KS', state_name: 'Kansas' },
  { city: 'Minneapolis', state: 'KS', state_name: 'Kansas' },
  { city: 'Mission', state: 'KS', state_name: 'Kansas' },
  { city: 'Mission Hills', state: 'KS', state_name: 'Kansas' },
  { city: 'Mitchell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Montgomery County', state: 'KS', state_name: 'Kansas' },
  { city: 'Morris County', state: 'KS', state_name: 'Kansas' },
  { city: 'Morton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Mound City', state: 'KS', state_name: 'Kansas' },
  { city: 'Moundridge', state: 'KS', state_name: 'Kansas' },
  { city: 'Mulvane', state: 'KS', state_name: 'Kansas' },
  { city: 'Nemaha County', state: 'KS', state_name: 'Kansas' },
  { city: 'Neodesha', state: 'KS', state_name: 'Kansas' },
  { city: 'Neosho County', state: 'KS', state_name: 'Kansas' },
  { city: 'Ness City', state: 'KS', state_name: 'Kansas' },
  { city: 'Ness County', state: 'KS', state_name: 'Kansas' },
  { city: 'New Century KS', state: 'KS', state_name: 'Kansas' },
  { city: 'Newton', state: 'KS', state_name: 'Kansas' },
  { city: 'Nickerson', state: 'KS', state_name: 'Kansas' },
  { city: 'North Newton', state: 'KS', state_name: 'Kansas' },
  { city: 'Norton', state: 'KS', state_name: 'Kansas' },
  { city: 'Norton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Oakley', state: 'KS', state_name: 'Kansas' },
  { city: 'Oberlin', state: 'KS', state_name: 'Kansas' },
  { city: 'Ogden', state: 'KS', state_name: 'Kansas' },
  { city: 'Olathe', state: 'KS', state_name: 'Kansas' },
  { city: 'Osage City', state: 'KS', state_name: 'Kansas' },
  { city: 'Osage County', state: 'KS', state_name: 'Kansas' },
  { city: 'Osawatomie', state: 'KS', state_name: 'Kansas' },
  { city: 'Osborne', state: 'KS', state_name: 'Kansas' },
  { city: 'Osborne County', state: 'KS', state_name: 'Kansas' },
  { city: 'Oskaloosa', state: 'KS', state_name: 'Kansas' },
  { city: 'Oswego', state: 'KS', state_name: 'Kansas' },
  { city: 'Ottawa', state: 'KS', state_name: 'Kansas' },
  { city: 'Ottawa County', state: 'KS', state_name: 'Kansas' },
  { city: 'Overbrook', state: 'KS', state_name: 'Kansas' },
  { city: 'Overland Park', state: 'KS', state_name: 'Kansas' },
  { city: 'Oxford', state: 'KS', state_name: 'Kansas' },
  { city: 'Paola', state: 'KS', state_name: 'Kansas' },
  { city: 'Park City', state: 'KS', state_name: 'Kansas' },
  { city: 'Parsons', state: 'KS', state_name: 'Kansas' },
  { city: 'Pawnee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Peabody', state: 'KS', state_name: 'Kansas' },
  { city: 'Phillips County', state: 'KS', state_name: 'Kansas' },
  { city: 'Phillipsburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Pittsburg', state: 'KS', state_name: 'Kansas' },
  { city: 'Plains', state: 'KS', state_name: 'Kansas' },
  { city: 'Plainville', state: 'KS', state_name: 'Kansas' },
  { city: 'Pleasanton', state: 'KS', state_name: 'Kansas' },
  { city: 'Pottawatomie County', state: 'KS', state_name: 'Kansas' },
  { city: 'Prairie Village', state: 'KS', state_name: 'Kansas' },
  { city: 'Pratt', state: 'KS', state_name: 'Kansas' },
  { city: 'Pratt County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rawlins County', state: 'KS', state_name: 'Kansas' },
  { city: 'Reno County', state: 'KS', state_name: 'Kansas' },
  { city: 'Republic County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rice County', state: 'KS', state_name: 'Kansas' },
  { city: 'Riley County', state: 'KS', state_name: 'Kansas' },
  { city: 'Roeland Park', state: 'KS', state_name: 'Kansas' },
  { city: 'Rooks County', state: 'KS', state_name: 'Kansas' },
  { city: 'Rose Hill', state: 'KS', state_name: 'Kansas' },
  { city: 'Rossville', state: 'KS', state_name: 'Kansas' },
  { city: 'Rush County', state: 'KS', state_name: 'Kansas' },
  { city: 'Russell', state: 'KS', state_name: 'Kansas' },
  { city: 'Russell County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sabetha', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint Francis', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint John', state: 'KS', state_name: 'Kansas' },
  { city: 'Saint Marys', state: 'KS', state_name: 'Kansas' },
  { city: 'Salina', state: 'KS', state_name: 'Kansas' },
  { city: 'Saline County', state: 'KS', state_name: 'Kansas' },
  { city: 'Satanta', state: 'KS', state_name: 'Kansas' },
  { city: 'Scott City', state: 'KS', state_name: 'Kansas' },
  { city: 'Scott County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedan', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedgwick', state: 'KS', state_name: 'Kansas' },
  { city: 'Sedgwick County', state: 'KS', state_name: 'Kansas' },
  { city: 'Seneca', state: 'KS', state_name: 'Kansas' },
  { city: 'Seward County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sharon Springs', state: 'KS', state_name: 'Kansas' },
  { city: 'Shawnee', state: 'KS', state_name: 'Kansas' },
  { city: 'Shawnee County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sheridan County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sherman County', state: 'KS', state_name: 'Kansas' },
  { city: 'Silver Lake', state: 'KS', state_name: 'Kansas' },
  { city: 'Smith Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Smith County', state: 'KS', state_name: 'Kansas' },
  { city: 'Solomon', state: 'KS', state_name: 'Kansas' },
  { city: 'South Hutchinson', state: 'KS', state_name: 'Kansas' },
  { city: 'Spring Hill', state: 'KS', state_name: 'Kansas' },
  { city: 'Stafford County', state: 'KS', state_name: 'Kansas' },
  { city: 'Stanton County', state: 'KS', state_name: 'Kansas' },
  { city: 'Sterling', state: 'KS', state_name: 'Kansas' },
  { city: 'Stevens County', state: 'KS', state_name: 'Kansas' },
  { city: 'Stockton', state: 'KS', state_name: 'Kansas' },
  { city: 'Sublette', state: 'KS', state_name: 'Kansas' },
  { city: 'Sumner County', state: 'KS', state_name: 'Kansas' },
  { city: 'Syracuse', state: 'KS', state_name: 'Kansas' },
  { city: 'Thomas County', state: 'KS', state_name: 'Kansas' },
  { city: 'Tonganoxie', state: 'KS', state_name: 'Kansas' },
  { city: 'Topeka', state: 'KS', state_name: 'Kansas' },
  { city: 'Towanda', state: 'KS', state_name: 'Kansas' },
  { city: 'Trego County', state: 'KS', state_name: 'Kansas' },
  { city: 'Tribune', state: 'KS', state_name: 'Kansas' },
  { city: 'Troy', state: 'KS', state_name: 'Kansas' },
  { city: 'Ulysses', state: 'KS', state_name: 'Kansas' },
  { city: 'Valley Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Valley Falls', state: 'KS', state_name: 'Kansas' },
  { city: 'Victoria', state: 'KS', state_name: 'Kansas' },
  { city: 'Wabaunsee County', state: 'KS', state_name: 'Kansas' },
  { city: 'WaKeeney', state: 'KS', state_name: 'Kansas' },
  { city: 'Wallace County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wamego', state: 'KS', state_name: 'Kansas' },
  { city: 'Washington', state: 'KS', state_name: 'Kansas' },
  { city: 'Washington County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wathena', state: 'KS', state_name: 'Kansas' },
  { city: 'Wellington', state: 'KS', state_name: 'Kansas' },
  { city: 'Wellsville', state: 'KS', state_name: 'Kansas' },
  { city: 'Westmoreland', state: 'KS', state_name: 'Kansas' },
  { city: 'Westwood', state: 'KS', state_name: 'Kansas' },
  { city: 'Wichita', state: 'KS', state_name: 'Kansas' },
  { city: 'Wichita County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wilson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Winfield', state: 'KS', state_name: 'Kansas' },
  { city: 'Woodson County', state: 'KS', state_name: 'Kansas' },
  { city: 'Wyandotte County', state: 'KS', state_name: 'Kansas' },
  { city: 'Yates Center', state: 'KS', state_name: 'Kansas' },
  { city: 'Adair County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Albany', state: 'KY', state_name: 'Kentucky' },
  { city: 'Alexandria', state: 'KY', state_name: 'Kentucky' },
  { city: 'Allen County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Anchorage', state: 'KY', state_name: 'Kentucky' },
  { city: 'Anderson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Annville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ashland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Auburn', state: 'KY', state_name: 'Kentucky' },
  { city: 'Audubon Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Augusta', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ballard County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barbourmeade', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barbourville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bardstown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bardwell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Barren County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bath County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beattyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beaver Dam', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bedford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Beechwood Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bellevue', state: 'KY', state_name: 'Kentucky' },
  { city: 'Benton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Berea', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bloomfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boone County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Booneville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bourbon County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bowling Green', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boyd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Boyle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bracken County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brandenburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breathitt County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breckinridge Center', state: 'KY', state_name: 'Kentucky' },
  { city: 'Breckinridge County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brodhead', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brooks', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brooksville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Brownsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Buckner', state: 'KY', state_name: 'Kentucky' },
  { city: 'Buechel', state: 'KY', state_name: 'Kentucky' },
  { city: 'Bullitt County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Burkesville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Burlington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Butler County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cadiz', state: 'KY', state_name: 'Kentucky' },
  { city: 'Caldwell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calhoun', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calloway County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Calvert City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Camargo', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campbell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campbellsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Campton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carlisle', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carlisle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carroll County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carrollton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Carter County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Casey County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Catlettsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cave City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Central City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Christian County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clark County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Claryville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clay County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clinton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Clinton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cloverport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Coal Run Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cold Spring', state: 'KY', state_name: 'Kentucky' },
  { city: 'Coldstream', state: 'KY', state_name: 'Kentucky' },
  { city: 'Columbia', state: 'KY', state_name: 'Kentucky' },
  { city: 'Corbin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Covington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crescent Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crestview Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crestwood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crittenden', state: 'KY', state_name: 'Kentucky' },
  { city: 'Crittenden County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cumberland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cumberland County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Cynthiana', state: 'KY', state_name: 'Kentucky' },
  { city: 'Danville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Daviess County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dawson Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dayton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dixon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Doe Valley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Douglass Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Dry Ridge', state: 'KY', state_name: 'Kentucky' },
  { city: 'Earlington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Eddyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edgewood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edmonson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Edmonton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elizabethtown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elk Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elkfork', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elkton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elliott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Elsmere', state: 'KY', state_name: 'Kentucky' },
  { city: 'Eminence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Erlanger', state: 'KY', state_name: 'Kentucky' },
  { city: 'Estill County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fairdale', state: 'KY', state_name: 'Kentucky' },
  { city: 'Falmouth', state: 'KY', state_name: 'Kentucky' },
  { city: 'Farley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fayette County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fern Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Flatwoods', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fleming County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Flemingsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Florence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Floyd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Campbell North', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Knox', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Mitchell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Thomas', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fort Wright', state: 'KY', state_name: 'Kentucky' },
  { city: 'Francisville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Frankfort', state: 'KY', state_name: 'Kentucky' },
  { city: 'Franklin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Franklin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Frenchburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fulton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Fulton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Gallatin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Garrard County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Georgetown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Glasgow', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grant County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Graves County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Graymoor-Devondale', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grayson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Grayson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Green County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greensburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenup', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenup County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Greenville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Guthrie', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hancock County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hardin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hardinsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harlan', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harlan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harrison County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Harrodsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hart County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hartford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hawesville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hazard', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hebron', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hebron Estates', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henderson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henderson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hendron', state: 'KY', state_name: 'Kentucky' },
  { city: 'Henry County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Heritage Creek', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hickman', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hickman County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Highland Heights', state: 'KY', state_name: 'Kentucky' },
  { city: 'Highview', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hillview', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hindman', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hodgenville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hopkins County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hopkinsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Horse Cave', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hurstbourne', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hurstbourne Acres', state: 'KY', state_name: 'Kentucky' },
  { city: 'Hyden', state: 'KY', state_name: 'Kentucky' },
  { city: 'Independence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Indian Hills', state: 'KY', state_name: 'Kentucky' },
  {
    city: 'Indian Hills Cherokee Section',
    state: 'KY',
    state_name: 'Kentucky',
  },
  { city: 'Inez', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ironville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Irvine', state: 'KY', state_name: 'Kentucky' },
  { city: 'Irvington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jackson', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jackson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jamestown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jefferson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jeffersontown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jeffersonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jenkins', state: 'KY', state_name: 'Kentucky' },
  { city: 'Jessamine County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Johnson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Junction City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Kenton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knottsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Knox County', state: 'KY', state_name: 'Kentucky' },
  { city: 'La Center', state: 'KY', state_name: 'Kentucky' },
  { city: 'La Grange', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lakeside Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lancaster', state: 'KY', state_name: 'Kentucky' },
  { city: 'Larue County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Laurel County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lawrence County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lawrenceburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lebanon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lebanon Junction', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ledbetter', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lee County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Leitchfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Leslie County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Letcher County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lewis County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lewisport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lexington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lexington-Fayette', state: 'KY', state_name: 'Kentucky' },
  { city: 'Liberty', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lincoln County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Livermore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Livingston County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Logan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'London', state: 'KY', state_name: 'Kentucky' },
  { city: 'Louisa', state: 'KY', state_name: 'Kentucky' },
  { city: 'Louisville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ludlow', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lyndon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Lyon County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Madison County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Madisonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Magoffin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Manchester', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marion', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marion County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Marshall County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Martin County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mason County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Masonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Massac', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mayfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Maysville', state: 'KY', state_name: 'Kentucky' },
  { city: 'McCracken County', state: 'KY', state_name: 'Kentucky' },
  { city: 'McCreary County', state: 'KY', state_name: 'Kentucky' },
  { city: 'McKee', state: 'KY', state_name: 'Kentucky' },
  { city: 'McLean County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Meade County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Meads', state: 'KY', state_name: 'Kentucky' },
  { city: 'Menifee County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mercer County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Metcalfe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Middlesboro', state: 'KY', state_name: 'Kentucky' },
  { city: 'Middletown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Midway', state: 'KY', state_name: 'Kentucky' },
  { city: 'Monroe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Montgomery County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Monticello', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morehead', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morgan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morganfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Morgantown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Olivet', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Sterling', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Vernon', state: 'KY', state_name: 'Kentucky' },
  { city: 'Mount Washington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Muhlenberg County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Munfordville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Murray', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nelson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'New Castle', state: 'KY', state_name: 'Kentucky' },
  { city: 'Newburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Newport', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nicholas County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nicholasville', state: 'KY', state_name: 'Kentucky' },
  { city: 'North Corbin', state: 'KY', state_name: 'Kentucky' },
  { city: 'Northfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Nortonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oak Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oakbrook', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ohio County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Okolona', state: 'KY', state_name: 'Kentucky' },
  { city: 'Oldham County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Olive Hill', state: 'KY', state_name: 'Kentucky' },
  { city: 'Orchard Grass Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owen County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owensboro', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owenton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owingsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Owsley County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paducah', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paintsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Paris', state: 'KY', state_name: 'Kentucky' },
  { city: 'Park Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pendleton County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Perry County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pewee Valley', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pike County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pikeville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pine Knot', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pineville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pioneer Village', state: 'KY', state_name: 'Kentucky' },
  { city: 'Plano', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pleasure Ridge Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Powell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Prestonsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Princeton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Prospect', state: 'KY', state_name: 'Kentucky' },
  { city: 'Providence', state: 'KY', state_name: 'Kentucky' },
  { city: 'Pulaski County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Raceland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Radcliff', state: 'KY', state_name: 'Kentucky' },
  { city: 'Reidland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Richmond', state: 'KY', state_name: 'Kentucky' },
  { city: 'Robertson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Rockcastle County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Rowan County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russell Springs', state: 'KY', state_name: 'Kentucky' },
  { city: 'Russellville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Ryland Heights', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Dennis', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Matthews', state: 'KY', state_name: 'Kentucky' },
  { city: 'Saint Regis Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Salyersville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sandy Hook', state: 'KY', state_name: 'Kentucky' },
  { city: 'Scott County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Scottsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sebree', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shelby County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shelbyville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shepherdsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Shively', state: 'KY', state_name: 'Kentucky' },
  { city: 'Silver Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Simpson County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Simpsonville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Smithland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Somerset', state: 'KY', state_name: 'Kentucky' },
  { city: 'South Shore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Southgate', state: 'KY', state_name: 'Kentucky' },
  { city: 'Spencer County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Springfield', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stanford', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stanton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Stearns', state: 'KY', state_name: 'Kentucky' },
  { city: 'Sturgis', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylor County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylor Mill', state: 'KY', state_name: 'Kentucky' },
  { city: 'Taylorsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Todd County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Tompkinsville', state: 'KY', state_name: 'Kentucky' },
  { city: 'Trigg County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Trimble County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Union', state: 'KY', state_name: 'Kentucky' },
  { city: 'Union County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Valley Station', state: 'KY', state_name: 'Kentucky' },
  { city: 'Van Lear', state: 'KY', state_name: 'Kentucky' },
  { city: 'Vanceburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Verona', state: 'KY', state_name: 'Kentucky' },
  { city: 'Versailles', state: 'KY', state_name: 'Kentucky' },
  { city: 'Villa Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Vine Grove', state: 'KY', state_name: 'Kentucky' },
  { city: 'Walton', state: 'KY', state_name: 'Kentucky' },
  { city: 'Warren County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Warsaw', state: 'KY', state_name: 'Kentucky' },
  { city: 'Washington County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Watterson Park', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wayne County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Webster County', state: 'KY', state_name: 'Kentucky' },
  { city: 'West Buechel', state: 'KY', state_name: 'Kentucky' },
  { city: 'West Liberty', state: 'KY', state_name: 'Kentucky' },
  { city: 'Westwood', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitesburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitley City', state: 'KY', state_name: 'Kentucky' },
  { city: 'Whitley County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wickliffe', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wilder', state: 'KY', state_name: 'Kentucky' },
  { city: 'Williamsburg', state: 'KY', state_name: 'Kentucky' },
  { city: 'Williamstown', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wilmore', state: 'KY', state_name: 'Kentucky' },
  { city: 'Winchester', state: 'KY', state_name: 'Kentucky' },
  { city: 'Windy Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wolfe County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Woodford County', state: 'KY', state_name: 'Kentucky' },
  { city: 'Worthington', state: 'KY', state_name: 'Kentucky' },
  { city: 'Worthington Hills', state: 'KY', state_name: 'Kentucky' },
  { city: 'Wurtland', state: 'KY', state_name: 'Kentucky' },
  { city: 'Abbeville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Abita Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'Acadia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Addis', state: 'LA', state_name: 'Louisiana' },
  { city: 'Albany', state: 'LA', state_name: 'Louisiana' },
  { city: 'Alexandria', state: 'LA', state_name: 'Louisiana' },
  { city: 'Allen Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ama', state: 'LA', state_name: 'Louisiana' },
  { city: 'Amelia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Amite', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arabi', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arcadia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Arnaudville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ascension Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Assumption Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Avondale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Avoyelles Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baker', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baldwin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ball', state: 'LA', state_name: 'Louisiana' },
  { city: 'Banks Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'Barataria', state: 'LA', state_name: 'Louisiana' },
  { city: 'Basile', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bastrop', state: 'LA', state_name: 'Louisiana' },
  { city: 'Baton Rouge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bawcomville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Cane', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Gauche', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bayou Vista', state: 'LA', state_name: 'Louisiana' },
  { city: 'Beauregard Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Belle Chasse', state: 'LA', state_name: 'Louisiana' },
  { city: 'Belle Rose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Benton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bernice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Berwick', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bienville Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Blanchard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bogalusa', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bossier City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bossier Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bourg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Boutte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Breaux Bridge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bridge City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Broussard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brownsfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brownsville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Brusly', state: 'LA', state_name: 'Louisiana' },
  { city: 'Bunkie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Caddo Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cade', state: 'LA', state_name: 'Louisiana' },
  { city: 'Calcasieu Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Caldwell Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cameron', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cameron Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Campti', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carencro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carlyss', state: 'LA', state_name: 'Louisiana' },
  { city: 'Carville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Catahoula', state: 'LA', state_name: 'Louisiana' },
  { city: 'Catahoula Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cecilia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Central', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chackbay', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chalmette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Charenton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Chauvin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Church Point', state: 'LA', state_name: 'Louisiana' },
  { city: 'Claiborne', state: 'LA', state_name: 'Louisiana' },
  { city: 'Claiborne Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Clarks', state: 'LA', state_name: 'Louisiana' },
  { city: 'Clinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Colfax', state: 'LA', state_name: 'Louisiana' },
  { city: 'Columbia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Concordia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Convent', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cottonport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Coushatta', state: 'LA', state_name: 'Louisiana' },
  { city: 'Covington', state: 'LA', state_name: 'Louisiana' },
  { city: 'Crowley', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cullen', state: 'LA', state_name: 'Louisiana' },
  { city: 'Cut Off', state: 'LA', state_name: 'Louisiana' },
  { city: 'De Soto Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Delcambre', state: 'LA', state_name: 'Louisiana' },
  { city: 'Delhi', state: 'LA', state_name: 'Louisiana' },
  { city: 'Denham Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'DeQuincy', state: 'LA', state_name: 'Louisiana' },
  { city: 'DeRidder', state: 'LA', state_name: 'Louisiana' },
  { city: 'Des Allemands', state: 'LA', state_name: 'Louisiana' },
  { city: 'Destrehan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Deville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Donaldsonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Dulac', state: 'LA', state_name: 'Louisiana' },
  { city: 'Duson', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Baton Rouge Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Carroll Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'East Feliciana Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eastwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eden Isle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Edgard', state: 'LA', state_name: 'Louisiana' },
  { city: 'Elmwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Elton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Erath', state: 'LA', state_name: 'Louisiana' },
  { city: 'Erwinville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Estelle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Eunice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Evangeline Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Farmerville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ferriday', state: 'LA', state_name: 'Louisiana' },
  { city: 'Fort Polk North', state: 'LA', state_name: 'Louisiana' },
  { city: 'Fort Polk South', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklin Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Franklinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'French Settlement', state: 'LA', state_name: 'Louisiana' },
  { city: 'Galliano', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gardere', state: 'LA', state_name: 'Louisiana' },
  { city: 'Garyville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Glenmora', state: 'LA', state_name: 'Louisiana' },
  { city: 'Golden Meadow', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gonzales', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grambling', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gramercy', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grand Isle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grand Point', state: 'LA', state_name: 'Louisiana' },
  { city: 'Grant Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gray', state: 'LA', state_name: 'Louisiana' },
  { city: 'Greensburg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Greenwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gretna', state: 'LA', state_name: 'Louisiana' },
  { city: 'Gueydan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hackberry', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hahnville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Hammond', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harahan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harrisonburg', state: 'LA', state_name: 'Louisiana' },
  { city: 'Harvey', state: 'LA', state_name: 'Louisiana' },
  { city: 'Haughton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Haynesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Henderson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Homer', state: 'LA', state_name: 'Louisiana' },
  { city: 'Houma', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iberia Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iberville Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Independence', state: 'LA', state_name: 'Louisiana' },
  { city: 'Inniswold', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iota', state: 'LA', state_name: 'Louisiana' },
  { city: 'Iowa', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jackson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jackson Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jean Lafitte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jeanerette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson Davis Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jefferson Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jena', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jennings', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jonesboro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Jonesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kaplan', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kenner', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kentwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Killian', state: 'LA', state_name: 'Louisiana' },
  { city: 'Kinder', state: 'LA', state_name: 'Louisiana' },
  { city: 'Krotz Springs', state: 'LA', state_name: 'Louisiana' },
  { city: 'La Salle Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Labadieville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lacombe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafayette', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafayette Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lafourche Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Arthur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Charles', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lake Providence', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lakeshore', state: 'LA', state_name: 'Louisiana' },
  { city: 'Laplace', state: 'LA', state_name: 'Louisiana' },
  { city: 'Larose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lawtell', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lecompte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Leesville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Leonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lincoln Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livingston', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livingston Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Livonia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lockport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lockport Heights', state: 'LA', state_name: 'Louisiana' },
  { city: 'Logansport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Luling', state: 'LA', state_name: 'Louisiana' },
  { city: 'Lutcher', state: 'LA', state_name: 'Louisiana' },
  { city: 'Madison Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mamou', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mandeville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mansfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mansura', state: 'LA', state_name: 'Louisiana' },
  { city: 'Many', state: 'LA', state_name: 'Louisiana' },
  { city: 'Maringouin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Marksville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Marrero', state: 'LA', state_name: 'Louisiana' },
  { city: 'Mathews', state: 'LA', state_name: 'Louisiana' },
  { city: 'Maurice', state: 'LA', state_name: 'Louisiana' },
  { city: 'Melville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Meraux', state: 'LA', state_name: 'Louisiana' },
  { city: 'Merrydale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Merryville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Metairie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Metairie Terrace', state: 'LA', state_name: 'Louisiana' },
  { city: 'Midway', state: 'LA', state_name: 'Louisiana' },
  { city: 'Milton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Minden', state: 'LA', state_name: 'Louisiana' },
  { city: 'Minorca', state: 'LA', state_name: 'Louisiana' },
  { city: 'Monroe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Montegut', state: 'LA', state_name: 'Louisiana' },
  { city: 'Monticello', state: 'LA', state_name: 'Louisiana' },
  { city: 'Montz', state: 'LA', state_name: 'Louisiana' },
  { city: 'Morehouse Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Morgan City', state: 'LA', state_name: 'Louisiana' },
  { city: 'Moss Bluff', state: 'LA', state_name: 'Louisiana' },
  { city: 'Napoleonville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natalbany', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natchitoches', state: 'LA', state_name: 'Louisiana' },
  { city: 'Natchitoches Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Iberia', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Llano', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Orleans', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Roads', state: 'LA', state_name: 'Louisiana' },
  { city: 'New Sarpy', state: 'LA', state_name: 'Louisiana' },
  { city: 'Newellton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Norco', state: 'LA', state_name: 'Louisiana' },
  { city: 'North Vacherie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oak Grove', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oak Hills Place', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oakdale', state: 'LA', state_name: 'Louisiana' },
  { city: 'Oberlin', state: 'LA', state_name: 'Louisiana' },
  { city: 'Old Jefferson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Olla', state: 'LA', state_name: 'Louisiana' },
  { city: 'Opelousas', state: 'LA', state_name: 'Louisiana' },
  { city: 'Orleans Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ossun', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ouachita Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Paradis', state: 'LA', state_name: 'Louisiana' },
  { city: 'Patterson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Paulina', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pearl River', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pierre Part', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pine Prairie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pineville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Plaquemine', state: 'LA', state_name: 'Louisiana' },
  { city: 'Plaquemines Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Pointe Coupee Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ponchatoula', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Allen', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Barre', state: 'LA', state_name: 'Louisiana' },
  { city: 'Port Sulphur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Poydras', state: 'LA', state_name: 'Louisiana' },
  { city: 'Prairieville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Presquille', state: 'LA', state_name: 'Louisiana' },
  { city: 'Prien', state: 'LA', state_name: 'Louisiana' },
  { city: 'Raceland', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rapides Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rayne', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rayville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Red Chute', state: 'LA', state_name: 'Louisiana' },
  { city: 'Red River Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Reserve', state: 'LA', state_name: 'Louisiana' },
  { city: 'Richland Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Richwood', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ringgold', state: 'LA', state_name: 'Louisiana' },
  { city: 'River Ridge', state: 'LA', state_name: 'Louisiana' },
  { city: 'Roseland', state: 'LA', state_name: 'Louisiana' },
  { city: 'Rosepine', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ruston', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sabine Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Bernard Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Charles Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Francisville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Gabriel', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Helena Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint James Parish', state: 'LA', state_name: 'Louisiana' },
  {
    city: 'Saint John the Baptist Parish',
    state: 'LA',
    state_name: 'Louisiana',
  },
  { city: 'Saint Joseph', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Landry Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Martin Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Martinville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Mary Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Rose', state: 'LA', state_name: 'Louisiana' },
  { city: 'Saint Tammany Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Schriever', state: 'LA', state_name: 'Louisiana' },
  { city: 'Scott', state: 'LA', state_name: 'Louisiana' },
  { city: 'Shenandoah', state: 'LA', state_name: 'Louisiana' },
  { city: 'Shreveport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sibley', state: 'LA', state_name: 'Louisiana' },
  { city: 'Simmesport', state: 'LA', state_name: 'Louisiana' },
  { city: 'Slidell', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sorrento', state: 'LA', state_name: 'Louisiana' },
  { city: 'South Vacherie', state: 'LA', state_name: 'Louisiana' },
  { city: 'Springhill', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sterlington', state: 'LA', state_name: 'Louisiana' },
  { city: 'Stonewall', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sulphur', state: 'LA', state_name: 'Louisiana' },
  { city: 'Sunset', state: 'LA', state_name: 'Louisiana' },
  { city: 'Supreme', state: 'LA', state_name: 'Louisiana' },
  { city: 'Swartz', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tallulah', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tangipahoa Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Tensas Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Terrebonne Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Terrytown', state: 'LA', state_name: 'Louisiana' },
  { city: 'Thibodaux', state: 'LA', state_name: 'Louisiana' },
  { city: 'Timberlane', state: 'LA', state_name: 'Louisiana' },
  { city: 'Union Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Urania', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vermilion Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vernon Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vidalia', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vienna Bend', state: 'LA', state_name: 'Louisiana' },
  { city: 'Village Saint George', state: 'LA', state_name: 'Louisiana' },
  { city: 'Ville Platte', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vinton', state: 'LA', state_name: 'Louisiana' },
  { city: 'Violet', state: 'LA', state_name: 'Louisiana' },
  { city: 'Vivian', state: 'LA', state_name: 'Louisiana' },
  { city: 'Waggaman', state: 'LA', state_name: 'Louisiana' },
  { city: 'Walker', state: 'LA', state_name: 'Louisiana' },
  { city: 'Washington Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Watson', state: 'LA', state_name: 'Louisiana' },
  { city: 'Webster Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Welsh', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Baton Rouge Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Carroll Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Feliciana Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Ferriday', state: 'LA', state_name: 'Louisiana' },
  { city: 'West Monroe', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westlake', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westminster', state: 'LA', state_name: 'Louisiana' },
  { city: 'Westwego', state: 'LA', state_name: 'Louisiana' },
  { city: 'White Castle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winn Parish', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winnfield', state: 'LA', state_name: 'Louisiana' },
  { city: 'Winnsboro', state: 'LA', state_name: 'Louisiana' },
  { city: 'Woodmere', state: 'LA', state_name: 'Louisiana' },
  { city: 'Woodworth', state: 'LA', state_name: 'Louisiana' },
  { city: 'Youngsville', state: 'LA', state_name: 'Louisiana' },
  { city: 'Zachary', state: 'LA', state_name: 'Louisiana' },
  { city: 'Zwolle', state: 'LA', state_name: 'Louisiana' },
  { city: 'Acton', state: 'ME', state_name: 'Maine' },
  { city: 'Addison', state: 'ME', state_name: 'Maine' },
  { city: 'Albion', state: 'ME', state_name: 'Maine' },
  { city: 'Alfred', state: 'ME', state_name: 'Maine' },
  { city: 'Androscoggin County', state: 'ME', state_name: 'Maine' },
  { city: 'Appleton', state: 'ME', state_name: 'Maine' },
  { city: 'Aroostook County', state: 'ME', state_name: 'Maine' },
  { city: 'Arundel', state: 'ME', state_name: 'Maine' },
  { city: 'Auburn', state: 'ME', state_name: 'Maine' },
  { city: 'Augusta', state: 'ME', state_name: 'Maine' },
  { city: 'Bangor', state: 'ME', state_name: 'Maine' },
  { city: 'Bar Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Bath', state: 'ME', state_name: 'Maine' },
  { city: 'Belfast', state: 'ME', state_name: 'Maine' },
  { city: 'Belgrade', state: 'ME', state_name: 'Maine' },
  { city: 'Benton', state: 'ME', state_name: 'Maine' },
  { city: 'Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'Bethel', state: 'ME', state_name: 'Maine' },
  { city: 'Biddeford', state: 'ME', state_name: 'Maine' },
  { city: 'Boothbay', state: 'ME', state_name: 'Maine' },
  { city: 'Boothbay Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Bradford', state: 'ME', state_name: 'Maine' },
  { city: 'Bradley', state: 'ME', state_name: 'Maine' },
  { city: 'Brewer', state: 'ME', state_name: 'Maine' },
  { city: 'Bridgton', state: 'ME', state_name: 'Maine' },
  { city: 'Bristol', state: 'ME', state_name: 'Maine' },
  { city: 'Brooks', state: 'ME', state_name: 'Maine' },
  { city: 'Brownfield', state: 'ME', state_name: 'Maine' },
  { city: 'Brownville', state: 'ME', state_name: 'Maine' },
  { city: 'Brunswick', state: 'ME', state_name: 'Maine' },
  { city: 'Buckfield', state: 'ME', state_name: 'Maine' },
  { city: 'Bucksport', state: 'ME', state_name: 'Maine' },
  { city: 'Burnham', state: 'ME', state_name: 'Maine' },
  { city: 'Buxton', state: 'ME', state_name: 'Maine' },
  { city: 'Calais', state: 'ME', state_name: 'Maine' },
  { city: 'Camden', state: 'ME', state_name: 'Maine' },
  { city: 'Canaan', state: 'ME', state_name: 'Maine' },
  { city: 'Canton', state: 'ME', state_name: 'Maine' },
  { city: 'Cape Neddick', state: 'ME', state_name: 'Maine' },
  { city: 'Caribou', state: 'ME', state_name: 'Maine' },
  { city: 'Carmel', state: 'ME', state_name: 'Maine' },
  { city: 'Castine', state: 'ME', state_name: 'Maine' },
  { city: 'Charleston', state: 'ME', state_name: 'Maine' },
  { city: 'Chelsea', state: 'ME', state_name: 'Maine' },
  { city: 'Cherryfield', state: 'ME', state_name: 'Maine' },
  { city: 'Chesterville', state: 'ME', state_name: 'Maine' },
  { city: 'China', state: 'ME', state_name: 'Maine' },
  { city: 'Chisholm', state: 'ME', state_name: 'Maine' },
  { city: 'Clinton', state: 'ME', state_name: 'Maine' },
  { city: 'Corinna', state: 'ME', state_name: 'Maine' },
  { city: 'Cornish', state: 'ME', state_name: 'Maine' },
  { city: 'Cornville', state: 'ME', state_name: 'Maine' },
  { city: 'Cumberland Center', state: 'ME', state_name: 'Maine' },
  { city: 'Cumberland County', state: 'ME', state_name: 'Maine' },
  { city: 'Cushing', state: 'ME', state_name: 'Maine' },
  { city: 'Damariscotta', state: 'ME', state_name: 'Maine' },
  { city: 'Dayton', state: 'ME', state_name: 'Maine' },
  { city: 'Dedham', state: 'ME', state_name: 'Maine' },
  { city: 'Deer Isle', state: 'ME', state_name: 'Maine' },
  { city: 'Denmark', state: 'ME', state_name: 'Maine' },
  { city: 'Dexter', state: 'ME', state_name: 'Maine' },
  { city: 'Dixfield', state: 'ME', state_name: 'Maine' },
  { city: 'Dixmont', state: 'ME', state_name: 'Maine' },
  { city: 'Dover-Foxcroft', state: 'ME', state_name: 'Maine' },
  { city: 'East Machias', state: 'ME', state_name: 'Maine' },
  { city: 'East Millinocket', state: 'ME', state_name: 'Maine' },
  { city: 'Easton', state: 'ME', state_name: 'Maine' },
  { city: 'Eastport', state: 'ME', state_name: 'Maine' },
  { city: 'Eddington', state: 'ME', state_name: 'Maine' },
  { city: 'Edgecomb', state: 'ME', state_name: 'Maine' },
  { city: 'Eliot', state: 'ME', state_name: 'Maine' },
  { city: 'Ellsworth', state: 'ME', state_name: 'Maine' },
  { city: 'Enfield', state: 'ME', state_name: 'Maine' },
  { city: 'Etna', state: 'ME', state_name: 'Maine' },
  { city: 'Fairfield', state: 'ME', state_name: 'Maine' },
  { city: 'Falmouth', state: 'ME', state_name: 'Maine' },
  { city: 'Falmouth Foreside', state: 'ME', state_name: 'Maine' },
  { city: 'Farmingdale', state: 'ME', state_name: 'Maine' },
  { city: 'Farmington', state: 'ME', state_name: 'Maine' },
  { city: 'Fayette', state: 'ME', state_name: 'Maine' },
  { city: 'Fort Fairfield', state: 'ME', state_name: 'Maine' },
  { city: 'Fort Kent', state: 'ME', state_name: 'Maine' },
  { city: 'Frankfort', state: 'ME', state_name: 'Maine' },
  { city: 'Franklin', state: 'ME', state_name: 'Maine' },
  { city: 'Franklin County', state: 'ME', state_name: 'Maine' },
  { city: 'Freeport', state: 'ME', state_name: 'Maine' },
  { city: 'Frenchville', state: 'ME', state_name: 'Maine' },
  { city: 'Friendship', state: 'ME', state_name: 'Maine' },
  { city: 'Fryeburg', state: 'ME', state_name: 'Maine' },
  { city: 'Gardiner', state: 'ME', state_name: 'Maine' },
  { city: 'Garland', state: 'ME', state_name: 'Maine' },
  { city: 'Gorham', state: 'ME', state_name: 'Maine' },
  { city: 'Gouldsboro', state: 'ME', state_name: 'Maine' },
  { city: 'Greenbush', state: 'ME', state_name: 'Maine' },
  { city: 'Greene', state: 'ME', state_name: 'Maine' },
  { city: 'Greenville', state: 'ME', state_name: 'Maine' },
  { city: 'Hallowell', state: 'ME', state_name: 'Maine' },
  { city: 'Hampden', state: 'ME', state_name: 'Maine' },
  { city: 'Hancock', state: 'ME', state_name: 'Maine' },
  { city: 'Hancock County', state: 'ME', state_name: 'Maine' },
  { city: 'Harpswell Center', state: 'ME', state_name: 'Maine' },
  { city: 'Harrison', state: 'ME', state_name: 'Maine' },
  { city: 'Hartford', state: 'ME', state_name: 'Maine' },
  { city: 'Hebron', state: 'ME', state_name: 'Maine' },
  { city: 'Hermon', state: 'ME', state_name: 'Maine' },
  { city: 'Hiram', state: 'ME', state_name: 'Maine' },
  { city: 'Hodgdon', state: 'ME', state_name: 'Maine' },
  { city: 'Holden', state: 'ME', state_name: 'Maine' },
  { city: 'Hollis Center', state: 'ME', state_name: 'Maine' },
  { city: 'Hope', state: 'ME', state_name: 'Maine' },
  { city: 'Houlton', state: 'ME', state_name: 'Maine' },
  { city: 'Howland', state: 'ME', state_name: 'Maine' },
  { city: 'Hudson', state: 'ME', state_name: 'Maine' },
  { city: 'Jay', state: 'ME', state_name: 'Maine' },
  { city: 'Jefferson', state: 'ME', state_name: 'Maine' },
  { city: 'Jonesport', state: 'ME', state_name: 'Maine' },
  { city: 'Kenduskeag', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebec County', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebunk', state: 'ME', state_name: 'Maine' },
  { city: 'Kennebunkport', state: 'ME', state_name: 'Maine' },
  { city: 'Kingfield', state: 'ME', state_name: 'Maine' },
  { city: 'Kittery', state: 'ME', state_name: 'Maine' },
  { city: 'Kittery Point', state: 'ME', state_name: 'Maine' },
  { city: 'Knox County', state: 'ME', state_name: 'Maine' },
  { city: 'Lake Arrowhead', state: 'ME', state_name: 'Maine' },
  { city: 'Lebanon', state: 'ME', state_name: 'Maine' },
  { city: 'Leeds', state: 'ME', state_name: 'Maine' },
  { city: 'Levant', state: 'ME', state_name: 'Maine' },
  { city: 'Lewiston', state: 'ME', state_name: 'Maine' },
  { city: 'Limerick', state: 'ME', state_name: 'Maine' },
  { city: 'Limestone', state: 'ME', state_name: 'Maine' },
  { city: 'Limington', state: 'ME', state_name: 'Maine' },
  { city: 'Lincoln', state: 'ME', state_name: 'Maine' },
  { city: 'Lincoln County', state: 'ME', state_name: 'Maine' },
  { city: 'Lincolnville', state: 'ME', state_name: 'Maine' },
  { city: 'Lisbon', state: 'ME', state_name: 'Maine' },
  { city: 'Lisbon Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Livermore', state: 'ME', state_name: 'Maine' },
  { city: 'Livermore Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Lovell', state: 'ME', state_name: 'Maine' },
  { city: 'Machias', state: 'ME', state_name: 'Maine' },
  { city: 'Machiasport', state: 'ME', state_name: 'Maine' },
  { city: 'Madawaska', state: 'ME', state_name: 'Maine' },
  { city: 'Madison', state: 'ME', state_name: 'Maine' },
  { city: 'Manchester', state: 'ME', state_name: 'Maine' },
  { city: 'Mechanic Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Medway', state: 'ME', state_name: 'Maine' },
  { city: 'Mexico', state: 'ME', state_name: 'Maine' },
  { city: 'Milbridge', state: 'ME', state_name: 'Maine' },
  { city: 'Milford', state: 'ME', state_name: 'Maine' },
  { city: 'Millinocket', state: 'ME', state_name: 'Maine' },
  { city: 'Milo', state: 'ME', state_name: 'Maine' },
  { city: 'Minot', state: 'ME', state_name: 'Maine' },
  { city: 'Monmouth', state: 'ME', state_name: 'Maine' },
  { city: 'Mount Vernon', state: 'ME', state_name: 'Maine' },
  { city: 'New Gloucester', state: 'ME', state_name: 'Maine' },
  { city: 'New Sharon', state: 'ME', state_name: 'Maine' },
  { city: 'Newfield', state: 'ME', state_name: 'Maine' },
  { city: 'Newport', state: 'ME', state_name: 'Maine' },
  { city: 'Nobleboro', state: 'ME', state_name: 'Maine' },
  { city: 'Norridgewock', state: 'ME', state_name: 'Maine' },
  { city: 'North Bath', state: 'ME', state_name: 'Maine' },
  { city: 'North Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'North Windham', state: 'ME', state_name: 'Maine' },
  { city: 'Northport', state: 'ME', state_name: 'Maine' },
  { city: 'Norway', state: 'ME', state_name: 'Maine' },
  { city: 'Oakland', state: 'ME', state_name: 'Maine' },
  { city: 'Ogunquit', state: 'ME', state_name: 'Maine' },
  { city: 'Old Orchard Beach', state: 'ME', state_name: 'Maine' },
  { city: 'Old Town', state: 'ME', state_name: 'Maine' },
  { city: 'Orland', state: 'ME', state_name: 'Maine' },
  { city: 'Orono', state: 'ME', state_name: 'Maine' },
  { city: 'Orrington', state: 'ME', state_name: 'Maine' },
  { city: 'Owls Head', state: 'ME', state_name: 'Maine' },
  { city: 'Oxford', state: 'ME', state_name: 'Maine' },
  { city: 'Oxford County', state: 'ME', state_name: 'Maine' },
  { city: 'Palermo', state: 'ME', state_name: 'Maine' },
  { city: 'Palmyra', state: 'ME', state_name: 'Maine' },
  { city: 'Paris', state: 'ME', state_name: 'Maine' },
  { city: 'Parsonsfield', state: 'ME', state_name: 'Maine' },
  { city: 'Patten', state: 'ME', state_name: 'Maine' },
  { city: 'Penobscot', state: 'ME', state_name: 'Maine' },
  { city: 'Penobscot County', state: 'ME', state_name: 'Maine' },
  { city: 'Peru', state: 'ME', state_name: 'Maine' },
  { city: 'Phillips', state: 'ME', state_name: 'Maine' },
  { city: 'Phippsburg', state: 'ME', state_name: 'Maine' },
  { city: 'Piscataquis County', state: 'ME', state_name: 'Maine' },
  { city: 'Pittsfield', state: 'ME', state_name: 'Maine' },
  { city: 'Pittston', state: 'ME', state_name: 'Maine' },
  { city: 'Plymouth', state: 'ME', state_name: 'Maine' },
  { city: 'Poland', state: 'ME', state_name: 'Maine' },
  { city: 'Porter', state: 'ME', state_name: 'Maine' },
  { city: 'Portland', state: 'ME', state_name: 'Maine' },
  { city: 'Presque Isle', state: 'ME', state_name: 'Maine' },
  { city: 'Randolph', state: 'ME', state_name: 'Maine' },
  { city: 'Raymond', state: 'ME', state_name: 'Maine' },
  { city: 'Readfield', state: 'ME', state_name: 'Maine' },
  { city: 'Richmond', state: 'ME', state_name: 'Maine' },
  { city: 'Rockland', state: 'ME', state_name: 'Maine' },
  { city: 'Rockport', state: 'ME', state_name: 'Maine' },
  { city: 'Rome', state: 'ME', state_name: 'Maine' },
  { city: 'Rumford', state: 'ME', state_name: 'Maine' },
  { city: 'Sabattus', state: 'ME', state_name: 'Maine' },
  { city: 'Saco', state: 'ME', state_name: 'Maine' },
  { city: 'Sagadahoc County', state: 'ME', state_name: 'Maine' },
  { city: 'Saint Albans', state: 'ME', state_name: 'Maine' },
  { city: 'Saint George', state: 'ME', state_name: 'Maine' },
  { city: 'Sanford', state: 'ME', state_name: 'Maine' },
  { city: 'Sangerville', state: 'ME', state_name: 'Maine' },
  { city: 'Scarborough', state: 'ME', state_name: 'Maine' },
  { city: 'Searsmont', state: 'ME', state_name: 'Maine' },
  { city: 'Sedgwick', state: 'ME', state_name: 'Maine' },
  { city: 'Shapleigh', state: 'ME', state_name: 'Maine' },
  { city: 'Sidney', state: 'ME', state_name: 'Maine' },
  { city: 'Skowhegan', state: 'ME', state_name: 'Maine' },
  { city: 'Somerset County', state: 'ME', state_name: 'Maine' },
  { city: 'South Berwick', state: 'ME', state_name: 'Maine' },
  { city: 'South Eliot', state: 'ME', state_name: 'Maine' },
  { city: 'South Paris', state: 'ME', state_name: 'Maine' },
  { city: 'South Portland', state: 'ME', state_name: 'Maine' },
  { city: 'South Portland Gardens', state: 'ME', state_name: 'Maine' },
  { city: 'South Sanford', state: 'ME', state_name: 'Maine' },
  { city: 'South Thomaston', state: 'ME', state_name: 'Maine' },
  { city: 'South Windham', state: 'ME', state_name: 'Maine' },
  { city: 'Springvale', state: 'ME', state_name: 'Maine' },
  { city: 'Steep Falls', state: 'ME', state_name: 'Maine' },
  { city: 'Stetson', state: 'ME', state_name: 'Maine' },
  { city: 'Steuben', state: 'ME', state_name: 'Maine' },
  { city: 'Stockton Springs', state: 'ME', state_name: 'Maine' },
  { city: 'Stonington', state: 'ME', state_name: 'Maine' },
  { city: 'Strong', state: 'ME', state_name: 'Maine' },
  { city: 'Sullivan', state: 'ME', state_name: 'Maine' },
  { city: 'Surry', state: 'ME', state_name: 'Maine' },
  { city: 'Swanville', state: 'ME', state_name: 'Maine' },
  { city: 'Thomaston', state: 'ME', state_name: 'Maine' },
  { city: 'Topsham', state: 'ME', state_name: 'Maine' },
  { city: 'Tremont', state: 'ME', state_name: 'Maine' },
  { city: 'Trenton', state: 'ME', state_name: 'Maine' },
  { city: 'Troy', state: 'ME', state_name: 'Maine' },
  { city: 'Turner', state: 'ME', state_name: 'Maine' },
  { city: 'Union', state: 'ME', state_name: 'Maine' },
  { city: 'Van Buren', state: 'ME', state_name: 'Maine' },
  { city: 'Vassalboro', state: 'ME', state_name: 'Maine' },
  { city: 'Veazie', state: 'ME', state_name: 'Maine' },
  { city: 'Vinalhaven', state: 'ME', state_name: 'Maine' },
  { city: 'Waldo County', state: 'ME', state_name: 'Maine' },
  { city: 'Waldoboro', state: 'ME', state_name: 'Maine' },
  { city: 'Warren', state: 'ME', state_name: 'Maine' },
  { city: 'Washington', state: 'ME', state_name: 'Maine' },
  { city: 'Washington County', state: 'ME', state_name: 'Maine' },
  { city: 'Waterboro', state: 'ME', state_name: 'Maine' },
  { city: 'Waterville', state: 'ME', state_name: 'Maine' },
  { city: 'Wayne', state: 'ME', state_name: 'Maine' },
  { city: 'Wells Beach Station', state: 'ME', state_name: 'Maine' },
  { city: 'West Kennebunk', state: 'ME', state_name: 'Maine' },
  { city: 'West Paris', state: 'ME', state_name: 'Maine' },
  { city: 'West Scarborough', state: 'ME', state_name: 'Maine' },
  { city: 'Westbrook', state: 'ME', state_name: 'Maine' },
  { city: 'Whitefield', state: 'ME', state_name: 'Maine' },
  { city: 'Wilton', state: 'ME', state_name: 'Maine' },
  { city: 'Windsor', state: 'ME', state_name: 'Maine' },
  { city: 'Winslow', state: 'ME', state_name: 'Maine' },
  { city: 'Winterport', state: 'ME', state_name: 'Maine' },
  { city: 'Winthrop', state: 'ME', state_name: 'Maine' },
  { city: 'Wiscasset', state: 'ME', state_name: 'Maine' },
  { city: 'Woodstock', state: 'ME', state_name: 'Maine' },
  { city: 'Woolwich', state: 'ME', state_name: 'Maine' },
  { city: 'Yarmouth', state: 'ME', state_name: 'Maine' },
  { city: 'York Beach', state: 'ME', state_name: 'Maine' },
  { city: 'York County', state: 'ME', state_name: 'Maine' },
  { city: 'York Harbor', state: 'ME', state_name: 'Maine' },
  { city: 'Aberdeen', state: 'MD', state_name: 'Maryland' },
  { city: 'Aberdeen Proving Ground', state: 'MD', state_name: 'Maryland' },
  { city: 'Accokeek', state: 'MD', state_name: 'Maryland' },
  { city: 'Adamstown', state: 'MD', state_name: 'Maryland' },
  { city: 'Adelphi', state: 'MD', state_name: 'Maryland' },
  { city: 'Algonquin', state: 'MD', state_name: 'Maryland' },
  { city: 'Allegany County', state: 'MD', state_name: 'Maryland' },
  { city: 'Andrews AFB', state: 'MD', state_name: 'Maryland' },
  { city: 'Annapolis', state: 'MD', state_name: 'Maryland' },
  { city: 'Anne Arundel County', state: 'MD', state_name: 'Maryland' },
  { city: 'Arbutus', state: 'MD', state_name: 'Maryland' },
  { city: 'Arden on the Severn', state: 'MD', state_name: 'Maryland' },
  { city: 'Arlington', state: 'MD', state_name: 'Maryland' },
  { city: 'Arnold', state: 'MD', state_name: 'Maryland' },
  { city: 'Ashton-Sandy Spring', state: 'MD', state_name: 'Maryland' },
  { city: 'Aspen Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Baden', state: 'MD', state_name: 'Maryland' },
  { city: 'Ballenger Creek', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore County', state: 'MD', state_name: 'Maryland' },
  { city: 'Baltimore Highlands', state: 'MD', state_name: 'Maryland' },
  { city: 'Bartonsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air North', state: 'MD', state_name: 'Maryland' },
  { city: 'Bel Air South', state: 'MD', state_name: 'Maryland' },
  { city: 'Beltsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Bennsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Berlin', state: 'MD', state_name: 'Maryland' },
  { city: 'Berwyn Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Bethesda', state: 'MD', state_name: 'Maryland' },
  { city: 'Bladensburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Boonsboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowie', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowleys Quarters', state: 'MD', state_name: 'Maryland' },
  { city: 'Bowling Green', state: 'MD', state_name: 'Maryland' },
  { city: 'Braddock Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Brandywine', state: 'MD', state_name: 'Maryland' },
  { city: 'Brentwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Brock Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Brooklyn Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Brookmont', state: 'MD', state_name: 'Maryland' },
  { city: 'Brunswick', state: 'MD', state_name: 'Maryland' },
  { city: 'Bryans Road', state: 'MD', state_name: 'Maryland' },
  { city: 'Buckeystown', state: 'MD', state_name: 'Maryland' },
  { city: 'Burtonsville', state: 'MD', state_name: 'Maryland' },
  { city: "Butcher's Hill", state: 'MD', state_name: 'Maryland' },
  { city: 'Cabin John', state: 'MD', state_name: 'Maryland' },
  { city: 'California', state: 'MD', state_name: 'Maryland' },
  { city: 'Calvert County', state: 'MD', state_name: 'Maryland' },
  { city: 'Calverton', state: 'MD', state_name: 'Maryland' },
  { city: 'Cambridge', state: 'MD', state_name: 'Maryland' },
  { city: 'Camp Springs', state: 'MD', state_name: 'Maryland' },
  { city: 'Cape Saint Claire', state: 'MD', state_name: 'Maryland' },
  { city: 'Capitol Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Carney', state: 'MD', state_name: 'Maryland' },
  { city: 'Caroline County', state: 'MD', state_name: 'Maryland' },
  { city: 'Carroll County', state: 'MD', state_name: 'Maryland' },
  { city: 'Catonsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Cavetown', state: 'MD', state_name: 'Maryland' },
  { city: 'Cecil County', state: 'MD', state_name: 'Maryland' },
  { city: 'Centreville', state: 'MD', state_name: 'Maryland' },
  { city: 'Charles County', state: 'MD', state_name: 'Maryland' },
  { city: 'Charles Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Charlestown', state: 'MD', state_name: 'Maryland' },
  { city: 'Charlotte Hall', state: 'MD', state_name: 'Maryland' },
  { city: 'Chesapeake Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Chesapeake Ranch Estates', state: 'MD', state_name: 'Maryland' },
  { city: 'Chester', state: 'MD', state_name: 'Maryland' },
  { city: 'Chestertown', state: 'MD', state_name: 'Maryland' },
  { city: 'Cheverly', state: 'MD', state_name: 'Maryland' },
  { city: 'Chevy Chase', state: 'MD', state_name: 'Maryland' },
  { city: 'Chevy Chase Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Chillum', state: 'MD', state_name: 'Maryland' },
  { city: 'City of Baltimore', state: 'MD', state_name: 'Maryland' },
  { city: 'Clarksburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Clinton', state: 'MD', state_name: 'Maryland' },
  { city: 'Clover Hill', state: 'MD', state_name: 'Maryland' },
  { city: 'Cloverly', state: 'MD', state_name: 'Maryland' },
  { city: 'Cobb Island', state: 'MD', state_name: 'Maryland' },
  { city: 'Cockeysville', state: 'MD', state_name: 'Maryland' },
  { city: 'Colesville', state: 'MD', state_name: 'Maryland' },
  { city: 'College Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Colmar Manor', state: 'MD', state_name: 'Maryland' },
  { city: 'Columbia', state: 'MD', state_name: 'Maryland' },
  { city: 'Coral Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Cottage City', state: 'MD', state_name: 'Maryland' },
  { city: 'Cresaptown', state: 'MD', state_name: 'Maryland' },
  { city: 'Crisfield', state: 'MD', state_name: 'Maryland' },
  { city: 'Crofton', state: 'MD', state_name: 'Maryland' },
  { city: 'Croom', state: 'MD', state_name: 'Maryland' },
  { city: 'Crownsville', state: 'MD', state_name: 'Maryland' },
  { city: 'Cumberland', state: 'MD', state_name: 'Maryland' },
  { city: 'Damascus', state: 'MD', state_name: 'Maryland' },
  { city: 'Darnestown', state: 'MD', state_name: 'Maryland' },
  { city: 'Davidsonville', state: 'MD', state_name: 'Maryland' },
  { city: 'Deale', state: 'MD', state_name: 'Maryland' },
  { city: 'Denton', state: 'MD', state_name: 'Maryland' },
  { city: 'Derwood', state: 'MD', state_name: 'Maryland' },
  { city: 'District Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Dorchester County', state: 'MD', state_name: 'Maryland' },
  { city: 'Drum Point', state: 'MD', state_name: 'Maryland' },
  { city: 'Dundalk', state: 'MD', state_name: 'Maryland' },
  { city: 'Dunkirk', state: 'MD', state_name: 'Maryland' },
  { city: 'Dunkirk Town Center', state: 'MD', state_name: 'Maryland' },
  { city: 'East Riverdale', state: 'MD', state_name: 'Maryland' },
  { city: 'Easton', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgemere', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgewater', state: 'MD', state_name: 'Maryland' },
  { city: 'Edgewood', state: 'MD', state_name: 'Maryland' },
  { city: 'Edmonston', state: 'MD', state_name: 'Maryland' },
  { city: 'Eldersburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Elkridge', state: 'MD', state_name: 'Maryland' },
  { city: 'Elkton', state: 'MD', state_name: 'Maryland' },
  { city: 'Ellicott City', state: 'MD', state_name: 'Maryland' },
  { city: 'Emmitsburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Essex', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairland', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairmount Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Fairwood', state: 'MD', state_name: 'Maryland' },
  { city: 'Fallston', state: 'MD', state_name: 'Maryland' },
  { city: 'Federalsburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Ferndale', state: 'MD', state_name: 'Maryland' },
  { city: 'Forest Glen', state: 'MD', state_name: 'Maryland' },
  { city: 'Forest Heights', state: 'MD', state_name: 'Maryland' },
  { city: 'Forestville', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort George G Mead Junction', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort Meade', state: 'MD', state_name: 'Maryland' },
  { city: 'Fort Washington', state: 'MD', state_name: 'Maryland' },
  { city: 'Fountainhead-Orchard Hills', state: 'MD', state_name: 'Maryland' },
  { city: 'Four Corners', state: 'MD', state_name: 'Maryland' },
  { city: 'Frederick', state: 'MD', state_name: 'Maryland' },
  { city: 'Frederick County', state: 'MD', state_name: 'Maryland' },
  { city: 'Friendly', state: 'MD', state_name: 'Maryland' },
  { city: 'Friendship Village', state: 'MD', state_name: 'Maryland' },
  { city: 'Frostburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Fruitland', state: 'MD', state_name: 'Maryland' },
  { city: 'Fulton', state: 'MD', state_name: 'Maryland' },
  { city: 'Gaithersburg', state: 'MD', state_name: 'Maryland' },
  { city: 'Gambrills', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrett County', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrett Park', state: 'MD', state_name: 'Maryland' },
  { city: 'Garrison', state: 'MD', state_name: 'Maryland' },
  { city: 'Germantown', state: 'MD', state_name: 'Maryland' },
  { city: 'Glassmanor', state: 'MD', state_name: 'Maryland' },
  { city: 'Glen Burnie', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenarden', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenmont', state: 'MD', state_name: 'Maryland' },
  { city: 'Glenn Dale', state: 'MD', state_name: 'Maryland' },
  { city: 'Goddard', state: 'MD', state_name: 'Maryland' },
  { city: 'Golden Beach', state: 'MD', state_name: 'Maryland' },
  { city: 'Grasonville', state: 'MD', state_name: 'Maryland' },
  { city: 'Greater Upper Marlboro', state: 'MD', state_name: 'Maryland' },
  { city: 'Green Haven', state: 'MD', state_name: 'Maryland' },
  { city: 'Green Valley', state: 'MD', state_name: 'Maryland' },
  { city: 'Greenbelt', state: 'MD', state_name: 'Maryland' },

  //YHA

  { city: 'Bridgeport', state: 'WA', state_name: 'Washington' },
  { city: 'Brier', state: 'WA', state_name: 'Washington' },
  { city: 'Browns Point', state: 'WA', state_name: 'Washington' },
  { city: 'Brush Prairie', state: 'WA', state_name: 'Washington' },
  { city: 'Bryant', state: 'WA', state_name: 'Washington' },
  { city: 'Bryn Mawr-Skyway', state: 'WA', state_name: 'Washington' },
  { city: 'Buckley', state: 'WA', state_name: 'Washington' },
  { city: 'Bunk Foss', state: 'WA', state_name: 'Washington' },
  { city: 'Burbank', state: 'WA', state_name: 'Washington' },
  { city: 'Burien', state: 'WA', state_name: 'Washington' },
  { city: 'Burley', state: 'WA', state_name: 'Washington' },
  { city: 'Burlington', state: 'WA', state_name: 'Washington' },
  { city: 'Camano', state: 'WA', state_name: 'Washington' },
  { city: 'Camas', state: 'WA', state_name: 'Washington' },
  { city: 'Canterwood', state: 'WA', state_name: 'Washington' },
  { city: 'Carnation', state: 'WA', state_name: 'Washington' },
  { city: 'Carson', state: 'WA', state_name: 'Washington' },
  { city: 'Cascade Valley', state: 'WA', state_name: 'Washington' },
  { city: 'Cashmere', state: 'WA', state_name: 'Washington' },
  { city: 'Castle Rock', state: 'WA', state_name: 'Washington' },
  { city: 'Cathcart', state: 'WA', state_name: 'Washington' },
  { city: 'Cathlamet', state: 'WA', state_name: 'Washington' },
  { city: 'Central Park', state: 'WA', state_name: 'Washington' },
  { city: 'Centralia', state: 'WA', state_name: 'Washington' },
  { city: 'Chehalis', state: 'WA', state_name: 'Washington' },
  { city: 'Chelan', state: 'WA', state_name: 'Washington' },
  { city: 'Chelan County', state: 'WA', state_name: 'Washington' },
  { city: 'Cheney', state: 'WA', state_name: 'Washington' },
  { city: 'Chewelah', state: 'WA', state_name: 'Washington' },
  { city: 'Chico', state: 'WA', state_name: 'Washington' },
  { city: 'City of Sammamish', state: 'WA', state_name: 'Washington' },
  { city: 'Clallam County', state: 'WA', state_name: 'Washington' },
  { city: 'Clark County', state: 'WA', state_name: 'Washington' },
  { city: 'Clarkston', state: 'WA', state_name: 'Washington' },
  { city: 'Clarkston Heights-Vineland', state: 'WA', state_name: 'Washington' },
  { city: 'Cle Elum', state: 'WA', state_name: 'Washington' },
  { city: 'Clear Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Clearview', state: 'WA', state_name: 'Washington' },
  { city: 'Clyde Hill', state: 'WA', state_name: 'Washington' },
  { city: 'Colfax', state: 'WA', state_name: 'Washington' },
  { city: 'College Place', state: 'WA', state_name: 'Washington' },
  { city: 'Columbia County', state: 'WA', state_name: 'Washington' },
  { city: 'Colville', state: 'WA', state_name: 'Washington' },
  { city: 'Connell', state: 'WA', state_name: 'Washington' },
  { city: 'Cosmopolis', state: 'WA', state_name: 'Washington' },
  { city: 'Cottage Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Coulee Dam', state: 'WA', state_name: 'Washington' },
  { city: 'Country Homes', state: 'WA', state_name: 'Washington' },
  { city: 'Coupeville', state: 'WA', state_name: 'Washington' },
  { city: 'Covington', state: 'WA', state_name: 'Washington' },
  { city: 'Cowlitz County', state: 'WA', state_name: 'Washington' },
  { city: 'Crocker', state: 'WA', state_name: 'Washington' },
  { city: 'Dallesport', state: 'WA', state_name: 'Washington' },
  { city: 'Darrington', state: 'WA', state_name: 'Washington' },
  { city: 'Davenport', state: 'WA', state_name: 'Washington' },
  { city: 'Dayton', state: 'WA', state_name: 'Washington' },
  { city: 'Deer Park', state: 'WA', state_name: 'Washington' },
  { city: 'Des Moines', state: 'WA', state_name: 'Washington' },
  { city: 'Desert Aire', state: 'WA', state_name: 'Washington' },
  { city: 'Dishman', state: 'WA', state_name: 'Washington' },
  { city: 'Dollar Corner', state: 'WA', state_name: 'Washington' },
  { city: 'Douglas County', state: 'WA', state_name: 'Washington' },
  { city: 'DuPont', state: 'WA', state_name: 'Washington' },
  { city: 'Duvall', state: 'WA', state_name: 'Washington' },
  { city: 'East Hill-Meridian', state: 'WA', state_name: 'Washington' },
  { city: 'East Port Orchard', state: 'WA', state_name: 'Washington' },
  { city: 'East Renton Highlands', state: 'WA', state_name: 'Washington' },
  { city: 'East Wenatchee', state: 'WA', state_name: 'Washington' },
  { city: 'East Wenatchee Bench', state: 'WA', state_name: 'Washington' },
  { city: 'Eastgate', state: 'WA', state_name: 'Washington' },
  { city: 'Eastmont', state: 'WA', state_name: 'Washington' },
  { city: 'Eatonville', state: 'WA', state_name: 'Washington' },
  { city: 'Edgewood', state: 'WA', state_name: 'Washington' },
  { city: 'Edmonds', state: 'WA', state_name: 'Washington' },
  { city: 'Electric City', state: 'WA', state_name: 'Washington' },
  { city: 'Elk Plain', state: 'WA', state_name: 'Washington' },
  { city: 'Ellensburg', state: 'WA', state_name: 'Washington' },
  { city: 'Elma', state: 'WA', state_name: 'Washington' },
  { city: 'Enetai', state: 'WA', state_name: 'Washington' },
  { city: 'Entiat', state: 'WA', state_name: 'Washington' },
  { city: 'Enumclaw', state: 'WA', state_name: 'Washington' },
  { city: 'Ephrata', state: 'WA', state_name: 'Washington' },
  { city: 'Erlands Point-Kitsap Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Esperance', state: 'WA', state_name: 'Washington' },
  { city: 'Everett', state: 'WA', state_name: 'Washington' },
  { city: 'Everson', state: 'WA', state_name: 'Washington' },
  { city: 'Fairchild Air Force Base', state: 'WA', state_name: 'Washington' },
  { city: 'Fairwood', state: 'WA', state_name: 'Washington' },
  { city: 'Fall City', state: 'WA', state_name: 'Washington' },
  { city: 'Federal Way', state: 'WA', state_name: 'Washington' },
  { city: 'Felida', state: 'WA', state_name: 'Washington' },
  { city: 'Fern Prairie', state: 'WA', state_name: 'Washington' },
  { city: 'Ferndale', state: 'WA', state_name: 'Washington' },
  { city: 'Ferry County', state: 'WA', state_name: 'Washington' },
  { city: 'Fife', state: 'WA', state_name: 'Washington' },
  { city: 'Fife Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Finley', state: 'WA', state_name: 'Washington' },
  { city: 'Fircrest', state: 'WA', state_name: 'Washington' },
  { city: 'Five Corners', state: 'WA', state_name: 'Washington' },
  { city: 'Fobes Hill', state: 'WA', state_name: 'Washington' },
  { city: 'Fords Prairie', state: 'WA', state_name: 'Washington' },
  { city: 'Forks', state: 'WA', state_name: 'Washington' },
  { city: 'Fox Island', state: 'WA', state_name: 'Washington' },
  { city: 'Franklin County', state: 'WA', state_name: 'Washington' },
  { city: 'Frederickson', state: 'WA', state_name: 'Washington' },
  { city: 'Freeland', state: 'WA', state_name: 'Washington' },
  { city: 'Friday Harbor', state: 'WA', state_name: 'Washington' },
  { city: 'Garfield County', state: 'WA', state_name: 'Washington' },
  { city: 'Garrett', state: 'WA', state_name: 'Washington' },
  { city: 'Geneva', state: 'WA', state_name: 'Washington' },
  { city: 'Gig Harbor', state: 'WA', state_name: 'Washington' },
  { city: 'Gleed', state: 'WA', state_name: 'Washington' },
  { city: 'Gold Bar', state: 'WA', state_name: 'Washington' },
  { city: 'Goldendale', state: 'WA', state_name: 'Washington' },
  { city: 'Graham', state: 'WA', state_name: 'Washington' },
  { city: 'Grand Coulee', state: 'WA', state_name: 'Washington' },
  { city: 'Grand Mound', state: 'WA', state_name: 'Washington' },
  { city: 'Grandview', state: 'WA', state_name: 'Washington' },
  { city: 'Granger', state: 'WA', state_name: 'Washington' },
  { city: 'Granite Falls', state: 'WA', state_name: 'Washington' },
  { city: 'Grant County', state: 'WA', state_name: 'Washington' },
  { city: 'Grays Harbor County', state: 'WA', state_name: 'Washington' },
  { city: 'Hansville', state: 'WA', state_name: 'Washington' },
  { city: 'Hazel Dell', state: 'WA', state_name: 'Washington' },
  { city: 'Highland', state: 'WA', state_name: 'Washington' },
  { city: 'Hobart', state: 'WA', state_name: 'Washington' },
  { city: 'Hockinson', state: 'WA', state_name: 'Washington' },
  { city: 'Home', state: 'WA', state_name: 'Washington' },
  { city: 'Hoquiam', state: 'WA', state_name: 'Washington' },
  { city: 'Indianola', state: 'WA', state_name: 'Washington' },
  { city: 'Inglewood-Finn Hill', state: 'WA', state_name: 'Washington' },
  { city: 'Island County', state: 'WA', state_name: 'Washington' },
  { city: 'Issaquah', state: 'WA', state_name: 'Washington' },
  { city: 'Jefferson County', state: 'WA', state_name: 'Washington' },
  { city: 'Joint Base Lewis McChord', state: 'WA', state_name: 'Washington' },
  { city: 'Kalama', state: 'WA', state_name: 'Washington' },
  { city: 'Kelso', state: 'WA', state_name: 'Washington' },
  { city: 'Kenmore', state: 'WA', state_name: 'Washington' },
  { city: 'Kennewick', state: 'WA', state_name: 'Washington' },
  { city: 'Kent', state: 'WA', state_name: 'Washington' },
  { city: 'Kettle Falls', state: 'WA', state_name: 'Washington' },
  { city: 'Key Center', state: 'WA', state_name: 'Washington' },
  { city: 'King County', state: 'WA', state_name: 'Washington' },
  { city: 'Kingsgate', state: 'WA', state_name: 'Washington' },
  { city: 'Kingston', state: 'WA', state_name: 'Washington' },
  { city: 'Kirkland', state: 'WA', state_name: 'Washington' },
  { city: 'Kitsap County', state: 'WA', state_name: 'Washington' },
  { city: 'Kittitas', state: 'WA', state_name: 'Washington' },
  { city: 'Kittitas County', state: 'WA', state_name: 'Washington' },
  { city: 'Klahanie', state: 'WA', state_name: 'Washington' },
  { city: 'Klickitat County', state: 'WA', state_name: 'Washington' },
  { city: 'La Center', state: 'WA', state_name: 'Washington' },
  { city: 'Lacey', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Forest Park', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Marcel-Stillwater', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Morton-Berrydale', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Shore', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Stevens', state: 'WA', state_name: 'Washington' },
  { city: 'Lake Stickney', state: 'WA', state_name: 'Washington' },
  { city: 'Lakeland North', state: 'WA', state_name: 'Washington' },
  { city: 'Lakeland South', state: 'WA', state_name: 'Washington' },
  { city: 'Lakewood', state: 'WA', state_name: 'Washington' },
  { city: 'Langley', state: 'WA', state_name: 'Washington' },
  { city: 'Larch Way', state: 'WA', state_name: 'Washington' },
  { city: 'Lea Hill', state: 'WA', state_name: 'Washington' },
  { city: 'Leavenworth', state: 'WA', state_name: 'Washington' },
  { city: 'Lewis County', state: 'WA', state_name: 'Washington' },
  { city: 'Lewisville', state: 'WA', state_name: 'Washington' },
  { city: 'Liberty Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Lincoln County', state: 'WA', state_name: 'Washington' },
  { city: 'Lochsloy', state: 'WA', state_name: 'Washington' },
  { city: 'Lofall', state: 'WA', state_name: 'Washington' },
  { city: 'Long Beach', state: 'WA', state_name: 'Washington' },
  { city: 'Longbranch', state: 'WA', state_name: 'Washington' },
  { city: 'Longview', state: 'WA', state_name: 'Washington' },
  { city: 'Longview Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Lynden', state: 'WA', state_name: 'Washington' },
  { city: 'Lynnwood', state: 'WA', state_name: 'Washington' },
  { city: 'Mabton', state: 'WA', state_name: 'Washington' },
  { city: 'Machias', state: 'WA', state_name: 'Washington' },
  { city: 'Maltby', state: 'WA', state_name: 'Washington' },
  { city: 'Manchester', state: 'WA', state_name: 'Washington' },
  { city: 'Manson', state: 'WA', state_name: 'Washington' },
  { city: 'Maple Heights-Lake Desire', state: 'WA', state_name: 'Washington' },
  { city: 'Maple Valley', state: 'WA', state_name: 'Washington' },
  { city: 'Maplewood', state: 'WA', state_name: 'Washington' },
  { city: 'Marietta', state: 'WA', state_name: 'Washington' },
  { city: 'Marietta-Alderwood', state: 'WA', state_name: 'Washington' },
  { city: 'Martha Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Marysville', state: 'WA', state_name: 'Washington' },
  { city: 'Mason County', state: 'WA', state_name: 'Washington' },
  { city: 'Mattawa', state: 'WA', state_name: 'Washington' },
  { city: 'McChord Air Force Base', state: 'WA', state_name: 'Washington' },
  { city: 'McCleary', state: 'WA', state_name: 'Washington' },
  { city: 'McMillin', state: 'WA', state_name: 'Washington' },
  { city: 'Mead', state: 'WA', state_name: 'Washington' },
  { city: 'Meadow Glade', state: 'WA', state_name: 'Washington' },
  { city: 'Meadowdale', state: 'WA', state_name: 'Washington' },
  { city: 'Medical Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Medina', state: 'WA', state_name: 'Washington' },
  { city: 'Mercer Island', state: 'WA', state_name: 'Washington' },
  { city: 'Midland', state: 'WA', state_name: 'Washington' },
  { city: 'Mill Creek', state: 'WA', state_name: 'Washington' },
  { city: 'Mill Creek East', state: 'WA', state_name: 'Washington' },
  { city: 'Mill Plain', state: 'WA', state_name: 'Washington' },
  { city: 'Millwood', state: 'WA', state_name: 'Washington' },
  { city: 'Milton', state: 'WA', state_name: 'Washington' },
  { city: 'Minnehaha', state: 'WA', state_name: 'Washington' },
  { city: 'Mirrormont', state: 'WA', state_name: 'Washington' },
  { city: 'Monroe', state: 'WA', state_name: 'Washington' },
  { city: 'Monroe North', state: 'WA', state_name: 'Washington' },
  { city: 'Montesano', state: 'WA', state_name: 'Washington' },
  { city: 'Morton', state: 'WA', state_name: 'Washington' },
  { city: 'Moses Lake', state: 'WA', state_name: 'Washington' },
  { city: 'Moses Lake North', state: 'WA', state_name: 'Washington' },
  { city: 'Mount Vernon', state: 'WA', state_name: 'Washington' },
  { city: 'Mount Vista', state: 'WA', state_name: 'Washington' },
  { city: 'Mountlake Terrace', state: 'WA', state_name: 'Washington' },
  { city: 'Mukilteo', state: 'WA', state_name: 'Washington' },
  { city: 'Napavine', state: 'WA', state_name: 'Washington' },
  { city: 'Navy Yard City', state: 'WA', state_name: 'Washington' },
  { city: 'Newcastle', state: 'WA', state_name: 'Washington' },
  { city: 'Newport', state: 'WA', state_name: 'Washington' },
  { city: 'Nooksack', state: 'WA', state_name: 'Washington' },
  { city: 'Normandy Park', state: 'WA', state_name: 'Washington' },
  { city: 'North Bend', state: 'WA', state_name: 'Washington' },
  { city: 'North Creek', state: 'WA', state_name: 'Washington' },
  { city: 'North Fort Lewis', state: 'WA', state_name: 'Washington' },
  { city: 'North Puyallup', state: 'WA', state_name: 'Washington' },
  { city: 'North Yelm', state: 'WA', state_name: 'Washington' },
  { city: 'Oak Harbor', state: 'WA', state_name: 'Washington' },
  { city: 'Ocean Park', state: 'WA', state_name: 'Washington' },
  { city: 'Ocean Shores', state: 'WA', state_name: 'Washington' },
  { city: 'Okanogan', state: 'WA', state_name: 'Washington' },
  { city: 'Okanogan County', state: 'WA', state_name: 'Washington' },
  { city: 'Olympia', state: 'WA', state_name: 'Washington' },
  { city: 'Omak', state: 'WA', state_name: 'Washington' },
  { city: 'Opportunity', state: 'WA', state_name: 'Washington' },
  { city: 'Orchards', state: 'WA', state_name: 'Washington' },
  { city: 'Oroville', state: 'WA', state_name: 'Washington' },
  { city: 'Orting', state: 'WA', state_name: 'Washington' },
  { city: 'Othello', state: 'WA', state_name: 'Washington' },
  { city: 'Otis Orchards-East Farms', state: 'WA', state_name: 'Washington' },
  { city: 'Pacific', state: 'WA', state_name: 'Washington' },
  { city: 'Pacific County', state: 'WA', state_name: 'Washington' },
  { city: 'Palouse', state: 'WA', state_name: 'Washington' },
  { city: 'Parkland', state: 'WA', state_name: 'Washington' },
  { city: 'Parkwood', state: 'WA', state_name: 'Washington' },
  { city: 'Pasco', state: 'WA', state_name: 'Washington' },
  { city: 'Peaceful Valley', state: 'WA', state_name: 'Washington' },
  { city: 'Pend Oreille County', state: 'WA', state_name: 'Washington' },
  { city: 'Picnic Point', state: 'WA', state_name: 'Washington' },
  {
    city: 'Picnic Point-North Lynnwood',
    state: 'WA',
    state_name: 'Washington',
  },
  { city: 'Pierce County', state: 'WA', state_name: 'Washington' },
  { city: 'Point Roberts', state: 'WA', state_name: 'Washington' },
  { city: 'Pomeroy', state: 'WA', state_name: 'Washington' },
  { city: 'Port Angeles', state: 'WA', state_name: 'Washington' },
  { city: 'Port Angeles East', state: 'WA', state_name: 'Washington' },
  { city: 'Port Hadlock-Irondale', state: 'WA', state_name: 'Washington' },
  { city: 'Port Ludlow', state: 'WA', state_name: 'Washington' },
  { city: 'Port Orchard', state: 'WA', state_name: 'Washington' },
  { city: 'Port Townsend', state: 'WA', state_name: 'Washington' },
  { city: 'Poulsbo', state: 'WA', state_name: 'Washington' },
  { city: 'Prairie Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Prairie Ridge', state: 'WA', state_name: 'Washington' },
  { city: 'Prosser', state: 'WA', state_name: 'Washington' },
  { city: 'Pullman', state: 'WA', state_name: 'Washington' },
  { city: 'Purdy', state: 'WA', state_name: 'Washington' },
  { city: 'Puyallup', state: 'WA', state_name: 'Washington' },
  { city: 'Quincy', state: 'WA', state_name: 'Washington' },
  { city: 'Rainier', state: 'WA', state_name: 'Washington' },
  { city: 'Ravensdale', state: 'WA', state_name: 'Washington' },
  { city: 'Raymond', state: 'WA', state_name: 'Washington' },
  { city: 'Redmond', state: 'WA', state_name: 'Washington' },
  { city: 'Renton', state: 'WA', state_name: 'Washington' },
  { city: 'Republic', state: 'WA', state_name: 'Washington' },
  { city: 'Richland', state: 'WA', state_name: 'Washington' },
  { city: 'Ridgefield', state: 'WA', state_name: 'Washington' },
  { city: 'Ritzville', state: 'WA', state_name: 'Washington' },
  { city: 'Riverbend', state: 'WA', state_name: 'Washington' },
  { city: 'Riverton', state: 'WA', state_name: 'Washington' },
  { city: 'Rochester', state: 'WA', state_name: 'Washington' },
  { city: 'Rocky Point', state: 'WA', state_name: 'Washington' },
  { city: 'Rosedale', state: 'WA', state_name: 'Washington' },
  { city: 'Royal City', state: 'WA', state_name: 'Washington' },
  { city: 'Salmon Creek', state: 'WA', state_name: 'Washington' },
  { city: 'Sammamish', state: 'WA', state_name: 'Washington' },
  { city: 'San Juan County', state: 'WA', state_name: 'Washington' },
  { city: 'Seabeck', state: 'WA', state_name: 'Washington' },
  { city: 'SeaTac', state: 'WA', state_name: 'Washington' },
  { city: 'Seattle', state: 'WA', state_name: 'Washington' },
  { city: 'Sedro-Woolley', state: 'WA', state_name: 'Washington' },
  { city: 'Selah', state: 'WA', state_name: 'Washington' },
  { city: 'Sequim', state: 'WA', state_name: 'Washington' },
  { city: 'Shelton', state: 'WA', state_name: 'Washington' },
  { city: 'Shoreline', state: 'WA', state_name: 'Washington' },
  { city: 'Silver Firs', state: 'WA', state_name: 'Washington' },
  { city: 'Silverdale', state: 'WA', state_name: 'Washington' },
  { city: 'Sisco Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Skagit County', state: 'WA', state_name: 'Washington' },
  { city: 'Skamania County', state: 'WA', state_name: 'Washington' },
  { city: 'Smokey Point', state: 'WA', state_name: 'Washington' },
  { city: 'Snohomish', state: 'WA', state_name: 'Washington' },
  { city: 'Snohomish County', state: 'WA', state_name: 'Washington' },
  { city: 'Snoqualmie', state: 'WA', state_name: 'Washington' },
  { city: 'Soap Lake', state: 'WA', state_name: 'Washington' },
  { city: 'South Bend', state: 'WA', state_name: 'Washington' },
  { city: 'South Hill', state: 'WA', state_name: 'Washington' },
  { city: 'South Wenatchee', state: 'WA', state_name: 'Washington' },
  { city: 'Southworth', state: 'WA', state_name: 'Washington' },
  { city: 'Spanaway', state: 'WA', state_name: 'Washington' },
  { city: 'Spokane', state: 'WA', state_name: 'Washington' },
  { city: 'Spokane County', state: 'WA', state_name: 'Washington' },
  { city: 'Spokane Valley', state: 'WA', state_name: 'Washington' },
  { city: 'Stanwood', state: 'WA', state_name: 'Washington' },
  { city: 'Steilacoom', state: 'WA', state_name: 'Washington' },
  { city: 'Stevens County', state: 'WA', state_name: 'Washington' },
  { city: 'Stevenson', state: 'WA', state_name: 'Washington' },
  { city: 'Sudden Valley', state: 'WA', state_name: 'Washington' },
  { city: 'Sultan', state: 'WA', state_name: 'Washington' },
  { city: 'Sumas', state: 'WA', state_name: 'Washington' },
  { city: 'Summit', state: 'WA', state_name: 'Washington' },
  { city: 'Summit View', state: 'WA', state_name: 'Washington' },
  { city: 'Sumner', state: 'WA', state_name: 'Washington' },
  { city: 'Sunnyside', state: 'WA', state_name: 'Washington' },
  { city: 'Sunnyslope', state: 'WA', state_name: 'Washington' },
  { city: 'Suquamish', state: 'WA', state_name: 'Washington' },
  { city: 'Tacoma', state: 'WA', state_name: 'Washington' },
  { city: 'Tanglewilde', state: 'WA', state_name: 'Washington' },
  { city: 'Tanglewilde-Thompson Place', state: 'WA', state_name: 'Washington' },
  { city: 'Tanner', state: 'WA', state_name: 'Washington' },
  { city: 'Tenino', state: 'WA', state_name: 'Washington' },
  { city: 'Terrace Heights', state: 'WA', state_name: 'Washington' },
  { city: 'Three Lakes', state: 'WA', state_name: 'Washington' },
  { city: 'Thurston County', state: 'WA', state_name: 'Washington' },
  { city: 'Tieton', state: 'WA', state_name: 'Washington' },
  { city: 'Tonasket', state: 'WA', state_name: 'Washington' },
  { city: 'Toppenish', state: 'WA', state_name: 'Washington' },
  { city: 'Town and Country', state: 'WA', state_name: 'Washington' },
  { city: 'Tracyton', state: 'WA', state_name: 'Washington' },
  { city: 'Trentwood', state: 'WA', state_name: 'Washington' },
  { city: 'Tukwila', state: 'WA', state_name: 'Washington' },
  { city: 'Tulalip', state: 'WA', state_name: 'Washington' },
  { city: 'Tulalip Bay', state: 'WA', state_name: 'Washington' },
  { city: 'Tumwater', state: 'WA', state_name: 'Washington' },
  { city: 'Union Gap', state: 'WA', state_name: 'Washington' },
  { city: 'Union Hill-Novelty Hill', state: 'WA', state_name: 'Washington' },
  { city: 'University Place', state: 'WA', state_name: 'Washington' },
  { city: 'Vancouver', state: 'WA', state_name: 'Washington' },
  { city: 'Vashon', state: 'WA', state_name: 'Washington' },
  { city: 'Venersborg', state: 'WA', state_name: 'Washington' },
  { city: 'Veradale', state: 'WA', state_name: 'Washington' },
  { city: 'Wahkiakum County', state: 'WA', state_name: 'Washington' },
  { city: 'Waitsburg', state: 'WA', state_name: 'Washington' },
  { city: 'Walla Walla', state: 'WA', state_name: 'Washington' },
  { city: 'Walla Walla County', state: 'WA', state_name: 'Washington' },
  { city: 'Walla Walla East', state: 'WA', state_name: 'Washington' },
  { city: 'Waller', state: 'WA', state_name: 'Washington' },
  { city: 'Walnut Grove', state: 'WA', state_name: 'Washington' },
  { city: 'Wapato', state: 'WA', state_name: 'Washington' },
  { city: 'Warden', state: 'WA', state_name: 'Washington' },
  { city: 'Warm Beach', state: 'WA', state_name: 'Washington' },
  { city: 'Washougal', state: 'WA', state_name: 'Washington' },
  { city: 'Waterville', state: 'WA', state_name: 'Washington' },
  { city: 'Wauna', state: 'WA', state_name: 'Washington' },
  { city: 'Wenatchee', state: 'WA', state_name: 'Washington' },
  { city: 'West Clarkston-Highland', state: 'WA', state_name: 'Washington' },
  { city: 'West Lake Sammamish', state: 'WA', state_name: 'Washington' },
  { city: 'West Lake Stevens', state: 'WA', state_name: 'Washington' },
  { city: 'West Longview', state: 'WA', state_name: 'Washington' },
  { city: 'West Pasco', state: 'WA', state_name: 'Washington' },
  { city: 'West Richland', state: 'WA', state_name: 'Washington' },
  { city: 'West Side Highway', state: 'WA', state_name: 'Washington' },
  { city: 'West Valley', state: 'WA', state_name: 'Washington' },
  { city: 'West Wenatchee', state: 'WA', state_name: 'Washington' },
  { city: 'Westport', state: 'WA', state_name: 'Washington' },
  { city: 'Whatcom County', state: 'WA', state_name: 'Washington' },
  { city: 'White Center', state: 'WA', state_name: 'Washington' },
  { city: 'White Salmon', state: 'WA', state_name: 'Washington' },
  { city: 'Whitman County', state: 'WA', state_name: 'Washington' },
  { city: 'Wilderness Rim', state: 'WA', state_name: 'Washington' },
  { city: 'Winlock', state: 'WA', state_name: 'Washington' },
  { city: 'Wollochet', state: 'WA', state_name: 'Washington' },
  { city: 'Woodinville', state: 'WA', state_name: 'Washington' },
  { city: 'Woodland', state: 'WA', state_name: 'Washington' },
  { city: 'Woods Creek', state: 'WA', state_name: 'Washington' },
  { city: 'Woodway', state: 'WA', state_name: 'Washington' },
  { city: 'Yacolt', state: 'WA', state_name: 'Washington' },
  { city: 'Yakima', state: 'WA', state_name: 'Washington' },
  { city: 'Yakima County', state: 'WA', state_name: 'Washington' },
  { city: 'Yarrow Point', state: 'WA', state_name: 'Washington' },
  { city: 'Yelm', state: 'WA', state_name: 'Washington' },
  { city: 'Zillah', state: 'WA', state_name: 'Washington' },
  { city: 'Alderson', state: 'WV', state_name: 'West Virginia' },
  { city: 'Alum Creek', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ansted', state: 'WV', state_name: 'West Virginia' },
  { city: 'Barbour County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Barboursville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Barrackville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Beaver', state: 'WV', state_name: 'West Virginia' },
  { city: 'Beckley', state: 'WV', state_name: 'West Virginia' },
  { city: 'Belington', state: 'WV', state_name: 'West Virginia' },
  { city: 'Belle', state: 'WV', state_name: 'West Virginia' },
  { city: 'Benwood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Berkeley County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Berkeley Springs', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bethany', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bethlehem', state: 'WV', state_name: 'West Virginia' },
  { city: 'Blennerhassett', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bluefield', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bluewell', state: 'WV', state_name: 'West Virginia' },
  { city: 'Boaz', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bolivar', state: 'WV', state_name: 'West Virginia' },
  { city: 'Boone County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bradley', state: 'WV', state_name: 'West Virginia' },
  { city: 'Braxton County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Bridgeport', state: 'WV', state_name: 'West Virginia' },
  { city: 'Brooke County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Brookhaven', state: 'WV', state_name: 'West Virginia' },
  { city: 'Brush Fork', state: 'WV', state_name: 'West Virginia' },
  { city: 'Buckhannon', state: 'WV', state_name: 'West Virginia' },
  { city: 'Buffalo', state: 'WV', state_name: 'West Virginia' },
  { city: 'Cabell County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Calhoun County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ceredo', state: 'WV', state_name: 'West Virginia' },
  { city: 'Chapmanville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Charles Town', state: 'WV', state_name: 'West Virginia' },
  { city: 'Charleston', state: 'WV', state_name: 'West Virginia' },
  { city: 'Cheat Lake', state: 'WV', state_name: 'West Virginia' },
  { city: 'Chesapeake', state: 'WV', state_name: 'West Virginia' },
  { city: 'Chester', state: 'WV', state_name: 'West Virginia' },
  { city: 'Clarksburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'Clay', state: 'WV', state_name: 'West Virginia' },
  { city: 'Clay County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Clendenin', state: 'WV', state_name: 'West Virginia' },
  { city: 'Coal City', state: 'WV', state_name: 'West Virginia' },
  { city: 'Coal Fork', state: 'WV', state_name: 'West Virginia' },
  { city: 'Crab Orchard', state: 'WV', state_name: 'West Virginia' },
  { city: 'Craigsville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Cross Lanes', state: 'WV', state_name: 'West Virginia' },
  { city: 'Culloden', state: 'WV', state_name: 'West Virginia' },
  { city: 'Daniels', state: 'WV', state_name: 'West Virginia' },
  { city: 'Despard', state: 'WV', state_name: 'West Virginia' },
  { city: 'Doddridge County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Dunbar', state: 'WV', state_name: 'West Virginia' },
  { city: 'Eleanor', state: 'WV', state_name: 'West Virginia' },
  { city: 'Elizabeth', state: 'WV', state_name: 'West Virginia' },
  { city: 'Elkins', state: 'WV', state_name: 'West Virginia' },
  { city: 'Elkview', state: 'WV', state_name: 'West Virginia' },
  { city: 'Fairlea', state: 'WV', state_name: 'West Virginia' },
  { city: 'Fairmont', state: 'WV', state_name: 'West Virginia' },
  { city: 'Fayette County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Fayetteville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Follansbee', state: 'WV', state_name: 'West Virginia' },
  { city: 'Fort Ashby', state: 'WV', state_name: 'West Virginia' },
  { city: 'Franklin', state: 'WV', state_name: 'West Virginia' },
  { city: 'Gilbert Creek', state: 'WV', state_name: 'West Virginia' },
  { city: 'Gilmer County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Glendale', state: 'WV', state_name: 'West Virginia' },
  { city: 'Glenville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Grafton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Grant County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Grantsville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Granville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Greenbrier County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hamlin', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hampshire County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hancock County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hardy County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Harrison County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Harrisville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hinton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hooverson Heights', state: 'WV', state_name: 'West Virginia' },
  { city: 'Huntington', state: 'WV', state_name: 'West Virginia' },
  { city: 'Hurricane', state: 'WV', state_name: 'West Virginia' },
  { city: 'Inwood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Jackson County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Jefferson County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Kanawha County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Kenova', state: 'WV', state_name: 'West Virginia' },
  { city: 'Keyser', state: 'WV', state_name: 'West Virginia' },
  { city: 'Kingwood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lavalette', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lesage', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lewis County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lewisburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lincoln County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Logan', state: 'WV', state_name: 'West Virginia' },
  { city: 'Logan County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Lubeck', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mabscott', state: 'WV', state_name: 'West Virginia' },
  { city: 'MacArthur', state: 'WV', state_name: 'West Virginia' },
  { city: 'Madison', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mallory', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mannington', state: 'WV', state_name: 'West Virginia' },
  { city: 'Marion County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Marlinton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Marmet', state: 'WV', state_name: 'West Virginia' },
  { city: 'Marshall County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Martinsburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mason County', state: 'WV', state_name: 'West Virginia' },
  { city: 'McDowell County', state: 'WV', state_name: 'West Virginia' },
  { city: 'McMechen', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mercer County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Middlebourne', state: 'WV', state_name: 'West Virginia' },
  { city: 'Milton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mineral County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mineral Wells', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mingo County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Monongah', state: 'WV', state_name: 'West Virginia' },
  { city: 'Monongalia County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Monroe County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Montgomery', state: 'WV', state_name: 'West Virginia' },
  { city: 'Moorefield', state: 'WV', state_name: 'West Virginia' },
  { city: 'Morgan County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Morgantown', state: 'WV', state_name: 'West Virginia' },
  { city: 'Moundsville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mount Gay-Shamrock', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mount Hope', state: 'WV', state_name: 'West Virginia' },
  { city: 'Mullens', state: 'WV', state_name: 'West Virginia' },
  { city: 'New Cumberland', state: 'WV', state_name: 'West Virginia' },
  { city: 'New Haven', state: 'WV', state_name: 'West Virginia' },
  { city: 'New Martinsville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Newell', state: 'WV', state_name: 'West Virginia' },
  { city: 'Nicholas County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Nitro', state: 'WV', state_name: 'West Virginia' },
  { city: 'Nutter Fort', state: 'WV', state_name: 'West Virginia' },
  { city: 'Oak Hill', state: 'WV', state_name: 'West Virginia' },
  { city: 'Oceana', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ohio County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Paden City', state: 'WV', state_name: 'West Virginia' },
  { city: 'Parkersburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'Parsons', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pea Ridge', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pendleton County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pennsboro', state: 'WV', state_name: 'West Virginia' },
  { city: 'Petersburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'Philippi', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pinch', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pineville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pleasant Valley', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pleasants County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Pocahontas County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Point Pleasant', state: 'WV', state_name: 'West Virginia' },
  { city: 'Preston County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Princeton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Prosperity', state: 'WV', state_name: 'West Virginia' },
  { city: 'Putnam County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Rainelle', state: 'WV', state_name: 'West Virginia' },
  { city: 'Raleigh County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Rand', state: 'WV', state_name: 'West Virginia' },
  { city: 'Randolph County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ranson', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ravenswood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Richwood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ripley', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ritchie County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Roane County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Romney', state: 'WV', state_name: 'West Virginia' },
  { city: 'Ronceverte', state: 'WV', state_name: 'West Virginia' },
  { city: 'Saint Albans', state: 'WV', state_name: 'West Virginia' },
  { city: 'Saint Marys', state: 'WV', state_name: 'West Virginia' },
  { city: 'Salem', state: 'WV', state_name: 'West Virginia' },
  { city: 'Shady Spring', state: 'WV', state_name: 'West Virginia' },
  { city: 'Shannondale', state: 'WV', state_name: 'West Virginia' },
  { city: 'Shepherdstown', state: 'WV', state_name: 'West Virginia' },
  { city: 'Shinnston', state: 'WV', state_name: 'West Virginia' },
  { city: 'Sissonville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Sistersville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Sophia', state: 'WV', state_name: 'West Virginia' },
  { city: 'South Charleston', state: 'WV', state_name: 'West Virginia' },
  { city: 'Spencer', state: 'WV', state_name: 'West Virginia' },
  { city: 'Stanaford', state: 'WV', state_name: 'West Virginia' },
  { city: 'Star City', state: 'WV', state_name: 'West Virginia' },
  { city: 'Stonewood', state: 'WV', state_name: 'West Virginia' },
  { city: 'Summers County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Summersville', state: 'WV', state_name: 'West Virginia' },
  { city: 'Sutton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Taylor County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Teays Valley', state: 'WV', state_name: 'West Virginia' },
  { city: 'Terra Alta', state: 'WV', state_name: 'West Virginia' },
  { city: 'Tornado', state: 'WV', state_name: 'West Virginia' },
  { city: 'Tucker County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Tyler County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Union', state: 'WV', state_name: 'West Virginia' },
  { city: 'Upshur County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Vienna', state: 'WV', state_name: 'West Virginia' },
  { city: 'Washington', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wayne', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wayne County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Webster County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Webster Springs', state: 'WV', state_name: 'West Virginia' },
  { city: 'Weirton', state: 'WV', state_name: 'West Virginia' },
  { city: 'Weirton Heights', state: 'WV', state_name: 'West Virginia' },
  { city: 'Welch', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wellsburg', state: 'WV', state_name: 'West Virginia' },
  { city: 'West Liberty', state: 'WV', state_name: 'West Virginia' },
  { city: 'West Union', state: 'WV', state_name: 'West Virginia' },
  { city: 'Weston', state: 'WV', state_name: 'West Virginia' },
  { city: 'Westover', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wetzel County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wheeling', state: 'WV', state_name: 'West Virginia' },
  { city: 'White Sulphur Springs', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wiley Ford', state: 'WV', state_name: 'West Virginia' },
  { city: 'Williamson', state: 'WV', state_name: 'West Virginia' },
  { city: 'Williamstown', state: 'WV', state_name: 'West Virginia' },
  { city: 'Winfield', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wirt County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wood County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Wyoming County', state: 'WV', state_name: 'West Virginia' },
  { city: 'Abbotsford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Adams', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Adams County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Addison', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Albany', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Algoma', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Allouez', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Alma', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Alto', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Altoona', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Amery', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Amherst', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Antigo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Appleton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Arcadia', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ashford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ashland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ashland County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ashwaubenon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Athens', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Augusta', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Aztalan', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Baldwin', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Balsam Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bangor', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Baraboo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Barneveld', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Barron', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Barron County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Barton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bayfield County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bayside', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Beaver Dam', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Belgium', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Belle Plaine', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Belleville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bellevue', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Beloit', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Berlin', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bevent', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Big Bend', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Black Creek', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Black Earth', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Black River Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Blair', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bloomer', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bohners Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bonduel', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Boscobel', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Boyceville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brice Prairie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brillion', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Bristol', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brodhead', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brookfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brooklyn', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brothertown', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brown County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brown Deer', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Browns Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Brussels', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Buffalo County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Burlington', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Burnett County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Butler', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cadott', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Caledonia', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Calumet County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cambridge', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cameron', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Camp Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Campbellsport', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cashton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cato', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cedar Grove', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cedarburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Chetek', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Chilton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Chippewa County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Chippewa Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Clark County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Clear Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cleveland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Clinton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Clintonville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Colby', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Colfax', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Columbia County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Columbus', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Combined Locks', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Como', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Concord', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cooperstown', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cornell', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cottage Grove', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Crandon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Crawford County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cross Plains', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cuba City', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cudahy', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Cumberland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dakota', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dane', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dane County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Darien', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Darlington', state: 'WI', state_name: 'Wisconsin' },
  { city: 'De Forest', state: 'WI', state_name: 'Wisconsin' },
  { city: 'De Pere', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Decatur', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Deerfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Delafield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Delavan', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Delavan Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Denmark', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dickeyville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dodge County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dodgeville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Door County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Douglas County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dousman', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Dunn County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Durand', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eagle', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eagle Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eagle River', state: 'WI', state_name: 'Wisconsin' },
  { city: 'East Troy', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Easton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eau Claire', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eau Claire County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Edgar', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Edgerton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Eldorado', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Elkhorn', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ellsworth', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Elm Grove', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Elroy', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Evansville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Evergreen', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fall Creek', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fall River', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fennimore', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fitchburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Florence', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Florence County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fond du Lac', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fond du Lac County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fontana', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Forest County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fort Atkinson', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fox Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fox Point', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Franklin', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Franksville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Frederic', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Fredonia', state: 'WI', state_name: 'Wisconsin' },
  { city: 'French Island', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Friendship', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Galesville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Genoa City', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Germantown', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Gillett', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Glendale', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Glenmore', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Glenwood City', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Grafton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Grant County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Grantsburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Green Bay', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Green County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Green Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Green Lake County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Greendale', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Greenfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Greenwood', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hales Corners', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hammond', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Harrison', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hartford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hartland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hayward', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hazel Green', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hilbert', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hillsboro', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hobart', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Holmen', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Horicon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hortonville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Howard', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Howards Grove', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hudson', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hurley', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Hustisford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Independence', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Iola', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Iowa County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Iron County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ixonia', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Jackson', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Jackson County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Janesville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Jefferson', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Jefferson County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Johnson Creek', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Juneau', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Juneau County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kaukauna', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kenosha', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kenosha County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Keshena', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kewaskum', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kewaunee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kewaunee County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kiel', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kimberly', state: 'WI', state_name: 'Wisconsin' },
  { city: 'King', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kohler', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Kronenwetter', state: 'WI', state_name: 'Wisconsin' },
  { city: 'La Crosse', state: 'WI', state_name: 'Wisconsin' },
  { city: 'La Crosse County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lac du Flambeau', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ladysmith', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lafayette County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Delton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Geneva', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Hallie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Koshkonong', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Mills', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Nebagamon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Ripley', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Wazeecha', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Wisconsin', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lake Wissota', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lamartine', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lancaster', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Langlade County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lannon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Legend Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lincoln County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Little Chute', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Little Round Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lodi', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Lomira', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Loyal', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Luck', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Luxemburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Madison', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Maine', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Manawa', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Manchester', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Manitowoc', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Manitowoc County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Maple Bluff', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marathon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marathon County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marinette', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marinette County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marion', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Markesan', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marquette County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marshall', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Marshfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mauston', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mayville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mazomanie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'McFarland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Medford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Menasha', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Menominee County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Menomonee Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Menomonie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mequon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Merrill', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Merton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Middleton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Milford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Milton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Milwaukee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Milwaukee County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mineral Point', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mishicot', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mondovi', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Monona', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Monroe', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Monroe County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Montello', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Monticello', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mosinee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mount Horeb', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mount Morris', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mount Pleasant', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Mukwonago', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Muscoda', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Muskego', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Nashotah', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Nashville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Neenah', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Neillsville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Nekoosa', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New Berlin', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New Glarus', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New Holstein', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New Lisbon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New London', state: 'WI', state_name: 'Wisconsin' },
  { city: 'New Richmond', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Newburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Niagara', state: 'WI', state_name: 'Wisconsin' },
  { city: 'North Fond du Lac', state: 'WI', state_name: 'Wisconsin' },
  { city: 'North Hudson', state: 'WI', state_name: 'Wisconsin' },
  { city: 'North La Crosse', state: 'WI', state_name: 'Wisconsin' },
  { city: 'North Prairie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oak Creek', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oakfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oconomowoc', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oconto', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oconto County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oconto Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Okauchee Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Omro', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Onalaska', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oneida', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oneida County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oostburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oregon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Orfordville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Osceola', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Oshkosh', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Osseo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Outagamie County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ozaukee County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Paddock Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Palmyra', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pardeeville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Park Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pell Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pepin County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Peshtigo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pewaukee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Phillips', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pierce County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pittsfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Platteville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pleasant Prairie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Plover', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Plymouth', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Polk County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Port Edwards', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Port Washington', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Portage', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Portage County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Portland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Potter Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Powers Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Poynette', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Prairie du Chien', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Prairie du Sac', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Prescott', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Price County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Princeton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Pulaski', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Racine', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Racine County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Randolph', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Random Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Redgranite', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Reedsburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Reedsville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rhinelander', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rib Mountain', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rice Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Richfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Richland Center', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Richland County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Richmond', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rio', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Ripon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'River Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'River Hills', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Roberts', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rochester', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rock County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rome', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rosendale', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rothschild', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Roxbury', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rusk County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Rutland', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Saint Croix County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Saint Croix Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Saint Francis', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Saint Peter', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Salem', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sauk City', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sauk County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Saukville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sawyer County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Schofield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Seymour', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sharon', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shawano', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shawano County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sheboygan', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sheboygan County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sheboygan Falls', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shell Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sherwood', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shorewood', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shorewood Hills', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Shullsburg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Silver Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Siren', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Slinger', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Somers', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Somerset', state: 'WI', state_name: 'Wisconsin' },
  { city: 'South Milwaukee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sparta', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Spencer', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Spooner', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Spring Green', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Spring Valley', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Stanley', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Stevens Point', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Stoughton', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Stratford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Strum', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sturgeon Bay', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sturtevant', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Suamico', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sun Prairie', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Superior', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Sussex', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Tainter Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Taylor County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Theresa', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Thiensville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Thorp', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Tichigan', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Tomah', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Tomahawk', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Trempealeau', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Trempealeau County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Turtle Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Twin Lakes', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Two Rivers', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Union Grove', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Vernon County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Verona', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Vilas County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Viroqua', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wales', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Walworth', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Walworth County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Washburn', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Washburn County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Washington County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waterford', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waterloo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Watertown', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waukesha', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waukesha County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waunakee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waupaca', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waupaca County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waupun', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wausau', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Waushara County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wautoma', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wauwatosa', state: 'WI', state_name: 'Wisconsin' },
  { city: 'West Allis', state: 'WI', state_name: 'Wisconsin' },
  { city: 'West Baraboo', state: 'WI', state_name: 'Wisconsin' },
  { city: 'West Bend', state: 'WI', state_name: 'Wisconsin' },
  { city: 'West Milwaukee', state: 'WI', state_name: 'Wisconsin' },
  { city: 'West Salem', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Westby', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Westfield', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Weston', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Weyauwega', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Whitefish Bay', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Whitehall', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Whitewater', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Whiting', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Williams Bay', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wind Lake', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wind Point', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Windsor', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Winnebago County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Winneconne', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wisconsin Dells', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wisconsin Rapids', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wittenberg', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wood County', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Woodville', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Wrightstown', state: 'WI', state_name: 'Wisconsin' },
  { city: 'Afton', state: 'WY', state_name: 'Wyoming' },
  { city: 'Albany County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Antelope Valley-Crestview', state: 'WY', state_name: 'Wyoming' },
  { city: 'Arapahoe', state: 'WY', state_name: 'Wyoming' },
  { city: 'Bar Nunn', state: 'WY', state_name: 'Wyoming' },
  { city: 'Basin', state: 'WY', state_name: 'Wyoming' },
  { city: 'Big Horn County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Buffalo', state: 'WY', state_name: 'Wyoming' },
  { city: 'Campbell County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Carbon County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Casper', state: 'WY', state_name: 'Wyoming' },
  { city: 'Cheyenne', state: 'WY', state_name: 'Wyoming' },
  { city: 'Cody', state: 'WY', state_name: 'Wyoming' },
  { city: 'Converse County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Crook County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Douglas', state: 'WY', state_name: 'Wyoming' },
  { city: 'Ethete', state: 'WY', state_name: 'Wyoming' },
  { city: 'Evanston', state: 'WY', state_name: 'Wyoming' },
  { city: 'Evansville', state: 'WY', state_name: 'Wyoming' },
  { city: 'Fort Washakie', state: 'WY', state_name: 'Wyoming' },
  { city: 'Fox Farm-College', state: 'WY', state_name: 'Wyoming' },
  { city: 'Fremont County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Gillette', state: 'WY', state_name: 'Wyoming' },
  { city: 'Glenrock', state: 'WY', state_name: 'Wyoming' },
  { city: 'Goshen County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Green River', state: 'WY', state_name: 'Wyoming' },
  { city: 'Greybull', state: 'WY', state_name: 'Wyoming' },
  { city: 'Guernsey', state: 'WY', state_name: 'Wyoming' },
  { city: 'Hoback', state: 'WY', state_name: 'Wyoming' },
  { city: 'Hot Springs County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Jackson', state: 'WY', state_name: 'Wyoming' },
  { city: 'Johnson County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Kemmerer', state: 'WY', state_name: 'Wyoming' },
  { city: 'Lander', state: 'WY', state_name: 'Wyoming' },
  { city: 'Laramie', state: 'WY', state_name: 'Wyoming' },
  { city: 'Laramie County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Lincoln County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Lovell', state: 'WY', state_name: 'Wyoming' },
  { city: 'Lusk', state: 'WY', state_name: 'Wyoming' },
  { city: 'Lyman', state: 'WY', state_name: 'Wyoming' },
  { city: 'Marbleton', state: 'WY', state_name: 'Wyoming' },
  { city: 'Mills', state: 'WY', state_name: 'Wyoming' },
  { city: 'Moorcroft', state: 'WY', state_name: 'Wyoming' },
  { city: 'Moose Wilson Road', state: 'WY', state_name: 'Wyoming' },
  { city: 'Mountain View', state: 'WY', state_name: 'Wyoming' },
  { city: 'Natrona County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Newcastle', state: 'WY', state_name: 'Wyoming' },
  { city: 'Niobrara County', state: 'WY', state_name: 'Wyoming' },
  { city: 'North Rock Springs', state: 'WY', state_name: 'Wyoming' },
  { city: 'Park County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Pine Bluffs', state: 'WY', state_name: 'Wyoming' },
  { city: 'Pinedale', state: 'WY', state_name: 'Wyoming' },
  { city: 'Platte County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Powell', state: 'WY', state_name: 'Wyoming' },
  { city: 'Rafter J Ranch', state: 'WY', state_name: 'Wyoming' },
  { city: 'Ranchettes', state: 'WY', state_name: 'Wyoming' },
  { city: 'Rawlins', state: 'WY', state_name: 'Wyoming' },
  { city: 'Riverton', state: 'WY', state_name: 'Wyoming' },
  { city: 'Rock Springs', state: 'WY', state_name: 'Wyoming' },
  { city: 'Saratoga', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sheridan', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sheridan County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sleepy Hollow', state: 'WY', state_name: 'Wyoming' },
  { city: 'South Greeley', state: 'WY', state_name: 'Wyoming' },
  { city: 'South Park', state: 'WY', state_name: 'Wyoming' },
  { city: 'Star Valley Ranch', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sublette County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sundance', state: 'WY', state_name: 'Wyoming' },
  { city: 'Sweetwater County', state: 'WY', state_name: 'Wyoming' },
  { city: 'Teton County', state: 'WY', state_name: 'Wyoming' },
];
