import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    color: theme.palette.text.primary,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '100%',
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
