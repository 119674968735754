import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  rolesList: [],
  userDetailsFormik: null,
  isUserDetailsValid: false,
  isUserDetailsFormDirty: false,
  newUserImage: null,
  userImage: null,
  userName: '',
  userDetails: null,
};

const userFormDetailsSlice = createSlice({
  name: 'userFormDetails',
  initialState: initialState,
  reducers: {
    clearState(state) {
      state.isLoading = true;
      state.rolesList = [];
      state.userDetailsFormik = null;
      state.isUserDetailsValid = false;
      state.isUserDetailsFormDirty = false;
      state.newUserImage = null;
      state.userImage = null;
      state.userName = null;
      state.userDetails = null;
    },
    setUserDetailsForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.rolesList = payload.hasOwnProperty('rolesList')
        ? payload.rolesList
        : state.rolesList;
      state.userDetailsFormik = payload.hasOwnProperty('userDetailsFormik')
        ? payload.userDetailsFormik
        : state.userDetailsFormik;
      state.isUserDetailsValid = payload.hasOwnProperty('isUserDetailsValid')
        ? payload.isUserDetailsValid
        : state.isUserDetailsValid;
      state.isUserDetailsFormDirty = payload.hasOwnProperty(
        'isUserDetailsFormDirty'
      )
        ? payload.isUserDetailsFormDirty
        : state.isUserDetailsFormDirty;
      state.newUserImage = payload.hasOwnProperty('newUserImage')
        ? payload.newUserImage
        : state.newUserImage;
      state.userImage = payload.hasOwnProperty('userImage')
        ? payload.userImage
        : state.userImage;
      state.userName = payload.hasOwnProperty('userName')
        ? payload.userName
        : state.userName;
      state.userDetails = payload.hasOwnProperty('userDetails')
        ? payload.userDetails
        : state.userDetails;
    },
  },
});

export const { setUserDetailsForm, clearState } = userFormDetailsSlice.actions;

export const userFormDetailsReducer = userFormDetailsSlice.reducer;
