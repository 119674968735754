import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { CONFIG } from '../../../../constants';

import {
  clientInfoInitialValues,
  clientInfoValidationSchema,
} from './ClientInfo';
import {
  companyInformationInitialValues,
  companyInformationValidationSchema,
} from './CompanyInformation';

const initialValues = {
  ...clientInfoInitialValues,
  ...companyInformationInitialValues,
};

const validationSchema = Yup.object().shape({
  ...clientInfoValidationSchema,
  ...companyInformationValidationSchema,
});

const createClient = async (values, toast, setLoading, history) => {
  try {
    const response = await axios.post(
      `${CONFIG?.API_CRM_URL}/api/customer`,
      values
    );

    if (response.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Done',
        detail: 'Client has been added successfully.',
        life: 3000,
      });
      return history.push(`/crm/view/customer/${response?.data.customer_id}`);
    }
  } catch (error) {
    if (error.response.status === 409) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail:
          error?.response?.data?.error ||
          'Client already exists with first name, last name and mobile number you provided.',
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong',
        life: 3000,
      });
    }
  } finally {
    setLoading(false);
  }
};

const updateClient = async (
  customerId,
  values,
  toast,
  setLoading,
  history,
  setValidate
) => {
  try {
    const response = await axios.patch(
      `${CONFIG?.API_CRM_URL}/api/customer/${customerId}`,
      values
    );

    if (response.status === 200) {
      setValidate(false);
      toast.current.show({
        severity: 'success',
        summary: 'Done',
        detail: 'Client has been updated successfully.',
        life: 3000,
      });
      return history.push(`/crm/view/customer/${customerId}`);
    }
  } catch (error) {
    if (error.response.status === 409) {
      setValidate(true);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail:
          error?.response?.data?.error ||
          'Client already exists with first name, last name and mobile number you provided.',
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong',
        life: 3000,
      });
    }
  } finally {
    setLoading(false);
  }
};

export const customerInfoSubmit = (
  customer,
  customerId,
  toast,
  setLoading,
  history,
  setValidate
) => {
  const constomerInitialValue =
    customerId && customer ? customer : initialValues;
  return useFormik({
    enableReinitialize: true,
    initialValues: constomerInitialValue,
    validationSchema: validationSchema,
    onSubmit: async values => {
      let { address, customer_phones, customer_emails, ...rest } = values;
      customer_phones = customer_phones.map(item => ({
        ...item,
        is_primary: item?.is_primary ? 1 : 0,
      }));

      customer_emails = Array.isArray(customer_emails)
        ? customer_emails?.map(item => ({
            ...item,
            is_primary: item?.is_primary ? 1 : 0,
          }))
        : [];

      const { billing_address, ...restAddress } = address;
      const reqBody = {
        ...rest,
        customer_phones,
        customer_emails,
        address: [{ ...restAddress, latitude: null, longitude: null }],
      };
      setLoading(true);
      if (customerId) {
        updateClient(
          customerId,
          reqBody,
          toast,
          setLoading,
          history,
          setValidate
        );
      } else {
        createClient(reqBody, toast, setLoading, history);
      }
    },
  });
};

export const getCustomerDetails = async customerId =>
  await axios.get(`${CONFIG?.API_CRM_URL}/api/customer/${customerId}`);

export const fetchCustomerInfo = async (
  customerId,
  setCustomerDetails,
  setLoading,
  history = null
) => {
  try {
    const customer = await axios.get(
      `${CONFIG?.API_CRM_URL}/api/customer/${customerId}`
    );

    if (customer?.status === 200 && customer?.data) {
      let {
        first_name,
        last_name,
        primary_mode_of_contact,
        receive_text_message,
        customer_phones,
        customer_emails,
        customer_related_notes,
        address,
        company_name,
        job_title,
        industry_id,
        company_size,
        annual_revenue,
        is_deleted,
        receive_promotional_emails,
      } = customer?.data;

      customer_phones = customer_phones.map(item => ({
        customer_phone_id: item.customer_phone_id,
        phone_type_id: item.phone_type_id,
        phone_number: item.phone_number,
        is_primary: item?.is_primary === 1 ? true : false,
      }));

      customer_emails = customer_emails.map(item => ({
        customer_email_id: item.customer_email_id,
        email_type: item.email_type,
        email: item.email,
        isCustomerRegistered: item.isCustomerRegistered || undefined,
        is_duplicate: item.is_duplicate || undefined,
        is_primary: item?.is_primary === 1 ? true : false,
      }));

      const info = {
        first_name,
        last_name,
        primary_mode_of_contact,
        customer_phones,
        customer_emails,
        customer_related_notes,
        address,
        company_name,
        job_title,
        industry_id,
        company_size,
        annual_revenue,
        is_deleted: is_deleted === 1 ? true : false,
        receive_text_message,
        receive_promotional_emails: receive_promotional_emails
          ? receive_promotional_emails
          : false,
      };
      setCustomerDetails(info);

      return info;
    }
  } catch (error) {
    if (error?.response?.status === 404 && history) {
      return history.push('/page-not-found');
    }
    console.log('error', error);
  } finally {
    setLoading(false);
  }
};

export const customerInfoFormBreadcrumb = (customerId, mode) => {
  return [
    {
      text: 'Project Management',
    },
    {
      text: 'Clients',
      link: `/project/clients`,
    },
    {
      text: `${mode[0].toUpperCase() + mode.slice(1)} Client`,
    },
  ];
};

export const getCustomerProjects = async (
  clientId,
  setListOfProjects,
  setLoading
) => {
  try {
    const response = await axios.get(
      `${CONFIG?.API_BASE_URL}/customers/${clientId}`
    );
    if (response.status === 200) {
      const listOfProjects = response?.data?.[0]?.project || [];
      setListOfProjects(listOfProjects);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
