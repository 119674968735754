import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Billing Details
export const getCountOfOrders = async (
  searchQuery,
  setLoading,
  setBillingDetails,
  setAlert
  // payment_request_id
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    // if (payment_request_id) {
    //   newQueryString = `${newQueryString}&payment_request_id=${payment_request_id}`;
    // }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/billings/get-billing-orders?${newQueryString}`
    );
    if (response?.status === 200) {
      setBillingDetails(response?.data);
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch Order Count', false, true);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
// Get Billing Details
export const getCountOfIncomingSms = async (
  searchQuery,
  setLoading,
  setIncomingCount,
  setAlert
  // payment_request_id
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    // if (payment_request_id) {
    //   newQueryString = `${newQueryString}&payment_request_id=${payment_request_id}`;
    // }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/billings/get-incoming-sms-billing?${newQueryString}`
    );
    if (response?.status === 200) {
      setIncomingCount(response?.data);
    }
  } catch (error) {
    setAlert(
      'error',
      'Failed to fetch count of Incoming messages.',
      false,
      true
    );
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
// Get Billing Details
export const getCountOfOutgoingSms = async (
  searchQuery,
  setLoading,
  setOutgoingCount,
  setAlert
  //payment_request_id
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    // if (payment_request_id) {
    //   newQueryString = `${newQueryString}&payment_request_id=${payment_request_id}`;
    // }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/billings/get-outgoing-sms-billing?${newQueryString}`
    );
    if (response?.status === 200) {
      setOutgoingCount(response?.data);
    }
  } catch (error) {
    setAlert(
      'error',
      'Failed to fetch count of Outgoing messages.',
      false,
      true
    );
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
