import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';

import { severityData, statusData } from '../../constants';

import AddNote from './AddNote';
import TaskList from './TaskList';
import {
  callUpdateTaskApi,
  addTask,
  getAssignToList,
  createCategory,
  searchCategoryName,
  addProjectTask,
  updateProjectTask,
} from './TaskManagement.service';

const TaskManagement = ({
  typeOfProject,
  taskProjectId,
  refereshOnClose,
  tableName,
}) => {
  const categoryRef = useRef(null);
  const assigneeRef = useRef(null);
  const toast = useRef(null);
  const [assignees, setAssignee] = useState(null);
  const [getCategorySuggestion, setCategorySuggestion] = useState([]);
  const [copyTo, setCopyTo] = useState([]);
  const [isCategoryBtnDisabled, setCategoryBtnDisabled] = useState(true);
  const [refereshList, setRefereshList] = useState(false);
  const [refreshNote, setRefreshNote] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assigneeDisabled, setAssigneeDisabled] = useState(true);
  const [isOwner, setIsOwner] = useState(true);
  const [isAssignee, setIsAssignee] = useState(true);
  const [addNewTask, setAddNewTask] = useState(true);
  const inputRef = useRef('');

  const onSubmit = async data => {
    let response;

    if (tableName === 'Tasks' || tableName === 'Project') {
      if (taskProjectId && addNewTask) {
        response = await addProjectTask(
          data,
          taskProjectId,
          tableName,
          setLoading
        );
      } else {
        response = await updateProjectTask(
          data,
          taskProjectId,
          tableName,
          setLoading
        );
      }
    } else {
      if (data.taskId === null) {
        response = await addTask(data, taskProjectId, tableName, setLoading);
      } else {
        response = await callUpdateTaskApi(data, taskProjectId, setLoading);
      }
    }

    if (response?.data?.error) {
      toast.current.show({
        severity: 'error',
        summary: `Unable to ${data.taskId === null ? 'add' : 'update'} task`,
        detail: response?.data?.error,
        life: '2500',
      });
    } else {
      setRefereshList(!refereshList);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `Task successfully ${data.taskId === null ? 'added' : 'updated'}`,
        life: '2500',
      });
      formik.resetForm();
    }
  };

  const validation = Yup.object().shape({
    taskTitle: Yup.string()
      .required('Title is required.')
      .min(3, 'Title length must be minimum 3 characters.')
      .max(50, 'Title length must be Maximum 50 characters.'),
    status: Yup.string().required('Status is required.'),
    severity: Yup.string().required('Severity is required.'),
    category: Yup.string().required('Category is required.'),
    assignedTo: Yup.object().required('Assignee is required.').nullable(),
    description: Yup.string()
      .max(250, 'Maximum 250 characters are allowed.')
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      taskTitle: '',
      status: '',
      severity: '',
      category: '',
      assignedTo: '',
      copyTo: [],
      followUp: '',
      description: '',
      dueDate: '',
      assignedToId: '',
      taskId: null,
    },
    validationSchema: validation,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const setFormData = async data => {
    setIsOwner(data?.is_owner);
    setIsAssignee(data?.is_assignedTo);
    setAddNewTask(data ? false : true);
    setCategorySuggestion([
      {
        'TaskCategory.category_name': data.category,
      },
    ]);
    const projectTaskUsers = data?.project_task_users?.map(item => {
      return {
        assignedTo:
          item?.UserMetum?.first_name && item?.UserMetum?.last_name
            ? item.UserMetum.first_name + ' ' + item.UserMetum.last_name
            : '',
        user_id: item?.user_id,
      };
    });

    const taskUsers = data?.task_users?.map(item => {
      return {
        assignedTo:
          item?.UserMetum?.first_name && item?.UserMetum?.last_name
            ? item.UserMetum.first_name + ' ' + item.UserMetum.last_name
            : '',
        user_id: item?.user_id,
      };
    });

    let assignedTo = {
      user_id: data?.assigned_user_id,
      assignedTo: data?.full_name,
    };
    const formData = {
      taskTitle: data.title,
      status: statusData?.find(status => status?.value === data?.status)?.value,
      severity: severityData?.find(severity => severity.name === data.severity)
        ?.name,
      category: data.category,
      assignedTo: assignedTo,
      copyTo: data?.project_task_users ? projectTaskUsers : taskUsers,
      assignedToId: data.assigned_user_id,
      followUp: data.follow_up_date,
      description: data.description,
      dueDate: data.due_date,
      taskId: data.task_id,
    };

    formik.setValues(formData);
    setRefreshNote(data.task_id);
  };
  const renderAssigneeList = async searchString => {
    if (searchString?.length >= 3) {
      const response = await getAssignToList(searchString);
      if (response) {
        const reponseData = response.map(item => ({
          assignedTo:
            item?.first_name && item?.last_name
              ? item.first_name + ' ' + item.last_name
              : '',
          user_id: item?.user_id,
        }));
        setAssignee(reponseData);
      }
    }
  };

  const onCopyToChange = async searchString => {
    const response = await getAssignToList(searchString);

    if (response) {
      const reponseData = response.map(item => {
        return {
          assignedTo: item.first_name + ' ' + item.last_name,
          user_id: item.user_id,
        };
      });
      setCopyTo(reponseData);
    }
  };

  const severityTemplate = option => {
    return (
      <div className="flex align-items-center">
        <span className={option.icon}></span>
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const disablePastDates = date => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date < currentDate;
  };

  const suggestCategoryData = async (event = null) => {
    const query = event?.query || '';
    if (query?.length >= 3) {
      const searchCategoryResponse = await searchCategoryName(query);
      setCategorySuggestion(searchCategoryResponse);
    }
  };

  const addNewCategory = async categoryName => {
    if (categoryName) {
      const newCategory = await createCategory(categoryName.trim());

      if (newCategory?.status) {
        setCategoryBtnDisabled(true);
        formik.setFieldValue(
          'category',
          newCategory?.data?.data?.category_name
        );
        const responseSearcData = {
          'TaskCategory.category_name': newCategory?.data?.data?.category_name,
          'TaskCategory.category_id': newCategory?.data?.data?.category_id,
        };

        setCategorySuggestion(prevArray => [...prevArray, responseSearcData]);

        toast?.current?.show({
          severity: 'success',
          summary: `${categoryName} has been created successfully`,
          life: 2000,
        });
      }
    }
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value == 'string') {
      if (value != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() == value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };
  const disabledFormFields = () => {
    return isOwner === false && isAssignee === true
      ? false
      : isOwner === true && isAssignee === true
        ? false
        : isOwner === true && isAssignee === false
          ? false
          : isOwner === false && isAssignee === false
            ? true
            : false;
  };

  return (
    <>
      <Toast
        baseZIndex="10000"
        className="taks-toast"
        style={{ zIndex: '9999!important' }}
        ref={toast}
      />

      <div className="grid mt-0">
        <div className="col-12 md:col-6" disabled={true}>
          <div className="py-2 px-3 border-200 border-1 shadow-1">
            <>
              <div className="flex justify-content-between flex-wrap">
                <h3 className="m-0 align-items-center">
                  <strong>
                    {formik.values.taskId === null ? 'Add' : 'Update'} Task
                  </strong>
                </h3>
                <Button
                  size="small"
                  severity="primary"
                  label="Add New"
                  onClick={() => {
                    setIsOwner(true);
                    setIsAssignee(true);
                    setAddNewTask(true);
                    formik.resetForm();
                  }}
                />
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-column gap-2 w-12"
              >
                <div className="grid">
                  <div className="field col-12 mb-0 mt-2 pb-0">
                    <span className="p-float-label">
                      <InputText
                        id="taskTitle"
                        name="taskTitle"
                        onChange={formik.handleChange}
                        value={formik.values.taskTitle}
                        className="w-12 m-0 p-inputtext-sm"
                        disabled={!isOwner}
                        autoComplete="off"
                      />
                      <label htmlFor="taskTitle" className="mb-0">
                        Task Title <span className="text-red-500">*</span>
                      </label>
                    </span>
                    {formik.touched.taskTitle && formik.errors.taskTitle ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.taskTitle}
                      </div>
                    ) : null}
                  </div>

                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <Dropdown
                        inputId="status"
                        name="status"
                        value={formik.values.status}
                        options={statusData}
                        optionValue="value"
                        optionLabel="value"
                        onChange={e => {
                          formik.setFieldValue('status', e.value);
                        }}
                        className="w-12"
                        disabled={disabledFormFields()}
                      />
                      <label htmlFor="status" className="mb-0">
                        Status <span className="text-red-500">*</span>
                      </label>
                    </span>
                    {formik.touched.status && formik.errors.status ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.status}
                      </div>
                    ) : null}
                  </div>

                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <Dropdown
                        inputId="severity"
                        name="severity"
                        optionValue="name"
                        value={formik.values.severity}
                        options={severityData}
                        optionLabel="name"
                        onChange={e => {
                          if (e && e.value != null && e.value != undefined) {
                            formik.setFieldValue('severity', e.value);
                          }
                        }}
                        itemTemplate={severityTemplate}
                        className="w-12"
                        disabled={disabledFormFields()}
                      />
                      <label htmlFor="severity" className="mb-0">
                        Severity <span className="text-red-500">*</span>
                      </label>
                    </span>
                    {formik.touched.severity && formik.errors.severity ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.severity}
                      </div>
                    ) : null}
                  </div>

                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2 category-field">
                    {/* <span className="p-float-label"> */}
                    <span className="p-float-label p-inputgroup flex-1 flex">
                      <AutoComplete
                        ref={categoryRef}
                        inputClassName="border-right-none border-noround-right p-inputtext-sm height-40"
                        id="category"
                        name="category"
                        appendTo="self"
                        value={formik.values.category || ''}
                        suggestions={getCategorySuggestion}
                        field="TaskCategory.category_name"
                        completeMethod={suggestCategoryData}
                        onFocus={e => {
                          if (categoryRef.current) {
                            categoryRef.current.show();
                          }
                        }}
                        onChange={e => {
                          if (typeof e.value === 'string') {
                            formik.setFieldValue(
                              'category',
                              e.value ? e.value : ''
                            );
                          }
                          if (typeof e.value === 'object') {
                            formik.setFieldValue(
                              'category',
                              e.value['TaskCategory.category_name']
                                ? e.value['TaskCategory.category_name']
                                : ''
                            );
                          }
                        }}
                        disabled={disabledFormFields()}
                      />
                      <Button
                        icon="pi pi-plus"
                        className="p-button-primary height-40 border-round-right-md"
                        severity="primary"
                        type="button"
                        onClick={() => addNewCategory(formik.values.category)}
                        disabled={
                          onDisabled(
                            getCategorySuggestion,
                            formik.values.category,
                            'TaskCategory.category_name'
                          ) ||
                          (onDisabled() === false && !isOwner === true
                            ? false
                            : null)
                        }
                      />
                      <label htmlFor="category" className="mb-0 z-5">
                        Category <span className="text-red-500">*</span>
                      </label>
                    </span>

                    {/* </span> */}
                    {formik.touched.category && formik.errors.category ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.category}
                      </div>
                    ) : null}
                  </div>

                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2 assignedTo">
                    <span className="p-float-label">
                      <AutoComplete
                        ref={assigneeRef}
                        field="assignedTo"
                        value={formik?.values?.assignedTo}
                        suggestions={assignees}
                        completeMethod={e => renderAssigneeList(e.query)}
                        onFocus={e => {
                          if (assigneeRef.current) {
                            assigneeRef.current.show();
                          }
                        }}
                        onChange={e => {
                          const val = e?.target?.value;
                          if (val) {
                            formik.setFieldValue('assignedTo', val ? val : '');
                            formik.setFieldValue(
                              'assignedToId',
                              val ? val.user_id : ''
                            );
                          } else {
                            formik.setFieldValue('assignedTo', '');
                            formik.setFieldValue('assignedToId', '');
                          }
                        }}
                        onBlur={formik.handleBlur}
                        disabled={!isOwner}
                        inputClassName="p-inputtext-sm w-12"
                        pt={{
                          root: {
                            className: 'w-full',
                          },
                        }}
                      />
                      <label htmlFor="assignedTo" className="mb-0">
                        Assigned To <span className="text-red-500">*</span>
                      </label>
                    </span>
                    {formik.touched.assignedTo && formik.errors.assignedTo ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.assignedTo}
                      </div>
                    ) : null}
                  </div>

                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <Calendar
                        inputId="followUp"
                        name="followUp"
                        value={
                          formik.values.followUp
                            ? typeof formik.values.followUp === 'string'
                              ? new Date(`${formik.values.followUp}T00:00:00`)
                              : new Date(formik.values.followUp)
                            : null
                        }
                        onChange={e => {
                          formik.setFieldValue('followUp', e.target.value);
                        }}
                        disabledDate={disablePastDates}
                        minDate={new Date()}
                        className="border-0 border-round"
                        disabled={disabledFormFields()}
                        dateFormat="mm/dd/yy"
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                      />
                      <label htmlFor="followUpDate" className="mb-0">
                        Follow Up Date
                      </label>
                    </span>
                  </div>
                  <div className="field col-12 md:col-4 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <Calendar
                        inputId="dueDate"
                        name="dueDate"
                        value={
                          formik.values.dueDate
                            ? typeof formik.values.dueDate === 'string'
                              ? new Date(`${formik.values.dueDate}T00:00:00`)
                              : new Date(formik.values.dueDate)
                            : null
                        }
                        onChange={e => {
                          formik.setFieldValue('dueDate', e.target.value);
                        }}
                        disabledDate={disablePastDates}
                        minDate={new Date()}
                        className="border-0 border-round"
                        disabled={disabledFormFields()}
                        dateFormat="mm/dd/yy"
                        pt={{
                          input: {
                            root: { className: 'p-inputtext-sm' },
                          },
                        }}
                      />
                      <label htmlFor="dueDate" className="mb-0">
                        Due Date
                      </label>
                    </span>
                  </div>

                  <div className="field col-12 md:col-12 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <AutoComplete
                        field="assignedTo"
                        value={formik?.values?.copyTo}
                        suggestions={copyTo}
                        completeMethod={e => onCopyToChange(e.query)}
                        onChange={e => {
                          const val = e?.target?.value;
                          if (val) {
                            formik.setFieldValue('copyTo', val ? val : '');
                          }
                        }}
                        className="w-12 copy-to h-4rem"
                        multiple
                        forceSelection
                        disabled={disabledFormFields()}
                        inputClassName="p-inputtext-sm w-12"
                        pt={{
                          container: {
                            className: 'w-12 h-4rem overflow-auto',
                          },
                          inputtoken: {
                            className: 'py-0',
                          },
                        }}
                      />
                      <label htmlFor="followUpDate" className="mb-0 flex">
                        Copy To
                      </label>
                    </span>
                  </div>

                  <div className="field col-12 mb-0 pb-0  mt-2">
                    <span className="p-float-label">
                      <InputTextarea
                        inputid="description"
                        name="description"
                        value={formik.values.description}
                        onChange={e => {
                          formik.setFieldValue('description', e.target.value);
                        }}
                        className="w-12 h-4rem"
                        disabled={!isOwner}
                      />
                      <label htmlFor="description" className="mb-0">
                        Description
                      </label>
                    </span>
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12 flex align-items-end justify-content-end">
                    <Button
                      type="submit"
                      severity="primary"
                      disabled={
                        !onDisabled(
                          getCategorySuggestion,
                          formik.values.category,
                          'TaskCategory.category_name'
                        ) ||
                        loading ||
                        disabledFormFields() ||
                        !formik.isValid ||
                        !formik.dirty
                      }
                      label="Submit"
                      size="small"
                      className="ml-2"
                    />
                  </div>
                </div>
              </form>
            </>
          </div>
          {formik.values.taskId ? (
            <AddNote
              task_id={refreshNote}
              typeOfProject={typeOfProject}
              taskProjectId={taskProjectId}
              tableName={tableName}
            />
          ) : (
            ''
          )}
        </div>

        <div className="col-12 md:col-6">
          <TaskList
            setFormData={setFormData}
            refereshList={refereshList}
            typeOfProject={typeOfProject}
            taskProjectId={taskProjectId}
            tableName={tableName}
          />
        </div>
      </div>
    </>
  );
};

export default TaskManagement;
