export const TestData = [
  {
    engine_result_id: 1,
    client_id: '15PF12LC',
    project_id: 449585,
    project_number: '770767351',
    po_number: '218517213',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      note: {
        store: null,
        status: null,
        category: { alias: 'retailer', category_name: 'Retailer' },
        store_id: null,
        client_id: '15PF12LC',
        closed_by: null,
        note_text: 'Adding Test Note, Please ignore',
        note_type: null,
        created_at: '2023-03-06T12:45:58.000Z',
        created_by: {
          last_name: 'Thomas',
          first_name: 'Chad',
          middle_name: null,
        },
        project_id: 449585,
        date_closed: null,
        modified_at: null,
        note_number: null,
        reviewed_by: null,
        note_type_id: null,
        order_number: null,
        tklr_bin_code: null,
        follow_up_date: null,
        note_status_id: null,
        note_created_at: '2023-03-06T12:45:58.000Z',
        note_created_by: 'b3d40586-7c5e-11ed-8368-0688ceb8ca66',
        project_note_id: 2345541,
        note_category_id: 2,
        reviewed_by_user: null,
        closed_by_group_type: null,
        created_by_group_type: null,
        external_note_created_by: null,
      },
      type: 'add',
      source: 'client_update',
      project: {
        store: {
          store_id: 4742,
          store_name: "LOWE'S OF SHEPHERDSVILLE, KY",
          store_number: 2945,
        },
        market: null,
        region: null,
        status: {
          status: 'Ready To Schedule',
          client_id: null,
          status_id: 6,
          status_type: 'Project',
          item_type_id: null,
          note_category_id: null,
        },
        windows: null,
        customer: {
          address: {
            city: 'MOUNT WASHINGTON',
            state: 'KY',
            zipcode: '40047',
            address1: '524 GOLDEN WING RD',
            address2: null,
            latitude: '38.05006270000000',
            longitude: '-85.54578770000000',
            address_id: 5924445,
            created_at: '2023-03-04T21:41:44.000Z',
            modified_at: null,
            occupant_type: 'Customer',
          },
          client_id: '15PF12LC',
          last_name: 'JOHNSON',
          first_name: 'JAKE',
          customer_id: 353990,
          primary_phone: '(502) 331-3634',
          alternate_phone: '(502) 331-3634',
          customer_emails: [],
          customer_phones: [
            {
              created_at: '2023-03-04T21:41:47.000Z',
              phone_type: {
                name: 'Mobile',
                created_at: '2022-09-29T15:52:00.000Z',
                modified_at: null,
                phone_type_id: 2,
              },
              customer_id: 353990,
              modified_at: null,
              phone_number: '(502) 331-3634',
              phone_type_id: 2,
              customer_phone_id: 387822,
            },
          ],
          billing_address_id: 5924445,
          receive_text_message: null,
          customer_related_notes: null,
          primary_mode_of_contact: 'Phone',
          refferal_source_name_id: null,
          refferal_account_source_id: null,
        },
        location: null,
        store_id: 4742,
        client_id: '15PF12LC',
        crew_lead: null,
        date_sold: '2023-03-04T00:00:00.000Z',
        po_number: '218517213',
        qb_rec_id: null,
        status_id: 6,
        created_at: '2023-03-04T21:41:47.000Z',
        created_by: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
        dd_confirm: 0,
        epa_job_id: null,
        permit_job: null,
        project_id: 449585,
        qb_proj_id: null,
        quote_name: null,
        year_built: null,
        customer_id: 353990,
        modified_at: '2023-03-04T21:45:16.000Z',
        modified_by: null,
        on_way_date: null,
        created_user: {
          user_id: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
          last_name: ' ',
          first_name: 'System',
          middle_name: null,
          user_meta_detail_id: 1,
        },
        job_end_time: null,
        pick_up_date: null,
        project_desc: 'CARPET & LAMINATE DETAIL',
        project_type: { project_type: 'Measurement', project_type_id: 1 },
        qb_proj_type: null,
        quote_number: null,
        modified_user: null,
        review_status: 'No',
        source_status: {
          status: 'Ready To Schedule',
          client_id: null,
          status_id: 78,
          status_type: 'Source',
          item_type_id: null,
          note_category_id: null,
        },
        source_system: {
          logo_url:
            'https://dsharma-demo-aws.s3.ap-south-1.amazonaws.com/lowes_logo.JPG',
          source_name: 'Lowes',
          source_system_id: 1,
        },
        invoice_number: null,
        job_start_time: null,
        project_number: '770767351',
        completion_date: null,
        override_reason: null,
        project_type_id: 1,
        work_order_name: null,
        current_activity: 'Ready to Schedule',
        est_job_duration: null,
        project_activity: [{ activity_status: 'Open' }],
        project_category: {
          category: 'FLOOR - CARPET',
          client_id: '15PF12LC',
          project_category_id: 136,
        },
        source_status_id: 78,
        source_system_id: 1,
        detail_fee_number: '',
        epa_permit_number: null,
        previous_provider: null,
        project_floorsoft: [],
        project_installer: [],
        reschedule_reason: {
          reason_id: 1,
          reason_code: 'Provider Reschedule',
          reason_desc: 'Provider Reschedule',
          reason_type: null,
          source_system_id: 1,
        },
        total_sale_amount: '40.00',
        work_order_number: null,
        date_scheduled_end: null,
        is_permit_obtained: null,
        is_permit_required: null,
        job_cancelled_date: null,
        production_type_id: null,
        rts_follow_up_date: null,
        scheduled_date_811: null,
        confirm_appointment: 0,
        confirmation_status: null,
        date_completed_date: null,
        date_scheduled_date: null,
        epa_permit_required: null,
        epa_remove_override: null,
        installer_reference: null,
        is_emergency_permit: null,
        lowes_delivery_date: null,
        lowes_delivery_sold: null,
        no_of_messages_sent: 0,
        pick_up_location_id: null,
        project_category_id: 136,
        absolute_project_url: null,
        date_scheduled_start: null,
        dd_confirm_follow_up: null,
        epa_lead_remediation: 0,
        installation_address: {
          city: 'MOUNT WASHINGTON',
          state: 'KY',
          zipcode: '40047',
          address1: '524 GOLDEN WING RD',
          address2: null,
          latitude: '38.05006270000000',
          longitude: '-85.54578770000000',
          address_id: 5924445,
          occupant_type: 'Customer',
        },
        lead_response_reason: null,
        reschedule_reason_id: 1,
        confirmation_status_id: null,
        date_scheduled_user_id: null,
        is_detail_fee_deducted: null,
        is_inspection_required: null,
        product_available_date: null,
        current_activity_status: 'Open',
        date_completion_user_id: null,
        epa_lead_test_result_id: null,
        installation_address_id: 5924445,
        is_pull_permit_required: 0,
        open_in_provider_portal:
          'https://scs-lowes.microsoftcrmportals.com/detail-management/details/detail-information/?id=0f2402d2-d4ba-ed11-9884-6045bdd5695f',
        is_pick_up_date_provided: null,
        current_activity_due_date: '2023-03-07T04:59:59.000Z',
        epa_is_renovator_certified: null,
        epa_lead_response_reason_id: null,
        epa_override_reason_code_id: null,
        epa_lead_assessment_required: 1,
        epa_add_lead_safe_practices_item: null,
        epa_is_lead_assessment_docs_submitted: null,
      },
      category: 'project-note',
      client_id: '15PF12LC',
      project_id: 449585,
    },
    engine_result: {
      events: [
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
      ],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
          ],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '770767351',
                  result: true,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  factResult: '40.00',
                  result: true,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 78,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project-note',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  factResult: 2,
                  result: true,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 0,
                  result: true,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  factResult: 6,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project-note',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  factResult: 2,
                  result: true,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 78,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 6,
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 6,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 78,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  factResult: 78,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 6,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 78,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 78,
                      result: true,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 6,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '15PF12LC',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 78,
                      result: true,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 6,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'add',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 78,
                      result: true,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 6,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 78,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 78,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project-note',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'add',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 78,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 0,
                          result: true,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project-note',
                result: true,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                factResult: 2,
                result: true,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: true,
        },
      ],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '770767351',
                result: true,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                factResult: '40.00',
                result: true,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 78,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project-note',
                result: true,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                factResult: 2,
                result: true,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 0,
                result: true,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                factResult: 6,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 78,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 6,
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 6,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 78,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                factResult: 78,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 6,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 78,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 78,
                    result: true,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 6,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '15PF12LC',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 78,
                    result: true,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 6,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'add',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 78,
                    result: true,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 6,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 78,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 78,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project-note',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'add',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 78,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 0,
                        result: true,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:45:59',
    modified_at: '2023-03-06 12:45:59',
  },
  {
    engine_result_id: 2,
    client_id: '22PF89TG',
    project_id: 433476,
    project_number: '769883666',
    po_number: '211222039',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {},
      category: 'project',
      client_id: '22PF89TG',
      project_id: 433476,
    },
    engine_result: {
      events: [],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:50:53',
    modified_at: '2023-03-06 12:50:53',
  },
  {
    engine_result_id: 3,
    client_id: '22PF89TG',
    project_id: 433476,
    project_number: '769883666',
    po_number: '211222039',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {
        store: {
          store_id: 11480,
          store_name: "LOWE'S OF LEOMINSTER, MA",
          store_number: 1858,
        },
        market: null,
        region: null,
        status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 7,
          status_type: 'Project',
          item_type_id: null,
          note_category_id: null,
        },
        windows: null,
        customer: {
          address: {
            city: 'LEOMINSTER',
            state: 'MA',
            zipcode: '01453',
            address1: '320 UNION ST',
            address2: null,
            latitude: '42.49743570000000',
            longitude: '-71.75281690000000',
            address_id: 5911318,
            created_at: '2023-02-25T17:35:44.000Z',
            modified_at: null,
            occupant_type: 'Customer',
          },
          client_id: '22PF89TG',
          last_name: 'BLAIR',
          first_name: 'ED',
          customer_id: 342831,
          primary_phone: '(978) 407-3536',
          alternate_phone: '(978) 407-3536',
          customer_emails: [],
          customer_phones: [
            {
              created_at: '2023-02-25T17:35:47.000Z',
              phone_type: {
                name: 'Mobile',
                created_at: '2022-09-29T15:52:00.000Z',
                modified_at: null,
                phone_type_id: 2,
              },
              customer_id: 342831,
              modified_at: null,
              phone_number: '(978) 407-3536',
              phone_type_id: 2,
              customer_phone_id: 376469,
            },
          ],
          billing_address_id: 5911318,
          receive_text_message: null,
          customer_related_notes: null,
          primary_mode_of_contact: 'Phone',
          refferal_source_name_id: null,
          refferal_account_source_id: null,
        },
        location: null,
        store_id: 11480,
        client_id: '22PF89TG',
        crew_lead: null,
        date_sold: '2023-02-25T00:00:00.000Z',
        po_number: '211222039',
        qb_rec_id: null,
        status_id: 7,
        created_at: '2023-02-25T17:35:47.000Z',
        created_by: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
        dd_confirm: 0,
        epa_job_id: null,
        permit_job: null,
        project_id: 433476,
        qb_proj_id: null,
        quote_name: null,
        year_built: null,
        customer_id: 342831,
        modified_at: '2023-03-06T12:50:31.000Z',
        modified_by: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
        on_way_date: null,
        created_user: {
          user_id: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
          last_name: ' ',
          first_name: 'System',
          middle_name: null,
          user_meta_detail_id: 1,
        },
        job_end_time: null,
        pick_up_date: null,
        project_desc: 'FRONT DOOR',
        project_type: { project_type: 'Measurement', project_type_id: 1 },
        qb_proj_type: null,
        quote_number: null,
        modified_user: {
          user_id: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
          last_name: 'Smith',
          first_name: 'Maggie',
          middle_name: null,
          user_meta_detail_id: 257,
        },
        review_status: 'No',
        source_status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 76,
          status_type: 'Source',
          item_type_id: null,
          note_category_id: null,
        },
        source_system: {
          logo_url:
            'https://dsharma-demo-aws.s3.ap-south-1.amazonaws.com/lowes_logo.JPG',
          source_name: 'Lowes',
          source_system_id: 1,
        },
        invoice_number: null,
        job_start_time: null,
        project_number: '769883666',
        completion_date: null,
        override_reason: null,
        project_type_id: 1,
        work_order_name: null,
        current_activity: 'Work Complete',
        est_job_duration: null,
        project_activity: [
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
        ],
        project_category: {
          category: 'MWORK - INT/EXT/PATIO DOOR',
          client_id: '22PF89TG',
          project_category_id: 309,
        },
        source_status_id: 76,
        source_system_id: 1,
        detail_fee_number: '',
        epa_permit_number: null,
        previous_provider: null,
        project_floorsoft: [],
        project_installer: [
          {
            worktypeid: 5,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: '2023-03-07T15:30:00.000Z',
            date_scheduled_start: '2023-03-07T15:00:00.000Z',
            project_installer_id: 229546,
          },
        ],
        reschedule_reason: {
          reason_id: 1,
          reason_code: 'Provider Reschedule',
          reason_desc: 'Provider Reschedule',
          reason_type: null,
          source_system_id: 1,
        },
        total_sale_amount: '35.00',
        work_order_number: null,
        date_scheduled_end: '2023-03-07T15:30:00.000Z',
        is_permit_obtained: null,
        is_permit_required: null,
        job_cancelled_date: null,
        production_type_id: null,
        rts_follow_up_date: null,
        scheduled_date_811: null,
        confirm_appointment: 0,
        confirmation_status: null,
        date_completed_date: null,
        date_scheduled_date: '2023-02-27T13:19:36.000Z',
        epa_permit_required: null,
        epa_remove_override: null,
        installer_reference: null,
        is_emergency_permit: null,
        lowes_delivery_date: null,
        lowes_delivery_sold: null,
        no_of_messages_sent: 0,
        pick_up_location_id: null,
        project_category_id: 309,
        absolute_project_url: null,
        date_scheduled_start: '2023-03-07T15:00:00.000Z',
        dd_confirm_follow_up: null,
        epa_lead_remediation: 0,
        installation_address: {
          city: 'LEOMINSTER',
          state: 'MA',
          zipcode: '01453',
          address1: '320 UNION ST',
          address2: null,
          latitude: '42.49743570000000',
          longitude: '-71.75281690000000',
          address_id: 5911318,
          occupant_type: 'Customer',
        },
        lead_response_reason: null,
        reschedule_reason_id: 1,
        confirmation_status_id: null,
        date_scheduled_user_id: null,
        is_detail_fee_deducted: null,
        is_inspection_required: null,
        product_available_date: null,
        current_activity_status: 'Open',
        date_completion_user_id: null,
        epa_lead_test_result_id: null,
        installation_address_id: 5911318,
        is_pull_permit_required: 0,
        open_in_provider_portal:
          'https://scs-lowes.microsoftcrmportals.com/detail-management/details/detail-information/?id=fa5a6559-32b5-ed11-9884-6045bdf01743',
        is_pick_up_date_provided: null,
        current_activity_due_date: '2023-03-08T04:59:59.000Z',
        epa_is_renovator_certified: null,
        epa_lead_response_reason_id: null,
        epa_override_reason_code_id: null,
        epa_lead_assessment_required: 1,
        epa_add_lead_safe_practices_item: null,
        epa_is_lead_assessment_docs_submitted: null,
      },
      category: 'project',
      client_id: '22PF89TG',
      project_id: 433476,
    },
    engine_result: {
      events: [
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
      ],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
          ],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '769883666',
                  result: true,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: 552,
                  result: true,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  factResult: '35.00',
                  result: true,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 0,
                  result: true,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Work Complete',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Work Complete',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 0,
                          result: true,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:00:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: true,
        },
      ],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '769883666',
                result: true,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: 552,
                result: true,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                factResult: '35.00',
                result: true,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 0,
                result: true,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:00:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Work Complete',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Work Complete',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 0,
                        result: true,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:50:53',
    modified_at: '2023-03-06 12:50:53',
  },
  {
    engine_result_id: 4,
    client_id: '22PF89TG',
    project_id: 433476,
    project_number: '769883666',
    po_number: '211222039',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {},
      category: 'project',
      client_id: '22PF89TG',
      project_id: '433476',
    },
    engine_result: {
      events: [],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:50:54',
    modified_at: '2023-03-06 12:50:54',
  },
  {
    engine_result_id: 5,
    client_id: '22PF89TG',
    project_id: 433476,
    project_number: '769883666',
    po_number: '211222039',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {
        store: {
          store_id: 11480,
          store_name: "LOWE'S OF LEOMINSTER, MA",
          store_number: 1858,
        },
        market: null,
        region: null,
        status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 7,
          status_type: 'Project',
          item_type_id: null,
          note_category_id: null,
        },
        windows: null,
        customer: {
          address: {
            city: 'LEOMINSTER',
            state: 'MA',
            zipcode: '01453',
            address1: '320 UNION ST',
            address2: null,
            latitude: '42.49743570000000',
            longitude: '-71.75281690000000',
            address_id: 5911318,
            created_at: '2023-02-25T17:35:44.000Z',
            modified_at: null,
            occupant_type: 'Customer',
          },
          client_id: '22PF89TG',
          last_name: 'BLAIR',
          first_name: 'ED',
          customer_id: 342831,
          primary_phone: '(978) 407-3536',
          alternate_phone: '(978) 407-3536',
          customer_emails: [],
          customer_phones: [
            {
              created_at: '2023-02-25T17:35:47.000Z',
              phone_type: {
                name: 'Mobile',
                created_at: '2022-09-29T15:52:00.000Z',
                modified_at: null,
                phone_type_id: 2,
              },
              customer_id: 342831,
              modified_at: null,
              phone_number: '(978) 407-3536',
              phone_type_id: 2,
              customer_phone_id: 376469,
            },
          ],
          billing_address_id: 5911318,
          receive_text_message: null,
          customer_related_notes: null,
          primary_mode_of_contact: 'Phone',
          refferal_source_name_id: null,
          refferal_account_source_id: null,
        },
        location: null,
        store_id: 11480,
        client_id: '22PF89TG',
        crew_lead: null,
        date_sold: '2023-02-25T00:00:00.000Z',
        po_number: '211222039',
        qb_rec_id: null,
        status_id: 7,
        created_at: '2023-02-25T17:35:47.000Z',
        created_by: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
        dd_confirm: 0,
        epa_job_id: null,
        permit_job: null,
        project_id: 433476,
        qb_proj_id: null,
        quote_name: null,
        year_built: null,
        customer_id: 342831,
        modified_at: '2023-03-06T12:50:31.000Z',
        modified_by: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
        on_way_date: null,
        created_user: {
          user_id: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
          last_name: ' ',
          first_name: 'System',
          middle_name: null,
          user_meta_detail_id: 1,
        },
        job_end_time: null,
        pick_up_date: null,
        project_desc: 'FRONT DOOR',
        project_type: { project_type: 'Measurement', project_type_id: 1 },
        qb_proj_type: null,
        quote_number: null,
        modified_user: {
          user_id: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
          last_name: 'Smith',
          first_name: 'Maggie',
          middle_name: null,
          user_meta_detail_id: 257,
        },
        review_status: 'No',
        source_status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 76,
          status_type: 'Source',
          item_type_id: null,
          note_category_id: null,
        },
        source_system: {
          logo_url:
            'https://dsharma-demo-aws.s3.ap-south-1.amazonaws.com/lowes_logo.JPG',
          source_name: 'Lowes',
          source_system_id: 1,
        },
        invoice_number: null,
        job_start_time: null,
        project_number: '769883666',
        completion_date: null,
        override_reason: null,
        project_type_id: 1,
        work_order_name: null,
        current_activity: 'Work Complete',
        est_job_duration: null,
        project_activity: [
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
        ],
        project_category: {
          category: 'MWORK - INT/EXT/PATIO DOOR',
          client_id: '22PF89TG',
          project_category_id: 309,
        },
        source_status_id: 76,
        source_system_id: 1,
        detail_fee_number: '',
        epa_permit_number: null,
        previous_provider: null,
        project_floorsoft: [],
        project_installer: [
          {
            worktypeid: 5,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: '2023-03-07T15:30:00.000Z',
            date_scheduled_start: '2023-03-07T15:00:00.000Z',
            project_installer_id: 229546,
          },
        ],
        reschedule_reason: {
          reason_id: 1,
          reason_code: 'Provider Reschedule',
          reason_desc: 'Provider Reschedule',
          reason_type: null,
          source_system_id: 1,
        },
        total_sale_amount: '35.00',
        work_order_number: null,
        date_scheduled_end: '2023-03-07T15:30:00.000Z',
        is_permit_obtained: null,
        is_permit_required: null,
        job_cancelled_date: null,
        production_type_id: null,
        rts_follow_up_date: null,
        scheduled_date_811: null,
        confirm_appointment: 0,
        confirmation_status: null,
        date_completed_date: null,
        date_scheduled_date: '2023-02-27T13:19:36.000Z',
        epa_permit_required: null,
        epa_remove_override: null,
        installer_reference: null,
        is_emergency_permit: null,
        lowes_delivery_date: null,
        lowes_delivery_sold: null,
        no_of_messages_sent: 0,
        pick_up_location_id: null,
        project_category_id: 309,
        absolute_project_url: null,
        date_scheduled_start: '2023-03-07T15:00:00.000Z',
        dd_confirm_follow_up: null,
        epa_lead_remediation: 0,
        installation_address: {
          city: 'LEOMINSTER',
          state: 'MA',
          zipcode: '01453',
          address1: '320 UNION ST',
          address2: null,
          latitude: '42.49743570000000',
          longitude: '-71.75281690000000',
          address_id: 5911318,
          occupant_type: 'Customer',
        },
        lead_response_reason: null,
        reschedule_reason_id: 1,
        confirmation_status_id: null,
        date_scheduled_user_id: null,
        is_detail_fee_deducted: null,
        is_inspection_required: null,
        product_available_date: null,
        current_activity_status: 'Open',
        date_completion_user_id: null,
        epa_lead_test_result_id: null,
        installation_address_id: 5911318,
        is_pull_permit_required: 0,
        open_in_provider_portal:
          'https://scs-lowes.microsoftcrmportals.com/detail-management/details/detail-information/?id=fa5a6559-32b5-ed11-9884-6045bdf01743',
        is_pick_up_date_provided: null,
        current_activity_due_date: '2023-03-08T04:59:59.000Z',
        epa_is_renovator_certified: null,
        epa_lead_response_reason_id: null,
        epa_override_reason_code_id: null,
        epa_lead_assessment_required: 1,
        epa_add_lead_safe_practices_item: null,
        epa_is_lead_assessment_docs_submitted: null,
      },
      category: 'project',
      client_id: '22PF89TG',
      project_id: '433476',
    },
    engine_result: {
      events: [
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
      ],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
          ],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '769883666',
                  result: true,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: 552,
                  result: true,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  factResult: '35.00',
                  result: true,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 0,
                  result: true,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: true,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:00:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Work Complete',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Work Complete',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Work Complete',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Work Complete',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 0,
                          result: true,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:00:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:00:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: true,
        },
      ],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '769883666',
                result: true,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: 552,
                result: true,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                factResult: '35.00',
                result: true,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 0,
                result: true,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Work Complete',
                result: true,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:00:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Work Complete',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Work Complete',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Work Complete',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Work Complete',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 0,
                        result: true,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:50:54',
    modified_at: '2023-03-06 12:50:54',
  },
  {
    engine_result_id: 6,
    client_id: '22PF89TG',
    project_id: 396506,
    project_number: '764271724',
    po_number: '211221907',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {},
      category: 'project',
      client_id: '22PF89TG',
      project_id: 396506,
    },
    engine_result: {
      events: [],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:52:10',
    modified_at: '2023-03-06 12:52:10',
  },
  {
    engine_result_id: 7,
    client_id: '22PF89TG',
    project_id: 396506,
    project_number: '764271724',
    po_number: '211221907',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {
        store: {
          store_id: 11480,
          store_name: "LOWE'S OF LEOMINSTER, MA",
          store_number: 1858,
        },
        market: null,
        region: null,
        status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 7,
          status_type: 'Project',
          item_type_id: null,
          note_category_id: null,
        },
        windows: null,
        customer: {
          address: {
            city: 'LEOMINSTER',
            state: 'MA',
            zipcode: '01453',
            address1: '107 EVA DR',
            address2: null,
            latitude: '42.50325250000000',
            longitude: '-71.76857310000000',
            address_id: 724659,
            created_at: '2023-02-10T15:36:33.000Z',
            modified_at: null,
            occupant_type: 'Customer',
          },
          client_id: '22PF89TG',
          last_name: 'FRATTURELLI',
          first_name: 'MIKE',
          customer_id: 319828,
          primary_phone: '(978) 424-3715',
          alternate_phone: '(978) 424-3715',
          customer_emails: [
            {
              email: 'MIFRATTURELLI@GMAIL.CLM',
              created_at: '2023-02-10T15:36:33.000Z',
              email_type: 'Primary',
              customer_id: 319828,
              modified_at: null,
              customer_email_id: 280017,
            },
          ],
          customer_phones: [
            {
              created_at: '2023-02-10T15:36:33.000Z',
              phone_type: {
                name: 'Mobile',
                created_at: '2022-09-29T15:52:00.000Z',
                modified_at: null,
                phone_type_id: 2,
              },
              customer_id: 319828,
              modified_at: null,
              phone_number: '(978) 424-3715',
              phone_type_id: 2,
              customer_phone_id: 352794,
            },
          ],
          billing_address_id: 724659,
          receive_text_message: null,
          customer_related_notes: null,
          primary_mode_of_contact: null,
          refferal_source_name_id: null,
          refferal_account_source_id: null,
        },
        location: null,
        store_id: 11480,
        client_id: '22PF89TG',
        crew_lead: null,
        date_sold: '2023-02-08T00:00:00.000Z',
        po_number: '211221907',
        qb_rec_id: null,
        status_id: 7,
        created_at: '2023-02-08T16:03:49.000Z',
        created_by: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
        dd_confirm: 0,
        epa_job_id: null,
        permit_job: null,
        project_id: 396506,
        qb_proj_id: 531100,
        quote_name: null,
        year_built: null,
        customer_id: 319828,
        modified_at: '2023-03-06T12:52:10.000Z',
        modified_by: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
        on_way_date: null,
        created_user: {
          user_id: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
          last_name: ' ',
          first_name: 'System',
          middle_name: null,
          user_meta_detail_id: 1,
        },
        job_end_time: null,
        pick_up_date: null,
        project_desc: 'IHC - EXTERIOR DOOR DETAIL',
        project_type: { project_type: 'Measurement', project_type_id: 1 },
        qb_proj_type: 'Details',
        quote_number: null,
        modified_user: {
          user_id: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
          last_name: 'Smith',
          first_name: 'Maggie',
          middle_name: null,
          user_meta_detail_id: 257,
        },
        review_status: 'No',
        source_status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 76,
          status_type: 'Source',
          item_type_id: null,
          note_category_id: null,
        },
        source_system: {
          logo_url:
            'https://dsharma-demo-aws.s3.ap-south-1.amazonaws.com/lowes_logo.JPG',
          source_name: 'Lowes',
          source_system_id: 1,
        },
        invoice_number: null,
        job_start_time: null,
        project_number: '764271724',
        completion_date: null,
        override_reason: null,
        project_type_id: 1,
        work_order_name: null,
        current_activity: 'Ready to Schedule',
        est_job_duration: null,
        project_activity: [
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
        ],
        project_category: {
          category: 'MWORK - PSE EXT DOOR',
          client_id: '22PF89TG',
          project_category_id: 308,
        },
        source_status_id: 76,
        source_system_id: 1,
        detail_fee_number: '',
        epa_permit_number: null,
        previous_provider: null,
        project_floorsoft: [],
        project_installer: [
          {
            worktypeid: 1,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: null,
            date_scheduled_start: null,
            project_installer_id: 97439,
          },
          {
            worktypeid: 5,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: '2023-03-07T16:00:00.000Z',
            date_scheduled_start: '2023-03-07T15:30:00.000Z',
            project_installer_id: 229547,
          },
        ],
        reschedule_reason: {
          reason_id: 1,
          reason_code: 'Provider Reschedule',
          reason_desc: 'Provider Reschedule',
          reason_type: null,
          source_system_id: 1,
        },
        total_sale_amount: '0.01',
        work_order_number: null,
        date_scheduled_end: '2023-03-07T16:00:00.000Z',
        is_permit_obtained: null,
        is_permit_required: null,
        job_cancelled_date: null,
        production_type_id: null,
        rts_follow_up_date: '2023-03-06T00:00:00.000Z',
        scheduled_date_811: null,
        confirm_appointment: 0,
        confirmation_status: null,
        date_completed_date: null,
        date_scheduled_date: '2023-02-24T16:09:44.000Z',
        epa_permit_required: null,
        epa_remove_override: null,
        installer_reference: '',
        is_emergency_permit: null,
        lowes_delivery_date: null,
        lowes_delivery_sold: null,
        no_of_messages_sent: 0,
        pick_up_location_id: null,
        project_category_id: 308,
        absolute_project_url: null,
        date_scheduled_start: '2023-03-07T15:30:00.000Z',
        dd_confirm_follow_up: null,
        epa_lead_remediation: 0,
        installation_address: {
          city: 'LEOMINSTER',
          state: 'MA',
          zipcode: '01453',
          address1: '107 EVA DR',
          address2: null,
          latitude: '42.50325249999999',
          longitude: '-71.76857310000000',
          address_id: 728213,
          occupant_type: 'Customer',
        },
        lead_response_reason: null,
        reschedule_reason_id: 1,
        confirmation_status_id: null,
        date_scheduled_user_id: null,
        is_detail_fee_deducted: null,
        is_inspection_required: null,
        product_available_date: null,
        current_activity_status: 'Open',
        date_completion_user_id: null,
        epa_lead_test_result_id: null,
        installation_address_id: 728213,
        is_pull_permit_required: 0,
        open_in_provider_portal:
          'https://scs-lowes.microsoftcrmportals.com/detail-management/details/detail-information/?id=3087ed3e-c9a7-ed11-aacf-002248318e41',
        is_pick_up_date_provided: null,
        current_activity_due_date: '2023-03-07T04:59:59.000Z',
        epa_is_renovator_certified: null,
        epa_lead_response_reason_id: null,
        epa_override_reason_code_id: null,
        epa_lead_assessment_required: 1,
        epa_add_lead_safe_practices_item: null,
        epa_is_lead_assessment_docs_submitted: null,
      },
      category: 'project',
      client_id: '22PF89TG',
      project_id: 396506,
    },
    engine_result: {
      events: [
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
      ],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
          ],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '764271724',
                  result: true,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: 552,
                  result: true,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  factResult: '0.01',
                  result: true,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: true,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 0,
                  result: true,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: true,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T16:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T16:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '',
                  result: true,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 0,
                          result: true,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T16:00:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '',
                result: true,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: true,
        },
      ],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '764271724',
                result: true,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: 552,
                result: true,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                factResult: '0.01',
                result: true,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: true,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 0,
                result: true,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: true,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T16:00:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 0,
                        result: true,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:52:10',
    modified_at: '2023-03-06 12:52:10',
  },
  {
    engine_result_id: 8,
    client_id: '22PF89TG',
    project_id: 396506,
    project_number: '764271724',
    po_number: '211221907',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {},
      category: 'project',
      client_id: '22PF89TG',
      project_id: '396506',
    },
    engine_result: {
      events: [],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:52:12',
    modified_at: '2023-03-06 12:52:12',
  },
  {
    engine_result_id: 9,
    client_id: '22PF89TG',
    project_id: 396506,
    project_number: '764271724',
    po_number: '211221907',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {
        store: {
          store_id: 11480,
          store_name: "LOWE'S OF LEOMINSTER, MA",
          store_number: 1858,
        },
        market: null,
        region: null,
        status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 7,
          status_type: 'Project',
          item_type_id: null,
          note_category_id: null,
        },
        windows: null,
        customer: {
          address: {
            city: 'LEOMINSTER',
            state: 'MA',
            zipcode: '01453',
            address1: '107 EVA DR',
            address2: null,
            latitude: '42.50325250000000',
            longitude: '-71.76857310000000',
            address_id: 724659,
            created_at: '2023-02-10T15:36:33.000Z',
            modified_at: null,
            occupant_type: 'Customer',
          },
          client_id: '22PF89TG',
          last_name: 'FRATTURELLI',
          first_name: 'MIKE',
          customer_id: 319828,
          primary_phone: '(978) 424-3715',
          alternate_phone: '(978) 424-3715',
          customer_emails: [
            {
              email: 'MIFRATTURELLI@GMAIL.CLM',
              created_at: '2023-02-10T15:36:33.000Z',
              email_type: 'Primary',
              customer_id: 319828,
              modified_at: null,
              customer_email_id: 280017,
            },
          ],
          customer_phones: [
            {
              created_at: '2023-02-10T15:36:33.000Z',
              phone_type: {
                name: 'Mobile',
                created_at: '2022-09-29T15:52:00.000Z',
                modified_at: null,
                phone_type_id: 2,
              },
              customer_id: 319828,
              modified_at: null,
              phone_number: '(978) 424-3715',
              phone_type_id: 2,
              customer_phone_id: 352794,
            },
          ],
          billing_address_id: 724659,
          receive_text_message: null,
          customer_related_notes: null,
          primary_mode_of_contact: null,
          refferal_source_name_id: null,
          refferal_account_source_id: null,
        },
        location: null,
        store_id: 11480,
        client_id: '22PF89TG',
        crew_lead: null,
        date_sold: '2023-02-08T00:00:00.000Z',
        po_number: '211221907',
        qb_rec_id: null,
        status_id: 7,
        created_at: '2023-02-08T16:03:49.000Z',
        created_by: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
        dd_confirm: 0,
        epa_job_id: null,
        permit_job: null,
        project_id: 396506,
        qb_proj_id: 531100,
        quote_name: null,
        year_built: null,
        customer_id: 319828,
        modified_at: '2023-03-06T12:52:10.000Z',
        modified_by: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
        on_way_date: null,
        created_user: {
          user_id: '6fb91b7b-f5bf-11ec-8fa1-0a924d8c6a19',
          last_name: ' ',
          first_name: 'System',
          middle_name: null,
          user_meta_detail_id: 1,
        },
        job_end_time: null,
        pick_up_date: null,
        project_desc: 'IHC - EXTERIOR DOOR DETAIL',
        project_type: { project_type: 'Measurement', project_type_id: 1 },
        qb_proj_type: 'Details',
        quote_number: null,
        modified_user: {
          user_id: 'beb0cafa-9290-11ed-a9cc-066c21ff9482',
          last_name: 'Smith',
          first_name: 'Maggie',
          middle_name: null,
          user_meta_detail_id: 257,
        },
        review_status: 'No',
        source_status: {
          status: 'Scheduled',
          client_id: null,
          status_id: 76,
          status_type: 'Source',
          item_type_id: null,
          note_category_id: null,
        },
        source_system: {
          logo_url:
            'https://dsharma-demo-aws.s3.ap-south-1.amazonaws.com/lowes_logo.JPG',
          source_name: 'Lowes',
          source_system_id: 1,
        },
        invoice_number: null,
        job_start_time: null,
        project_number: '764271724',
        completion_date: null,
        override_reason: null,
        project_type_id: 1,
        work_order_name: null,
        current_activity: 'Ready to Schedule',
        est_job_duration: null,
        project_activity: [
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Completed' },
          { activity_status: 'Open' },
        ],
        project_category: {
          category: 'MWORK - PSE EXT DOOR',
          client_id: '22PF89TG',
          project_category_id: 308,
        },
        source_status_id: 76,
        source_system_id: 1,
        detail_fee_number: '',
        epa_permit_number: null,
        previous_provider: null,
        project_floorsoft: [],
        project_installer: [
          {
            worktypeid: 1,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: null,
            date_scheduled_start: null,
            project_installer_id: 97439,
          },
          {
            worktypeid: 5,
            installer_id: 552,
            installer_detail: {
              ssn: null,
              user: {
                dob: null,
                team_id: '2,1',
                user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
                documents: [
                  {
                    file: {
                      url: 'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/documents/202302148f2fa4a0-7a27-470f-9569-0b6fed04412f_Elon%20Acceptance%20Letter.pdf',
                      name: 'Elon Acceptance Letter.pdf',
                    },
                    notes: null,
                    description: 'Test letter',
                  },
                ],
                hire_date: null,
                last_name: 'Brehio',
                shoe_size: '7',
                address_id: 722981,
                contact_no: '(603) 545-7803',
                created_at: '2023-02-10T15:32:15.000Z',
                first_name: 'Glen',
                companyname: null,
                credentials: [
                  {
                    city: 'Hopkinton',
                    type: 4,
                    state: 'NH',
                    number: '(603) 545-7803',
                    description: 'Drivers',
                    issuing_body: 'NH',
                    insurance_type: null,
                    expiration_date: '2025-11-06T00:00:00.000Z',
                  },
                ],
                lastvisited: '2023-02-15T19:14:24.000Z',
                middle_name: null,
                modified_at: '2023-02-15T19:14:24.000Z',
                position_id: 5,
                installerbio: null,
                team_reviews: [
                  {
                    type: 1,
                    files: [],
                    description:
                      'One of the greatest installers and owners to ever live!!!!',
                  },
                ],
                dashboard_ids: '132',
                shirt_size_id: null,
                invite_status_id: null,
                termination_date: null,
                measuringsystemid: null,
                profile_image_url:
                  'https://projectforce-prod-ui.s3.us-east-2.amazonaws.com/clients/22PF89TG/user/18f19570-a958-11ed-a9cc-066c21ff9482/Glen%20pic.jpg',
                schedulesortorder: null,
                send_email_invite: false,
                type_coverage_ids: null,
                store_coverage_ids: null,
                user_meta_detail_id: 573,
                google_email_address: 'gbrehio@gmail.com',
                catergory_coverage_ids: null,
                emergency_contact_info: [
                  {
                    name: 'Teri Brehio',
                    phone: '(603) 860-8853',
                    relationship: 1,
                  },
                ],
              },
              tax_id: null,
              crew_id: null,
              user_id: '18f19570-a958-11ed-a9cc-066c21ff9482',
              check_in: '2023-02-15T19:09:01.000Z',
              check_out: '2023-02-15T19:14:06.000Z',
              client_id: '22PF89TG',
              hire_date: null,
              shoe_size: null,
              created_at: '2023-02-10T15:32:15.000Z',
              fax_number: null,
              hourly_rate: null,
              labor_total: null,
              modified_at: '2023-02-15T19:14:07.000Z',
              installer_id: 552,
              installer_bio: null,
              shirt_size_id: null,
              skill_tier_id: null,
              payroll_type_id: null,
              badge_expiration: null,
              friday_available: null,
              monday_available: null,
              monthly_base_pay: null,
              sunday_available: null,
              able_to_see_labor: null,
              tuesday_available: null,
              type_coverage_ids: null,
              saturday_available: null,
              store_coverage_ids: null,
              thursday_available: null,
              floorsoft_id_number: null,
              wednesday_available: null,
              project_category_ids: null,
              custom_labor_percentage: null,
            },
            date_scheduled_end: '2023-03-07T16:00:00.000Z',
            date_scheduled_start: '2023-03-07T15:30:00.000Z',
            project_installer_id: 229547,
          },
        ],
        reschedule_reason: {
          reason_id: 1,
          reason_code: 'Provider Reschedule',
          reason_desc: 'Provider Reschedule',
          reason_type: null,
          source_system_id: 1,
        },
        total_sale_amount: '0.01',
        work_order_number: null,
        date_scheduled_end: '2023-03-07T16:00:00.000Z',
        is_permit_obtained: null,
        is_permit_required: null,
        job_cancelled_date: null,
        production_type_id: null,
        rts_follow_up_date: '2023-03-06T00:00:00.000Z',
        scheduled_date_811: null,
        confirm_appointment: 0,
        confirmation_status: null,
        date_completed_date: null,
        date_scheduled_date: '2023-02-24T16:09:44.000Z',
        epa_permit_required: null,
        epa_remove_override: null,
        installer_reference: '',
        is_emergency_permit: null,
        lowes_delivery_date: null,
        lowes_delivery_sold: null,
        no_of_messages_sent: 0,
        pick_up_location_id: null,
        project_category_id: 308,
        absolute_project_url: null,
        date_scheduled_start: '2023-03-07T15:30:00.000Z',
        dd_confirm_follow_up: null,
        epa_lead_remediation: 0,
        installation_address: {
          city: 'LEOMINSTER',
          state: 'MA',
          zipcode: '01453',
          address1: '107 EVA DR',
          address2: null,
          latitude: '42.50325249999999',
          longitude: '-71.76857310000000',
          address_id: 728213,
          occupant_type: 'Customer',
        },
        lead_response_reason: null,
        reschedule_reason_id: 1,
        confirmation_status_id: null,
        date_scheduled_user_id: null,
        is_detail_fee_deducted: null,
        is_inspection_required: null,
        product_available_date: null,
        current_activity_status: 'Open',
        date_completion_user_id: null,
        epa_lead_test_result_id: null,
        installation_address_id: 728213,
        is_pull_permit_required: 0,
        open_in_provider_portal:
          'https://scs-lowes.microsoftcrmportals.com/detail-management/details/detail-information/?id=3087ed3e-c9a7-ed11-aacf-002248318e41',
        is_pick_up_date_provided: null,
        current_activity_due_date: '2023-03-07T04:59:59.000Z',
        epa_is_renovator_certified: null,
        epa_lead_response_reason_id: null,
        epa_override_reason_code_id: null,
        epa_lead_assessment_required: 1,
        epa_add_lead_safe_practices_item: null,
        epa_is_lead_assessment_docs_submitted: null,
      },
      category: 'project',
      client_id: '22PF89TG',
      project_id: '396506',
    },
    engine_result: {
      events: [
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
      ],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
          ],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '764271724',
                  result: true,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: 552,
                  result: true,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  factResult: '0.01',
                  result: true,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: true,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  factResult: 0,
                  result: true,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: null,
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-06T00:00:00.000Z',
                  result: true,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T16:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      factResult: '2023-03-07T15:30:00.000Z',
                      result: true,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      factResult: 7,
                      result: true,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T15:30:00.000Z',
                  result: true,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '2023-03-07T16:00:00.000Z',
                  result: true,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  factResult: 7,
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  factResult: '',
                  result: true,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: true,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      factResult: null,
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  factResult: 7,
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 0,
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      factResult: 1,
                      result: true,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  factResult: 1,
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      factResult: 76,
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      factResult: 7,
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  factResult: 76,
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      factResult: 'Ready to Schedule',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          factResult: 'Ready to Schedule',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          factResult: 'Open',
                          result: false,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  factResult: 1,
                  result: true,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  factResult: 76,
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  factResult: 'Ready to Schedule',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  factResult: 'Open',
                  result: true,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      factResult: 1,
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 1,
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          factResult: 0,
                          result: true,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    factResult: '2023-03-07T15:30:00.000Z',
                    result: true,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    factResult: 7,
                    result: true,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T16:00:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: true,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '',
                result: true,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: true,
        },
      ],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '764271724',
                result: true,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: 552,
                result: true,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                factResult: '0.01',
                result: true,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: true,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                factResult: 0,
                result: true,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: null,
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                factResult: '2023-03-06T00:00:00.000Z',
                result: true,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T15:30:00.000Z',
                result: true,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                factResult: '2023-03-07T16:00:00.000Z',
                result: true,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                factResult: 7,
                result: true,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    factResult: null,
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                factResult: 7,
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 0,
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    factResult: 1,
                    result: true,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                factResult: 1,
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    factResult: 76,
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    factResult: 7,
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                factResult: 76,
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    factResult: 'Ready to Schedule',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        factResult: 'Ready to Schedule',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        factResult: 'Open',
                        result: false,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                factResult: 1,
                result: true,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                factResult: 76,
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                factResult: 'Ready to Schedule',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                factResult: 'Open',
                result: true,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    factResult: 1,
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 1,
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        factResult: 0,
                        result: true,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:52:12',
    modified_at: '2023-03-06 12:52:12',
  },
  {
    engine_result_id: 10,
    client_id: '22PF89TG',
    project_id: 396506,
    project_number: '764271724',
    po_number: '211221907',
    topic_name: 'pf-entity-existing-emitter',
    fact: {
      type: 'update',
      source: 'client_update',
      project: {},
      category: 'project',
      client_id: '22PF89TG',
      project_id: 396506,
    },
    engine_result: {
      events: [],
      almanac: {
        events: {
          failure: [
            {
              type: 'Project create payment',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_payment',
              },
            },
            {
              type: 'Lowes FloorSoft Create Project',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_floorsoft_project',
              },
            },
            {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                args: null,
                script: 'update_API',
                function: 'get_floorsoft_documents',
              },
            },
            {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
              },
            },
            {
              type: 'Project create invoice',
              params: {
                args: null,
                script: 'update_API',
                function: 'create_invoice',
              },
            },
            {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_orders',
              },
            },
            {
              type: 'New permit added to Lowes project of type installation',
              params: {
                args: null,
                script: 'exe_lowes_scraping',
                function: 'add_permit',
              },
            },
            {
              type: 'Confirm Appointment',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
              },
            },
            {
              type: 'New Retailer note added for  project',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_note_scraping',
              },
            },
            {
              type: 'Refigure request via scraping',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'refigure_request',
              },
            },
            {
              type: 'New detail document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'New need support request created for installation project',
              params: {
                args: null,
                script: 'update_API',
                function: 'need_support',
              },
            },
            {
              type: 'New need support request created for type detail',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'need_support',
              },
            },
            {
              type: 'New Retailer note added for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_note',
              },
            },
            {
              type: 'Order completed for project',
              params: {
                args: null,
                script: 'update_API',
                function: 'complete_order',
              },
            },
            {
              type: 'On Way Clicked SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [0, 56] },
                function: 'sms_on_way',
              },
            },
            {
              type: 'Project RTS Followup API',
              params: {
                args: null,
                script: 'update_API',
                function: 'update_followup',
              },
            },
            {
              type: 'Reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'reschedule_order',
              },
            },
            {
              type: 'Schedule or reschedule SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [4, 5, 14] },
                function: 'schedule_reschedule_sms',
              },
            },
            {
              type: 'Schedule or reschedule order API',
              params: {
                args: null,
                script: 'update_API',
                function: 'schedule_order',
              },
            },
            {
              type: 'Installer reference added',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'add_installer_reference',
              },
            },
            {
              type: 'DD Confirm',
              params: {
                args: null,
                script: 'exe_scraping',
                function: 'dd_confirm',
              },
            },
            {
              type: 'New lead document added to  project',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
            {
              type: 'Project marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Project marked RTS sms',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: [8],
                function: 'project_marked_rts_sms',
              },
            },
            {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [3, 15] },
                function: 'schedule_tomorrow_response_sms',
              },
            },
            {
              type: 'Workorder marked RTS',
              params: {
                args: null,
                script: 'update_lowes_API',
                options: { template_id: [9] },
                function: 'wo_marked_rts_sms',
              },
            },
            {
              type: 'New Document added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'New photo added for project via API',
              params: {
                args: null,
                script: 'update_API',
                function: 'add_doc_project',
              },
            },
            {
              type: 'COC document added',
              params: {
                args: null,
                script: 'update_API',
                function: 'submit_doc_complete',
              },
            },
          ],
          success: [],
        },
        factMap: {},
        ruleResults: [
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-payment-request',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.source_system_id',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.project.total_sale_amount',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.project.invoice_number',
                },
                {
                  operator: 'greaterThan',
                  value: 0,
                  fact: 'paymentrequest',
                  path: '$.check_amount',
                },
                {
                  operator: 'equal',
                  value: 'Invoice Paid',
                  fact: 'paymentrequest',
                  result: false,
                  path: '$.payment_status',
                },
              ],
            },
            event: {
              type: 'Project create payment',
              params: {
                script: 'update_API',
                function: 'create_payment',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 'Millwork',
                  fact: 'project_category',
                  result: true,
                  path: '$.category',
                },
                {
                  operator: 'notExists',
                  value: null,
                  fact: 'project',
                  result: true,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_number',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Create Project',
              params: {
                script: 'update_API',
                function: 'create_floorsoft_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_completed',
                },
              ],
            },
            event: {
              type: 'Lowes FloorSoft Detail Fetch Document',
              params: {
                script: 'update_API',
                function: 'get_floorsoft_documents',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_floorsoft[0].floorsoft_transaction_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_detail.user.measuringsystemid',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.project_installer[0].installer_id',
                },
              ],
            },
            event: {
              type: 'Lowes Floorsoft Update Installer',
              params: {
                script: 'update_API',
                function: 'update_floorsoft_assign_person',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanZero',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.total_sale_amount',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
              ],
            },
            event: {
              type: 'Project create invoice',
              params: {
                script: 'update_API',
                function: 'create_invoice',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Reschedule RTS FOLLOWUP',
              params: {
                script: 'update_API',
                function: 'reschedule_orders',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-permit',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Pull Permit',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'New permit added to Lowes project of type installation',
              params: {
                script: 'exe_lowes_scraping',
                function: 'add_permit',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-permit.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Confirm Appointment',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Confirm Appointment',
              params: {
                script: 'exe_scraping',
                function: 'confirm_appointment_scrap',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
              ],
            },
            event: {
              type: 'New Retailer note added for  project',
              params: {
                script: 'exe_scraping',
                function: 'add_note_scraping',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 6,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 'Refigure Request',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Refigure request via scraping',
              params: {
                script: 'exe_scraping',
                function: 'refigure_request',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_assessment_required',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 0,
                  fact: 'project',
                  result: false,
                  path: '$.epa_lead_remediation',
                },
              ],
            },
            event: {
              type: 'New detail document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Additional Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Missing/Damaged Product',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                    {
                      operator: 'equal',
                      value: 'Labor Only',
                      fact: 'support',
                      result: false,
                      path: '$.support_type.type_name',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for installation project',
              params: {
                script: 'update_API',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-need-support-api.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-support',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'notEqual',
                  value: 4,
                  fact: 'project',
                  result: true,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'support',
                      result: false,
                      path: '$.support_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New need support request created for type detail',
              params: {
                script: 'exe_scraping',
                function: 'need_support',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scrapping-need-support.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-note',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'note',
                  result: false,
                  path: '$.note_category_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Retailer note added for project',
              params: {
                script: 'update_API',
                function: 'add_note',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-note.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.completion_date',
                },
              ],
            },
            event: {
              type: 'Order completed for project',
              params: {
                script: 'update_API',
                function: 'complete_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-complete-order-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.on_way_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'On Way Clicked SMS',
              params: {
                script: 'update_lowes_API',
                function: 'sms_on_way',
                options: { template_id: [0, 56] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  operator: 'greaterThanToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.rts_follow_up_date',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Project RTS Followup API',
              params: {
                script: 'update_API',
                function: 'update_followup',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'greaterThanOrEqualToday',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 'Work Complete',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Reschedule order API',
              params: {
                script: 'update_API',
                function: 'reschedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-reschedule-orders.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                    {
                      operator: 'greaterThanOrEqualToday',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.date_scheduled_start',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                    {
                      operator: 'equal',
                      value: 7,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_reschedule_sms',
                options: { template_id: [4, 5, 14] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_start',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.date_scheduled_end',
                },
                {
                  operator: 'equal',
                  value: 7,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'Ready to Schedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                    {
                      operator: 'equal',
                      value: 'Reschedule',
                      fact: 'project',
                      result: false,
                      path: '$.current_activity',
                    },
                  ],
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
              ],
            },
            event: {
              type: 'Schedule or reschedule order API',
              params: {
                script: 'update_API',
                function: 'schedule_order',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'exists',
                  value: null,
                  fact: 'project',
                  result: false,
                  path: '$.installer_reference',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Installer reference added',
              params: {
                script: 'exe_scraping',
                function: 'add_installer_reference',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  operator: 'equal',
                  value: 75,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'DD Confirmed',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm',
                    },
                    {
                      operator: 'exists',
                      value: null,
                      fact: 'project',
                      result: false,
                      path: '$.dd_confirm_follow_up',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'DD Confirm',
              params: {
                script: 'exe_scraping',
                function: 'dd_confirm',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 10,
                  fact: 'project',
                  result: false,
                  path: '$.status_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_assessment_required',
                    },
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.epa_lead_remediation',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New lead document added to  project',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: '11MT97PY',
                  fact: 'client_id',
                  factResult: '22PF89TG',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 2,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Project marked RTS sms',
              params: {
                script: 'update_lowes_API',
                function: 'project_marked_rts_sms',
                options: [8],
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_project_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-sms',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'received',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'Yes',
                  fact: 'repliedToMessage',
                  result: false,
                  path: '$.sms_responded',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 1,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 2,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                  ],
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 'SMS Scheduled Tomorrow Reminder',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                    {
                      operator: 'equal',
                      value: 'SMS Schedule Confirmation',
                      fact: 'repliedToMessage',
                      result: false,
                      path: '$.sms_title',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Schedule Tomorrow Response SMS',
              params: {
                script: 'update_lowes_API',
                function: 'schedule_tomorrow_response_sms',
                options: { template_id: [3, 15] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project',
                  fact: 'category',
                  factResult: 'project',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 'update',
                  fact: 'type',
                  factResult: 'update',
                  result: true,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'project',
                  result: false,
                  path: '$.project_type_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 78,
                      fact: 'project',
                      result: false,
                      path: '$.source_status_id',
                    },
                    {
                      operator: 'equal',
                      value: 6,
                      fact: 'project',
                      result: false,
                      path: '$.status_id',
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'Workorder marked RTS',
              params: {
                script: 'update_lowes_API',
                function: 'wo_marked_rts_sms',
                options: { template_id: [9] },
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New Document added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-document-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 3,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 'SOURCE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'notIn',
                  value: [49, 73, 74, 135],
                  fact: 'project',
                  result: true,
                  path: '$.source_status_id',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'notEqual',
                      value: 'Submit Documents',
                      fact: 'project',
                      result: true,
                      path: '$.current_activity',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 'Submit Documents',
                          fact: 'project',
                          result: false,
                          path: '$.current_activity',
                        },
                        {
                          operator: 'notEqual',
                          value: 'Open',
                          fact: 'project',
                          result: true,
                          path: '$.current_activity_status',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'New photo added for project via API',
              params: {
                script: 'update_API',
                function: 'add_doc_project',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-add-photo-API.yaml',
            result: false,
          },
          {
            conditions: {
              priority: 1,
              all: [
                {
                  operator: 'equal',
                  value: 'project-document',
                  fact: 'category',
                  factResult: 'project',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 'add',
                  fact: 'type',
                  factResult: 'update',
                  result: false,
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'document',
                  result: false,
                  path: '$.document_type_id',
                },
                {
                  operator: 'equal',
                  value: 1,
                  fact: 'project',
                  result: false,
                  path: '$.source_system_id',
                },
                {
                  operator: 'equal',
                  value: 77,
                  fact: 'project',
                  result: false,
                  path: '$.source_status_id',
                },
                {
                  operator: 'equal',
                  value: 'Submit Documents',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity',
                },
                {
                  operator: 'equal',
                  value: 'COMPLETE',
                  fact: 'document',
                  result: false,
                  path: '$.document_store_type',
                },
                {
                  operator: 'equal',
                  value: 'Open',
                  fact: 'project',
                  result: false,
                  path: '$.current_activity_status',
                },
                {
                  priority: 1,
                  any: [
                    {
                      operator: 'equal',
                      value: 3,
                      fact: 'project',
                      result: false,
                      path: '$.project_type_id',
                    },
                    {
                      priority: 1,
                      all: [
                        {
                          operator: 'equal',
                          value: 2,
                          fact: 'project',
                          result: false,
                          path: '$.project_type_id',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_assessment_required',
                        },
                        {
                          operator: 'equal',
                          value: 0,
                          fact: 'project',
                          result: false,
                          path: '$.epa_lead_remediation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            event: {
              type: 'COC document added',
              params: {
                script: 'update_API',
                function: 'submit_doc_complete',
                args: null,
              },
            },
            priority: 1,
            name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
            result: false,
          },
        ],
        factResultsCache: {},
        allowUndefinedFacts: true,
      },
      results: [],
      failureEvents: [
        {
          type: 'Project create payment',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_payment',
          },
        },
        {
          type: 'Lowes FloorSoft Create Project',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_floorsoft_project',
          },
        },
        {
          type: 'Lowes FloorSoft Detail Fetch Document',
          params: {
            args: null,
            script: 'update_API',
            function: 'get_floorsoft_documents',
          },
        },
        {
          type: 'Lowes Floorsoft Update Installer',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_floorsoft_assign_person',
          },
        },
        {
          type: 'Project create invoice',
          params: {
            args: null,
            script: 'update_API',
            function: 'create_invoice',
          },
        },
        {
          type: 'Reschedule RTS FOLLOWUP',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_orders',
          },
        },
        {
          type: 'New permit added to Lowes project of type installation',
          params: {
            args: null,
            script: 'exe_lowes_scraping',
            function: 'add_permit',
          },
        },
        {
          type: 'Confirm Appointment',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'confirm_appointment_scrap',
          },
        },
        {
          type: 'New Retailer note added for  project',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_note_scraping',
          },
        },
        {
          type: 'Refigure request via scraping',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'refigure_request',
          },
        },
        {
          type: 'New detail document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'New need support request created for installation project',
          params: {
            args: null,
            script: 'update_API',
            function: 'need_support',
          },
        },
        {
          type: 'New need support request created for type detail',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'need_support',
          },
        },
        {
          type: 'New Retailer note added for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_note',
          },
        },
        {
          type: 'Order completed for project',
          params: {
            args: null,
            script: 'update_API',
            function: 'complete_order',
          },
        },
        {
          type: 'On Way Clicked SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [0, 56] },
            function: 'sms_on_way',
          },
        },
        {
          type: 'Project RTS Followup API',
          params: {
            args: null,
            script: 'update_API',
            function: 'update_followup',
          },
        },
        {
          type: 'Reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'reschedule_order',
          },
        },
        {
          type: 'Schedule or reschedule SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [4, 5, 14] },
            function: 'schedule_reschedule_sms',
          },
        },
        {
          type: 'Schedule or reschedule order API',
          params: {
            args: null,
            script: 'update_API',
            function: 'schedule_order',
          },
        },
        {
          type: 'Installer reference added',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'add_installer_reference',
          },
        },
        {
          type: 'DD Confirm',
          params: {
            args: null,
            script: 'exe_scraping',
            function: 'dd_confirm',
          },
        },
        {
          type: 'New lead document added to  project',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
        {
          type: 'Project marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Project marked RTS sms',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: [8],
            function: 'project_marked_rts_sms',
          },
        },
        {
          type: 'Schedule Tomorrow Response SMS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [3, 15] },
            function: 'schedule_tomorrow_response_sms',
          },
        },
        {
          type: 'Workorder marked RTS',
          params: {
            args: null,
            script: 'update_lowes_API',
            options: { template_id: [9] },
            function: 'wo_marked_rts_sms',
          },
        },
        {
          type: 'New Document added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'New photo added for project via API',
          params: {
            args: null,
            script: 'update_API',
            function: 'add_doc_project',
          },
        },
        {
          type: 'COC document added',
          params: {
            args: null,
            script: 'update_API',
            function: 'submit_doc_complete',
          },
        },
      ],
      failureResults: [
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-payment-request',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.source_system_id',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.project.total_sale_amount',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'paymentrequest',
                result: false,
                path: '$.project.invoice_number',
              },
              {
                operator: 'greaterThan',
                value: 0,
                fact: 'paymentrequest',
                path: '$.check_amount',
              },
              {
                operator: 'equal',
                value: 'Invoice Paid',
                fact: 'paymentrequest',
                result: false,
                path: '$.payment_status',
              },
            ],
          },
          event: {
            type: 'Project create payment',
            params: {
              script: 'update_API',
              function: 'create_payment',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-payment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 'Millwork',
                fact: 'project_category',
                result: true,
                path: '$.category',
              },
              {
                operator: 'notExists',
                value: null,
                fact: 'project',
                result: true,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_number',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0][1].installer_detail.user.measuringsystemid',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Create Project',
            params: {
              script: 'update_API',
              function: 'create_floorsoft_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-create-project.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_completed',
              },
            ],
          },
          event: {
            type: 'Lowes FloorSoft Detail Fetch Document',
            params: {
              script: 'update_API',
              function: 'get_floorsoft_documents',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-fetch-document.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_floorsoft[0].floorsoft_transaction_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_detail.user.measuringsystemid',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.project_installer[0].installer_id',
              },
            ],
          },
          event: {
            type: 'Lowes Floorsoft Update Installer',
            params: {
              script: 'update_API',
              function: 'update_floorsoft_assign_person',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-floorsoft-update-installer.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanZero',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.total_sale_amount',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
            ],
          },
          event: {
            type: 'Project create invoice',
            params: {
              script: 'update_API',
              function: 'create_invoice',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-quickbooks-create-invoice.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Reschedule RTS FOLLOWUP',
            params: {
              script: 'update_API',
              function: 'reschedule_orders',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-permit',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Pull Permit',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'New permit added to Lowes project of type installation',
            params: {
              script: 'exe_lowes_scraping',
              function: 'add_permit',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-permit.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Confirm Appointment',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Confirm Appointment',
            params: {
              script: 'exe_scraping',
              function: 'confirm_appointment_scrap',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-confirm-appointment.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
            ],
          },
          event: {
            type: 'New Retailer note added for  project',
            params: {
              script: 'exe_scraping',
              function: 'add_note_scraping',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 6,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 'Refigure Request',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Refigure request via scraping',
            params: {
              script: 'exe_scraping',
              function: 'refigure_request',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-refigure-request.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_assessment_required',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 0,
                fact: 'project',
                result: false,
                path: '$.epa_lead_remediation',
              },
            ],
          },
          event: {
            type: 'New detail document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-detail-non-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Additional Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Missing/Damaged Product',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                  {
                    operator: 'equal',
                    value: 'Labor Only',
                    fact: 'support',
                    result: false,
                    path: '$.support_type.type_name',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for installation project',
            params: {
              script: 'update_API',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-need-support-api.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-support',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'notEqual',
                value: 4,
                fact: 'project',
                result: true,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'support',
                    result: false,
                    path: '$.support_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New need support request created for type detail',
            params: {
              script: 'exe_scraping',
              function: 'need_support',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scrapping-need-support.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-note',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'note',
                result: false,
                path: '$.note_category_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Retailer note added for project',
            params: {
              script: 'update_API',
              function: 'add_note',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-note.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.completion_date',
              },
            ],
          },
          event: {
            type: 'Order completed for project',
            params: {
              script: 'update_API',
              function: 'complete_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-complete-order-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.on_way_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'On Way Clicked SMS',
            params: {
              script: 'update_lowes_API',
              function: 'sms_on_way',
              options: { template_id: [0, 56] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-on-way-clicked-sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                operator: 'greaterThanToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.rts_follow_up_date',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Project RTS Followup API',
            params: {
              script: 'update_API',
              function: 'update_followup',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-project-rts-follow-up.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'greaterThanOrEqualToday',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 'Work Complete',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Reschedule order API',
            params: {
              script: 'update_API',
              function: 'reschedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-reschedule-orders.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                  {
                    operator: 'greaterThanOrEqualToday',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.date_scheduled_start',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                  {
                    operator: 'equal',
                    value: 7,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_reschedule_sms',
              options: { template_id: [4, 5, 14] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-SMS.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_start',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.date_scheduled_end',
              },
              {
                operator: 'equal',
                value: 7,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'Ready to Schedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                  {
                    operator: 'equal',
                    value: 'Reschedule',
                    fact: 'project',
                    result: false,
                    path: '$.current_activity',
                  },
                ],
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
            ],
          },
          event: {
            type: 'Schedule or reschedule order API',
            params: {
              script: 'update_API',
              function: 'schedule_order',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-schedule-reschedule-order.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'exists',
                value: null,
                fact: 'project',
                result: false,
                path: '$.installer_reference',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Installer reference added',
            params: {
              script: 'exe_scraping',
              function: 'add_installer_reference',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-add-installer-reference.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                operator: 'equal',
                value: 75,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'DD Confirmed',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm',
                  },
                  {
                    operator: 'exists',
                    value: null,
                    fact: 'project',
                    result: false,
                    path: '$.dd_confirm_follow_up',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'DD Confirm',
            params: {
              script: 'exe_scraping',
              function: 'dd_confirm',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-scraping-dd-confirm.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 10,
                fact: 'project',
                result: false,
                path: '$.status_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_assessment_required',
                  },
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.epa_lead_remediation',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New lead document added to  project',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-and-detail-lead-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_detail_marked_rts_sms .yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: '11MT97PY',
                fact: 'client_id',
                factResult: '22PF89TG',
                result: false,
              },
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 2,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Project marked RTS sms',
            params: {
              script: 'update_lowes_API',
              function: 'project_marked_rts_sms',
              options: [8],
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_project_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-sms',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'received',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'Yes',
                fact: 'repliedToMessage',
                result: false,
                path: '$.sms_responded',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 1,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 2,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                ],
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 'SMS Scheduled Tomorrow Reminder',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                  {
                    operator: 'equal',
                    value: 'SMS Schedule Confirmation',
                    fact: 'repliedToMessage',
                    result: false,
                    path: '$.sms_title',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Schedule Tomorrow Response SMS',
            params: {
              script: 'update_lowes_API',
              function: 'schedule_tomorrow_response_sms',
              options: { template_id: [3, 15] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_schedule_tomorrow_response_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project',
                fact: 'category',
                factResult: 'project',
                result: true,
              },
              {
                operator: 'equal',
                value: 'update',
                fact: 'type',
                factResult: 'update',
                result: true,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'project',
                result: false,
                path: '$.project_type_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 78,
                    fact: 'project',
                    result: false,
                    path: '$.source_status_id',
                  },
                  {
                    operator: 'equal',
                    value: 6,
                    fact: 'project',
                    result: false,
                    path: '$.status_id',
                  },
                ],
              },
            ],
          },
          event: {
            type: 'Workorder marked RTS',
            params: {
              script: 'update_lowes_API',
              function: 'wo_marked_rts_sms',
              options: { template_id: [9] },
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes_wo_marked_rts_sms.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New Document added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-document-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 3,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 'SOURCE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'notIn',
                value: [49, 73, 74, 135],
                fact: 'project',
                result: true,
                path: '$.source_status_id',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'notEqual',
                    value: 'Submit Documents',
                    fact: 'project',
                    result: true,
                    path: '$.current_activity',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 'Submit Documents',
                        fact: 'project',
                        result: false,
                        path: '$.current_activity',
                      },
                      {
                        operator: 'notEqual',
                        value: 'Open',
                        fact: 'project',
                        result: true,
                        path: '$.current_activity_status',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'New photo added for project via API',
            params: {
              script: 'update_API',
              function: 'add_doc_project',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-add-photo-API.yaml',
          result: false,
        },
        {
          conditions: {
            priority: 1,
            all: [
              {
                operator: 'equal',
                value: 'project-document',
                fact: 'category',
                factResult: 'project',
                result: false,
              },
              {
                operator: 'equal',
                value: 'add',
                fact: 'type',
                factResult: 'update',
                result: false,
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'document',
                result: false,
                path: '$.document_type_id',
              },
              {
                operator: 'equal',
                value: 1,
                fact: 'project',
                result: false,
                path: '$.source_system_id',
              },
              {
                operator: 'equal',
                value: 77,
                fact: 'project',
                result: false,
                path: '$.source_status_id',
              },
              {
                operator: 'equal',
                value: 'Submit Documents',
                fact: 'project',
                result: false,
                path: '$.current_activity',
              },
              {
                operator: 'equal',
                value: 'COMPLETE',
                fact: 'document',
                result: false,
                path: '$.document_store_type',
              },
              {
                operator: 'equal',
                value: 'Open',
                fact: 'project',
                result: false,
                path: '$.current_activity_status',
              },
              {
                priority: 1,
                any: [
                  {
                    operator: 'equal',
                    value: 3,
                    fact: 'project',
                    result: false,
                    path: '$.project_type_id',
                  },
                  {
                    priority: 1,
                    all: [
                      {
                        operator: 'equal',
                        value: 2,
                        fact: 'project',
                        result: false,
                        path: '$.project_type_id',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_assessment_required',
                      },
                      {
                        operator: 'equal',
                        value: 0,
                        fact: 'project',
                        result: false,
                        path: '$.epa_lead_remediation',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          event: {
            type: 'COC document added',
            params: {
              script: 'update_API',
              function: 'submit_doc_complete',
              args: null,
            },
          },
          priority: 1,
          name: '/service/rules_config/lowes-submit-document-project-non-lead-and-workorders-API.yaml',
          result: false,
        },
      ],
    },
    created_at: '2023-03-06 12:52:34',
    modified_at: '2023-03-06 12:52:34',
  },
];
