// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { Grid, TextField, Box } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO } from 'date-fns';
import moment from 'moment';

// **** Custom Components ****
import { useAlerts } from '../shared/Alerts/alertsService';

// **** Styles/Images/Icons ****
import { useStyles } from '../ProjectManagement/Scheduling/Reports/Reports.styles';
import { checkPermission } from '../../utils/Helpers';
import permissions from '../../config/permissions';

const ProjectDetailsViewEditForm = ({
  projectDetailsFormik,
  rescheduleReasonOptions,
}) => {
  const { clearAlert } = useAlerts();
  React.useEffect(() => {
    clearAlert();
  }, []);

  const classes = useStyles();
  return (
    <Grid container spacing={2} direction="column">
      {/* <pre>{JSON.stringify(projectDetailsFormik?.values, null, 2)}</pre> */}
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Store#"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'store_number',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.store_number || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Type"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('type', event.target.value);
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.type || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxWideColumn}>
          <TextField
            label="Category"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'category',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.category || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Status"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('status', event.target.value);
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.status || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Project Number"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'project_number',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.project_number || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Date Sold"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'date_sold',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={
              moment(
                projectDetailsFormik?.values?.date_sold?.slice(0, 10)
              ).format('MM-DD-YYYY') || ''
            }
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Client"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('client', event.target.value);
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.client || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Address"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('address', event.target.value);
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.address || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Mobile Number"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'mobile_number',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.mobile_number || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Alternate Number"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'alternate_phone',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.alternate_number || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="Email"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('email', event.target.value);
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.email || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <TextField
            label="IMS Status"
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue(
                'ims_status',
                event.target.value
              );
            }}
            multiline
            maxRows={4}
            disabled={true}
            value={projectDetailsFormik?.values?.ims_status || ''}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <Autocomplete
            id="reschedule_reason"
            name="reschedule_reason"
            options={rescheduleReasonOptions ?? []}
            disableListWrap
            getOptionLabel={option =>
              option?.reason_desc ? `${option.reason_desc} ` : ''
            }
            renderInput={params => (
              <TextField {...params} label="Reschedule Reason" />
            )}
            value={projectDetailsFormik?.values?.reschedule_reason || ''}
            onChange={(event, value) => {
              projectDetailsFormik.setFieldValue('reschedule_reason', value);
            }}
            onBlur={projectDetailsFormik.handleBlur}
            disabled={
              !checkPermission(
                permissions?.viewEditProject?.actionEditScheduler
              )
            }
          />
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              id="rts_follow_up_date"
              name="rts_follow_up_date"
              label={
                <>
                  <span>RTS Follow Up Date</span>
                </>
              }
              format="MM-dd-yyyy"
              value={
                (projectDetailsFormik?.values?.rts_follow_up_date &&
                  moment(
                    projectDetailsFormik?.values?.rts_follow_up_date?.slice(
                      0,
                      10
                    )
                  ).format('MM-DD-YYYY')) ||
                null
              }
              onChange={(event, value) =>
                projectDetailsFormik.setFieldValue('rts_follow_up_date', value)
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"
              fullWidth
              onBlur={projectDetailsFormik.handleBlur}
              disabled={
                !checkPermission(
                  permissions?.viewEditProject?.actionEditScheduler
                )
              }
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box m={1} className={classes.boxColumn}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              id="date_scheduled_start"
              name="date_scheduled_start"
              label="Scheduled Date and Time"
              format="MM-dd-yyyy hh:mm a"
              value={projectDetailsFormik?.values?.date_scheduled_start || null}
              onChange={(date, value) => {
                projectDetailsFormik.setFieldValue(
                  'date_scheduled_start',
                  date
                );
                projectDetailsFormik.setFieldTouched(
                  'date_scheduled_start',
                  true
                );
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onBlur={projectDetailsFormik.handleBlur}
              disabled
            />
          </MuiPickersUtilsProvider>
        </Box>

        <Box m={1} className={classes.boxColumn}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              id="date_scheduled_end"
              label="Project End Date and Time"
              format="MM-dd-yyyy hh:mm a"
              onBlur={projectDetailsFormik?.handleBlur}
              value={
                (projectDetailsFormik?.touched?.date_scheduled_start &&
                  !projectDetailsFormik?.touched?.date_scheduled_end &&
                  projectDetailsFormik?.values?.date_scheduled_start) ||
                projectDetailsFormik?.values?.date_scheduled_end
              }
              onChange={(date, value) => {
                projectDetailsFormik.setFieldValue('date_scheduled_end', date);
                projectDetailsFormik.setFieldTouched(
                  'date_scheduled_end',
                  true
                );
              }}
              InputLabelProps={{ shrink: true }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
              disabled
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
    </Grid>
  );
};

export default ProjectDetailsViewEditForm;
