import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breadCrumbType: 'Client',
};

const quoteHeaderSlice = createSlice({
  name: 'quoteHeader',
  initialState: initialState,
  reducers: {
    setQuoteHeader(state, { payload }) {
      state.breadCrumbType = payload.hasOwnProperty('breadCrumbType')
        ? payload?.breadCrumbType
        : state?.breadCrumbType;
    },
  },
});
export const { setQuoteHeader } = quoteHeaderSlice.actions;
export const quoteHeaderReducer = quoteHeaderSlice.reducer;
