import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Box,
  InputAdornment,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Button,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { FieldArray } from 'formik';
import InputMask from 'react-input-mask';

//**** Custom components */
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Close as CloseIcon,
  LocationOn as LocationOnIcon,
  Search as SearchIcon,
} from '@material-ui/icons/';

import MapContainer from '../../shared/MapContainer';
import { libraries } from '../../../constants.js';
import { REACT_APP_GOOGLE_MAPS_KEY } from '../../../constants/envConstants';

import { getStates, getPhoneTypes } from './Clients.service';
import { useStyles } from './ClientInfo.styles.js';

// **** Services *****

const ClientInfo = ({ setLoading, formik, action }) => {
  const classes = useStyles();
  const [states, setStates] = React.useState([]);
  const [phoneTypes, setPhoneTypes] = React.useState();

  const [address, setAddress] = React.useState('');
  const primaryModeOfContactOptions = ['Email', 'Phone'];
  const emailCategoryOptions = ['Primary', 'Alternative'];
  const [getCustomerPhonesDelete, setCustomersPhonesDelete] = useState([]);
  const [getCustomerEmailsDelete, setCustomersEmailsDelete] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  React.useEffect(() => {
    formik.setFieldValue(`customer_phones_deleted`, getCustomerPhonesDelete);
  }, [getCustomerPhonesDelete]);

  React.useEffect(() => {
    formik.setFieldValue(`customer_emails_deleted`, getCustomerEmailsDelete);
  }, [getCustomerEmailsDelete]);

  React.useEffect(() => {
    getStates(setStates, setLoading);
    getPhoneTypes(setPhoneTypes, setLoading);
  }, []);

  const handleChange = address => {
    setAddress(address);
  };

  const getDetailsFromAddress = addressComponents => {
    let address = {};
    formik.setFieldValue('latitude', '');
    formik.setFieldValue('longitude', '');
    formik.setFieldValue('street', '');
    addressComponents.forEach(function (c) {
      switch (c.types[0]) {
        case 'street_number':
          address.StreetNumber = c.long_name;
          break;
        case 'route':
          address.StreetName = c.long_name;
          break;
        case 'neighborhood':
        case 'locality':
          address.City = c.long_name;
          break;
        case 'administrative_area_level_1':
          address.State = c.short_name;
          break;
        case 'postal_code':
          address.Zip = c.long_name;
          break;
        case 'country':
          address.Country = c.long_name;
          break;
      }
    });
    formik.setFieldValue(
      'street',
      address.StreetName && address.StreetName
        ? address.StreetNumber + ' ' + address.StreetName
        : ''
    );
    formik.setFieldValue('city', address.City);
    formik.setFieldValue('state', address.State);
    formik.setFieldValue('zip', address.Zip);
  };
  const handleSelect = address => {
    setAddress(address);
    geocodeByAddress(address)
      .then(results => {
        getDetailsFromAddress(results[0].address_components);
        return getLatLng(results[0]);
      })
      .then(latLng => {
        if (formik.values.street !== '') {
          formik.setFieldValue('latitude', latLng.lat);
          formik.setFieldValue('longitude', latLng.lng);
        }
      })
      .catch(error => console.error('Error', error));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box m={1} className={classes.smallbox}>
              <TextField
                name="first_name"
                label="First Name"
                value={formik?.values?.first_name || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                required={action === 'edit' || action === 'add'}
                error={formik.touched.first_name && formik.errors.first_name}
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Box>
            <Box m={1} className={classes.smallbox}>
              <TextField
                name="last_name"
                label="Last Name"
                value={formik?.values?.last_name || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                required={action === 'edit' || action === 'add'}
                error={formik.touched.last_name && formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Box>
            <Box className={classes.smallbox} m={1}>
              <InputLabel
                required={action === 'edit' || action === 'add'}
                shrink
                id="primary_mode_of_contact-label"
                className={classes.label}
              >
                Primary Mode of Contact
              </InputLabel>
              <Select
                labelId="primary_mode_of_contact-label"
                name="primary_mode_of_contact"
                value={formik?.values?.primary_mode_of_contact || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                displayEmpty={true}
                variant="standard"
                classes={{ select: classes.sourceSelect }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                error={
                  formik.touched.primary_mode_of_contact &&
                  formik.errors.primary_mode_of_contact
                }
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {primaryModeOfContactOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.primary_mode_of_contact &&
                formik.errors.primary_mode_of_contact && (
                  <FormHelperText error>
                    {formik.errors.primary_mode_of_contact}
                  </FormHelperText>
                )}
            </Box>
            <Box className={classes.smallbox} m={1}>
              <FormControlLabel
                value="Receive Text Messages"
                control={
                  <Switch
                    color="primary"
                    name="receive_text_message"
                    required={action === 'edit' || action === 'add'}
                    onChange={(event, value) =>
                      formik.setFieldValue('receive_text_message', value)
                    }
                    onBlur={formik.handleBlur}
                    checked={formik?.values?.receive_text_message || false}
                    InputProps={{
                      readOnly: action === 'view' ? true : false,
                    }}
                    className={classes.switch}
                    disabled={action === 'view' ? true : false}
                  />
                }
                classes={{
                  label:
                    'MuiFormLabel-root MuiInputLabel-animated MuiInputLabel-shrink',
                  labelPlacementTop: classes.receiveLabel,
                }}
                color="primary"
                label="Receive Text Messages"
                labelPlacement="top"
              />
            </Box>
            <Grid item xs={12}>
              <Box className={classes.smallbox} m={1}>
                <FormControlLabel
                  value="Client Active"
                  control={
                    <Switch
                      color="primary"
                      name="is_deleted"
                      required={action === 'edit' || action === 'add'}
                      onChange={(event, value) =>
                        formik.setFieldValue('is_deleted', !value)
                      }
                      onBlur={formik.handleBlur}
                      checked={!formik?.values?.is_deleted}
                      InputProps={{
                        readOnly: action === 'view' ? true : false,
                      }}
                      className={classes.switch}
                      disabled={action === 'view' ? true : false}
                    />
                  }
                  classes={{
                    label:
                      'MuiFormLabel-root MuiInputLabel-animated MuiInputLabel-shrink',
                    labelPlacementTop: classes.receiveLabel,
                  }}
                  color="primary"
                  label="Client Active"
                  labelPlacement="top"
                />
              </Box>
            </Grid>
            {formik?.values?.customer_phones && (
              <FieldArray
                name="customer_phones"
                render={arrayHelpers => (
                  <>
                    {formik.values.customer_phones.map((phone, i) => (
                      <>
                        {phoneTypes && (
                          <Box
                            className={classes.smallbox}
                            m={1}
                            key={phone.phone_type}
                          >
                            <Autocomplete
                              name={`customer_phones.${i}.phone_type`}
                              options={phoneTypes || []}
                              getOptionLabel={option => {
                                return option && option.name;
                              }}
                              value={
                                formik?.values?.customer_phones &&
                                formik?.values?.customer_phones[i].phone_type
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  `customer_phones.${i}.phone_type`,
                                  value
                                    ? value
                                    : { phone_type_id: '', name: '' }
                                );
                              }}
                              onBlur={event => {
                                formik.setFieldTouched(
                                  `customer_phones[${i}].phone_type.name`,
                                  true
                                );
                              }}
                              InputProps={{
                                readOnly: action === 'view' ? true : false,
                              }}
                              required={action === 'edit' || action === 'add'}
                              openOnFocus={true}
                              getOptionDisabled={option =>
                                (option?.name == 'Mobile' &&
                                  formik.values.customer_phones?.filter(
                                    phone => phone.phone_type?.name == 'Mobile'
                                  ).length > 0) ||
                                (option?.name == 'Home' &&
                                  formik.values.customer_phones?.filter(
                                    phone => phone.phone_type?.name == 'Home'
                                  ).length > 0) ||
                                (option?.name == 'Alternate' &&
                                  formik.values.customer_phones?.filter(
                                    phone =>
                                      phone.phone_type?.name == 'Alternate'
                                  ).length > 0)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Contact Category"
                                  required={
                                    action === 'edit' || action === 'add'
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  error={
                                    action === 'add'
                                      ? formik.touched.customer_phones &&
                                        formik.touched.customer_phones[i] &&
                                        formik.touched.customer_phones[i]
                                          .phone_type &&
                                        formik.touched.customer_phones[i]
                                          .phone_type.name &&
                                        formik.errors.customer_phones &&
                                        formik.errors.customer_phones[i] &&
                                        formik.errors.customer_phones[i]
                                          .phone_type &&
                                        formik?.errors?.customer_phones[i]
                                          ?.phone_type?.name
                                      : formik.errors.customer_phones &&
                                        formik.errors.customer_phones[i] &&
                                        formik.errors.customer_phones[i]
                                          .phone_type &&
                                        formik?.errors?.customer_phones[i]
                                          ?.phone_type?.name
                                  }
                                  helperText={
                                    action === 'add'
                                      ? formik.touched.customer_phones &&
                                        formik.touched.customer_phones[i] &&
                                        formik.touched.customer_phones[i]
                                          .phone_type &&
                                        formik.touched.customer_phones[i]
                                          .phone_type.name &&
                                        formik.errors.customer_phones &&
                                        formik.errors.customer_phones[i] &&
                                        formik.errors.customer_phones[i]
                                          .phone_type &&
                                        formik?.errors?.customer_phones[i]
                                          ?.phone_type?.name
                                      : formik.errors.customer_phones &&
                                        formik.errors.customer_phones[i] &&
                                        formik.errors.customer_phones[i]
                                          .phone_type &&
                                        formik?.errors?.customer_phones[i]
                                          ?.phone_type?.name
                                  }
                                />
                              )}
                              classes={{ input: classes.statusInput }}
                              disabled={action === 'view' ? true : false}
                            />
                          </Box>
                        )}
                        <Box className={classes.smallbox} m={1}>
                          <InputMask
                            mask="(999) 999-9999"
                            value={
                              formik?.values?.customer_phones?.[i]
                                ?.phone_number ?? ''
                            }
                            onChange={
                              (action === 'edit' || action === 'add') &&
                              formik.handleChange
                            }
                            onBlur={
                              (action === 'edit' || action === 'add') &&
                              formik.handleBlur
                            }
                            maskChar=" "
                            placeholder=""
                            disabled={action === 'view' ? true : false}
                            InputProps={{
                              readOnly: action === 'view' ? true : false,
                            }}
                          >
                            {() => (
                              <TextField
                                name={`customer_phones.${i}.phone_number`}
                                label="Contact Number"
                                required={action === 'edit' || action === 'add'}
                                InputLabelProps={{ shrink: true }}
                                error={
                                  action === 'add'
                                    ? formik?.touched?.customer_phones?.[i]
                                        ?.phone_number &&
                                      formik?.errors?.customer_phones?.[i]
                                        ?.phone_number
                                    : formik?.errors?.customer_phones?.[i]
                                        ?.phone_number
                                }
                                helperText={
                                  action === 'add'
                                    ? formik?.touched?.customer_phones?.[i]
                                        ?.phone_number &&
                                      formik?.errors?.customer_phones?.[i]
                                        ?.phone_number
                                    : formik?.errors?.customer_phones?.[i]
                                        ?.phone_number
                                }
                              />
                            )}
                          </InputMask>
                        </Box>

                        {i > 0 && action !== 'view' && (
                          <IconButton
                            key={i}
                            className={classes.closeButton}
                            onClick={() => {
                              const customer_phones_deleted = [
                                ...(getCustomerPhonesDelete
                                  ? getCustomerPhonesDelete
                                  : []),
                                {
                                  customer_phone_id:
                                    formik?.values?.customer_phones?.[i]
                                      ?.customer_phone_id || '',
                                  phone_number:
                                    formik?.values?.customer_phones?.[i]
                                      ?.phone_number || '',
                                  phone_type_id:
                                    formik?.values?.customer_phones?.[i]
                                      ?.phone_type_id || '',
                                  is_deleted: true,
                                },
                              ];
                              setCustomersPhonesDelete(customer_phones_deleted);
                              arrayHelpers.remove(i);
                            }}
                          >
                            <CloseIcon className={classes.closeIcon} />
                          </IconButton>
                        )}
                      </>
                    ))}

                    {formik?.values?.customer_phones?.length == 0 &&
                      formik?.touched?.customer_phones &&
                      formik?.errors?.customer_phones && (
                        <FormHelperText error>
                          {formik?.errors?.customer_phones}
                        </FormHelperText>
                      )}
                    {formik?.values?.customer_phones?.length > 0 &&
                      formik?.values?.customer_phones?.filter(
                        phone => phone.phone_type?.phone_type_id === 2
                      )?.length < 1 && (
                        <FormHelperText error>
                          Choose atleast one Mobile Number
                        </FormHelperText>
                      )}
                    {(action === 'add' || action === 'edit') && (
                      <>
                        <Box>
                          <Button
                            variant="text"
                            color="primary"
                            disabled={
                              formik?.values?.customer_phones?.length >= 3
                            }
                            onClick={() => {
                              if (formik?.values?.customer_phones?.length) {
                                arrayHelpers.push({
                                  phone_number: '',
                                  phone_type: { phone_type_id: '', name: '' },
                                });
                              } else {
                                arrayHelpers.push({
                                  phone_type_id: '',
                                  name: 'Mobile',
                                });
                              }
                            }}
                          >
                            Add Phone Number
                          </Button>
                          {formik?.values?.customer_phones?.length == 0 && (
                            <FormHelperText error>
                              Please add a contact
                            </FormHelperText>
                          )}
                        </Box>
                      </>
                    )}
                  </>
                )}
              />
            )}
            <Box m={1} className={classes.largebox}>
              <TextField
                name="customer_related_notes"
                label="Customer Related Notes"
                multiline
                value={formik?.values?.customer_related_notes || ''}
                onChange={
                  (action === 'edit' || action === 'add') && formik.handleChange
                }
                onBlur={
                  (action === 'edit' || action === 'add') && formik.handleBlur
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                error={
                  formik.touched.customer_related_notes &&
                  formik.errors.customer_related_notes
                }
                helperText={
                  formik.touched.customer_related_notes &&
                  formik.errors.customer_related_notes
                }
              />
            </Box>

            {formik?.values?.customer_emails && (
              <FieldArray
                name="customer_emails"
                render={arrayHelpers => (
                  <>
                    {formik.values.customer_emails.map((phone, i) => (
                      <>
                        <Box className={classes.smallbox} m={1}>
                          <TextField
                            name={`customer_emails.${i}.email`}
                            label="Email"
                            value={
                              (formik?.values?.customer_emails &&
                                formik?.values?.customer_emails[i] &&
                                formik?.values?.customer_emails[i]?.email) ||
                              ''
                            }
                            onChange={
                              (action === 'edit' || action === 'add') &&
                              formik.handleChange
                            }
                            onBlur={
                              (action === 'edit' || action === 'add') &&
                              formik.handleBlur
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              readOnly: action === 'view' ? true : false,
                            }}
                            error={
                              formik.touched.customer_emails &&
                              formik.touched.customer_emails[i] &&
                              formik.touched.customer_emails[i].email &&
                              formik.errors.customer_emails &&
                              formik.errors.customer_emails[i] &&
                              formik.errors.customer_emails[i].email
                            }
                            helperText={
                              formik.touched.customer_emails &&
                              formik.touched.customer_emails[i] &&
                              formik.touched.customer_emails[i].email &&
                              formik.errors.customer_emails &&
                              formik.errors.customer_emails[i] &&
                              formik.errors.customer_emails[i].email
                            }
                          />
                        </Box>
                        {emailCategoryOptions && (
                          <Box
                            className={classes.smallbox}
                            m={1}
                            key={phone.email_type}
                          >
                            <Autocomplete
                              name={`customer_emails.${i}.email_type`}
                              options={emailCategoryOptions || []}
                              getOptionLabel={option => {
                                return option && option;
                              }}
                              defaultValue={
                                formik?.values?.customer_emails
                                  ? formik?.values?.customer_emails[i]
                                      .email_type
                                  : 'Primary'
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  `customer_emails.${i}.email_type`,
                                  value ? value : ''
                                );
                              }}
                              onBlur={event => {
                                formik.setFieldTouched(
                                  `customer_emails[${i}].email_type`,
                                  true
                                );
                              }}
                              InputProps={{
                                readOnly: action === 'view' ? true : false,
                              }}
                              openOnFocus={true}
                              getOptionDisabled={option =>
                                option == 'Primary' &&
                                formik.values.customer_emails?.filter(
                                  email => email.email_type == 'Primary'
                                ).length > 0
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label="Email Category"
                                  InputLabelProps={{ shrink: true }}
                                  error={
                                    formik.errors.customer_emails &&
                                    formik.errors.customer_emails[i] &&
                                    formik.errors.customer_emails[i].email_type
                                  }
                                  helperText={
                                    formik.errors.customer_emails &&
                                    formik.errors.customer_emails[i] &&
                                    formik.errors.customer_emails[i].email_type
                                  }
                                />
                              )}
                              classes={{ input: classes.statusInput }}
                              disabled={
                                action === 'view' || i === 0 ? true : false
                              }
                            />
                          </Box>
                        )}
                        {i > 0 &&
                          action !== 'view' &&
                          formik?.values?.customer_emails?.length == 2 && (
                            <IconButton
                              key={i}
                              className={classes.closeButton}
                              onClick={() => {
                                const customer_emails_deleted = [
                                  ...(getCustomerEmailsDelete
                                    ? getCustomerEmailsDelete
                                    : []),
                                  {
                                    customer_email_id:
                                      formik?.values?.customer_emails?.[i]
                                        ?.customer_email_id || '',
                                    email_type:
                                      formik?.values?.customer_emails?.[i]
                                        ?.email_type || '',
                                    email:
                                      formik?.values?.customer_emails?.[i]
                                        ?.email || '',
                                    is_deleted: true,
                                  },
                                ];
                                setCustomersEmailsDelete(
                                  customer_emails_deleted
                                );
                                arrayHelpers.remove(i);
                              }}
                            >
                              <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                          )}
                      </>
                    ))}
                    {(action === 'add' || action === 'edit') && (
                      <Box>
                        <Button
                          variant="text"
                          color="primary"
                          disabled={
                            formik?.values?.customer_emails?.length >= 2
                          }
                          onClick={() => {
                            if (formik?.values?.customer_emails?.length) {
                              arrayHelpers.push({ email: '', email_type: '' });
                            } else {
                              arrayHelpers.push({
                                email: '',
                                email_type: 'Primary',
                              });
                            }
                          }}
                        >
                          Add Email
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box className={classes.largebox} m={1}>
              {(action === 'edit' || action === 'add') && isLoaded && (
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  //Restricted search to USA
                  searchOptions={{ componentRestrictions: { country: 'us' } }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <TextField
                        {...getInputProps({
                          placeholder: 'Search Places ...',
                          className: 'location-search-input',
                        })}
                        label="Billing Address"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Search"
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <Grid
                              container
                              alignItems="center"
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <Grid item>
                                <LocationOnIcon className={classes.icon} />
                              </Grid>
                              <Grid item xs>
                                <span
                                  key={i}
                                  style={{
                                    fontWeight: suggestion.highlight
                                      ? 700
                                      : 400,
                                  }}
                                >
                                  {suggestion.description}
                                </span>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              )}
            </Box>
            <Box className={classes.largebox} m={1}>
              <TextField
                name="street"
                label="Street"
                value={formik?.values?.street || ''}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                required={action === 'edit' || action === 'add'}
                error={formik.touched.street && formik.errors.street}
                helperText={formik.touched.street && formik.errors.street}
              />
            </Box>
            <Box className={classes.largebox} m={1}>
              <TextField
                name="city"
                label="City"
                value={formik?.values?.city || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                required={action === 'edit' || action === 'add'}
                error={formik.touched.city && formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Box>
            <Box className={classes.smallbox} m={1}>
              <InputLabel
                required={action === 'edit' || action === 'add'}
                shrink
                id="state-label"
                className={classes.label}
              >
                State or Region
              </InputLabel>
              <Select
                labelId="state-label"
                name="state"
                value={formik?.values?.state || ''}
                onChange={event => {
                  formik.setFieldValue('state', event.target.value);
                }}
                onBlur={formik.handleBlur}
                displayEmpty={true}
                variant="standard"
                classes={{ select: classes.sourceSelect }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                disabled={action === 'view' ? true : false}
                error={formik.touched.state && formik.errors.state}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {states.map((state, index) => (
                  <MenuItem key={index} value={state.state_code}>
                    {state.state_name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.state && formik.errors.state && (
                <FormHelperText error>{formik.errors.state}</FormHelperText>
              )}
            </Box>
            <Box className={classes.smallbox} m={1}>
              <TextField
                name="zip"
                label="Postal Code"
                value={formik?.values?.zip || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                inputProps={{
                  maxLength: 10,
                }}
                required={action === 'edit' || action === 'add'}
                disabled={action === 'view' ? true : false}
                error={formik.touched.zip && formik.errors.zip}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </Box>
            <Box m={1} className={classes.largebox}>
              <MapContainer
                lat={Number(formik?.values?.latitude)}
                long={Number(formik?.values?.longitude)}
                isLoaded={isLoaded}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientInfo;
