//Imports
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useEffect, useState, useContext, useRef, useMemo } from 'react';

//Service
import {
  formatCurrency,
  formatNumber,
  isCrmEnabled,
} from '../../../utils/Helpers';

import { calculateProjectLaborCost } from './ProjectItemsService';

//Helpers
import PdfViewer from './Pdfviewer';

//Component
export default function ProjectItemsCostSummary(props) {
  const viewAllMerchandiseCost = props.viewAllMerchandiseCost;
  const allLaborItemsCostField = props.allLaborItemsCostField;
  const projectInstallers = props?.projectInstallers;
  const projectDetails = props?.projectDetails;
  const systemStatuses = props?.systemStatuses;
  const storeDetails = props?.storeDetails;
  const setIsTechnicianDailogOpen = props?.setIsTechnicianDailogOpen;
  const isTechnicianDailogOpen = props?.isTechnicianDailogOpen;
  const totalSaleAmount = props?.totalSaleAmount && +props.totalSaleAmount;
  const isSalesCommissionOpted = !!props?.isSalesCommissionOpted;

  const [isLoading, setIsLoading] = useState(false);

  const { calculationData = {} } = props;

  let totalLaborRevenue =
    calculationData?.source_cost && +calculationData.source_cost;
  let totalLaborCost =
    calculationData?.labor_cost && +calculationData.labor_cost;
  let totalLaborRetail =
    calculationData?.labor_sell_price && +calculationData.labor_sell_price;
  let totalLaborProfit =
    calculationData?.labor_profit && +calculationData.labor_profit;
  let totalLaborMargin =
    calculationData?.labor_margin && +calculationData.labor_margin;
  let totalMerchandiseRevenue =
    calculationData?.merchandise_source_cost &&
    +calculationData.merchandise_source_cost;
  let totalMerchandiseCost =
    calculationData?.merchandise_cost && +calculationData.merchandise_cost;
  let totalMerchandiseRetail =
    calculationData?.merchandise_sell_price &&
    +calculationData.merchandise_sell_price;
  let totalMerchandiseProfit =
    calculationData?.merchandise_profit && +calculationData.merchandise_profit;
  let totalMerchandiseMargin =
    calculationData?.merchandise_margin && +calculationData.merchandise_margin;
  let totalRevenue =
    calculationData?.total_revenue && +calculationData.total_revenue;
  let totalRetail =
    calculationData?.total_retail && +calculationData.total_retail;
  let totalCost = calculationData?.total_cost && +calculationData.total_cost;
  let totalCommission =
    calculationData?.total_commission && +calculationData.total_commission;
  let installerSalesCommission =
    calculationData?.installer_sales_commission &&
    +calculationData.installer_sales_commission;
  let totalExcludeFromRevenue = calculationData?.total_exclude_from_revenue;
  const totalExcludeFromCommission =
    calculationData?.total_exclude_from_commission;

  useEffect(() => {
    props.setTotalRevenue(calculationData?.total_revenue);
  }, [totalRevenue]);
  const HiphenTemplate = () => <div className="ml-5">-</div>;
  return (
    <>
      {(allLaborItemsCostField || viewAllMerchandiseCost) && (
        <div>
          {allLaborItemsCostField && (
            <>
              <div className="grid">
                <div className="col-6 xl:col-2">
                  <small>Total Sale $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalSaleAmount ? (
                      formatCurrency(totalSaleAmount)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Retailer Commission $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalCommission ? (
                      formatCurrency(totalCommission)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Total Retail $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalRetail ? (
                      formatCurrency(totalRetail)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Total Revenue $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalRevenue ? (
                      formatCurrency(totalRevenue)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Total Cost $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalCost ? formatCurrency(totalCost) : <HiphenTemplate />}
                  </p>
                </div>

                <div className="col-6 xl:col-2">
                  {isSalesCommissionOpted && (
                    <>
                      <small>Sales Commission $</small>
                      <p className="text-primary my-0 xl:text-sm text-align-center">
                        {installerSalesCommission ? (
                          formatCurrency(installerSalesCommission)
                        ) : (
                          <HiphenTemplate />
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="border-bottom-1 border-200 my-2" />
              <div className="grid">
                <div className="col-6 xl:col-2">
                  <small>Labor Retail $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalLaborRetail ? (
                      formatCurrency(totalLaborRetail)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Labor Revenue $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalLaborRevenue ? (
                      formatCurrency(totalLaborRevenue)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Labor Cost $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalLaborCost ? (
                      formatCurrency(totalLaborCost)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Labor Profit</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalLaborProfit ? (
                      formatCurrency(totalLaborProfit)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Labor Margin</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalLaborMargin ? (
                      `${formatNumber(totalLaborMargin, 2, true)}%`
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Excluded Labor $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalExcludeFromRevenue && totalExcludeFromRevenue > 0 ? (
                      `${formatCurrency(totalExcludeFromRevenue, 2, true)}`
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
              </div>
              <div className="border-bottom-1 border-200 my-2" />
            </>
          )}
          {viewAllMerchandiseCost && (
            <>
              <div className="grid">
                <div className="col-6 xl:col-2">
                  <small>Product Retail $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalMerchandiseRetail ? (
                      formatCurrency(totalMerchandiseRetail)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Product Revenue $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalMerchandiseRevenue ? (
                      formatCurrency(totalMerchandiseRevenue)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Product Cost $</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalMerchandiseCost ? (
                      formatCurrency(totalMerchandiseCost)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Product Profit</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalMerchandiseProfit ? (
                      formatCurrency(totalMerchandiseProfit)
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Product Margin</small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalMerchandiseMargin ? (
                      `${formatNumber(totalMerchandiseMargin, 2, true)}%`
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>
                <div className="col-6 xl:col-2">
                  <small>Excluded Commission $ </small>
                  <p className="text-primary my-0 xl:text-sm text-align-center">
                    {totalExcludeFromCommission &&
                    totalExcludeFromCommission > 0 ? (
                      `${formatCurrency(totalExcludeFromCommission, 2, true)}`
                    ) : (
                      <HiphenTemplate />
                    )}
                  </p>
                </div>

                <div className="col-6 xl:col-2">
                  {isSalesCommissionOpted && (
                    <>
                      <small></small>
                      <p className="text-primary my-0 xl:text-sm text-align-center"></p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="mt-4 mb-0" />
          <PdfViewer
            projectId={props?.projectId}
            projectInstallers={projectInstallers}
            projectDetails={projectDetails}
            systemStatuses={systemStatuses}
            storeDetails={storeDetails}
            setIsTechnicianDailogOpen={setIsTechnicianDailogOpen}
            isTechnicianDailogOpen={isTechnicianDailogOpen}
          />
        </div>
      )}
    </>
  );
}
