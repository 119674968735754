// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Notes Data
export const getActivitiesData = async (
  projectId,
  setLoading,
  setNotesResponse
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/activities`
    );
    setNotesResponse(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
