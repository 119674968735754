import axios from 'axios';

import { TestData } from './TESTDATA.js';

//to get the Logs list
export const getEngineResultList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setEngineResultData,
  setLogListCount
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters).length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        .filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val.toString())}`
        )
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }
    const response = TestData;

    setEngineResultData(TestData);
    //setLogListCount(response?.data?.total);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
