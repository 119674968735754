// **** React Imports ****
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';

/*Custom Components*/
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import {
  formatCurrency,
  checkFutureDate,
  dateFormatter,
} from '../../../utils/Helpers';
import SkeletonLoader from '../../shared/Loader/skeleton';
import {
  RETAINAGE_PAYOUT_TYPE_OPTION,
  RetainageFrequencyTypeOptions,
} from '../../../constants';

import UserFormRetainagePayout from './user-form-retainage-payout';
import {
  searchCategory,
  createCategory,
  createType,
  searchType,
  createRetainage,
  getRetainage,
  editRetainage,
  getRetainagePayout,
} from './services/user-form-retainage.service';
import UserChargeBackView from './components/user-chargeback-view';

const cols = [
  { field: 'release_date', header: 'Release Date' },
  { field: 'payment_date', header: 'Payment Date' },
  { field: 'payment_category', header: 'Payment Category' },
  { field: 'payment_type', header: 'Payment Type' },
  { field: 'payment_status', header: 'Payment Status' },
  { field: 'payment_amount', header: 'Payment Amount' },
  { field: 'payment_txn_id', header: 'Payment Date' },
  { field: 'description', header: 'Description' },
];

const UserFormRetainage = () => {
  const [
    retainageCategoriesSearchResults,
    setRetainageCategoriesSearchResults,
  ] = useState([]);
  const [retainageTypesSearchResults, setRetainageTypesSearchResults] =
    useState([]);
  const [retainageDetails, setRetainageDetails] = useState();
  const [selectedRetainagePayout, setSelectedRetainagePayout] = useState();
  const [retainagePayoutDetails, setRetainagePayoutDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(false);
  const [payoutType, setPayoutType] = useState();

  const [showRetainagePayoutDialog, setShowRetainagePayoutDialog] =
    useState(false);
  const [tableDataState, setTableDataState] = useState({
    limit: 5,
    page: 1,
    sortField: 'retainage_id',
    sortOrder: 'asc',
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { userId } = useSelector(state => state.userForm);
  const toast = useRef(null);
  const exportOverlayRef = useRef(null);
  const dt = useRef(null);
  const [refreshList, setRefreshList] = useState(false);
  const [tablePaginationState, setTablePaginationState] = useState({
    first: 0,
    rows: 5,
  });

  const formik = useFormik({
    initialValues: {
      categoryId: retainageDetails?.category,
      initiateDate: retainageDetails?.initiate_date,
      totalAmount: retainageDetails?.total_amount,
      typeId: retainageDetails?.type,
      payPercent: retainageDetails?.pay_percent,
      fixedAmount: retainageDetails?.fixed_amount,
      description: retainageDetails?.description,
      frequency: retainageDetails?.frequency,
      percentage_of_payroll: retainageDetails?.percentage_of_payroll,
    },
    validationSchema: Yup.object().shape({
      totalAmount: Yup.number().min(0).required('Required'),
    }),
    enableReinitialize: true,
  });
  //Helper to clear filter
  const clearFilters = e => {
    setGlobalFilterValue('');
    setTableDataState({
      limit: 10,
      page: 1,
      sortField: 'retainage_id',
      sortOrder: 'asc',
    });
  };

  // Helper to export CSV file
  const exportCSV = () => {
    exportOverlayRef?.current?.hide();
  };
  const exportColumns = useMemo(
    () => cols.map(col => ({ title: col.header, dataKey: col.field })),
    []
  );
  const exportArr = useMemo(
    () =>
      retainagePayoutDetails?.result.map(e => {
        return {
          release_date: e?.release_date,
          payment_date: e?.payment_date,
          payment_category: e?.payment_category?.name,
          payment_type: e?.payment_type?.payment_type,
          payment_status: e?.payment_status?.status,
          payment_amount: e?.payment_amount,
          payment_txn_id: e?.payment_txn_id,
          description: e?.description,
        };
      }),
    [retainagePayoutDetails]
  );

  // Helper to export pdf file
  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, exportArr);
        doc.save('retainage.pdf');
        exportOverlayRef?.current?.hide();
      });
    });
  };

  const actionTemplate = rowData => {
    return (
      <div className="flex gap-3">
        <i
          className={
            checkFutureDate(rowData?.release_date) &&
            rowData?.payment_status?.status !== 'Paid'
              ? 'pi pi-pencil cursor-pointer'
              : 'pi pi-pencil text-300'
          }
          onClick={() => {
            if (
              checkFutureDate(rowData?.release_date) &&
              rowData?.payment_status?.status !== 'Paid'
            ) {
              setSelectedRetainagePayout(rowData);
              setShowRetainagePayoutDialog(true);
            }
          }}
        ></i>
      </div>
    );
  };

  const retainageCategoriesRef = useRef(null);
  const retainageTypeRef = useRef(null);

  const suggestRetainageCategories = async (event = null) => {
    const searchRetainageCategoriesResponse = await searchCategory(
      event?.query || ''
    );
    if (searchRetainageCategoriesResponse?.data?.count)
      setRetainageCategoriesSearchResults(
        searchRetainageCategoriesResponse?.data?.result
      );
    else setRetainageCategoriesSearchResults([]);
  };
  const handleRetainageCategoriesFocus = () => {
    suggestRetainageCategories();
    if (retainageCategoriesRef.current) {
      retainageCategoriesRef.current.show();
    }
  };
  const addCategory = async name => {
    if (name) {
      const newCategory = await createCategory(userId, { name });

      if (newCategory?.data) {
        const newCategoryObject = {
          category_id: newCategory.data?.category_id,
          name: newCategory.data?.name,
        };
        formik.setFieldValue('categoryId', newCategoryObject);
        suggestRetainageCategories();
        toast?.current?.show({
          severity: 'success',
          summary: newCategory?.message,
          life: 2000,
        });
      }
    }
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  const suggestRetainageTypes = async (event = null) => {
    const searchRetainageTypesResponse = await searchType(event?.query || '');
    if (searchRetainageTypesResponse?.data?.count)
      setRetainageTypesSearchResults(
        searchRetainageTypesResponse?.data?.result
      );
    else setRetainageTypesSearchResults([]);
  };
  const handleRetainageTypesFocus = () => {
    suggestRetainageTypes();
    if (retainageTypeRef.current) {
      retainageTypeRef.current.show();
    }
  };
  const addType = async name => {
    if (name) {
      const newType = await createType(userId, { name });
      if (newType?.data) {
        const newTypeObject = {
          type_id: newType.data?.type_id,
          name: newType.data?.name,
        };
        formik.setFieldValue('typeId', newTypeObject);
        suggestRetainageTypes();

        toast?.current?.show({
          severity: 'success',
          summary: newType?.message,
          life: 2000,
        });
      }
    }
  };

  const saveRetainage = async () => {
    const category = formik?.values?.categoryId?.category_id;
    const type = formik?.values?.typeId?.type_id;

    const body = {
      category_id: category,
      initiate_date: formik.values?.initiateDate,
      total_amount: formik.values.totalAmount || 0,
      type_id: type,
      pay_percent: formik.values.payPercent || 0,
      fixed_amount: formik.values.fixedAmount || 0,
      description: formik.values.description,
      frequency: formik.values.frequency,
      percentage_of_payroll: formik.values.percentage_of_payroll,
    };
    for (const key in body) {
      if ([null, undefined].includes(body[key])) {
        delete body[key];
      }
    }
    setIsLoading(true);
    if (retainageDetails?.retainage_id) {
      const response = await editRetainage(userId, body);
      if (response?.data?.result) {
        toast?.current?.show({
          severity: 'success',
          summary: response?.message,
          life: 2000,
        });
      }
    } else {
      const response = await createRetainage(userId, body);
      if (response?.data?.result) {
        toast?.current?.show({
          severity: 'success',
          summary: response?.message,
          life: 2000,
        });
      }
    }
    formik.resetForm();
    setRefreshList(prev => !prev);
  };

  const fetchInitiationData = async () => {
    setIsLoading(true);
    try {
      const response = await getRetainage(userId);
      if (response?.data) {
        setRetainageDetails(response.data);
        setPayoutType(
          response.data?.fixed_amount
            ? RETAINAGE_PAYOUT_TYPE_OPTION[0].value
            : response.data?.pay_percent
              ? RETAINAGE_PAYOUT_TYPE_OPTION[1].value
              : response.data?.percentage_of_payroll
                ? RETAINAGE_PAYOUT_TYPE_OPTION[2].value
                : ''
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchList = async searchValue => {
    setIsListLoading(true);
    const urlParams = {
      ...tableDataState,
      sortOrder: tableDataState.sortOrder === 1 ? 'asc' : 'desc',
    };
    if (searchValue || globalFilterValue)
      urlParams.globalSearch = searchValue || globalFilterValue;
    const payoutResponse = await getRetainagePayout(
      userId,
      retainageDetails?.retainage_id,
      urlParams
    );
    if (payoutResponse?.data) {
      setRetainagePayoutDetails(payoutResponse.data);
    }
    setIsListLoading(false);
  };
  useEffect(() => {
    fetchInitiationData();
  }, [refreshList]);
  useEffect(() => {
    retainageDetails && fetchList();
  }, [retainageDetails, tableDataState]);

  const paymentCategoryTemplate = rowData => {
    return <>{rowData?.payment_category?.name}</>;
  };
  const paymentTypeTemplate = rowData => {
    return <>{rowData?.payment_type?.payment_type}</>;
  };
  const paymentStatusTemplate = rowData => {
    return <>{rowData?.payment_status?.status}</>;
  };

  const paymentReleaseDateTemplate = rowData => {
    return rowData?.release_date
      ? dateFormatter(rowData?.release_date, 'MM/DD/YYYY')
      : null;
  };

  const paymentDateTemplate = rowData => {
    return rowData?.payment_date
      ? dateFormatter(rowData?.payment_date, 'MM/DD/YYYY')
      : null;
  };

  const paymentAmountTemplate = rowData => {
    return (
      <>
        {!isNaN(rowData?.payment_amount)
          ? formatCurrency(rowData?.payment_amount)
          : ''}
      </>
    );
  };

  const debouncedOnChange = useCallback(debounce(fetchList, 500), [
    retainageDetails,
  ]);
  // To render header
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
          />
        </span>
        <div className="flex gap-4 align-items-center">
          <Button
            label="Clear"
            icon="pi pi-filter-slash"
            iconPos="left"
            outlined
            size="small"
            onClick={e => clearFilters(e)}
          />
          <div className="icon-round">
            <i
              className="icon pi pi-download cursor-pointer"
              onClick={e => exportOverlayRef?.current?.toggle(e)}
            />
            <OverlayPanel ref={exportOverlayRef}>
              <div className="flex flex-column gap-2">
                <CSVLink data={exportArr} filename={'Retainage'}>
                  <Button
                    size="small"
                    severity="primary"
                    outlined
                    onClick={exportCSV}
                  >
                    Export in CSV
                  </Button>
                </CSVLink>
                <Button color="primary" outlined onClick={exportPdf}>
                  Export in PDF
                </Button>
              </div>
            </OverlayPanel>
          </div>
          <div className="icon-round">
            <i
              className="icon pi pi-refresh cursor-pointer"
              onClick={() => fetchList()}
            />
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();
  const disabledRetainage = () => {
    return (
      retainageDetails?.payout_amount > 0 || !retainageDetails?.is_editable
    );
  };

  return (
    <>
      <div className="col-12 pl-2 py-3">
        {isLoading ? (
          <SkeletonLoader columnCount={1} columnWidth="25rem" />
        ) : (
          retainageDetails?.total_amount > 0 && (
            <>
              <span className="text-primary">
                Retainage Amount Balance:&nbsp;
                {!isNaN(retainageDetails?.remaining_balance)
                  ? formatCurrency(retainageDetails?.remaining_balance)
                  : formatCurrency(retainageDetails?.total_amount)}
              </span>
              <span>&nbsp;out of&nbsp;</span>
              <span className="font-bold">
                {retainageDetails?.total_amount
                  ? formatCurrency(retainageDetails?.total_amount)
                  : ''}
              </span>
            </>
          )
        )}
      </div>
      <div className="pt-4 pb-3">
        <div className="grid">
          <div className="grid col-10">
            <div className="font-bold text-lg ml-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="25rem" />
              ) : retainageDetails?.retainage_id ? (
                'Edit Retainage'
              ) : (
                'Add Retainage'
              )}
            </div>
          </div>
          <div className="grid col-2 justify-content-end">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="5rem" />
            ) : (
              <Button
                label="Save"
                size="small"
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  !formik.values.totalAmount ||
                  disabledRetainage()
                }
                onClick={saveRetainage}
              />
            )}
          </div>
        </div>

        <div className="grid">
          <div className="col-3">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <AutoComplete
                  ref={retainageCategoriesRef}
                  id="categoryId"
                  aria-describedby="categoryId-help"
                  className="w-10"
                  suggestions={retainageCategoriesSearchResults}
                  field="name"
                  completeMethod={suggestRetainageCategories}
                  value={formik?.values?.categoryId || ''}
                  onBlur={formik.handleBlur}
                  onChange={e => {
                    formik.setFieldValue('categoryId', e.target.value);
                  }}
                  inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                  onFocus={handleRetainageCategoriesFocus}
                  disabled={disabledRetainage()}
                />
                <label htmlFor="categoryId">Category</label>
                <Button
                  icon="pi pi-check"
                  className="p-button-primary w-2 border-noround-left height-40"
                  disabled={
                    onDisabled(
                      retainageCategoriesSearchResults,
                      formik?.values?.categoryId,
                      'name'
                    ) || disabledRetainage()
                  }
                  tooltip={
                    onDisabled(
                      retainageCategoriesSearchResults,
                      formik?.values?.categoryId,
                      'name'
                    )
                      ? ''
                      : 'Add Category'
                  }
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => addCategory(formik?.values?.categoryId)}
                />
              </span>
            )}
          </div>

          <div className="col-3">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <InputNumber
                  inputId="totalAmount"
                  name="totalAmount"
                  value={formik?.values?.totalAmount}
                  onValueChange={e => {
                    formik.setFieldValue('totalAmount', e.value);
                  }}
                  onBlur={formik.handleBlur}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                  className="w-full"
                  disabled={disabledRetainage()}
                />
                <label htmlFor="totalAmount">
                  Total Retainage Amount<i className="text-red-400">*</i>
                </label>
              </span>
            )}
          </div>
          <div className="col-3">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <AutoComplete
                  ref={retainageTypeRef}
                  id="typeId"
                  aria-describedby="typeId-help"
                  className="w-10"
                  suggestions={retainageTypesSearchResults}
                  field="name"
                  completeMethod={suggestRetainageTypes}
                  value={formik?.values?.typeId || ''}
                  onChange={e => {
                    formik.setFieldValue('typeId', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                  onFocus={handleRetainageTypesFocus}
                  disabled={disabledRetainage()}
                />

                <label htmlFor="typeId">Type</label>
                <Button
                  icon="pi pi-check"
                  className="p-button-primary w-2 border-noround-left height-40"
                  disabled={
                    onDisabled(
                      retainageTypesSearchResults,
                      formik?.values?.typeId,
                      'name'
                    ) || disabledRetainage()
                  }
                  tooltip={
                    onDisabled(
                      retainageTypesSearchResults,
                      formik?.values?.typeId,
                      'name'
                    )
                      ? ''
                      : 'Type'
                  }
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => addType(formik?.values?.typeId)}
                />
              </span>
            )}
          </div>
        </div>
        <div className="grid mt-1 mb-3">
          <div className="col-12 md:col-6 lg:col-3">
            {isLoading ? (
              <SkeletonLoader columnCount={1} columnWidth="20rem" />
            ) : (
              <span className="p-float-label">
                <Dropdown
                  id="payout_type"
                  className="w-12"
                  optionLabel="value"
                  options={RETAINAGE_PAYOUT_TYPE_OPTION}
                  value={payoutType}
                  onChange={e => {
                    setPayoutType(e.value);
                  }}
                  onBlur={formik.handleBlur}
                  pt={{
                    input: {
                      className: 'p-inputtext p-inputtext-sm',
                    },
                  }}
                  disabled={disabledRetainage()}
                />
                <label htmlFor="frequency">Payout Type</label>
              </span>
            )}
          </div>
          {payoutType === RETAINAGE_PAYOUT_TYPE_OPTION[1]?.value && (
            <div className="col-12 md:col-6 lg:col-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <InputNumber
                    inputId="payPercent"
                    name="payPercent"
                    value={formik?.values?.payPercent}
                    onValueChange={e => {
                      formik.setFieldValue('fixedAmount', null);
                      formik.setFieldValue('percentage_of_payroll', null);
                      formik.setFieldValue('payPercent', e.value);
                    }}
                    suffix="%"
                    onBlur={formik.handleBlur}
                    min={0}
                    minFractionDigits={2}
                    pt={{
                      root: { className: 'w-full' },
                    }}
                    disabled={disabledRetainage()}
                  />
                  <label htmlFor="payPercent">% of Pay</label>
                </span>
              )}
            </div>
          )}

          {payoutType === RETAINAGE_PAYOUT_TYPE_OPTION[2]?.value && (
            <div className="col-12 md:col-6 lg:col-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <InputNumber
                    inputId="percentage_of_payroll"
                    name="percentage_of_payroll"
                    value={formik?.values?.percentage_of_payroll}
                    onValueChange={e => {
                      formik.setFieldValue('fixedAmount', null);
                      formik.setFieldValue('payPercent', null);
                      formik.setFieldValue('percentage_of_payroll', e.value);
                    }}
                    suffix="%"
                    onBlur={formik.handleBlur}
                    min={0}
                    minFractionDigits={2}
                    pt={{
                      root: { className: 'w-full' },
                    }}
                    disabled={disabledRetainage()}
                  />
                  <label htmlFor="percentage_of_payroll">% of Pay</label>
                </span>
              )}
            </div>
          )}
          {payoutType === RETAINAGE_PAYOUT_TYPE_OPTION[0]?.value && (
            <div className="col-12 md:col-6 lg:col-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <InputNumber
                    inputId="fixedAmount"
                    name="fixedAmount"
                    value={formik?.values?.fixedAmount}
                    onValueChange={e => {
                      formik.setFieldValue('payPercent', null);
                      formik.setFieldValue('percentage_of_payroll', null);
                      formik.setFieldValue('fixedAmount', e.value);
                    }}
                    onBlur={formik.handleBlur}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    min={0}
                    pt={{
                      root: { className: 'w-full' },
                    }}
                    disabled={disabledRetainage()}
                  />
                  <label htmlFor="fixed_amount">Fixed Amount</label>
                </span>
              )}
            </div>
          )}
          {payoutType !== RETAINAGE_PAYOUT_TYPE_OPTION[2]?.value && (
            <div className="col-12 md:col-6 lg:col-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <Dropdown
                    id="frequency"
                    className="w-12"
                    optionLabel="value"
                    optionValue="key"
                    options={RetainageFrequencyTypeOptions}
                    value={formik?.values?.frequency}
                    onChange={e => {
                      formik.setFieldValue('frequency', e?.target?.value);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    disabled={disabledRetainage()}
                  />
                  <label htmlFor="frequency">Frequency</label>
                </span>
              )}
            </div>
          )}

          {payoutType != RETAINAGE_PAYOUT_TYPE_OPTION[2]?.value && (
            <div className="col-12 md:col-6 lg:col-3">
              {isLoading ? (
                <SkeletonLoader columnCount={1} columnWidth="20rem" />
              ) : (
                <span className="p-float-label">
                  <Calendar
                    inputId="initiateDate"
                    onChange={e =>
                      formik?.setFieldValue(
                        'initiateDate',
                        dateFormatter(e?.target?.value, 'YYYY-MM-DD')
                      )
                    }
                    dateFormat={'mm/dd/yy'}
                    value={
                      formik?.values?.initiateDate
                        ? new Date(
                            dateFormatter(
                              formik?.values?.initiateDate,
                              'MM/DD/YYYY'
                            )
                          )
                        : null
                    }
                    onBlur={formik.handleBlur}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    disabled={disabledRetainage()}
                  />
                  <label htmlFor="initiateDate">Initiation Date</label>
                </span>
              )}
            </div>
          )}
        </div>

        <div className="grid">
          <div className="col-12">
            {isLoading ? (
              <SkeletonLoader
                columnCount={1}
                columnWidth="70rem"
                columnHeight="5rem"
              />
            ) : (
              <span className="p-float-label">
                <InputTextarea
                  id="description"
                  value={formik?.values?.description}
                  onChange={e => {
                    formik.setFieldValue('description', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  rows={5}
                  cols={30}
                  className="w-full"
                  disabled={disabledRetainage()}
                />
                <label htmlFor="description">Retainage Description</label>
              </span>
            )}
          </div>
        </div>

        {!isLoading && retainageDetails?.retainage_id && (
          <>
            <div className="flex flex-row justify-content-between align-items-center pt-3 pb-3">
              <Button
                label="Release Retainage"
                size="small"
                outlined
                onClick={() => {
                  setSelectedRetainagePayout({
                    retainage_id: retainageDetails?.retainage_id,
                  });
                  setShowRetainagePayoutDialog(true);
                }}
                disabled={isListLoading}
                className="hidden"
              />
            </div>
            <Accordion activeIndex={-1}>
              <AccordionTab header="Retainage Details">
                <DataTable
                  loading={isListLoading}
                  header={header}
                  editMode="row"
                  value={
                    retainagePayoutDetails?.count
                      ? retainagePayoutDetails.result
                      : []
                  }
                  lazy
                  dataKey="retainage_id"
                  paginator
                  rows={tablePaginationState?.rows}
                  first={tablePaginationState?.first}
                  totalRecords={retainagePayoutDetails?.count}
                  onPage={event => {
                    setTableDataState(prev => ({
                      ...prev,
                      page: event?.page + 1,
                      limit: event?.rows,
                    }));
                    setTablePaginationState(prev => ({
                      ...prev,
                      first: event.first,
                      rows: event.rows,
                    }));
                  }}
                  rowsPerPageOptions={[5, 10]}
                  onSort={event =>
                    setTableDataState(prev => ({
                      ...prev,
                      sortField: event?.sortField,
                      sortOrder: event?.sortOrder,
                    }))
                  }
                  sortField={tableDataState?.sortField}
                  sortOrder={tableDataState?.sortOrder}
                  ref={dt}
                >
                  <Column
                    field="action"
                    header="Action"
                    body={actionTemplate}
                  />
                  <Column
                    field="release_date"
                    header="Release Date"
                    body={paymentReleaseDateTemplate}
                    sortable
                  />
                  <Column
                    field="payment_date"
                    header="Payment Date"
                    sortable
                    body={paymentDateTemplate}
                  />
                  <Column
                    field="payment_category"
                    body={paymentCategoryTemplate}
                    header="Payment Category"
                    sortable
                  />
                  <Column
                    field="payment_type"
                    body={paymentTypeTemplate}
                    header="Payment Type"
                    sortable
                  />
                  <Column
                    field="payment_status"
                    body={paymentStatusTemplate}
                    header="Payment Status"
                    sortable
                  />
                  <Column
                    field="payment_amount"
                    header="Payment Amount"
                    sortable
                    body={paymentAmountTemplate}
                  />
                  <Column
                    field="payment_txn_id"
                    header="Payment Transaction#"
                    sortable
                  />
                  <Column field="description" header="Description" sortable />
                </DataTable>
              </AccordionTab>
            </Accordion>
          </>
        )}
        {isLoading ? (
          <SkeletonLoader
            columnCount={1}
            columnWidth="70rem"
            columnHeight="5rem"
          />
        ) : (
          <Accordion activeIndex={0} className="my-2">
            <AccordionTab header="User Chargeback">
              <UserChargeBackView />
            </AccordionTab>
          </Accordion>
        )}
      </div>
      <Toast ref={toast} />
      {showRetainagePayoutDialog && (
        <>
          <UserFormRetainagePayout
            visible={showRetainagePayoutDialog}
            setShowRetainagePayoutDialog={setShowRetainagePayoutDialog}
            retainagePayout={selectedRetainagePayout}
            setRefreshList={setRefreshList}
            retainageToast={toast}
          />
          <ConfirmDialog />
        </>
      )}
    </>
  );
};

export default UserFormRetainage;
