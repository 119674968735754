import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import PageHeader from '../../shared/PageHeader/PageHeader';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFButton from '../../shared/PFPrime/PFButton';
import { fetchOpportunityById } from '../Opportunities/helpers/Opportunity';
import { fetchCustomerInfo } from '../Clients/helpers/CustomerInfo';
import { isMeasureSquareEnabled } from '../../../utils/Helpers';
import MeasureSquareQuotes from '../Projects/components/MeasureSquareQuotes';

import OpportunityInfomation from './components/OpportunityInfomation';
import ClientInfomation from './components/ClientInfomation';
import QuoteListing from './components/QuoteListing';
import {
  clientInformation,
  quotesBreadcrumb,
  clientInformationData,
} from './helpers/Quotes';

const QuoteContainer = ({}) => {
  const [opportunityDetails, setOpportunityDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState('');
  const { quoteListLayout, apiCallQuoteList } = QuoteListing();
  const toast = useRef(null);
  const { customerId, opportunityId } = useParams();

  const customerName = customerDetails
    ? `${customerDetails.first_name} ${customerDetails.last_name}`
    : '';

  return (
    <div className="w-full crm">
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <div className="grid w-full crm flex">
        <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
          <PageHeader
            pageTitle={`Quotes`}
            breadCrumbArray={quotesBreadcrumb(
              customerId,
              opportunityDetails?.opportunityId,
              opportunityDetails?.opportunityNumber,
              customerName,
              undefined
            )}
          />
        </div>
      </div>
      <div className="grid w-full crm flex py-2">
        <div className="sm:col-12 md:col-6 lg:col-8 pl-0">
          <ClientInfomation
            customerId={customerId}
            globalCustomerDetails={setCustomerDetails}
          />
        </div>
        <div className="sm:col-12 md:col-6 lg:col-4 pr-0">
          <OpportunityInfomation
            opportunityId={opportunityId}
            customerId={customerId}
            globalOpportunityDetails={setOpportunityDetails}
          />
        </div>
        <div className="sm:col-12 md:col-12 lg:col-12 p-0">
          <Card style={{ boxShadow: 'none' }}>
            {isMeasureSquareEnabled(
              opportunityDetails?.from_external_source
            ) ? (
              <MeasureSquareQuotes />
            ) : (
              quoteListLayout()
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default QuoteContainer;
