import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const SkeletonLoader = ({ columnCount, columnWidth, mTopBottom }) => {
  const numberOfSkeletons = Array.from(
    Array(columnCount || 20),
    (x, i) => i + 1
  );

  return (
    <div
      className={`w-full h-full flex flex-wrap justify-content-between mt-${mTopBottom || 0}`}
    >
      {numberOfSkeletons.map(skeleton => (
        <Skeleton
          key={skeleton}
          height="3rem"
          width={columnWidth || '16rem'}
          className="mb-2"
        ></Skeleton>
      ))}
    </div>
  );
};

export default SkeletonLoader;
