import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  paper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0px',
    overflow: 'hidden',
    marginBottom: '10px',
  },
  previewButton: {
    margin: 'auto',
    marginBottom: '10px',
  },
  margin: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  tableLabel: {
    width: '100px',
  },
}));
