// **** React Imports ****
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// **** External Utilities ****
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';

import { checkPermission } from '../../../utils/Helpers';
import useToken from '../../../hooks/useToken';
import permissions from '../../../config/permissions';

// **** Custom Components ****
import PdfViewerComponentNew from '../../PSPDF/PdfViewerComponentNew';

// **** Services *****
import {
  getDocumentById,
  createProjectDocumentFromTemplateDocument,
  updateProjectDocument,
} from './Docs.service';

// **** Styles/Images/Icons ****
import '../../../pdfviewerpage.css';
import DocumentSaveConfirmationDialog from './DocumentSaveConfirmationDialog';
import { useStyles } from './Docs.styles';

import { applyInstallerVisibilityTemplates } from '../../Admin/DocumentCenter/Templates.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

//pspdf toolbar items
const sourceFieldOptions = [];
const toolbarItems = [
  { type: 'pager' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'zoom-mode' },
  { type: 'export-pdf' },
  { type: 'debug' },
];

const ViewProjectDocument = () => {
  //Get projectId from url params
  const { projectId, documentId, documentType } = useParams();
  let history = useHistory();
  const [documentData, setDocumentData] = useState(null);
  const [annotationData, setAnnotationData] = useState(null);
  const [annotatedPdf, setAnnotatedPdf] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [tempLoading, setTempLoading] = React.useState(false);
  const [saveMode, setSaveMode] = React.useState(false);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const classes = useStyles();
  const [isChecked, setIsChecked] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);

  const toastRef = React.useRef(null);
  function setAlert(severity, title, message) {
    try {
      toastRef.current.show({
        severity: severity,
        summary: title,
        detail: message,
        life: 3000,
      });
    } catch (error) {}
  }

  let pdfViewerRef = React.useRef(null);
  const setPdfViewerRef = React.useMemo(() => {
    return refObj => {
      if (refObj) {
        pdfViewerRef.current = refObj;
      }
    };
  }, []);

  useEffect(() => {
    setIsChecked(!!documentData?.is_customer_visible);
  }, [documentData?.is_customer_visible]);

  React.useEffect(() => {
    getDocumentById(
      projectId,
      documentId,
      setDocumentData,
      setAnnotationData,
      setLoading,
      setAlert,
      getDocumentType()
    );
  }, []);

  const getDocumentType = () => {
    try {
      return parseInt(documentType);
    } catch (error) {}
    return 0;
  };
  const saveButtonClickHandler = async () => {
    setShowSaveDialog(true);
  };

  const documentSaveConfirmationDialogHandler = async value => {
    let val = value;
    if (loading) return;
    await savePdf(val);
  };
  const savePdf = async storeType => {
    try {
      const pdfByteArray = await pdfViewerRef.current.exportPDF();
      const pdfBlob = new Blob([pdfByteArray], { type: 'application/pdf' });
      const uploadFileName = documentData?.template_name;
      if (getDocumentType() === 2) {
        createProjectDocumentFromTemplateDocument(
          projectId,
          pdfBlob,
          uploadFileName,
          setLoading,
          setAlert,
          history,
          storeType,
          documentData?.hd_doc_cd_number,
          documentData?.ll_document_type,
          Number(isChecked),
          documentData?.is_signature_required,
          documentId
        );
      } else if (getDocumentType() === 1) {
        updateProjectDocument(
          storeType,
          projectId,
          documentId,
          pdfBlob,
          setLoading,
          setAlert,
          history
        );
      } else {
        setAlert(
          'Error',
          'Invalid Document Type',
          'Cannot process this request'
        );
      }
    } catch (error) {
      console.log(error, pdfViewerRef, 'error');
      setAlert('Error', 'Invalid Document Type', 'Cannot process this request');
    }
  };

  const cancelButtonClickHandler = async () => {
    setSaveMode(false);
  };

  const closeButtonClickHandler = async () => {
    history.push(`/project/view/${projectId}/#tab=docs`);
  };

  const editButtonClickHandler = async () => {
    setSaveMode(true);
  };

  const pdfUrl =
    getDocumentType() === 1
      ? documentData?.aws_doc_url
      : `${
          URL_CONSTANTS.API.BASE_URL
        }/documents/templates/${documentId}/project/${projectId}/render?client_id=${localStorage.getItem(
          'client_id'
        )}&token=${localStorage.getItem('cubejsAuthorizationToken')?.split(' ')?.[1]}`;

  return (
    <React.Fragment>
      <Toast ref={toastRef} position="center" />
      {showSaveDialog && (
        <DocumentSaveConfirmationDialog
          confirmHandler={documentSaveConfirmationDialogHandler}
          cancelHandler={setShowSaveDialog}
          alertHandler={setAlert}
          showDialog={showSaveDialog}
        />
      )}
      <div className="document__editor__header">
        <div>
          <h2 className="document__editor__header__title">Project Document</h2>
          <h1 className="document__editor__header__name">
            {documentData ? documentData.template_name : ''}
          </h1>
        </div>
        {checkPermission(permissions.viewEditProject.tabDocsAddProjectDocs) && (
          <div className="document__editor__header__buttons">
            {saveMode && (
              <>
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  iconPos="left"
                  severity="primary"
                  outlined
                  disabled={loading}
                  onClick={cancelButtonClickHandler}
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  iconPos="left"
                  severity="primary"
                  disabled={loading}
                  onClick={saveButtonClickHandler}
                />
              </>
            )}

            {!saveMode && (
              <Button
                label="Back"
                icon="pi pi-chevron-left"
                iconPos="left"
                severity="primary"
                outlined
                onClick={closeButtonClickHandler}
              />
            )}
            {!saveMode && (
              <Button
                label="Edit"
                icon="pi pi-file-edit"
                iconPos="left"
                severity="primary"
                disabled={loading}
                onClick={editButtonClickHandler}
              />
            )}
          </div>
        )}
      </div>
      <div className="PDF-viewer" style={{ width: '100%', height: '100vh' }}>
        {loading && (
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProgressSpinner />
          </div>
        )}
        {pdfUrl && (
          <PdfViewerComponentNew
            toolbarItems={toolbarItems}
            setParentRef={setPdfViewerRef}
            fieldOptions={sourceFieldOptions}
            document={pdfUrl}
            editable={false}
            readOnly={!saveMode}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewProjectDocument;
