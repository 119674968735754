import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setProjectTypes } from '../redux/slices/projectTypesMaster.slice';
import { CONFIG } from '../constants';

const useProjectType = () => {
  const dispatch = useDispatch();
  const { projectTypes: types } = useSelector(
    state => state?.masters?.projectTypesReducer
  );
  const [projectTypes, setValue] = useState(types);

  const getProjectTypes = async () => {
    try {
      if (projectTypes.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_BASE_URL}/projects/master/project-type`
        );
        setValue(response?.data);
        dispatch(setProjectTypes(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectTypes();
  }, []);

  return { projectTypes };
};

export default useProjectType;
