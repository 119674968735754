import * as Yup from 'yup';

const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));

const initialValues = {
  installer_id: '',
  type: '',
  date_scheduled_start: '',
  date_scheduled_end: '',
  installer_arrival_start_time: '',
  installer_arrival_end_time: '',
};

const InstallerFormikSchema = Yup.object().shape({
  installer_id: Yup.string().trim().required('Required'),
  type: Yup.number().required('Required'),
  date_scheduled_start: Yup.string().when('type', {
    is: val => val === 1 || val === 7, // Tentative or Schedule
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  date_scheduled_end: Yup.string().when('type', {
    is: val => val === 1 || val === 7, // Tentative or Schedule
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
});

const handleFormValidate = values => {
  let error = {};
  if (
    values?.date_scheduled_end &&
    values?.date_scheduled_start &&
    values?.date_scheduled_end < values?.date_scheduled_start
  ) {
    error.date_scheduled_end = 'End Date must be greater than Start Date.';
  }
  if (
    values?.installer_arrival_start_time &&
    values?.installer_arrival_end_time &&
    values?.installer_arrival_start_time >= values?.installer_arrival_end_time
  ) {
    error.installer_arrival_end_time =
      'End Time must be greater than Start Time.';
  }
  return error;
};

export { InstallerFormikSchema, handleFormValidate, initialValues };
