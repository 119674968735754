import * as Yup from 'yup';

import { FiltersOperators, WidgetTypeOptions } from '../../../../constants';
import { WIDGET_FILTER_TYPES } from '../constant/constant';

const widgetsFormInitialValues = {
  widget_type: '',
  widget_name: '',
  widget_description: '',
  chart_configuration: {
    dimension: '',
    measure: '',
    measureSummarizedBy: '',
    measureTarget: 100,
    groupBy: [],
    orderByField: '',
    orderBy: 'asc',
  },
  table_configuration: [{ measureName: '', alice: '' }],
  chart_filter_conditions: {
    condition: 'and',
    filters: [
      {
        condition: 'and',
        fields: [{ tableField: '', operator: '', value: '' }],
      },
    ],
  },
  table_filter_conditions: {
    condition: 'and',
    filters: [
      {
        condition: 'and',
        fields: [{ tableField: '', operator: '', value: '' }],
      },
    ],
  },
  order_by: {
    orderField: '',
    orderBy: 'asc',
  },
  widget_filter_type: 'normal-filter',
  custom_properties: {
    selectedChartTable: 'Project',
    selectedReportTable: 'Project',
    reportTablePrimaryKey: 'Project.project_id',
    chartMeta: null,
    buttonInfoUrl: '',
    buttonColor: '',
    buttonType: '',
    openIn: { name: 'Same Window', code: 'same-tab' },
    linkWidget: '',
    widgetProperty: '',
    actionType: 'display',
    buttonWidgetType: 'tabularWidget',
  },
  assignToDashBoardList: null,
  editable: null,
  tableLimit: 10,
  route_widget: 0,
};

// **** Create new widget form Validation Schema ****
const getWidgetFieldsValidationSchema = (dimensionList, selectedTable) => {
  return Yup.object({
    widget_type: Yup.string()
      .oneOf(WidgetTypeOptions.map(i => i.key))
      .required('Widget type is required'),
    widget_name: Yup.string().required('Widget name is required'),
    widget_filter_type: Yup.string()
      .oneOf(Object.keys(WIDGET_FILTER_TYPES))
      .required('Widget filter type is required'),
    chart_configuration: Yup.object().when(['widget_type'], {
      is: widget_type => {
        const tempTypeList = [];
        WidgetTypeOptions.forEach(typeObj => {
          if (
            typeObj.key !== 'external-link-button' &&
            typeObj.key !== 'table-report'
          ) {
            tempTypeList.push(typeObj.key);
          }
        });
        return tempTypeList.includes(widget_type);
      },
      then: Yup.object({
        dimension: Yup.string().required('Dimension is required'),
        measureTarget: Yup.number()
          .min(0, 'Target must be a positive number or zero')
          .required('Target is required'),
        groupBy: Yup.array(),
        orderByField: Yup.string().when(['groupBy'], {
          is: groupBy => groupBy.length > 0,
          then: Yup.string().required('Order By  field is required'),
          otherwise: Yup.string(),
        }),
        orderBy: Yup.string().when(['groupBy'], {
          is: groupBy => groupBy.length > 0,
          then: Yup.string().required('Order By is required'),
          otherwise: Yup.string(),
        }),
      }),
      otherwise: Yup.object(),
    }),
    table_configuration: Yup.array().when(
      ['widget_type', 'custom_properties'],
      {
        is: (widget_type, custom_properties) => {
          const tempTypeList = [];
          WidgetTypeOptions.forEach(typeObj => {
            if (
              typeObj.key !== 'external-link-button' ||
              (typeObj.key == 'external-link-button' &&
                ['tabularWidget', 'routingWidget'].includes(
                  custom_properties?.buttonWidgetType
                ))
            ) {
              tempTypeList.push(typeObj.key);
            }
          });
          return tempTypeList.includes(widget_type);
        },
        then: Yup.array().of(
          Yup.object({
            measureName: Yup.string()
              .oneOf(dimensionList.map(i => i.name))
              .required("Measure name can't be empty"),
            alice: Yup.string().required("Alias can't be empty"),
          })
        ),
        otherwise: Yup.array().of(Yup.object({})),
      }
    ),
    chart_filter_conditions: Yup.object().when(['widget_type'], {
      is: widget_type => {
        const tempTypeList = [];
        WidgetTypeOptions.forEach(typeObj => {
          if (
            typeObj.key !== 'external-link-button' &&
            typeObj.key !== 'table-report'
          ) {
            tempTypeList.push(typeObj.key);
          }
        });
        return tempTypeList.includes(widget_type);
      },
      then: Yup.object({
        condition: Yup.string().oneOf(['and', 'or']),
        filters: Yup.array()
          .of(
            Yup.object({
              condition: Yup.string().oneOf(['and', 'or']),
              fields: Yup.array().of(
                Yup.object({
                  tableField: Yup.string()
                    .oneOf(dimensionList.map(i => i.name))
                    .required('Chart filter field is required'),
                  operator: Yup.string().required(
                    'Chart filter operator is required'
                  ),
                })
              ),
            })
          )
          .min(1)
          .required(),
      }),
    }),
    table_filter_conditions: Yup.object().when(
      ['widget_type', 'custom_properties'],
      {
        is: (widget_type, custom_properties) => {
          const tempTypeList = [];
          WidgetTypeOptions.forEach(typeObj => {
            if (
              typeObj.key !== 'external-link-button' ||
              (typeObj.key == 'external-link-button' &&
                ['tabularWidget', 'routingWidget'].includes(
                  custom_properties?.buttonWidgetType
                ))
            ) {
              tempTypeList.push(typeObj.key);
            }
          });
          return tempTypeList.includes(widget_type);
        },
        then: Yup.object({
          condition: Yup.string().oneOf(['and', 'or']),
          filters: Yup.array()
            .of(
              Yup.object({
                condition: Yup.string().oneOf(['and', 'or']),
                fields: Yup.array().of(
                  Yup.object({
                    tableField: Yup.string()
                      .oneOf(
                        dimensionList
                          .filter(item => item.firstLetter === selectedTable)
                          .map(i => i.name)
                      )
                      .required('Table filter field is required'),
                    operator: Yup.string().required(
                      'Table filter operator is required'
                    ),
                  })
                ),
              })
            )
            .min(1)
            .required(),
        }),
      }
    ),
    order_by: Yup.object().when(['widget_type'], {
      is: widget_type => {
        const tempTypeList = [];
        WidgetTypeOptions.forEach(typeObj => {
          if (typeObj.key != 'external-link-button') {
            tempTypeList.push(typeObj.key);
          }
        });
        return tempTypeList.includes(widget_type);
      },
      then: Yup.object({
        orderField: Yup.string()
          .oneOf(
            dimensionList
              .filter(item => item.firstLetter === selectedTable)
              .map(i => i.name)
          )
          .required('Order By field is required'),
        orderBy: Yup.string()
          .oneOf(['asc', 'desc'])
          .required('Order by direction is required'),
      }),
      otherwise: Yup.object(),
    }),
    custom_properties: Yup.object().when(['widget_type'], {
      is: widget_type => {
        const tempTypeList = [];
        WidgetTypeOptions.forEach(typeObj => {
          if (typeObj.key == 'external-link-button') {
            tempTypeList.push(typeObj.key);
          }
        });
        return tempTypeList.includes(widget_type);
      },
      then: Yup.object({
        buttonInfoUrl: Yup.string().when('buttonWidgetType', {
          is: value => value && value === 'urlWidget',
          then: Yup.string().required('URL is required'),
          otherwise: Yup.string(),
        }),
      }),
    }),
  });
};

export { widgetsFormInitialValues, getWidgetFieldsValidationSchema };
