import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { PFButton, PFCheckbox, PFSidebar } from '../pf-prime/Prime';

import InventoryDialogBody from './InventoryDialogBody.view';
import { getProduct, updateItemInSql } from './ProductDetails.model';

import './css/style.css';
import { fetchWarehouseInventoryList } from '../../Locations/Warehouse.service';

import { addWarehouse, warehouseClearState } from './reducers/WarehouseSlice';
import { productClearState } from './reducers/ProductDetailsSlice';
import { getAllWarehouses, updateExcludeCommission } from './inventory.model';

const Inventory = props => {
  const dispatch = useDispatch();
  let { itemInfo } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  const [visible, setVisible] = useState(false);
  const [inventoryMode, setInventoryMode] = useState(true);
  const [excludeFromCommission, setExcludeFromCommission] = useState(
    props?.inventoryItem?.exclude_from_commission
  );
  const [excludeFromRevenue, setExcludeFromRevenue] = useState(
    props?.inventoryItem?.exclude_from_revenue
  );

  // setExcludeFromRevenue
  const [checkEnableExcludeCommission, setCheckEnableExcludeCommission] =
    useState(0);
  const [checkEnableExcludeRevenue, setCheckEnableExcludeRevenue] = useState(0);

  useEffect(() => {
    const productId = props?.inventoryItem?.item_id;
    productId &&
      props?.inventoryOpen &&
      getProduct(productId, dispatch, props?.inventoryItem);
  }, [props?.inventoryItem?.item_id, props?.openedTimes, props?.inventoryOpen]);

  useEffect(() => {
    setVisible(props?.inventoryOpen);
  }, [props?.inventoryOpen]);

  const changeMode = async () => {
    if (!inventoryMode) {
      const res = updateItemInSql(`${props?.inventoryItem?.item_id}`, {
        inventory_managment: false,
      });

      res.then(response => {
        if (response?.status === 200) {
          setVisible(false);
          props?.setInventoryOpen(false);
          props.itemsReload && props?.itemsReload(true);
        }
      });
    }
  };

  useEffect(() => {
    if (!itemInfo) {
      setVisible(false);
    }
  }, [itemInfo]);

  useEffect(() => {
    getAllWarehouses(dispatch);
    return () => {
      dispatch(productClearState());
      dispatch(warehouseClearState());
      props.itemsReload && props?.itemsReload(true);
    };
  }, []);

  useEffect(() => {
    if (itemInfo && itemInfo._id) {
      fetchWarehouseInventoryList(itemInfo._id)
        .then(response => {
          dispatch(addWarehouse({ item: response || [] }));
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [itemInfo]);

  const ExcludeCommissionInitialValues = {
    exclude_from_commission: excludeFromCommission,
  };

  const handleSubmitForm = () => async values => {
    const payload = {
      item_number: props?.inventoryItem?.item_number || '',
      item_desc: props?.inventoryItem?.item_desc || '',
      exclude_from_commission: values?.exclude_from_commission || false,
    };

    const response = await updateExcludeCommission(
      props?.inventoryItem?.item_id,
      payload
    );
    if (response?.item_id && response?.item_id > 0) {
      props?.showAlert(
        'success',
        `Item is ${
          excludeFromCommission ? 'Excluded' : 'Included'
        } Successfully from Sales Commission Calculation`
      );
    } else {
      props?.showAlert('error', 'Something went wrong.');
    }
  };

  const handleExcludeFromRevenueSubmit = () => async values => {
    const payload = {
      item_number: props?.inventoryItem?.item_number || '',
      item_desc: props?.inventoryItem?.item_desc || '',
      exclude_from_revenue: values?.exclude_from_revenue || false,
    };

    const response = await updateExcludeCommission(
      props?.inventoryItem?.item_id,
      payload
    );
    if (response?.item_id && response?.item_id > 0) {
      props?.showAlert(
        'success',
        `Item is ${
          excludeFromCommission ? 'Excluded' : 'Included'
        } Successfully from Revenue Calculation`
      );
    } else {
      props?.showAlert('error', 'Something went wrong.');
    }
  };

  const handleSumForTotalQuantitySubmit = () => async values => {
    const payload = {
      item_number: props?.inventoryItem?.item_number || '',
      item_desc: props?.inventoryItem?.item_desc || '',
      is_sum_for_total_quantity: values?.is_sum_for_total_quantity || false,
    };
    try {
      const response = await updateExcludeCommission(
        props?.inventoryItem?.item_id,
        payload
      );
      if (response?.item_id && response?.item_id > 0) {
        props?.showAlert(
          'success',
          `Item is ${
            sumForTotalQuantityFormik.values.is_sum_for_total_quantity
              ? 'Included'
              : 'Excluded'
          } Successfully from Revenue Calculation`
        );
      } else {
        props?.showAlert('error', 'Something went wrong.');
      }
    } catch (error) {
      props?.showAlert(
        'error',
        'Error occurred while updating Sum for total quantity.'
      );
    }
  };

  const handleExcludeFromCommissionFormik = useFormik({
    enableReinitialize: true,
    initialValues: ExcludeCommissionInitialValues,
    onSubmit: handleSubmitForm(),
    enableReinitialize: true,
  });

  const handleExcludeFromRevenueFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      exclude_from_revenue: excludeFromRevenue,
    },
    onSubmit: handleExcludeFromRevenueSubmit(),
    enableReinitialize: true,
  });

  const sumForTotalQuantityFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      is_sum_for_total_quantity:
        props?.inventoryItem?.is_sum_for_total_quantity,
      check_enable_sum_for_total: 0,
    },
    onSubmit: handleSumForTotalQuantitySubmit(),
    enableReinitialize: true,
  });

  const productTitle = () => (
    <div className="grid">
      <div className="col-12 md:col-6 lg:col-4 xl:w-16rem">
        <PFCheckbox
          value={inventoryMode}
          label="Inventory Management"
          onChange={e => setInventoryMode(e)}
        />
        {!inventoryMode && (
          <>
            <p className="text-xs ml-5 my-2">
              Are you sure you want to disable the inventory for this item. This
              action will not delete the records and you can view it again after
              enabling it.
            </p>

            <PFButton
              className={`mt-1 ml-5 inventory-button`}
              onClick={changeMode}
              label="Confirm?"
              severity="primary"
              rounded
            />
          </>
        )}
      </div>
      <div className="col-12 md:col-6 lg:col-4 xl:col-3">
        <PFCheckbox
          name="exclude_from_commission"
          value={excludeFromCommission}
          label="Exclude From Commission"
          onChange={e => {
            setCheckEnableExcludeCommission(checkEnableExcludeCommission + 1);
            setExcludeFromCommission(e);
            if (checkEnableExcludeCommission > 0)
              handleExcludeFromCommissionFormik.handleSubmit();
          }}
        />
      </div>
      {props?.inventoryItem?.item_type?.type_name == 'Labor' && (
        <div className="col-12 md:col-6 lg:col-4 xl:col-3">
          <PFCheckbox
            name="exclude_from_revenue"
            value={excludeFromRevenue}
            label="Exclude From Revenue"
            onChange={e => {
              setCheckEnableExcludeRevenue(checkEnableExcludeRevenue + 1);
              setExcludeFromRevenue(e);
              if (checkEnableExcludeRevenue > 0)
                handleExcludeFromRevenueFormik.handleSubmit();
            }}
          />
        </div>
      )}
      <div className="col-12 md:col-6 lg:col-4 xl:col-3">
        <PFCheckbox
          name="is_sum_for_total_quantity"
          value={sumForTotalQuantityFormik.values.is_sum_for_total_quantity}
          label="Sum For Total Quantity"
          onChange={e => {
            sumForTotalQuantityFormik.setFieldValue(
              'check_enable_sum_for_total',
              sumForTotalQuantityFormik.values.check_enable_sum_for_total + 1
            );
            sumForTotalQuantityFormik.setFieldValue(
              'is_sum_for_total_quantity',
              e
            );
            if (sumForTotalQuantityFormik.values.check_enable_sum_for_total > 0)
              sumForTotalQuantityFormik.handleSubmit();
          }}
        />
      </div>
    </div>
  );

  const customHeader = () => (
    <React.Fragment>
      <div className="grid w-12 text-white m-0 text-center justify-content-center">
        <h2 className="m-0 text-white">Manage Inventory</h2>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      {visible && (
        <>
          <PFSidebar
            header={productTitle(itemInfo)}
            visible={visible}
            onHide={() => {
              dispatch(productClearState());
              setVisible(false);
              props?.setInventoryOpen(false);
            }}
            panelClass="inventory-panel mt-2"
            icons={() =>
              customHeader(
                `${itemInfo?.productNumber}-${itemInfo?.productName}`
              )
            }
            body={InventoryDialogBody}
            pt={{
              icons: 'w-12 text-center',
              header: 'bg-primary',
            }}
          />
        </>
      )}
    </div>
  );
};

export default Inventory;
