// **** React Imports ****
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import { Button, OverlayPanel } from 'primereact';
// **** External Utilities ****
import {
  Grid,
  Typography,
  Menu,
  MenuItem,
  TextField,
  Chip,
  Badge,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import axios from 'axios';
import { debounce } from 'lodash';
import { FilterList as FilterListIcon } from '@material-ui/icons';

import { getFiltersDropdown } from '../../HomeDashboard/HomeDashboard.service';

// **** Styles/Images/Icons ****
import { useStyles } from '../../HomeDashboard/HomeDashboard.styles';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { searchWorkrooms } from '../Stores/Stores.service';
import { DateSold } from '../../../constants';

const DashboardFilters = forwardRef(
  (
    {
      applyFilters,
      setApplyFilters,
      widgetFilterQuery,
      clearFilters,
      setClearFilters,
      setWidgetFilterQuery,
      homeFilters,
      setHomeFilters,
      region,
      setRegion,
      storeNumber,
      setStoreNumber,
      district,
      setDistrict,
      category,
      setCategory,
      source,
      setSource,
      projectType,
      setProjectType,
      installers,
      setInstallers,
      status,
      setStatus,
      workroom,
      setWorkroom,
      setScheduleDate,
      scheduleDate,
      setCompletionDate,
      completionDate,
      checkDate,
      setCheckDate,
      homeFilterDateDimension,
      setHomeFilterDateDimension,
      refreshDashboardList,
      disabledDashboard,
      setDisabledDashboard,
      dateSold,
      setDateSold,
    },
    ref
  ) => {
    const classes = useStyles();

    const [regionOptions, setRegionOptions] = React.useState([]);
    const [districtOptions, setDistrictOptions] = React.useState([]);
    const [storeNoOptions, setStoreNoOptions] = React.useState([]);
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const [typeOptions, setTypeOptions] = React.useState([]);
    const [sourceOptions, setSourceOptions] = React.useState([]);
    const [statusOptions, setStatusOptions] = React.useState([]);
    const [workroomOptions, setWorkroomOptions] = React.useState([]);
    const [initialWorkroomOptions, setInitialWorkroomOptions] = React.useState(
      []
    );
    const [counter, setCounter] = React.useState(30);
    const [installerOptions, setInstallerOptions] = React.useState([]);
    const anchorEl = useRef(null);
    const [showFiltersGrid, setShowFiltersGrid] = React.useState(false);
    React.useEffect(async () => {
      if (axios.defaults.headers.common['client_id']) {
        await getFiltersDropdown(
          setRegionOptions,
          setDistrictOptions,
          setStoreNoOptions,
          setCategoryOptions,
          setTypeOptions,
          setSourceOptions,
          setInstallerOptions,
          setStatusOptions,
          setWorkroomOptions,
          setInitialWorkroomOptions
        );
      }
    }, [axios.defaults.headers.common['client_id']]);

    const handleClick = event => {
      anchorEl.current.toggle(event);
    };
    const handleClose = event => {
      anchorEl.current.toggle(event);
      setApplyFilters(false);
    };
    useImperativeHandle(ref, () => ({
      handleClearAll() {
        setRegion([]);
        setStoreNumber([]);
        setDistrict([]);
        setProjectType([]);
        setCategory([]);
        setSource([]);
        setHomeFilters([]);
        setInstallers([]);
        setShowFiltersGrid(false);
        setClearFilters(true);
        setHomeFilterDateDimension([]);
        setStatus([]);
        setWorkroom([]);
        setScheduleDate({ start: null, end: null });
        setCompletionDate({ start: null, end: null });
        setCheckDate({ start: null, end: null });
        setDateSold({ start: null, end: null });
      },
    }));
    const handleClearAll = () => {
      setRegion([]);
      setStoreNumber([]);
      setDistrict([]);
      setProjectType([]);
      setInstallers([]);
      setCategory([]);
      setSource([]);
      setHomeFilters([]);
      setShowFiltersGrid(false);
      setClearFilters(true);
      setHomeFilterDateDimension([]);
      setStatus([]);
      setWorkroom([]);
      setScheduleDate({ start: null, end: null });
      setCompletionDate({ start: null, end: null });
      setCheckDate({ start: null, end: null });
      setDateSold({ start: null, end: null });
      setApplyFilters(false);
    };

    const fetchWorkroomDropdown = async event => {
      const searchString = event?.target?.value;
      if (searchString?.length > 2) {
        const workrooms = await searchWorkrooms(searchString);
        workrooms && workrooms?.length > 0 && setWorkroomOptions(workrooms);
      } else {
        if (searchString === '') {
          setWorkroomOptions(initialWorkroomOptions);
        }
      }
    };

    useEffect(() => {
      if (disabledDashboard) {
        const intervalId = setInterval(() => {
          setCounter(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
      }
    }, [disabledDashboard]);

    useEffect(() => {
      if (counter === 0) {
        clearInterval();
        setDisabledDashboard(false);
        setCounter(30);
      }
    }, [counter]);

    return (
      <>
        <Grid
          container
          item
          direction="row"
          className="mt-2 lg:mt-0 flex md:justify-content-end"
        >
          <Grid item className="py-0">
            <div className="flex">
              <Button
                type="button"
                icon="pi pi-refresh"
                label={disabledDashboard ? counter : 'Refresh'}
                outlined
                size="small"
                onClick={refreshDashboardList}
                className="mr-2"
                disabled={disabledDashboard || false}
              />
              {checkPermission(permissions?.home?.applyFilter) ? (
                homeFilters?.length || homeFilterDateDimension.length > 0 ? (
                  <Badge badgeContent="*" color="secondary">
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Filter"
                      outlined
                      onClick={e => handleClick(e)}
                      className="mr-2"
                    />
                  </Badge>
                ) : (
                  <Button
                    type="button"
                    icon="pi pi-filter"
                    label="Filter"
                    size="small"
                    outlined
                    onClick={e => handleClick(e)}
                    className="mr-2"
                  />
                )
              ) : (
                ''
              )}
            </div>

            <OverlayPanel ref={anchorEl}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                classes={{ root: classes.typoRoot }}
              >
                <Grid item>
                  <Typography variant="h3">Filters</Typography>
                </Grid>
              </Grid>
              <MenuItem>
                <Autocomplete
                  name="storeNo"
                  multiple
                  options={storeNoOptions}
                  getOptionLabel={option =>
                    option && `${option.store_number}-${option.store_name}`
                  }
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Store No"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  value={storeNumber}
                  onChange={(event, value) => {
                    setStoreNumber(value);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'Project.store_id'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member == 'Project.store_id'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'Project.store_id',
                            values: value.map(cat => cat?.store_id.toString()),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'Project.store_id',
                          values: value.map(cat => cat?.store_id.toString()),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  name="category"
                  options={categoryOptions}
                  getOptionLabel={option => option && option.category}
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Category"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  multiple
                  value={category}
                  onChange={(event, value) => {
                    setCategory(value);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'Project.project_category_id'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member == 'Project.project_category_id'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'Project.project_category_id',
                            values: value.map(cat =>
                              cat?.project_category_id.toString()
                            ),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'Project.project_category_id',
                          values: value.map(cat =>
                            cat?.project_category_id.toString()
                          ),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  name="projectType"
                  options={typeOptions}
                  getOptionLabel={option => option && option.project_type}
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Type"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  multiple
                  value={projectType}
                  onChange={(event, value) => {
                    setProjectType(value);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'Project.project_type_id'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member == 'Project.project_type_id'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'Project.project_type_id',
                            values: value.map(cat =>
                              cat?.project_type_id.toString()
                            ),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'Project.project_type_id',
                          values: value.map(cat =>
                            cat?.project_type_id.toString()
                          ),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  name="installer"
                  options={installerOptions}
                  getOptionLabel={option => option && option.installer_name}
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Technician"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  multiple
                  value={installers}
                  onChange={(event, value) => {
                    setInstallers(value);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'InstallerDetail.installer_id'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member == 'InstallerDetail.installer_id'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'InstallerDetail.installer_id',
                            values: value.map(cat =>
                              cat?.installer_id.toString()
                            ),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'InstallerDetail.installer_id',
                          values: value.map(cat =>
                            cat?.installer_id.toString()
                          ),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  name="status"
                  options={statusOptions}
                  getOptionLabel={option => option && option.label}
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Status"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  multiple
                  value={status}
                  onChange={(event, value) => {
                    setStatus(value);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'Project.status_id'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member == 'Project.status_id'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'Project.status_id',
                            values: value.map(cat => cat?.value.toString()),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'Project.status_id',
                          values: value.map(cat => cat?.value.toString()),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  name="workroom"
                  options={workroomOptions}
                  getOptionLabel={option => option && option.label}
                  classes={{ root: classes.autocompleteRoot }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Workroom"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  multiple
                  value={workroom}
                  onInputChange={debounce(fetchWorkroomDropdown, 300)}
                  onChange={(event, value) => {
                    setWorkroom(value);
                    setWorkroomOptions(initialWorkroomOptions);
                    let obj,
                      prevObj = [];
                    prevObj =
                      homeFilters.filter(
                        fil => fil.member !== 'Project.workroom_type'
                      ) || [];
                    if (
                      homeFilters.filter(
                        fil => fil.member === 'Project.workroom_type'
                      ).length > 0
                    ) {
                      if (value?.length) {
                        obj = [
                          {
                            member: 'Project.workroom_type',
                            values: value.map(cat => cat?.label),
                            operator: 'equals',
                          },
                        ];
                      } else {
                        obj = [];
                      }
                    } else {
                      obj = [
                        {
                          member: 'Project.workroom_type',
                          values: value.map(cat => cat?.label),
                          operator: 'equals',
                        },
                      ];
                    }
                    setHomeFilters([...obj, ...prevObj]);
                  }}
                />
              </MenuItem>
              <MenuItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ paddingRight: '10px' }}
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Scheduled Date Start"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={
                      scheduleDate.start ? moment(scheduleDate.start) : null
                    }
                    onChange={(date, value) => {
                      setScheduleDate({
                        ...scheduleDate,
                        start: moment(date).format('YYYY-MM-DD'),
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];
                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'Project.date_scheduled_start'
                      );
                      const newCondition = {
                        dimension: 'Project.date_scheduled_start',
                        dateRange: [
                          moment(date).format('YYYY-MM-DD'),
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ paddingRight: '10px' }}
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Scheduled Date End"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={scheduleDate.end ? moment(scheduleDate.end) : null}
                    onChange={(date, value) => {
                      setScheduleDate({
                        ...scheduleDate,
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];

                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'Project.date_scheduled_start'
                      );

                      const newCondition = {
                        dimension: 'Project.date_scheduled_start',
                        dateRange: [
                          scheduleDate.start,
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </MenuItem>
              <MenuItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ paddingRight: '10px' }}
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Completion Date Start"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={
                      completionDate.start ? moment(completionDate.start) : null
                    }
                    onChange={(date, value) => {
                      setCompletionDate({
                        ...completionDate,
                        start: moment(date).format('YYYY-MM-DD'),
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];
                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'Project.completion_date'
                      );
                      const newCondition = {
                        dimension: 'Project.completion_date',
                        dateRange: [
                          moment(date).format('YYYY-MM-DD'),
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Completion Date End"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={
                      completionDate.end ? moment(completionDate.end) : null
                    }
                    onChange={(date, value) => {
                      setCompletionDate({
                        ...completionDate,
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];

                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'Project.completion_date'
                      );

                      const newCondition = {
                        dimension: 'Project.completion_date',
                        dateRange: [
                          completionDate.start,
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </MenuItem>
              <MenuItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ paddingRight: '10px' }}
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Check Date From"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={checkDate.start ? moment(checkDate.start) : null}
                    onChange={(date, value) => {
                      setCheckDate({
                        ...checkDate,
                        start: moment(date).format('YYYY-MM-DD'),
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];
                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'PaymentRequest.check_date'
                      );
                      const newCondition = {
                        dimension: 'PaymentRequest.check_date',
                        dateRange: [
                          moment(date).format('YYYY-MM-DD'),
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Check Date To"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={checkDate.end ? moment(checkDate.end) : null}
                    onChange={(date, value) => {
                      setCheckDate({
                        ...checkDate,
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];

                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === 'PaymentRequest.check_date'
                      );

                      const newCondition = {
                        dimension: 'PaymentRequest.check_date',
                        dateRange: [
                          checkDate.start,
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </MenuItem>

              <MenuItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ paddingRight: '10px' }}
                    autoOk
                    id="date_sold_from"
                    name="date_sold_from"
                    variant="inline"
                    label="Date Sold From"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={dateSold?.start ? moment(dateSold?.start) : null}
                    onChange={(date, value) => {
                      setDateSold({
                        ...dateSold,
                        start: moment(date).format('YYYY-MM-DD'),
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];
                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === DateSold
                      );
                      const newCondition = {
                        dimension: DateSold,
                        dateRange: [
                          moment(date).format('YYYY-MM-DD'),
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    id="date_sold_to"
                    name="date_sold_to"
                    variant="inline"
                    label="Date Sold To"
                    format="yyyy-MM-dd"
                    inputProps={{ autoComplete: 'off' }}
                    value={dateSold?.end ? moment(dateSold?.end) : null}
                    onChange={(date, value) => {
                      setDateSold({
                        ...dateSold,
                        end: moment(date).format('YYYY-MM-DD'),
                      });
                      const tempFiltes = [...homeFilterDateDimension];

                      const foundIndex = homeFilterDateDimension.findIndex(
                        fil => fil.dimension === DateSold
                      );

                      const newCondition = {
                        dimension: DateSold,
                        dateRange: [
                          dateSold?.start,
                          moment(date).format('YYYY-MM-DD'),
                        ],
                      };
                      if (foundIndex >= 0) {
                        tempFiltes[foundIndex] = newCondition;
                      } else {
                        tempFiltes.push(newCondition);
                      }
                      setHomeFilterDateDimension(tempFiltes);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </MenuItem>
              {/* End */}
              <Grid
                container
                item
                justifyContent="space-between"
                classes={{ root: classes.clearAll }}
              >
                <Button
                  severity="primary"
                  outlined
                  size="small"
                  onClick={handleClearAll}
                  disabled={!checkPermission(permissions?.home?.clearFilter)}
                >
                  Clear All
                </Button>
                <Button
                  severity="primary"
                  size="small"
                  onClick={() => {
                    anchorEl.current.toggle(event);
                    setApplyFilters(true);
                    setShowFiltersGrid(true);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </OverlayPanel>
          </Grid>
          {/* {showFiltersGrid && homeFilters.length > 0 && (
            <Grid item justifyContent="flex-end" classes={{ root: classes.clearAll }}>
              <Button color="primary" variant="contained" onClick={handleClearAll}>
                Clear Filters
              </Button>
            </Grid>
          )} */}
        </Grid>
      </>
    );
  }
);

export default DashboardFilters;
