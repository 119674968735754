import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useParams } from 'react-router';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import dayjs from 'dayjs';

/*Custom Components*/
import { debounce } from 'lodash';
import { CSVLink } from 'react-csv';
import { Skeleton } from '@mui/material';

import {
  checkFutureDate,
  formatCurrency,
  dateFormatter,
} from '../../../../../utils/Helpers';
import { getPaymentType } from '../../../Billing/Billing.service';
import { getStatusOptions } from '../../../../Admin/Stores/Stores.service';
import {
  addEditChargeback,
  getChargebackPayouts,
} from '../../services/ChargebackService';

import PayoutForm from './PayoutForm';

const cols = [
  { field: 'created_at', header: 'Create Date' },
  { field: 'payment_date', header: 'Payment Date' },
  { field: 'payment_category', header: 'Payment Category' },
  { field: 'payment_type', header: 'Payment Type' },
  { field: 'payment_status', header: 'Payment Status' },
  { field: 'payment_amount', header: 'Payment Amount' },
  { field: 'payment_txn_id', header: 'Payment Date' },
  { field: 'description', header: 'Description' },
];
const exportColumns = cols.map(col => ({
  title: col.header,
  dataKey: col.field,
}));
const Payout = ({ disabledFields }) => {
  const { chargebackId } = useParams();
  const [chargebackPayoutList, setChargebackPayoutList] = useState();
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [tableDataState, setTableDataState] = useState({
    limit: 5,
    page: 1,
    sortField: 'chargeback_payout_id',
    sortOrder: 1,
  });
  const [tablePaginationState, setTablePaginationState] = useState({
    first: 0,
    rows: 5,
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isListLoading, setIsListLoading] = useState(true);
  const [showPayoutDialog, setShowPayoutDialog] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState();

  const toast = useRef(null);
  const exportOverlayRef = useRef(null);

  // Helper to export CSV file
  const exportCSV = () => {
    exportOverlayRef?.current?.hide();
  };
  const exportArr = useMemo(
    () =>
      chargebackPayoutList?.result.map(e => {
        return {
          created_at: dayjs(new Date(e?.created_at)).format('MM-DD-YYYY'),
          payment_date: dayjs(new Date(e?.payment_date)).format('MM-DD-YYYY'),
          payment_category: e?.payment_category?.name,
          payment_type: e?.payment_type?.payment_type,
          payment_status: e?.payment_status?.status,
          payment_amount: e?.payment_amount,
          payment_txn_id: e?.payment_txn_id,
          description: e?.description,
        };
      }),
    [chargebackPayoutList]
  );

  // Helper to export pdf file
  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, exportArr);
        doc.save('chargeback-payouts.pdf');
        exportOverlayRef?.current?.hide();
      });
    });
  };

  const clearFilters = e => {
    setGlobalFilterValue('');
    setTableDataState({
      limit: 10,
      page: 1,
      sortField: 'chargeback_payout_id',
      sortOrder: 1,
    });
  };

  const actionTemplate = rowData => {
    return isListLoading ? (
      <Skeleton />
    ) : (
      <div className="flex flex-wrap gap-3">
        <i
          className={
            checkFutureDate(rowData?.emi_date) &&
            rowData?.payment_status?.status !== 'Paid'
              ? 'pi pi-pencil cursor-pointer'
              : 'pi pi-pencil text-300'
          }
          onClick={() => {
            if (
              checkFutureDate(rowData?.emi_date) &&
              rowData?.payment_status?.status !== 'Paid'
            ) {
              setSelectedPayout(rowData);
              setShowPayoutDialog(true);
            }
          }}
        ></i>
      </div>
    );
  };

  const fetchList = async searchValue => {
    setIsListLoading(true);
    const urlParams = {
      ...tableDataState,
      sortOrder: tableDataState.sortOrder === 1 ? 'asc' : 'desc',
    };
    if (searchValue || globalFilterValue)
      urlParams.globalSearch = searchValue || globalFilterValue;
    const payoutResponse = await getChargebackPayouts(chargebackId, urlParams);
    if (payoutResponse?.data) {
      setChargebackPayoutList(payoutResponse.data);
    }
    setIsListLoading(false);
  };
  const initPaymentTypeOptions = options => {
    if (Array.isArray(options)) {
      setPaymentTypeOptions(
        options.map(({ payment_type_id, payment_type }) => ({
          payment_type_id,
          payment_type,
        }))
      );
    }
  };
  const initPaymentStatusOptions = options => {
    if (Array.isArray(options)) {
      setPaymentStatusOptions(
        options.map(({ status_id, status }) => ({
          status_id,
          status,
        }))
      );
    }
  };
  useEffect(() => {
    if (chargebackId) {
      const promises = [fetchList()];
      if (Array.isArray(paymentTypeOptions) && !paymentTypeOptions.length)
        promises.push(getPaymentType(initPaymentTypeOptions));
      if (Array.isArray(paymentStatusOptions) && !paymentStatusOptions.length)
        promises.push(
          getStatusOptions(
            'ChargebackPayout',
            () => null,
            initPaymentStatusOptions
          )
        );
      Promise.all(promises).then(() => setIsListLoading(false));
    }
  }, [chargebackId, refreshList, tableDataState]);

  const createDateTemplate = rowData => {
    return isListLoading ? (
      <Skeleton />
    ) : rowData?.created_at ? (
      dateFormatter(rowData?.created_at, 'MM/DD/YYYY')
    ) : null;
  };
  const paymentDateTemplate = rowData => {
    return isListLoading ? (
      <Skeleton />
    ) : rowData?.payment_date ? (
      dateFormatter(rowData?.payment_date, 'MM/DD/YYYY')
    ) : null;
  };
  const emiDateTemplate = rowData => {
    return isListLoading ? (
      <Skeleton />
    ) : rowData?.emi_date ? (
      dateFormatter(rowData?.emi_date, 'MM/DD/YYYY')
    ) : null;
  };
  const paymentCategoryTemplate = rowData => {
    return isListLoading ? <Skeleton /> : rowData?.payment_category?.name;
  };
  const paymentTypeTemplate = rowData => {
    return isListLoading ? <Skeleton /> : rowData?.payment_type?.payment_type;
  };
  const paymentStatusTemplate = rowData => {
    return isListLoading ? <Skeleton /> : rowData?.payment_status?.status;
  };
  const debouncedOnChange = useCallback(debounce(fetchList, 500), []);

  // To render header
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
              debouncedOnChange(e.target.value);
            }}
            disabled={chargebackPayoutList?.count < 1}
          />
        </span>
        <div className="flex gap-4 align-items-center">
          <Button
            label="Clear"
            icon="pi pi-filter-slash"
            iconPos="left"
            outlined
            size="small"
            onClick={e => clearFilters(e)}
          />
          <div className="icon-round">
            <i
              className="icon pi pi-download cursor-pointer"
              onClick={e =>
                chargebackPayoutList?.count &&
                exportOverlayRef?.current?.toggle(e)
              }
              style={{
                opacity: chargebackPayoutList?.count || 0.5,
              }}
            />
            <OverlayPanel ref={exportOverlayRef}>
              <div className="flex flex-column gap-2">
                <CSVLink data={exportArr} filename={'chargeback-payouts'}>
                  <Button
                    size="small"
                    severity="primary"
                    outlined
                    onClick={exportCSV}
                  >
                    Export in CSV
                  </Button>
                </CSVLink>
                <Button color="primary" outlined onClick={exportPdf}>
                  Export in PDF
                </Button>
              </div>
            </OverlayPanel>
          </div>
          <div className="icon-round">
            <i
              className="icon pi pi-refresh cursor-pointer"
              onClick={() => fetchList()}
            />
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <div className="flex flex-row justify-content-between align-items-center p-3">
        <div className="text-xl font-bold">Chargeback Payout</div>
        <Button
          label="Collect Chargeback"
          size="small"
          onClick={() => {
            setSelectedPayout({});
            setShowPayoutDialog(true);
          }}
          disabled={isListLoading || disabledFields}
        />
      </div>
      <div className="card">
        <DataTable
          header={header}
          editMode="row"
          value={chargebackPayoutList?.count ? chargebackPayoutList.result : []}
          lazy
          dataKey="retainageId"
          paginator
          rows={tablePaginationState?.rows}
          rowsPerPageOptions={[5, 10]}
          onPage={event => {
            setTableDataState(prev => ({
              ...prev,
              page: event?.page + 1,
              limit: event?.rows,
            }));
            setTablePaginationState(prev => ({
              ...prev,
              first: event.first,
              rows: event.rows,
            }));
          }}
          first={tablePaginationState?.first}
          totalRecords={chargebackPayoutList?.count}
          onSort={event =>
            setTableDataState(prev => ({
              ...prev,
              sortField: event?.sortField,
              sortOrder: event?.sortOrder,
            }))
          }
          sortField={tableDataState?.sortField}
          sortOrder={tableDataState?.sortOrder}
        >
          <Column
            field="action"
            header="Action"
            body={actionTemplate}
            style={{ minWidth: '100px' }}
          />
          <Column
            field="created_at"
            body={createDateTemplate}
            header="Create Date"
            sortable
          />
          <Column
            field="payment_date"
            body={paymentDateTemplate}
            header="Payment Date"
            sortable
          />
          <Column
            field="emi_date"
            body={emiDateTemplate}
            header="Initiation Date"
            sortable
          />
          <Column
            field="payment_category"
            body={paymentCategoryTemplate}
            header="Payment Category"
            sortable
          />
          <Column
            field="payment_type"
            body={paymentTypeTemplate}
            header="Payment Type"
            sortable
          />
          <Column
            field="payment_status"
            body={paymentStatusTemplate}
            header="Payment Status"
            sortable
          />
          <Column
            field="payment_amount"
            header="Payment Amount"
            sortable
            body={rowData =>
              isListLoading ? (
                <Skeleton />
              ) : (
                formatCurrency(rowData?.payment_amount)
              )
            }
          />
          <Column
            field="payment_txn_id"
            header="Payment Transaction ID"
            sortable
            body={params =>
              isListLoading ? <Skeleton /> : params?.payment_txn_id
            }
          />
        </DataTable>
      </div>
      <Toast ref={toast} />
      {showPayoutDialog && (
        <>
          <PayoutForm
            chargebackId={chargebackId}
            visible={showPayoutDialog}
            setShowPayoutDialog={setShowPayoutDialog}
            chargebackPayout={selectedPayout}
            setRefreshList={setRefreshList}
            paymentTypeOptions={paymentTypeOptions}
            paymentStatusOptions={paymentStatusOptions}
          />
          <ConfirmDialog />
        </>
      )}
      <ConfirmDialog />
    </>
  );
};

export default Payout;
