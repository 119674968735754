import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';

import { checkUrlString } from '../../../../utils/Helpers';

const ActionButtons = ({
  action,
  widgetFormik,
  dashboardListData,
  saveLoading,
}) => {
  const user_id = localStorage.getItem('user_id');
  const history = useHistory();
  const onEdit = () => {
    const widgetId = window.location.pathname.split('/')[4];
    history.push(`/admin/widget/edit/${widgetId}`);
  };
  const checkEditPermission = obj => {
    if (!obj.editable) {
      return true;
    }

    if (obj.owner_user_id == user_id) {
      return true;
    }
    return false;
  };

  const currentPath = window?.location?.href;
  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      sm={6}
      justifyContent="flex-end"
      spacing={2}
      className="mt-1"
    >
      {action === 'create' && (
        <>
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                onChange={(event, value) => {
                  widgetFormik.setFieldValue('editable', value);
                }}
              />
            }
            label="Private"
            labelPlacement="end"
          />
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              multiple
              options={
                dashboardListData.items && dashboardListData.items.length
                  ? dashboardListData.items
                  : []
              }
              getOptionLabel={option => option && option.name}
              renderInput={params => (
                <TextField {...params} label="Assign To Dashboard" />
              )}
              onChange={(event, value) => {
                const dashIds =
                  value && value.length
                    ? value.map(obj => obj.dashboard_id)
                    : [];
                widgetFormik.setFieldValue('assignToDashBoardList', dashIds);
              }}
            />
          </Grid>
        </>
      )}
      {action === 'view' &&
        checkEditPermission(widgetFormik.values) &&
        !saveLoading && (
          <Grid item xs={12} md={6} className="sm:text-right">
            <Button color="primary" variant="contained" onClick={onEdit}>
              Edit
            </Button>
          </Grid>
        )}
      {(action === 'create' || action === 'edit') && (
        <>
          <Grid item xs={12} md={6} className="sm:text-right">
            <Button
              color="primary"
              className="mr-2"
              variant="outlined"
              onClick={() =>
                checkUrlString(currentPath, 'source=dashboard')
                  ? window.open('/dashboard', '_self', 'noopener,noreferrer')
                  : history.goBack()
              }
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={widgetFormik.handleSubmit}
              disabled={!widgetFormik?.dirty || !widgetFormik?.isValid}
            >
              Save
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ActionButtons;
