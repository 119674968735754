import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';
import { Message } from 'primereact/message';

import { REACT_APP_GMAPS_KEY } from '../../../../constants/envConstants';

import InstallerTabs from './InstallerTabs';
import RoutingMap from './RoutingMap';
import colors from '../../../../constants/color.constants';

const googleMapsApiKey = REACT_APP_GMAPS_KEY;

const InstallerModal = ({ selectedItem, widgetId, isArchiveTab }) => {
  const [dates, setDates] = useState(new Date(selectedItem.route_start_date));
  const [loading, setLoading] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = React.useState();
  const [filteredInstallers, setfilteredInstallers] = React.useState();
  const [defaultZoom, setDefaultZoom] = React.useState(10);
  const [showRoute, setShowRoute] = React.useState('hide');
  const [apiResponse, setApiResponse] = React.useState();

  const routeMap = useSelector(state => state.routeMap);

  const [checkedInstaller, setCheckedInstaller] = React.useState();
  const [selected, setSelected] = React.useState(null);

  const [defaultCenter, setDefaultCenter] = React.useState({
    lat: 28.820709,
    lng: -82.014584,
  });

  React.useEffect(() => {
    setFilteredCustomers([]);
    setfilteredInstallers([]);
  }, []);

  React.useEffect(() => {
    setCheckedInstaller(0);
    setShowRoute('show');
  }, [selectedItem]);

  React.useEffect(() => {
    if (routeMap?.data?.length) {
      const record = routeMap?.data[0];
      if (record?.start_location?.lat && record?.start_location?.lon) {
        setDefaultCenter({
          lat: parseFloat(record.start_location.lat),
          lng: parseFloat(record.start_location.lon),
        });
      }
    }
  }, [routeMap]);

  const tempArr = [];
  routeMap?.data?.forEach((record, index) => {
    if (record.job_location.lat && record.job_location.lon) {
      tempArr.push({
        Name: record.installer_name,
        job_sort_order: record.sort_order,
        job_id: record.job_id,
        load: record.load,
        location: {
          lat: parseFloat(record.job_location.lat),
          lon: parseFloat(record.job_location.lon),
        },
        customer_name: record.customer_name,
        address: record.job_address,
        store_number: record.store_number,
        date_schdeuled: record.date_scheduled,
        job_category: record.job_category,
        isInstallerStartLocation: record.isInstallerStartLocation,
        isInstallerEndLocation:
          routeMap?.data.length == index + 1 ? true : false,
        to_address: record.to_address,
      });
    }
  });
  if (tempArr.length == 1) {
    tempArr.push(tempArr[0]);
  }

  const content = (
    <div className="flex align-items-center">
      <i className="pi pi-info-circle text-lg"></i>
      <div className="ml-2">
        In order to provide you with an instant plan of how you should proceed,
        this search result will combine all other previously generated routes
        for each technician.
      </div>
    </div>
  );
  return (
    <>
      <div className="grid py-3 flex align-items-center">
        <div className="col-12 md:col-6 lg:col-3 py-0 mb-2">
          <span className="p-float-label">
            <Calendar
              showIcon
              value={dates}
              onChange={e => setDates(e.value)}
              minDate={new Date(selectedItem?.route_start_date)}
              maxDate={new Date(selectedItem?.route_end_date)}
              baseZIndex="1000000"
            />
            <label htmlFor="start_date">Search Data</label>
          </span>
        </div>
        <div className="col-12 md:col-6 lg:col-9 py-0">
          <Message severity="info" content={content} />
        </div>
        <div className="col-12 md:col-12">
          <div className="flex">
            <RoutingMap
              key={tempArr}
              googleMapURL={
                'https://maps.googleapis.com/maps/api/js?key=' +
                googleMapsApiKey +
                '&libraries=geometry,drawing,places'
              }
              places={filteredCustomers}
              installers={filteredInstallers}
              loadingElement={<div style={{ height: '100%', width: '100%' }} />}
              containerElement={
                <div style={{ height: '400px', width: '100%' }} />
              }
              mapElement={<div style={{ height: '100%', width: '100%' }} />}
              defaultCenter={{
                lat: defaultCenter?.lat || filteredCustomers[0]?.latitude,
                lng: defaultCenter?.lng || filteredCustomers[0]?.longitude,
              }}
              defaultZoom={8}
              showRoute={showRoute}
              apiResponse={
                tempArr.length
                  ? { routed_job_by_provider: { 1: tempArr } }
                  : undefined
              }
              checkedInstaller={checkedInstaller}
              setSelected={setSelected}
              colors={colors}
            />
          </div>
        </div>

        {selectedItem && dates && (
          <div className="col-12">
            <InstallerTabs
              key={dates}
              selectedItem={selectedItem}
              dates={dates}
              widgetId={widgetId}
              isArchiveTab={isArchiveTab}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InstallerModal;
