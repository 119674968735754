import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';

import { getDayWiseSlotsCount } from '../service/CxSchedulingConfigService';
import {
  addWorkHours,
  getWorkHours,
} from '../service/BusinessHourConfigService';
import { WORK_HOURS } from '../../../../constants';

import AddEditCxSchedulingSlotDialog from './AddEditCxSchedulingSlotDialog';

const CX_SCHEDULING_SLOTS = [
  {
    day: 'Monday',
    slots_count: 0,
  },
  {
    day: 'Tuesday',
    slots_count: 0,
  },
  {
    day: 'Wednesday',
    slots_count: 0,
  },
  {
    day: 'Thursday',
    slots_count: 0,
  },
  {
    day: 'Friday',
    slots_count: 0,
  },
  {
    day: 'Saturday',
    slots_count: 0,
  },
  {
    day: 'Sunday',
    slots_count: 0,
  },
];
const enabledClasses = 'cursor-pointer bg-green-500 hover:bg-green-700';
const disabledClasses = 'surface-700 pointer-events-none opacity-60';
const CxSchedulingSlotsComponent = ({ clientId }) => {
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const [cxSchedulingSlots, setCxSchedulingSlots] =
    useState(CX_SCHEDULING_SLOTS);
  const [isVisible, setIsVisible] = useState(false);
  const [addEditDialogContent, setAddEditDialogContent] = useState();
  const [workingDays, setWorkingDays] = useState([]);
  const toast = useRef(null);

  const callGetDayWiseSlotsCount = async (isInitial = false) => {
    setLoading(true);
    try {
      const response = await getDayWiseSlotsCount(clientId);
      if (
        response?.status &&
        Array.isArray(response?.data) &&
        response.data.length
      ) {
        const dayWiseCountMap = {};
        response.data.forEach(element => {
          dayWiseCountMap[element.day] = element.count;
        });
        setCxSchedulingSlots(
          cxSchedulingSlots.map(each => {
            return {
              ...each,
              slots_count: dayWiseCountMap[each.day] || each.slots_count,
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      !isInitial && setLoading(false);
    }
  };
  const findAndSetWorkingDays = data => {
    const workHourWorkingDays = [];
    data.forEach(eachDay => {
      if (eachDay.is_working) workHourWorkingDays.push(eachDay.day);
    });
    setWorkingDays(workHourWorkingDays);
  };
  const callGetWorkHours = async (isInitial = false) => {
    setLoading(true);
    try {
      const getApiResponse = await getWorkHours(clientId);
      if (getApiResponse?.status) {
        if (Array.isArray(getApiResponse.data) && getApiResponse.data.length) {
          findAndSetWorkingDays(getApiResponse.data);
        } else {
          const payload = { selected_days: WORK_HOURS };
          const addApiResponse = await addWorkHours(clientId, payload);
          if (
            addApiResponse?.status &&
            Array.isArray(addApiResponse.data) &&
            addApiResponse.data.length
          ) {
            findAndSetWorkingDays(addApiResponse.data);
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      !isInitial && setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      Promise.all([callGetDayWiseSlotsCount(true), callGetWorkHours(true)])
        .then()
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientId]);

  return (
    <Card title="Slots Configuration" className="mt-3">
      <Toast ref={toast} key={`ttoast`} />
      <div
        key={`dcontent`}
        className="flex flex-start align-items-center gap-5 flex-wrap"
      >
        {(Array.isArray(cxSchedulingSlots) ? cxSchedulingSlots : []).map(
          (eachDay, index) => (
            <>
              {loading ? (
                <Skeleton
                  key={`slot-load-${index}`}
                  width="7rem"
                  height="3rem"
                />
              ) : (
                <Avatar
                  key={`slot-avatar-${index}`}
                  label={eachDay?.day}
                  size="large"
                  onClick={
                    workingDays.includes(eachDay?.day)
                      ? () => {
                          setAddEditDialogContent(eachDay);
                          setIsVisible(true);
                        }
                      : () =>
                          toast.current.show({
                            severity: 'error',
                            summary: `${eachDay?.day} is business off`,
                            life: 1500,
                          })
                  }
                  className={`w-7rem p-overlay-badge text-white text-lg ${
                    workingDays.includes(eachDay?.day)
                      ? enabledClasses
                      : disabledClasses
                  }`}
                >
                  {workingDays.includes(eachDay?.day) ? (
                    <Badge
                      value={eachDay?.slots_count}
                      severity={eachDay?.slots_count ? 'primary' : 'secondary'}
                    ></Badge>
                  ) : (
                    <Badge value="off" severity="danger"></Badge>
                  )}
                </Avatar>
              )}
            </>
          )
        )}
      </div>

      {isVisible && (
        <AddEditCxSchedulingSlotDialog
          clientId={clientId}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          slotDetail={addEditDialogContent}
          setCxSchedulingSlots={setCxSchedulingSlots}
        />
      )}
    </Card>
  );
};

export default CxSchedulingSlotsComponent;
