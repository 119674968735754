import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '47%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },
  titleHeaderGrid: {
    padding: '10px 24px 10px 10px',
  },
}));
