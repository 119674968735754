import { Message } from 'primereact/message';
import { useState, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useStyles } from '../Admin/Auth/Login/Login.styles';
import apiService from '../../services/api.service';
import { URL_CONSTANTS } from '../../constants/urlConstants';

const OnboardRefresh = () => {
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const accountNumber = urlParams.get('acc');
  const classes = useStyles();

  const generateOnboardLink = async () => {
    const response = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CRM.generateOnboardLink}?acc=${accountNumber}`,
      null,
      URL_CONSTANTS.CRM.baseUrl
    );

    if (response && response.status && response.data) {
      window.location.href = response.data;
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateOnboardLink();
  }, []);

  return (
    <div
      className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
    >
      <div>
        <div className="text-center md:pt-5 pt-0">
          <img
            src="/images/PF-white-logo.png"
            alt="Project Force"
            className="mb-3"
            style={{ maxWidth: '180px' }}
          />
          <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
            ProjectsForce puts you in control of your work, keeping both you and
            your clients on-track towards completing the job and getting paid.{' '}
          </p>
          <div className="surface-1 text-600 text-center">
            {loading ? (
              <ProgressSpinner
                style={{ width: '50px', height: '50px' }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            ) : (
              <Message
                severity="info"
                text="Session has been expired, Please connect with PF admin"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardRefresh;
