import React from 'react';
import { ListBox } from 'primereact/listbox';

import PFDialog from '../../shared/PFPrime/PFDialog';

const SelectedJobCoverageValueList = ({
  coverageDialogVisible,
  setCoverageDialogVisible,
  selectedCoverageValues,
}) => {
  const columns = [
    { field: 'coverage_value', header: 'Selected Coverage Value' },
  ];
  return (
    <PFDialog
      header="Coverage Values"
      show={coverageDialogVisible}
      hide={() => setCoverageDialogVisible(false)}
      className="w-11 lg:w-4"
      BodyComponent={
        <ListBox
          listStyle={{ maxHeight: '250px' }}
          value={selectedCoverageValues}
          options={selectedCoverageValues.map(value => ({
            label: value,
            value,
          }))}
          optionLabel="label"
          className="w-full"
        />
      }
    ></PFDialog>
  );
};
export default SelectedJobCoverageValueList;
