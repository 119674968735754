import React, { useState } from 'react';

import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import ApiService from '../../../services/api.service';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

import { useStyles } from './ViewEditTemplate.styles';
import { applyInstallerVisibilityTemplates } from './Templates.service';

const ShowToCustomerCheckbox = ({ templateData, setAlert }) => {
  const [tempLoading, setTempLoading] = useState(false);
  const [signatureStatusLoading, setSignatureStatusLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({
    is_customer_visible: templateData?.is_customer_visible === 1 ? true : false,
  });
  const [isCheckedSignatureRequired, setIsCheckedSignatureRequired] = useState(
    templateData?.is_signature_required === 1 ? true : false
  );
  const classes = useStyles();
  const [reloadList, setReloadList] = useState(false);

  const handleCheckboxChange = async (event, value) => {
    setReloadList(false);
    setTempLoading(true);
    const queryParam = value;
    const payload = [
      {
        templateId: templateData?.template_id,
      },
    ];

    if (queryParam === 'customerProjectTemplates') {
      payload[0]['isCustomerProjectTemplateVisible'] = event.target.checked;
    }
    applyInstallerVisibilityTemplates(
      queryParam,
      payload,
      setTempLoading,
      setAlert,
      setReloadList
    ).then(res => {
      if (res?.status === 200) {
        setTempLoading(false);
        setIsChecked({
          ...isChecked,
          is_customer_visible: event.target.checked,
        });
      }
    });
  };

  const signatureRequiredChangeHandler = async (
    templateId,
    is_signature_required
  ) => {
    try {
      setSignatureStatusLoading(true);
      setIsCheckedSignatureRequired(is_signature_required);
      await ApiService.post(
        '/documents/templates/update-ready-signature-status',
        {
          templateId,
          is_signature_required: is_signature_required ? 1 : 0,
        }
      );
      setSignatureStatusLoading(false);
    } catch (error) {
      console.error(error);
      setSignatureStatusLoading(false);
    }
  };

  return (
    <div className="flex align-items-center mr-3 gap-2">
      {checkPermission(permissions?.documentCenter?.showToCustomer) && (
        <>
          <PFCheckbox
            color="primary"
            onChange={e => handleCheckboxChange(e, 'customerProjectTemplates')}
            disabled={tempLoading || isCheckedSignatureRequired}
            checked={
              isChecked?.is_customer_visible || isCheckedSignatureRequired
            }
          />
          <label>Show to Customer</label>
        </>
      )}

      {checkPermission(permissions?.documentCenter?.requireSignature) && (
        <>
          <PFCheckbox
            color="primary"
            onChange={event => {
              signatureRequiredChangeHandler(
                templateData?.template_id,
                event.target.checked
              );
              if (event.target.checked) {
                handleCheckboxChange(event, 'customerProjectTemplates');
              }
            }}
            disabled={tempLoading}
            checked={isCheckedSignatureRequired}
          />
          <label>Require Signature on CX Portal</label>
        </>
      )}
    </div>
  );
};

export default ShowToCustomerCheckbox;
