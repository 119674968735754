import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectCategory: [],
};

const projectCategoryMasterSlice = createSlice({
  name: 'projectCategoryMaster',
  initialState: initialState,
  reducers: {
    setProjectCategory(state, { payload }) {
      state.projectCategory = payload;
    },
  },
});

export const { setProjectCategory } = projectCategoryMasterSlice.actions;

export const projectCategoryReducer = projectCategoryMasterSlice.reducer;
