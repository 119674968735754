import { useRef } from 'react';
import { OverlayPanel, Toast } from 'primereact';
const TransactionIDSection = ({ params }) => {
  const stripeReqIdRef = useRef(null);
  const toast = useRef(null);
  return (
    <div>
      <p
        className="cursor-pointer"
        onMouseEnter={e => {
          stripeReqIdRef.current.toggle(e);
        }}
      >
        {params.transaction_number && (
          <i
            className="pi pi-info-circle mr-2 my-0"
            style={{ color: 'var(--primary-color)' }}
          ></i>
        )}
        {params.transaction_number}
      </p>

      <OverlayPanel ref={stripeReqIdRef}>
        <div>
          {params?.stripe_payment_request_id?.substring(0, 25 - 3) + '...'}
          <i
            className="pi pi-copy ml-2 cursor-pointer"
            style={{ color: 'var(--primary-color)' }}
            onClick={() => {
              navigator.clipboard.writeText(params.stripe_payment_request_id);
              toast?.current?.show({
                severity: 'success',
                summary: 'Copied',
                life: 2000,
              });
            }}
          ></i>
        </div>
      </OverlayPanel>
      <Toast ref={toast} />
    </div>
  );
};

export default TransactionIDSection;
