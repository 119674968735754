import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  SystemUpdateAlt as DownloadIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';

import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';

import { useStyles } from './Docs.styles.js';

import 'pdfjs-dist/build/pdf.worker.entry';
import { DOCUMENT_PHOTO_TYPE } from '../../../constants.js';
import PFButton from '../../shared/PFPrime/PFButton.js';
import { confirmDialog } from 'primereact/confirmdialog';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

export default function ThumbnailCard({
  docDetails,
  handleClick,
  handleFileDownload,
  handleInstallerVisiblity,
  handleOfflineAvailability,
  handleSelectAll,
  installerVisibleDocuments,
  offlineDocuments,
  relatedOfflineDocumentsMode = false,
  setInstallerVisibleDocuments,
  installerVisible,
  selectedJob,
  documentVisibleToInstaller,
  showDocStoreType,
  handleDocUpload,
  setPermissionCheckBox,
  permissionCheckBox,
  handlePermissionCheckBox,
  copyRelatedDoc,
  handleSelectRelatedDoc = null,
  handleDeleteDoc
}) {
  let documentName = '';
  try {
    if (docDetails) {
      if (docDetails.documetTypeId == 2)
        documentName = docDetails?.templateName;
      else documentName = docDetails?.documentName;
    }
  } catch (error) {}

  const [showDownload, setShowDownload] = useState(false);
  const classes = useStyles();
  const [isCheckBox, setIsCheckBox] = useState([]);

  const getDocStoreTypeName = type => {
    let response = null;
    if (type === 'INTERNAL') {
      response = 'Internal Document';
    } else if (type === 'SOURCE') {
      response = 'Send to Source';
    } else if (type === 'COMPLETE') {
      response = 'Complete';
    } else if (type === 'NEEDS_REVIEW') {
      response = 'Needs Review';
    }
    return response;
  };

  useEffect(() => {
    if (permissionCheckBox) {
      setIsCheckBox(permissionCheckBox);
    }
  }, [permissionCheckBox]);

  const [
    isSendPermission,
    isCompletePermission,
    isDocDeletePermission,
  ] = useMemo(
    () => [
      checkPermission(permissions?.documentCenter?.sendToSourceDoc),
      checkPermission(permissions?.documentCenter?.completeDoc),
      checkPermission(permissions?.documentCenter?.deleteDoc),
    ],
    [permissions]
  );
  const cardHTML = !docDetails ? null : (
    <Grid item>
      {showDocStoreType && (
        <Grid item classes={{ root: classes.storeTypeGrid }}>
          {docDetails?.documentStoreType ? (
            <>
              <Typography
                classes={{ root: classes.storeTypeLabel }}
                component="span"
                variant="subtitle1"
              >
                Status:
              </Typography>
              <Typography
                classes={{ root: classes.storeTypeText }}
                variant="body1"
                component="span"
              >{`${getDocStoreTypeName(docDetails?.documentStoreType)}`}</Typography>
            </>
          ) : (
            <Typography component="span" variant="subtitle1">
              &nbsp;
            </Typography>
          )}
        </Grid>
      )}

      <Card
        classes={{ root: classes.root }}
        onClick={() => handleClick(docDetails?.docTemplateId)}
      >
        <CardActionArea
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowDownload(true);
          }}
          onMouseLeave={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowDownload(false);
          }}
        >
          {!docDetails?.docTemplateId &&
            docDetails?.is_signature_required === 1 && (
              <IconButton classes={{ root: classes.signatureIcone }}>
                <img
                  src={
                    docDetails?.is_signed === 1
                      ? '/images/signature-green.svg'
                      : '/images/signature-grey.svg'
                  }
                  width={30}
                  height={30}
                  alt="signature"
                  style={{ zIndex: 999 }}
                />
              </IconButton>
            )}

          {showDownload && (
            <>
              <IconButton
                aria-label="download"
                size="medium"
                color="primary"
                classes={{ root: classes.downloadIcon }}
                onClick={e => {
                  e.stopPropagation();
                  handleFileDownload(docDetails.documentId);
                }}
              >
                <DownloadIcon color="primary" />
              </IconButton>
              {showDocStoreType && (
                <>
                  {isSendPermission && (
                    <Button
                      classes={{ root: classes.sendToSourceButton }}
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        e.stopPropagation();
                        handleDocUpload(
                          docDetails?.projectId,
                          docDetails?.documentId,
                          docDetails?.documentName,
                          'SOURCE'
                        );
                      }}
                      className="text-xs"
                    >
                      Send To Source
                    </Button>
                  )}
                  {isCompletePermission && (
                    <Button
                      classes={{ root: classes.completeButton }}
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        e.stopPropagation();
                        handleDocUpload(
                          docDetails?.projectId,
                          docDetails?.documentId,
                          docDetails?.documentName,
                          'COMPLETE'
                        );
                      }}
                      className="text-xs mt-2"
                    >
                      Complete
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          <Card>
            {docDetails?.documentUrl ? (
              <Document
                file={docDetails?.documentUrl}
                className={`${docDetails?.documentUrl ? 'doc-height' : 'doc-template'}`}
                error={
                  <CardMedia
                    classes={{ root: classes.media }}
                    className="doc-height"
                    image={docDetails?.documentThumbnail}
                    title={documentName}
                  />
                }
              >
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  height={165}
                />
              </Document>
            ) : (
              <CardMedia
                classes={{ root: classes.media }}
                image={docDetails?.documentThumbnail}
                title={documentName}
              />
            )}
            <CardContent classes={{ root: classes.docDetails }}>
              <Typography
                classes={{ root: classes.docName }}
                noWrap
                variant="body2"
              >
                {documentName}
              </Typography>
              <Typography variant="body2" noWrap>
                {docDetails.docCreatedDate}
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return docDetails ? (
    <Grid container classes={{ root: classes.singleDocContainer }}>
      {cardHTML}
      <Grid item container justifyContent="center" alignItems="center">
        {docDetails?.documetTypeId == 1 &&
          !relatedOfflineDocumentsMode &&
          installerVisible &&
          !documentVisibleToInstaller && (
            <Tooltip title="View as Technician">
              <IconButton
                // classes={{ root: classes.actionIcons }}
                // className={classes.viewAction}
                classes={{
                  root: classes.checkboxRoot,
                }}
              >
                <VisibilityIcon
                  onClick={event => {
                    handleInstallerVisiblity(docDetails?.documentId);
                  }}
                  fontSize="large"
                  color={
                    installerVisibleDocuments?.filter(
                      doc =>
                        doc.documentId === docDetails?.documentId &&
                        doc.isVisibleToInstaller
                    ).length > 0
                      ? 'primary'
                      : ''
                  }
                />
              </IconButton>
            </Tooltip>
          )}
        {(relatedOfflineDocumentsMode
          ? selectedJob?.project_type == 'Measurement' && installerVisible
          : installerVisible) && (
          <Tooltip
            title={
              relatedOfflineDocumentsMode &&
              offlineDocuments?.filter(
                doc => doc.isRelatedProjectDoucmentOffine
              ).length >= 2
                ? 'Only 2 documents are allowed to select '
                : 'Visible Offline'
            }
          >
            <Grid>
              <IconButton
                // classes={{ root: classes.actionIcons }}
                // className={classes.viewAction}
                disabled={
                  relatedOfflineDocumentsMode &&
                  offlineDocuments?.filter(
                    doc => doc.isRelatedProjectDoucmentOffine
                  ).length >= 2 &&
                  !(
                    offlineDocuments?.filter(
                      doc =>
                        docDetails.documentId === doc.documentId &&
                        doc.isRelatedProjectDoucmentOffine
                    )?.length > 0
                  )
                }
              >
                <CloudDownloadIcon
                  onClick={event => {
                    handleOfflineAvailability(
                      docDetails?.documentId,
                      relatedOfflineDocumentsMode
                    );
                  }}
                  fontSize="large"
                  color={
                    relatedOfflineDocumentsMode
                      ? offlineDocuments?.filter(
                          doc =>
                            doc.documentId === docDetails?.documentId &&
                            doc.isRelatedProjectDoucmentOffine
                        ).length > 0
                        ? 'primary'
                        : ''
                      : offlineDocuments?.filter(
                            doc =>
                              doc.documentId === docDetails?.documentId &&
                              doc.isProjectDoucmentOffine
                          ).length > 0
                        ? 'primary'
                        : ''
                  }
                  disabled={
                    relatedOfflineDocumentsMode &&
                    offlineDocuments?.filter(
                      doc => doc.isRelatedProjectDoucmentOffine
                    ).length >= 2
                  }
                />
              </IconButton>
            </Grid>
          </Tooltip>
        )}
        {handleSelectRelatedDoc && (
          <>
            <Tooltip title={`Copy`}>
              <Checkbox
                color="primary"
                onChange={event =>
                  handleSelectRelatedDoc(event, docDetails?.documentId)
                }
                checked={copyRelatedDoc?.copiedIds?.includes(
                  docDetails?.documentId
                )}
                pt={{
                  box: {
                    className: 'border-noround w-10',
                  },
                }}
              />
            </Tooltip>
          </>
        )}

        {installerVisible && (
          <Tooltip
            title={
              isCheckBox?.some(
                doc =>
                  doc?.documentId === docDetails?.documentId &&
                  doc?.isCustomerProjectDoucmentVisible
              )
                ? 'Hide from customer'
                : 'Show to customer'
            }
          >
            <Grid>
              <Checkbox
                color="primary"
                onChange={(event, value) => {
                  handlePermissionCheckBox(docDetails?.documentId);
                }}
                disabled={docDetails?.is_signed === 1 ? true : false}
                checked={isCheckBox?.some(
                  doc =>
                    doc?.documentId === docDetails?.documentId &&
                    doc?.isCustomerProjectDoucmentVisible
                )}
              />
            </Grid>
          </Tooltip>
        )}
        {isDocDeletePermission && docDetails?.documentId && 1 === docDetails?.documetTypeId && (
          <Tooltip title="Delete">
            <Grid>
              <PFButton
                severity=" "
                icon="pi pi-trash"
                text
                rounded
                onClick={() => {
                  confirmDialog({
                    message: `This action would delete this attachment and cannot be rolled back. Would you like to continue?`,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => handleDeleteDoc(docDetails?.projectId, docDetails?.documentId),
                  });
                }}
              />
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Card
        classes={{ root: classes.add }}
        className={`${
          checkPermission(permissions.viewEditProject.tabDocsAddProjectDocs)
            ? 'cursor-pointer'
            : 'cursor-auto'
        }`}
      >
        <CardContent classes={{ root: classes.addIcon }}>
          {checkPermission(
            permissions.viewEditProject.tabDocsAddProjectDocs
          ) && <AddIcon fontSize="large" className={classes.plusIcon} />}
        </CardContent>
      </Card>
    </Grid>
  );
}
