import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import {
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  Modal,
  Button,
  TextField,
  DialogContentText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import rrulePlugin from '@fullcalendar/rrule';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { Avatar } from 'primereact/avatar';
import {
  ConfirmDialog,
  confirmDialog as primeReactConfirmDialog,
} from 'primereact/confirmdialog';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';

import GenericConfirmationDialog from '../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { SCHEDULER_CARD_VIEW_OPTIONS } from '../Admin/NewConfiguration/company-config.constant';

// **** Services *****
import { useAlerts } from '../shared/Alerts/alertsService';

import { getCalendarData, updateInstallerSchedule } from './Scheduler.service';
import { getAllInstallersList } from './new-scheduler/service/new-scheduler.service';

// **** Styles/Images/Icons ****
import { useStyles } from './CalenderContainer.styles';

let eventColors = [
  { bgColor: '#FFECEA', textColor: '#FF7F00' },
  { bgColor: '#DCFFDC', textColor: '#009500' },
  { bgColor: '#F3EDFF', textColor: '#4D00FF' },
  { bgColor: '#FFFAE5', textColor: '#FF2700' },
  { bgColor: '#ADD8E6', textColor: '#333333' },
];

const EVENT_TYPES = {
  BLOCK: 'blockedTime',
  TIME_OFF: 'timeoff',
};
import {
  CX_SCHEDULED_STATUS,
  DEFAULT_COLORS_BY_PROJECT_TYPE,
  TEXT_COLOR_BLACK,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserAction,
  scheduleInfoUserCustomAction,
} from '../../constants';

import InstallerViewBlockCalendar from './installer-view-block-calendar';

import { setInstallerViewCalendar } from '../../redux/slices/installer-view-calendar.slice';
import { addNotes } from '../ProjectManagement/Notes/Notes.service';
import GenericDialog from '../shared/Dialog/GenericDialog';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import ProfileIcon from './ProfileIcon';

import {
  checkPermission,
  doesKeyExistInArray,
  getDefaultTechnicianSchedulerView,
  getPercentageValue,
  getUniqueArrayItemsByKey,
  selectedRapidSlot,
} from '../../utils/Helpers';
import permissions from '../../config/permissions';
import { getSystemTenants } from '../Admin/Configuration/RestrictAccessbyRole.service';

import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';

import PFInputNumber from '../shared/PFPrime/PFInputNumber';

import { Tooltip as PrimeTooltip } from 'primereact/tooltip';

const mapProjectTypeToColor = (
  colorsByProjectType,
  projectType,
  projectBusted,
  color_code = ''
) => {
  if (projectBusted === 'Busted') {
    return {
      bgColor: DEFAULT_COLORS_BY_PROJECT_TYPE?.Busted,
    };
  } else {
    if (color_code) {
      return {
        bgColor: color_code,

        textColor: TEXT_COLOR_BLACK,
      };
    }
    if (colorsByProjectType.hasOwnProperty(projectType)) {
      return {
        bgColor: colorsByProjectType[projectType],

        textColor: TEXT_COLOR_BLACK,
      };
    }

    return eventColors[1]; // default
  }
};

const CalenderContainer = ({
  viewEditProjectDetails,
  calendarFilterData,
  setCalendarFilterData,
  setReloadList,
  projectDataLoading,
  calendarFullScreen,
  setCalendarFullScreen,
  reloadCalender,
  setSelected,
  reportData,
  statusOptions,
  filterCalenderData,
  setFilterCalenderData,
  setSelectedTypeIds,
  setSelectedTypeCoverageIds,
  setSelectedCategoryIds,
  setSelectedStoreIds,
  setTeamValue,
  setChildGetParms,
  setChildGetCalendarData,
  calendarDataResetData,
  setSelectedStoreView,
  schedulerLoader,
  installerOptions,
}) => {
  const classes = useStyles();
  const calendarRef = useRef();
  const [calendarData, setCalendarData] = React.useState([]);
  //const [eventData, setEventData] = React.useState([]);
  const [reloadCalendar, setReloadCalendar] = React.useState(false);
  const [calendarLoading, setCalendarLoading] = React.useState(false);
  const [, setEventLoading] = React.useState(false);
  const { setAlert } = useAlerts();
  const [infoData, setInfoData] = React.useState();
  const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [calenderParams, setCalenderParams] = React.useState(null);
  const [dropModalValue, setDropModalValue] = useState(false);
  const [tableRowDropData, setTableRowDropData] = useState('');
  const [dropData, setDropData] = useState('');
  const [statusId, setStatusId] = useState(7);
  const [colorsByProjectType, setColorsByProjectType] = useState(
    DEFAULT_COLORS_BY_PROJECT_TYPE
  );
  let calendarApi;
  const dispatch = useDispatch();
  const { installersList } = useSelector(state => state.installerViewCalendar);
  const [dialogSettings, setDialogSettings] = useState({
    title: 'Reschedule Confirmation',
    button1Text: 'Skip',
    button2Text: 'Continue',
    showButton1: true,
    showButton2: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [startDateStr, setStartDateStr] = useState('');
  const [endDateStr, setEndDateStr] = useState('');
  const [infoValue, setInfoValue] = useState({});
  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartOrEndDateInvalid, setIsStartOrEndDateInvalid] = useState(false);
  const [technicianAction, setTechnicianAction] = useState(null);

  const [cxSchedulerIcon, setCxSchedulerIcon] = useState(null);
  const [isBlockTime, setIsBlockTime] = useState(false);
  const [schedulePercentage, setSchedulePercentage] = useState(0);
  const [schedulerCardView, setSchedulerCardView] = useState(null);
  const toast = useRef(null);
  useEffect(() => {
    const clientDetails = localStorage.getItem('client_details');
    if (clientDetails) {
      try {
        const Config = JSON.parse(clientDetails);
        if (Config?.cx_scheduling_module && Config?.job_identifier)
          setCxSchedulerIcon(Config?.job_identifier);
        if (
          Config?.scheduler_config.calender_view &&
          Config?.scheduler_config.calender_view?.card_visibility
        ) {
          setSchedulerCardView(
            Config?.scheduler_config.calender_view?.card_visibility
          );
        }
      } catch (ex) {}
    }
    setColorsByProjectType(colorsByProjectType);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const timeDiff = endDate.getTime() - startDate.getTime();
      if (timeDiff <= 0) {
        setIsStartOrEndDateInvalid(true);
      } else {
        setIsStartOrEndDateInvalid(false);
      }
    } else {
      setIsStartOrEndDateInvalid(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    try {
      getSystemTenants().then(clientConfiguration => {
        if (clientConfiguration) {
          const { included_permissions, allocation_percentage } =
            clientConfiguration.tenant_permissions[0];

          const allocateItemKeys = Object.keys(scheduleInfoUserAction);
          const alreadyAllocatePermission = included_permissions?.filter(perm =>
            allocateItemKeys.includes(perm)
          );
          setTechnicianAction(
            scheduleInfoUserAction[alreadyAllocatePermission?.[0]]
          );
          setSchedulePercentage(allocation_percentage);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    if (!schedulerLoader) {
      let draggableEl = document.getElementsByClassName('MuiTableBody-root');
      new Draggable(draggableEl[0], {
        itemSelector: '.MuiTableRow-root',
        eventData: function (eventEl) {
          return {
            title: eventEl.getAttribute('data-description'),
            id: eventEl.getAttribute('data-event'),
            create: false,
          };
        },
      });
    }
  }, [schedulerLoader]);

  React.useEffect(async () => {
    if (Object.values(filterCalenderData)?.some(value => value?.length > 0)) {
      const params = {
        date: calendarApi
          ? moment(calendarApi?.getDate()).format()
          : moment().format(),
        period: calendarApi ? getPeriod(calendarApi?.view?.type) : 'D',
      };
      await getCalendarData(
        params,
        setCalendarData,
        setCalendarLoading,
        setAlert,
        filterCalenderData
      );
    }
  }, [filterCalenderData]);

  React.useEffect(async () => {
    let params = {
      date: calendarApi
        ? moment(calendarApi?.getDate()).format()
        : moment().format(),
      period: calendarApi ? getPeriod(calendarApi?.view?.type) : 'D',
    };
    if (calendarFilterData) {
      params.projectTypeIds = calendarFilterData?.project_type_id || null;
      params.projectCategoryId =
        calendarFilterData?.project_category_id || null;
      params.projectStoreId = calendarFilterData?.project_store_id || null;
      if (calendarFilterData?.isJobCoverage)
        params.isJobCoverage = calendarFilterData?.isJobCoverage;
    }
    setCalenderParams(params);
    await getCalendarData(
      params,
      setCalendarData,
      setCalendarLoading,
      setAlert,
      filterCalenderData
    );
    setChildGetParms(params);
  }, [calendarFilterData, reloadCalender]);

  React.useEffect(() => {
    if (calendarDataResetData?.length > 0) {
      setCalendarData(calendarDataResetData);
    }
  }, [calendarDataResetData]);

  //Mapping calendardata to eventData and Resource Data

  // Grey boxes for approved time off
  let TimeoffApprovedEvents =
    calendarData
      ?.filter(
        cal =>
          cal.time_off_start_date &&
          cal.time_off_end_date &&
          cal.status_id === 88 &&
          cal.is_blocked_time !== 1
      )
      .reduce((filter, current) => {
        var dk = filter.find(
          item =>
            item.time_off_start_date === current.time_off_start_date &&
            item.time_off_end_date === current.time_off_end_date &&
            item.project_number === current.project_number
        );
        if (!dk) {
          return filter.concat([current]);
        } else {
          return filter;
        }
      }, [])

      .map((val, index) => {
        let eventColor =
          eventColors[Math.floor(Math.random() * eventColors?.length)];
        if (val.time_off_start_date && val.time_off_end_date) {
          const time_off_start_date = val.time_off_start_date;
          const time_off_end_date = val.time_off_end_date;
          const unformatedEndDate = moment(time_off_start_date);
          const endDateHour = moment(time_off_end_date).get('hour');
          const endDateMinute = moment(time_off_end_date).get('minute');
          const endDate = unformatedEndDate.set({
            hour: endDateHour,
            minute: endDateMinute,
          });

          const startDate = moment(time_off_start_date);
          const durationInHour = moment.duration(endDate.diff(startDate));
          const splitHours = durationInHour.asHours().toString().split('.');
          const unformattedHours = splitHours[0];
          let minute = '00';

          if (splitHours.length > 1) {
            minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
          }
          const duration = unformattedHours
            .padStart(2, '0')
            .concat(':', minute);

          const availabilityUpdate = {
            title: val.time_off_note || '',
            resourceId: val.installer_id,
            backgroundColor: 'rgb(161, 161, 161, 0.4)',
            textColor: eventColor.textColor,
            border: 'none',
            installer_id: val.installer_id,
            project_id: val.project_id,
            start: moment(val.time_off_start_date).format(),
            end: moment(val.time_off_end_date).format(),
            allDay: val.full_day == 1 ? true : false,
            eventType: 'timeoff',
            rrule: {
              freq: 'weekly',
              interval: val?.is_recurring_every_other_week,
              byweekday: [
                ...(val.sun ? ['su'] : []),
                ...(val.mon ? ['mo'] : []),
                ...(val.tue ? ['tu'] : []),
                ...(val.wed ? ['we'] : []),
                ...(val.thus ? ['th'] : []),
                ...(val.fri ? ['fr'] : []),
                ...(val.sat ? ['sa'] : []),
              ],
              until: moment(val.time_off_end_date).format(),
              dtstart: moment(val.time_off_start_date).format(),
            },
            overlap: true,
            isDisabled: true,
            duration,
            timeOffRequestId: val?.user_time_off_request_id,
            is_customer_requested: val.is_customer_requested,
          };
          return availabilityUpdate;
        }
      }) || [];

  // Grey lines for tentative time off
  let TimeoffTentativeEvents =
    calendarData
      ?.filter(
        cal =>
          cal.time_off_start_date &&
          cal.time_off_end_date &&
          cal.status_id === 86 &&
          cal.is_blocked_time !== 1
      )
      .reduce((filter, current) => {
        var dk = filter.find(
          item =>
            item.time_off_start_date === current.time_off_start_date &&
            item.time_off_end_date === current.time_off_end_date &&
            item.project_number === current.project_number
        );
        if (!dk) {
          return filter.concat([current]);
        } else {
          return filter;
        }
      }, [])

      .map((val, index) => {
        let eventColor =
          eventColors[Math.floor(Math.random() * eventColors?.length)];
        if (val.time_off_start_date && val.time_off_end_date) {
          const time_off_start_date = val.time_off_start_date;
          const time_off_end_date = val.time_off_end_date;
          const unformatedEndDate = moment(time_off_start_date);
          const endDateHour = moment(time_off_end_date).get('hour');
          const endDateMinute = moment(time_off_end_date).get('minute');
          const endDate = unformatedEndDate.set({
            hour: endDateHour,
            minute: endDateMinute,
          });

          const startDate = moment(time_off_start_date);
          const durationInHour = moment.duration(endDate.diff(startDate));
          const splitHours = durationInHour.asHours().toString().split('.');
          const unformattedHours = splitHours[0];
          let minute = '00';

          if (splitHours.length > 1) {
            minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
          }
          const duration = unformattedHours
            .padStart(2, '0')
            .concat(':', minute);
          return {
            //id: val.project_id,
            title: val.time_off_note || '',
            resourceId: val.installer_id,
            background:
              'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
            textColor: eventColor.textColor,
            border: 'none',
            installer_id: val.installer_id,
            project_id: val.project_id,
            start: moment(val.time_off_start_date).format(),
            end: moment(val.time_off_end_date).format(),
            allDay: val.full_day == 1 ? true : false,
            eventType: 'timeoff',
            overlap: true,
            classNames: [classes.tentativeTimeOff],
            rrule: {
              freq: 'weekly',
              interval: val?.is_recurring_every_other_week,
              byweekday: [
                ...(val.sun ? ['su'] : []),
                ...(val.mon ? ['mo'] : []),
                ...(val.tue ? ['tu'] : []),
                ...(val.wed ? ['we'] : []),
                ...(val.thus ? ['th'] : []),
                ...(val.fri ? ['fr'] : []),
                ...(val.sat ? ['sa'] : []),
              ],
              until: moment(val.time_off_end_date).format(),
              dtstart: moment(val.time_off_start_date).format(),
            },
            duration,
            isDisabled: true,
            timeOffRequestId: val?.user_time_off_request_id,
          };
        }
      }) || [];

  let blockedEvents =
    calendarData?.length > 0 &&
    calendarData
      ?.filter(val => val?.is_blocked_time === 1)
      ?.map(val => {
        const eventColor = eventColors[4];
        return {
          title: val?.time_off_note || '',
          resourceId: val?.installer_id,
          backgroundColor: eventColor?.bgColor,
          textColor: eventColor?.textColor,
          start: moment(val?.time_off_start_date).format(),
          end: moment(val?.time_off_end_date).format(),
          allDay: val?.full_day === 1 ? true : false,
          eventType: EVENT_TYPES.BLOCK,
          overlap: true,
          isDisabled: true,
          installerId: val?.installer_id,
          timeOffRequestId: val?.user_time_off_request_id,
          editable: false,
          durationEditable: false,
          is_customer_requested: val.is_customer_requested,
        };
      });

  let eventData =
    calendarData?.map((val, index) => {
      let eventColor = eventColors[1];
      if (!!val?.project_type && !!colorsByProjectType) {
        eventColor = mapProjectTypeToColor(
          colorsByProjectType,
          val?.project_type,
          val?.project_status,
          val?.color_code
        );
      }
      return {
        id: val.project_id,
        title: val.project_desc || '',
        resourceId: val.installer_id,
        backgroundColor: eventColor.bgColor,
        textColor: eventColor.textColor,
        border: 'none',
        installer_id: val.installer_id,
        project_id: val.project_id,
        start: val.schedule_date,
        end: val.schedule_end_date,
        project_desc: val.project_desc,
        store_number: val.store_number,
        project_type: val.project_type,
        source_system_id: val.source_system_id,
        category: val.category,
        project_status: val.project_status,
        project_number: val.project_number,
        client_name: val.client_name,
        Time:
          moment(val.schedule_date).format('hh:mm A') +
          ' - ' +
          moment(val.schedule_end_date).format('hh:mm A'),
        mobile_phone: val.mobile_phone,
        address: val.address,
        timeoff_status: val.status_id,
        time_off_start_date: val.time_off_start_date,
        time_off_end_date: val.time_off_end_date,
        total_sale_amount: val?.total_sale_amount,
        total_revenue: val?.total_revenue,
        confirmation_status: val?.confirmation_status,
        is_customer_requested: val.is_customer_requested,
        overlap: true,
        background:
          'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
      };
    }) || [];

  let resourceData =
    calendarData
      ?.sort((a, b) => {
        const titleA = a?.installer_name?.toLowerCase()?.trim();
        const titleB = b?.installer_name?.toLowerCase()?.trim();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      })
      ?.map((val, index) => {
        return {
          id: val.installer_id,
          is_customer_requested: val.is_customer_requested,
          title: val?.installer_name?.trim(),
          email: val?.installer_email,
          phone: val?.installer_phone,
          companyname: val?.companyname,
          avtarIcon: val?.installer_profile_image_url,
          sortOrder:
            val.installer_name === 'Unassigned Technician' ? 0 : index + 1,
          installerbio: val?.installerbio,
        };
      }) || [];

  eventData = [
    ...eventData,
    ...getUniqueArrayItemsByKey(TimeoffApprovedEvents, 'timeOffRequestId'),
    ...getUniqueArrayItemsByKey(TimeoffTentativeEvents, 'timeOffRequestId'),
    ...getUniqueArrayItemsByKey(blockedEvents, 'timeOffRequestId'),
  ];

  function renderEventContent(eventInfo) {
    if (
      eventInfo?.event?.extendedProps?.eventType === 'timeoff' ||
      eventInfo?.event?.extendedProps?.eventType === 'blockedTime'
    ) {
      return (
        <Tooltip
          title={renderBlockTimeTooltip(eventInfo)}
          arrow
          placement="top"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="h-6rem"
          >
            <Grid
              item
              container
              justifyContent="center"
              className={classes.eventLoadingIndicator}
              id={`eventLoading-${eventInfo.event.id}`}
            >
              <CircularProgress />
            </Grid>
            <Grid item xs={12} className={`event-${eventInfo.event.id}`}>
              <Typography noWrap align="center" spacing={2}>
                {eventInfo?.event?.extendedProps.eventType !== 'timeoff' &&
                  eventInfo?.event?.extendedProps.eventType !== 'blockedTime' &&
                  eventInfo.timeText}
              </Typography>
              <Typography noWrap className={classes.title} spacing={2}>
                {eventInfo.event.title || ''}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={renderTooltipContent(
          eventInfo.event.extendedProps,
          eventInfo?.event?.extendedProps?.Time
        )}
        arrow
        placement="right"
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            className={classes.eventLoadingIndicator}
            id={`eventLoading-${eventInfo.event.id}`}
          >
            <CircularProgress />
          </Grid>
          <Grid item xs={12} className={`event-${eventInfo.event.id}`}>
            {eventInfo?.event?.extendedProps?.project_status ===
              CX_SCHEDULED_STATUS ||
            (!isNaN(eventInfo?.event?.extendedProps?.is_customer_requested) &&
              eventInfo?.event?.extendedProps?.is_customer_requested > 0) ? (
              <div className="flex w-full align-items-center justify-content-center mb-2">
                <i
                  className={`${cxSchedulerIcon}`}
                  style={{ fontSize: '1.8rem' }}
                ></i>
              </div>
            ) : null}

            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.description
            ) ? (
              <Typography
                noWrap
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Description :{' '}
                {eventInfo?.event?.extendedProps?.project_desc &&
                  ` ${eventInfo?.event?.extendedProps?.project_desc}`}
              </Typography>
            ) : null}
            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.time
            ) ? (
              <Typography
                noWrap
                align="center"
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Time :{' '}
                {eventInfo?.event?.extendedProps?.Time &&
                  ` ${eventInfo?.event?.extendedProps?.Time}`}
              </Typography>
            ) : null}
            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.store
            ) ? (
              <Typography
                noWrap
                align="center"
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Store #{' '}
                {eventInfo?.event?.extendedProps?.store_number &&
                  ` ${eventInfo?.event?.extendedProps?.store_number}`}
              </Typography>
            ) : null}
            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.project_type
            ) ? (
              <Typography
                noWrap
                align="center"
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Type :{' '}
                {eventInfo?.event?.extendedProps?.project_type &&
                  ` ${eventInfo?.event?.extendedProps?.project_type}`}
              </Typography>
            ) : null}

            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.category
            ) ? (
              <Typography
                noWrap
                align="center"
                spacing={2}
                className={classes.title}
                style={{ fontSize: '10px', margin: '-9px' }}
              >
                Category :{' '}
                {eventInfo?.event?.extendedProps?.category &&
                  `${eventInfo?.event?.extendedProps?.category}`}
              </Typography>
            ) : null}
            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.status
            ) ? (
              <Typography
                noWrap
                align="center"
                spacing={2}
                className={classes.title}
                style={{ fontSize: '10px', margin: '-9px' }}
              >
                Status :{''}{' '}
                {eventInfo?.event?.extendedProps?.project_status &&
                  `${eventInfo?.event?.extendedProps?.project_status}`}
              </Typography>
            ) : null}

            {checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) ? (
              <>
                {doesKeyExistInArray(
                  schedulerCardView,
                  SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_sale
                ) ? (
                  <Typography
                    noWrap
                    align="center"
                    spacing={2}
                    className={classes.title}
                    style={{ fontSize: '10px', margin: '-7px' }}
                  >
                    Total Sale $ :{' '}
                    {eventInfo?.event?.extendedProps?.total_sale_amount &&
                      `$ ${eventInfo?.event?.extendedProps?.total_sale_amount}`}
                  </Typography>
                ) : null}
                {doesKeyExistInArray(
                  schedulerCardView,
                  SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_revenue
                ) ? (
                  <Typography
                    noWrap
                    align="center"
                    spacing={2}
                    className={classes.title}
                    style={{ fontSize: '10px', margin: '-7px' }}
                  >
                    Total Revenue $ :{' '}
                    {eventInfo?.event?.extendedProps?.total_revenue &&
                      `$ ${eventInfo?.event?.extendedProps?.total_revenue}`}
                  </Typography>
                ) : null}
              </>
            ) : null}

            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.project_number
            ) ? (
              <Typography
                noWrap
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Project No :{' '}
                {eventInfo?.event?.extendedProps?.project_number &&
                  `${eventInfo?.event?.extendedProps?.project_number}`}
              </Typography>
            ) : null}
            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.client_name
            ) ? (
              <Typography
                noWrap
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-7px' }}
              >
                Client Name :{' '}
                {eventInfo?.event?.extendedProps?.client_name &&
                  `${eventInfo?.event?.extendedProps?.client_name}`}
              </Typography>
            ) : null}

            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.address
            ) ? (
              <Typography
                noWrap
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-9px' }}
              >
                Address :{' '}
                {eventInfo?.event?.extendedProps?.address &&
                  `${eventInfo?.event?.extendedProps?.address}`}
              </Typography>
            ) : null}

            {doesKeyExistInArray(
              schedulerCardView,
              SCHEDULER_CARD_VIEW_OPTIONS[1].type.phone
            ) ? (
              <Typography
                noWrap
                className={classes.title}
                spacing={2}
                style={{ fontSize: '10px', margin: '-9px' }}
              >
                Phone :{' '}
                {eventInfo?.event?.extendedProps?.mobile_phone &&
                  `${eventInfo?.event?.extendedProps?.mobile_phone}`}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Tooltip>
    );
  }

  function renderResourceContent(eventInfo) {
    const fullName = eventInfo?.fieldValue?.split(' ');
    const firstLetters = fullName
      ?.map(word => word?.charAt(0))
      ?.join(' ')
      ?.toUpperCase();

    const [firstWord, secondWord] = firstLetters?.split(' ', 2);
    const nameLeffters = [firstWord, secondWord];

    return (
      <>
        <div className="grid w-12 m-0 technicians-info">
          <div
            className={`grid w-12 m-0 align-items-center scrollable-container ${eventInfo?.resource?._resource?.extendedProps?.installerbio ? 'cursor-pointer' : 'cursor-auto'}`}
          >
            {eventInfo?.resource?._resource?.extendedProps?.installerbio && (
              <div className="installerbio-tooltip">
                <div className="description">
                  {eventInfo?.resource?._resource?.extendedProps?.installerbio}
                </div>
              </div>
            )}
            <div
              className="col-12 md:col-6 lg:col-1 p-0 "
              style={{ width: '35px' }}
            >
              <ProfileIcon eventInfo={eventInfo} nameLeffters={nameLeffters} />
            </div>
            <div className={`${classes.installerName} mt-0 col-8`}>
              {eventInfo.fieldValue}
            </div>
          </div>
          <div className={`${classes.installerName} w-12`}>
            {eventInfo?.resource?._resource?.extendedProps?.email}
          </div>
          <div className={`${classes.installerName} w-12`}>
            {eventInfo?.resource?._resource?.extendedProps?.phone}
          </div>
          <div className={`${classes.installerName} w-12`}>
            Company Name:{' '}
            {eventInfo?.resource?._resource?.extendedProps?.companyname}
          </div>
        </div>
      </>
    );
  }

  const renderTooltipContent = (eventInfo, time) => {
    return (
      <Grid container>
        {time && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Time</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography noWrap className={classes.detail}>
              {time}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.store_number && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Store #</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.store_number}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.client_name && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>
              Client Name{' '}
            </Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.client_name}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.category && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Category</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.category}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.address && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Address</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.address}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.total_sale_amount &&
          checkPermission(
            permissions?.projectItems?.viewProjectLevelCostField
          ) && (
            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabel}>
                Total Sale $
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {`$ ${eventInfo?.total_sale_amount}`}{' '}
              </Typography>
            </Grid>
          )}
        {eventInfo?.total_revenue &&
          checkPermission(
            permissions?.projectItems?.viewProjectLevelCostField
          ) && (
            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabel}>
                Total Revenue $
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {`$ ${eventInfo?.total_revenue}`}{' '}
              </Typography>
            </Grid>
          )}
        {eventInfo?.project_desc && (
          <Grid
            item
            container
            alignItems="stretch"
            xs={12}
            direction="row"
            m={4}
          >
            <Typography className={classes.detailLabel}>Description</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography noWrap className={classes.detail}>
              {eventInfo?.project_desc}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.project_type && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Type</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.project_type}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.project_status && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Status</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.project_status}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.project_number && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Project No</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.project_number}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.mobile_phone && (
          <Grid container item xs={12} direction="row" m={4}>
            <Typography className={classes.detailLabel}>Phone</Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography className={classes.detail}>
              {eventInfo?.mobile_phone}{' '}
            </Typography>
          </Grid>
        )}
        {eventInfo?.confirmation_status && (
          <Grid
            item
            container
            alignItems="stretch"
            xs={12}
            direction="row"
            m={4}
          >
            <Typography className={classes.detailLabel}>
              Confirmation Status
            </Typography>
            <Typography className={classes.colon}>:</Typography>
            <Typography noWrap className={classes.detail}>
              {eventInfo?.confirmation_status}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderBlockTimeTooltip = eventInfo => {
    return <div>{eventInfo?.event?.title}</div>;
  };

  const makeTodaybtnActive = active => {
    if (active) {
      let todayButton = document.getElementsByClassName('fc-today-button')[0];

      todayButton?.removeAttribute('disabled');

      todayButton.addEventListener('click', e => {
        onViewChange('today');
      });
    } else {
      document
        .getElementsByClassName('fc-today-button')[0]
        .setAttribute('disabled', true);
    }
  };
  const makeResetbtnActive = active => {
    active
      ? document
          .getElementsByClassName('fc-resetButton-button')[0]
          ?.removeAttribute('disabled')
      : document
          .getElementsByClassName('fc-resetButton-button')[0]
          .setAttribute('disabled', true);
  };

  const updateDragSchedule = async info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    calendarApi = calendarRef?.current?.getApi();
    document.getElementsByClassName(`event-${info.event.id}`)[0].style.display =
      'none';
    document.getElementById(`eventLoading-${info.event.id}`).style.display =
      'flex';
    const statusId = statusOptions?.find(
      item => item?.status === info.event.extendedProps.project_status
    );
    let updateObj = {
      installerId: parseInt(info.event.extendedProps.installer_id),
      projectId: parseInt(info.event.extendedProps.project_id),
      projectStartDateTime: moment(info.event.startStr),
      projectEndDateTime: moment(info.event.endStr),
      old_installerId: info.event.extendedProps.installer_id.toString(),
      old_startDateTime: moment(info.oldEvent.startStr),
      old_endDateTime: moment(info.oldEvent.endStr),
      status_id: statusId?.status_id || '',
    };
    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList
    );
    setReloadCalendar(!reloadCalendar);
    calendarApi.refetchEvents();
  };

  const updateSchedule = info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    setIsUpdateSchedule(true);
    setStartDateStr(info?.event?.startStr);
    setEndDateStr(info?.event?.endStr);
    setInfoValue(info);
    notesFormik.setValues({
      type: '',
      note_text: '',
      follow_up_date: null,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Reschedule Confirmation',
      showButton2: true,
    }));
    setIsOpen(true);
    notesFormik?.handleReset();
  };

  const getPeriod = type => {
    let period;
    switch (type) {
      case 'resourceTimelineDay':
        period = 'D';
        break;
      case 'resourceTimelineWeek':
        period = 'W';
        break;
      case 'resourceTimelineMonth':
        period = 'M';
        break;
    }
    return period;
  };

  const onViewChange = view => {
    let params;
    calendarApi = calendarRef?.current?.getApi();
    setCalendarLoading(true);
    switch (view) {
      case 'prev':
        calendarApi?.prev();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
        };
        break;
      case 'next':
        calendarApi?.next();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
        };
        break;
      case 'resourceTimelineDay':
        calendarApi?.changeView('resourceTimelineDay');
        if (!moment().diff(calendarApi.getDate(), 'days')) {
          makeTodaybtnActive(false);
        }
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'D',
        };
        break;
      case 'resourceTimelineWeek':
        calendarApi?.changeView('resourceTimelineWeek');
        makeTodaybtnActive(true);
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'W',
        };
        break;
      case 'resourceTimelineMonth':
        calendarApi?.changeView('resourceTimelineMonth');
        makeTodaybtnActive(true);
        //calendarApi.gotoDate(calendarApi?.getDate());
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'M',
        };
        break;
      case 'today':
        calendarApi?.changeView('resourceTimelineDay');
        calendarApi?.today();
        makeTodaybtnActive(false);
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'D',
        };
        break;
    }
    setCalenderParams(params);
    getCalendarData(
      params,
      setCalendarData,
      setCalendarLoading,
      setAlert,
      filterCalenderData
    );
    setChildGetParms(params);
  };

  const handleUpdateEventDrop = async info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    const infoResources = info.event.getResources();
    const resourceId = infoResources[0]._resource.id;
    const statusId = statusOptions?.find(
      item => item?.status === info.event.extendedProps.project_status
    );
    setEventLoading(true);
    setReloadList(false);
    document.getElementsByClassName(`event-${info.event.id}`)[0].style.display =
      'none';
    document.getElementById(`eventLoading-${info.event.id}`).style.display =
      'flex';
    let updateObj = {
      installerId: parseInt(info?.newResource?.id) || parseInt(resourceId),
      projectId: parseInt(info.event.extendedProps.project_id),
      projectStartDateTime: moment(info.event.startStr),
      projectEndDateTime: moment(info.event.endStr),
      old_installerId: info?.oldResource?.id || resourceId,
      old_startDateTime: moment(info.oldEvent.startStr),
      old_endDateTime: moment(info.oldEvent.endStr),
      status_id: statusId?.status_id || '',
    };

    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList
    );
    setReloadCalendar(!reloadCalendar);
    calendarApi.refetchEvents();
  };

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const notesFormik = useFormik({
    initialValues: {
      type: 6,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      if (values?.note_text?.length > 0) {
        await addNotes(
          parseInt(infoValue?.event?.extendedProps?.project_id),
          values,
          () => {},
          setAlert,
          setReloadList,
          setIsOpen
        );
      } else {
        setIsOpen(false);
      }
      if (isUpdateSchedule) {
        updateDragSchedule(infoValue);
      } else {
        handleUpdateEventDrop(infoValue);
      }

      setReloadList(false);
    },
    enableReinitialize: true,
  });

  const handleEventDrop = info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    setStartDateStr(info?.event?.startStr);
    setEndDateStr(info?.event?.endStr);
    setInfoValue(info);
    notesFormik.setValues({
      type: '',
      note_text: '',
      follow_up_date: null,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Reschedule Confirmation',
      showButton2: true,
    }));
    setIsOpen(true);
    notesFormik?.handleReset();
  };
  //Continue scheduling
  const continueScheduling = async () => {
    const projectData = reportData.find(
      data =>
        data.project_id ===
        parseInt(infoData?.draggedEl?.attributes['data-event']?.value)
    );
    const statusId =
      statusOptions &&
      statusOptions.length > 0 &&
      statusOptions?.find(item => item?.status === projectData?.project_status);

    let updateObj = {
      installerId: parseInt(infoData.resource.id),
      projectId: parseInt(infoData.draggedEl.attributes['data-event']?.value),
      projectStartDateTime: moment(infoData.dateStr),
      projectEndDateTime:
        calendarApi?.view?.type == 'resourceTimelineWeek' ||
        calendarApi?.view?.type == 'resourceTimelineMonth'
          ? moment(infoData.dateStr).endOf('day')
          : moment(infoData.dateStr).add(1, 'hours'),
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId?.status_id || '',
      user_time_off_request_ids: dropTimeOffRequestIds,
    };
    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList
    );
    setReloadCalendar(!reloadCalendar);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const confirmScheduling = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This technician applied leave for this period. Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };

  const saveDropCalendraData = async () => {
    let updateObj = {
      installerId: parseInt(tableRowDropData.resource.id),
      projectId: parseInt(
        tableRowDropData.draggedEl.attributes['data-event']?.value
      ),
      projectStartDateTime: startDate ? startDate?.toISOString() : undefined,
      projectEndDateTime: endDate ? endDate?.toISOString() : undefined,
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId,
      userAction: technicianAction,
      percentage:
        technicianAction === scheduleInfoUserCustomAction
          ? schedulePercentage
          : 0,
    };

    const updateInstallerResponse = await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList
    );
    setSchedulePercentage(0);
    if (updateInstallerResponse?.status === 206) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: updateInstallerResponse?.message,
      });
    }

    setReloadCalendar(!reloadCalendar);
    handleDropModalClose();
  };
  const fetchInstallerList = async () => {
    dispatch(setInstallerViewCalendar({ isLoading: true }));
    try {
      const allInstallersList = await getAllInstallersList();
      dispatch(
        setInstallerViewCalendar({
          installersList: allInstallersList,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setInstallerViewCalendar({ isLoading: false }));
    }
  };
  useEffect(() => {
    if (isBlockTime) {
      fetchInstallerList();
    }
  }, [isBlockTime]);
  const handleSelect = selectInfo => {
    const { minutesDifference, startSlotTime } = selectedRapidSlot(selectInfo);

    dispatch(
      setInstallerViewCalendar({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: selectInfo?.start,
          end: minutesDifference > 0 ? selectInfo?.end : startSlotTime,
        },
        initialInstaller: selectInfo.resource._resource.id,
      })
    );
  };
  const calenderRefreshHandler = async () => {
    !!calenderParams &&
      (await getCalendarData(
        calenderParams,
        setCalendarData,
        setCalendarLoading,
        setAlert,
        filterCalenderData
      ));
  };

  useEffect(() => {
    calenderRefreshHandler();
  }, [reloadCalendar]);

  const viewScheduleReport = projectId => {
    window.open(`/project/view/${dropData?.project_id}`, '_blank');
  };

  const confirmBlockTimeWarning = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This slot is already blocked for the technician, Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };

  const handleDropModalClose = () => {
    setDropModalValue(false);
    setStartDate(null);
    setEndDate(null);
    setIsStartOrEndDateInvalid(false);
  };

  return (
    <>
      {calendarLoading && !projectDataLoading && (
        <Grid
          container
          justifyContent="center"
          className={`${classes.calendarLoadingIndicator} mt-8`}
        >
          <CircularProgress className="mt-8" />
        </Grid>
      )}
      <Toast ref={toast} />

      <FullCalendar
        timeZone="local"
        plugins={[
          rrulePlugin,
          resourceTimelinePlugin,
          momentPlugin,
          interactionPlugin,
        ]}
        initialView={getDefaultTechnicianSchedulerView()}
        headerToolbar={{
          left: 'prevButton title nextButton today resetButton',
          right:
            'resourceTimelineDay resourceTimelineWeek resourceTimelineMonth refreshButton fullScreenButton',
        }}
        customButtons={{
          resetButton: {
            text: 'Reset',
            click: function () {
              makeResetbtnActive(false);
              setCalendarFilterData(null);
              setFilterCalenderData('');
              setSelectedTypeIds([]);
              setSelectedTypeCoverageIds([]);
              setSelectedCategoryIds([]);
              setSelectedStoreIds([]);
              setTeamValue('');
              setSelectedStoreView([]);
              if (calendarFilterData?.length > 0) {
                makeResetbtnActive(true);
                setCalendarFilterData(null);
                setSelected(null);
                setFilterCalenderData('');
                setSelectedTypeIds([]);
                setSelectedTypeCoverageIds([]);
                setSelectedCategoryIds([]);
                setSelectedStoreIds([]);
                setTeamValue('');
                setSelectedStoreView([]);
              }
            },
          },
          prevButton: {
            icon: 'fc-icon-chevron-left',
            click: () => onViewChange('prev'),
          },
          nextButton: {
            icon: 'fc-icon-chevron-right',
            click: () => onViewChange('next'),
          },
          resourceTimelineDay: {
            text: 'Day',
            click: () => onViewChange('resourceTimelineDay'),
          },
          resourceTimelineWeek: {
            text: 'Week',
            click: () => onViewChange('resourceTimelineWeek'),
          },
          resourceTimelineMonth: {
            text: 'Month',
            click: () => onViewChange('resourceTimelineMonth'),
          },
          today: {
            text: 'Today',
            click: () => onViewChange('today'),
          },
          refreshButton: {
            text: 'Refresh',
            click: calenderRefreshHandler,
          },
          fullScreenButton: {
            text: calendarFullScreen ? 'Minimize' : 'FullScreen',
            //icon: 'fas fa-external-link-alt',
            click: () => setCalendarFullScreen(!calendarFullScreen),
          },
        }}
        resourceOrder="sortOrder"
        resources={resourceData}
        ref={calendarRef}
        events={eventData}
        nowIndicator={true}
        now={new Date().toISOString()}
        //scrollTime="08:00"
        displayEventTime={true}
        droppable={true}
        eventDrop={handleEventDrop}
        selectAllow={info => {
          if (
            checkPermission(permissions?.viewEditProject?.actionEditScheduler)
          ) {
            return true;
          } else {
            return false;
          }
        }}
        drop={async function (info) {
          let showWarningMessage = false;
          let showBlockTimeWarning = false;
          const getInfoDateEnd = () => {
            let infoDateEnd;
            const add1Hour = input => {
              const inputDate = new Date(input);
              inputDate.setHours(inputDate.getHours() + 1);
              return inputDate;
            };
            if (info?.view?.type === 'resourceTimelineDay') {
              infoDateEnd = add1Hour(info?.dateStr);
            }
            return infoDateEnd;
          };
          const eventList = [
            ...TimeoffTentativeEvents,
            ...TimeoffApprovedEvents,
          ];
          for (let i = 0; i < eventList.length; i++) {
            const timeoff = eventList[i];
            if (
              (timeoff.resourceId == info.resource.id &&
                moment(info.dateStr).isBetween(
                  moment(timeoff.start),
                  moment(timeoff.end)
                )) ||
              moment(info.dateStr).isSame(moment(timeoff.start)) ||
              moment(info.dateStr).isSame(moment(timeoff.end))
            ) {
              if (timeoff?.rrule) {
                if (
                  moment(info.dateStr).isBetween(
                    moment(timeoff.rrule.dtstart),
                    moment(timeoff.rrule.until)
                  ) ||
                  moment(info.dateStr).isSame(moment(timeoff.rrule.dtstart)) ||
                  moment(info.dateStr).isSame(moment(timeoff.rrule.until))
                ) {
                  if (
                    info.allDay &&
                    timeoff?.rrule?.byweekday?.includes(
                      moment(info.dateStr).format('dd').toLowerCase()
                    )
                  ) {
                    showWarningMessage = true;
                    break;
                  }
                  if (!info.allDay) {
                    const newStartHour = moment(timeoff.rrule.dtstart).get(
                      'hour'
                    );
                    const newStartMinute = moment(timeoff.rrule.dtstart).get(
                      'minute'
                    );
                    const newEndHour = moment(timeoff.rrule.until).get('hour');
                    const newEndMinute = moment(timeoff.rrule.until).get(
                      'minute'
                    );
                    const newStartDate = moment(info.dateStr).set({
                      hour: newStartHour,
                      minute: newStartMinute,
                    });
                    const newEndDate = moment(info.dateStr).set({
                      hour: newEndHour,
                      minute: newEndMinute,
                    });
                    if (
                      (newStartDate < moment(info.dateStr) &&
                        moment(info.dateStr) < newEndDate) ||
                      (newStartDate < moment(info.dateStr).add(1, 'hours') &&
                        moment(info.dateStr).add(1, 'hours') < newEndDate)
                    ) {
                      showWarningMessage = true;
                      break;
                    }
                  }
                  if (info.allDay && !timeoff?.rrule?.byweekday.length) {
                    showWarningMessage = true;
                    break;
                  }
                }
              }
            }
          }
          const blockEventsOfDroppedInstaller = blockedEvents?.filter(
            i => i.resourceId === parseInt(info?.resource?.id)
          );
          const blockTimeRequests = [];
          for (const blockTimeEvent of blockEventsOfDroppedInstaller) {
            if (
              moment(info?.dateStr).isBetween(
                moment(blockTimeEvent?.start),
                moment(blockTimeEvent?.end)
              ) ||
              moment(getInfoDateEnd()).isBetween(
                moment(blockTimeEvent?.start),
                moment(blockTimeEvent?.end)
              ) ||
              moment(info?.dateStr).isSame(moment(blockTimeEvent?.start)) ||
              moment(info?.dateStr).isSame(moment(blockTimeEvent?.end))
            ) {
              showBlockTimeWarning = true;
              blockTimeRequests.push(blockTimeEvent?.timeOffRequestId);
            }
          }
          setDropTimeOffRequestIds(blockTimeRequests);
          if (showWarningMessage) {
            confirmScheduling(info);
          } else {
            if (showBlockTimeWarning) {
              confirmBlockTimeWarning(info);
            } else {
              setDropModalValue(true);
              setTableRowDropData(info);
              setStatusId(7);
              const dataFind = reportData.find(
                data =>
                  data.project_id ===
                  parseInt(info?.draggedEl?.attributes['data-event']?.value)
              );
              setDropData(dataFind);
              const startDateObj = structuredClone(info?.date);
              let endDateObj = structuredClone(info?.date);
              switch (calendarApi?.view?.type) {
                case 'resourceTimelineDay':
                  endDateObj.setHours(endDateObj.getHours() + 1);
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                case 'resourceTimelineWeek':
                case 'resourceTimelineMonth':
                  endDateObj.setDate(endDateObj.getDate() + 1);
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                default:
                  break;
              }
            }
          }
        }}
        loading={bool => {
          if (!bool) {
            calendarApi = calendarRef?.current?.getApi();
          }
        }}
        eventMinHeight={50}
        eventResize={info => updateSchedule(info)}
        navLinks={true}
        eventContent={renderEventContent}
        resourceLabelContent={renderResourceContent}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        editable={event => {
          event?.extendedProps?.eventType === EVENT_TYPES?.BLOCK ? false : true;
        }}
        selectOverlap={event => {
          return (
            event?.extendedProps?.eventType !== EVENT_TYPES?.BLOCK &&
            event?.extendedProps?.eventType !== EVENT_TYPES.TIME_OFF
          );
        }}
        eventResourceEditable={true}
        eventResizableFromStart={true}
        resourceAreaHeaderContent="Technicians"
        resourceAreaWidth="20%"
        height={700}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
        }}
        eventBorderColor="none"
        eventClick={info => {
          if (!info.event.extendedProps?.isDisabled) {
            viewEditProjectDetails(
              null,
              info.event.extendedProps.source_system_id,
              info.event.extendedProps.project_id
            );
          }
          if (info.event.extendedProps?.eventType === 'blockedTime') {
            const blockDialogData = {
              start: info?.event?.start,
              end: info?.event?.end,
              note: info?.event?.title,
              installerId: info?.event?.extendedProps?.installerId,
            };
            dispatch(
              setInstallerViewCalendar({
                isSelectDialogOpen: true,
                newEventInfo: {
                  start: info?.event?.start,
                  end: info?.event?.end,
                  note: info?.event?.title,
                  timeOffRequestId:
                    info?.event?.extendedProps?.timeOffRequestId,
                },
                initialInstaller: info?.event?.extendedProps?.installerId,
                mode: 'edit',
              })
            );
          }
        }}
        refetchResourcesOnNavigate={true}
        views={{
          resourceTimelineDay: {
            slotDuration: '00:15',
            buttonText: 'Day',
            slotLabelFormat: 'LT',
            titleFormat: 'dddd, D MMMM YYYY',
            slotMinWidth: 40,
            scrollTime: '08:00',
            eventMinWidth: 40,
            eventMinHeight: 150,
          },
          resourceTimelineWeek: {
            buttonText: 'Week',
            type: 'timeline',
            duration: { weeks: 1 },
            slotDuration: { days: 1 },
            slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
            titleFormat: 'D MMMM YYYY',
            slotMinWidth: 150,
            scrollTime: '08:00',
            eventMinWidth: 150,
            eventMinHeight: 150,
          },
          resourceTimelineMonth: {
            buttonText: 'Month',
            slotLabelFormat: [{ day: '2-digit', weekday: 'short' }],
            titleFormat: 'MMMM YYYY',
            slotMinWidth: 180,
            eventMinWidth: 180,
            eventMinHeight: 150,
          },
        }}
        selectable={true}
        select={handleSelect}
      />
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={continueScheduling}
      />
      {dropModalValue ? (
        <Modal
          open={dropModalValue}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={handleDropModalClose}
          className={classes.modal}
        >
          <Grid
            container
            item
            direction="row"
            xs={3}
            justifyContent="flex-end"
            spacing={2}
            className={classes.boxWidth}
          >
            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Store #
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.store_number}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Client Name{' '}
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.client_name}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Category
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.category}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Address
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.installation_address}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>Type</Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.project_type}{' '}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              m={4}
              className="align-items-center"
            >
              <Typography className={classes.detailLabelModal}>
                Schedule Start Date
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <div className={classes.detail}>
                <Calendar
                  id="startDate"
                  className="w-full"
                  value={startDate}
                  onChange={e => setStartDate(e.value)}
                  showTime
                  hourFormat="12"
                  appendTo="self"
                  stepMinute={15}
                />
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              m={4}
              className="align-items-center"
            >
              <Typography className={classes.detailLabelModal}>
                Schedule End Date
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <div className={classes.detail}>
                <Calendar
                  id="endDate"
                  className="w-full"
                  value={endDate}
                  onChange={e => setEndDate(e.value)}
                  showTime
                  hourFormat="12"
                  appendTo="self"
                  stepMinute={15}
                />
                {isStartOrEndDateInvalid && (
                  <div className="mt-1 text-red-600">
                    End date must be after start date
                  </div>
                )}
              </div>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Status
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Autocomplete
                id="status"
                disableListWrap
                options={statusOptions}
                getOptionLabel={option => option && option.status}
                onChange={(event, value) => {
                  setStatusId(value?.status_id);
                }}
                renderInput={params => (
                  <TextField {...params} InputLabelProps={{ shrink: true }} />
                )}
                classes={{ input: classes.statusInput }}
                value={statusOptions.find(
                  status => status.status_id === statusId
                )}
              />
            </Grid>

            <Grid container item xs={12} direction="row" m={4} className="grid">
              <Typography className="col-3 pl-0">
                Default Technician Action
              </Typography>
              <Typography className="col-1 text-center">:</Typography>
              <Typography className="col-8">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={technicianAction}
                  name="radio-buttons-group"
                  onChange={e => setTechnicianAction(e?.target.value)}
                >
                  <FormControlLabel
                    value={scheduleInfoUserWipeAction}
                    control={<Radio />}
                    label="Full Allocation"
                  />
                  <div className="flex align-items-center">
                    <FormControlLabel
                      value={scheduleInfoUserCustomAction}
                      control={<Radio />}
                      label="Custom Allocation"
                    />
                    {technicianAction === scheduleInfoUserCustomAction && (
                      <PFInputNumber
                        id="allocat_percentage"
                        value={schedulePercentage}
                        onChange={(event, value) => {
                          const percentage = getPercentageValue(value);
                          setSchedulePercentage(percentage);
                        }}
                        suffix={'%'}
                        placeholder="%"
                        className="w-36"
                        pt={{
                          root: {
                            className: 'h-2rem pb-2 pt-1',
                          },
                          input: {
                            root: {
                              className:
                                'w-full border-noround border-bottom-1 border-top-none border-x-none p-0  shadow-none border-gray-500',
                            },
                          },
                        }}
                        min={0}
                        maxLength={6}
                        max={100}
                      />
                    )}
                  </div>

                  <FormControlLabel
                    value={scheduleInfoUserFillAction}
                    control={<Radio />}
                    label="Allocate Remaining"
                  />

                  <FormControlLabel
                    value={scheduleInfoUserSkipAction}
                    control={<Radio />}
                    label="Leave Unassigned"
                  />
                </RadioGroup>
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Source Status
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.IMS_status}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Project No
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.project_number}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Mobile Number
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.home_phone}{' '}
              </Typography>
            </Grid>

            <Grid container item xs={12} direction="row" m={4}>
              <Typography className={classes.detailLabelModal}>
                Alternate Number
              </Typography>
              <Typography className={classes.colon}>:</Typography>
              <Typography className={classes.detail}>
                {dropData?.mobile_phone}{' '}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => viewScheduleReport(dropData?.project_id)}
              >
                GoTo Job
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={saveDropCalendraData}
                disabled={isStartOrEndDateInvalid}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDropModalClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : null}

      <form>
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
            notesFormik.handleReset();
          }}
          disabledButton2={false}
          disabledButton1={false}
          dialogSettings={dialogSettings}
          handleSave={() => notesFormik.handleSubmit()}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <DialogContentText>
                <b>
                  Please confirm, if you would like to reschedule this job for{' '}
                  {moment(startDateStr).format('YYYY-MM-DD hh:mm A')} -{' '}
                  {moment(endDateStr).format('YYYY-MM-DD hh:mm A')}
                </b>
              </DialogContentText>
            </Grid>
            <Grid item>
              <TextField
                id="note_text"
                name="note_text"
                label="Note"
                multiline
                onChange={notesFormik.handleChange}
                onBlur={notesFormik.handleBlur}
                value={notesFormik?.values?.note_text}
              />
            </Grid>
          </Grid>
        </GenericDialog>
      </form>
      <InstallerViewBlockCalendar
        calenderRefreshHandler={calenderRefreshHandler}
        setIsBlockTime={setIsBlockTime}
      />
      <ConfirmDialog />
    </>
  );
};
export default CalenderContainer;
