import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Paper,
  Backdrop,
  IconButton,
  Link,
  TextField,
  LinearProgress,
  Modal,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Input,
  Checkbox,
  ListItemText,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import { isValid as isDateValid } from 'date-fns';

// **** Custom Components ****
import PageHeader from '../shared/PageHeader/PageHeader';
import GenericDataTable from '../shared/GenericDataTable/GenericDataTable';
import MapContainer from '../shared/SchedulerMap';
import { useAlerts } from '../shared/Alerts/alertsService';
import GenericDialog from '../shared/Dialog/GenericDialog';
import { Alert } from '../shared/Alerts/Alert';
import Notes from '../ProjectManagement/Notes/Notes';
import Installer from '../ProjectManagement/ScheduleInfo/Installer';
import { useStyles } from '../ProjectManagement/Scheduling/Reports/Reports.styles';
import {
  getReportResponse,
  getAssignedAndUnassignedJobsMap,
  getProjectDataById,
  updateProjectScheduleInfo,
  getFiltersDropdownOptions,
  getRescheduleReasonOptions,
  getTableFiltersOptions,
  getDropdownDetails,
  getStoresData,
  getDefaultFilterList,
} from '../ProjectManagement/Scheduling/Reports/Reports.service';
import { searchDistrict } from '../Admin/user-form/services/user-form-coverage.service';
import {
  getUserTypes,
  searchUserType,
} from '../Admin/user-form/services/user-form-service';
import { checkPermission } from '../../utils/Helpers';
import permissions from '../../config/permissions';
import TableLoader from '../shared/Loader/TableLoader';
import ApiService from '../../services/api.service';
import BlockLoader from '../shared/Loader/BlockLoader';

import NewScheduler from './new-scheduler/new-schduler';
import { getCalendarData } from './Scheduler.service';
import ProjectDetailsViewEditForm from './ProjectDetailsViewEditForm';
import CalenderContainer from './CalenderContainer';
import { getProjectStatusList } from './new-scheduler/service/new-scheduler.service';
import CustomFilter from './CutomFilter/CustomFilter';

export const getInitials = name => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};

const ProjectReport = props => {
  const classes = useStyles();
  const { projectNumber } = useParams();
  const history = useHistory();
  const [view, setView] = React.useState('calendar');
  const [installerFilterPopUp, setInstallerFilterPopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [schedulerLoader, setSchedulerLoader] = React.useState(true);
  const [reportData, setReportData] = React.useState([]);
  const [storeNumberOptions, setStoreNumberOptions] = React.useState([]);
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [installerOptions, setInstallerOptions] = React.useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = React.useState([]);
  const [reloadCalender, setReloadCalender] = React.useState(true);
  const [storeSearchValueOnMap, setStoreSearchValueOnMap] = React.useState('');
  const [storeLoading, setStoreLoading] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);
  const { alert, setAlert } = useAlerts();
  const [, setActiveRow] = React.useState(null);
  const [calendarFilterData, setCalendarFilterData] = React.useState();
  const [calendarFullScreen, setCalendarFullScreen] = React.useState(false);
  const [projectDataLoading, setProjectDataLoading] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  const [rescheduleReasonOptions, setRescheduleReasonOptions] =
    React.useState();
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [typeOptions, setTypeOptions] = React.useState([]);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = React.useState([]);
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [teamOptions, setTeamOptions] = React.useState();
  const [storeOpen, setStoreOpen] = React.useState(false);
  const [storeLoadingMap, setStoreLoadingMap] = React.useState(false);
  const [customerOpen, setCustomerOpen] = React.useState(false);
  const [customerLoading, setCustomerLoading] = React.useState(false);
  const [districtOptions, setDistrictOptions] = React.useState([]);
  const [workRoomOptions, setWorkRoomOptions] = React.useState([]);
  const [showErrorMsg, setShowErrorMsg] = React.useState(false);
  const [selectedTypeIds, setSelectedTypeIds] = React.useState([]);
  const [installerFilterValue, setInstallerFilterValue] = React.useState('');
  const [initialDistricts, setInitialDistricts] = React.useState([]);
  const [districtFilterOptions, setDistrictFilterOptions] = React.useState([]);
  const [selectedDistricts, setSelectedDistricts] = React.useState([]);
  const [initialUserTypes, setInitialUserTypes] = React.useState([]);
  const [userTypesOptions, setUserTypesOptions] = React.useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = React.useState([]);
  const [selectedTypeCoverageIds, setSelectedTypeCoverageIds] = React.useState(
    []
  );
  const [typeCoverageValue, setTypeCoverageValue] = React.useState('');
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = React.useState('');
  const [storeValueOptions, setStoreValueOptions] = React.useState('');
  const [selectedStoreValue, setSelectedStoreValue] = React.useState('');
  const [selectedStoreView, setSelectedStoreView] = React.useState([]);
  const [selectedWorkroomView, setSelectedWorkroomView] = React.useState([]);
  const [searchStoreLoading, setSearchStoreLoading] = React.useState(false);
  const [searchTypeCoverageText, setSearchTypeCoverageText] =
    React.useState('');
  const [searchTypeText, setSearchTypeText] = React.useState('');
  const [searchCategoryText, setSearchCategoryText] = React.useState('');
  const [searchStoreText, setSearchStoreText] = React.useState('');
  const [searchWorkroomText, setSearchWorkroomText] = React.useState('');
  const [teamValue, setTeamValue] = React.useState('');
  const [filterCalenderData, setFilterCalenderData] = React.useState('');
  const [childGetParms, setChildGetParms] = React.useState(null);
  const [calendarData, setCalendarData] = React.useState([]);
  const [childGetCalendarLoading, setCalendarLoading] = React.useState(false);
  const [displayedStoreOptions, setDisplayedStoreOptions] = React.useState([]);
  const [customFilter, setCustomFilter] = React.useState(false);
  const [calendarViewAPICall, setCalendarViewAPICall] = React.useState(false);
  const [openScheduleModel, setOpenScheduleModel] = React.useState(false);
  const [visibleFilter, setVisibleFilter] = React.useState(false);
  const [currDate, setCurrDate] = React.useState(
    moment(new Date()).format('MM-DD-YYYY')
  );
  const [defaultFilters, setDefaultFilters] = React.useState([]);
  const [selectedDefaultFilterId, setSelectedDefaultFilterId] =
    React.useState('');
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    projectCategoryIds: [],
    installerIds: [],
    projectTypeIds: [],
    storeIds: [],
    statusId: [],
    teams: [],
    startDate: moment(new Date()).format('MM-DD-yyyy'),
    endDate: moment(new Date()).format('MM-DD-yyyy'),
  });

  const [mapFilterApi, setMapFilterApi] = useState(false);
  const [checkedStores, setCheckedStores] = useState(false);
  const [checkedAssigned, setCheckedAssigned] = useState(1);
  const [checkedUnAssigned, setCheckedUnAssigned] = useState(0);
  const [stores, setStores] = useState([]);
  const [mapData, setMapData] = React.useState([]);
  const [, setMapLoading] = React.useState(true);
  const [mapDate, setMapDate] = React.useState(
    moment(new Date()).format('MM-DD-yyyy hh:mm A')
  );
  const [filterDates, setFilterDates] = React.useState({
    start_date: moment(new Date()).format('MM-DD-yyyy'),
    end_date: moment(new Date()).format('MM-DD-yyyy'),
  });
  const [searchQuery, setSearchQuery] = React.useState({
    startDate: moment(new Date()).format('MM-DD-yyyy'),
    endDate: moment(new Date()).format('MM-DD-yyyy'),
    assigned: 1,
    unAssigned: 0,
  });

  const [RTSSearchQuery, setRTSSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    searchQuery: projectNumber,
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  });

  const makeResetbtnActive = active => {
    active
      ? document
          .getElementsByClassName('fc-resetButton-button')[0]
          ?.removeAttribute('disabled')
      : document
          .getElementsByClassName('fc-resetButton-button')[0]
          ?.setAttribute('disabled', true);
  };
  const [selected, setSelected] = React.useState(null);
  const filteredCategoryOptions = categoryOptions.filter(value =>
    value?.category?.trim()
  );
  const fetchFilterData = async (fieldName, searchValue) => {
    if (searchValue.length > 2) {
      if (fieldName == 'customers') {
        setCustomerOpen(false);
        setCustomerLoading(true);
      } else if (fieldName !== 'workroomId') {
        setStoreLoading(true);
        setStoreOpen(false);
      }
      const dataList = await getDropdownDetails({
        reportId: 6,
        fieldName,
        searchValue,
      });
      if (fieldName == 'customers') {
        setCustomerOptions(dataList);
        setCustomerOpen(true);
        setCustomerLoading(false);
      } else if (fieldName === 'workroomId') {
        setWorkRoomOptions(dataList);
      } else {
        setStoreNumberOptions(dataList);
        setStoreOpen(true);
        setStoreLoading(false);
      }
    }
  };
  const fetchInstallerFilterData = async (fieldName, searchValue) => {
    if (searchValue?.length > 2) {
      setSearchStoreLoading(true);
      const dataList = await getDropdownDetails({
        searchValue,
      });

      setStoreValueOptions(dataList);
      setSearchStoreLoading(false);
    }
  };
  const readyToScheuleTableColumns = [
    {
      name: '',
      label: '',
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: dataIndex => (
          <span>
            <IconButton classes={{ root: classes.actionIcons }}>
              <Link
                to={`/project/view/${
                  readyToScheduleDataArray &&
                  readyToScheduleDataArray[dataIndex]?.project_id
                }`}
                target="_blank"
              >
                <VisibilityIcon />
              </Link>
            </IconButton>
          </span>
        ),
      },
    },
    {
      name: 'rts_follow_up_date',
      label: 'RTS Follow-up Date',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '163px', maxWidth: '163px' },
        }),
      },
    },
    {
      name: 'date_sold',
      label: 'Date Sold',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '106px', maxWidth: '106px' },
        }),
      },
    },
    {
      name: 'customerId',
      label: 'Client Name',
      options: {
        sort: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v =>
            v.map(
              val => `${val.first_name} ${val.last_name}-${val.primary_phone}`
            ),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="client_name"
                  noOptionsText="Search for client name"
                  multiple
                  open={customerOpen}
                  onOpen={() => {
                    setCustomerOpen(true);
                  }}
                  onClose={() => {
                    setCustomerOpen(false);
                  }}
                  options={customerOptions}
                  onInputChange={event =>
                    fetchFilterData('customers', event.currentTarget.value)
                  }
                  onChange={(event, value) => {
                    filterList[index] = value.map(val => val);
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option =>
                    option &&
                    `${option.first_name} ${option.last_name}- ${
                      option.primary_phone ? option.primary_phone : ''
                    }`
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Client Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {customerLoading ? (
                  <LinearProgress />
                ) : (
                  <p className={classes.hintLabel}>
                    Type at least 3 character to search
                  </p>
                )}
              </>
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
      },
    },
    {
      name: 'mobile_number',
      label: 'Mobile No.',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '117px', maxWidth: '117px' },
        }),
      },
    },
    {
      name: 'home_phone',
      label: 'Home Phone',
      options: {
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '127px', maxWidth: '127px' },
        }),
      },
    },
    {
      name: 'storeNumber',
      label: 'Store #',
      options: {
        sort: true,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => `${val.store_number}-${val.store_name}`),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="store"
                  multiple
                  noOptionsText="Search for store"
                  open={storeOpen}
                  onClose={() => {
                    setStoreOpen(false);
                  }}
                  onOpen={() => {
                    setStoreOpen(true);
                  }}
                  options={storeNumberOptions}
                  onInputChange={event =>
                    fetchFilterData('store', event.currentTarget.value)
                  }
                  onChange={(event, value) => {
                    filterList[index] = value.map(val => val);
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option =>
                    option && `${option.store_number}-${option.store_name}`
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Store"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {storeLoading ? (
                  <LinearProgress />
                ) : (
                  <p className={classes.hintLabel}>
                    Type at least 3 character to search
                  </p>
                )}
              </>
            );
          },
        },
        serverSide: true,
        setCellProps: () => ({ style: { minWidth: '92px', maxWidth: '92px' } }),
      },
    },
    {
      name: 'project',
      label: 'Project #',
      options: { filter: false },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        sort: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v =>
            v.map(val => `${val.project_category_id}-${val.category}`),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="category_type"
                multiple
                options={filteredCategoryOptions}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option =>
                  option && `${option.project_category_id}-${option.category}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '120px' },
        }),
      },
    },
    {
      name: 'market',
      label: 'Market',
      options: { filter: false },
    },
    {
      name: 'projectTypeId',
      label: 'Type',
      options: {
        sort: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.project_type),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="project_type"
                multiple
                options={projectTypeOptions}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option.project_type}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Project Type"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '105px', maxWidth: '105px' },
        }),
      },
    },
    {
      name: 'statusId',
      label: 'Status',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.status),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="status"
                multiple
                options={statusOptions}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option?.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="subtitle2"
            className={
              (value === 'Completed' && classes.statusColorCompleted) ||
              (value === 'Cancelled' && classes.statusColorCancelled) ||
              classes.statusColor
            }
          >
            {value}
          </Typography>
        ),
        setCellProps: () => ({ style: { minWidth: '95px', maxWidth: '95px' } }),
      },
    },
    {
      name: 'imsStatus',
      label: 'Source Status',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val?.status),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="IMS_status"
                multiple
                options={sourceStatusOptions}
                onChange={(event, value) => {
                  filterList[index] = value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option?.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Source Status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        setCellProps: () => ({ style: { minWidth: '95px', maxWidth: '95px' } }),
      },
    },
    {
      name: 'installation_address',
      label: 'Installation Address',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
      },
    },
    {
      name: 'districtId',
      label: 'District',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v?.map(val => `${val?.district}`),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="districtId"
                  noOptionsText="District"
                  multiple
                  options={districtOptions}
                  onChange={(event, value) => {
                    filterList[index] = value;
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option => option && `${option?.district}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="District"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </>
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
      },
    },
    {
      name: 'workroomId',
      label: 'Workroom',
      options: {
        sort: false,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v?.map(val => `${val?.label} `),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <>
                <Autocomplete
                  name="workroomId"
                  noOptionsText="Workroom"
                  multiple
                  options={workRoomOptions}
                  onInputChange={event =>
                    fetchFilterData('workroomId', event?.currentTarget?.value)
                  }
                  onChange={(event, value) => {
                    filterList[index] = value;
                    onChange(filterList[index], index, column);
                  }}
                  value={filterList[index]}
                  getOptionLabel={option => option && `${option?.label}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Workroom"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </>
            );
          },
        },
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
      },
    },
  ];

  React.useEffect(() => {
    if (alert.scrollWindow) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [alert.scrollWindow]);

  // ******* Get Scheduler Page Report and Map Data *******
  React.useEffect(() => {
    getReportResponse(
      6,
      undefined,
      RTSSearchQuery,
      selectedFilters,
      setLoading,
      setReportData,
      setSchedulerLoader
    );
  }, [reloadList, RTSSearchQuery, selectedFilters]);
  // ************************************
  // ********* Map - Handlers *********
  // ************************************

  // ******* Get Map Data on Filters Changes *******

  React.useEffect(() => {
    if (applyFilter) return;
    if (searchQuery && (view === 'map' || view === 'split')) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap(
        setMapLoading,
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
        stores,
        checkedStores
      );
    }
  }, [searchQuery, view]);

  React.useEffect(() => {
    if (applyFilter) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap(
        setMapLoading,
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg
      );
      setApplyFilter(false);
    }
  }, [searchQuery, applyFilter]);

  useEffect(() => {
    if (checkedStores && (view === 'map' || view === 'split')) {
      getStoresData(
        setProjectDataLoading,
        setMapLoading,
        mapData,
        setMapData,
        setStores,
        stores
      );
    }
  }, [checkedStores]);

  // To fetch the filter options
  React.useEffect(() => {
    // if (customFilter) {
    getTableFiltersOptions(
      setStoreNumberOptions,
      setCustomerOptions,
      setStatusOptions,
      setInstallerOptions,
      setProjectTypeOptions,
      setSourceStatusOptions
    );

    const getCategoryOptions = async () => {
      try {
        const response = await ApiService.get(
          `/projects/master/project-category`
        );
        setCategoryOptions(response);
      } catch (error) {
        console.log(error);
      }
    };
    getCategoryOptions();
    handleDistrictSearch();
    // }
  }, []);

  React.useEffect(() => {
    if (visibleFilter || mapFilterApi) {
      getFiltersDropdownOptions(
        setCategoryOptions,
        setTypeOptions,
        setInstallerOptions,
        setStatusOptions,
        setStoreOptions,
        setTeamOptions,
        setDistrictOptions,
        setWorkRoomOptions
      );
    }
  }, [visibleFilter, mapFilterApi]);

  const fetchInitialDistricts = async () => {
    const districtResponse = await searchDistrict();
    setDistrictOptions(districtResponse);
    const initialDistricts = districtResponse?.map(item => item?.district);
    setInitialDistricts(initialDistricts);
    setDistrictFilterOptions(initialDistricts);
  };

  const fetchInitialUserTypes = async () => {
    const initialUserTypes = await getUserTypes();
    setInitialUserTypes(initialUserTypes);
    setUserTypesOptions(initialUserTypes);
  };
  const fetchDefaultFilterList = async () => {
    const response = await getDefaultFilterList();
    let data = [
      {
        filter_id: 'AC',
        filter_name: 'Custom',
      },
      ...response,
    ];
    setDefaultFilters(data);
  };
  const handleDefaultFilters = value => {
    let selectedFilterObj = {};
    value?.filters?.forEach(item => {
      switch (item?.filterType?.type_name) {
        case 'store':
          selectedFilterObj.storeNumber = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;
        case 'projectCategory':
          selectedFilterObj.projectCategoryId = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;

        case 'projectType':
          selectedFilterObj.projectTypeId = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;

        case 'status':
          selectedFilterObj.statusId = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;

        case 'sourceStatus':
          selectedFilterObj.imsStatus = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;

        case 'district':
          selectedFilterObj.districtId = [...item?.filter_value.split(',')];
          break;

        case 'workroom':
          selectedFilterObj.workroomId = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
          break;
        case 'customer':
          selectedFilterObj.customerId = item?.filter_value
            ?.split(',')
            ?.map(value => parseInt(value));
        default:
          break;
      }
    });
    setSelectedFilters(selectedFilterObj);
  };

  React.useEffect(async () => {
    await fetchInitialDistricts();
    await fetchInitialUserTypes();
    const projectStatusList = await getProjectStatusList();
    setStatusOptions(projectStatusList);
    await fetchDefaultFilterList();
  }, []);

  const fetchStoreList = async () => {
    setStoreLoadingMap(true);
    const result = await getDropdownDetails({
      fieldName: 'store',
      searchValue: storeSearchValueOnMap,
    });
    setStoreOptions(result);
    setStoreLoadingMap(false);
  };

  React.useEffect(() => {
    fetchStoreList();
  }, [storeSearchValueOnMap]);

  React.useEffect(() => {
    if (RTSSearchQuery.searchQuery && reportData.items) {
      setCalendarFilterData({
        project_type_id: reportData?.items[0]?.project_type_id,
        project_category_id: reportData?.items[0]?.project_category_id,
        project_store_id: reportData?.items[0]?.store_id,
        isJobCoverage: true,
      });
      setSelected(
        mapData.filter(
          map => map.project_id === reportData?.items[0]?.project_id
        )[0]
      );
    }
  }, [reportData, mapData]);

  // Toggle Between Map/Calendar/Split View
  const buttonHandler = buttonName => {
    setView(buttonName);
    setCalendarViewAPICall(true);
  };

  // ******************************************
  // *** Ready To Schedule Report - Handlers ***
  // ******************************************
  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal;

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchQuery: searchString || null,
      }));
    }, 500);
  };
  const handleFilterSubmit = applyNewFilters => {
    let appliedFiltersList = applyNewFilters();
    let selectedFilterObject = {};
    appliedFiltersList.map((data, index) => {
      if (data?.length) {
        let columnKey = readyToScheuleTableColumns[index]?.name;
        let selectedFilterOptionsKey = [];
        switch (columnKey) {
          case 'storeNumber':
            selectedFilterOptionsKey = data.map(val => val.store_number);
            break;
          case 'imsStatus':
            selectedFilterOptionsKey = data.map(val => val.status_id);
            break;
          case 'customerId':
            selectedFilterOptionsKey = data.map(val => val.customer_id);
            break;
          case 'category':
            selectedFilterOptionsKey = data.map(val => val.project_category_id);
            break;
          case 'statusId':
            selectedFilterOptionsKey = data.map(val => val.status_id);
            break;
          case 'projectTypeId':
            selectedFilterOptionsKey = data.map(val => val.project_type_id);
            break;
          case 'workroomId':
            selectedFilterOptionsKey = data?.map(val => val?.type_id);
            break;
          case 'districtId':
            selectedFilterOptionsKey = data?.map(val => val?.district);
            break;
          default:
            selectedFilterOptionsKey = [];
        }
        if (columnKey === 'category') {
          columnKey = 'projectCategoryId';
        }
        selectedFilterObject = {
          ...selectedFilterObject,
          [columnKey]: selectedFilterOptionsKey,
        };
      }
    });
    setSelectedFilters(selectedFilterObject);
  };
  let readyToScheduleDataArray = reportData?.items?.map((val, index) => {
    return {
      rts_follow_up_date: val.rts_follow_up_date
        ? moment(val.rts_follow_up_date?.slice(0, 10)).format('MM-DD-YYYY')
        : null,
      date_sold: moment(val.date_sold?.slice(0, 10))?.format('MM-DD-YYYY'),
      customerId: val.client_name,
      category: val.category,
      market: val.market,
      project_description: val.category,
      project: val.project_number,
      storeNumber: val.store_number,
      installer: val.Installer,
      statusId: val.project_status,
      projectTypeId: val.project_type,
      mobile_number: val.mobile_phone,
      home_phone: val.home_phone,
      imsStatus: val.IMS_status,
      project_id: val.project_id,
      installation_address: val.installation_address,
      districtId: val?.district ? val?.district : null,
      workroomId: val?.workroom_label ? val?.workroom_label : null,
    };
  });

  const readyToScheduleDataTableOptions = {
    download: false,
    print: false,
    selectableRows: false,
    responsive: 'standard',
    tableBodyMinHeight: '200px',
    tableBodyMaxHeight: '350px',
    count: reportData?.count,
    serverSide: true,
    confirmFilters: true,
    filter: false,
    //searchText: RTSSearchQuery.searchQuery,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        'data-event': reportData.items[rowIndex].project_id,
        'data-description': reportData?.items[rowIndex]?.category || '',
        onDoubleClick: () => {
          setCalendarFilterData({
            project_type_id: reportData.items[rowIndex].project_type_id,
            project_category_id: reportData.items[rowIndex].project_category_id,
            project_store_id: reportData.items[rowIndex].store_id,
            isJobCoverage: true,
          });
          setSelected(
            mapData.filter(
              map => map.project_id === reportData?.items[rowIndex]?.project_id
            )[0]
          );
          makeResetbtnActive(true);
        },
      };
    },
    textLabels: {
      body: {
        noMatch:
          !loading && !readyToScheduleDataArray?.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * RTSSearchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setRTSSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction,
      }));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setRTSSearchQuery(prevState => ({
        ...prevState,
        searchQuery: null,
      }));
      setCalendarFilterData({});
      setSelected(null);
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      setCustomFilter(true);
      return (
        <div
          style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
          <span style={{ marginLeft: '10px', color: 'rgba(0, 0, 0, 0.54)' }}>
            This will override the applied filters.
          </span>
        </div>
      );
    },
    onFilterChange: (
      column,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'storeNumber':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.store_number
          );
          break;
        case 'category':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.project_category_id
          );
          break;
        case 'customerId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.customer_id
          );
          break;
        case 'imsStatus':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'statusId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.status_id
          );
          break;
        case 'projectTypeId':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val.project_type_id
          );
          break;
        default:
          selectedFilterOptionsKey = [];
      }
      if (column === 'category') {
        column = 'projectCategoryId';
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
  };

  // *** Update map on Date change ***
  const changeMapDate = day => {
    const currentDate = new Date(mapDate);

    if (day === 'previous') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const updatedDate = currentDate.toISOString().split('T')[0];

    setMapDate(updatedDate);
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: updatedDate,
      endDate: updatedDate,
    }));
  };

  // **** Project Details Form Validation Schema ****
  const ProjectDetailsFormikSchema = Yup.object().shape({
    date_scheduled_start: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
    date_scheduled_end: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
  });
  // **** Project Details Formik Form Values ****
  const projectDetailsFormik = useFormik({
    initialValues: {
      store_number: projectData?.store?.store_number,
      type: projectData?.project_type?.project_type,
      category: projectData?.project_category?.category,
      status: projectData?.status?.status,
      project_number: projectData?.project_number,
      date_sold: projectData?.date_sold,
      client: projectData?.customer
        ? `${projectData?.customer?.first_name} ${projectData?.customer?.last_name}`
        : '',
      address: projectData?.installation_address?.address1,
      mobile_number: projectData?.customer?.customer_phones[0]?.phone_number,
      alternate_number: projectData?.customer?.alternate_phone,
      email: projectData?.customer?.customer_emails[0]?.email,
      ims_status: projectData?.source_status?.status,
      reschedule_reason: projectData?.reschedule_reason,
      rts_follow_up_date: projectData?.rts_follow_up_date,
      date_scheduled_start: projectData?.date_scheduled_start,
      date_scheduled_end: projectData?.date_scheduled_end,
      project_type_id: projectData?.project_type_id,
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateProjectScheduleInfo(
        projectData.project_id,
        {
          reschedule_reason_id:
            projectDetailsFormik?.values?.reschedule_reason?.reason_id,
          rts_follow_up_date: projectDetailsFormik?.values?.rts_follow_up_date,
          date_scheduled_start:
            projectDetailsFormik?.values?.date_scheduled_start,
          date_scheduled_end: projectDetailsFormik?.values?.date_scheduled_end,
          project_type_id: projectDetailsFormik?.values?.project_type_id,
        },
        setProjectDataLoading,
        setAlert,
        setReloadList,
        setIsOpen,
        resetForm
      );
    },
    validationSchema: ProjectDetailsFormikSchema,
    enableReinitialize: true,
  });

  // *** View/Edit Project Details on Marker Click ***
  const viewEditProjectDetails = (
    reschedule_reason,
    source_system_id,
    project_id
  ) => {
    setOpenScheduleModel(true);
    projectDetailsFormik?.handleReset();

    getRescheduleReasonOptions(
      'Project',
      source_system_id,
      setRescheduleReasonOptions,
      value => {}
    );

    getProjectDataById(
      project_id,
      setProjectDataLoading,
      setProjectData,
      setIsOpen,
      setOpenScheduleModel
    );
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Edit Project Info',
    }));
  };

  const installerCalendarFilterPopUp = () => {
    setVisibleFilter(true);
  };

  const handleTypeChange = event => {
    const {
      target: { value },
    } = event;

    const countMap = new Map();
    value.forEach(item => {
      if (item) {
        if (countMap.has(item.user_id)) {
          countMap.set(item.user_id, countMap.get(item.user_id) + 1);
        } else {
          countMap.set(item.user_id, 1);
        }
      }
    });
    const filterForAll = value.filter(
      item => item && countMap.get(item.user_id) === 1 && item.user_id !== null
    );

    if (filterForAll.length > 0 && value[value?.length - 1].user_id !== null) {
      setSelectedTypeIds(filterForAll);
      setInstallerFilterValue(
        filterForAll.length > 0
          ? filterForAll.map(val => val?.user_id).join(',')
          : null
      );
    } else {
      setSelectedTypeIds([
        {
          user_id: null,
          first_name: 'All',
          last_name: '',
        },
      ]);
      setInstallerFilterValue(null);
    }
  };

  const handleDistrictChange = event => {
    const districts = event?.target?.value?.filter(item => !!item);
    if (districts?.length > 0) {
      setSelectedDistricts(districts);
    } else {
      setSelectedDistricts([]);
    }
  };

  const handleDistrictSearch = async event => {
    const searchString = event?.target?.value;
    if (searchString?.length > 0) {
      const searchDistrictResponse = await searchDistrict(searchString);
      const searchedDistricts = searchDistrictResponse?.map(
        item => item?.district
      );
      setDistrictFilterOptions(searchedDistricts);
    } else {
      setDistrictFilterOptions(initialDistricts);
    }
  };

  const handleDistrictClose = () => {
    setSearchTypeText('');
    setDistrictFilterOptions(initialDistricts);
  };

  const handleUserTypeChange = event => {
    const getUniqueUserTypes = input => {
      const uniqueUserTypes = {};
      input?.forEach(item => {
        if (item?.user_type_id) {
          uniqueUserTypes[item.user_type_id] = item;
        }
      });
      const resultArray = Object.values(uniqueUserTypes);
      return resultArray;
    };
    const userTypes = getUniqueUserTypes(
      event?.target?.value?.filter(item => !!item)
    );
    if (userTypes?.length > 0) {
      setSelectedUserTypes(userTypes);
    } else {
      setSelectedUserTypes([]);
    }
  };

  const handleUserTypeSearch = async event => {
    const searchString = event?.target?.value;
    if (searchString?.length > 0) {
      const searchUserTypes = await getUserTypes(searchString);
      setUserTypesOptions(searchUserTypes);
    } else {
      setUserTypesOptions(initialUserTypes);
    }
  };

  const handleUserTypeClose = () => {
    setSearchTypeText('');
    setUserTypesOptions(initialUserTypes);
  };

  const handleTypeCoverageChange = event => {
    const {
      target: { value },
    } = event;
    const countMap = new Map();
    value?.forEach(item => {
      if (item) {
        if (countMap.has(item.project_type_id)) {
          countMap.set(
            item.project_type_id,
            countMap.get(item.project_type_id) + 1
          );
        } else {
          countMap.set(item.project_type_id, 1);
        }
      }
    });
    const filterForAll = value?.filter(
      item =>
        item &&
        countMap.get(item.project_type_id) === 1 &&
        item?.project_type_id !== null
    );

    if (
      filterForAll.length > 0 &&
      value[value?.length - 1].project_type_id !== null
    ) {
      setSelectedTypeCoverageIds(filterForAll);
      setTypeCoverageValue(
        filterForAll.length > 0
          ? filterForAll.map(val => val?.project_type_id).join(',')
          : null
      );
    } else {
      setSelectedTypeCoverageIds([
        {
          project_type_id: null,
          project_type: 'All',
        },
      ]);
      setTypeCoverageValue(null);
    }
  };

  const handleCategoryChange = event => {
    const {
      target: { value },
    } = event;

    const countMap = new Map();
    value.forEach(item => {
      if (item) {
        if (countMap.has(item.project_category_id)) {
          countMap.set(
            item.project_category_id,
            countMap.get(item.project_category_id) + 1
          );
        } else {
          countMap.set(item.project_category_id, 1);
        }
      }
    });
    const filterForAll = value.filter(
      item =>
        item &&
        countMap.get(item.project_category_id) === 1 &&
        item?.project_category_id !== null
    );
    if (filterForAll.length > 0) {
      setSelectedCategoryIds(filterForAll);
      setSelectedCategoryValue(
        filterForAll.length > 0
          ? filterForAll.map(val => val?.project_category_id).join(',')
          : null
      );
    } else {
      setSelectedCategoryIds([
        {
          project_category_id: null,
          category: 'All',
        },
      ]);
      setSelectedCategoryValue(null);
    }
  };

  const handleStoreChange = event => {
    const {
      target: { value },
    } = event;

    const filterForAll = value?.filter(sel => sel && sel?.store_id !== null);
    if (
      filterForAll?.length > 0 &&
      value[value?.length - 1]?.store_id !== null
    ) {
      setSelectedStoreView(filterForAll);
      setSelectedStoreValue(
        filterForAll?.length > 0
          ? filterForAll?.map(val => val?.store_id)?.join(',')
          : null
      );
    } else {
      setSelectedStoreView([]);
      setSelectedStoreValue(null);
    }
  };
  const handleWorkroomChange = event => {
    const {
      target: { value },
    } = event;

    const countMap = new Map();
    value?.forEach(item => {
      if (item) {
        if (countMap.has(item.type_id)) {
          countMap.set(item.type_id, countMap.get(item.type_id) + 1);
        } else {
          countMap.set(item.type_id, 1);
        }
      }
    });
    const filterForAll = value?.filter(
      item => item && countMap.get(item.type_id) === 1 && item?.type_id !== null
    );

    if (value?.length) {
      setSelectedWorkroomView(filterForAll);
    } else {
      setSelectedWorkroomView([]);
    }
  };

  const handleInstallerTypeChange = event => {
    const {
      target: { value },
    } = event;
    const filterForAll = value?.filter(sel => sel && sel?.worktypeid !== null);

    if (
      filterForAll?.length > 0 &&
      value[value?.length - 1]?.worktypeid !== null
    ) {
      setSelectedInstallerTypeIds(filterForAll);
      setInstallerTypeValue(
        filterForAll?.length > 0
          ? filterForAll?.map(val => val?.worktypeid)?.join(',')
          : null
      );
    } else {
      setSelectedInstallerTypeIds([
        {
          worktype: 'All',
          worktypeid: null,
        },
      ]);
      setInstallerTypeValue(null);
    }
  };

  const containsText = (text, searchText) =>
    text?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1;

  const displayedTypeOptions = React.useMemo(
    () =>
      installerOptions?.length > 0
        ? installerOptions.filter(option =>
            searchTypeText.length > 0
              ? containsText(
                  option.first_name + '' + option.last_name,
                  searchTypeText
                )
              : option
          )
        : [],
    [searchTypeText, installerOptions]
  );

  const displayTypeCoverageOption = React.useMemo(
    () =>
      projectTypeOptions.filter(option =>
        searchTypeCoverageText.length > 0
          ? containsText(option.project_type, searchTypeCoverageText)
          : option
      ),
    [searchTypeCoverageText, projectTypeOptions]
  );

  const displayedCategoryOptions = React.useMemo(
    () =>
      categoryOptions.filter(option =>
        searchCategoryText.length > 0
          ? containsText(option.category, searchCategoryText)
          : option
      ),
    [searchCategoryText, categoryOptions]
  );

  const fetchStore = async () => {
    if (searchStoreText?.length > 2) {
      const result = await getDropdownDetails({
        fieldName: 'store',
        searchValue: searchStoreText,
      });
      if (result) {
        const allStoreOptions = [...selectedStoreView, ...result];
        const uniqueStoreIds = new Set();
        const filteredStoreOptions = allStoreOptions?.filter(options => {
          const storeId = options?.store_id;
          if (uniqueStoreIds.has(storeId)) {
            return false;
          }
          uniqueStoreIds.add(storeId);
          return true;
        });
        setDisplayedStoreOptions(filteredStoreOptions);
      }
    }
  };

  React.useEffect(() => {
    fetchStore();
  }, [searchStoreText]);

  const fetchWorkroom = async () => {
    if (searchWorkroomText?.length > 2) {
      const dataList = await getDropdownDetails({
        fieldName: 'workroomId',
        searchValue: searchWorkroomText,
      });
      setWorkRoomOptions(dataList);
    }
  };
  React.useEffect(() => {
    fetchWorkroom();
  }, [searchWorkroomText]);

  const handleClosePopup = () => {
    setInstallerFilterPopUp(false);
    setVisibleFilter(false);
    setSelectedTypeIds([]);
    setSelectedDistricts([]);
    setSelectedUserTypes([]);
    setSelectedTypeCoverageIds([]);
    setSelectedCategoryIds([]);
    setTeamValue('');
    setInstallerFilterValue('');
    setTypeCoverageValue('');
    setSelectedCategoryValue('');
    setSelectedStoreValue('');
    setSelectedStoreView([]);
    setStoreValueOptions('');
    setSelectedWorkroomView([]);
  };

  const installerFilterformik = useFormik({
    initialValues: {
      installerFilterValue: installerFilterValue,
      selectedStoreValue: selectedStoreValue,
      selectedCategoryValue: selectedCategoryValue,
      typeCoverageValue: typeCoverageValue,
    },
    onSubmit: (values, { resetForm }) => {
      if (installerFilterValue?.length > 0) {
        const data = {
          installerIds: installerFilterValue,
        };
        setFilterCalenderData(data);
        setInstallerFilterPopUp(false);
        setVisibleFilter(false);
      } else if (
        selectedCategoryValue?.length > 0 ||
        selectedStoreValue?.length > 0 ||
        typeCoverageValue?.length > 0 ||
        selectedDistricts?.length > 0 ||
        selectedUserTypes?.length > 0 ||
        selectedWorkroomView.length > 0
      ) {
        const data = {
          projectCategoryId: selectedCategoryValue,
          projectStoreId: selectedStoreValue,
          projectTypeIds: typeCoverageValue,
          workRoomIds:
            selectedWorkroomView
              .filter(workRoom => workRoom?.type_id)
              .map(workRoom => workRoom?.type_id)
              .join(',') || '',
          districts:
            selectedDistricts?.length > 0 ? selectedDistricts?.join(',') : '',
          userTypeIds:
            selectedUserTypes?.length > 0
              ? selectedUserTypes?.map(u => u?.user_type_id)?.join(',')
              : '',
          isJobCoverage: 'true',
        };
        setFilterCalenderData(data);
        setInstallerFilterPopUp(false);
        setVisibleFilter(false);
      }
    },
  });

  const handleResetFilters = async () => {
    setInstallerFilterPopUp(false);
    !!childGetParms &&
      (await getCalendarData(
        childGetParms,
        setCalendarData,
        setCalendarLoading,
        setAlert,
        setFilterCalenderData
      ));
    setSelectedTypeIds([]);
    setSelectedDistricts([]);
    setSelectedUserTypes([]);
    setSelectedTypeCoverageIds([]);
    setSelectedCategoryIds([]);
    setTeamValue('');
    setInstallerFilterValue('');
    setTypeCoverageValue('');
    setSelectedCategoryValue('');
    setSelectedStoreValue('');
    setSelectedStoreView([]);
    setSelectedWorkroomView([]);
    setStoreValueOptions('');
    setFilterCalenderData('');
    setDistrictOptions([]);
    setWorkRoomOptions([]);
  };

  const handleApplyFilter = () => {
    setSearchQuery(prevState => ({
      ...prevState,
      projectCategoryIds: selectedFilter.projectCategoryIds
        .map(category => category.project_category_id)
        .join(','),
      installerIds: selectedFilter.installerIds
        .map(installer => installer?.installer_id)
        ?.join(','),
      projectTypeIds: selectedFilter.projectTypeIds
        .map(type => type.project_type_id)
        .join(','),
      storeIds: selectedFilter.storeIds.map(store => store.store_id).join(','),
      statusId: selectedFilter.statusId
        .map(status => status.status_id)
        .join(','),
      teams: selectedFilter.teams.map(team => team.team_id).join(','),
      startDate: selectedFilter?.startDate
        ? selectedFilter?.startDate
        : currDate,
      endDate: selectedFilter?.endDate ? selectedFilter?.endDate : currDate,
    }));
    setApplyFilter(true);
  };

  const handleStoresChange = event => {
    setCheckedStores(event.target.checked);
    if (!event.target.checked) {
      const filteredData = mapData?.filter(item => !item.is_store);
      setMapData(filteredData);
    }
  };
  const handleAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: event.target.checked ? 1 : 0,
      unAssigned: searchQuery?.unAssigned,
    }));
    setCheckedAssigned(event.target.checked);
  };
  const handleUnAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: searchQuery?.assigned,
      unAssigned: event.target.checked ? 1 : 0,
    }));
    setCheckedUnAssigned(event.target.checked);
  };

  return (
    <>
      {openScheduleModel ? <BlockLoader /> : null}
      <Grid item xs={12}>
        {alert.exists && (
          <>
            <Alert /> <br />
          </>
        )}
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item container spacing={2}>
          <Grid item xs={12} md={7}>
            <PageHeader pageTitle="Scheduler" />
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container alignItems="center" spacing={2}>
              <CustomFilter
                defaultFilters={defaultFilters}
                handleDefaultFilters={handleDefaultFilters}
                setSelectedFilters={setSelectedFilters}
                fetchDefaultFilterList={fetchDefaultFilterList}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          <>
            <Grid
              item
              classes={{ root: classes.dataTableWrapper }}
              xs={12}
              style={{ display: calendarFullScreen ? 'none' : undefined }}
            >
              {schedulerLoader ? (
                <div className="flex justify-cintent-center">
                  <TableLoader columnCount={6} noOfRow={9} mTopBottom={0} />
                </div>
              ) : (
                <GenericDataTable
                  title={'Ready to Schedule Report'}
                  columns={readyToScheuleTableColumns}
                  data={readyToScheduleDataArray}
                  options={readyToScheduleDataTableOptions}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ display: calendarFullScreen ? 'none' : undefined }}
            >
              {(view === 'calendar' || view === 'split') && (
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={installerCalendarFilterPopUp}
                  >
                    Filter Technician
                  </Button>
                </Grid>
              )}

              <Grid
                container
                item
                justifyContent="center"
                // xs={view === 'calendar' || view === 'split' ? 8 : 12}
                xs={12}
                md={10}
                className="mt-2 md:mt-0 calendar-head"
              >
                <Grid item>
                  <Button
                    variant={view === 'fullCalendar' && 'contained'}
                    color="primary"
                    onClick={() => buttonHandler('fullCalendar')}
                  >
                    Calendar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={view === 'calendar' && 'contained'}
                    color="primary"
                    onClick={() => buttonHandler('calendar')}
                  >
                    Technician View
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={view === 'map' && 'contained'}
                    color="primary"
                    onClick={() => buttonHandler('map')}
                  >
                    Map
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant={view === 'split' && 'contained'}
                    onClick={() => buttonHandler('split')}
                  >
                    Split View
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        {view === 'fullCalendar' && (
          <NewScheduler
            reportData={reportData?.items}
            calendarViewAPICall={calendarViewAPICall}
            setCalendarViewAPICall={setCalendarViewAPICall}
            calendarFilterData={calendarFilterData}
          />
        )}
        {view === 'calendar' || view === 'split' ? (
          <Grid
            item
            xs={12}
            lg={view === 'split' && !calendarFullScreen ? 6 : 12}
            md={view === 'split' && !calendarFullScreen ? 6 : 12}
            sm={12}
          >
            <Paper classes={{ root: classes.calendarPaper }} elevation={0}>
              <Grid container direction="column">
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <CalenderContainer
                    viewEditProjectDetails={viewEditProjectDetails}
                    calendarFilterData={calendarFilterData}
                    setCalendarFilterData={setCalendarFilterData}
                    setReloadList={setReloadList}
                    projectDataLoading={projectDataLoading}
                    calendarFullScreen={calendarFullScreen}
                    setCalendarFullScreen={setCalendarFullScreen}
                    reloadCalender={reloadCalender}
                    setSelected={setSelected}
                    reportData={reportData?.items}
                    statusOptions={statusOptions}
                    filterCalenderData={filterCalenderData}
                    setFilterCalenderData={setFilterCalenderData}
                    setSelectedTypeIds={setSelectedTypeIds}
                    setSelectedTypeCoverageIds={setSelectedTypeCoverageIds}
                    setSelectedCategoryIds={setSelectedCategoryIds}
                    setSelectedStoreIds={setSelectedStoreValue}
                    setTeamValue={setTeamValue}
                    setChildGetParms={setChildGetParms}
                    calendarDataResetData={calendarData}
                    setSelectedStoreView={setSelectedStoreView}
                    schedulerLoader={schedulerLoader}
                    setSelectedWorkroomView={setSelectedWorkroomView}
                    installerOptions={installerOptions}
                    setOpenScheduleModel={setOpenScheduleModel}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          ''
        )}
        {(view === 'map' || view === 'split') && !calendarFullScreen && (
          <Grid
            item
            xs={12}
            lg={view === 'split' ? 6 : 12}
            md={view === 'split' ? 6 : 12}
            sm={12}
          >
            <Paper classes={{ root: classes.mapPaper }} elevation={0}>
              <Grid container direction="column">
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <MapContainer
                    lat={59.95}
                    long={30.33}
                    markers={mapData}
                    setActiveRow={setActiveRow}
                    viewEditProjectDetails={viewEditProjectDetails}
                    categoryOptions={categoryOptions}
                    typeOptions={typeOptions}
                    installerOptions={installerOptions}
                    statusOptions={statusOptions}
                    storeOptions={storeOptions}
                    teamOptions={teamOptions}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    mapDate={mapDate}
                    setMapDate={setMapDate}
                    changeMapDate={changeMapDate}
                    selected={selected}
                    setSelected={setSelected}
                    makeResetbtnActive={makeResetbtnActive}
                    showErrorMsg={showErrorMsg}
                    setStoreSearchValueOnMap={setStoreSearchValueOnMap}
                    storeLoadingMap={storeLoadingMap}
                    handleApplyFilter={handleApplyFilter}
                    setSelectedFilter={setSelectedFilter}
                    handleStoresChange={handleStoresChange}
                    handleAssignedChange={handleAssignedChange}
                    handleUnAssignedChange={handleUnAssignedChange}
                    checkedAssigned={checkedAssigned}
                    checkedUnAssigned={checkedUnAssigned}
                    checkedStores={checkedStores}
                    mapFilterApi={setMapFilterApi}
                    setFilterDates={setFilterDates}
                    currDate={currDate}
                  />
                </Grid>
                <br /> <br />
              </Grid>
            </Paper>
          </Grid>
        )}
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          dialogWidth="lg"
          handleClose={() => {
            setIsOpen(false);
            projectDetailsFormik?.handleReset();
          }}
          handleSave={projectDetailsFormik.handleSubmit}
          dialogSettings={dialogSettings}
          disabledButton2={
            !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
          }
          disabledButton1={false}
        >
          <Grid container direction="column" spacing={2}>
            <>
              <Grid item>{alert.exists && <Alert />}</Grid>
              <Grid item className={classes.projectRedirectButton}>
                {' '}
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      `/project/view/${projectData?.project_id}`,
                      '_blank'
                    );
                  }}
                  disabled={
                    !checkPermission(
                      permissions?.viewEditProject?.actionEditScheduler
                    )
                  }
                >
                  View Project
                </Button>
              </Grid>
              <Grid item>
                <form onSubmit={projectDetailsFormik.handleSubmit}>
                  <ProjectDetailsViewEditForm
                    projectDetailsFormik={projectDetailsFormik}
                    rescheduleReasonOptions={rescheduleReasonOptions}
                  />
                </form>
              </Grid>
              <Grid item className={classes.tableWrapper}>
                {isOpen && (
                  <Installer
                    formik={{
                      values: {
                        project_id: projectData.project_id,
                        date_scheduled:
                          projectDetailsFormik?.values?.date_scheduled,
                        proj_end_date:
                          projectDetailsFormik?.values?.project_end_date,
                      },
                    }}
                    setReloadCalender={setReloadCalender}
                    reloadCalender={reloadCalender}
                    projectId={projectData.project_id}
                    schedulerEditPermission={
                      !checkPermission(
                        permissions?.viewEditProject?.actionEditScheduler
                      )
                    }
                    openProjectInfoDialog={isOpen}
                  />
                )}
              </Grid>
              <Grid item className={classes.tableWrapper}>
                <Notes
                  formik={{ values: { project_id: projectData.project_id } }}
                  action=""
                  schedulerEditPermission={
                    !checkPermission(
                      permissions?.viewEditProject?.actionEditScheduler
                    )
                  }
                />
              </Grid>
            </>
          </Grid>
        </GenericDialog>
      </Grid>

      {visibleFilter ? (
        <Modal
          open={visibleFilter}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          onClose={event => {
            setVisibleFilter(false);
          }}
          className={classes.modal}
        >
          <Grid
            container
            item
            direction="row"
            spacing={2}
            className={`${classes.boxWidth} filter-installer`}
          >
            <Grid item xs={12} md={5}>
              <h4 className="mt-0">Filter Technician</h4>
            </Grid>
            <form
              className={classes.formWrapper}
              onSubmit={event => {
                event.preventDefault();
                installerFilterformik.handleSubmit();
              }}
            >
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box flexDirection="row">
                    <Box m={1} className={classes.boxMinHeight}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          Technician
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedTypeIds
                            .map(a => a && a?.first_name + '' + a?.last_name)
                            .join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="type-checkbox-label"
                            id="type-checkbox"
                            multiple
                            className={classes.selectStyle}
                            value={selectedTypeIds || []}
                            onChange={handleTypeChange}
                            onClose={() => setSearchTypeText('')}
                            input={<Input label="Tag" />}
                            renderValue={selected => {
                              return selected.map((value, index) => {
                                const option = displayedTypeOptions?.find(
                                  o => o?.user_id === value?.user_id
                                );
                                let addedComma =
                                  index == 0 || index == selected?.length - 1
                                    ? ' '
                                    : ', ';
                                return (
                                  option &&
                                  (option !== null || undefined) && (
                                    <Chip
                                      label={
                                        option.first_name +
                                          '' +
                                          option.last_name || 'All'
                                      }
                                      key={index}
                                    />
                                  )
                                );
                              });
                            }}
                            disabled={
                              selectedCategoryValue ||
                              selectedStoreValue ||
                              typeCoverageValue ||
                              selectedDistricts?.length > 0 ||
                              selectedUserTypes?.length > 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={e =>
                                  setSearchTypeText(e.target.value)
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>
                            {displayedTypeOptions?.length > 0 &&
                              displayedTypeOptions?.map(type => (
                                <MenuItem key={type.user_id} value={type}>
                                  <Checkbox
                                    checked={
                                      selectedTypeIds?.filter(
                                        o => o?.user_id === type?.user_id
                                      )?.length > 0
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      type.first_name + ' ' + type.last_name
                                    }
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                      <FormControl className="mt-2">
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          Type
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedTypeCoverageIds
                            ?.map(a => a && a?.project_type)
                            ?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="type-checkbox-label"
                            id="type-checkbox"
                            multiple
                            className={classes.selectStyle}
                            value={selectedTypeCoverageIds || []}
                            onChange={handleTypeCoverageChange}
                            onClose={() => setSearchTypeCoverageText('')}
                            input={<Input label="Tag" />}
                            renderValue={selected => {
                              return selected?.map((value, index) => {
                                const option = displayTypeCoverageOption?.find(
                                  o => o?.project_type === value?.project_type
                                );
                                let addedComma =
                                  index === 0 || index === selected?.length - 1
                                    ? ' '
                                    : ', ';
                                return (
                                  option &&
                                  (option !== null || undefined) && (
                                    <Chip
                                      label={option?.project_type || 'All'}
                                      key={index}
                                    />
                                  )
                                );
                              });
                            }}
                            disabled={installerFilterValue ? true : false}
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={e =>
                                  setSearchTypeCoverageText(e.target.value)
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>

                            {displayTypeCoverageOption?.length > 0 &&
                              displayTypeCoverageOption?.map(type => (
                                <MenuItem
                                  key={type?.project_type_id}
                                  value={type}
                                >
                                  <Checkbox
                                    checked={
                                      selectedTypeCoverageIds?.filter(
                                        o =>
                                          o?.project_type_id ===
                                          type?.project_type_id
                                      )?.length > 0
                                    }
                                  />
                                  <ListItemText primary={type?.project_type} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                      <FormControl className="mt-2">
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          Store
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedStoreView
                            ?.map(a => a && a?.store_name)
                            ?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="type-checkbox-label"
                            id="type-checkbox"
                            multiple
                            className={classes.selectStyle}
                            value={selectedStoreView || []}
                            onChange={handleStoreChange}
                            onClose={() => setSearchStoreText('')}
                            input={<Input label="Tag" />}
                            renderValue={selected => {
                              return selected?.map((value, index) => {
                                const option = displayedStoreOptions?.find(
                                  o => o?.store_id === value?.store_id
                                );
                                let addedComma =
                                  index === 0 || index === selected?.length - 1
                                    ? ' '
                                    : ', ';
                                return (
                                  option &&
                                  (option !== null || undefined) && (
                                    <Chip
                                      label={option?.store_name || 'All'}
                                      key={index}
                                    />
                                  )
                                );
                              });
                            }}
                            disabled={
                              installerFilterValue ||
                              selectedDistricts?.length > 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type at least 3 character to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={e =>
                                  setSearchStoreText(e.target.value)
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>
                            {displayedStoreOptions?.length > 0 &&
                              displayedStoreOptions?.map(type => (
                                <MenuItem key={type?.store_id} value={type}>
                                  <Checkbox
                                    checked={
                                      selectedStoreView?.filter(
                                        o => o?.store_id === type?.store_id
                                      )?.length > 0
                                    }
                                  />
                                  <ListItemText primary={type?.store_name} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                      <FormControl className="mt-2">
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          Category
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedCategoryIds
                            ?.map(a => a && a?.category)
                            ?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="type-checkbox-label"
                            id="type-checkbox"
                            multiple
                            className={classes.selectStyle}
                            value={selectedCategoryIds || []}
                            onChange={handleCategoryChange}
                            onClose={() => setSearchCategoryText('')}
                            input={<Input label="Tag" />}
                            renderValue={selected => {
                              return selected?.map((value, index) => {
                                const option = displayedCategoryOptions?.find(
                                  o =>
                                    o?.project_category_id ===
                                    value?.project_category_id
                                );
                                let addedComma =
                                  index === 0 || index === selected?.length - 1
                                    ? ' '
                                    : ', ';
                                return (
                                  option &&
                                  (option !== null || undefined) && (
                                    <Chip
                                      label={option?.category || 'All'}
                                      key={index}
                                    />
                                  )
                                );
                              });
                            }}
                            disabled={installerFilterValue ? true : false}
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={e =>
                                  setSearchCategoryText(e.target.value)
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>

                            {displayedCategoryOptions?.length > 0 &&
                              displayedCategoryOptions?.map(type => (
                                <MenuItem
                                  key={type?.project_category_id}
                                  value={type}
                                >
                                  <Checkbox
                                    checked={
                                      selectedCategoryIds?.filter(
                                        o =>
                                          o?.project_category_id ===
                                          type?.project_category_id
                                      )?.length > 0
                                    }
                                  />
                                  <ListItemText primary={type?.category} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </Box>
                    <Box m={1} className={classes.boxMinHeight}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          District
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedDistricts?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="district-label"
                            id="district"
                            multiple
                            className={classes.selectStyle}
                            value={selectedDistricts || []}
                            disabled={
                              installerFilterValue || selectedStoreValue
                                ? true
                                : false
                            }
                            onChange={handleDistrictChange}
                            onClose={handleDistrictClose}
                            input={<Input label="Tag" />}
                            renderValue={() =>
                              selectedDistricts
                                ?.filter(item => !!item)
                                ?.map(value => (
                                  <Chip key={value} label={value} />
                                ))
                            }
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleDistrictSearch}
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>
                            {districtFilterOptions?.length > 0 &&
                              districtFilterOptions?.map(district => (
                                <MenuItem key={district} value={district}>
                                  <Checkbox
                                    checked={selectedDistricts?.some(
                                      d => d === district
                                    )}
                                  />
                                  <ListItemText primary={district} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </Box>
                    <Box m={1} className={classes.boxMinHeight}>
                      <FormControl>
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          User Type
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedUserTypes
                            ?.map(u => u?.label)
                            ?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="user-type-label"
                            id="user-type"
                            multiple
                            className={classes.selectStyle}
                            value={selectedUserTypes || []}
                            disabled={installerFilterValue ? true : false}
                            onChange={handleUserTypeChange}
                            onClose={handleUserTypeClose}
                            input={<Input label="Tag" />}
                            renderValue={() =>
                              selectedUserTypes
                                ?.filter(user => !!user)
                                ?.map(user => (
                                  <Chip
                                    key={user?.user_type_id}
                                    label={user?.label}
                                  />
                                ))
                            }
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleUserTypeSearch}
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>
                            {userTypesOptions?.length > 0 &&
                              userTypesOptions?.map(user => (
                                <MenuItem key={user?.user_type_id} value={user}>
                                  <Checkbox
                                    checked={selectedUserTypes?.some(
                                      u =>
                                        u?.user_type_id === user?.user_type_id
                                    )}
                                  />
                                  <ListItemText primary={user?.label} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>

                      <FormControl className="mt-2">
                        <InputLabel
                          shrink
                          id="type-checkbox-label"
                          className={classes.label}
                        >
                          Workroom
                        </InputLabel>
                        <Tooltip
                          arrow
                          style={{ fontWeight: 'bold' }}
                          title={selectedWorkroomView
                            ?.map(a => a && a?.label)
                            ?.join(', ')}
                          placement="left-start"
                        >
                          <Select
                            labelId="type-checkbox-label"
                            id="type-checkbox"
                            multiple
                            className={classes.selectStyle}
                            value={selectedWorkroomView || []}
                            onChange={handleWorkroomChange}
                            onClose={() => setSearchWorkroomText('')}
                            input={<Input label="Tag" />}
                            renderValue={selected => {
                              return selected?.map((value, index) => {
                                const option = selectedWorkroomView?.find(
                                  o => o?.label === value?.label
                                );
                                let addedComma =
                                  index === 0 || index === selected?.length - 1
                                    ? ' '
                                    : ', ';
                                return (
                                  option &&
                                  (option !== null || undefined) && (
                                    <Chip
                                      label={option?.label || 'All'}
                                      key={index}
                                    />
                                  )
                                );
                              });
                            }}
                            disabled={
                              installerFilterValue ||
                              selectedDistricts?.length > 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem>
                              <TextField
                                size="small"
                                placeholder="Type at least 3 character to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={e =>
                                  setSearchWorkroomText(e.target.value)
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </MenuItem>
                            {workRoomOptions?.length > 0 &&
                              workRoomOptions?.map(type => (
                                <MenuItem key={type?.label} value={type}>
                                  <Checkbox
                                    checked={
                                      selectedWorkroomView?.filter(
                                        o => o?.label === type?.label
                                      )?.length > 0
                                    }
                                  />

                                  <ListItemText primary={type?.label} />
                                </MenuItem>
                              ))}
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={3}> */}
                <div className="w-12 flex justify-content-end pr-2 my-2">
                  <Button
                    outlined
                    color="primary"
                    onClick={() => handleClosePopup()}
                    className={classes.margLeft}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    outlined
                    className="ml-2"
                    onClick={() => handleResetFilters()}
                    className={classes.margLeft}
                  >
                    Filter Reset
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    className="ml-2"
                    color="primary"
                  >
                    Apply Filters
                  </Button>
                </div>
              </Grid>
            </form>
          </Grid>
        </Modal>
      ) : null}
    </>
  );
};

export default ProjectReport;
