import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneType: [],
};

const phoneTypeSlice = createSlice({
  name: 'phoneType',
  initialState: initialState,
  reducers: {
    setPhoneType(state, { payload }) {
      state.phoneType = payload;
    },
  },
});

export const { setPhoneType } = phoneTypeSlice.actions;

export const phoneTypeReducer = phoneTypeSlice.reducer;
