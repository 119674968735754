// **** React Imports ****
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// **** External Utilities ****
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import { Edit as EditIcon } from '@material-ui/icons';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { Alert } from '../../shared/Alerts/Alert';
import { useAlerts } from '../../shared/Alerts/alertsService';

import ClientInfo from './ClientInfo';
import ClientProjects from './ClientProjects';
// **** Services *****
import { getClientById, addClient, updateClient } from './Clients.service';

// **** Styles/Images/Icons ****
import { useStyles } from './AddViewEditClientDetails.styles';

const AddViewEditClientDetails = () => {
  //Get clientId from url params
  const { clientId } = useParams();
  const action = window.location.pathname.split('/')[2];
  const history = useHistory();
  const { alert, setAlert, clearAlert } = useAlerts();
  const classes = useStyles();
  const [clientDetailsData, setClientDetailsData] = React.useState();
  const [reloadForm, setReloadForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const clientDetailsBreadcrumb = [
    {
      text: 'Project Management',
    },
    {
      link: '/project/clients',
      text: 'Clients',
    },
    {
      text: action === 'add' ? 'Add Client' : 'Client Details',
    },
  ];

  React.useEffect(() => {
    if (action !== 'add') {
      getClientById(clientId, setLoading, setAlert, setClientDetailsData);
      setReloadForm(false);
    }
  }, [clientId, reloadForm]);
  React.useEffect(() => {
    action !== 'view' ? clearAlert() : null;
  }, []);

  Yup.addMethod(
    Yup.array,
    'phone_number_unique',
    function (message, mapper = a => a) {
      return this.test('unique', message, function (list) {
        for (let i = 0; i < list?.length; i++) {
          if (i === 0) continue;
          if (!(list?.length === new Set(list.map(mapper)).size)) {
            return this.createError({
              path: `customer_phones.${i}.phone_number`,
              message: message,
            });
          }
        }
        return true;
      });
    }
  );

  // **** Form Validation Schema ****
  const addClientFormValidationSchema = Yup.object().shape({
    customer_id: Yup.string().trim(),
    first_name: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[a-zA-ZÀ-ÿ-.' ]*$/, 'Please enter a valid First Name.'),
    last_name: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[a-zA-ZÀ-ÿ-.' ]*$/, 'Please enter a valid Last Name.'),
    street: Yup.string().trim().required('Required'),
    city: Yup.string()
      .trim()
      .required('Required')
      .matches(/^[a-zA-ZÀ-ÿ-.' ]*$/, 'Please enter a valid City Name.'),
    state: Yup.string().trim().required('Required'),
    zip: Yup.number()
      .typeError('Please enter numbers only')
      .min(1)
      .required('Required'),
    latitude: Yup.string().trim(),
    longitude: Yup.string().trim(),
    primary_mode_of_contact: Yup.string().trim().required('Required'),
    receive_text_message: Yup.boolean().required('Required'),
    customer_related_notes: Yup.string().trim().nullable(),
    projects: Yup.array(),
    customer_phones: Yup.array()
      .of(
        Yup.object().shape({
          phone_number: Yup.string()
            .trim()
            .required('Required')
            .matches(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, 'Invalid'),
          phone_type: Yup.object()
            .shape({
              name: Yup.string().trim().required('Required'),
            })
            .required(),
        })
      )
      .phone_number_unique('Duplicate Contact number', a => a.phone_number)
      .nullable()
      .required('Required')
      .test('at-least-one-mobile', 'you must provide at least one', value =>
        value?.filter(p => p.phone_type?.phone_type_id === 2)
      ),
    customer_emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().trim().email('Invalid Email'),
          email_type: Yup.string().trim(),
        })
      )
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      customer_id: action === 'add' ? '' : clientDetailsData?.customer_id,
      first_name: action === 'add' ? '' : clientDetailsData?.first_name,
      last_name: action === 'add' ? '' : clientDetailsData?.last_name,
      street: action === 'add' ? '' : clientDetailsData?.address?.address1,
      city: action === 'add' ? '' : clientDetailsData?.address?.city,
      state: action === 'add' ? '' : clientDetailsData?.address?.state,
      zip: action === 'add' ? '' : clientDetailsData?.address?.zipcode,
      latitude:
        action === 'add' ? '' : clientDetailsData?.address?.latitude || '',
      longitude:
        action === 'add' ? '' : clientDetailsData?.address?.longitude || '',
      primary_mode_of_contact:
        action === 'add' ? '' : clientDetailsData?.primary_mode_of_contact,
      receive_text_message:
        action === 'add'
          ? false
          : clientDetailsData?.receive_text_message === 1
            ? true
            : false,
      is_deleted:
        action === 'add'
          ? false
          : clientDetailsData?.is_deleted === 1
            ? true
            : false,
      customer_related_notes:
        action === 'add' ? '' : clientDetailsData?.customer_related_notes,
      projects: action === 'add' ? '' : clientDetailsData?.project,
      customer_phones:
        action === 'add'
          ? [
              {
                phone_number: '',
                phone_type: { phone_type_id: 2, name: 'Mobile' },
              },
            ]
          : clientDetailsData?.customer_phones,
      customer_emails:
        action === 'add'
          ? [{ email: '', email_type: 'Primary' }]
          : clientDetailsData?.customer_emails,
      customer_phones_deleted: [],
      customer_emails_deleted: [],
    },
    validationSchema: addClientFormValidationSchema,
    validateForm: true,
    onSubmit: values => {
      setLoading(true);
      if (action === 'edit') {
        updateClient(clientId, values, history, setLoading, setAlert);
      } else {
        addClient(values, setLoading, setAlert, history);
      }
    },
    enableReinitialize: true,
  });
  return (
    <>
      <form className={classes.formWrapper}>
        <Grid container direction="column" spacing={2}>
          {alert.exists && (
            <Grid item>
              {' '}
              <Alert />
            </Grid>
          )}
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <PageHeader
                pageTitle={action === 'add' ? 'Add Client' : 'Client Info'}
                breadCrumbArray={clientDetailsBreadcrumb}
              />
            </Grid>

            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                spacing={2}
              >
                {action === 'view' && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={loading}
                      onClick={() => history.push('/project/clients')}
                    >
                      Back
                    </Button>
                  </Grid>
                )}
                {(action === 'add' || action === 'edit') && (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          if (action === 'edit') {
                            history.push(`/crm/view/customer/${clientId}`);
                            formik.resetForm();
                          } else {
                            history.goBack();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!formik?.dirty || !formik.isValid}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        Save and Close
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : clientDetailsData || action === 'add' ? (
            <>
              <Grid item spacing={2} xs={12}>
                <Paper square className={classes.paperRoot}>
                  <Grid container direction="column">
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="space-between"
                      className={classes.containerHeader}
                      xs={12}
                    >
                      <Grid item>
                        <Typography variant="h3">Client Info</Typography>
                      </Grid>
                      <Grid item>
                        {action == 'view' && (
                          <Button
                            startIcon={
                              <EditIcon className={classes.editIcon} />
                            }
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              history.push(`/clients/edit/${clientId}`)
                            }
                          >
                            Edit
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid item className={classes.containerContent}>
                      <FormikProvider value={formik}>
                        <ClientInfo
                          setLoading={setLoading}
                          formik={formik}
                          action={action}
                        />
                      </FormikProvider>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item spacing={2} xs={12}>
                {action === 'view' && (
                  <ClientProjects
                    projects={formik?.values?.projects}
                    clientId={clientId}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Paper square className={classes.paperRoot}>
              <Grid className={classes.clientNotFound}>
                <Typography variant="h6">Client Not found</Typography>
              </Grid>
            </Paper>
          )}
        </Grid>
      </form>
    </>
  );
};

export default AddViewEditClientDetails;
