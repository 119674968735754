import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  clientNotFound: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    minHeight: '250px',
    alignItems: 'center',
  },
  container: {
    maxHeight: 440,
  },
  containerHeader: {
    height: '50px',
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '22px',
    paddingBottom: '14px',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
  },
  containerContent: {
    display: 'flex',
    padding: '24px',
  },
  formWrapper: {
    width: '100%',
  },
  footer: {
    position: 'relative',
    bottom: 0,
    paddingTop: '0px',
    marginTop: '10px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      maxWidth: 80,
      width: '100%',
    },
  },
  paperRoot: {
    minHeight: '250px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '12px',
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  tableContainer: {
    maxHeight: 440,
  },
  tableFirstColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 9,
  },
  tableFirstColumnBody: {
    position: 'sticky',
    left: 0,
    zIndex: 6,
    background: 'white',
    border: '1px solid grey',
  },
}));
