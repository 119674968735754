import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';

import PFTabView from '../../../shared/PFPrime/PFTabView';
import OpportunitiesTab from '../../Opportunities/OpportunitiesTab';
import NotesComponent from '../../Communications/components/NotesComponent';
import EmailComponent from '../../Communications/components/EmailComponent';
import SMSComponent from '../../Communications/components/SMSComponent';
import QuoteListing from '../../Quotes/components/QuoteListing';
import ProjectsListing from '../../Projects/components/ProjectsListing';
import QuoteContainer from '../../Quotes/QuoteContainer';
import {
  isCrmEnabled,
  isMeasureSquareEnabled,
} from '../../../../utils/Helpers';
import MeasureSquareQuotes from '../../Projects/components/MeasureSquareQuotes';

const CustomerOperations = ({ customerId }) => {
  const { quoteListLayout: QuoteListLayout, apiCallQuoteList } =
    QuoteListing(true);
  const isCrm = isCrmEnabled();
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  const isMSEnabled = clientDetails?.is_measuresquare;

  const CustomerOperationsModel = {
    tabs: [
      ...(isCrm ? [{ header: 'Leads' }] : []),
      ...(isCrm ? [{ header: 'Quotes' }] : []),
      ...(isMSEnabled ? [{ header: 'MeasureSquare' }] : []),
      { header: 'Projects', disabled: false },
      { header: 'Notes', disabled: false },
      { header: 'SMS', disabled: false },
      { header: 'Email', disabled: false },
    ],
    components: {
      ...(isCrm && { Leads: <OpportunitiesTab /> }),
      ...(isCrm && {
        Quotes: (
          <div className="py-1 px-3">
            <QuoteListLayout isGlobalLevel={true} />
          </div>
        ),
      }),
      ...(isMSEnabled && {
        MeasureSquare: (
          <div className="py-1 px-3">
            <MeasureSquareQuotes isGlobalLevel={true} />
          </div>
        ),
      }),
      Projects: <ProjectsListing quoteNumber={true} opportunityNumber={true} />,
      Notes: <NotesComponent customerId={customerId} />,
      SMS: <SMSComponent customerId={customerId} />,
      Email: <EmailComponent customerId={customerId} />,
    },
  };

  return (
    <div className="w-full pt-3 crm p-0">
      <PFTabView model={CustomerOperationsModel} />
    </div>
  );
};

export default CustomerOperations;
