// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Stores List
export const getClientProjects = async (
  clientId,
  searchQuery,
  setLoading,
  setClientProjectsListData
) => {
  try {
    setLoading(true);
    console.l;
    const newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    //?${newQueryString}
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/customers/${clientId}/projects?${newQueryString}`
    );
    console.log(response.data);
    setClientProjectsListData(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
