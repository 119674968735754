import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';

import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFInputMask from '../../../shared/PFPrime/PFInputMask';

const OpportunityContact = ({
  handleChange,
  values,
  errors,
  setFieldValue,
  touched,
  customerDetails,
}) => {
  const { first_name, last_name, customer_phones, customer_emails } =
    customerDetails;
  const queryParams = useParams();
  const { mode } = queryParams;

  useEffect(() => {
    if (mode === 'create' && customerDetails) {
      setFieldValue('contactPerson', `${first_name} ${last_name}`);
      setFieldValue(
        'primaryPhone',
        customer_phones?.find(item => item.is_primary === true)?.phone_number ||
          ''
      );
      setFieldValue(
        'primaryEmail',
        customer_emails?.find(item => item.is_primary === true)?.email || ''
      );
    }
  }, [customerDetails]);

  return (
    <div className="w-full crm">
      <Card style={{ boxShadow: 'none' }} className="mt-3">
        <div htmlFor="General Information">
          <div className="grid">
            <div className="text-1xl font-bold px-3 py-2 col-12 md:col-12 lg:col-12">
              Contact Information
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    id="contactPerson"
                    name="contactPerson"
                    type="text"
                    value={values?.contactPerson || ''}
                    onChange={handleChange}
                    className={
                      touched?.contactPerson && errors?.contactPerson
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="contactPerson">
                    Contact Person <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="contactPerson"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputMask
                    id="primaryPhone"
                    name="primaryPhone"
                    value={values?.primaryPhone || ''}
                    onChange={setFieldValue}
                    mask="(999) 999-9999"
                    className={
                      touched?.primaryPhone && errors?.primaryPhone
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="primaryPhone">
                    Primary Phone Number <span style={{ color: 'red' }}>*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="primaryPhone"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputMask
                    id="alternativePhone"
                    name="alternativePhone"
                    value={values?.alternativePhone || ''}
                    onChange={setFieldValue}
                    mask="(999) 999-9999"
                    className={
                      touched?.alternativePhone && errors?.alternativePhone
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="alternativePhone">
                    Alternate Phone Number
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="contact_person_phone_additional"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    id="primaryEmail"
                    name="primaryEmail"
                    type="text"
                    value={values?.primaryEmail || ''}
                    onChange={handleChange}
                    className={
                      touched?.primaryEmail && errors?.primaryEmail
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="primaryEmail">Primary Email</label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="primaryEmail"
                />
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
              <div className="w-full">
                <span className="p-float-label">
                  <PFInputText
                    id="alternativeEmail"
                    name="alternativeEmail"
                    type="text"
                    value={values?.alternativeEmail || ''}
                    onChange={handleChange}
                    className={
                      touched?.alternativeEmail && errors?.alternativeEmail
                        ? 'error'
                        : ''
                    }
                    disabled={mode === 'view' ? true : false}
                  />
                  <label htmlFor="alternativeEmail">Alternate Email</label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="alternativeEmail"
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OpportunityContact;
