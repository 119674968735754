import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  licenseData: [],
};

const userFormLicenseSlice = createSlice({
  name: 'userFormLicense',
  initialState: initialState,
  reducers: {
    setUserLicenseForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.licenseData = payload.hasOwnProperty('licenseData')
        ? payload.licenseData
        : state.licenseData;
    },
  },
});

export const { setUserLicenseForm } = userFormLicenseSlice.actions;

export const userFormLicenseReducer = userFormLicenseSlice.reducer;
