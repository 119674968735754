// **** React Imports ****
import React from 'react';
import { Tooltip } from 'primereact/tooltip';

// **** Utilities ****
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';

// **** Custom Components *****
import { checkPermission } from '../../../utils/Helpers';

// **** Styles *****
import { useStyles } from './GenericTable.styles';

const GenericTable = ({
  title,
  headerLinks,
  columnData,
  rowData,
  showActions,
  handleView = null,
  handleEdit,
  handleDelete,
  handleClone,
  permissions,
  ...rest
}) => {
  const [showActionIcons, setShowActionIcons] = React.useState(true);
  const classes = useStyles({
    dataLength: rowData?.length,
    showActions: showActions,
    showActionIcons: showActionIcons,
  });
  const [hoverRowIndex, setHoverRowIndex] = React.useState();

  const viewPermission =
    permissions === undefined ||
    (permissions &&
      Object.keys(permissions).length &&
      (Object.keys(permissions).filter(data => data === 'view').length
        ? checkPermission(permissions?.view)
        : true || permissions === undefined));

  const editPermission =
    permissions === undefined ||
    (permissions &&
      Object.keys(permissions).length &&
      (Object.keys(permissions).filter(data => data === 'edit').length
        ? checkPermission(permissions?.edit)
        : true));

  const deletePermission =
    permissions === undefined ||
    (permissions &&
      Object.keys(permissions).length &&
      (Object.keys(permissions).filter(data => data === 'delete').length
        ? checkPermission(permissions?.delete)
        : true));

  const clonePermission =
    permissions === undefined ||
    (permissions &&
      Object.keys(permissions).length &&
      (Object.keys(permissions).filter(data => data === 'clone').length
        ? checkPermission(permissions?.clone)
        : true));

  const actionColumnData = (row, index) => {
    return showActions?.view || showActions?.edit || showActions?.delete ? (
      <TableCell classes={{ sizeSmall: classes.sizeSmall }} padding="normal">
        <>
          {viewPermission && showActions?.view ? (
            <span>
              <IconButton
                classes={{ root: classes.actionIcons }}
                className={classes.viewAction}
                onClick={() => {
                  handleView(index);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </span>
          ) : null}
          {row['hideEditIcon']?.value &&
          row['hideEditIcon']?.value === 'yes' ? (
            ''
          ) : editPermission && showActions?.edit ? (
            <span>
              <IconButton
                classes={{ root: classes.actionIcons }}
                className={classes.editAction}
                onClick={() => {
                  handleEdit(index);
                }}
              >
                <EditIcon />
              </IconButton>
            </span>
          ) : null}
          <span>
            {(title === 'Labor Items' || title === 'Merchandise Items') &&
            rowData[index]?.itemDetails?.inventory_managment ? (
              <>
                <Tooltip target=".custom-target-icon" />
                <i
                  onClick={() => rest?.handleInventory(index)}
                  className="custom-target-icon pi pi-chart-bar cursor-pointe"
                  data-pr-tooltip="Inventory Management"
                  data-pr-position="right"
                  data-pr-at="right+5 top+10"
                  data-pr-my="left center-2"
                ></i>
              </>
            ) : null}
          </span>
          {row['hideDeleteIcon']?.value &&
          row['hideDeleteIcon']?.value === 'yes' ? (
            ''
          ) : deletePermission && showActions?.delete ? (
            <span>
              <IconButton
                classes={{ root: classes.actionIcons }}
                className={classes.deleteAction}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null}
          {row['hideCloneIcon']?.value &&
          row['hideCloneIcon']?.value === 'yes' ? (
            ''
          ) : clonePermission && showActions?.clone ? (
            <span>
              <IconButton
                classes={{ root: classes.actionIcons }}
                onClick={() => handleClone(index)}
              >
                <FileCopyIcon />
              </IconButton>
            </span>
          ) : null}
        </>
      </TableCell>
    ) : (
      ''
    );
  };
  return (
    <Paper
      classes={{ root: classes.gridPaper }}
      elevation={0}
      className="border-none"
    >
      <Grid container direction="column">
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            classes={{ root: classes.titleHeaderGrid }}
          >
            <Grid item>
              <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid item>
              {headerLinks?.length
                ? headerLinks?.map((link, index) =>
                    link.type === 'checkbox' ? (
                      <FormControlLabel
                        value="top"
                        control={
                          <Checkbox
                            color="primary"
                            classes={{
                              root: classes.checkboxRoot,
                            }}
                            onChange={link.handler}
                            checked={link.value}
                          />
                        }
                        disabled={link.disabled}
                        label={link.label}
                        labelPlacement="top"
                        classes={{
                          root: classes.checkboxFormRoot,
                          labelPlacementTop: classes.checkboxLabelPlacement,
                          label: classes.checkboxLabel,
                        }}
                      />
                    ) : (
                      <Button
                        key={index}
                        size="small"
                        color="primary"
                        variant="outlined"
                        disableRipple
                        classes={{ root: classes.buttonRoot }}
                        disabled={
                          link?.add ? !checkPermission(link?.add) : false
                        }
                        onClick={() => {
                          link.handler();
                        }}
                      >
                        {link.label}
                      </Button>
                    )
                  )
                : ''}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <TableContainer classes={{ root: classes.tableContainer }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead classes={{ root: classes.tableHeader }}>
                <TableRow>
                  {(showActions?.view && viewPermission) ||
                  (showActions?.edit && editPermission) ||
                  (showActions?.delete && deletePermission) ||
                  (showActions?.clone && clonePermission) ? (
                    <TableCell
                      classes={{
                        root: classes.tableHeaderCell,
                        sizeSmall: classes.sizeSmall,
                      }}
                      width={
                        showActions?.edit || showActions?.delete ? '10%' : '11%'
                      }
                    >
                      &nbsp;&nbsp;&nbsp;
                    </TableCell>
                  ) : (
                    ''
                  )}
                  {columnData.map(
                    (column, index) =>
                      !column.hidden && (
                        <TableCell
                          key={index}
                          classes={{ root: classes.tableHeaderCell }}
                          width={column.width}
                        >
                          {column.value}
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody classes={{ root: classes.tableBody }}>
                {rowData?.length ? (
                  rowData.map((row, index) => (
                    <TableRow
                      classes={{ root: classes.TableRow }}
                      onDoubleClick={
                        handleView ? () => handleView(index) : () => {}
                      }
                      key={index}
                      hover
                      onMouseEnter={() => {
                        setHoverRowIndex(index);
                        setShowActionIcons(true);
                      }}
                      onMouseLeave={() => setShowActionIcons(true)}
                    >
                      {actionColumnData(row, index)}
                      {columnData.map(
                        (column, key) =>
                          !column.hidden && (
                            <TableCell
                              component="td"
                              scope="row"
                              key={key}
                              classes={{ root: classes.tableCell }}
                            >
                              {row[column.id]?.value}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      colSpan={columnData.length + 1}
                      align="center"
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default GenericTable;
