import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Skeleton } from 'primereact/skeleton';
import { Rating } from 'primereact/rating';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFInputText';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PFButton from '../../shared/PFPrime/PFButton';
import { getConvertedDate } from '../../../utils/date.helper';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

import {
  addAnswers,
  deleteAnswers,
  eraseAnswers,
  getAnswers,
  getModuleByTagName,
  updateAnswers,
} from './questionnaireSerivce';

const AnswerComponent = ({
  moduleTagName,
  targetId,
  moduleId,
  selectedModuleName,
  isNewRecord = false,
  selectedRecord,
  isRenderConfirmationPopup = true,
}) => {
  if (!moduleTagName || !targetId) return <></>;
  const [isModule, setIsModule] = useState(false);
  const [initialValues, setinitialValues] = useState(null);
  const [isUnableSubmitButton, setIsUnableSubmitButton] = useState(true);
  const [isUnableDeleteButton, setIsUnableDeleteButton] = useState(false);
  const [disableChecklistName, setDisableChecklistName] = useState(false);
  const [updatedAnsIndexes, setUpdatedAnsIndexes] = useState([]);

  const {
    isAnswerLoading,
    refreshList,
    moduleDetail,
    isNewAnswer,
    refetchInspectionDetails,
    hideFormPopup,
  } = useSelector(state => state.questionnaire);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      answers: [],
      label: '',
    },
    validationSchema: Yup.object().shape({
      answers: Yup.array(),
      label: Yup.string().trim().required(),
    }),
    onSubmit: async values => {
      try {
        const answerBody = [];
        values.answers.forEach(eachAnswer => {
          if (
            ![null, undefined, ''].includes(eachAnswer?.answer) &&
            !isDisable(eachAnswer?.is_editable)
          ) {
            if (
              ['rating10', 'rating5', 'text'].includes(eachAnswer.question_type)
            ) {
              answerBody.push({
                question_id: eachAnswer.question_id,
                answer: eachAnswer.answer || null,
              });
            } else if (eachAnswer.question_type === 'list') {
              answerBody.push({
                question_id: eachAnswer.question_id,
                answer:
                  typeof eachAnswer.answer === 'object'
                    ? eachAnswer.answer?.option_id
                    : eachAnswer.answer || null,
              });
            }
          }
        });
        if (!Array.isArray(answerBody) || !answerBody?.length) {
          toast?.current?.show &&
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: 'No answer provided',
              life: 3000,
            });
          return;
        }
        dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
        const func = isNewAnswer ? addAnswers : updateAnswers;
        const response = await func(moduleId, targetId, {
          answers: answerBody,
          label: values.label,
          version: !isNewAnswer ? selectedRecord.version : undefined,
        });

        if (response?.status && response?.data) {
          toast?.current?.show &&
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: response?.message,
              life: 3000,
            });
          dispatch(updateQuestionnaireStore({ isNewAnswer: false }));
          formik.setFieldValue('answers', response?.data);
          dispatch(
            updateQuestionnaireStore({
              submittedFormDetails: response?.data,
            })
          );
        }
      } catch (ex) {
        toast?.current?.show &&
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: ex?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
        dispatch(
          updateQuestionnaireStore({
            isAnswerLoading: false,
          })
        );
      } finally {
        dispatch(
          updateQuestionnaireStore({
            // isAnswerLoading: false,
            refreshList: !refreshList,
            refetchInspectionDetails: true,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      formik.values &&
      initialValues &&
      JSON.stringify(initialValues) != JSON.stringify(formik.values)
    ) {
      setIsUnableSubmitButton(false);
    }
  }, [formik.values, initialValues]);

  const isDisable = (isEditable, type) => {
    if (isNewAnswer) return false;
    if (isEditable === true) return false;
    if (isEditable === false) return true;
    return false;
  };
  const handleFormValueChange = (value, index) => {
    const { answers = [] } = formik.values;
    if (
      Array.isArray(answers) &&
      answers.length &&
      answers[index] &&
      !isDisable(answers[index]?.is_editable)
    ) {
      const answersCopy = [...answers];
      const answeredQuestion = { ...answersCopy[index] };
      answeredQuestion.answer = value;
      answersCopy[index] = answeredQuestion;
      formik.setFieldValue('answers', answersCopy);
    }
  };
  const clearAnswers = async () => {
    dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
    callGetAnswers().then(() =>
      dispatch(
        updateQuestionnaireStore({
          isAnswerLoading: false,
          refetchInspectionDetails: true,
        })
      )
    );
  };
  const refreshAnswers = async () => {
    dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
    try {
      setIsUnableDeleteButton(true);
      const response = await deleteAnswers(
        moduleId,
        targetId,
        selectedRecord.version
      );
      if (response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || 'Answers refreshed successfully',
          life: 3000,
        });
        callGetAnswers().then(() =>
          setTimeout(() => {
            setIsUnableDeleteButton(false);
          }, 3000)
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(
        updateQuestionnaireStore({
          isAnswerLoading: false,
          hideFormPopup: !hideFormPopup,
        })
      );
    }
  };
  const callGetAnswers = async () => {
    try {
      dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
      setIsUnableSubmitButton(true);
      const response = await getAnswers(
        moduleId,
        targetId,
        isNewRecord,
        selectedRecord?.version
      );
      if (response?.status && response?.data) {
        if (response?.data?.result?.[0]) {
          formik.setFieldValue(
            'label',
            response?.data?.result?.[0]?.label || ''
          );
        }
        formik.setFieldValue('answers', response.data?.result);
        setinitialValues({
          answers: response.data?.result,
          label: response?.data?.result?.[0]?.label || '',
        });
        if (!response.data?.result) {
          setDisableChecklistName(true);
        }
        dispatch(
          updateQuestionnaireStore({
            isNewAnswer: !!response.data?.isNew,
            refetchInspectionDetails: true,
          })
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    }
  };
  const callGetModuleByTagName = async () => {
    try {
      const response = await getModuleByTagName(moduleTagName, moduleId);
      if (response?.status && response?.data) {
        dispatch(
          updateQuestionnaireStore({
            moduleDetail: {
              ...response.data,
              module_tag: response.data?.module_tag?.toLowerCase(),
            },
          })
        );
        setIsModule(true);
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    }
  };
  // useEffect(() => {
  //   if (moduleTagName) {
  //     dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
  //     callGetModuleByTagName().then(() =>
  //       dispatch(updateQuestionnaireStore({ isAnswerLoading: false }))
  //     );
  //   }
  // }, [moduleTagName, targetId]);
  useEffect(() => {
    if (
      isModule &&
      moduleTagName
      // moduleDetail?.module_tag === moduleTagName.toLowerCase() &&
      // targetId
    ) {
      dispatch(updateQuestionnaireStore({ isAnswerLoading: true }));
      callGetAnswers().then(() =>
        dispatch(updateQuestionnaireStore({ isAnswerLoading: false }))
      );
      setIsModule(false);
    }
  }, [isModule, refreshList]);

  useEffect(() => {
    callGetAnswers().then(() =>
      dispatch(updateQuestionnaireStore({ isAnswerLoading: false }))
    );
  }, [moduleId]);

  const RenderModificationDetail = ({ answer }) => {
    let name = null,
      date = null;
    if (!isNewAnswer) {
      if (answer?.modified_by_user) {
        name = `${answer?.modified_by_user?.first_name} ${answer?.modified_by_user?.last_name}`;
        date = answer?.modified_at;
      } else if (answer?.created_by_user) {
        name = `${answer?.created_by_user?.first_name} ${answer?.created_by_user?.last_name}`;
        date = answer?.created_at;
      }
    }
    return (
      name && (
        <div className="flex gap-5 justify-content-end mt-1">
          <div className="flex gap-2">
            <div className="font-semibold">Modified By:</div>
            <div>{name}</div>
          </div>
          {date && (
            <div className="flex gap-2">
              <div className="font-semibold">Modified At:</div>
              <div>{getConvertedDate(date)}</div>
            </div>
          )}
        </div>
      )
    );
  };
  const confirmClear = () => {
    confirmDialog({
      message: 'Please confirm if you would like to clear all answers?',
      header: 'Clear Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: clearAnswers,
      rejectLabel: 'Cancel',
      acceptLabel: 'Clear',
      acceptIcon: 'pi pi-eraser',
    });
  };
  const confirmRefresh = () => {
    confirmDialog({
      message:
        'This action will delete all your responses against this checklist, would you like to still delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: refreshAnswers,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  return (
    <div className="flex flex-column w-full">
      <Toast ref={toast} />
      {isRenderConfirmationPopup && <ConfirmDialog />}

      {selectedModuleName && (
        <>
          <div className="flex w-full justify-content-between mb-2">
            <div className="flex">
              <h4 className="text-xl font-semibold font-semibold">
                Module: {selectedModuleName}
              </h4>
            </div>
            <div className="flex align-items-center gap-2">
              <PFButton
                onClick={confirmClear}
                size="small"
                label="Clear"
                outlined="outlined"
                icon={
                  isAnswerLoading ? 'pi pi-spin pi-spinner' : 'pi pi-eraser'
                }
                iconPos="left"
                disabled={
                  isAnswerLoading ||
                  !Array.isArray(formik.values?.answers) ||
                  !formik.values.answers.length
                }
              />

              {checkPermission(permissions.questionnaire.projectDeleteAnswer) &&
                !isNewRecord && (
                  <PFButton
                    onClick={confirmRefresh}
                    size="small"
                    label="Delete"
                    outlined="outlined"
                    className="p-button-danger"
                    icon={
                      isAnswerLoading ? 'pi pi-spin pi-spinner' : 'pi pi-trash'
                    }
                    iconPos="left"
                    disabled={isAnswerLoading || isUnableDeleteButton}
                  />
                )}

              <PFButton
                label="Submit"
                size="small"
                onClick={formik.handleSubmit}
                disabled={
                  isAnswerLoading ||
                  !Array.isArray(formik.values?.answers) ||
                  !formik.values.answers.length ||
                  !formik.isValid ||
                  isUnableSubmitButton
                }
              />
            </div>
          </div>
          <div className=" flex-column w-full my-2">
            <span className="p-float-label">
              <PFInputText
                i="pi pi-search"
                type="text"
                value={formik.values.label}
                onChange={e => formik?.setFieldValue('label', e.target.value)}
                disabled={disableChecklistName || !isNewAnswer}
              />
              <label htmlFor="clientFirstName">
                Checklist Name <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </>
      )}
      {isAnswerLoading ? (
        <div className="p-card flex flex-column mb-4 p-2">
          <div className="flex w-full">
            <Skeleton className="mb-2" width="30rem" height="3rem" />
          </div>
          <div className="flex flex-column">
            <div className="flex w-full">
              <Skeleton className="mb-2" height="6rem" />
            </div>
          </div>
        </div>
      ) : Array.isArray(formik.values?.answers) &&
        formik.values.answers.length ? (
        formik.values.answers.map((eachAnswer, index) => (
          <div className="p-card flex flex-column mb-4 p-3" key={index}>
            <div className="flex flex-column gap-2">
              <h4 className="font-semibold w-10">
                {index + 1}. {eachAnswer?.question}
              </h4>

              {eachAnswer?.question_type === 'rating10' && (
                <div className="flex w-full">
                  <Rating
                    value={formik.values?.answers?.[index]?.answer}
                    onChange={e => handleFormValueChange(e.value, index)}
                    cancel={false}
                    stars={10}
                    disabled={isDisable(
                      formik.values?.answers?.[index]?.is_editable
                    )}
                  />
                </div>
              )}
              {eachAnswer?.question_type === 'rating5' && (
                <div className="flex w-full">
                  <Rating
                    value={formik.values?.answers?.[index]?.answer}
                    onChange={e => handleFormValueChange(e.value, index)}
                    cancel={false}
                    disabled={isDisable(
                      formik.values?.answers?.[index]?.is_editable
                    )}
                  />
                </div>
              )}
              {eachAnswer?.question_type === 'list' &&
                eachAnswer?.options?.length > 0 && (
                  <span className="p-float-label">
                    <PFDropdown
                      value={
                        typeof formik.values?.answers?.[index]?.answer ===
                          'object' &&
                        formik.values?.answers?.[index]?.answer?.option_id
                          ? formik.values?.answers?.[index]?.answer
                          : formik.values?.answers?.[index]?.answer
                            ? eachAnswer?.options.find(
                                e =>
                                  e.option_id ==
                                  formik.values?.answers?.[index]?.answer
                              )
                            : ''
                      }
                      onChange={(name, value) =>
                        handleFormValueChange(value, index)
                      }
                      options={eachAnswer?.options}
                      disabled={isDisable(
                        formik.values?.answers?.[index]?.is_editable
                      )}
                      optionLabel="label"
                      placeholder="Select an option"
                      inputId={`list_${index}`}
                      name={`list_${index}`}
                      className="w-4"
                      appendTo="self"
                    />
                    <label htmlFor={`list_${index}`}>Select</label>
                  </span>
                )}
              {eachAnswer?.question_type === 'text' && (
                <div className="flex w-full">
                  <InputTextarea
                    id={`text_${index}`}
                    name={`text_${index}`}
                    rows={5}
                    value={formik.values?.answers?.[index]?.answer}
                    onChange={e =>
                      handleFormValueChange(e?.target?.value, index)
                    }
                    className="w-full"
                    disabled={isDisable(
                      formik.values?.answers?.[index]?.is_editable
                    )}
                  />
                </div>
              )}
            </div>
            <RenderModificationDetail
              answer={formik.values?.answers?.[index]}
            />
          </div>
        ))
      ) : (
        <div className="w-full flex align-items-center justify-content-center mt-2">
          <h2 className="font-semibold">No record found</h2>
        </div>
      )}
    </div>
  );
};

export default AnswerComponent;
