import { useState, useEffect } from 'react';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

const OnboardRefresh = () => {
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const accountNumber = urlParams.get('acc');

  const generateOnboardLink = async () => {
    const response = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CRM.generateOnboardLink}?acc=${accountNumber}`,
      null,
      URL_CONSTANTS.CRM.baseUrl
    );

    if (response && response.status && response.data) {
      window.location.href = response.data;
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateOnboardLink();
  }, []);

  return (
    <div className="col-12 text-center">
      <div className=" font-bold text-5xl mb-3">
        {loading ? (
          <ProgressSpinner
            style={{ width: '50px', height: '50px' }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <Message
            severity="info"
            text="Session has been expired, Please connect with PF admin"
          />
        )}
      </div>
    </div>
  );
};

export default OnboardRefresh;
