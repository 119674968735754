import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';

const InstallersListFilter = () => {
  const dispatch = useDispatch();
  const { selectedInstallers, installersList, isSelectAllInstallers } =
    useSelector(state => state.newScheduler);

  return (
    <div className="flex flex-column mt-3">
      <MultiSelect
        className="w-full"
        value={selectedInstallers?.filter(i => !!i)}
        key={selectedInstallers?.length}
        options={installersList}
        onChange={event => {
          const selectedInstallersList = event?.target?.value;
          const isSelectAllInstallers =
            selectedInstallersList?.length === installersList?.length;
          dispatch(
            setNewSchedulerComponent({
              selectedInstallers: selectedInstallersList,
              isSelectAllInstallers: isSelectAllInstallers,
            })
          );
        }}
        optionLabel="name"
        dataKey="id"
        placeholder="Filter Technicians"
        display="chip"
        filter
      />
    </div>
  );
};

export default InstallersListFilter;
