import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isDeleteDialogOpen: false,
  statesList: [],
  crewMemberData: [],
  deleteRowIndex: null,
  crewMemberIdToDelete: null,
};

const userFormCrewMemberSlice = createSlice({
  name: 'userFormCrewMember',
  initialState: initialState,
  reducers: {
    setUserCrewMember(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.crewMemberData = payload.hasOwnProperty('crewMemberData')
        ? payload.crewMemberData
        : state.crewMemberData;
      state.crewMemberDataCount = payload.hasOwnProperty('crewMemberDataCount')
        ? payload.crewMemberDataCount
        : state.crewMemberDataCount;
      state.isDeleteDialogOpen = payload.hasOwnProperty('isDeleteDialogOpen')
        ? payload.isDeleteDialogOpen
        : state.isDeleteDialogOpen;
      state.deleteRowIndex = payload.hasOwnProperty('deleteRowIndex')
        ? payload.deleteRowIndex
        : state.deleteRowIndex;
      state.crewMemberIdToDelete = payload.hasOwnProperty(
        'crewMemberIdToDelete'
      )
        ? payload.crewMemberIdToDelete
        : state.crewMemberIdToDelete;
    },
  },
});

export const { setUserCrewMember } = userFormCrewMemberSlice.actions;

export const userFormCrewMemberReducer = userFormCrewMemberSlice.reducer;
