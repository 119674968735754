import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Box,
  DialogContentText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import GenericDialog from '../../shared/Dialog/GenericDialog';
import {
  momentTz,
  convertTimeForTimePickerValues,
  getPercentageValue,
} from '../../../utils/Helpers';
import { addNotes } from '../Notes/Notes.service';
import {
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  scheduleInfoUserFillAction,
  COMMISSION_WORK_TYPE,
  scheduleInfoUserCustomAction,
  TECHNICIAN_FILTER_MESSAGE,
} from '../../../constants';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';

const InstallerForm = ({
  isOpen,
  handleClose,
  installerFormik,
  dialogSettings,
  installerOptions,
  filteredInstallerOptions,
  formAction,
  installerTypeOptions,
  filteredInstallerTypeOptions,
  project_id,
  isCommissionTypeTechnicianFound,
  isSalesCommissionOpted,
  handlePercentage,
  setPercentageAllocation,
  percentageAllocation,
}) => {
  const [dialogNotesSettings, setDialogNotesSettings] = useState({
    title: 'Reschedule Confirmation',
    button1Text: 'Skip',
    button2Text: 'Continue',
    showButton1: true,
    showButton2: true,
  });
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [dateStartEndChange, setDateStartEndChange] = useState(false);
  const [isCommisiontype, setIsCommisiontype] = useState(false);

  const tentativeTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Tentative Schedule'
  )?.worktypeid;
  const scheduleTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Schedule'
  )?.worktypeid;
  const commissionTypeId = installerTypeOptions?.find(
    type => type?.worktype === 'Commission'
  )?.worktypeid;
  const getInstallerTypeOptions = () => {
    if (formAction === 'add') {
      return (isCommissionTypeTechnicianFound &&
        installerFormik?.type !== COMMISSION_WORK_TYPE.ID) ||
        !isSalesCommissionOpted
        ? filteredInstallerTypeOptions?.filter(
            e => e?.worktypeid != COMMISSION_WORK_TYPE.ID
          ) || filteredInstallerTypeOptions
        : filteredInstallerTypeOptions;
    } else if (
      formAction === 'edit' &&
      installerFormik?.initialValues?.type === tentativeTypeId
    ) {
      return installerTypeOptions?.filter(
        type => type?.worktype === 'Schedule'
      );
    } else {
      return (isCommissionTypeTechnicianFound &&
        installerFormik?.type !== COMMISSION_WORK_TYPE.ID) ||
        !isSalesCommissionOpted
        ? installerTypeOptions?.filter(
            e => e?.worktypeid != COMMISSION_WORK_TYPE.ID
          )
        : installerTypeOptions;
    }
  };
  const [button2Text, setButton2Text] = useState();

  const handleButton2Text = worktypeid => {
    if (worktypeid !== undefined && worktypeid === tentativeTypeId) {
      setButton2Text('Tentative Schedule');
    } else if (worktypeid === scheduleTypeId) {
      setButton2Text('Schedule');
    } else {
      setButton2Text('Save');
    }
  };

  const applyMinDateRestrictions = event => {
    event.preventDefault();
    event.target.min = `${momentTz().format('YYYY-MM-DD')}T00:00`;
  };

  const getInstallerOptions = () => {
    if (formAction === 'add' || formAction === 'clone') {
      return filteredInstallerOptions || [];
    } else {
      const currentlySelectedInstaller = installerOptions?.find(
        installer =>
          installer.installer_id === installerFormik?.values?.installer_id
      );
      return [
        ...(filteredInstallerOptions || []),
        ...(currentlySelectedInstaller ? [currentlySelectedInstaller] : []),
      ];
    }
  };

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const installerHandleSubmit = () => {
    if (dateStartEndChange) {
      notesFormik.setValues({
        type: '',
        note_text: '',
        follow_up_date: null,
      });
      setDialogNotesSettings(prevState => ({
        ...prevState,
        title: 'Reschedule Confirmation',
        showButton2: true,
      }));
      setIsNotesOpen(true);
      setDateStartEndChange(false);
      notesFormik?.handleReset();
    } else {
      installerFormik.handleSubmit();
    }
  };

  const notesFormik = useFormik({
    initialValues: {
      type: 6,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      if (values?.note_text?.length > 0) {
        await addNotes(
          project_id,
          values,
          () => {},
          () => {},
          () => {},
          setIsNotesOpen
        );
      } else {
        setIsNotesOpen(false);
      }
      installerFormik.handleSubmit();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (installerFormik?.values?.type === COMMISSION_WORK_TYPE?.ID) {
      setIsCommisiontype(true);
    } else {
      setIsCommisiontype(false);
    }
  }, [installerFormik?.values?.type]);

  const handleInstallerDialogClose = () => {
    setIsCommisiontype(false);
    handleClose();
  };

  return (
    <>
      <GenericDialog
        isOpen={isOpen}
        handleClose={handleClose}
        disabledButton2={
          !installerFormik.dirty ||
          !installerFormik.isValid ||
          installerFormik.isSubmitting ||
          installerFormik?.initialValues === installerFormik?.values ||
          (installerFormik?.values.userAction ===
            scheduleInfoUserCustomAction &&
            !percentageAllocation)
        }
        disabledButton1={false}
        dialogSettings={dialogSettings}
        handleSave={
          formAction === 'edit'
            ? installerHandleSubmit
            : installerFormik.handleSubmit
        }
        button2Text={button2Text}
        dialogWidth="md"
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Autocomplete
              id="installer"
              name="Technician"
              options={getInstallerOptions()}
              getOptionLabel={option =>
                option ? `${option.first_name} ${option.last_name}` : ''
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Technician"
                  required={
                    (formAction === 'edit' || formAction === 'add') &&
                    installerFormik?.values?.type
                  }
                />
              )}
              value={
                installerOptions?.find(
                  installer =>
                    installer.installer_id ===
                    installerFormik?.values?.installer_id
                ) || ''
              }
              onChange={(event, value) => {
                if (value !== null || '') {
                  installerFormik.setFieldValue(
                    'installer_id',
                    value?.installer_id
                  );
                }
                {
                  if (value === null || '') {
                    installerFormik.setFieldValue('installer_id', '');
                  }
                }
              }}
              onBlur={installerFormik.handleBlur}
              disabled={formAction === 'view'}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              id="type"
              name="type"
              options={getInstallerTypeOptions()}
              getOptionLabel={option => option && option.worktype}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Type"
                  required={
                    (formAction === 'edit' || formAction === 'add') &&
                    installerFormik?.values?.installer_id
                  }
                />
              )}
              value={
                installerTypeOptions?.find(
                  type => type.worktypeid === installerFormik?.values?.type
                ) || ''
              }
              onChange={(event, value) => {
                installerFormik.setFieldValue('type', value?.worktypeid);
                handleButton2Text(value?.worktypeid);
                if (value === null || '') {
                  installerFormik.setFieldValue('type', '');
                }
                if (
                  !!installerFormik?.values?.date_scheduled_start ||
                  !!installerFormik?.values?.date_scheduled_end
                ) {
                } else {
                  if (
                    value?.worktypeid === tentativeTypeId ||
                    value?.worktypeid === scheduleTypeId
                  ) {
                    installerFormik.setFieldValue(
                      'date_scheduled_start',
                      `${momentTz().format('YYYY-MM-DD')}T08:00`
                    );
                    installerFormik.setFieldValue(
                      'date_scheduled_end',
                      `${momentTz().format('YYYY-MM-DD')}T17:00`
                    );
                  } else {
                    installerFormik.setFieldValue('date_scheduled_start', '');
                    installerFormik.setFieldValue('date_scheduled_end', '');
                  }
                }
                if (value?.worktypeid === commissionTypeId) {
                  setIsCommisiontype(true);
                  installerFormik.setFieldValue('date_scheduled_start', '');
                  installerFormik.setFieldValue('date_scheduled_end', '');
                } else {
                  setIsCommisiontype(false);
                }
                installerFormik.validateForm();
              }}
              onBlur={installerFormik.handleBlur}
              disabled={
                formAction === 'view' ||
                (formAction === 'edit' &&
                  installerFormik?.initialValues?.type === scheduleTypeId) ||
                (formAction === 'edit' &&
                  installerFormik?.initialValues?.type === commissionTypeId)
              }
            />
          </Grid>
          {!isCommisiontype && (
            <>
              <Grid item>
                <TextField
                  id="date_scheduled_start"
                  label="Start Date & Time"
                  type="datetime-local"
                  autoOk
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 900, // 15 min
                  }}
                  value={installerFormik?.values?.date_scheduled_start}
                  onChange={(date, value) => {
                    setDateStartEndChange(true);
                    installerFormik.setFieldValue(
                      'date_scheduled_start',
                      `${date.target.value}`
                    );
                  }}
                  disabled={formAction === 'view' ? true : false}
                  onBlur={installerFormik?.handleBlur}
                  error={installerFormik?.errors?.date_scheduled_start}
                  helperText={installerFormik?.errors?.date_scheduled_start}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="date_scheduled_end"
                  label="End Date & Time"
                  type="datetime-local"
                  autoOk
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 900, // 15 min
                  }}
                  value={installerFormik?.values?.date_scheduled_end}
                  onChange={(date, value) => {
                    setDateStartEndChange(true);
                    installerFormik.setFieldValue(
                      'date_scheduled_end',
                      `${date.target.value}`
                    );
                  }}
                  disabled={formAction === 'view' ? true : false}
                  onBlur={installerFormik?.handleBlur}
                  error={installerFormik?.errors?.date_scheduled_end}
                  helperText={installerFormik?.errors?.date_scheduled_end}
                />
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    id="installer_arrival_start_time"
                    label="Technician Arrival Start Time"
                    type="time"
                    autoOk
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900, // 15 min
                    }}
                    value={convertTimeForTimePickerValues(
                      installerFormik?.values?.installer_arrival_start_time
                    )}
                    onChange={(date, value) => {
                      installerFormik.setFieldTouched(
                        'installer_arrival_start_time',
                        true
                      );
                      installerFormik.setFieldValue(
                        'installer_arrival_start_time',
                        `${date.target.value}:00`
                      );
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                      style: { display: formAction === 'view' && 'none' },
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                    error={
                      installerFormik?.errors?.installer_arrival_start_time
                    }
                    helperText={
                      installerFormik?.errors?.installer_arrival_start_time
                    }
                    style={{ marginRight: '16px' }}
                  />
                  <TextField
                    id="installer_arrival_end_time"
                    label="Technician Arrival End Time"
                    type="time"
                    autoOk
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900, // 15 min
                    }}
                    value={convertTimeForTimePickerValues(
                      installerFormik?.values?.installer_arrival_end_time
                    )}
                    onChange={(date, value) => {
                      installerFormik.setFieldTouched(
                        'installer_arrival_end_time',
                        true
                      );
                      installerFormik.setFieldValue(
                        'installer_arrival_end_time',
                        `${date.target.value}:00`
                      );
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                      style: { display: formAction === 'view' && 'none' },
                    }}
                    disabled={formAction === 'view' ? true : false}
                    onBlur={installerFormik?.handleBlur}
                    error={installerFormik?.errors?.installer_arrival_end_time}
                    helperText={
                      installerFormik?.errors?.installer_arrival_end_time
                    }
                    style={{ marginLeft: '16px' }}
                  />
                </Box>
              </Grid>
              {formAction === 'add' && (
                <Box>
                  <br />
                  <div className="pl-2 my-2">
                    <small className="mb-3">{TECHNICIAN_FILTER_MESSAGE}</small>
                  </div>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={installerFormik?.values.userAction}
                    name="radio-buttons-group"
                    onChange={e =>
                      installerFormik?.setFieldValue(
                        'userAction',
                        e?.target.value
                      )
                    }
                  >
                    <div
                      id="defaultBehaviourContainer"
                      className="grid pl-2 lg:justify-content-between align-items-center"
                    >
                      <div className="col-12 md:col-6 lg:col-2">
                        <FormControlLabel
                          value={scheduleInfoUserWipeAction}
                          control={<Radio />}
                          label="Full Allocation"
                        />
                      </div>
                      <div className="col-12 md:col-6 lg:col-3  flex min-h-32 align-items-end">
                        <FormControlLabel
                          value={scheduleInfoUserCustomAction}
                          control={<Radio />}
                          label="Custom Allocation"
                        />
                        {installerFormik?.values.userAction ===
                          scheduleInfoUserCustomAction && (
                          <PFInputNumber
                            id="percentage"
                            value={percentageAllocation}
                            onChange={(e, value) => {
                              const percent = getPercentageValue(value);
                              setPercentageAllocation(percent);
                              installerFormik?.setFieldValue(
                                'percentage',
                                percent
                              );
                            }}
                            suffix={'%'}
                            placeholder="%"
                            className="w-36"
                            pt={{
                              root: {
                                className: 'h-2rem pb-2',
                              },
                              input: {
                                root: {
                                  className:
                                    'w-full border-noround border-bottom-1 border-top-none border-x-none p-0 shadow-none border-gray-500 ',
                                },
                              },
                            }}
                            maxLength={6}
                            max={100}
                            min={0}
                          />
                        )}
                      </div>

                      <div className="col-12 md:col-6 lg:col-3">
                        <FormControlLabel
                          value={scheduleInfoUserFillAction}
                          control={<Radio />}
                          label="Allocate Remaining"
                        />
                      </div>
                      <div className="col-12 md:col-6 lg:col-3">
                        <FormControlLabel
                          value={scheduleInfoUserSkipAction}
                          control={<Radio />}
                          label="Leave Unassigned"
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </Box>
              )}

              {formAction === 'edit' && (
                <div className="pl-2 my-2">
                  <small>
                    Replacing this technician would update all items assignment
                    and calculated Labor Cost, Would you still like to continue
                    ?
                  </small>
                </div>
              )}
            </>
          )}
        </Grid>
      </GenericDialog>
      {isNotesOpen && (
        <form>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsNotesOpen(false);
              notesFormik.handleReset();
            }}
            disabledButton2={false}
            disabledButton1={false}
            dialogSettings={dialogNotesSettings}
            handleSave={() => notesFormik.handleSubmit()}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <DialogContentText>
                  <b>
                    Please confirm, if you would like to reschedule this job for{' '}
                    {installerFormik?.values?.date_scheduled_start
                      ? momentTz(
                          installerFormik?.values?.date_scheduled_start
                        ).format('YYYY-MM-DD hh:mm A')
                      : ''}{' '}
                    -{' '}
                    {installerFormik?.values?.date_scheduled_end
                      ? momentTz(
                          installerFormik?.values?.date_scheduled_end
                        ).format('YYYY-MM-DD hh:mm A')
                      : ''}
                  </b>
                </DialogContentText>
              </Grid>
              <Grid item>
                <TextField
                  id="note_text"
                  name="note_text"
                  label="Note"
                  multiline
                  onChange={notesFormik.handleChange}
                  onBlur={notesFormik.handleBlur}
                  value={notesFormik?.values?.note_text}
                />
              </Grid>
            </Grid>
          </GenericDialog>
        </form>
      )}
    </>
  );
};

export default InstallerForm;
