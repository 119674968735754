import React, { useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';

import { Colors } from '../../../src/constants';
import { routes } from '../../config/routes';

import { getPageMetaInfoBySlug } from './services/PageMeta.services';

const PageMeta = ({ style }) => {
  const overlayPanelRef = React.createRef();
  const [metaData, setMetaData] = useState(null);
  const [status, setStatus] = useState(false);
  const [pageIdentifier, setPageIdentifier] = useState();

  const routePath = () => {
    const route = routes;
    for (let i = 0; i < route.length; i++) {
      const currentPath = route[i];
      if (
        currentPath?.path?.includes('/:mode') ||
        currentPath?.path?.includes('/:action')
      ) {
        const methods = ['/view', '/add', '/edit', '/update'];
        const newPath = [];
        for (let i = 0; i < methods.length; i++) {
          const method = methods[i];
          const modifiedPath = currentPath?.path?.replace(
            /\/:(mode|action)(?!Id\b)/,
            method
          );

          if (modifiedPath) {
            newPath.push(modifiedPath);
          }
        }
        if (newPath.includes(window.location.pathname)) {
          setPageIdentifier(currentPath.identifier);
        }
      } else if (currentPath.path === window.location.pathname) {
        setPageIdentifier(currentPath.identifier);
      }
    }
  };

  useEffect(() => {
    routePath();
  }, [window.location.pathname]);

  useEffect(() => {
    if (pageIdentifier?.length > 0) {
      fetchData(pageIdentifier);
    }
  }, [pageIdentifier]);

  const fetchData = async slug => {
    try {
      const requestBody = {
        page_identifier: slug,
      };
      const response = await getPageMetaInfoBySlug(requestBody);
      const { page_identifier, title, description, status } = response?.data;
      setMetaData({ title, description });
      setStatus(status);
    } catch (error) {
      return error;
    }
  };

  const hideOverlayPanel = () => {
    overlayPanelRef.current.hide();
  };

  const renderDescription = datum => {
    if (
      !datum ||
      typeof datum.description === 'undefined' ||
      datum?.description?.length <= 0
    ) {
      return null;
    }
    if (datum.description) {
      try {
        const decodedHtml = decodeURI(datum.description);
        return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
      } catch (error) {
        return <div>Invalid content</div>;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {status &&
        metaData?.description &&
        metaData.description.trim() !== '' && (
          <div style={{ ...style }}>
            <Button
              icon="pi pi-question-circle text-xl"
              size="small"
              className="h-2rem w-2rem p-1 mr-1 focus:shadow-none"
              text
              onClick={event => overlayPanelRef.current.toggle(event)}
            />
          </div>
        )}
      <OverlayPanel ref={overlayPanelRef} onHide={hideOverlayPanel}>
        <div style={{ width: '50vw' }}>
          <div className="flex justify-content-between">
            <div className="font-bold my-3 font-bold">
              <h3>{metaData?.title} User Guide</h3>
            </div>
            <div>
              <i
                className="pi pi-times"
                style={{
                  cursor: 'pointer',
                  fontSize: '1rem',
                  color: `${Colors.PF_BLACK}`,
                  marginTop: '15px',
                }}
                onClick={hideOverlayPanel}
              ></i>
            </div>
          </div>
          <div style={{ height: 'auto', maxHeight: '25vw', overflowY: 'auto' }}>
            {renderDescription(metaData)}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

PageMeta.propTypes = {
  style: PropTypes.object,
};

export default PageMeta;
