import { apiService } from './api.services';
export const getApiCall = async productId => {
  //GET API for inventory adjustments item add to DB
  return await apiService
    ?.get('/v2/inventory/all-adjustment?id=' + productId)
    .then(responce => {
      if (responce?.status) {
        console.log('response', responce);
        return responce;
      }
    })
    .catch(error => {
      console.log('all-adjustment-error', error);
    });
};

export const adjustmentDispatch = async adjustmentId => {
  const response = await apiService.patch(
    `/stock-adjustment/action-dispatch/${adjustmentId}`
  );
  return response;
};

export const adjustmentOnOrder = async adjustmentId => {
  const response = await apiService.patch(
    `/stock-adjustment/on-order/${adjustmentId}`
  );
  return response;
};

export const adjustmentUndoDispatch = async adjustmentId => {
  const response = await apiService.patch(
    `/stock-adjustment/action-undo-dispatch/${adjustmentId}`
  );
  return response;
};

export const getInventoryAdjustmetns = async productId => {
  const response = await apiService.get(
    `/stock-adjustment/get-all/${productId}`
  );
  return response;
};

export const getWarehouseInventories = async productId => {
  const response = await apiService.get(
    `/warehouses/get-product-inventory/${productId}`
  );
  return response;
};

export const getInventoryProduct = async productId => {
  const response = await apiService.get(`/product/get-product/${productId}`);
  return response;
};
export const createAdjustment = async payload => {
  return await apiService.post(`/stock-adjustment/create`, payload);
};
export const updateAdjustment = async payload => {
  return await apiService.patch(
    `/stock-adjustment/update/${payload?._id}`,
    payload?._id,
    payload
  );
};

export const getInventoryById = async id => {
  return await apiService.get(`/stock-adjustment/get/${id}`);
};
