const colors = [
  {
    name: 'Red (RYB)',
    hex: '#FE2712',
  },

  {
    name: 'Purple Plum',
    hex: '#9C51B6',
  },
  {
    name: 'Tractor Red',
    hex: '#FD0E35',
  },
  {
    name: 'Wood Brown',
    hex: '#C19A6B',
  },
  {
    name: 'Spanish Bistre',
    hex: '#807532',
  },
  {
    name: 'Old Lace',
    hex: '#FDF5E6',
  },
  {
    name: 'Tan',
    hex: '#D2B48C',
  },
  {
    name: 'Acid Green',
    hex: '#B0BF1A',
  },
  {
    name: 'Ocean Boat Blue',
    hex: '#0077BE',
  },
  {
    name: 'Byzantine',
    hex: '#BD33A4',
  },
  {
    name: 'Jasmine',
    hex: '#F8DE7E',
  },
  {
    name: 'Dark Midnight Blue',
    hex: '#003366',
  },
  {
    name: 'Yellow Orange',
    hex: '#FFAE42',
  },
  {
    name: 'Vanilla',
    hex: '#F3E5AB',
  },
  {
    name: 'French Lilac',
    hex: '#86608E',
  },
  {
    name: 'Amethyst',
    hex: '#9966CC',
  },
  {
    name: 'Orioles Orange',
    hex: '#FB4F14',
  },
  {
    name: 'Zinnwaldite Brown',
    hex: '#2C1608',
  },
  {
    name: 'Topaz',
    hex: '#FFC87C',
  },
  {
    name: 'Outer Space',
    hex: '#414A4C',
  },
  {
    name: 'Burnished Brown',
    hex: '#A17A74',
  },
  {
    name: 'Charm Pink',
    hex: '#E68FAC',
  },
  {
    name: 'Gunmetal',
    hex: '#2a3439',
  },
  {
    name: 'Dingy Dungeon',
    hex: '#C53151',
  },
  {
    name: 'Jungle Green',
    hex: '#29AB87',
  },
  {
    name: 'Maroon (X11)',
    hex: '#B03060',
  },
  {
    name: 'Psychedelic Purple',
    hex: '#DF00FF',
  },
  {
    name: 'Dim Gray',
    hex: '#696969',
  },
  {
    name: 'English Vermillion',
    hex: '#CC474B',
  },
  {
    name: 'Pastel Green',
    hex: '#77DD77',
  },
  {
    name: 'Slimy Green',
    hex: '#299617',
  },
  {
    name: 'School Bus Yellow',
    hex: '#FFD800',
  },
  {
    name: 'Folly',
    hex: '#FF004F',
  },
  {
    name: 'Bright Lilac',
    hex: '#D891EF',
  },
  {
    name: "Payne's Grey",
    hex: '#536878',
  },
  {
    name: 'Vivid Tangerine',
    hex: '#FFA089',
  },

  {
    name: 'Lavender (Floral)',
    hex: '#B57EDC',
  },
  {
    name: 'Ocean Blue',
    hex: '#4F42B5',
  },
  {
    name: 'Puce Red',
    hex: '#722F37',
  },
  {
    name: 'Macaroni And Cheese',
    hex: '#FFBD88',
  },
  {
    name: 'Fern Green',
    hex: '#4F7942',
  },
  {
    name: 'Desert',
    hex: '#C19A6B',
  },
  {
    name: 'Deep Violet',
    hex: '#330066',
  },
  {
    name: 'Mauve',
    hex: '#E0B0FF',
  },
  {
    name: 'Catawba',
    hex: '#703642',
  },
  {
    name: 'Golden Poppy',
    hex: '#FCC200',
  },
  {
    name: 'Pearly Purple',
    hex: '#B768A2',
  },
  {
    name: 'Amaranth Purple',
    hex: '#AB274F',
  },
  {
    name: 'Old Rose',
    hex: '#C08081',
  },
  {
    name: 'Jazzberry Jam',
    hex: '#A50B5E',
  },
  {
    name: 'Sunset Orange',
    hex: '#FD5E53',
  },
  {
    name: 'Coral Reef',
    hex: '#FD7C6E',
  },
  {
    name: 'Lavender Blush',
    hex: '#FFF0F5',
  },
  {
    name: 'Maximum Red Purple',
    hex: '#A63A79',
  },
  {
    name: 'Wenge',
    hex: '#645452',
  },
  {
    name: 'Very Pale Yellow',
    hex: '#FFFFBF',
  },
  {
    name: 'Ecru',
    hex: '#C2B280',
  },
  {
    name: 'Blueberry',
    hex: '#4F86F7',
  },
  {
    name: 'Sandy Brown',
    hex: '#F4A460',
  },
  {
    name: 'Sunglow',
    hex: '#FFCC33',
  },
  {
    name: 'Orange (Crayola)',
    hex: '#FF7538',
  },
  {
    name: 'Blond',
    hex: '#FAF0BE',
  },
  {
    name: 'Brink Pink',
    hex: '#FB607F',
  },
  {
    name: 'Cultured',
    hex: '#F5F5F5',
  },
  {
    name: 'Persian Green',
    hex: '#00A693',
  },
  {
    name: 'Viridian Green',
    hex: '#009698',
  },
  {
    name: 'Middle Purple',
    hex: '#D982B5',
  },
  {
    name: 'Medium Taupe',
    hex: '#674C47',
  },
  {
    name: 'Dark Tan',
    hex: '#918151',
  },
  {
    name: 'Naples Yellow',
    hex: '#FADA5E',
  },
  {
    name: 'Air Superiority Blue',
    hex: '#72A0C1',
  },
  {
    name: 'Rust',
    hex: '#B7410E',
  },
  {
    name: 'Pistachio',
    hex: '#93C572',
  },
  {
    name: 'Brick Red',
    hex: '#CB4154',
  },
  {
    name: 'Pastel Yellow',
    hex: '#FDFD96',
  },
  {
    name: 'Celestial Blue',
    hex: '#4997D0',
  },
  {
    name: 'Amber',
    hex: '#FFBF00',
  },
  {
    name: 'Loeen (Lopen) Look',
    hex: '#15F2FD',
  },
  {
    name: 'Bittersweet',
    hex: '#FE6F5E',
  },
  {
    name: 'Boysenberry',
    hex: '#873260',
  },
  {
    name: 'Deep Aquamarine',
    hex: '#40826D',
  },
  {
    name: 'Ube',
    hex: '#8878C3',
  },
  {
    name: 'Wild Watermelon',
    hex: '#FC6C85',
  },
  {
    name: 'Piggy Pink',
    hex: '#FDDDE6',
  },
  {
    name: 'Chrome Yellow',
    hex: '#FFA700',
  },
  {
    name: 'Willpower Orange',
    hex: '#FD5800',
  },
  {
    name: 'Dark Cerulean',
    hex: '#08457E',
  },
  {
    name: 'Spanish Gray',
    hex: '#989898',
  },
  {
    name: 'Blue Bolt',
    hex: '#00B9FB',
  },
  {
    name: 'Byzantium',
    hex: '#702963',
  },
  {
    name: 'Light Cornflower Blue',
    hex: '#93CCEA',
  },
  {
    name: 'Turquoise Blue',
    hex: '#00FFEF',
  },
  {
    name: 'Dark Scarlet',
    hex: '#560319',
  },
  {
    name: 'Liberty',
    hex: '#545AA7',
  },
  {
    name: 'Charleston Green',
    hex: '#232B2B',
  },
  {
    name: 'Red (Crayola)',
    hex: '#EE204D',
  },
  {
    name: 'Safety Yellow',
    hex: '#EED202',
  },
  {
    name: 'Pear',
    hex: '#D1E231',
  },
  {
    name: 'African Violet',
    hex: '#B284BE',
  },
  {
    name: 'Pastel Gray',
    hex: '#CFCFC4',
  },
  {
    name: 'Pearl',
    hex: '#EAE0C8',
  },
  {
    name: 'Heliotrope',
    hex: '#DF73FF',
  },
  {
    name: 'Pale Aqua',
    hex: '#BCD4E6',
  },
  {
    name: 'Teal Blue',
    hex: '#367588',
  },
  {
    name: 'Blue-Green',
    hex: '#0D98BA',
  },
  {
    name: 'Pale Spring Bud',
    hex: '#ECEBBD',
  },
  {
    name: 'Ginger',
    hex: '#B06500',
  },
  {
    name: 'Lemon Yellow',
    hex: '#FFF44F',
  },
  {
    name: 'Medium Red-Violet',
    hex: '#BB3385',
  },
  {
    name: 'Rosso Corsa',
    hex: '#D40000',
  },
  {
    name: 'Green (Pigment)',
    hex: '#00A550',
  },
  {
    name: 'Spanish Red',
    hex: '#E60026',
  },
  {
    name: 'Mystic',
    hex: '#D65282',
  },
  {
    name: 'Amaranth Deep Purple',
    hex: '#9F2B68',
  },
  {
    name: 'Vivid Violet',
    hex: '#9F00FF',
  },
  {
    name: 'Blue Jeans',
    hex: '#5DADEC',
  },
  {
    name: 'Kobi',
    hex: '#E79FC4',
  },
  {
    name: 'Sunny',
    hex: '#F2F27A',
  },
  {
    name: 'Gamboge Orange (Brown)',
    hex: '#996600',
  },
  {
    name: 'Violet-Blue',
    hex: '#324AB2',
  },
  {
    name: 'Brilliant Lavender',
    hex: '#F4BBFF',
  },
  {
    name: 'Light Apricot',
    hex: '#FDD5B1',
  },
  {
    name: 'Peach Puff',
    hex: '#FFDAB9',
  },
  {
    name: 'Maroon (Crayola)',
    hex: '#C32148',
  },
  {
    name: 'Gray-Blue',
    hex: '#8C92AC',
  },
  {
    name: 'Twilight Lavender',
    hex: '#8A496B',
  },
  {
    name: 'Medium Turquoise',
    hex: '#48D1CC',
  },
  {
    name: 'Imperial Blue',
    hex: '#002395',
  },
  {
    name: 'Meat Brown',
    hex: '#E5B73B',
  },
  {
    name: 'Steel Blue',
    hex: '#4682B4',
  },
  {
    name: 'Mode Beige',
    hex: '#967117',
  },
  {
    name: 'Snow',
    hex: '#FFFAFA',
  },
  {
    name: 'Lavender Magenta',
    hex: '#EE82EE',
  },
  {
    name: 'Light Steel Blue',
    hex: '#B0C4DE',
  },
  {
    name: 'Permanent Geranium Lake',
    hex: '#E12C2C',
  },
  {
    name: 'Guppie Green',
    hex: '#00FF7F',
  },
  {
    name: 'UCLA Blue',
    hex: '#536895',
  },
  {
    name: 'Shocking Pink (Crayola)',
    hex: '#FF6FFF',
  },
  {
    name: 'Maximum Purple',
    hex: '#733380',
  },
  {
    name: 'Old Heliotrope',
    hex: '#563C5C',
  },
  {
    name: 'Congo Pink',
    hex: '#F88379',
  },
  {
    name: 'Cool Black',
    hex: '#002E63',
  },
  {
    name: 'Yellow (Munsell)',
    hex: '#EFCC00',
  },
  {
    name: 'Maximum Green',
    hex: '#5E8C31',
  },
  {
    name: 'Absolute Zero',
    hex: '#0048BA',
  },
  {
    name: 'French Mauve',
    hex: '#D473D4',
  },
  {
    name: 'Dark Vanilla',
    hex: '#D1BEA8',
  },
  {
    name: 'Tiffany Blue',
    hex: '#0ABAB5',
  },
  {
    name: 'Ruby Red',
    hex: '#9B111E',
  },
  {
    name: 'French Raspberry',
    hex: '#C72C48',
  },
  {
    name: 'Deep Jungle Green',
    hex: '#004B49',
  },
  {
    name: 'Jet',
    hex: '#343434',
  },
  {
    name: 'Mint Green',
    hex: '#98FF98',
  },
  {
    name: 'Azure Mist',
    hex: '#F0FFFF',
  },
  {
    name: 'Old Mauve',
    hex: '#673147',
  },
  {
    name: 'Moonstone Blue',
    hex: '#73A9C2',
  },
  {
    name: 'Cerulean',
    hex: '#007BA7',
  },
  {
    name: 'Vivid Amber',
    hex: '#CC9900',
  },
  {
    name: 'Beige',
    hex: '#F5F5DC',
  },
  {
    name: 'Vivid Mulberry',
    hex: '#B80CE3',
  },
  {
    name: 'Isabelline',
    hex: '#F4F0EC',
  },
  {
    name: 'Ceil',
    hex: '#92A1CF',
  },
  {
    name: 'Tropical Rain Forest',
    hex: '#00755E',
  },
  {
    name: 'Almond',
    hex: '#EFDECD',
  },
  {
    name: 'Turkish Rose',
    hex: '#B57281',
  },
  {
    name: 'United Nations Blue',
    hex: '#5B92E5',
  },
  {
    name: 'Floral White',
    hex: '#FFFAF0',
  },
  {
    name: 'Rose Bonbon',
    hex: '#F9429E',
  },
  {
    name: 'Cyber Yellow',
    hex: '#FFD300',
  },
  {
    name: 'Fuchsia (Crayola)',
    hex: '#C154C1',
  },
  {
    name: 'Medium Jungle Green',
    hex: '#1C352D',
  },
  {
    name: 'Middle Blue Green',
    hex: '#8DD9CC',
  },
  {
    name: 'Dark Gunmetal',
    hex: '#1F262A',
  },
  {
    name: 'Moss Green',
    hex: '#8A9A5B',
  },
  {
    name: 'Lumber',
    hex: '#FFE4CD',
  },
  {
    name: 'Umber',
    hex: '#635147',
  },
  {
    name: 'Cornflower Blue',
    hex: '#6495ED',
  },
  {
    name: 'Dodger Blue',
    hex: '#1E90FF',
  },
  {
    name: 'Ruddy',
    hex: '#FF0028',
  },
  {
    name: 'Scarlet',
    hex: '#FF2400',
  },
  {
    name: 'Skobeloff',
    hex: '#007474',
  },
  {
    name: 'Kobe',
    hex: '#882D17',
  },
  {
    name: 'Cherry',
    hex: '#DE3163',
  },
  {
    name: 'Smoky Topaz',
    hex: '#933D41',
  },
  {
    name: 'Royal Fuchsia',
    hex: '#CA2C92',
  },
  {
    name: 'Pale Turquoise',
    hex: '#AFEEEE',
  },
  {
    name: 'Purpureus',
    hex: '#9A4EAE',
  },
  {
    name: 'Star Command Blue',
    hex: '#007BB8',
  },
  {
    name: 'Patriarch',
    hex: '#800080',
  },
  {
    name: 'Saddle Brown',
    hex: '#8B4513',
  },
  {
    name: 'Caput Mortuum',
    hex: '#592720',
  },
  {
    name: 'Glossy Grape',
    hex: '#AB92B3',
  },
  {
    name: 'Red Salsa',
    hex: '#FD3A4A',
  },
  {
    name: 'Green Sheen',
    hex: '#6EAEA1',
  },
  {
    name: 'Vivid Orange',
    hex: '#FF5F00',
  },
  {
    name: 'Xanadu',
    hex: '#738678',
  },
  {
    name: 'Russian Green',
    hex: '#679267',
  },
  {
    name: 'Flamingo Pink',
    hex: '#FC8EAC',
  },
  {
    name: 'Olive Drab #7',
    hex: '#3C341F',
  },
  {
    name: 'Mimi Pink',
    hex: '#FFDAE9',
  },
  {
    name: 'Old Silver',
    hex: '#848482',
  },
  {
    name: 'Mindaro',
    hex: '#E3F988',
  },
  {
    name: 'Red-Brown',
    hex: '#A52A2A',
  },
  {
    name: 'Blue (Munsell)',
    hex: '#0093AF',
  },
  {
    name: 'Midnight',
    hex: '#702670',
  },
  {
    name: 'Russet',
    hex: '#80461B',
  },
  {
    name: 'Lime (Color Wheel)',
    hex: '#BFFF00',
  },
  {
    name: 'Dark Tangerine',
    hex: '#FFA812',
  },
  {
    name: 'Lincoln Green',
    hex: '#195905',
  },
  {
    name: 'Light Cyan',
    hex: '#E0FFFF',
  },
  {
    name: 'Cyan-Blue Azure',
    hex: '#4682BF',
  },
  {
    name: 'Key Lime',
    hex: '#E8F48C',
  },
  {
    name: 'Upsdell Red',
    hex: '#AE2029',
  },
  {
    name: 'Dark Green (X11)',
    hex: '#006400',
  },
  {
    name: 'Wageningen Green',
    hex: '#34B233',
  },
  {
    name: 'Quick Silver',
    hex: '#A6A6A6',
  },
  {
    name: 'Tropical Violet',
    hex: '#CDA4DE',
  },
  {
    name: 'Giants Orange',
    hex: '#FE5A1D',
  },
  {
    name: 'Super Pink',
    hex: '#CF6BA9',
  },
  {
    name: 'CG Blue',
    hex: '#007AA5',
  },
  {
    name: 'Dark Liver',
    hex: '#534B4F',
  },
  {
    name: 'Ebony',
    hex: '#555D50',
  },
  {
    name: 'Deep Maroon',
    hex: '#820000',
  },
  {
    name: 'Deer',
    hex: '#BA8759',
  },
  {
    name: 'Dark Slate Gray',
    hex: '#2F4F4F',
  },
  {
    name: 'Brown-Nose',
    hex: '#6B4423',
  },
  {
    name: 'Blue (Crayola)',
    hex: '#1F75FE',
  },
  {
    name: 'Parrot Pink',
    hex: '#D998A0',
  },
  {
    name: 'Gold (Metallic)',
    hex: '#D4AF37',
  },
  {
    name: 'Ultramarine Blue',
    hex: '#4166F5',
  },
  {
    name: 'Medium Purple',
    hex: '#9370DB',
  },
  {
    name: 'Tuscan Red',
    hex: '#7C4848',
  },
  {
    name: 'Smitten',
    hex: '#C84186',
  },
  {
    name: 'Red (Pantone)',
    hex: '#ED2939',
  },
  {
    name: 'Ivory',
    hex: '#FFFFF0',
  },
  {
    name: 'Dark Taupe',
    hex: '#483C32',
  },
  {
    name: 'Salmon Pink',
    hex: '#FF91A4',
  },
  {
    name: 'Thistle',
    hex: '#D8BFD8',
  },
  {
    name: 'Yankees Blue',
    hex: '#1C2841',
  },
  {
    name: 'Antique White',
    hex: '#FAEBD7',
  },
  {
    name: 'Alloy Orange',
    hex: '#C46210',
  },
  {
    name: 'Jelly Bean',
    hex: '#DA614E',
  },
  {
    name: 'Forest Green (Web)',
    hex: '#228B22',
  },
  {
    name: 'Honolulu Blue',
    hex: '#006DB0',
  },
  {
    name: 'Queen Pink',
    hex: '#E8CCD7',
  },
  {
    name: 'Roast Coffee',
    hex: '#704241',
  },
  {
    name: 'Dark Raspberry',
    hex: '#872657',
  },
  {
    name: 'Winter Wizard',
    hex: '#A0E6FF',
  },
  {
    name: 'Aero Blue',
    hex: '#C9FFE5',
  },
  {
    name: 'Boston University Red',
    hex: '#CC0000',
  },
  {
    name: 'Red (Munsell)',
    hex: '#F2003C',
  },
  {
    name: 'Camouflage Green',
    hex: '#78866B',
  },
  {
    name: 'Lavender (Web)',
    hex: '#E6E6FA',
  },
  {
    name: 'Pale Silver',
    hex: '#C9C0BB',
  },
  {
    name: 'Amazon Store',
    hex: '#3B7A57',
  },
  {
    name: 'Medium Violet-Red',
    hex: '#C71585',
  },
  {
    name: 'Yellow (Pantone)',
    hex: '#FEDF00',
  },
  {
    name: 'Coconut',
    hex: '#965A3E',
  },
  {
    name: 'Carmine',
    hex: '#960018',
  },
  {
    name: 'Periwinkle',
    hex: '#CCCCFF',
  },
  {
    name: 'Titanium Yellow',
    hex: '#EEE600',
  },
  {
    name: 'Blue',
    hex: '#0000FF',
  },
  {
    name: 'Red-Orange',
    hex: '#FF5349',
  },
  {
    name: 'Rifle Green',
    hex: '#444C38',
  },
  {
    name: 'Alizarin Crimson',
    hex: '#E32636',
  },
  {
    name: 'Black Bean',
    hex: '#3D0C02',
  },
  {
    name: 'Imperial Red',
    hex: '#ED2939',
  },
  {
    name: 'Light Grayish Magenta',
    hex: '#CC99CC',
  },
  {
    name: 'Sap Green',
    hex: '#507D2A',
  },
  {
    name: 'Seashell',
    hex: '#FFF5EE',
  },
  {
    name: 'Pale Brown',
    hex: '#987654',
  },
  {
    name: "Hooker's Green",
    hex: '#49796B',
  },
  {
    name: 'Sand Dune',
    hex: '#967117',
  },
  {
    name: 'Dark Sky Blue',
    hex: '#8CBED6',
  },
  {
    name: 'Sea Serpent',
    hex: '#4BC7CF',
  },
  {
    name: 'Mystic Maroon',
    hex: '#AD4379',
  },
  {
    name: 'Blue (Pigment)',
    hex: '#333399',
  },
  {
    name: 'Straw',
    hex: '#E4D96F',
  },
  {
    name: 'Bright Pink',
    hex: '#FF007F',
  },
  {
    name: 'Sandstorm',
    hex: '#ECD540',
  },
  {
    name: 'Razzle Dazzle Rose',
    hex: '#FF33CC',
  },
  {
    name: 'Raspberry Glace',
    hex: '#915F6D',
  },
  {
    name: 'Aztec Gold',
    hex: '#C39953',
  },
  {
    name: 'Corn',
    hex: '#FBEC5D',
  },
  {
    name: 'Spanish Carmine',
    hex: '#D10047',
  },
  {
    name: 'Light Green',
    hex: '#90EE90',
  },
  {
    name: 'Sheen Green',
    hex: '#8FD400',
  },
  {
    name: 'Very Pale Orange',
    hex: '#FFDFBF',
  },
  {
    name: 'Denim Blue',
    hex: '#2243B6',
  },
  {
    name: 'Quartz',
    hex: '#51484F',
  },
  {
    name: 'Magic Mint',
    hex: '#AAF0D1',
  },
  {
    name: 'Cyclamen',
    hex: '#F56FA1',
  },
  {
    name: 'Burnt Umber',
    hex: '#8A3324',
  },
  {
    name: 'Chlorophyll Green',
    hex: '#4AFF00',
  },
  {
    name: 'Persian Red',
    hex: '#CC3333',
  },
  {
    name: 'White',
    hex: '#FFFFFF',
  },
  {
    name: 'Chinese Violet',
    hex: '#856088',
  },
  {
    name: 'Dartmouth Green',
    hex: '#00703C',
  },
  {
    name: 'Dark Medium Gray',
    hex: '#A9A9A9',
  },
  {
    name: 'Rufous',
    hex: '#A81C07',
  },
  {
    name: 'Pale Gold',
    hex: '#E6BE8A',
  },
  {
    name: 'Amaranth Pink',
    hex: '#F19CBB',
  },
  {
    name: 'Rose Pink',
    hex: '#FF66CC',
  },
  {
    name: 'Vivid Orange Peel',
    hex: '#FFA000',
  },
  {
    name: 'Liver Chestnut',
    hex: '#987456',
  },
  {
    name: 'Turquoise Surf',
    hex: '#00C5CD',
  },
  {
    name: 'Lapis Lazuli',
    hex: '#26619C',
  },
  {
    name: 'Fuchsia Pink',
    hex: '#FF77FF',
  },
  {
    name: 'Medium Slate Blue',
    hex: '#7B68EE',
  },
  {
    name: 'Wine',
    hex: '#722F37',
  },
  {
    name: 'Medium Ruby',
    hex: '#AA4069',
  },
  {
    name: 'Pink',
    hex: '#FFC0CB',
  },
  {
    name: 'Lava',
    hex: '#CF1020',
  },
  {
    name: 'Cadet',
    hex: '#536872',
  },
  {
    name: 'Pale Violet-Red',
    hex: '#DB7093',
  },
  {
    name: 'Electric Blue',
    hex: '#7DF9FF',
  },
  {
    name: 'Azure (Web Color)',
    hex: '#F0FFFF',
  },
  {
    name: 'Light Goldenrod Yellow',
    hex: '#FAFAD2',
  },
  {
    name: 'Lavender Rose',
    hex: '#FBA0E3',
  },
  {
    name: 'Bright Turquoise',
    hex: '#08E8DE',
  },
  {
    name: 'Kombu Green',
    hex: '#354230',
  },
  {
    name: 'Yale Blue',
    hex: '#0F4D92',
  },
  {
    name: 'Pastel Violet',
    hex: '#CB99C9',
  },
  {
    name: 'Dark Byzantium',
    hex: '#5D3954',
  },
  {
    name: 'Majorelle Blue',
    hex: '#6050DC',
  },
  {
    name: 'Cinereous',
    hex: '#98817B',
  },
  {
    name: 'French Pink',
    hex: '#FD6C9E',
  },
  {
    name: 'Middle Green',
    hex: '#4D8C57',
  },
  {
    name: 'Light Medium Orchid',
    hex: '#D39BCB',
  },
  {
    name: 'Maroon (HTML/CSS)',
    hex: '#800000',
  },
  {
    name: 'Icterine',
    hex: '#FCF75E',
  },
  {
    name: 'Maximum Yellow',
    hex: '#FAFA37',
  },
  {
    name: 'Cinnamon',
    hex: '#D2691E',
  },
  {
    name: "Screamin' Green",
    hex: '#66FF66',
  },
  {
    name: 'Dark Pastel Purple',
    hex: '#966FD6',
  },
  {
    name: 'Aquamarine',
    hex: '#7FFFD4',
  },
  {
    name: 'Light Fuchsia Pink',
    hex: '#F984EF',
  },
  {
    name: 'Sandy Taupe',
    hex: '#967117',
  },
  {
    name: 'Wisteria',
    hex: '#C9A0DC',
  },
  {
    name: 'Irresistible',
    hex: '#B3446C',
  },
  {
    name: 'Yellow',
    hex: '#FFFF00',
  },
  {
    name: 'Cosmic Latte',
    hex: '#FFF8E7',
  },
  {
    name: 'Light Carmine Pink',
    hex: '#E66771',
  },
  {
    name: 'Maximum Yellow Red',
    hex: '#F2BA49',
  },
  {
    name: 'MSU Green',
    hex: '#18453B',
  },
  {
    name: 'Selective Yellow',
    hex: '#FFBA00',
  },
  {
    name: 'Bluebonnet',
    hex: '#1C1CF0',
  },
  {
    name: 'Fulvous',
    hex: '#E48400',
  },
  {
    name: 'Windsor Tan',
    hex: '#A75502',
  },
  {
    name: 'Maximum Blue Green',
    hex: '#30BFBF',
  },
  {
    name: 'Otter Brown',
    hex: '#654321',
  },
  {
    name: 'Big Foot Feet',
    hex: '#E88E5A',
  },
  {
    name: 'Green (Color Wheel) (X11 Green)',
    hex: '#00FF00',
  },
  {
    name: 'Pastel Orange',
    hex: '#FFB347',
  },
  {
    name: 'Castleton Green',
    hex: '#00563F',
  },
  {
    name: 'Liseran Purple',
    hex: '#DE6FA1',
  },
  {
    name: 'Baker-Miller Pink',
    hex: '#FF91AF',
  },
  {
    name: 'Deep Lemon',
    hex: '#F5C71A',
  },
  {
    name: 'Copper Red',
    hex: '#CB6D51',
  },
  {
    name: 'Purple (HTML)',
    hex: '#800080',
  },
  {
    name: 'Lemon Chiffon',
    hex: '#FFFACD',
  },
  {
    name: 'English Violet',
    hex: '#563C5C',
  },
  {
    name: 'Safety Orange',
    hex: '#FF7800',
  },
  {
    name: 'Light Sea Green',
    hex: '#20B2AA',
  },
  {
    name: 'Mahogany',
    hex: '#C04000',
  },
  {
    name: 'Vivid Lime Green',
    hex: '#A6D608',
  },
  {
    name: 'Light Blue',
    hex: '#ADD8E6',
  },
  {
    name: 'Alien Armpit',
    hex: '#84DE02',
  },
  {
    name: 'Palatinate Blue',
    hex: '#273BE2',
  },
  {
    name: 'Dark Liver (Horses)',
    hex: '#543D37',
  },
  {
    name: 'Sky Blue',
    hex: '#87CEEB',
  },
  {
    name: 'Sweet Brown',
    hex: '#A83731',
  },
  {
    name: "Mummy's Tomb",
    hex: '#828E84',
  },
  {
    name: 'Air Force Blue (USAF)',
    hex: '#00308F',
  },
  {
    name: 'Cinnamon Satin',
    hex: '#CD607E',
  },
  {
    name: 'Jonquil',
    hex: '#F4CA16',
  },
  {
    name: 'Classic Rose',
    hex: '#FBCCE7',
  },
  {
    name: 'Taupe',
    hex: '#483C32',
  },
  {
    name: 'Brandeis Blue',
    hex: '#0070FF',
  },
  {
    name: 'International Klein Blue',
    hex: '#002FA7',
  },
  {
    name: 'Tuscan Tan',
    hex: '#A67B5B',
  },
  {
    name: 'Pictorial Carmine',
    hex: '#C30B4E',
  },
  {
    name: 'Green Lizard',
    hex: '#A7F432',
  },
  {
    name: 'Plum (Web)',
    hex: '#DDA0DD',
  },
  {
    name: 'Alabaster',
    hex: '#F2F0E6',
  },
  {
    name: 'Dark Green',
    hex: '#013220',
  },
  {
    name: 'Sea Blue',
    hex: '#006994',
  },
  {
    name: 'Cherry Blossom Pink',
    hex: '#FFB7C5',
  },
  {
    name: 'Orange (RYB)',
    hex: '#FB9902',
  },
  {
    name: 'Eggshell',
    hex: '#F0EAD6',
  },
  {
    name: 'Spanish Violet',
    hex: '#4C2882',
  },
  {
    name: 'Warm Black',
    hex: '#004242',
  },
  {
    name: 'Dandelion',
    hex: '#F0E130',
  },
  {
    name: 'Yellow (RYB)',
    hex: '#FEFE33',
  },
  {
    name: 'Pomp And Power',
    hex: '#86608E',
  },
  {
    name: 'Blue-Violet',
    hex: '#8A2BE2',
  },
  {
    name: 'Red',
    hex: '#FF0000',
  },
  {
    name: 'Lemon Meringue',
    hex: '#F6EABE',
  },
  {
    name: 'Dark Electric Blue',
    hex: '#536878',
  },
  {
    name: 'Bright Lavender',
    hex: '#BF94E4',
  },
  {
    name: 'Teal',
    hex: '#008080',
  },
  {
    name: 'Deep Carmine Pink',
    hex: '#EF3038',
  },
  {
    name: 'Green (Crayola)',
    hex: '#1CAC78',
  },
  {
    name: 'Melon',
    hex: '#FDBCB4',
  },
  {
    name: 'Light Cobalt Blue',
    hex: '#88ACE0',
  },
  {
    name: 'Inchworm',
    hex: '#B2EC5D',
  },
  {
    name: 'Spanish Pink',
    hex: '#F7BFBE',
  },
  {
    name: 'Rich Black (FOGRA39)',
    hex: '#010203',
  },
  {
    name: 'Medium Spring Green',
    hex: '#00FA9A',
  },
  {
    name: 'Ruddy Pink',
    hex: '#E18E96',
  },
  {
    name: 'Very Light Azure',
    hex: '#74BBFB',
  },
  {
    name: 'Neon Carrot',
    hex: '#FFA343',
  },
  {
    name: 'Dark Slate Blue',
    hex: '#483D8B',
  },
  {
    name: 'Sunray',
    hex: '#E3AB57',
  },
  {
    name: 'Light Taupe',
    hex: '#B38B6D',
  },
  {
    name: 'Red-Purple',
    hex: '#E40078',
  },
  {
    name: 'Pale Goldenrod',
    hex: '#EEE8AA',
  },
  {
    name: 'Brown (Traditional)',
    hex: '#964B00',
  },
  {
    name: 'New Car',
    hex: '#214FC6',
  },
  {
    name: 'Turquoise Green',
    hex: '#A0D6B4',
  },
  {
    name: "Tiger's Eye",
    hex: '#E08D3C',
  },
  {
    name: 'Yellow Sunshine',
    hex: '#FFF700',
  },
  {
    name: 'Cyan Azure',
    hex: '#4E82B4',
  },
  {
    name: 'Violet-Red',
    hex: '#F75394',
  },
  {
    name: 'Vivid Yellow',
    hex: '#FFE302',
  },
  {
    name: 'Green (NCS)',
    hex: '#009F6B',
  },
  {
    name: 'Desire',
    hex: '#EA3C53',
  },
  {
    name: 'Electric Ultramarine',
    hex: '#3F00FF',
  },
  {
    name: 'Persian Orange',
    hex: '#D99058',
  },
  {
    name: 'Cool Grey',
    hex: '#8C92AC',
  },
  {
    name: 'Chestnut',
    hex: '#954535',
  },
  {
    name: 'Cyan',
    hex: '#00FFFF',
  },
  {
    name: 'UP Forest Green',
    hex: '#014421',
  },
  {
    name: 'Light Hot Pink',
    hex: '#FFB3DE',
  },
  {
    name: 'Sky Magenta',
    hex: '#CF71AF',
  },
  {
    name: 'Onyx',
    hex: '#353839',
  },
  {
    name: 'Rocket Metallic',
    hex: '#8A7F80',
  },
  {
    name: 'Medium Orchid',
    hex: '#BA55D3',
  },
  {
    name: 'Zaffre',
    hex: '#0014A8',
  },
  {
    name: 'Ocean Green',
    hex: '#48BF91',
  },
  {
    name: 'Cyan Cornflower Blue',
    hex: '#188BC2',
  },
  {
    name: 'Viridian',
    hex: '#40826D',
  },
  {
    name: 'Carmine Pink',
    hex: '#EB4C42',
  },
  {
    name: 'Atomic Tangerine',
    hex: '#FF9966',
  },
  {
    name: 'Gray',
    hex: '#808080',
  },
  {
    name: 'Canary Yellow',
    hex: '#FFEF00',
  },
  {
    name: 'Deep Moss Green',
    hex: '#355E3B',
  },
  {
    name: 'Kiwi',
    hex: '#8EE53F',
  },
  {
    name: 'Bulgarian Rose',
    hex: '#480607',
  },
  {
    name: 'UFO Green',
    hex: '#3CD070',
  },
  {
    name: 'Vivid Auburn',
    hex: '#922724',
  },
  {
    name: 'Raspberry Rose',
    hex: '#B3446C',
  },
  {
    name: 'Teal Deer',
    hex: '#99E6B3',
  },
  {
    name: 'May Green',
    hex: '#4C9141',
  },
  {
    name: 'Magnolia',
    hex: '#F8F4FF',
  },
  {
    name: 'Moccasin',
    hex: '#FAEBD7',
  },
  {
    name: 'Vivid Raspberry',
    hex: '#FF006C',
  },
  {
    name: 'Citron',
    hex: '#9FA91F',
  },
  {
    name: 'Harvard Crimson',
    hex: '#C90016',
  },
  {
    name: 'Debian Red',
    hex: '#D70A53',
  },
  {
    name: 'Phthalo Green',
    hex: '#123524',
  },
  {
    name: 'Electric Lime',
    hex: '#CCFF00',
  },
  {
    name: 'Mountbatten Pink',
    hex: '#997A8D',
  },
  {
    name: 'Pale Copper',
    hex: '#DA8A67',
  },
  {
    name: 'Pastel Brown',
    hex: '#836953',
  },
  {
    name: 'Carmine (M&P)',
    hex: '#D70040',
  },
  {
    name: 'Lemon Glacier',
    hex: '#FDFF00',
  },
  {
    name: 'Iguana Green',
    hex: '#71BC78',
  },
  {
    name: 'Venetian Red',
    hex: '#C80815',
  },
  {
    name: 'Cadet Grey',
    hex: '#91A3B0',
  },
  {
    name: 'Antique Ruby',
    hex: '#841B2D',
  },
  {
    name: 'Indian Red',
    hex: '#CD5C5C',
  },
  {
    name: 'Deep Space Sparkle',
    hex: '#4A646C',
  },
  {
    name: 'Pastel Pink',
    hex: '#DEA5A4',
  },
  {
    name: 'Metallic Seaweed',
    hex: '#0A7E8C',
  },
  {
    name: 'Arsenic',
    hex: '#3B444B',
  },
  {
    name: 'Ultramarine',
    hex: '#3F00FF',
  },
  {
    name: 'Raw Sienna',
    hex: '#D68A59',
  },
  {
    name: 'Light Orange',
    hex: '#FED8B1',
  },
  {
    name: 'Battery Charged Blue',
    hex: '#1DACD6',
  },
  {
    name: 'Dark Salmon',
    hex: '#E9967A',
  },
  {
    name: 'Persian Rose',
    hex: '#FE28A2',
  },
  {
    name: 'Pink Lace',
    hex: '#FFDDF4',
  },
  {
    name: 'Rose Dust',
    hex: '#9E5E6F',
  },
  {
    name: 'Deep Saffron',
    hex: '#FF9933',
  },
  {
    name: 'Dark Magenta',
    hex: '#8B008B',
  },
  {
    name: 'Pale Pink',
    hex: '#FADADD',
  },
  {
    name: 'Orange Soda',
    hex: '#FA5B3D',
  },
  {
    name: 'Deep Spring Bud',
    hex: '#556B2F',
  },
  {
    name: 'Gainsboro',
    hex: '#DCDCDC',
  },
  {
    name: 'Sizzling Sunrise',
    hex: '#FFDB00',
  },
  {
    name: 'Coffee',
    hex: '#6F4E37',
  },
  {
    name: 'Wine Dregs',
    hex: '#673147',
  },
  {
    name: 'Turquoise',
    hex: '#40E0D0',
  },
  {
    name: 'English Red',
    hex: '#AB4B52',
  },
  {
    name: 'Prune',
    hex: '#701C1C',
  },
  {
    name: 'Purple Navy',
    hex: '#4E5180',
  },
  {
    name: 'Deep Puce',
    hex: '#A95C68',
  },
  {
    name: 'USC Gold',
    hex: '#FFCC00',
  },
  {
    name: 'Brown (Web)',
    hex: '#A52A2A',
  },
  {
    name: 'Peridot',
    hex: '#E6E200',
  },
  {
    name: 'Light Coral',
    hex: '#F08080',
  },
  {
    name: 'Carrot Orange',
    hex: '#ED9121',
  },
  {
    name: 'Puce',
    hex: '#CC8899',
  },
  {
    name: 'Duke Blue',
    hex: '#00009C',
  },
  {
    name: 'Chartreuse (Traditional)',
    hex: '#DFFF00',
  },
  {
    name: 'Tea Rose',
    hex: '#F88379',
  },
  {
    name: 'Polished Pine',
    hex: '#5DA493',
  },
  {
    name: 'Lavender Purple',
    hex: '#967BB6',
  },
  {
    name: 'Pale Cerulean',
    hex: '#9BC4E2',
  },
  {
    name: 'Dark Gray (X11)',
    hex: '#A9A9A9',
  },
  {
    name: 'Sacramento State Green',
    hex: '#00563F',
  },
  {
    name: 'Electric Yellow',
    hex: '#FFFF33',
  },
  {
    name: 'UA Red',
    hex: '#D9004C',
  },
  {
    name: 'Burgundy',
    hex: '#800020',
  },
  {
    name: 'Verdigris',
    hex: '#43B3AE',
  },
  {
    name: "B'dazzled Blue",
    hex: '#2E5894',
  },
  {
    name: 'La Salle Green',
    hex: '#087830',
  },
  {
    name: 'Dark Imperial Blue',
    hex: '#00147E',
  },
  {
    name: 'Green-Yellow',
    hex: '#ADFF2F',
  },
  {
    name: 'AuroMetalSaurus',
    hex: '#6E7F80',
  },
  {
    name: 'Pale Robin Egg Blue',
    hex: '#96DED1',
  },
  {
    name: 'Flirt',
    hex: '#A2006D',
  },
  {
    name: 'Mauvelous',
    hex: '#EF98AA',
  },
  {
    name: 'Fuchsia Rose',
    hex: '#C74375',
  },
  {
    name: 'Persian Blue',
    hex: '#1C39BB',
  },
  {
    name: 'Barn Red',
    hex: '#7C0A02',
  },
  {
    name: 'Dark Orange',
    hex: '#FF8C00',
  },
  {
    name: 'Red Devil',
    hex: '#860111',
  },
  {
    name: 'Liver',
    hex: '#674C47',
  },
  {
    name: 'Bittersweet Shimmer',
    hex: '#BF4F51',
  },
  {
    name: 'Dark Violet',
    hex: '#9400D3',
  },
  {
    name: 'Magenta (Crayola)',
    hex: '#FF55A3',
  },
  {
    name: 'Purple Taupe',
    hex: '#50404D',
  },
  {
    name: 'Pastel Blue',
    hex: '#AEC6CF',
  },
  {
    name: 'Dark Spring Green',
    hex: '#177245',
  },
  {
    name: 'Laser Lemon',
    hex: '#FFFF66',
  },
  {
    name: 'Falu Red',
    hex: '#801818',
  },
  {
    name: 'Deep Taupe',
    hex: '#7E5E60',
  },
  {
    name: 'Generic Viridian',
    hex: '#007F66',
  },
  {
    name: 'Pale Blue',
    hex: '#AFEEEE',
  },
  {
    name: 'Pale Cornflower Blue',
    hex: '#ABCDEF',
  },
  {
    name: 'True Blue',
    hex: '#0073CF',
  },
  {
    name: 'Fluorescent Orange',
    hex: '#FFBF00',
  },
  {
    name: 'Interdimensional Blue',
    hex: '#360CCC',
  },
  {
    name: 'Magic Potion',
    hex: '#FF4466',
  },
  {
    name: 'Pale Chestnut',
    hex: '#DDADAF',
  },
  {
    name: 'Rose',
    hex: '#FF007F',
  },
  {
    name: 'Smashed Pumpkin',
    hex: '#FF6D3A',
  },
  {
    name: 'Rhythm',
    hex: '#777696',
  },
  {
    name: 'English Lavender',
    hex: '#B48395',
  },
  {
    name: 'Shadow Blue',
    hex: '#778BA5',
  },
  {
    name: 'Desaturated Cyan',
    hex: '#669999',
  },
  {
    name: 'Awesome',
    hex: '#FF2052',
  },
  {
    name: 'Daffodil',
    hex: '#FFFF31',
  },
  {
    name: 'Antique Fuchsia',
    hex: '#915C83',
  },
  {
    name: 'Medium Champagne',
    hex: '#F3E5AB',
  },
  {
    name: 'Myrtle Green',
    hex: '#317873',
  },
  {
    name: 'Granite Gray',
    hex: '#676767',
  },
  {
    name: 'Mint Cream',
    hex: '#F5FFFA',
  },
  {
    name: 'Cal Poly Pomona Green',
    hex: '#1E4D2B',
  },
  {
    name: 'Cyan Cobalt Blue',
    hex: '#28589C',
  },
  {
    name: 'Bistre',
    hex: '#3D2B1F',
  },
  {
    name: 'Ruddy Brown',
    hex: '#BB6528',
  },
  {
    name: "Big Dip O'ruby",
    hex: '#9C2542',
  },
  {
    name: 'Seal Brown',
    hex: '#59260B',
  },
  {
    name: 'Marigold',
    hex: '#EAA221',
  },
  {
    name: 'Deep Ruby',
    hex: '#843F5B',
  },
  {
    name: 'Dark Pastel Red',
    hex: '#C23B22',
  },
  {
    name: 'Morning Blue',
    hex: '#8DA399',
  },
  {
    name: 'Champagne',
    hex: '#F7E7CE',
  },
  {
    name: 'Razzmatazz',
    hex: '#E3256B',
  },
  {
    name: 'Thulian Pink',
    hex: '#DE6FA1',
  },
  {
    name: 'Royal Yellow',
    hex: '#FADA5E',
  },
  {
    name: 'Carmine Red',
    hex: '#FF0038',
  },
  {
    name: 'Iris',
    hex: '#5A4FCF',
  },
  {
    name: 'Fawn',
    hex: '#E5AA70',
  },
  {
    name: 'Baby Pink',
    hex: '#F4C2C2',
  },
  {
    name: 'Scarlet',
    hex: '#FD0E35',
  },
  {
    name: 'Fuchsia',
    hex: '#FF00FF',
  },
  {
    name: 'Cream',
    hex: '#FFFDD0',
  },
  {
    name: 'Stil De Grain Yellow',
    hex: '#FADA5E',
  },
  {
    name: 'Peach-Yellow',
    hex: '#FADFAD',
  },
  {
    name: 'Spring Bud',
    hex: '#A7FC00',
  },
  {
    name: 'Champagne Pink',
    hex: '#F1DDCF',
  },
  {
    name: 'Battleship Grey',
    hex: '#848482',
  },
  {
    name: 'Peru',
    hex: '#CD853F',
  },
  {
    name: 'Asparagus',
    hex: '#87A96B',
  },
  {
    name: 'Tyrian Purple',
    hex: '#66023C',
  },
  {
    name: 'Wild Orchid',
    hex: '#D470A2',
  },
  {
    name: 'Jordy Blue',
    hex: '#8AB9F1',
  },
  {
    name: 'Salmon',
    hex: '#FA8072',
  },
  {
    name: 'Button Blue',
    hex: '#24A0ED',
  },
  {
    name: 'Mughal Green',
    hex: '#306030',
  },
  {
    name: 'Madder Lake',
    hex: '#CC3336',
  },
  {
    name: 'Rubine Red',
    hex: '#D10056',
  },
  {
    name: 'Lavender Gray',
    hex: '#C4C3D0',
  },
  {
    name: 'Indian Yellow',
    hex: '#E3A857',
  },
  {
    name: 'Light Slate Gray',
    hex: '#778899',
  },
  {
    name: 'Rose Quartz',
    hex: '#AA98A9',
  },
  {
    name: 'Cadet Blue',
    hex: '#5F9EA0',
  },
  {
    name: 'Carolina Blue',
    hex: '#56A0D3',
  },
  {
    name: 'Linen',
    hex: '#FAF0E6',
  },
  {
    name: 'Gray-Asparagus',
    hex: '#465945',
  },
  {
    name: 'Deep Carrot Orange',
    hex: '#E9692C',
  },
  {
    name: 'Pale Carmine',
    hex: '#AF4035',
  },
  {
    name: 'Brilliant Azure',
    hex: '#3399FF',
  },
  {
    name: 'Dark Sienna',
    hex: '#3C1414',
  },
  {
    name: 'Mountain Meadow',
    hex: '#30BA8F',
  },
  {
    name: 'Bright Green',
    hex: '#66FF00',
  },
  {
    name: 'Slate Blue',
    hex: '#6A5ACD',
  },
  {
    name: 'Coyote Brown',
    hex: '#81613C',
  },
  {
    name: 'Cyan (Process)',
    hex: '#00B7EB',
  },
  {
    name: 'Minion Yellow',
    hex: '#F5E050',
  },
  {
    name: 'Baby Powder',
    hex: '#FEFEFA',
  },
  {
    name: 'Rosy Brown',
    hex: '#BC8F8F',
  },
  {
    name: 'Very Light Blue',
    hex: '#6666FF',
  },
  {
    name: 'Magenta (Dye)',
    hex: '#CA1F7B',
  },
  {
    name: 'Electric Cyan',
    hex: '#00FFFF',
  },
  {
    name: 'Dust Storm',
    hex: '#E5CCC9',
  },
  {
    name: 'Powder Blue',
    hex: '#B0E0E6',
  },
  {
    name: 'Tangerine',
    hex: '#F28500',
  },
  {
    name: 'Bronze',
    hex: '#CD7F32',
  },
  {
    name: 'Rose Vale',
    hex: '#AB4E52',
  },
  {
    name: 'Roman Silver',
    hex: '#838996',
  },
  {
    name: 'Cinnabar',
    hex: '#E34234',
  },
  {
    name: 'Deep Champagne',
    hex: '#FAD6A5',
  },
  {
    name: 'Cafe Noir',
    hex: '#4B3621',
  },
  {
    name: 'Bright Navy Blue',
    hex: '#1974D2',
  },
  {
    name: 'Rose Gold',
    hex: '#B76E79',
  },
  {
    name: 'Deep Mauve',
    hex: '#D473D4',
  },
  {
    name: 'Dark Goldenrod',
    hex: '#B8860B',
  },
  {
    name: 'Middle Red',
    hex: '#E58E73',
  },
  {
    name: 'Spiro Disco Ball',
    hex: '#0FC0FC',
  },
  {
    name: 'Dark Candy Apple Red',
    hex: '#A40000',
  },
  {
    name: 'Rose Red',
    hex: '#C21E56',
  },
  {
    name: 'Sage',
    hex: '#BCB88A',
  },
  {
    name: 'Steel Teal',
    hex: '#5F8A8B',
  },
  {
    name: 'Yellow (Process)',
    hex: '#FFEF00',
  },
  {
    name: 'Azure',
    hex: '#007FFF',
  },
  {
    name: 'Ghost White',
    hex: '#F8F8FF',
  },
  {
    name: 'Deep Sky Blue',
    hex: '#00BFFF',
  },
  {
    name: 'Pastel Red',
    hex: '#FF6961',
  },
  {
    name: 'Alice Blue',
    hex: '#F0F8FF',
  },
  {
    name: 'Vivid Red-Tangelo',
    hex: '#DF6124',
  },
  {
    name: 'Green (Munsell)',
    hex: '#00A877',
  },
  {
    name: 'Orange Peel',
    hex: '#FF9F00',
  },
  {
    name: 'Paris Green',
    hex: '#50C878',
  },
  {
    name: 'Red (NCS)',
    hex: '#C40233',
  },
  {
    name: 'Dark Imperial Blue',
    hex: '#00416A',
  },
  {
    name: 'Celadon Green',
    hex: '#2F847C',
  },
  {
    name: 'Pullman Green',
    hex: '#3B331C',
  },
  {
    name: 'Stizza',
    hex: '#990000',
  },
  {
    name: 'Cornell Red',
    hex: '#B31B1B',
  },
  {
    name: 'Magenta-Pink',
    hex: '#CC338B',
  },
  {
    name: 'Light Deep Pink',
    hex: '#FF5CCD',
  },
  {
    name: 'Smoky Black',
    hex: '#100C08',
  },
  {
    name: 'Light Pastel Purple',
    hex: '#B19CD9',
  },
  {
    name: 'Buff',
    hex: '#F0DC82',
  },
  {
    name: 'Ruber',
    hex: '#CE4676',
  },
  {
    name: 'Paradise Pink',
    hex: '#E63E62',
  },
  {
    name: 'Rich Black',
    hex: '#004040',
  },
  {
    name: 'Sizzling Red',
    hex: '#FF3855',
  },
  {
    name: 'Brunswick Green',
    hex: '#1B4D3E',
  },
  {
    name: 'Caribbean Green',
    hex: '#00CC99',
  },
  {
    name: 'Green (Pantone)',
    hex: '#00AD43',
  },
  {
    name: 'Persimmon',
    hex: '#EC5800',
  },
  {
    name: 'Silver Pink',
    hex: '#C4AEAD',
  },
  {
    name: 'Flavescent',
    hex: '#F7E98E',
  },
  {
    name: 'Silver Lake Blue',
    hex: '#5D89BA',
  },
  {
    name: 'Eucalyptus',
    hex: '#44D7A8',
  },
  {
    name: "Giant's Club",
    hex: '#B05C52',
  },
  {
    name: 'Black',
    hex: '#000000',
  },
  {
    name: 'Steel Pink',
    hex: '#CC33CC',
  },
  {
    name: 'Resolution Blue',
    hex: '#002387',
  },
  {
    name: 'Dirt',
    hex: '#9B7653',
  },
  {
    name: 'Popstar',
    hex: '#BE4F62',
  },
  {
    name: 'Deep Green',
    hex: '#056608',
  },
  {
    name: 'Ball Blue',
    hex: '#21ABCD',
  },
  {
    name: 'Rich Lilac',
    hex: '#B666D2',
  },
  {
    name: 'Amaranth',
    hex: '#E52B50',
  },
  {
    name: 'Dark Powder Blue',
    hex: '#003399',
  },
  {
    name: 'Tuscany',
    hex: '#C09999',
  },
  {
    name: 'Green (RYB)',
    hex: '#66B032',
  },
  {
    name: 'French Bistre',
    hex: '#856D4D',
  },
  {
    name: 'Vivid Cerulean',
    hex: '#00AAEE',
  },
  {
    name: 'Dark Orchid',
    hex: '#9932CC',
  },
  {
    name: 'Green-Blue',
    hex: '#1164B4',
  },
  {
    name: 'Blush',
    hex: '#DE5D83',
  },
  {
    name: 'Pearl Aqua',
    hex: '#88D8C0',
  },
  {
    name: 'Orange-Yellow',
    hex: '#F8D568',
  },
  {
    name: 'Bud Green',
    hex: '#7BB661',
  },
  {
    name: 'Light Salmon',
    hex: '#FFA07A',
  },
  {
    name: 'Purple Mountain Majesty',
    hex: '#9678B6',
  },
  {
    name: 'Pewter Blue',
    hex: '#8BA8B7',
  },
  {
    name: 'Arctic Lime',
    hex: '#D0FF14',
  },
  {
    name: 'Manatee',
    hex: '#979AAA',
  },
  {
    name: 'KSU Purple',
    hex: '#512888',
  },
  {
    name: 'Middle Yellow Red',
    hex: '#ECB176',
  },
  {
    name: 'Blue Sapphire',
    hex: '#126180',
  },
  {
    name: 'Bright Yellow (Crayola)',
    hex: '#FFAA1D',
  },
  {
    name: 'Sonic Silver',
    hex: '#757575',
  },
  {
    name: 'Dark Blue-Gray',
    hex: '#666699',
  },
  {
    name: 'Fire Engine Red',
    hex: '#CE2029',
  },
  {
    name: 'Plump Purple',
    hex: '#5946B2',
  },
  {
    name: 'Gray (X11 Gray)',
    hex: '#BEBEBE',
  },
  {
    name: 'Black Shadows',
    hex: '#BFAFB2',
  },
  {
    name: 'Maximum Red',
    hex: '#D92121',
  },
  {
    name: 'Barbie Pink',
    hex: '#E0218A',
  },
  {
    name: 'Royal Blue',
    hex: '#002366',
  },
  {
    name: 'Little Boy Blue',
    hex: '#6CA0DC',
  },
  {
    name: 'Eton Blue',
    hex: '#96C8A2',
  },
  {
    name: 'Bubbles',
    hex: '#E7FEFF',
  },
  {
    name: 'Android Green',
    hex: '#A4C639',
  },
  {
    name: 'Middle Red Purple',
    hex: '#210837',
  },
  {
    name: 'Candy Pink',
    hex: '#E4717A',
  },
  {
    name: 'Mango Tango',
    hex: '#FF8243',
  },
  {
    name: 'Paolo Veronese Green',
    hex: '#009B7D',
  },
  {
    name: 'Magenta',
    hex: '#FF00FF',
  },
  {
    name: 'Emerald',
    hex: '#50C878',
  },
  {
    name: 'Harvest Gold',
    hex: '#DA9100',
  },
  {
    name: 'Wild Strawberry',
    hex: '#FF43A4',
  },
  {
    name: 'Pastel Magenta',
    hex: '#F49AC2',
  },
  {
    name: 'Non-Photo Blue',
    hex: '#A4DDED',
  },
  {
    name: 'Light Orchid',
    hex: '#E6A8D7',
  },
  {
    name: 'Apricot',
    hex: '#FBCEB1',
  },
  {
    name: 'Silver Chalice',
    hex: '#ACACAC',
  },
  {
    name: 'Coral Red',
    hex: '#FF4040',
  },
  {
    name: 'Magenta Haze',
    hex: '#9F4576',
  },
  {
    name: 'Mauve Taupe',
    hex: '#915F6D',
  },
  {
    name: 'Light Brown',
    hex: '#B5651D',
  },
  {
    name: 'Banana Yellow',
    hex: '#FFE135',
  },
  {
    name: 'Sea Foam Green',
    hex: '#9FE2BF',
  },
  {
    name: 'Mint',
    hex: '#3EB489',
  },
  {
    name: 'Nadeshiko Pink',
    hex: '#F6ADC6',
  },
  {
    name: 'Kenyan Copper',
    hex: '#7C1C05',
  },
  {
    name: 'Tickle Me Pink',
    hex: '#FC89AC',
  },
  {
    name: 'Gargoyle Gas',
    hex: '#FFDF46',
  },
  {
    name: 'Orchid Pink',
    hex: '#F2BDCD',
  },
  {
    name: 'Dark Purple',
    hex: '#301934',
  },
  {
    name: 'Cadmium Yellow',
    hex: '#FFF600',
  },
  {
    name: 'Anti-Flash White',
    hex: '#F2F3F4',
  },
  {
    name: 'Cosmic Cobalt',
    hex: '#2E2D88',
  },
  {
    name: 'Dark Blue',
    hex: '#00008B',
  },
  {
    name: 'French Wine',
    hex: '#AC1E44',
  },
  {
    name: 'Vermilion',
    hex: '#D9381E',
  },
  {
    name: 'Medium Candy Apple Red',
    hex: '#E2062C',
  },
  {
    name: 'Royal Purple',
    hex: '#7851A9',
  },
  {
    name: 'Maximum Green Yellow',
    hex: '#D9E650',
  },
  {
    name: 'Gray (HTML/CSS Gray)',
    hex: '#808080',
  },
  {
    name: 'Russian Violet',
    hex: '#32174D',
  },
  {
    name: 'Mantis',
    hex: '#74C365',
  },
  {
    name: 'Laurel Green',
    hex: '#A9BA9D',
  },
  {
    name: 'Grape',
    hex: '#6F2DA8',
  },
  {
    name: 'Goldenrod',
    hex: '#DAA520',
  },
  {
    name: 'Cotton Candy',
    hex: '#FFBCD9',
  },
  {
    name: 'Schauss Pink',
    hex: '#FF91AF',
  },
  {
    name: 'Winter Sky',
    hex: '#FF007C',
  },
  {
    name: 'Persian Pink',
    hex: '#F77FBE',
  },
  {
    name: 'Khaki (HTML/CSS) (Khaki)',
    hex: '#C3B091',
  },
  {
    name: 'Red (Pigment)',
    hex: '#ED1C24',
  },
  {
    name: 'Platinum',
    hex: '#E5E4E2',
  },
  {
    name: 'Sinopia',
    hex: '#CB410B',
  },
  {
    name: 'Peach',
    hex: '#FFCBA4',
  },
  {
    name: 'Medium Spring Bud',
    hex: '#C9DC87',
  },
  {
    name: 'Dollar Bill',
    hex: '#85BB65',
  },
  {
    name: 'Deep Lilac',
    hex: '#9955BB',
  },
  {
    name: 'Purple (Munsell)',
    hex: '#9F00C5',
  },
  {
    name: 'Light Crimson',
    hex: '#F56991',
  },
  {
    name: 'Misty Rose',
    hex: '#FFE4E1',
  },
  {
    name: 'Hunter Green',
    hex: '#355E3B',
  },
  {
    name: 'Navy',
    hex: '#000080',
  },
  {
    name: 'Grizzly',
    hex: '#885818',
  },
  {
    name: 'Electric Crimson',
    hex: '#FF003F',
  },
  {
    name: 'Camel',
    hex: '#C19A6B',
  },
  {
    name: 'Donkey Brown',
    hex: '#664C28',
  },
  {
    name: 'Princess Perfume',
    hex: '#FF85CF',
  },
  {
    name: 'Sea Green',
    hex: '#2E8B57',
  },
  {
    name: 'Dutch White',
    hex: '#EFDFBB',
  },
  {
    name: 'Cerulean Frost',
    hex: '#6D9BC3',
  },
  {
    name: 'Flame',
    hex: '#E25822',
  },
  {
    name: 'Burnt Orange',
    hex: '#CC5500',
  },
  {
    name: 'International Orange (Engineering)',
    hex: '#BA160C',
  },
  {
    name: 'Flattery',
    hex: '#6B4423',
  },
  {
    name: 'Japanese Carmine',
    hex: '#9D2933',
  },
  {
    name: 'Ochre',
    hex: '#CC7722',
  },
  {
    name: 'Aero',
    hex: '#7CB9E8',
  },
  {
    name: 'Dark Cyan',
    hex: '#008B8B',
  },
  {
    name: 'Ultra Pink',
    hex: '#FF6FFF',
  },
  {
    name: 'Cerise',
    hex: '#DE3163',
  },
  {
    name: 'Spring Green',
    hex: '#00FF7F',
  },
  {
    name: 'Chocolate (Traditional)',
    hex: '#7B3F00',
  },
  {
    name: 'Slate Gray',
    hex: '#708090',
  },
  {
    name: 'Vivid Malachite',
    hex: '#00CC33',
  },
  {
    name: 'Brilliant Rose',
    hex: '#FF55A3',
  },
  {
    name: 'Bitter Lime',
    hex: '#BFFF00',
  },
  {
    name: 'Islamic Green',
    hex: '#009000',
  },
  {
    name: 'Feldgrau',
    hex: '#4D5D53',
  },
  {
    name: 'Rebecca Purple',
    hex: '#663399',
  },
  {
    name: 'Prussian Blue',
    hex: '#003153',
  },
  {
    name: 'Mellow Yellow',
    hex: '#F8DE7E',
  },
  {
    name: 'Lavender Mist',
    hex: '#E6E6FA',
  },
  {
    name: 'Golden Brown',
    hex: '#996515',
  },
  {
    name: 'Dark Pastel Blue',
    hex: '#779ECB',
  },
  {
    name: 'Sapphire Blue',
    hex: '#0067A5',
  },
  {
    name: 'Halaya Ube',
    hex: '#663854',
  },
  {
    name: 'Rich Black (FOGRA29)',
    hex: '#010B13',
  },
  {
    name: 'Rackley',
    hex: '#5D8AA8',
  },
  {
    name: 'Chamoisee',
    hex: '#A0785A',
  },
  {
    name: 'Liver (Dogs)',
    hex: '#B86D29',
  },
  {
    name: 'Bangladesh Green',
    hex: '#006A4E',
  },
  {
    name: 'Medium Vermilion',
    hex: '#D9603B',
  },
  {
    name: 'Old Moss Green',
    hex: '#867E36',
  },
  {
    name: 'Spanish Sky Blue',
    hex: '#00FFFF',
  },
  {
    name: 'Mordant Red 19',
    hex: '#AE0C00',
  },
  {
    name: 'Indigo',
    hex: '#4B0082',
  },
  {
    name: 'Columbia Blue',
    hex: '#C4D8E2',
  },
  {
    name: 'Light Red Ochre',
    hex: '#E97451',
  },
  {
    name: 'Brass',
    hex: '#B5A642',
  },
  {
    name: 'Blue (RYB)',
    hex: '#0247FE',
  },
  {
    name: 'Middle Yellow',
    hex: '#FFEB00',
  },
  {
    name: 'Tea Green',
    hex: '#D0F0C0',
  },
  {
    name: 'Nickel',
    hex: '#727472',
  },
  {
    name: 'Pale Magenta-Pink',
    hex: '#FF99CC',
  },
  {
    name: 'Maximum Blue Purple',
    hex: '#ACACE6',
  },
  {
    name: 'Electric Green',
    hex: '#00FF00',
  },
  {
    name: 'Fluorescent Pink',
    hex: '#FF1493',
  },
  {
    name: 'Frogert',
    hex: '#E936A7',
  },
  {
    name: 'Vivid Burgundy',
    hex: '#9F1D35',
  },
  {
    name: 'Rich Maroon',
    hex: '#B03060',
  },
  {
    name: 'Lavender Blue',
    hex: '#CCCCFF',
  },
  {
    name: 'Banana Mania',
    hex: '#FAE7B5',
  },
  {
    name: 'Lavender Pink',
    hex: '#FBAED2',
  },
  {
    name: 'Antique Bronze',
    hex: '#665D1E',
  },
  {
    name: 'Vista Blue',
    hex: '#7C9ED9',
  },
  {
    name: 'Pale Magenta',
    hex: '#F984E5',
  },
  {
    name: 'Plum',
    hex: '#8E4585',
  },
  {
    name: 'Bronze Yellow',
    hex: '#737000',
  },
  {
    name: 'Harlequin Green',
    hex: '#46CB18',
  },
  {
    name: 'Sandy Tan',
    hex: '#FDD9B5',
  },
  {
    name: 'OU Crimson Red',
    hex: '#990000',
  },
  {
    name: 'Sugar Plum',
    hex: '#914E75',
  },
  {
    name: 'Cadmium Orange',
    hex: '#ED872D',
  },
  {
    name: 'Opera Mauve',
    hex: '#B784A7',
  },
  {
    name: 'Lemon',
    hex: '#FFF700',
  },
  {
    name: 'Cobalt Blue',
    hex: '#0047AB',
  },
  {
    name: 'Orange-Red',
    hex: '#FF4500',
  },
  {
    name: 'Firebrick',
    hex: '#B22222',
  },
  {
    name: 'Vivid Red',
    hex: '#F70D1A',
  },
  {
    name: 'Peach-Orange',
    hex: '#FFCC99',
  },
  {
    name: 'Aureolin',
    hex: '#FDEE00',
  },
  {
    name: 'Rich Lavender',
    hex: '#A76BCF',
  },
  {
    name: 'Deep Koamaru',
    hex: '#333366',
  },
  {
    name: 'Electric Violet',
    hex: '#8F00FF',
  },
  {
    name: 'Heliotrope Magenta',
    hex: '#AA00BB',
  },
  {
    name: 'Fuzzy Wuzzy',
    hex: '#CC6666',
  },
  {
    name: 'Silver',
    hex: '#C0C0C0',
  },
  {
    name: 'Shocking Pink',
    hex: '#FC0FC0',
  },
  {
    name: 'Cerulean Blue',
    hex: '#2A52BE',
  },
  {
    name: 'June Bud',
    hex: '#BDDA57',
  },
  {
    name: 'Phlox',
    hex: '#DF00FF',
  },
  {
    name: 'Light Yellow',
    hex: '#FFFFE0',
  },
  {
    name: 'Electric Lavender',
    hex: '#F4BBFF',
  },
  {
    name: 'Maya Blue',
    hex: '#73C2FB',
  },
  {
    name: 'Spanish Crimson',
    hex: '#E51A4C',
  },
  {
    name: 'Egyptian Blue',
    hex: '#1034A6',
  },
  {
    name: 'China Rose',
    hex: '#A8516E',
  },
  {
    name: 'Deep Tuscan Red',
    hex: '#66424D',
  },
  {
    name: 'Medium Tuscan Red',
    hex: '#79443B',
  },
  {
    name: 'Eminence',
    hex: '#6C3082',
  },
  {
    name: 'Carnation Pink',
    hex: '#FFA6C9',
  },
  {
    name: 'Oxford Blue',
    hex: '#002147',
  },
  {
    name: 'Toolbox',
    hex: '#746CC0',
  },
  {
    name: 'Trolley Grey',
    hex: '#808080',
  },
  {
    name: 'Portland Orange',
    hex: '#FF5A36',
  },
  {
    name: 'Aqua',
    hex: '#00FFFF',
  },
  {
    name: 'Lemon Curry',
    hex: '#CCA01D',
  },
  {
    name: 'Blue (NCS)',
    hex: '#0087BD',
  },
  {
    name: 'Shimmering Blush',
    hex: '#D98695',
  },
  {
    name: 'Unbleached Silk',
    hex: '#FFDDCA',
  },
  {
    name: 'Honeydew',
    hex: '#F0FFF0',
  },
  {
    name: 'Bole',
    hex: '#79443B',
  },
  {
    name: 'Pacific Blue',
    hex: '#1CA9C9',
  },
  {
    name: 'Medium Sky Blue',
    hex: '#80DAEB',
  },
  {
    name: 'Medium Sea Green',
    hex: '#3CB371',
  },
  {
    name: 'Heart Gold',
    hex: '#808000',
  },
  {
    name: 'Vivid Vermilion',
    hex: '#E56024',
  },
  {
    name: 'French Plum',
    hex: '#811453',
  },
  {
    name: 'Palatinate Purple',
    hex: '#682860',
  },
  {
    name: 'Silver Sand',
    hex: '#BFC1C2',
  },
  {
    name: 'Green (HTML/CSS Color)',
    hex: '#008000',
  },
  {
    name: 'Dark Coral',
    hex: '#CD5B45',
  },
  {
    name: 'University Of Tennessee Orange',
    hex: '#F77F00',
  },
  {
    name: 'Violet (Color Wheel)',
    hex: '#7F00FF',
  },
  {
    name: 'Deep Pink',
    hex: '#FF1493',
  },
  {
    name: 'Van Dyke Brown',
    hex: '#664228',
  },
  {
    name: 'Vivid Tangelo',
    hex: '#F07427',
  },
  {
    name: 'Japanese Violet',
    hex: '#5B3256',
  },
  {
    name: 'Lilac',
    hex: '#C8A2C8',
  },
  {
    name: 'Taupe Gray',
    hex: '#8B8589',
  },
  {
    name: 'Vivid Gamboge',
    hex: '#FF9900',
  },
  {
    name: 'Yellow (NCS)',
    hex: '#FFD300',
  },
  {
    name: 'Bleu De France',
    hex: '#318CE7',
  },
  {
    name: 'Claret',
    hex: '#7F1734',
  },
  {
    name: 'Deep Cerise',
    hex: '#DA3287',
  },
  {
    name: 'Mardi Gras',
    hex: '#880085',
  },
  {
    name: 'Pixie Powder',
    hex: '#391285',
  },
  {
    name: 'Tuscan',
    hex: '#FAD6A5',
  },
  {
    name: 'Light Thulian Pink',
    hex: '#E68FAC',
  },
  {
    name: 'Pine Green',
    hex: '#01796F',
  },
  {
    name: 'Beau Blue',
    hex: '#BCD4E6',
  },
  {
    name: 'Pineapple',
    hex: '#563C0D',
  },
  {
    name: 'Safety Orange (Blaze Orange)',
    hex: '#FF6700',
  },
  {
    name: 'Light French Beige',
    hex: '#C8AD7F',
  },
  {
    name: 'Electric Purple',
    hex: '#BF00FF',
  },
  {
    name: 'Raspberry',
    hex: '#E30B5D',
  },
  {
    name: 'Milk',
    hex: '#FDFFF5',
  },
  {
    name: 'India Green',
    hex: '#138808',
  },
  {
    name: 'Purple Pizzazz',
    hex: '#FE4EDA',
  },
  {
    name: 'Raw Umber',
    hex: '#826644',
  },
  {
    name: 'Dark Terra Cotta',
    hex: '#CC4E5C',
  },
  {
    name: 'Booger Buster',
    hex: '#DDE26A',
  },
  {
    name: 'Orange (Web)',
    hex: '#FFA500',
  },
  {
    name: 'Sand',
    hex: '#C2B280',
  },
  {
    name: 'Smalt (Dark Powder Blue)',
    hex: '#003399',
  },
  {
    name: 'Pansy Purple',
    hex: '#78184A',
  },
  {
    name: 'Magenta (Pantone)',
    hex: '#D0417E',
  },
  {
    name: 'Alabama Crimson',
    hex: '#AF002A',
  },
  {
    name: 'Terra Cotta',
    hex: '#E2725B',
  },
  {
    name: 'Napier Green',
    hex: '#2A8000',
  },
  {
    name: 'Crimson Glory',
    hex: '#BE0032',
  },
  {
    name: 'Citrine',
    hex: '#E4D00A',
  },
  {
    name: 'Arylide Yellow',
    hex: '#E9D66B',
  },
  {
    name: 'Spanish Blue',
    hex: '#0070B8',
  },
  {
    name: 'Amber (SAE/ECE)',
    hex: '#FF7E00',
  },
  {
    name: 'Pink Raspberry',
    hex: '#980036',
  },
  {
    name: 'Tangelo',
    hex: '#F94D00',
  },
  {
    name: 'Deep Magenta',
    hex: '#CC00CC',
  },
  {
    name: 'Medium Lavender Magenta',
    hex: '#DDA0DD',
  },
  {
    name: 'Metallic Sunburst',
    hex: '#9C7C38',
  },
  {
    name: 'Rajah',
    hex: '#FBAB60',
  },
  {
    name: 'Turtle Green',
    hex: '#8A9A5B',
  },
  {
    name: 'Copper (Crayola)',
    hex: '#DA8A67',
  },
  {
    name: 'Yellow (Crayola)',
    hex: '#FCE883',
  },
  {
    name: 'Blue Bell',
    hex: '#A2A2D0',
  },
  {
    name: 'Black Olive',
    hex: '#3B3C36',
  },
  {
    name: 'Candy Apple Red',
    hex: '#FF0800',
  },
  {
    name: 'Dolphin Gray',
    hex: '#828E84',
  },
  {
    name: 'Fuchsia Purple',
    hex: '#CC397B',
  },
  {
    name: 'Imperial Purple',
    hex: '#66023C',
  },
  {
    name: 'Wintergreen Dream',
    hex: '#56887D',
  },
  {
    name: 'Tango Pink',
    hex: '#E4717A',
  },
  {
    name: 'Indigo (Web)',
    hex: '#4B0082',
  },
  {
    name: 'Neon Fuchsia',
    hex: '#FE4164',
  },
  {
    name: 'Copper Rose',
    hex: '#996666',
  },
  {
    name: 'Field Drab',
    hex: '#6C541E',
  },
  {
    name: 'British Racing Green',
    hex: '#004225',
  },
  {
    name: 'Radical Red',
    hex: '#FF355E',
  },
  {
    name: 'Tangerine Yellow',
    hex: '#FFCC00',
  },
  {
    name: 'Ruby',
    hex: '#E0115F',
  },
  {
    name: 'Tenne (Tawny)',
    hex: '#CD5700',
  },
  {
    name: 'Blue-Magenta Violet',
    hex: '#553592',
  },
  {
    name: 'Brown Yellow',
    hex: '#cc9966',
  },
  {
    name: 'Han Purple',
    hex: '#5218FA',
  },
  {
    name: 'Violet (RYB)',
    hex: '#8601AF',
  },
  {
    name: 'Misty Moss',
    hex: '#BBB477',
  },
  {
    name: 'Old Burgundy',
    hex: '#43302E',
  },
  {
    name: 'Mandarin',
    hex: '#F37A48',
  },
  {
    name: 'Fandango',
    hex: '#B53389',
  },
  {
    name: 'Dark Brown',
    hex: '#654321',
  },
  {
    name: 'Apple Green',
    hex: '#8DB600',
  },
  {
    name: 'Rosewood',
    hex: '#65000B',
  },
  {
    name: 'Heliotrope Gray',
    hex: '#AA98A9',
  },
  {
    name: 'Outrageous Orange',
    hex: '#FF6E4A',
  },
  {
    name: 'Crimson Red',
    hex: '#990000',
  },
  {
    name: 'Pakistan Green',
    hex: '#006600',
  },
  {
    name: 'USC Cardinal',
    hex: '#990000',
  },
  {
    name: 'Cedar Chest',
    hex: '#C95A49',
  },
  {
    name: 'Livid',
    hex: '#6699CC',
  },
  {
    name: 'Bottle Green',
    hex: '#006A4E',
  },
  {
    name: 'White Smoke',
    hex: '#F5F5F5',
  },
  {
    name: 'Bazaar',
    hex: '#98777B',
  },
  {
    name: 'Pale Red-Violet',
    hex: '#DB7093',
  },
  {
    name: 'Lilac Luster',
    hex: '#AE98AA',
  },
  {
    name: 'Dark Lava',
    hex: '#483C32',
  },
  {
    name: 'Nyanza',
    hex: '#E9FFDB',
  },
  {
    name: 'Mulberry',
    hex: '#C54B8C',
  },
  {
    name: 'Cadmium Green',
    hex: '#006B3C',
  },
  {
    name: 'Mellow Apricot',
    hex: '#F8B878',
  },
  {
    name: 'China Pink',
    hex: '#DE6FA1',
  },
  {
    name: 'Pink (Pantone)',
    hex: '#D74894',
  },
  {
    name: 'Khaki (X11) (Light Khaki)',
    hex: '#F0E68C',
  },
  {
    name: 'Ferrari Red',
    hex: '#FF2800',
  },
  {
    name: 'Carnelian',
    hex: '#B31B1B',
  },
  {
    name: 'Picton Blue',
    hex: '#45B1E8',
  },
  {
    name: 'Coquelicot',
    hex: '#FF3800',
  },
  {
    name: 'Rich Brilliant Lavender',
    hex: '#F1A7FE',
  },
  {
    name: "St. Patrick's Blue",
    hex: '#23297A',
  },
  {
    name: 'Blue Lagoon',
    hex: '#ACE5EE',
  },
  {
    name: 'Feldspar',
    hex: '#FDD5B1',
  },
  {
    name: 'Dark Red',
    hex: '#8B0000',
  },
  {
    name: 'Pink Sherbet',
    hex: '#F78FA7',
  },
  {
    name: 'Harlequin',
    hex: '#3FFF00',
  },
  {
    name: 'Pale Violet',
    hex: '#CC99FF',
  },
  {
    name: 'Deep Chestnut',
    hex: '#B94E48',
  },
  {
    name: 'Flax',
    hex: '#EEDC82',
  },
  {
    name: 'Coral',
    hex: '#FF7F50',
  },
  {
    name: 'Volt',
    hex: '#CEFF00',
  },
  {
    name: 'Capri',
    hex: '#00BFFF',
  },
  {
    name: 'Dark Moss Green',
    hex: '#4A5D23',
  },
  {
    name: 'Liver (Organ)',
    hex: '#6C2E1F',
  },
  {
    name: 'Middle Red Purple',
    hex: '#A55353',
  },
  {
    name: 'French Beige',
    hex: '#A67B5B',
  },
  {
    name: 'Red-Violet',
    hex: '#C71585',
  },
  {
    name: 'Black Coral',
    hex: '#54626F',
  },
  {
    name: 'Dark Jungle Green',
    hex: '#1A2421',
  },
  {
    name: 'Army Green',
    hex: '#4B5320',
  },
  {
    name: 'Black Leather Jacket',
    hex: '#253529',
  },
  {
    name: 'Light Gray',
    hex: '#D3D3D3',
  },
  {
    name: 'Copper Penny',
    hex: '#AD6F69',
  },
  {
    name: 'Mustard',
    hex: '#FFDB58',
  },
  {
    name: 'Canary',
    hex: '#FFFF99',
  },
  {
    name: 'Pink-Orange',
    hex: '#FF9966',
  },
  {
    name: 'Smokey Topaz',
    hex: '#832A0D',
  },
  {
    name: 'CG Red',
    hex: '#E03C31',
  },
  {
    name: 'Solid Pink',
    hex: '#893843',
  },
  {
    name: 'Gold Fusion',
    hex: '#85754E',
  },
  {
    name: 'Copper',
    hex: '#B87333',
  },
  {
    name: 'Japanese Indigo',
    hex: '#264348',
  },
  {
    name: 'Celadon',
    hex: '#ACE1AF',
  },
  {
    name: 'Mikado Yellow',
    hex: '#FFC40C',
  },
  {
    name: 'Shadow',
    hex: '#8A795D',
  },
  {
    name: 'Olivine',
    hex: '#9AB973',
  },
  {
    name: 'Cardinal',
    hex: '#C41E3A',
  },
  {
    name: 'UP Maroon',
    hex: '#7B1113',
  },
  {
    name: 'Navy Purple',
    hex: '#9457EB',
  },
  {
    name: 'Waterspout',
    hex: '#A4F4F9',
  },
  {
    name: 'Tart Orange',
    hex: '#FB4D46',
  },
  {
    name: 'Magenta (Process)',
    hex: '#FF0090',
  },
  {
    name: 'Dark Brown-Tangelo',
    hex: '#88654E',
  },
  {
    name: 'Wheat',
    hex: '#F5DEB3',
  },
  {
    name: 'Burlywood',
    hex: '#DEB887',
  },
  {
    name: 'Baby Blue Eyes',
    hex: '#A1CAF1',
  },
  {
    name: 'Dark Yellow',
    hex: '#9B870C',
  },
  {
    name: 'Pink Lavender',
    hex: '#D8B2D1',
  },
  {
    name: 'Cambridge Blue',
    hex: '#A3C1AD',
  },
  {
    name: 'Indigo Dye',
    hex: '#091F92',
  },
  {
    name: 'Kelly Green',
    hex: '#4CBB17',
  },
  {
    name: 'Veronica',
    hex: '#A020F0',
  },
  {
    name: 'Middle Blue Purple',
    hex: '#8B72BE',
  },
  {
    name: 'Hansa Yellow',
    hex: '#E9D66B',
  },
  {
    name: 'Rose Ebony',
    hex: '#674846',
  },
  {
    name: 'Hollywood Cerise',
    hex: '#F400A1',
  },
  {
    name: 'Han Blue',
    hex: '#446CCF',
  },
  {
    name: 'Shiny Shamrock',
    hex: '#5FA778',
  },
  {
    name: 'Bright Ube',
    hex: '#D19FE8',
  },
  {
    name: 'Very Light Tangelo',
    hex: '#FFB077',
  },
  {
    name: 'Vegas Gold',
    hex: '#C5B358',
  },
  {
    name: 'Medium Carmine',
    hex: '#AF4035',
  },
  {
    name: 'Cerise Pink',
    hex: '#EC3B83',
  },
  {
    name: 'Rose Madder',
    hex: '#E32636',
  },
  {
    name: 'Amazonite',
    hex: '#00C4B0',
  },
  {
    name: 'Earth Yellow',
    hex: '#E1A95F',
  },
  {
    name: 'Razzmic Berry',
    hex: '#8D4E85',
  },
  {
    name: 'Telemagenta',
    hex: '#CF3476',
  },
  {
    name: 'Light Pink',
    hex: '#FFB6C1',
  },
  {
    name: 'American Rose',
    hex: '#FF033E',
  },
  {
    name: 'Celeste',
    hex: '#B2FFFF',
  },
  {
    name: 'Satin Sheen Gold',
    hex: '#CBA135',
  },
  {
    name: 'Pale Cyan',
    hex: '#87D3F8',
  },
  {
    name: 'French Fuchsia',
    hex: '#FD3F92',
  },
  {
    name: 'Deep Carmine',
    hex: '#A9203E',
  },
  {
    name: 'Bubble Gum',
    hex: '#FFC1CC',
  },
  {
    name: 'UCLA Gold',
    hex: '#FFB300',
  },
  {
    name: 'Cadmium Red',
    hex: '#E30022',
  },
  {
    name: 'Eerie Black',
    hex: '#1B1B1B',
  },
  {
    name: 'Glitter',
    hex: '#E6E8FA',
  },
  {
    name: 'Middle Green Yellow',
    hex: '#ACBF60',
  },
  {
    name: 'Blanched Almond',
    hex: '#FFEBCD',
  },
  {
    name: 'Redwood',
    hex: '#A45A52',
  },
  {
    name: 'Dark Pink',
    hex: '#E75480',
  },
  {
    name: 'Azureish White',
    hex: '#DBE9F4',
  },
  {
    name: 'Persian Indigo',
    hex: '#32127A',
  },
  {
    name: 'Cafe Au Lait',
    hex: '#A67B5B',
  },
  {
    name: 'Brown Sugar',
    hex: '#AF6E4D',
  },
  {
    name: 'Medium Blue',
    hex: '#0000CD',
  },
  {
    name: 'Strawberry',
    hex: '#FC5A8D',
  },
  {
    name: 'Gamboge',
    hex: '#E49B0F',
  },
  {
    name: 'Fallow',
    hex: '#C19A6B',
  },
  {
    name: 'Dark Puce',
    hex: '#4F3A3C',
  },
  {
    name: 'Grullo',
    hex: '#A99A86',
  },
  {
    name: 'Hot Magenta',
    hex: '#FF1DCE',
  },
  {
    name: 'Kobicha',
    hex: '#6B4423',
  },
  {
    name: 'Rich Carmine',
    hex: '#D70040',
  },
  {
    name: 'Timberwolf',
    hex: '#DBD7D2',
  },
  {
    name: 'Glaucous',
    hex: '#6082B6',
  },
  {
    name: 'Crimson',
    hex: '#DC143C',
  },
  {
    name: 'North Texas Green',
    hex: '#059033',
  },
  {
    name: 'Deep Green-Cyan Turquoise',
    hex: '#0E7C61',
  },
  {
    name: 'Deep Red',
    hex: '#850101',
  },
  {
    name: 'Hot Pink',
    hex: '#FF69B4',
  },
  {
    name: 'Sienna',
    hex: '#882D17',
  },
  {
    name: 'Bistre Brown',
    hex: '#967117',
  },
  {
    name: 'Spanish Viridian',
    hex: '#007F5C',
  },
  {
    name: 'Violet (Web)',
    hex: '#EE82EE',
  },
  {
    name: 'Chartreuse (Web)',
    hex: '#7FFF00',
  },
  {
    name: 'Medium Aquamarine',
    hex: '#66DDAA',
  },
  {
    name: 'Pumpkin',
    hex: '#FF7518',
  },
  {
    name: 'Navajo White',
    hex: '#FFDEAD',
  },
  {
    name: 'Bitter Lemon',
    hex: '#CAE00D',
  },
  {
    name: 'Phthalo Blue',
    hex: '#000F89',
  },
  {
    name: 'USAFA Blue',
    hex: '#004F98',
  },
  {
    name: 'Rose Taupe',
    hex: '#905D5D',
  },
  {
    name: 'Vivid Cerise',
    hex: '#DA1D81',
  },
  {
    name: 'Fashion Fuchsia',
    hex: '#F400A1',
  },
  {
    name: 'Pale Plum',
    hex: '#DDA0DD',
  },
  {
    name: 'Independence',
    hex: '#4C516D',
  },
  {
    name: 'Spartan Crimson',
    hex: '#9E1316',
  },
  {
    name: 'Medium Persian Blue',
    hex: '#0067A5',
  },
  {
    name: 'Blue-Gray',
    hex: '#6699CC',
  },
  {
    name: 'Drab',
    hex: '#967117',
  },
  {
    name: 'Sasquatch Socks',
    hex: '#FF4681',
  },
  {
    name: 'Sunburnt Cyclops',
    hex: '#FF404C',
  },
  {
    name: 'Electric Indigo',
    hex: '#6F00FF',
  },
  {
    name: 'KU Crimson',
    hex: '#E8000D',
  },
  {
    name: 'Vivid Sky Blue',
    hex: '#00CCFF',
  },
  {
    name: 'Chocolate (Web)',
    hex: '#D2691E',
  },
  {
    name: 'UA Blue',
    hex: '#0033AA',
  },
  {
    name: 'Old Gold',
    hex: '#CFB53B',
  },
  {
    name: 'Dark Sea Green',
    hex: '#8FBC8F',
  },
  {
    name: 'Fluorescent Yellow',
    hex: '#CCFF00',
  },
  {
    name: 'Blue Yonder',
    hex: '#5072A7',
  },
  {
    name: 'Jade',
    hex: '#00A86B',
  },
  {
    name: 'International Orange (Aerospace)',
    hex: '#FF4F00',
  },
  {
    name: 'Sepia',
    hex: '#704214',
  },
  {
    name: 'Maastricht Blue',
    hex: '#001C3D',
  },
  {
    name: 'Limerick',
    hex: '#9DC209',
  },
  {
    name: 'Dark Olive Green',
    hex: '#556B2F',
  },
  {
    name: 'French Blue',
    hex: '#0072BB',
  },
  {
    name: 'Bisque',
    hex: '#FFE4C4',
  },
  {
    name: 'Chinese Red',
    hex: '#AA381E',
  },
  {
    name: 'Utah Crimson',
    hex: '#D3003F',
  },
  {
    name: 'Celadon Blue',
    hex: '#007BA7',
  },
  {
    name: 'Light Salmon Pink',
    hex: '#FF9999',
  },
  {
    name: 'Eggplant',
    hex: '#614051',
  },
  {
    name: 'Gold (Web) (Golden)',
    hex: '#FFD700',
  },
  {
    name: 'Dogwood Rose',
    hex: '#D71868',
  },
  {
    name: 'Middle Blue',
    hex: '#7ED4E6',
  },
  {
    name: 'Teal Green',
    hex: '#00827F',
  },
  {
    name: 'Tulip',
    hex: '#FF878D',
  },
  {
    name: 'Begonia',
    hex: '#FA6E79',
  },
  {
    name: 'Spanish Orange',
    hex: '#E86100',
  },
  {
    name: 'Beaver',
    hex: '#9F8170',
  },
  {
    name: 'Princeton Orange',
    hex: '#F58025',
  },
  {
    name: 'Vanilla Ice',
    hex: '#F38FA9',
  },
  {
    name: 'Pale Lavender',
    hex: '#DCD0FF',
  },
  {
    name: 'Diamond',
    hex: '#B9F2FF',
  },
  {
    name: 'Heidelberg Red',
    hex: '#960018',
  },
  {
    name: 'Light Khaki',
    hex: '#F0E68C',
  },
  {
    name: 'French Violet',
    hex: '#8806CE',
  },
  {
    name: 'Dark Turquoise',
    hex: '#00CED1',
  },
  {
    name: 'Ming',
    hex: '#36747D',
  },
  {
    name: 'Midnight Green (Eagle Green)',
    hex: '#004953',
  },
  {
    name: 'Pale Green',
    hex: '#98FB98',
  },
  {
    name: 'Vermilion',
    hex: '#E34234',
  },
  {
    name: 'Granny Smith Apple',
    hex: '#A8E4A0',
  },
  {
    name: 'Royal Azure',
    hex: '#0038A8',
  },
  {
    name: 'Antique Brass',
    hex: '#CD9575',
  },
  {
    name: 'Pale Taupe',
    hex: '#BC987E',
  },
  {
    name: 'Spring Frost',
    hex: '#87FF2A',
  },
  {
    name: 'Iceberg',
    hex: '#71A6D2',
  },
  {
    name: 'Spicy Mix',
    hex: '#8B5f4D',
  },
  {
    name: 'Spanish Green',
    hex: '#009150',
  },
  {
    name: 'Light Moss Green',
    hex: '#ADDFAD',
  },
  {
    name: 'Orchid',
    hex: '#DA70D6',
  },
  {
    name: 'Avocado',
    hex: '#568203',
  },
  {
    name: 'Lemon Lime',
    hex: '#E3FF00',
  },
  {
    name: 'Malachite',
    hex: '#0BDA51',
  },
  {
    name: 'Jasper',
    hex: '#D73B3E',
  },
  {
    name: 'Lime (Web) (X11 Green)',
    hex: '#00FF00',
  },
  {
    name: 'Bondi Blue',
    hex: '#0095B6',
  },
  {
    name: 'Office Green',
    hex: '#008000',
  },
  {
    name: 'Cornsilk',
    hex: '#FFF8DC',
  },
  {
    name: 'GO Green',
    hex: '#00AB66',
  },
  {
    name: 'Maximum Blue',
    hex: '#47ABCC',
  },
  {
    name: 'Bright Cerulean',
    hex: '#1DACD6',
  },
  {
    name: 'Sangria',
    hex: '#92000A',
  },
  {
    name: 'Deep Peach',
    hex: '#FFCBA4',
  },
  {
    name: 'Tuscan Brown',
    hex: '#6F4E37',
  },
  {
    name: 'Artichoke',
    hex: '#8F9779',
  },
  {
    name: 'Raspberry Pink',
    hex: '#E25098',
  },
  {
    name: 'Cordovan',
    hex: '#893F45',
  },
  {
    name: 'Dark Khaki',
    hex: '#BDB76B',
  },
  {
    name: 'Rich Electric Blue',
    hex: '#0892D0',
  },
  {
    name: 'Lime Green',
    hex: '#32CD32',
  },
  {
    name: 'Peach',
    hex: '#FFE5B4',
  },
  {
    name: 'English Green',
    hex: '#1B4D3E',
  },
  {
    name: 'Infra Red',
    hex: '#FF496C',
  },
  {
    name: 'Orange (Color Wheel)',
    hex: '#FF7F00',
  },
  {
    name: 'Tomato',
    hex: '#FF6347',
  },
  {
    name: 'French Rose',
    hex: '#F64A8A',
  },
  {
    name: 'Ash Gray',
    hex: '#B2BEB5',
  },
  {
    name: 'Golden Yellow',
    hex: '#FFDF00',
  },
  {
    name: 'Cocoa Brown',
    hex: '#D2691E',
  },
  {
    name: 'Zomp',
    hex: '#39A78E',
  },
  {
    name: 'Pink Flamingo',
    hex: '#FC74FD',
  },
  {
    name: 'Old Lavender',
    hex: '#796878',
  },
  {
    name: 'Orange (Pantone)',
    hex: '#FF5800',
  },
  {
    name: 'Vivid Orchid',
    hex: '#CC00FF',
  },
  {
    name: 'University Of California Gold',
    hex: '#B78727',
  },
  {
    name: 'Violet',
    hex: '#8F00FF',
  },
  {
    name: 'Shamrock Green',
    hex: '#009E60',
  },
  {
    name: 'Fandango Pink',
    hex: '#DE5285',
  },
  {
    name: 'Imperial',
    hex: '#602F6B',
  },
  {
    name: 'Soap',
    hex: '#CEC8EF',
  },
  {
    name: 'Fresh Air',
    hex: '#A6E7FF',
  },
  {
    name: 'Royal Blue',
    hex: '#4169E1',
  },
  {
    name: 'Dodie Yellow',
    hex: '#FEF65B',
  },
  {
    name: 'Maize',
    hex: '#FBEC5D',
  },
  {
    name: 'Auburn',
    hex: '#A52A2A',
  },
  {
    name: 'Amaranth Red',
    hex: '#D3212D',
  },
  {
    name: 'Cyber Grape',
    hex: '#58427C',
  },
  {
    name: 'Illuminating Emerald',
    hex: '#319177',
  },
  {
    name: 'Rusty Red',
    hex: '#DA2C43',
  },
  {
    name: 'Unmellow Yellow',
    hex: '#FFFF66',
  },
  {
    name: 'Yellow-Green',
    hex: '#9ACD32',
  },
  {
    name: 'Urobilin',
    hex: '#E1AD21',
  },
  {
    name: 'Papaya Whip',
    hex: '#FFEFD5',
  },
  {
    name: 'Green-Cyan',
    hex: '#009966',
  },
  {
    name: 'Dark Lavender',
    hex: '#734F96',
  },
  {
    name: 'French Sky Blue',
    hex: '#77B5FE',
  },
  {
    name: 'Catalina Blue',
    hex: '#062A78',
  },
  {
    name: 'Midnight Blue',
    hex: '#191970',
  },
  {
    name: 'Registration Black',
    hex: '#000000',
  },
  {
    name: 'Vivid Crimson',
    hex: '#CC0033',
  },
  {
    name: 'Space Cadet',
    hex: '#1D2951',
  },
  {
    name: 'Deep Coffee',
    hex: '#704241',
  },
  {
    name: 'Light Sky Blue',
    hex: '#87CEFA',
  },
  {
    name: 'Keppel',
    hex: '#3AB09E',
  },
  {
    name: 'Pink Pearl',
    hex: '#E7ACCF',
  },
  {
    name: 'Yellow Rose',
    hex: '#FFF000',
  },
  {
    name: 'Baby Blue',
    hex: '#89CFF0',
  },
  {
    name: 'New York Pink',
    hex: '#D7837F',
  },
  {
    name: 'Pastel Purple',
    hex: '#B39EB5',
  },
  {
    name: 'Ogre Odor',
    hex: '#FD5240',
  },
  {
    name: 'Wild Blue Yonder',
    hex: '#A2ADD0',
  },
  {
    name: 'Robin Egg Blue',
    hex: '#00CCCC',
  },
  {
    name: 'French Puce',
    hex: '#4E1609',
  },
  {
    name: 'Saffron',
    hex: '#F4C430',
  },
  {
    name: 'French Lime',
    hex: '#9EFD38',
  },
  {
    name: 'Desert Sand',
    hex: '#EDC9AF',
  },
  {
    name: 'Denim',
    hex: '#1560BD',
  },
  {
    name: 'Ao (English)',
    hex: '#008000',
  },
  {
    name: 'Burnt Sienna',
    hex: '#E97451',
  },
  {
    name: 'Lust',
    hex: '#E62020',
  },
  {
    name: 'Tumbleweed',
    hex: '#DEAA88',
  },
  {
    name: 'International Orange (Golden Gate Bridge)',
    hex: '#C0362C',
  },
  {
    name: 'Deep Fuchsia',
    hex: '#C154C1',
  },
  {
    name: 'Pullman Brown (UPS Brown)',
    hex: '#644117',
  },
  {
    name: 'Fiery Rose',
    hex: '#FF5470',
  },
  {
    name: 'Regalia',
    hex: '#522D80',
  },
  {
    name: 'Ultra Red',
    hex: '#FC6C85',
  },
  {
    name: 'Olive',
    hex: '#808000',
  },
  {
    name: 'Raisin Black',
    hex: '#242124',
  },
  {
    name: 'Lawn Green',
    hex: '#7CFC00',
  },
  {
    name: 'Purple Heart',
    hex: '#69359C',
  },
  {
    name: 'Bright Maroon',
    hex: '#C32148',
  },
  {
    name: 'Blizzard Blue',
    hex: '#ACE5EE',
  },
  {
    name: 'Blue (Pantone)',
    hex: '#0018A8',
  },
  {
    name: 'Lavender Indigo',
    hex: '#9457EB',
  },
  {
    name: 'Tea Rose',
    hex: '#F4C2C2',
  },
  {
    name: 'Dark Chestnut',
    hex: '#986960',
  },
  {
    name: 'Licorice',
    hex: '#1A1110',
  },
  {
    name: 'Persian Plum',
    hex: '#701C1C',
  },
  {
    name: 'Palm Leaf',
    hex: '#6F9940',
  },
  {
    name: 'Stormcloud',
    hex: '#4F666A',
  },
  {
    name: 'Heat Wave',
    hex: '#FF7A00',
  },
  {
    name: 'Very Light Malachite Green',
    hex: '#64E986',
  },
  {
    name: 'Forest Green (Traditional)',
    hex: '#014421',
  },
  {
    name: 'Queen Blue',
    hex: '#436B95',
  },
  {
    name: 'Olive Drab (#3)',
    hex: '#6B8E23',
  },
  {
    name: 'Quinacridone Magenta',
    hex: '#8E3A59',
  },
  {
    name: 'Charcoal',
    hex: '#36454F',
  },
  {
    name: 'Dark Pastel Green',
    hex: '#03C03C',
  },
  {
    name: 'Cameo Pink',
    hex: '#EFBBCC',
  },
  {
    name: 'Bone',
    hex: '#E3DAC9',
  },
  {
    name: 'Sapphire',
    hex: '#0F52BA',
  },
  {
    name: 'Tufts Blue',
    hex: '#3E8EDE',
  },
  {
    name: 'Mexican Pink',
    hex: '#E4007C',
  },
  {
    name: 'Languid Lavender',
    hex: '#D6CADD',
  },
  {
    name: 'Smoke',
    hex: '#738276',
  },
  {
    name: "Davy's Grey",
    hex: '#555555',
  },
  {
    name: 'Sunset',
    hex: '#FAD6A5',
  },
  {
    name: 'Neon Green',
    hex: '#39FF14',
  },
  {
    name: 'Blast-Off Bronze',
    hex: '#A57164',
  },
  {
    name: 'Air Force Blue (RAF)',
    hex: '#5D8AA8',
  },
  {
    name: 'Italian Sky Blue',
    hex: '#B2FFFF',
  },
  {
    name: 'Coral Pink',
    hex: '#F88379',
  },
  {
    name: 'Weldon Blue',
    hex: '#7C98AB',
  },
  {
    name: 'Purple (X11)',
    hex: '#A020F0',
  },
  {
    name: 'Medium Electric Blue',
    hex: '#035096',
  },
  {
    name: 'Shampoo',
    hex: '#FFCFF1',
  },
];

export default colors;
