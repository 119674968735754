// **** External Utilities ****
import axios from 'axios';

import { CONFIG } from '../../../../constants';
import apiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const getReportResponse = async (
  reportId,
  scheduledTodayFlag,
  searchQuery,
  selectedFilters,
  setLoading,
  setReportData,
  setSchedulerLoader
) => {
  setLoading(true);

  try {
    let newQuery;
    let newQueryString;

    newQueryString = Object.entries(searchQuery)
      ?.filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters)?.length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        ?.filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val?.toString())}`
        )
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/reports/${reportId}?${newQueryString}`
    );
    setReportData(response?.data);
    if (response?.data) {
      setSchedulerLoader(false);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    setSchedulerLoader(false);
  }
};

export const getAssignedAndUnassignedJobsMap = async (
  setMapLoading,
  setMapData,
  searchQuery,
  setProjectDataLoading,
  setShowErrorMsg,
  stores,
  checkedStores
) => {
  setProjectDataLoading(true);
  setShowErrorMsg(false);
  try {
    const newQueryString = Object.entries(searchQuery)
      ?.filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/map-view?${newQueryString}`
    );
    let newData = [...response?.data?.items];
    if (checkedStores) {
      newData = [...newData, ...stores];
    }
    setMapData(newData);
    setShowErrorMsg(!newData.length);
  } catch (error) {
    console.log(error);
  } finally {
    setProjectDataLoading(false);
    setMapLoading(false);
  }
};

export const getStoresData = async (
  setProjectDataLoading,
  setMapLoading,
  mapData,
  setMapData,
  setStores,
  stores
) => {
  setProjectDataLoading(true);
  try {
    if (!stores?.length) {
      const response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/stores/all-stores`
      );
      setMapData([...mapData, ...response?.data]);
      setStores(response?.data);
    } else {
      setMapData([...mapData, ...stores]);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setProjectDataLoading(false);
    setMapLoading(false);
  }
};

export const getProjectDataById = async (
  projectId,
  setProjectDataLoading,
  setProjectData,
  setIsOpen,
  setOpenScheduleModel
) => {
  try {
    setProjectDataLoading(true);

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/map/${projectId}/edit-schedule`
    );

    setProjectData(response.data);
    setOpenScheduleModel(false);

    setIsOpen(true);
  } catch (error) {
    console.log(error);
  } finally {
    setProjectDataLoading(false);
    setOpenScheduleModel(false);
  }
};

//Update Permit
export const updateProjectScheduleInfo = async (
  projectId,
  values,
  setProjectDataLoading,
  setAlert,
  setReloadList,
  setOpen,
  resetForm
) => {
  console.log('Values..', values);
  try {
    setProjectDataLoading(true);
    setOpen(false);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      values
    );
    if (response) {
      resetForm();
      setAlert('success', 'Project Details updated successfully.', false, true);
      setReloadList(true);
      setProjectDataLoading(false);
    }
  } catch (error) {
    setAlert('error', 'Error in updating project details', false, true);
    setProjectDataLoading(false);
  }
};

//Get reschedule Reason
export const getRescheduleReasonOptions = async (
  projectType,
  sourceId,
  setRescheduleReasonOptions,
  setBackdropLoading
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reason?reasonType=${projectType}&sourceSystemId=${sourceId}`
    );
    setRescheduleReasonOptions(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setBackdropLoading(false);
  }
};

// Get Project Type, Category, Source, Customers dropdown options
export const getFiltersDropdownOptions = async (
  setCategoryOptions,
  setTypeOptions,
  setInstallerOptions,
  setStatusOptions,
  setStoreOptions,
  setTeamOptions,
  setDistrictOptions,
  setWorkRoomOptions
) => {
  try {
    const searchString = '';
    const categoryRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-category`
    );

    const projectTypeRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-type`
    );
    const installerRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );

    const statusRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Project`
    );
    // const storeRequest = axios.get(`${URL_CONSTANTS.API.BASE_URL}/stores`);
    const teamRequest = axios.get(`${URL_CONSTANTS.API.BASE_URL}/system/team`);
    const districtRequest = axios.get(
      `${CONFIG?.API_BASE_URL}/stores/district`
    );
    const workRoomRequest = axios.get(
      `${CONFIG?.API_BASE_URL}/system/workroom/list?searchString=${searchString}`
    );
    const responses = await Promise.all([
      categoryRequest,
      projectTypeRequest,
      installerRequest,
      teamRequest,
      statusRequest,
      districtRequest,
      workRoomRequest,
    ]);

    setCategoryOptions(responses[0]?.data || []);
    setTypeOptions(responses[1]?.data || []);
    setInstallerOptions(responses[2]?.data || []);
    setTeamOptions(responses[3]?.data || []);
    setStatusOptions(responses[4]?.data || []);
    setDistrictOptions(responses[5]?.data || []);
    setWorkRoomOptions(responses[6]?.data || []);
  } catch (error) {
    console.log(error);
  }
};

// Get filter options
export const getTableFiltersOptions = async (
  setStoreNumberOptions,
  setCustomerOptions,
  setStatusOptions,
  setInstallerOptions,
  setProjectTypeOptions,
  setSourceStatusOptions
) => {
  try {
    const statusRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=project`
    );
    const installerRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );
    const projectTypeRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-type`
    );
    const sourceStatusRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=source`
    );
    const responses = await Promise.all([
      statusRequest,
      installerRequest,
      projectTypeRequest,
      sourceStatusRequest,
    ]);

    setStatusOptions(responses[0]?.data);
    setInstallerOptions(responses[1]?.data);
    setProjectTypeOptions(responses[2]?.data);
    setSourceStatusOptions(responses[3]?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getDropdownDetails = async ({
  reportId,
  fieldName,
  searchValue,
}) => {
  let response = [];
  if (fieldName == 'customers') {
    response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/customers?searchString=${searchValue}&offset=0&limit=10`
    );
  } else if (fieldName === 'workroomId') {
    response = await axios.get(
      `${CONFIG?.API_BASE_URL}/system/workroom/list?searchString=${searchValue}`
    );
  } else {
    response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores?searchString=${searchValue}&offset=0&limit=10`
    );
  }

  return response?.data || [];
};

export const getDefaultFilterList = async () => {
  try {
    const response = await apiService.get(
      `/admin-config/schedulerFilter/get-All-scheduler-info`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
