import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { TreeTable } from 'primereact/treetable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import './style.css';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFDropdown';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

const SearchChildProject = ({
  searchVisible,
  setSearchVisible,
  syncDialogEnable,
  setSyncDialogEnable,
  customer_id,
  child_project,
  setSyncData,
  config,
  syncProject,
}) => {
  const { force_sync, options } = config;
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [searchKey, setSearchKey] = useState('customer');

  const getListData = () => {
    setLoading(true);
    const linkProjects = apiService.getWithDiffBaseUrl(
      URL_CONSTANTS.CRM.projectLinkage?.getLinkedProjects,
      {
        page,
        rows,
        customer_id,
        child_project: child_project,
        search_key: searchKey,
        search_terms: globalFilter,
      },
      URL_CONSTANTS.CRM.baseUrl
    );
    linkProjects
      .then(project => {
        if (project?.status) {
          setTotalRecords(project?.data?.total_count || 0);
          setProjects(project?.data?.data || []);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  };

  const actionTemplate = node => {
    const { project_id } = node.data;
    return (
      <React.Fragment>
        <div className="flex align-items-center">
          <PFButton
            icon="pi pi-eye"
            outlined
            text
            className="text-white border-none focus:shadow-none shadow-none"
            severity="secondary"
            aria-label="Edit"
            onClick={() => window.open(`/project/view/${project_id}`, '_blank')}
            pt={{ icon: { className: 'text-color' } }}
          />
          <img
            alt="Link"
            width="15"
            height="15"
            className="p-0 width-18 height-18 cursor-pointer"
            src="/images/link-Icon.svg"
            onClick={() => {
              if (force_sync) {
                const technician = options.includes('technician') ? 'Y' : 'N';
                const schedule = options.includes('schedule') ? 'Y' : 'N';
                const documents = options.includes('documents') ? 'Y' : 'N';

                syncProject({
                  linked_project_id: project_id,
                  customer_id,
                  project_id: child_project,
                  technician: technician,
                  schedule: schedule,
                  documents: documents,
                });
                setSyncDialogEnable(true);
                setSearchVisible(false);
              } else {
                setSyncData({ project_id, customer_id, child_project });
                setSyncDialogEnable(true);
                setSearchVisible(false);
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    return (
      <PFButton
        label={node?.data?.pr_number}
        outlined
        text
        className="text-white border-none focus:shadow-none shadow-none"
        severity="secondary"
        aria-label="Click to expand"
        onClick={options.onClick}
        pt={{ label: { className: 'text-color' } }}
      />
    );
  };

  useEffect(() => {
    if (globalFilter.length > 2) {
      getListData();
    }
  }, [globalFilter]);

  const getHeader = () => {
    return (
      <>
        <div className="grid">
          <div className="col-12 md:col-6 xl:col-3">
            <div className="p-input-icon-left w-full">
              <PFDropdown
                name="type"
                optionLabel="name"
                optionValue="value"
                value={searchKey || 'customer'}
                options={[
                  { name: 'Customer', value: 'customer' },
                  { name: 'Project#', value: 'project_number' },
                  { name: 'PO#', value: 'po_number' },
                ]}
                onChange={(name, value) => setSearchKey(value)}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3">
            <div className="p-input-icon-left">
              <i className="pi pi-search"></i>
              <InputText
                type="search"
                value={globalFilter}
                onInput={e => setGlobalFilter(e.target.value)}
                placeholder="Search Project"
                className="height-40"
                pt={{
                  root: 'w-full',
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Search Child Project"
        visible={searchVisible || false}
        className="w-11 lg:w-9 min-h-full py-7 shadow-none"
        onHide={() => {
          setSearchVisible(false);
        }}
      >
        <div>
          {getHeader()}
          {loading ? (
            <PFTableLoader />
          ) : (
            <div className="card py-2">
              <TreeTable
                value={projects}
                tableStyle={{ minWidth: '2000px' }}
                togglerTemplate={togglerTemplate}
                className="overflow-auto"
                loading={loading}
              >
                <Column
                  body={actionTemplate}
                  header="Link"
                  style={{ width: '100px' }}
                  className="p-0"
                />
                <Column
                  field="source_name"
                  header="Source"
                  style={{ width: '200px' }}
                ></Column>
                <Column
                  field="customer_name"
                  className="w-12rem"
                  header="Customer Name"
                ></Column>
                <Column
                  field="customer_address"
                  className="w-12rem"
                  header="Customer Address"
                ></Column>
                <Column
                  field="customer_phone"
                  className="w-12rem"
                  header="Customer Phone Number"
                ></Column>
                <Column
                  field="project_type.project_type"
                  header="Type"
                  style={{ width: '200px' }}
                ></Column>
                <Column
                  field="project_status"
                  header="Status"
                  className="w-10rem"
                ></Column>
                <Column
                  field="project_category.category"
                  header="Category"
                  style={{ width: '200px' }}
                ></Column>
                <Column
                  field="project_number"
                  header="Project#"
                  style={{ width: '300px' }}
                ></Column>
                <Column
                  field="po_number"
                  header="PO#"
                  style={{ width: '200px' }}
                ></Column>
              </TreeTable>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default SearchChildProject;
