import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Tag } from 'primereact/tag';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';

export const getPhoneTypes = async setPhoneTypes => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/phone-type`
    );
    setPhoneTypes(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const newNumberInitialValue = {
  is_primary: false,
  phone_type_id: '',
  phone_number: '',
};

const newNumberValidationSchema = Yup.object().shape({
  is_primary: Yup.bool().required('Required'),
  phone_type_id: Yup.number().required('Required'),
  phone_number: Yup.string().required('Required'),
});

export const phoneSubmit = (
  setPhoneObject,
  phone_type_id,
  updateVlue,
  setUpdateValue,
  setBtnLabel,
  btnLabel
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: updateVlue ? updateVlue?.rowData : newNumberInitialValue,
    validationSchema: newNumberValidationSchema,
    onSubmit: async values => {
      setPhoneObject(prevObject => {
        if (prevObject?.length <= 0) {
          values['is_primary'] = true;
        }
        if (btnLabel === 'Update') {
          const updatedList = [...prevObject];
          updatedList[updateVlue?.rowIndex] = values;
          return updatedList;
        }
        if (btnLabel === 'Add') {
          return [...prevObject, values];
        }
      });
      formik.resetForm({ values: newNumberInitialValue });
      setBtnLabel('Add');
      setUpdateValue('');
    },
  });

  return formik;
};

export const columns = (
  phoneTypeOption,
  deleteNumber,
  mode,
  primaryId,
  updateNumber
) => {
  let main = [
    {
      field: 'primary_number',
      header: 'Primary',
      sortable: false,
      filter: false,
      body: (rowData, { rowIndex }) => {
        return (
          <>
            <PFCheckbox
              checked={rowData?.is_primary}
              onChange={e => updateNumber(rowData, rowIndex, e.checked)}
              className="mr-1"
              disabled={mode === 'view'}
            />
          </>
        );
      },
    },
    {
      field: 'phone_type_id',
      header: 'Type',
      sortable: false,
      filter: false,
      body: rowData =>
        phoneTypeOption?.find(
          item => item?.phone_type_id === rowData?.phone_type_id
        )?.name,
    },
    {
      field: 'phone_number',
      header: 'Phone Number',
      sortable: false,
      filter: false,
    },
  ];

  if (mode !== 'view') {
    main = [
      ...main,
      {
        field: 'Action',
        header: 'Action',
        sortable: false,
        filter: false,
        body: (rowData, option) => (
          <>
            {!rowData?.is_primary ? (
              <>
                <PFButton
                  type="button"
                  text={false}
                  label="Edit"
                  icon="pi pi-pencil"
                  severity="primary"
                  outlined={true}
                  onClick={() => updateNumber(rowData, option?.rowIndex)}
                  className="mr-2"
                />
                <PFButton
                  type="button"
                  text={false}
                  label="Delete"
                  icon="pi pi-trash"
                  severity="danger"
                  outlined={true}
                  onClick={() => deleteNumber(option?.rowIndex)}
                />
              </>
            ) : (
              <>
                <PFButton
                  type="button"
                  text={true}
                  label="Edit"
                  icon="pi pi-pencil"
                  outlined={true}
                  onClick={() => updateNumber(rowData, option?.rowIndex)}
                />
              </>
            )}
          </>
        ),
      },
    ];
  }

  return main;
};
