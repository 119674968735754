export const categoryValidateType = {
  userAccessCoverageRes: 'userAccessCoverageRes',
  userJobCoverageRes: 'userJobCoverageRes',
  clientServiceTimeRes: 'clientServiceTimeRes',
  userRotationPercentRes: 'userRotationPercentRes',
  userServiceTimeRes: 'userServiceTimeRes',
};
export const INDEX_TO_TAB_MAPPING = {
  0: 'userAccessCoverageRes',
  1: 'userJobCoverageRes',
  2: 'userRotationPercentRes',
  3: 'userServiceTimeRes',
  4: 'clientServiceTimeRes',
};

export const updateIsArchival = (data = []) => {
  const processedIdsByUser = {};
  data.forEach(record => {
    const { user_id, project_type_id } = record;
    if (!processedIdsByUser.hasOwnProperty(user_id)) {
      processedIdsByUser[user_id] = new Set();
    }
    if (!processedIdsByUser[user_id].has(project_type_id)) {
      record.is_archival = 0;
      processedIdsByUser[user_id].add(project_type_id);
    } else {
      record.is_archival = 1;
    }
  });
  return data;
};

export const updateIsArchivalWhenTypeIdChanges = (data = []) => {
  data.sort((a, b) => a.type_id - b.type_id);
  let previousTypeId = 0;
  data.forEach(record => {
    if (record.type_id != previousTypeId) {
      (previousTypeId = record.type_id), (record.is_archival = 0);
    } else {
      record.is_archival = 1;
    }
  });
  return data;
};
export const updateIsArchivalWhenRotation = (data = []) => {
  const processedIdsByUser = {};
  data.forEach(record => {
    const { user_id, project_type_id } = record;
    if (!processedIdsByUser.hasOwnProperty(user_id)) {
      processedIdsByUser[user_id] = new Set();
    }
    if (!processedIdsByUser[user_id].has(project_type_id)) {
      record.is_archival = 0;
      processedIdsByUser[user_id].add(project_type_id);
    } else {
      record.is_archival = 1;
    }
  });
  return data;
};
export const updateIsArchivalWhenUserServiceTime = (data = []) => {
  const processedIdsByUser = {};
  data.forEach(record => {
    const { user_id, project_type_id } = record;
    if (!processedIdsByUser.hasOwnProperty(user_id)) {
      processedIdsByUser[user_id] = new Set();
    }
    if (!processedIdsByUser[user_id].has(project_type_id)) {
      record.is_archival = 0;
      processedIdsByUser[user_id].add(project_type_id);
    } else {
      record.is_archival = 1;
    }
  });
  return data;
};

export const sortByCategory = (arr, property) => {
  if (!arr || arr.length === 0) {
    return arr;
  }
  return arr.sort((a, b) => a[property].localeCompare(b[property]));
};
