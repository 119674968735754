import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  relationsList: [],
  contactData: [],
};

const userFormContactSlice = createSlice({
  name: 'userFormContact',
  initialState: initialState,
  reducers: {
    setUserContactForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.relationsList = payload.hasOwnProperty('relationsList')
        ? payload.relationsList
        : state.relationsList;
      state.contactData = payload.hasOwnProperty('contactData')
        ? payload.contactData
        : state.contactData;
    },
  },
});

export const { setUserContactForm } = userFormContactSlice.actions;

export const userFormContactReducer = userFormContactSlice.reducer;
