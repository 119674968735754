import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import dayjs from 'dayjs';
import _ from 'lodash';

import { domHandlerCall } from '../../user-form/common';
import {
  addWorkHours,
  getWorkHours,
} from '../service/BusinessHourConfigService';
import {
  convertTo12HourFormat,
  createDateFromTimeString,
  getTimeFromDate,
  isStartTimeLessThanEndTime,
  timeDifference,
  updateArrayWithMatchingDays,
} from '../../../../utils/Helpers';
import { WORK_HOURS } from '../../../../constants';
import PFButton from '../../../shared/PFPrime/PFButton';

const WorkHoursComponent = ({ clientId }) => {
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const [workHours, setWorkHours] = useState(WORK_HOURS);
  const [selectedDays, setSelectedDays] = useState(WORK_HOURS.slice(0, 6));
  const toast = useRef(null);

  const timeEditor = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => options.editorCallback(getTimeFromDate(e.target.value))}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const timeEditorBreakStart = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => {
          options.rowData.break_duration == '';
          options.editorCallback(getTimeFromDate(e.target.value));
        }}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const timeEditorBreakEnd = options => {
    return (
      <Calendar
        value={createDateFromTimeString(options?.value)}
        onChange={e => {
          options.editorCallback(getTimeFromDate(e.target.value));
        }}
        timeOnly
        hourFormat="12"
      />
    );
  };

  const DayTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{rowData?.day}</span>
    );
  };

  const StartTimeTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.start_time)}</span>
    );
  };

  const EndTimeTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.end_time)}</span>
    );
  };

  const BreakStartTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.break_start_time)}</span>
    );
  };

  const BreakEndTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <span>{convertTo12HourFormat(rowData?.break_end_time)}</span>
    );
  };

  const allowedDurationTemplate = rowData => {
    return loading ? (
      <Skeleton className="w-full" height="3rem" />
    ) : (
      <Tag
        value={timeDifference(
          rowData?.break_end_time,
          rowData?.break_start_time
        )}
        severity="info"
      />
    );
  };

  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
    }, 300);
  };

  const handleSelectionChange = e => {
    const newSelectedDays = updateArrayWithMatchingDays(e.value, workHours);
    setSelectedDays(newSelectedDays);
  };

  const onRowEditComplete = async e => {
    let editedBusinessHour = structuredClone(workHours);
    let { newData, index } = e;
    editedBusinessHour[index] = newData;
    const updatedSelectedDays = updateArrayWithMatchingDays(
      structuredClone(selectedDays),
      editedBusinessHour
    );
    setWorkHours(editedBusinessHour);
    setSelectedDays(updatedSelectedDays);
    disableEditButton(false);
  };

  const handleRowValidation = (e, f) => {
    let error = null;
    if (!e?.start_time) {
      error = 'Invalid Start Time';
    } else if (!e?.end_time) {
      error = 'Invalid End Time';
    } else if (!e?.break_start_time) {
      error = 'Invalid Break Start Time';
    } else if (!e?.break_end_time) {
      error = 'Invalid Break End Time';
    } else if (isStartTimeLessThanEndTime(e?.end_time, e?.start_time)) {
      error = 'Start Time cannot be greater than End Time';
    } else if (
      isStartTimeLessThanEndTime(e?.break_end_time, e?.break_start_time)
    ) {
      error = 'Break Start Time cannot be greater than Break End Time';
    } else if (isStartTimeLessThanEndTime(e?.break_start_time, e?.start_time)) {
      error = 'Break Start Time cannot be less than or equal to Start Time';
    } else if (isStartTimeLessThanEndTime(e?.end_time, e?.break_end_time)) {
      error = 'End Time cannot be less than or equal to Break End Time';
    } else {
      const shiftStartTime = dayjs(e?.start_time, 'HH:mm');
      const shiftEndTime = dayjs(e?.end_time, 'HH:mm');
      const breakStartTime = dayjs(e?.break_start_time, 'HH:mm');
      const breakEndTime = dayjs(e?.break_end_time, 'HH:mm');

      if (!breakStartTime.isBetween(shiftStartTime, shiftEndTime)) {
        error = 'Break Start Time should be between Start Time and End Time';
      }
      if (!breakEndTime.isBetween(shiftStartTime, shiftEndTime)) {
        error = 'Break End time should be between Start Time and End Time';
      }
    }
    if (error) {
      toast.current.show({
        severity: 'error',
        summary: error,
        life: 3000,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (isNew = false) => {
    if (Array.isArray(workHours) && workHours.length) {
      setLoading(true);
      try {
        const payload = { selected_days: isNew ? workHours : [] };
        if (!isNew) {
          const selectionMap = {};
          if (Array.isArray(selectedDays) && selectedDays.length) {
            selectedDays.forEach(eachDay => {
              selectionMap[eachDay.day] = eachDay;
            });
          }
          workHours.forEach(eachHour => {
            const commonValues = _.pick(eachHour, [
              'day',
              'start_time',
              'end_time',
              'break_start_time',
              'break_end_time',
            ]);
            if (selectionMap[eachHour.day]) {
              payload.selected_days.push({
                ...commonValues,
                is_working: true,
              });
            } else payload.selected_days.push(commonValues);
          });
        }
        const apiResponse = await addWorkHours(clientId, payload);
        if (
          apiResponse?.status &&
          Array.isArray(apiResponse.data) &&
          apiResponse.data.length
        ) {
          if (!isNew) {
            toast.current.show({
              severity: 'success',
              summary: apiResponse?.message || 'Record saved Successfully',
              life: 1500,
            });
            setSelectedDays(
              apiResponse.data.filter(eachDay => eachDay.is_working)
            );
          }
          setWorkHours(apiResponse.data);
        } else {
          if (!isNew) {
            toast.current.show({
              severity: 'error',
              summary:
                apiResponse.message || 'Error occurred while saving records',
              life: 1500,
            });
          }
        }
      } catch (ex) {
        if (!isNew) {
          toast.current.show({
            severity: 'error',
            detail:
              (ex.response && ex.response.message) || 'Something Went Wrong',
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const callGetWorkHours = async () => {
    setLoading(true);
    try {
      const apiResponse = await getWorkHours(clientId);
      if (apiResponse?.status) {
        if (Array.isArray(apiResponse.data) && apiResponse.data.length) {
          setSelectedDays(
            apiResponse.data.filter(eachDay => eachDay.is_working)
          );
          setWorkHours(apiResponse.data);
        } else await handleSubmit(true);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      callGetWorkHours();
    }
  }, [clientId]);

  const CardHeader = (
    <div className="flex justify-content-between align-items-center">
      <p>Work Hours</p>
      <span className="flex flex-end align-items-center">
        <PFButton label="Save" size="small" onClick={() => handleSubmit()} />
      </span>
    </div>
  );

  return (
    <Card
      title={CardHeader}
      pt={{
        body: {
          className: 'pt-1',
        },
        title: {
          className: 'p-0 mb-0 mt-0',
        },
        content: {
          className: 'p-0 mt-0',
        },
      }}
    >
      <Toast ref={toast} />
      <DataTable
        value={Array.isArray(workHours) ? workHours : []}
        dataKey="day"
        selectionMode="checkbox"
        className="w-full"
        onSelectionChange={handleSelectionChange}
        selection={selectedDays}
        editMode="row"
        onRowEditComplete={onRowEditComplete}
        rowEditValidator={handleRowValidation}
        onRowEditCancel={prop => {
          disableEditButton(false);
        }}
        onRowEditInit={prop => {
          disableEditButton(true);
        }}
        showSelectAll={false}
      >
        <Column
          rowEditor={true}
          headerStyle={{ width: '10%', minWidth: '7rem' }}
          bodyStyle={{ textAlign: 'center' }}
        />
        <Column
          header="Working"
          selectionMode="multiple"
          headerStyle={{ width: '3rem' }}
          // body={(rowData) => (loading ? <Skeleton className="w-full" height="3rem" /> : rowData)}
        />
        <Column field="day" header="Day" body={DayTemplate} />
        <Column
          field="start_time"
          header="Start Time"
          editor={options => timeEditor(options)}
          body={StartTimeTemplate}
        />
        <Column
          field="end_time"
          header="End Time"
          editor={options => timeEditor(options)}
          body={EndTimeTemplate}
        />
        <Column
          field="break_start_time"
          header="Break Start"
          editor={options => timeEditorBreakStart(options)}
          body={BreakStartTemplate}
        />
        <Column
          field="break_end_time"
          header="Break End"
          editor={options => timeEditorBreakEnd(options)}
          body={BreakEndTemplate}
        />
        <Column header="Break Duration" body={allowedDurationTemplate} />
      </DataTable>
    </Card>
  );
};

export default WorkHoursComponent;
