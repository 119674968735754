import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  buttonBox: {
    marginTop: '10px',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  tableWrapper: {
    maxWidth: '100%',
  },
  titleHeaderGrid: {
    padding: '10px 24px 10px 10px',
  },
}));
