// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { Grid, TextField, Chip, Switch } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createNewLaborProfileItem,
  editLaborProfileItem,
  deleteLaborProfileItem,
  getStores,
  getItems,
} from './LaborProfiles.service';

// **** Styles *****
import { useStyles } from './LaborProfiles.styles';

const labourProfileItemsColumns = [
  {
    id: 'itemNumber',
    value: 'Item#',
  },
  {
    id: 'description',
    value: 'Description',
  },
  {
    id: 'cost',
    value: 'Cost Each',
  },
  {
    id: 'percent',
    value: 'Percent',
  },
];
const withValueLimit = inputObj => {
  const { value } = inputObj;
  if (value == '' || value > 0 || value == 0) return true;
  return false;
};

const LaborProfileItems = ({
  labourProfileItems,
  installerId,
  setReloadLaborProfileItemsList,
}) => {
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [itemsLoading, setItemsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [getCurrentOffset, setCurrentOffset] = React.useState(0);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Labor Profile Item',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [storesList, setStoresList] = React.useState([]);
  const [itemsList, setItemsList] = React.useState([]);
  const [duplicateItemError, setDuplicateItemError] = React.useState(false);
  let labourProfileItemsData = [];
  let currentLabourProfileItemsId = [];
  React.useEffect(() => {
    getStores(setStoresList);
    getItems(
      setItemsList,
      setItemsLoading,
      setCurrentOffset,
      itemsList,
      getCurrentOffset
    );
  }, []);

  // **** Data Modification to Display *****
  if (labourProfileItems?.length) {
    labourProfileItemsData = labourProfileItems?.map(labourProfileItem => {
      return {
        item_id: { value: labourProfileItem?.item_id },
        itemNumber: { value: labourProfileItem?.items?.item_number },
        description: { value: labourProfileItem?.items?.item_desc },
        cost: {
          value:
            labourProfileItem?.cost || labourProfileItem?.cost === 0
              ? '$' + labourProfileItem?.cost
              : null,
        },
        percent: {
          value: labourProfileItem?.percent
            ? labourProfileItem?.percent + '%'
            : '',
        },
        installer_labor_profile_item_id: {
          value: labourProfileItem?.installer_labor_profile_item_id,
        },
      };
    });
    currentLabourProfileItemsId = labourProfileItems?.map(labourProfileItem => {
      return {
        value: labourProfileItem?.item_id,
      };
    });
  }

  //View Labor Profile Item
  const viewEditLaborProfileItem = (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Labor Profile Item') ||
        'Edit Labor Profile Item',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };
  const confirmDeleteLaborProfileItem = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete Labor Profile Item ?',
      header: 'Delete Labor Profile Item',
    });
  };
  const onConfirmDialog = () => {
    deleteLaborProfileItem(
      installerId,
      labourProfileItemsData[rowIndex]?.installer_labor_profile_item_id?.value,
      setLoading,
      setAlert,
      setReloadLaborProfileItemsList,
      setConfirmDialog
    );
  };

  //Labor Profile Item ValidationSchema
  const laborProfileItemValidationSchema = Yup.object().shape(
    {
      item_id: Yup.number().required(),
      description: Yup.string().trim(),
      cost: Yup.string()
        .trim()
        .when('percent', (percent, schema) => {
          if (percent) {
            return schema;
          } else {
            return schema.required('Required');
          }
        }),
      percent: Yup.string()
        .trim()
        .when('cost', (cost, schema) => {
          if (cost) {
            return schema;
          } else {
            return schema.required('Required');
          }
        }),
    },
    [['cost', 'percent']]
  );
  // **** Formik Form Values ****
  const laborProfileItemFormik = useFormik({
    initialValues: {
      item_id:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.item_id.value) ||
        '',
      description:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.description.value) ||
        '',
      cost:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.cost.value) ||
        '',
      percent:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.percent.value) ||
        '',
      isPercent:
        formAction !== 'add' && labourProfileItemsData[rowIndex]?.percent.value
          ? true
          : false,
    },
    onSubmit: values => {
      if (formAction === 'add') {
        createNewLaborProfileItem(
          installerId,
          values,
          setLoading,
          setIsOpen,
          setReloadLaborProfileItemsList,
          setAlert
        );
      } else {
        editLaborProfileItem(
          installerId,
          labourProfileItemsData[rowIndex]?.installer_labor_profile_item_id
            ?.value,
          values,
          setLoading,
          setIsOpen,
          setReloadLaborProfileItemsList,
          setAlert
        );
      }
    },
    validationSchema: laborProfileItemValidationSchema,
    enableReinitialize: true,
  });

  const laborItemDropDown =
    itemsList?.length > 0 && itemsList.filter(a => a.item_type_id === 2);

  const getLaborprofileItemViewEditForm = () => {
    return (
      itemsList?.length > 0 && (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Autocomplete
              name="item"
              ListboxProps={{
                onScroll: event => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    getItems(
                      setItemsList,
                      setItemsLoading,
                      setCurrentOffset,
                      itemsList,
                      getCurrentOffset
                    );
                  }
                },
              }}
              options={laborItemDropDown}
              onBlur={laborProfileItemFormik?.handleBlur}
              onKeyUp={laborProfileItemFormik?.handleChange}
              onChange={(event, value) => {
                if (value) {
                  if (
                    !currentLabourProfileItemsId?.some(
                      val => val?.value === value?.item_id
                    )
                  ) {
                    setDuplicateItemError(false);
                    laborProfileItemFormik.setFieldValue(
                      'item_id',
                      value.item_id
                    );
                    laborProfileItemFormik.setFieldValue(
                      'description',
                      value.item_desc
                    );
                  } else {
                    setDuplicateItemError(true);
                    laborProfileItemFormik.setFieldValue('item_id', '');
                    laborProfileItemFormik.setFieldValue('description', '');
                  }
                } else {
                  setDuplicateItemError(false);
                  laborProfileItemFormik.setFieldValue('item_id', '');
                  laborProfileItemFormik.setFieldValue('description', '');
                }
              }}
              getOptionLabel={option =>
                option.item_number || option?.item_desc
                  ? option.item_number?.toString() +
                    option?.item_desc?.toString()
                  : ''
              }
              renderOption={(option, { inputValue }) => (
                <AutoCompleteTitle option={option} inputValue={inputValue} />
              )}
              renderInput={params => (
                <>
                  <TextField
                    {...params}
                    label="Item #"
                    required={formAction !== 'view'}
                    InputLabelProps={{ shrink: true }}
                  />
                  {duplicateItemError && (
                    <div className="text-red-500 text-xm">
                      This item already exists in the profile items list.
                    </div>
                  )}
                </>
              )}
              value={
                itemsList.filter(
                  item =>
                    item.item_id == laborProfileItemFormik?.values?.item_id
                )[0] || ''
              }
              disabled={formAction === 'view'}
            />
          </Grid>
          <Grid item>
            <TextField
              id="item_description"
              label="Item Description"
              name="description"
              onChange={laborProfileItemFormik.handleChange}
              onBlur={laborProfileItemFormik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disabled
              value={laborProfileItemFormik?.values?.description}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            component="label"
          >
            <Grid item>Cost Each</Grid>
            <Grid item>
              <Switch
                checked={laborProfileItemFormik?.values?.isPercent}
                //onChange={(event) => setIsCost(event.target.checked)}
                onChange={(event, value) => {
                  laborProfileItemFormik.setFieldValue('isPercent', value);
                  value
                    ? laborProfileItemFormik.setFieldValue('cost', '')
                    : laborProfileItemFormik.setFieldValue('percent', '');
                }}
                value={laborProfileItemFormik?.values?.isPercent}
                color="primary"
                disabled={formAction === 'view'}
              />
            </Grid>
            <Grid item>Percent</Grid>
          </Grid>
          {!laborProfileItemFormik?.values?.isPercent ? (
            <Grid item>
              <NumberFormat
                id="cost_each"
                name="cost"
                allowNegative={false}
                onChange={laborProfileItemFormik.handleChange}
                onBlur={laborProfileItemFormik.handleBlur}
                label={`Cost Each`}
                decimalScale={2}
                customInput={TextField}
                inputmode="numeric"
                InputLabelProps={{ shrink: true }}
                prefix={'$'}
                value={laborProfileItemFormik?.values?.cost || ''}
                error={
                  laborProfileItemFormik.touched.cost &&
                  laborProfileItemFormik.errors.cost
                }
                helperText={
                  laborProfileItemFormik.touched.cost &&
                  laborProfileItemFormik.errors.cost
                }
                disabled={formAction === 'view' ? true : false}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          ) : (
            <Grid item>
              <NumberFormat
                id="percent"
                name="percent"
                inputProps={{ maxLength: 6 }}
                allowNegative={false}
                onChange={laborProfileItemFormik.handleChange}
                onBlur={laborProfileItemFormik.handleBlur}
                label={`Percent`}
                decimalScale={2}
                customInput={TextField}
                inputmode="numeric"
                InputLabelProps={{ shrink: true }}
                suffix={'%'}
                value={laborProfileItemFormik?.values?.percent || ''}
                error={
                  laborProfileItemFormik.touched.percent &&
                  laborProfileItemFormik.errors.percent
                }
                helperText={
                  laborProfileItemFormik.touched.percent &&
                  laborProfileItemFormik.errors.percent
                }
                disabled={formAction === 'view' ? true : false}
              />
            </Grid>
          )}
        </Grid>
      )
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item className="w-12">
            <PFTableLoader />
          </Grid>
        </Grid>
      )) || (
        <Grid
          container
          spacing={2}
          direction="column"
          className="w-12 overflow-auto"
        >
          <Grid item classes={{ root: classes.tableWrapper }}>
            {itemsLoading ? (
              <GenericTable
                title="Labor Profile Items"
                columnData={labourProfileItemsColumns}
                rowData={labourProfileItemsData}
                showActions={{
                  view: true,
                  edit: true,
                  delete: true,
                  clone: true,
                }}
                headerLinks={[
                  {
                    label: 'Add Item',
                    handler: () => {
                      setFormAction('add');
                      laborProfileItemFormik.resetForm();
                      setDialogSettings(prevState => ({
                        ...prevState,
                        showButton2: true,
                        title: 'Add Labor Profile Item',
                      }));
                      setIsOpen(true);
                    },
                  },
                ]}
                handleView={index => viewEditLaborProfileItem('view', index)}
                handleEdit={index => viewEditLaborProfileItem('edit', index)}
                handleDelete={index => confirmDeleteLaborProfileItem(index)}
                handleClone={index => {
                  setFormAction('add');
                  laborProfileItemFormik.resetForm();
                  laborProfileItemFormik.setFieldValue(
                    'store_ids',
                    labourProfileItemsData[index]?.store_ids?.value
                  );
                  setDialogSettings(prevState => ({
                    ...prevState,
                    showButton2: true,
                    title: 'Add Labor Profile Item',
                  }));
                  setIsOpen(true);
                }}
              />
            ) : (
              <PFTableLoader />
            )}
          </Grid>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              laborProfileItemFormik?.handleReset();
              setDuplicateItemError(false);
            }}
            handleSave={laborProfileItemFormik.handleSubmit}
            dialogSettings={dialogSettings}
            disabledButton2={
              !laborProfileItemFormik?.dirty || !laborProfileItemFormik?.isValid
            }
            disabledButton1={false}
          >
            <form>{getLaborprofileItemViewEditForm()}</form>
          </GenericDialog>
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />
        </Grid>
      )}
    </>
  );
};

const AutoCompleteTitle = ({ option, inputValue }) => {
  const matches = match(option.item_number.toString(), inputValue);
  const parts = parse(option.item_number.toString(), matches);

  const matchesDesc = match(option.item_desc, inputValue);
  const partsDesc = parse(option.item_desc, matchesDesc);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <br />
      {partsDesc.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
    </div>
  );
};

export default LaborProfileItems;
