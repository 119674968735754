import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import Header from '../user-list/components/header.js';
import UserManagementFilters from '../user-list/components/filters.js';
import SkeletonLoader from '../../../shared/Loader/skeleton.jsx';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import usePFDataTable from '../../../shared/PFPrime/PFDatatable.jsx';
import TableLoader from '../../../shared/Loader/TableLoader.js';
import { setUserManagementComponent } from '../../../../redux/slices/user-management.slice.js';

import {
  getAllUsersList,
  getRolesList,
  getUserList,
  handleDataTableActionButton,
  handleIsActiveStatus,
  handleSendInvite,
  userGridColumns,
} from './user-listing.model';

const UserListingView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [filterList, setFilterList] = useState({ users: [], roles: [] });
  const [selectFilters, setSelectFilters] = useState([]);
  const toast = useRef(null);
  const {
    layout: UserTable,
    data: userTableData,
    columns,
    totalRecords,
    rows,
    page,
    sortBy,
    orderBy,
  } = usePFDataTable();

  const userAction = props => {
    if (props.actionName === 'changeStatus') {
      handleIsActiveStatus(props.userId, props.status)
        .then(() => {
          UserDataList();
          toast.current.show({
            severity: 'success',
            summary: 'Status Changed',
            detail: 'User status has been updated successfully',
            life: 3000,
          });
        })
        .catch(error => {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update user status',
            life: 3000,
          });
        });
    }
    if (props.actionName === 'sendInvite') {
      handleSendInvite(props.userId)
        .then(() => {
          toast.current.show({
            severity: 'info',
            summary: 'Info',
            detail: 'Sending invitation',
            life: 2000,
          });
        })
        .catch(error => {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to send invitation',
            life: 2000,
          });
        });
    }
    if (props.actionName === 'edit') {
      props['dispatch'] = dispatch;
      props['history'] = history;
      handleDataTableActionButton(props);
    }
  };

  const UserDataList = () => {
    setLoading(true);
    getUserList(rows, page, sortBy, orderBy, selectFilters).then(response => {
      userTableData(response.records);
      totalRecords(response.totalCount);
      columns(userGridColumns(response.records, userAction));
      setLoading(false);
    });
  };

  const userRolesList = () => {
    getRolesList().then(response => {
      setFilterList(prevFilterList => ({ ...prevFilterList, roles: response }));
    });
  };

  const userList = () => {
    getAllUsersList().then(response => {
      setFilterList(prevUserOptionList => ({
        ...prevUserOptionList,
        users: response,
      }));
    });
  };
  useEffect(() => {
    dispatch(
      setUserManagementComponent({
        userList: filterList?.users,
      })
    );
  }, [filterList]);

  useEffect(() => {
    userRolesList();
  }, []);

  useEffect(() => {
    UserDataList();
    userList();
  }, [rows, page, sortBy, orderBy, selectFilters]);

  return (
    <div className="w-full">
      <Toast ref={toast} />
      <Header history={history} />
      <UserManagementFilters
        filterList={filterList}
        setSelectFilters={setSelectFilters}
      />
      {loading ? (
        <div className="flex justify-cintent-center">
          <TableLoader columnCount={8} noOfRow={10} mTopBottom={0} />
        </div>
      ) : (
        <UserTable />
      )}
    </div>
  );
};

export default UserListingView;
