import React from 'react';

import FilterReport from '../NewConfiguration/SchedularReportConfig/components/FilterReports';

const UserFormSchedulerFiters = () => {
  return (
    <div className="col-12 mt-3 p-4">
      <FilterReport />
    </div>
  );
};
export default UserFormSchedulerFiters;
