import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import PFButton from '../../shared/PFPrime/PFButton';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import { QuestionTypes } from '../../../constants';
import { getConvertedDate } from '../../../utils/date.helper';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';

import { deleteQuestionById } from './questionnaireSerivce';

const QuestionListComponent = ({ toast, editPermission }) => {
  const { isLoading, moduleList, questionList, questionCount, selectedModule } =
    useSelector(state => state.questionnaire);
  const dispatch = useDispatch();

  const handleQuestionEdit = question => {
    if (Array.isArray(moduleList) && question?.module) {
      const module = moduleList.filter(
        ({ module_id }) => module_id === question?.module?.module_id
      );
      const question_type = QuestionTypes.filter(
        ({ id }) => id === question?.question_type
      );
      dispatch(
        updateQuestionnaireStore({
          isQuestionDialogOpen: true,
          questionDialogContent: {
            ...question,
            question_type: question_type.length ? question_type[0] : {},
            module: module.length ? module[0] : {},
          },
        })
      );
    }
  };

  const handleQuestionDelete = async questionId => {
    if (questionId) {
      dispatch(updateQuestionnaireStore({ isLoading: true }));
      try {
        const response = await deleteQuestionById(
          selectedModule?.module_id,
          questionId
        );
        if (response?.status) {
          toast?.current?.show &&
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: response?.message || 'Question deleted successfully',
              life: 3000,
            });
          dispatch(
            updateQuestionnaireStore({
              questionList: Array.isArray(questionList)
                ? questionList.filter(
                    ({ question_id }) => question_id != questionId
                  )
                : questionList,
              questionCount: questionCount ? questionCount - 1 : questionCount,
            })
          );
        }
      } catch (ex) {
        toast?.current?.show &&
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: ex?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
      } finally {
        dispatch(updateQuestionnaireStore({ isLoading: false }));
      }
    }
  };

  const confirmDelete = value => {
    confirmDialog({
      message: 'Please confirm if you would like to delete this question?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleQuestionDelete(value),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  return (
    <div className="flex flex-column w-full">
      {isLoading ? (
        <div className="p-card flex flex-column mb-4 p-4">
          <div className="flex w-full justify-content-between">
            <Skeleton className="mb-2" width="16rem" height="3rem" />
            <div className="flex gap-5">
              <Skeleton className="mb-2" width="2rem" height="2rem" />
              <Skeleton className="mb-2" width="2rem" height="2rem" />
            </div>
          </div>
          <div className="flex flex-column">
            <div className="flex gap-8">
              <Skeleton className="mb-2" width="16rem" height="3rem" />
              <Skeleton className="mb-2" width="10rem" height="3rem" />
            </div>
            <div className="flex w-full">
              <Skeleton className="mb-2" height="6rem" />
            </div>
            <div className="flex gap-8">
              <Skeleton className="mb-2" width="16rem" height="3rem" />
              <Skeleton className="mb-2" width="16rem" height="3rem" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <ConfirmDialog />
          {selectedModule?.module_id && questionCount > 0 ? (
            ((Array.isArray(questionList) && questionList) || [])?.map(
              (eachQuestion, index) => (
                <div className="p-card flex flex-column mb-4 p-4" key={index}>
                  <div className="flex flex-row justify-content-between mb-2">
                    <div className="text-2xl">{`Question ${index + 1}`}</div>
                    {
                      <div className="flex gap-2 align-items-center">
                        {editPermission && (
                          <>
                            <PFButton
                              icon="pi pi-pencil"
                              rounded
                              text
                              severity="secondary"
                              aria-label="edit"
                              onClick={() => handleQuestionEdit(eachQuestion)}
                              tooltip="Edit"
                              tooltipOptions={{ position: 'bottom' }}
                            />
                            <PFButton
                              icon="pi pi-trash"
                              rounded
                              text
                              severity="secondary"
                              aria-label="edit"
                              onClick={() =>
                                confirmDelete(eachQuestion?.question_id)
                              }
                              tooltip="Delete"
                              tooltipOptions={{ position: 'bottom' }}
                            />
                          </>
                        )}
                      </div>
                    }
                  </div>
                  <div className="flex flex-column gap-2">
                    <div className="grid mb-2">
                      <div className="flex flex-column col-4">
                        <span className="p-float-label">
                          <PFInputText
                            type="text"
                            id="question_type"
                            name="question_type"
                            value={
                              QuestionTypes?.find(
                                i => i?.id === eachQuestion?.question_type
                              )?.label
                            }
                            className="pointer-events-none"
                          />
                          <label htmlFor="question_type">Question Type</label>
                        </span>
                      </div>
                      <div className="flex col-4 justify-content-center">
                        <span className="align-items-center">Active</span>
                        <PFInputSwitch
                          className="ml-2 pointer-events-none"
                          inputId="is_active"
                          name="is_active"
                          value={eachQuestion?.is_active}
                          checked={eachQuestion?.is_active}
                          onChange={
                            e => null /* handleActiveChange(eachQuestion, e) */
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="flex col-2 justify-content-center">
                        <span className="align-items-center">
                          Modify Answer
                        </span>
                        <PFInputSwitch
                          className="ml-2 pointer-events-none"
                          inputId="is_active"
                          name="is_active"
                          value={eachQuestion?.is_editable}
                          checked={eachQuestion?.is_editable}
                          onChange={
                            e => null /* handleActiveChange(eachQuestion, e) */
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="flex w-full">
                      <span className="p-float-label w-full">
                        <InputTextarea
                          id="question"
                          name="question"
                          rows={5}
                          value={eachQuestion?.question}
                          className="w-full pointer-events-none"
                        />
                        <label htmlFor="question">Question</label>
                      </span>
                    </div>
                    {eachQuestion?.question_type === QuestionTypes[2].id &&
                      eachQuestion?.options?.length > 0 && (
                        <div className="flex flex-column">
                          <PFDataTable
                            editMode="cell"
                            columns={[
                              {
                                field: 'label',
                                header: 'List Options',
                                sortable: false,
                                filter: false,
                              },
                            ]}
                            data={eachQuestion?.options}
                            stripedRows={true}
                            scrollable
                            scrollHeight="250px"
                          />
                        </div>
                      )}
                    <div className="grid mt-2 ml-1">
                      <div className="col-4">
                        <span className="mr-2">Modified By:</span>
                        <span>
                          {(eachQuestion?.modified_by_user &&
                            `${eachQuestion?.modified_by_user?.first_name} ${eachQuestion?.modified_by_user?.last_name}`) ||
                            (eachQuestion?.created_by_user &&
                              `${eachQuestion?.created_by_user?.first_name} ${eachQuestion?.created_by_user?.last_name}`)}
                        </span>
                      </div>
                      <div className="col-4">
                        <span className="mr-2">Modified At:</span>
                        <span>
                          {getConvertedDate(
                            eachQuestion?.modified_at ||
                              eachQuestion?.created_at
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="w-full flex align-items-center justify-content-center">
              <h2 className="font-semibold">
                {selectedModule ? (
                  <>No record found</>
                ) : (
                  <>Select a module to fetch questions.</>
                )}
              </h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionListComponent;
