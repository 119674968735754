// **** React Imports ****
import React from 'react';

// **** Utilities ****
import { Grid } from '@material-ui/core';

import EmailListView from './EmailListView';
import EmailDetailView from './EmailDetailView';
import ComposeEmail from './ComposeEmail';

const EmailNotifications = ({
  formik,
  storeDetails,
  setAlert,
  customerDetails,
}) => {
  const [showPanel, setShowPanel] = React.useState('listView');
  const [getMessageId, setMessageId] = React.useState(null);

  return (
    <Grid container direction="column">
      {showPanel && showPanel === 'listView' && (
        <EmailListView
          project_id={formik?.values?.project_id}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          setMessageId={setMessageId}
          setAlert={setAlert}
        />
      )}
      {showPanel && showPanel === 'detailView' && (
        <EmailDetailView
          messageId={getMessageId}
          setShowPanel={setShowPanel}
          setAlert={setAlert}
        />
      )}
      {showPanel && showPanel === 'createView' && (
        <ComposeEmail
          project_id={formik?.values?.project_id}
          project_number={formik?.values?.project_number}
          setShowPanel={setShowPanel}
          setAlert={setAlert}
          storeDetails={storeDetails}
          customerDetails={customerDetails}
        />
      )}
    </Grid>
  );
};
export default EmailNotifications;
