export const COVERAGE_TYPE = {
  ZIP_CODE: 1,
  STORE: 2,
  DISTRICT: 3,
};
export const COVERAGE_VALUES = {
  1: 'Zip Code',
  2: 'Store',
  3: 'District',
};
export const ACTION = {
  CREATE: 1,
  GROUP_EDIT: 2,
  ROW_EDIT: 3,
  GROUP_CLONE: 4,
  ROW_CLONE: 5,
};
export const mapResponseToFormik = res => {
  const coverageCategory = res?.category_coverage_ids.map(item => item);
  let coverageValueID = null;
  if (res?.coverage_type?.id === COVERAGE_TYPE.STORE) {
    coverageValueID = res.coverage_value.map(store => store.store_id);
  } else if (res?.coverage_type?.id === COVERAGE_TYPE.DISTRICT) {
    coverageValueID = res?.coverage_value;
  } else {
    coverageValueID = res?.coverage_value.split(',');
  }
  return {
    project_type: res?.type_coverage_id?.project_type_id,
    category: coverageCategory,
    coverage_type: res?.coverage_type?.id,
    coverage_value: coverageValueID,
    rotation_percentage: '',
    service_time: '',
    user_id: res?.user_id,
    user_job_coverage_id: res?.record_id,
  };
};
export const maxProjects = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
];

export const transformCoverageData = (data, categoryList) => {
  return data?.map((project, index) => {
    return {
      key: project?.project_type_id,
      data: {
        project_type: project.project_type,
        project_typeId: project.project_type_id,
        category: `${project?.categories.length} / ${categoryList.length} selected`,
        clientId: project.client_id,
        userId: project.user_id,
        user_coverage_preferenceId: project.user_coverage_preference_id,
        project_categoryId: project.project_category_ids,
        rotationPercentage: project.rotation_percentage,
      },
      children:
        project?.categories?.map((category, catIndex) => {
          return {
            key: category?.project_category_ids,
            data: {
              category: category?.category,
              source_category_id: category?.source_category_id,
              coverage_by: category?.coverage_by,
              zipcode_or_store_ids: category.zipcode_or_store_ids,
              client_id: category.client_id,
              coverage_values:
                category?.coverage_by == COVERAGE_TYPE.STORE
                  ? category.coverage_values?.map(record => parseInt(record))
                  : category.coverage_values,
              is_archival: category.is_archival,
              project_categoryId: category.project_category_ids,
              project_typeId: category.project_type_id,
              rotation_percentage: category.rotation_percentage,
              service_time: category.service_time,
              user_coverage_preference_id: category.user_coverage_preference_id
                ? category.user_coverage_preference_id
                : category?.user_job_coverage_id,
              user_id: category.user_id,
              zipcode_or_store_ids: category.zipcode_or_store_ids,
            },
          };
        }) || [],
    };
  });
};
const coverageByValues = ({
  type,
  values,
  storeList,
  districtList,
  zipCodeList,
}) => {
  if (type == COVERAGE_TYPE.ZIP_CODE) return zipCodeList;
  if (type == COVERAGE_TYPE.DISTRICT) return districtList;
  if (type == COVERAGE_TYPE.STORE) {
    const selectedStore = storeList?.filter(record => {
      const foundRecord = values.find(id => id == record.store_id);
      if (foundRecord) {
        return record;
      }
    });
    return selectedStore?.map(
      record => `${record.store_number} - ${record.store_name}`
    );
  }
};
export const prepareDataForPreview = ({
  selectedValues,
  typesList,
  categoryList,
  COVERAGE_TYPE,
  storeList,
  districtList,
  COVERAGE_VALUES,
  selectedRecord,
  differentCoverageBy,
  differentCoverageValues,
}) => {
  const selectedType = typesList.find(
    record => record.project_type_id == selectedValues.type
  )?.project_type;
  let response = [];
  if (selectedValues.category.length) {
    response = selectedValues.category.map((categoryId, index) => {
      const newData = {
        type: selectedType,
        category: categoryList.find(
          categoryRecord => categoryRecord.project_category_id == categoryId
        )?.category,
        coverageBy: selectedValues.coverageBy
          ? COVERAGE_VALUES[selectedValues.coverageBy]
          : differentCoverageBy?.length
            ? COVERAGE_VALUES[differentCoverageBy[index]]
            : undefined,
        coverageValues: coverageByValues({
          type: selectedValues.coverageBy
            ? selectedValues.coverageBy
            : differentCoverageBy?.length
              ? differentCoverageBy[index]
              : undefined,
          values: selectedValues.coverageValues.length
            ? selectedValues.coverageValues
            : differentCoverageValues.length
              ? differentCoverageValues[index][differentCoverageBy[index]]
              : [],
          storeList: storeList,
          districtList: selectedValues.coverageValues.length
            ? selectedValues.coverageValues
            : differentCoverageValues.length
              ? differentCoverageValues[index][differentCoverageBy[index]]
              : [],
          zipCodeList: selectedValues.coverageValues.length
            ? selectedValues.coverageValues
            : differentCoverageValues.length
              ? differentCoverageValues[index][differentCoverageBy[index]]
              : [],
        }),
      };
      const previousData = {};
      if (
        selectedRecord &&
        selectedRecord.data &&
        selectedRecord?.children?.length
      ) {
        if (selectedValues.type != selectedRecord?.data?.project_typeId) {
          const selectedPreviousType = typesList.find(
            record =>
              record.project_type_id == selectedRecord?.data?.project_typeId
          )?.project_type;
          previousData.previousType = selectedPreviousType || undefined;
        }
        if (
          categoryId !=
          selectedRecord?.children?.[index]?.data?.project_categoryId
        ) {
          const selectedPreviousCategory = categoryList.find(
            categoryRecord =>
              categoryId ==
              selectedRecord?.children?.[index]?.data?.project_categoryId
          )?.category;
          previousData.previousCategory = selectedPreviousCategory || undefined;
        }
        if (
          newData.coverageBy !=
          COVERAGE_VALUES[selectedRecord?.children?.[index]?.data?.coverage_by]
        ) {
          previousData.previousCoverageBy =
            COVERAGE_VALUES[
              selectedRecord?.children?.[index]?.data?.coverage_by
            ];
        }
        previousData.previousCoverageValues = coverageByValues({
          type: selectedRecord?.children?.[index]?.data?.coverage_by,
          values: selectedRecord?.children?.[index]?.data?.coverage_values,
          storeList: storeList,
          districtList: selectedValues.coverageValues,
          zipCodeList: selectedValues.coverageValues,
        });
      }
      return { ...newData, ...previousData };
    });
  }
  return response;
};
export function areCoverageValuesEqual(data) {
  if (data.length === 0) return true;
  const firstCoverageValues = data[0]?.data?.coverage_values;
  for (let i = 1; i < data.length; i++) {
    const coverageValues = data[i].data.coverage_values;
    if (
      JSON.stringify(coverageValues) !== JSON.stringify(firstCoverageValues)
    ) {
      return false;
    }
  }
  return true;
}
