import { createTheme } from '@material-ui/core/styles';
import '@fontsource/inter';
import { Link as RouterLink } from 'react-router-dom';
/*
  *** PF_PRIMARY_MAIN = '#1D25D5' - Purple Shade ****
    -- Buttons
    -- Drawer Menu Selected Items
    -- Links
    -- Check box selected

  *** PF_GREY_700 = '#B3B3B3'; // Border color - Greyish  ****
    --Border color for:
      - Paper
      - Appbar
      - Toolbar
      - Table

  *** PF_TEXT_BLACK = '#171F28' // Primary text color
  *** PF_GREY_600 = '#697C91'; // Secondary text color
  *** PF_GREY_100 = '#FBFBFB'; // Paper background color
*/
const FONT_FAMILY = 'Inter';

const PF_WHITE = '#FFFFFF';
const PF_PRIMARY_MAIN = '#0074d9';
const PF_BACKGROUND_BLUE = '#F3F3FF';
const PF_TEXT_BLACK = '#171F28';
// Grey shades
const PF_GREY_100 = '#FBFBFB'; // Background color
const PF_GREY_200 = '#F6F6F6'; // Table row background
const PF_GREY_300 = '#DFE3E8';
const PF_GREY_400 = '#C4CDD5';
const PF_GREY_500 = '#7D8286'; // Navbar sub-menu color
const PF_GREY_600 = '#697C91'; // Secondary text color
const PF_GREY_700 = '#B3B3B3'; // Border  color
const PF_GREY_800 = '#212B36';
const PF_GREY_900 = '#eff6ff';

//Error
const PF_ERROR_RED = '#f44336';

export const Theme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 500,
  },
  palette: {
    primary: {
      main: PF_PRIMARY_MAIN,
      light: PF_BACKGROUND_BLUE,
    },
    text: {
      primary: PF_TEXT_BLACK,
      secondary: PF_GREY_600,
    },
    grey: {
      100: PF_GREY_100,
      200: PF_GREY_200,
      300: PF_GREY_300,
      400: PF_GREY_400,
      500: PF_GREY_500,
      600: PF_GREY_600,
      700: PF_GREY_700,
      800: PF_GREY_800,
      900: PF_GREY_900,
    },
  },
  error: {
    main: PF_ERROR_RED,
  },
  props: {
    MuiLink: {
      component: RouterLink,
    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#bdbdbd',
        },
      },
    },
    MuiPopover: {
      paper: {
        overflow: 'hidden !important',
        '&:hover': {
          overflowY: 'auto !important',
          'scrollbar-width': 'thin',
          '&::-webkit-scrollbar': {
            // Width of vertical scroll bar
            width: '8px',
            // Height of horizontal scroll bar
            height: '10px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
          },
        },
      },
    },
    /** Styling for Typography**/
    MuiTypography: {
      h1: {
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '48px',
      },
      h2: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
      },
      h3: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
      },
      h4: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '21px',
      },
      h6: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
      },
      subtitle1: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '18px',
      },
      body1: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      body2: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '21px',
      },
    },
    MuiPaper: {
      root: {
        // border: `1px Solid ${PF_GREY_700}`,
        backgroundColor: PF_WHITE,
      },
    },
    /** Styling for AppBar **/
    MuiAppBar: {
      root: {
        display: 'flex',
        '&.MuiPaper-root': {
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
        },
      },
      colorPrimary: {
        backgroundColor: PF_WHITE,
      },
    },
    /** Styling for Drawer **/
    MuiDrawer: {
      paper: {
        whiteSpace: 'nowrap',
        padding: '0px',
        borderTop: 'none',
        borderLeft: 'none',
        borderBottom: 'none',
        background: PF_WHITE,
      },
      paperAnchorDockedLeft: {
        borderRight: `1px Solid ${PF_GREY_700}`,
      },
    },
    /** Styling for Icon **/
    MuiSvgIcon: {
      root: {
        fill: PF_TEXT_BLACK,
        width: '16px',
        height: '16px',
      },
      fontSizeSmall: {
        width: '10px',
        height: '10px',
      },
      fontSizeLarge: {
        width: '20px',
        height: '20px',
      },
      colorPrimary: {
        fill: PF_PRIMARY_MAIN,
      },
    },
    /**Styling for List */
    MuiListItemIcon: {
      root: {
        minWidth: '35px',
        width: '12px',
        height: '12px',
      },
    },
    MuiListItemText: {
      root: {
        color: PF_TEXT_BLACK,
      },
    },
    /** Styling for Button component **/
    MuiButton: {
      root: {
        fontSize: '12px',
        borderRadius: '0px',
        padding: '6px 14px',
        textTransform: 'none',
        fontWeight: 600,
      },
      contained: {
        backgroundColor: PF_GREY_900,
        '&:hover': {
          backgroundColor: PF_PRIMARY_MAIN,
        },
        boxShadow: 'unset',
      },
      outlinedPrimary: {
        color: PF_PRIMARY_MAIN,
        borderColor: PF_PRIMARY_MAIN,
        padding: '4px 14px',
      },
      containedPrimary: {
        backgroundColor: PF_PRIMARY_MAIN,
        '&:hover': {
          backgroundColor: PF_PRIMARY_MAIN,
        },
        boxShadow: 'unset',
      },
    },
    //** Styling for checkbox **/
    MuiCheckbox: {
      root: {
        width: '26px',
        height: '26px',
        '& .MuiSvgIcon-root': {
          fill: PF_PRIMARY_MAIN,
        },
        '&.Mui-disabled': {
          '& .MuiSvgIcon-root': {
            fill: PF_GREY_700,
          },
        },
      },

      colorPrimary: {
        fill: PF_PRIMARY_MAIN,
        '&:hover': {
          background: 'none',
        },
        '&.MuiIconButton-colorPrimary.Mui-checked:hover': {
          backgroundColor: 'unset',
        },
      },
      colorSecondary: {
        '&.Mui-disabled': {
          '& .MuiSvgIcon-root': {},
        },
      },
    },

    //** Styling for Form **/
    MuiFormControl: {
      root: {
        width: '100%',
      },
      label: {
        fontSize: '17px',
        lineHeight: '13px',
      },
    },
    MuiFormControlLabel: {
      label: {
        '&.Mui-disabled': {
          color: PF_GREY_600,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: PF_ERROR_RED,
      },
      root: {
        color: PF_GREY_600,
        fontSize: '14px',
        '&.Mui-focused': {
          color: 'unset',
        },
        '&.Mui-disabled': {
          color: PF_GREY_600,
        },
        '&.Mui-error': {
          color: PF_GREY_600,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${PF_TEXT_BLACK}`,
        },
        '&:before:not(.Mui-focused)': {
          borderBottom: `1px solid ${PF_TEXT_BLACK} `,
        },
        '&.Mui-disabled:after': {
          borderBottom: `1px solid ${PF_GREY_700}`,
        },
        '&.Mui-disabled:before': {
          borderBottom: `1px solid ${PF_GREY_700}`,
        },
        '&:after:not(.Mui-focused)': {
          borderBottom: `1px solid ${PF_TEXT_BLACK} `,
        },
        '&.Mui-focused:after': {
          borderBottom: `1px solid ${PF_PRIMARY_MAIN}`,
        },
        '&.Mui-focused:before': {
          borderBottom: `1px solid ${PF_PRIMARY_MAIN}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontSize: '14px',
      },
      input: {
        color: PF_TEXT_BLACK,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiSwitch: {
      switchBase: {
        '&.Mui-disabled': {
          color: `${PF_PRIMARY_MAIN} !important`,
        },
      },
    },
    //** Styling for Tabs **/

    MuiTab: {
      root: {
        minWidth: '0px !important',
        padding: '12px 20px 0px 20px',
        '&:first-child': {
          paddingLeft: '10px',
        },
        left: '0!important',
      },
      textColorInherit: {
        opacity: 'unset',
        color: '#609AF8!important',
        fontSize: '13px',
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
      wrapper: {
        textTransform: 'capitalize',
      },
    },
    MuiTabs: {
      scrollButtons: {
        width: '24px',
      },
    },
    MuiTabPanel: {
      root: {
        padding: '10px 24px',
        minHeight: '300px',
      },
    },
    //** Styling for Generic Data Table **/
    MUIDataTable: {
      paper: {
        background: PF_WHITE,
        boxShadow: 'unset',
        border: `0px solid ${PF_GREY_700}`,
      },
      tableRoot: {
        height: '100%',
      },
    },
    MUIDataTableViewCol: {
      title: {
        color: PF_TEXT_BLACK,
      },
    },
    MUIDataTableToolbar: {
      root: {
        padding: '0px 16px',
        minHeight: '56px',
        background: PF_GREY_900,
      },
      titleText: {
        color: PF_WHITE,
        fontSize: '14px',
        fontWeight: 600,
      },
      icon: {
        '& .MuiSvgIcon-root': {
          fill: '#212121',
        },
      },
      iconActive: {
        '& .MuiSvgIcon-root': {
          fill: '#212121',
        },
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        padding: '5px 5px',
        fontSize: '12px',
        color: '#343A40',
        fontWeight: 600,
        backgroundColor: '#eff6ff',
        borderTop: '1px solid #dcdcdc',
        '& .MuiButton-root': {
          fontSize: '12px',
          color: '#343A40',
          fontWeight: 600,
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      root: {
        cursor: 'pointer',
        '&.MuiTableRow-root:nth-child(odd)': {
          backgroundColor: PF_WHITE,
        },
        '&.MuiTableRow-root:nth-child(odd):hover': {
          backgroundColor: PF_WHITE,
        },
        '&.MuiTableRow-root:nth-child(even)': {
          backgroundColor: PF_WHITE,
        },
      },
    },
    MUIDataTableBodyCell: {
      stackedHeader: {
        fontSize: '18px',
        fontWeight: 600,
      },
      root: {
        fontSize: '14px',
        fontWeight: 300,
        color: '#495057',
        '&.MuiTableCell-root': {
          padding: '14px',
        },
      },
    },
    MUIDataTableSearch: {
      main: {
        '& .MuiSvgIcon-root': {
          fill: PF_PRIMARY_MAIN,
          marginTop: '18px',
          padding: '0',
        },
        '& button': {
          '& .MuiSvgIcon-root': {
            marginTop: '6px',
            marginLeft: '10px',
          },
          padding: '0',
        },
        '& button:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiInputBase-input': {
          height: '28px',
          border: `1px solid ${PF_PRIMARY_MAIN}`,
          borderRadius: '6px',
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        '& .MuiInput-underline:before': {
          display: 'none',
        },
        '& .MuiInput-underline.Mui-focused:after': {
          borderBottom: 'none',
        },
      },
      searchText: {
        paddingTop: '3px',
      },
    },
    MUIDataTableFooter: {
      root: {
        '& .MuiTableCell-root': {
          borderBottom: 'unset',
        },
      },
    },
    MUIDataTableFilter: {
      root: {
        width: '450px !important',
        '&  button': {
          border: 'none !important',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          color: 'black',
          '&& $icon': {
            color: 'black',
          },
        },
      },
    },
    MuiTablePagination: {
      root: {
        overflow: 'hidden',
      },
      actions: {
        marginLeft: '-10px',
      },
      input: {
        '&.MuiInputBase-root': {
          width: 'inherit',
        },
      },
    },
    MuiCardHeader: {
      title: {
        fontFamily: FONT_FAMILY,
      },
    },

    //** Styling for Generic Dialog **/
    MuiDialog: {
      paper: {
        borderRadius: '0px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px 24px 24px 24px',
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '8px',
          background: '#c2c9d2',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 24px 16px 24px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px',
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#f44336',
        },
      },
    },
    // Styling for Autocomplete
    MuiAutocomplete: {
      listbox: {
        overflowY: 'auto',
        'scrollbar-width': 'thin',
        '&::-webkit-scrollbar': {
          // Width of vertical scroll bar
          width: '8px',
          // Height of horizontal scroll bar
          height: '10px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '8px',
          background: '#c2c9d2',
        },
        "& .MuiAutocomplete-option[aria-selected='true']": {
          backgroundColor: PF_GREY_200,
        },
        "& .MuiAutocomplete-option[data-focus='true']": {
          backgroundColor: PF_BACKGROUND_BLUE,
        },
      },
      option: {
        borderBottom: '1px solid #DBE0E4',
        minHeight: '50px',
        maxHeight: '150px',
        height: 'auto',
      },
    },
    //Styling for select menu
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: PF_BACKGROUND_BLUE,
        },
        '&.Mui-selected:hover': {
          backgroundColor: PF_BACKGROUND_BLUE,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: PF_BACKGROUND_BLUE,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 430,
        padding: '10px',
        border: `1px Solid ${PF_GREY_700}`,
        color: PF_GREY_600,
        borderRadius: '0px',
        backgroundColor: PF_WHITE,
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
    },
  },
});
