// **** External Utilities ****
import axios from 'axios';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

export const getUsersList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setUsersListData,
  setCount,
  searchString,
  payload
) => {
  try {
    setLoading(true);
    let newQueryString,
      postPayload = {};
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    if (searchString) postPayload.search = searchString;
    if (payload) postPayload.filters = payload;

    const response = await axios.post(
      `${URL_CONSTANTS.USER_MANAGEMENT.baseUrl}/api/user-list/users?${newQueryString}`,
      { ...postPayload }
    );
    setUsersListData(response?.data?.records);
    setCount(response?.data?.totalCount);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getUserListWithFilters = async (searchQuery, selectedFilters) => {
  let newQueryString;
  newQueryString = Object.entries(searchQuery)
    .filter(([prop, value]) => Boolean(value) || value === 0)
    .map(([key, value], index) => `${key}=${value}`)
    .join('&');

  // Logic to add selected filters in query string
  if (selectedFilters && Object.values(selectedFilters)?.length) {
    let searchQueryWithFilters = Object.entries(selectedFilters)
      .filter(([prop, value]) => value?.length)
      .map(
        ([key, value], index) => `${key}=${value?.map(val => val?.toString())}`
      )
      .join('&');

    newQueryString = newQueryString + '&' + searchQueryWithFilters;
  }
  const response = await apiService.get(
    `/auth/user/report/list?${newQueryString}`
  );
  return response?.items;
};

// Get user Details by installet Id
export const getUserDataByUserId = async (
  userId,
  setLoading,
  setAlert,
  setUserData,
  setEmergencyContacts,
  setTeamReviews,
  setDocuments,
  setCredentials,
  setReloadList
) => {
  try {
    setLoading(true);
    await axios
      .get(`${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`)
      .then(response => {
        setUserData(response?.data);
        setEmergencyContacts(response?.data?.emergency_contact_info);
        setTeamReviews(response?.data?.team_reviews);
        setDocuments(response?.data?.documents);
        setCredentials(response?.data?.credentials);
      });
    setReloadList(false);
  } catch (error) {
    setAlert('error', 'Error in Fetching User Data');
  } finally {
    setLoading(false);
  }
};

export const sendEmailInvite = async (userId, setLoading, setAlert) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/sendEmailInvite`,
      {
        users: userId,
      }
    );
    if (response.data) {
      if (response.data[0].error) {
        setAlert(
          'error',
          'Error while sending email invite, please try again after sometime.'
        );
      } else {
        setAlert('success', 'Email invite has been successfully sent.');
      }
    }
  } catch (error) {
    setAlert(
      'error',
      'Error while sending email invite, please try again after sometime.'
    );
  } finally {
    setLoading(false);
  }
};

export const addNewUser = async (
  values,
  history,
  setLoading,
  setAlert,
  image
) => {
  try {
    // const response = await axios.post(`${URL_CONSTANTS.API.BASE_URL}/auth/user/manage`, {
    //   default_percent_paid: parseInt(createObj.default_percent_paid.replace('%', '')),
    // });
    // if (response.status === 200) {
    //   history.push(`/admin/usermanagement/view/${response.data?.user_id}`);
    //   setAlert('success', ' User Created successfully.');
    // }

    const formData = new FormData();
    const createObj = Object.fromEntries(
      Object.entries(values).filter(
        ([prop, value]) => value !== null && value != ''
      )
    );

    createObj['is_active'] = createObj.is_active ? 18 : 19;
    createObj.first_name = createObj.first_name.trim() || null;
    createObj.last_name = createObj.last_name.trim() || null;
    createObj.store_coverage_ids =
      createObj?.store_coverage_ids?.trim() || null;
    createObj.type_coverage_ids = createObj?.type_coverage_ids?.trim() || null;
    createObj.category_coverage_ids =
      createObj?.category_coverage_ids?.trim() || null;

    delete createObj.file;
    delete createObj.role_name;
    if (image) createObj['file'] = image;

    //return;

    // formData.append('first_name', createObj.first_name);
    // formData.append('last_name', createObj.last_name);
    // formData.append('email', createObj.email_id);
    // formData.append('phone_number', createObj.mobile_number);
    // //formData.append('photo', createObj.photo);
    // formData.append('is_staff', createObj.is_staff ? true : false);
    // formData.append('is_installer', createObj.is_installer ? true : false);
    // formData.append('is_active', createObj.is_installer ? 18 : 19);
    // formData.append('role_id', createObj.role);
    // formData.append('dashboard_ids', createObj.dashboard);
    // if (createObj.date_of_birth) formData.append('dob', createObj.date_of_birth);
    // formData.append('manager_id', createObj.manager);
    // formData.append('position_id', createObj.position || null);
    // if (createObj.hire_date) formData.append('hire_date', createObj.hire_date);
    // if (createObj.termination_date) formData.append('termination_date', createObj.termination_date);

    // formData.append('shirt_size_id', createObj.shirt_size);
    // formData.append('shoe_size', createObj.shoe_size || null);
    // formData.append('store_coverage_ids', createObj.store_coverage || null);
    // formData.append('type_coverage_ids', createObj.type_coverage || null);
    // formData.append('category_coverage_ids', createObj.category_coverage || null);
    // formData.append('team_ids', createObj.team || null);
    // formData.append('google_email_address', createObj.google_email_address || null);
    // formData.append('home_address', createObj.home_address || null);
    // formData.append('work_address', createObj.work_address || null);
    // formData.append('installer_bio', createObj.installer_bio || null);
    // formData.append('measuring_system_id', createObj.measuring_system_id || null);
    // formData.append('scheduler_sort_order', createObj.scheduler_sort_order || null);

    for (var key in createObj) {
      formData.append(key, createObj[key]);
    }

    // formData.append('item_number', values.item_number);
    // formData.append('item_desc', values.item_desc);
    // formData.append('item_type_id', values.item_type_id);
    // if (!values.file && values.image_url) formData.append('image_url', values.image_url);
    // formData.append('image_type', 'upload');
    // if (values.file) formData.append('file', values.file);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage`,
      formData,
      {
        headers: headers,
      }
    );
    response &&
      history.push(`/admin/usermanagement/view/${response.data?.user_id}`);
    setAlert('success', 'User Created successfully.');
  } catch (error) {
    setAlert(
      'error',
      error?.response?.status === 422
        ? error?.response?.data?.error
        : `An user already exist with email address you provided.`
    );
  } finally {
    setLoading(false);
  }
};
export const updateUserDetails = async (
  userId,
  editObj,
  history,
  sentUserInvite,
  setLoading,
  setAlert,
  image
) => {
  try {
    const formData = new FormData();
    editObj['is_active'] = editObj.is_active ? 18 : 19;
    editObj.sent_email_invite = editObj.sent_email_invite ? 1 : 0;
    editObj.team_reviews = JSON.stringify(editObj.team_reviews);
    editObj.first_name = editObj.first_name.trim() || null;
    editObj.last_name = editObj.last_name.trim() || null;
    editObj.store_coverage_ids = editObj?.store_coverage_ids?.trim() || null;
    editObj.type_coverage_ids = editObj?.type_coverage_ids?.trim() || null;
    editObj.category_coverage_ids =
      editObj?.category_coverage_ids?.trim() || null;

    delete editObj.file;
    delete editObj.role_name;
    delete editObj.emergencyContactInfo;
    if (image) {
      editObj['file'] = image;
    }
    for (var key in editObj) {
      if (editObj[key] && editObj[key] !== 'null') {
        formData.append(key, editObj[key]);
      }
    }
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      formData,
      { headers: headers }
    );
    if (response?.data) {
      if (sentUserInvite) {
        const responseInvite = await axios.post(
          `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/sendEmailInvite`,
          {
            users: [{ userId }],
          }
        );
        if (responseInvite.data) {
          if (responseInvite.data[0].error) {
            setAlert(
              'error',
              'Error while sending email invite, please try again after sometime.'
            );
          } else {
            history.push(`/admin/usermanagement/view/${userId}`);
            setAlert('success', 'User details updated successfully.');
          }
        }
      } else {
        history.push(`/admin/usermanagement/view/${userId}`);
        setAlert('success', 'User details updated successfully.');
      }
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

export const deleteUser = async (
  deleteUserDetailId,
  setLoading,
  setReloadList,
  setAlert
) => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${deleteUserDetailId}`
    );

    if (response?.data?.status) {
      setReloadList(true);
      setAlert('success', 'User profile deleted successfully');
    }
  } catch (error) {
    setAlert('error', 'Error while deleting User profile.', false, true);
  }
};
// Get dropdown values
export const getDropdownValues = async (
  setLoading,
  setRoleOptions,
  setDashboardOptions,
  setManagerOptions,
  setShirtSizeOptions,
  setPositionOptions,
  setStoreCoverageOptions,
  setTypeCoverageOptions,
  setCategoryOptions,
  setTeamOptions
) => {
  try {
    setLoading(true);

    const rolesRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/list`
    );
    const dashboardRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/list/available-dashboards?limit=100&offset=0`
    );
    const managersRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/list`
    );
    const shirtSizesRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/shirt-size`
    );
    const positionsRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/position/list`
    );
    const storeCoveragesRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores`
    );
    const typeCoveragesRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-type`
    );
    const categoryRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-category`
    );

    const teamRequest = axios.get(`${URL_CONSTANTS.API.BASE_URL}/system/team`);

    const responses = await Promise.all([
      rolesRequest,
      dashboardRequest,
      managersRequest,
      shirtSizesRequest,
      positionsRequest,
      storeCoveragesRequest,
      typeCoveragesRequest,
      categoryRequest,
      teamRequest,
    ]);

    setRoleOptions(responses[0]?.data?.items);
    setDashboardOptions && setDashboardOptions([...responses[1]?.data?.items]);

    setManagerOptions && setManagerOptions(responses[2]?.data?.items);
    setShirtSizeOptions && setShirtSizeOptions(responses[3]?.data);
    //setShoeSizeOptions;
    setPositionOptions && setPositionOptions(responses[4]?.data);
    // setPayMethodOptions(responses[4]?.data);    let responseData = response?.data;
    let storeResponseData = responses[5]?.data;
    let anyStore = [
      {
        store_id: null,
        store_name: 'All',
      },
    ];
    setStoreCoverageOptions &&
      setStoreCoverageOptions([...anyStore, ...storeResponseData]);
    let typeResponseData = responses[6]?.data;
    let anyType = [
      {
        project_type_id: null,
        project_type: 'All',
      },
    ];
    setTypeCoverageOptions &&
      setTypeCoverageOptions([...anyType, ...typeResponseData]);
    let categoryResponseData = responses[7]?.data;
    let anyCategory = [
      {
        project_category_id: null,
        category: 'All',
      },
    ];
    setCategoryOptions &&
      setCategoryOptions([...anyCategory, ...categoryResponseData]);
    setTeamOptions && setTeamOptions(responses[8]?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Active Installers
export const getActiveInstallers = async (
  setActiveInstallersListData,
  setAlert
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/installers/reports/active-installer-for-labor-profile`
    );
    response?.data &&
      setActiveInstallersListData(response?.data?.acitveInstallerforLabor);
  } catch (error) {
    setAlert('error', 'Error while fetching active Installers', false, true);
  }
};

//Create Emergency contact
export const createNewEmergencyContact = async (
  userId,
  values,
  setLoading,
  setIsOpen,
  setReloadEmergencyContactInfoList,
  setAlert
) => {
  try {
    setLoading(true);

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        emergency_contact_info: JSON.stringify(values),
      }
    );

    if (response.status === 200) {
      setReloadEmergencyContactInfoList(true);
      setAlert('success', 'Emergency Contact added successfully', false, true);
    }
    // setAlert('success', 'Emergency Contact added successfully', false, true);
  } catch (error) {
    setAlert('error', 'Error in emergency contact creation', false, true);
  } finally {
    setIsOpen(false);
    setLoading(false);
  }
};

//Edit Emergency contact
export const editEmergencyContact = async (
  userId,
  values,
  editObj,
  setLoading,
  setIsOpen,
  setReloadEmergencyContactInfoList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        emergency_contact_info: JSON.stringify(values),
      }
    );

    if (response.status === 200) {
      setReloadEmergencyContactInfoList(true);
      setAlert(
        'success',
        'Emergency Contact updated successfully',
        false,
        true
      );
    }
  } catch (error) {
    setAlert('error', 'Error in Emergency Contact updation', false, true);
  } finally {
    setLoading(false);
  }
};

//Delete Emergency Contact
export const deleteEmergencyContact = async (
  userId,
  remainingEmergencyData,
  setLoading,
  setAlert,
  setReloadEmergencyContactInfoList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        emergency_contact_info: JSON.stringify([...remainingEmergencyData]),
      }
    );
    // const response = await axios.delete(
    //   `${URL_CONSTANTS.API.BASE_URL}/users/${installerId}/emergency-contacts/${emergencyContactId}`
    // );
    if (response) {
      setReloadEmergencyContactInfoList(true);
      setAlert(
        'success',
        'Emergency Contact deleted successfully',
        false,
        true
      );
    }

    //setReloadEmergencyContactInfoList(true);
    //setAlert('success', 'Emergency Contact Deleted successfully', false, true);
  } catch (error) {
    setAlert('error', 'Error in Emergency Contact deletion', false, true);
  } finally {
    setLoading(false);
  }
};

// Get Relations
export const getRelations = async setRelationsList => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/relationship-type`
    );
    setRelationsList(response?.data);
    //setRelationsList(relations);
  } catch (error) {
    console.log(error);
  }
};

//Create Emergency contact
export const createNewTeamReview = async (
  userId,
  existingTeamReviews,
  values,
  setLoading,
  setIsOpen,
  setReloadTeamReviewsList,
  setAlert
) => {
  try {
    setLoading(true);
    let fileValues;

    if (values.files) {
      const filesToUpload = [values.files];

      const fileUploadRequests = filesToUpload.map(val => {
        const formData = new FormData();

        formData.append('type', 'team_reviews');
        formData.append('file', val);

        let headers = {
          'Content-Type': 'multipart/form-data',
        };
        return axios.post(
          `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
          formData,
          { headers: headers }
        );
      });

      const responses = await Promise.all(fileUploadRequests);

      fileValues = responses.map(val => val.data);
    }

    const createTeamReviewObj = { ...values };

    if (fileValues) {
      createTeamReviewObj['files'] = fileValues;
    } else {
      delete createTeamReviewObj?.files;
    }

    const teamReviews = existingTeamReviews ? existingTeamReviews : [];

    const teamReviewFormValues = [...teamReviews, createTeamReviewObj];

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        team_reviews: JSON.stringify(teamReviewFormValues),
      }
    );
    if (response.status === 200) {
      setReloadTeamReviewsList(true);
      setAlert('success', 'Team Review added successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in Team Review creation', false, true);
  } finally {
    setLoading(false);
    setIsOpen(false);
  }
};

//Edit Emergency contact
export const editTeamReview = async (
  userId,
  rowIndex,
  existingData,
  values,
  setLoading,
  setIsOpen,
  setReloadTeamReviewsList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    let editTeamReviewObj = {};
    if (values.files) {
      if (!values.files.url) {
        const filesToUpload = [values.files];
        const fileUploadRequests = filesToUpload.map(val => {
          const formData = new FormData();
          formData.append('type', 'team_reviews');
          formData.append('file', val);
          let headers = {
            'Content-Type': 'multipart/form-data',
          };
          return axios.post(
            `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
            formData,
            { headers: headers }
          );
        });
        const responses = await Promise.all(fileUploadRequests);
        const fileValues = responses.map(val => val.data);
        editTeamReviewObj = { ...values, files: fileValues };
      }
    }
    const updatedEmergencyData = await existingData.map(val => {
      if (rowIndex !== val.team_review_id?.value) {
        return {
          type: val.type.id,
          description: val.description.value,
          files: val.files.data,
        };
      } else {
        if (values.files) {
          if (values.files.url) {
            return {
              type: values.type,
              description: values.description,
              files: [values.files],
            };
          } else {
            return {
              type: editTeamReviewObj.type,
              description: editTeamReviewObj.description,
              files: editTeamReviewObj.files,
            };
          }
        } else {
          return {
            type: values.type,
            description: values.description,
          };
        }
      }
    });

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        team_reviews: JSON.stringify(updatedEmergencyData),
      }
    );
    if (response.status === 200) {
      setReloadTeamReviewsList(true);
      setAlert('success', 'Team Review updated successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in Team Review updation', false, true);
  } finally {
    setLoading(false);
  }
};

// Get TeamReviewTypes
export const getTeamReviewTypes = async setTeamReviewTypes => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/team-review-type`
    );
    // setRelationsList(response?.data?.items);
    setTeamReviewTypes(response?.data);
  } catch (error) {
    console.log(error);
  }
};

//Create Document
export const createNewDocument = async (
  userId,
  existingDocumentData,
  values,
  setLoading,
  setIsOpen,
  setReloadDocumentsList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);

    const filesToUpload = [values.file];

    const fileUploadRequests = filesToUpload.map(val => {
      const formData = new FormData();

      formData.append('type', 'documents');
      formData.append('file', val);

      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      return axios.post(
        `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
        formData,
        { headers: headers }
      );
    });

    const responses = await Promise.all(fileUploadRequests);

    const fileValues = responses.map(val => val.data);

    const createDocumentObj = { ...values, file: fileValues[0] };

    const existingDoc = existingDocumentData ? existingDocumentData : [];

    const documentFormValues = [...existingDoc, createDocumentObj];

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        documents: JSON.stringify(documentFormValues),
      }
    );
    if (response.status === 200) {
      setReloadDocumentsList(true);
      setAlert('success', 'Document added successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error while uploading a new document', false, true);
  } finally {
    setLoading(false);
  }
};

//Edit Document
export const editDocument = async (
  userId,
  rowIndex,
  existingDocumentData,
  values,
  setLoading,
  setIsOpen,
  setReloadDocumentsList,
  setAlert
) => {
  try {
    setIsOpen(false);
    setLoading(true);

    let editDocumentObj = {};

    if (values.file) {
      if (!values.file.url) {
        const filesToUpload = [values.file];

        const fileUploadRequests = filesToUpload.map(val => {
          const formData = new FormData();

          formData.append('type', 'documents');
          formData.append('file', val);

          let headers = {
            'Content-Type': 'multipart/form-data',
          };
          return axios.post(
            `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
            formData,
            { headers: headers }
          );
        });

        const responses = await Promise.all(fileUploadRequests);

        const fileValues = responses.map(val => val.data);

        editDocumentObj = { ...values, file: fileValues[0] };
      }
    }

    const updatedEmergencyData = await existingDocumentData.map(val => {
      if (rowIndex !== val.document_id?.value) {
        return {
          file: val.file.data,
          description: val.description.value,
          notes: val.notes.value,
        };
      } else {
        if (values?.file && !values?.file?.url) {
          return editDocumentObj;
        } else {
          return {
            file: val?.file?.data,
            description: values.description,
            notes: values.notes,
          };
        }
      }
    });

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        documents: JSON.stringify(updatedEmergencyData),
      }
    );
    if (response.status === 200) {
      setReloadDocumentsList(true);
      setAlert('success', 'Document updated successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in document updation', false, true);
  } finally {
    setLoading(false);
  }
};
//Delete Emergency Contact
export const deleteDocument = async (
  userId,
  remainingDocumentData,
  setLoading,
  setAlert,
  setReloadDocumentsList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        documents: JSON.stringify([...remainingDocumentData]),
      }
    );

    response && setReloadDocumentsList(true);
    setAlert('success', 'Document Deleted successfully', false, true);
  } catch (error) {
    setAlert('error', 'Error in deleting Document', false, true);
  } finally {
    setLoading(false);
  }
};

//Delete Emergency Contact
export const deleteTeamReview = async (
  userId,
  remainingDocumentData,
  setLoading,
  setAlert,
  setReloadDocumentsList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        team_reviews: JSON.stringify([...remainingDocumentData]),
      }
    );

    response && setReloadDocumentsList(true);
    setAlert('success', 'Team Review Deleted successfully', false, true);
  } catch (error) {
    setAlert('error', 'Error in deleting Document', false, true);
  } finally {
    setLoading(false);
  }
};

//Create Document
export const createNewCredential = async (
  userId,
  credentialsData,
  values,
  setLoading,
  setIsOpen,
  setReloadCredentialsList,
  setAlert
) => {
  try {
    setLoading(true);
    let fileValues;
    if (values.files) {
      const filesToUpload = [values.files];

      const fileUploadRequests = filesToUpload.map(val => {
        const formData = new FormData();

        formData.append('type', 'credentials');
        formData.append('file', val);

        let headers = {
          'Content-Type': 'multipart/form-data',
        };
        return axios.post(
          `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
          formData,
          { headers: headers }
        );
      });

      const responses = await Promise.all(fileUploadRequests);

      fileValues = responses.map(val => val.data);
    }

    const createCredentialsObj = { ...values };

    if (fileValues) {
      createCredentialsObj['files'] = fileValues;
    } else {
      delete createCredentialsObj?.files;
    }

    const existingCred = credentialsData ? credentialsData : [];

    const addNewCredentialData = [...existingCred, createCredentialsObj];

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        credentials: JSON.stringify(addNewCredentialData),
      }
    );

    if (response.status === 200) {
      setReloadCredentialsList(true);
      setAlert('success', 'Credential added successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in creating a new credential', false, true);
  } finally {
    setIsOpen(false);
    setLoading(false);
  }
};

// //Edit Credentials
export const editCredential = async (
  userId,
  editObj,
  values,
  setLoading,
  setIsOpen,
  setReloadCredentialsList,
  setAlert,
  isNewFileSelected,
  setIsNewFileSelected
) => {
  try {
    setIsOpen(false);
    setLoading(true);

    let fileValues;
    const editCredentialsObj = { ...values };
    if (values.files && isNewFileSelected) {
      const filesToUpload = [values.files];

      const fileUploadRequests = filesToUpload.map(val => {
        const formData = new FormData();

        formData.append('type', 'credentials');
        formData.append('file', val);

        let headers = {
          'Content-Type': 'multipart/form-data',
        };
        return axios.post(
          `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}/files`,
          formData,
          { headers: headers }
        );
      });

      const responses = await Promise.all(fileUploadRequests);

      fileValues = responses.map(val => val.data);

      if (fileValues) {
        editCredentialsObj['files'] = fileValues;
      } else {
        delete editCredentialsObj?.files;
      }

      const existingCred = editObj ? editObj : [];

      //const editCredentialData = [...existingCred, editCredentialsObj];
    }
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        credentials: JSON.stringify([editCredentialsObj]),
      }
    );

    if (response.status === 200) {
      setReloadCredentialsList(true);
      setAlert('success', 'Credential updated successfully', false, true);
    }
    setIsNewFileSelected(false);
  } catch (error) {
    setAlert('error', 'Error in credential updation', false, true);
  } finally {
    setLoading(false);
  }
};

//delete Credential
export const deleteCredential = async (
  userId,
  remainingCredentialData,
  setLoading,
  setAlert,
  setReloadCredentialsList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    // const response = await axios.delete(
    //   `${URL_CONSTANTS.API.BASE_URL}/users/${installerId}/emergency-contacts/${emergencyContactId}`
    // );
    // if (response) {
    //   setReloadEmergencyContactInfoList(true);
    //   setAlert('success', 'Emergency Contact Deleted successfully', false, true);
    // }

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        credentials: JSON.stringify([...remainingCredentialData]),
      }
    );
    // const response = await axios.delete(
    //   `${URL_CONSTANTS.API.BASE_URL}/users/${installerId}/emergency-contacts/${emergencyContactId}`
    // );
    if (response) {
      setReloadCredentialsList(true);
      setAlert('success', 'Credential Deleted successfully', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in deleting Credential', false, true);
  } finally {
    setLoading(false);
  }
};

// Get TypesOptions
export const getTypesOptions = async setTypesOptions => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/credential-type`
    );
    //setRelationsList(response?.data?.items);
    setTypesOptions(response?.data);
  } catch (error) {
    console.log(error);
  }
};
// Get InsuranceTypesOptions
export const getInsuranceTypesOptions = async setInsuranceTypes => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/insurance-type`
    );
    // setRelationsList(response?.data?.items);
    setInsuranceTypes(response?.data);
  } catch (error) {
    console.log(error);
  }
};
// Get Pre-signed URL for Documents
export const getPreSignedUrl = async (setLoading, setPreSignedUrl, docUrl) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/documents/common/generate-signed-url`,
      { document_url: docUrl }
    );
    setPreSignedUrl(response?.data?.document_signed_url);
    return response?.data?.document_signed_url;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const updateUserStatus = async (userId, status) => {
  try {
    const statusId = status ? '18' : '19';
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/active-deactive-user/${userId}`,
      {
        statusId: statusId,
      }
    );
    return response?.data;
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.error,
    };
  }
};

export const updateIsAvailableForRouting = async (userId, status) => {
  try {
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    const statusId = status ? 1 : 0;
    formData.append('is_available_for_routing', statusId);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      formData,
      headers
    );
    return response?.data;
  } catch (err) {
    console.error(err);
  }
};

export const getFiltersDropdownValues = async () => {
  try {
    const storeCoveragesRequest = apiService.get(`/stores`);
    const typeCoveragesRequest = apiService.get(
      `/projects/master/project-type`
    );
    const categoryRequest = apiService.get(`/projects/master/project-category`);

    const [storesList, typesList, categoryList] = await Promise.all([
      storeCoveragesRequest,
      typeCoveragesRequest,
      categoryRequest,
    ]);
    return { storesList, typesList, categoryList };
  } catch (error) {
    console.error(error);
  }
};

export const filteredList = async payload => {
  try {
    const response = await apiService.userManagementPost(
      `${URL_CONSTANTS.USER_MANAGEMENT.filters.filterList}`,
      payload
    );
    return response;
  } catch (error) {
    console.error('Error fetching filtered user list', error);
  }
};
