// **** React Imports ****
import React from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';

// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Icon,
  Typography,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { JSONTree } from 'react-json-tree';

// **** Custom Components ****
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

import { getEngineResultList } from './EngineResult.service';

// **** Styles/Images/Icons ****
import { useStyles } from './EngineResult.styles';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const EngineResult = () => {
  const clientsBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Engine Result',
    },
  ];

  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortDirection: 'DESC',
    sortKey: 'created_at',
  });
  const [formAction, setFormAction] = React.useState('add');
  const history = useHistory();
  const classes = useStyles();
  const [engineResultData, setEngineResultData] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const { alert, setAlert, clearAlert } = useAlerts();
  const [rowIndex, setRowIndex] = React.useState();
  const [factLog, setFactLog] = React.useState([]);
  const [engineResultLog, setEngineResultLog] = React.useState([]);
  const [reloadForm, setReloadForm] = React.useState(false);
  const [logListCount, setLogListCount] = React.useState();
  const [factDialogSettings, setFactDialogSettings] = React.useState({
    title: '',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: false,
  });
  const [engResultdialogSettings, setEngResultDialogSettings] = React.useState({
    title: '',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: false,
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [engResultIsOpen, setEngResultIsOpen] = React.useState(false);

  React.useEffect(() => {
    clearAlert();
  }, []);

  React.useEffect(() => {
    getEngineResultList(
      searchQuery,
      selectedFilters,
      setLoading,
      setEngineResultData,
      setLogListCount
    );
  }, [searchQuery, selectedFilters, reloadForm, setLoading]);

  const dataTableColumns = [
    {
      name: 'project_id',
      label: 'Project ID',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '90px', maxWidth: '90px' },
        }),
      },
    },
    {
      name: 'project_number',
      label: 'Project Number',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '90px', maxWidth: '90px' },
        }),
      },
    },
    {
      name: 'po_number',
      label: 'P.O. Number',

      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '90px', maxWidth: '90px' },
        }),
      },
    },
    {
      name: 'topic_name',
      label: 'Topic Name',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '210px', maxWidth: '210px' },
        }),
      },
    },
    {
      name: 'fact',
      label: 'Fact',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { minWidth: '80px', maxWidth: '80px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <Link
              key={dataIndex}
              onClick={index => {
                viewFactLog(
                  'view',
                  dataIndex,
                  dataArray && dataArray[dataIndex]
                );
              }}
            >
              <span>View Fact Log</span>
            </Link>
          );
        },
      },
    },
    {
      name: 'engine_result',
      label: 'Engine Result',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <Link
              key={dataIndex}
              onClick={index => {
                viewEngineResultLog(
                  'view',
                  dataIndex,
                  dataArray && dataArray[dataIndex]
                );
              }}
            >
              <span>View Engine Result Log</span>
            </Link>
          );
        },
      },
    },
    {
      name: 'created_at',
      label: 'Created At',

      options: {
        sort: true,
        filter: false,
        viewColumns: true,
        // setCellProps: () => ({
        //   style: { minWidth: '120px', maxWidth: '120px' },
        // }),
      },
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchQuery: searchString,
      }));
    }, 500);
  };

  let dataArray =
    engineResultData?.map((val, index) => {
      return {
        project_id: val?.project_id,
        project_number: val?.project_number,
        po_number: val?.po_number,
        topic_name: val?.topic_name,
        event_name: val?.event_name,
        fact_data: val?.fact,
        engine_result_data: val?.engine_result,
        created_at: moment(val?.created_at).format('MM-DD-YYYY hh:mm A'),
      };
    }) || [];

  //View Fact Modal
  const viewFactLog = (action, index, rowData) => {
    setFormAction(action);
    setFactLog(rowData);
    setFactDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'view' ? false : true,
      title: 'View Fact Log',
    }));

    setRowIndex(index);
    setIsOpen(true);
  };
  //View Engine Result Modal
  const viewEngineResultLog = (action, index, rowData) => {
    setFormAction(action);
    setEngineResultLog(rowData);
    setEngResultDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'view' ? false : true,
      title: 'View Engine Result Log',
    }));

    setRowIndex(index);
    setEngResultIsOpen(true);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: logListCount || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          setLoading(true);
          setSearchQuery(prevState => ({
            ...prevState,
            searchQuery: e.target.defaultValue,
            offset: 0,
          }));
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      handleSearch('');
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        searchQuery: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
      };
    },
    confirmFilters: true,
  };

  const jsonTreeTheme = {
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    alignItems: 'left',
    nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
      style: {
        textTransform: expanded ? 'uppercase' : style.textTransform,
        fontSize: '12px',
        fontWeight: expanded ? 'bold' : style.fontWeight,
      },
    }),
    nestedKeyLabel: {
      fontSize: '12px',
    },
    value: {
      fontSize: '12px',
    },
  };

  const getFactLogData = () => {
    return (
      <Grid container spacing={2} direction="column">
        <JSONTree data={factLog?.fact_data} theme={jsonTreeTheme} />
      </Grid>
    );
  };

  const getEngineResultLogData = () => {
    return (
      <Grid container spacing={2} direction="column">
        <JSONTree
          data={engineResultLog?.engine_result_data?.failureResults.map(
            (e, i) => {
              const data = e;
              return data;
            }
          )}
          theme={jsonTreeTheme}
          hideRoot={true}
        />
      </Grid>
    );
  };
  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Engine Result"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
        </Grid>
        {loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        <Grid item classes={{ root: classes.dataTableWrapper }}>
          {engineResultData && (
            <GenericDataTable
              title="Engine Result"
              columns={dataTableColumns}
              data={dataArray}
              options={dataTableOptions}
            />
          )}
        </Grid>
        <GenericDialog
          fullwidth
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          dialogSettings={factDialogSettings}
          disabledButton1={false}
        >
          <form>{getFactLogData()}</form>
        </GenericDialog>
        <GenericDialog
          fullwidth
          isOpen={engResultIsOpen}
          handleClose={() => {
            setEngResultIsOpen(false);
          }}
          dialogSettings={engResultdialogSettings}
          disabledButton1={false}
        >
          <form>{getEngineResultLogData()}</form>
        </GenericDialog>
      </Grid>
    </>
  );
};

export default EngineResult;
