import {} from 'react';
import {
  Grid,
  CircularProgress,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { CHARGEBACK_KEY, PROJECT_KEY } from '../constant/constant';

const TableDetails = ({ dimensionList, action, widgetFormik }) => {
  const headingKeys = [],
    tableLen = widgetFormik.values?.table_configuration.length;
  widgetFormik.values?.table_configuration.forEach(record => {
    if (!headingKeys.includes(record.measureName)) {
      headingKeys.push(record.measureName);
    }
  });
  const handleRemove = index => {
    widgetFormik.values.table_configuration.splice(index, 1);
    widgetFormik.setFieldValue(
      'table_configuration',
      widgetFormik.values.table_configuration
    );
  };
  const handleInsert = index => {
    const firstHalfArr = widgetFormik.values.table_configuration.slice(
      0,
      index + 1
    );
    const secondHalfArr = widgetFormik.values.table_configuration.slice(
      index + 1,
      widgetFormik.values.table_configuration.length
    );
    const finalArr = [
      ...firstHalfArr,
      { measureName: '', alice: '' },
      ...secondHalfArr,
    ];
    widgetFormik.setFieldValue('table_configuration', finalArr);
  };
  return widgetFormik.values.table_configuration.map((record, index) => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <Autocomplete
            key={widgetFormik.values.table_configuration[index].measureName}
            id="chartType"
            name={`table_configuration.${index}.label`}
            fullWidth
            groupBy={option => option.firstLetter}
            options={dimensionList.filter(
              ({ name }) => !headingKeys.includes(name)
            )}
            getOptionLabel={option => option && option.value}
            renderInput={params => (
              <TextField {...params} label="Field" required />
            )}
            disabled={action === 'view' ? true : false}
            value={dimensionList.find(
              item =>
                item.name ==
                widgetFormik.values.table_configuration[index].measureName
            )}
            onChange={(event, value) => {
              if (
                value?.firstLetter === CHARGEBACK_KEY &&
                value?.key?.includes(PROJECT_KEY) &&
                widgetFormik.values.custom_properties
              ) {
                widgetFormik.values.custom_properties.reportTableSecondaryKey = `${CHARGEBACK_KEY}.project_id`;
              }
              widgetFormik.values.table_configuration[index].measureName =
                value?.name || '';
              widgetFormik.values.table_configuration[index].alice =
                value?.shortTitle || '';
              widgetFormik.setFieldValue('table_configuration', [
                ...widgetFormik.values.table_configuration,
              ]);
              if (widgetFormik.values?.order_by?.orderField == '') {
                widgetFormik.values.order_by.orderField = value?.name || '';
                widgetFormik.setFieldValue(
                  'order_by',
                  widgetFormik.values.order_by
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <TextField
            label="Field Alias"
            value={widgetFormik.values.table_configuration[index].alice}
            onChange={event => {
              widgetFormik.values.table_configuration[index].alice =
                event.target.value;
              widgetFormik.setFieldValue(
                'table_configuration',
                widgetFormik.values.table_configuration
              );
            }}
            required
            disabled={action === 'view' ? true : false}
          />
        </Grid>
        {action !== 'view' && (
          <Grid container item xs={2} md={1} alignItems="center">
            <div className="flex gap-5">
              {tableLen > 1 && (
                <RemoveCircleOutlineIcon
                  color="primary"
                  fontSize="large"
                  onClick={() => handleRemove(index)}
                  className="cursor-pointer"
                />
              )}
              <AddCircleOutlineIcon
                color="primary"
                fontSize="large"
                onClick={() => handleInsert(index)}
                className="cursor-pointer"
              />
            </div>
          </Grid>
        )}
      </>
    );
  });
};

export default TableDetails;
