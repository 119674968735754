import { useState, useCallback, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tooltip, Card, Tag, Toast, Chip } from 'primereact';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import { SEND_RECEIVED } from '../constants/constants';
import { YesNoDropdown } from '../../../../constants';
import {
  capitalizeFirstLetter,
  formatDateTimeMDYHM,
  isCrmEnabled,
  formatDateTime,
} from '../../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import TableLoader from '../../../shared/Loader/TableLoader';
import { generateUrlParams } from '../helper/helper';
import { fetchCustomerInfo } from '../../Clients/helpers/CustomerInfo';

import SMSForm from './SMSForm';

const SMSList = ({
  customerId,
  reload,
  communicationType,
  communicationId,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  setReload,
  isVersionActive = true,
}) => {
  const [smsList, setSmsList] = useState([]);
  const [totalRecordsCount, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    sender: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    text: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    isSend: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'scheduleTime',
    sortOrder: -1,
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [customerDetails, setCustomerDetails] = useState(null);
  const toast = useRef(null);

  const fetchSmsList = async () => {
    try {
      const urlParams = generateUrlParams({
        offset: lazyState.first,
        limit: lazyState.rows,
        sortField: lazyState.sortField,
        sortOrder: lazyState.sortOrder > 0 ? 'ASC' : 'DESC',
        globalSearch: globalFilterValue,
        communicationType,
        communicationId,
      });
      const response = await apiService.getWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.communication.sms.getAll}/${customerId}?${urlParams}`,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );
      if (response && response?.data) {
        setSmsList(response.data);
        setTotalRecords(response.count);
      }

      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSmsList();
  }, [customerId, reload, lazyState, globalFilterValue]);
  useEffect(() => {
    fetchCustomerInfo(customerId, setCustomerDetails, () => {});
  }, []);
  const sendOrReceivedFilter = options => {
    return (
      <PFDropdown
        value={options?.value}
        options={SEND_RECEIVED}
        optionLabel="label"
        onChange={(name, value) => {
          options.filterApplyCallback(value);
        }}
        placeholder="Select One"
        className="p-column-filter w-full align-items-center"
        showClear
        pt={{
          input: {
            className: 'p-inputtext p-inputtext-sm',
          },
        }}
      />
    );
  };

  const renderHeader = () => {
    return (
      <div className="grid m-1 pl-2 pr-2">
        <div className="col-9 p-0">
          {isVersionActive && (
            <SMSForm
              customerId={customerId}
              reload={reload}
              setReload={setReload}
              opportunityId={opportunityId}
              quoteId={quoteId}
              invoiceId={invoiceId}
              paymentId={paymentId}
            />
          )}
        </div>

        <div className="col-3 flex justify-content-end align-items-center p-0">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <PFInputText
              value={globalFilterValue}
              onChange={e => {
                setGlobalFilterValue(e.target.value);
                setlazyState({
                  ...lazyState,
                  first: 0,
                  page: 1,
                });
              }}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };
  const dataTableParams = { header: renderHeader() };
  const tableColum = [
    {
      field: 'scheduleTime',
      header: 'Date',
      sortable: true,
      filter: false,
      style: { width: '20%' },
      body: rowData => {
        return (
          <>
            {rowData.sendOrReceived === '0' ? (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-arrow-down"
                  severity="info"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.scheduleTime)}
              </>
            ) : rowData.schedule === '1' ? (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-clock"
                  severity="warning"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.scheduleTime)}
              </>
            ) : rowData.sendFailed ? (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-stop-circle"
                  severity="danger"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.scheduleTime)}
              </>
            ) : (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-check"
                  severity="success"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.scheduleTime)}
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'sender',
      header: 'Sender',
      sortable: true,
      filter: false,
      style: { width: '10%' },
      body: rowData => {
        return rowData.sender
          ? capitalizeFirstLetter(rowData.sender)
          : customerDetails
            ? capitalizeFirstLetter(
                `${customerDetails.first_name} ${customerDetails.last_name}`
              )
            : '';
      },
    },
    {
      field: 'message',
      header: 'Message',
      sortable: true,
      filter: false,
      body: rowData => {
        return (
          <>
            <div>{rowData.message}</div>
          </>
        );
      },
    },
  ];

  if (!communicationType && !communicationId) {
    tableColum.push({
      field: '',
      header: 'Reference',
      sortable: false,
      filter: false,
      style: { width: '12%' },
      hidden: !isCrmEnabled(),
      body: params => {
        if (params.parent_id && params.parent_type && params.parent_number) {
          let redirectUrl = '';
          if (params.parent_type == 'opportunity') {
            redirectUrl = `/crm/${customerId}/lead/view/${params.parent_id}`;
          }
          if (params.parent_type == 'quote') {
            redirectUrl = `/crm/${customerId}/${params.parent_opportunity_key}/quote/view/${params.parent_id}`;
          }
          if (params.parent_type == 'invoice') {
            redirectUrl = `/crm/invoice/view/${params.parent_id}`;
          }
          return (
            <Chip
              label={params.parent_number}
              icon="pi pi-external-link"
              onClick={() => window.open(redirectUrl)}
              style={{ cursor: 'pointer' }}
              className="text-sm"
            />
          );
        }
        return null;
      },
    });
  }

  return loading ? (
    <>
      <SkeletonLoader columnCount={1} columnWidth="100%" />
      <TableLoader columnCount={3} noOfRow={4} mTopBottom={0} />
      <Toast ref={toast} />
    </>
  ) : (
    <>
      <PFDataTable
        columns={tableColum}
        data={smsList}
        stripedRows
        onFilter={() => {}}
        filters={filters}
        dataTableParams={dataTableParams}
        lazy={true}
        loading={false}
        dataKey="id"
        paginator={true}
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 50]}
        tableStyle={{ minWidth: '50rem' }}
        stripedRows={true}
        onPage={event => {
          setlazyState({
            ...lazyState,
            first: event.first,
            pageCount: event.pageCount,
            rows: event.rows,
          });
        }}
        first={lazyState.first}
        totalRecords={totalRecordsCount}
        onSort={event => {
          setlazyState({
            ...lazyState,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
          });
        }}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
      />

      <Tooltip target=".custom-target-icon" />

      <Toast ref={toast} />
    </>
  );
};

export default SMSList;
