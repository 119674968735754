import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Chips, Toast, Dialog } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../../shared/PFPrime/PFMultiSelect';
import PFDropDown from '../../../shared/PFPrime/PFDropdown';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import {
  COVERAGE_TYPE,
  prepareDataForPreview,
  COVERAGE_VALUES,
  ACTION,
  areCoverageValuesEqual,
} from '../Helper';
import {
  addAndCloneCoveragePreference,
  groupCloneCoveragePreference,
  updateCoveragePreference,
} from '../services/user-form-coverage.service';
import {
  addAndCloneJobCoverage,
  editJobCoverage,
  groupCloneJobCoverage,
} from '../services/user-form-job-assign-coverage.service';

import PreviewTable from './PreviewTable';

const createCoverage = async (userId, values) => {
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
  });
};

const groupCloneCoverage = async (userId, values) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values.coverageValues
          : values.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
    };
  });
  return await groupCloneCoveragePreference(userId, groupClonePayload);
};

const rowCloneCoverage = async (userId, values) => {
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values?.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
  });
};

const groupEditCoverage = async (userId, values, originalData) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    const updateRecord = originalData?.children?.find(child => {
      if (child?.data?.project_categoryId == record) {
        return child?.data?.project_categoryId;
      }
    });
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values?.coverageBy || values?.differentCoverageBy[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index]?.[
              values?.differentCoverageBy?.[index]
            ],
      },
      user_coverage_preference_id:
        updateRecord?.data?.user_coverage_preference_id || undefined,
    };
  });

  return await updateCoveragePreference(userId, groupClonePayload);
};

const rowEditCoverageCoverage = async (userId, values, originalData) => {
  const updateRecord = originalData?.children?.find(child => {
    if (child.data.project_categoryId == values?.category?.[0]) {
      return child?.data?.project_categoryId;
    }
  });
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values?.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
    // user_coverage_preference_id: updateRecord?.data?.user_coverage_preference_id || undefined,
    user_coverage_preference_id:
      updateRecord?.data?.user_coverage_preference_id ||
      originalData?.data?.user_coverage_preference_id,
  });
};

//JobAssgin
const createJobCoverage = async (userId, values) => {
  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: '',
      service_time: '',
    },
  ]);
};

const groupJobCloneCoverage = async (userId, values) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      // coverage: {
      //   type: values.coverageBy,
      //   values: values.coverageValues,
      // },
      coverage: {
        type: values?.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
    };
  });
  return await groupCloneJobCoverage(userId, groupClonePayload);
};

const rowCloneJobCoverage = async (userId, values) => {
  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: values?.rotation_percentage,
      service_time: values?.service_time,
    },
  ]);
};

const groupEditJobCoverage = async (userId, values, originalData) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    const updateRecord = originalData?.children.find(child => {
      if (child.data.project_categoryId == record) {
        return child?.data?.project_categoryId;
      }
    });

    return {
      type_coverage_id: values.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
      user_job_coverage_id:
        updateRecord?.data?.user_coverage_preference_id || undefined,
    };
  });

  return await editJobCoverage(userId, groupClonePayload);
};

const rowEditJobCoverageCoverage = async (userId, values, originalData) => {
  const updateRecord = originalData?.children?.find(child => {
    if (child.data.project_categoryId == values?.category?.[0]) {
      return child?.data?.project_categoryId;
    }
  });

  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: '',
      service_time: '',
      user_job_coverage_id:
        updateRecord?.data?.user_coverage_preference_id ||
        originalData?.data?.user_coverage_preference_id,
    },
  ]);
};
const validationSchema = Yup.object().shape({
  type: Yup.number().required('Type is required'),
  category: Yup.array()
    .of(Yup.number().required())
    .min(1, 'At least one category is required')
    .required('Category is required'),
  coverageBy: Yup.number().notRequired(), // coverageBy is optional, but if present, coverageValues should be required
  coverageValues: Yup.array()
    .min(1, 'At least one category is required')
    .required('Category is required'),
});

const CoverageClone = ({
  selectedRecord,
  typesList,
  categoryList,
  coverageTypeList,
  storeList,
  districtList,
  setSelectedRecord,
  isCloneOrEdit,
  setVisible,
  userId,
  setRefreshCoverageData,
  refreshCoverageData,
  isJob,
}) => {
  const toast = useRef(null);
  const [isRequiredConfirmation, setIsRequiredConfirmation] = useState(
    isCloneOrEdit == ACTION.GROUP_EDIT
  );
  const [loading, setLoading] = useState(false);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [isCancelConfirmationPopup, setIsCancelConfirmationPopup] =
    useState(false);
  const mode =
    isCloneOrEdit == ACTION.GROUP_CLONE ||
    isCloneOrEdit == ACTION.ROW_CLONE ||
    isCloneOrEdit == ACTION.GROUP_EDIT ||
    isCloneOrEdit == ACTION.ROW_EDIT
      ? true
      : false;
  const selectedCategories = [];
  const selectedCoverageValues = [];
  let differentCoverageBy = [];
  let differentCoverageValues = [];
  let coverageByValue = null;
  if (mode && selectedRecord) {
    coverageByValue = selectedRecord?.children?.[0]?.data?.coverage_by;
    const isAllCoverageValuesSame = areCoverageValuesEqual(
      selectedRecord.children
    );
    selectedRecord?.children?.forEach(record => {
      if (coverageByValue != record?.data?.coverage_by) {
        coverageByValue = null;
      }

      selectedCategories.push(record?.data?.project_categoryId);
      isAllCoverageValuesSame &&
        selectedCoverageValues.push(...record?.data?.coverage_values);
      differentCoverageBy.push(record.data.coverage_by);
      differentCoverageValues.push({
        [record.data.coverage_by]: [...record.data.coverage_values],
      });
    });
  }
  const onSaveUpdateHandler = async values => {
    let apiResponse = null;
    try {
      if (isJob) {
        if (isCloneOrEdit == ACTION.CREATE) {
          apiResponse = await createJobCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_CLONE) {
          apiResponse = await groupJobCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.ROW_CLONE) {
          apiResponse = await rowCloneJobCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_EDIT) {
          apiResponse = await groupEditJobCoverage(
            userId,
            values,
            selectedRecord
          );
        } else if (isCloneOrEdit == ACTION.ROW_EDIT) {
          apiResponse = await rowEditJobCoverageCoverage(
            userId,
            values,
            selectedRecord
          );
        }
      } else {
        if (isCloneOrEdit == ACTION.CREATE) {
          apiResponse = await createCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_CLONE) {
          apiResponse = await groupCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.ROW_CLONE) {
          apiResponse = await groupCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_EDIT) {
          apiResponse = await groupEditCoverage(userId, values, selectedRecord);
        } else if (isCloneOrEdit == ACTION.ROW_EDIT) {
          apiResponse = await rowEditCoverageCoverage(
            userId,
            values,
            selectedRecord
          );
        }
      }

      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Created',
          detail: `The record has been successfully ${
            isCloneOrEdit === ACTION.GROUP_EDIT ||
            isCloneOrEdit === ACTION.ROW_EDIT
              ? `updated.`
              : isCloneOrEdit === ACTION.ROW_CLONE ||
                  isCloneOrEdit === ACTION.GROUP_CLONE
                ? `copied`
                : `created.`
          } `,
          life: 3000,
        });
        setTimeout(() => {
          setLoading(false);
          setRefreshCoverageData(!refreshCoverageData);
          setSelectedRecord(null);
          setVisible(false);
        }, 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: apiResponse.message || 'Something went wrong.',
          life: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (apiResponse?.message) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong.',
          life: 3000,
        });
        setLoading(false);
      }
    }
  };
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: mode ? selectedRecord.data.project_typeId : null,
      category: selectedCategories,
      coverageBy: coverageByValue,
      coverageValues: !coverageByValue
        ? []
        : [...new Set(selectedCoverageValues)],
      differentCoverageBy: differentCoverageBy,
      differentCoverageValues: differentCoverageValues,
    },
    onSubmit: async values => {
      try {
        onSaveUpdateHandler(values);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <>
      <div className="coverage-dialog-parent">
        <PFDialog
          show={true}
          hide={() => {
            setSelectedRecord(null);
            setVisible(false);
          }}
          className="w-11 lg:w-9"
          style={{ height: '80vh' }}
          pt={{
            closeButtonIcon: { className: 'hidden' },
            header: { className: 'hidden' },
            content: { className: 'border-round' },
          }}
          appendTo="self"
          draggable={false}
          BodyComponent={
            <>
              <form onSubmit={() => {}}>
                <div className="flex justify-content-between mb-2 mt-3 align-items-center">
                  <div>
                    <h3>
                      {isCloneOrEdit === ACTION.CREATE
                        ? 'Add Coverage'
                        : isCloneOrEdit === ACTION.ROW_EDIT
                          ? 'Edit Coverage'
                          : isCloneOrEdit === ACTION.ROW_CLONE
                            ? 'Clone Coverage'
                            : isCloneOrEdit === ACTION.GROUP_EDIT
                              ? 'Edit Coverage Combination'
                              : isCloneOrEdit === ACTION.GROUP_CLONE
                                ? 'Clone Coverage Combination'
                                : ''}
                    </h3>
                  </div>
                  <div className="flex">
                    <PFButton
                      label="Cancel"
                      onClick={() => {
                        if (formik.isValid && formik.dirty) {
                          setIsCancelConfirmationPopup(true);
                        } else {
                          setSelectedRecord(null);
                          setVisible(false);
                        }
                      }}
                      size="small"
                      outlined
                      className="m-2"
                      severity="primary"
                    />
                    <PFButton
                      label="Save "
                      size="small"
                      onClick={() =>
                        isRequiredConfirmation
                          ? setIsShowConfirmationPopup(true)
                          : (formik.submitForm(), setLoading(true))
                      }
                      loading={loading}
                      className="m-2"
                      severity="primary"
                      disabled={!formik.isValid || !formik.dirty}
                    />
                  </div>
                </div>

                <div className="grid my-2">
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropDown
                        value={formik?.values?.type || null}
                        optionLabel="project_type"
                        optionValue="project_type_id"
                        name="project_type"
                        options={typesList}
                        onChange={(name, value) => {
                          // formik.setFieldValue('type', value);
                          formik.setValues({
                            ...formik.values,
                            type: value,
                            differentCoverageBy: [],
                            differentCoverageValues: [],
                          });
                        }}
                        filter={true}
                        placeholder="Select a Type"
                        className="w-full h-40rem"
                        style={{ height: '40px' }}
                        pt={{
                          input: {
                            className: 'p-inputtext p-inputtext-sm ',
                          },
                        }}
                      />
                      <label htmlFor="client_timezone">Type</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-4 lg:col-4  py-0">
                    <span className="p-float-label">
                      {selectedRecord?.rowEdit || selectedRecord?.rowClone ? (
                        <PFDropDown
                          key="project_category_id"
                          value={formik.values.category?.[0] || null}
                          options={categoryList}
                          className="mr-3 w-full"
                          name="category"
                          filter={true}
                          optionLabel="category"
                          optionValue="project_category_id"
                          onChange={(name, value) => {
                            formik.setValues({
                              ...formik.values,
                              category: [value],
                              differentCoverageBy: [],
                              differentCoverageValues: [],
                            });
                          }}
                          style={{ height: '40px' }}
                        />
                      ) : (
                        <PFMultiSelect
                          value={formik?.values?.category || []}
                          optionLabel="category"
                          optionValue="project_category_id"
                          name="category"
                          maxSelectedLabels={1}
                          options={categoryList}
                          onChange={event => {
                            formik.setValues({
                              ...formik.values,
                              category: event?.value,
                              differentCoverageBy: [],
                              differentCoverageValues: [],
                            });
                          }}
                          filter={true}
                          // onFocus={displaySelectedCategory}
                          placeholder="Select a Category"
                          style={{ height: '40px' }}
                          className="w-full"
                          pt={{
                            input: {
                              className: 'p-inputtext p-inputtext-sm',
                            },
                          }}
                        />
                      )}

                      <label htmlFor="client_timezone">Category</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropDown
                        key="coverageTypeList"
                        value={formik.values.coverageBy || null}
                        options={coverageTypeList}
                        className="mr-3 w-full"
                        name="coverage_type"
                        optionLabel="label"
                        optionValue="id"
                        onChange={(name, value) => {
                          formik.setValues({
                            ...formik.values,
                            coverageBy: value,
                            coverageValues: [], // reset values selected when type change
                            differentCoverageBy: [],
                            differentCoverageValues: [],
                          });
                        }}
                        style={{ height: '40px' }}
                      />
                      <label htmlFor="client_timezone">Coverage Type</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-4 lg:col-12 mb-3 py-0">
                    <span className="p-float-label">
                      {formik.values.coverageBy === COVERAGE_TYPE.ZIP_CODE ? (
                        <>
                          <Chips
                            value={formik.values.coverageValues || []}
                            name="Coverage_value"
                            onChange={event => {
                              formik.setValues({
                                ...formik.values,
                                coverageValues: event.target.value,
                                differentCoverageBy: [],
                                differentCoverageValues: [],
                              });
                            }}
                            separator=","
                            className="w-12 p-0 h-auto"
                            pt={{
                              container: {
                                className: 'w-12 overflow-auto',
                              },
                              root: {
                                className: 'p-inputtext p-inputtext-sm',
                              },
                            }}
                          />
                          <span className="m-2 text-xs text-500">
                            Enter zip code in comma separated
                          </span>
                        </>
                      ) : (
                        <PFMultiSelect
                          value={formik.values.coverageValues || []}
                          maxSelectedLabels={8}
                          style={{ height: '40px' }}
                          filter={true}
                          options={
                            formik.values.coverageBy === COVERAGE_TYPE.STORE
                              ? storeList.map(store => ({
                                  label: `${store.store_number} - ${store.store_name}`,
                                  value: store.store_id,
                                  store_number: store.store_number,
                                }))
                              : formik.values.coverageBy ===
                                  COVERAGE_TYPE.DISTRICT
                                ? districtList?.map(dist => dist?.district)
                                : []
                          }
                          className="mr-3 w-12"
                          onChange={event => {
                            // formik.setFieldValue('coverageValues', event?.value);
                            formik.setValues({
                              ...formik.values,
                              coverageValues: event?.value,
                              differentCoverageBy: [],
                              differentCoverageValues: [],
                            });
                          }}
                          placeholder="Select coverage value"
                          pt={{
                            input: {
                              className: 'p-inputtext p-inputtext-sm',
                            },
                          }}
                        />
                      )}

                      <label htmlFor="client_timezone">Coverage Values</label>
                    </span>
                  </div>
                </div>
              </form>

              {/* &&
            formik.values?.coverageBy &&
            formik.values?.coverageValues?.length */}
              {formik.values.type && formik.values?.category?.length ? (
                <PreviewTable
                  data={prepareDataForPreview({
                    selectedValues: formik.values,
                    typesList,
                    categoryList,
                    COVERAGE_TYPE,
                    storeList,
                    districtList,
                    COVERAGE_VALUES,
                    selectedRecord,
                    differentCoverageBy: formik.values.differentCoverageBy,
                    differentCoverageValues:
                      formik.values.differentCoverageValues,
                  })}
                  isShowPreviousDetails={
                    false
                    // isCloneOrEdit == ACTION.GROUP_EDIT || isCloneOrEdit == ACTION.ROW_EDIT
                  }
                />
              ) : null}
              <Toast ref={toast} />
            </>
          }
        ></PFDialog>
      </div>
      <div className="coverage-dialog-child">
        <Dialog
          appendTo="self"
          baseZIndex={9999}
          header="Confirmation"
          visible={isShowConfirmationPopup}
          style={{ width: '450px' }}
          onHide={() => setIsShowConfirmationPopup(false)}
          footer={
            <div>
              <PFButton
                label="No"
                text
                outlined
                onClick={() => setIsShowConfirmationPopup(false)}
              />
              <PFButton
                label="Yes"
                onClick={() => {
                  setIsShowConfirmationPopup(false);
                  formik.submitForm();
                  setLoading(true);
                }}
              />
            </div>
          }
          draggable={false}
        >
          <div>
            Making a change at parent record will change all the values of its
            child records. Would you still like to continue?
          </div>
        </Dialog>
      </div>
      <div className="coverage-dialog-child">
        <Dialog
          appendTo="self"
          baseZIndex={9999}
          header="Warning"
          visible={isCancelConfirmationPopup}
          style={{ width: '450px' }}
          onHide={() => setIsCancelConfirmationPopup(false)}
          footer={
            <div>
              <PFButton
                label="No"
                text
                outlined
                onClick={() => setIsCancelConfirmationPopup(false)}
              />
              <PFButton
                label="Yes"
                onClick={() => {
                  setSelectedRecord(null);
                  setVisible(false);
                }}
              />
            </div>
          }
          draggable={false}
        >
          <div>
            All unsaved changes would be discarded. Would you like to continue ?
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default CoverageClone;
