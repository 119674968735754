import '../style.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';

import PFDataTable from '../PFPrime/PFDataTable';
import PFBreadCrumb from '../PFPrime/PFBreadCrumb';
import PFButton from '../PFPrime/PFButton';
import { getClientsResponse } from '../../ProjectManagement/Clients/Clients.service';
import { setQuoteHeader } from '../../../redux/slices/quote-header.slice';
import PFInputText from '../PFPrime/PFInputText';
import PFDropdown from '../PFPrime/PFDropdown';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

import { quoteStatus } from './components/QuoteInfo.model';
import { formatDate } from './Quote.model';
import { callQuoteList, columns, deleteQuote } from './QuoteListing.model';

const initDefaultFilter = {
  limit: 10,
  page: 1,
};

export const QuoteManagement = ({ action, clientId, rowLimit }) => {
  const toast = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultFilter = clientId
    ? { customerId: clientId, ...initDefaultFilter }
    : initDefaultFilter;
  if (rowLimit) defaultFilter.limit = rowLimit;
  const [filters, setFilters] = useState({
    quoteId: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    clientName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    expiryDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    followupDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [quoteListing, setQuoteListing] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyState, setLazyState] = useState(defaultFilter);
  const [isLoading, setLoading] = useState(true);
  const [isLoadList, setLoadList] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [initialClientsListData, setInitialClientsListData] = useState();
  const [clientsListData, setClientsListData] = useState();
  const [globalSearchKeyWord, setGlobalSearchKeyword] = useState('');

  //Helper to handle the Client Dropdown and global search
  let intervalVal = '';
  const handleFilterSearch = (searchInputVal, element) => {
    const searchString = searchInputVal?.trim();
    setGlobalSearchKeyword(searchString);
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      if (element == 'global') {
        searchInputVal
          ? setLazyState(prevState => ({
              ...prevState,
              globalSearch: searchInputVal,
            }))
          : setLazyState(current => {
              const { globalSearch, ...rest } = current;
              return rest;
            });
      } else {
        getClientsResponse(
          { limit: 50, offset: 0, searchString: searchString },
          setSearching,
          setClientsListData,
          () => null
        );
      }
    }, 1000);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={lazyState?.globalSearch}
            onChange={e => {
              handleFilterSearch(e.target.value, 'global');
            }}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const [dataTableParams, setDataTableParams] = useState({
    header: renderHeader(),
  });

  useEffect(() => {
    dispatch(
      setQuoteHeader({
        breadCrumbType: clientId ? 'Client' : 'Quote',
      })
    );
  }, []);

  useEffect(() => {
    callQuoteList({
      lazyState,
      setLoading,
      setTotalRecords,
      setQuoteListing,
      toast,
    });
  }, [lazyState, isLoadList]);

  useEffect(() => {
    getClientsResponse(
      { limit: 50, offset: 0 },
      setSearching,
      setInitialClientsListData,
      () => null
    );
  }, []);

  //Client Filter Dopdown
  const clientFilterTemplate = options => {
    return (
      <div className="flex flex-row justify-content-between align-items-center">
        <MultiSelect
          className="w-full"
          value={options?.value}
          options={clientsListData || initialClientsListData}
          onChange={e => {
            options.filterCallback(e?.value);
          }}
          optionLabel="customer_name"
          dataKey="customer_id"
          placeholder="Client Name"
          display="chip"
          filter
          onFilter={e =>
            e?.filter
              ? handleFilterSearch(e.filter, 'client')
              : setClientsListData(initialClientsListData)
          }
          style={{ height: '42px', alignItems: 'center', maxWidth: '250px' }}
        />
        {isSearching && (
          <ProgressSpinner
            style={{ width: '10px', height: '10px' }}
            aria-label="Searching"
          />
        )}
      </div>
    );
  };
  const statusFilterTemplate = options => {
    return (
      <MultiSelect
        className="w-full"
        value={options?.value}
        options={quoteStatus}
        onChange={e => {
          options.filterCallback(e?.value);
        }}
        optionLabel="name"
        dataKey="name"
        placeholder="Status"
        display="chip"
        style={{ height: '42px', alignItems: 'center', maxWidth: '250px' }}
      />
    );
  };
  const dateFilterTemplate = options => {
    const placeholder = {
      createDate: 'Create (YYYY-MM-DD)',
      expiryDate: 'Expiry (YYYY-MM-DD)',
      followupDate: 'Followup (YYYY-MM-DD)',
    }[options.field];
    return (
      <Calendar
        value={options.value}
        onChange={e => options.filterCallback(e.value)}
        dateFormat="yy-mm-dd"
        placeholder={placeholder || 'YYYY-MM-DD'}
      />
    );
  };
  const stringFilterTemplate = options => {
    const placeholder = {
      quoteId: 'Quote No',
      title: 'Title',
    }[options.field];
    return (
      <PFInputText
        type="text"
        value={options.value}
        onChange={e => options.filterCallback(e.target.value)}
        placeholder={placeholder || ''}
      />
    );
  };

  const onPage = event => {
    setLazyState({
      ...lazyState,
      limit: event?.rows,
      page: event?.page + 1,
    });
  };

  /*Helper for sorting*/
  const onSort = event => {
    const updatedParams = {
      ...dataTableParams,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    setDataTableParams(updatedParams);
    setLazyState({
      ...lazyState,
      sortBy: event.sortField,
      sortOrder: event.sortOrder == 1 ? 'ASC' : 'DESC',
    });
  };

  const handleDelete = id => {
    confirmDialog({
      message: `Do you want to delete this Quote.`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        deleteQuote({ quoteId: id, toast, setLoadList });
      },
      reject: () => {
        toast.current.show({
          severity: 'warn',
          summary: 'Rejected',
          detail: '',
        });
      },
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };
  // Helper to Apply the filters
  const applyFilter = props => {
    const conditions = {};
    for (const key in props?.filters || {}) {
      if (props.filters[key].value) {
        if ('clientName' === key) {
          props.filters[key].value.forEach(clientObj => {
            if (conditions?.customerId)
              conditions.customerId.push(clientObj.customer_id);
            else conditions.customerId = [clientObj.customer_id];
          });
        } else if ('status' === key) {
          props.filters[key].value.forEach(statusObj => {
            if (conditions?.status) conditions.status.push(statusObj.name);
            else conditions.status = [statusObj.name];
          });
        } else if (['quoteId', 'title'].includes(key))
          conditions[key] = props.filters[key].value;
        else if (['createDate', 'expiryDate', 'followupDate'].includes(key)) {
          conditions[key] = formatDate(props.filters[key].value);
        }
      }
    }
    setFilters(prev => {
      return {
        ...prev,
        ...(props?.filters || {}),
      };
    });
    const filterSort = {
      ...defaultFilter,
      sortOrder: props?.sortOrder === 1 ? 'ASC' : 'DESC',
    };
    if (props?.sortField) {
      filterSort.sortBy = props?.sortField;
    }
    if (globalSearchKeyWord) {
      filterSort.globalSearch = globalSearchKeyWord;
    }

    if (Object.keys(conditions)?.length === 0) {
      setLazyState(filterSort);
    } else {
      setLazyState({
        ...filterSort,
        ...conditions,
      });
    }
  };
  return (
    <div
      className={`grid w-12 quoteList ${
        action === 'add'
          ? 'border-solid border-1 border-200 border-noround'
          : ''
      }`}
    >
      <Toast ref={toast} />
      {action !== 'add' ? (
        <div className="col-12 md:col-12 lg:col-12 p-0 m-0">
          {/* <h2 className="m-1">Quote Management</h2> */}
          <div className="mb-2">
            {/* <PFBreadCrumb items={[{ label: 'Quote' }]} home={{ label: 'CRM' }} /> */}
          </div>
        </div>
      ) : (
        checkPermission(permissions?.crm?.addQuote) && (
          <div className="col-12 md:col-12 lg:col-12 p-0 m-0 flex flex-row justify-content-between  align-items-center flex-wrap">
            <h3 className="ml-3 "> Quotes </h3>
            <div className="m-1 ">
              <PFButton
                label="Add Quote"
                onClick={() =>
                  history.push(`/client/${clientId}/crm/quote/create`)
                }
                className="h-2rem mr-2 border-noround"
                outlined
              />
            </div>
          </div>
        )
      )}

      <Card className="w-full quote border-solid border-1 border-200 border-noround p-0 quoteListing">
        <PFDataTable
          lazy={true}
          loading={isLoading}
          dataKey="quote_id"
          columns={columns(
            clientFilterTemplate,
            dateFilterTemplate,
            stringFilterTemplate,
            statusFilterTemplate
          )}
          data={quoteListing}
          paginator={true}
          rows={lazyState.limit}
          rowsPerPageOptions={[5, 10, 50]}
          tableStyle={{ minWidth: '50rem' }}
          sortMode="multiple"
          stripedRows={true}
          onPage={onPage}
          first={(lazyState.page - 1) * lazyState.limit}
          totalRecords={totalRecords}
          handleDelete={handleDelete}
          onSort={onSort}
          dataTableParams={dataTableParams}
          filters={filters}
          onFilter={applyFilter}
        />
        <ConfirmDialog />
      </Card>
    </div>
  );
};
