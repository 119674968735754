import GenericTable from '../../shared/GenericTable/GenericTable';

const columnData = [
  {
    id: 'installer',
    value: 'Technician',
  },
  {
    id: 'type',
    value: 'Type',
  },
  {
    id: 'scheduleDate',
    value: 'Schedule Date and Time',
  },
  {
    id: 'projectEndDate',
    value: 'Project End Date and Time',
  },
  {
    id: 'installer_arrival_start_time',
    value: 'Arrival Start Time',
  },
  {
    id: 'installer_arrival_end_time',
    value: 'Arrival End Time',
  },
  {
    id: 'laborAmount',
    value: 'Labor Amount',
  },
];

const InstallerTable = ({
  permissions,
  rowData,
  handleAddInstaller,
  viewEditInstaller,
  deleteInstaller,
  handleCloneInstaller,
  schedulerEditPermission,
}) => {
  return (
    <GenericTable
      title="Technicians"
      headerLinks={[
        {
          label: 'Add Technician',
          add: schedulerEditPermission
            ? permissions?.viewEditProject?.actionEditScheduler
            : permissions?.viewEditProject?.addInstaller,
          handler: handleAddInstaller,
        },
      ]}
      columnData={columnData}
      rowData={rowData}
      showActions={{ view: true, edit: true, delete: true, clone: true }}
      handleView={index => viewEditInstaller('view', index)}
      handleEdit={index => viewEditInstaller('edit', index)}
      handleDelete={index => deleteInstaller(index)}
      handleClone={index => {
        handleCloneInstaller('clone', index);
      }}
      permissions={{
        view: permissions?.viewEditProject?.viewInstaller,
        edit: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.editInstaller,
        delete: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.deleteInstaller,
        clone: schedulerEditPermission
          ? permissions?.viewEditProject?.actionEditScheduler
          : permissions?.viewEditProject?.addInstaller,
      }}
    />
  );
};

export default InstallerTable;
