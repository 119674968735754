export const WidgetUrl = {
  project: {
    view: id => `/project/view/${id}`,
    edit: id => `/project/edit/${id}`,
  },

  quote: {
    view: (customerId, opportunityId, id) =>
      `/crm/${customerId}/${opportunityId}/quote/view/${id}`,
    edit: (customerId, opportunityId, id) =>
      `/crm/${customerId}/${opportunityId}/quote/edit/${id}`,
  },

  chargeback: {
    projectChargebackViewUrl: secondaryId => `/project/view/${secondaryId}`,
    projectChargebackEditUrl: additionalDetails =>
      `/project/edit/${additionalDetails}`,
    chargebackViewUrl: id => `/admin/chargeback/view/chargeback/${id}`,
    chargebackEditUrl: id => `/admin/chargeback/edit/chargeback/${id}`,
  },
};
