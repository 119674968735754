import React, { useState, useEffect, useRef } from 'react';
import { Card, Divider } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import Calendar from '../../../shared/PFPrime/PFCalendar';
import { formatDateTime } from '../../../../utils/Helpers';
import ApiService from '../../../../services/api.service';

import SMSList from './SMSList';
import { SMSManagement } from './SMSListing.view';

const SMSComponent = ({
  hideForm,
  customerId,
  communicationType,
  communicationId,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  isVersionActive,
}) => {
  const [reload, setReload] = useState(false);
  return (
    <div className="p-3">
      <SMSList
        customerId={customerId}
        reload={reload}
        communicationType={communicationType}
        communicationId={communicationId}
        setReload={setReload}
        opportunityId={opportunityId}
        quoteId={quoteId}
        invoiceId={invoiceId}
        paymentId={paymentId}
        isVersionActive={isVersionActive}
      />
    </div>
  );
};

export default SMSComponent;
