// **** React Imports ****
import React from 'react';
import { useHistory } from 'react-router-dom';

// **** Utilities ****
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components *****
import { getName } from 'domutils';

import GenericDialog from '../../shared/Dialog/GenericDialog';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

import {
  createDashboard,
  cloneDashboard,
  editDashboard,
} from './Dashboards.service';
// **** Styles *****
import { useStyles } from './DashboardList.styles';

const CreateDashboardDialog = ({
  setDialogOpen,
  dialogOpen,
  cloneValues,
  isClone,
  isEdit,
  setIsClone,
  setIsEdit,
  setReloadList,
}) => {
  const [loading, setLoading] = React.useState(false);
  const dialogSettings = {
    title: (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          {isEdit ? 'Edit Dashboard' : 'Create Dashboard'}
        </Grid>
        {loading && (
          <Grid container item xs={6} justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: isEdit ? 'Edit' : 'Create',
    showButton1: true,
    showButton2: true,
  };

  const history = useHistory();
  const classes = useStyles();
  // To show the message
  const { alert, setAlert, clearAlert } = useAlerts();

  // **** Form Validation Schema ****
  const CreateDashboardSchema = Yup.object().shape({
    name: Yup.string().trim().required('Required'),
    description: Yup.string().trim().required('Required'),
  });

  React.useEffect(() => {
    clearAlert();
  }, [dialogOpen]);

  const getName = () => {
    let name = null;
    if (isClone) {
      name = `${cloneValues?.name} Copy`;
    } else if (isEdit) {
      name = `${cloneValues?.name}`;
    }
    return name;
  };

  const getDescription = () => {
    let description = null;
    if (isClone) {
      description = `${cloneValues.description} Copy`;
    } else if (isEdit) {
      description = `${cloneValues.description}`;
    }
    return description;
  };

  const createDashboardFormik = useFormik({
    initialValues: {
      name: getName(),
      description: getDescription(),
      private: isClone || isEdit ? cloneValues.private : 0,
    },
    onSubmit: values => {
      if (isClone) {
        cloneDashboard(
          cloneValues?.dashboard_id,
          {
            ...values,
            name: values?.name.trim() || null,
            description: values?.description.trim() || null,
          },
          setLoading,
          setIsClone,
          setDialogOpen,
          setReloadList,
          setAlert
        );
      } else if (isEdit) {
        editDashboard(
          cloneValues?.dashboard_id,
          values,
          setLoading,
          setIsEdit,
          setDialogOpen,
          setReloadList,
          setAlert
        );
      } else {
        createDashboard(
          {
            ...values,
            name: values?.name.trim() || null,
            description: values?.description.trim() || null,
          },
          history,
          setLoading,
          setAlert
        );
      }
    },
    validationSchema: CreateDashboardSchema,
    enableReinitialize: true,
  });

  return (
    <GenericDialog
      fullwidth
      isOpen={dialogOpen}
      handleClose={() => {
        setDialogOpen(false);
        setIsClone(false);
        setIsEdit(false);
        clearAlert();
        createDashboardFormik.resetForm();
      }}
      disabledButton2={
        !isClone &&
        (!createDashboardFormik.dirty || !createDashboardFormik.isValid)
      }
      dialogSettings={dialogSettings}
      handleSave={() => createDashboardFormik.handleSubmit()}
    >
      <Grid container spacing={2} direction="column">
        {alert?.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid item>
          <TextField
            label="Name"
            id="name"
            value={createDashboardFormik?.values?.name || ''}
            InputLabelProps={{ shrink: true }}
            onChange={createDashboardFormik.handleChange}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            label="Description"
            id="description"
            value={createDashboardFormik?.values?.description || ''}
            InputLabelProps={{ shrink: true }}
            onChange={createDashboardFormik.handleChange}
            required
          />
        </Grid>
        <Grid item>
          {' '}
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkBoxRoot,
                }}
                onChange={(event, value) => {
                  if (value) {
                    createDashboardFormik.setFieldValue('private', 1);
                  } else {
                    createDashboardFormik.setFieldValue('private', 0);
                  }
                }}
                checked={
                  createDashboardFormik?.values?.private === 1 ? true : false
                }
              />
            }
            label="Private"
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default CreateDashboardDialog;
