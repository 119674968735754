import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sources: [],
};

const opportunitySourceMasterSlice = createSlice({
  name: 'opportunitySourceMaster',
  initialState: initialState,
  reducers: {
    setOpportunitySource(state, { payload }) {
      state.sources = payload;
    },
  },
});

export const { setOpportunitySource } = opportunitySourceMasterSlice.actions;

export const opportunitySourceReducer = opportunitySourceMasterSlice.reducer;
