import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { PFDataTable } from '../pf-prime/Prime';

import unitConverter from './helper';
import SkeletonLoader from './Loader/skeleton';
import usePFDataTable from '../../shared/PFPrime/PFDatatable.jsx';
import { Height } from '@material-ui/icons';
const WarehouseDetails = () => {
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
    setRowsPerPageOptions,
  } = usePFDataTable();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  let { itemInfo: productDetails } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  let { listOfWarehouses, isLoading } = useSelector(
    state => state.inventoryManagment?.Warehouse
  );
  const [binList, setBinList] = useState([]);

  useEffect(() => {
    const baseUnitId = units?.find(unit => unit.isLowest === 1)?.unitId;
    const warehouseList = listOfWarehouses?.map(item => {
      const rowObject = {};
      const binLabel = item?.binCode ? `${item?.binCode}-${item?.binName}` : '';
      setBinList(preValue => {
        if (item?.binCode) {
          return [
            ...(preValue || []),
            { binCode: item?.binCode, label: binLabel },
          ];
        }
      });

      rowObject['name'] = item?.warehouseName;
      rowObject['binName'] = binLabel;
      rowObject['available'] =
        item.availableQuantity && units.length > 0
          ? unitConverter(item?.availableQuantity, units)
          : `0.00`;
      rowObject['stock'] =
        item.actualQuantity && units.length > 0
          ? unitConverter(item?.actualQuantity, units)
          : `0.00`;
      rowObject['allocated'] =
        item.allottedQuantity && units.length > 0
          ? unitConverter(item?.allottedQuantity, units)
          : 0;
      rowObject['damaged'] =
        item.damagedQuantity && units.length > 0
          ? unitConverter(item?.damagedQuantity, units)
          : 0;
      rowObject['lost'] =
        item.lostQuantity && units.length > 0
          ? unitConverter(item?.lostQuantity, units)
          : 0;
      rowObject['sold'] =
        item.soldQuantity && units.length > 0
          ? unitConverter(item?.soldQuantity, units)
          : 0;
      rowObject['delivered'] =
        item.deliveredQuantity && units.length > 0
          ? unitConverter(item?.deliveredQuantity, units)
          : 0;
      return rowObject;
    });
    tableData(warehouseList);
    totalRecords(warehouseList?.length || 0);
    setLoading(isLoading);
  }, [listOfWarehouses]);

  const columns = [
    {
      field: 'name',
      header: 'Warehouse',
      body: data => <strong>{data?.name}</strong>,
      className: 'bg-white',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
    },
    {
      field: 'binName',
      header: 'Bin',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
      body: body => body.binName || 'Total',
    },
    { field: 'available', header: 'On Hand' },
    { field: 'stock', header: 'Available' },
    { field: 'allocated', header: 'Allocated' },
    { field: 'damaged', header: 'Damaged' },
    { field: 'lost', header: 'Lost' },
    { field: 'delivered', header: 'Dispatched' },
  ];

  const rowClassName = rowData => {
    if (!rowData?.binName) {
      return 'bg-blue-100';
    }
  };

  useEffect(() => {
    setDefaultTableProps(preValue => ({
      ...preValue,
      rowGroupMode: 'rowspan',
      groupRowsBy: 'name',
      sortMode: 'single',
      sortOrder: 1,
      stripedRows: false,
      paginator: false,
      scrollable: true,
      scrollHeight: '540px',
      className: 'border-200',
      rowClassName: rowClassName,

      pt: {
        table: {
          className: 'xl',
        },
        bodyrow: {
          className: 'inventory-body-row-height',
        },
        thead: {
          className: 'inventory-thead-height',
        },
        filteroverlay: {
          className: 'inventory-filter-z-index',
        },
      },
      paginator: false,
      lazy: false,
      paginatorTemplate:
        'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
      rows: 7,
    }));
    DataTableColumns(columns);
  }, []);

  useEffect(() => {
    let { unit } = productDetails;

    const units = _.cloneDeep(unit);
    units?.map(item => {
      item['parentId'] = item.pId;
      item['fractionAble'] = false;
    });
    setUnits(units);
  }, [productDetails]);

  return (
    <div className="">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className="border-200">
          <TableLayout />
        </div>
      )}
    </div>
  );
};

export default WarehouseDetails;
