// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get roles List
export const getRolesList = async (
  searchQuery,
  setLoading,
  setRolesData,
  setRolesCount
) => {
  try {
    setLoading(true);

    const newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/list?${newQueryString}`
    );
    setRolesData(response?.data?.items);
    setRolesCount(response?.data?.count);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Create new role
export const createNewRole = async (
  createObj,
  setLoading,
  setAlert,
  setReloadForm,
  setOpen
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role`,
      createObj
    );

    if (response?.data?.status) {
      setReloadForm(true);
      setOpen(false);
      setAlert('success', 'Role added successfully.');
    } else {
      setAlert('error', response?.data?.message);
    }
  } catch (error) {
    setAlert('error', 'Failed to create a new role');
  } finally {
    setLoading(false);
  }
};

//clone role
export const cloneRole = async (
  roleId,
  formValues,
  setLoading,
  setAlert,
  setReloadList,
  setIsOpen
) => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/${roleId}/clone`,
      formValues
    );
    if (response?.data?.status) {
      setReloadList(true);
      setAlert('success', 'Role cloned successfully.');
      setIsOpen(false);
    } else {
      setAlert('error', response?.data?.message);
    }
  } catch (error) {
    setAlert('error', 'Error while cloning Role.', false, true);
  } finally {
    setLoading(false);
  }
};

//delete role
export const deleteRoleById = async (
  deleteData,
  setLoading,
  setReloadList,
  setAlert
) => {
  setLoading(true);
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/auth/role/${deleteData}`
    );
    if (response?.data?.status) {
      setReloadList(true);
      setAlert('success', 'Role deleted successfully');
    } else {
      setAlert(
        'error',
        'Unable to Delete, as there are active users assigned to this Role.'
      );
    }
  } catch (error) {
    setAlert(
      'error',
      'Unable to Delete, as there are active users assigned to this Role.',
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};
