import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

const PFAutoComplete = ({
  value,
  suggestions,
  selectedValue,
  name,
  className,
  getSuggestions,
  inputId,
  placeholder,
}) => {
  const [val, setVal] = useState(value);
  const [items, setItems] = useState(suggestions);

  const search = event => {
    getSuggestions(event.query);
  };

  useEffect(() => {
    setItems(suggestions);
  }, [suggestions]);

  useEffect(() => {
    const index = suggestions?.indexOf(val);
    if (val && index !== -1) {
      selectedValue(index);
    }
  }, [val]);

  return (
    <AutoComplete
      name={name || ''}
      value={val || ''}
      suggestions={items || []}
      completeMethod={search}
      onChange={e => setVal(e.value)}
      className={`w-full p-inputtext-sm ${className || ''}`}
      inputId={inputId}
      placeholder={placeholder || ''}
    />
  );
};

export default PFAutoComplete;
