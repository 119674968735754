// **** React Imports ****
import { BrowserRouter } from 'react-router-dom';

// **** External Utilities ****
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';

// **** Custom Components ****
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import App from './App';
import { AlertsProvider } from './components/shared/Alerts/alertsService';
import { store } from './redux';

// **** Styles/Images/Icons ****
import { Theme } from './utils/theme-palette/Theme';

const AppWrapper = () => {
  const persistor = persistStore(store);
  return (
    <>
      {/*i To normalize the styling */}
      <CssBaseline />
      <MuiThemeProvider theme={Theme}>
        <DndProvider backend={HTML5Backend}>
          <AlertsProvider>
            <BrowserRouter>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <App />
                </PersistGate>
              </Provider>
            </BrowserRouter>
          </AlertsProvider>
        </DndProvider>
      </MuiThemeProvider>
    </>
  );
};

export default AppWrapper;
