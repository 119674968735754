import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  aestrik: {
    color: theme.palette.error.main,
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '46%',
    },
    [theme.breakpoints.up('md')]: {
      width: '22%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '23%',
    },
  },
  formEdit: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #92A9BE',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #92A9BE',
    },
    '& .MuiInput-underline.Mui-focused': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  installationText: {
    paddingTop: '10px',
  },
  disableSection: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  iconBtn: {
    minWidth: '45px',
    maxWidth: '45px',
    '& .MuiButton-startIcon': {
      margin: '0px',
      '& svg': {
        fill: '#ffffff',
      },
    },
    '&.Mui-disabled': {
      '& svg': {
        fill: '#b8bbbd !important',
      },
    },
  },
  noDropdownIcon: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
}));
