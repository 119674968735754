import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

// import { addServiceTimeById } from '../service/ServiceTimeConfigService';
import PFButton from '../../shared/PFPrime/PFButton';

import { UpdateServiceTime } from './services/user-form-job-assign-coverage.service';
const ServiceTimeTemp = ({
  user,
  userJobCoverageId,
  serviceTime,
  row,
  projectTypeResult,
  toast,
}) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [durationValue, setDurationValue] = useState(row?.service_time);
  const callAddServiceTimeById = async () => {
    setLoading(true);
    try {
      const payload = {
        service_time: durationValue || 0,
      };
      const response = await UpdateServiceTime(
        user,
        userJobCoverageId,
        payload
      );
      if (response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `Service time updated successfully`,
          life: 3000,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDurationChange = async () => {
    await callAddServiceTimeById();
    setIsEditEnabled(false);
  };
  return (
    <span className="flex align-items-center w-full">
      {isEditEnabled ? (
        <div className="p-inputgroup flex-1">
          <span className="p-float-label flex align-items-center">
            <InputNumber
              autoFocus
              className="w-full p-inputtext-sm"
              inputClassName="border-noround-right"
              value={durationValue}
              onChange={e => setDurationValue(e.value)}
              disabled={loading}
              min={0}
              id="in_minutes"
              name="in_minutes"
            />
            <label htmlFor="in_minutes">in minutes</label>
          </span>
          <PFButton
            icon={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-check'}`}
            iconPos="left"
            tooltip="Save"
            tooltipOptions={{ position: 'top' }}
            onClick={handleDurationChange}
            disabled={loading || row?.service_time == durationValue}
          />
        </div>
      ) : (
        <div
          className={`pl-2 pt-1 align-items-center w-9rem border-1 border-200 hover:border-500 border-round h-2rem cursor-text ${
            loading ? 'pointer-events-none' : ''
          }`}
          onClick={() => setIsEditEnabled(true)}
          onFocus={() => setIsEditEnabled(true)}
          disabled={loading}
        >
          {durationValue ? (
            <>
              {durationValue}
              {durationValue && durationValue > 1 ? ' m' : ''}
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </span>
  );
};
export default ServiceTimeTemp;
