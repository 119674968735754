import jwt_decode from 'jwt-decode';

import { WidgetUrl } from './Constants/Constants';

export const filterDropdownFilters = (filters = []) =>
  filters.filter(
    ele => Object.keys(ele).includes('and') || Object.keys(ele).includes('or')
  );

export const setFiltersForWidgetType = (widgetType, filters) => {
  const customfilters = filterDropdownFilters(filters);
  switch (widgetType) {
    case 'user': {
      return [
        ...customfilters,
        {
          member: 'ProjectType.project_type_id',
          dynamicValue: 'type_coverage_ids',
          operator: 'equals',
        },
        {
          member: 'Store.store_id',
          dynamicValue: 'store_coverage_ids',
          operator: 'equals',
        },
        {
          member: 'ProjectCategory.project_category_id',
          dynamicValue: 'catergory_coverage_ids',
          operator: 'equals',
        },
      ];
    }
    case 'company': {
      return [
        ...customfilters,
        {
          subQuery: {
            filters: [
              {
                member: 'UserMeta.companyname',
                dynamicValue: 'companyname',
                operator: 'equals',
              },
            ],
            dimensions: [
              'UserMeta.store_coverage_ids',
              'UserMeta.type_coverage_ids',
              'UserMeta.catergory_coverage_ids',
            ],
          },
          fieldMapping: {
            'UserMeta.store_coverage_ids': 'Store.store_id',
            'UserMeta.type_coverage_ids': 'ProjectType.project_type_id',
            'UserMeta.catergory_coverage_ids':
              'ProjectCategory.project_category_id',
          },
        },
      ];
    }
    case 'installer': {
      return [
        ...customfilters,
        {
          subQuery: {
            filters: [
              {
                member: 'InstallerDetail.user_id',
                dynamicValue: 'user_id',
                operator: 'equals',
              },
            ],
            dimensions: ['InstallerDetail.installer_id'],
          },
          fieldMapping: {
            'InstallerDetail.installer_id': 'ProjectInstaller.installer_id',
          },
        },
      ];
    }
    default: {
      return customfilters;
    }
  }
};

export const addDefaultCondition = {
  Opportunity: ['Opportunity.customer_primary_key'],
  Quotes: ['Quotes.customer_primary_key', 'Quotes.opportunity_id'],
};
