import React, { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';

import PageHeader from '../../shared/PageHeader/PageHeader';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDialog from '../../shared/PFPrime/PFDialog';
import { dateFormatter, convertToQueryParams } from '../../../utils/Helpers';
import {
  ExportedReportsStatus,
  PayrollReportFilters,
} from '../../../constants';

import {
  deleteExportedReport,
  downlaodPayrollReport,
  getExportedReportsList,
} from './services/ExportedReports.service';

const reportBreadcrumb = () => {
  return [
    {
      text: 'Admin',
    },

    {
      text: `Exported Reports`,
    },
  ];
};

const ReportContainer = () => {
  const [searchString, setSearchString] = React.useState({
    offset: 0,
    limit: 5,
    page: 1,
  });
  const [first, setFirst] = React.useState(0);
  const [reportDataResponse, setReportDataResponse] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useRef(null);
  const [isdownloading, setIsDownloading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [filterData, setFilterData] = React.useState();

  const showFilterDialog = val => {
    setVisible(true);
    let isFirst = true;
    let html = [];
    if (val && Object.keys(val)?.length) {
      for (const [key, value] of Object.entries(val)) {
        if (isFirst) {
          html.push(
            <div className="flex mb-2">
              <div className="text-primary w-10rem">
                {PayrollReportFilters?.find(val => val?.key === key)?.value ||
                  key}
                :
              </div>
              <div>{value === 1 ? 'True' : value === 0 ? 'False' : value}</div>
            </div>
          );
          isFirst = false;
        } else {
          html.push(
            <div className="flex mb-2">
              <div className="text-primary w-10rem">
                {PayrollReportFilters?.find(val => val?.key === key)?.value ||
                  key}
                :
              </div>
              <div>{value === 1 ? 'True' : value === 0 ? 'False' : value}</div>
            </div>
          );
        }
      }
    } else {
      html = [];
    }
    setFilterData(html);
  };

  const tableColumns = [
    {
      field: 'action_buttons',
      header: 'Actions',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.action_buttons;
      },
    },
    {
      field: 's_no',
      header: 'S.No',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.s_no;
      },
    },
    {
      field: 'report_request_id',
      header: 'Report Request Id',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.report_request_id;
      },
    },
    {
      field: 'report_name',
      header: 'Report Name',
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          rowData?.report_data_file_path &&
            decodeURI(rowData?.report_data_file_path?.split('/')?.slice(-1))
        );
      },
    },

    {
      field: 'date_range',
      header: 'Report Date Range',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.date_range;
      },
    },
    {
      field: 'created_date',
      header: 'Report Created On',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.created_date;
      },
    },
    {
      field: 'created_by',
      header: 'Report Created By',
      style: { 'text-transform': 'capitalize' },
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.created_by;
      },
    },
    {
      field: 'filters',
      header: 'Report Filters',
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.filters;
      },
    },
    {
      field: 'status',
      header: 'Report Status',
      body: rowData => {
        let className = ExportedReportsStatus?.find(
          val => val?.key === rowData?.status
        )?.className;
        return isLoading ? (
          <Skeleton />
        ) : (
          <div className={className}>{rowData?.status}</div>
        );
      },
    },
  ];
  // To fetch exported reports list
  const fetchInitialValues = async (event = null) => {
    try {
      setIsLoading(true);
      let searchStringVal = convertToQueryParams(searchString);
      let response = await getExportedReportsList(searchStringVal);
      if (response?.data?.length) {
        setReportDataResponse(response);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchInitialValues();
  }, [searchString]);

  // Delete selected data
  const handleDelete = async val => {
    let deleteResponse;
    setIsLoading(true);

    try {
      deleteResponse = await deleteExportedReport(val);
      if (deleteResponse?.data) {
        toast.current.show({
          severity: 'success',
          summary: 'Record deleted successfully.',
          life: 2000,
        });
        fetchInitialValues();
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
        return;
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadReport = async value => {
    try {
      setIsDownloading(true);
      let response = await downlaodPayrollReport(value);

      if (response?.fileURL) {
        const payRollFile = response?.fileURL;
        const link = document.createElement('a');
        link.href = payRollFile;
        link.target = '_blank';
        link.download = 'file.xls';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.current.show({
          severity: 'success',
          summary: 'Report downloaded successfully.',
          life: 2000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const deleteReport = async e => {
    confirmDialog({
      message: 'Please confirm if you would like to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDelete(e),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const tableData = reportDataResponse?.data?.map((val, key) => {
    return {
      s_no: key + 1,
      action_buttons: (
        <div className="flex flex-row" style={{ gap: '10px' }}>
          <i
            className={`pi pi-download  ${
              val?.report_history_status === 'DONE' &&
              'cursor-pointer text-primary'
            }`}
            onClick={e =>
              val?.report_history_status === 'DONE'
                ? downloadReport(val?.report_data_file_path)
                : e?.preventDefault()
            }
          ></i>

          <i
            className="pi pi-trash cursor-pointer"
            onClick={() => deleteReport(val?.report_history_id)}
          ></i>
        </div>
      ),
      report_data_file_path: val?.report_data_file_path,
      date_range: `${dateFormatter(val?.date_from, 'MM/DD/YYYY')} - ${dateFormatter(
        val?.date_to,
        'MM/DD/YYYY'
      )}`,
      created_date: dateFormatter(val?.created_at, 'MM/DD/YYYY, hh:mm A'),
      created_by: val?.report_created_by,
      filters: (
        <a
          className="text-primary cursor-pointer"
          onClick={e => showFilterDialog(val?.report_filter?.filter)}
        >
          View
        </a>
      ),
      status: val?.report_history_status,
      report_history_id: val?.report_history_id,
      report_request_id: val?.report_request_id,
    };
  });

  return (
    <>
      <Toast ref={toast} />

      <div className="col-6 md:col-6 lg:col-6">
        <PageHeader
          pageTitle={`Exported Reports`}
          breadCrumbArray={reportBreadcrumb()}
        />
      </div>
      <div className="flex col-12 gap-2  justify-content-end pb-3">
        <PFButton icon="pi pi-refresh" onClick={() => fetchInitialValues()} />
      </div>
      {isdownloading && (
        <div className="grid grid-nogutter w-full">
          <ProgressBar
            mode="indeterminate"
            style={{ width: '100rem', height: '12px' }}
          ></ProgressBar>
        </div>
      )}
      <div className="grid grid-nogutter w-full mt-3">
        <PFDataTable
          paginator
          rows={searchString?.limit}
          rowsPerPageOptions={[5, 10, 25, 50]}
          columns={tableColumns}
          data={tableData || []}
          stripedRows
          dataKey="id"
          lazy={true}
          tableStyle={{ minWidth: '50rem' }}
          stripedRows={true}
          totalRecords={reportDataResponse?.count}
          first={first}
          onPage={event => {
            setSearchString(prev => ({
              ...prev,
              limit: event?.rows,
              page: event?.page + 1,
            }));
            setFirst(event?.first);
          }}
        />
      </div>
      <ConfirmDialog />
      <PFDialog
        show={visible}
        hide={setVisible}
        header="Report Filters"
        BodyComponent={filterData}
        style={{ width: '30vw' }}
      />
    </>
  );
};

export default ReportContainer;
