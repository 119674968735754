import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setPhoneType } from '../redux/slices/phone-type.slice';
import { CONFIG } from '../constants';

const usePhoneCategory = () => {
  const dispatch = useDispatch();
  const { phoneType } = useSelector(state => state?.masters?.phoneTypeReducer);
  const [phoneCategory, setValue] = useState(phoneType);

  const getPhoneTypes = async () => {
    try {
      if (phoneCategory.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_BASE_URL}/system/phone-type`
        );
        setValue(response?.data);
        dispatch(setPhoneType(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPhoneTypes();
  }, []);

  return { phoneCategory };
};

export default usePhoneCategory;
