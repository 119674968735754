export const MyQuillToolbar = () => {
  return (
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>

      <button className="ql-list" value="ordered" title="Ordered List"></button>
      <button
        className="ql-list"
        value="bullet"
        title="Unordered List"
      ></button>
      <button className="ql-indent" value="-1" title="Decrease Indent"></button>
      <button className="ql-indent" value="+1" title="Increase Indent"></button>
      <select className="ql-size" title="Size">
        <option value="small"></option>
        <option value=""></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
      <select className="ql-header" title="Header" defaultValue="">
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
        <option value="6"></option>
      </select>
      <select className="ql-color" title="Color"></select>
      <select className="ql-background" title="Background"></select>
      <select className="ql-align" title="Align"></select>
      <button className="ql-link" title="Insert Link"></button>
      <button className="ql-clean" title="Remove Formatting"></button>
    </span>
  );
};
