import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  routingFormik: null,
  isRoutingFormValid: false,
};

const userFormRoutingSlice = createSlice({
  name: 'userFormRouting',
  initialState: initialState,
  reducers: {
    setUserRoutingForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.routingFormik = payload.hasOwnProperty('routingFormik')
        ? payload.routingFormik
        : state.routingFormik;
      state.isRoutingFormValid = payload.hasOwnProperty('isRoutingFormValid')
        ? payload.isRoutingFormValid
        : state.isRoutingFormValid;
    },
  },
});

export const { setUserRoutingForm } = userFormRoutingSlice.actions;

export const userFormRoutingReducer = userFormRoutingSlice.reducer;
