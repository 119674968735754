// **** React Imports ****
import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// **** External Utilities ****
import { Grid } from '@material-ui/core';

import { formatDate } from '../../../utils/Helpers';
// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';

const ClientProjects = ({ projects, clientId }) => {
  let history = useHistory();
  let projectData =
    projects &&
    projects?.map(project => {
      return {
        project_id: { value: project.project_id },
        project_number: { value: project.project_number },
        po_number: { value: project.po_number },
        invoice_number: { value: project.invoice_number },
        project_type: { value: project.project_type?.project_type },
        status: { value: project?.status?.status },
        rts_follow_up_date: {
          value: project.rts_follow_up_date
            ? formatDate(project.rts_follow_up_date)
            : null,
        },
        date_sold: {
          value: project.date_sold ? formatDate(project.date_sold) : null,
        },
        date_scheduled: {
          value: project.date_scheduled_start
            ? formatDate(project.date_scheduled_start)
            : null,
        },
        completion_date: {
          value: project.completion_date
            ? formatDate(project.completion_date)
            : null,
        },
        window_start: {
          value: project.date_scheduled_start
            ? moment(project.date_scheduled_start).format('MM-DD-YYYY hh:mm A')
            : null,
        },
        window_end: {
          value: project.date_scheduled_end
            ? moment(project.date_scheduled_end).format('MM-DD-YYYY hh:mm A')
            : null,
        },
        store: { value: project?.store?.store_number },
      };
    });

  const projectsColumns = [
    {
      id: 'project_number',
      value: 'Project#',
    },
    {
      id: 'po_number',
      value: 'PO#',
    },
    {
      id: 'store',
      value: 'Store#',
    },
    {
      id: 'invoice_number',
      value: 'Invoice#',
    },
    {
      id: 'project_type',
      value: 'Type',
    },
    {
      id: 'status',
      value: 'Status',
    },
    {
      id: 'rts_follow_up_date',
      value: 'RTS Follow up Date',
    },
    {
      id: 'date_sold',
      value: 'Date Sold',
    },
    {
      id: 'date_scheduled',
      value: 'Date Scheduled',
    },
    {
      id: 'completion_date',
      value: 'Completion Date',
    },
    {
      id: 'window_start',
      value: 'Schedule Date and Time',
    },
    {
      id: 'window_end',
      value: 'Project End Date and Time',
    },
  ];

  return (
    <>
      {projectData && (
        <Grid container spacing={4} direction="column">
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <GenericTable
              title="Projects"
              headerLinks={[
                {
                  label: 'Add Project',
                  handler: () =>
                    history.push(`/project/add?clientId=${clientId}`),
                },
              ]}
              columnData={projectsColumns}
              rowData={projectData}
              showActions={{ view: true, edit: true }}
              handleView={index => {
                window.open(
                  `/project/view/` + projectData[index].project_id.value,
                  '_blank'
                );
              }}
              handleEdit={index =>
                history.push(
                  `/project/edit/` + projectData[index].project_id.value
                )
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ClientProjects;
