// **** React Imports ****
import React from 'react';

// **** Utilities ****
import { Grid, TextField, CircularProgress, Box } from '@material-ui/core';
import moment from 'moment';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';

// **** Services *****
import { getActivitiesData } from './Activities.service';

// **** Styles *****
import { useStyles } from './Activities.styles.js';

const activityTableColumns = [
  {
    id: 'date_created',
    value: 'Date Created',
    width: '14%',
  },
  {
    id: 'date_activity_created',
    value: 'Date Activity Created',
    width: '14%',
  },
  {
    id: 'activity_type',
    value: 'Activity Type',
    width: '14%',
  },
  {
    id: 'activity_due_date',
    value: 'Activity Due Date',
    width: '14%',
  },
  {
    id: 'job_number',
    value: 'Job Number',
    width: '14%',
  },
  {
    id: 'status',
    value: 'Status',
    width: '14%',
  },
];

const Activities = ({ formik }) => {
  const classes = useStyles();

  const [activityResponse, setActivityResponse] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  let activityDisplayList = [];

  React.useEffect(() => {
    if (formik?.values?.project_id)
      getActivitiesData(
        formik?.values?.project_id,
        setLoading,
        setActivityResponse
      );
  }, [formik?.values?.project_id]);

  // **** Data Modification to Display *****
  if (activityResponse?.items?.length) {
    activityDisplayList = activityResponse.items.map(val => {
      return {
        date_created: {
          value:
            val.created_at &&
            moment(val.created_at).format('MM-DD-YYYY hh:mm A'),
        },
        date_activity_created: {
          value:
            val.activity_created_date &&
            moment(val.activity_created_date).format('MM-DD-YYYY hh:mm A'),
        },
        activity_type: {
          value: val.activity_type,
        },
        activity_due_date: {
          value:
            val.activity_due_date &&
            moment(val.activity_due_date).format('MM-DD-YYYY hh:mm A'),
        },
        job_number: {
          value: val.job_number,
        },
        status: {
          value: val.activity_status,
        },
      };
    });
  }

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={4} direction="column">
        <Grid item>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {/* Activity Type */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Activity Type"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={activityDisplayList[0]?.activity_type?.value || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Date Activity Created */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Date Activity Created"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  activityDisplayList[0]?.date_activity_created?.value || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Activity Due Date */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Activity Due Date"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={activityDisplayList[0]?.activity_due_date?.value || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Activity Status */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Activity Status"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={activityDisplayList[0]?.status?.value || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <GenericTable
            title="Activities"
            columnData={activityTableColumns}
            rowData={activityDisplayList}
            showActions={{ view: false, edit: false }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Activities;
