import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  availabilityData: [
    {
      day: 'Monday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Tuesday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Wednesday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Thursday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Friday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Saturday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
    {
      day: 'Sunday',
      start_time: '09:00',
      end_time: '17:00',
      break_start: '12:00',
      break_end: '13:00',
    },
  ],
  selectedDays: [],
};

const userFormAvailabilitySlice = createSlice({
  name: 'userFormAvailability',
  initialState: initialState,
  reducers: {
    setUserAvailabilityForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.availabilityData = payload.hasOwnProperty('availabilityData')
        ? payload.availabilityData
        : state.availabilityData;
      state.selectedDays = payload.hasOwnProperty('selectedDays')
        ? payload.selectedDays
        : state.selectedDays;
    },
  },
});

export const { setUserAvailabilityForm } = userFormAvailabilitySlice.actions;

export const userFormAvailabilityReducer = userFormAvailabilitySlice.reducer;
