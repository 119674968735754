import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';

import PFButton from '../../../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../../../shared/PFPrime/PFMultiSelect';
import PFDataTable from '../../../../shared/PFPrime/PFDataTable';
import { openNewWindow } from '../../../../../utils/Helpers';
import {
  SchedulerDefaultView,
  SCHEDULER_FEATURE_TYPE,
  SCHEDULER_CARD_VIEW_OPTIONS,
} from '../../company-config.constant';

const SchedulerConfig = ({ formik, setBtnDisables, btnDisables }) => {
  const [dailogVisible, setDailogVisible] = useState(false);
  const tableData = [
    {
      type: SCHEDULER_FEATURE_TYPE.schedularDefaultView,
      label: 'Default View',
    },
    {
      type: SCHEDULER_FEATURE_TYPE.schedularCardView,
      label: 'Card Visibility',
    },
    {
      type: SCHEDULER_FEATURE_TYPE.schedularRTSreportFilter,
      label: 'Report Filters',
    },
  ];
  function extractValues(options) {
    return options.map(option => option?.value);
  }
  useEffect(() => {
    if (
      formik?.values?.scheduler_config?.calender_view?.card_visibility ==
      undefined
    ) {
      formik.setFieldValue(
        'scheduler_config.calender_view.card_visibility',
        extractValues(SCHEDULER_CARD_VIEW_OPTIONS[0].options)
      );
    }
  }, []);
  const tabelColumn = [
    {
      header: 'Features',
      field: 'label',
    },
    {
      header: '',
      field: 'label',
      body: rowData => {
        switch (rowData.type) {
          case SCHEDULER_FEATURE_TYPE.schedularDefaultView:
            return (
              <SelectButton
                value={formik?.values?.default_calender_view}
                options={SchedulerDefaultView}
                optionLabel="label"
                onChange={e => {
                  formik?.setFieldValue('default_calender_view', e?.value);
                  setBtnDisables(false);
                }}
                pt={{ label: { className: 'text-xs' } }}
                className="w-full"
              />
            );
          case SCHEDULER_FEATURE_TYPE.schedularCardView:
            return (
              <span className="p-float-label py-0 px-0">
                <PFMultiSelect
                  className="w-10 text-sm"
                  name="card_view"
                  value={
                    formik?.values?.scheduler_config?.calender_view
                      ?.card_visibility
                  }
                  options={SCHEDULER_CARD_VIEW_OPTIONS[0].options}
                  onChange={e => {
                    formik.setFieldValue(
                      'scheduler_config.calender_view.card_visibility',
                      e.value
                    );
                    setBtnDisables(false);
                  }}
                  optionLabel="label"
                  maxSelectedLabels={0}
                  filter
                />
                <label htmlFor="card_view">Select Visibility</label>
              </span>
            );
          case SCHEDULER_FEATURE_TYPE.schedularRTSreportFilter:
            return (
              <PFButton
                label="Configure"
                size="small"
                outlined
                onClick={() =>
                  openNewWindow(`/admin/configuration/scheduler-rts-report`)
                }
              />
            );
          default:
            return null;
        }
      },
    },
  ];
  const handleHide = () => {
    setDailogVisible(false);
  };
  const handleUpdate = () => {
    formik.handleSubmit();
    setTimeout(() => {
      setDailogVisible(false);
    }, 3000);
  };
  return (
    <>
      <PFButton
        label="Configure"
        size="small"
        outlined
        onClick={e => {
          setDailogVisible(true);
        }}
      />
      {dailogVisible && (
        <Dialog
          header={'Scheduler Configure'}
          visible={dailogVisible}
          onHide={handleHide}
          className="w-5 xl:w-5"
        >
          <PFDataTable columns={tabelColumn} data={tableData} />
          <div className="col-12 flex justify-content-end pr-0">
            <PFButton
              label="Update"
              onClick={handleUpdate}
              disabled={btnDisables}
            ></PFButton>
          </div>
        </Dialog>
      )}
    </>
  );
};
export default SchedulerConfig;
