import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { InputNumber } from 'primereact/inputnumber';
import * as Yup from 'yup';

import { multiPartUpdateTenantData } from '../service/TenantConfig.service';
import PFButton from '../../../shared/PFPrime/PFButton';

const DefaultServiceTimeComponent = ({ clientId, clientDetail }) => {
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const validation = Yup.object().shape({
    default_service_time: Yup.number().min(0).required(),
  });

  const formik = useFormik({
    initialValues: {
      default_service_time: clientDetail?.default_service_time,
    },
    validationSchema: validation,
    onSubmit: async (values, submitProps) => {
      setLoading(true);
      const payload = {
        client_id: clientId,
        default_service_time: values?.default_service_time,
      };
      try {
        const apiResponse = await multiPartUpdateTenantData(payload, true);
        if (apiResponse?.data?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Default Service Time Updated Successfully',
            life: 3000,
          });
          formik.dirty && submitProps.resetForm({ values });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error occurred while Updating record',
            life: 3000,
          });
          return;
        }
      } catch (error) {
        console.log(error);
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong',
          life: 3000,
        });
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <Card>
      <Toast ref={toast} />
      <div className="flex flex-start align-items-center gap-3 flex-wrap w-full">
        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <div className="p-inputgroup flex-1 w-full">
              <div className="p-float-label flex align-items-center">
                <InputNumber
                  autoComplete="off"
                  id="default_service_time"
                  name="default_service_time"
                  onChange={e =>
                    formik.setFieldValue('default_service_time', e.value)
                  }
                  value={formik.values?.default_service_time}
                  onBlur={formik.handleBlur}
                  className="w-full p-inputtext-sm"
                  inputClassName="border-noround-right"
                  min={0}
                />
                <label htmlFor="default_service_time">
                  Default Service Time
                </label>
              </div>
              <span className="p-inputgroup-addon height-40">minutes</span>
            </div>
          )}
        </div>
        <div className="w-10rem">
          {loading ? (
            <Skeleton className="w-5rem" height="3rem" />
          ) : (
            <span className="w-full">
              <PFButton
                label="Save"
                size="small"
                onClick={
                  Number.isInteger(formik.values?.default_service_time)
                    ? formik.handleSubmit
                    : () => null
                }
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  !formik.errors ||
                  loading ||
                  !Number.isInteger(formik.values?.default_service_time)
                }
              />
            </span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DefaultServiceTimeComponent;
