import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userList: [],
  nextUserId: null,
  prevUserId: null,
  nextPageOffset: null,
  prevPageOffset: null,
  userSearchQuery: {
    limit: 10,
    offset: 0,
    searchString: '',
    sortKey: 'created_user_id',
    sortDirection: 'ASC',
  },
  userSelectedFilters: { statusIds: [18] },
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState,
  reducers: {
    setUserManagementComponent(state, { payload }) {
      state.userList = payload.hasOwnProperty('userList')
        ? payload.userList
        : state.userList;
      state.nextUserId = payload.hasOwnProperty('nextUserId')
        ? payload.nextUserId
        : state.nextUserId;
      state.prevUserId = payload.hasOwnProperty('prevUserId')
        ? payload.prevUserId
        : state.prevUserId;
      state.userSearchQuery = payload.hasOwnProperty('userSearchQuery')
        ? payload.userSearchQuery
        : state.userSearchQuery;
      state.userSelectedFilters = payload.hasOwnProperty('userSelectedFilters')
        ? payload.userSelectedFilters
        : state.userSelectedFilters;
      state.nextPageOffset = payload.hasOwnProperty('nextPageOffset')
        ? payload.nextPageOffset
        : state.nextPageOffset;
      state.prevPageOffset = payload.hasOwnProperty('prevPageOffset')
        ? payload.prevPageOffset
        : state.prevPageOffset;
    },
  },
});

export const { setUserManagementComponent } = userManagementSlice.actions;

export const userManagementReducer = userManagementSlice.reducer;
