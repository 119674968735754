import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const WidgetLoader = ({ chart_type }) => {
  if (['pie-chart', 'gauge'].includes(chart_type)) {
    return <Skeleton shape="circle" size="7.5rem"></Skeleton>;
  } else if (['line-chart', 'bar-chart'].includes(chart_type)) {
    return (
      <div className="skeleton-class align-items-end">
        <Skeleton width="22%" height="100%" className="mr-1"></Skeleton>
        <Skeleton width="22%" height="70%" className="mr-1"></Skeleton>
        <Skeleton width="22%" height="45%" className="mr-1"></Skeleton>
        <Skeleton width="22%" height="24%" className="mr-1"></Skeleton>
      </div>
    );
  } else if (chart_type === 'table-report') {
    const items = Array.from({ length: 5 }, (v, i) => i);

    const bodyTemplate = () => {
      return <Skeleton></Skeleton>;
    };
    return (
      <DataTable
        value={items}
        className="p-datatable-striped"
        style={{ width: '100%' }}
      >
        <Column className="w-3" body={bodyTemplate}></Column>
        <Column className="w-3" body={bodyTemplate}></Column>
        <Column className="w-3" body={bodyTemplate}></Column>
        <Column className="w-3" body={bodyTemplate}></Column>
      </DataTable>
    );
  }
  return <>Loading...</>;
};
export default WidgetLoader;
