import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Collapse,
  Box,
  TextField,
  Checkbox,
  Hidden,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Email } from '@material-ui/icons';

import { saveConfigurationEntities } from './FormFieldsConfiguration.service';

const useStyles = makeStyles({
  formField: {
    marginTop: '30px',
  },
});

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Please enter the required field')
    .matches(
      /^[a-zA-Z0-9 \s]+$/,
      'Only alphabets and numbers are allowed for this field '
    ),
});

const FormFields = ({
  currentType,
  getEditConfiguration,
  setAlert,
  reloadFieldConfigurationList,
  setReloadFieldConfigurationList,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: getEditConfiguration?.name?.value || '',
      slug: getEditConfiguration?.slug?.value || '',
      type: getEditConfiguration?.type?.value || '',
      min_length: getEditConfiguration?.min_length?.value || '',
      max_length: getEditConfiguration?.max_length?.value || '',
      help_text: getEditConfiguration?.help_text?.value || '',
      default: getEditConfiguration?.default?.value || '',
      regex: getEditConfiguration?.regex?.value || '',
      is_required:
        getEditConfiguration?.is_required?.value === 'Yes' ? ['Yes'] : [],
      is_hidden:
        getEditConfiguration?.is_hidden?.value === 'Yes' ? ['Yes'] : [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const data = {
        ...values,
        ...(!values?.type && { type: currentType }),
        ...(values?.is_hidden?.length
          ? { is_hidden: true }
          : { is_hidden: false }),
        ...(values?.is_required?.length
          ? { is_required: true }
          : { is_required: false }),
      };
      saveConfigurationEntities(
        'projects',
        data,
        setLoading,
        setAlert,
        setReloadFieldConfigurationList
      );
    },
  });

  useEffect(() => {
    formik.resetForm();
    setReloadFieldConfigurationList(false);
  }, [reloadFieldConfigurationList]);

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="space-between"
      spacing={2}
      className={classes.formField}
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          {currentType && (
            <>
              <Grid container spacing={2} direction="column">
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Field name"
                      type="text"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      fullWidth
                      id="default"
                      name="default"
                      label="Default Value"
                      type="text"
                      value={formik.values.default}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.default && Boolean(formik.errors.default)
                      }
                      helperText={
                        formik.touched.default && formik.errors.default
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  {(currentType == 'TEXT' ||
                    currentType == 'EMAIL' ||
                    currentType == 'URL') && (
                    <>
                      <Box className={classes.boxColumn} m={1}>
                        <TextField
                          fullWidth
                          id="min_length"
                          name="min_length"
                          label="Minimum length"
                          type="number"
                          InputProps={{ inputProps: { min: 1 } }}
                          value={formik.values.min_length}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.min_length &&
                            Boolean(formik.errors.min_length)
                          }
                          helperText={
                            formik.touched.min_length &&
                            formik.errors.min_length
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                      <Box className={classes.boxColumn} m={1}>
                        <TextField
                          fullWidth
                          id="max_length"
                          name="max_length"
                          label="Maximum length"
                          type="number"
                          InputProps={{ inputProps: { min: 1 } }}
                          value={formik.values.max_length}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.max_length &&
                            Boolean(formik.errors.max_length)
                          }
                          helperText={
                            formik.touched.max_length &&
                            formik.errors.max_length
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                    </>
                  )}
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      fullWidth
                      id="regex"
                      name="regex"
                      label="Regex"
                      type="text"
                      value={formik.values.regex}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.regex && Boolean(formik.errors.regex)
                      }
                      helperText={formik.touched.regex && formik.errors.regex}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      fullWidth
                      id="help_text"
                      name="help_text"
                      label="Help Text"
                      type="text"
                      value={formik.values.help_text}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.help_text &&
                        Boolean(formik.errors.help_text)
                      }
                      helperText={
                        formik.touched.help_text && formik.errors.help_text
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box className={classes.boxColumn} m={1}>
                    <FormControlLabel
                      value="Is Required?"
                      control={
                        <Checkbox
                          id="is_required"
                          name="is_required"
                          value={'Yes'}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Is Required?"
                      labelPlacement="top"
                    />
                  </Box>
                  <Box className={classes.boxColumn} m={1}>
                    <FormControlLabel
                      value="Is Hidden?"
                      control={
                        <Checkbox
                          id="is_hidden"
                          name="is_hidden"
                          value={'Yes'}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Is Hidden?"
                      labelPlacement="top"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                container
                item
                direction="column"
                justifyContent="space-between"
                spacing={3}
                className={classes.formField}
              >
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFields;
