// **** React Imports ****
import React from 'react';

// **** Utilities ****
import {
  Grid,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';

// **** Services *****
import { getTimeManagementData } from './TimeManagement.service';

// **** Styles *****
import { useStyles } from './TimeManagement.styles.js';

// *** Format Date in YYYY-MM-DD ***
const formatDate = date => (date ? moment(date).format('YYYY-MM-DD') : '');

const TimeManagement = ({ formik }) => {
  const classes = useStyles();
  const [timeManagementResponse, setTimeManagementResponse] = React.useState(
    {}
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (formik?.values?.project_id)
      getTimeManagementData(
        formik?.values?.project_id,
        setLoading,
        setTimeManagementResponse
      );
  }, [formik?.values?.project_id]);

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  function ParseFloatTurnTime(str, val) {
    str = str.toString();
    str = str.slice(0, str?.indexOf('.') + val + 1);
    return Number(str);
  }
  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            classes={{ root: classes.titleHeaderGrid }}
          >
            <Grid item>
              <Typography variant="h3">Time Management</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {/* Date Product Available */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Date Product Available"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                value={
                  formatDate(timeManagementResponse.date_product_available) ||
                  ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Date Scheduled */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Date Scheduled DT"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                value={formatDate(timeManagementResponse.date_scheduled) || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Sold to Product Available Turn Time */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Sold to Product Available Turn Time"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.sold_to_product_available_turn_time
                    ? `${ParseFloatTurnTime(
                        timeManagementResponse.sold_to_product_available_turn_time,
                        2
                      )} day(s)`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Available To Scheduled Turn Time */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Available To Scheduled Turn Time"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.available_to_schedule_turn_time
                    ? `${ParseFloatTurnTime(
                        timeManagementResponse.available_to_schedule_turn_time,
                        2
                      )} day(s)`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Available to Start Turn Time */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Available to Start Turn Time"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.available_to_start_turn_time
                    ? `${ParseFloatTurnTime(
                        timeManagementResponse.available_to_start_turn_time,
                        2
                      )} day(s)`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Date Completed Dt*/}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Sold To Complete Turn Time"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.sold_to_complete_turn_time
                    ? `${ParseFloatTurnTime(
                        timeManagementResponse.sold_to_complete_turn_time,
                        2
                      )} day(s)`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Date completed */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="
                Date Completed DT"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={formatDate(timeManagementResponse.date_completed)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Product Available to Complete Turn Time */}
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Product Available to Complete Turn Time"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.product_available_to_complete_turn_time
                    ? `${ParseFloatTurnTime(
                        timeManagementResponse.product_available_to_complete_turn_time,
                        2
                      )} day(s)`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Aging"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.aging
                    ? `${timeManagementResponse.aging || 0} ${
                        timeManagementResponse?.aging > 1 ? 'day(s)' : 'day'
                      }`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Date Scheduled Date to Start Turn Time"
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.date_scheduled_date_to_start_turn_time
                    ? `${timeManagementResponse.date_scheduled_date_to_start_turn_time || 0} ${
                        timeManagementResponse?.date_scheduled_date_to_start_turn_time >
                        1
                          ? 'day(s)'
                          : 'day'
                      }`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                label="Date Scheduled Date to Completion Turn Time"
                InputProps={{
                  readOnly: true,
                }}
                value={
                  timeManagementResponse.date_scheduled_date_to_completion_turn_time
                    ? `${timeManagementResponse.date_scheduled_date_to_completion_turn_time || 0} ${
                        timeManagementResponse?.date_scheduled_date_to_completion_turn_time >
                        1
                          ? 'day(s)'
                          : 'day'
                      }`
                    : ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeManagement;
