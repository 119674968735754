// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';

import { StatusColors } from '../../../../constants';

export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#ffffff',
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  boxWideColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '37%',
    },
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  gridPaper: {
    padding: '10px',
  },
  mapPaper: {
    padding: '10px',
    maxHeight: '730px',
  },
  calendarPaper: {
    padding: '0px',
    maxHeight: '700px',
    '@media (max-width: 768px)': {
      '& .fc-header-toolbar': {
        overflow: 'auto',
      },
      '& .fc-toolbar-title': {
        width: '200px',
      },
      '& .fc-toolbar-chunk': {
        display: 'flex',
      },
    },
  },
  h1Style: {
    position: 'absolute',
    top: '5rem',
    left: '5rem',
    color: '#281414',
    zIndex: 10,
    margin: 0,
    padding: 15,
    backgroundColor: '#ffffff',
    '@media (max-width: 768px)': {
      left: '0',
    },
  },
  mapWrapperDiv: {
    position: 'relative',
  },
  marker: {
    position: 'relative',
    textAlign: 'center',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableWrapper: {
    width: '100%',
  },
  statusColorCompleted: { color: StatusColors.PF_GREEN },
  statusColorCancelled: { color: StatusColors.PF_RED },
  statusColor: { color: StatusColors.PF_YELLOW },
  projectRedirectButton: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: '20px',
    marginBottom: '10px',
  },
  hintLabel: {
    color: '#697C91',
    fontSize: '11px',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: 1,
  },
  selectBoxClass: {
    top: '80px !important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxWidth: {
    backgroundColor: '#ffffff',
    padding: '15px',
    maxWidth: '100%',
    flexBasis: '40% !important',
  },
  formWrapper: {
    width: '100%',
  },
  margLeft: {
    marginLeft: '10px',
  },
}));
