import { InputText } from 'primereact/inputtext';

export default function AddCustomAddressForm({ formik }) {
  const isFormFieldInvalid = name =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = name => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error error text-xs text-red-500">
        {formik.errors[name]}
      </small>
    ) : null;
  };

  return (
    <>
      <div className="formgrid grid m-2">
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="name"
              id="name"
              className="p-inputtext p-component w-12"
              value={formik.values.name}
              onChange={e => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            {getFormErrorMessage('name')}
            <label htmlFor="name">Name</label>
          </span>
        </div>
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="address1"
              id="address1"
              className="w-12"
              value={formik.values.address1}
              onChange={e => {
                formik.setFieldValue('address1', e.target.value);
              }}
            />
            {getFormErrorMessage('address1')}
            <label htmlFor="address1">Address1</label>
          </span>
        </div>
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="address2"
              id="address2"
              className="w-12"
              value={formik.values.address2}
              onChange={e => {
                formik.setFieldValue('address2', e.target.value);
              }}
            />
            {getFormErrorMessage('address2')}
            <label htmlFor="address2">Address2</label>
          </span>
        </div>
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="city"
              id="city"
              className="w-12"
              value={formik.values.city}
              onChange={e => {
                formik.setFieldValue('city', e.target.value);
              }}
            />
            {getFormErrorMessage('city')}
            <label htmlFor="city">City</label>
          </span>
        </div>
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="state"
              id="state"
              className="w-12"
              value={formik.values.state}
              onChange={e => {
                formik.setFieldValue('state', e.target.value);
              }}
            />
            {getFormErrorMessage('state')}
            <label htmlFor="state">State</label>
          </span>
        </div>
        <div className="field col-12">
          <span className="p-float-label">
            <InputText
              autoComplete="off"
              name="zipcode"
              id="zipcode"
              className="w-12"
              value={formik.values.zipcode}
              onChange={e => {
                formik.setFieldValue('zipcode', e.target.value);
              }}
            />
            {getFormErrorMessage('zipcode')}
            <label htmlFor="zipcode">Zip Code</label>
          </span>
        </div>
      </div>
    </>
  );
}
