import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  itemInfo: {},
  projectInfo: {},
  isListReloading: false,
  warehours: [],
};

const ProductDetailsSlice = createSlice({
  name: 'ProductDetails',
  initialState: initialState,
  reducers: {
    productClearState(state) {
      state.isLoading = true;
      state.itemInfo = {};
      state.projectInfo = {};
      state.isListReloading = false;
      state.warehours = [];
    },
    setData(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      if (payload.hasOwnProperty('item')) {
        state.itemInfo = { ...state.itemInfo, ...payload?.item };
      }
      if (payload.hasOwnProperty('isListReloading')) {
        state.isListReloading = payload?.isListReloading || false;
      }
      if (payload.hasOwnProperty('warehours')) {
        state.warehours = { ...state.warehours, ...payload?.warehours };
      }
    },
    setProjectInfo(state, { payload }) {
      state.projectInfo = payload;
    },
  },
});

export const { productClearState, setData, setProjectInfo } =
  ProductDetailsSlice.actions;

export const ProductDetailsReducer = ProductDetailsSlice.reducer;
