import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserDocumentsData = async userId => {
  try {
    const userDetailsResponse = await ApiService.get(
      `/auth/user/manage/${userId}`
    );
    return {
      documents: userDetailsResponse?.documents || [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const editUserDocuments = async (userId, documentsInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        documents: JSON.stringify(documentsInfo),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
