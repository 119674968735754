// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import useToken from '../../hooks/useToken';
// **** Custom Components ****
import PageHeader from '../shared/PageHeader/PageHeader';

const CreateNewProject = () => {
  const { accessToken } = useToken();
  const clientsBreadcrumb = [
    {
      text: 'Get Started',
      link: '/getstarted',
    },
    {
      text: 'How to Create New Project?',
    },
  ];
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      '&.MuiPaper-root': { border: 'none' },
    },
  }));
  const classes = useStyles();
  return accessToken ? (
    <>
      <Grid container spacing={2} direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="How it works"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={10}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Create New Project"
                  image="/images/get_started/Project.png"
                  height="355"
                  title="Create New Project"
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="h2">
                    How to Create New Project?
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    A new project can be created by navigating to the Add
                    Project tab inside the Project setting. We can provide the
                    schedule information while creating the project.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <>Please Log-in to access the application</>
  );
};

export default CreateNewProject;
