import { Message } from 'primereact/message';

import { useStyles } from '../Admin/Auth/Login/Login.styles';
const OnboardSuccess = () => {
  const classes = useStyles();

  return (
    <>
      <div
        className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
      >
        <div>
          <div className="text-center md:pt-5 pt-0">
            <img
              src="/images/PF-white-logo.png"
              alt="Project Force"
              className="mb-3"
              style={{ maxWidth: '180px' }}
            />
            <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
              ProjectsForce puts you in control of your work, keeping both you
              and your clients on-track towards completing the job and getting
              paid.{' '}
            </p>
            <div className="surface-1 text-600 text-center">
              <Message
                severity="success"
                text="Thank you for completing onboarding"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardSuccess;
