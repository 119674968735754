import { useState } from 'react';
import PropTypes from 'prop-types';

// **** Utilities ****
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

const GenericInputDialog = ({ isOpen, onClose, onConfirm, title, label }) => {
  const [inputText, setInputText] = useState('');
  const handleSubmit = () => onConfirm(inputText);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label={label}
          type="text"
          fullWidth
          value={inputText}
          onChange={e => setInputText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={!inputText}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GenericInputDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default GenericInputDialog;
