import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  itemsAccordions: {
    '& .MuiAccordion-root:not(:first-child)': {
      marginTop: 15,
    },
    '& .MuiAccordion-root': {
      borderColor: 'lightgray',
    },
    '& .MuiAccordionDetails-root': {
      display: 'block',
    },
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  dividerLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
  displayImage: {
    height: 'auto !important',
    maxHeight: '50px !important',
    width: 'auto !important',
    maxWidth: '50px !important',
  },
  input: {
    color: theme.palette.text.primary,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  select: {
    width: '100%',
    '&:hover': {
      overflowY: 'auto',
      'scrollbar-width': 'thin',
      '&::-webkit-scrollbar': {
        // Width of vertical scroll bar
        width: '8px',
        // Height of horizontal scroll bar
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: '#c2c9d2',
      },
    },
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  fileInput: {
    display: 'none',
  },
  buttonOnHeade: {
    marginLeft: 'auto',
  },
  fileInputLabel: {
    color: '#0074D9',
    right: 0,
  },
  fileInputButton: {
    width: '40px',
    height: '30px',
    marginLeft: '10px',
  },
  imageInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableWrapper: {
    maxWidth: '100%',
  },
  addButton: {
    marginBottom: 10,
  },
  itemPopUpSize: {
    height: '775px',
    width: '429px',
  },
  laborTableHeading: {
    marginTop: '5px',
  },
  merchandiseTableHeading: {
    marginTop: '6px',
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
}));
