import * as Yup from 'yup';

export const quoteAmountInit = {
  totalPrice: null,
  depositType: 'Amount',
  depositPercent: null,
  payableAmount: null,
  amountToBePaidAfterService: null,
  paymentTerms: '',
};

export const quoteAmountValidation = {
  totalPrice: Yup.mixed(),
  depositType: Yup.string().required('Required'),
  depositPercent: Yup.mixed(),
  payableAmount: Yup.mixed(),
  amountToBePaidAfterService: Yup.mixed(),
  paymentTerms: Yup.string().required('Required'),
};

export const depositTypeModes = [
  { id: 1, name: 'Percentage' },
  { id: 2, name: 'Amount' },
];
export const paymentTerms = [
  { id: 1, name: 'Less than 15 Days' },
  { id: 2, name: '60 Days' },
  { id: 3, name: '90 Days' },
];
