import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  toggleButtonLabel: 'Select All',
  clientList: [],
};

const measureSquareConfigSlice = createSlice({
  name: 'MeasureSquareConfig',
  initialState: initialState,
  reducers: {
    measureSquareConfigUpdateClientList(state, { payload }) {
      state.clientList = payload.hasOwnProperty('clientList')
        ? payload.clientList
        : state.clientList;
    },
    measureSquareConfigUpdateComponent(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.toggleButtonLabel = payload.hasOwnProperty('toggleButtonLabel')
        ? payload.toggleButtonLabel
        : state.toggleButtonLabel;
    },
  },
});

export const {
  measureSquareConfigUpdateClientList,
  measureSquareConfigUpdateComponent,
} = measureSquareConfigSlice.actions;

export const measureSquareConfigReducer = measureSquareConfigSlice.reducer;
