import axios from 'axios';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

import { setData as setProductData } from './reducers/ProductDetailsSlice';
const status = [
  {
    id: 1,
    name: 'Active',
    value: 'Active',
  },
  {
    id: 2,
    name: 'Inactive',
    value: 'Inactive',
  },
];

const productType = [
  {
    id: 1,
    name: 'Product',
    value: 'Product',
  },
  {
    id: 2,
    name: 'Labor',
    value: 'Labor',
  },
  {
    id: 3,
    name: 'SOS',
    value: 'SOS',
  },

  {
    id: 4,
    name: 'Stock',
    value: 'Stock',
  },
  {
    id: 5,
    name: 'Pseudo',
    value: 'Pseudo',
  },
  {
    id: 6,
    name: 'Merchandise',
    value: 'Merchandise',
  },
];

export const validator = ({ key, value }) => {
  let res = { error: '', validation: true };
  if (key === 'productType') {
    if (_.isEmpty(value)) {
      const message = 'Product type should not be empty';
      res = { error: message, validation: false };
    }
  }
  if (key === 'productName') {
    if (_.isEmpty(value)) {
      const message = 'Product name should not be empty';
      res = { error: message, validation: false };
    }
  }

  if (key === 'productNumber') {
    if (value === undefined || value === null) {
      const message = 'Product number should not be empty';
      res = { error: message, validation: false };
    }
  }

  return res;
};

const updateItemInSql = async (id, values, productDetails) => {
  const keysArray = [
    'productName',
    'productNumber',
    'sourcePrice',
    'inventory_managment',
    'salesPrice',
    'purchasePrice',
  ];

  if (keysArray.includes(Object.keys(values)[0])) {
    let reqBody = null;
    if (Object.keys(values)[0] === 'productName') {
      reqBody = { item_desc: values?.productName };
    }
    if (Object.keys(values)[0] === 'productNumber') {
      reqBody = { item_number: values?.productNumber.toString() };
    }
    if (Object.keys(values)[0] === 'sourcePrice') {
      reqBody = { source_price: values?.sourcePrice };
    }
    if (Object.keys(values)[0] === 'inventory_managment') {
      reqBody = { inventory_managment: values?.inventory_managment };
    }

    if (Object.keys(values)[0] === 'salesPrice') {
      reqBody = { retail_price: values?.salesPrice };
    }
    if (Object.keys(values)[0] === 'purchasePrice') {
      reqBody = { cost_price: values?.purchasePrice };
    }

    if (reqBody) {
      const updatePayload = {
        ...reqBody,
        item_number: productDetails?.productNumber,
        item_desc: productDetails?.productName,
      };
      const response = await axios.patch(
        `${URL_CONSTANTS.API.BASE_URL}/system/items/${id}`,
        updatePayload
      );

      return response;
    }
  }
};

export const addUpdateProduct = async (
  data,
  dispatch,
  toast = null,
  setDataTableloading = () => {}
) => {
  try {
    let addUpdateProduct = null;
    if (!data?._id) {
      addUpdateProduct = await apiService.postWithDiffBaseUrl(
        `/product/create`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    } else {
      addUpdateProduct = await apiService.patchWithDiffBaseUrl(
        `/product/update/${data?._id}`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    }

    if (addUpdateProduct?.status) {
      dispatch(setProductData({ item: addUpdateProduct?.data }));
      setDataTableloading(false);
      data?._id &&
        toast &&
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Record Updated successfully',
          life: 3000,
        });
    } else {
      setDataTableloading(false);

      toast &&
        toast.current.show({
          severity: 'error',
          summary: 'Error!',
          detail: 'something went wrong!',
          life: 3000,
        });
    }

    return addUpdateProduct;
  } catch (error) {
    console.error('error ', error.message);
    return [];
  }
};

export const getProduct = async (productId, dispatch, inventoryItem = null) => {
  try {
    const getProduct = await apiService.getWithDiffBaseUrl(
      `/product/get-product/${productId}`,
      null,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    if (getProduct?.status) {
      if (getProduct?.data) {
        if (inventoryItem) {
          const productData = {
            ...getProduct?.data,
            productName: inventoryItem?.item_desc || '',
            productNumber: inventoryItem?.item_number || '',

            sourcePrice: inventoryItem?.source_price || '0',
            salesPrice: inventoryItem?.retail_price || '0',
            purchasePrice: inventoryItem?.cost_price || '0',
          };
          addUpdateProduct(productData, dispatch);
        } else {
          dispatch(setProductData({ item: getProduct?.data }));
        }
      } else {
        const productData = {
          productId: inventoryItem?.item_id,
          status: 'Active',
          productType: inventoryItem?.item_type?.type_name || '',
          productName: inventoryItem?.item_desc || '',
          productNumber: inventoryItem?.item_number || '',

          sourcePrice: inventoryItem?.source_price || '0',
          salesPrice: inventoryItem?.retail_price || '0',
          purchasePrice: inventoryItem?.cost_price || '0',

          totalQuantityInHand: '0',
          totalQuantityAvailable: '0',
          totalQuantityAllocated: '0',
          totalQuantityDispatched: '0',
          threshold: '0',

          salesDescription: '',
          purchaseDescription: '',

          salesPricePerUnit: '0',
          purchasePricePerUnit: '0',

          totalPurchaseAmount: '0',
          totalSalesAmount: '0',
        };

        addUpdateProduct(productData, dispatch);
      }
    }
    return getProduct;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};

export { status, productType, updateItemInSql };
