import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tabRoot: {
    background: theme.palette.grey[200],
    fontFamily: `var(--font - family)`,
    minHeight: '0px',
    borderTop: `1px Solid ${theme.palette.grey[700]}`,
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.primary,
      width: '14px',
      height: '14px',
    },
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
    '& .MuiButtonBase-root': {
      height: '40px',
      '&.MuiTab-root': {
        paddingBottom: '7px',
      },
    },
  },
  tabTextColor: {
    color: `var(--primary-400)`,
  },
  tabsPaper: {
    flexGrow: 1,
    width: '100%',
    minHeight: '50px',
  },
  tab: {
    transition: 'background-color 0.3s ease',
    cursor: 'move',
    borderRight: '2px solid var(--primary-400)',
  },
  selectedTab: {
    backgroundColor: 'var(--primary-400) !important',
    color: '#ffffff !important',
    opacity: `1 !important`,
  },
  unSelectedTab: {
    color: `var(--primary-400)`,
    opacity: `1 !important`,
  },
  addButtonTab: {
    borderRadius: `0px`,
    backgroundColor: `var(--primary-color) !important`,
    color: `var(--blue-50)`,
  },
}));
