import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
  Radio,
  Switch,
  Select,
  MenuItem,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close as MuiCloseIcon } from '@material-ui/icons';
import * as Yup from 'yup';

import {
  LEAD_RESPONSE_REASON,
  StatusColors,
  SOURCE_SYSTEMS,
  workTypeConstants,
} from '../../../constants';
import LrrpConfigurationForm from '../../Admin/Configuration/LrrpConfigurationComponents/index';
import {
  formikProjectMapping,
  lrrpConfigFormikValidationSchema,
} from '../../Admin/Configuration/LrrpConfiguration.constant';
import ApiService from '../../../services/api.service';
import apiService from '../../../services/api.service';

import { getHdEpaDocs, getHomeDepotOverrideReasons } from './EPALead.service';
import HomeDepotFields from './HomeDepotFields';

const validationSchema = (value, projectDetailsData) =>
  Yup.object().shape({
    epa_lead_assessment_required: Yup.number().optional().nullable(),
    epa_lead_remediation: Yup.number().optional().nullable(),
    tested_lead_negative: Yup.object().when('lead_response_reason', {
      is: 'Tested Lead Negative',
      then: Yup.object().shape({
        test_kit_manufacturer: Yup.string().required(
          'Test kit manufacturer is required'
        ),
        component_location_tested: Yup.string().required(
          'Component location tested is required'
        ),
      }),
      otherwise: Yup.object().shape({
        test_kit_manufacturer: Yup.string(),
        component_location_tested: Yup.string(),
      }),
    }),
    tested_lead_positive: Yup.object().when('lead_response_reason', {
      is: 'Tested Lead Positive',
      then: Yup.object().shape({
        test_kit_manufacturer: Yup.string()
          .trim()
          .required('Test kit manufacturer is required'),
        component_location_tested: Yup.string()
          .trim()
          .required('Component location tested is required'),
        notification_confirmation: Yup.string().required(
          'Notification confirmation is required'
        ),
        is_common_area_involved: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        reason_common_area: Yup.string()
          .trim()
          .when('is_common_area_involved', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
        work_area_signage: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
        signage_explanation: Yup.string()
          .trim()
          .when('work_area_signage', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
        prohibited_practices: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        waste_contained: Yup.string().oneOf(['yes', 'no', 'N/A']).required(),
        worksite_properly_cleaned: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        post_renovation_cleaning_method: Yup.string()
          .oneOf(['Cleaning verification', 'Dust clearance'])
          .required(),
        cleaning_verification_results: Yup.string()
          .trim()
          .when('post_renovation_cleaning_method', {
            is: 'Cleaning verification',
            then: Yup.string().required(
              'Cleaning verification results is required'
            ),
            otherwise: Yup.string(),
          }),
        number_of_wet_cloths: Yup.string().when(
          'post_renovation_cleaning_method',
          {
            is: 'Cleaning verification',
            then: Yup.string().required('Number of wet cloths is required'),
            otherwise: Yup.string(),
          }
        ),
        number_of_dry_cloths: Yup.string().when(
          'post_renovation_cleaning_method',
          {
            is: 'Cleaning verification',
            then: Yup.string().required('Number of dry cloths is required'),
            otherwise: Yup.string(),
          }
        ),
        is_child_occupied_facility: Yup.string()
          .oneOf(['yes', 'no', 'N/A'])
          .required(),
        reason_child_facility: Yup.string()
          .trim()
          .when('is_child_occupied_facility', {
            is: 'N/A',
            then: Yup.string().required('Reason for N/A is required'),
            otherwise: Yup.string(),
          }),
      }),
      otherwise: Yup.object().shape({
        test_kit_manufacturer: Yup.string(),
        component_location_tested: Yup.string(),
      }),
    }),
    presumed_lead: Yup.object().shape({
      notification_confirmation: Yup.string(),
      is_common_area_involved: Yup.string().oneOf(['yes', 'no', 'N/A']),
      reason_common_area: Yup.string(),
      work_area_signage: Yup.string().oneOf(['yes', 'no', 'N/A']),
      signage_explanation: Yup.string(),
      work_areas_contained: Yup.string(),
      prohibited_practices: Yup.string().oneOf(['yes', 'no', 'N/A']),
      waste_contained: Yup.string().oneOf(['yes', 'no', 'N/A']),
      worksite_properly_cleaned: Yup.string(),
      post_renovation_cleaning_method: Yup.string(),
      number_of_wet_cloths: Yup.string(),
      number_of_dry_cloths: Yup.string(),
      cleaning_verification_results: Yup.string(),
      is_child_occupied_facility: Yup.string().oneOf(['yes', 'no', 'N/A']),
      reason_child_facility: Yup.string(),
    }),
    lead_response_reason: Yup.string()
      .notOneOf(['placeholder'])
      .required('lead_response_reason is required'),
    other: Yup.object().when('lead_response_reason', {
      is: 'Other',
      then: Yup.object().shape({
        lrrp_explanation: Yup.string().required('Lrrp Explanation is required'),
        document_required: Yup.string().oneOf(['yes', 'no', 'N/A']),
        wo_explanation: Yup.string().required(
          'Work Order Explanation is required'
        ),
      }),
    }),
  });

const EPALead = ({ projectId, projectDetailsData }) => {
  const [option, setOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [showLRRPTriggerMessage, setShowLRRPTriggerMessage] = useState(false);
  const [leadAutocompleteActivity, setLeadAutocompleteActivity] =
    useState(false);
  const [homeDepotOverrideOptions, setHomeDepotOverrideOptions] = useState([]);
  const [hdEpaDocs, setHdEpaDocs] = useState([]);
  const [renovatorInfo, setRenovatorInfo] = useState(null);

  useEffect(async () => {
    if (
      projectDetailsData &&
      projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
    ) {
      const userId = localStorage.getItem('user_id') || null;
      const installerData = await ApiService.get(
        `/projects/${projectId}/project-installer`
      );
      if (installerData?.length > 0) {
        const scheduleInstaller = installerData?.find(
          item => item.worktypeid == workTypeConstants?.SCHEDULED
        );
        const schedulerUserId =
          scheduleInstaller?.installer_detail?.user_id || null;

        if (schedulerUserId) {
          const userDetailsRequest = await ApiService.get(
            `/auth/user/manage/${schedulerUserId}`
          );
          if (userDetailsRequest) {
            const { renovator_name, renovator_certification_number } =
              userDetailsRequest;
            setRenovatorInfo({
              name: renovator_name,
              certificate: renovator_certification_number,
            });
          }
        }
      }
    }
  }, [projectDetailsData]);

  const LrrpConfigurationFormik = useFormik({
    initialValues: {
      ...{
        epa_lead_assessment_required: '',
        epa_lead_remediation: '',
        lead_response_reason: '',
        tested_lead_negative: {
          test_kit_manufacturer: '',
          component_location_tested: '',
        },
        tested_lead_positive: {
          test_kit_manufacturer: '',
          component_location_tested: '',
          notification_confirmation: '',
          is_common_area_involved: 'no',
          reason_common_area: '',
          work_area_signage: 'no',
          signage_explanation: '',
          work_areas_contained: '',
          prohibited_practices: 'no',
          waste_contained: 'no',
          worksite_properly_cleaned: 'no',
          post_renovation_cleaning_method: '',
          number_of_wet_cloths: '',
          number_of_dry_cloths: '',
          cleaning_verification_results: '',
          is_child_occupied_facility: 'no',
          reason_child_facility: '',
        },
        presumed_lead: {
          notification_confirmation: '',
          is_common_area_involved: 'no',
          reason_common_area: '',
          work_area_signage: 'no',
          signage_explanation: '',
          work_areas_contained: '',
          prohibited_practices: 'no',
          waste_contained: 'no',
          worksite_properly_cleaned: 'no',
          post_renovation_cleaning_method: '',
          number_of_wet_cloths: '',
          number_of_dry_cloths: '',
          cleaning_verification_results: '',
          is_child_occupied_facility: 'no',
          reason_child_facility: '',
        },
        lswp_required: '',
        lead_test_req: '',
        lswp_followed: '',
        hd_lead_test_result: '',
        epa_override_reason_code_id: '',
        other: {
          lrrp_explanation: '',
          document_required: 'no',
          wo_explanation: '',
        },
      },
      ...(projectDetailsData &&
      projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
        ? {
            epa_renovator_name: '',
            epa_renovator_cert: '',
          }
        : {}),
    },
    onSubmit: async values => {
      setLoading(true);
      const key = values?.lead_response_reason
        ?.toLocaleLowerCase()
        ?.split(' ')
        .join('_');
      if (projectDetailsData?.source_system_id === 2) {
        await ApiService.post(`/system/lrrp-configuration/${projectId}`, {
          lswp_required: values?.lswp_required,
          lead_test_req: values?.lead_test_req,
          lswp_followed: values?.lswp_followed,
          hd_lead_test_result: values?.hd_lead_test_result,
          epa_override_reason_code_id: values?.epa_override_reason_code_id,
          year_built: values?.year_built,
        });
      } else {
        await ApiService.post(`/system/lrrp-configuration/${projectId}`, {
          lead_response_reason: values.lead_response_reason,
          [key]: values[key],
          epa_lead_assessment_required: values?.epa_lead_assessment_required,
          epa_lead_remediation: values?.epa_lead_remediation,
          ...(projectDetailsData &&
          projectDetailsData?.source_system_id === SOURCE_SYSTEMS?.LOWES
            ? {
                epa_renovator_name: values?.epa_renovator_name,
                epa_renovator_cert: values?.epa_renovator_cert,
              }
            : {}),
        });
      }
      setLoading(false);
    },
    enableReinitialize: true,
    validationSchema: value => validationSchema(value, projectDetailsData),
  });
  const getData = async () => {
    setLoading(true);
    const response = await ApiService.get(
      `/system/lrrp-configuration/${projectId}`
    );
    formikProjectMapping(LrrpConfigurationFormik, response);
    LrrpConfigurationFormik.setFieldValue(
      'epa_renovator_name',
      response?.epa_renovator_name || ''
    );
    LrrpConfigurationFormik.setFieldValue(
      'epa_renovator_cert',
      response?.epa_renovator_cert || ''
    );

    setLeadAutocompleteActivity(response.lead_autocomplete_activity);
    setOption(response.default_lead_response_reason);
    if (
      (response?.lead_autocomplete_activity === 0 ||
        response?.lead_autocomplete_activity === null) &&
      !response?.project_lead_response_reason &&
      projectDetailsData?.source_system_id !== 2
    ) {
      LrrpConfigurationFormik.resetForm();
      LrrpConfigurationFormik.setFieldValue(
        'lead_response_reason',
        'placeholder'
      );
      setOption('');
    }
    if (
      response?.epa_lead_assessment_required ||
      response?.epa_lead_remediation
    ) {
      LrrpConfigurationFormik.setFieldValue(
        'epa_lead_assessment_required',
        response?.epa_lead_assessment_required
      );
      LrrpConfigurationFormik.setFieldValue(
        'epa_lead_remediation',
        response?.epa_lead_remediation
      );
    }
    setLoading(false);
  };

  const getHomeDepotData = async () => {
    setLoading(true);
    const [overrideReasons, hdEpaDocsResponse] = await Promise.all([
      getHomeDepotOverrideReasons(),
      getHdEpaDocs(projectId),
    ]);
    setHomeDepotOverrideOptions(overrideReasons);
    setHdEpaDocs(hdEpaDocsResponse?.items);
    setLoading(false);
  };

  useEffect(() => {
    if (projectDetailsData?.source_system_id !== 2) {
      getData();
    }
  }, [projectId]);

  useEffect(() => {
    projectDetailsData &&
      projectDetailsData.source_system_id === 2 &&
      getHomeDepotData();
  }, [projectId]);

  const triggerSubmit = async () => {
    setLoading(true);
    await ApiService.get(`/system/lrrp-configuration-trigger/${projectId}`);
    setShowLRRPTriggerMessage(true);
    setLoading(false);
  };

  const handleSubmitButton = async () => {
    await LrrpConfigurationFormik.handleSubmit();
    await triggerSubmit();
  };

  const projectOtherDetailsData =
    projectDetailsData?.project_type?.project_type_id === 3 &&
    projectDetailsData?.project_type?.project_type === 'Work Order';

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid
            container
            item
            direction="row"
            spacing={2}
            style={{ marginTop: '8px' }}
          >
            {showLRRPTriggerMessage &&
              projectDetailsData.source_system_id !== 2 && (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    action={
                      <MuiIconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowLRRPTriggerMessage(false);
                        }}
                      >
                        <MuiCloseIcon fontSize="inherit" />
                      </MuiIconButton>
                    }
                  >
                    Lead response reason is saved and would be passed to Source
                    as soon as the activity gets open
                  </Alert>
                </Grid>
              )}
            {projectDetailsData &&
              projectDetailsData?.source_system_id !== 2 && (
                <Grid item xs={6}>
                  <div className="grid flex-row  mb-3">
                    <div className="col-12 lg:col-12">
                      <label
                        style={{
                          color: '#777e98',
                          fontSize: '0.9rem',
                          fontWeight: 400,
                        }}
                      >
                        Lead Assessment :
                      </label>
                      <Select
                        label="Lead Assessment"
                        value={
                          LrrpConfigurationFormik?.values
                            ?.epa_lead_assessment_required
                        }
                        onChange={event => {
                          LrrpConfigurationFormik.setFieldValue(
                            'epa_lead_assessment_required',
                            event.target?.value
                          );
                        }}
                        disabled={true}
                      >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </div>
                    <div className="col-12 lg:col-12">
                      <label
                        style={{
                          color: '#777e98',
                          fontSize: '0.9rem',
                          fontWeight: 400,
                        }}
                      >
                        Lead Remediation :
                      </label>
                      <Select
                        label="Lead Remediation"
                        value={
                          LrrpConfigurationFormik?.values?.epa_lead_remediation
                        }
                        onChange={event => {
                          LrrpConfigurationFormik.setFieldValue(
                            'epa_lead_remediation',
                            event.target.value
                          );
                        }}
                        disabled={true}
                      >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>
              )}
            {projectDetailsData &&
              projectDetailsData?.source_system_id ===
                SOURCE_SYSTEMS?.LOWES && (
                <Grid item xs={6}>
                  <div className="grid flex-row  mb-3">
                    <div className="col-12 lg:col-12">
                      <label
                        style={{
                          color: StatusColors?.PF_GRAY,
                          fontSize: '0.9rem',
                          fontWeight: 400,
                        }}
                      >
                        Renovator Name
                      </label>
                      <TextField
                        value={
                          LrrpConfigurationFormik?.values?.epa_renovator_name ||
                          renovatorInfo?.name
                        }
                        onChange={event => {
                          LrrpConfigurationFormik.setFieldValue(
                            'epa_renovator_name',
                            event.target?.value
                          );
                          setRenovatorInfo(null);
                        }}
                      />
                    </div>
                    <div className="col-12 lg:col-12">
                      <label
                        style={{
                          color: StatusColors?.PF_GRAY,
                          fontSize: '0.9rem',
                          fontWeight: 400,
                        }}
                      >
                        Renovator Cert#{' '}
                      </label>
                      <TextField
                        value={
                          LrrpConfigurationFormik?.values?.epa_renovator_cert ||
                          renovatorInfo?.certificate
                        }
                        onChange={event => {
                          LrrpConfigurationFormik.setFieldValue(
                            'epa_renovator_cert',
                            event.target?.value
                          );
                          setRenovatorInfo(null);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              )}
            {projectDetailsData.source_system_id !== 2 && (
              <>
                <Grid item xs={12} md={6}>
                  <label>
                    Lead Response Reason{' '}
                    <span style={{ color: '#f44336' }}>*</span>
                  </label>
                  <Select
                    label="Lead Response Reason"
                    value={
                      LrrpConfigurationFormik?.values?.lead_response_reason
                    }
                    onChange={event => {
                      setLeadAutocompleteActivity(1);
                      LrrpConfigurationFormik.setFieldValue(
                        'lead_response_reason',
                        event.target.value
                      );
                      setOption(event.target.value);
                    }}
                  >
                    <MenuItem key="placeholder" value="placeholder" disabled>
                      <span style={{ color: '#9E9E9E' }}>
                        {' '}
                        Please make a selection
                      </span>
                    </MenuItem>
                    {LEAD_RESPONSE_REASON.map(reason =>
                      projectOtherDetailsData || reason !== 'Other' ? (
                        <MenuItem key={reason} value={reason}>
                          {reason}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </Grid>
                <LrrpConfigurationForm
                  option={option}
                  LrrpConfigurationFormik={LrrpConfigurationFormik}
                />
              </>
            )}
            {projectDetailsData &&
              projectDetailsData.source_system_id === 2 && ( // Home depot specific
                <HomeDepotFields
                  homeDepotOverrideOptions={homeDepotOverrideOptions}
                  hdEpaDocs={hdEpaDocs}
                  source_system_id={projectDetailsData?.source_system_id}
                  projectId={projectId}
                />
              )}
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            style={{ padding: '10px', gap: '10px' }}
          >
            {projectDetailsData &&
              projectDetailsData?.source_system_id !== 2 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmitButton}
                  disabled={!LrrpConfigurationFormik?.isValid}
                >
                  Submit
                </Button>
              )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EPALead;
