import { CONFIG } from '../../../constants';

const companyConfigConstants = {
  baseUrl: CONFIG.API_BASE_URL,

  tenantDataUpdateEndpoint: () => {
    return `/admin-config/`;
  },
};
export default companyConfigConstants;

export const SchedulerDefaultView = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

export const SCHEDULER_FEATURE_TYPE = {
  schedularDefaultView: 'schedularDefaultView',
  schedularRTSreportFilter: 'schedularRTSreportFilter',
  schedularCardView: 'schedularCardView',
};

export const SCHEDULER_CONFIG_DATA = {
  calender_view: {
    card_visibility: [],
  },
};

export const SCHEDULER_CARD_VIEW_OPTIONS = [
  {
    options: [
      { value: { description: 'Description' }, label: 'Description' },
      { value: { time: 'Time' }, label: 'Time' },
      { value: { store: 'Store #' }, label: 'Store #' },
      { value: { project_type: 'Type' }, label: 'Type' },
      { value: { category: 'Category' }, label: 'Category' },
      { value: { status: 'Status' }, label: 'Status' },
      { value: { total_sale: 'Total Sale $' }, label: 'Total Sale $' },
      { value: { total_revenue: 'Total Revenue $' }, label: 'Total Revenue $' },
      { value: { project_number: 'Project No' }, label: 'Project No' },
      { value: { client_name: 'Client Name' }, label: 'Client Name' },
      { value: { address: 'Address' }, label: 'Address' },
      { value: { phone: 'Phone' }, label: 'Phone' },
    ],
  },
  {
    type: {
      description: 'description',
      time: 'time',
      store: 'store',
      project_type: 'project_type',
      category: 'category',
      status: 'status',
      total_sale: 'total_sale',
      total_revenue: 'total_revenue',
      project_number: 'project_number',
      client_name: 'client_name',
      address: 'address',
      phone: 'phone',
    },
  },
];
