import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  closeIcon: {
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    padding: '22px 11px 16px 24px',
  },
  dialogHeader: {
    lineHeight: '17px',
    width: '100%',
  },
  divider: {
    color: '#E5E8EB',
  },
  dialogFooter: {
    height: '64px',
  },
  scrollPaper: props => ({
    overflow: props?.scrollHidden && 'hidden',
  }),
}));
