import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import './TaskManagement.css';
import TaskManagement from './TaskManagement';

const typeOfProject = 'taskType';

const Task = () => {
  const [visible, setVisible] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [refereshList, setRefereshList] = useState(false);
  const [checkProjectPage, setCheckProjectPage] = useState('');
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const urlParams = history?.location?.pathname;

    const viewEditParam = urlParams.split('/');
    const isProjectViewEdit = viewEditParam.slice(1, 3).join('/');
    if (
      isProjectViewEdit === 'project/view' ||
      isProjectViewEdit === 'project/edit'
    ) {
      setCheckProjectPage(true);
    } else {
      setCheckProjectPage(false);
    }
  }, [location]);
  const customHeader = (
    <React.Fragment>
      <div className="grid w-12 text-white m-0 text-center justify-content-center">
        <h2 className="m-0 pl-4">Task Planner</h2>
      </div>
    </React.Fragment>
  );
  return (
    <>
      <div className="card flex justify-content-center">
        <Sidebar
          baseZIndex="1000"
          visible={visible}
          onHide={() => {
            setIsAddMode(true);
            setRefereshList(!refereshList);
            setVisible(false);
          }}
          fullScreen
          className="task-management"
          icons={customHeader}
          pt={{
            header: {
              className: 'justify-content-between bg-primary',
            },
            icons: {
              className: 'w-12',
            },
          }}
        >
          <TaskManagement
            typeOfProject={typeOfProject}
            refereshOnClose={refereshList}
          />
        </Sidebar>
        {checkProjectPage ? (
          ''
        ) : (
          <Button
            icon="pi pi-book text-2xl"
            onClick={() => setVisible(true)}
            rounded
            severity="primary"
            className="p-1 fixed"
            style={{
              zIndex: '9999',
              bottom: '30px',
              left: '30px',
            }}
          />
        )}
      </div>
    </>
  );
};

export default Task;
