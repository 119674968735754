import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  autocompleteRoot: {
    width: '100%',
  },
  clearAll: {
    padding: '10px 12px 0px 0px',
    margin: '0px 8px',
  },
  filterschips: {
    marginTop: '8px',
  },
  footerTab: {
    position: 'fixed',
    bottom: 0,
  },

  indicator: {
    display: 'flex',
    justifyContent: 'center',
    background: '#609AF8',
    bottom: '8px',
    top: '0px',
  },
  menuRoot: {
    minWidth: '20%',
    background: theme.palette.common.white,
    left: '1000px !important',
    right: '100px',
    top: '100px !important',
  },
  paddingBody: {
    paddingBottom: '30px',
    margin: 0,
    padding: 0,
  },
  scrollButtons: {
    paddingTop: '4px',
    '& .MuiSvgIcon-fontSizeSmall': {
      width: '25px',
      height: '25px',
      fill: theme.palette.text.primary,
    },
  },
  typoRoot: {
    padding: '12px',
    borderBottom: `1px Solid ${theme.palette.grey[700]}`,
  },

  tabRoot: {
    background: theme.palette.grey[200],

    minHeight: '0px',
    borderTop: `1px Solid ${theme.palette.grey[700]}`,
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.palette.text.primary,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.primary,
      width: '14px',
      height: '14px',
    },
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
    '& .MuiButtonBase-root': {
      height: '40px',
      '&.MuiTab-root': {
        minHeight: '40px',
        paddingBottom: '7px',
      },
    },
  },
  tabPanelRoot: {
    padding: '0px',
    paddingTop: '8px',
  },
  tabTextColor: {
    color: theme.palette.grey[500],
  },

  tabsPaper: {
    flexGrow: 1,
    width: '100%',
    minHeight: '50px',
  },
  dialogSize: {
    minHeight: '80vh !important',
    maxWidth: '80vw !important',
  },
}));
