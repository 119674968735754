import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isClientsListLoading: true,
  clientsList: [],
  selectedClients: [],
  isAssignWidgetDialogVisible: false,
  isAssignWidgetValidateDialogVisible: false,
  selectedWidgets: [],
  validateTableData: [],
};

const assignWidgetsSlice = createSlice({
  name: 'assignWidgets',
  initialState: initialState,
  reducers: {
    setAssignWidgets(state, { payload }) {
      state.isClientsListLoading = payload.hasOwnProperty(
        'isClientsListLoading'
      )
        ? payload.isClientsListLoading
        : state.isClientsListLoading;
      state.clientsList = payload.hasOwnProperty('clientsList')
        ? payload.clientsList
        : state.clientsList;
      state.selectedClients = payload.hasOwnProperty('selectedClients')
        ? payload.selectedClients
        : state.selectedClients;
      state.isAssignWidgetDialogVisible = payload.hasOwnProperty(
        'isAssignWidgetDialogVisible'
      )
        ? payload.isAssignWidgetDialogVisible
        : state.isAssignWidgetDialogVisible;
      state.isAssignWidgetValidateDialogVisible = payload.hasOwnProperty(
        'isAssignWidgetValidateDialogVisible'
      )
        ? payload.isAssignWidgetValidateDialogVisible
        : state.isAssignWidgetValidateDialogVisible;
      state.selectedWidgets = payload.hasOwnProperty('selectedWidgets')
        ? payload.selectedWidgets
        : state.selectedWidgets;
      state.validateTableData = payload.hasOwnProperty('validateTableData')
        ? payload.validateTableData
        : state.validateTableData;
    },
  },
});

export const { setAssignWidgets } = assignWidgetsSlice.actions;

export const assignWidgetsReducer = assignWidgetsSlice.reducer;
