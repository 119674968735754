import { makeStyles } from '@material-ui/core/styles';

import { Colors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  //PDF styles
  droppedItemsContainer: {
    position: 'relative',
  },
  pspdfContainer: {
    width: '800px',
    height: '1000px',
    display: 'none',
  },
  front: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'transparent',
  },
  closeButton: {
    width: '10px',
    height: '10px',
    padding: 0,
    alignSelf: 'center',
    position: 'absolute',
    bottom: '18px',
  },
  signatureCloseButton: {
    width: '10px',
    height: '10px',
    padding: 0,
    alignSelf: 'center',
    position: 'absolute',
    bottom: '50px',
    left: '200px',
  },
  iframeContainer: {
    position: 'relative',
    backgroundColor: 'white',
    zIndex: 999,
    margin: '0 auto',
  },
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '12px',
  },
  tableContainer: {
    maxHeight: 440,
  },
  dragTextInputField: {
    width: '139px',
    height: '50px',
    color: 'black',
    cursor: 'pointer',
  },
  dragBox: {
    width: '97px',
    height: '81px',
    display: 'flex',
    border: `1px solid ${Colors.PF_BORDER}`,
    borderRadius: '10px',
    alignItems: 'center',
    fontStyle: 'italic',
    justifyContent: 'center',
    marginBottom: '16px',
    marginTop: '16px',
  },
  itemImg: {
    width: '30px',
    height: '30px',
  },
  itemBox: {
    width: '80px',
    height: '85px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableFirstColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 9,
  },
  tableFirstColumnBody: {
    position: 'sticky',
    left: 0,
    zIndex: 6,
    background: 'white',
    border: '1px solid grey',
  },
  cardFooter: {
    padding: '10px 20px',
    justifyContent: 'flex-end',
  },
  signaturebox: {
    width: '200px',
    height: '50px',
    border: '1px dashed skyblue',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  todaybox: {
    width: '50px',
    height: '20px',
    cursor: 'pointer',
    border: '1px dashed skyblue',
  },
  todayCloseButton: {
    width: '10px',
    height: '10px',
    padding: 0,
    alignSelf: 'center',
    position: 'absolute',
    bottom: '20px',
    left: '50px',
  },
  tablewidgetContainer: {
    height: 'auto',
    width: '400px',
    border: '1px solid #E5E8EB',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    position: 'absolute',
    margin: 0,
    zIndex: 1000,
  },
  sourcetable: {
    display: 'flex',
    flexDirection: 'column',
  },
  action: {
    margin: 0,
    alignSelf: 'center',
  },
  widgetText: {
    padding: '15px 20px',
    //width: "100%",
    fontSize: '10px',
    borderBottom: '1px solid #E5E8EB',
  },
  dragText: {
    border: '1px solid #E5E8EB',
    height: '50px',
    width: '50px',
  },
  //document

  documentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    position: 'relative',
    //overflow: "scroll",
  },
  input: {
    cursor: 'pointer',
    color: 'red',
    border: 'none',
    padding: 0,
  },
  listItem: {
    padding: '8px 20px',
    fontSize: '14px',
    borderLeft: `8px solid white`,
    '&.Mui-selected': {
      backgroundColor: 'none',
      borderLeftStyle: 'solid',
      borderLeftColor: 'none',
      borderLeftWidth: '8px',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'none',
      borderLeftStyle: '​solid',
      borderLeftColor: 'none',
      borderLeftWidth: '8px',
    },
  },
  dialog: {
    padding: theme.spacing(2),
  },
  formControl: {
    padding: theme.spacing(1),
    minWidth: '100%',
  },
  label: {
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputfileupload: {
    marginTop: '20px',
  },
  checkbox: {
    transform: 'scale(1.75)',
    padding: 0,
    margin: '5px 10px',
  },
  draggableText: {
    width: '139px',
    //height: '50px',
    cursor: 'pointer',
    '& .MuiInput-underline:before': {
      border: '1px solid #92A9BE',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '1px solid #92A9BE',
    },
    '& .MuiInput-underline.Mui-focused': {
      border: `1px solid #92A9BE`,
    },
  },
  iframe: {
    position: 'absolute',
    border: 'solid 1px #000',
    marginBottom: 0,
  },
  pdfContainer: {
    position: 'relative',
  },

  //widgets
  widgetContainer: {
    height: '370px',
    width: '264px',
    border: '1px solid #E5E8EB',
    borderRadius: '20px',
    margin: '10px',
    position: 'fixed',
    top: 140,
    right: 0,
    zIndex: 1000,
    backgroundColor: Colors.PF_WHITE,
    boxShadow: '0 0 2px 0 rgba(145,158,171,0.24)',
  },
  //existing
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  clientNotFound: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: '20px',
    minHeight: '250px',
    alignItems: 'center',
  },
  container: {
    maxHeight: 440,
  },
  containerHeader: {
    height: '50px',
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '22px',
    paddingBottom: '14px',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    borderRadius: '20px 20px 0px 0px',
  },
  containerContent: {
    display: 'flex',
    padding: '24px',
  },
  formWrapper: {
    width: '100%',
  },
  footer: {
    position: 'relative',
    bottom: 0,
    paddingTop: '0px',
    marginTop: '10px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      maxWidth: 80,
      width: '100%',
    },
  },
  paperRoot: {
    borderRadius: '20px',
    minHeight: '250px',
    border: 'none',
    backgroundColor: 'none',
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  //Ordered
  dragWrapper: {
    height: '40px',
  },
  widgetIcon: {
    width: '37px',
    height: '40px',
    fill: Colors.PF_WIDGETCOLOR,
    cursor: 'move',
  },
  checkboxRoot: {
    padding: '9px 0px',
    width: '23px',
    height: '23px',
  },
  checkboxFormRoot: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
  },
}));
