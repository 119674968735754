// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

/**
 * Fetch Configuration Entities.
 *
 * @param {*} entityType
 * @param {*} setConfigurationEntities
 * @param {*} setLoading
 */
export const fetchConfigurationEntities = async (
  entityType,
  setConfigurationEntities,
  setLoading,
  setAlert
) => {
  try {
    setLoading(true);
    if (entityType) {
      const response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`
      );
      if (response?.data) {
        setConfigurationEntities(response.data);
      }
    }
  } catch (error) {
    console.log(error);
    setAlert(
      'error',
      `Error occurred while fetching ${entityType} configurations.`,
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};

/**
 * Save Entity Configuration.
 *
 * @param {*} entityType
 * @param {*} data
 * @param {*} setLoading
 * @param {*} setAlert
 */
export const saveConfigurationEntities = async (
  entityType,
  data,
  setLoading,
  setAlert,
  setReloadFieldConfigurationList
) => {
  try {
    setLoading(true);
    if (entityType) {
      // Get Entity Configuration to check if field exist or if it's first time call.
      const configurationEntities = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`
      );
      let response = null;
      let responseMessage = 'Configuration has been updated successfully.';
      if (configurationEntities?.data?.length) {
        const config = { ...configurationEntities?.data[0].config };
        const fields = [...config.field_groups[0].fields];
        let runPatchCall = false;
        // Patch Call.
        if (data?.slug) {
          runPatchCall = true;
          const getIndex = fields.findIndex(obj => obj.slug === data?.slug);
          fields[getIndex] = data;
        } else {
          //check if name exist.
          if (fields.some(obj => obj.name === data?.name)) {
            setAlert(
              'error',
              `Field with name ${data?.name} already exist, please try again with different name.`,
              false,
              true
            );
          } else {
            runPatchCall = true;
            const patchSlug = data?.name
              ?.toLowerCase()
              .replace(/[^a-zA-Z0-9]+/g, '_');
            fields.push({
              ...data,
              ...{ slug: patchSlug },
            });
          }
        }
        if (runPatchCall) {
          config.field_groups[0].fields = fields;
          response = await axios.patch(
            `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`,
            config
          );
          if (response?.data) {
            setAlert('success', responseMessage, false, true);
            setReloadFieldConfigurationList(true);
          }
        }
      } else {
        responseMessage = 'Configuration has been added successfully.';
        const postSlug = data?.name
          ?.toLowerCase()
          .replace(/[^a-zA-Z0-9]+/g, '_');
        const postData = {
          ...data,
          ...{ slug: postSlug },
        };
        const apiData = {
          slug: entityType,
          type: 'SYSTEM',
          group: 'Projects',
          is_enabled: true,
          display_name: {
            plural: 'Projects',
            singular: 'Project',
          },
          field_groups: [
            {
              name: 'Project Information',
              slug: 'project_information',
              fields: [postData],
            },
          ],
        };
        response = await axios.post(
          `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`,
          apiData
        );
        if (response?.data) {
          setAlert('success', responseMessage, false, true);
          setReloadFieldConfigurationList(true);
        }
      }
    }
  } catch (error) {
    console.log(error);
    setAlert(
      'error',
      `Error occurred while fetching ${entityType} configurations.`,
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};

export const deleteConfigurationEntities = async (
  entityType,
  data,
  setLoading,
  setAlert,
  setReloadFieldConfigurationList
) => {
  try {
    setLoading(true);
    if (entityType) {
      // Get Entity Configuration to check if field exist or if it's first time call.
      const configurationEntities = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`
      );
      if (configurationEntities?.data?.length) {
        const config = { ...configurationEntities?.data[0].config };
        const fields = [...config.field_groups[0].fields];
        if (data?.slug?.value) {
          config.field_groups[0].fields =
            fields?.filter(item => item.slug !== data?.slug?.value) || [];
          const response = await axios.patch(
            `${URL_CONSTANTS.API.BASE_URL}/configurator/entities?entityName=${entityType}`,
            config
          );
          if (response?.data) {
            setAlert('success', 'Record deleted successfully.', false, true);
            setReloadFieldConfigurationList(true);
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    setAlert(
      'error',
      `Error occurred while fetching ${entityType} configurations.`,
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};
