import React, { useState, useEffect } from 'react';
import { Message } from 'primereact/message';

import SkeletonLoader from '../../../shared/Loader/skeleton';
import usePFDataTable from '../../../shared/PFPrime/PFDatatable.jsx';
import { getInvoiceList, invoiceGridColumns } from '../helpers/Invoice.js';

const InvoiceLising = ({ projectId }) => {
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();
  const [loading, setLoading] = useState(false);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);

  const invoiceApiCall = () => {
    setLoading(true);

    const listing = getInvoiceList(projectId);
    listing
      .then(data => {
        if (data?.status === 200) {
          const listData = data?.data?.data;
          console.log('listData', listData);
          const sum = listData.reduce(
            (sum, item) => sum + (item.total_invoice_amount || 0),
            0
          );
          setTotalInvoiceAmount(sum);
          tableData(listData);
          totalRecords(listData.length || 0);
          setLoading(false);
        }
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    setDefaultTableProps(preProps => ({ ...preProps, lazy: false }));
    DataTableColumns(invoiceGridColumns());
    invoiceApiCall();
  }, []);

  return (
    <>
      <div className="pt-2">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="py-2">
              <Message
                severity="info"
                text={() => {
                  return (
                    <div>
                      <strong>
                        Total Invoice Amount: $ {totalInvoiceAmount?.toFixed(2)}
                      </strong>
                    </div>
                  );
                }}
                className="border-primary w-full justify-content-start"
                style={{
                  border: 'solid #696cff',
                  borderWidth: '0 0 0 6px',
                }}
                pt={{
                  root: {
                    className: 'surface-ground',
                  },
                }}
              />
            </div>
            <TableLayout />
          </>
        )}
      </div>
    </>
  );
};

export default InvoiceLising;
