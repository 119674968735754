import axios from 'axios';

import { URL_CONSTANTS } from '../constants/urlConstants';

class ApiService {
  async get(url) {
    const response = await axios.get(URL_CONSTANTS.API.BASE_URL + url);
    return response.data;
  }

  async postAuditLog(url, payload) {
    const response = await axios.post(
      URL_CONSTANTS.AUDIT_LOG.baseUrl + url,
      payload
    );
    return response.data;
  }

  async post(url, payload) {
    const response = await axios.post(
      URL_CONSTANTS.API.BASE_URL + url,
      payload
    );
    return response.data;
  }

  async userManagementPost(url, payload) {
    const response = await axios.post(
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl + url,
      payload
    );
    return response.data;
  }

  async put(url, payload) {
    const response = await axios.put(URL_CONSTANTS.API.BASE_URL + url, payload);
    return response.data;
  }
  async patch(url, payload) {
    const response = await axios.patch(
      URL_CONSTANTS.API.BASE_URL + url,
      payload
    );
    return response.data;
  }

  async delete(url) {
    const response = await axios.delete(URL_CONSTANTS.API.BASE_URL + url);
    return response.data;
  }

  async getWithDiffBaseUrl(url, payload, baseUrl = URL_CONSTANTS.API.BASE_URL) {
    const response = await axios.get(baseUrl + url, {
      ...(payload && {
        params: payload,
      }),
    });
    return response.data;
  }

  async postWithDiffBaseUrl(
    url,
    payload,
    baseUrl = URL_CONSTANTS.API.BASE_URL,
    headers = ''
  ) {
    const response = await axios.post(baseUrl + url, payload, headers);
    return response.data;
  }

  async patchWithDiffBaseUrl(
    url,
    payload,
    baseUrl = URL_CONSTANTS.API.BASE_URL
  ) {
    const response = await axios.patch(baseUrl + url, payload);
    return response.data;
  }

  async deleteWithDiffBaseUrl(
    url,
    payload,
    baseUrl = URL_CONSTANTS.API.BASE_URL
  ) {
    const response = await axios.delete(baseUrl + url, payload);
    return response.data;
  }

  async putWithDiffBaseUrl(url, payload, baseUrl = URL_CONSTANTS.API.BASE_URL) {
    const response = await axios.put(baseUrl + url, payload);
    return response.data;
  }

  formatUrl(formatString, ...args) {
    return formatString.replace(/%s/g, () => args.shift());
  }
}

export default new ApiService();
