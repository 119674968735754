import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { getQueryResult } from '../../../Admin/Widget/service/widget.service';

//To fetch projects by project number/project id
export const getProjects = async searchStringVal => {
  try {
    const response = await ApiService.get(
      `${URL_CONSTANTS.CHARGEBACK.projectSummary.search}${searchStringVal}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To create new Chargeback Reason
export const createChargebackReason = async value => {
  try {
    let body = { name: value };
    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.chargebackReason}`,
      body,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchChargebackReason = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/reason/list`,
      query,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To save chargeback
export const addEditChargeback = async (
  body,
  chargebackId = null,
  isDelete = false
) => {
  try {
    let response;
    if (chargebackId) {
      if (isDelete) {
        response = await ApiService.deleteWithDiffBaseUrl(
          `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}`,
          { data: body },
          URL_CONSTANTS.CHARGEBACK.baseUrl
        );
      } else {
        response = await ApiService.putWithDiffBaseUrl(
          `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}`,
          body,
          URL_CONSTANTS.CHARGEBACK.baseUrl
        );
      }
    } else {
      response = await ApiService.postWithDiffBaseUrl(
        `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.chargebackCreate}`,
        body,
        URL_CONSTANTS.CHARGEBACK.baseUrl
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch chargeback with details
export const getChargeback = async chargebackId => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}`,
      null,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch payout list of a chargebackId
export const getChargebackPayouts = async (chargebackId, queryParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/payout/list`,
      queryParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const searchPayoutCategory = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/payout/category/list`,
      query,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const createPayoutCategory = async body => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/payout/category/create`,
      body,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To upload and save photos
export const uploadPhotos = async (chargebackId, files = []) => {
  try {
    const formData = new FormData();
    if (files) {
      files?.map(file => formData.append('files', file));
    }
    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/upload/photos`,
      formData,
      URL_CONSTANTS.CHARGEBACK.baseUrl,
      { headers: headers }
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch photos
export const fetchPhotos = async chargebackId => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/photos/list`,
      null,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch items list
export const fetchItems = async chargebackId => {
  try {
    const response = await ApiService.get('/system/items');

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch Labor Items list
export const getLaborItems = async (chargebackId, urlParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/labor-items/list`,
      urlParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch Merchandise Items list
export const getMerchandiseItems = async (chargebackId, urlParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/merchandise-items/list`,
      urlParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
//To fetch Notes
export const getNotes = async (chargebackId, urlParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.chargebackSummary.getChargeback}/${chargebackId}/notes/list`,
      urlParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To fetch Chargeback list
export const getChargebackList = async urlParams => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      URL_CONSTANTS.CHARGEBACK.list,
      urlParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
