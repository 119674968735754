import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isFileUploading: false,
  documentsData: [],
};

const userFormDocumentsSlice = createSlice({
  name: 'userFormDocuments',
  initialState: initialState,
  reducers: {
    setUserDocumentsForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.isFileUploading = payload.hasOwnProperty('isFileUploading')
        ? payload.isFileUploading
        : state.isFileUploading;
      state.documentsData = payload.hasOwnProperty('documentsData')
        ? payload.documentsData
        : state.documentsData;
      state.newUserDocument = payload.hasOwnProperty('newUserDocument')
        ? payload.newUserDocument
        : state.newUserDocument;
    },
  },
});

export const { setUserDocumentsForm } = userFormDocumentsSlice.actions;

export const userFormDocumentsReducer = userFormDocumentsSlice.reducer;
