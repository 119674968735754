// **** React Imports ****
import React from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';

// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Icon,
  Typography,
  Box,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// **** Custom Components ****
import PageHeader from '../PageHeader/PageHeader';
import GenericDataTable from '../GenericDataTable/GenericDataTable';
import { routes } from '../../../config/routes';

// **** Services *****
import { getClientProjects } from './ProjectSearchResults.service';

// **** Styles/Images/Icons ****
import { useStyles } from './ProjectSearchResults.styles';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const ProjectSearchResults = () => {
  const { clientId } = useParams();

  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();

  const classes = useStyles();
  const [clientProjectsListData, setClientProjectsListData] = React.useState(
    []
  );

  const previousScreen = routes.find(
    route => route.path === history.location.state?.from
  );

  const clientsBreadcrumb = [
    {
      text: 'Home',
    },
    {
      text: previousScreen?.title || 'Dashboard',
      link: previousScreen?.path || '/',
    },
    {
      text: 'Search Results',
    },
  ];

  const dataTableColumns = [
    {
      name: 'source_name',
      label: 'Source',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({ style: { width: '12%' } }),
      },
    },
    {
      name: 'date_sold',
      label: 'Date Sold',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '12%' } }),
      },
    },
    {
      name: 'store_number',
      label: 'Store No',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '10%' } }),
      },
    },
    {
      name: 'installer',
      label: 'Technician',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: 'project_number',
      label: 'Project',
      options: {
        sort: true,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: 'client_name',
      label: 'Client Name',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: 'project_status',
      label: 'Status',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { minWidth: '90px', maxWidth: '90px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span style={{ display: 'none' }} id={dataIndex}>
              <span>
                <IconButton classes={{ root: classes.actionIcons }}>
                  <Link
                    to={`/project/view/${dataArray && dataArray[dataIndex]?.project_id}`}
                  >
                    <VisibilityIcon />
                  </Link>
                </IconButton>
              </span>
              <span>
                <IconButton classes={{ root: classes.actionIcons }}>
                  <Link
                    to={`/project/edit/${dataArray && dataArray[dataIndex]?.project_id}`}
                  >
                    <EditIcon />
                  </Link>
                </IconButton>
              </span>
            </span>
          );
        },
      },
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_query: searchString,
      }));
    }, 500);
  };

  React.useEffect(() => {
    getClientProjects(
      clientId,
      searchQuery,
      setLoading,
      setClientProjectsListData
    );
  }, [clientId, searchQuery]);

  let dataArray = clientProjectsListData?.map((val, index) => {
    return {
      source_name: val?.source_system?.logo_url ? (
        <Icon fontSize="large" component="span">
          <img
            width="40"
            height="30"
            alt="ProjectForce"
            src={val?.source_system?.logo_url}
          />
        </Icon>
      ) : (
        <Box className={classes.sourceName}>
          <Typography variant="subtitle2">
            {val?.source_system?.source_name}
          </Typography>
        </Box>
      ),
      project_description: val?.project_category?.category,
      date_sold: val.date_sold,
      store_number: val?.store?.store_number,
      installer: val.Installer,
      project_number: val.project_number,
      client_name: (
        <Typography variant="subtitle2" color="primary">
          {val?.customer?.first_name
            ? `${val?.customer?.first_name} ${val?.customer?.last_name} `
            : ''}
        </Typography>
      ),
      project_status: (
        <Typography
          variant="subtitle2"
          className={
            (val.project_status === 'Completed' &&
              classes.statusColorCompleted) ||
            (val.project_status === 'Completed' &&
              classes.statusColorCancelled) ||
            classes.statusColor
          }
        >
          {val?.status?.status || ''}
        </Typography>
      ),
      project_id: val.project_id,
    };
  });

  const dataTableOptions = {
    download: false,
    print: false,
    selectableRows: false,
    responsive: 'standard',
    filterType: 'multiselect',
    filter: false,
    tableBodyMinHeight: '300px',
    count: clientProjectsListData.length || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray?.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        order: encodeURIComponent(JSON.stringify([[changedColumn, direction]])),
      }));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {},
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        onDoubleClick: () => {
          history.push(
            `/project/view/${dataArray ? dataArray[dataIndex].project_id : ''}`
          );
        },
      };
    },
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Search Results"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
          <Grid item classes={{ root: classes.selfAlignGrid }}></Grid>
        </Grid>

        <Grid item>
          {
            <GenericDataTable
              title="Reports"
              columns={dataTableColumns}
              data={dataArray}
              options={dataTableOptions}
            />
          }
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectSearchResults;
