import * as Yup from 'yup';

export const companyInformationInitialValues = {};

export const companyInformationValidationSchema = {
  company_name: Yup.string().nullable(),
  job_title: Yup.string().max(200).nullable(),
  industry_id: Yup.number().nullable(),
  company_size: Yup.string().nullable(),
  annual_revenue: Yup.number().nullable(),
  customer_related_notes: Yup.string().max(512).nullable(),
};
