import React from 'react';
import moment from 'moment';
import { Image } from 'primereact/image';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';
import { setUserManagementComponent } from '../../../../redux/slices/user-management.slice';
import { updateUserStatus } from '../UserManagement.service';

import { USER_STATUS } from './helpers/helper';

export const getRolesList = async () => {
  try {
    const rolePromise = await apiService.get(
      '/auth/role/list?limit=100&offset=0'
    );
    return rolePromise.items;
  } catch (error) {
    console.error(error);
  }
};

export const handleDataTableActionButton = props => {
  const currentUserIndex = props?.usersListData?.findIndex(
    user => user?.user_id === props?.userId
  );
  let nextUserId;
  let prevUserId;
  if (currentUserIndex !== -1) {
    nextUserId = props?.usersListData?.at(currentUserIndex + 1)?.user_id;
    prevUserId = props?.usersListData?.at(currentUserIndex - 1)?.user_id;

    props?.dispatch(
      setUserManagementComponent({
        nextUserId,
        prevUserId,
      })
    );
  }

  switch (props?.actionName) {
    case 'view':
      props?.history.push(
        `/admin/usermanagement/user-form/view/${props?.userId}`
      );
      break;
    case 'edit':
      props?.history.push(
        `/admin/usermanagement/user-form/edit/${props?.userId}`
      );
      break;
    default:
      break;
  }
};

export const handleIsActiveStatus = async (userId, checked) => {
  try {
    const statusId = checked ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;
    const response = await apiService.patch(
      `/auth/user/manage/active-deactive-user/${userId}`,
      {
        statusId: statusId,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.error);
  }
};

export const handleSendInvite = async userId => {
  const response = await apiService.post('/auth/user/manage/sendEmailInvite', {
    users: [{ userId: userId }],
  });
};

export const getUserList = async (
  rows,
  page,
  sortBy,
  orderBy,
  filters = []
) => {
  try {
    let queryParams = `limit=${rows || 10}`;
    if (page) {
      queryParams = `${queryParams}&offset=${page * rows}`;
    }
    const payload = {
      sortBy,
      sortOrder: orderBy === 1 ? 'ASC' : 'DESC',
    };
    if (filters.length > 0) {
      payload['filters'] = filters;
    }

    const response = await apiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.USER_MANAGEMENT.filters.filterList}?${queryParams}`,
      payload,
      URL_CONSTANTS?.USER_MANAGEMENT?.baseUrl
    );
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const userGridColumns = (userList, userAction) => {
  let main = [
    {
      field: '',
      header: '',
      body: rowData => {
        return (
          <div className="flex flex-row" style={{ gap: '10px' }}>
            <i
              className="pi pi-pencil cursor-pointer text-color-900"
              onClick={() =>
                userAction({
                  actionName: 'edit',
                  usersListData: userList,
                  userId: rowData.user_id,
                })
              }
            ></i>
            {rowData?.status === USER_STATUS.ACTIVE && (
              <i
                className="pi pi-envelope cursor-pointer text-color-900"
                onClick={() =>
                  userAction({
                    userId: rowData.user_id,
                    actionName: 'sendInvite',
                  })
                }
              ></i>
            )}
          </div>
        );
      },
    },
    {
      field: 'user_name',
      header: 'Name',
      sortable: true,
      headerStyle: {
        minWidth: 250,
      },
      style: { minWidth: '300px' },
      body: rowData => {
        return (
          <div className="flex align-items-center">
            {rowData.photo ? (
              <Image
                src={rowData.photo}
                alt="Image"
                preview={!!rowData.photo}
                className="w-3rem shadow-2 border-round"
                pt={{
                  image: { className: 'w-full' },
                }}
              />
            ) : (
              <Avatar icon="pi pi-user" size="large" shape="circle" />
            )}
            <span className="ml-2">{rowData.user_name}</span>
          </div>
        );
      },
    },
    {
      field: 'mobile_phone',
      header: 'Mobile',
      headerStyle: {
        minWidth: 200,
      },
    },
    {
      field: 'email',
      header: 'Email',
    },
    {
      field: 'role_name',
      header: 'Role',
      sortable: true,
      headerStyle: {
        minWidth: 150,
      },
    },
    {
      field: 'employment_type',
      header: 'Employment Type',
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      body: rowData => {
        return (
          <Checkbox
            checked={rowData?.status === USER_STATUS.ACTIVE}
            onChange={event => {
              userAction({
                userId: rowData.user_id,
                status: event.checked,
                actionName: 'changeStatus',
              });
            }}
          />
        );
      },
    },
    {
      field: 'is_job_assignment_enabled',
      header: 'Job Assignment Enabled',
      body: rowData => {
        const option = rowData.is_job_assignment_enabled ? 'Yes' : 'No';
        return (
          <Button
            label={option}
            severity={option === 'Yes' ? 'success' : 'danger'}
            outlined
            size="small"
            disabled
            className="p-2"
          />
        );
      },
    },
    {
      field: 'is_access_coverage',
      header: 'Has Access Coverage',
      body: rowData => {
        const option = rowData.is_access_coverage === 1 ? 'Yes' : 'No';
        return (
          <Button
            label={option}
            severity={option === 'Yes' ? 'success' : 'danger'}
            outlined
            size="small"
            disabled
            className="p-2"
          />
        );
      },
    },
    {
      field: 'is_job_access_coverage',
      header: 'Has Job Coverage',
      body: rowData => {
        const option = rowData.is_job_access_coverage === 1 ? 'Yes' : 'No';
        return (
          <Button
            label={option}
            severity={option === 'Yes' ? 'success' : 'danger'}
            outlined
            size="small"
            disabled
            className="p-2"
          />
        );
      },
    },
    {
      field: 'last_visited',
      header: 'Last Visited',
      sortable: true,
      headerStyle: {
        minWidth: 200,
      },
      body: rowData => {
        return rowData.last_visited
          ? moment(rowData.last_visited).format('MM-DD-YYYY hh:mm A')
          : 'Never';
      },
    },
    {
      field: 'created_at',
      header: 'Date Created',
      sortable: true,
      headerStyle: {
        minWidth: 200,
      },
      body: rowData => {
        return rowData.created_at
          ? moment(rowData.created_at).format('MM-DD-YYYY hh:mm A')
          : '';
      },
    },
  ];

  return main;
};

export const getAllUsersList = async () => {
  try {
    const payload = [];
    const response = await apiService.postWithDiffBaseUrl(
      `/api/user-list/users?limit=500&offset=0`,
      payload,
      URL_CONSTANTS?.USER_MANAGEMENT?.baseUrl
    );
    return response?.records;
  } catch (error) {
    throw new Error(error.message);
  }
};
