import React, { useState } from 'react';
import { Avatar } from 'primereact/avatar';

const ProfileIcon = ({ eventInfo, nameLeffters }) => {
  const [placeholderIcon, setPlaceholderIcon] = useState(false);

  return eventInfo?.resource?._resource?.extendedProps?.avtarIcon != null &&
    !placeholderIcon ? (
    <img
      src={eventInfo?.resource?._resource?.extendedProps?.avtarIcon}
      alt={eventInfo?.resource?._resource?.extendedProps?.avtarIcon}
      onError={event => {
        if ((event.type = 'error')) {
          setPlaceholderIcon(true);
        }
      }}
      width={32}
      height={32}
      className="border-circle"
    />
  ) : (
    <Avatar
      label={nameLeffters}
      style={{ backgroundColor: '#bdbdbd', color: '#ffffff' }}
      shape="circle"
    />
  );
};

export default ProfileIcon;
