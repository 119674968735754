import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function PFTableLoader() {
  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <DataTable value={items} className="p-datatable-striped w-12 my-3">
      <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
      <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
      <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
      <Column style={{ width: '25%' }} body={<Skeleton />}></Column>
    </DataTable>
  );
}
