import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserCredentialsData = async userId => {
  try {
    const credentialsTypeRequest = ApiService.get(`/system/credential-type`);
    const insuranceTypeRequest = ApiService.get(`/system/insurance-type`);
    const userDetailsRequest = ApiService.get(`/auth/user/manage/${userId}`);

    const [credentialsTypeList, insuranceTypeResponse, userDetailsResponse] =
      await Promise.all([
        credentialsTypeRequest,
        insuranceTypeRequest,
        userDetailsRequest,
      ]);

    return {
      credentialsTypeList: credentialsTypeList,
      insuranceTypeList: insuranceTypeResponse,
      credentials: userDetailsResponse?.credentials || [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const editUserCredentials = async (userId, credentialsInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        credentials: JSON.stringify(credentialsInfo),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
