import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
  Radio,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
} from '@material-ui/core';

const LrrpDocumentation = ({ LrrpConfigurationFormik, setBtnDisables }) => {
  return (
    <Grid container item direction="row" spacing={2}>
      <Grid
        container
        item
        xs={12}
        direction="row"
        spacing={2}
        style={{ padding: '10px' }}
      >
        <Grid item xs={4} style={{ marginTop: '4px' }}>
          <TextField
            id="name"
            label="Lrrp Explanation"
            name="name"
            value={LrrpConfigurationFormik.values?.other?.lrrp_explanation}
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('other', {
                ...LrrpConfigurationFormik.values.other,
                lrrp_explanation: event.target.value,
              });
              setBtnDisables(false);
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <label style={{ fontSize: '1em' }}>Document Required</label>
          <Select
            label="Document Required"
            value={LrrpConfigurationFormik.values?.other?.document_required}
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('other', {
                ...LrrpConfigurationFormik.values.other,
                document_required: event.target.value,
              });
              setBtnDisables(false);
            }}
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem
              value={
                LrrpConfigurationFormik.values?.other?.document_required ===
                'no'
                  ? 'no'
                  : 'N/A'
              }
            >
              No
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid item xs={8}>
        <label
          shrink
          style={{ marginBottom: '8px', fontSize: '1em' }}
          id="woExplanation"
        >
          WO Explanation{' '}
          <span style={{ color: '#f44336', fontSize: '0.9em' }}>*</span>
        </label>
        <Grid>
          <TextareaAutosize
            minRows={5}
            id="woExplanation"
            labelId="woExplanation"
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('other', {
                ...LrrpConfigurationFormik.values.other,
                wo_explanation: event.target.value,
              });
              setBtnDisables(false);
            }}
            value={LrrpConfigurationFormik.values?.other?.wo_explanation}
            maxLength={2000}
            InputLabelProps={{ shrink: true }}
            required
            style={{ width: '48%', marginTop: '8px', resize: 'none' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LrrpDocumentation;
