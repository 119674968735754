import { useState, useRef, useEffect } from 'react';
import { AutoComplete } from 'primereact';

import { searchDistrict } from './services/user-form-coverage.service';

const DistrictPicker = ({ options }) => {
  const [districtSearchResult, setDistrictSearchResult] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState(
    options?.value?.values
  );
  const districtRef = useRef(null);

  const suggestDistrictName = async (event = null) => {
    const searchStoreResponse = await searchDistrict(event?.query || '');
    setDistrictSearchResult(searchStoreResponse);
  };
  const displayDistrictRef = async e => {
    suggestDistrictName();
    if (districtRef.current) {
      districtRef.current.show();
    }
  };
  const displayDistrictRefOnClick = async e => {
    if (districtRef.current) {
      districtRef.current.show();
    }
  };
  useEffect(async () => {
    await suggestDistrictName();
  }, []);

  return (
    <AutoComplete
      ref={districtRef}
      id="store"
      className="w-12 mt-2"
      suggestions={districtSearchResult}
      field="district"
      multiple
      completeMethod={suggestDistrictName}
      value={selectedDistricts}
      onChange={e => {
        {
          let selectedValues = e?.target?.value;
          options.editorCallback({
            type: options?.value?.type,
            values: selectedValues,
          });
          setSelectedDistricts(selectedValues);
        }
      }}
      placeholder="Search"
      onFocus={displayDistrictRef}
      onClick={displayDistrictRefOnClick}
      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
      pt={{
        container: {
          className: 'w-12 h-auto py-1',
        },
        inputtoken: {
          className: 'p-0',
        },
        input: {
          className: 'text-sm',
        },
      }}
    />
  );
};

export default DistrictPicker;
