import React, { useEffect, useState, useRef } from 'react';
/* Prime Imports */
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { ProgressSpinner } from 'primereact/progressspinner';

/*External Library Imports*/
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';

/*Custom Components*/
import SkeletonLoader from '../../shared/Loader/skeleton';
import {
  setUserCrewForm,
  clearUserCrewForm,
} from '../../../redux/slices/user-form-crew.slice';
import { setUserCrewMember } from '../../../redux/slices/user-form-crew-member.slice';

import {
  createUserCrew,
  createUserCrewType,
  getUserCrewList,
  searchCrewType,
  updateUserCrewDetailById,
} from './services/user-form-crew.service';
import UserFormCrewMember from './user-form-crew-member';
import { domHandlerCall } from './common';

const crewDetailsInitialValues = {
  name: null,
  crewTypeId: null,
};

const CrewDetailSection = ({
  crew,
  cancel,
  toast,
  setSelectedCrew,
  userId,
}) => {
  const { userDetails } = useSelector(state => state.userFormDetails);
  const { crewMemberDataCount } = useSelector(
    state => state.userFormCrewMember
  );
  const [crewTypesSearchResults, setCrewTypesSearchResults] = useState([]);
  const [currentCrew, setCurrentCrew] = useState(crew);
  const [isLoading, setIsLoading] = useState(false);
  const crewTypesRef = useRef(null);
  const formik = useFormik({
    initialValues: { ...crewDetailsInitialValues },
    onSubmit: values => {},
    enableReinitialize: true,
  });
  const saveCrew = async value => {
    const crewType = value?.type_id;

    if (formik?.values?.name && crewType) {
      const body = {
        name: formik.values.name,
        type_id: crewType,
      };
      setIsLoading(true);
      if (currentCrew?.crew_id) {
        const response = await updateUserCrewDetailById(
          userId,
          currentCrew.crew_id,
          body
        );
        if (response?.data) {
          setCurrentCrew(response?.data);
          setSelectedCrew(response?.data);
          toast?.current?.show({
            severity: 'success',
            summary: response?.message,
            life: 2000,
          });
        }
      } else {
        const response = await createUserCrew(userId, body);
        if (response?.data) {
          setCurrentCrew(response?.data);
          setSelectedCrew(response?.data);
          toast?.current?.show({
            severity: 'success',
            summary: response?.message,
            life: 2000,
          });
        }
      }
      setIsLoading(false);
    }
  };
  const suggestCrewTypes = async (event = null) => {
    const searchCrewTypeResponse = await searchCrewType(event?.query || '');
    if (searchCrewTypeResponse?.data?.count)
      setCrewTypesSearchResults(searchCrewTypeResponse?.data?.result);
    else setCrewTypesSearchResults([]);
  };
  const handleCrewTypesFocus = () => {
    suggestCrewTypes();
    if (crewTypesRef.current) {
      crewTypesRef.current.show();
    }
  };
  const addCrewType = async typeName => {
    setIsLoading(true);
    if (typeName) {
      const newType = await createUserCrewType(userId, { name: typeName });

      if (newType?.data) {
        const newTypeObject = {
          type_id: newType.data?.type_id,
          name: newType.data?.name,
        };

        formik.setFieldValue('crewTypeId', newTypeObject);
        suggestCrewTypes();
        debouncedSaveCrew(newTypeObject);
        setIsLoading(false);
        toast?.current?.show({
          severity: 'success',
          summary: newType?.message,
          life: 2000,
        });
      }
    }
  };
  const onDisabled = (searchResult, value, key) => {
    let res = true;

    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };
  useEffect(() => {
    if (currentCrew?.crew_id) {
      formik.setFieldValue('name', currentCrew?.name);
      if (currentCrew?.type) {
        const newTypeObject = {
          type_id: currentCrew?.type?.type_id,
          name: currentCrew?.type?.name,
        };
        formik.setFieldValue('crewTypeId', newTypeObject);
      }
    }
  }, [currentCrew?.crew_id]);
  const debouncedSaveCrew = _.debounce(saveCrew, 1000);
  return (
    <div id="crew-detail-section" className="flex flex-wrap p-2 pt-4">
      <div className="flex flex-wrap gap-2">
        <span className="p-float-label">
          <InputText
            id="name"
            aria-describedby="name-help"
            className="p-inputtext-sm"
            value={formik?.values?.name || ''}
            onChange={e => {
              formik.setFieldValue('name', e.target.value);
            }}
            onBlur={e => {
              if (e.target.value !== currentCrew?.name)
                debouncedSaveCrew(formik?.values?.crewTypeId);
            }}
          />
          <label htmlFor="name">
            Crew Name<span style={{ color: 'red' }}>*</span>
          </label>
        </span>
        <span className="p-float-label">
          <InputText
            id="lead"
            aria-describedby="lead-help"
            value={`${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`}
            disabled={true}
            className="p-inputtext-sm"
          />
          <label htmlFor="lead">
            Crew Lead<i className="text-red-400">*</i>
          </label>
        </span>
        <span className="p-float-label">
          <AutoComplete
            ref={crewTypesRef}
            id="crewTypeId"
            aria-describedby="crewTypeId-help"
            className="w-10"
            suggestions={crewTypesSearchResults}
            field="name"
            completeMethod={suggestCrewTypes}
            value={formik?.values?.crewTypeId || ''}
            onChange={e => {
              formik.setFieldValue('crewTypeId', e.target.value);
              debouncedSaveCrew(e.target.value);
            }}
            inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
            onFocus={handleCrewTypesFocus}
          />
          <label htmlFor="crewTypeId">
            Crew Type<i className="text-red-400">*</i>
          </label>
          <Button
            icon="pi pi-check"
            className="p-button-primary w-2 border-noround-left height-40"
            disabled={onDisabled(
              crewTypesSearchResults,
              formik?.values?.crewTypeId,
              'name'
            )}
            tooltip={
              onDisabled(
                crewTypesSearchResults,
                formik?.values?.crewTypeId,
                'name'
              )
                ? ''
                : 'Add Crew Type'
            }
            tooltipOptions={{ position: 'top' }}
            onClick={() => addCrewType(formik?.values?.crewTypeId)}
          />
        </span>
        <span className="p-float-label">
          <InputText
            id="lead"
            aria-describedby="lead-help"
            value={crewMemberDataCount}
            disabled={true}
            className="p-inputtext-sm"
          />
          <label htmlFor="lead">
            Capacity (No. of Members)<i className="text-red-400">*</i>
          </label>
        </span>
        <span className="p-float-label">
          <InputText
            id="created"
            aria-describedby="created-help"
            value={
              currentCrew?.created_at &&
              dayjs(new Date(currentCrew.created_at)).format('MM-DD-YYYY')
            }
            disabled={true}
            className="p-inputtext-sm"
          />
          <label htmlFor="created">
            Date Created<i className="text-red-400">*</i>
          </label>
        </span>
        <div className="flex justify-content-end align-items-center m-2">
          <i
            className="pi pi-times cursor-pointer text-primary mr-2"
            onClick={() => cancel()}
            data-pr-tooltip="Cancel"
            data-pr-position="top"
          ></i>
          {isLoading && <ProgressSpinner className="w-2rem h-2rem" />}
        </div>
      </div>
    </div>
  );
};

const CrewCard = ({ userId, data, setSelectedCrew, toast }) => {
  const [checked, setChecked] = useState(data.is_active);
  const [isLoading, setIsLoading] = useState(false);
  const handleSwitch = async (e, data, userId) => {
    setChecked(e.value);
    setIsLoading(true);
    const response = await updateUserCrewDetailById(userId, data.crew_id, {
      is_active: e.value,
    });
    if (response?.status && response?.data) {
      toast?.current?.show({
        severity: 'success',
        summary: `Crew (${data?.name}) ${e.value ? 'activated' : 'deactivated'} successfully`,
        life: 2000,
      });
    }
    setIsLoading(false);
  };
  const footer = (
    <div
      className="flex justify-content-center align-items-center gap-2 p-1"
      onClick={event => event.stopPropagation()}
    >
      <span>{checked ? 'Active' : 'Inactive'}</span>
      <InputSwitch
        checked={checked}
        onChange={e => handleSwitch(e, data, userId)}
      />
    </div>
  );
  return (
    <Card
      id="crew-card"
      title={data?.name}
      footer={footer}
      className="shadow-6 cursor-pointer"
      onClick={() => setSelectedCrew(data)}
      disabled={isLoading}
      pt={{ title: { className: 'text-primary text-lg' } }}
    >
      <div className="flex flex-column text-sm">
        <div className="flex flex-row">
          <div className="flex flex-column">
            <div>Capacity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</div>
            <div>
              Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
            </div>
            <div>Date Created&nbsp;:</div>
            <div>Date Updated:</div>
          </div>
          <div className="flex flex-column">
            <div>{data.capacity || 0} Members</div>
            <div>{data?.type?.name || '-'}</div>
            <div>
              {data.created_at
                ? dayjs(new Date(data.created_at)).format('MM-DD-YYYY')
                : '-'}
            </div>
            <div>
              {data?.updated_at
                ? dayjs(new Date(data.updated_at)).format('MM-DD-YYYY')
                : '-'}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const UserFormCrew = () => {
  const dispatch = useDispatch();
  const { isLoading, crewListData } = useSelector(state => state.userFormCrew);
  const { userId } = useSelector(state => state.userForm);
  const { userDetails } = useSelector(state => state.userFormDetails);
  const toast = useRef(null);
  const [selectedCrew, setSelectedCrew] = useState();
  const addressSection = useRef(null);

  const fetchInitiationData = async () => {
    dispatch(setUserCrewForm({ isLoading: true }));
    try {
      const { statusCode, data } = await getUserCrewList(userId);
      if (statusCode === 200 && data) {
        dispatch(
          setUserCrewForm({
            crewListData: data,
          })
        );
      }
    } catch (error) {
    } finally {
      dispatch(setUserCrewForm({ isLoading: false }));
    }
  };
  const handleCancel = () => {
    setSelectedCrew(null);
    fetchInitiationData();
  };

  useEffect(() => {
    userId && fetchInitiationData();
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <div className="p-2">
          <SkeletonLoader
            columnHeight="10rem"
            columnCount={5}
            columnWidth="15%"
            gap={5}
          />
        </div>
      ) : selectedCrew ? (
        <>
          <CrewDetailSection
            crew={selectedCrew}
            setSelectedCrew={setSelectedCrew}
            cancel={handleCancel}
            toast={toast}
            userId={userId}
          />
          {selectedCrew !== 'new' && (
            <div className="p-2 pt-4 w-full">
              <UserFormCrewMember
                userId={userId}
                selectedCrew={selectedCrew}
                addressSection={addressSection}
              />
            </div>
          )}
        </>
      ) : (
        <div
          id="crew-cards"
          className="card flex flex-wrap gap-5 p-3 align-items-center"
        >
          {crewListData?.count > 0 ? (
            crewListData.result.map((element, index) => (
              <CrewCard
                data={element}
                setSelectedCrew={setSelectedCrew}
                userId={userId}
                toast={toast}
              />
            ))
          ) : (
            <></>
          )}
          <i
            className="pi pi-plus-circle cursor-pointer text-primary"
            onClick={() => {
              setSelectedCrew('new');
              dispatch(
                setUserCrewMember({
                  crewMemberData: [],
                })
              );
            }}
          ></i>
        </div>
      )}

      <Toast ref={toast} />
    </>
  );
};

export default UserFormCrew;
