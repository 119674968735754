import ApiService from '../../../../services/api.service';
import { getQueryResult } from '../../Widget/service/widget.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const getUserCrewList = async (userId, urlParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/list`,
      urlParams,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createUserCrew = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/create`,
      body,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const updateUserCrewDetailById = async (userId, crewId, body) => {
  try {
    const response = await ApiService.putWithDiffBaseUrl(
      `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/${crewId}`,
      body,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserCrewMemberList = async (userId, crewId, urlParams) => {
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/${crewId}/member/list`,
      urlParams,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addEditCrewMember = async (userId, crewId, body) => {
  try {
    let response;
    if (body?.member_id) {
      const updateBody = { ...body };
      delete updateBody.member_id;
      if (updateBody.status === 'On Leave') updateBody.status = 'on_leave';
      response = await ApiService.putWithDiffBaseUrl(
        `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/${crewId}/member/${body?.member_id}`,
        updateBody,
        URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
      );
      return response;
    } else {
      response = await ApiService.postWithDiffBaseUrl(
        `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/${crewId}/member/create`,
        body,
        URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
      );
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const removeUserCrewMemberDetailById = async (
  userId,
  crewId,
  crewMemberId
) => {
  try {
    const response = await ApiService.deleteWithDiffBaseUrl(
      `${URL_CONSTANTS.CREW_MANAGEMENT.crew.get}/${userId}/crew/${crewId}/member/${crewMemberId}`,
      null,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createUserCrewType = async (userId, body) => {
  try {
    const response = await ApiService.postWithDiffBaseUrl(
      `/api/crew/type/create`,
      body,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchCrewType = async searchString => {
  const query = {
    limit: 10,
    page: 1,
    sortBy: 'name',
    sortOrder: 'ASC',
  };
  if (searchString) query.name = searchString;
  try {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/crew/type/list`,
      query,
      URL_CONSTANTS.CREW_MANAGEMENT.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
