import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import TableLoader from '../../shared/Loader/TableLoader';
import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { setUserCredentialsForm } from '../../../redux/slices/user-form-credentials.slice';

import { domHandlerCall } from './common';
import {
  editUserCredentials,
  getUserCredentialsData,
} from './services/user-form-credentials.service';
import { uploadUserFile } from './services/user-form-service';

const emptyRow = {
  status: '',
  type: '',
  insurance_type: '',
  issuing_body: '',
  expiration_date: new Date(),
  city: '',
  state: '',
  description: '',
  number: '',
  file: '',
  certificate_number: '',
};

const mapTableDataForPayload = tableData => {
  const mappedPayload = tableData
    .map(row => {
      if (!row?.type || !row?.expiration_date) {
        return null;
      } else {
        return {
          type: row.type?.credential_type_id,
          insurance_type: row?.insurance_type?.insurance_type_id,
          issuing_body: row?.issuing_body,
          city: row?.city,
          state: row?.state,
          description: row?.description,
          number: row?.number,
          expiration_date: row?.expiration_date,
          file: row?.file,
          certificate_number: row?.certificate_number,
        };
      }
    })
    .filter(i => i !== null);
  return mappedPayload;
};

const keepOnlyDigits = inputString => {
  return inputString.replace(/\D/g, '');
};

const UserFormCredentials = () => {
  const { isLoading, isFileUploading, credentialsTypeList, credentialsData } =
    useSelector(state => state.userFormCredentials);
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);
  const [fileUploaderRowIndex, setFileUploaderRowIndex] = useState();
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [newUserFile, setNewUserFile] = useState(null);
  const fetchInitiationData = async () => {
    dispatch(setUserCredentialsForm({ isLoading: true }));
    try {
      const initializationData =
        !!userId && (await getUserCredentialsData(userId));
      const { credentialsTypeList, insuranceTypeList, credentials } =
        initializationData;
      const mappedData = credentials.map(row => {
        return {
          status:
            new Date() < new Date(row?.expiration_date) ? 'Active' : 'Expired',
          type: credentialsTypeList.find(
            item => item?.credential_type_id == row?.type
          ),
          insurance_type: insuranceTypeList.find(
            item => item?.insurance_type_id == row?.insurance_type
          ),
          issuing_body: row?.issuing_body,
          expiration_date: row?.expiration_date,
          number: row?.number,
          city: row?.city,
          state: row?.state,
          description: row?.description,
          certificate_number: row?.certificate_number,
          file: row?.file,
        };
      });
      dispatch(
        setUserCredentialsForm({
          credentialsTypeList,
          insuranceTypeList,
          credentialsData: mappedData,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setUserCredentialsForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const handleDelete = async rowIndex => {
    let editedData = structuredClone(credentialsData);
    editedData.splice(rowIndex, 1);
    dispatch(
      setUserCredentialsForm({ credentialsData: editedData, isLoading: true })
    );
    const payload = mapTableDataForPayload(editedData);
    let deleteResponse;
    try {
      deleteResponse = await editUserCredentials(userId, payload);
      if (deleteResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted row',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserCredentialsForm({ isLoading: false }));
    }
  };

  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async e => {
    confirmDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(e?.index),
      reject: handleDeleteCancel,
      onHide: handleDeleteCancel,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };

  const StatusTemplate = rowData => {
    return (
      rowData?.status && (
        <span>
          <Tag
            value={rowData?.status}
            severity={rowData?.status === 'Active' ? 'success' : 'warning'}
          />
        </span>
      )
    );
  };

  const CredentialsTypeTemplate = rowData => {
    return <span>{rowData?.type?.type_name}</span>;
  };

  const ExpirationDateTemplate = rowData => {
    return (
      <span>
        {rowData?.expiration_date
          ? new Date(rowData?.expiration_date)?.toLocaleDateString()
          : ''}
      </span>
    );
  };

  const PhoneTemplate = rowData => {
    return <span>{rowData?.number}</span>;
  };

  const CertificateTemplate = rowData => {
    return <span>{rowData?.certificate_number}</span>;
  };

  const credentialsTypeEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="type_name"
        options={credentialsTypeList}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
      />
    );
  };

  const nameEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const certificateNumberEditor = options => {
    const handleChange = e => {
      const value = e?.target?.value;
      const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '');
      if (value !== alphanumericValue) {
        toast.current.show({
          severity: 'info',
          detail: 'Special characters are not allowed',
          life: 3000,
        });
      }
      options.editorCallback(alphanumericValue);
    };
    return (
      <InputText
        value={options.value}
        onChange={handleChange}
        maxLength={100}
      />
    );
  };

  const phoneEditor = options => {
    return (
      <InputMask
        mask="(999) 999-9999"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const fileTemplate = rowData => {
    return (
      <a
        href={rowData?.file?.url}
        target="_blank"
        rel="noreferrer"
        download={rowData?.file?.name}
      >
        {rowData?.file?.name}
      </a>
    );
  };

  const handleUploaderClick = e => {
    setIsUploaderVisible(true);
  };

  const fileUploadEditor = options => {
    setFileUploaderRowIndex(options?.rowIndex);
    return (
      <>
        <a
          href={credentialsData[options?.rowIndex]?.file?.url}
          target="_blank"
          rel="noreferrer"
          download={credentialsData[options?.rowIndex]?.file?.name}
        >
          {credentialsData[options?.rowIndex]?.file?.name}
        </a>
        <i
          className="ml-2 pi pi-upload cursor-pointer"
          onClick={handleUploaderClick}
        ></i>
        {isFileUploading && (
          <ProgressSpinner
            style={{ width: '30px', height: '30px' }}
            strokeWidth="6"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
      </>
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserCredentialsForm({
        credentialsData: [emptyRow, ...credentialsData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };
  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };
  const onRowEditComplete = async e => {
    let editedCredentials = structuredClone(credentialsData);
    let { newData, index } = e;
    if (newData) {
      newData.file = {
        url: credentialsData[index]?.file?.url,
        name: credentialsData[index]?.file?.name,
      };
    }
    editedCredentials[index] = newData;
    dispatch(
      setUserCredentialsForm({
        credentialsData: editedCredentials,
        isLoading: true,
      })
    );
    let editedResponse;
    try {
      const payload = mapTableDataForPayload(editedCredentials);
      editedResponse = await editUserCredentials(userId, payload);
      if (editedResponse?.status === 200) {
        fetchInitiationData();
        toast.current.show({
          severity: 'success',
          summary: 'Updated Credentials',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserCredentialsForm({ isLoading: false }));
      disableEditButton(false);
      setIsDisableAddButton(false);
    }
  };

  const handleRowValidation = (e, f) => {
    const validationError = [];
    if (!e?.type) {
      validationError.push('Type');
    }
    if (!e?.issuing_body) {
      validationError.push('Issuing Body');
    }
    if (!e?.expiration_date) {
      validationError.push('Expiration Date');
    }

    if (validationError.length > 0) {
      toast.current.show({
        severity: 'error',
        summary: `${validationError.join(',')} required`,
        life: 2000,
      });
      return false;
    }
    return true;
  };

  const dateEditor = options => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : new Date()}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleUserDocumentUpload = async e => {
    try {
      dispatch(setUserCredentialsForm({ isFileUploading: true }));
      setIsUploaderVisible(false);
      if (e?.files && e.files.length > 0) {
        setNewUserFile(e.files[0]);
        try {
          const fileUpload = await uploadUserFile(
            userId,
            'credentials',
            e.files[0]
          );
          if (fileUpload?.name && fileUpload?.url) {
            let editedDocuments = structuredClone(credentialsData);
            const rowData = editedDocuments[fileUploaderRowIndex];
            rowData['file'] = fileUpload;
            editedDocuments[fileUploaderRowIndex] = rowData;

            dispatch(
              setUserCredentialsForm({ credentialsData: editedDocuments })
            );
          } else {
            toast.current.show({
              severity: 'error',
              summary: `Uploaded File Type is not supported`,
              life: 3000,
            });
          }
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: `An error occurred while uploading file.`,
            life: 2000,
          });
        }
      } else {
        throw new Error('No files uploaded');
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserCredentialsForm({ isFileUploading: false }));
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonLoader columnCount={20} columnWidth="24%" mTopBottom={4} />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-content-between align-items-center py-3">
            <h4>Credentials</h4>
            <Button
              label="Add Credentials"
              severity="primary"
              size="small"
              onClick={handleAddNewRow}
              disabled={isDisableAddButton}
            />
          </div>
          <DataTable
            value={credentialsData}
            editMode="row"
            onRowEditComplete={onRowEditComplete}
            rowEditValidator={handleRowValidation}
            onRowEditCancel={handleOnRowEditCancel}
            onRowEditInit={prop => {
              disableEditButton(true);
            }}
          >
            <Column
              field="type"
              header="Type"
              editor={options => credentialsTypeEditor(options)}
              body={CredentialsTypeTemplate}
            />
            <Column
              field="issuing_body"
              header="Issuing Body"
              editor={options => nameEditor(options)}
            />
            <Column
              field="certificate_number"
              header="Number"
              editor={options => certificateNumberEditor(options)}
              body={CertificateTemplate}
            />
            <Column
              field="city"
              header="City"
              editor={options => nameEditor(options)}
            />
            <Column
              field="state"
              header="State"
              editor={options => nameEditor(options)}
            />
            <Column
              field="expiration_date"
              header="Expiration Date"
              headerStyle={{ minWidth: '10rem' }}
              body={ExpirationDateTemplate}
              editor={options => dateEditor(options)}
            />
            <Column
              field="number"
              header="Phone Number"
              editor={options => phoneEditor(options)}
              body={PhoneTemplate}
            />
            <Column field="status" header="Status" body={StatusTemplate} />
            <Column
              field="file"
              header="File"
              editor={options => fileUploadEditor(options)}
              body={fileTemplate}
            />
            <Column
              rowEditor
              headerStyle={{ width: '15%', minWidth: '7rem' }}
              bodyStyle={{ textAlign: 'center' }}
            ></Column>
          </DataTable>
        </>
      )}
      <Toast ref={toast}></Toast>
      <Dialog
        header="Upload Document"
        visible={isUploaderVisible}
        style={{ width: '50vw' }}
        onHide={() => setIsUploaderVisible(false)}
      >
        <FileUploadTemplate
          onFileUpload={handleUserDocumentUpload}
          acceptedFormat="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </Dialog>
    </>
  );
};

export default UserFormCredentials;
