import React, { useEffect, useState } from 'react';
import { Message } from 'primereact/message';
import { CircularProgress } from '@material-ui/core';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import Loader from '../../../shared/Loader/Loader';
import apiService from '../../../../services/api.service';
import { CONFIG } from '../../../../constants';

const MSHDPODetails = ({ projectId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fieldLabel = {
    com_order_number: 'Com Order Number',
    vendor_name: 'Vendor Name',
    shor_sku_desc: 'Short Sku Desc',
    add_trip_retail_amount: 'Add Trip Retail Amount',
    status_effective_timestamp: 'Status Effective Timestamp',
    open_note_count: 'Open Note Count',
    followup_action: 'Followup Action',
    vendor_number: 'Vendor Number',
    po_status_desc: 'Po Status Desc',
    sku_number: 'Sku Number',
    po_cost_amount: 'PO Cost Amount',
    po_retail_amount: 'PO Retail Amount',
    po_line_item_retail_amount: 'PO Line Item Retail Amount',
    dept_number: 'Dept Number',
    merch_class_number: 'Merch Class Number',
    order_quantity: 'Order Quantity',
    key_rec_number: 'Key Rec Number',
    key_rec_date: 'Key Rec Date',
    key_rec_cost: 'Key Rec Cost',
    po_creation_date: 'PO Creation Date',
    original_quantity: 'Original Quantity',
    special_instruction_text: 'Special Instruction Text',
    trip_cost_mul_factor: 'Trip Cost Mul Factor',
    suoi_desc: 'SUOI Desc',
    basic_install_cost_amount: 'Basic Install Cost Amount',
    basic_install_quantity: 'Basic Install Quantity',
    install_line_number: 'Install Line Number',
    effective_install_end_date: 'Effective Install End Date',
    duoi_code: 'DUOI Code',
    related_cust_ord_number: 'Related Cust Ord Number',
    related_measure_po_number: 'Related Measure PO Number',
    related_install_number: 'Related Install Number',
  };

  useEffect(() => {
    const result = apiService.getWithDiffBaseUrl(
      `/api/measure-square/project-order?projectId=${projectId}`,
      undefined,
      CONFIG?.API_CRM_URL
    );

    result
      .then(res => {
        if (res.status)
          if (Object.keys(res?.data).length > 0) {
            const {
              trip_cost_mul_factor,
              suoi_desc,
              basic_install_cost_amount,
              basic_install_quantity,
              install_line_number,
              effective_install_end_date,
              duoi_code,
              related_cust_ord_number,
              related_measure_po_number,
              related_install_number,
              ...rest
            } = res?.data;
            setData({
              poInfo: { ...rest },
              installInfo: {
                basic_install_cost_amount,
                basic_install_quantity,
                install_line_number,
                effective_install_end_date,
                duoi_code,
                related_cust_ord_number,
                related_measure_po_number,
                related_install_number,
              },
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
      })
      .catch(err => {
        console.log('result', err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="grid p-1">
      {loading ? (
        <div className="col-12 md:col-12 lg:col-12">
          <div className="flex align-items-center justify-content-center">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <>
          {data ? (
            <>
              <div className="col-12 md:col-12 lg:col-12 ">
                <h3>PO</h3>
              </div>
              {Object.keys(data.poInfo).map((key, index) => (
                <>
                  {fieldLabel[key] && (
                    <div className="col-12 md:col-2 lg:col-2 ">
                      <span className="p-float-label">
                        <PFInputText
                          id={key}
                          name={key}
                          type="text"
                          value={
                            data.poInfo[key]?.value || data.poInfo[key] || 'NA'
                          }
                          disabled={true}
                        />
                        <label htmlFor="companyName" className="text-sm">
                          {fieldLabel[key] || 'NA'}
                        </label>
                      </span>
                    </div>
                  )}
                </>
              ))}

              <div className="col-12 md:col-12 lg:col-12 ">
                <h3>Install Info</h3>
              </div>
              {Object.keys(data.installInfo).map((key, index) => (
                <>
                  {fieldLabel[key] && (
                    <div className="col-12 md:col-2 lg:col-2 ">
                      <span className="p-float-label">
                        <PFInputText
                          id={key}
                          name={key}
                          type="text"
                          value={
                            data.installInfo[key]?.value ||
                            data.installInfo[key] ||
                            'NA'
                          }
                          disabled={true}
                        />
                        <label htmlFor="companyName" className="text-sm">
                          {fieldLabel[key] || 'NA'}
                        </label>
                      </span>
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              <div className="col-12 md:col-12 lg:col-12 ">
                <Message text="Home Depot Project Order not available" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MSHDPODetails;
