import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

//To fetch exported reports
export const getExportedReportsList = async searchStringVal => {
  try {
    const response = await ApiService.get(
      `${URL_CONSTANTS.EXPORTED_REPORTS.reportList}${searchStringVal}`
    );

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To delete record
export const deleteExportedReport = async val => {
  try {
    const response = await ApiService.delete(
      `${URL_CONSTANTS.EXPORTED_REPORTS.report}/${val}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// To download report
export const downlaodPayrollReport = async fileURL => {
  try {
    const response = await ApiService.get(
      `${URL_CONSTANTS.EXPORTED_REPORTS.downlaod}${fileURL}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
