import { makeStyles } from '@material-ui/core/styles';

// Red Shades
const PF_LIGHT_RED = '#FFDBD9';
const PF_DARK_RED = '#B72136';

//Green Shades
const PF_LIGHT_GREEN = '#DDF7D5';
const PF_DARK_GREEN = '#229A16';

// //Yellow Shades
const PF_LIGHT_YELLOW = '#FFF3CD';
const PF_DARK_YELLOW = '#B78103';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWrapper: {
    maxWidth: '77%',
    flexBasis: '77%',
  },
  checkboxFormRoot: {
    alignItems: 'flex-start',
  },
  checkboxRoot: {
    padding: '9px 0px',
  },
  checkboxLabelPlacement: {
    marginLeft: '0px',
  },
  checkboxLabel: {
    color: theme.palette.grey[600],
    lineHeight: '14px',
    fontSize: '12px',
  },
  container: {
    maxHeight: 440,
  },
  containerHeader: {
    height: '50px',
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '22px',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
  },
  containerContent: {
    display: 'flex',
    padding: '24px',
  },
  formWrapper: {
    width: '100%',
  },
  fullWidthGridItem: {
    maxWidth: '100%',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      maxWidth: 80,
      width: '100%',
    },
  },
  moreIconRoot: {
    height: '100%',
    marginRight: '10px',
  },
  paperRoot: {
    minHeight: '250px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '12px',
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  statusWrapper: {
    maxWidth: '30%',
    flexBasis: '30%',
  },
  statusButton: props => ({
    backgroundColor:
      (props.status === 'Cancelled' && PF_LIGHT_RED) ||
      (props.status === 'Completed' && PF_LIGHT_GREEN) ||
      PF_LIGHT_YELLOW,
    color:
      (props.status === 'Cancelled' && PF_DARK_RED) ||
      (props.status === 'Completed' && PF_DARK_GREEN) ||
      PF_DARK_YELLOW,
    fontWeight: 600,
    padding: '4px 6px',
    '&:hover': {
      backgroundColor:
        (props.status === 'Cancelled' && PF_LIGHT_RED) ||
        (props.status === 'Completed' && PF_LIGHT_GREEN) ||
        PF_LIGHT_YELLOW,
    },
  }),
  scrollButtons: {
    paddingTop: '12px',
    '& .MuiSvgIcon-fontSizeSmall': {
      width: '25px',
      height: '25px',
      fill: theme.palette.common.white,
    },
  },
  tabRoot: {
    borderBottom: '1px Solid #DFE3E8',
  },
  tableContainer: {
    maxHeight: 440,
  },
  tabsPaper: {
    flexGrow: 1,
    width: '100%',
    minHeight: '50px',
  },
  tableFirstColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 9,
  },
  tableFirstColumnBody: {
    position: 'sticky',
    left: 0,
    zIndex: 6,
    background: 'white',
    border: '1px solid grey',
  },
}));
