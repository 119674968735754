import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { TreeTable } from 'primereact/treetable';
// import { IconField } from 'primereact/iconfield';
// import { InputIcon } from 'primereact/inputicon';
// import { InputText } from 'primereact/inputtext';

import {
  addBulkServiceTime,
  getProjectTypes,
  getServiceTimeConfig,
} from '../service/ServiceTimeConfigService';
import { formatDate } from '../../../../utils/Helpers';

import DurationTemplate from './DurationTemplate';
import ApplyToAllDuration from './ApplyToAllDuration';
// import { debounce } from 'lodash';

const tableDataState = {
  count: 1000,
  page: 1,
  sortField: 'config_id',
  sortOrder: 1,
};
const columnResizerPt = {
  columnResizer: {
    style: {
      borderRight: '1px solid #E0E0E0',
      height: '35px',
      position: 'absolute',
      top: '7px',
    },
  },
};
const TypeAndCategoryTime = ({ clientId }) => {
  if (!clientId) return <></>;
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasChild, setHasChild] = useState(false);
  const [rootTreeData, setRootTreeData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  // const [treeDataCopyForSearch, setTreeDataCopyForSearch] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(null);
  /* const [search, setSearch] = useState({
    type: null,
    category: null,
    modifiedBy: null,
  }); */

  const callProjectType = async () => {
    setLoading(true);
    try {
      const projectTypeResult = await getProjectTypes();
      if (Array.isArray(projectTypeResult)) {
        const tableData = projectTypeResult.map(eachType => {
          return {
            key: `type-${eachType?.project_type_id}`,
            name: `type`,
            id: eachType?.project_type_id,
            data: {
              type: eachType?.project_type,
              category: '',
              duration_value: '',
              modifiedBy: '',
              modified_at: '',
            },
            children: [{}],
          };
        });
        setTreeData(tableData);
        setRootTreeData(tableData);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callProjectType();
  }, []);

  const handleApplyToAll = async (type_id, durationValue, event) => {
    if (!Number.isInteger(type_id) || +type_id < 1) return;
    setLoading(true);
    try {
      const payload = {
        filter: {
          type_id,
        },
        update: {
          duration_value: durationValue || 0,
          duration_type: 'minute',
        },
      };
      const response = await addBulkServiceTime(clientId, payload);
      if (response?.status && response?.data) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || `Bulk Service time updated successfully`,
          life: 3000,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      onExpand(event);
    }
  };
  const onExpand = async event => {
    if (event.node?.name === 'type') {
      setLoading(true);
      try {
        const response = await getServiceTimeConfig(clientId, {
          ...tableDataState,
          sortOrder: tableDataState.sortOrder === 1 ? 'asc' : 'desc',
          type_id: event.node.id,
        });
        if (response?.status && Array.isArray(response?.data?.result)) {
          const copyRootTreeData = structuredClone(rootTreeData);
          let lazyNode = {
            ...event.node,
            children: [],
            data: { ...(event.node?.data || {}) },
          };
          lazyNode.children = response.data.result.map(eachConfig => {
            return {
              key: `config-${eachConfig?.config_id}`,
              name: `config`,
              data: {
                category: eachConfig?.category?.category,
                durationTest: eachConfig?.duration_value,
                duration_value: (
                  <DurationTemplate
                    clientId={clientId}
                    row={eachConfig}
                    toast={toast}
                  />
                ),
                modifiedBy:
                  eachConfig?.modifiedBy &&
                  `${eachConfig?.modifiedBy?.first_name} ${eachConfig?.modifiedBy?.last_name}`,
                modified_at:
                  eachConfig?.modifiedBy && formatDate(eachConfig?.modified_at),
              },
            };
          });
          lazyNode.data.duration_value = (
            <ApplyToAllDuration
              handleApplyToAll={async durationValue => {
                await handleApplyToAll(lazyNode.id, durationValue, event);
              }}
            />
          );
          let _treeData = copyRootTreeData.map(node => {
            if (node.key === event.node.key) {
              node = lazyNode;
            } else {
              node.data = {
                ...node.data,
                duration_value: '',
              };
            }
            return node;
          });
          setExpandedKeys(null);
          setHasChild(true);
          setTreeData(_treeData);
        } else setHasChild(false);
      } catch (ex) {
        console.log(ex);
      } finally {
        setLoading(false);
        setExpandedKeys({
          [event.node.key]: true,
        });
        /* setSearch((prev) => ({
          ...prev,
          category: null,
          modifiedBy: null,
        })); */
      }
    }
  };
  const onCollapse = async event => {
    setLoading(true);
    setTimeout(() => {
      setExpandedKeys(null);
      setTreeData(rootTreeData);
      setHasChild(false);
      setLoading(false);
    }, 100);
  };
  const rootTreeDataValue = rootTreeData.map(eachType => {
    return {
      ...eachType,
      children: [
        {
          key: `initial-${eachType?.project_type_id}`,
          name: `initial`,
          data: {
            category: <Skeleton />,
            duration_value: <Skeleton />,
            modifiedBy: <Skeleton />,
            modified_at: <Skeleton />,
          },
        },
      ],
    };
  });
  /* const handleSearch = (searchField, value) => {
    console.log(searchField, value, 'searchField, value');
    if (value && typeof value === 'string') {
      setTreeDataCopyForSearch(treeData);
      switch (searchField) {
        case 'type':
          const filtered = treeData.filter(
            ({ data: { type } }) =>
              typeof type === 'string' && type.toUpperCase().includes(value.toUpperCase())
          );
          if (expandedKeys) {
            setTreeData(filtered);
          } else {
            setRootTreeData(filtered);
          }
          break;
        case 'category':
          console.log(expandedKeys, 'expandedKeys');
          if (expandedKeys) {
            const rootKey = Object.keys(expandedKeys)[0];
            const filtered = treeData.find(({ key }) => key == rootKey);
            console.log(expandedKeys, rootKey, filtered, 'rootKey');
            if (filtered) {
              filtered.children = filtered.children.filter(({ data: { category } }) =>
                category.includes(value)
              );
              // setTreeData(filtered);
            }
          }
          break;
        case 'modifiedBy':
          if (expandedKeys) {
            const rootKey = Object.keys(expandedKeys)[0];
            const filtered = treeData.find(({ key }) => key == rootKey);
            if (filtered) {
              filtered.children = filtered.children.filter(({ data: { modifiedBy } }) =>
                modifiedBy.includes(value)
              );
              setTreeData(filtered);
            }
          }
          break;
      }
    } else {
      console.log(expandedKeys, 'expandedKeys');
      console.log(treeDataCopyForSearch, 'treeDataCopyForSearch');
      console.log(treeData, 'treeData');
      console.log(rootTreeData, 'rootTreeData');
      if (expandedKeys) {
        setTreeData(treeDataCopyForSearch);
      } else {
        setRootTreeData(treeData);
      }
    }
  };
  const debouncedOnChange = useCallback(debounce(handleSearch, 300), []); */
  return (
    <Card title="Service Time Configurations" className="mt-3">
      <Toast ref={toast} />
      <TreeTable
        value={expandedKeys && !loading ? treeData : rootTreeDataValue}
        tableStyle={{ minWidth: '50rem' }}
        style={{
          maxHeight: 500,
          overflow: 'auto',
        }}
        onExpand={onExpand}
        onCollapse={onCollapse}
        loading={loading}
        expandedKeys={expandedKeys}
        onToggle={e => setExpandedKeys(e.value)}
        columnResizeMode="expand"
        resizableColumns
        resizeHelper
      >
        <Column
          expander
          field="type"
          header="Type"
          sortable
          filter
          /* filterElement={
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                value={search?.type}
                onChange={(e) => {
                  setSearch((prev) => ({ ...prev, type: e.target?.value }));
                  debouncedOnChange('type', e.target?.value);
                }}
              />
            </IconField>
          } */
          pt={{
            ...columnResizerPt,
            filterInput: {
              root: { placeholder: 'Search By Type' },
            },
          }}
          headerStyle={{
            minWidth: 300,
            width: 300,
          }}
        />
        <Column
          field="category"
          header="Category"
          sortable={!!expandedKeys && hasChild}
          filter={!!expandedKeys && hasChild}
          /* filterElement={
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                value={search?.category}
                onChange={(e) => {
                  setSearch((prev) => ({ ...prev, category: e.target?.value }));
                  debouncedOnChange('category', e.target?.value);
                }}
              />
            </IconField>
          } */
          pt={{
            ...columnResizerPt,
            filterInput: {
              root: { placeholder: 'Search By Category' },
            },
          }}
          headerStyle={{
            minWidth: 250,
            width: 250,
          }}
        />
        <Column
          field="duration_value"
          header="Duration"
          pt={columnResizerPt}
          headerStyle={{
            minWidth: 250,
            width: 250,
          }}
        />
        <Column
          field="modifiedBy"
          header="Modified By"
          sortable={!!expandedKeys && hasChild}
          filter={!!expandedKeys && hasChild}
          /* filterElement={
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                value={search?.modifiedBy}
                onChange={(e) => {
                  setSearch((prev) => ({ ...prev, modifiedBy: e.target?.value }));
                  debouncedOnChange('modifiedBy', e.target?.value);
                }}
              />
            </IconField>
          } */
          pt={{
            ...columnResizerPt,
            filterInput: {
              root: { placeholder: 'Search By Modified By' },
            },
          }}
          headerStyle={{
            minWidth: 250,
            width: 250,
          }}
        />
        <Column field="modified_at" header="Modified Date" />
      </TreeTable>
    </Card>
  );
};

export default TypeAndCategoryTime;
