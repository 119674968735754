import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

// To fetch search filter dropdowns
export const getSearchDropdownOptions = async userId => {
  try {
    const workroomRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.workroom
    );
    const storeNoRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.store
    );
    const categoryRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.category
    );
    const typeRequest = ApiService.get(URL_CONSTANTS.PROJECTS.dropdowns.type);
    const statusRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.status
    );
    const installerRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.installer
    );
    const installerTypeRequest = ApiService.get(
      URL_CONSTANTS.PROJECTS.dropdowns.installerType
    );

    const [
      workRoomList,
      storeNoList,
      categoryList,
      typeList,
      statusList,
      installerList,
      installerTypeList,
    ] = await Promise.all([
      workroomRequest,
      storeNoRequest,
      categoryRequest,
      typeRequest,
      statusRequest,
      installerRequest,
      installerTypeRequest,
    ]);

    return {
      workRoomList,
      storeNoList,
      categoryList,
      typeList,
      statusList,
      installerList,
      installerTypeList,
    };
  } catch (error) {
    console.error(error);
  }
};

//To fetch payroll report
export const getPayrollSearchResult = async searchStringVal => {
  try {
    const response = await ApiService.get(
      `${URL_CONSTANTS.PAYROLL.report}${searchStringVal}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

//To Review, Approve & Exclude payroll record
export const payrollAction = async data => {
  try {
    const response = await ApiService.post(URL_CONSTANTS.PAYROLL.action, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// To export Payroll
export const exportPayroll = async searchStringVal => {
  try {
    const response = await ApiService.get(
      `${URL_CONSTANTS.PAYROLL.downlaod}${searchStringVal}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// To generate report
export const generatePayrollReport = async searchStringVal => {
  try {
    const response = await ApiService.post(
      URL_CONSTANTS.EXPORTED_REPORTS.reportList,
      searchStringVal
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
