// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  schedulingReport: {
    '& *': {
      cursor: 'pointer',
    },
  },
  paper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRadius: '0px',
    overflow: 'hidden',
  },
  reactGridLayout: props => ({
    margin: '10px',
    backgroundRepeat: props.isDragging ? 'repeat-y' : '',
    backgroundPosition: props.isDragging ? '0px -4px' : '',
    backgroundSize: props.isDragging ? '100% 52px' : '',
  }),
}));
