export const ERROR_CODES = {
  INVALID_INPUT: 400,
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  TIMEOUT: 408,
  TOO_MANY_REQUESTS: 429,
};
