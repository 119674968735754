import axios from 'axios';

import pageMetaConstant from '../PageMetaConstants';
import apiService from '../../../services/api.service';

export const getPageMetaInfoBySlug = async identifier => {
  try {
    const response = await apiService.post(
      `/${pageMetaConstant.getPageMetaEndpoint()}`,
      identifier
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getPageMetaUpdateTable = async (first, limit) => {
  try {
    const response = await apiService.get(
      `/${pageMetaConstant.getPageMetaUpdatesEndpoint(first, limit)}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const putEditPageMetaInfo = async data => {
  try {
    const response = await axios.put(
      `${pageMetaConstant.baseUrl}/${pageMetaConstant.putEditPageMetaEndpoint()}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
