import React from 'react';
import { Button } from 'primereact/button';

export default function PFButton({
  label,
  type,
  severity,
  text,
  raised,
  className,
  onClick,
  disabled,
  outlined,
  icon,
  size,
  tooltip,
  tooltipOptions,
  rounded,
  iconPos,
  pt,
  ...props
}) {
  onClick = onClick ? onClick : () => {};

  return (
    <Button
      label={label || ''}
      type={type || 'button'}
      severity={severity || 'primary'}
      text={text || false}
      rounded={rounded || false}
      raised={raised || false}
      className={`${className || ''} `}
      onClick={onClick || ''}
      disabled={disabled || false}
      size={size || 'small'}
      outlined={outlined || false}
      icon={`${icon || 'hidden'}`}
      tooltip={tooltip || ''}
      tooltipOptions={tooltipOptions || {}}
      iconPos={iconPos || 'left'}
      pt={pt || ''}
      {...props}
    />
  );
}
