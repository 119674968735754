// **** React Imports ****
import React, { useState, useEffect } from 'react';

// **** External Utilities ****
import {
  Grid,
  Box,
  TextField,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';

// **** Services *****
import { getProjectById } from '../ProjectInfo/ProjectDetails.service';
import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Styles *****
import { useStyles } from './Extracolumn.styles.js';

const Extracolumn = ({ projectId, projectDetailsData, loading }) => {
  const classes = useStyles();

  return (
    <>
      {!loading ? (
        <Grid container spacing={4} direction="column">
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {projectDetailsData?.extra_fields !== null &&
                projectDetailsData?.extra_fields !== undefined &&
                Object.entries(projectDetailsData?.extra_fields) &&
                Object.entries(projectDetailsData?.extra_fields).map(value => (
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      id="floorsoft_transaction_id"
                      label={value[0]}
                      value={value[1]}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default Extracolumn;
