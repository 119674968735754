import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  selectedDashboards: [],
  isSelectDashboardDirty: false,
};

const userFormDashboardsSlice = createSlice({
  name: 'userFormDashboards',
  initialState: initialState,
  reducers: {
    setUserDashboardsForm(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.isSelectDashboardDirty = payload.hasOwnProperty(
        'isSelectDashboardDirty'
      )
        ? payload.isSelectDashboardDirty
        : state.isSelectDashboardDirty;
      state.selectedDashboards = payload.hasOwnProperty('selectedDashboards')
        ? payload.selectedDashboards
        : state.selectedDashboards;
    },
  },
});

export const { setUserDashboardsForm } = userFormDashboardsSlice.actions;

export const userFormDashboardsReducer = userFormDashboardsSlice.reducer;
