import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
const SelectedCoverageValueList = ({
  coverageDialogVisible,
  setCoverageDialogVisible,
  selectedCoverageValues,
}) => {
  return (
    <Dialog
      header="Coverage Values"
      visible={coverageDialogVisible}
      onHide={() => setCoverageDialogVisible(false)}
      className="w-11 lg:w-4"
    >
      <ListBox
        listStyle={{ maxHeight: '250px' }}
        value={selectedCoverageValues}
        options={selectedCoverageValues.map(value => ({ label: value, value }))}
        optionLabel="label"
        className="w-full"
      />
    </Dialog>
  );
};
export default SelectedCoverageValueList;
