import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setIndustryTypes } from '../redux/slices/industryTypesMaster.slice';
import { CONFIG } from '../constants';

const useIndustryType = () => {
  const dispatch = useDispatch();
  const { industryTypes: types } = useSelector(
    state => state?.masters?.industryTypesReducer
  );
  const [industryTypes, setValue] = useState(types);

  const getIndustryTypes = async () => {
    try {
      if (industryTypes.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_BASE_URL}/system/types/industry-type`
        );
        setValue(response?.data);
        dispatch(setIndustryTypes(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIndustryTypes();
  }, []);

  return { industryTypes };
};

export default useIndustryType;
