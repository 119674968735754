import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { TreeTable } from 'primereact/treetable';

import DurationTemplate from '../NewConfiguration/ServiceTimeConfig/DurationTemplate';
import ApplyToAllDuration from '../NewConfiguration/ServiceTimeConfig/ApplyToAllDuration';
import { formatDate } from '../../../utils/Helpers';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import SkeletonLoader from '../../shared/Loader/skeleton';

import {
  bulkUpdateServiceTime,
  getServiceTime,
} from './services/user-form-job-assign-coverage.service';
import { getUserCoveragePreferences } from './services/user-form-coverage.service';
import ServiceTimeTemp from './ServiceTypeTemplate';
import ApplyServiceToAll from './ApplyServiceTimeToAll';

const UserJobAssignServiceTime = () => {
  const toast = useRef(null);
  const { user } = useParams();
  const [loading, setLoading] = useState(false);
  const [rootTreeData, setRootTreeData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [paginator, setPaginator] = useState({ first: 0, rows: 10, page: 1 });
  const [typeList, setTypeList] = useState([]);
  const [type, setType] = useState(null);
  const [category, setCategory] = useState([]);
  const fetchOptionsData = async () => {
    try {
      const OptionsListData = await getUserCoveragePreferences(user, paginator);
      const { categoryList, typesList } = OptionsListData;
      setTypeList(typesList);
      setCategoriesList(categoryList);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchOptionsData();
  }, []);

  const fetchServiceTime = async ({ type, category }) => {
    try {
      const projectTypeResult = await getServiceTime(
        user,
        paginator,
        type,
        category
      );
      const tableData = projectTypeResult?.data?.queryResponse?.map(
        eachType => ({
          key: `type-${eachType?.project_type_id}`,
          name: eachType?.project_type,
          id: eachType?.project_type_id,
          data: {
            type: eachType?.project_type,
            duration_value: expandedKeys ? (
              <ApplyServiceToAll
                handleApplyToAll={async durationValue => {
                  await handleApplyToAll(
                    eachType?.project_type_id,
                    durationValue
                  );
                }}
              />
            ) : (
              ''
            ),
            category: '',
          },
          is_expand: false,
          children: eachType.categories.map(category => ({
            key: `category-${category.project_category_ids}`,
            name: 'category',
            data: {
              category: category.category,
              duration_value: (
                <ServiceTimeTemp
                  user={user}
                  userJobCoverageId={category.user_job_coverage_id}
                  serviceTime={category.service_time}
                  row={category}
                  toast={toast}
                  projectTypeResult={projectTypeResult}
                />
              ),
            },
          })),
        })
      );
      setTreeData(tableData);
      setRootTreeData(tableData);
    } catch (ex) {
      console.error('Error fetching service time:', ex);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchServiceTime({ type, category });
  }, [user, paginator, expandedKeys]);
  const handleReset = async () => {
    setLoading(true);
    setType(null);
    setCategory([]);
    await fetchServiceTime({ type: null, category: [] });
  };
  const handleApplyToAll = async (type_id, durationValue) => {
    if (!Number.isInteger(type_id) || +type_id < 1) return;

    try {
      const payload = {
        project_type_id: type_id,
        service_time: durationValue,
      };
      const bulkUpdate = await bulkUpdateServiceTime(user, payload);
      if (bulkUpdate?.status) {
        setLoading(true);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: ` service time updated completed successfully`,
          life: 3000,
        });
        setExpandedKeys(null);
        // fetchServiceTime({ type: null, category: [] });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'Something went wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const onCollapse = event => {
    // setLoading(true);
    setTimeout(() => {
      setExpandedKeys(null);
      // setTreeData(rootTreeData);
    }, 100);
  };
  const rootTreeDataValue = rootTreeData.map(eachType => ({
    ...eachType,
    children: [
      {
        key: `initial-${eachType?.project_type_id}`,
        name: `initial`,
        data: {
          category: <Skeleton />,
          duration_value: <Skeleton />,
        },
      },
    ],
  }));

  const rowClassName = node => {
    return { 'p-highlight': node?.children && node?.children?.length > 0 };
  };
  return (
    <div className="m-4">
      <Toast ref={toast} />
      <div className="flex justify-content-between py-3">
        <h3 className="col-12 md:col-4 lg:col-6">Filters</h3>
      </div>
      <div className="grid mt-0">
        <div className="col-12 md:col-4 lg:col-8">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  value={type}
                  optionLabel="project_type"
                  name="project_type"
                  options={typeList}
                  onChange={(name, e) => setType(e)}
                  placeholder="Select a Type"
                  className="w-full"
                  filter={true}
                  style={{ height: '40px' }}
                  pt={{
                    input: { className: 'p-inputtext p-inputtext-sm' },
                  }}
                />
                <label htmlFor="client_timezone">Select Type</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              <span className="p-float-label">
                <PFMultiSelect
                  value={category}
                  optionLabel="category"
                  maxSelectedLabels={1}
                  name="category"
                  filter={true}
                  options={categoriesList}
                  onChange={e => setCategory(e.value)}
                  placeholder="Select a Category"
                  style={{ height: '40px' }}
                  className="w-full"
                  pt={{
                    input: { className: 'p-inputtext p-inputtext-sm' },
                  }}
                />
                <label htmlFor="client_timezone">Select Category</label>
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-4">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-3 col-offset-6 py-0">
              <PFButton
                label="Reset"
                size="small"
                outlined
                onClick={handleReset}
                severity="primary"
                className="w-full"
              />
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <PFButton
                label="Apply"
                size="small"
                severity="primary"
                className="w-full"
                onClick={() => fetchServiceTime({ type, category })}
              />
            </div>
          </div>
        </div>
      </div>
      <TreeTable
        value={expandedKeys && !loading ? treeData : rootTreeDataValue}
        tableStyle={{ minWidth: '50rem' }}
        style={{ maxHeight: 500, overflow: 'auto' }}
        onCollapse={onCollapse}
        expandedKeys={expandedKeys}
        rowClassName={rowClassName}
        onToggle={e => setExpandedKeys(e.value)}
        columnResizeMode="expand"
        resizableColumns
        resizeHelper
        paginator
        rows={10}
        loading={loading}
      >
        <Column
          expander
          field="type"
          header="Type"
          sortable
          headerStyle={{ minWidth: 300, width: 300 }}
        />
        <Column
          field="category"
          header="Category"
          sortable
          headerStyle={{ minWidth: 250, width: 250 }}
        />
        <Column
          field="duration_value"
          header="Duration"
          headerStyle={{ minWidth: 250, width: 250 }}
        />
      </TreeTable>
    </div>
  );
};
export default UserJobAssignServiceTime;
