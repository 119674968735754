import React, { useState, useEffect, useRef } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import SkeletonLoader from '../../shared/Loader/skeleton';
import { setUserDashboardsForm } from '../../../redux/slices/user-form-dashboards.slice';
import { userFormUpdateComponent } from '../../../redux/slices/user-form.slice';

import {
  getDashboardList,
  getUserAssignedDashboards,
  addDashboards,
} from './services/user-form-service';

const UserFormAssignedDashboards = () => {
  const [allDashboardList, setAllDashboardList] = useState([]);

  const { isLoading, selectedDashboards, isSelectDashboardDirty } = useSelector(
    state => state.userFormDashboards
  );
  const { isFormDataChanged, mode, userId } = useSelector(
    state => state.userForm
  );
  const dispatch = useDispatch();
  const toast = useRef(null);

  const onDashboardCheck = e => {
    let checkedDashboards = [...selectedDashboards];
    if (e.checked) checkedDashboards.push(e.value);
    else
      checkedDashboards = checkedDashboards.filter(
        dashboard => dashboard.dashboard_id !== e.value.dashboard_id
      );
    dispatch(
      setUserDashboardsForm({
        selectedDashboards: checkedDashboards,
        isSelectDashboardDirty: true,
      })
    );
    if (!isFormDataChanged) {
      dispatch(userFormUpdateComponent({ isFormDataChanged: true }));
    }
  };

  const fetchInitiationData = async () => {
    dispatch(setUserDashboardsForm({ isLoading: true }));

    try {
      const dashboards = await getDashboardList();
      setAllDashboardList(dashboards);
      if (!!userId && (mode === 'view' || mode === 'edit')) {
        const userDashboards = await getUserAssignedDashboards(userId);
        dispatch(setUserDashboardsForm({ selectedDashboards: userDashboards }));
      }
    } catch (error) {
    } finally {
      dispatch(setUserDashboardsForm({ isLoading: false }));
    }
  };
  useEffect(() => {
    fetchInitiationData();
  }, [userId]);

  const accept = async () => {
    try {
      const dashboardIds = selectedDashboards?.map(
        dashboard => dashboard?.dashboard_id
      );
      if (dashboardIds) {
        const addDashboardsRespnse = await addDashboards(userId, dashboardIds);
        if (addDashboardsRespnse?.status === 200) {
          dispatch(setUserDashboardsForm({ isSelectDashboardDirty: false }));
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'User Data saved successfully',
            life: 3000,
          });
          dispatch(
            userFormUpdateComponent({
              mode: 'edit',
              isFormDataChanged: false,
            })
          );
        }
      } else {
        toast.current.show({
          severity: 'error',
          detail: 'Select at list one dashboard',
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserDashboardsForm({ isLoading: false }));
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Cancel',
      detail: 'User data did not save',
      life: 3000,
    });
  };

  const handleSave = () => {
    if (selectedDashboards?.length === 0) {
      toast.current.show({
        severity: 'error',
        detail: 'Select at list one dashboard',
        life: 3000,
      });
    } else {
      if (isSelectDashboardDirty) {
        confirmDialog({
          message: 'Are you sure you want to save this user data?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle text-xl',
          accept,
          reject,
          acceptClassName: 'p-button-primary p-button-sm',
          rejectClassName: 'p-button-sm p-button-outlined',
        });
      } else {
        dispatch(setUserDashboardsForm({ isSelectDashboardDirty: false }));
      }
    }
  };

  return isLoading ? (
    <SkeletonLoader columnCount={28} columnWidth="24%" mTopBottom={4} />
  ) : (
    <div className="flex flex-column">
      <div className="flex justify-content-end mr-2 mt-3">
        <Button
          label="Save"
          severity="primary"
          size="small"
          onClick={handleSave}
        />
      </div>
      <div className="grid grid-nogutter p-2">
        {allDashboardList?.map(dashboard => {
          return (
            <div
              key={dashboard.dashboard_id}
              className="col-12 sm:col-6 md:col-4 lg:col-3 pr-3 flex align-items-center mb-2 white-space-nowrap overflow-hidden text-overflow-ellipsis"
            >
              <Checkbox
                inputId={dashboard.dashboard_id}
                name="category"
                value={dashboard}
                onChange={onDashboardCheck}
                checked={
                  selectedDashboards &&
                  selectedDashboards.some(
                    item => item.dashboard_id === dashboard.dashboard_id
                  )
                }
              />
              <label
                htmlFor={dashboard.dashboard_id}
                className="ml-2 white-space-nowrap overflow-hidden text-overflow-ellipsis"
              >
                {dashboard.name}
              </label>
            </div>
          );
        })}
      </div>
      <Toast ref={toast} />
    </div>
  );
};
export default UserFormAssignedDashboards;
