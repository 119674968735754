import { createSlice } from '@reduxjs/toolkit';

// when we get into the services architecture
// import {} from '../services';

const initialState = {
  user: {},
  userToken: '',
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    clearState(state, { payload }) {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
    },
    setAccessToken(state, { payload }) {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.userToken = payload.token;
    },
  },
});

export const { clearState, setAccessToken } = userSlice.actions;

export default userSlice.reducer;
