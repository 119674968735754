import { InventoryAdjustmentReducer as Adjustment } from './InventoryAdjustmentSlice';
import { InventoryTransferReducer as Transfer } from './InventoryTransferSlice';
import { ProductDetailsReducer as ProductInfo } from './ProductDetailsSlice';
import { WarehouseReducer as Warehouse } from './WarehouseSlice';
export const inventoryReduces = {
  Adjustment,
  Transfer,
  ProductInfo,
  Warehouse,
};
