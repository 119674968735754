import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import TableLoader from '../../shared/Loader/TableLoader';

import { getProjectConfigDetails, getProjectMaterials } from './Docs.service';

const ProjectMaterials = ({ projectId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projectMaterials, setProjectMaterials] = useState([]);

  const fetchInitData = async () => {
    setIsLoading(true);
    try {
      const initData = await getProjectMaterials(projectId);
      setProjectMaterials(initData?.items);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  return (
    <div className="m-3 p-3 surface-border border-solid border-round">
      {isLoading ? (
        <TableLoader columnCount={5} noOfRow={5} />
      ) : (
        <div>
          <h3 className="text-lg font-bold mb-3">Project Materials</h3>
          <DataTable value={projectMaterials} scrollable scrollHeight="300px">
            <Column field="item_number" header="Item Number"></Column>
            <Column field="material_desc" header="Material Desc"></Column>
            <Column field="model_id" header="Model Id"></Column>
            <Column field="required_product" header="Required Product"></Column>
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default ProjectMaterials;
