import { useRef, useState, useEffect } from 'react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { FileUpload, Message, Toast, Panel, Card, Divider } from 'primereact';

import PFEditor from '../../../shared/PFPrime/PFEditor';
import PFButton from '../../../shared/PFPrime/PFButton';
import InputText from '../../../shared/PFPrime/PFInputText';
import PFAccordion from '../../../shared/PFPrime/PFAccordion';
import ApiService from '../../../../services/api.service';

import EmailList from './EmailList';
import EmailForm from './EmailForm';

const EmailComponent = ({
  hideForm,
  customerId,
  communicationType,
  communicationId,
  opportunityId,
  quoteId,
  invoiceId,
  paymentId,
  primaryEmailAddress,
  isVersionActive = true,
}) => {
  const [reload, setReload] = useState(false);

  return (
    <div className="p-3">
      {isVersionActive && (
        <EmailForm
          customerId={customerId}
          reload={reload}
          setReload={setReload}
          opportunityId={opportunityId}
          quoteId={quoteId}
          invoiceId={invoiceId}
          paymentId={paymentId}
          primaryEmailAddress={primaryEmailAddress}
        />
      )}
      {isVersionActive && <Divider type="solid" />}
      <EmailList
        customerId={customerId}
        communicationType={communicationType}
        communicationId={communicationId}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
};

export default EmailComponent;
