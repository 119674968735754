import React, { useEffect, memo, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import {
  HD_DOC_NUMBERS_FOR_DOCUMENTS,
  HD_DOC_NUMBERS_FOR_PHOTOS,
  LL_DOC_TYPE_FOR_DOCUMENTS,
  LL_DOC_TYPE_FOR_PHOTOS,
  SOURCE_SYSTEMS,
} from '../../../constants';
import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';

const UploadStoreTypeDialog = ({
  type = 'Document',
  openDialogStoreType,
  setOpenDialogStoreType,
  storeType,
  setStoreType,
  docInputRef,
  sourceSystemId,
  hdDocCdNumber,
  setHdDocCdNumber,
  llDocumentType,
  setLlDocumentType,
  onUploadHandler = () => {},
}) => {
  const handleClose = () => {
    setOpenDialogStoreType(false);
  };

  const handleUpload = () => {
    if (docInputRef) {
      docInputRef.current.click();
    }
    if (onUploadHandler) {
      onUploadHandler();
    }
    setOpenDialogStoreType(false);
  };

  const handleChange = event => {
    setStoreType(event.target.value);
  };

  const docNumberOptions =
    type === 'Photo'
      ? HD_DOC_NUMBERS_FOR_PHOTOS
      : type === 'Document'
        ? HD_DOC_NUMBERS_FOR_DOCUMENTS
        : [];

  const [hdDocNumber, setHdDocNumber] = useState(hdDocCdNumber);
  const [isHdDocNumberReadOnly, setIsHdDocNumberReadOnly] =
    useState(!!hdDocCdNumber);
  const [llDocType, setLLDocType] = useState(llDocumentType);

  const llDocTypeOptions =
    type === 'Photo'
      ? LL_DOC_TYPE_FOR_PHOTOS
      : type === 'Document'
        ? LL_DOC_TYPE_FOR_DOCUMENTS
        : [];

  const isReviewPermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.reviewPhoto
          : permissions?.documentCenter?.reviewDoc
      ),
    []
  );
  const isSendPermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.sendToSourcePhoto
          : permissions?.documentCenter?.sendToSourceDoc
      ),
    []
  );
  const isCompletePermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.completePhoto
          : permissions?.documentCenter?.completeDoc
      ),
    []
  );
  return (
    <Dialog
      open={openDialogStoreType}
      onClose={handleClose}
      aria-labelledby="alert-store-type-title"
      aria-describedby="alert-store-type-description"
    >
      <DialogTitle id="alert-store-type-title">
        {`What would you like to do with this ${type}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-store-type-description">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="upload-store-type"
              name="upload-store-type"
              onChange={handleChange}
              value={storeType}
            >
              {isReviewPermission && (
                <FormControlLabel
                  value="NEEDS_REVIEW"
                  control={<Radio />}
                  label={`Needs Review`}
                />
              )}
              <FormControlLabel
                value="INTERNAL"
                control={<Radio />}
                label={`Internal ${type}`}
              />
              {isSendPermission && (
                <FormControlLabel
                  value="SOURCE"
                  control={<Radio />}
                  label="Send to Source"
                />
              )}
              {isCompletePermission && (
                <FormControlLabel
                  value="COMPLETE"
                  control={<Radio />}
                  label="Complete"
                />
              )}
            </RadioGroup>
          </FormControl>
          {
            // Only for home depot
            sourceSystemId === 2 && (
              <FormControl component="fieldset">
                <InputLabel id="doc-number">Doc Number</InputLabel>
                <Select
                  labelId="doc-number"
                  id="doc-number"
                  value={hdDocNumber}
                  disabled={isHdDocNumberReadOnly}
                  onChange={event => {
                    setHdDocNumber(event.target.value);
                    setHdDocCdNumber(event.target.value);
                  }}
                >
                  {docNumberOptions?.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
          {sourceSystemId === SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
            <FormControl component="fieldset">
              <InputLabel id="ll-doc-type">Doc Type</InputLabel>
              <Select
                labelId="ll-doc-type"
                id="ll-doc-type"
                value={llDocType}
                onChange={event => {
                  setLLDocType(event.target.value);
                  setLlDocumentType(event.target.value);
                }}
              >
                {llDocTypeOptions?.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          variant="contained"
          autoFocus
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(UploadStoreTypeDialog);
