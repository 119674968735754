import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  listItems: [],
};

const InventoryAdjustmentSlice = createSlice({
  name: 'InventoryAdjustment',
  initialState: initialState,
  reducers: {
    adjustmentClearState(state) {
      state.listItems = [];
      state.isLoading = true;
    },

    addAdjustment(state, { payload }) {
      state.isLoading = true;
      if (payload.hasOwnProperty('item')) {
        state.listItems = payload?.item;
        state.isLoading = false;
      }
    },
  },
});

export const { adjustmentClearState, addAdjustment } =
  InventoryAdjustmentSlice.actions;

export const InventoryAdjustmentReducer = InventoryAdjustmentSlice.reducer;
