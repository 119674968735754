import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';
import { getQueryResult } from '../../../Admin/Widget/service/widget.service';

export const getDashboardList = async () => {
  try {
    const dashboardRequest = ApiService.get(
      `/dashboard/manage/list/available-dashboards?limit=100&offset=0`
    );
    const [dashboardResponse] = await Promise.all([dashboardRequest]);
    return [...dashboardResponse?.items];
  } catch (error) {
    console.error(error);
  }
};

export const getRolesList = async () => {
  try {
    const rolesRequest = await ApiService.get(
      `/auth/role/list?limit=100&offset=0`
    );
    const rolesList = rolesRequest?.items;
    return rolesList;
  } catch (error) {
    console.error(error);
  }
};

export const getShirtSizesList = async () => {
  try {
    const shirtSizeRequest = await ApiService.get(`/system/shirt-size`);
    const shirtSizesList = shirtSizeRequest;
    return shirtSizesList;
  } catch (error) {
    console.error(error);
  }
};

export const getTeamList = async () => {
  try {
    const teamListResponse = await ApiService.get(`/system/team`);
    return teamListResponse;
  } catch (error) {
    console.error(error);
  }
};

export const getManagersList = async () => {
  try {
    const managerListResponse = await ApiService.get(`/auth/user/report/list`);
    return managerListResponse?.items;
  } catch (error) {
    console.error(error);
  }
};

export const searchManager = async searchString => {
  try {
    const searchManagerResponse = await ApiService.get(
      `/auth/user/report/list?searchString=${searchString}&limit=10&offset=0`
    );
    return searchManagerResponse?.items;
  } catch (error) {
    console.error(error);
  }
};

export const searchCompanyName = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['Companies.company_name', 'Companies.company_id'],
    filters: [
      {
        member: 'Companies.company_name',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchCompanyNameResponse = await getQueryResult(query);
    return searchCompanyNameResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchUserType = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['UserTypes.label', 'UserTypes.user_type_id'],
    filters: [
      {
        member: 'UserTypes.label',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchUserTypeResponse = await getQueryResult(query);
    return searchUserTypeResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserTypeById = async userTypeId => {
  const query = {
    dimensions: ['UserTypes.label', 'UserTypes.user_type_id'],
    filters: [
      {
        member: 'UserTypes.user_type_id',
        values: [`${userTypeId}`],
        operator: 'equals',
      },
    ],
  };
  try {
    const getUserTypeResponse = await getQueryResult(query);
    return getUserTypeResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserWorkroomById = async workRoomId => {
  try {
    return await ApiService.get(`/system/workroom/${workRoomId}`);
  } catch (error) {
    console.error(`Error in fetching workroom list : ${error}`);
    return null;
  }
};

export const getCompanyById = async companyId => {
  const query = {
    dimensions: ['Companies.company_name', 'Companies.company_id'],
    filters: [
      {
        member: 'Companies.company_id',
        values: [`${companyId}`],
        operator: 'equals',
      },
    ],
  };
  try {
    const getCompanyResponse = await getQueryResult(query);
    return getCompanyResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchTeam = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['Teams.team_name', 'Teams.team_id'],
    filters: [
      {
        member: 'Teams.team_name',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchResponse = await getQueryResult(query);
    return searchResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTeamById = async id => {
  const query = {
    dimensions: ['Teams.team_name', 'Teams.team_id'],
    filters: [
      {
        member: 'Teams.team_id',
        values: [`${id}`],
        operator: 'equals',
      },
    ],
  };
  try {
    const getResponse = await getQueryResult(query);
    return getResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchShirtSize = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['ShirtSizes.size_name', 'ShirtSizes.shirt_size_id'],
    filters: [
      {
        member: 'ShirtSizes.size_name',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchResponse = await getQueryResult(query);
    return searchResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getShirtSizeById = async id => {
  const query = {
    dimensions: ['ShirtSizes.size_name', 'ShirtSizes.shirt_size_id'],
    filters: [
      {
        member: 'ShirtSizes.shirt_size_id',
        values: [`${id}`],
        operator: 'equals',
      },
    ],
  };
  try {
    const getResponse = await getQueryResult(query);
    return getResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchShoeSize = async searchString => {
  const query = {
    limit: 10,
    offset: 0,
    dimensions: ['ShoeSizes.shoe_size', 'ShoeSizes.shoe_size_id'],
    filters: [
      {
        member: 'ShoeSizes.shoe_size',
        values: [searchString],
        operator: 'startsWith',
      },
    ],
  };
  try {
    const searchResponse = await getQueryResult(query);
    return searchResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getShoeSizeById = async id => {
  const query = {
    dimensions: ['ShoeSizes.shoe_size', 'ShoeSizes.shoe_size_id'],
    filters: [
      {
        member: 'ShoeSizes.shoe_size_id',
        values: [`${id}`],
        operator: 'equals',
      },
    ],
  };
  try {
    const getResponse = await getQueryResult(query);
    return getResponse?.data;
  } catch (error) {
    console.error(error);
  }
};

export const addNewUser = async (userDetails, newUserImage) => {
  const dropdowns = ['role_id'];
  const dateFields = ['dob', 'hire_date', 'termination_date'];
  const autoCompleteFields = [
    {
      payloadKey: 'manager_id',
      payloadValue: 'user_id',
    },
    {
      payloadKey: 'companyname',
      payloadValue: 'Companies.company_id',
    },
    {
      payloadKey: 'user_type_id',
      payloadValue: 'UserTypes.user_type_id',
    },
    {
      payloadKey: 'team_id',
      payloadValue: 'Teams.team_id',
    },
    {
      payloadKey: 'shoe_size',
      payloadValue: 'ShoeSizes.shoe_size_id',
    },
    {
      payloadKey: 'shirt_size_id',
      payloadValue: 'ShirtSizes.shirt_size_id',
    },
  ];
  const multiselectFields = [
    {
      payloadKey: 'user_workroom_id',
      payloadValue: 'type_id',
    },
  ];
  const formData = new FormData();
  for (const key in userDetails) {
    if (userDetails[key]) {
      switch (true) {
        case dropdowns?.includes(key):
          formData.append(key, userDetails[key][key]);
          break;
        case dateFields?.includes(key):
          formData.append(key, new Date(userDetails[key])?.toISOString());
          break;
        case autoCompleteFields?.map(i => i.payloadKey)?.includes(key):
          const pKey = autoCompleteFields.find(
            i => i.payloadKey === key
          )?.payloadValue;
          formData.append(key, userDetails[key][pKey]);
          break;
        case multiselectFields?.map(i => i.payloadKey)?.includes(key):
          const mKey = multiselectFields.find(
            i => i.payloadKey === key
          )?.payloadValue;
          !!userDetails[key] &&
            userDetails[key]?.length > 0 &&
            formData.append(
              key,
              userDetails[key]
                ?.filter(workRoom => workRoom?.[mKey])
                ?.map(workRoom => workRoom?.[mKey])
                ?.join(',')
            );
          break;
        default:
          formData.append(key, userDetails[key]);
          break;
      }
    }
  }

  formData.append('is_active', 18);
  if (newUserImage) {
    formData.append('file', newUserImage);
  }

  try {
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage`,
      formData,
      { headers: headers }
    );
    return response;
  } catch (error) {
    console.error(error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.error,
    };
  }
};

export const updateUserDetails = async (userId, userDetails, newUserImage) => {
  const dropdowns = ['role_id'];
  const dateFields = ['dob', 'hire_date', 'termination_date'];
  const autoCompleteFields = [
    {
      payloadKey: 'manager_id',
      payloadValue: 'user_id',
    },
    {
      payloadKey: 'companyname',
      payloadValue: 'Companies.company_id',
    },
    {
      payloadKey: 'user_type_id',
      payloadValue: 'UserTypes.user_type_id',
    },
    {
      payloadKey: 'team_id',
      payloadValue: 'Teams.team_id',
    },
    {
      payloadKey: 'shoe_size',
      payloadValue: 'ShoeSizes.shoe_size_id',
    },
    {
      payloadKey: 'shirt_size_id',
      payloadValue: 'ShirtSizes.shirt_size_id',
    },
  ];
  const multiselectFields = [
    {
      payloadKey: 'user_workroom_id',
      payloadValue: 'type_id',
    },
  ];
  const formData = new FormData();
  for (const key in userDetails) {
    if (key === multiselectFields?.[0]?.payloadKey && !userDetails[key]) {
      continue;
    }
    if (userDetails[key]) {
      switch (true) {
        case dropdowns?.includes(key):
          formData.append(key, userDetails[key][key]);
          break;
        case dateFields?.includes(key):
          formData.append(key, new Date(userDetails[key])?.toISOString());
          break;
        case autoCompleteFields?.map(i => i.payloadKey)?.includes(key):
          const pKey = autoCompleteFields.find(
            i => i.payloadKey === key
          )?.payloadValue;
          !!userDetails[key][pKey] &&
            formData.append(key, userDetails[key][pKey]);
          break;
        case multiselectFields?.map(i => i.payloadKey)?.includes(key):
          const mKey = multiselectFields.find(
            i => i.payloadKey === key
          )?.payloadValue;
          !!userDetails[key] &&
            userDetails[key]?.length > 0 &&
            formData.append(
              key,
              userDetails[key]
                ?.filter(workRoom => workRoom?.[mKey])
                ?.map(workRoom => workRoom?.[mKey])
                ?.join(',')
            );
          break;
        default:
          formData.append(key, userDetails[key]);
          break;
      }
    } else {
      formData.append(key, null);
    }
  }

  if (newUserImage) {
    formData.append('file', newUserImage);
  }

  try {
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      formData,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return {
      status: error?.response?.status,
      message: error?.response?.data?.error,
    };
  }
};

export const addDashboards = async (userId, dashboards) => {
  const formData = new FormData();
  formData.append('dashboard_ids', dashboards || '');
  try {
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      formData,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserDetails = async userId => {
  try {
    const userDetailsResponse = await ApiService.get(
      `/auth/user/manage/${userId}`
    );
    return userDetailsResponse;
  } catch (error) {
    console.error(error);
  }
};

export const getUserDetailsViewModeInitialization = async userId => {
  try {
    const rolesRequest = ApiService.get(`/auth/role/list?limit=100&offset=0`);

    const userDetailsRequest = ApiService.get(`/auth/user/manage/${userId}`);
    const [rolesList, userDetails] = await Promise.all([
      rolesRequest,
      userDetailsRequest,
    ]);

    const managerResponse =
      !!userDetails &&
      !!userDetails?.manager_id &&
      (await ApiService.get(`/auth/user/manage/${userDetails?.manager_id}`));

    const companyResponse =
      !!userDetails &&
      !!userDetails?.companyname &&
      (await getCompanyById(userDetails?.companyname));

    const userTypeResponse =
      !!userDetails &&
      !!userDetails?.user_type_id &&
      (await getUserTypeById(userDetails?.user_type_id));

    const userWorkRoomResponse =
      !!userDetails &&
      !!userDetails?.user_workroom_id &&
      userDetails.user_workroom_id.length > 0 &&
      (await Promise.all(
        userDetails.user_workroom_id.map(workroom =>
          getUserWorkroomById(workroom.workroom_id)
        )
      ));

    const teamResponse =
      !!userDetails &&
      !!userDetails?.team_id &&
      (await getTeamById(userDetails?.team_id));

    const shoeResponse =
      !!userDetails &&
      !!userDetails?.shoe_size &&
      (await getShoeSizeById(userDetails?.shoe_size));

    const shirtResponse =
      !!userDetails &&
      !!userDetails?.shirt_size_id &&
      (await getShirtSizeById(userDetails?.shirt_size_id));

    return {
      rolesList: rolesList?.items,
      userDetails: userDetails,
      manager: userDetails?.manager_id
        ? {
            user_name:
              !!managerResponse?.first_name && !!managerResponse?.last_name
                ? `${managerResponse?.first_name} ${managerResponse?.last_name}`
                : '',
            id: `${userDetails?.manager_id}`,
          }
        : undefined,
      companyName: companyResponse?.length > 0 ? companyResponse[0] : undefined,
      userType: userTypeResponse?.length > 0 ? userTypeResponse[0] : undefined,
      team: teamResponse?.length > 0 ? teamResponse[0] : undefined,
      shoeSize: shoeResponse?.length > 0 ? shoeResponse[0] : undefined,
      shirtSize: shirtResponse?.length > 0 ? shirtResponse[0] : undefined,
      userWorkroom: userWorkRoomResponse,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getUserDetailsAddModeInitialization = async () => {
  try {
    const rolesRequest = ApiService.get(`/auth/role/list?limit=100&offset=0`);

    const [rolesList] = await Promise.all([rolesRequest]);

    return {
      rolesList: rolesList?.items,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getUserAssignedDashboards = async userId => {
  try {
    const dashboardRequest = ApiService.get(
      `/dashboard/manage/list/available-dashboards?limit=100&offset=0`
    );
    const userDetailsRequest = await ApiService.get(
      `/auth/user/manage/${userId}`
    );
    const [dashboardResponse, userDetailsResponse] = await Promise.all([
      dashboardRequest,
      userDetailsRequest,
    ]);

    const allDashboards = [...dashboardResponse?.items];

    const userDashboardIds = userDetailsResponse?.dashboard_ids
      ?.split(',')
      .map(id => parseInt(id || 0));

    const userDashboards = allDashboards?.filter(dashboard =>
      userDashboardIds?.includes(dashboard?.dashboard_id)
    );

    return userDashboards;
  } catch (error) {
    console.error(error);
  }
};

export const uploadUserFile = async (userId, documentType, file) => {
  try {
    const formData = new FormData();
    if (!!userId || !!file || !!fileType) {
      formData.append('type', documentType);
      formData.append('file', file);

      let headers = {
        'Content-Type': 'multipart/form-data',
      };

      const filesResponse = await ApiService.post(
        `/auth/user/manage/${userId}/files`,
        formData,
        headers
      );

      return filesResponse;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserTypes = async searchString => {
  try {
    const userTypeUrl = searchString
      ? `/system/user-types?searchString=${searchString}`
      : `/system/user-types`;
    const response = await ApiService.get(userTypeUrl);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createUserType = async userType => {
  try {
    const response = await ApiService.post(`/system/user-types`, {
      label: userType,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createCompany = async companyName => {
  try {
    const filesResponse = await ApiService.post(`/system/company`, {
      company_name: companyName,
    });
    return filesResponse;
  } catch (error) {
    console.error(error);
  }
};
export const createShirtSize = async sizeName => {
  try {
    const filesResponse = await ApiService.post(`/system/shirt-size`, {
      size_name: sizeName,
    });
    return filesResponse;
  } catch (error) {
    console.error(error);
  }
};
export const createShowSize = async shoeSize => {
  try {
    const filesResponse = await ApiService.post(`/system/shoe-size`, {
      shoe_size: shoeSize,
    });
    return filesResponse;
  } catch (error) {
    console.error(error);
  }
};

export const searchWorkRoom = async searchString => {
  try {
    return await ApiService.get(
      `/system/workroom/list?searchString=${searchString}`
    );
  } catch (error) {
    console.error(`Error in fetching workroom list : ${error}`);
  }
};
