import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import { initialValues, itemSubmit } from '../helpers/Items';
import { floatParser } from '../../../../utils/Helpers';

import ItemForm from './ItemForm';

const Items = ({
  reference,
  setItems,
  laborItems,
  merchandiseItems,
  isMeasureSquareEnabled,
}) => {
  const { mode } = useParams();
  const [isMeasureSquare, setIsMeasureSqure] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [formInitValue, setFormInitValue] = useState(initialValues);
  const [editIndex, setEditIndex] = useState(null);

  const submitAction = itemSubmit(
    setGridData,
    setIsForm,
    formInitValue,
    editIndex,
    setEditIndex
  );
  const { handleSubmit, resetForm, values } = submitAction;
  const [editItemDesc, seteditItemDesc] = useState('');

  const accept = indexToDelete => {
    const newArray = gridData.filter((_, index) => index !== indexToDelete);

    const resultArray = newArray.map((item, index) => ({ ...item, index }));
    setItems(resultArray);
    setGridData(resultArray);
  };

  const onDelete = indexToDelete => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this item?',
      icon: 'pi pi-trash',
      acceptClassName: 'p-button-primary p-button-sm',
      accept: () => accept(indexToDelete),
      reject: () => false,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };

  const onEdit = (indexToEdit, item) => {
    resetForm();
    setIsForm(true);
    setEditIndex(indexToEdit);
    seteditItemDesc(item?.itemDescription || '');

    const editObject = gridData.find((_, index) => index === indexToEdit);

    setFormInitValue(editObject);
  };

  useEffect(() => {
    gridData?.length > 0 && setItems(gridData);
  }, [gridData]);

  useEffect(() => {
    if (gridData?.length === 0) {
      if (reference === 'Labor') {
        setGridData(laborItems);
      }
      if (reference === 'Merchandise') {
        setGridData(merchandiseItems);
      }
    }
  }, [laborItems, merchandiseItems]);

  useEffect(() => {
    setIsMeasureSqure(isMeasureSquareEnabled);
  }, [isMeasureSquareEnabled]);

  return (
    <>
      <div className="mt-2 grid w-full flex">
        <div className="col-6 md:col-6 lg:col-6"></div>
        <div className="sm:col-6 md:col-6 lg:col-6">
          {mode !== 'view' ? (
            <div className="align-items-end justify-content-end flex">
              <PFButton
                label="Add"
                onClick={() => {
                  setIsForm(true);
                  setFormInitValue(initialValues);
                  resetForm();
                  setEditIndex(null);
                }}
                icon="pi pi-plus"
                outlined={false}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <div className="p-datatable p-component p-datatable-responsive-scroll">
            <div
              className="p-datatable-wrapper py-0"
              style={{ overflow: 'auto', minHeight: '500px' }}
            >
              <form className="w-full" onSubmit={handleSubmit}>
                <table className="p-datatable-table w-full item-static-table">
                  <thead className="p-datatable-thead">
                    <tr>
                      {mode !== 'view' && <th className="text-left">Action</th>}
                      <th className="text-left">Item</th>
                      <th className="text-left">Qty</th>
                      <th className="text-left">Unit Price</th>
                      <th className="text-left">Discount Type</th>
                      <th className="text-left">Discount </th>
                      {isMeasureSquare && (
                        <>
                          <th className="text-left">Taxable </th>
                          <th className="text-left">Cost Price</th>
                          <th className="text-left">Sales Amount</th>
                          <th className="text-left">Cost Amount</th>
                        </>
                      )}

                      <th className="text-left">Tax (%)</th>
                      <th className="text-left">Tax ($)</th>
                      <th className="text-left">{`${
                        isMeasureSquare ? 'Total Retail ($)' : 'Payment'
                      } `}</th>
                    </tr>
                  </thead>
                  <tbody className="p-datatable-tbody">
                    {isForm && editIndex === null && (
                      <ItemForm
                        submitAction={submitAction}
                        setIsForm={setIsForm}
                        reference={reference}
                        editItemDesc={editItemDesc}
                        isMeasureSquare={isMeasureSquare}
                      />
                    )}

                    {gridData?.length > 0
                      ? gridData?.map((item, index) =>
                          isForm && index === editIndex ? (
                            <ItemForm
                              submitAction={submitAction}
                              setIsForm={setIsForm}
                              reference={reference}
                              editItemDesc={editItemDesc}
                              isMeasureSquare={isMeasureSquare}
                            />
                          ) : (
                            <tr key={index}>
                              {mode !== 'view' && (
                                <td
                                  style={{
                                    maxWidth: '200px',
                                    minWidth: '100px',
                                  }}
                                >
                                  <div className="flex">
                                    <PFButton
                                      icon="pi pi-trash"
                                      outlined
                                      text
                                      severity="primary"
                                      aria-label="Cancel"
                                      className="text-white border-none"
                                      onClick={() => onDelete(index)}
                                    />
                                    <PFButton
                                      icon="pi pi-pencil"
                                      outlined
                                      text
                                      className="ml-2 text-white border-none"
                                      aria-label="Edit"
                                      onClick={() => onEdit(index, item)}
                                    />
                                  </div>
                                </td>
                              )}
                              <td
                                style={{ maxWidth: '200px', minWidth: '200px' }}
                              >
                                <span>{`${item?.itemNumber ? item?.itemNumber + '-' : ''}${
                                  item?.itemDescription
                                }`}</span>
                              </td>
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{item?.quantity}</span>
                              </td>
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{`$${floatParser(item?.unitPrice)}`}</span>
                              </td>
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{item?.discountType}</span>
                              </td>
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{`${item?.discountType === 'Amount' ? '$' : ''}${floatParser(
                                  item?.discount
                                )}${item?.discountType === 'Percentage' ? '%' : ''}`}</span>
                              </td>
                              {isMeasureSquare && (
                                <>
                                  <td
                                    style={{
                                      maxWidth: '200px',
                                      minWidth: '100px',
                                    }}
                                  >
                                    {item?.is_taxable !== undefined &&
                                    item?.is_taxable &&
                                    item?.is_taxable.toString() === '1'
                                      ? 'Yes'
                                      : 'No'}
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: '200px',
                                      minWidth: '100px',
                                    }}
                                  >
                                    <span>{`$${floatParser(item?.cost_price)}`}</span>
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: '200px',
                                      minWidth: '100px',
                                    }}
                                  >
                                    <span>{`$${floatParser(item?.sales_amount)}`}</span>
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: '200px',
                                      minWidth: '100px',
                                    }}
                                  >
                                    <span>{`$${floatParser(item?.cost_amount)}`}</span>
                                  </td>
                                </>
                              )}
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{`${floatParser(item?.tax)}%`}</span>
                              </td>
                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span>{`$${floatParser(item?.taxAmount)}`}</span>
                              </td>

                              <td
                                style={{ maxWidth: '200px', minWidth: '100px' }}
                              >
                                <span className="pl-2">{`$${floatParser(item?.totalPrice)}`}</span>
                              </td>
                            </tr>
                          )
                        )
                      : !isForm && (
                          <tr>
                            <td colSpan={9}>No results found</td>
                          </tr>
                        )}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Items;
