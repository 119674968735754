import React, { useState, useEffect } from 'react';
import { Tooltip } from 'primereact/tooltip';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import { lineItemList } from '../helpers/Items';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';

const ItemForm = ({
  submitAction,
  setIsForm,
  reference,
  editItemDesc,
  addtitionalItems = false,
}) => {
  const discountTypes = [
    { name: 'Amount', value: 'Amount' },
    { name: 'Percentage', value: 'Percentage' },
  ];
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = submitAction;
  const [lineItems, setLineItems] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  const getItemList = (search = null) => {
    const typeId = reference === 'Merchandise' ? 6 : 2;
    const list = lineItemList(search, typeId);
    list
      .then(res => {
        if (res.status === 200) {
          if (res?.data?.items?.length > 0) {
            setLineItems(res?.data?.items);
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    !editItemDesc && getItemList();
  }, [editItemDesc]);

  const searchItem = e => {
    if (e) {
      getItemList(e);
    }
  };

  useEffect(() => {
    if (editItemDesc) {
      searchItem(editItemDesc);
    }
  }, [editItemDesc]);

  useEffect(() => {
    if (values?.itemId) {
      const selectedItem = lineItems.find(
        lineItem => lineItem?.item_id === values?.itemId
      );
      if (selectedItem?.retail_price && !isNaN(selectedItem?.retail_price)) {
        setFieldValue('unitPrice', parseFloat(selectedItem?.retail_price));
      }
      if (selectedItem?.retail_price === null) {
        setFieldValue('unitPrice', 0);
      }
      setFieldValue('itemDescription', selectedItem?.item_desc);
      setFieldValue('itemNumber', selectedItem?.item_number);
      setFieldValue('itemTypeId', selectedItem?.item_type_id);
      setFieldValue('item', selectedItem);
    }
  }, [values?.itemId]);

  const calculateRowPayment = values => {
    let payment = 0;
    let discountAmount = 0;
    let taxAmount = 0;
    let itemDescription = '';

    if (values?.quantity && values?.unitPrice) {
      payment = values?.quantity * values?.unitPrice;
    }
    if (values?.discount && values?.discountType) {
      if (values?.discountType === 'Percentage') {
        discountAmount = (payment * values?.discount) / 100;
        payment = payment - discountAmount;
      }
      if (values?.discountType === 'Amount') {
        discountAmount = values?.discount;
        payment = payment - discountAmount;
      }
    }
    if (values?.tax) {
      taxAmount = (payment * values?.tax) / 100;
      payment = payment + taxAmount;
    }
    return {
      payment: payment,
      discountAmount: discountAmount,
      taxAmount: taxAmount,
    };
  };

  useEffect(() => {
    const pay = calculateRowPayment(values);
    setFieldValue('totalPrice', pay?.payment > 0 ? pay?.payment : '0');
    setFieldValue(
      'discountAmount',
      pay?.discountAmount > 0 ? pay?.discountAmount : '0'
    );
    setFieldValue('taxAmount', pay?.taxAmount > 0 ? pay?.taxAmount : '0');
  }, [values]);

  return (
    <>
      <tr>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <div className="flex mt-1">
            <PFButton
              icon="pi pi-save"
              outlined
              text
              aria-label="Save"
              className="text-white border-none"
              onClick={handleSubmit}
            />
            <PFButton
              icon="pi pi-ban"
              outlined
              text
              aria-label="Cancel"
              className="ml-2 text-white border-none"
              onClick={() => {
                setIsForm(false);
              }}
            />
          </div>
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '200px' }}
        >
          {addtitionalItems ? (
            <>
              <span className="p-float-label">
                <PFInputText
                  id="itemDescription"
                  name="itemDescription"
                  type="text"
                  value={values?.itemDescription || ''}
                  onChange={handleChange}
                  className={
                    touched?.itemDescription && errors?.itemDescription
                      ? 'error p-inputtext-sm quote-items'
                      : 'p-inputtext-sm quote-items'
                  }
                  disabled={values?.project_id ? true : false}
                  minLength={1}
                  maxLength={50}
                />
              </span>
              <PFFormikError
                touched={touched}
                errors={errors}
                field="itemDescription"
              />
            </>
          ) : (
            <>
              <span className="p-float-label">
                <PFDropdown
                  options={lineItems || []}
                  optionLabel="item_desc"
                  optionValue="item_id"
                  name="itemId"
                  filter={true}
                  value={values?.itemId || ''}
                  onChange={setFieldValue}
                  className={
                    touched?.itemId && errors?.itemId
                      ? 'error p-inputtext-sm quote-items'
                      : 'p-inputtext-sm quote-items'
                  }
                  onKeyUp={e => searchItem(e.target.value)}
                  onKeyDown={e => searchItem(e.target.value)}
                  pt={{
                    panel: {
                      className: 'w-28rem',
                    },
                  }}
                  disabled={values?.project_id ? true : false}
                />
              </span>
              <PFFormikError touched={touched} errors={errors} field="itemId" />
            </>
          )}
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFInputNumber
              inputId="quantity"
              name="quantity"
              minFractionDigits={2}
              value={values?.quantity || ''}
              onChange={setFieldValue}
              className={
                touched?.quantity && errors?.quantity
                  ? 'error w-full'
                  : 'w-full'
              }
              version={2}
              inputStyle={{ width: '100px' }}
            />
          </span>
          <PFFormikError touched={touched} errors={errors} field="quantity" />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFInputNumber
              inputId="unitPrice"
              name="unitPrice"
              mode={'currency'}
              currency="USD"
              minFractionDigits={2}
              value={values?.unitPrice || '0'}
              onChange={setFieldValue}
              className={touched?.unitPrice && errors?.unitPrice ? 'error' : ''}
              version={2}
              inputStyle={{ width: '100px' }}
            />
          </span>
          <PFFormikError touched={touched} errors={errors} field="unitPrice" />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFDropdown
              options={discountTypes}
              optionLabel="name"
              optionValue="value"
              name="discountType"
              value={values?.discountType || ''}
              onChange={setFieldValue}
              className={
                touched?.discountType && errors?.discountType
                  ? 'error p-inputtext-sm quote-items'
                  : 'p-inputtext-sm quote-items'
              }
              appendTo={'self'}
            />
          </span>
          <PFFormikError
            touched={touched}
            errors={errors}
            field="discountType"
          />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            {values?.discountType === 'Amount' ? (
              <PFInputNumber
                inputId="discount"
                name="discount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={values?.discount || '0'}
                onChange={setFieldValue}
                className={touched?.discount && errors?.discount ? 'error' : ''}
                version={2}
                inputStyle={{ width: '100px' }}
              />
            ) : (
              <PFInputNumber
                inputId="discount"
                name="discount"
                minFractionDigits={2}
                value={values?.discount || '0'}
                onChange={setFieldValue}
                className={touched?.discount && errors?.discount ? 'error' : ''}
                version={2}
                inputStyle={{ width: '100px' }}
              />
            )}
          </span>
          <PFFormikError touched={touched} errors={errors} field="discount" />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFInputNumber
              inputId="tax"
              name="tax"
              minFractionDigits={2}
              value={values?.tax || '0'}
              onChange={setFieldValue}
              className={touched?.tax && errors?.tax ? 'error' : ''}
              version={2}
              inputStyle={{ width: '100px' }}
            />
          </span>
          <PFFormikError touched={touched} errors={errors} field="tax" />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFInputNumber
              inputId="tax"
              name="taxAmount"
              minFractionDigits={2}
              mode={'currency'}
              currency="USD"
              value={values?.taxAmount || ''}
              onChange={setFieldValue}
              className={touched?.taxAmount && errors?.taxAmount ? 'error' : ''}
              version={2}
              inputStyle={{ width: '100px' }}
              disabled={true}
            />
          </span>
          <PFFormikError touched={touched} errors={errors} field="taxAmount" />
        </td>
        <td
          className="vertical-align-top"
          style={{ maxWidth: '200px', minWidth: '100px' }}
        >
          <span className="p-float-label">
            <PFInputNumber
              inputId="totalPrice"
              name="totalPrice"
              mode={'currency'}
              currency="USD"
              minFractionDigits={2}
              value={values?.totalPrice || ''}
              onChange={setFieldValue}
              className={
                touched?.totalPrice && errors?.totalPrice ? 'error' : ''
              }
              version={2}
              inputStyle={{ width: '100px' }}
              disabled={true}
            />
          </span>
          <PFFormikError touched={touched} errors={errors} field="totalPrice" />
        </td>
      </tr>
    </>
  );
};

export default ItemForm;
