// **** React Imports ****
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// **** External Utilities ****
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';
import { useAlerts } from '../../shared/Alerts/alertsService';
import ApiService from '../../../services/api.service';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import UserDetails from './UserDetails';
import EmergencyContactInfo from './EmergencyContactInfo';
import TeamReviews from './TeamReviews';
import Documents from './Documents';
import Credentials from './Credentials';
// **** Services *****
import {
  getUsersList,
  getUserDataByUserId,
  addNewUser,
  updateUserDetails,
  deleteUser,
  sendEmailInvite,
} from './UserManagement.service';

// **** Styles/Images/Icons ****
import { useStyles } from './AddViewEditUserDetails.styles';

const AddViewEditUserDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [getFormikValues, setFormikValues] = React.useState(false);
  const { userId } = useParams();
  const formAction = window.location.pathname.split('/')[3];
  const history = useHistory();
  const { alert, setAlert, clearAlert } = useAlerts();
  const classes = useStyles();
  const [userData, setUserData] = React.useState();
  const [emergencyContacts, setEmergencyContacts] = React.useState();
  const [teamReviews, setTeamReviews] = React.useState();
  const [documents, setDocuments] = React.useState();
  const [credentials, setCredentials] = React.useState();
  const [, setReloadForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [usersListData, setUsersListData] = useState([]);
  const [count, setCount] = useState();
  const [searchQuery, setSearchQuery] = React.useState({
    limit: null,
    offset: 0,
  });
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [reloadList, setReloadList] = React.useState(false);
  const [fixedRenovatorDetails, setFixedRenovatorDetails] = React.useState('');

  const [image, setImage] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });

  const userDetailsBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/usermanagement',
      text: 'User Management',
    },
    {
      text:
        formAction === 'add'
          ? 'New User'
          : formAction === 'edit'
            ? 'Edit User'
            : 'User Details',
    },
  ];
  React.useEffect(() => {
    //clearAlert();
  }, [formAction]);

  const featchLrrpDetails = async () => {
    const response = await ApiService.get('/system/lrrp-configuration');
    setFixedRenovatorDetails(response.fixed_renovator_details);
  };

  React.useEffect(() => {
    featchLrrpDetails();
    if (formAction !== 'add') {
      getUserDataByUserId(
        userId,
        setLoading,
        setAlert,
        setUserData,
        setEmergencyContacts,
        setTeamReviews,
        setDocuments,
        setCredentials,
        setReloadList
      );
    }
  }, []);

  React.useEffect(() => {
    if (reloadList) {
      getUserDataByUserId(
        userId,
        setLoading,
        setAlert,
        setUserData,
        setEmergencyContacts,
        setTeamReviews,
        setDocuments,
        setCredentials,
        setReloadList
      );
    }
  }, [reloadList]);

  React.useEffect(() => {
    getUsersList(
      searchQuery,
      selectedFilters,
      setLoading,
      setUsersListData,
      setCount
    );
  }, [searchQuery, selectedFilters]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // // **** Form Validation Schema ****
  const userDataValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required('Required')
      .nullable()
      .matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid First Name.'),
    last_name: Yup.string()
      .trim()
      .required('Required')
      .nullable()
      .matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid Last Name.'),
    email: Yup.string()
      .trim()
      .email('Invalid Email')
      .required('Required')
      .nullable(),
    phone_number: Yup.string()
      .trim()
      .required('Required')
      .matches(
        /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
        'Invalid Phone number'
      )
      .nullable(),
    is_active: Yup.boolean(),
    role_id: Yup.string().trim().required('Required'),
    google_email_address: Yup.string().trim().nullable().email('Invalid Email'),
    dashboard_ids: Yup.string().trim().required('Required').nullable(),
    termination_date: Yup.date()
      .typeError('Choose a valid Date')
      .nullable()
      .default(null)
      .min(today, 'Date cannot be in the past'),
  });

  let formik = useFormik({
    initialValues: {
      first_name: formAction !== 'add' ? userData?.first_name || null : null,
      last_name: formAction !== 'add' ? userData?.last_name || null : null,
      email: formAction !== 'add' ? userData?.email || null : null,
      phone_number: formAction !== 'add' ? userData?.contact_no || null : '',
      file: formAction !== 'add' ? userData?.profile_image_url || null : '',
      is_active:
        formAction !== 'add'
          ? userData?.is_active === 18
            ? true
            : false
          : true,
      role_id: formAction !== 'add' ? userData?.role_id || null : null,
      role_name: formAction !== 'add' ? userData?.role_name || null : null,
      dashboard_ids:
        formAction !== 'add' ? userData?.dashboard_ids || null : null,
      dob: formAction !== 'add' ? userData?.dob || null : null,
      team_member_id:
        formAction !== 'add'
          ? userData?.userLowesRegDetails?.team_member_id || null
          : null,
      manager_id: formAction !== 'add' ? userData?.manager_id || null : null,
      position_id: formAction !== 'add' ? userData?.position_id || null : null,
      hire_date: formAction !== 'add' ? userData?.hire_date || null : null,
      termination_date:
        formAction !== 'add' ? userData?.termination_date || null : null,
      shirt_size_id:
        formAction !== 'add' ? userData?.shirt_size_id || null : null,
      shoe_size: formAction !== 'add' ? userData?.shoe_size || null : null,
      //pay_schedule: formAction !== 'add' ? userData?.pay_schedule : '',
      //pay_method: formAction !== 'add' ? userData?.pay_method : '',
      store_coverage_ids:
        formAction !== 'add'
          ? userData?.store_coverage_ids
            ? userData?.store_coverage_ids?.trim()
            : null
          : null,
      type_coverage_ids:
        formAction !== 'add'
          ? userData?.type_coverage_ids
            ? userData?.type_coverage_ids?.trim()
            : null
          : null,
      category_coverage_ids:
        formAction !== 'add'
          ? userData?.catergory_coverage_ids
            ? userData?.catergory_coverage_ids
            : null
          : null,
      team_ids:
        formAction !== 'add'
          ? userData?.team_id
            ? userData?.team_id?.trim()
            : null
          : null,
      google_email_address:
        formAction !== 'add' ? userData?.google_email_address || null : null,
      estimator_id:
        formAction !== 'add' ? userData?.estimator_id || null : null,
      digital_badge_id:
        formAction !== 'add' ? userData?.digital_badge_id || null : null,
      home_address:
        formAction !== 'add' ? userData?.home_address || null : null,
      work_address:
        formAction !== 'add' ? userData?.work_address || null : null,
      emergencyContactInfo:
        formAction !== 'add' ? userData?.emergency_contact_info || null : null,
      installer_bio:
        formAction !== 'add' ? userData?.installerbio || null : null,
      measuring_system_id:
        formAction !== 'add' ? userData?.measuringsystemid || null : null,
      scheduler_sort_order:
        formAction !== 'add' ? userData?.schedulesortorder || null : null,
      team_reviews:
        formAction !== 'add' ? userData?.team_reviews || null : null,
      companyname: formAction !== 'add' ? userData?.companyname || null : null,
      renovator_name:
        formAction !== 'add' ? userData?.renovator_name || null : null,
      renovator_certification_number:
        formAction !== 'add'
          ? userData?.renovator_certification_number || null
          : null,
    },

    validationSchema: userDataValidationSchema,
    onSubmit: values => {
      // Check if Action is Edit and email is not changed.
      if (formAction === 'edit' && values?.email === userData?.email) {
        setLoading(true);
        updateUserDetails(
          userId,
          values,
          history,
          false,
          setLoading,
          setAlert,
          image
        );
      } else {
        setFormikValues(values);
        handleClickOpen();
      }
      if (
        formAction === 'edit' &&
        usersListData?.filter(a => a?.role_id === 1)?.length <= 1 &&
        formik?.values?.role_id !== 1
      ) {
        handleClickUserOpen();
      }
    },
    enableReinitialize: true,
  });

  const onUserDeleteConfirmDialog = async () => {
    setReloadForm(false);
    deleteUser(
      userData.user_id,
      setLoading,
      setAlert,
      setReloadForm,
      setConfirmDialog
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    setLoading(true);
    addNewUser(getFormikValues, history, setLoading, setAlert, image);
    setOpen(false);
  };
  const dialogAddNewUser = () => {
    setLoading(true);
    const data = { ...getFormikValues, sent_email_invite: true };
    addNewUser(data, history, setLoading, setAlert, image);
    setOpen(false);
  };

  const dialogEditUserWithInviteSent = () => {
    setLoading(true);
    updateUserDetails(
      userId,
      getFormikValues,
      history,
      true,
      setLoading,
      setAlert,
      image
    );
    setOpen(false);
  };

  const dialogEditUserWithNoInviteSent = () => {
    setLoading(true);
    updateUserDetails(
      userId,
      getFormikValues,
      history,
      false,
      setLoading,
      setAlert,
      image
    );
    setOpen(false);
  };

  const handleSendInvite = userId => {
    setLoading(true);
    sendEmailInvite([{ userId: userId }], setLoading, setAlert);
  };
  const handleClickUserOpen = () => {
    setUserModalOpen(true);
  };
  const handleCloseAdmin = () => {
    setUserModalOpen(false);
  };

  return (
    <>
      <form className={classes.formWrapper}>
        <Grid container direction="column" spacing={2}>
          {alert.exists && (
            <Grid item>
              {' '}
              <Alert />
            </Grid>
          )}
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <PageHeader
                pageTitle={
                  formAction === 'add'
                    ? 'New User'
                    : formAction === 'edit'
                      ? 'Edit User Details'
                      : 'User Details'
                }
                breadCrumbArray={userDetailsBreadcrumb}
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="center">
                {loading && <PFTableLoader />}
              </Grid>
            </Grid>

            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                spacing={2}
              >
                {formAction === 'view' ? (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={loading}
                        onClick={() =>
                          history.push(`/admin/usermanagement/edit/${userId}`)
                        }
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          !formik.isValid ||
                          loading ||
                          !formik?.values?.is_active
                        }
                        onClick={() => handleSendInvite(userId)}
                      >
                        Send Invite
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          history.push(`/admin/usermanagement/add`);
                        }}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          if (formAction === 'edit') {
                            formik.resetForm();
                            history.push(
                              `/admin/usermanagement/view/${userId}`
                            );
                          } else {
                            history.goBack();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!formik?.dirty || !formik.isValid || loading}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.fullWidthGridItem}>
            <Paper square className={classes.paperRoot} elevation={0}>
              <Grid container direction="column">
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  className={classes.containerHeader}
                  xs={12}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    spacing={1}
                    alignItems="center"
                    classes={{ root: classes.statusWrapper }}
                  >
                    <Grid item>
                      <Typography variant="h3">User Details</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={9}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    classes={{ root: classes.buttonWrapper }}
                  >
                    <Grid item>
                      <img
                        alt="Business"
                        width="15"
                        height="15"
                        src={'/images/formIcons/Close.png'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid item className={classes.containerContent}>
                  <UserDetails
                    loading={loading}
                    setLoading={setLoading}
                    userId={userId}
                    formik={formik}
                    action={formAction}
                    setImage={setImage}
                    fixedRenovatorDetails={fixedRenovatorDetails}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {formAction !== 'add' ? (
            <>
              <Grid item spacing={2} xs={12}>
                <EmergencyContactInfo
                  emergencyContactInfo={emergencyContacts}
                  userId={userId}
                  setReloadEmergencyContactInfoList={setReloadList}
                />
              </Grid>
              <Grid item spacing={2} xs={12}>
                <TeamReviews
                  teamReviews={teamReviews}
                  userId={userId}
                  setReloadTeamReviewsList={setReloadList}
                />
              </Grid>
              <Grid item spacing={2} xs={12}>
                <Documents
                  documents={documents}
                  userId={userId}
                  setReloadDocumentsList={setReloadList}
                />
              </Grid>
              <Grid item spacing={2} xs={12} style={{ maxWidth: '100%' }}>
                <Credentials
                  credentialsData={credentials}
                  userId={userId}
                  setReloadCredentialsList={setReloadList}
                />
              </Grid>
            </>
          ) : (
            ''
          )}
        </Grid>
      </form>

      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onUserDeleteConfirmDialog}
      />
      {/* Dialog box for confirming to send email invite */}
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-send-email-invite-title"
        aria-describedby="alert-send-email-invite-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to send email invite?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Send an email invitation to ${formik?.values?.first_name} ${formik?.values?.last_name}. The email contains a link directly to this application.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {formAction === 'edit' ? (
            <>
              <Button
                color="primary"
                variant="outlined"
                onClick={dialogEditUserWithNoInviteSent}
              >
                Disagree
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={dialogEditUserWithInviteSent}
                autoFocus
              >
                Agree
              </Button>
            </>
          ) : (
            <>
              <Button color="primary" variant="outlined" onClick={handleClose}>
                Disagree
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={dialogAddNewUser}
                autoFocus
              >
                Agree
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* Dialog box for Restricting User as Admin */}
      <Dialog
        open={userModalOpen}
        onClose={handleCloseAdmin}
        aria-labelledby="alert-restrict-user"
        aria-describedby="alert-restrict-user-description"
      >
        <DialogTitle id="alert-dialog-restrict-title">
          {'Changing User type Restricted!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-restrict-description">
            {
              'You cannot change this Admin user as other user type, since the user is a Company Admin.'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleCloseAdmin}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddViewEditUserDetails;
