import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  radioLabel: {
    color: '#777e98',
    fontSize: '1em',
    fontWeight: 500,
    margin: '5px 5px',
  },
  paperComponent: { width: '100%', padding: '10px' },
}));
