import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  titleHeaderGrid: {
    padding: '10px 24px 10px 10px',
  },
  formEdit: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #92A9BE',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #92A9BE',
    },
    '& .MuiInput-underline.Mui-focused': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));
