// **** React Imports ****
// **** External Utilities ****
import { Typography, TextField, Checkbox, IconButton } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { Close as CloseIcon } from '@material-ui/icons';

// **** Styles/Images/Icons ****
import { useStyles } from './ViewEditTemplate.styles';

const Field = ({
  item,
  fieldUpdated,
  setOpenTableWidget,
  setDroppedCoords,
  removeField,
}) => {
  const classes = useStyles();
  const [, dragRef] = useDrag(
    () => ({
      type: 'box',
      item: { ...item, movedItem: true },
      collect: monitor => ({}),
    }),
    [item]
  );
  return (
    <div
      ref={dragRef}
      style={{
        position: 'absolute',
        left: item.x,
        top: item.y,
      }}
    >
      {item.field_type === 'textInput' && (
        <>
          <TextField
            value={
              item?.source && item?.field_name
                ? '~' + item?.source_config.cube + ':' + item?.field_name + '~'
                : ''
            }
            onClick={() => {
              setDroppedCoords({ x: item?.x, y: item?.y + 20 });
              setOpenTableWidget(item.index);
            }}
            //onChange={(event) => fieldUpdated(item.index, event.target.value)}
            inputProps={{ className: classes.input }}
            className={classes.draggableText}
          />
          <IconButton
            className={classes.closeButton}
            onClick={() => removeField(item.index, item.field_type)}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </>
      )}
      {item.field_type === 'checkbox' && (
        <>
          <Checkbox
            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
            className={classes.checkbox}
            onClick={() => {
              setDroppedCoords({ x: item?.x, y: item?.y + 20 });
              setOpenTableWidget(item.index);
            }}
          />
          <IconButton
            className={classes.closeButton}
            onClick={() => removeField(item.index, item.field_type)}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </>
      )}
      {item.field_type === 'signature' && (
        <>
          <div className={classes.signaturebox}>
            <Typography>signature</Typography>
          </div>
          <IconButton
            className={classes.signatureCloseButton}
            onClick={() => removeField(item.index, item.field_type)}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </>
      )}
      {item.field_type === 'today' && (
        <>
          <div className={classes.todaybox}>
            <Typography>Today</Typography>
          </div>
          <IconButton
            className={classes.todayCloseButton}
            onClick={() => removeField(item.index, item.field_type)}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default Field;
