import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './TaskManagement.css';
import { Toast } from 'primereact/toast';

import { addNoteAPI } from './TaskManagement.service';
import NoteList from './NoteList';

const AddNote = ({ task_id, typeOfProject, taskProjectId, tableName }) => {
  const [refreshNote, setRefreshNote] = useState(false);
  const toast = useRef(null);

  const validation = Yup.object().shape({
    note: Yup.string().required('Note is required.'),
  });

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: validation,
    onSubmit: async data => {
      const response = await addNoteAPI(
        data.note,
        task_id,
        taskProjectId,
        tableName,
        typeOfProject
      );
      formik.resetForm();

      if (response) {
        setRefreshNote(!refreshNote);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Note successfully added.',
          life: '5000',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Unable to add note.',
          detail: response.error,
          life: '5000',
        });
      }
    },

    enableReinitialize: true,
  });

  useEffect(() => {
    formik.resetForm();
  }, [refreshNote, task_id]);

  return (
    <>
      <Toast
        baseZIndex="10000"
        className="taks-toast"
        style={{ zIndex: '9999!important' }}
        ref={toast}
      />
      <div className="py-2 px-3 border-200 mt-2 border-1 shadow-1 notes">
        <h3 className="mt-0 mb-2">Notes</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-inputgroup">
            <InputText
              placeholder="Add note..."
              id="note"
              name="note"
              onChange={formik.handleChange}
              value={formik?.values?.note}
              className="w-12 m-0"
            />
            <Button
              type="submit"
              severity="primary"
              label="Add"
              className="text-sm font-light height-40"
            />
          </div>
          {formik.touched.note && formik.errors.note ? (
            <div className="error">{formik.errors.note}</div>
          ) : null}
        </form>

        <NoteList
          taskId={task_id}
          typeOfProject={typeOfProject}
          taskProjectId={taskProjectId}
          refreshNote={refreshNote}
          tableName={tableName}
        />
      </div>
    </>
  );
};

export default AddNote;
