import { useFormik } from 'formik';
import * as Yup from 'yup';

import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

const auditLogInit = {
  reportName: '',
  moduleId: '',
  subModuleId: '',
  fields: [],
};

const auditLogValidation = Yup.object().shape({
  reportName: Yup.string().required('required'),
  moduleId: Yup.number().required('required'),
  subModuleId: Yup.number().required('required'),
});

const transformArray = arr => {
  return arr?.map(item => {
    return {
      field: item?.field,
      title: item?.title,
      order: arr?.indexOf(item) + 1,
    };
  });
};

const updateTransformArray = arr => {
  return arr?.map(item => {
    return {
      audit_log_modal_field_id: item?.audit_log_modal_field_id,
      field: item?.field,
      title: item?.title,
      order: arr?.indexOf(item) + 1,
    };
  });
};

export const auditLogSubmit = (
  toast,
  history,
  action,
  getValues = null,
  setBlocked
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: getValues ? getValues : auditLogInit,
    validationSchema: auditLogValidation,
    onSubmit: async values => {
      setBlocked(true);
      if (action === 'add') {
        const transformedArray = transformArray(values?.fields);
        const payload = {
          report_name: values?.reportName,
          module_id: values?.moduleId,
          sub_module_id: values?.subModuleId,
          fields: transformedArray,
        };
        try {
          const response = await ApiService.post(
            URL_CONSTANTS.AUDIT_LOG.module.createConfiguration,
            payload
          );
          if (response?.status === true) {
            setBlocked(false);
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Created Successfully',
              life: 3000,
            });
            setTimeout(() => {
              history.push('/audit-log-reports');
            }, 1000);
          }
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      } else {
        const transformedArray = updateTransformArray(values?.fields);
        const payload = {
          report_name: values?.reportName,
          module_id: values?.moduleId,
          sub_module_id: values?.subModuleId,
          fields: transformedArray,
        };
        try {
          const response = await ApiService.patch(
            `${URL_CONSTANTS.AUDIT_LOG.module.updateConfiguration}${values?.audit_log_modal_id}`,
            payload
          );
          if (response?.status === true) {
            setBlocked(false);
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Successfully',
              life: 3000,
            });
            setTimeout(() => {
              history.push('/audit-log-reports');
            }, 1000);
          }
        } catch (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  });
};
