import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';

import { getConvertedDate, getUserTimeZone } from '../../../utils/Helpers';

export default function ShowLogsDialog(props) {
  const [displayData, setDisplayData] = useState([]);
  const selectedItemRef = useRef(null);

  useEffect(() => {
    if (props.selectedItem?.user_meta?.first_name?.length > 0) {
      selectedItemRef.current = props.selectedItem;
    }
    if (selectedItemRef.current?.project_item_id) {
      let name = '';
      if (
        typeof selectedItemRef?.current?.project_item_user?.user_meta !==
        'undefined'
      ) {
        const first_name =
          selectedItemRef?.current?.project_item_user?.user_meta?.first_name;
        const last_name =
          selectedItemRef?.current?.project_item_user?.user_meta?.last_name;

        if (first_name && first_name?.trim().length > 0) {
          name = first_name;
        }
        if (last_name && last_name?.trim().length > 0) {
          name = `${name} ${last_name}`;
        }
      }

      let modifiedAt = '';
      if (selectedItemRef?.current?.modified_at) {
        modifiedAt = getConvertedDate(
          getUserTimeZone(),
          selectedItemRef?.current?.modified_at
        );
      }

      setDisplayData([
        {
          modified_at: modifiedAt,
          name: name,
        },
      ]);
    }
  }, [props.selectedItem]);

  const handleClose = () => {
    props.onHide();
  };

  return (
    <Dialog
      header="Logs"
      visible={props.visible}
      onHide={() => handleClose()}
      className="w-5"
      pt={{
        header: { className: 'mb-0 pb-0' },
        content: { className: 'pt-2' },
      }}
    >
      <DataTable
        value={displayData}
        stripedRows={true}
        showGridlines={true}
        size="small"
      >
        <Column
          field="modified_at"
          header="Last Modified At"
          pt={{
            headerCell: { className: 'bg-primary' },
          }}
          style={{ width: '150px' }}
        ></Column>
        <Column
          field="name"
          header="Last Modified By"
          pt={{
            headerCell: { className: 'bg-primary' },
          }}
          style={{ width: '150px' }}
        ></Column>
      </DataTable>
    </Dialog>
  );
}
