// **** External Utilities ****
import axios from 'axios';

import { momentTz } from '../../../utils/Helpers';
import { CONFIG } from '../../../constants';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Availability UpdatesList
export const getAvailabilityUpdatesList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setAlert,
  setAvailabilityUpdatesListData,
  setAvailabilityCount
) => {
  try {
    setLoading(true);

    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters).length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        .filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val.toString())}`
        )
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/installers/reports/availability?${newQueryString}`
    );
    if (response?.status === 200) {
      setAvailabilityUpdatesListData(response?.data?.items);
      setAvailabilityCount(response?.data?.count);
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch Availability updates', false, true);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

// Get dropdown options
export const getInstallersList = async (
  setLoading,
  setAlert,
  setInstallersList
) => {
  try {
    setLoading(true);
    const installersResponse = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );
    if (installersResponse?.status === 200) {
      setInstallersList(installersResponse?.data);
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch Installers', false, true);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getAvailabilityUpdate = async (
  installerId,
  userTimeOffId,
  setLoadingAvailability,
  setAlert,
  setAvailabilityUpdatesItemData
) => {
  try {
    setLoadingAvailability(true);

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/availability/${userTimeOffId}`
    );
    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch Availability Update', false, true);
  } finally {
    setLoadingAvailability(false);
  }
};

// Create Availability Update
export const createAvailabilityUpdate = async (
  createObj,
  setLoading,
  setAlert,
  setReloadForm,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    let createRequestObj = {
      user_id: createObj.user_id,
      time_off_start_date: momentTz(`${createObj.time_off_start_date}:00`),
      time_off_end_date: momentTz(`${createObj.time_off_end_date}:00`),
      full_day: createObj.full_day,
      is_recurring: createObj.is_recurring,
      is_recurring_every_other_week: createObj.is_recurring_every_other_week,
      sun: createObj.sun,
      mon: createObj.mon,
      tue: createObj.tue,
      wed: createObj.wed,
      thus: createObj.thus,
      fri: createObj.fri,
      sat: createObj.sat,
      time_off_note: createObj.time_off_note.trim() || null,
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${createObj.installer_id}/availability`,
      createRequestObj
    );
    if (response?.data?.status === false && response?.data?.message) {
      setReloadForm(true);
      setAlert('error', response?.data?.message);
    }
    if (response?.status === 200 && response?.data?.status === true) {
      setReloadForm(true);
      setAlert('success', 'Availability Update added successfully.');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};
// Update Availability Update
export const updateAvailabilityUpdate = async (
  updateObj,
  setLoading,
  setAlert,
  setReloadForm,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    let updateRequestObj = {
      time_off_start_date: momentTz(`${updateObj.time_off_start_date}:00`),
      time_off_end_date: momentTz(`${updateObj.time_off_end_date}:00`),
      full_day: updateObj.full_day,
      is_recurring: updateObj.is_recurring,
      is_recurring_every_other_week: updateObj.is_recurring_every_other_week,
      sun: updateObj.sun,
      mon: updateObj.mon,
      tue: updateObj.tue,
      wed: updateObj.wed,
      thus: updateObj.thus,
      fri: updateObj.fri,
      sat: updateObj.sat,
      time_off_note: updateObj.time_off_note.trim() || null,
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${updateObj.installer_id}/availability/${updateObj.user_time_off_request_id}`,
      updateRequestObj
    );
    if (response) {
      setReloadForm(true);
      setAlert('success', 'Availability updated successfully.');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};

//updates status of Availability Update
export const updateAvailabilityUpdateStatus = async (
  statusUpdateObj,
  setLoading,
  setAlert,
  setReloadForm,
  setConfirmDialog
) => {
  try {
    setLoading(true);
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${statusUpdateObj.installer_id}/availability/${statusUpdateObj.user_time_off_request_id}/UpdateStatus`,
      {
        installer_user_id: statusUpdateObj.installer_user_id,
        status_id: statusUpdateObj.status_id,
      }
    );
    if (response?.status === 200) {
      setReloadForm(true);
      if (response?.data?.status === true) {
        setAlert('success', response?.data?.message);
      } else {
        setAlert('error', response?.data?.message.split('Error:')?.[1]);
      }
    }
  } catch (error) {
    setAlert('error', 'Error while updating status', false, true);
  } finally {
    setLoading(false);
  }
};

export const updateInstallerBlockTime = async (
  installerId,
  userTimeOffRequestId,
  payload
) => {
  try {
    const response = await axios.patch(
      `${CONFIG.API_BASE_URL}/installers/${installerId}/availability/${userTimeOffRequestId}`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteInstallerBlockTime = async (
  installerId,
  userTimeOffRequestId
) => {
  try {
    const response = await axios.delete(
      `${CONFIG.API_BASE_URL}/installers/${installerId}/availability/${userTimeOffRequestId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
