import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const getUserContactData = async userId => {
  try {
    const relationsRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/relationship-type`
    );
    const userDetailsRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`
    );

    const [relationsList, userDetailsResponse] = await Promise.all([
      relationsRequest,
      userDetailsRequest,
    ]);

    return {
      relationsList: relationsList?.data,
      contactInfo: userDetailsResponse?.data?.emergency_contact_info || [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const editEmergencyContact = async (userId, contactInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      { emergency_contact_info: JSON.stringify(contactInfo) }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
