import React from 'react';
/*Prime Imports*/
import { InputTextarea } from 'primereact/inputtextarea';

const DeclineReason = ({ formik, disabledFields }) => {
  return (
    <div className="grid p-2">
      <div className="col-12">
        <span className="p-float-label">
          <InputTextarea
            id="decline_reason"
            className="w-full"
            value={formik?.values?.decline_reason || ''}
            onChange={e => {
              formik.setFieldValue('decline_reason', event.target.value || '');
            }}
            disabled={disabledFields}
            rows={8}
          />
          <label htmlFor="decline_reason">Decline Reason</label>
        </span>
      </div>
    </div>
  );
};

export default DeclineReason;
