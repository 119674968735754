import { React, useState, useEffect } from 'react';

import PFDataTable from '../../CRM/PFPrime/PFDataTable';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { formatDateTimeMDYHM } from '../../../utils/Helpers';
import apiService from '../../../services/api.service';
import { CONFIG } from '../../../constants';

const Stages = ({ projectId }) => {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);

  const stageCol = [
    {
      field: 'stageId',
      header: 'Stage Type',
      body: data => (data?.stageId ? data.stageId : ''),
    },
    {
      field: 'staged',
      header: 'Staged',
      body: data => (data?.staged ? (data?.staged === 1 ? 'Yes' : 'No') : 'No'),
    },
    {
      field: 'stagedAt',
      header: 'Staged At',
      body: data =>
        data?.stagedAt ? formatDateTimeMDYHM(data.stagedAt) : null,
    },
    {
      field: 'stagedBy',
      header: 'Staged By',
      body: data => (data?.stagedBy ? data.stagedBy : ''),
    },
  ];
  const getStages = async () => {
    setLoading(true);
    try {
      const response = await apiService.getWithDiffBaseUrl(
        `/projects/project-stages/${projectId}`,
        '',
        CONFIG.API_PROJECT_URL
      );
      if (response && response?.data) {
        setLoading(false);
        setStages(response.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStages();
  }, []);
  return (
    <>
      <div className="card w-12 pt-2">
        <div className="p-2 my-2">
          {loading ? (
            <PFTableLoader />
          ) : (
            <>
              <div className="my-2">
                <span className="text-xl font-bold">Stages</span>
              </div>
              <PFDataTable columns={stageCol} stripedRows data={stages || []} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Stages;
