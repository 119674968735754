import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';

import PageHeader from '../../shared/PageHeader/PageHeader';

import '../style.css';
import PFButton from '../../shared/PFPrime/PFButton';
import { fetchCustomerInfo } from '../Clients/helpers/CustomerInfo';
import SkeletonLoader from '../../shared/Loader/skeleton';
import CommunicationsContainer from '../Communications/CommunicationsContainer';
import { COMMUNICATION_TYPE } from '../Communications/constants/constants';
import { isMeasureSquareEnabled } from '../../../utils/Helpers';

import OpportunityContact from './components/OpportunityContact';
import OpportunityInfo from './components/OpportunityInfo';
import {
  leadSubmit,
  opportunityBreadcrumb,
  apiResponse,
  fetchOpportunityById,
} from './helpers/Opportunity';

const OpportunityContainer = () => {
  const history = useHistory();
  const queryParams = useParams();
  const { mode, customerId, actionId } = queryParams;
  const toast = useRef(null);

  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState('');
  const [opportunityDetails, setOpportunityDetails] = useState(null);

  const submitAction = leadSubmit(
    setLoading,
    toast,
    history,
    opportunityDetails,
    mode
  );

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    submitAction;

  const customerInformations = () => {
    setLoading(true);
    fetchCustomerInfo(customerId, setCustomerDetails, setLoading);
  };

  const opportunityInformations = history => {
    fetchOpportunityById(
      actionId,
      customerId,
      setOpportunityDetails,
      setLoading,
      history
    );
  };

  useEffect(() => {
    if (customerId) {
      customerInformations();
      setFieldValue('customerId', customerId);
    }
  }, [customerId]);

  useEffect(() => {
    actionId && opportunityInformations(history);
  }, [actionId, mode]);

  const accept = () => {
    return history.push(`/crm/view/customer/${customerId}`);
  };

  const reject = () => false;

  const calcelConfirm = event => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Do you want to cancel this?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-info',
      accept,
      reject,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };
  const customerName = customerDetails
    ? `${customerDetails.first_name} ${customerDetails.last_name}`
    : '';

  return (
    <div className="w-full crm">
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid w-full crm flex">
          <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
            <PageHeader
              pageTitle={
                <>{`${mode[0].toUpperCase() + mode.slice(1)} Lead ${
                  mode !== 'create'
                    ? `: ${values?.opportunityNumber || ''}`
                    : ''
                }`}</>
              }
              breadCrumbArray={opportunityBreadcrumb(
                mode,
                opportunityDetails?.opportunityNumber,
                customerId,
                customerName
              )}
            />
          </div>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <>
              <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
                <div className="mb-2 align-items-end justify-content-end flex">
                  <PFButton
                    label={`Cancel`}
                    className="ml-1"
                    severity="primary"
                    outlined={true}
                    onClick={
                      mode !== 'view'
                        ? calcelConfirm
                        : () => history.push(`/crm/view/customer/${customerId}`)
                    }
                  />
                  {isMeasureSquareEnabled(
                    opportunityDetails?.from_external_source
                  ) && (
                    <PFButton
                      icon="pi pi-file-import"
                      label={`Project`}
                      className="ml-2"
                      severity="primary"
                      outlined={true}
                      onClick={() => {
                        window.open(
                          `/crm/${customerId}/${actionId}/project/view/${opportunityDetails?.external_project_id}`
                        );
                      }}
                    />
                  )}

                  {mode === 'view' ? (
                    <PFButton
                      onClick={() =>
                        history.push(`/crm/${customerId}/lead/edit/${actionId}`)
                      }
                      label="Edit"
                      className="ml-1"
                      outlined={false}
                    />
                  ) : (
                    <PFButton
                      onClick={handleSubmit}
                      label="Save"
                      className="ml-1"
                      outlined={false}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 md:col-12 lg:col-12 p-0 mt-1">
                <OpportunityInfo {...submitAction} />
                <OpportunityContact
                  {...submitAction}
                  customerDetails={customerDetails}
                />
                {mode === 'view' && (
                  <div className="mt-3">
                    <CommunicationsContainer
                      customerId={customerId}
                      opportunityId={actionId}
                      communicationType={COMMUNICATION_TYPE.opportunity}
                      communicationId={actionId}
                      primaryEmailAddress={opportunityDetails?.primaryEmail}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default OpportunityContainer;
