import { useRef } from 'react';
import { Dialog, Toast } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import {
  deleteCoveragePreference,
  updateGroupDelete,
} from '../services/user-form-coverage.service';
import {
  bulkDelete,
  deleteJobCoverage,
} from '../services/user-form-job-assign-coverage.service';
const CoverageDelete = ({
  userId,
  closeDeleteConfirmationPopup,
  groupDelete,
  setRefreshCoverageData,
  refreshCoverageData,
  itemToDelete,
  isJob,
}) => {
  const toast = useRef(null);
  const deleteRow = async () => {
    try {
      let deleteData;
      if (isJob) {
        if (!groupDelete && itemToDelete?.data?.user_coverage_preference_id) {
          deleteData = await deleteJobCoverage(
            userId,
            itemToDelete.data.user_coverage_preference_id
          );
        } else if (groupDelete) {
          const prefIdsToDelete = itemToDelete.children.map(
            child => child?.data?.user_coverage_preference_id
          );
          deleteData = await bulkDelete(userId, prefIdsToDelete);
        }
      } else {
        if (!groupDelete && itemToDelete?.data?.user_coverage_preference_id) {
          deleteData = await deleteCoveragePreference(
            userId,
            itemToDelete.data.user_coverage_preference_id
          );
        } else if (groupDelete) {
          const prefIdsToDelete = itemToDelete.children.map(
            child => child?.data?.user_coverage_preference_id
          );
          deleteData = await updateGroupDelete(userId, prefIdsToDelete);
        }
      }

      if (deleteData) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted',
          detail: 'The record has been successfully deleted.',
          life: 3000,
        });
        setTimeout(() => {
          closeDeleteConfirmationPopup(false);
          setRefreshCoverageData(!refreshCoverageData);
        }, 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while deleting the row.',
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while deleting the row.',
        life: 3000,
      });
    }
  };

  return (
    <>
      <Dialog
        header="Delete Confirmation"
        visible={true}
        style={{ width: '450px' }}
        onHide={closeDeleteConfirmationPopup}
        footer={
          <div>
            <PFButton
              label="No"
              text
              outlined
              onClick={closeDeleteConfirmationPopup}
            />
            <PFButton label="Yes" onClick={deleteRow} />
          </div>
        }
        draggable={false}
      >
        <div>
          Are you sure you want to delete this coverage{' '}
          {groupDelete ? 'combination' : 'record'}?
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default CoverageDelete;
