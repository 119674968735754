import axios from 'axios';

import { URL_CONSTANTS } from '../../constants/urlConstants';

// Get Billing Details
export const getBillingList = async (
  searchQuery,
  setLoading,
  setBillingDetails,
  setAlert,
  payment_request_id
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    if (payment_request_id) {
      newQueryString = `${newQueryString}&payment_request_id=${payment_request_id}`;
    }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/billings?${newQueryString}`
    );
    if (response?.status === 200) {
      setBillingDetails(response?.data);
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch billing list', false, true);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
