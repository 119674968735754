import React, { useRef } from 'react';

// **** Styles/Images/Icons ****
import 'primeicons/primeicons.css';
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

const Notification = ({
  StoreNotificationItem = [],
  icon,
  notificationsLength,
}) => {
  const menuRight = useRef(null);
  const buttonRef = useRef(null);

  return (
    <>
      <Button
        icon={icon}
        ref={buttonRef}
        onClick={event => menuRight.current.toggle(event)}
        badgeClassName="p-badge-secondary blue-bg-100 text-primary"
        pt={{
          badge: {
            className: 'p-0 absolute ml-4 mb-3',
          },
          root: {
            className: `focus:shadow-none relative p-1`,
          },
          menu: {
            className: `max-h-7rem overflow-auto`,
          },
        }}
      ></Button>

      <Tooltip
        target={buttonRef}
        content={notificationsLength === undefined ? 0 : notificationsLength}
        position="top"
        className="custom-tooltip"
      />
      <i
        className="p-text-secondary p-overlay-badge"
        data-pr-tooltip={
          notificationsLength === undefined ? 0 : notificationsLength
        }
        data-pr-position="right"
        data-pr-at="right top"
        data-pr-my="left center-2"
      >
        {notificationsLength > 0 && (
          <Badge severity="danger" style={{ left: '-16px', top: '-16px' }} />
        )}
      </i>

      <Menu
        model={StoreNotificationItem}
        popup
        ref={menuRight}
        id={`notification-menu`}
        popupAlignment="right"
        className="mt-1 w-22rem"
        pt={{
          menu: {
            className: `max-h-22rem overflow-auto`,
          },
          menuitem: {
            className: `border-bottom-1 border-50`,
          },
        }}
      />
    </>
  );
};

export default Notification;
