import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Message } from 'primereact/message';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';

const TermsConditions = ({
  setFormPayload,
  formPayload,
  isVersionActive = true,
}) => {
  const queryParams = useParams();
  const { mode } = queryParams;
  const [conditions, setConditions] = useState([]);
  const [text, setText] = useState('');
  const onCancel = delteIndex => {
    const newArray = conditions.filter((_, index) => index !== delteIndex);
    setConditions(newArray);
  };

  useEffect(() => {
    setFormPayload(preValues => ({
      ...preValues,
      termAndConditions: conditions,
    }));
  }, [conditions]);

  useEffect(() => {
    setConditions(formPayload?.termAndConditions || []);
  }, []);

  return (
    <div
      className="w-full py-3"
      style={{ overflow: 'auto', minHeight: '500px' }}
    >
      {mode !== 'view' && isVersionActive && (
        <div className="w-full p-inputgroup">
          <PFInputText
            placeholder={'Terms and Conditions'}
            onChange={e => setText(e.target.value)}
            value={text}
          />
          <PFButton
            label={'Add'}
            icon={'pi pi-plus'}
            onClick={() => {
              setConditions(preValues => [...preValues, { description: text }]);
              setText('');
            }}
            outlined={false}
            disabled={text.trim() ? false : true}
          />
        </div>
      )}
      <div>
        {conditions?.length <= 0 && mode === 'view' ? (
          <Message
            severity="info"
            text="Terms and condition not available."
            className="border-primary w-full justify-content-start "
            style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
            }}
            pt={{
              root: {
                className: 'surface-ground',
              },
            }}
          />
        ) : (
          <>
            <div className="p-datatable p-component p-datatable-responsive-scroll">
              <div
                className="p-datatable-wrapper py-0"
                style={{ overflow: 'auto', minHeight: '500px' }}
              >
                <table className="p-datatable-table w-full">
                  <tbody className="p-datatable-tbody">
                    {conditions?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="flex flex-wrap align-items-center">
                            <span className="mr-2">{`${index + 1}. ${item?.description}`}</span>
                            {mode !== 'view' && (
                              <PFButton
                                icon="pi pi-times"
                                outlined
                                text
                                severity="primary"
                                className="text-white border-none"
                                aria-label="Cancel"
                                onClick={() => onCancel(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TermsConditions;
