import React from 'react';

function BinDetails({ bin }) {
  return (
    <>
      <div className="flex justify-content-center">
        <p>
          <h1>{bin.name}</h1>
        </p>
      </div>
      <div className="font-semibold flex justify-content-center">
        <h1>Bin Management and Adjustment functionality coming soon</h1>
      </div>
    </>
  );
}

export default BinDetails;
