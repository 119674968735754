import React, { useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';

export default function PFRadioButton({
  inputId,
  name,
  value,
  onChange,
  checked,
  disabled,
}) {
  return (
    <RadioButton
      inputId={`${inputId || ''}`}
      name={`${name || ''}`}
      value={`${value || ''}`}
      onChange={e => onChange(name, e.value)}
      checked={checked || false}
      className="mt-1"
      disabled={disabled || false}
    />
  );
}
