import { Grid } from '@material-ui/core';

import GenericTable from '../../shared/GenericTable/GenericTable';
const columnData = [
  {
    id: 'document_id',
    value: 'Document Id',
  },
  {
    id: 'document_name',
    value: 'Document Name',
  },
];

const HdEpaDocsTable = ({ rowData }) => {
  rowData = rowData?.map(item => {
    return {
      document_id: {
        value: item?.document_id,
      },
      document_name: {
        value: item?.document_name,
      },
    };
  });

  return (
    <Grid container spacing={4} direction="column" style={{ margin: '8px 0' }}>
      <Grid item xs={6} lg={6} md={6} sm={6}>
        <GenericTable
          title="EPA Docs Required"
          columnData={columnData}
          rowData={rowData}
        />
      </Grid>
    </Grid>
  );
};

export default HdEpaDocsTable;
