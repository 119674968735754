import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';

export default function PFCalendar({
  id,
  value,
  onChange,
  placeholder,
  name,
  disabled,
  className,
  showTime = undefined,
  hourFormat = undefined,
  appendTo = 'self',
  minDate,
  dateFormat,
  maxDate,
  ...props
}) {
  return (
    <Calendar
      id={id || ''}
      value={value || ''}
      name={name || ''}
      onChange={onChange}
      className={`w-full ${className || ''}`}
      placeholder={placeholder || ''}
      appendTo={appendTo === null ? null : appendTo}
      disabled={!!disabled}
      showTime={showTime}
      hourFormat={hourFormat}
      minDate={minDate || ''}
      maxDate={maxDate || ''}
      dateFormat={dateFormat || 'mm-dd-yy'}
      inputClassName="p-inputtext-sm"
      {...props}
    />
  );
}
