import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useStyles } from '../../ProjectManagement/Scheduling/Reports/Reports.styles';
import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import { Alert } from '../../shared/Alerts/Alert';
import Notes from '../../ProjectManagement/Notes/Notes';
import Installer from '../../ProjectManagement/ScheduleInfo/Installer';
import ProjectDetailsViewEditForm from '../ProjectDetailsViewEditForm';

const ProjectEditDialog = ({ projectDetailsFormik }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isProjectEditDialogOpen,
    projectEditDialogSettings,
    projectData,
    rescheduleReasons,
    viewChangeFlag,
  } = useSelector(state => state.newScheduler);
  const [reloadCalender, setReloadCalender] = useState(true);
  const history = useHistory();

  const handleClose = () => {
    dispatch(
      setNewSchedulerComponent({
        isProjectEditDialogOpen: false,
        viewChangeFlag: !viewChangeFlag,
      })
    );
    projectDetailsFormik?.handleReset();
  };

  const handleViewProject = () => {
    dispatch(
      setNewSchedulerComponent({
        isProjectEditDialogOpen: false,
      })
    );
    projectDetailsFormik?.handleReset();
    window.open(`/project/view/${projectData?.project_id}`, '_blank');
  };

  return (
    <GenericDialog
      fullwidth
      isOpen={isProjectEditDialogOpen}
      dialogWidth="lg"
      handleClose={handleClose}
      handleSave={projectDetailsFormik?.handleSubmit}
      dialogSettings={projectEditDialogSettings}
      disabledButton2={
        !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
      }
      disabledButton1={false}
    >
      <div>{alert.exists && <Alert />}</div>
      <div className="flex flex-column gap-2">
        <div className={classes.projectRedirectButton}>
          <Button onClick={handleViewProject}>View Project</Button>
        </div>
        <div>
          <form onSubmit={projectDetailsFormik?.handleSubmit}>
            <ProjectDetailsViewEditForm
              projectDetailsFormik={projectDetailsFormik}
              rescheduleReasonOptions={rescheduleReasons}
            />
          </form>
        </div>

        <div className={classes.tableWrapper}>
          <Installer
            formik={{
              values: {
                project_id: projectData?.project_id,
                date_scheduled: projectDetailsFormik?.values?.date_scheduled,
                proj_end_date: projectDetailsFormik?.values?.project_end_date,
              },
            }}
            setReloadCalender={setReloadCalender}
            reloadCalender={reloadCalender}
            projectId={projectData?.project_id}
          />
        </div>
        <div className={classes.tableWrapper}>
          <Notes
            formik={{ values: { project_id: projectData?.project_id } }}
            action=""
          />
        </div>
      </div>
    </GenericDialog>
  );
};

export default ProjectEditDialog;
