// **** React Imports ****
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// **** External Utilities ****
import {
  Avatar,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  Card,
  CardActionArea,
  CardContent,
  Button,
  Modal,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ProgressSpinner } from 'primereact/progressspinner';
import Backdrop from '@material-ui/core/Backdrop';

// **** Custom Components ****
import { Add as AddIcon } from '@material-ui/icons';
import { Image } from 'primereact';
import { Tooltip } from 'primereact/tooltip';

import { getRelatedPOData } from '../RelatedPO/RelatedPO.service';

// **** Services *****
// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Styles *****

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { DOCUMENT_PHOTO_TYPE } from '../../../constants';
import useToken from '../../../hooks/useToken';

import UploadStoreTypeDialog from './UploadStoreTypeDialog';
import { useStyles } from './Docs.styles.js';
import {
  getListOfDocuments,
  getDocumentTypes,
  upLoadDocumentOrPhoto,
  updateDocumentById,
  getRelatedDocuments,
  applyInstallerVisibilityDocuments,
  deleteProjectDocument,
} from './Docs.service';
import RelatedMeasurementPhotosCard from './RelatedMeasurementPhotosCard';
import PFButton from '../../shared/PFPrime/PFButton.js';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Photos = ({ projectId, formik, setReloadForm }) => {
  const [downloadDocumentLoading, setDownloadDocumentLoading] =
    React.useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [relatedDocuments, setRelatedDocuments] = React.useState([]);
  const [getPhotoDownloadPermission, setPhotoDownloadPermission] =
    React.useState(false);
  const [getPhotoAddPermission, setPhotoAddPermission] = React.useState(false);
  const imageInputRef = React.useRef();
  const [photoError, setPhotoError] = React.useState(null);
  const [, setdocumentTypes] = useState([]);
  const [relatedPOResponse, setRelatedPOResponse] = React.useState({});
  const [loading, setLoading] = useState();
  const [photosLoader, setPhotosLoader] = useState();
  const [reloadList, setReloadList] = useState(false);
  const [selectedJob, setSelectedJob] = React.useState();
  const [relatedDocloading, setRelatedDocloading] = useState(false);
  const [relatedOfflineDocuments, setRelatedOfflineDocuments] = React.useState(
    []
  );
  const [relatedDocsPayload, setRelatedDocsPayload] = React.useState([]);
  const [relatedProjectDocsOfflineMode, setRelatedProjectDocsOfflineMode] =
    React.useState(false);
  const installerVisible = !checkPermission(
    permissions?.userManagement?.userManagementModifyInstaller
  );
  const classes = useStyles();
  const history = useHistory();
  const { setAlert } = useAlerts();
  const { accessToken } = useToken();
  const tabPhotosAddPermission = checkPermission(
    permissions.viewEditProject.tabPhotosAdd
  );
  const tabPhotosDownloadPermission = checkPermission(
    permissions.viewEditProject.tabPhotosDownload
  );
  const [openDialogStoreType, setOpenDialogStoreType] = React.useState(false);
  const [storeType, setStoreType] = React.useState('NEEDS_REVIEW');
  const [showHoverButton, setShowHoverButton] = useState(null);
  const [imagePreviewName, setImagePreviewName] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [hdDocCdNumber, setHdDocCdNumber] = React.useState(null);
  const [llDocType, setLlDocType] = React.useState(null);
  const [toolTipDocument, setToolTipDocument] = useState(null);

  const documentPhotoTypeId = DOCUMENT_PHOTO_TYPE?.PHOTO;

  React.useEffect(() => {
    if (tabPhotosAddPermission) {
      setPhotoAddPermission(true);
    }
    if (tabPhotosDownloadPermission) {
      setPhotoDownloadPermission(true);
    }
  }, []);

  React.useEffect(() => {
    async function getListOfDocumentsData(projectId) {
      await getListOfDocuments(
        projectId,
        setDocuments,
        setLoading,
        documentPhotoTypeId
      );
    }
    getDocumentTypes(setdocumentTypes);
    getRelatedPOData(projectId, setLoading, setRelatedPOResponse);
    getListOfDocumentsData(projectId);
    if (selectedJob?.project_id) {
      handleJobSelection(selectedJob);
    }
  }, [reloadList]);

  const handleUpload = (files, documentTypeId) => {
    setReloadList(false);
    setLoading(false);
    setPhotosLoader(true);
    const uploadObj = {
      file: [],
      document_type_id: documentTypeId,
      document_store_type: storeType || '',
      hd_doc_cd_number: hdDocCdNumber || null,
      ll_document_type: llDocType || null,
    };
    Object.values(files).forEach(file => {
      uploadObj?.file?.push({ file: file, document_name: file?.name });
    });
    try {
      upLoadDocumentOrPhoto(
        projectId,
        uploadObj,
        setLoading,
        setAlert,
        setReloadList,
        setReloadForm,
        setPhotosLoader
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleJobSelection = project => {
    getRelatedDocuments(
      project.project_id,
      setRelatedDocuments,
      setRelatedDocloading,
      documentPhotoTypeId
    );
    setSelectedJob(project);
    setRelatedDocsPayload([]);
  };

  const applyVisibilityDocuments = () => {
    setReloadList(false);
    let queryParam = 'visibilityAndOffline';
    if (relatedProjectDocsOfflineMode) {
      queryParam = 'relatedProjectDocuments';
    }
    if (relatedDocsPayload?.length > 0) {
      applyInstallerVisibilityDocuments(
        projectId,
        queryParam,
        relatedProjectDocsOfflineMode ? relatedDocsPayload : docsPayload,
        setLoading,
        setAlert,
        setReloadList,
        tabPhotosAddPermission
      );
    }
  };

  const handleOfflineAvailability = (document_id, relatedDocsMode) => {
    let relOfflineDocs = _.cloneDeep(relatedOfflineDocuments);
    let relDocumentsPayload = _.cloneDeep(relatedDocsPayload);

    if (
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relOfflineDocs.filter(
        doc => doc?.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relOfflineDocs.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    }
    if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    } else if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId != document_id).length > 0
    ) {
      relDocumentsPayload.splice(
        relDocumentsPayload.findIndex(doc => doc.documentId == document_id),
        1
      );
    } else {
      relDocumentsPayload.push({
        documentId: document_id,
        isRelatedProjectDoucmentOffine: relOfflineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine,
      });
    }
    setRelatedProjectDocsOfflineMode(true);
    setRelatedDocsPayload(relDocumentsPayload);
    setRelatedOfflineDocuments(relOfflineDocs);
  };

  React.useEffect(() => {
    setRelatedOfflineDocuments(
      relatedDocuments
        ?.filter(doc => doc.documetTypeId === 3)
        ?.map(document => {
          return {
            documentId: document.documentId,
            isRelatedProjectDoucmentOffine: document.is_rel_proj_docu_visibility
              ? true
              : false,
          };
        })
    );
  }, [relatedDocuments, reloadList]);

  const getDocStoreTypeName = type => {
    let response = null;
    if (type === 'INTERNAL') {
      response = 'Internal Photo';
    } else if (type === 'SOURCE') {
      response = 'Send to Source';
    } else if (type === 'COMPLETE') {
      response = 'Complete';
    } else if (type === 'NEEDS_REVIEW') {
      response = 'Needs Review';
    }
    return response;
  };

  const handleDocUpload = (projectId, documentId, documentName, type) => {
    updateDocumentById(
      projectId,
      documentId,
      documentName,
      type,
      documents,
      'Photo',
      setDocuments,
      setLoading,
      setAlert,
      setReloadForm
    );
  };

  const handleTooltipContent = value => {
    const truncatedCreatedBy =
      value?.docCreatedBy?.length > 150
        ? `${value?.docCreatedBy?.split(' ')?.slice(0, 15)?.join(' ')} |||`
        : value?.docCreatedBy;

    const content = `${truncatedCreatedBy} (${value?.docCreatedDate})`;

    return content;
  };

  const handleDeletePhoto = async (projectId, id) => {
    try {
      await deleteProjectDocument(projectId, id, 'photo');
    } catch (ex) {
    } finally {
      setReloadList(prev => !prev);
    }
  };
  const [isSendPermission, isCompletePermission, isPhotoDeletePermission] =
    useMemo(
      () => [
        checkPermission(permissions?.documentCenter?.sendToSourcePhoto),
        checkPermission(permissions?.documentCenter?.completePhoto),
        checkPermission(permissions?.documentCenter?.deletePhoto),
      ],
      []
    );
  return (
    <>
      <Backdrop className={classes.backdrop} open={downloadDocumentLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog />
      <UploadStoreTypeDialog
        type={'Photo'}
        openDialogStoreType={openDialogStoreType}
        setOpenDialogStoreType={setOpenDialogStoreType}
        storeType={storeType}
        setStoreType={setStoreType}
        docInputRef={imageInputRef}
        sourceSystemId={formik?.values?.source_system?.source_system_id}
        hdDocCdNumber={hdDocCdNumber}
        setHdDocCdNumber={setHdDocCdNumber}
        llDocumentType={llDocType}
        setLlDocumentType={setLlDocType}
      />
      {!loading ? (
        <Grid container spacing={4} direction="column">
          <Grid item spacing={2} xs={12}>
            <Paper classes={{ root: classes.gridPaperPhoto }}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    classes={{ root: classes.titleHeaderGrid }}
                  >
                    <Grid item>
                      <Typography variant="h3">Photos</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  lg={12}
                  md={12}
                  sm={12}
                  classes={{ root: classes.docsContainer }}
                >
                  {documents &&
                    documents.filter(doc => doc.documetTypeId == 3).length >
                      0 &&
                    documents
                      .filter(doc => doc.documetTypeId == 3)
                      .map(document => (
                        <>
                          <Grid
                            item
                            classes={{ root: classes.storeTypePhotGrid }}
                          >
                            {document?.documentStoreType ? (
                              <div className="flex align-items-center justify-content-center gap-1">
                                <Typography
                                  classes={{ root: classes.storeTypeLabel }}
                                  component="span"
                                  variant="subtitle1"
                                >
                                  Status:
                                </Typography>
                                <Typography
                                  classes={{ root: classes.storeTypeText }}
                                  variant="body1"
                                  component="span"
                                >{`${getDocStoreTypeName(
                                  document?.documentStoreType
                                )}`}</Typography>
                              </div>
                            ) : (
                              <Typography
                                classes={{ root: classes.storeTypeBlankDiv }}
                                component="div"
                                variant="subtitle1"
                              >
                                &nbsp;
                              </Typography>
                            )}

                            <Card classes={{ root: classes.photoCards }}>
                              <Tooltip
                                target=".photoToolTipCard"
                                position="bottom"
                                content={handleTooltipContent(toolTipDocument)}
                              />
                              <CardActionArea
                                onMouseEnter={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowHoverButton(document?.documentId);
                                  setToolTipDocument(document);
                                }}
                                onMouseLeave={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowHoverButton(null);
                                }}
                                className="photoToolTipCard"
                              >
                                {showHoverButton === document?.documentId && (
                                  <>
                                    {isSendPermission && (
                                      <Button
                                        classes={{
                                          root: classes.sendToSourcePhotoButton,
                                        }}
                                        color="primary"
                                        variant="contained"
                                        className="px-2"
                                        onClick={e => {
                                          e.stopPropagation();
                                          handleDocUpload(
                                            document?.projectId,
                                            document?.documentId,
                                            document?.documentName,
                                            'SOURCE'
                                          );
                                        }}
                                      >
                                        Send To Source
                                      </Button>
                                    )}
                                    {isCompletePermission && (
                                      <Button
                                        classes={{
                                          root: classes.completePhotoButton,
                                        }}
                                        color="primary"
                                        variant="contained"
                                        className="px-2 mt-2"
                                        onClick={e => {
                                          e.stopPropagation();
                                          handleDocUpload(
                                            document?.projectId,
                                            document?.documentId,
                                            document?.documentName,
                                            'COMPLETE'
                                          );
                                        }}
                                      >
                                        Complete
                                      </Button>
                                    )}
                                  </>
                                )}

                                <CardContent
                                  classes={{ root: classes.docDetails }}
                                >
                                  <Image
                                    alt={document?.documentName}
                                    src={document?.documentUrl}
                                    imageStyle={{
                                      verticalAlign: 'middle',
                                      margin: '0 8px 0 0',
                                    }}
                                    preview={
                                      document?.documentUrl ? true : false
                                    }
                                    imageClassName={`${classes.photo} ${classes.avatarImg}`}
                                  />
                                </CardContent>
                              </CardActionArea>
                              {isPhotoDeletePermission && (
                                <PFButton
                                  severity=" "
                                  icon="pi pi-trash"
                                  text
                                  rounded
                                  onClick={() => {
                                    confirmDialog({
                                      message: `This action would delete this attachment and cannot be rolled back. Would you like to continue?`,
                                      header: 'Confirmation',
                                      icon: 'pi pi-exclamation-triangle',
                                      accept: () =>
                                        handleDeletePhoto(
                                          document?.projectId,
                                          document?.documentId
                                        ),
                                    });
                                  }}
                                />
                              )}
                            </Card>
                          </Grid>
                        </>
                      ))}
                  {getPhotoAddPermission && (
                    <div>
                      {photosLoader && (
                        <div className={classes.spinnerContainer}>
                          <ProgressSpinner />
                        </div>
                      )}
                      <Grid className={classes.photoUploadLabel}>
                        <input
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                          multiple={storeType !== 'COMPLETE'}
                          onClick={event => {
                            event.target.value = null;
                          }}
                          ref={imageInputRef}
                          onChange={event => {
                            const files = event.target.files;
                            let isValid = true;
                            let errorMessage = '';

                            if (files?.length > 5) {
                              isValid = false;
                              errorMessage =
                                'Please select up to 5 files only.';
                            } else {
                              for (let i = 0; i < files.length; i++) {
                                const file = files[i];
                                if (file?.type?.split('/')?.[0] !== 'image') {
                                  isValid = false;
                                  errorMessage =
                                    'Only image files are allowed.';
                                  break;
                                } else if (
                                  ['svg'].includes(
                                    file?.name?.split('.')?.pop()
                                  )
                                ) {
                                  isValid = false;
                                  errorMessage =
                                    'System does not accept SVG files.';
                                  break;
                                }
                              }
                            }

                            if (isValid) {
                              handleUpload(files, 3);
                              setPhotoError(null);
                            } else {
                              setPhotoError(errorMessage);
                              imageInputRef.current.value = '';
                            }
                          }}
                        />
                        <label onClick={() => setOpenDialogStoreType(true)}>
                          <Avatar variant="square" className={classes.addPhoto}>
                            <AddIcon
                              fontSize="large"
                              className={classes.plusIcon}
                            />
                          </Avatar>
                        </label>
                        {photoError && (
                          <p className="ml-2 text-red-600">{photoError}</p>
                        )}
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {checkPermission(
            permissions?.viewEditProject?.relatedMeasurementPhotos
          ) && (
            <Grid item spacing={2} xs={12}>
              <Paper classes={{ root: classes.gridPaper }}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      classes={{ root: classes.titleHeaderGrid }}
                    >
                      <Grid item container justifyContent="space-between">
                        <Grid item xs={8} lg={8}>
                          <Typography variant="h3">
                            Related Measurement Photos
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={4}
                          lg={4}
                          justifyContent="space-between"
                        >
                          <Autocomplete
                            style={{
                              width: '200px',
                            }}
                            disableClearable
                            id="combo-box-demo"
                            options={relatedPOResponse?.items || []}
                            getOptionLabel={option =>
                              option.project_number + ' ' + option.project_type
                            }
                            openOnFocus={true}
                            value={
                              relatedPOResponse?.items?.filter(
                                rel =>
                                  rel.project_id === selectedJob?.project_id
                              )[0]
                            }
                            onChange={(event, value) =>
                              handleJobSelection(value)
                            }
                            renderInput={params => (
                              <TextField {...params} label="Select Job" />
                            )}
                          />
                          {relatedDocuments?.length > 0 &&
                            installerVisible &&
                            selectedJob?.project_type == 'Measurement' && (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={applyVisibilityDocuments}
                              >
                                Apply
                              </Button>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {relatedDocloading ? (
                    <Grid container justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      direction="row"
                      wrap="nowrap"
                      classes={{ root: classes.docsContainer }}
                    >
                      {relatedDocuments &&
                      relatedDocuments.filter(doc => doc.documetTypeId === 3)
                        .length > 0 ? (
                        relatedDocuments
                          .filter(doc => doc.documetTypeId === 3)
                          .map(document => (
                            <RelatedMeasurementPhotosCard
                              key={document.documentId}
                              docDetails={document}
                              handleOfflineAvailability={
                                handleOfflineAvailability
                              }
                              relatedOfflineDocumentsMode={true}
                              offlineDocuments={relatedOfflineDocuments}
                              installerVisible={installerVisible}
                              selectedJob={selectedJob}
                            />
                          ))
                      ) : (
                        <Grid container justifyContent="center">
                          <span className={classes.emptyText}>
                            No Measurement Photos found!
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default Photos;
