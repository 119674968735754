import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setProjectCategory } from '../redux/slices/projectCategoryMaster.slice';
import { CONFIG } from '../constants';

const useProjectCategory = () => {
  const dispatch = useDispatch();
  const { projectCategory: types } = useSelector(
    state => state?.masters?.projectCategoryReducer
  );
  const [projectCategory, setValue] = useState(types);

  const getProjectCategory = async () => {
    try {
      if (projectCategory.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_BASE_URL}/projects/master/project-category`
        );
        setValue(response?.data);
        dispatch(setProjectCategory(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectCategory();
  }, []);

  return { projectCategory };
};

export default useProjectCategory;
