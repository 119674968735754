// **** React Imports ****
import React from 'react';

// **** Utilities ****
import {
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  LockOpen,
} from '@material-ui/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { checkPermission, formatDate } from '../../../utils/Helpers';
// **** Custom Components ****
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import {
  VIEW_NOTE_TYPES,
  SOURCE_SYSTEMS,
  SOURCE_STATUS,
  HD_CATEGORY,
} from '../../../constants';
import permissions from '../../../config/permissions';

import {
  getNotesData,
  getNotesTypeOptions,
  addNotes,
  updateNote,
  deleteNoteById,
} from './Notes.service';
import { useStyles } from './Notes.styles';

const NOTES_FORM_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add',
};

const NOTE_TYPE = {
  INSTALLER: 1,
  RETAILER: 2,
  STORE: 3,
  INTERNAL: 4,
  ITEM: 5,
  RESCHEDULE: 6,
  BUSTED: 7,
  SCHEDULED: 8,
};

const EDITABLE_NOTE_TYPES = [
  NOTE_TYPE.INSTALLER,
  NOTE_TYPE.INTERNAL,
  NOTE_TYPE.SCHEDULED,
];

const Notes = ({ formik, action, schedulerEditPermission }) => {
  const [notesList, setNotesList] = React.useState({ count: 0, items: [] });
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  let presentationNotesList = [];
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Notes',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [formAction, setFormAction] = React.useState('add');

  const [typeOptions, setTypeOptions] = React.useState();
  const [viewNoteTypes, setViewNoteTypes] = React.useState(VIEW_NOTE_TYPES);
  const { setAlert } = useAlerts();
  const [reloadList, setReloadList] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });

  React.useEffect(() => {
    if (formik?.values?.project_id) {
      getNotesData(
        formik?.values?.project_id,
        searchQuery,
        setLoading,
        setNotesList
      );
    }
    if (reloadList)
      getNotesData(
        formik?.values?.project_id,
        searchQuery,
        setLoading,
        setNotesList
      );
  }, [formik?.values?.project_id, reloadList, searchQuery]);

  React.useEffect(() => {
    if (isOpen) {
      getNotesTypeOptions(setTypeOptions);
    }
  }, [isOpen]);

  // Modified data to display in Generic DataTable
  if (notesList?.items?.length) {
    presentationNotesList = notesList.items.map(val => {
      const notes = {
        project_note_id: {
          value: val.project_note_id,
        },
        note_category_id: {
          value: val.note_category_id,
        },
        type: {
          value: val.category?.category_name ? val.category?.category_name : '',
        },
        note_text: {
          value: val.note_text,
        },
        note_created_by: {
          value:
            val.external_note_created_by &&
            val.external_note_created_by !== null
              ? val.external_note_created_by
              : val.created_by && val.created_by !== null
                ? `${val?.created_by?.first_name} ${val?.created_by?.last_name}`
                : '',
        },
        date_created: {
          value:
            (val?.note_created_at &&
              moment(val?.note_created_at).format('MM-DD-YYYY hh:mm A')) ||
            null,
        },
        follow_up_date: {
          value: formatDate(val?.follow_up_date) || null,
        },
        follow_up_date_value: {
          value: val?.follow_up_date || null,
        },
        status: {
          reviewed_at: val?.reviewed_at,
          reviewed_by: val?.reviewed_by_user
            ? `${val?.reviewed_by_user?.first_name} ${val?.reviewed_by_user?.last_name}`
            : null,
          value:
            val?.reviewed_at && val?.reviewed_by ? (
              <Typography
                variant="subtitle2"
                className={classes.statusColorAcknowledged}
              >
                {'Reviewed'}
              </Typography>
            ) : val?.viewed_by_user ? (
              <Typography
                variant="subtitle2"
                className={classes.statusColorPending}
              >
                {'Viewed'}
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                className={classes.statusColorPending}
              >
                {'-'}
              </Typography>
            ),
        },
      };
      notes.source_status = { value: val?.source_status || '-' };
      return notes;
    });
  }

  let dataArray =
    notesList?.items?.map((val, index) => {
      const notes = {
        reviewed_by: `${
          val?.reviewed_by_user && val?.reviewed_by_user !== null
            ? `${val?.reviewed_by_user?.first_name} ${val?.reviewed_by_user?.last_name}`
            : '-'
        } `,
        viewable_by: `${
          val?.viewed_by_user && val?.viewed_by_user !== null
            ? `${val?.viewed_by_user?.first_name} ${val?.viewed_by_user?.last_name}`
            : '-'
        } `,
        project_note_id: val.project_note_id,
        note_category_id: val.note_category_id,
        category_name: val.category?.category_name
          ? val.category?.category_name
          : '',
        note_text: val.note_text,
        note_created_by:
          val.external_note_created_by && val.external_note_created_by !== null
            ? val.external_note_created_by
            : val.created_by && val.created_by !== null
              ? `${val?.created_by?.first_name} ${val?.created_by?.last_name}`
              : '',
        note_created_at:
          (val?.note_created_at &&
            moment(val?.note_created_at).format('MM-DD-YYYY hh:mm A')) ||
          null,
        follow_up_date: formatDate(val?.follow_up_date) || null,
        follow_up_date_value: val?.follow_up_date || null,
        status: val?.reviewed_by_user ? (
          <Typography
            variant="subtitle2"
            className={classes.statusColorAcknowledged}
          >
            {'Reviewed'}
          </Typography>
        ) : val?.viewed_by_user ? (
          <Typography
            variant="subtitle2"
            className={classes.statusColorPending}
          >
            {'Viewed'}
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            className={classes.statusColorPending}
          >
            {'-'}
          </Typography>
        ),
      };
      notes.source_status = val.source_status || '-';
      return notes;
    }) || [];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const search = searchInputVal?.trim();

    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search: search,
      }));
    }, 500);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    search: true,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: notesList?.count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch:
          (loading && '') ||
          (!loading && !dataArray.length && 'No records found'),
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    onSearchChange: searchText => {
      if (searchText?.length > 2) {
        handleSearch(searchText);
      }
    },
    onSearchClose: () => {
      handleSearch('');
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
  };

  // **** Form Validation Schema ****
  const NotesFormikSchema = Yup.object().shape({
    type: Yup.number().required('Type is required.'),
    note_text: Yup.string().trim().required('Notes is required.'),
  });

  const notesFormik = useFormik({
    initialValues: {
      type: '',
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      if (formAction === 'add') {
        addNotes(
          formik?.values?.project_id,
          values,
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
      if (formAction === NOTES_FORM_MODE.EDIT) {
        try {
          setLoading(true);
          const projectId = formik?.values?.project_id;
          const noteId = values?.project_note_id;
          const payload = {
            note_category_id: values?.type,
            note_text: values?.note_text,
            follow_up_date: values?.follow_up_date,
          };
          const updateResponse = await updateNote(projectId, noteId, payload);
          if (updateResponse?.status === 200) {
            setAlert('success', 'Note Updated Successfully.', false, true);
            setIsOpen(false);
            setLoading(false);
            setReloadList(true);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    enableReinitialize: true,
  });
  const viewNotes = (action, index) => {
    notesFormik.setValues({
      project_note_id: presentationNotesList[index]?.project_note_id?.value,
      type: presentationNotesList[index]?.note_category_id.value,
      note_text: presentationNotesList[index]?.note_text.value,
      follow_up_date: presentationNotesList[index]?.follow_up_date_value.value,
      review_at: presentationNotesList[index]?.status.reviewed_at,
      review_by: presentationNotesList[index]?.status.reviewed_by,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === NOTES_FORM_MODE.VIEW ? false : true,
      title: action === NOTES_FORM_MODE.EDIT ? 'Edit Note' : 'View Note',
    }));
    setFormAction(action);
    setIsOpen(true);
    action === NOTES_FORM_MODE.EDIT && setReloadList(false);
  };

  const deleteNote = async index => {
    setReloadList(false);
    try {
      setLoading(true);
      const deleteResponse = await deleteNoteById(
        formik?.values?.project_id,
        presentationNotesList[index]?.project_note_id?.value
      );
      if (deleteResponse?.status === 200) {
        setAlert('success', 'Note Deleted Successfully.', false, true);
        setReloadList(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeNotes = async index => {
    setReloadList(false);
    try {
      const projectId = formik?.values?.project_id;
      const noteId = presentationNotesList[index]?.project_note_id?.value;
      const payload = {
        source_status: SOURCE_STATUS.CLOSED,
      };

      const updateResponse = await updateNote(projectId, noteId, payload);
      if (updateResponse?.status === 200) {
        setAlert('success', 'Notes Closed Successfully.', false, true);
        setIsOpen(false);
        setLoading(false);
        setReloadList(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkDeletePermission = noteCategoryId => {
    let deletableNoteTypes = [];
    if (checkPermission(permissions?.viewEditProject?.deleteRetailerNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.RETAILER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInstallerNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.INSTALLER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInternalNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.INTERNAL);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteScheduledNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.SCHEDULED);
    }
    if (deletableNoteTypes?.includes(noteCategoryId)) {
      return true;
    }
    return false;
  };

  /*Columns required in Generic DataTable*/
  let dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '150px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span id={`labor-icons-${dataIndex}`} className="ml-3">
              <span>
                {presentationNotesList?.at(dataIndex)?.note_category_id
                  ?.value <= NOTE_TYPE.SCHEDULED && (
                  <IconButton onClick={() => viewNotes('view', dataIndex)}>
                    <VisibilityIcon />
                  </IconButton>
                )}

                {checkDeletePermission(
                  presentationNotesList?.at(dataIndex)?.note_category_id?.value
                ) &&
                  presentationNotesList?.at(dataIndex)?.note_category_id
                    ?.value <= NOTE_TYPE.SCHEDULED && (
                    <IconButton onClick={() => deleteNote(dataIndex)}>
                      <DeleteIcon />
                    </IconButton>
                  )}

                {presentationNotesList?.at(dataIndex)?.source_status?.value ===
                  SOURCE_STATUS.OPEN &&
                  formik.values?.source_system?.source_system_id ===
                    SOURCE_SYSTEMS.HOME_DEPOT &&
                  formik.values?.hd_category === HD_CATEGORY.BLINDS && (
                    <IconButton onClick={() => closeNotes(dataIndex)}>
                      <LockOpen />
                    </IconButton>
                  )}
              </span>
            </span>
          );
        },
      },
    },
    {
      name: 'category_name',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'note_text',
      label: 'Note',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '30%' },
        }),
      },
    },
    {
      name: 'follow_up_date',
      label: 'Follow up Date',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'note_created_by',
      label: 'Created By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
      },
    },
    {
      name: 'note_created_at',
      label: 'Created at',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { width: '12%' },
        }),
      },
    },
    {
      name: 'status',
      label: 'Visibility Status',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'reviewed_by',
      label: 'Reviewed By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'viewable_by',
      label: 'Viewable By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
  ];

  if (
    formik.values?.source_system?.source_system_id ===
      SOURCE_SYSTEMS.HOME_DEPOT &&
    formik.values?.hd_category === HD_CATEGORY.BLINDS
  ) {
    dataTableColumns = [
      ...dataTableColumns,
      {
        name: 'source_status',
        label: 'Source Status',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            style: { width: '10%' },
          }),
        },
      },
    ];
  }

  const restrictedTypeOptions = typeOptions?.filter(
    item => item && item?.note_category_id !== 2
  );

  const newTypeOptions = checkPermission(
    permissions?.mobilePermissions?.retailerNoteVisibility
  )
    ? typeOptions
    : restrictedTypeOptions;

  return (
    <>
      {formik.values.project_id && !loading ? (
        <Grid container direction="column" spacing={4}>
          {checkPermission(permissions?.viewEditProject?.tabNotesAdd) && (
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end">
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disableRipple
                  classes={{ root: classes.buttonRoot }}
                  onClick={() => {
                    notesFormik.setValues({
                      type: '',
                      note_text: '',
                      follow_up_date: null,
                    });
                    setDialogSettings(prevState => ({
                      ...prevState,
                      title: 'Add Notes',
                      showButton2: true,
                    }));
                    setFormAction('add');
                    setIsOpen(true);
                    setReloadList(false);
                    notesFormik?.handleReset();
                  }}
                  disabled={schedulerEditPermission}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <GenericDataTable
              title="Notes"
              columns={dataTableColumns}
              data={dataArray}
              options={dataTableOptions}
            />
            <form>
              <GenericDialog
                fullwidth
                isOpen={isOpen}
                handleClose={() => {
                  setIsOpen(false);
                  notesFormik.handleReset();
                }}
                disabledButton2={!notesFormik.dirty || !notesFormik.isValid}
                disabledButton1={false}
                dialogSettings={dialogSettings}
                handleSave={() => notesFormik.handleSubmit()}
              >
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Autocomplete
                      id="type"
                      name="type"
                      options={
                        formAction === NOTES_FORM_MODE.VIEW
                          ? viewNoteTypes || []
                          : typeOptions || []
                      }
                      getOptionLabel={option => option && option.category_name}
                      renderInput={params => (
                        <TextField {...params} label="Type" required />
                      )}
                      value={
                        formAction === NOTES_FORM_MODE.VIEW
                          ? viewNoteTypes?.find(
                              type =>
                                type.note_category_id ===
                                notesFormik?.values?.type
                            )
                          : typeOptions?.find(
                              type =>
                                type.note_category_id ===
                                notesFormik?.values?.type
                            ) || null
                      }
                      onChange={(event, value) => {
                        if (value != null) {
                          notesFormik.setFieldValue(
                            'type',
                            value?.note_category_id
                          );
                        } else {
                          notesFormik.setFieldValue('type', null);
                        }
                      }}
                      onBlur={notesFormik.handleBlur}
                      disabled={
                        formAction === NOTES_FORM_MODE.VIEW ||
                        formAction === NOTES_FORM_MODE.EDIT
                      }
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      required
                      id="note_text"
                      name="note_text"
                      label="Note"
                      multiline
                      onChange={notesFormik.handleChange}
                      onBlur={notesFormik.handleBlur}
                      InputProps={{
                        readOnly: formAction === 'view' ? true : false,
                      }}
                      value={notesFormik?.values?.note_text}
                      error={
                        notesFormik?.touched?.note_text &&
                        notesFormik.errors.note_text
                      }
                      helperText={
                        notesFormik.touched.note_text &&
                        notesFormik.errors.note_text
                      }
                    />
                  </Grid>
                  <Grid item className="mui-calendar">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <MuiKeyboardDatePicker
                        id="follow_up_date"
                        autoOk
                        label="Follow up Date"
                        format="MM-dd-yyyy"
                        value={
                          (notesFormik?.values?.follow_up_date?.split('T')
                            ?.length &&
                            parseISO(
                              notesFormik?.values?.follow_up_date?.slice(0, 10)
                            )) ||
                          null
                        }
                        onChange={(date, value) =>
                          notesFormik?.setFieldValue(
                            'follow_up_date',
                            `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                          )
                        }
                        disablePast={formAction !== 'view'}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                          style: { display: formAction === 'view' && 'none' },
                        }}
                        disabled={
                          formAction === NOTES_FORM_MODE.VIEW ||
                          formAction === NOTES_FORM_MODE.EDIT
                        }
                        onBlur={notesFormik.handleBlur}
                        variant="inline"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {notesFormik?.values?.review_by &&
                    notesFormik?.values?.review_at && (
                      <Typography variant="subtitle2">
                        <span className={classes.statusColorAcknowledged}>
                          Reviewed By : {notesFormik.values.review_by}
                        </span>
                        <br />
                        On{' '}
                        {moment(notesFormik.values.review_at).format(
                          'MM-DD-YYYY hh:mm A'
                        )}
                      </Typography>
                    )}
                </Grid>
              </GenericDialog>
            </form>
          </Grid>
        </Grid>
      ) : (
        loading && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="w-12"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default Notes;
