import { InputText } from 'primereact/inputtext';
export default function PFInputText({
  id,
  type,
  value,
  onChange,
  placeholder,
  name,
  className,
  disabled,
  minLength,
  maxLength,
  autoComplete,
  ...props
}) {
  return (
    <InputText
      id={id || ''}
      type={type || 'search'}
      value={value || ''}
      name={name || ''}
      onChange={onChange}
      placeholder={placeholder || ''}
      className={`${className || ''} p-inputtext-sm w-full`}
      disabled={disabled || false}
      onKeyDown={onChange}
      minLength={minLength || null}
      maxLength={maxLength || null}
      autoComplete={autoComplete || 'off'}
      {...props}
    />
  );
}
