import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';

const PFManuButton = () => {
  const menuLeft = useRef(null);
  const menuRight = useRef(null);
  const toast = useRef(null);
  const [items, setItems] = useState([]);
  const template = props => (
    <div className="card flex justify-content-center">
      <Toast ref={toast}></Toast>
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <Button
        type="button"
        {...props}
        size="small"
        onClick={event => menuLeft.current.toggle(event)}
      />
    </div>
  );

  return { PFManuTemplate: template, items: setItems };
};

export default PFManuButton;
