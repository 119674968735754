import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, Dialog } from 'primereact';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import PFButton from '../../shared/PFPrime/PFButton';
import PageHeader from '../../shared/PageHeader/PageHeader';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFFormikError from '../../shared/PFPrime/PFFormikError';
import ApiService from '../../../services/api.service';

import QuestionComponent from './QuestionComponent';
import QuestionDialog from './QuestionDialog';
import { getModules, getQuestions, addModule } from './questionnaireSerivce';

const breadcrumb = [
  {
    text: 'Admin',
  },
  {
    text: 'Checklist',
  },
];
const TYPE_OPTIONS = [
  {
    name: 'Project',
    value: 'PROJECT',
    remainingPayload: {
      reference_table: 't_projects',
      reference_table_column: 'project_id',
      config: { max_question_count: 20 },
    },
  },
  {
    name: 'User',
    value: 'USER',
    remainingPayload: {
      reference_table: 't_user_meta_details',
      reference_table_column: 'user_id',
      config: { max_question_count: 20 },
    },
  },
];
const QuestionnaireComponent = () => {
  const [isShowModulePopup, setIsShowModulePopup] = useState(false);
  const [isCreateModuleLoading, setIsCreateModuleLoading] = useState(false);
  const { isLoading, moduleList, moduleCount, questionList, selectedModule } =
    useSelector(state => state.questionnaire);
  const moduleFormik = useFormik({
    initialValues: {
      moduleName: '',
      moduleType: 'PROJECT',
    },
    validationSchema: Yup.object().shape({
      moduleName: Yup.string().trim().required('required'),
      moduleType: Yup.string().trim().required('required'),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setIsCreateModuleLoading(true);
      const foundModuleDetails = TYPE_OPTIONS.find(
        record => record.value === values.moduleType
      );
      try {
        const payload = {
          module_name: values.moduleName,
          module_tag: values.moduleType,
          ...foundModuleDetails.remainingPayload,
        };
        const { error, data } = await addModule(payload);
        if (error) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
        } else {
          setIsShowModulePopup(false);
          callGetModules();
          resetForm();
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Module created successfully',
            life: 3000,
          });
        }
        setIsCreateModuleLoading(false);
      } catch (error) {
        setIsCreateModuleLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.response?.data?.message || 'something went wrong',
          life: 3000,
        });
      }
    },
  });
  const dispatch = useDispatch();
  const editPermission = checkPermission(permissions.questionnaire.edit);

  const toast = useRef(null);

  const callGetQuestions = async moduleId => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    try {
      const response = await getQuestions(moduleId);
      if (response?.status && response?.data) {
        dispatch(
          updateQuestionnaireStore({
            questionList: Array.isArray(response.data)
              ? response.data
              : questionList,
            questionCount: Array.isArray(response.data)
              ? response.data.length
              : questionCount,
          })
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isLoading: false }));
    }
  };
  const callGetModules = async () => {
    dispatch(updateQuestionnaireStore({ isLoading: true }));
    try {
      const response = await getModules();
      if (response?.status && response?.data) {
        dispatch(
          updateQuestionnaireStore({
            moduleList: Array.isArray(response.data?.result)
              ? response.data.result
              : moduleList,
            moduleCount: response.data?.count
              ? response.data.count
              : moduleCount,
          })
        );
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: ex?.response?.data?.message || 'something went wrong',
        life: 3000,
      });
    } finally {
      dispatch(updateQuestionnaireStore({ isLoading: false }));
    }
  };
  useEffect(() => callGetModules(), []);

  useEffect(() => {
    return () => {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: null,
          questionDialogContent: null,
          questionCount: 0,
          questionList: [],
        })
      );
    };
  }, []);

  const handleAddNewQuestion = () => {
    dispatch(updateQuestionnaireStore({ isQuestionDialogOpen: true }));
  };
  const handleModuleChange = (name, value) => {
    if (value) {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: value,
          questionDialogContent: { module: value },
        })
      );
      callGetQuestions(value?.module_id);
    } else {
      dispatch(
        updateQuestionnaireStore({
          selectedModule: null,
          questionDialogContent: null,
          questionCount: 0,
          questionList: [],
        })
      );
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="grid pl-3 w-12">
        <div className="col-12 md:col-6">
          <PageHeader
            pageTitle="Interactive Questions"
            breadCrumbArray={breadcrumb}
          />
        </div>
        <div className="col-12 md:col-6 flex gap-2 align-items-center justify-content-end">
          <div className="w-8">
            <span className="p-float-label">
              <PFDropdown
                value={selectedModule}
                onChange={handleModuleChange}
                options={moduleList}
                disabled={isLoading}
                className="w-full"
                optionLabel="module_name"
                placeholder="Select a Module"
                inputId="module"
                name="module"
              />
              <label htmlFor="module">Module</label>
            </span>
          </div>
          {editPermission && (
            <div className="w-3">
              <PFButton
                onClick={handleAddNewQuestion}
                size="small"
                label="Add Question"
                severity="primary"
                icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                iconPos="left"
                disabled={isLoading || !selectedModule}
              />
            </div>
          )}
          <div className="w-3">
            <PFButton
              onClick={() => {
                setIsShowModulePopup(true);
                moduleFormik.resetForm();
              }}
              size="small"
              label="Create Module"
              severity="primary"
              icon={'pi pi-plus'}
              iconPos="left"
            />
          </div>
        </div>
      </div>
      <QuestionComponent toast={toast} editPermission={editPermission} />
      <QuestionDialog toast={toast} editPermission={editPermission} />
      <Dialog
        header="Create Module"
        visible={isShowModulePopup}
        className="w-11 md:w-8 h-15rem"
        onHide={() => setIsShowModulePopup(false)}
      >
        <p className="m-0">
          <div className="grid pl-3 w-12">
            <div className="col-12 md:col-5">
              <PFInputText
                i="pi pi-search"
                type="text"
                value={moduleFormik.values.moduleName}
                onChange={e =>
                  moduleFormik?.setFieldValue('moduleName', e.target.value)
                }
                placeholder="Enter module Name"
              />
              <PFFormikError
                touched={moduleFormik.touched}
                errors={moduleFormik.errors}
                field="moduleName"
              />
            </div>

            <div className="col-12 md:col-4">
              <PFDropdown
                optionLabel="name"
                placeholder="Select Type"
                options={TYPE_OPTIONS}
                value={moduleFormik.values.moduleType}
                onChange={(name, value) => {
                  moduleFormik.setFieldValue('moduleType', value);
                }}
                appendTo="self"
              />
              <PFFormikError
                touched={moduleFormik.touched}
                errors={moduleFormik.errors}
                field="moduleType"
              />
            </div>

            <div className="col-12 md:col-3">
              <PFButton
                size="small"
                label="Create Module"
                severity="primary"
                icon={
                  isCreateModuleLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'
                }
                iconPos="left"
                onClick={() => moduleFormik.handleSubmit()}
                disabled={isCreateModuleLoading}
              />
            </div>
          </div>
        </p>
      </Dialog>
    </>
  );
};

export default QuestionnaireComponent;
