import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';

import PFDropdown from '../../../../shared/PFPrime/PFDropdown';
import PFMultiSelect from '../../../../shared/PFPrime/PFMultiSelect';
import PFButton from '../../../../shared/PFPrime/PFButton';
import {
  EMPLOYMENT_TYPES,
  USER_STATUS_OPTIONS,
} from '../../../../../constants';
import { COVERAGE_TYPE } from '../../../user-form/Helper';
import {
  filteredList,
  getFiltersDropdownValues,
} from '../../UserManagement.service';
import { searchDistrict } from '../../../user-form/services/user-form-coverage.service';
import { getSeverity } from '../helpers/helper';
import { getUserList } from '../user-listing.model';

const coverageTypeList = [
  { id: 2, label: 'Store' },
  { id: 3, label: 'District' },
];

const UserManagementFilters = ({ filterList, setSelectFilters }) => {
  const [type, setType] = useState();
  const [category, setCategory] = useState([]);
  const [store, setStore] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [userOptionList, setUserOptionList] = useState([]);
  const [statuses] = useState([
    { label: 'Yes', code: 'Y' },
    { label: 'No', code: 'N' },
  ]);

  const getFilterOptions = async () => {
    const { storesList, typesList, categoryList } =
      await getFiltersDropdownValues();
    const district = await searchDistrict();
    setType(typesList);
    setCategory(categoryList);
    setStore(storesList);
    setDistrictList(district);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  const statusItemTemplate = option => {
    return (
      <Button
        label={option.label}
        severity={getSeverity(option.code)}
        className="p-1"
        size="small"
        outlined
      />
    );
  };

  const formik = useFormik({
    initialValues: {
      search: '',
      filters: [
        { type: 'USER_ID', value: [] },
        { type: 'USER_ROLE', value: [] },
        { type: 'EMPLOYMENT_TYPE', value: [] },
        { type: 'STATUS', value: [] },
        { type: 'IS_JOB_ASSIGNMENT_ENABLED', value: [] },
        { type: 'IS_ACCESS_COVERAGE', value: [] },
        { type: 'IS_JOB_ACCESS_COVERAGE', value: [] },
        { type: 'PROJECT_TYPE', value: [] },
        { type: 'PROJECT_CATEGORY', value: [] },
        { type: 'COVERAGE_TYPE', value: [] },
        { type: 'COVERAGE_VALUES', value: [] },
      ],
    },
    onSubmit: async values => {
      const filterValue = values.filters.reduce((acc, { type, value }) => {
        if (
          value &&
          (Array.isArray(value) ? value.length > 0 : value !== null)
        ) {
          const newValue = [
            'IS_JOB_ASSIGNMENT_ENABLED',
            'IS_ACCESS_COVERAGE',
            'IS_JOB_ACCESS_COVERAGE',
          ].includes(type)
            ? value === 'Y'
              ? 1
              : 0
            : value;

          acc.push({
            type,
            value: Array.isArray(newValue) ? newValue : [newValue],
          });
        }
        return acc;
      }, []);

      setSelectFilters(filterValue || []);
    },
  });

  const handleReset = async () => {
    formik.resetForm();
    setSelectFilters([]);
    getUserList();
  };

  return (
    <Accordion activeIndex={-1} className="mb-4">
      <AccordionTab
        header="Filters"
        pt={{
          headeraction: { className: 'surface-100' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid my-2">
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFMultiSelect
                  key="USER_ID"
                  className="mr-3 w-full"
                  name="USER_ID"
                  value={formik?.values?.filters[0].value || []}
                  options={filterList?.users?.map(record => ({
                    ...record,
                    created_user_id: record.user_id,
                  }))}
                  filter={true}
                  maxSelectedLabels={1}
                  optionLabel="user_name"
                  optionValue="user_id"
                  style={{ height: '40px' }}
                  showClear={true}
                  onChange={e =>
                    formik.setFieldValue('filters[0].value', e.value)
                  }
                />
                <label htmlFor="client_timezone">Name</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="USER_ROLE"
                  className="mr-3 w-full"
                  name="USER_ROLE"
                  value={formik?.values?.filters[1].value || []}
                  options={filterList.roles?.map(record => ({
                    ...record,
                    user_role: record.role_id,
                  }))}
                  filter={true}
                  optionLabel="role_name"
                  optionValue="role_id"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[1].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear
                />
                <label htmlFor="client_timezone">Role</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="EMPLOYMENT_TYPE"
                  className="mr-3 w-full"
                  name="EMPLOYMENT_TYPE"
                  value={formik?.values?.filters[2].value || []}
                  options={EMPLOYMENT_TYPES}
                  filter={true}
                  optionLabel="title"
                  optionValue="value"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[2].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Employment Type</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="STATUS"
                  className="mr-3 w-full"
                  name="STATUS"
                  value={formik?.values?.filters[3].value || null}
                  options={USER_STATUS_OPTIONS}
                  filter={true}
                  optionLabel="label"
                  optionValue="statusIds"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[3].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Status</label>
              </span>
            </div>
          </div>
          <div className="grid my-2">
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="PROJECT_TYPE"
                  className="mr-3 w-full"
                  name="PROJECT_TYPE"
                  value={formik?.values?.filters[7].value || []}
                  options={type}
                  filter={true}
                  optionLabel="project_type"
                  optionValue="project_type_id"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[7].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Type</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFMultiSelect
                  value={formik?.values?.filters[8].value || []}
                  optionLabel="category"
                  optionValue="project_category_id"
                  maxSelectedLabels={1}
                  name="PROJECT_CATEGORY"
                  filter={true}
                  options={category}
                  filter={true}
                  onChange={e =>
                    formik.setFieldValue('filters[8].value', e.value)
                  }
                  placeholder="Select a Category"
                  style={{ height: '40px' }}
                  className="w-full"
                  showClear={true}
                  pt={{
                    input: {
                      className: 'p-inputtext p-inputtext-sm',
                    },
                  }}
                />
                <label htmlFor="client_timezone">Category</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="coverageTypeList"
                  className="mr-3 w-full"
                  name="COVERAGE_TYPE"
                  value={formik.values.filters[9].value || null}
                  options={coverageTypeList}
                  optionLabel="label"
                  optionValue="id"
                  style={{ height: '40px' }}
                  onChange={(name, e) => {
                    formik.setFieldValue('filters[9].value', e);
                    formik.setFieldValue('filters[10].value', []);
                  }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Coverage Type</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFMultiSelect
                  value={formik.values.filters[10]?.value || []}
                  name="COVERAGE_VALUE"
                  maxSelectedLabels={1}
                  filter={true}
                  options={
                    formik?.values?.filters[9]?.value === COVERAGE_TYPE.STORE
                      ? store.map(storeValue => ({
                          label: `${storeValue.store_number} - ${storeValue.store_name}`,
                          value: storeValue.store_id,
                          store_number: storeValue.store_number,
                        }))
                      : formik?.values?.filters[9]?.value ===
                          COVERAGE_TYPE.DISTRICT
                        ? districtList?.map(dist => dist?.district)
                        : []
                  }
                  onChange={e =>
                    formik.setFieldValue('filters[10].value', e.value)
                  }
                  placeholder="Select a Coverage"
                  style={{ height: '40px' }}
                  className="w-full"
                  showClear={true}
                  pt={{
                    input: {
                      className: 'p-inputtext p-inputtext-sm',
                    },
                  }}
                />

                <label htmlFor="client_timezone">Coverage Value</label>
              </span>
            </div>
          </div>
          <div className="grid my-2">
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="IS_JOB_ASSIGNMENT_ENABLED"
                  className="mr-3 w-full"
                  name="IS_JOB_ASSIGNMENT_ENABLED"
                  value={formik?.values?.filters[4].value ?? []}
                  options={statuses}
                  itemTemplate={statusItemTemplate}
                  optionLabel="label"
                  optionValue="code"
                  onChange={(name, e) => {
                    formik.setFieldValue('filters[4].value', e);
                  }}
                  style={{ height: '40px' }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Job Assignment Enabled</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="IS_ACCESS_COVERAGE"
                  className="mr-3 w-full"
                  name="IS_ACCESS_COVERAGE"
                  value={formik?.values?.filters[5].value || null}
                  options={statuses}
                  itemTemplate={statusItemTemplate}
                  optionLabel="label"
                  optionValue="code"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[5].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear={true}
                />
                <label htmlFor="client_timezone">Access Coverage</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <span className="p-float-label">
                <PFDropdown
                  key="IS_JOB_ACCESS_COVERAGE"
                  className="mr-3 w-full"
                  name="IS_JOB_ACCESS_COVERAGE"
                  value={formik?.values?.filters[6].value || null}
                  options={statuses}
                  itemTemplate={statusItemTemplate}
                  optionLabel="label"
                  optionValue="code"
                  onChange={(name, e) =>
                    formik.setFieldValue('filters[6].value', e)
                  }
                  style={{ height: '40px' }}
                  showClear
                />
                <label htmlFor="client_timezone">Job Coverage</label>
              </span>
            </div>
            <div className="col-12 md:col-4 lg:col-3 py-0">
              <div className="grid">
                <div className="col-12 md:col-6 lg:col-6">
                  <PFButton
                    label="Reset"
                    outlined
                    size="small"
                    className="w-full"
                    onClick={handleReset}
                  />
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                  <PFButton
                    label="Apply"
                    size="small"
                    type="submit"
                    className="w-full"
                    disabled={!formik.dirty}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </AccordionTab>
    </Accordion>
  );
};

export default UserManagementFilters;
