import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setStateMaster } from '../redux/slices/stateMaster.slice';
import { CONFIG } from '../constants';

const useStateMaster = () => {
  const dispatch = useDispatch();
  const { states: currentStates } = useSelector(
    state => state?.masters?.stateMasterReducer
  );
  const [states, setValue] = useState(currentStates);

  const getStates = async () => {
    try {
      if (states.length === 0) {
        const response = await axios.get(
          `${CONFIG?.API_BASE_URL}/system/state`
        );
        setValue(response?.data);
        dispatch(setStateMaster(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return { states };
};

export default useStateMaster;
