import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';

export default function PFCalendar({
  id,
  value,
  onChange,
  placeholder,
  name,
  disabled,
  className,
}) {
  return (
    <Calendar
      id={id || ''}
      value={value || ''}
      name={name || ''}
      onChange={onChange}
      // dateTemplate={dateTemplate}
      className={`w-full p-inputtext-sm ${className || ''}`}
      placeholder={placeholder || ''}
      appendTo="self"
      disabled={!!disabled}
    />
  );
}
