import Login from '../Login/Login';
import ForgetPassword from '../Login/ForgetPassword';
import ResetPassword from '../Login/ResetPassword';
import SetPassword from '../Login/SetPassword';
import OnboardSuccess from '../../../PublicPages/OnboardSuccess';
import OnboardRefresh from '../../../PublicPages/OnboardRefresh';

export const AuthRoutes = [
  {
    path: '/',
    title: 'Login Component',
    component: Login,
  },
  {
    path: '/forget-password',
    title: 'Forget Password',
    component: ForgetPassword,
  },
  {
    path: '/reset-password',
    title: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/invitation',
    title: 'Set Password',
    component: SetPassword,
  },
  {
    path: '/onboarding-success',
    title: 'Onboard Success',
    component: OnboardSuccess,
  },
  {
    path: '/onboarding-refresh',
    title: 'Onboard Refresh',
    component: OnboardRefresh,
  },
];
