import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  activeTabIndex: 0,
  isFormDataChanged: null,
  userId: null,
  mode: null,
  availableForRouting: false,
  isInitDataLoaded: false,
};

const userFormSlice = createSlice({
  name: 'userForm',
  initialState: initialState,
  reducers: {
    clearUserFormUpdateComponent(state, { payload }) {
      state.isLoading = false;
      state.isFormDataChanged = null;
      state.userId = null;
      state.mode = null;
      state.availableForRouting = false;
    },
    userFormUpdateComponent(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.activeTabIndex = payload.hasOwnProperty('activeTabIndex')
        ? payload.activeTabIndex
        : state.activeTabIndex;
      state.isFormDataChanged = payload.hasOwnProperty('isFormDataChanged')
        ? payload.isFormDataChanged
        : state.isFormDataChanged;
      state.userId = payload.hasOwnProperty('userId')
        ? payload.userId
        : state.userId;
      state.mode = payload.hasOwnProperty('mode') ? payload.mode : state.mode;
      state.availableForRouting = payload.hasOwnProperty('availableForRouting')
        ? payload.availableForRouting
        : state.availableForRouting;
      state.isInitDataLoaded = payload.hasOwnProperty('isInitDataLoaded')
        ? payload.isInitDataLoaded
        : state.isInitDataLoaded;
    },
  },
});

export const { userFormUpdateComponent, clearUserFormUpdateComponent } =
  userFormSlice.actions;

export const userFormReducer = userFormSlice.reducer;
