import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  deletFileIcon: {
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  secondaryHeading: {
    marginLeft: 'auto',
  },
  detailEmailBoxHeader: {
    borderBottom: '1px solid #B3B3B3',
  },
  error: {
    color: 'red',
  },
  fileInput: {
    display: 'none',
  },
  listReviewed: {
    color: 'rgb(44, 170, 66)',
  },
  reviewedBySection: {
    marginBottom: '20px',
  },
  downloadAttachement: {
    cursor: 'pointer',
    color: '#1D25D5',
    marginRight: '15px',
    '& svg': {
      margin: '0px 2px -4px 2px',
    },
  },
  detailEmailBoxHeaderFirst: {
    '& div:first-child': {
      height: 42,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      float: 'left',
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    '& div:last-child': {
      float: 'left',
    },
    '& span': {
      color: '#A0A0A0',
      fontSize: '0.75rem',
    },
  },
  detailEmailBoxHeaderLast: {
    height: 42,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
  },
  createEmailBoxHeader: {
    borderBottom: '1px solid #B3B3B3',
  },
  createEmailBoxHeaderLast: {
    '& div': {
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    textAlign: 'right',
  },
  createEmailBoxFooter: {
    borderTop: '1px solid #B3B3B3',
    '& button': {
      float: 'left',
    },
    '& > div': {
      float: 'left',
      boxShadow: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 5,
    },
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },

  createEmailBox: {
    '& .react-multi-email': {
      border: 0,
      background: 'transparent',
      borderBottom: '1px Solid #B3B3B3',
      borderRadius: 0,
      paddingLeft: 0,
      '& > input': {
        background: 'transparent',
      },
    },
  },
}));
