// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';

const GenericConfirmationDialog = ({
  confirmDialog,
  setConfirmDialog,
  onConfirmDialog,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={confirmDialog.isOpen}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant="h3">{confirmDialog.header}</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="body2">{confirmDialog.subtitle}</Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            {confirmDialog.button1Text || 'Cancel'}
          </Button>
          {!confirmDialog.hideButton2 && (
            <Button
              color="primary"
              variant="contained"
              onClick={onConfirmDialog}
            >
              {confirmDialog.button2Text || 'Delete'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenericConfirmationDialog;
