import { Message } from 'primereact/message';

const OnboardSuccess = () => {
  return (
    <div className="col-12 text-center">
      <div className=" font-bold text-5xl mb-3">
        <Message
          severity="success"
          text="Thank you for completing onboarding"
        />
      </div>
    </div>
  );
};

export default OnboardSuccess;
