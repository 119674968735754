import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';

import { addUpdateProduct } from './ProductDetails.model';

const ProductUnit = () => {
  const dispatch = useDispatch();
  let { itemInfo: productDetails } = useSelector(
    state => state.inventoryManagment?.ProductInfo
  );
  const [fields, setFields] = useState(
    [{ id: 1, parent: null, value: null, name: null }] || []
  );
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const firstParentUnit = fields[0]?.parent;

  const defaultObjectUnit = {
    id: fields?.length + 1,
    parent: null,
    value: 1,
    name: firstParentUnit,
  };

  const handleUnit = (id, event, index) => {
    const updatedFields =
      fields &&
      fields?.map(field => (field?.id === id ? { ...field, ...event } : field));
    setFields(updatedFields);

    const nextIndexValue = index + 1;
    if (nextIndexValue < fields.length) {
      const newRows = [...fields];
      newRows[nextIndexValue].parent = event?.name;
    }
  };

  const addInputField = () => {
    const parentInputValue = fields?.reduce(
      (lastValue, obj) => obj?.name,
      null
    );
    const newField = {
      id: fields?.length + 1,
      parent: parentInputValue,
    };
    setFields([...fields, newField]);
  };

  const deleteInputField = (index, event) => {
    event.preventDefault();
    const updatedFields = [...fields];
    updatedFields?.splice(index, 1);
    setFields(updatedFields);
  };

  const saveUnit = e => {
    const emptyValueCount = fields?.filter(
      item => !item?.name || !item?.parent || !item?.value
    );

    if (emptyValueCount?.length > 0) {
      toast.current.clear();
      toast.current.show({
        severity: 'error',
        summary: 'Required fields.',
        detail: 'All fields are required!',
        life: '3000',
      });
    } else {
      fields?.push(defaultObjectUnit);
      const reqBody = { unit: fields, _id: productDetails?._id };

      addUpdateProduct(reqBody, dispatch, toast);
      setFields([{ id: 1, parent: null, value: null, name: null }]);
    }
  };

  const acceptSubmission = () => {
    saveUnit();
    setVisible(false);
  };

  return (
    <>
      <Dialog
        header="Confirmation"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        appendTo="self"
        style={{ zIndex: '9999999' }}
      >
        <p>
          <i className="pi pi-exclamation-triangle mr-1 text-lg"> </i>This is a
          one time entry, please confirm as this cannot be edited after
          submission?
        </p>
        <div className="text-right">
          <Button
            size="small"
            type="button"
            text
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button size="small" type="button" onClick={acceptSubmission}>
            Submit
          </Button>
        </div>
      </Dialog>

      <Toast
        baseZIndex="10000"
        className="taks-toast"
        ref={toast}
        position="bottom-center"
      />
      <div style={{ minHeight: '70vh' }}>
        <div className="col-12 md:col-6 lg:col-11 p-input-icon-right mb-3 m-0 p-0 ">
          <h2>Product Unit</h2>
        </div>
        {productDetails?.unit?.length > 0 ? (
          productDetails?.unit?.slice(0, -1)?.map((field, index) => (
            <div
              className="grid flex align-items-center m-0 px-2 my-1"
              key={index}
            >
              <p className="my-2">
                {index + 1}. One
                <strong className="mx-3">{field?.parent}</strong>
                <span className="mr-3">Has</span>
                <strong className="mr-3">{field?.value}</strong>
                <strong className="mr-3">{field?.name}.</strong>
              </p>
            </div>
          ))
        ) : (
          <form>
            <div className="flex align-items-center">
              <div className="col px-0">
                {fields &&
                  fields?.map((field, index) => (
                    <div
                      className="grid block md:flex align-items-center m-0 px-0 my-1"
                      key={index}
                    >
                      <div className="col-auto mt-1 md:mt-3">One</div>
                      <div className="col px-0 md:px-3">
                        <label
                          className="text-sm"
                          htmlFor={index}
                          style={{ left: '20px' }}
                        >
                          Unit<span className="text-red-500">*</span>
                        </label>
                        <InputText
                          className="w-12"
                          id={index}
                          name="parent"
                          type="text"
                          value={field?.parent}
                          onChange={e => {
                            handleUnit(field?.id, { parent: e?.target?.value });
                          }}
                          disabled={
                            productDetails?.unit?.length > 0
                              ? true
                              : index > 0
                                ? true
                                : false
                          }
                          autoComplete="off"
                          placeholder="Roll"
                        />
                      </div>
                      <div className="col-auto mt-1 md:mt-3">Has</div>
                      <div className="col w-12 px-0 md:px-3">
                        <label
                          className="text-sm"
                          htmlFor={index}
                          style={{ left: '20px' }}
                        >
                          Child Quantity<span className="text-red-500">*</span>
                        </label>
                        <InputNumber
                          className="quantity w-12"
                          id={index}
                          name="childQuantity"
                          type="text"
                          value={field?.value}
                          onValueChange={e => {
                            handleUnit(field?.id, { value: e?.target?.value });
                          }}
                          disabled={
                            productDetails?.unit?.length > 0 ? true : false
                          }
                          min="0"
                          autoComplete="off"
                          placeholder="30"
                        />
                      </div>
                      <div className="col p-1  w-12 px-0 md:px-3">
                        <label
                          className="text-sm"
                          htmlFor="username"
                          style={{ left: '20px' }}
                        >
                          Child Unit<span className="text-red-500">*</span>
                        </label>
                        <InputText
                          className="w-12"
                          id={index}
                          name="childUnit"
                          type="text"
                          value={field?.name}
                          onChange={e => {
                            handleUnit(
                              field?.id,
                              { name: e?.target?.value },
                              index
                            );
                          }}
                          disabled={
                            productDetails?.unit?.length > 0 ? true : false
                          }
                          autoComplete="off"
                          placeholder="Feet"
                        />
                      </div>
                      {productDetails?.unit?.length > 0 ? null : (
                        <div className="col-12 md:col-1 text-right md:text-center pr-0 md:pl-3">
                          {index > 0 ? (
                            <i
                              onClick={e => deleteInputField(index, e)}
                              disabled={index > 0 ? false : true}
                              className="pi pi-times-circle md:mx-1 cursor-pointer text-red-500 mt-1 md:mt-4"
                            ></i>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {productDetails?.unit?.length > 0 ? null : (
                <div className="col-auto">
                  <i
                    onClick={addInputField}
                    className="pi pi-plus-circle mx-1 cursor-pointer text-primary mt-4"
                  ></i>
                </div>
              )}
            </div>
            <div className="text-right">
              {productDetails?.unit?.length > 0 ? null : (
                <Button
                  type="button"
                  size="small"
                  onClick={() => setVisible(true)}
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ProductUnit;
