import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { debounce } from 'lodash';

import PFButton from '../../shared/PFPrime/PFButton';
import PFInputText from '../../shared/PFPrime/PFInputText';

import {
  FilteredUserList,
  copyCoverageToUser,
  getUserList,
} from './services/user-form-coverage.service';

const filterRecord = (userId, records) => {
  const userList = [];
  records.forEach(item => {
    if (userId != item?.user_id) {
      userList.push({
        userName: item?.user_name,
        role: item?.role_name,
        email: item?.email,
        user_id: item?.user_id,
      });
    }
  });
  return userList;
};
const UserCoverageFormUserList = ({
  copyUserCoverage,
  setCopyUserCoverage,
  userId,
  toast,
}) => {
  const [userList, setUserList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const fetchData = async () => {
    try {
      const userListResponse = await getUserList();
      const userList = filterRecord(userId, userListResponse?.items);
      setUserList(userList);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const filterUserName = async searchText => {
    setSearch(searchText);
    if (searchText.length < 3) {
      fetchData();
      return;
    }
    try {
      const userListResponse = await FilteredUserList(searchText);
      const userList = filterRecord(userId, userListResponse?.items);
      setUserList(userList);
    } catch (error) {
      console.error('Error filtering user list:', error);
    }
  };
  const debouncedFilterUserName = useCallback(debounce(filterUserName), []);
  const handleCopyCoverageToUser = async () => {
    const copyToUsers = selectedProducts.map(item => item.user_id);
    const requestBody = { copyToUsers };
    try {
      const copyUser = await copyCoverageToUser(userId, requestBody);
      if (copyUser?.status === true) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Coverage copied successfully',
          life: 3000,
        });
        setSelectedProducts([]);
        setCopyUserCoverage(false);
        setConfirmationVisible(false);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy coverage.',
        });
      }
    } catch (error) {
      console.error('An error occurred while copying coverage:', error);
    }
  };
  return (
    <Dialog
      header="Users List"
      visible={copyUserCoverage}
      style={{ width: '650px' }}
      onHide={() => {
        setCopyUserCoverage(false);
        setSelectedProducts([]);
        setSearch('');
        fetchData();
      }}
      draggable={false}
    >
      <>
        <div className="flex justify-content-end align-items-center">
          <div className="w-full">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <PFInputText
                type="text"
                placeholder="Search by Name or Role"
                value={search}
                className="w-full"
                onChange={e => debouncedFilterUserName(e.target.value)}
              />
            </span>
          </div>
          <div>
            <PFButton
              label="Copy to User"
              size="small"
              onClick={() => setConfirmationVisible(true)}
              className="m-2 w-8rem"
              severity="primary"
              disabled={selectedProducts.length === 0}
            />
          </div>
        </div>

        <DataTable
          value={userList}
          paginator
          rows={8}
          selectionMode="checkbox"
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="user_id"
          tableStyle={{ minWidth: '20rem' }}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: '3rem' }}
          ></Column>
          <Column field="userName" header="Name"></Column>
          <Column field="role" header="Role"></Column>
        </DataTable>
      </>
      <Dialog
        visible={confirmationVisible}
        style={{ width: '450px' }}
        onHide={() => setConfirmationVisible(false)}
        draggable={false}
        header="Confirmation"
      >
        <div>
          <p>
            Copying this coverage will overwrite the existing coverage of these
            users. Do you want to proceed?
          </p>
          <div className="flex justify-content-end mt-4">
            <PFButton
              label="No"
              outlined
              className="p-button-text mx-2"
              onClick={() => {
                setConfirmationVisible(false);
              }}
            />
            <PFButton
              label="Yes"
              className="mx-2"
              onClick={handleCopyCoverageToUser}
            />
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};
export default UserCoverageFormUserList;
