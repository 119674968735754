import * as Yup from 'yup';
import moment from 'moment';

export const quoteInfoInit = {
  title: '',
  createDate: '',
  expiryDate: '',
  followupDate: '',
  status: '',
  recurrence: '',
  leadBy: '',
};

export const quoteValidation = {
  title: Yup.string().required('Required'),
  createDate: Yup.string()
    .required('Required')
    .test(
      'is-greater',
      'Create Date should not be greater than Expiry Date',
      function (value) {
        const { expiryDate } = this.parent;
        return moment(value).diff(moment(expiryDate), 'minutes') <= 0;
      }
    ),
  expiryDate: Yup.date().required('Required'),
  followupDate: Yup.date(),
  status: Yup.string(),
  recurrence: Yup.string(),
};

export const quoteStatus = [
  { id: 1, name: 'OPEN' },
  { id: 2, name: 'REVIEW' },
  { id: 3, name: 'APPROVED' },
  { id: 4, name: 'REJECTED' },
  { id: 5, name: 'SOLD' },
  { id: 6, name: 'CONCERN' },
];

export const recurrences = [
  { id: 1, name: 'Daily' },
  { id: 2, name: 'Weekly' },
  { id: 3, name: 'Monthly' },
  { id: 4, name: 'Yearly' },
];
