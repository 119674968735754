import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import React, { useMemo } from 'react';

import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';

export default function DocumentSaveConfirmationDialog(props) {
  const [radioValue, setRadioValue] = React.useState('');

  function handleSave() {
    if (radioValue && radioValue.length > 0) {
      props.confirmHandler(radioValue);
    } else {
      props.alertHandler('error', 'Please select a value', '');
    }
  }

  function handleCancel() {
    props.cancelHandler(false);
  }
  const isReviewPermission = useMemo(
    () => checkPermission(permissions?.documentCenter?.reviewDoc),
    []
  );
  const isSendPermission = useMemo(
    () => checkPermission(permissions?.documentCenter?.sendToSourceDoc),
    []
  );
  const isCompletePermission = useMemo(
    () => checkPermission(permissions?.documentCenter?.completeDoc),
    []
  );
  return (
    <Dialog
      header="What would you like to do with this Document?"
      visible={props.showDialog}
      style={{ width: '50vw' }}
      onHide={handleCancel}
      draggable={false}
    >
      <div className="flex flex-wrap gap-3">
        {isReviewPermission && (
          <div className="flex align-items-center">
            <RadioButton
              inputId="rd1"
              name="radioButton"
              value="NEEDS_REVIEW"
              onChange={e => setRadioValue(e.value)}
              checked={radioValue === 'NEEDS_REVIEW'}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Needs Review
            </label>
          </div>
        )}
        <div className="align-items-center">
          <RadioButton
            inputId="rd2"
            name="radioButton"
            value="INTERNAL"
            onChange={e => setRadioValue(e.value)}
            checked={radioValue === 'INTERNAL'}
          />
          <label htmlFor="ingredient2" className="ml-2">
            Internal Document
          </label>
        </div>
        {isSendPermission && (
          <div className="flex align-items-center">
            <RadioButton
              inputId="rd3"
              name="radioButton"
              value="SOURCE"
              onChange={e => setRadioValue(e.value)}
              checked={radioValue === 'SOURCE'}
            />
            <label htmlFor="ingredient3" className="ml-2">
              Send To Source
            </label>
          </div>
        )}
        {isCompletePermission && (
          <div className="flex align-items-center">
            <RadioButton
              inputId="rd4"
              name="radioButton"
              value="COMPLETE"
              onChange={e => setRadioValue(e.value)}
              checked={radioValue === 'COMPLETE'}
            />
            <label htmlFor="ingredient4" className="ml-2">
              Complete
            </label>
          </div>
        )}
      </div>

      <div className="mt-4 text-right">
        <Button
          label="Cancel"
          icon="pi pi-times"
          iconPos="left"
          severity="primary"
          outlined
          onClick={handleCancel}
          className="mr-2"
          size="small"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          iconPos="left"
          severity="primary"
          onClick={handleSave}
          size="small"
        />
      </div>
    </Dialog>
  );
}
