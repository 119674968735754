const BOOLEAN = { YES: 'Y', NO: 'N' };
const SEVERITY = {
  DANGER: 'danger',
  SUCCESS: 'success',
  PRIMARY: 'primary',
  INFO: 'info',
};
export const USER_STATUS = { ACTIVE: 18, INACTIVE: 19 };

export const getSeverity = status => {
  switch (status) {
    case BOOLEAN.NO:
      return SEVERITY.DANGER;

    case BOOLEAN.YES:
      return SEVERITY.SUCCESS;
    default:
      return SEVERITY.INFO;
  }
};
