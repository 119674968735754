import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserAvailability = async userId => {
  try {
    if (userId) {
      const userAvailabilityResponse = await ApiService.get(
        `/auth/user/manage/availability-schedule/${userId}`
      );
      return {
        userAvailabilityData:
          userAvailabilityResponse?.data?.availability || [],
        start_location:
          userAvailabilityResponse?.data?.routingPreferenceRes
            ?.start_location || '',
        end_location:
          userAvailabilityResponse?.data?.routingPreferenceRes?.end_location ||
          '',
        user_timezone:
          userAvailabilityResponse?.data?.routingPreferenceRes?.user_timezone ||
          [],
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const editUserAvailability = async (userId, availabilityData) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/availability-schedule/${userId}`,
      {
        availability: JSON.stringify(availabilityData?.availability),
        start_location: availabilityData?.start_location,
        end_location: availabilityData?.end_location,
        user_timezone: availabilityData?.user_timezone,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getWorkHours = async userId => {
  return await ApiService.get(`/auth/user/manage/work-schedule/${userId}`);
};
export const addWorkHours = async (userId, payload) => {
  return await ApiService.post(
    `/auth/user/manage/work-schedule/${userId}`,
    payload
  );
};
