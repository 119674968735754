import PieChartComponent from './PieChartComponent';
import GaugeChartComponent from './GaugeChartComponent';
import BarChartComponent from './BarChartComponent';
import LineChartComponent from './LineChartComponent';
import ButtonComponent from './ButtonComponent';
import ButtonTabularComponent from './ButtonTabularComponent';
import CardComponent from './CardComponent';

const WidgetRenderer = ({
  cubeQuery,
  widget_type,
  cubeResponse,
  target,
  annotation,
  formikData,
  onClickHandler,
  showButtonPreview,
  widgetId,
  dashboardLogDetails = null,
  dashboardDetails = null,
}) => {
  switch (true) {
    case widget_type == 'pie-chart':
      return (
        <PieChartComponent
          cubeResponse={cubeResponse}
          annotation={annotation?.dimensions}
          dashboardLogDetails={dashboardLogDetails}
          dashboardDetails={dashboardDetails}
        />
      );
      break;
    case widget_type == 'gauge':
      return (
        <GaugeChartComponent
          cubeResponse={cubeResponse}
          target={target}
          annotation={annotation?.measures}
          dashboardLogDetails={dashboardLogDetails}
          dashboardDetails={dashboardDetails}
        />
      );
      break;
    case widget_type == 'bar-chart':
      return (
        <BarChartComponent
          cubeResponse={cubeResponse}
          annotation={annotation?.dimensions}
          dashboardLogDetails={dashboardLogDetails}
          dashboardDetails={dashboardDetails}
        />
      );
      break;
    case widget_type == 'external-link-button' && showButtonPreview:
      return <ButtonComponent formikData={formikData} />;
      break;
    case widget_type == 'line-chart':
      return (
        <LineChartComponent
          cubeResponse={cubeResponse}
          annotation={annotation?.dimensions}
          dashboardLogDetails={dashboardLogDetails}
          dashboardDetails={dashboardDetails}
        />
      );
      break;
    case widget_type == 'tabular-report':
      return (
        <ButtonTabularComponent
          formikData={formikData}
          onClickHandler={onClickHandler}
        />
      );
    case widget_type == 'card':
      return (
        <CardComponent
          formikData={formikData}
          cubeResponse={cubeResponse}
          showButtonPreview={showButtonPreview}
          annotation={annotation?.measures}
          widgetId={widgetId}
          dashboardLogDetails={dashboardLogDetails}
          dashboardDetails={dashboardDetails}
        />
      );

    default:
      return null;
      break;
  }
};

export default WidgetRenderer;
