import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import useQuoteStatus from '../../../../hooks/useQuoteStatus';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import { getInvoiceStatus } from '../helpers/Invoice';
import { addDays } from '../../../../utils/Helpers';

const ItemResult = ({
  aggregate,
  setExpiryDate,
  setStatusId,
  expiryDate,
  statusId,
  quoteId,
  invoiceCreateAt,
  projectId,
  projectNumber,
  quoteNumber,
  customerId,
  opportunityId,
  invoiceId,
  setStatus,
  advanceAmount,
  setAdvanceAmount,
  invoicePendingAmount,
  paidAmount,
}) => {
  const clientDetails = localStorage.getItem('client_details') || 0;
  let dueDays = 0;
  if (clientDetails && JSON.parse(clientDetails)?.due_days) {
    dueDays = parseInt(JSON.parse(clientDetails).due_days);
  }

  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const { quoteStatus } = useQuoteStatus();
  const { mode } = useParams();
  const { totalQuoteAmount, totalDiscountAmount, totalTaxAmount } = aggregate;
  const [selectedQuoteStatus, setSelectedQuoteStatus] = useState('');
  const [validUntil, setValidUntil] = useState(addDays(new Date(), dueDays));
  const onQuoteStatus = (name, value) => {
    setSelectedQuoteStatus(value);
  };

  useEffect(() => {
    const invoiceStatus = getInvoiceStatus()
      .then(res => {
        if (res.status === 200) {
          setInvoiceStatus(res?.data);
          setStatus(res?.data);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (mode === 'create') {
      if (invoiceStatus?.length > 0) {
        const newStatusId = invoiceStatus.find(
          item => item.status.toLowerCase() === 'open'
        )?.status_id;
        setSelectedQuoteStatus(newStatusId);
      }
    }
  }, [invoiceStatus]);

  const onValidUntil = e => {
    setValidUntil(e.value);
  };

  const modifiedDate = (days = 0) => {
    const extend = days * 24;
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + extend * 60 * 60 * 1000);
    return newDate;
  };

  useEffect(() => {
    setExpiryDate(validUntil);
  }, [validUntil]);

  useEffect(() => {
    selectedQuoteStatus && setStatusId(selectedQuoteStatus);
  }, [selectedQuoteStatus]);

  useEffect(() => {
    if (expiryDate) {
      const date = new Date(expiryDate);
      setValidUntil(date);
    }
  }, [expiryDate]);

  useEffect(() => {
    if (statusId && !selectedQuoteStatus) {
      setSelectedQuoteStatus(statusId);
    }
  }, [statusId]);

  return (
    <div htmlFor="Item Result">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <Link
              to={`/crm/${customerId}/${opportunityId}/project/view/${projectId}`}
            >
              <span className="p-float-label">
                <PFInputText
                  id="projectNumber"
                  name="projectNumber"
                  type="text"
                  value={projectNumber}
                  disabled={true}
                  className="text-primary"
                />

                <label htmlFor="clientFirstName">Project ID</label>
              </span>
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <Link
              to={`/crm/${customerId}/${opportunityId}/quote/view/${quoteId}`}
            >
              <span className="p-float-label">
                <PFInputText
                  id="quoteNumber"
                  name="quoteNumber"
                  type="text"
                  value={quoteNumber}
                  disabled={true}
                  className="text-primary"
                />
                <label htmlFor="clientFirstName">Quote ID</label>
              </span>
            </Link>
          </div>
        </div>
        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="invoiceId"
                  name="invoiceId"
                  type="text"
                  value={invoiceId || ''}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Invoice ID</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFDropdown
                options={invoiceStatus}
                optionLabel="status"
                optionValue="status_id"
                name="statusId"
                filter={true}
                value={selectedQuoteStatus}
                onChange={onQuoteStatus}
                disabled={mode === 'edit' ? false : true}
              />
              <label htmlFor="statusId">
                Invoice Status <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="createdOn"
                  name="created_on"
                  type="text"
                  value={
                    invoiceCreateAt &&
                    moment(invoiceCreateAt, 'YYYY-MM-DD hh:mm A').format(
                      'MM-DD-YYYY'
                    )
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Created On</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFCalendar
                id="validUntil"
                name="validUntil"
                value={validUntil}
                onChange={onValidUntil}
                minDate={modifiedDate(0)}
                disabled={mode === 'view' ? true : false}
              />
              <label htmlFor="clientFirstName">
                Due Date <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalDiscountAmount"
                name="totalDiscountAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalDiscountAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Discount</label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalTaxAmount"
                name="totalTaxAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalTaxAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Tax</label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="totalQuoteAmount"
                name="totalQuoteAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={totalQuoteAmount || '0'}
                version={2}
                className="w-full"
                disabled={true}
              />
              <label htmlFor="clientFirstName">Total Invoice Amount </label>
            </span>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFInputNumber
                inputId="advanceAmount"
                name="advanceAmount"
                mode={'currency'}
                currency="USD"
                minFractionDigits={2}
                value={advanceAmount || '0.0'}
                version={2}
                className="w-full"
                disabled={mode === 'view' ? true : false}
                onChange={(name, value) => {
                  setAdvanceAmount(value || 0);
                }}
              />
              <label htmlFor="advanceAmount">Advance Amount </label>
            </span>
          </div>
        </div>

        {mode !== 'create' && (
          <>
            <div className="col-12 md:col-12 lg:col-12">
              <div className="w-full px-1">
                <span className="p-float-label">
                  <PFInputNumber
                    inputId="invoicePendingAmount"
                    name="invoicePendingAmount"
                    mode={'currency'}
                    currency="USD"
                    minFractionDigits={2}
                    value={invoicePendingAmount || '0'}
                    version={2}
                    className="w-full"
                    disabled={true}
                  />
                  <label htmlFor="clientFirstName">Due Amount </label>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-12 lg:col-12">
              <div className="w-full px-1">
                <span className="p-float-label">
                  <PFInputNumber
                    inputId="paidAmount"
                    name="paidAmount"
                    mode={'currency'}
                    currency="USD"
                    minFractionDigits={2}
                    value={paidAmount || '0'}
                    version={2}
                    className="w-full"
                    disabled={true}
                  />
                  <label htmlFor="clientFirstName">Paid Amount </label>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ItemResult;
