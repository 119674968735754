import { useState, useEffect, useRef } from 'react';
import { Grid, Select, MenuItem } from '@material-ui/core';
import { Toast } from 'primereact/toast';

import { getHomeDepotOverrideReasons } from '../../ProjectManagement/EPALead/EPALead.service';

const EditHomeDepotConfig = ({ formik, setBtnDisables }) => {
  const [reasons, setReasons] = useState([]);
  const toast = useRef(null);

  const getHomeDepotData = async () => {
    const response = await getHomeDepotOverrideReasons();
    setReasons(response);
  };

  useEffect(() => {
    getHomeDepotData();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="w-12">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <label>LSWP Followed :</label>
            <Select
              label="LSWP Followed"
              value={formik.values?.hd_lrrp_configuration?.lswp_followed}
              onChange={event => {
                formik.setFieldValue('hd_lrrp_configuration', {
                  ...formik?.values?.hd_lrrp_configuration,
                  lswp_followed: event?.target?.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} lg={4}>
            <label>Lead Response:</label>
            <Select
              label="Lead Response"
              value={formik.values?.hd_lrrp_configuration?.hd_lead_test_result}
              onChange={event => {
                formik.setFieldValue('hd_lrrp_configuration', {
                  ...formik?.values?.hd_lrrp_configuration,
                  hd_lead_test_result: event?.target?.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={1}>Positive</MenuItem>
              <MenuItem value={0}>Negative</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} lg={4}>
            <label>Override Reason:</label>
            <Select
              label="Override Reason"
              value={formik.values?.hd_default_reason}
              onChange={event => {
                formik.setFieldValue('hd_default_reason', event.target.value);
                setBtnDisables(false);
              }}
            >
              {reasons?.map(item => (
                <MenuItem value={item?.reason_code} key={item?.reason_code}>
                  {item?.reason_desc}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditHomeDepotConfig;
