// **** React Imports ****
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// **** Custom Components ****
import { Card } from 'primereact/card';

import { checkPermission } from './utils/Helpers';
import { AccessDenied } from './components/shared/AccessDenied/AccessDenied';
import { NotFound } from './components/shared/NotFound/NotFound';
import { routes } from './config/routes';

// **** Styles/Images/Icons ****
import { useStyles } from './App.styles';

export const Routes = () => {
  const { permissionList, isLoading } = useSelector(
    state => state.permissionsList
  );

  const classes = useStyles();

  return (
    <>
      {!isLoading && (
        <Switch>
          {routes.map((route, index) =>
            route?.permissions ? (
              checkPermission(
                route?.permissions,
                route.title,
                permissionList
              ) ? (
                <Route
                  key={index}
                  path={route.path}
                  exact={true}
                  render={props => (
                    <route.component {...props} identifier={route.identifier} />
                  )}
                />
              ) : (
                <Route
                  path={route.path}
                  exact={true}
                  component={AccessDenied}
                />
              )
            ) : (
              <Route
                key={index}
                path={route.path}
                exact={true}
                render={props => (
                  <route.component {...props} identifier={route.identifier} />
                )}
              />
            )
          )}
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      )}
    </>
  );
};
