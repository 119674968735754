import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import { fetchOpportunityById } from '../../Opportunities/helpers/Opportunity';
import { isMeasureSquareEnabled } from '../../../../utils/Helpers';

const OpportunityInfomation = ({
  opportunityId,
  customerId,
  globalOpportunityDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [opportunityDetails, setOpportunityDetails] = useState(null);

  useEffect(() => {
    globalOpportunityDetails && globalOpportunityDetails(opportunityDetails);
  }, [opportunityDetails]);

  useEffect(() => {
    opportunityId &&
      fetchOpportunityById(
        opportunityId,
        customerId,
        setOpportunityDetails,
        setLoading
      );
  }, [opportunityId]);

  return (
    <Card style={{ boxShadow: 'none' }}>
      <div htmlFor="Opportinuty Information">
        <div className="grid">
          <div className="text-1xl font-bold px-3 py-2 col-12 md:col-12 lg:col-12 flex">
            <h3>Lead Information</h3>
            {isMeasureSquareEnabled(
              opportunityDetails?.from_external_source
            ) && (
              <div className="pl-2">
                <Tag value="MeasureSquare"></Tag>
              </div>
            )}
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="w-full">
              <Link to={`/crm/${customerId}/lead/view/${opportunityId}`}>
                <span className="p-float-label">
                  <PFInputText
                    id="opportunityId"
                    name="opportunity_id"
                    type="text"
                    value={opportunityDetails?.opportunityNumber || ''}
                    disabled={true}
                    className="text-primary"
                  />
                  <label htmlFor="clientFirstName">Lead#</label>
                </span>
              </Link>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="category"
                  name="category"
                  type="text"
                  value={opportunityDetails?.category || ''}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Category</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="status"
                  name="status"
                  type="text"
                  value={opportunityDetails?.status || ''}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Status</label>
              </span>
            </div>
          </div>
        </div>
        <div className="grid py-2">
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full">
              <span className="p-float-label">
                <PFInputText
                  id="opportunityDescription"
                  name="opportunity_description"
                  type="text"
                  value={opportunityDetails?.description || 'Not Available'}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Lead Description</label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OpportunityInfomation;
