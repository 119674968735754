import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { PERSIST, PURGE } from 'redux-persist/es/constants';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { inventoryReduces } from '../components/ProjectManagement/Inventory/reducers';

import {
  routeConfigReducer,
  userReducer,
  userFormReducer,
  userManagementReducer,
  userFormDetailsReducer,
  userFormDashboardsReducer,
  userFormCoverageReducer,
  userFormContactReducer,
  userFormAddressReducer,
  userFormLicenseReducer,
  userFormTeamReviewReducer,
  userFormDocumentsReducer,
  userFormCredentialsReducer,
  userFormAvailabilityReducer,
  permissionsListReducer,
  userFormRoutingReducer,
  assignWidgetsReducer,
  newSchedulerReducer,
  routeMapSliceReducer,
  pageHeaderReducer,
  installerViewCalendarReducer,
  quoteHeaderReducer,
  phoneTypeReducer,
  stateMasterReducer,
  industryTypesReducer,
  projectTypesReducer,
  projectCategoryReducer,
  warrantyConfigReducer,
  opportunitySourceReducer,
  opportunityStatusReducer,
  quoteStatusReducer,
  invoiceItems,
  userFormCrewReducer,
  userFormCrewMemberReducer,
  measureSquareConfigReducer,
  questionnaireReducer,
} from './slices';

const rootReducer = combineReducers({
  user: userReducer,
  routeConfig: routeConfigReducer,
  userForm: userFormReducer,
  userManagement: userManagementReducer,
  userFormDetails: userFormDetailsReducer,
  userFormDashboards: userFormDashboardsReducer,
  userFormCoverage: userFormCoverageReducer,
  userFormContact: userFormContactReducer,
  userFormAddress: userFormAddressReducer,
  userFormLicense: userFormLicenseReducer,
  userFormTeamReview: userFormTeamReviewReducer,
  userFormDocuments: userFormDocumentsReducer,
  userFormCredentials: userFormCredentialsReducer,
  userFormAvailability: userFormAvailabilityReducer,
  permissionsList: permissionsListReducer,
  userFormRouting: userFormRoutingReducer,
  assignWidgets: assignWidgetsReducer,
  newScheduler: newSchedulerReducer,
  inventoryManagment: combineReducers(inventoryReduces),
  routeMap: routeMapSliceReducer,
  pageHeader: pageHeaderReducer,
  installerViewCalendar: installerViewCalendarReducer,
  quoteHeader: quoteHeaderReducer,
  masters: phoneTypeReducer,
  userFormCrew: userFormCrewReducer,
  userFormCrewMember: userFormCrewMemberReducer,
  masters: combineReducers({
    phoneTypeReducer,
    stateMasterReducer,
    industryTypesReducer,
    projectTypesReducer,
    projectCategoryReducer,
    opportunitySourceReducer,
    opportunityStatusReducer,
    quoteStatusReducer,
  }),
  warrantyConfig: warrantyConfigReducer,
  crm: combineReducers({ projectInvoice: invoiceItems }),
  measureSquareConfig: measureSquareConfigReducer,
  questionnaire: questionnaireReducer,
});
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      createStateSyncMiddleware({
        blacklist: [PERSIST, PURGE],
        serializableCheck: false,
      })
    ),
});

initMessageListener(store);
