import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Tag } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFCheckbox from '../../../shared/PFPrime/PFCheckbox';

export const getEmailTypes = [
  { id: 1, name: 'Primary', email_type: 'Primary' },
  { id: 2, name: 'Alternative', email_type: 'Alternative' },
];

const newEmailInitialValue = {
  is_primary: false,
  email: '',
};

const newEmailValidationSchema = Yup.object().shape({
  is_primary: Yup.bool().required('Required'),
  email: Yup.string().email().required('Required'),
});

export const emailSubmit = (
  setEmailObject,
  email_type,
  updateVlue,
  setUpdateValue,
  setBtnLabel,
  btnLabel
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: updateVlue
      ? updateVlue?.rowData
      : { ...newEmailInitialValue },

    validationSchema: newEmailValidationSchema,
    onSubmit: async values => {
      setEmailObject(prevObject => {
        if (prevObject?.length <= 0) {
          values['is_primary'] = true;
        }
        if (btnLabel === 'Update') {
          const updatedList = [...prevObject];
          updatedList[updateVlue?.rowIndex] = values;
          return updatedList;
        }
        if (btnLabel === 'Add') {
          return [...prevObject, values];
        }
      });

      formik.resetForm({ values: newEmailInitialValue });
      setBtnLabel('Add');
      setUpdateValue('');
    },
  });

  return formik;
};

export const columns = (
  emailTypeOption,
  deleteEmail,
  mode,
  primaryId,
  updateEmail
) => {
  let main = [
    {
      field: 'primary_number',
      header: 'Primary',
      sortable: false,
      filter: false,
      body: (rowData, { rowIndex }) => {
        return (
          <>
            <PFCheckbox
              checked={rowData?.is_primary}
              onChange={e => updateEmail(rowData, rowIndex, e.checked)}
              className="mr-1"
              disabled={mode === 'view'}
            />
          </>
        );
      },
    },
    {
      field: 'email',
      header: 'Email',
      sortable: false,
      filter: false,
    },
  ];
  if (mode !== 'view') {
    main = [
      ...main,
      {
        field: 'Action',
        header: 'Action',
        sortable: false,
        filter: false,
        body: (rowData, option) => (
          <>
            {rowData?.isCustomerRegistered ? null : !rowData?.is_primary ? (
              <>
                <PFButton
                  type="button"
                  text={true}
                  label="Edit"
                  icon="pi pi-pencil"
                  outlined={true}
                  className="mr-2"
                  severity="primary"
                  onClick={() => updateEmail(rowData, option?.rowIndex)}
                />
                <PFButton
                  type="button"
                  text={false}
                  label="Delete"
                  icon="pi pi-trash"
                  severity="danger"
                  outlined={true}
                  onClick={() => deleteEmail(option?.rowIndex)}
                />
              </>
            ) : (
              <>
                <PFButton
                  type="button"
                  text={false}
                  label="Edit"
                  severity="primary"
                  icon="pi pi-pencil"
                  outlined={true}
                  onClick={() => updateEmail(rowData, option?.rowIndex)}
                />
              </>
            )}
            {rowData.is_duplicate && (
              <Tag
                severity={'warning'}
                value={'Duplicate'}
                className="ml-3"
              ></Tag>
            )}
          </>
        ),
      },
    ];
  } else {
    main = [
      ...main,
      {
        field: 'Action',
        header: '',
        sortable: false,
        filter: false,
        body: (rowData, option) => (
          <>
            {rowData.is_duplicate && (
              <Tag
                severity={'warning'}
                value={'Duplicate'}
                className="ml-3"
              ></Tag>
            )}
          </>
        ),
      },
    ];
  }
  return main;
};
