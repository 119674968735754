import React from 'react';
import { Button } from 'primereact/button';

export default function PFButton({
  label,
  type,
  severity,
  text,
  raised,
  className,
  onClick,
  disabled,
  outlined,
  rounded,
  icon,
  ariaLabel,
  iconPos,
}) {
  onClick = onClick ? onClick : () => {};
  return (
    <Button
      label={label || ''}
      type={type || 'button'}
      severity={severity || 'primary'}
      text={text || false}
      raised={raised || false}
      className={className || ''}
      onClick={onClick || ''}
      disabled={disabled || false}
      size="small"
      rounded={rounded || false}
      outlined={outlined || false}
      icon={`${icon || ''}`}
      iconPos={iconPos || ''}
      aria-label={`${ariaLabel || ''}`}
    />
  );
}
