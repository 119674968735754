// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  IconButton,
  Switch,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Input,
  ListSubheader,
  InputAdornment,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { parseISO } from 'date-fns';

import {
  formatDate,
  checkPermission,
  isValidHttpUrl,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

// **** Services *****
import { getDropdownValues } from './UserManagement.service';

// **** Styles/Images/Icons ****
import { useStyles } from './UserManagement.styles';

const UserDetails = ({
  userId,
  setLoading,
  setAlert,
  formik,
  action,
  profileId,
  setImage,
  fixedRenovatorDetails,
}) => {
  const classes = useStyles();
  const [searchStoreText, setSearchStoreText] = React.useState('');
  const [searchTypeText, setSearchTypeText] = React.useState('');
  const [searchCategoryText, setSearchCategoryText] = React.useState('');
  const [selectedStoreIds, setSelectedStoreIds] = React.useState([]);
  const [selectedTypeIds, setSelectedTypeIds] = React.useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);
  const imageInputRef = React.useRef();
  const options = ['Staff', 'Installer', 'Admin'];
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [dashboardOptions, setDashboardOptions] = React.useState([]);
  const [managerOptions, setManagerOptions] = React.useState([]);
  const [shirtSizeOptions, setShirtSizeOptions] = React.useState([]);
  const [storeCoverageOptions, setStoreCoverageOptions] = React.useState([]);
  const [typeCoverageOptions, setTypeCoverageOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const [positionOptions, setPositionOptions] = React.useState([]);
  // const [payScheduleOptions, setPayScheduleOptions] = React.useState([]);
  // const [payMethodOptions, setPayMethodOptions] = React.useState([]);

  const [teamOptions, setTeamOptions] = React.useState([]);
  const ITEM_HEIGHT = 30;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      className: classes.selectBoxClass,
    },
  };

  const today = new Date();
  const yesterdayDate = today.setHours(0, 0, 0, 0);

  // To load independent dropdowns values like Type, Source etc.
  React.useEffect(() => {
    getDropdownValues(
      setLoading,
      setRoleOptions,
      setDashboardOptions,
      setManagerOptions,
      setShirtSizeOptions,
      setPositionOptions,
      // setPayScheduleOptions,
      // setPayMethodOptions,
      setStoreCoverageOptions,
      setTypeCoverageOptions,
      setCategoryOptions,
      setTeamOptions
    );
  }, []);

  const containsText = (text, searchText) =>
    text.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1;

  const displayedStoreOptions = React.useMemo(
    () =>
      storeCoverageOptions.filter(option =>
        searchStoreText.length > 0
          ? containsText(
              `${option.store_number} - ${option.store_name}`,
              searchStoreText
            )
          : option
      ),
    [searchStoreText, storeCoverageOptions]
  );

  const displayedTypeOptions = React.useMemo(
    () =>
      typeCoverageOptions.filter(option =>
        searchTypeText.length > 0
          ? containsText(option.project_type, searchTypeText)
          : option
      ),
    [searchTypeText, typeCoverageOptions]
  );

  const displayedCategoryOptions = React.useMemo(
    () =>
      categoryOptions.filter(option =>
        searchCategoryText.length > 0
          ? containsText(option.category, searchCategoryText)
          : option
      ),
    [searchCategoryText, categoryOptions]
  );

  React.useEffect(() => {
    if (formik?.values?.store_coverage_ids && storeCoverageOptions) {
      const selectedIdsSplitToIntegers = formik?.values?.store_coverage_ids
        ?.split(',')
        .map(Number);
      const selectedStores = displayedStoreOptions?.filter(item =>
        selectedIdsSplitToIntegers.includes(item.store_id)
      );
      setSelectedStoreIds(selectedStores);
    } else {
      setSelectedStoreIds(
        displayedStoreOptions?.filter(item => item.store_id == null)
      );
    }
  }, [
    formik?.values?.store_coverage_ids &&
      formik?.values?.store_coverage_ids === null,
    storeCoverageOptions,
  ]);

  React.useEffect(() => {
    if (formik?.values?.type_coverage_ids && typeCoverageOptions) {
      const selectedIdsSplitToIntegers = formik?.values?.type_coverage_ids
        ?.split(',')
        .map(Number);
      const selectedTypes = displayedTypeOptions?.filter(item =>
        selectedIdsSplitToIntegers.includes(item.project_type_id)
      );
      setSelectedTypeIds(selectedTypes);
    } else {
      setSelectedTypeIds(
        displayedTypeOptions?.filter(item => item.project_type_id == null)
      );
    }
  }, [
    formik?.values?.type_coverage_ids &&
      formik?.values?.type_coverage_ids === null,
    typeCoverageOptions,
  ]);

  React.useEffect(() => {
    if (formik?.values?.category_coverage_ids && categoryOptions) {
      const selectedIdsSplitToIntegers = formik?.values?.category_coverage_ids
        ?.split(',')
        .map(Number);
      const selectedCategories = displayedCategoryOptions?.filter(item =>
        selectedIdsSplitToIntegers.includes(item.project_category_id)
      );
      setSelectedCategoryIds(selectedCategories);
    } else {
      setSelectedCategoryIds(
        displayedCategoryOptions?.filter(
          item => item.project_category_id == null
        )
      );
    }
  }, [
    formik?.values?.category_coverage_ids &&
      formik?.values?.category_coverage_ids === null,
    categoryOptions,
  ]);

  let selectedDashboardIds = [];
  if (formik?.values?.dashboard_ids && dashboardOptions) {
    const selectedIdsSplit = formik?.values?.dashboard_ids?.split(',');
    const selectedIdsSplitToIntegers = selectedIdsSplit.map(Number);
    dashboardOptions.forEach(item => {
      if (selectedIdsSplitToIntegers.includes(item.dashboard_id)) {
        selectedDashboardIds.push(item);
      }
    });
  }

  const handleStoreChange = event => {
    const {
      target: { value },
    } = event;
    let filterForAll = value?.filter(sel => sel && sel?.store_id !== null);

    if (
      filterForAll.length > 0 &&
      value[value?.length - 1]?.store_id !== null
    ) {
      setSelectedStoreIds(filterForAll);
      formik.setFieldValue(
        'store_coverage_ids',
        filterForAll.length > 0
          ? filterForAll.map(val => val?.store_id).join(',')
          : null
      );
    } else {
      setSelectedStoreIds([
        {
          store_id: null,
          store_name: 'All',
        },
      ]);
      formik.setFieldValue('store_coverage_ids', null);
    }
  };

  const handleTypeChange = event => {
    const {
      target: { value },
    } = event;
    let filterForAll = value?.filter(
      sel => sel && sel?.project_type_id !== null
    );

    if (
      filterForAll.length > 0 &&
      value[value?.length - 1].project_type_id !== null
    ) {
      setSelectedTypeIds(filterForAll);
      formik.setFieldValue(
        'type_coverage_ids',
        filterForAll.length > 0
          ? filterForAll.map(val => val?.project_type_id).join(',')
          : null
      );
    } else {
      setSelectedTypeIds([
        {
          project_type_id: null,
          project_type: 'All',
        },
      ]);
      formik.setFieldValue('type_coverage_ids', null);
    }
  };

  const handleCategoryChange = event => {
    const {
      target: { value },
    } = event;

    let filterForAll = value?.filter(
      sel => sel && sel?.project_category_id !== null
    );

    if (
      filterForAll.length > 0 &&
      value[value?.length - 1]?.project_category_id !== null
    ) {
      setSelectedCategoryIds(filterForAll);
      formik.setFieldValue(
        'category_coverage_ids',
        filterForAll.length > 0
          ? filterForAll.map(val => val?.project_category_id).join(',')
          : null
      );
    } else {
      setSelectedCategoryIds([
        {
          project_category_id: null,
          category: 'All',
        },
      ]);
      formik.setFieldValue('category_coverage_ids', null);
    }
  };
  let pattern = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  function ValidateEmailField(str) {
    if (pattern.test(str)) {
      return true;
    }
    return false;
  }

  const handleDigitalBadge = event => {
    const digitalBadgeValue = formik?.values?.digital_badge_id;
    if (isValidHttpUrl(digitalBadgeValue)) {
      window.open(digitalBadgeValue, '_blank');
    }
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box className={classes.boxColumn} m={1}>
            <TextField
              id="first_name"
              label="First Name"
              InputProps={{
                readOnly: action === 'view',
              }}
              disabled={action === 'view'}
              required={action !== 'view'}
              value={formik?.values?.first_name || ''}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && formik.errors.first_name}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Box>
          <Box className={classes.boxColumn} m={1}>
            <TextField
              id="last_name"
              label="Last Name"
              InputProps={{
                readOnly: action === 'view',
              }}
              disabled={action === 'view'}
              required={action !== 'view'}
              value={formik?.values?.last_name || ''}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && formik.errors.last_name}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Box>
          <Box className={classes.boxColumnEmail} m={1}>
            <TextField
              id="email"
              label="Email"
              InputProps={{
                readOnly:
                  action === 'view' ||
                  userId === localStorage.getItem('user_id'),
              }}
              disabled={
                action === 'view' || userId === localStorage.getItem('user_id')
              }
              required={action !== 'view'}
              value={formik?.values?.email}
              InputLabelProps={{ shrink: true }}
              onChange={event => {
                const emailTrim = event.target.value;
                formik.setFieldValue('email', emailTrim?.trim());
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box className={classes.boxColumn} m={1}>
            <InputMask
              mask="(999) 999-9999"
              value={formik?.values?.phone_number || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maskChar=" "
              placeholder=""
              disabled={action === 'view'}
              InputProps={{
                readOnly: action === 'view',
              }}
            >
              {() => (
                <TextField
                  name={`phone_number`}
                  label="Phone Number"
                  required={action !== 'view'}
                  disabled={action === 'view'}
                  InputLabelProps={{ shrink: true }}
                  error={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                />
              )}
            </InputMask>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          className={classes.photoSectionWrapper}
        >
          <Box className={classes.boxColumn} m={1}>
            <InputLabel shrink id="image-label" className={classes.label}>
              Photo
            </InputLabel>
            <div className={classes.imageInputWrapper}>
              {action !== 'view' && (
                <>
                  <input
                    type="file"
                    className={classes.fileInput}
                    id="contained-button-file"
                    onClick={event => {
                      event.target.value = null;
                    }}
                    ref={imageInputRef}
                    onChange={(event, value) => {
                      if (event.target.files && event.target.files[0]) {
                        if (
                          event.currentTarget.files[0].type.split('/')[0] !==
                          'image'
                        ) {
                          formik.setErrors({
                            file: 'Only image files are allowed',
                          });
                          imageInputRef.current.value = '';
                        } else if (
                          ['svg', 'SVG', 'gif', 'GIF', 'tiff', 'TIFF'].includes(
                            event.currentTarget.files[0].name.split('.').pop()
                          )
                        ) {
                          const imageFormat = event.currentTarget.files[0].name
                            .split('.')
                            .pop();
                          if (imageFormat === 'svg' || imageFormat === 'SVG') {
                            formik.setErrors({
                              file: 'System does not accept SVG files.',
                            });
                            imageInputRef.current.value = '';
                          }
                          if (imageFormat === 'GIF' || imageFormat === 'gif') {
                            formik.setErrors({
                              file: 'System does not accept GIF files.',
                            });
                            imageInputRef.current.value = '';
                          }
                          if (
                            imageFormat === 'tiff' ||
                            imageFormat === 'TIFF'
                          ) {
                            formik.setErrors({
                              file: 'System does not accept TIFF files.',
                            });
                            imageInputRef.current.value = '';
                          }
                        } else {
                          setImage(event.target.files[0]);
                          formik.setFieldValue(
                            'file',
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }
                    }}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className={classes.fileInputLabel}
                  >
                    Browse
                  </label>
                  {formik.errors.file && (
                    <Typography variant="caption" component="p" color="error">
                      {formik.errors.file}
                    </Typography>
                  )}
                </>
              )}

              {/* {image ? <img src={image} width="50" height="40" alt="" /> : <>&nbsp; {` `}</>} */}

              {formik?.values?.file ? (
                <>
                  <img
                    id="display_image"
                    name="display_image"
                    src={formik?.values?.file}
                    className={classes.displayImage}
                  />
                  {action === 'add' && (
                    <IconButton
                      color="secondary"
                      variant="text"
                      onClick={() => {
                        setImage(null);
                        formik.setFieldValue('file', '');
                      }}
                      className={classes.closeIcon}
                    >
                      <CloseIcon color="primary" fontSize="large" />
                    </IconButton>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          </Box>
          <Box className={classes.boxColumn} m={1}>
            <Box className={classes.fieldsMinHeight}>
              {action === 'view' ? (
                <TextField
                  id="dob"
                  label="Date of Birth"
                  InputProps={{
                    readOnly: action === 'view',
                  }}
                  disableFuture={true}
                  maxDate={yesterdayDate}
                  disabled={action === 'view'}
                  required={action !== 'view'}
                  value={
                    (formik?.values?.dob?.split('T')?.length &&
                      formatDate(formik?.values?.dob)) ||
                    null
                  }
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dob && formik.errors.dob}
                />
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className={classes.datePicker}
                    autoOk
                    id="dob"
                    name="dob"
                    variant="inline"
                    label="Date of Birth"
                    disableFuture={true}
                    maxDate={yesterdayDate}
                    format="MM-dd-yyyy"
                    inputProps={{ autoComplete: 'off' }}
                    invalidDateMessage="Invalid Date"
                    value={
                      (formik?.values?.dob?.split('T')?.length &&
                        parseISO(formik?.values?.dob?.slice(0, 10))) ||
                      null
                    }
                    onChange={(date, value) =>
                      formik?.setFieldValue(
                        'dob',
                        `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                      )
                    }
                    disabled={action === 'view'}
                    onBlur={formik.handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              )}
            </Box>
            {(action === 'view' || action === 'edit') && (
              <Box className={classes.fieldsMinHeight}>
                <TextField
                  id="team_member_id"
                  label="Team Member Id"
                  multiline
                  InputProps={{
                    readOnly: action === 'view' ? true : false,
                  }}
                  value={formik?.values?.team_member_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={action === 'view'}
                />
              </Box>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            <Box className={classes.fieldsMinHeight}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    required={action === 'edit' || action === 'add'}
                    onChange={(event, value) =>
                      formik.setFieldValue('is_active', value)
                    }
                    onBlur={formik.handleBlur}
                    checked={formik?.values?.is_active || false}
                    InputProps={{
                      readOnly: action === 'view' ? true : false,
                    }}
                    className={classes.switch}
                    disabled={action === 'view' ? true : false}
                  />
                }
                classes={{
                  label:
                    'MuiFormLabel-root MuiInputLabel-animated MuiInputLabel-shrink',
                  labelPlacementTop: classes.activeLabel,
                }}
                color="primary"
                label="Active"
                labelPlacement="top"
              />
            </Box>
            <Box className={classes.fieldsMinHeight}>
              <Autocomplete
                id="manager_id"
                name="manager_id"
                options={managerOptions || []}
                disableListWrap
                getOptionLabel={option =>
                  option.user_name ? option.user_name : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Manager"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={
                  managerOptions.filter(
                    item => item.user_id === formik?.values?.manager_id
                  )[0] || ''
                }
                onChange={(event, value) => {
                  formik.setFieldValue('manager_id', value.user_id);
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            </Box>
          </Box>
          <Box className={classes.rolesColumn} m={1}>
            <Box className={classes.fieldsMinHeight}>
              <Autocomplete
                id="role_id"
                name="role"
                options={roleOptions || []}
                disableListWrap
                inputProps={{
                  tabIndex: 1,
                }}
                getOptionLabel={option =>
                  option.role_name ? option.role_name : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Role"
                    InputLabelProps={{ shrink: true }}
                    required={action !== 'view'}
                  />
                )}
                value={
                  roleOptions.filter(
                    item => item.role_id === formik?.values?.role_id
                  )[0] || null
                }
                onChange={(event, value) => {
                  if (value != null) {
                    formik.setFieldValue('role_id', value.role_id);
                    formik.setFieldValue('role_name', value.role_name);
                  } else {
                    formik.setFieldValue('role_id', null);
                  }
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            </Box>
            <Box className={classes.fieldsMinHeight}>
              <Autocomplete
                id="position_id"
                name="position"
                options={positionOptions || []}
                disableListWrap
                getOptionLabel={option =>
                  option.position_name ? option.position_name : ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Position"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={
                  positionOptions.filter(
                    item => item.position_id === formik?.values?.position_id
                  )[0] || null
                }
                onChange={(event, value) => {
                  if (value != null) {
                    formik.setFieldValue('position_id', value?.position_id);
                  } else {
                    formik.setFieldValue('position_id', null);
                  }
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            </Box>
          </Box>
          <Box className={classes.rolesColumn} m={1}>
            <Box className={classes.fieldsMinHeight}>
              <Autocomplete
                multiple
                id="dashboard_ids"
                name="dashboard_ids"
                options={
                  dashboardOptions.filter(val => val?.private != 1) || []
                }
                disableListWrap
                inputProps={{
                  tabIndex: 1,
                }}
                getOptionLabel={option => (option.name ? option.name : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Dashboard"
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                )}
                value={selectedDashboardIds || []}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    'dashboard_ids',
                    value.map(dash => dash.dashboard_id).join(',')
                  );
                }}
                onBlur={formik.handleBlur}
                disabled={action === 'view'}
              />
            </Box>
            <Box className={classes.fieldsMinHeight}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  name="hire_date"
                  label="Hire Date"
                  format="MM-dd-yyyy"
                  autoOk
                  onChange={(date, value) =>
                    formik?.setFieldValue(
                      'hire_date',
                      `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                    )
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    disabled: action === 'view',
                    style: { display: action === 'view' && 'none' },
                  }}
                  disabled={action === 'view'}
                  value={
                    (formik?.values?.hire_date?.split('T')?.length &&
                      parseISO(formik?.values?.hire_date?.slice(0, 10))) ||
                    null
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={formik.touched.hire_date && formik.errors.hire_date}
                  maxDate={yesterdayDate}
                  disableFuture={true}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiKeyboardDatePicker
                clearable
                name="termination_date"
                label="Termination Date"
                format="MM-dd-yyyy"
                autoOk
                value={
                  (formik?.values?.termination_date &&
                    formik?.values?.termination_date?.split('T')?.length &&
                    parseISO(formik?.values?.termination_date.slice(0, 10))) ||
                  null
                }
                onChange={(date, value) => {
                  if (date) {
                    formik?.setFieldValue(
                      'termination_date',
                      `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                    );
                  } else {
                    formik?.setFieldValue('termination_date', null);
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  disabled: action === 'view',
                  style: { display: action === 'view' && 'none' },
                }}
                disabled={action === 'view'}
                fullWidth
                minDate={yesterdayDate}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <Autocomplete
              id="shirt_size_id"
              name="shirt_size"
              options={shirtSizeOptions || []}
              disableListWrap
              getOptionLabel={option =>
                option.size_name ? option.size_name : ''
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Shirt Size"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={
                shirtSizeOptions.filter(
                  item => item.shirt_size_id === formik?.values?.shirt_size_id
                )[0] || null
              }
              onChange={(event, value) => {
                if (value != null) {
                  formik.setFieldValue('shirt_size_id', value?.shirt_size_id);
                } else {
                  formik.setFieldValue('shirt_size_id', null);
                }
              }}
              onBlur={formik.handleBlur}
              disabled={action === 'view'}
            />
          </Box>
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            {/* <Autocomplete
              id="shoe_size"
              name="shoe_size"
              options={shoeSizeOptions || []}
              disableListWrap
              getOptionLabel={(option) => (option.shoe_size ? option.shoe_size : '')}
              renderInput={(params) => (
                <TextField {...params} label="Shoe Size" InputLabelProps={{ shrink: true }} />
              )}
              value={
                managerOptions.filter(
                  (item) => item.shoe_size_id === formik?.values?.shoe_size
                )[0] || ''
              }
              onChange={(event, value) => {
                formik.setFieldValue('shoe_size', value?.shoe_size_id);
              }}
              onBlur={formik.handleBlur}
              disabled={action === 'view'}
            /> */}

            <TextField
              id="shoe_size"
              label="Shoe Size"
              multiline
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              inputProps={{ maxLength: 2 }}
              value={formik?.values?.shoe_size}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.shoe_size && formik.errors.shoe_size}
              helperText={formik.touched.shoe_size && formik.errors.shoe_size}
              disabled={action === 'view'}
            />
          </Box>
          {/* <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <Autocomplete
              id="pay_schedule"
              name="pay_schedule"
              options={payScheduleOptions || []}
              disableListWrap
              getOptionLabel={(option) => (option.payment_type ? option.payment_type : '')}
              renderInput={(params) => (
                <TextField {...params} label="Pay Schedule" InputLabelProps={{ shrink: true }} />
              )}
              value={
                managerOptions.filter(
                  (item) => item.payment_type_id === formik?.values?.pay_schedule
                )[0] || ''
              }
              onChange={(event, value) => {
                formik.setFieldValue('pay_schedule', value?.payment_type_id);
              }}
              onBlur={formik.handleBlur}
              disabled={action === 'view'}
            />
          </Box>
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <Autocomplete
              id="pay_method"
              name="pay_method"
              options={payMethodOptions || []}
              disableListWrap
              getOptionLabel={(option) => (option.payment_type ? option.payment_type : '')}
              renderInput={(params) => (
                <TextField {...params} label="Pay Method" InputLabelProps={{ shrink: true }} />
              )}
              value={
                managerOptions.filter(
                  (item) => item.payment_type_id === formik?.values?.pay_method
                )[0] || ''
              }
              onChange={(event, value) => {
                formik.setFieldValue('pay_method', value?.payment_type_id);
              }}
              onBlur={formik.handleBlur}
              disabled={action === 'view'}
            />
          </Box> */}
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <Autocomplete
              multiple
              id="team"
              name="team"
              options={teamOptions || []}
              disableListWrap
              getOptionLabel={option =>
                option.team_name ? option.team_name : ''
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Team"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={
                formik?.values?.team_ids
                  ? teamOptions.filter(
                      item =>
                        formik?.values?.team_ids
                          ?.trim()
                          ?.indexOf(item.team_id) != -1
                    ) || []
                  : []
              }
              onChange={(event, value) => {
                formik.setFieldValue(
                  'team_ids',
                  value.map(team => team.team_id).join(',')
                );
              }}
              onBlur={formik.handleBlur}
              disabled={action === 'view'}
            />
          </Box>
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="google_email_address"
              label="Alternate email address"
              InputProps={{
                readOnly: action === 'view',
              }}
              disabled={action === 'view'}
              value={formik?.values?.google_email_address || ''}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.google_email_address &&
                formik.errors.google_email_address
              }
              helperText={
                formik.touched.google_email_address &&
                formik.errors.google_email_address
              }
            />
          </Box>

          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="estimator_id"
              label="IME Estimator ID"
              InputProps={{
                readOnly: action === 'view',
              }}
              disabled={action === 'view'}
              value={formik?.values?.estimator_id || ''}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>

          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            {action === 'edit' || action === 'add' ? (
              <TextField
                id="digital_badge_id"
                label="Digital Badge ID"
                InputProps={{
                  readOnly: action === 'view',
                }}
                disabled={action === 'view'}
                value={formik?.values?.digital_badge_id || ''}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            ) : (
              <FormControl>
                <InputLabel
                  shrink
                  id="digital_badge_id"
                  className={classes.label}
                >
                  Digital Badge ID
                </InputLabel>
                <a
                  href={formik?.values?.digital_badge_id}
                  target="_blank"
                  className={classes.paddingTop}
                  onClick={handleDigitalBadge}
                  rel="noreferrer"
                >
                  {formik?.values?.digital_badge_id}
                </a>
              </FormControl>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <FormControl>
              <InputLabel
                shrink
                id="type-checkbox-label"
                className={classes.label}
              >
                Type Coverage
              </InputLabel>
              <Tooltip
                arrow
                style={{ fontWeight: 'bold' }}
                title={
                  selectedTypeIds.find(o => o?.project_type_id === null)
                    ? 'All'
                    : selectedTypeIds
                        .map(a => a && a?.project_type)
                        .join(', ') || 'All'
                }
                placement="left-start"
              >
                <Select
                  labelId="type-checkbox-label"
                  id="type-checkbox"
                  multiple
                  style={{
                    width: `${selectedTypeIds?.map(a => a && a?.project_type)?.length * 95}px`,
                    minWidth: '200px',
                    maxWidth: '1000px',
                  }}
                  className={classes.selectStyle}
                  value={selectedTypeIds || []}
                  onChange={handleTypeChange}
                  onClose={() => setSearchTypeText('')}
                  input={<Input label="Tag" />}
                  renderValue={selected => {
                    return selected.map((value, index) => {
                      const option = displayedTypeOptions.find(
                        o => o?.project_type_id === value?.project_type_id
                      );
                      let addedComma =
                        index == 0 || index == selected?.length - 1
                          ? ' '
                          : ', ';
                      return (
                        option &&
                        (option !== null || undefined) && (
                          <Chip
                            label={option?.project_type || 'All'}
                            key={index}
                          />
                        )
                      );
                    });
                  }}
                  MenuProps={MenuProps}
                  disabled={action === 'view'}
                >
                  <MenuItem>
                    <TextField
                      size="small"
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setSearchTypeText(e.target.value)}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </MenuItem>
                  {displayedTypeOptions.map(type => (
                    <MenuItem key={type.project_type_id} value={type}>
                      <Checkbox
                        checked={
                          selectedTypeIds?.filter(
                            o => o?.project_type_id === type?.project_type_id
                          )?.length > 0
                        }
                      />
                      <ListItemText primary={type.project_type} />
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box m={1} className={[classes.boxColumn, classes.boxMinHeight]}>
            <FormControl>
              <InputLabel shrink id="category-checkbox-label">
                Category Coverage
              </InputLabel>
              <Tooltip
                arrow
                style={{ fontWeight: 'bold' }}
                title={
                  selectedCategoryIds.find(o => o?.project_category_id === null)
                    ? 'All'
                    : selectedCategoryIds
                        .map(a => a && a?.category)
                        .join(', ') || 'All'
                }
                placement="left-start"
              >
                <Select
                  labelId="category-checkbox-label"
                  id="category-checkbox"
                  multiple
                  style={{
                    width: `${selectedCategoryIds?.map(a => a && a?.category)?.length * 180}px`,
                    minWidth: '200px',
                    maxWidth: '1100px',
                  }}
                  className={classes.selectStyle}
                  value={selectedCategoryIds || []}
                  onChange={handleCategoryChange}
                  onClose={() => setSearchCategoryText('')}
                  input={<Input label="Tag" />}
                  renderValue={selected => {
                    return selected.map((value, index) => {
                      const option = displayedCategoryOptions.find(
                        o =>
                          o?.project_category_id === value?.project_category_id
                      );
                      let addedComma =
                        index == 0 || index == selected?.length - 1
                          ? ' '
                          : ', ';
                      return (
                        option &&
                        (option !== null || undefined) && (
                          <Chip label={option?.category || 'All'} key={index} />
                        )
                      );
                    });
                  }}
                  MenuProps={MenuProps}
                  disabled={action === 'view'}
                >
                  <MenuItem>
                    <TextField
                      size="small"
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => setSearchCategoryText(e.target.value)}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </MenuItem>
                  {displayedCategoryOptions.map(category => (
                    <MenuItem
                      key={category.project_category_id}
                      value={category}
                    >
                      <Checkbox
                        checked={
                          selectedCategoryIds?.filter(
                            o =>
                              o?.project_category_id ===
                              category?.project_category_id
                          )?.length > 0
                        }
                      />
                      <ListItemText primary={category.category} />
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box ml={1} m={1}>
            <FormControl>
              <InputLabel
                shrink
                id="store_coverage-label"
                className={classes.label}
              >
                Store Coverage
              </InputLabel>

              <Tooltip
                arrow
                style={{ fontWeight: 'bold' }}
                title={
                  selectedStoreIds.find(o => o?.store_id === null)
                    ? 'All'
                    : selectedStoreIds
                        .map(a => a && a?.store_number)
                        .join(', ') || 'All'
                }
                placement="left-start"
              >
                <Select
                  labelId="store_coverage-label"
                  id="store_coverage"
                  multiple
                  style={{
                    width: `${selectedStoreIds.length * 53}px`,
                    minWidth: '200px',
                    maxWidth: '900px',
                  }}
                  value={selectedStoreIds || []}
                  onChange={handleStoreChange}
                  onClose={() => setSearchStoreText('')}
                  input={<Input label="Tag" />}
                  renderValue={selected => {
                    return selected.map((value, index) => {
                      const option = displayedStoreOptions.find(
                        o => o.store_id === value?.store_id
                      );
                      let addedComma =
                        index == 0 || index == selected?.length - 1
                          ? ' '
                          : ', ';
                      return (
                        option &&
                        (option !== null || undefined) && (
                          <Chip
                            label={option?.store_number || 'All'}
                            key={index}
                          />
                        )
                      );
                    });
                  }}
                  MenuProps={MenuProps}
                  disabled={action === 'view'}
                >
                  <MenuItem>
                    <TextField
                      style={{ width: '500px' }}
                      size="small"
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      onChange={e => setSearchStoreText(e.target.value)}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </MenuItem>
                  {displayedStoreOptions.map(store => (
                    <MenuItem key={store.store_id} value={store}>
                      <Checkbox
                        checked={
                          selectedStoreIds?.filter(
                            o => o?.store_id === store?.store_id
                          )?.length > 0
                        }
                      />
                      <ListItemText
                        primary={
                          store && store?.store_number && store?.store_name
                            ? store?.store_number + ' - ' + store?.store_name
                            : store?.store_name
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box className={[classes.addressBox, classes.boxMinHeight]} m={1}>
            <TextField
              id="home_address"
              label="Home Address"
              multiline
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              inputProps={{ maxLength: 500 }}
              value={formik?.values?.home_address}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.home_address && formik.errors.home_address}
              helperText={
                formik.touched.home_address && formik.errors.home_address
              }
              disabled={action === 'view'}
            />
          </Box>
          <Box className={[classes.addressBox, classes.boxMinHeight]} m={1}>
            <TextField
              id="work_address"
              label="Work Address"
              multiline
              inputProps={{ maxLength: 500 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              value={formik?.values?.work_address}
              InputLabelProps={{ shrink: true }}
              error={formik.touched.work_address && formik.errors.work_address}
              helperText={
                formik.touched.work_address && formik.errors.work_address
              }
              disabled={action === 'view'}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="installer_bio"
              label="Technician Bio"
              multiline
              inputProps={{ maxLength: 500 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              value={formik?.values?.installer_bio}
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.installer_bio && formik.errors.installer_bio
              }
              helperText={
                formik.touched.work_address && formik.errors.installer_bio
              }
              disabled={action === 'view'}
            />
          </Box>
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="companyname"
              label="Company Name"
              multiline
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              value={formik?.values?.companyname}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              disabled={action === 'view'}
            />
          </Box>
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="measuring_system_id"
              label="Measuring System ID"
              multiline
              inputProps={{ maxLength: 500 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              value={formik?.values?.measuring_system_id}
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.measuring_system_id &&
                formik.errors.measuring_system_id
              }
              helperText={
                formik.touched.measuring_system_id &&
                formik.errors.measuring_system_id
              }
              disabled={action === 'view'}
            />
          </Box>
          <Box className={[classes.boxColumn, classes.boxMinHeight]} m={1}>
            <TextField
              id="scheduler_sort_order"
              label="Schedule Sort Order"
              multiline
              inputProps={{ maxLength: 500 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                readOnly: action === 'view' ? true : false,
              }}
              value={formik?.values?.scheduler_sort_order}
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.scheduler_sort_order &&
                formik.errors.scheduler_sort_order
              }
              helperText={
                formik.touched.scheduler_sort_order &&
                formik.errors.scheduler_sort_order
              }
              disabled={action === 'view'}
            />
          </Box>
        </Box>
        {fixedRenovatorDetails == 'no' && (
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box className={[classes.boxColumn, classes.boxMinHeight]} m={2}>
              <TextField
                id="renovator_name"
                label="Renovator Name"
                inputProps={{ maxLength: 500 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                value={formik?.values?.renovator_name}
                InputLabelProps={{ shrink: true }}
                disabled={action === 'view'}
              />
            </Box>
            <Box className={[classes.boxColumn, classes.boxMinHeight]} m={2}>
              <TextField
                id="renovator_certification_number"
                label="Renovator Certification Number"
                inputProps={{ maxLength: 700 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  readOnly: action === 'view' ? true : false,
                }}
                value={formik?.values?.renovator_certification_number}
                InputLabelProps={{ shrink: true }}
                disabled={action === 'view'}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default UserDetails;
