import UserFormCoverage from './user-form-coverage';

const UserFormPreferences = () => {
  return (
    <div className="flex flex-column">
      <UserFormCoverage />
    </div>
  );
};

export default UserFormPreferences;
