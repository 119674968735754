// **** External Utilities ****
import axios from 'axios';

import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

//get pr-types
export const getLaborItemsList = async (
  searchQuery,
  setLoading,
  setLaborItemsList
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString =
      Object.entries(searchQuery)
        ?.filter(([prop, value]) => Boolean(value) || value === 0)
        .map(([key, value], index) => `${key}=${value}`)
        .join('&') || '';
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/item-types/2/items?${newQueryString}`
    );
    setLaborItemsList(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getMerchandiseItemsList = async (
  merchandiseSearchQuery,
  setLoading,
  setMerchandiseItemsList
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString =
      Object.entries(merchandiseSearchQuery)
        ?.filter(([prop, value]) => Boolean(value) || value === 0)
        .map(([key, value], index) => `${key}=${value}`)
        .join('&') || '';
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/item-types/6/items?${newQueryString}`
    );
    setMerchandiseItemsList(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
// Add Labor Master Item
export const createLaborMasterItem = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadMasterItems,
  setOpen = () => {}
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/system/items`,
      values
    );
    if (response) {
      if (response?.data?.error) {
        setAlert('error', response ? response?.data?.error : null, false, true);
        setReloadMasterItems(true);
      } else {
        setReloadMasterItems(true);
        setAlert('success', 'Labor Item added successfully.', false, true);
      }
      return response;
    }
  } catch (error) {
    setAlert(
      'error',
      error ? error?.response?.data?.error : 'Error in Labor Item creation.',
      false,
      true
    );
  } finally {
    setLoading(false);
  }
};

//Add LaborItem
export const createLaborItem = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/system/items`,
      values
    );
    if (response) {
      if (response?.data?.error) {
        setAlert('error', response ? response?.data?.error : null, false, true);
        setReloadList(true);
      } else {
        setReloadList(true);
        setAlert('success', 'Labor Item added successfully.', false, true);
      }
    }
  } catch (error) {
    setAlert(
      'error',
      error ? error?.response?.data?.error : 'Error in Labor Item creation.',
      false,
      true
    );
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//Update Labor Item
export const updateLaborItem = async (
  projectId,
  laborItemId,
  values,
  setLoading,
  setAlert,
  setReloadMasterItems,
  setOpen = () => {}
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/system/items/${laborItemId}`,
      values
    );
    if (response) {
      if (response?.data?.error) {
        setAlert('error', response ? response?.data?.error : null, false, true);
        setReloadMasterItems(true);
      } else {
        setReloadMasterItems(true);
        setAlert('success', 'Labor Item updated successfully.', false, true);
      }
      return response;
    }
  } catch (error) {
    setAlert(
      'error',
      error ? error?.response?.data?.error : 'Error in updating Labor Item.',
      false,
      true
    );
    setLoading(false);
  }
};

// ************* Merchandise Items *************

//Add Merchandise Master Item
export const createMerchandiseMasterItem = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadMasterItems,
  setOpen = () => {}
) => {
  try {
    setOpen(false);
    setLoading(true);

    const formData = new FormData();

    formData.append('item_number', values.item_number);
    formData.append('item_desc', values.item_desc);
    formData.append('item_type_id', values.item_type_id);
    formData.append('retail_price', values.retail_price);
    formData.append('revenue_price', values.revenue_price);
    formData.append('cost_price', values.cost_price);
    if (values?.manufacturer)
      formData.append('manufacturer', values.manufacturer);
    if (values?.vendor) formData.append('vendor', values.vendor);
    formData.append(
      'inventory_managment',
      values?.inventory_managment || false
    );
    if (!values.file && values.image_url)
      formData.append('image_url', values.image_url);
    formData.append('image_type', 'upload');
    if (values.file) formData.append('file', values.file);
    formData.append('express_ship', values.express_ship || false);
    formData.append(
      'exclude_from_commission',
      values.exclude_from_commission || false
    );
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/system/items`,
      formData,
      {
        headers: headers,
      }
    );

    if (response) {
      setReloadMasterItems(true);
      setAlert(
        'success',
        'Merchandise Master Item added successfully.',
        false,
        true
      );
      return response;
    }
  } catch (error) {
    setAlert(
      'error',
      error
        ? error?.response?.data?.error
        : 'Item with the description/ID is already added, please provide a different ID',
      false,
      true
    );
    return error;
  } finally {
    setLoading(false);
  }
};

//Add MerchandiseItem
export const createMerchandiseItem = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/system/items`,
      values
    );
    if (response) {
      if (response?.data?.error) {
        setAlert('error', response ? response?.data?.error : null, false, true);
        setReloadList(true);
      } else {
        setReloadList(true);
        setAlert(
          'success',
          'Merchandise Item added successfully.',
          false,
          true
        );
      }
    }
  } catch (error) {
    setAlert(
      'error',
      error
        ? error?.response?.data?.error
        : 'Error in Merchandise Item creation.',
      false,
      true
    );
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//Update Merchandise Item
export const updateMerchandiseItem = async (
  merchandiseItemId,
  values,
  setLoading,
  setAlert,
  setReloadMasterItems,
  setOpen = () => {}
) => {
  try {
    setLoading(true);
    setOpen(false);
    const formData = new FormData();
    if (values.item_number) {
      formData.append('item_number', values.item_number);
    }
    if (values.item_desc) {
      formData.append('item_desc', values.item_desc.trim() || null);
    }
    if (values.item_type_id) {
      formData.append('item_type_id', values.item_type_id);
    }
    if (values.retail_price) {
      formData.append('retail_price', values.retail_price);
    }
    if (values.revenue_price) {
      formData.append('revenue_price', values.revenue_price);
    }
    if (values.cost_price) {
      formData.append('cost_price', values.cost_price);
    }
    if (values?.manufacturer)
      formData.append('manufacturer', values.manufacturer);
    if (values?.vendor) formData.append('vendor', values.vendor);

    if (!values.file && values.image_url)
      formData.append('image_url', values.image_url);
    formData.append(
      'inventory_managment',
      values?.inventory_managment || false
    );
    formData.append('image_type', 'upload');
    if (values.file) formData.append('file', values.file);
    formData.append('express_ship', values.express_ship);
    formData.append('exclude_from_commission', values.exclude_from_commission);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/system/items/${merchandiseItemId}`,
      formData,
      { headers: headers }
    );
    if (response) {
      if (response?.data?.error) {
        setAlert('error', response ? response?.data?.error : null, false, true);
        setReloadMasterItems(true);
      } else {
        setAlert(
          'success',
          'Merchandise Item updated successfully.',
          false,
          true
        );
        setReloadMasterItems(true);
      }
      return response;
    }
  } catch (error) {
    setAlert(
      'error',
      error
        ? error?.response?.data?.error
        : 'Error in updating Merchandise Item.',
      false,
      true
    );
    setLoading(false);
    return error;
  }
};

// Get Manufacturer Vendor options
export const getManufacturerVendorOptions = async (
  setManufacturerOptions,
  setVendorOptions,
  value
) => {
  let queryString = value ? `limit=10&query=${value}` : 'limit=10';
  try {
    const manufacturerRequest = ApiService.get(
      `/system/items/meta-list/manufacturer?${queryString}`
    );
    const vendorRequest = ApiService.get(
      `/system/items/meta-list/vendor?${queryString}`
    );
    const responses = await Promise.all([manufacturerRequest, vendorRequest]);
    setManufacturerOptions(responses[0]);
    setVendorOptions(responses[1]);
  } catch (error) {
    console.log(error);
  }
};

// Get Manufacturer options
export const getManufacturerOptions = async value => {
  let queryString = value ? `limit=10&query=${value}` : 'limit=10';
  try {
    const manufacturerRequest = await ApiService.get(
      `/system/items/meta-list/manufacturer?${queryString}`
    );
    return manufacturerRequest;
  } catch (error) {
    console.log(error);
  }
};

// Get Vendor options
export const getVendorOptions = async value => {
  let queryString = value ? `limit=10&query=${value}` : 'limit=10';
  try {
    const vendorRequest = await ApiService.get(
      `/system/items/meta-list/vendor?${queryString}`
    );
    return vendorRequest;
  } catch (error) {
    console.log(error);
  }
};
//Add new Manufacturer and Vendor
export const addNewManufacturerVendor = async value => {
  try {
    const response = await ApiService.post(`/system/items/meta/create`, value);
    return response;
  } catch (error) {
    console.log('error', error);
  }
};
