import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';

//helpers
import { Button } from 'primereact/button';

import {
  floatParser,
  formatDateTimeMDYHM,
  formatNumber,
  getConvertedDate,
  getUserTimeZone,
  showFirst30Characters,
} from '../../../utils/Helpers';
import PFButton from '../../shared/PFPrime/PFButton';
import { setProjectInfo } from '../Inventory/reducers/ProductDetailsSlice';
import Inventory from '../Inventory/Inventory.view';

import ItemUserNoteDialog from './ItemUserNoteDialog.js';
import EditProjectMerchandiseItemDialog from './EditProjectMerchandiseItemDialog';

export default function ProductItemCardView(props) {
  const projectId = props.projectId;
  const projectNumber = props.projectNumber;
  const [openedTimes, setOpenedTimes] = useState(1);
  const itemOptionsMenuRef = useRef(null);
  const [timeZone, setTimeZone] = useState(null);
  const [isItemNoteDialogVisible, setIsItemNoteDialogVisible] = useState(false);

  const [selectAll, setSelectAll] = useState({
    reqFrom: 'self',
    value: false,
  });
  const viewAllMerchandiseCost = props.viewAllMerchandiseCost
    ? props.viewAllMerchandiseCost
    : false;
  const merchandiseItems = props.merchandiseItems
    ? props.merchandiseItems
    : false;
  const tabProjectItemsMerchandiseModify =
    props.tabProjectItemsMerchandiseModify;
  const tabProjectItemsMerchandiseDelete =
    props.tabProjectItemsMerchandiseDelete;
  const isSalesCommissionOpted = !!props.isSalesCommissionOpted;
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    !inventoryOpen && props?.setUpdateMerchandiseItems(true);
  }, [inventoryOpen]);
  //Component

  useEffect(() => {
    if (selectAll?.reqFrom === 'self' && merchandiseItems?.length > 0) {
      merchandiseItems.map(rowData => {
        const invoiceGenerated = props?.projectInvoiceItems.find(
          item =>
            item.project_item_id === rowData.project_item_id &&
            item?.invoice?.status !== 'Cancelled'
        );
        !invoiceGenerated &&
          props?.handleInvoiceItems(rowData, selectAll?.value);
      });
    }
  }, [selectAll?.value, merchandiseItems]);

  useEffect(() => {
    setTimeZone(getUserTimeZone());
  }, []);

  const itemMenuOptions = [
    ...(tabProjectItemsMerchandiseModify
      ? [
          {
            label: 'Edit',
            icon: 'pi pi-pencil',
            command: () => {
              props?.setIsEditProjectMerchandiseItemDialogVisible(true);
            },
          },
        ]
      : []),
    ...(tabProjectItemsMerchandiseDelete
      ? [
          {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
              props?.setIsDeleteMerchandiseItemVisible(true);
            },
          },
        ]
      : []),
  ];

  const optionsButtonTemplate = rowData => {
    return (
      <>
        <div className="flex align-items-center">
          <PFButton
            rounded
            text
            icon="pi pi-clone"
            tooltip="Clone"
            onClick={() => {
              props?.setSelectedItem(rowData);
              props?.setCloneItem(true);
              props?.setIsAssignMerchandiseDialogOpen(true);
              props.setIsFromClone(true);
            }}
            pt={{
              icon: {
                className: 'text-color-secondary test-md text-lg',
              },
            }}
            className="border-none focus:shadow-none bg-transparent"
          ></PFButton>
          <PFButton
            type="button"
            icon="pi pi-ellipsis-v"
            tooltipOptions={{ position: 'bottom' }}
            onClick={e => {
              props?.setSelectedItem(rowData);
              itemOptionsMenuRef.current.toggle(e);
            }}
            pt={{
              icon: {
                className: 'text-color',
              },
            }}
            className="border-none focus:shadow-none bg-transparent"
            text
            rounded
            size="small"
          />

          <i
            data-pr-tooltip={` ${
              userMetaTemplate(rowData) === null
                ? ''
                : `${userMetaTemplate(rowData)}`
            } ${
              modifiedAtDateTemplate(rowData) === null
                ? ''
                : `${modifiedAtDateTemplate(rowData)}`
            } `}
            data-pr-position="right"
            data-pr-my="left center-2"
            className="pi pi-exclamation-circle modifued-by mr-3"
          ></i>
          <Tooltip target=".modifued-by" className="text-capitalize" />
        </div>
      </>
    );
  };

  const itemTemplate = rowData => {
    if (rowData?.item?.item_number) {
      return rowData?.item?.item_number;
    } else {
      return '-';
    }
  };

  const itemDescriptionTemplate = rowData => {
    if (rowData?.item?.item_desc) {
      return rowData?.item?.item_desc;
    } else {
      return '-';
    }
  };

  const quantityTemplate = rowData => {
    if (rowData?.item_quantity) {
      return rowData?.item_quantity;
    } else {
      return '-';
    }
  };

  const totalCostTemplate = rowData => {
    if (rowData?.edited_labor_cost_ea) {
      return parseFloat(rowData?.edited_labor_cost_ea * rowData?.item_quantity);
    } else {
      return 0;
    }
  };

  const displayTotalCostTemplate = rowData => {
    const value = totalCostTemplate(rowData);
    return value ? `$${value.toFixed(2)}` : '-';
  };

  const totalRevenueTemplate = rowData => {
    if (rowData?.cost_ea && +rowData.cost_ea) {
      return parseFloat(rowData.cost_ea * rowData.item_quantity);
    } else {
      return 0;
    }
  };
  const displayTotalRevenueTemplate = rowData => {
    const value = totalRevenueTemplate(rowData);
    return value ? `$${value.toFixed(2)}` : '-';
  };

  const totalRetailTemplate = rowData => {
    if (rowData?.sell_price && +rowData?.sell_price) {
      return parseFloat(rowData?.sell_price * rowData?.item_quantity);
    } else {
      return 0;
    }
  };
  const displayTotalRetailTemplate = rowData => {
    const value = totalRetailTemplate(rowData);
    return value ? `$${value.toFixed(2)}` : '-';
  };

  const totalProfitTemplate = rowData => {
    let totalRevenue = totalRevenueTemplate(rowData);
    let totalCost = totalCostTemplate(rowData);
    if (!totalRevenue) totalRevenue = 0;
    if (!totalCost) totalCost = 0;
    const totalProfit = totalRevenue - totalCost;
    if (totalProfit < 0) {
      return `-$${formatNumber(Math.abs(totalProfit.toFixed(2)), 2, true)}`;
    } else {
      return totalProfit ? `$${formatNumber(totalProfit, 2, true)}` : '-';
    }
  };

  const marginTemplate = rowData => {
    if (rowData?.margin) {
      return `${formatNumber(rowData.margin)}%`;
    } else {
      return '-';
    }
  };

  const totalSalesCommissionTemplate = rowData => {
    if (rowData?.sales_commission && +rowData.sales_commission) {
      return parseFloat(rowData.sales_commission);
    } else {
      return 0;
    }
  };
  const displaySalesCommissionTemplate = rowData => {
    const value = totalSalesCommissionTemplate(rowData);
    return value ? `$${value.toFixed(2)}` : '-';
  };

  const modifiedAtDateTemplate = rowData => {
    if (rowData?.modified_at) {
      return formatDateTimeMDYHM(rowData?.modified_at);
    } else {
      return '-';
    }
  };

  const userMetaTemplate = rowData => {
    if (typeof rowData?.user_meta === 'undefined') {
      return null;
    } else {
      const first_name = rowData?.user_meta?.first_name;
      const last_name = rowData?.user_meta?.last_name;
      let name = '';
      if (first_name && first_name?.trim().length > 0) {
        name = first_name;
      }
      if (last_name && last_name?.trim().length > 0) {
        name = `${name} ${last_name}`;
      }
      if (name.length > 0) return name;
      else return null;
    }
  };

  const deliveryTypeTemplate = (rowData, column) => {
    if (rowData?.delivery_type) {
      return rowData?.delivery_type;
    } else {
      return '-';
    }
  };

  let invoiceNumber = null;

  const invoiceNumberDisplay = rowData => {
    let itemExistsInInvoice = props?.invoiceItems.some(
      item => item.project_item_id === rowData.project_item_id
    );
    const invoiceGenerated = props?.projectInvoiceItems?.find(
      item =>
        item.project_item_id === rowData.project_item_id &&
        item?.invoice?.status !== 'Cancelled'
    );
    if (invoiceGenerated) {
      props?.setNonInvoicedItems(true);
      itemExistsInInvoice = true;
      invoiceNumber = {
        project_item_id: invoiceGenerated?.project_item_id,
        no: invoiceGenerated?.invoice?.invoice_no,
        id: invoiceGenerated?.invoice?.invoice_id,
        created_at: invoiceGenerated?.invoice?.created_at,
        status: invoiceGenerated?.invoice?.status,
      };
    } else {
      invoiceNumber = null;
      props?.setNonInvoicedItems(false);
    }
    return true;
  };

  const invoiceCheckbox = rowData => {
    let itemExistsInInvoice = props?.invoiceItems.some(
      item => item.project_item_id === rowData.project_item_id
    );

    const invoiceGenerated = props?.projectInvoiceItems?.find(
      item =>
        item.project_item_id === rowData.project_item_id &&
        item?.invoice?.status !== 'Cancelled'
    );

    if (invoiceGenerated) {
      props?.setNonInvoicedItems(true);
      itemExistsInInvoice = true;
      invoiceNumber = {
        no: invoiceGenerated?.invoice?.invoice_no,
        id: invoiceGenerated?.invoice?.invoice_id,
        created_at: invoiceGenerated?.invoice?.created_at,
        status: invoiceGenerated?.invoice?.status,
      };
    } else {
      invoiceNumber = null;
      props?.setNonInvoicedItems(false);
    }

    return (
      <>
        {props?.CRMEnabled && !props?.isMeasureSquareEnabled && (
          <>
            {!invoiceGenerated && (
              <Checkbox
                onChange={e => {
                  props?.handleInvoiceItems(rowData, e.checked);
                  e.checked === false &&
                    setSelectAll(preState => ({
                      reqFrom: 'indirect',
                      value: e.checked,
                    }));
                }}
                checked={itemExistsInInvoice || false}
                disabled={invoiceGenerated ? true : false}
                style={invoiceGenerated && { checkboxStyle }}
              ></Checkbox>
            )}
          </>
        )}
      </>
    );
  };

  const handleInventory = () => {
    if (selectedItem?.item?.inventory_managment) {
      let item = selectedItem?.item;
      dispatch(
        setProjectInfo({
          projectId: projectId || null,
          projectNumber: projectNumber || null,
          projectNotes: selectedItem?.note_text || null,
          itemId: selectedItem?.item_id || null,
        })
      );
      item['projectId'] = projectId || null;
      item['projectNumber'] = projectNumber || null;

      if (item) {
        setInventoryOpen(true);
        setOpenedTimes(openedTimes + 1);
      }
    }
  };

  const noteTemplate = rowData => {
    if (rowData?.project_item_id && rowData?.project_item_id > 0) {
      return (
        <>
          <Button
            icon="pi pi-file-edit"
            type="button"
            rounded
            size="small"
            text
            className="bg-transparent focus:shadow-none paid-review text-green-600"
            aria-label="Update Note"
            onClick={() => {
              setSelectedItem(rowData);
              setIsItemNoteDialogVisible(true);
            }}
            pt={{
              icon: {
                className: `${rowData?.note_text?.length > 0 ? 'text-green-600' : 'text-primary'}`,
              },
            }}
            data-pr-my="left center-2"
            tooltip={
              rowData?.note_text?.length > 0 ? rowData?.note_text : 'Add Note'
            }
            tooltipOptions={{
              position: 'left',
              mouseTrack: true,
              mouseTrackTop: 0,
              className: `${rowData?.note_text?.length > 0 ? 'w-15rem md:w-26rem' : ''}`,
            }}
          />
        </>
      );
    }
  };

  const onItemUserNoteDialogHide = projectItem => {
    setSelectedItem(null);
    setIsItemNoteDialogVisible(false);
  };

  return (
    <>
      {isItemNoteDialogVisible && selectedItem && (
        <ItemUserNoteDialog
          visible={isItemNoteDialogVisible}
          onHide={onItemUserNoteDialogHide}
          selectedItem={selectedItem}
          projectId={projectId}
        ></ItemUserNoteDialog>
      )}
      <Menu
        model={itemMenuOptions}
        popup
        ref={itemOptionsMenuRef}
        pt={{ action: { className: 'py-2 text-sm' } }}
      />
      <div
        className={`overflow-auto ${merchandiseItems?.length > 3 ? 'max-h-25rem' : ''}`}
      >
        {merchandiseItems?.length === 0
          ? 'No results found.'
          : merchandiseItems?.length > 0 &&
            merchandiseItems?.map((rowData, index) => (
              <div
                key={index}
                className="py-2 border-bottom-1 border-200 minWidth-970"
              >
                <div className="grid grid-nogutter align-items-center">
                  {optionsButtonTemplate(rowData)}
                  <div className="col py-0 pr-1 pl-2 text-xs">
                    <small className="text-gray-600">Item#:</small>
                    <h4 className="text-primary my-1 text-xs line-height-1 text-break-anywhere">
                      {itemTemplate(rowData)}
                    </h4>
                    <small className="text-gray-600">Desc:</small>
                    {rowData?.item?.item_desc?.length > 30 ? (
                      <PFButton
                        label={
                          rowData?.item?.item_desc.length > 30
                            ? showFirst30Characters(rowData?.item?.item_desc)
                            : rowData?.item?.item_desc
                        }
                        type="button"
                        size="small"
                        text
                        className="bg-transparent focus:shadow-none p-0 text-left text-xs border-none"
                        data-pr-my="left center-2"
                        tooltip={rowData?.item?.item_desc}
                        tooltipOptions={{
                          position: 'right',
                          mouseTrack: true,
                          mouseTrackTop: 0,
                          className: 'w-15rem md:w-26rem',
                        }}
                        pt={{
                          label: {
                            className: 'line-height-1 font-bold word-break',
                          },
                        }}
                      />
                    ) : (
                      <p className="text-primary my-0 word-break text-xs line-height-1 font-bold word-break">
                        {rowData?.item?.item_desc}
                      </p>
                    )}
                  </div>
                  <div className="col-9 align-items-center flex border-left-2 border-200">
                    <div className="grid grid-nogutter w-12 my-0 flex justify-content-between flex-nowrap">
                      <div className="col py-0  pr-1 text-xs pl-2">
                        <small className="text-gray-600">Quantity:</small>
                        <h4 className="mb-1 mt-0 text-xs text-blue-900">
                          {quantityTemplate(rowData)}
                          {rowData?.original_qty ? (
                            <>
                              <i
                                className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                data-pr-tooltip={
                                  rowData?.original_qty === null
                                    ? '0'
                                    : rowData?.original_qty
                                }
                                data-pr-position="right"
                                data-pr-at="right+5 top"
                                data-pr-my="left center-2"
                                style={{
                                  fontSize: '0.8rem',
                                  cursor: 'pointer',
                                  marginLeft: '5px',
                                }}
                              ></i>
                              <Tooltip target=".custom-target-icon" />
                            </>
                          ) : null}
                        </h4>
                        <small className="text-gray-600">Delivery Type:</small>
                        <h4 className="mb-1 mt-0 text-xs text-blue-900">
                          {deliveryTypeTemplate(rowData)}
                        </h4>
                      </div>
                      <div className="col py-0  pr-1 text-xs pl-2">
                        <small className="text-gray-600">Vendor:</small>
                        <h4 className="mb-1 mt-0 text-xs text-blue-900">
                          {rowData?.item?.meta_type_vendor?.value || '-'}
                        </h4>
                        <small className="text-gray-600">Manufacturer:</small>
                        <h4 className="mb-1 mt-0 text-xs text-blue-900">
                          {rowData?.item?.meta_type_manufacturer?.value || '-'}
                        </h4>
                      </div>
                      {viewAllMerchandiseCost ? (
                        <div className="col py-0  pr-1 text-xs pl-2">
                          <small className="text-gray-600">Revenue $:</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {displayTotalRevenueTemplate(rowData)}
                          </h4>
                          <small className="text-gray-600">Cost $:</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {displayTotalCostTemplate(rowData)}
                          </h4>
                        </div>
                      ) : null}

                      {viewAllMerchandiseCost ? (
                        <div className="col py-0  pr-1 text-xs pl-2">
                          <small className="text-gray-600">Profit $:</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {totalProfitTemplate(rowData)}
                          </h4>
                          <small className="text-gray-600">Margin%</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {marginTemplate(rowData)}
                          </h4>
                        </div>
                      ) : null}
                      <div className="col py-0  pr-1 text-xs pl-2">
                        {viewAllMerchandiseCost ? (
                          <>
                            <small className="text-gray-600">Retail $:</small>
                            <h4 className="mb-1 mt-0 text-xs text-blue-900">
                              {displayTotalRetailTemplate(rowData)}
                            </h4>
                          </>
                        ) : null}
                        {isSalesCommissionOpted && (
                          <>
                            <small className="text-gray-600">
                              Sales Commission $
                            </small>
                            <h4 className="mb-1 mt-0 text-xs text-blue-900">
                              {displaySalesCommissionTemplate(rowData)}
                            </h4>
                          </>
                        )}
                      </div>

                      <div>{invoiceNumberDisplay(rowData)}</div>
                      {props?.CRMEnabled && !props?.isMeasureSquareEnabled ? (
                        <div className="col py-0  pr-1 text-xs pl-2">
                          <small className="text-gray-600">Invoice #</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {invoiceNumber ? (
                              <div className="flex align-items-center">
                                <i className="pi pi-check-circle mr-1 text-green-600 text-xs"></i>
                                <Link
                                  to={`/crm/invoice/view/${invoiceNumber?.id}`}
                                  className="no-underline text-primary text-xs"
                                >
                                  {invoiceNumber?.no || ''}
                                </Link>
                              </div>
                            ) : (
                              '-'
                            )}
                          </h4>
                        </div>
                      ) : null}

                      {props?.CRMEnabled && !props?.isMeasureSquareEnabled ? (
                        <div className="col py-0  pr-1 text-xs pl-2">
                          <small className="text-gray-600">Invoice Date:</small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {invoiceNumber?.created_at
                              ? moment(invoiceNumber?.created_at).format(
                                  'MM-DD-YYYY'
                                )
                              : '-'}
                          </h4>
                          <small className="text-gray-600">
                            Invoice Status:
                          </small>
                          <h4 className="mb-1 mt-0 text-xs text-blue-900">
                            {invoiceNumber?.status || '-'}
                          </h4>
                        </div>
                      ) : null}

                      {props?.CRMEnabled &&
                      !props?.isMeasureSquareEnabled &&
                      props?.quoteNumber ? (
                        <>
                          <div className="col py-0  pr-1 text-xs pl-2">
                            <small className="text-gray-600">Discount</small>
                            <h4 className="mb-1 mt-0 text-xs text-blue-900">
                              {rowData?.discount_type === 'Amount'
                                ? `$${floatParser(rowData?.discount)}`
                                : `${floatParser(rowData?.discount)}%`}
                            </h4>
                            <small className="text-gray-600">Tax</small>
                            <h4 className="mb-1 mt-0 text-xs text-blue-900">
                              {`${floatParser(rowData?.tax)}%`}
                            </h4>
                          </div>
                          <div className="col py-0  pr-1 text-xs pl-2">
                            <small className="text-gray-600">Net Cost</small>
                            <h4 className="mb-1 mt-0 text-xs text-blue-900">
                              {`$${floatParser(rowData?.net_cost)}`}
                            </h4>
                          </div>
                        </>
                      ) : null}
                      {(rowData?.item_po || rowData?.expected_arrival_date) && (
                        <div className="col py-0  pr-1 text-xs pl-2">
                          {rowData?.item_po && (
                            <>
                              <small className="text-gray-600">
                                Item PO# :
                              </small>
                              <h4 className="mb-0 mt-1 text-xs text-blue-900">
                                {rowData?.item_po}
                              </h4>
                            </>
                          )}
                          {rowData?.expected_arrival_date && (
                            <>
                              <small className="text-gray-600">
                                Expected Arrival Date:
                              </small>
                              <h4 className="mb-0 mt-1 text-xs text-blue-900">
                                {rowData?.expected_arrival_date
                                  ? moment(
                                      rowData?.expected_arrival_date
                                    )?.format('MM-DD-YYYY')
                                  : ''}
                              </h4>
                            </>
                          )}
                        </div>
                      )}
                      <div className="col py-0 px-1 text-xs">
                        <div className="grid w-full">
                          <div>
                            {rowData?.masterWarehouse &&
                              rowData?.masterWarehouse?.name && (
                                <div className="col py-0 px-1 text-xs mt-2">
                                  <small className="text-gray-600">
                                    Warehouse:
                                  </small>
                                  <h4 className="mb-0 mt-1 text-xs text-blue-900">
                                    {rowData?.masterWarehouse?.name}
                                  </h4>
                                </div>
                              )}
                          </div>
                          <div>
                            {rowData?.inventory_bin &&
                              rowData?.inventory_bin?.code && (
                                <div className="col py-0 px-1 text-xs">
                                  <small className="text-gray-600">Bin:</small>
                                  <h4 className="mb-0 mt-1 text-xs text-blue-900">
                                    {rowData?.inventory_bin?.code}-
                                    {rowData?.inventory_bin?.name}
                                  </h4>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2rem">{invoiceCheckbox(rowData)}</div>
                  </div>

                  <div className="col-1 px-3">
                    <div className="p-1">{noteTemplate(rowData)}</div>
                    <div>
                      {rowData?.item?.inventory_managment && (
                        <Button
                          text
                          type="button"
                          tooltip="Inventory Management"
                          tooltipOptions={{
                            position: 'left',
                            mouseTrack: true,
                            mouseTrackTop: 15,
                          }}
                          rounded
                          onClick={() => {
                            setSelectedItem(rowData);
                            handleInventory();
                          }}
                          outlined
                          severity="primary"
                          className="border-none w-1rem p-0 focus:shadow-none px-4"
                          size="small"
                          icon="pi pi-box"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>
      <>
        {inventoryOpen && (
          <Inventory
            className="ml-1"
            inventoryOpen={inventoryOpen}
            setInventoryOpen={setInventoryOpen}
            inventoryItem={selectedItem?.item ? selectedItem?.item : null}
          />
        )}
      </>
    </>
  );
}
