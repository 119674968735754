//imports
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Field, Form, Formik, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';

//Service
import { updateLaborItem } from './ProjectItemsService';

/* Components */
import PFFormikError from './PFFormikError';

export default function ItemUserNoteDialog(props) {
  //variables
  const projectId = props.projectId;
  const selectedItem = props.selectedItem;
  const toast = useRef(null);
  const projectItem = useRef(null);

  const [itemNum, setItemNum] = useState(null);
  const [itemDesc, setItemDesc] = useState(null);
  const [installerName, setInstallerName] = useState(null);

  if (
    props.selectedItem?.project_item_id &&
    props.selectedItem?.project_item_id > 0
  ) {
    projectItem.current = props.selectedItem;
  }
  const isUpdate = projectItem?.current?.note_text?.trim().length > 0;
  //mount
  useEffect(() => {
    if (projectItem.current?.project_item_id > 0) {
      setItemNum(
        projectItem.current?.item?.item_number
          ? projectItem.current?.item?.item_number
          : ''
      );
      setItemDesc(
        projectItem.current?.item?.item_desc
          ? projectItem.current?.item?.item_desc
          : ''
      );

      formik.setFieldValue(
        'noteVal',
        projectItem.current?.note_text &&
          projectItem.current?.note_text?.length > 0
          ? projectItem.current?.note_text
          : ''
      );
    }
  }, [projectItem.current, selectedItem]);

  const formik = useFormik({
    initialValues: {
      noteVal: '',
    },
    onSubmit: async values => {
      const updateResp = await updateLaborItem(
        projectItem.current?.project_item_id,
        projectId,
        {
          note_text: values.noteVal,
        }
      );
      if (updateResp?.data?.status && updateResp.data.status === true) {
        setAlert(
          'success',
          `Note ${isUpdate ? 'Updated' : 'Added'} Successfully`
        );
        projectItem.current.note_text = values.noteVal;
        handleClose(projectItem.current);
      } else {
        setAlert('error', 'Unable To Update Status');
      }
    },
  });

  //methods
  const handleClose = (projectItem = null, timeout = 0) => {
    if (timeout > 0) {
      setTimeout(() => {
        props.onHide(projectItem);
      }, timeout);
    } else {
      props.onHide(projectItem);
    }
  };

  const setAlert = (severityValue, summaryValue, life = 1500) => {
    toast.current.show({
      severity: severityValue,
      summary: `${summaryValue}`,
      life,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={isUpdate ? 'Update Note' : 'Add Note'}
        visible={props.visible}
        onHide={handleClose}
        className="w-5"
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-1' },
        }}
      >
        <div className="flex flex-column mt-2">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-1"
          >
            <div className="grid">
              <div className="col-6">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    className="p-inputtext-sm max-w-full"
                    id="itemId"
                    value={itemNum}
                    readOnly
                    pt={{
                      root: { className: 'w-full' },
                    }}
                  />
                  <label htmlFor="itemId">Item Number</label>
                </span>
              </div>

              <div className="col-6">
                <span className="p-float-label ">
                  <InputText
                    type="text"
                    className="p-inputtext-sm max-w-full"
                    id="itemDescription"
                    value={itemDesc}
                    readOnly
                    pt={{
                      root: { className: 'w-full' },
                    }}
                  />
                  <label htmlFor="itemDescription">Description</label>
                </span>
              </div>
            </div>
            <div className="flex flex-column mt-1">
              <InputTextarea
                value={formik.values.noteVal}
                onChange={e => formik.setFieldValue('noteVal', e.target.value)}
                rows={5}
                className="w-full"
                placeholder="The maximum limit is 500 characters."
                maxLength={500}
              ></InputTextarea>
            </div>

            <div className="flex ml-auto mt-2">
              <Button
                label="Cancel"
                onClick={() => handleClose(null, 0)}
                size="small"
                outlined
                type="button"
              ></Button>
              <Button
                label="Save"
                size="small"
                className="ml-2"
                disabled={formik.isSubmitting}
              ></Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}
