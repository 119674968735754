import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import { getNotesTypeOptions } from '../Notes/Notes.service';
import PFFormikError from '../ProjectItemsNewUI/PFFormikError';
import PFCalendar from '../../shared/PFPrime/PFCalendar';
import { QUICK_ACTION_TYPE } from '../../../constants';
import PFDialog from '../../shared/PFPrime/PFDialog';

import { groupTypes } from './ProjectDetails.service';

const ActionNoteDialog = props => {
  const {
    bustedJobDialogVisible,
    setBustedJobDialogVisible,
    projectInstaller,
    projectId,
    notesFormik,
  } = props;
  const [reasonOptions, setReasonOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = notesFormik();
  const bustedReason = async () => {
    try {
      const response = await groupTypes(QUICK_ACTION_TYPE.BUSTED_JOB);
      if (response?.status && response?.data) {
        setReasonOptions(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    bustedReason();
    getNotesTypeOptions(setTypeOptions);
  }, []);

  useEffect(() => {
    if (props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB) {
      setFieldValue('busted_installer_id', projectInstaller?.installer_id);
      setFieldValue('busted_user_id', projectInstaller?.user_id);
    }
  }, [projectInstaller?.installer_id, bustedJobDialogVisible]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const BodyComponent = () => (
    <>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid pt-2">
          {props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB && (
            <>
              <div className="col-12">
                <span className="p-float-label">
                  <PFInputText
                    value={projectInstaller?.installer_name}
                    disabled={true}
                  />
                  <label htmlFor="busted-technician">Busted Technician</label>
                </span>
              </div>
              <div className="col-12">
                <span className="p-float-label">
                  <PFDropdown
                    optionLabel="label"
                    optionValue="type_id"
                    options={reasonOptions}
                    value={values?.busted_reason_id || ''}
                    name="busted_reason_id"
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <label htmlFor="busted-reason">Busted Reason</label>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="busted_reason_id"
                  />
                </span>
              </div>
            </>
          )}

          <div className="col-12">
            <span className="p-float-label">
              <PFInputText
                name="note_text"
                onChange={handleChange}
                value={values?.note_text || ''}
              />
              <label htmlFor="note">
                Note <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
            <PFFormikError
              touched={touched}
              errors={errors}
              field="note_text"
            />
          </div>
          {props?.quickActionReq === QUICK_ACTION_TYPE.READY_TO_SCHEDULE && (
            <div className="col-12">
              <PFCalendar
                appendTo={null}
                minDate={new Date()}
                label="Follow Up Date"
                placeholder="Follow Up Date"
                name="follow_up_date"
                onChange={e => setFieldValue(e.target.name, e.target.value)}
                value={values?.follow_up_date || ''}
              />
            </div>
          )}

          <div className="col-12 flex justify-content-end flex-wrap">
            <PFButton label="Save" onClick={handleSubmit} />
          </div>
        </div>
      </form>
    </>
  );
  return (
    <PFDialog
      header={`${
        props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB
          ? QUICK_ACTION_TYPE.BUSTED_TITLE
          : QUICK_ACTION_TYPE.NOTES_TITLE
      }`}
      visible={bustedJobDialogVisible}
      className="w-3"
      onHide={() => {
        if (!bustedJobDialogVisible) return;
        setBustedJobDialogVisible(false);
        resetForm();
      }}
      pt={{
        root: {
          className: 'border-noround',
        },
      }}
      BodyComponent={BodyComponent()}
    ></PFDialog>
  );
};

export default ActionNoteDialog;
