import { InputText } from 'primereact/inputtext';
export default function PFInputText({
  id,
  type,
  value,
  onChange,
  placeholder,
  name,
  className,
  disabled,
}) {
  return (
    <InputText
      id={id || ''}
      type={type || 'search'}
      value={value || ''}
      name={name || ''}
      onChange={onChange}
      placeholder={placeholder || ''}
      className={`w-full p-inputtext-sm ${className || ''}`}
      disabled={disabled || false}
      onKeyDown={onChange}
    />
  );
}
