//Common Imports
import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

//Custom Style
import './project-item.css';

//Service
import { Column } from 'primereact/column';

import AddEditMerchandiseItemDialog from '../Items/AddEditMerchandiseItemDialog';
import { getUserTimeZone } from '../../../utils/Helpers';
import { getMerchandiseItemsList } from '../Items/Items.service';

import { getMerchandiseItems } from './ProjectItemsService';

//Components

import AddProjectMerchandiseItemDialog from './AddProjectMerchandiseItemDialog';
import DeleteProjectItemDialog from './DeleteProjectItemDialog';
import EditProjectMerchandiseItemDialog from './EditProjectMerchandiseItemDialog';

//helpers

import ProductItemCardView from './ProductItemCardView';

export default function ProjectMerchandiseItemsGrid(props) {
  //console.log('props', props);

  //permissions
  const viewAllMerchandiseCost = props.viewAllMerchandiseCost
    ? props.viewAllMerchandiseCost
    : false;
  const addAllMerchandiseCost = props.addAllMerchandiseCost
    ? props.addAllMerchandiseCost
    : false;
  const editAllMerchandiseCost = props.editAllMerchandiseCost
    ? props.editAllMerchandiseCost
    : false;
  const tabProjectItemsMerchandiseAdd = props.tabProjectItemsMerchandiseAdd
    ? props.tabProjectItemsMerchandiseAdd
    : false;
  const tabProjectItemsMerchandiseModify =
    props.tabProjectItemsMerchandiseModify
      ? props.tabProjectItemsMerchandiseModify
      : false;
  const tabProjectItemsMerchandiseDelete =
    props.tabProjectItemsMerchandiseDelete
      ? props.tabProjectItemsMerchandiseDelete
      : false;

  const projectInvoiceItems = props?.projectInvoiceItems;
  const handleInvoiceItems = props?.handleInvoiceItems;

  //States and Other Variables
  const projectId = props.projectId;
  const projectNumber = props.projectNumber;
  const [updateMerchandiseItems, setUpdateMerchandiseItems] = useState(true);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const [isAddMerchandiseDialogOpen, setIsAddMerchandiseDialogOpen] =
    useState(false);
  const [isAssignMerchandiseDialogOpen, setIsAssignMerchandiseDialogOpen] =
    useState(false);
  const [isDeleteMerchandiseItemVisible, setIsDeleteMerchandiseItemVisible] =
    useState(false);
  const [
    isEditProjectMerchandiseItemDialogVisible,
    setIsEditProjectMerchandiseItemDialogVisible,
  ] = useState(false);
  const [loadSystemItems, setLoadSystemItems] = useState(true);
  const [systemItems, setSystemItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const toast = useRef(null);

  const menuRef = useRef(null);
  const itemOptionsMenuRef = useRef(null);
  const [selectAll, setSelectAll] = useState({
    reqFrom: 'self',
    value: false,
  });
  const [nonInvoicedItems, setNonInvoicedItems] = useState(true);
  const [cloneItem, setCloneItem] = useState(false);
  const [isFromClone, setIsFromClone] = useState(false);

  useEffect(() => {
    if (selectAll?.reqFrom === 'self' && merchandiseItems?.length > 0) {
      merchandiseItems.map(rowData => {
        const invoiceGenerated = props?.projectInvoiceItems.find(
          item =>
            item.project_item_id === rowData.project_item_id &&
            item?.invoice?.status !== 'Cancelled'
        );
        !invoiceGenerated &&
          props?.handleInvoiceItems(rowData, selectAll?.value);
      });
    }
  }, [selectAll?.value, merchandiseItems]);

  const checkboxStyle = {
    borderColor: '#31b55c',
    background: '#31b55c',
  };

  const merchandiseMenuOptions = [
    {
      label: 'Add Product Item',
      icon: 'pi pi-user-plus',
      command: () => {
        setIsAddMerchandiseDialogOpen(true);
      },
    },
    ...(tabProjectItemsMerchandiseAdd
      ? [
          {
            label: 'Assign Product Item',
            icon: 'pi pi-users',
            command: () => {
              setCloneItem(false);
              setIsAssignMerchandiseDialogOpen(true);
            },
          },
        ]
      : []),
  ];

  //Mount
  useEffect(() => {
    if (updateMerchandiseItems) {
      getMerchandiseItems(projectId)
        .then(res => {
          setMerchandiseItems(
            res?.data?.status && res?.data?.status === true ? res.data.data : []
          );
        })
        .catch(err => {
          setMerchandiseItems([]);
        });
      setUpdateMerchandiseItems(false);
    }
  }, [updateMerchandiseItems]);

  useEffect(() => {
    if (props.updateMerchandiseItems) {
      setUpdateMerchandiseItems(true);
    }
  }, [props.updateMerchandiseItems]);

  useEffect(() => {
    if (loadSystemItems) {
      getMerchandiseItemsList(
        {},
        setLoadSystemItems,
        result => result?.count && setSystemItems(result?.items)
      );
    }
  }, [loadSystemItems]);

  if (merchandiseItems?.length >= 0) {
    props.updateParentMerchandiseItems(merchandiseItems);
  } else {
    props.updateParentMerchandiseItems([]);
  }

  useEffect(() => {
    setTimeZone(getUserTimeZone());
  }, []);

  //Methods
  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const onEditProjectMerchandiseItemDialogHide = projectItemEdited => {
    setSelectedItem(null);
    setIsEditProjectMerchandiseItemDialogVisible(false);
    if (projectItemEdited && projectItemEdited === true) {
      setUpdateMerchandiseItems(true);
    }
  };

  const onDeleteProjectItemDialog = projectItemDeleted => {
    setIsDeleteMerchandiseItemVisible(false);
    if (projectItemDeleted) {
      const merchandiseItemsList = [...merchandiseItems];
      const index = merchandiseItemsList.findIndex(
        item => item.project_item_id === selectedItem.project_item_id
      );
      if (index > -1) {
        merchandiseItemsList.splice(index, 1);
        setMerchandiseItems(merchandiseItemsList);
      }
    }
  };

  const onAddMerchandiseItemsDialogHide = systemItem => {
    if (systemItem?.item_id) {
      //refresh system items here
      setLoadSystemItems(true);
    }
    setIsAddMerchandiseDialogOpen(false);
  };

  const onAddProjectMerchandise = projectItemCreated => {
    setIsAssignMerchandiseDialogOpen(false);
    if (projectItemCreated) {
      setUpdateMerchandiseItems(true);
    }
  };

  //Templates
  const merchandiseCardHeaderTemplate = () => {
    return (
      <>
        <div className="flex justify-content-start align-content-center align-items-center">
          <h3>Product Items</h3>
          <div className="ml-auto align-items-center flex">
            {props?.CRMEnabled && !props?.isMeasureSquareEnabled ? (
              <>
                <Checkbox
                  onChange={e =>
                    setSelectAll(preState => ({
                      reqFrom: 'self',
                      value: !preState?.value,
                    }))
                  }
                  checked={selectAll?.value || false}
                  className="mr-2"
                  disabled={nonInvoicedItems}
                ></Checkbox>
                <label className="mr-3">Select All Invoice</label>
              </>
            ) : null}
            <Button
              type="button"
              onClick={event => menuRef.current.toggle(event)}
              icon="pi pi-plus"
              outlined
              label="Product Item"
              size="small"
            ></Button>
          </div>
          <div className="flex"></div>
        </div>
      </>
    );
  };

  let invoiceNumber = null;

  //Component
  return (
    <Card
      className="shadow-none"
      header={merchandiseCardHeaderTemplate}
      pt={{ body: { className: 'py-0 px-0' } }}
    >
      <Toast ref={toast} />
      <Menu
        model={merchandiseMenuOptions}
        popup
        ref={menuRef}
        pt={{ action: { className: 'py-2 text-sm' } }}
      />

      {isAddMerchandiseDialogOpen && (
        <AddEditMerchandiseItemDialog
          visible={isAddMerchandiseDialogOpen}
          setIsAddMerchandiseItemDialogVisible={setIsAddMerchandiseDialogOpen}
          itemId={null}
          itemsReload={setLoadSystemItems}
          setMerchandiseLoading={() => {}}
          action="add"
          rowData={null}
          showAlert={showAlert}
        ></AddEditMerchandiseItemDialog>
      )}

      {isAssignMerchandiseDialogOpen && (
        <AddProjectMerchandiseItemDialog
          visible={isAssignMerchandiseDialogOpen}
          projectId={projectId}
          onHide={onAddProjectMerchandise}
          itemsList={systemItems}
          addAllMerchandiseCost={addAllMerchandiseCost}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled || false}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
          selectedProjectItem={cloneItem && selectedItem}
          isFromClone={isFromClone}
          setIsFromClone={setIsFromClone}
        ></AddProjectMerchandiseItemDialog>
      )}

      {isDeleteMerchandiseItemVisible && selectedItem && (
        <DeleteProjectItemDialog
          visible={isDeleteMerchandiseItemVisible}
          projectId={projectId}
          selectedItem={selectedItem}
          onHide={onDeleteProjectItemDialog}
          showAlert={showAlert}
        ></DeleteProjectItemDialog>
      )}

      {isEditProjectMerchandiseItemDialogVisible && selectedItem && (
        <EditProjectMerchandiseItemDialog
          visible={isEditProjectMerchandiseItemDialogVisible}
          projectId={projectId}
          selectedProjectItem={selectedItem}
          onHide={onEditProjectMerchandiseItemDialogHide}
          itemsList={systemItems}
          editAllMerchandiseCost={editAllMerchandiseCost}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled || false}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled || false}
          warehouseList={props?.warehouseList || []}
        ></EditProjectMerchandiseItemDialog>
      )}

      <ProductItemCardView
        setUpdateMerchandiseItems={setUpdateMerchandiseItems}
        merchandiseItems={merchandiseItems}
        tabProjectItemsMerchandiseModify={tabProjectItemsMerchandiseModify}
        tabProjectItemsMerchandiseDelete={tabProjectItemsMerchandiseDelete}
        setIsEditProjectMerchandiseItemDialogVisible={
          setIsEditProjectMerchandiseItemDialogVisible
        }
        setIsDeleteMerchandiseItemVisible={setIsDeleteMerchandiseItemVisible}
        setSelectedItem={setSelectedItem}
        projectInvoiceItems={projectInvoiceItems}
        handleInvoiceItems={handleInvoiceItems}
        CRMEnabled={props?.CRMEnabled || false}
        invoiceItems={props?.invoiceItems}
        setNonInvoicedItems={setNonInvoicedItems}
        viewAllMerchandiseCost={viewAllMerchandiseCost}
        isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
        isSalesCommissionOpted={props?.isSalesCommissionOpted}
        projectId={projectId}
        projectNumber={projectNumber}
        setIsAssignMerchandiseDialogOpen={setIsAssignMerchandiseDialogOpen}
        setCloneItem={setCloneItem}
        setIsFromClone={setIsFromClone}
      />
    </Card>
  );
}
