function validate(e, r) {
  if (!e)
    return { status: !1, message: 'Error: Value is required for convertion.' };
  if (!Array.isArray(r))
    return { status: !1, message: 'Error: Second argument type of array.' };
  if (isNaN(e))
    return { status: !1, message: 'Error: Value must be a number type.' };
  let t = {
      name: 'string',
      parentId: 'number',
      unitId: 'number',
      isLowest: 'number',
      value: 'number',
    },
    n = r?.filter(e => 1 === e.isLowest).length;
  if (n > 1 || 0 === n)
    return { status: !1, message: 'Error: isLowest count should be only one.' };
  for (let s in t)
    for (let a of r) {
      if (!a.hasOwnProperty(s))
        return { status: !1, message: `Error: ${s} key missing in array.` };
      if (t[s] !== typeof a[s])
        return {
          status: !1,
          message: `Error: ${s} key type ${t[s]} is expected in array.`,
        };
      if (a?.parentId > 0) {
        let i = r?.find(e => e?.unitId === a?.parentId);
        if (void 0 === i)
          return {
            status: !1,
            message: 'Error: parentId not matched in unitId object in array.',
          };
      }
    }
  return { status: !0, message: 'Validate Successfully.' };
}

export default function unitConverter(value = 0, units = []) {
  let t = validate(value, units);
  if (!t.status) return t?.message;
  let sign = value < 0 ? '-' : '';
  value = value < 0 ? Math.abs(value) : value;
  let returnValue = [];
  let dividend = value;
  let unitsOrder = units.find(u => u.isLowest === 1);
  while (unitsOrder) {
    const divisor = unitsOrder?.value;
    const quotient = Math.floor(dividend / divisor);
    let remainder = dividend % divisor;
    unitsOrder.quotient = quotient;
    unitsOrder.remainder = remainder;
    returnValue = [...returnValue, unitsOrder];
    dividend = quotient;
    unitsOrder = units.find(u => u.unitId === unitsOrder.parentId);
  }
  let output = [];

  returnValue.reverse().map((item, i) => {
    if (i === 0) {
      if (item?.quotient > 0) {
        output = [...output, `${item?.quotient.toFixed(2)} ${item?.name}`];
      }
    } else {
      if (item?.remainder > 0) {
        output = [...output, `${item?.remainder.toFixed(2)} ${item?.name}`];
      }
    }
  });
  return `${sign} ${output.join(', ')}`;
}
