// **** React Imports ****
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

// **** External Utilities ****
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useParams } from 'react-router';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ContextMenu } from 'primereact/contextmenu';
import { Dialog, Message, Toast } from 'primereact';

import { getFilterDetails } from '../Widget/service/widget.service';
import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Styles/Images/Icons ****

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import { Alert } from '../../shared/Alerts/Alert';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { checkPermission, convertHexToRgb } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { setPageHeaderComponent } from '../../../redux/slices/page-header.slice';
import { PRIMARY_COLOR } from '../../../../src/constants';

import DashboardWidgetRenderer from './WidgetRenderer/DashboardWidgetRenderer';
import OverlayReport from './Widgets/OverlayReport';
import ConfigureDashboardWidget from './ConfigureDashboardWidget';
import WidgetsList from './WidgetsList';
import { useStyles } from './AddViewEditDashboard.styles';
import {
  getDashboardDetails,
  updateWidgets,
  deleteWidget,
} from './Dashboards.service';

const ResponsiveGridLayout = WidthProvider(Responsive);

const AddViewEditDashboard = ({
  type,
  dashboardId,
  setHomeDashboardLoading,
  homeDashboardLoading,
  setWidgetFilterQuery,
  applyFilters,
  widgetFilterQuery,
  setApplyFilters,
  clearFilters,
  setClearFilters,
  homeFilters,
  region,
  storeNumber,
  district,
  category,
  source,
  projectType,
  installers,
  homeFilterDateDimension,
  status,
  workroom,
  scheduleDate,
  completionDate,
  checkDate,
  setDisabledDashboard,
  refreshDashboardList,
  dateSold,
}) => {
  const { id } = useParams();
  const action = window.location.pathname.split('/')[3];
  const history = useHistory();
  const dispatch = useDispatch();
  const toastTopCenter = useRef(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  // Widgets query and data variables
  const [widgetQuery, setWidgetQuery] = React.useState([]);
  const [widgetRendererData, setWidgetRendererData] = React.useState([]);
  const [, setChartType] = React.useState();
  // Dashboard variable
  const [dashboardData, setDashboardData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  // To show the message
  const { alert, setAlert, clearAlert } = useAlerts();
  const [layout, setLayout] = React.useState([]);
  // Widget Conifgure and Delete Menu variables
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [originalFilterData, setOriginalFilterData] = React.useState();
  const [deleteWidgetId, setDeleteWidgetId] = React.useState(null);
  const [deleteWidgetIds, setDeleteWidgetIds] = React.useState([]);
  const [configureDialogOpen, setConfigureDialogOpen] = React.useState(false);
  const [getRemoveWidgetId, setRemoveWidgetId] = React.useState(null);
  const [configureWidget, setConfigureWidget] = React.useState();
  const [getDashboardName, setDashboardName] = React.useState(null);
  const [gridCols, setGridCols] = React.useState(12);
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [filterDetails, setFilterDetails] = React.useState(null);
  const [isDragging, setIsDragging] = React.useState(false);
  // View Dashboard Filter States
  const [viewDashboardWidgetFilterQuery, setViewDashboardWidgetFilterQuery] =
    React.useState([]);
  const [viewDashboardClearFilters, setViewDashboardClearFilters] =
    React.useState(false);
  const [viewDashboardApplyFilters, setViewDashboardApplyFilters] =
    React.useState(false);
  const [overlayReportQuery, setOverlayReportQuery] = React.useState(null);
  const [overlayReportVisible, setOverlayReportVisible] = React.useState(false);
  const [clickedWidgetId, setClickedWidgetId] = React.useState(0);
  const [routingWidget, setRoutingWidget] = React.useState(0);
  const [modifyTemplateId, setModifyTemplateId] = React.useState();
  const [isShowDelayPopUp, setIsShowDelayPopUp] = React.useState(false);
  const addWidgetBreadcrumb = [
    {
      text: 'Admin',
      link: '/admin/dashboards',
    },
    {
      text: 'Dashboards',
      link: action ? '/admin/dashboards' : '/dashboard',
    },
  ];

  if (getDashboardName?.dashboard_id) {
    addWidgetBreadcrumb.push({
      text: getDashboardName?.name,
      link: `/admin/dashboard/edit/${getDashboardName?.dashboard_id}`,
    });
  }
  const contextmenuRef = useRef(null);
  const classes = useStyles({ isDragging: isDragging });
  // Widget Menu Click event
  const handleClick = (event, widgetQuery, index) => {
    setAnchorEl(event.currentTarget);
    setDeleteWidgetId(widgetQuery?.widget_id);
    setModifyTemplateId(widgetQuery?.widget_template_id);
    if (widgetQuery?.widget_id) {
      setDeleteWidgetIds([...deleteWidgetIds, widgetQuery?.widget_id]);
    }
    setConfigureWidget(widgetQuery);
    setRemoveWidgetId(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Widget Delete handler
  const handleDelete = () => {
    setAnchorEl(null);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: updatedLayout?.length
        ? type == 'home'
          ? 'This would delete the widget from this dashboard, would you like to continue ?'
          : 'Current layout changes would be discarded. Are you sure you still want to delete the Widget?'
        : 'Are you sure you still want to delete the Widget?',
      header: 'Delete Widget',
    });
  };
  const onConfirmDialog = async () => {
    if (deleteWidgetId && deleteWidgetId !== undefined) {
      const deleteWIdget = await deleteWidget(
        deleteWidgetId,
        setAlert,
        setConfirmDialog,
        id,
        setLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery
      );
      if (deleteWIdget) {
        refreshDashboardList();
      }
    } else {
      if (widgetQuery.length === 1) {
        setWidgetQuery(null);
        setConfirmDialog(false);
      } else {
        widgetQuery.splice(getRemoveWidgetId, 1);
        setWidgetQuery(widgetQuery);
        setConfirmDialog(false);
      }
    }
  };
  // To create initial Dashboard Layout
  const generateLayout = () => {
    return _.map(widgetQuery, function (item, i) {
      let maxY = layout?.length
        ? Math.max(...layout.map(val => val.y + val.h))
        : Math.max(
            ...widgetQuery.map(val => val.y_axis_position + val.height)
          ) || Math.floor((i * 2) / 6) * 2;

      let maxX = layout?.length
        ? Math.max(...layout.map(val => val.x + val.w))
        : Math.max(
            ...widgetQuery.map(val => val.x_axis_position + val.width)
          ) || (i * 4) % 6;
      const isGaugeChart = item?.chart_type === 'gauge';
      const isTabularReport = item?.chart_type === 'tabular-report';
      const isPieChart = item?.chart_type === 'pie-chart';
      const isTableReport = item?.chart_type === 'table-report';
      const isButtonReport =
        item?.widget_template?.widget_display_type?.[0] ===
        'external-link-button';
      const isCardReport = item?.chart_type === 'card';
      return {
        widget_id: item?.widget_id,
        x:
          item?.x_axis_position !== null
            ? item?.x_axis_position
            : layout[i]?.x || maxX,
        y:
          item?.y_axis_position !== null
            ? item?.y_axis_position
            : layout[i]?.y || maxY,
        w: item?.width
          ? item?.width
          : isButtonReport || isTabularReport
            ? 2
            : isGaugeChart
              ? 2.2
              : isTableReport
                ? 10
                : 2.2,
        h: item?.height
          ? item?.height
          : isButtonReport || isTabularReport || isCardReport
            ? 2
            : isGaugeChart
              ? 6
              : isPieChart
                ? 6
                : isTableReport
                  ? 15
                  : 6,
        i: item?.index?.toString(),
        static: action == 'view' || type == 'home' ? true : false,
        minW: isGaugeChart ? 2.2 : isTableReport ? 10 : isCardReport ? 1.25 : 2,
        minH: isGaugeChart
          ? 6
          : isPieChart
            ? 6
            : isTableReport
              ? 15
              : isButtonReport || isCardReport
                ? 2
                : 6,
        maxW: (isButtonReport && 4) || Infinity,
        maxH: (isButtonReport && 2) || Infinity,
        isResizable:
          action == 'view' || type == 'home' || isTabularReport ? false : true,
      };
    });
  };

  // Fetch dashboard details by its id
  React.useEffect(async () => {
    clearAlert();
    setLoading(true);
    const filterResponse = await getFilterDetails();
    setFilterDetails(filterResponse);
    if (action !== 'create' && type !== 'home') {
      getDashboardDetails(
        id,
        setLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery,
        setViewDashboardWidgetFilterQuery,
        setApplyFilters,
        setDashboardName
      );
    }

    if (type === 'home') {
      getDashboardDetails(
        dashboardId,
        setHomeDashboardLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery,
        setWidgetFilterQuery,
        setApplyFilters,
        setDashboardName
      );
    }
  }, [action]);

  React.useEffect(() => {
    if (isShowDelayPopUp)
      toastTopCenter?.current?.show({
        severity: 'warn',
        summary: '',
        detail: 'We are expecting a delay, Please retry after 30 sec',
        life: 3000,
      });
  }, [isShowDelayPopUp]);

  // Fetch dashboard when filters are cleared
  React.useEffect(() => {
    clearAlert();
    if (clearFilters) {
      getDashboardDetails(
        dashboardId,
        setHomeDashboardLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery,
        setWidgetFilterQuery,
        setApplyFilters,
        setDashboardName
      );
      setClearFilters(false);
    }
  }, [clearFilters]);

  // Fetch dashboard when filters are cleared (View Dashboard)
  React.useEffect(() => {
    clearAlert();
    if (viewDashboardClearFilters) {
      getDashboardDetails(
        id,
        setLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery,
        setWidgetFilterQuery,
        setViewDashboardApplyFilters,
        setDashboardName
      );
      setViewDashboardClearFilters(false);
    }
  }, [viewDashboardClearFilters]);

  // Update Dashboard Layout as per its widgets count
  React.useEffect(() => {
    if ((widgetQuery?.length && !layout.length) || !configureDialogOpen) {
      setLayout(generateLayout());
      setUpdatedLayout(generateLayout());
    }
  }, [widgetQuery, configureDialogOpen]);

  if (type === 'home' && widgetQuery.length && !layout.length) {
    setLayout(generateLayout());
  }

  // Handler to update Dashboard Layout
  const [updatedLayout, setUpdatedLayout] = React.useState([]);
  const onLayoutChange = layout => {
    const updateLayout = [];

    layout.map((lay, index) => {
      widgetQuery.map(widget => {
        if (widget?.index?.toString() === lay.i) {
          updateLayout.push(lay);
        }
      });
    });
    setUpdatedLayout(updateLayout);
  };
  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = (breakpoint, cols) => {
    setGridCols(cols);
  };
  const handleSave = () => {
    const updatedWidget = [];

    updatedLayout.map((lay, index) => {
      widgetQuery.map(widget => {
        if (widget?.index?.toString() === lay.i) {
          updatedWidget.push(widget);
        }
      });
    });

    updateWidgets(
      true,
      updatedLayout,
      updatedWidget,
      history,
      setLoading,
      setDashboardData,
      setWidgetRendererData,
      setWidgetQuery,
      setUpdatedLayout
    );
  };

  // Handler to open a dialog to add new widget
  const handleAddNewWidget = () => {
    setDialogOpen(true);
  };
  const dashboardDetails = {
    dashboard_id: getDashboardName?.dashboard_id,
    dashboard_name: getDashboardName?.name,
    dashboard_client_id: getDashboardName?.client_id,
    user_id: localStorage.getItem('user_id'),
    start_time: new Date().getTime(),
  };

  const editWidgetHandler = widget_template_id => {
    window.open(
      `/admin/widget/edit/${widget_template_id}?source=dashboard`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <>
      <Grid container spacing={2} direction="column" wrap="nowrap">
        {alert?.exists && (
          <Grid item>
            <Alert />
          </Grid>
        )}
        {type !== 'home' ? (
          <>
            <Grid
              container
              item
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Grid item xs={4}>
                <PageHeader breadCrumbArray={addWidgetBreadcrumb} />
              </Grid>
              {action === 'view' && (
                <>
                  <Grid container item xs={6} justifyContent="flex-end">
                    {checkPermission(
                      permissions?.dashboardManagement?.editDashboard
                    ) && (
                      <Grid item xs={2}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            history.push(`/admin/dashboard/edit/${id}?list=1`)
                          }
                        >
                          Edit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {(action === 'edit' || action === 'create') && (
                <Grid
                  container
                  item
                  direction="row"
                  xs={6}
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleAddNewWidget()}
                    >
                      Add Widget
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={updatedLayout.length === 0}
                      onClick={() => handleSave()}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : null}

        <Grid item className={classes.gridWrapper}>
          {widgetQuery?.length ? (
            <ResponsiveGridLayout
              className={classes.layout}
              layouts={{ lg: layout }}
              cols={{ lg: 12, md: 10, sm: 8, xs: 6, xxs: 4 }}
              onLayoutChange={onLayoutChange}
              containerPadding={[0, 0]}
              rowHeight={25}
              onDrag={() => setIsDragging(true)}
              onDragStop={() => setIsDragging(false)}
              onResizeStart={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                setIsDragging(true);
              }}
              onResizeStop={() => setIsDragging(false)}
              compactType="null"
              preventCollision={true}
              onBreakpointChange={onBreakpointChange}
            >
              {widgetQuery?.length &&
                layout.map(
                  (item, index) => {
                    let colorValue = [];
                    let startURL =
                      widgetQuery[
                        index
                      ]?.custom_properties?.buttonInfoUrl?.split('://')
                        ?.length ||
                      widgetQuery[
                        index
                      ]?.widget_template?.custom_properties?.buttonInfoUrl?.split(
                        '://'
                      )?.length;
                    if (
                      widgetQuery[index]?.widget_template
                        ?.widget_display_type?.[0] === 'card'
                    ) {
                      colorValue = convertHexToRgb(
                        widgetQuery[index]?.custom_properties?.buttonColor ||
                          widgetQuery[index]?.widget_template?.custom_properties
                            ?.buttonColor ||
                          PRIMARY_COLOR
                      );
                    }

                    return (
                      <div
                        key={widgetQuery[index]?.index?.toString()}
                        className={`${
                          widgetQuery[index]?.chart_type === 'table-report'
                            ? 'table-report-style'
                            : null
                        }`}
                      >
                        <Paper
                          elevation={0}
                          onContextMenu={e => {
                            e.preventDefault();
                            let onClickArgs = _.cloneDeep(
                              widgetQuery[index]?.widget_template
                            );
                            if (
                              action !== 'edit' &&
                              onClickArgs?.widget_display_type?.[0] !=
                                'table-report'
                            ) {
                              dispatch(
                                setPageHeaderComponent({
                                  activeWidget: {
                                    widgetTemplate: onClickArgs,
                                    homeFilters: homeFilters,
                                    region,
                                    storeNumber,
                                    district,
                                    category,
                                    source,
                                    projectType,
                                    installers,
                                    workroom,
                                    status,
                                    homeFilterDateDimension,
                                    scheduleDate,
                                    completionDate,
                                    checkDate,
                                    dateSold,
                                  },
                                })
                              );
                              setClickedWidgetId(
                                onClickArgs.widget_template_id
                              );
                              setRoutingWidget(onClickArgs?.route_widget);
                              contextmenuRef.current.show(e);
                            }
                          }}
                          id={widgetQuery[index]?.widget_id}
                          key={widgetQuery[index]?.index?.toString()}
                          classes={{ root: classes.widgetPaper }}
                          className={
                            widgetQuery[index]?.widget_template
                              ?.widget_display_type?.[0] ===
                            'external-link-button'
                              ? classes.buttonGrid
                              : null
                          }
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            cursor:
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                                'external-link-button' &&
                              widgetQuery[index]?.widget_template
                                ?.custom_properties?.buttonWidgetType ==
                                'readOnlyWidget'
                                ? 'none'
                                : 'pointer',
                            border:
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                                'external-link-button' && 'none',
                            background:
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                              'external-link-button'
                                ? widgetQuery[index]?.custom_properties
                                    ?.buttonColor ||
                                  widgetQuery[index]?.widget_template
                                    ?.custom_properties?.buttonColor ||
                                  PRIMARY_COLOR
                                : 'none',

                            color:
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                              'external-link-button'
                                ? widgetQuery[index]?.custom_properties
                                    ?.buttonTextColor ||
                                  widgetQuery[index]?.widget_template
                                    ?.custom_properties?.buttonTextColor ||
                                  '#FFFFFF'
                                : 'none',
                          }}
                          onDoubleClick={() => {
                            if (
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                                'external-link-button' &&
                              widgetQuery[index]?.widget_template
                                ?.custom_properties?.buttonWidgetType ==
                                'readOnlyWidget'
                            ) {
                              return false;
                            } else if (
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                                'external-link-button' &&
                              widgetQuery[index]?.widget_template
                                ?.custom_properties?.buttonWidgetType ==
                                'urlWidget'
                            ) {
                              widgetQuery[index]?.widget_template
                                ?.custom_properties?.openIn?.code == 'new-tab'
                                ? window.open(
                                    startURL > 1
                                      ? widgetQuery[index]?.custom_properties
                                          ?.buttonInfoUrl ||
                                          widgetQuery[index]?.widget_template
                                            ?.custom_properties?.buttonInfoUrl
                                      : `http://${widgetQuery[index]?.custom_properties?.buttonInfoUrl}` ||
                                          `http://${widgetQuery[index]?.widget_template?.custom_properties?.buttonInfoUrl}`
                                  )
                                : (window.location.href =
                                    startURL > 1
                                      ? widgetQuery[index]?.custom_properties
                                          ?.buttonInfoUrl ||
                                        widgetQuery[index]?.widget_template
                                          ?.custom_properties?.buttonInfoUrl
                                      : `http://${widgetQuery[index]?.custom_properties?.buttonInfoUrl}` ||
                                        `http://${widgetQuery[index]?.widget_template?.custom_properties?.buttonInfoUrl}`);
                            } else if (
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                                'external-link-button' &&
                              widgetQuery[index]?.widget_template
                                ?.custom_properties?.buttonWidgetType ==
                                'dashboardWidget'
                            ) {
                              if (
                                widgetQuery[index]?.widget_template
                                  ?.custom_properties?.attachedDashboard
                                  ?.private &&
                                widgetQuery[index]?.widget_template
                                  ?.custom_properties?.attachedDashboard
                                  ?.owner_user_id !==
                                  localStorage.getItem('user_id')
                              ) {
                                if (
                                  widgetQuery[index]?.widget_template
                                    ?.custom_properties?.openIn?.code ==
                                  'new-tab'
                                ) {
                                  window.open('/access-denied', '_blank');
                                } else {
                                  history.push({
                                    pathname: '/access-denied',
                                  });
                                }
                              } else {
                                dispatch(
                                  setPageHeaderComponent({
                                    activeDashboard: {
                                      ...widgetQuery[index]?.widget_template
                                        ?.custom_properties?.attachedDashboard,
                                      id: widgetQuery[index]?.widget_template
                                        ?.custom_properties?.attachedDashboard
                                        ?.dashboard_id,
                                      name: widgetQuery[index]?.widget_template
                                        ?.custom_properties?.attachedDashboard
                                        ?.name,
                                    },
                                  })
                                );
                                widgetQuery[index]?.widget_template
                                  ?.custom_properties?.openIn?.code == 'new-tab'
                                  ? window.open('/dashboard-widget', '_blank')
                                  : history.push({
                                      pathname: 'dashboard-widget',
                                    });
                              }
                            } else {
                              let onClickArgs = _.cloneDeep(
                                widgetQuery[index]?.widget_template
                              );
                              if (
                                action !== 'edit' &&
                                onClickArgs?.widget_display_type?.[0] !=
                                  'table-report'
                              ) {
                                dispatch(
                                  setPageHeaderComponent({
                                    activeWidget: {
                                      widgetTemplate: onClickArgs,
                                      homeFilters: homeFilters,
                                      region,
                                      storeNumber,
                                      district,
                                      category,
                                      source,
                                      projectType,
                                      installers,
                                      workroom,
                                      status,
                                      homeFilterDateDimension,
                                      scheduleDate,
                                      completionDate,
                                      checkDate,
                                      dateSold,
                                    },
                                  })
                                );
                                if (onClickArgs?.route_widget) {
                                  if (
                                    (widgetQuery[index]?.widget_template
                                      ?.widget_display_type?.[0] ===
                                      'external-link-button' ||
                                      widgetQuery[index]?.widget_template
                                        ?.widget_display_type?.[0] ===
                                        'card') &&
                                    widgetQuery[index]?.widget_template
                                      ?.custom_properties?.openIn?.code ==
                                      'new-tab'
                                  ) {
                                    window.open('/routing', '_blank');
                                  } else {
                                    history.push({
                                      pathname: 'routing',
                                    });
                                  }
                                } else {
                                  const widgetId =
                                    onClickArgs.widget_template_id;
                                  if (
                                    (widgetQuery[index]?.widget_template
                                      ?.widget_display_type?.[0] ===
                                      'external-link-button' ||
                                      widgetQuery[index]?.widget_template
                                        ?.widget_display_type?.[0] ===
                                        'card') &&
                                    widgetQuery[index]?.widget_template
                                      ?.custom_properties?.openIn?.code ==
                                      'new-tab'
                                  ) {
                                    window.open(
                                      `/widget/details/${widgetId}`,
                                      '_blank'
                                    );
                                  } else {
                                    history.push({
                                      pathname: `/widget/details/${widgetId}`,
                                    });
                                  }
                                }
                              }
                            }
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            className={
                              widgetQuery[index]?.widget_template
                                ?.widget_display_type?.[0] ===
                              'external-link-button'
                                ? classes.buttonContainer
                                : widgetQuery[index]?.chart_type ===
                                    'table-report'
                                  ? 'h-3rem pt-2'
                                  : null
                            }
                            classes={{ root: classes.widgetHeaderContainer }}
                          >
                            <Grid
                              item
                              xs={10}
                              className={
                                widgetQuery[index]?.widget_template
                                  ?.widget_display_type?.[0] ===
                                'external-link-button'
                                  ? classes.buttonWrapper
                                  : null
                              }
                            >
                              <Typography
                                variant="h4"
                                noWrap
                                title={
                                  widgetQuery[index]?.widget_template
                                    ?.widget_name
                                }
                                style={{
                                  color:
                                    widgetQuery[index]?.widget_template
                                      ?.custom_properties?.buttonTextColor ||
                                    'inherit',
                                }}
                              >
                                {
                                  widgetQuery[index]?.widget_template
                                    ?.widget_name
                                }
                              </Typography>
                            </Grid>
                            {action !== 'view' && (
                              <Grid
                                item
                                container
                                xs={2}
                                justifyContent="flex-end"
                                className="absolute right-0 pr-2"
                              >
                                {checkPermission(
                                  permissions?.widgetManagement?.editWidget
                                ) &&
                                checkPermission(
                                  permissions?.widgetManagement?.deleteWidget
                                ) ? (
                                  <>
                                    <MoreVertIcon
                                      fontSize="medium"
                                      onClick={e => {
                                        e.preventDefault();
                                        handleClick(
                                          e,
                                          widgetQuery[index],
                                          index
                                        );
                                      }}
                                      className="relative z-5"
                                    />
                                    <Menu
                                      elevation={0}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      classes={{ paper: classes.menuRoot }}
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                      disableScrollLock
                                    >
                                      {checkPermission(
                                        permissions?.widgetManagement
                                          ?.editWidget
                                      ) ? (
                                        <MenuItem
                                          onClick={() =>
                                            editWidgetHandler(modifyTemplateId)
                                          }
                                        >
                                          Modify Widget
                                        </MenuItem>
                                      ) : null}
                                      {checkPermission(
                                        permissions?.widgetManagement
                                          ?.deleteWidget
                                      ) ? (
                                        <MenuItem
                                          onClick={() => handleDelete()}
                                        >
                                          Remove Widget
                                        </MenuItem>
                                      ) : null}
                                    </Menu>
                                  </>
                                ) : null}
                              </Grid>
                            )}
                          </Grid>
                          {widgetQuery[index] &&
                          (widgetQuery[index]?.chart_type !==
                            'external-link-button' ||
                            widgetQuery[index]?.widget_template
                              ?.widget_display_type?.[0] !==
                              'external-link-button') ? (
                            <DashboardWidgetRenderer
                              widgetDetails={widgetQuery[index]}
                              filterDetails={filterDetails}
                              homeFilters={homeFilters}
                              applyFilters={applyFilters}
                              setApplyFilters={setApplyFilters}
                              clearFilters={clearFilters}
                              homeFilterDateDimension={homeFilterDateDimension}
                              type={type}
                              onClickHandler={() => {
                                if (
                                  type == 'home' &&
                                  ['tabular-report'].includes(
                                    widgetQuery[index]?.chart_type
                                  )
                                ) {
                                  if (
                                    widgetQuery[index].widget_template
                                      .custom_properties.openIn.code ==
                                    'new-tab'
                                  ) {
                                    let onClickArgs = _.cloneDeep(
                                      widgetQuery[index]?.widget_template
                                    );
                                    const widgetId =
                                      onClickArgs.widget_template_id;
                                    if (onClickArgs?.route_widget) {
                                      history.push({
                                        pathname: 'routing',
                                        state: {
                                          widgetTemplate: onClickArgs,
                                          homeFilters: homeFilters,
                                          region,
                                          storeNumber,
                                          district,
                                          category,
                                          source,
                                          projectType,
                                          installers,
                                        },
                                      });
                                    } else {
                                      history.push({
                                        pathname: `/widget/details/${widgetId}`,
                                        state: {
                                          widgetTemplate: onClickArgs,
                                          homeFilters: homeFilters,
                                          region,
                                          storeNumber,
                                          district,
                                          category,
                                          source,
                                          projectType,
                                          installers,
                                        },
                                      });
                                    }
                                  } else {
                                    setOverlayReportVisible(true);
                                    setOverlayReportQuery(
                                      widgetQuery[index].widget_template
                                    );
                                  }
                                }
                              }}
                              dashboardDetails={dashboardDetails}
                              setDisabledDashboard={setDisabledDashboard}
                              setIsShowDelayPopUp={setIsShowDelayPopUp}
                            />
                          ) : null}
                        </Paper>
                      </div>
                    );
                  }
                  // )
                )}
            </ResponsiveGridLayout>
          ) : (
            !loading &&
            !homeDashboardLoading &&
            widgetQuery?.length === 0 && (
              <Grid container justifyContent="center">
                <Typography variant="h3">No widgets available</Typography>
              </Grid>
            )
          )}
          <WidgetsList
            setDialogOpen={setDialogOpen}
            dialogOpen={dialogOpen}
            setWidgetQuery={setWidgetQuery}
            setWidgetRendererData={setWidgetRendererData}
            widgetQuery={widgetQuery}
            setLayout={setLayout}
            layout={layout}
            dashboardId={id}
            setChartType={setChartType}
            dashboardData={dashboardData}
            cols={gridCols}
            parentLoading={loading}
            setParentLoading={setLoading}
            deleteWidgetIds={deleteWidgetIds}
          />

          <ConfigureDashboardWidget
            dialogOpen={configureDialogOpen}
            setDialogOpen={setConfigureDialogOpen}
            configureWidget={configureWidget}
            layout={layout}
            setDashboardData={setDashboardData}
            setWidgetRendererData={setWidgetRendererData}
            setWidgetQuery={setWidgetQuery}
            setUpdatedLayout={setUpdatedLayout}
          />

          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />
        </Grid>
      </Grid>
      {overlayReportQuery && (
        <OverlayReport
          query={overlayReportQuery}
          overlayReportVisible={overlayReportVisible}
          setOverlayReportVisible={setOverlayReportVisible}
          applyFilters={applyFilters}
          setOverlayReportQuery={setOverlayReportQuery}
        />
      )}

      <ContextMenu
        model={[
          {
            label: 'Open in new tab',
            icon: 'pi pi-external-link',
            command: () => {
              if (routingWidget) {
                window.open('/routing', '_blank');
              } else {
                window.open(
                  `/widget/details/${clickedWidgetId}`,
                  '_blank',
                  'noopener,noreferrer'
                );
              }
            },
          },
        ]}
        ref={contextmenuRef}
        breakpoint="767px"
      />
      <Toast ref={toastTopCenter} position="top-center" />
    </>
  );
};

export default AddViewEditDashboard;
