import { makeStyles } from '@material-ui/core/styles';

import { StatusColors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  newCateryError: {
    color: 'red',
    width: '500px',
    marginBottom: '20px',
  },
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  formWrapper: {
    width: '100%',
  },
  sourceImage: {
    paddingBottom: '10px',
  },
  statusInput: props => ({
    color:
      (props.status === 'Cancelled' && StatusColors.PF_RED) ||
      (props.status === 'Completed' && StatusColors.PF_GREEN) ||
      StatusColors.PF_YELLOW,
  }),
  reviewInput: props => ({
    color: 'rgba(0, 0, 0, 0.38)',
  }),
  sourceSelect: {
    paddingBottom: '2px',
    height: '25px',
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
