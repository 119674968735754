import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import { BlockUI } from 'primereact/blockui';

import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { QuestionTypes } from '../../../constants';

import { addQuestion, updateQuestionById } from './questionnaireSerivce';

const MAX_LIST_ITEMS = 10;

const QuestionDialog = ({ toast, editPermission }) => {
  const {
    refreshList,
    isQuestionSaving,
    moduleList,
    isQuestionDialogOpen,
    selectedModule,
    questionDialogContent,
  } = useSelector(state => state.questionnaire);
  const dispatch = useDispatch();
  const [newListOption, setNewListOption] = useState(null);

  const formik = useFormik({
    initialValues: {
      module: questionDialogContent?.module || selectedModule,
      question: questionDialogContent?.question,
      question_type: questionDialogContent?.question_type,
      is_active: !!questionDialogContent?.is_active,
      is_editable: !!questionDialogContent?.is_editable,
      options: questionDialogContent?.options,
    },
    validationSchema: Yup.object().shape({
      module: Yup.object().shape({
        module_id: Yup.number().required('Required'),
        module_name: Yup.string().required('Module is required'),
      }),
      question: Yup.string().trim().min(1).required('Question is required'),
      question_type: Yup.object().shape({
        id: Yup.string().required('Required'),
        label: Yup.string().required('Question type is required'),
      }),
      is_active: Yup.boolean().required('Required'),
      is_editable: Yup.boolean().required('Required'),
      options: Yup.array().when('response_type.id', {
        is: 'list',
        then: Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string()
                .trim()
                .min(1)
                .required('Option cannot be empty'),
            })
          )
          .min(1, 'Options are required')
          .required('Options are required'),
        otherwise: Yup.array().of(
          Yup.object().shape({
            label: Yup.string()
              .trim()
              .min(1)
              .required('Option cannot be empty'),
          })
        ),
      }),
    }),
    onSubmit: async values => {
      if (!values?.module?.module_id) {
        toast?.current?.show &&
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Module is required',
            life: 3000,
          });
        return;
      }
      if (!values?.question || !values?.question?.trim()?.length) {
        toast?.current?.show &&
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Question cannot be empty',
            life: 3000,
          });
        return;
      }
      try {
        dispatch(updateQuestionnaireStore({ isQuestionSaving: true }));
        const payload = {
          question: values?.question,
          question_type: values?.question_type?.id,
          is_active: !!values?.is_active,
          is_editable: !!values?.is_editable,
        };
        if (
          Array.isArray(values?.options) &&
          values?.question_type?.id === QuestionTypes[2].id
        ) {
          payload.options = values.options.map(({ label = null }) => {
            return { label };
          });
        }
        let response;
        if (questionDialogContent?.question_id) {
          response = await updateQuestionById(
            values.module.module_id,
            questionDialogContent.question_id,
            payload
          );
        } else {
          response = await addQuestion(values.module.module_id, payload);
        }
        if (response?.status && Array.isArray(response?.data)) {
          dispatch(
            updateQuestionnaireStore({
              questionDialogContent: null,
              questionList: response.data,
              questionCount: response.data.length,
            })
          );
          toast?.current?.show &&
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: response?.message,
              life: 3000,
            });
          formik.resetForm();
        }
      } catch (ex) {
        toast?.current?.show &&
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: ex?.response?.data?.message || 'something went wrong',
            life: 3000,
          });
      } finally {
        dispatch(
          updateQuestionnaireStore({
            isQuestionDialogOpen: false,
            isQuestionSaving: false,
            refreshList: !refreshList,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const closeAddNewQuestionDialog = () => {
    formik.resetForm();
    setNewListOption(null);
    dispatch(
      updateQuestionnaireStore({
        isQuestionDialogOpen: false,
        questionDialogContent: null,
      })
    );
  };

  const handleModuleChange = (name, value) => {
    formik.setFieldValue('module', value);
    dispatch(
      updateQuestionnaireStore({
        selectedModule: value,
        questionDialogContent: {
          ...(questionDialogContent || {}),
          module: value,
        },
      })
    );
  };

  const handleListOptionDelete = rowIndex => {
    let updatedListOptions = structuredClone(formik.values?.options);
    updatedListOptions.splice(rowIndex, 1);
    formik.setFieldValue('options', updatedListOptions);
  };

  const listOptiontextEditor = options => {
    return (
      <PFInputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const onListOptionCellEditComplete = e => {
    let updatedListOptions = structuredClone(formik.values?.options);
    updatedListOptions[e?.rowIndex] = e?.newRowData;
    formik.setFieldValue('options', updatedListOptions);
  };

  const handleAddOption = option => {
    if (option || newListOption) {
      if (
        (option && !option.trim().length) ||
        (newListOption && !newListOption.trim().length)
      ) {
        formik.setErrors({ options: 'Empty text is not allowed' });
        return;
      }
      if (
        Array.isArray(formik.values?.options) &&
        formik.values.options.length
      ) {
        formik.setFieldValue('options', [
          ...formik.values.options,
          { label: option || newListOption },
        ]);
      } else {
        formik.setFieldValue('options', [{ label: option || newListOption }]);
      }
      setNewListOption(null);
    }
  };
  return (
    <>
      <Dialog
        header={
          questionDialogContent?.question_id
            ? 'Edit Question'
            : 'Add New Question'
        }
        visible={isQuestionDialogOpen}
        style={{ width: '60vw' }}
        onHide={closeAddNewQuestionDialog}
      >
        <BlockUI
          blocked={isQuestionSaving}
          className="opacity-30"
          pt={{ mask: { className: 'gray-bg-300' } }}
        >
          <div className="flex flex-column gap-2 mt-3">
            <div className="flex flex-column">
              <div className="grid mb-2">
                <div className="col-3">
                  <span className="p-float-label w-full">
                    <PFDropdown
                      value={formik?.values?.module}
                      onChange={handleModuleChange}
                      options={moduleList}
                      optionLabel="module_name"
                      placeholder="Select a Module"
                      // filter
                      inputId="module"
                      name="module"
                    />
                    <label htmlFor="module">
                      Module <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                  {formik.touched?.module && formik.errors?.module && (
                    <div className="mt-1 text-red-600">
                      {formik.errors?.module?.module_name}
                    </div>
                  )}
                </div>
                <div className="flex col-3">
                  <span className="p-float-label w-full">
                    <PFDropdown
                      value={formik?.values?.question_type}
                      onChange={(name, value) =>
                        formik.setFieldValue('question_type', value || '')
                      }
                      options={QuestionTypes}
                      optionLabel="label"
                      placeholder="Select Question Type"
                      inputId="question_type"
                      name="question_type"
                    />
                    <label htmlFor="question_type">
                      Question Type <span style={{ color: 'red' }}>*</span>
                    </label>
                  </span>
                </div>

                <div className="flex col-3 justify-content-center">
                  <span>Active</span>
                  <PFInputSwitch
                    className="ml-2"
                    inputId="is_active"
                    name="is_active"
                    value={formik?.values?.is_active}
                    onChange={formik.handleChange}
                    checked={formik?.values?.is_active}
                  />
                </div>

                <div className="flex col-3">
                  <span>Modify Answer</span>
                  <PFInputSwitch
                    className="ml-2"
                    inputId="is_editable"
                    name="is_editable"
                    value={formik?.values?.is_editable}
                    onChange={formik.handleChange}
                    checked={formik?.values?.is_editable}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-column">
              <span className="p-float-label">
                <InputTextarea
                  id="question"
                  name="question"
                  rows={5}
                  value={formik?.values?.question}
                  onChange={e => {
                    if (
                      !e?.target?.value ||
                      !e?.target?.value?.trim()?.length
                    ) {
                      formik.setErrors({
                        question: 'Empty question is not allowed',
                      });
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="w-full"
                />
                <label htmlFor="question">
                  Question <span style={{ color: 'red' }}>*</span>
                </label>
              </span>
              {formik.touched?.question && formik.errors?.question && (
                <div className="mt-1 text-red-600">
                  {formik.errors?.question}
                </div>
              )}
            </div>
            {formik.values?.question_type?.id === QuestionTypes[2].id && (
              <>
                <div className="flex flex-column">
                  <div className="flex flex-row col-6 p-inputgroup">
                    <span className="p-float-label">
                      <PFInputText
                        id="options"
                        name="options"
                        value={newListOption}
                        onChange={e => {
                          formik.setErrors({ options: null });
                          if (13 === e?.keyCode)
                            handleAddOption(e?.target?.value);
                          else setNewListOption(e?.target?.value);
                        }}
                        disabled={
                          Array.isArray(formik.values?.options) &&
                          formik.values.options.length >= MAX_LIST_ITEMS
                        }
                      />
                      <label htmlFor="options">
                        Add List Options
                        {formik?.values?.options?.length <= 0 && (
                          <span style={{ color: 'red' }}>*</span>
                        )}
                      </label>
                    </span>
                    <PFButton
                      label={'Add'}
                      icon={'pi pi-plus'}
                      onClick={() => handleAddOption()}
                      outlined={true}
                      disabled={
                        !newListOption ||
                        newListOption?.length < 1 ||
                        (Array.isArray(formik.values?.options) &&
                          formik.values.options.length >= MAX_LIST_ITEMS)
                      }
                    />
                  </div>

                  {Array.isArray(formik.values?.options) &&
                    formik.values.options.length >= MAX_LIST_ITEMS && (
                      <span style={{ color: 'red' }}>
                        Maximum(10) limit reached
                      </span>
                    )}
                  {formik.errors?.options && (
                    <span style={{ color: 'red' }}>
                      {formik.errors?.options}
                    </span>
                  )}
                </div>
                {Array.isArray(formik.values?.options) &&
                  formik.values?.options?.length > 0 && (
                    <div className="flex flex-column">
                      <PFDataTable
                        editMode="cell"
                        columns={[
                          {
                            field: '',
                            header: '',
                            style: { width: '32px' },
                            sortable: false,
                            filter: false,
                            body: (rowData, option) => (
                              <>
                                <i
                                  className="pi pi-trash cursor-pointer"
                                  style={{ color: 'var(--red-500)' }}
                                  onClick={() =>
                                    handleListOptionDelete(option?.rowIndex)
                                  }
                                ></i>
                              </>
                            ),
                          },
                          {
                            field: 'label',
                            header: 'List Options',
                            sortable: false,
                            filter: false,
                            editor: row => listOptiontextEditor(row),
                            onCellEditComplete: onListOptionCellEditComplete,
                          },
                        ]}
                        data={formik.values?.options}
                        stripedRows={true}
                        scrollable
                        scrollHeight="250px"
                      />
                    </div>
                  )}
              </>
            )}
          </div>

          {editPermission && (
            <div className="w-full flex justify-content-end gap-2 mt-2">
              <PFButton
                label="Cancel"
                onClick={closeAddNewQuestionDialog}
                className="p-button-text"
                outlined
                disabled={isQuestionSaving}
              />
              <PFButton
                label="Submit"
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid || isQuestionSaving}
              />
            </div>
          )}
        </BlockUI>
      </Dialog>
    </>
  );
};

export default QuestionDialog;
