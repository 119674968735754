import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserTeamReviewData = async userId => {
  try {
    const teamReviewTypeRequest = ApiService.get(`/system/team-review-type`);
    const userDetailsRequest = ApiService.get(`/auth/user/manage/${userId}`);

    const [teamReviewTypeList, userDetailsResponse] = await Promise.all([
      teamReviewTypeRequest,
      userDetailsRequest,
    ]);

    return {
      teamReviewTypeList: teamReviewTypeList,
      teamReviews: userDetailsResponse?.team_reviews || [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const editTeamReview = async (userId, teamReviewInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        team_reviews: JSON.stringify(teamReviewInfo),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
