import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Toast, Card } from 'primereact';
import { useSelector } from 'react-redux';

import PFButton from '../PFPrime/PFButton';
import { getClientById } from '../../ProjectManagement/Clients/Clients.service';
import PageHeader from '../../shared/PageHeader/PageHeader';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { getClientDetails } from '../../Admin/Configuration/RestrictAccessbyRole.service';
import CommunicationsContainer from '../Communications/CommunicationsContainer';
import PFTabView from '../../shared/PFPrime/PFTabView';

import {
  callGetQuoteById,
  previewQuote,
  quoteSubmit,
  sendQuote,
} from './Quote.model';
import QuoteInfo from './components/QuoteInfo.view';
import ClientInfo from './components/ClientInfo.view';
import QuoteAmount from './components/QuoteAmount.view';
import Notes from './components/Notes.view';
import TermsCondition from './components/TermsCondition.view';
import LineItems from './components/LineItems.view';
import SenderDetails from './components/SenderDetails.view';
import { QuotePreview } from './QuotePreview.view';

export const Quote = () => {
  const { customerId, quoteId, action, tag } = useParams();

  const history = useHistory();
  const toast = useRef(null);
  const isEdit = quoteId && action === 'edit';
  const isView = quoteId && action === 'view';
  const [clientDetails, setClientDetails] = useState();
  const [quoteDetails, setQuoteDetails] = useState();
  const [tenantDetails, setTenantDetails] = useState();
  const [initialValue, setInitialValue] = useState();
  const [loading, setLoading] = useState(-1);
  const breadCrumbType = useSelector(state => state.quoteHeader.breadCrumbType);

  const CustomerOperationsModel = {
    tabs: [
      { header: 'Versions', disabled: false, delete: false },
      { header: 'Invoices', disabled: false, delete: false },
      { header: 'Receipts', disabled: false, delete: false },
    ],
    components: {
      Versions: <h6>Under Development</h6>,
      Invoices: <h6>Under Development</h6>,
      Receipts: <h6>Under Development</h6>,
    },
  };

  const configureInitialValue = data => {
    setQuoteDetails(data);
    const initialValueObj = {};
    for (const key in data) {
      switch (key) {
        case 'title':
        case 'status':
        case 'recurrence':
        case 'termAndConditions':
          initialValueObj[key] = data[key];
          break;
        case 'notes':
          initialValueObj[key] = data[key].map(e => {
            e.dateTime = moment(new Date(e.dateTime)).format(
              'YYYY-DD-MM hh:mm A'
            );
            return e;
          });
          break;
        case 'createDate':
        case 'expiryDate':
        case 'followupDate':
          initialValueObj[key] = new Date(data[key]);
          break;
        case 'customerBillingAddress':
          initialValueObj[key] =
            data[key] || data?.clientDetails?.customerBillingAddress;
          break;
        case 'customerServiceAddress':
          initialValueObj[key] = data[key] || '';
          break;
        case 'lineItems':
          initialValueObj[key] = data[key].items;
          initialValueObj['discount'] = data[key].discount;
          initialValueObj['tax'] = data[key].tax;
          break;
        case 'amount':
          initialValueObj['totalPrice'] = data[key].totalPrice;
          initialValueObj['depositType'] = data[key].depositType;
          initialValueObj['depositPercent'] = data[key].depositPercent;
          initialValueObj['payableAmount'] = data[key].payableAmount;
          initialValueObj['amountToBePaidAfterService'] =
            data[key].amountToBePaidAfterService;
          initialValueObj['paymentTerms'] = data[key].paymentTerms;
          break;
        case 'leadSource':
          initialValueObj['leadBy'] = data[key].leadBy;
          break;
      }
    }
    setInitialValue(initialValueObj);
  };
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    quoteSubmit({
      customerId,
      setLoading,
      toast,
      history,
      initialValue,
      isEdit,
      quoteDetails,
      configureInitialValue,
    });

  const callToast = (type, msg) => {
    toast.current.show({
      severity: type,
      summary: msg,
      detail: msg,
    });
  };

  if (!clientDetails && !isView && !isEdit && loading === -1) {
    Promise.all([
      getClientById(customerId, setLoading, callToast, setClientDetails),
      (async () => {
        const response = await getClientDetails();
        if (response) {
          setTenantDetails(response);
        }
      })(),
    ])
      .then()
      .catch();
  }
  if (!quoteDetails && (isView || isEdit) && loading === -1) {
    callGetQuoteById({
      quoteId,
      setLoading,
      toast,
      cb: data => {
        configureInitialValue(data);
        setClientDetails(data?.clientDetails);
      },
    });
  }
  const defaultProps = {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    clientDetails,
    tenantDetails,
    quoteDetails,
    isView,
    isEdit,
  };
  const quoteFormBreadcrumb = [
    {
      text: 'Client',
    },
    {
      text: 'Client Details',
      link: `/clients/view/${customerId}`,
    },
    {
      text:
        !isView && !isEdit
          ? `${tag[0].toUpperCase() + tag.slice(1)}`
          : `${isView ? 'View' : 'Edit'} ${tag[0].toUpperCase() + tag.slice(1)} `,
    },
  ];
  const quoteManagementBreadCrumb = [
    { text: 'CRM' },
    { text: 'Quote', link: '/crm/quote-managment' },
    {
      text:
        !isView && !isEdit
          ? 'Create Quote'
          : `${isView ? 'View' : 'Edit'} Quote`,
    },
  ];

  return (
    <>
      <form className="w-full create-quote-template">
        <div className="grid w-full quoteListing flex">
          <Toast ref={toast} />
          <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
            {isEdit || isView ? (
              <PageHeader
                pageTitle={
                  <div className="flex align-items-center">
                    {tag[0].toUpperCase() + tag.slice(1)} #:
                    {(quoteDetails && quoteDetails?.quoteId) || '-'}
                  </div>
                }
                breadCrumbArray={
                  breadCrumbType === 'Client'
                    ? quoteFormBreadcrumb
                    : quoteManagementBreadCrumb
                }
              />
            ) : (
              <PageHeader
                pageTitle={`Create ${tag[0].toUpperCase() + tag.slice(1)}`}
                breadCrumbArray={quoteFormBreadcrumb}
              />
            )}
          </div>

          {loading === 0 ? (
            <ProgressSpinner
              style={{ width: '30px', height: '30px' }}
              aria-label="Loading"
            />
          ) : (
            <>
              <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
                <div className="mb-2 align-items-end text-right ">
                  <PFButton
                    label="Cancel"
                    onClick={() => {
                      window.history.back();
                      // Todo: API should be called if data persist
                      isEdit &&
                        callGetQuoteById({
                          quoteId,
                          setLoading,
                          toast,
                          cb: data => {
                            configureInitialValue(data);
                            setClientDetails(data?.clientDetails);
                          },
                        }) &&
                        setInitialValue(null);
                    }}
                    severity="secondary"
                    text={true}
                    raised={true}
                    className="mr-1  border-noround fontFamily"
                    disabled={[1, 2, 3].includes(loading)}
                  />
                  {isView ? (
                    <>
                      <PFButton
                        label="Preview"
                        onClick={() => setLoading(1)}
                        type="button"
                        raised={true}
                        className="mr-1"
                        disabled={[1, 2, 3].includes(loading)}
                      />
                      {Array.isArray(clientDetails?.customer_emails) && (
                        <PFButton
                          label={
                            !clientDetails?.customer_emails?.[0]?.email
                              ? 'Send (Email not available)'
                              : 'Send'
                          }
                          onClick={() => {
                            if (!clientDetails?.customer_emails?.[0]?.email)
                              return;
                            setLoading(2);
                            sendQuote({
                              quoteId,
                              toast,
                              setLoading,
                            });
                          }}
                          type="button"
                          raised={true}
                          className="mr-1"
                          disabled={
                            [1, 2, 3].includes(loading) ||
                            !clientDetails?.customer_emails?.[0]?.email
                          }
                        />
                      )}
                      {loading === 2 && (
                        <ProgressSpinner
                          style={{ width: 15, height: 15, lineHeight: 1.5 }}
                          aria-label="Loading"
                        />
                      )}
                      {tag === 'invoice' ? (
                        <PFButton
                          label="Payment"
                          // onClick={() => setLoading(1)}
                          type="button"
                          raised={true}
                          className="mr-1"
                          disabled={[1, 2, 3].includes(loading)}
                        />
                      ) : (
                        <PFButton
                          label="Generate Invoice"
                          onClick={() => {
                            const url = `/client/${customerId}/crm/invoice/${quoteId}/view`;
                            window.open(url, '_blank');
                          }}
                          // onClick={() => setLoading(1)}
                          type="button"
                          raised={true}
                          className="mr-1"
                          disabled={[1, 2, 3].includes(loading)}
                        />
                      )}

                      {checkPermission(permissions?.crm?.editQuote) && (
                        <PFButton
                          label="Edit"
                          onClick={() => {
                            history.push(
                              `/client/${customerId}/crm/quote/${quoteId}/edit`
                            );
                          }}
                          type="button"
                          raised={true}
                          className="mr-1"
                          disabled={[1, 2, 3].includes(loading)}
                        />
                      )}
                    </>
                  ) : (
                    <PFButton
                      label="Save"
                      onClick={handleSubmit}
                      type="button"
                      raised={true}
                      className="mr-1 fontFamily"
                    />
                  )}
                </div>
              </div>
              <div className="col-12 md:col-12 lg:col-12 p-0 mt-1">
                <QuoteInfo {...defaultProps} />
                <ClientInfo {...defaultProps} />
                <LineItems {...defaultProps} />
                <QuoteAmount {...defaultProps} />
                <div className="w-full pt-3 crm">
                  <PFTabView model={CustomerOperationsModel} />
                </div>
                {/* <LeadSource {...defaultProps} /> */}
                <SenderDetails {...defaultProps} />
                <TermsCondition {...defaultProps} />
                {/* <Notes {...defaultProps} />
                 */}
                <Card className="mt-3 w-full border-200 border-solid border-1">
                  <CommunicationsContainer />
                </Card>
              </div>
            </>
          )}
        </div>
      </form>

      {isView && quoteDetails && (
        <Dialog
          visible={loading === 1}
          style={{ width: '70vw', height: '100vh' }}
          onHide={() => setLoading(-2)}
          header="Quote Preview"
          className={'previewContent'}
        >
          <QuotePreview
            quoteDetails={quoteDetails}
            clientDetails={clientDetails}
          />
        </Dialog>
      )}
    </>
  );
};
