import React from 'react';
import moment from 'moment';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import apiService from '../../../services/api.service';
import { dateFormatter, formatCurrency } from '../../../utils/Helpers';
import { addEditChargeback } from '../../ProjectManagement/Chargeback/services/ChargebackService';

export const getChargebackListByUser = async (userId, rows, page) => {
  try {
    let queryParams = { limit: rows || 10 };
    if (page) {
      queryParams.page = page + 1;
    }
    const response = await apiService.getWithDiffBaseUrl(
      `${URL_CONSTANTS.CHARGEBACK.list}/${userId}`,
      queryParams,
      URL_CONSTANTS.CHARGEBACK.baseUrl
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const viewChargeBack = (history, params) => {
  params?.project_id
    ? history.push(
        `/admin/chargeback/view/project/${params?.project_id}/chargeback/${params?.chargeback_id}`
      )
    : history.push(
        `/admin/chargeback/view/chargeback/${params?.chargeback_id}`
      );
};

const editChargeBack = (history, params) => {
  params?.project_id
    ? history.push(
        `/admin/chargeback/edit/project/${params?.project_id}/chargeback/${params?.chargeback_id}`
      )
    : history.push(
        `/admin/chargeback/edit/chargeback/${params?.chargeback_id}`
      );
};

export const userGridColumns = (history, userAction) => {
  const main = [
    {
      field: '',
      header: '',
      body: params => {
        return (
          <div className="flex flex-row" style={{ gap: '10px' }}>
            <i
              className="pi pi-eye cursor-pointer"
              onClick={e => viewChargeBack(history, params)}
            ></i>
            <i
              className={
                params?.is_editable
                  ? 'pi pi-pencil cursor-pointer'
                  : 'pi pi-pencil text-300'
              }
              onClick={e => {
                if (params?.is_editable) {
                  editChargeBack(history, params);
                }
              }}
            ></i>
            <i
              className={
                params?.is_editable
                  ? 'pi pi-trash cursor-pointer'
                  : 'pi pi-trash text-300'
              }
              onClick={e => {
                if (params?.is_editable) {
                  userAction({
                    chargebackId: params?.chargeback_id,
                    actionName: 'deleteChargeBack',
                  });
                }
              }}
            ></i>
          </div>
        );
      },
    },
    {
      field: 'created_at',
      header: 'Chargeback Date',
      body: rowData => {
        return rowData.created_at
          ? dateFormatter(rowData.created_at, 'MM-DD-YYYY hh:mm A')
          : '-';
      },
    },
    {
      field: 'created',
      header: 'Created By',
      body: params => {
        return params.created
          ? params.created.first_name + ' ' + params.created.last_name
          : '-';
      },
      headerStyle: {
        minWidth: 200,
      },
    },
    {
      field: 'total_technician',
      header: 'Chargeback Amount',
      body: params => formatCurrency(params?.total_technician),
    },
    {
      field: 'payout_amount',
      header: 'Payment Amount',
      body: params => formatCurrency(params?.payout_amount),
    },
    {
      field: 'payment_count',
      header: 'No. of Payments',
    },
    {
      field: 'payment_amount_balance',
      header: 'Remaining Balance',
      body: params => formatCurrency(params?.payment_amount_balance),
    },
    {
      field: 'debit_user',
      header: 'Debit Technician',
      body: params => {
        return params?.debit_user
          ? params?.debit_user?.first_name + ' ' + params?.debit_user?.last_name
          : '-';
      },
    },
    {
      field: 'credit_user',
      header: 'Credit Technician',
      body: params => {
        return params?.credit_user
          ? params?.credit_user?.first_name +
              ' ' +
              params?.credit_user?.last_name
          : '-';
      },
    },
    {
      field: 'chargeback_status.status',
      header: 'Status',
      headerStyle: {
        minWidth: 150,
      },
    },
    {
      field: '',
      header: 'Approved/Rejected By',
      body: params => {
        return params.approved_or_rejected_by
          ? params.approved_or_rejected_by?.first_name +
              ' ' +
              params.approved_or_rejected_by?.last_name
          : '-';
      },
    },
    {
      field: '',
      header: 'Approved/Rejected Date',
      body: rowData => {
        return rowData.approved_or_rejected_at
          ? dateFormatter(rowData.approved_or_rejected_at, 'MM-DD-YYYY hh:mm A')
          : '-';
      },
    },
  ];

  return main;
};
