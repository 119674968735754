import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: { color: theme.palette.primary.main },
  container: {
    marginTop: '10%',
    marginBottom: '4%',
    '& .MuiTypography-h2': { fontWeight: '400' },
  },
}));
