// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';

import { StatusColors } from '../../../constants';

export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  customerName: {
    textTransform: 'capitalize',
  },
  email: {
    textTransform: 'lowercase',
  },
  selfAlignGrid: {
    alignSelf: 'flex-end',
  },
  sourceName: {
    height: '53px',
    display: 'flex',
    alignItems: 'center',
  },
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
  statusColorActive: { color: StatusColors.PF_GREEN },
  statusColorInactive: { color: StatusColors.PF_RED },
}));
