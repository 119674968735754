import { createSlice } from '@reduxjs/toolkit';

// when we get into the services architecture
// import {} from '../services';

const initialState = {
  isLoading: true,
  isAnswerLoading: true,
  refreshList: false,
  isQuestionSaving: false,
  moduleList: [],
  moduleCount: 0,
  questionList: [],
  questionCount: 0,
  answerList: [],
  answerCount: 0,
  isQuestionDialogOpen: false,
  selectedModule: null,
  questionDialogContent: null,
  moduleDetail: null,
  isNewAnswer: false,
  refetchInspectionDetails: false,
  submittedFormDetails: null,
  hideFormPopup: false,
};

export const QuestionnaireActionTypes = {
  Loader: 'Loader',
  QuestionDialog: 'QuestionDialog',
  Module: 'Module',
  Question: 'Question',
  Answer: 'Answer',
};

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: initialState,
  reducers: {
    updateQuestionnaireStore(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { updateQuestionnaireStore } = questionnaireSlice.actions;

export const questionnaireReducer = questionnaireSlice.reducer;
