import React, { useState, useEffect } from 'react';

const LoginWelcome = () => {
  return (
    <React.Fragment>
      <div className="flex col-12 md:col-6 p-6 login-container-left">
        <div className="flex align-center text-center login-left-inner">
          <img src="/images/login-image.png" className="w-12" alt="dashboard" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginWelcome;
