import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

/* Services */
import { assignProjectItemUser } from './ProjectItemsService';

const AssignTechnicianDialog = props => {
  const {
    existingItems,
    technicians,
    selectedItem,
    projectId,
    onHide,
    visible,
    showAlert,
  } = props;

  const validationSchema = Yup.object().shape({
    selectedTechnician: Yup.number()
      .positive('Select a Technician')
      .required('Select a Technician'),
    allocationPercentage: Yup.number()
      .typeError('Allocation Percentage must be a valid number')
      .positive('Percentage Value Should be Greater Than 0')
      .integer()
      .min(1)
      .max(100)
      .required('Percentage is required'),
  });

  const projectTechnicians = props.technicians?.map(item => {
    const fname =
      item.installer_detail?.user?.first_name &&
      item.installer_detail?.user?.first_name?.length > 0
        ? item.installer_detail?.user?.first_name
        : '';
    const mname =
      item.installer_detail?.user?.middle_name &&
      item.installer_detail?.user?.middle_name?.length > 0
        ? ' ' + item.installer_detail?.user?.middle_name
        : '';
    const lname =
      item.installer_detail?.user?.last_name &&
      item.installer_detail?.user?.last_name?.length > 0
        ? ' ' + item.installer_detail?.user?.last_name
        : '';

    return {
      ...item,
      fullname: `${fname}${mname}${lname}`,
    };
  });

  const formik = useFormik({
    initialValues: {
      selectedTechnician: null,
      allocationPercentage: 0,
    },
    validationSchema,
    onSubmit: async values => {
      const { selectedTechnician, allocationPercentage } = values;

      let totalPercentage = 0;
      for (let i = 0; i < existingItems.length; i++) {
        const current = existingItems[i];
        if (current.project_item_id === selectedItem.project_item_id) {
          totalPercentage += current?.project_item_user?.percentage;
        }
      }

      if (!totalPercentage || isNaN(totalPercentage)) totalPercentage = 0;
      totalPercentage += allocationPercentage;
      if (totalPercentage > 100) {
        showAlert(
          'error',
          'Sum Of Allocation Of All Users Exceeds 100 Percentage, Please Use Another Value'
        );
        return;
      }

      const requestBody = {
        project_item_id: selectedItem.project_item_id,
        installer_id: selectedTechnician,
        percentage: allocationPercentage,
      };

      const assignResponse = await assignProjectItemUser(
        projectId,
        requestBody
      );

      if (assignResponse?.data?.status) {
        showAlert(
          'success',
          `Technician assigned successfully with ${allocationPercentage} percentage`
        );
        handleClose(true);
      } else {
        showAlert(
          'error',
          assignResponse?.data?.message
            ? assignResponse?.data?.message
            : 'Unable To Assign Technician'
        );
      }
    },
  });

  const handleClose = (projectItem = null, timeout = 0) => {
    formik.resetForm();

    if (timeout > 0) {
      setTimeout(() => {
        onHide(projectItem);
      }, timeout);
    } else {
      onHide(projectItem);
    }
  };

  return (
    <>
      <Dialog
        header="Assign Technician"
        visible={visible}
        onHide={() => handleClose()}
        className="w-5"
        pt={{
          header: { className: 'mb-0 pb-0' },
          content: { className: 'pt-2' },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-column">
            <Dropdown
              value={formik.values.selectedTechnician}
              options={projectTechnicians}
              optionValue="installer_id"
              optionLabel="fullname"
              placeholder="Select Technician"
              onChange={e =>
                formik.setFieldValue('selectedTechnician', e.value)
              }
              className="w-full"
              pt={{
                input: {
                  className: 'p-inputtext p-inputtext-sm',
                },
              }}
            />

            <span className="p-float-label mt-3">
              <InputNumber
                type="text"
                className="p-inputtext-sm w-full"
                id="allocationPercentage"
                name="allocationPercentage"
                value={formik.values.allocationPercentage}
                onChange={e => {
                  formik.setFieldValue(
                    'allocationPercentage',
                    !isNaN(parseInt(e.value)) ? parseInt(e.value) : null
                  );
                }}
              />
              <label htmlFor="allocationPercentage">
                Allocation Percentage
              </label>
            </span>
            <br />
            <div className="flex ml-auto">
              <Button
                label="Cancel"
                onClick={() => handleClose()}
                size="small"
                outlined
              />
              <Button
                label="Save"
                type="submit"
                size="small"
                className="ml-2"
                severity="primary"
                disabled={
                  !formik.isValid || !formik.dirty || formik.isSubmitting
                }
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default AssignTechnicianDialog;
