import { makeStyles } from '@material-ui/core/styles';

// Red Shades
const PF_DARK_RED = '#B72136';

//Green Shades
const PF_DARK_GREEN = '#229A16';

// //Yellow Shades
const PF_DARK_YELLOW = '#B78103';

export const useStyles = makeStyles(theme => ({
  boxColumn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '18%',
    },
  },
  boxExtendedColumn: {
    margin: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '62%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '37%',
    },
  },
  formWrapper: {
    width: '100%',
  },
  receiveLabel: { marginLeft: 0 },
  sourceSelect: {
    paddingBottom: '2px',
    height: '25px',
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  statusInput: props => ({
    color:
      (props.status === 'Cancelled' && PF_DARK_RED) ||
      (props.status === 'Completed' && PF_DARK_GREEN) ||
      PF_DARK_YELLOW,
  }),
  switch: {
    alignSelf: 'flex-start',
    marginLeft: '-10px',
  },
  noDropdownIcon: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
  iconBtn: {
    minWidth: '45px',
    maxWidth: '45px',
    '@media (max-width: 768px)': {
      minWidth: '100%',
      maxWidth: '100%',
    },
    '& .MuiButton-startIcon': {
      margin: '0px',
      '& svg': {
        fill: '#ffff !important',
      },
    },
  },
  boxBtn: {
    '& .Mui-disabled': {
      '& svg': {
        fill: '#b8bbbd !important',
      },
    },
  },
}));
