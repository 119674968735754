// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Lead Response Reason list
export const getEPALeadResponseOptions = async (
  setLoading,
  setEPALeadResponseOptions
) => {
  try {
    setLoading(true);

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reasons/epa-lead-response-list`
    );
    setEPALeadResponseOptions(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get EPA Override Reason list
export const getEPAOverrideReasonOptions = async (
  setLoading,
  setEPAOverrideReasonOptions
) => {
  try {
    setLoading(true);

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reasons/epa-override-reasons-list`
    );
    setEPAOverrideReasonOptions(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getHomeDepotOverrideReasons = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reason?reasonType=Override%20Reason&sourceSystemId=2`
    );
    const filteredData = response?.data?.filter(
      item => item?.reason_type === 'Override Reason'
    );
    return filteredData;
  } catch (error) {
    console.error(error);
  }
};

export const getHdEpaDocs = async projectId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/hd-epa-docs`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
