import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import PFButton from '../../shared/PFPrime/PFButton';
import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

export default function SyncDialog({
  searchVisible,
  setSearchVisible,
  syncDialogEnable,
  setSyncDialogEnable,
  syncData,
  getList,
  parentLoader,
  setAlert,
  config,
  syncProject,
}) {
  const { options = [] } = config;
  const [technician, setTechnician] = useState('N');
  const [schedule, setSchedule] = useState('N');
  const [documents, setDocuments] = useState('N');

  useEffect(() => {
    if (options?.length > 0) {
      if (options?.includes('technician')) {
        setTechnician('Y');
      }
      if (options?.includes('schedule')) {
        setSchedule('Y');
      }
      if (options?.includes('documents')) {
        setDocuments('Y');
      }
    }
  }, [options]);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Sync"
        visible={syncDialogEnable}
        className="w-11 lg:w-5"
        onHide={() => {
          setSyncDialogEnable(false);
          setSearchVisible(true);
        }}
      >
        <div className="text-sm mb-4">
          Kindly select the modules to sync data between Parent and Child Job
        </div>
        <div className="p-0 text-sm">
          <div className="grid py-2">
            <div className="col-1 flex align-items-center justify-content-center">
              <PFCheckbox
                onClick={e => setTechnician(e?.target?.checked ? 'Y' : 'N')}
                checked={technician === 'Y' ? true : false}
              />
            </div>
            <div className="col-11">
              Technician <br></br>
              Technician assigned on parent job will be auto assigned to child
              with same type
            </div>
          </div>

          <div className="grid py-2">
            <div className="col-1 flex align-items-center justify-content-center">
              <PFCheckbox
                onClick={e => setSchedule(e?.target?.checked ? 'Y' : 'N')}
                checked={schedule === 'Y' ? true : false}
              />
            </div>
            <div className="col-11">
              Schedule Date and Time<br></br>
              Schedule Start and End Date and Time would be auto copied from
              parent job to child.
            </div>
          </div>

          <div className="grid py-2">
            <div className="col-1 flex align-items-center justify-content-center">
              <PFCheckbox
                onClick={e => setDocuments(e?.target?.checked ? 'Y' : 'N')}
                checked={documents === 'Y' ? true : false}
              />
            </div>
            <div className="col-11">
              Documents <br></br>
              All Project document would be auto copied from parent job to
              child.
            </div>
          </div>

          <div className="grid pt-3">
            <div className="w-full flex justify-content-end">
              {options?.length === 0 && (
                <PFButton
                  label={`No`}
                  onClick={() => {
                    setSyncDialogEnable(false);
                  }}
                  className="mr-2"
                  outlined={true}
                  text={true}
                />
              )}

              <PFButton
                label={`${options?.length > 0 ? 'Sync & Save' : 'Yes'}`}
                onClick={() => {
                  const {
                    project_id: linked_project_id,
                    customer_id,
                    child_project: project_id,
                  } = syncData;

                  syncProject({
                    linked_project_id,
                    customer_id,
                    project_id,
                    technician,
                    schedule,
                    documents,
                  });
                  setSyncDialogEnable(false);
                  setSearchVisible(false);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
