/* global google */
import { Avatar } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from 'react-google-maps';

class MapDirectionsRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: null,
      error: null,
      routeIndex: null,
      selected: null,
    };
  }

  componentDidMount() {
    const { places, travelMode, routeIndex, polylineColor } = this.props;
    const waypoints = places?.map(p => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true,
    }));

    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
        provideRouteAlternatives: true,
        optimizeWaypoints: true,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
            routeIndex: routeIndex,
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>;
    }
    return (
      this.state.directions && (
        <>
          <DirectionsRenderer
            directions={this.state.directions}
            routeIndex={this.props.routeIndex || ''}
            options={{
              polylineOptions: {
                strokeOpacity: 1,
                strokeColor: this.props.polylineColor,
              },
              markerOptions: {
                label: '',
                // icon: `https://ui-avatars.com/api/?rounded=true&background=random&name=SourabhLalwani&font-size=0.25&length=2&size=128`,
              },
              suppressMarkers: true,
              clickable: true,
            }}
          ></DirectionsRenderer>

          {this?.props?.places?.map((marker, index) => {
            const routIndex = marker.isInstallerStartLocation
              ? 0
              : marker.job_sort_order;
            const position = { lat: marker.latitude, lng: marker.longitude };
            return (
              <Marker
                key={index}
                position={position}
                clickable={true}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    selected: marker,
                  });
                }}
                icon={{
                  url: `https://ui-avatars.com/api?rounded=true&background=${this.props.polylineColor.replace(
                    '#',
                    ''
                  )}&name=${marker.name}-${routIndex}&font-size=0.25&length=5&size=256&bold=true`,
                  scaledSize: new window.google.maps.Size(50, 50),
                  origin: new window.google.maps.Point(0, 0),
                }}
              />
            );
          })}

          {this.state.selected ? (
            <InfoWindow
              options={{ zoomControl: true }}
              position={{
                lat: parseFloat(
                  this.state.selected.client_lat || this.state.selected.latitude
                ),
                lng: parseFloat(
                  this.state.selected.client_long ||
                    this.state.selected.longitude
                ),
              }}
              onCloseClick={() => {
                // setSelected(null);
                this.setState({
                  ...this.state,
                  selected: null,
                });
              }}
            >
              <div
                style={{
                  width: '204px',
                  minHeight: '201px',
                  maxHeight: '350px',
                  backgroundColor: '#ffffff',
                }}
              >
                {/* <img
                  style={{
                    maxHeight: '190px',
                    backgroundColor: '#ffffff',
                  }}
                  src={
                    this.state.selected?.installer_image ||
                    this.state.selected?.logo_url ||
                    '/images/user-profile.svg'
                  }
                  width="100%"
                /> */}

                <div>
                  <h2
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '2px',
                    }}
                  >
                    {this.state.selected?.name}
                    <Avatar
                      alt="Remy Sharp"
                      src={`https://ui-avatars.com/api/?rounded=true&background=${this.props.colors[
                        this.state.selected?.key
                      ].hex.replace('#', '')}&name=${
                        this.state.selected?.Name
                      }&font-size=0.25&length=2&size=128`}
                    />
                  </h2>
                  <p>
                    <b>Stop Number</b>:
                    {this.state.selected?.isInstallerStartLocation
                      ? 0
                      : this.state.selected?.job_sort_order}{' '}
                  </p>
                  {!this.state.selected?.isInstallerStartLocation &&
                  this.state.selected?.customer_name ? (
                    <>
                      <p>
                        <b>Customer Name: </b>
                        {this.state.selected?.customer_name}{' '}
                      </p>
                      <p>
                        <b>Customer Address: </b>
                        {this.state.selected?.address}{' '}
                      </p>
                      <p>
                        <b>Category: </b> {this.state.selected?.job_category}{' '}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        <b> Address:</b>
                        {this.state.selected?.isInstallerEndLocation
                          ? this.state.selected?.to_address
                          : this.state.selected?.address}{' '}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </>
      )
    );
  }
}

const colors = ['#0000FF', 'Red', 'Green', '#ffc6a5', 'Brown'];

const RoutingMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      // defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
      center={props.defaultCenter}
      zoom={props.defaultZoom}
    >
      {props.showRoute == 'show' ? (
        <>
          {props?.apiResponse?.routed_job_by_provider ? (
            <>
              {Object.entries(props?.apiResponse?.routed_job_by_provider)
                ?.filter(([keys, val], index) => {
                  return (
                    val.length > 0 &&
                    (props.checkedInstaller
                      ? keys == props.checkedInstaller
                      : true)
                  );
                })
                ?.map(([key, val], index) => {
                  return (
                    <MapDirectionsRenderer
                      places={
                        val?.map(plc => {
                          return {
                            name: plc?.Name,
                            latitude: plc?.location?.lat,
                            longitude: plc?.location?.lon,
                            ...plc,
                            key: key,
                          };
                        }) || []
                      }
                      routeIndex={props.checkedInstaller}
                      travelMode={google.maps.TravelMode.DRIVING}
                      polylineColor={props.colors[key].hex}
                      colors={props.colors}
                    />
                  );
                })}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {props?.places?.map((marker, index) => {
            const position = { lat: marker.latitude, lng: marker.longitude };
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: `https://ui-avatars.com/api/?rounded=true&background=random&name=${marker.Name}&font-size=0.25&length=2&size=128`,
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                }}
                onClick={e => {
                  props.viewProjectDetails(marker);
                }}
                onDblClick={() => {
                  //setDefaultCenter({ lat: marker.client_lat, lng: marker.client_long });
                  props.setSelected(marker);
                  this.setState({
                    ...this.state,
                    selected: marker,
                  });
                }}
              />
            );
          })}

          {props?.installers?.map((marker, index) => {
            const position = { lat: marker.latitude, lng: marker.longitude };
            return (
              <Marker
                key={index}
                position={position}
                icon={{
                  url: `https://ui-avatars.com/api?rounded=false&name=${marker.Name}&font-size=0.25&length=2&size=256&bold=true`,
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                }}
              />
            );
          })}
        </>
      )}
    </GoogleMap>
  ))
);

export default RoutingMap;
