import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  industryTypes: [],
};

const industryTypesMasterSlice = createSlice({
  name: 'stateMaster',
  initialState: initialState,
  reducers: {
    setIndustryTypes(state, { payload }) {
      state.industryTypes = payload;
    },
  },
});

export const { setIndustryTypes } = industryTypesMasterSlice.actions;

export const industryTypesReducer = industryTypesMasterSlice.reducer;
