import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Card } from 'primereact/card';

import PFInputText from '../../PFPrime/PFInputText';

export default function ClientInfo({
  handleChange,
  values,
  errors,
  clientDetails,
  quoteDetails,
  isView,
  isEdit,
}) {
  const addr1 = clientDetails?.address?.address1;
  const addr2 = clientDetails?.address?.address2
    ? ` ${clientDetails?.address?.address2}`
    : '';
  const city = clientDetails?.address?.city;
  const state = clientDetails?.address?.state;
  const zipcode = clientDetails?.address?.zipcode;
  const customerBillingAddress =
    isView || isEdit
      ? `${quoteDetails?.customerBillingAddress}`
      : `${addr1}${addr2}, ${city}, ${state}, ${zipcode}`;
  return (
    <Card className="mt-3 w-full border-200 border-solid border-1">
      <div htmlFor="Client Info">
        <div className="w-12 text-1xl font-bold p-1">Client Info</div>
        <div className="grid flex flex-row flex-wrap w-full m-0 mt-2">
          <div className="formgroup-inline w-full">
            <div className="quote-expiry-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputText
                  id="clientName"
                  name="clientName"
                  type="text"
                  value={`${clientDetails?.first_name || ''} ${clientDetails?.last_name || ''}`}
                  disabled={true}
                  placeholder="Name"
                />
              </span>
              <div style={{ color: 'red' }} className="px-1">
                {errors?.clientName || null}
              </div>
            </div>
            <div className="quote-expiry-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputText
                  id="email"
                  name="email"
                  type="text"
                  value={clientDetails?.customer_emails?.[0]?.email || ''}
                  disabled={true}
                  placeholder="Email"
                />
              </span>
            </div>
            <div className="quote-followup-date align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputText
                  inputId="phone"
                  name="phone"
                  value={clientDetails?.primary_phone || ''}
                  disabled={true}
                  placeholder="Phone Number"
                />
              </span>
            </div>
            <div className="quote-status align-items-center justify-content-center w-3">
              <span className="p-float-label p-1">
                <PFInputText
                  id="customerBillingAddress"
                  name="customerBillingAddress"
                  type="text"
                  value={customerBillingAddress}
                  disabled={true}
                />
              </span>
            </div>
            <div className="grid flex flex-row flex-wrap w-full m-0 py-1">
              <div className="quote-recurrence align-items-center justify-content-center w-3">
                <span className="p-float-label p-1">
                  <PFInputText
                    id="customerServiceAddress"
                    name="customerServiceAddress"
                    type="text"
                    value={values?.customerServiceAddress || ''}
                    onChange={handleChange}
                    disabled={isView}
                  />
                  <label htmlFor="customerServiceAddress">
                    Service Address
                  </label>
                </span>
                <div style={{ color: 'red' }} className="px-1">
                  {errors?.customerServiceAddress || null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
