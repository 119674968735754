import { useState } from 'react';
import { ListBox } from 'primereact';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFDialog from '../../../shared/PFPrime/PFDialog';

const PreviewTable = ({ data, isShowPreviousDetails }) => {
  const [showCoverageValues, setShowCoverageValues] = useState([]);
  const previewTableColumns = [
    {
      field: 'type',
      header: 'Type',
      body: record => {
        if (isShowPreviousDetails && record.previousType) {
          return (
            <span className="flex gap-2">
              {record.previousType} <i className="pi pi-arrow-right"></i>{' '}
              {record.type}
            </span>
          );
        }
        return record.type;
      },
    },
    {
      field: 'category',
      header: 'Category',
      body: record => {
        if (isShowPreviousDetails && record.previousCategory) {
          return (
            <span className="flex gap-2">
              {record.previousCategory} <i className="pi pi-arrow-right"></i>{' '}
              {record.category}
            </span>
          );
        }
        return record.category;
      },
    },
    {
      field: 'coverageBy',
      header: 'Coverage By',
      body: record => {
        if (isShowPreviousDetails && record.previousCoverageBy) {
          return (
            <span className="flex gap-2">
              {record.previousCoverageBy} <i className="pi pi-arrow-right"></i>{' '}
              {record.coverageBy}
            </span>
          );
        }
        return record.coverageBy;
      },
    },
    {
      field: 'coverageValues',
      header: 'Coverage Values',
      body: record => {
        return (
          <PFButton
            label={`${record.coverageValues?.length || 0} Selected`}
            size="small"
            text
            severity="Primary"
            className="mx-2 md:mb-2"
            onClick={() => {
              setShowCoverageValues(record.coverageValues);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <PFDataTable
        columns={previewTableColumns}
        data={data}
        stripedRows
        tableStyle={{ minWidth: '50rem' }}
        stripedRows={true}
      />
      {showCoverageValues.length ? (
        <PFDialog
          header="Coverage Values"
          show={true}
          style={{ width: '15vw' }}
          hide={() => setShowCoverageValues([])}
          BodyComponent={
            <div className="card flex justify-content-center">
              <ListBox
                options={showCoverageValues}
                className="w-full md:w-14rem"
              />
            </div>
          }
        ></PFDialog>
      ) : null}
    </>
  );
};

export default PreviewTable;
