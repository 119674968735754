import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  toggleButtonLabel: 'Select All',
  clientList: [],
};

const routeConfigSlice = createSlice({
  name: 'routeConfig',
  initialState: initialState,
  reducers: {
    routeConfigUpdateClientList(state, { payload }) {
      state.clientList = payload.hasOwnProperty('clientList')
        ? payload.clientList
        : state.clientList;
    },
    routeConfigUpdateComponent(state, { payload }) {
      state.isLoading = payload.hasOwnProperty('isLoading')
        ? payload.isLoading
        : state.isLoading;
      state.toggleButtonLabel = payload.hasOwnProperty('toggleButtonLabel')
        ? payload.toggleButtonLabel
        : state.toggleButtonLabel;
    },
  },
});

export const { routeConfigUpdateClientList, routeConfigUpdateComponent } =
  routeConfigSlice.actions;

export const routeConfigReducer = routeConfigSlice.reducer;
