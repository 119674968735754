import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import { useParams } from 'react-router';

import SkeletonLoader from '../../../shared/Loader/skeleton';
import usePFDataTable from '../../../shared/PFPrime/PFDatatable.jsx';
import PFButton from '../../../shared/PFPrime/PFButton.js';
import {
  createExternalProject,
  quoteGridColumns,
  quoteGridData,
  tableDataMS,
} from '../helpers/MeasureSquareQuotes.js';

const MeasureSquareQuotes = props => {
  const isGlobalLevel = props?.isGlobalLevel || false;
  let { customerId, opportunityId, projectId } = useParams();
  customerId = customerId || props?.projectDetails?.customer_id;
  opportunityId =
    opportunityId || props?.projectDetails?.project_crm?.opportunity_id;
  const toast = useRef(null);

  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();
  const [loading, setLoading] = useState(false);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [dataCount, setDataCounts] = useState(0);

  const gridList = () => {
    setLoading(true);
    quoteGridData(customerId, opportunityId, rows, page, sortBy, orderBy)
      .then(res => {
        if (res?.status === 200) {
          tableData(res?.data?.data?.result || []);
          totalRecords(res?.data?.data?.count || 0);
          setDataCounts(res?.data?.data?.count || 0);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
      });
  };

  const createMeasureSquareProject = () => {
    setLoading(true);
    const data = {
      project_id: projectId || null,
      quote_id: props?.quoteId || null,
      opportunity_id: opportunityId || null,
      client_id: localStorage.getItem('client_id') || null,
    };

    const newProject = createExternalProject(data, toast, gridList, setLoading);
  };

  useEffect(() => {
    tableData([]);
    totalRecords(0);
    DataTableColumns(quoteGridColumns(toast, setLoading, gridList));
  }, []);

  useEffect(() => {
    if (customerId) {
      gridList();
    }
  }, [rows, page, sortBy, orderBy, customerId, opportunityId]);

  return (
    <>
      <Tooltip target=".disabled-button" />
      <div className="px-3 py-1">
        <Toast ref={toast}></Toast>
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="col-12 md:col-12 lg:col-12">
              <div className="w-full flex justify-content-end">
                <PFButton
                  icon="pi pi-refresh"
                  label="Refresh"
                  outlined={true}
                  onClick={() => gridList()}
                  className="mr-2"
                />
                {dataCount === 0 && !isGlobalLevel && (
                  <PFButton
                    icon="pi pi-plus"
                    label="Create project in MeasureSquare"
                    outlined={false}
                    onClick={() => createMeasureSquareProject()}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!props?.msProjectInstaller || false}
                    tooltip={`${
                      !props?.msProjectInstaller
                        ? 'Cannot create project in Measure Square without Technician'
                        : ''
                    }`}
                  />
                )}
              </div>
            </div>
            <TableLayout />
          </>
        )}
      </div>
    </>
  );
};

export default MeasureSquareQuotes;
