import React, { useEffect, useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { Button } from 'primereact/button';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import useToken from '../../../../hooks/useToken';
import { decodeUserToken } from '../../../../utils/Helpers';
import { setClientId } from '../../../../redux/slices/page-header.slice';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

const Tenant = () => {
  const dispatch = useDispatch();
  const [user_id, setUserId] = useState('');
  const [firstTenantsId, setFirstTenantsId] = useState('');
  const [tenants, setTenants] = useState([]);
  const { accessToken, logout } = useToken();
  const history = useHistory();

  useEffect(() => {
    const decodeToken = accessToken && decodeUserToken(accessToken);
    setUserId(decodeToken.sub);

    getAllUserTenants();
  }, [user_id]);

  const getAllUserTenants = async () => {
    const response = await axios.get(
      `${URL_CONSTANTS.LOGIN.baseUrl}/get-all-user-tenants?user_id=${user_id}`
    );
    if (response?.data) {
      setTenants(response?.data);
      const firstTenantsId = response?.data[1]?.client_id;
      if (firstTenantsId) {
        localStorage.setItem('client_id', firstTenantsId);
        dispatch(setClientId(firstTenantsId));
        getAllUserPermissionsForTenant(firstTenantsId);
        setFirstTenantsId(firstTenantsId);
      }
    } else {
      logout();
    }
  };

  const getAllUserPermissionsForTenant = async tenant_id => {
    try {
      const response = await axios.get(
        `${URL_CONSTANTS.LOGIN.baseUrl}/get-all-user-permissions-for-tenant?client_id=${tenant_id}&user_id=${user_id}`
      );
      if (response?.data) {
        localStorage.setItem('permissions', JSON.stringify(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const switchTenant = async client_id => {
    dispatch(setClientId(String(client_id)));
    getAllUserPermissionsForTenant(client_id).then(() =>
      history.push('/dashboard')
    );
  };

  return (
    <>
      <ul className="pl-0 tanent-name">
        {tenants.length &&
          tenants.map((item, index) => {
            return (
              <li
                className="flex align-items-center"
                key={`side-menu-${index}`}
              >
                <Button
                  onClick={() => switchTenant(item.client_id)}
                  link
                  style={{ color: '#495057' }}
                >
                  {item.client_name}
                </Button>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default Tenant;
