// **** External Utilities ****
import axios from 'axios';

import { CONFIG, mockFilterDataByDimension } from '../../../../constants';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

import { setFiltersForWidgetType } from './utils';

// Get Data Source List
export const getDataSource = async (setLoadingList, setDataSource) => {
  try {
    let requiredCubes = [
      'Project',
      'Customer',
      'Store',
      'InstallerDetail',
      'PaymentRequest',
      'ProjectType',
      'ProjectCategory',
      'SourceStatus',
      'ProjectStatus',
      'UserMeta',
      'ProjectSupport',
      'ProjectPermit',
      'PermitStatus',
      'SupportType',
      'ConfirmationStatus',
    ];
    setLoadingList(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/cubes`
    );
    response?.data?.filter(fil => requiredCubes.find(f => f === fil.name));
    setDataSource(
      response?.data?.filter(fil =>
        requiredCubes.find(cube => cube === fil.name)
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    setLoadingList(false);
  }
};

// Create Widget Template
export const createWidgetTemplate = async (
  values,
  filtersList,
  customfiltersValues,
  history,
  setLoading,
  setCreateWidgetDialogOpen,
  setParentAlert,
  setAlert
) => {
  try {
    setLoading(true);
    let widgetTemplateObj = {};
    widgetTemplateObj = {
      widget_name: values?.name,
      widget_description: values?.description,
      editable: 1,
      resizable: 0,
      ungrouped: true,
      widget_display_type: [values?.chartType?.key],
      data_source: [
        {
          dimensions: ['bar-chart', 'line-chart', 'pie-chart'].includes(
            values?.chartType?.key
          )
            ? values?.dataSource?.dimensions
            : [],
          ...(customfiltersValues.length > 0 && {
            filters: customfiltersValues,
          }),

          ...(customfiltersValues.length && {
            filters: customfiltersValues

              ?.filter(response => response.type !== 'time')

              .map(item => {
                delete item.type;

                return item;
              }),
          }),
          measures: values?.dataSource?.measures,
          timeDimensions:
            values?.dataSource?.timeDimensions?.filter(
              ({ dimension }) => !!dimension
            ) || [],
        },
      ],
      filters: [],
      custom_properties: {
        ...values?.customProperties,
        ...{ filters: filtersList },
      },
      private: 0,
    };

    let on_click_intent = null;
    let on_click_intent_args = [];
    if (values.chartType.key) {
      let order = {};
      if (values?.clickArguments?.order?.columnSelected?.name) {
        order[values.clickArguments.order.columnSelected.name] =
          values?.clickArguments?.order?.order || 'desc';
      }
      let dimensions = [];
      let table_alias = {};
      values &&
        values?.clickArguments?.fields?.map(response => {
          dimensions.push(response?.column?.name);
          table_alias[response?.column?.name] = response.alias;
        });
      switch (values.chartType.key) {
        case 'gauge':
        case 'bar-chart':
        case 'line-chart':
        case 'pie-chart':
          on_click_intent = 'table';
          on_click_intent_args = [
            {
              ...(order.length && {
                order: order,
              }),
              ...((customfiltersValues.length && {
                filters: customfiltersValues

                  ?.filter(response => response.type !== 'time')

                  .map(item => {
                    delete item.type;

                    return item;
                  }),
              }) ??
                {}),
              dimensions: dimensions,
              measures: values?.dataSource?.measures,
              timeDimensions:
                values?.dataSource?.timeDimensions?.filter(
                  ({ dimension }) => !!dimension
                ) || [],
            },
          ];
          break;
        case 'external-link-button':
          on_click_intent = 'url';
          break;
        default:
      }
      widgetTemplateObj.on_click_intent = on_click_intent;
      widgetTemplateObj.on_click_intent_args = on_click_intent_args;
      widgetTemplateObj.custom_properties = {
        ...widgetTemplateObj?.custom_properties,
        ...{ table_alias: table_alias },
        ...(values?.clickArguments
          ? { clickArguments: values?.clickArguments }
          : {}),
        ...(values?.dataSource?.filters
          ? { dataSourceFilters: values?.dataSource?.filters }
          : []),
      };
    }

    if (values?.customProperties?.widgetType) {
      widgetTemplateObj.data_source[0].filters = setFiltersForWidgetType(
        values?.customProperties?.widgetType,
        widgetTemplateObj.data_source[0].filters
      );
      widgetTemplateObj.on_click_intent_args[0].filters =
        setFiltersForWidgetType(
          values?.customProperties?.widgetType,
          widgetTemplateObj.data_source[0].filters
        );
    }

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates`,
      widgetTemplateObj
    );

    if (response?.data) {
      history.push(`/admin/widget/view/${response?.data[0]?.WidgetTemplateId}`);
      setAlert('success', 'Widget Created successfully.');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
    console.log(error);
  } finally {
    setLoading(false);
  }
};
export const updateWidgetTemplate = async (
  widgetId,
  values,
  filtersList,
  customfiltersValues,
  history,
  setLoading,
  setCreateWidgetDialogOpen,
  setParentAlert,
  setAlert
) => {
  try {
    setLoading(true);
    let widgetTemplateObj = {};
    widgetTemplateObj = {
      widget_name: values?.name,
      widget_description: values?.description,
      editable: 1,
      resizable: 0,
      ungrouped: true,
      widget_display_type: [values?.chartType?.key],
      data_source: [
        {
          dimensions: ['bar-chart', 'line-chart', 'pie-chart'].includes(
            values?.chartType?.key
          )
            ? values?.dataSource?.dimensions
            : [],
          ...(customfiltersValues.length && {
            filters: customfiltersValues

              ?.filter(response => response.type !== 'time')

              .map(item => {
                delete item.type;

                return item;
              }),
          }),
          measures: values?.dataSource?.measures,
          timeDimensions:
            values?.dataSource?.timeDimensions?.filter(
              ({ dimension }) => !!dimension
            ) || [],
        },
      ],
      filters: [],
      custom_properties: {
        ...values?.customProperties,
        ...{ filters: filtersList },
      },
      private: 0,
    };

    let on_click_intent = null;
    let on_click_intent_args = [];
    if (values.chartType.key) {
      let order = {};
      if (values?.clickArguments?.order?.columnSelected) {
        order[values?.clickArguments?.order?.columnSelected?.name] =
          values?.clickArguments?.order?.order || 'desc';
      }
      let dimensions = [];
      let table_alias = {};
      values &&
        values?.clickArguments?.fields?.map(response => {
          dimensions.push(response?.column?.name);
          table_alias[response?.column?.name] = response.alias;
        });
      switch (values.chartType.key) {
        case 'gauge':
        case 'bar-chart':
        case 'line-chart':
        case 'pie-chart':
          on_click_intent = 'table';
          on_click_intent_args = [
            {
              ...(order && {
                order: order,
              }),
              ...((customfiltersValues.length && {
                filters: customfiltersValues

                  ?.filter(response => response.type !== 'time')

                  .map(item => {
                    delete item.type;

                    return item;
                  }),
              }) ??
                {}),
              dimensions: dimensions,
              measures: values?.dataSource?.measures,
              timeDimensions:
                values?.dataSource?.timeDimensions?.filter(
                  ({ dimension }) => !!dimension
                ) || [],
            },
          ];
          break;
        case 'external-link-button':
          on_click_intent = 'url';
          break;
        default:
      }
      widgetTemplateObj.on_click_intent = on_click_intent;
      widgetTemplateObj.on_click_intent_args = on_click_intent_args;
      widgetTemplateObj.custom_properties = {
        ...widgetTemplateObj.custom_properties,
        ...{ table_alias: table_alias },
        ...(values.clickArguments
          ? { clickArguments: values.clickArguments }
          : {}),
        ...(values.dataSource.filters
          ? { dataSourceFilters: values.dataSource.filters }
          : []),
      };
    }

    if (values?.customProperties?.widgetType) {
      widgetTemplateObj.data_source[0].filters = setFiltersForWidgetType(
        values?.customProperties?.widgetType,
        widgetTemplateObj.data_source[0].filters
      );
      widgetTemplateObj.on_click_intent_args[0].filters =
        setFiltersForWidgetType(
          values?.customProperties?.widgetType,
          widgetTemplateObj.data_source[0].filters
        );
    }

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/${widgetId}`,
      widgetTemplateObj
    );

    if (response?.data) {
      history.push(`/admin/widget/view/${widgetId}`);
      setAlert('success', 'Widget updated successfully.');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//GetwidgetDataById
export const getWidgetDataById = async (
  widgetId,
  setLoading,
  setAlert,
  setWidgetTemplateData
) => {
  try {
    setLoading(true);
    await axios
      .get(
        `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/${widgetId}`
      )
      .then(response => {
        setWidgetTemplateData(response?.data);
      });
  } catch (error) {
    setAlert('error', 'Error in Fetching Widget Data');
  } finally {
    setLoading(false);
  }
};
//getFiltersByDimension

export const getFiltersByDimension = async (
  cubeName,
  dimensionName,
  filtersDataByDimension,
  setFiltersDataByDimension,
  index
) => {
  if (!cubeName) return;
  try {
    //setLoadingList(true);

    let response = mockFilterDataByDimension[dimensionName];

    if (!response) {
      response = await axios.get(
        `${URL_CONSTANTS.API.BASE_URL}/system/cubes/master?cube=${cubeName}&dimension=${dimensionName}`
      );
    }

    let filtersDataByDimensionCopy = [...filtersDataByDimension];
    filtersDataByDimensionCopy[index] = response?.data || response;
    setFiltersDataByDimension(filtersDataByDimensionCopy);
  } catch (error) {
    console.log(error);
  } finally {
    //setLoadingList(false);
  }
};

export const cloneWidget = async (
  {
    client_id,
    created_at,
    modified_at,
    owner_name,
    owner_user_id,
    widget_template_id,
    widget_name,
    ...widgetTemplateObj
  },
  newWidgetName,
  setLoading,
  setAlert
) => {
  try {
    const newWidgetObj = { ...widgetTemplateObj, widget_name: newWidgetName };
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates`,
      newWidgetObj
    );

    if (response?.data) {
      setAlert('success', 'Widget copied successfully.');
      return {
        ...newWidgetObj,
        widget_template_id: response?.data[0]?.WidgetTemplateId,
      };
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

export const deleteWidget = async (widgetId, setLoading, setAlert) => {
  try {
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/${widgetId}`
    );
    if (response.data.status) {
      setAlert('success', 'Widget deleted successfully', false, true);
      return true;
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

export const assignWidgets = async payload => {
  const assignWidgetsResponse = await axios.patch(
    `${CONFIG.API_BASE_URL}/dashboard/widgets/assign-widgets-to-clients`,
    payload
  );
  return assignWidgetsResponse;
};

export const assignWidgetsValidate = async payload => {
  const assignWidgetsResponse = await axios.post(
    `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/validate-assign-to-clients`,
    payload
  );
  return assignWidgetsResponse?.data;
};
