import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

//to get the Logs list
export const getLogsList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setLogListData,
  setLogListCount
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters).length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        .filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val.toString())}`
        )
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/rule-engine/list?${newQueryString}`
    );

    setLogListData(response?.data?.rows);
    setLogListCount(response?.data?.total);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//Triggering Pipeline Logs
export const triggerPipelineLogs = async (
  createObj,
  setLoading,
  setAlert,
  setReloadForm,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    let createRequestObj = {
      pipeline_event_id: createObj.pipeline_event_id,
    };

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/rule-engine/trigger-event`,
      createRequestObj
    );
    if (response?.status === 200) {
      setReloadForm(true);
      setAlert('success', 'Logs Triggered successfully.');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};
