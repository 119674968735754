import React from 'react';
import { useParams } from 'react-router';
/*Prime Imports*/
import { TabView, TabPanel } from 'primereact/tabview';

/*Custom Components*/
import ChargebackSummary from './ChargebackTabs/ChargebackSummary';
import Photos from './ChargebackTabs/Photos';
import Notes from './ChargebackTabs/Notes';
import MerchandiseItems from './ChargebackTabs/MerchandiseItems';
import LaborItems from './ChargebackTabs/LaborItems';
import DeclineReason from './ChargebackTabs/DeclineReason';
import Payout from './ChargebackTabs/Payout';

const ChargebackTabs = ({
  toast,
  chargebackFormik,
  disabledFields,
  activeIndex,
  setActiveIndex,
  isLoading,
}) => {
  const { action, projectId } = useParams();

  const chargeFormTabs = [
    {
      header: 'Chargeback Summary',
      component: ChargebackSummary,
      visible: true,
      formik: chargebackFormik,
    },
    { header: 'Photos', component: Photos, visible: action !== 'add' },
    { header: 'Notes', component: Notes, visible: action !== 'add' },
    {
      header: 'Products',
      component: MerchandiseItems,
      visible: action !== 'add',
    },
    { header: 'Labor Items', component: LaborItems, visible: action !== 'add' },
    {
      header: 'Decline Reason',
      component: DeclineReason,
      visible: action !== 'add',
      formik: chargebackFormik,
    },
    { header: 'Details', component: Payout, visible: action !== 'add' },
  ];
  return (
    <div className="card">
      <TabView
        onTabChange={e => setActiveIndex(e.index)}
        activeIndex={activeIndex}
      >
        {chargeFormTabs.map(tab => {
          const Component = tab.component;
          return (
            tab.visible && (
              <TabPanel
                key={tab.header}
                header={tab.header}
                className="min-h-full pt-3"
              >
                <Component
                  toast={toast}
                  formik={tab?.formik}
                  disabledFields={disabledFields}
                  isLoading={isLoading}
                />
              </TabPanel>
            )
          );
        })}
      </TabView>
    </div>
  );
};

export default ChargebackTabs;
