import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { number } from 'prop-types';

import { CONFIG } from '../../../../constants';

export const initialValues = {
  itemId: '',
  itemDescription: '',
  quantity: '',
  unitPrice: '',
  discountType: 'Amount',
  discountAmount: '',
  discount: 0,
  tax: 0,
  taxAmount: '',
  totalPrice: '',
};
const validationSchema = values => {
  return Yup.object().shape({
    itemId: Yup.number().required('required'),
    quantity: Yup.number().required('required').nullable(),
    unitPrice: Yup.number().required('required').nullable(),
    discount:
      values?.discountType === 'Amount'
        ? Yup.number()
            .nullable()
            .max((values?.quantity || 0) * (values?.unitPrice || 0), () => {
              const maxAmount =
                (values?.quantity || 0) * (values?.unitPrice || 0);
              return `Discount must be less or equal to $${maxAmount.toFixed(2)}`;
            })
        : Yup.number()
            .nullable()
            .max(100, () => {
              return `Discount must be less or equal to 100%`;
            }),
    tax: Yup.number()
      .nullable()
      .max(100, () => {
        return `Tax must be less or equal to 100%`;
      }),
  });
};

export const itemSubmit = (
  setGridData,
  setIsForm,
  formInitValue,
  editIndex,
  setEditIndex
) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitValue,
    validationSchema: () => validationSchema(formik.values),
    onSubmit: async values => {
      if (typeof editIndex === 'number') {
        setGridData(preValues => {
          const newValues = [...preValues];
          newValues[editIndex] = {
            ...values,
            itemDescription:
              values?.itemDescription || formInitValue?.itemDescription,
            itemNumber: values?.itemNumber || formInitValue?.itemNumber,
          };
          return newValues;
        });
        setEditIndex(null);
        setIsForm(false);
      } else {
        setGridData(preValues => [values, ...preValues]);
        setIsForm(false);
      }
    },
  });

  return formik;
};

export const lineItemList = async (searchString, typeId = 6) => {
  let url = `${CONFIG.API_BASE_URL}/system/item-types/${typeId}/items`;
  if (searchString) {
    url = `${url}/?limit=100&offset=0&query=${searchString}`;
  }
  return await axios.get(url);
};
