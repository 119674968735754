import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  footerContainer: {
    position: 'relative',
    bottom: 0,
    paddingTop: '16px',
    borderTop: `1px solid ${theme.palette.grey[700]}`,
    height: '30px',
    marginTop: '10px',
  },
  textColorPrimary: {
    color: theme.palette.primary.main,
  },
}));
