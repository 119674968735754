import moment from 'moment-timezone';
import {
  format,
  subDays,
  addDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfWeek,
} from 'date-fns';

import { getLoginUserId } from '../../../../utils/Helpers';
import { DATE_OPERATORS } from '../../../../constants';

export const getChartQuery = params => {
  const where = [];
  const timeDimensions = [];
  let ignoreCondition = false;
  const filters =
    params?.chart_filter_conditions &&
    params?.chart_filter_conditions?.filters?.length
      ? params?.chart_filter_conditions?.filters
      : [];
  filters.forEach(item => {
    const innerCondition = [];
    item?.fields?.forEach(innerRecord => {
      if (!innerRecord.tableField) {
        ignoreCondition = true;
      }
      let operator = innerRecord.operator;

      if (
        innerRecord.isTimeFilter &&
        !innerRecord.operatorType &&
        !innerRecord.operatorType
      ) {
        calculateDateRange(innerRecord, innerCondition);
      } else if (
        innerRecord.isTimeFilter &&
        innerRecord.operatorType == 'daysField' &&
        innerRecord.operator != 'custom'
      ) {
        timeDimensions.push({
          dimension: innerRecord.tableField,
          dateRange: innerRecord.operator.replace(
            ' x ',
            ` ${innerRecord.value} `
          ),
        });
      } else if (
        innerRecord.isTimeFilter &&
        innerRecord.operatorType == 'datePicker'
      ) {
        operator = innerRecord.dateFilterOperatorType;
        innerCondition.push({
          member: innerRecord.tableField,
          values: [innerRecord.value],
          operator: operator,
        });
      } else if (innerRecord.operatorType == 'nullField') {
        innerCondition.push({
          member: innerRecord.tableField,
          values: [null],
          operator: innerRecord.dateFilterOperatorType,
        });
      } else {
        innerCondition.push({
          member: innerRecord.tableField,
          values: [innerRecord.value.toString()],
          operator: operator,
        });
      }
    });

    const finalCondition = [...innerCondition];

    where.push({
      [item.condition]: finalCondition,
    });
  });
  const groupByDimension =
    params?.chart_configuration?.groupBy?.map(
      groupByRecord => groupByRecord.name
    ) || [];

  // let dimensions =
  // params?.chart_configuration?.dimension && !params?.chart_configuration?.isPrimaryKeySelected
  //   ? [params.chart_configuration.dimension]
  //   : groupByDimension;

  let dimensions = groupByDimension;
  let measures = params?.chart_configuration?.measureSummarizedBy
    ? [params.chart_configuration.measureSummarizedBy]
    : [];

  const orderBy =
    params?.chart_configuration?.orderByField &&
    params?.chart_configuration?.orderBy
      ? [
          [
            params.chart_configuration.orderByField,
            params.chart_configuration.orderBy,
          ],
        ]
      : [];
  const query = {
    // limit: params.tableLimit || params?.chart_configuration?.measureTarget,
    measures: measures,
    dimensions: dimensions,
    timeDimensions: timeDimensions,
    order: orderBy,
    filters: ignoreCondition
      ? []
      : [{ [params.chart_filter_conditions.condition]: [...where] }],
  };
  return query;
};

export const getTableQuery = params => {
  const where = [];
  const timeDimensions = [];
  const measures = [];
  let ignoreCondition = false;
  params?.table_filter_conditions?.filters?.forEach(item => {
    const innerCondition = [];
    item.fields.forEach(innerRecord => {
      if (!innerRecord.tableField) {
        ignoreCondition = true;
      }
      let operator = innerRecord.operator;

      if (
        innerRecord.isTimeFilter &&
        !innerRecord.operatorType &&
        !innerRecord.operatorType
      ) {
        calculateDateRange(innerRecord, innerCondition);
      } else if (
        innerRecord.isTimeFilter &&
        innerRecord.operatorType == 'daysField' &&
        innerRecord.operator != 'custom'
      ) {
        timeDimensions.push({
          dimension: innerRecord.tableField,
          dateRange: innerRecord.operator.replace(
            ' x ',
            ` ${innerRecord.value} `
          ),
        });
      } else if (
        innerRecord.isTimeFilter &&
        innerRecord.operatorType == 'datePicker'
      ) {
        operator = innerRecord.dateFilterOperatorType;
        innerCondition.push({
          member: innerRecord.tableField,
          values: [innerRecord.value],
          operator: operator,
        });
      } else if (innerRecord.operatorType == 'nullField') {
        innerCondition.push({
          member: innerRecord.tableField,
          values: [null],
          operator: innerRecord.dateFilterOperatorType,
        });
      } else {
        innerCondition.push({
          member: innerRecord.tableField,
          values: [innerRecord.value.toString()],
          operator: operator,
        });
      }
    });
    const finalCondition = [...innerCondition];
    where.push({
      [item.condition]: finalCondition,
    });
  });
  let dimensionsList =
    params?.table_configuration?.map(item => item.measureName) || [];
  if (params?.report_group_by) {
    dimensionsList = [params.report_group_by.dimension];
    if (params?.report_group_by?.primaryKeyMeasureName) {
      measures.push(params?.report_group_by?.primaryKeyMeasureName);
    }
    /* if (params?.report_group_by_aggr && Object.entries(params?.report_group_by_aggr).length) {
      for (const key in params?.report_group_by_aggr) {
        if (params?.report_group_by_aggr[key]?.measureSummarizedBy)
          measures.push(params?.report_group_by_aggr[key].measureSummarizedBy);
      }
    } */
  }
  const order = [];
  if (params?.order_by?.orderBy && params?.order_by?.orderField) {
    order.push([params?.order_by?.orderField, params?.order_by?.orderBy]);
  }

  const query = {
    measures: Array.isArray(params?.measures)
      ? [...new Set(params?.measures)]
      : measures,
    limit: params?.tableLimit,
    dimensions:
      dimensionsList && dimensionsList.length
        ? [...new Set(dimensionsList)]
        : [],
    timeDimensions: timeDimensions,
    order: order,
    filters: ignoreCondition
      ? []
      : [{ [params?.table_filter_conditions.condition]: [...where] }],
    // ungrouped: true,
  };
  return query;
};

export const mapResponseToFormik = res => {
  return {
    widget_type: res.widget_display_type[0],
    widget_name: res.widget_name,
    widget_description: res.widget_description,
    chart_configuration: res.chart_configuration,
    table_configuration: res.table_configuration,
    chart_filter_conditions: res.chart_filter_conditions,
    table_filter_conditions: res.table_filter_conditions,
    order_by: res.order_by,
    widget_filter_type: res.widget_filter_type,
    custom_properties: res.custom_properties,
    editable: res.editable,
    owner_user_id: res.owner_user_id,
    route_widget: res.route_widget || 0,
    report_group_by: res?.report_group_by,
    report_group_by_aggr: res?.report_group_by_aggr,
  };
};
export const prepareChartQueryWithFilters = ({
  query,
  formattedQuery,
  filterDetails,
}) => {
  widgetTypeFilter(query, formattedQuery, filterDetails);
  return formattedQuery;
};
export const prepareQueryWithFilters = ({
  tablePagination,
  query,
  formattedQuery,
  applyFilters,
  cubeResponse,
  isCountQuery = false,
  filterDetails,
  orderByConfiguration,
  paginator,
}) => {
  widgetTypeFilter(query, formattedQuery, filterDetails);
  globalFilter(applyFilters, formattedQuery);
  OrderBy(orderByConfiguration, formattedQuery);
  if (!isCountQuery) {
    formattedQuery.limit = paginator.perPage;
    formattedQuery.offset = paginator.currentPage * paginator.perPage;
  }
  getColumnFilters(tablePagination, cubeResponse, formattedQuery);
  return formattedQuery;
};
function OrderBy(orderByConfiguration, formattedQuery) {
  if (orderByConfiguration?.columns && orderByConfiguration?.direction) {
    formattedQuery.order = [
      [
        orderByConfiguration?.columns,
        orderByConfiguration?.direction > 0 ? 'asc' : 'desc',
      ],
    ];
  }
}

function globalFilter(applyFilters, formattedQuery) {
  if (applyFilters && homeFilters.length) {
    formattedQuery.filters.push(...homeFilters);
  }
  if (applyFilters && homeFilterDateDimension?.length) {
    homeFilterDateDimension.forEach(timeRecord => {
      formattedQuery?.timeDimensions?.push({
        dimension: timeRecord.dimension,
        dateRange: timeRecord.dateRange,
      });
    });
  }
}

function calculateDateRange(innerRecord, innerCondition) {
  let today = new Date();
  let dateOperator = innerRecord.operator;
  let operator = innerRecord.dateFilterOperatorType;
  const multiDateOperators = {
    equals: 'inDateRange',
    notEquals: 'notInDateRange',
    beforeDate: 'beforeDate',
    afterDate: 'afterDate',
  };
  let dateValues = [];
  switch (true) {
    case dateOperator === DATE_OPERATORS?.TODAY:
      dateValues = [format(new Date(), 'yyyy-MM-dd')];
      break;
    case dateOperator === DATE_OPERATORS?.YESTERDAY:
      dateValues = [format(subDays(new Date(), 1), 'yyyy-MM-dd')];
      break;
    case dateOperator === DATE_OPERATORS?.TOMORROW:
      dateValues = [format(addDays(new Date(), 1), 'yyyy-MM-dd')];
      break;
    case dateOperator === DATE_OPERATORS?.THIS_WEEK:
      // Get the first day of the current week (Sunday)
      const firstDay = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      // Get the last day of the current week (Saturday)
      const lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 6)
      );

      switch (operator) {
        case 'equals':
          dateValues = [
            format(addDays(firstDay, 1), 'yyyy-MM-dd'),
            format(addDays(lastDay, 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(addDays(firstDay, 1), 'yyyy-MM-dd'),
            format(addDays(lastDay, 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(addDays(firstDay, 1), 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(addDays(lastDay, 1), 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator === DATE_OPERATORS?.THIS_MONTH:
      const startDate = startOfMonth(today);
      const endDate = endOfMonth(today);
      switch (operator) {
        case 'equals':
          dateValues = [
            format(startDate, 'yyyy-MM-dd'),
            format(endDate, 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(startDate, 'yyyy-MM-dd'),
            format(endDate, 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(startDate, 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(endDate, 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator === DATE_OPERATORS?.THIS_YEAR:
      const startYearDate = startOfYear(today);
      const endYearDate = endOfYear(today);
      switch (operator) {
        case 'equals':
          dateValues = [
            format(startYearDate, 'yyyy-MM-dd'),
            format(endYearDate, 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(startYearDate, 'yyyy-MM-dd'),
            format(endYearDate, 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(startYearDate, 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(endYearDate, 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator.startsWith('Last'):
      let lastDays = dateOperator.split(' ')[1];
      if (isNaN(lastDays)) {
        if (lastDays === 'week') {
          const weekStart = startOfWeek(today);
          switch (operator) {
            case 'equals':
              dateValues = [
                format(subDays(weekStart, 6), 'yyyy-MM-dd'),
                format(weekStart, 'yyyy-MM-dd'),
              ];
              break;
            case 'notEquals':
              dateValues = [
                format(subDays(weekStart, 6), 'yyyy-MM-dd'),
                format(weekStart, 'yyyy-MM-dd'),
              ];
              break;
            case 'beforeDate':
              dateValues = [format(subDays(weekStart, 6), 'yyyy-MM-dd')];
              break;
            case 'afterDate':
              dateValues = [format(weekStart, 'yyyy-MM-dd')];
              break;
            default:
              dateValues = [];
              break;
          }
        } else if (lastDays === 'month') {
          const monthStart = startOfMonth(today);
          switch (operator) {
            case 'equals':
              dateValues = [
                format(subDays(monthStart, 30), 'yyyy-MM-dd'),
                format(subDays(monthStart, 1), 'yyyy-MM-dd'),
              ];
              break;
            case 'notEquals':
              dateValues = [
                format(subDays(monthStart, 30), 'yyyy-MM-dd'),
                format(subDays(monthStart, 1), 'yyyy-MM-dd'),
              ];
              break;
            case 'beforeDate':
              dateValues = [format(subDays(monthStart, 30), 'yyyy-MM-dd')];
              break;
            case 'afterDate':
              dateValues = [format(subDays(monthStart, 1), 'yyyy-MM-dd')];
              break;
            default:
              dateValues = [];
              break;
          }
        } else if (lastDays === 'quarter') {
          const monthQtrStart = startOfMonth(today);
          switch (operator) {
            case 'equals':
              dateValues = [
                format(subDays(monthQtrStart, 90), 'yyyy-MM-dd'),
                format(subDays(monthQtrStart, 1), 'yyyy-MM-dd'),
              ];
              break;
            case 'notEquals':
              dateValues = [
                format(subDays(monthQtrStart, 90), 'yyyy-MM-dd'),
                format(subDays(monthQtrStart, 1), 'yyyy-MM-dd'),
              ];
              break;
            case 'beforeDate':
              dateValues = [format(subDays(monthQtrStart, 90), 'yyyy-MM-dd')];
              break;
            case 'afterDate':
              dateValues = [format(subDays(monthQtrStart, 1), 'yyyy-MM-dd')];
              break;
            default:
              dateValues = [];
              break;
          }
        }
      } else {
        switch (operator) {
          case 'equals':
            dateValues = [
              format(subDays(new Date(), lastDays), 'yyyy-MM-dd'),
              format(subDays(new Date(), 1), 'yyyy-MM-dd'),
            ];
            break;
          case 'notEquals':
            dateValues = [
              format(subDays(new Date(), lastDays), 'yyyy-MM-dd'),
              format(subDays(new Date(), 1), 'yyyy-MM-dd'),
            ];
            break;
          case 'beforeDate':
            dateValues = [format(subDays(new Date(), lastDays), 'yyyy-MM-dd')];
            break;
          case 'afterDate':
            dateValues = [format(subDays(new Date(), 1), 'yyyy-MM-dd')];
            break;
          default:
            dateValues = [];
            break;
        }
      }
      break;
    case dateOperator === DATE_OPERATORS?.TILL_TODAY:
      switch (operator) {
        case 'equals':
          dateValues = [
            format(subDays(new Date(), 100000), 'yyyy-MM-dd'),
            format(subDays(new Date(), 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(subDays(new Date(), 100000), 'yyyy-MM-dd'),
            format(subDays(new Date(), 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(new Date(), 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(new Date(), 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator === DATE_OPERATORS?.FROM_365_DAYS_TO_NOW:
      switch (operator) {
        case 'equals':
          dateValues = [
            format(subDays(new Date(), 365), 'yyyy-MM-dd'),
            format(subDays(new Date(), 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(subDays(new Date(), 365), 'yyyy-MM-dd'),
            format(subDays(new Date(), 1), 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(subDays(new Date(), 365), 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(subDays(new Date(), 1), 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator.startsWith('Next'):
      let nextDays = dateOperator.split(' ')[1];
      switch (operator) {
        case 'equals':
          dateValues = [
            format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            format(addDays(new Date(), nextDays), 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            format(addDays(new Date(), nextDays), 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(addDays(new Date(), 1), 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(addDays(new Date(), nextDays), 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    case dateOperator.startsWith('From now to'):
      let withinDays = dateOperator.split(' ')[3];
      switch (operator) {
        case 'equals':
          dateValues = [
            format(new Date(), 'yyyy-MM-dd'),
            format(addDays(new Date(), withinDays), 'yyyy-MM-dd'),
          ];
          break;
        case 'notEquals':
          dateValues = [
            format(new Date(), 'yyyy-MM-dd'),
            format(addDays(new Date(), withinDays), 'yyyy-MM-dd'),
          ];
          break;
        case 'beforeDate':
          dateValues = [format(new Date(), 'yyyy-MM-dd')];
          break;
        case 'afterDate':
          dateValues = [format(addDays(new Date(), withinDays), 'yyyy-MM-dd')];
          break;
        default:
          dateValues = [];
          break;
      }
      break;
    default:
      break;
  }
  innerCondition.push({
    member: innerRecord.tableField,
    values: dateValues,
    operator: [
      DATE_OPERATORS?.TODAY,
      DATE_OPERATORS?.YESTERDAY,
      DATE_OPERATORS?.TOMORROW,
    ].includes(dateOperator)
      ? operator
      : multiDateOperators[operator],
  });
}

function widgetTypeFilter(query, formattedQuery, filterDetails) {
  if ('user-filter' == query.widget_filter_type && filterDetails) {
    formattedQuery.filters.push(filterDetails.userFilterCondition);
  } else if ('company-filter' == query.widget_filter_type && filterDetails) {
    formattedQuery.filters.push(filterDetails.companyFilterCondition);
  } else if ('assigned-to-self' == query.widget_filter_type && filterDetails) {
    formattedQuery.filters.push({
      member: 'UserMeta.user_id',
      values: [getLoginUserId()],
      operator: 'equals',
    });
  } else if (
    'user-and-assigned-to-self' == query.widget_filter_type &&
    filterDetails
  ) {
    const userAndAssignToSelfFilter = {
      or: [
        {
          member: 'UserMeta.user_id',
          values: [getLoginUserId()],
          operator: 'equals',
        },
      ],
    };
    if (filterDetails.userFilterCondition) {
      userAndAssignToSelfFilter.or.push(filterDetails.userFilterCondition);
    }
    formattedQuery.filters.push(userAndAssignToSelfFilter);
  }
}

function getColumnFilters(tablePagination, cubeResponse, formattedQuery) {
  if (tablePagination.filters && Object.keys(tablePagination.filters)?.length) {
    const tempFilters = [];
    const tempTimeDimension = [];
    Object.keys(tablePagination.filters).forEach(key => {
      if (cubeResponse?.annotation?.dimensions?.[key]?.type == 'time') {
        if (tablePagination?.filters?.[key]) {
          tablePagination.filters?.[key].constraints.forEach(filterKey => {
            if (filterKey?.value && filterKey.value?.length) {
              tempTimeDimension.push({
                dimension: key,
                dateRange: [
                  moment(filterKey.value[0]).format('Y-M-D'),
                  moment(filterKey.value[1]).format('Y-M-D'),
                ],
              });
            }
          });
        }
      } else if (tablePagination.filters?.[key]) {
        tablePagination.filters?.[key].constraints.forEach(filterKey => {
          if (filterKey.value) {
            tempFilters.push({
              member: key,
              values: [filterKey.value?.toString()],
              operator: filterKey.matchMode,
            });
          }
        });
      }
    });
    if (tempFilters.length) {
      formattedQuery.filters.push(...tempFilters);
    }
    if (tempTimeDimension) {
      formattedQuery.timeDimensions.push(...tempTimeDimension);
    }
  }
}

export const routingWidgetDefaultCondition = () => {
  return {
    member: 'Project.status',
    values: ['Cancelled', 'Closed', 'Completed', 'Refunded'],
    operator: 'notEquals',
  };
};
