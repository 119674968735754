import { makeStyles } from '@material-ui/core/styles';

const PF_WHITE = '#FFFFFF !important';
const PF_BORDER_BLACK = '#171F28 !important';
// //Yellow Shades
const PF_GREY_BORDER = '#E5E8EB !important';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  add: {
    width: 140,
    height: 202,
    margin: theme.spacing(2),
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: PF_BORDER_BLACK,
    boxSizing: 'border-box',
    opacity: 0.5,

    backgroundolor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  singleDocContainer: {
    width: 'min-content',
  },
  addIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  docsContainer: {
    display: 'flex !important',
    padding: '0 10px 10px 10px !important',
    overflow: 'auto',
    margin: '0px 10px',
    boxShadow: 'unset',
    position: 'relative',
    minHeight: '250px',
  },
  docDetails: {
    fontWeight: 500,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '8px 0px',
    padding: theme.spacing(0),
  },
  docName: {
    width: '111px',
    textAlign: 'center',
  },
  downloadIcon: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    right: 0,
    padding: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    zIndex: 999,
  },
  signatureIcone: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    left: 0,
    padding: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    zIndex: 999,
  },
  sendToSourceButton: {
    position: 'absolute',
    top: 80,
    left: 10,
    zIndex: 999,
  },
  completeButton: {
    position: 'absolute',
    top: 120,
    left: 25,
    zIndex: 999,
  },
  sendToSourcePhotoButton: {
    position: 'absolute',
    top: 70,
    left: 20,
    zIndex: 1,
  },
  storeTypeBlankDiv: {
    marginTop: 4,
  },
  completePhotoButton: {
    position: 'absolute',
    top: 110,
    left: 35,
    zIndex: 1,
  },
  storeTypeGrid: {
    textAlign: 'center',
  },
  storeTypePhotGrid: {
    textAlign: 'center',
    margin: '0 25px 0 0',
  },
  storeTypeLabel: {
    fontSize: 10,
  },
  storeTypeText: {
    fontSize: 10,
  },
  gridPaper: {
    padding: '10px 0px 10px 0px',
    border: '1px solid #DBE0E4',
    maxHeight: '450px',
  },
  gridPaperPhoto: {
    padding: '10px 0px 10px 0px',
    border: '1px solid #DBE0E4',
  },
  input: {
    display: 'none',
  },
  media: {
    height: 150,
    backgroundSize: 'contain',
    width: 138,
  },
  noResultsContainer: {
    display: 'flex !important',
    justifyContent: 'center',
    minHeight: '150px',
    alignItems: 'center',
  },
  photo: {
    width: `${theme.spacing(16)}px !important`,
    height: `${theme.spacing(16)}px  !important`,
    margin: `${theme.spacing(2)}px  !important`,
    backgroundColor: PF_WHITE,
  },
  addPhoto: {
    width: `${theme.spacing(16)}px !important`,
    height: `${theme.spacing(16)}px  !important`,
    margin: `${theme.spacing(2)}px  !important`,
    backgroundColor: PF_WHITE,
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: PF_BORDER_BLACK,
    boxSizing: 'border-box',
    opacity: 0.5,

    cursor: 'pointer',
  },
  avatarImg: {
    objectFit: 'contain !important',
    cursor: 'pointer',
  },
  plusIcon: {
    transform: 'scale(1.3)',
    opacity: 0.5,
  },
  root: {
    width: '140px',
    height: '202px',
    margin: theme.spacing(2),
    borderWidth: '1px',
    borderColor: PF_GREY_BORDER,
    borderStyle: 'solid',
    backgroundolor: theme.palette.primary.light,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  photoCards: {
    width: 'auto',
    height: 'auto',
    margin: '0',
    padding: 0,
  },
  addDocumentLabel: {
    '& > div': {
      marginTop: 38,
    },
  },
  photoUploadLabel: {
    marginTop: 22,
  },
  titleHeaderGrid: {
    padding: '10px 24px 0px 24px',
  },
  selectAllGrid: {
    marginLeft: '16px',
  },
  emptyText: {
    marginTop: '50px',
    fontWeight: 'bold',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconLeft: {
    marginLeft: '-20px',
  },
  checkboxRoot: {
    padding: '9px 0px',
    width: '23px',
    height: '23px',
  },
}));
