import { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  TextField,
  CircularProgress,
  Input,
  InputAdornment,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { debounce } from 'lodash';

import { formatDate } from '../../../utils/Helpers';
import { ISSUES_STATUS_OPTION } from '../../../constants';
import permissions from '../../../config/permissions';
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { useAlerts } from '../../shared/Alerts/alertsService';
import ApiService from '../../../services/api.service';
import { FEATURE_LIST } from '../../Admin/NewConfiguration/EditCompanyConfig';

import {
  getIssuesData,
  createIssue,
  updateIssue,
  deleteIssue,
  getCategoryList,
  getOriginatingFromList,
  getSourceStatusListOptions,
} from './Issues.service';
import { useStyles } from './Issues.styles';

const issuesTableColumns = [
  {
    id: 'date_created',
    value: 'Date Created',
    width: '10%',
  },
  {
    id: 'originated_from',
    value: 'Originated From',
    width: '7%',
  },
  {
    id: 'employee',
    value: 'Employee',
    width: '20%',
  },
  {
    id: 'store_associate_name',
    value: 'Store Associate Name',
    width: '8%',
  },
  {
    id: 'dollar_amount',
    value: 'Dollar Amount',
    width: '5%',
  },
  {
    id: 'description',
    value: 'Description',
    width: '10%',
  },
  {
    id: 'category',
    value: 'Category',
    width: '8%',
  },
  {
    id: 'issue_status',
    value: 'Status',
    width: '7%',
  },
  {
    id: 'quantity',
    value: 'Quantity',
    width: '5%',
  },
  {
    id: 'issue_total_amount',
    value: 'Issue Total $',
    width: '5%',
  },
  {
    id: 'last_modified_by',
    value: 'Modified By',
    width: '10%',
  },
  {
    id: 'last_modified_at',
    value: 'Modified At',
    width: '10%',
  },
];

const getConvertToLaborItemPermission = () => {
  try {
    return JSON.parse(
      localStorage?.getItem('client_details')
    )?.tenant_permissions?.[0]?.included_permissions?.includes(
      FEATURE_LIST[3].key
    );
  } catch (error) {
    return false;
  }
};
const Issues = ({ formik }) => {
  const classes = useStyles();
  const [issuesResponse, setIssuesResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [originatingFromList, setOriginatingFromList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [formAction, setFormAction] = useState('add');
  const [dialogSettings, setDialogSettings] = useState({
    title: 'View Issue',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [reloadList, setReloadList] = useState(false);
  const [laborItemsList, setLaborItemsList] = useState([]);
  const { setAlert } = useAlerts();
  const convertIssueToLaborItemPermission = getConvertToLaborItemPermission();

  let issuesDisplayList = [];

  useEffect(() => {
    if (formik?.values?.project_id) {
      getIssuesData(formik?.values?.project_id, setLoading, setIssuesResponse);

      getCategoryList(setCategoryList);
      getOriginatingFromList(setOriginatingFromList);
    }
  }, [formik?.values?.project_id]);

  useEffect(() => {
    if (reloadList) {
      getIssuesData(formik?.values?.project_id, setLoading, setIssuesResponse);
    }
  }, [reloadList]);

  useEffect(() => getLaborItems(), []);

  // **** Data Modification to Display *****
  if (issuesResponse?.items?.length) {
    issuesDisplayList = issuesResponse?.items?.map(val => {
      return {
        date_created: {
          value: moment(val.created_at).format('MM-DD-YYYY hh:mm A'),
        },
        date_created_value: {
          value: val.created_at,
        },
        originated_from: {
          value: val.originated_from,
        },
        employee: {
          value: val.employee,
        },
        store_associate_name: {
          value: val.store_associate_name,
        },
        dollar_amount: {
          value: val.dollar_amt ? `$ ${val.dollar_amt}` : '',
        },
        description: {
          value: val.description,
        },
        category: {
          value: val.issue_category?.category,
        },
        follow_up_date: {
          value: val?.project?.rts_follow_up_date || '',
        },
        issue_status: {
          value: val?.issues_status?.status || '',
          status_id: val?.issues_status?.status_id,
        },
        item_id: {
          value: val?.item_id || '',
        },
        quantity: {
          value: val?.quantity || 0,
        },
        issue_total_amount: {
          value:
            val.dollar_amt && val?.quantity
              ? `$ ${val.issue_total_amount}`
              : '$ 0.00',
        },
        last_modified_by: {
          value: val?.modifiedby
            ? val?.modifiedby?.first_name + ' ' + val?.modifiedby?.last_name
            : '-',
        },
        last_modified_at: {
          value: val?.modified_at
            ? moment(val?.modified_at).format('MM-DD-YYYY hh:mm A')
            : '-',
        },
      };
    });
  }

  // **** Form Validation Schema ****
  const IssueValidationSchema = Yup.object().shape({
    date_created: Yup.string().trim(),
    originated_from: Yup.string().trim().required(),
    store_associate_name: Yup.string().trim(),
    dollar_amount: Yup.number()
      .typeError('Dollar Amount must be a number')
      .min(1)
      .max(99999999.99),
    description: Yup.string()
      .trim()
      .required()
      .test(
        'len',
        'Enter a valid description',
        val => val && val.trim().length >= 1
      ),
    category: Yup.object().required(),
    follow_up_date: Yup.date().nullable(),
    item_id: Yup.object().nullable(),
    quantity: Yup.number(),
  });

  // **** Formik Form Values ****
  const issueFormik = useFormik({
    initialValues: {
      date_created:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.date_created_value.value) ||
        formatDate(new Date()),
      originated_from:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.originated_from.value) ||
        '',
      store_associate_name:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.store_associate_name.value) ||
        '',
      dollar_amount:
        (formAction !== 'add' &&
          parseFloat(
            issuesDisplayList[rowIndex]?.dollar_amount.value
              .replace('$', '')
              .trim()
          )) ||
        '',
      description:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.description.value) ||
        '',
      category:
        (formAction !== 'add' &&
          issuesResponse?.items[rowIndex]?.issue_category) ||
        '',
      follow_up_date:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.follow_up_date.value) ||
        null,
      issue_status:
        (formAction !== 'add' && issuesDisplayList[rowIndex]?.issue_status) ||
        '',
      item_id:
        (formAction !== 'add' && issuesDisplayList[rowIndex]?.item_id?.value) ||
        null,
      quantity:
        (formAction !== 'add' &&
          issuesDisplayList[rowIndex]?.quantity?.value) ||
        '',
      issue_total_amount:
        (formAction !== 'add' &&
          parseFloat(
            issuesDisplayList[rowIndex]?.issue_total_amount.value
              .replace('$', '')
              .trim()
          )) ||
        '',
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (formAction === 'add') {
        createIssue(
          formik?.values?.project_id,
          {
            date_created: issueFormik.values.date_created,
            originated_from: issueFormik.values.originated_from,
            store_associate_name:
              issueFormik.values.store_associate_name.trim() || null,
            description: issueFormik.values.description.trim() || null,
            dollar_amt: issueFormik.values.dollar_amount,
            issue_category_id: issueFormik?.values?.category?.issue_category_id,
            rts_follow_up_date: issueFormik.values.follow_up_date,
            issue_status: issueFormik?.values?.issue_status?.status_id || null,
            item_id: issueFormik?.values?.item_id?.item_id || null,
            quantity: issueFormik?.values?.quantity || null,
            issue_total_amount:
              issueFormik.values.dollar_amount && issueFormik?.values?.quantity
                ? issueFormik.values.issue_total_amount
                : 0,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      } else {
        updateIssue(
          formik?.values?.project_id,
          issuesResponse.items[rowIndex]?.project_issue_id,
          {
            date_created: issueFormik.values.date_created,
            originated_from: issueFormik.values.originated_from,
            store_associate_name:
              issueFormik.values.store_associate_name.trim() || null,
            dollar_amt: issueFormik.values.dollar_amount,
            description: issueFormik.values.description.trim() || null,
            issue_category_id: issueFormik?.values?.category?.issue_category_id,
            rts_follow_up_date: issueFormik.values.follow_up_date,
            issue_status: issueFormik?.values?.issue_status?.status_id || null,
            item_id: issueFormik?.values?.item_id?.item_id || null,
            quantity: issueFormik?.values?.quantity || null,
            issue_total_amount:
              issueFormik.values.dollar_amount && issueFormik?.values?.quantity
                ? issueFormik.values.issue_total_amount
                : 0,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
      setReloadList(false);
      resetForm();
    },
    validationSchema: IssueValidationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    const dollarAmount = parseFloat(issueFormik?.values?.dollar_amount) || 0;
    const quantity = parseFloat(issueFormik?.values?.quantity) || 0;
    const totalAmount = dollarAmount * quantity;
    issueFormik.setFieldValue('issue_total_amount', totalAmount);
  }, [issueFormik?.values?.dollar_amount, issueFormik?.values?.quantity]);

  const memoizedStatus = useMemo(() => {
    return statusList.find(
      val => val?.status_id === issueFormik?.values?.issue_status?.status_id
    );
  }, [statusList, issueFormik?.values?.issue_status?.status_id]);

  const getStatusList = async () => {
    try {
      const response = await getSourceStatusListOptions();
      setStatusList(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStatusList();
  }, [reloadList]);

  const addNewIssue = () => {
    setFormAction('add');

    issueFormik?.setValues({
      date_created: format(new Date(), 'yyyy-MM-dd'),
      originated_from: '',
      store_associate_name: '',
      dollar_amount: '',
      description: '',
      category: '',
      follow_up_date: format(new Date(), 'yyyy-MM-dd'),
      issue_status: '',
      item_id: '',
    });

    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Add Issue',
    }));
    setIsOpen(true);
  };

  const viewEditIssue = (action, index) => {
    if (issuesDisplayList[index]?.item_id?.value) {
      // fetch item by id
      getLaborItems(null, issuesDisplayList[index]?.item_id?.value);
    }
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Issue') || 'Edit Issue',
    }));

    setRowIndex(index);
    setFormAction(action);

    setIsOpen(true);
  };

  const deleteIssueConfirmation = index => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `Are you sure you want to delete Issue?`,
      header: 'Delete Issue',
    });
    setRowIndex(index);
  };

  const onIssueConfirmDialog = async () => {
    setReloadList(false);
    deleteIssue(
      formik?.values?.project_id,
      issuesResponse.items[rowIndex]?.project_issue_id,
      setLoading,
      setAlert,
      setReloadList,
      setConfirmDialog
    );
  };
  const getLaborItems = async (event, itemId = null) => {
    try {
      if (itemId) {
        const response = await ApiService.get(`/system/items/${itemId}`);
        if (response) {
          const recordDetails = {
            item_id: response.item_id,
            item_name: `${response.item_number} - ${response.item_desc}`,
          };
          issueFormik.setFieldValue('item_id', recordDetails);
          setLaborItemsList([recordDetails]);
        } else {
          setLaborItemsList([]);
        }
      } else {
        const searchString = event?.target?.value;
        let query = '';
        if (searchString) query = `&query=${searchString}`;

        const response = await ApiService.get(
          `/system/item-types/2/items?limit=10&offset=0${query}`
        );
        if (response?.items && response.items.length) {
          const laborList = response.items.map(item => {
            return {
              item_id: item.item_id,
              item_name: `${item.item_number} - ${item.item_desc}`,
            };
          });
          setLaborItemsList(laborList);
        } else {
          setLaborItemsList([]);
        }
      }
    } catch (error) {
      setLaborItemsList([]);
    }
  };
  const issueTotalDollar = issueFormik?.values?.issue_total_amount
    ? Math.floor(issueFormik?.values?.issue_total_amount * 100) / 100
    : (0).toFixed(2);

  const getIssueViewEditForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        className={
          (formAction === 'edit' || formAction === 'add') && classes.formEdit
        }
      >
        <Grid item xs={12}>
          <TextField
            id="date_created"
            label="Date Created"
            name="date_created"
            InputProps={{
              readOnly: true,
            }}
            value={formatDate(issueFormik?.values?.date_created)}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} className="">
          <Autocomplete
            id="originated_from"
            name="originated_from"
            required={formAction === 'edit' || formAction === 'add'}
            options={originatingFromList || []}
            disableListWrap
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField
                {...params}
                label="Originated From"
                required={formAction === 'edit' || formAction === 'add'}
              />
            )}
            value={issueFormik?.values?.originated_from || ''}
            onChange={(event, value) =>
              issueFormik.setFieldValue('originated_from', value)
            }
            onBlur={issueFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="store_associate_name"
            label="Store Associate Name"
            name="store_associate_name"
            onChange={issueFormik.handleChange}
            onBlur={issueFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={issueFormik?.values?.store_associate_name}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="cost_each">
            Dollar Amount
          </InputLabel>
          <Input
            id="dollar_amount"
            name="dollar_amount"
            type="number"
            value={issueFormik?.values?.dollar_amount}
            onChange={(event, value) => {
              issueFormik.setFieldValue('cost_each', event.target.value);
              issueFormik.setFieldValue(
                'dollar_amount',
                parseFloat(event.target.value) || ''
              );
            }}
            onBlur={issueFormik.handleBlur}
            readOnly={formAction === 'view' ? true : false}
            error={
              issueFormik.touched.dollar_amount &&
              issueFormik.errors.dollar_amount
            }
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
          {issueFormik.touched.dollar_amount &&
            issueFormik.errors.dollar_amount && (
              <FormHelperText error>
                {issueFormik.errors.dollar_amount}
              </FormHelperText>
            )}
        </Grid>
        <Grid item>
          <TextField
            id="description"
            label="Description"
            name="description"
            required={formAction === 'edit' || formAction === 'add'}
            onChange={(event, value) => {
              issueFormik.setFieldValue('description', event?.target?.value);
            }}
            onBlur={issueFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={issueFormik?.values?.description}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="category"
            name="Category"
            required={formAction === 'edit' || formAction === 'add'}
            options={categoryList || []}
            disableListWrap
            getOptionLabel={option => option.category || option}
            renderInput={params => (
              <TextField
                {...params}
                label="Category"
                required={formAction === 'edit' || formAction === 'add'}
              />
            )}
            value={issueFormik?.values?.category || ''}
            onChange={(event, value) => {
              issueFormik.setFieldValue('category', value);
            }}
            onBlur={issueFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item className="mui-calendar">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="follow_up_date"
              name="follow_up_date"
              variant="inline"
              inputProps={{ autoComplete: 'off' }}
              label="Follow Up Date"
              invalidDateMessage="Invalid Date"
              format="MM-dd-yyyy"
              value={
                (issueFormik?.values?.follow_up_date?.split('T')?.length &&
                  parseISO(
                    issueFormik?.values?.follow_up_date?.slice(0, 10)
                  )) ||
                null
              }
              onChange={(date, value) =>
                issueFormik?.setFieldValue(
                  'follow_up_date',
                  `${moment(value).format('YYYY-MM-DD')}`
                )
              }
              onBlur={issueFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disablePast
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              error={
                issueFormik?.touched?.follow_up_date &&
                issueFormik?.errors?.follow_up_date
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="issue_status"
            name="Status"
            options={statusList || []}
            disableListWrap
            getOptionLabel={option => {
              return option.status || option;
            }}
            renderInput={params => <TextField {...params} label="Status" />}
            value={memoizedStatus}
            onChange={(event, value) => {
              issueFormik.setFieldValue('issue_status', value);
            }}
            disabled={formAction === 'view'}
          />
        </Grid>

        {convertIssueToLaborItemPermission && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                id="item"
                name="Item #"
                options={laborItemsList || []}
                disableListWrap
                getOptionLabel={option => {
                  return option.item_name || '';
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Item #"
                    InputLabelProps={{ shrink: true }}
                    onChange={debounce(getLaborItems, 300)}
                  />
                )}
                value={issueFormik?.values?.item_id}
                onChange={(event, value) => {
                  issueFormik.setFieldValue('item_id', value || null);
                  issueFormik.setFieldValue('quantity', 1);
                }}
                disabled={formAction === 'view'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel shrink htmlFor="quantity">
                Quantity
              </InputLabel>

              <Input
                id="quantity"
                name="quantity"
                type="number"
                value={issueFormik?.values?.quantity}
                onChange={(event, value) => {
                  issueFormik.setFieldValue('quantity', event.target.value);
                }}
                readOnly={formAction === 'view' ? true : false}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="cost_each">
            Issue Total $
          </InputLabel>
          <Input
            id="issue_total_amount"
            name="issue_total_amount"
            type="number"
            value={issueTotalDollar}
            InputProps={{
              readOnly: true,
            }}
            disabled
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item classes={{ root: classes.tableWrapper }}>
          <GenericTable
            title="Issues"
            headerLinks={[
              {
                add: permissions?.viewEditProject?.tabIssuesAdd,
                label: 'Add Item',
                handler: addNewIssue,
              },
            ]}
            columnData={issuesTableColumns}
            rowData={issuesDisplayList}
            showActions={{ view: true, edit: true, delete: true }}
            handleView={index => viewEditIssue('view', index)}
            handleEdit={index => viewEditIssue('edit', index)}
            handleDelete={index => deleteIssueConfirmation(index)}
            permissions={{
              view: permissions?.viewEditProject?.tabIssuesView,
              edit: permissions?.viewEditProject?.tabIssuesEdit,
              delete: permissions?.viewEditProject?.tabIssuesDelete,
            }}
          />
        </Grid>
      </Grid>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          issueFormik?.handleReset();
        }}
        handleSave={issueFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !issueFormik?.touched || !issueFormik?.dirty || !issueFormik?.isValid
        }
        disabledButton1={false}
      >
        {getIssueViewEditForm()}
      </GenericDialog>
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onIssueConfirmDialog}
      />
    </>
  );
};

export default Issues;
