import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { TabView, TabPanel } from 'primereact/tabview';
import './warehouseStyle.css';

import Geocode from 'react-geocode';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { AutoComplete } from 'primereact/autocomplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { transformAddressFromGeocode } from '../../utils/Helpers';
import { CONFIG, libraries } from '../../constants';
import BinContainer from './BinContainer.jsx';
import { WAREHOUSE_BIN } from './constants.js';
import { REACT_APP_GMAPS_KEY } from '../../constants/envConstants';

import DeleteWarehouse from './DeleteWarehouse';
import WarehouseStockView from './WarehouseStockView';
import BinDetails from './BinDetails';
import { LocationValidation } from './WarehouseValidation';
import AddWarehouseForm from './AddWarehouseForm';
import BinLocations from './BinLocations';

export default function AddWarehouse({
  editableData,
  createNewWarehouseDetails,
  updateNewWarehouseDetails,
  deleteWareHouseHandler,
  onHideSidebar,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [places, setPlaces] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [zoom, setZoom] = useState(12);
  const [binData, setBinData] = useState('');
  const [address, setAddress] = useState('');
  const onMapLoad = React.useCallback(map => {
    setMapInstance(map);
  }, []);
  const [center, setCenter] = useState({
    lat: 36.11310790984111,
    lng: -115.17653312308538,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: CONFIG.GOOGLE_MAP_KEY,
    libraries,
  });

  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  useEffect(() => {
    Geocode.setApiKey(REACT_APP_GMAPS_KEY);
    Geocode.setLanguage('en');
    if (editableData) {
      const coordinates = {
        lat: editableData.latitude,
        lng: editableData.longitude,
      };
      setPlaces([coordinates]);
      setCenter(coordinates);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      id: editableData?.id,
      name: editableData?.name || '',
      warehouseCode: editableData?.warehouseCode || '',
      addressLineOne: editableData?.addressLineOne || '',
      addressLineTwo: editableData?.addressLineTwo || '',
      city: editableData?.city || '',
      state: editableData?.state || '',
      zipcode: editableData?.zipcode || '',
      manager: editableData?.manager || '',
      phoneNumber: editableData?.phoneNumber || '',
      emailAddress: editableData?.emailAddress || '',
      latitude: editableData?.latitude || '36.1695501',
      longitude: editableData?.longitude || '-115.1683622',
      bins: editableData?.binsArray?.[0]?.bins || [],
    },
    enableReinitialize: true,
    validationSchema: LocationValidation,
    onSubmit: data => {
      const formattedWareHouseData = {
        phoneNumber: data.phoneNumber,
        emailAddress: data.emailAddress,
        manager: data.manager,
        address: formattedAddress(data),
        name: data.name,
        latitude: data.latitude,
        longitude: data.longitude,
        ...data,
      };
      if (editableData) {
        updateNewWarehouseDetails(
          {
            ...formattedWareHouseData,
            ...(editableData && { _id: editableData._id }),
          },
          onHideSidebar
        );
      } else {
        createNewWarehouseDetails(formattedWareHouseData, onHideSidebar);
      }
    },
  });

  const searchGeocode = React.useCallback(
    debounce(formValues => {
      updateMapLocation(formValues);
    }, 1000),
    []
  );

  const validateAddress = () => {
    if (
      !formik.values.addressLineOne ||
      !formik.values.addressLineTwo ||
      !formik.values.city ||
      !formik.values.state
    )
      return false;
    else return true;
  };

  useEffect(() => {
    if (validateAddress()) searchGeocode(formik.values);
  }, [
    formik.values.addressLineOne,
    formik.values.addressLineTwo,
    formik.values.city,
    formik.values.state,
    formik.values.zipcode,
  ]);

  const formattedAddress = formValues => {
    return `${formValues.addressLineOne}, ${formValues.addressLineTwo}, ${formValues.city}, ${formValues.state}, ${formValues.zipcode}`;
  };

  const updateMapLocation = async formValues => {
    const address = formattedAddress(formValues);
    const geoCodeResponse = await Geocode.fromAddress(address);
    if (
      geoCodeResponse &&
      geoCodeResponse.results &&
      geoCodeResponse.results[0]
    ) {
      geoCodeResponse.results[0].geometry.location;
      const coordinates = {
        ...geoCodeResponse.results[0].geometry.location,
      };
      setPlaces([coordinates]);
      setCenter(coordinates);
      setZoom(12);

      if (mapInstance) {
        mapInstance.panTo(coordinates);
        mapInstance.setZoom(12);
      }
    }
  };

  const populateGeocodeResponse = response => {
    const { addressLineOne, addressLineTwo, city, zipcode, state } =
      transformAddressFromGeocode(response.results[0].address_components);
    const latitude =
      typeof response.results[0].geometry.location.lat === 'function'
        ? response.results[0].geometry.location.lat()
        : response.results[0].geometry.location.lat;
    const longitude =
      typeof response.results[0].geometry.location.lng === 'function'
        ? response.results[0].geometry.location.lng()
        : response.results[0].geometry.location.lng;
    formik.setFieldValue('city', city || '');
    formik.setFieldValue('state', state || '');
    formik.setFieldValue('addressLineOne', addressLineOne || '');
    formik.setFieldValue('addressLineTwo', addressLineTwo || '');
    formik.setFieldValue('zipcode', zipcode || '');
    formik.setFieldValue('latitude', latitude);
    formik.setFieldValue('longitude', longitude);
  };

  const onFormSubmitHandler = () => {
    formik.handleSubmit();
  };

  const addBinHandler = e => {
    let clonedData = [].concat(formik.values.bins);

    clonedData.push({
      name: binData,
    });
    setBinData('');
    formik.setFieldValue('bins', clonedData);
  };

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        getLatLng(results[0]).then(latLng => {
          const coordinates = {
            lat: latLng.lat,
            lng: latLng.lng,
          };
          setPlaces([coordinates]);
          setCenter(coordinates);
          populateGeocodeResponse({ results });
          setAddress(address);
          if (mapInstance) {
            mapInstance.panTo(coordinates);
            mapInstance.setZoom(12);
          }
          setZoom(12);
        });
      })
      .catch(error => console.error('Error', error));
  };

  const onClickDragLocationSelected = e => {
    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setPlaces([coordinates]);
    setCenter(coordinates);

    if (mapInstance) {
      mapInstance.panTo(coordinates);
      mapInstance.setZoom(12);
    }
    setZoom(12);
    // Get address from latitude & longitude.
    Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
      response => {
        populateGeocodeResponse(response);
      },
      error => {
        console.error(error);
      }
    );
  };

  const onDeleteWarehouseHandler = e => {
    deleteWareHouseHandler(editableData);
    onHideSidebar();
  };

  return (
    <>
      <TabView
        className="mt-3"
        activeIndex={activeIndex}
        onTabChange={e => setActiveIndex(e.index)}
      >
        <TabPanel className="pb-0" header="Warehouse Details">
          <div className="card flex justify-content-center">
            <form onSubmit={onFormSubmitHandler} className="w-12 m-1">
              <div className="grid pt-1">
                <div className="col-12 lg:col-6 xl:col-5 m-0 p-0">
                  <AddWarehouseForm
                    formik={formik}
                    onFormSubmitHandler={onFormSubmitHandler}
                    onCancelBtnClickHandler={onHideSidebar}
                    btnText={editableData ? 'Update' : 'Save'}
                  />
                </div>
                <div className="col-12 lg:col-6 xl:col-7 p-input-icon-right m-0">
                  <div className="col-12 md:col-12 lg:col-12 p-input-icon-right pb-0">
                    <div className="m-0 grid px-0">
                      <div className="col-12  lg:col p-0 mb-3">
                        {isLoaded && (
                          <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            searchOptions={{
                              componentRestrictions: { country: 'us' },
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="w-12">
                                <span className="p-float-label">
                                  <AutoComplete
                                    {...getInputProps({
                                      className:
                                        'location-search-input lg:mb-0 w-12 p-inputtext-sm',
                                    })}
                                  />
                                  <label htmlFor="ac">Search Places</label>
                                </span>

                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer',
                                        }
                                      : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer',
                                        };
                                    return (
                                      <div
                                        key={index + suggestion.description}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        )}
                      </div>
                      <div
                        className={`${
                          editableData ? 'lg:pl-3' : 'lg:pl-0'
                        } p-0 flex justify-content-end mt-2 lg:mt-0 mb-4`}
                      >
                        <DeleteWarehouse
                          editableData={editableData}
                          confirmationDeletion={onDeleteWarehouseHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-12 lg:col-12 p-input-icon-right pt-0">
                    {isLoaded && (
                      <GoogleMap
                        onClick={onClickDragLocationSelected}
                        onLoad={onMapLoad}
                        mapContainerStyle={mapStyles}
                        zoom={zoom}
                        center={center}
                      >
                        {places?.map((marker, index) => {
                          const position = {
                            lat: parseFloat(marker.lat),
                            lng: parseFloat(marker.lng),
                          };
                          return (
                            <Marker
                              key={index}
                              position={position}
                              onDragEnd={onClickDragLocationSelected}
                              draggable={true}
                            />
                          );
                        })}
                      </GoogleMap>
                    )}
                  </div>
                </div>
                {/* <div className="col-12 md:col-2 lg:col-2 p-input-icon-right mb-3 m-0 p-0">
                  <BinLocations formik={formik} />
                </div> */}
              </div>
            </form>
          </div>
        </TabPanel>
        {editableData?._id && (
          <TabPanel header={WAREHOUSE_BIN.TAB_PANEL_HEADER_BIN}>
            <BinContainer
              warehouseId={editableData?._id}
              wareHouseName={editableData?.name || ''}
            />
          </TabPanel>
        )}
        {editableData?._id && (
          <TabPanel header={WAREHOUSE_BIN.TAB_PANEL_HEADER_STOCK}>
            <WarehouseStockView wareHouseId={editableData._id} />
          </TabPanel>
        )}
      </TabView>
    </>
  );
}
