// **** React Imports ****
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// **** External Utilities ****
import { Grid, Button } from '@material-ui/core';

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { Alert } from '../../shared/Alerts/Alert';

import FormFields from './FormFields';
import ListFormConfiguration from './ListFormConfiguration';

// **** Services *****

const useStyles = makeStyles(theme => ({
  width: {
    minWidth: '120px',
  },
}));

const SelectFields = () => {
  let clientId;
  let history = useHistory();
  //Get projectId and action from url params
  const { projectId } = useParams();
  const action = window.location.pathname.split('/')[2];
  // Get report name from search string
  const params = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const [selectedField, setSelectedField] = useState(null);
  const [getConfiguration, setConfiguration] = useState({});
  const [reloadFieldConfigurationList, setReloadFieldConfigurationList] =
    useState(false);
  const { alert, setAlert, clearAlert } = useAlerts();
  let projectDetailsBreadcrumb = [
    {
      link: '/project/scheduling',
      text: 'Project Management',
    },
    {
      text: 'Add Fields',
    },
  ];

  useEffect(() => {
    clearAlert();
  }, []);

  const setActiveField = selected => {
    setSelectedField(selected);
  };

  const setEditConfiguration = data => {
    setConfiguration(data);
    setSelectedField(data?.type?.value || '');
  };
  return (
    <>
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle={'New Fields'}
              breadCrumbArray={projectDetailsBreadcrumb}
            />
          </Grid>
        </Grid>
        {alert?.exists && (
          <Grid container item>
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <ListFormConfiguration
              reloadFieldConfigurationList={reloadFieldConfigurationList}
              setReloadFieldConfigurationList={setReloadFieldConfigurationList}
              setAlert={setAlert}
              setEditConfiguration={setEditConfiguration}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('TEXT')}
              className={classes.width}
            >
              Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('EMAIL')}
              className={classes.width}
            >
              Email
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('URL')}
              className={classes.width}
            >
              URL
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('DATE')}
              className={classes.width}
            >
              Date
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('TIMESTAMP')}
              className={classes.width}
            >
              Date Time
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setActiveField('TEXTAREA')}
              className={classes.width}
            >
              Textarea
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            {selectedField && (
              <FormFields
                reloadFieldConfigurationList={reloadFieldConfigurationList}
                setReloadFieldConfigurationList={
                  setReloadFieldConfigurationList
                }
                setAlert={setAlert}
                getEditConfiguration={getConfiguration}
                currentType={selectedField}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectFields;
