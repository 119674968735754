import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';

/* Services */

import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { BlockUI } from 'primereact/blockui';

import { getTypesList } from '../../../services/types.service';
import { getPaymentType } from '../../ProjectManagement/Billing/Billing.service';
import { getStatusOptions } from '../Stores/Stores.service';
import SkeletonLoader from '../../shared/Loader/skeleton';

import {
  searchPaymentCategory,
  createPaymentCategory,
  createRetainagePayout,
  updateRetainagePayout,
} from './services/user-form-retainage.service';

const UserFormRetainagePayout = ({
  visible,
  retainagePayout,
  setShowRetainagePayoutDialog,
  setRefreshList,
  retainageToast,
}) => {
  const toast = useRef(null);
  const paymentCategoriesRef = useRef(null);
  const { userId } = useSelector(state => state.userForm);
  const [paymentDate, setPaymentDate] = useState(
    retainagePayout?.payment_date && new Date(retainagePayout.payment_date)
  );

  const [paymentCategory, setPaymentCategory] = useState(
    retainagePayout?.payment_category
  );
  const [paymentType, setPaymentType] = useState(retainagePayout?.payment_type);
  const [paymentAmount, setPaymentAmount] = useState(
    retainagePayout?.payment_amount
  );
  const [paymentTransaction, setPaymentTransaction] = useState(
    retainagePayout?.payment_txn_id
  );
  const [paymentStatus, setPaymentStatus] = useState(
    retainagePayout?.payment_status
  );
  const [description, setDescription] = useState(retainagePayout?.description);
  const [releaseRetainageChecked, setReleaseRetainageChecked] = useState(
    !!retainagePayout?.release_date
  );

  const [loading, setLoading] = useState(true);
  const [paymentCategoriesSearchResults, setPaymentCategoriesSearchResults] =
    useState([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
  const handleSubmit = async e => {
    const body = {
      payment_date: paymentDate && dayjs(paymentDate).format('YYYY-MM-DD'),
      payment_category_id: paymentCategory?.payment_category_id,
      payment_type_id: paymentType?.payment_type_id,
      payment_status_id: paymentStatus?.status_id,
      payment_amount: paymentAmount || 0,
      payment_txn_id: paymentTransaction,
      description,
      release_date: retainagePayout?.release_date,
    };
    setLoading(true);
    let response;
    if (retainagePayout?.payout_id)
      response = await updateRetainagePayout(
        userId,
        retainagePayout.retainage_id,
        retainagePayout.payout_id,
        body
      );
    else
      response = await createRetainagePayout(
        userId,
        retainagePayout.retainage_id,
        body
      );
    if (response?.status && response?.data) {
      setRefreshList(prev => !prev);
      retainageToast?.current?.show({
        severity: 'success',
        summary: response?.message,
        life: 2000,
      });
    } else if (response?.data?.message) {
      retainageToast?.current?.show({
        severity: 'error',
        summary: response?.data?.message?.replace(/_/g, ' '),
        life: 5000,
      });
    }
    setLoading(false);
    handleClose();
  };

  const handleClose = (systemItem = null, timeout = 0) => {
    setShowRetainagePayoutDialog(false);
  };

  const suggestPaymentCategories = async (event = null) => {
    const searchPaymentCategoriesResponse = await searchPaymentCategory(
      event?.query || ''
    );
    if (searchPaymentCategoriesResponse?.data?.count)
      setPaymentCategoriesSearchResults(
        searchPaymentCategoriesResponse?.data?.result
      );
    else setPaymentCategoriesSearchResults([]);
  };
  const handlePaymentCategoriesFocus = () => {
    suggestPaymentCategories();
    if (paymentCategoriesRef.current) {
      paymentCategoriesRef.current.show();
    }
  };

  const addPaymentCategory = async name => {
    if (name) {
      const newCategory = await createPaymentCategory(userId, { name });
      if (newCategory?.status && newCategory?.data) {
        const newCategoryObject = {
          payment_category_id: newCategory.data?.payment_category_id,
          name: newCategory.data?.name,
        };
        setPaymentCategory(newCategoryObject);
        suggestPaymentCategories();
        toast?.current?.show({
          severity: 'success',
          summary: `${name} category has been created successfully`,
          life: 2000,
        });
      }
    }
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  useEffect(() => {
    Promise.all([
      getPaymentType(setPaymentTypeOptions),
      getStatusOptions('RetainagePayout', () => null, setPaymentStatusOptions),
    ]).then(() => setLoading(false));
  }, []);

  return (
    <Dialog
      header="Release Retainage"
      style={{ width: '50vw' }}
      visible={visible}
      onHide={handleClose}
    >
      <div className="card flex justify-content-center">
        <Toast ref={toast} />
        <BlockUI blocked={loading}>
          <div className="grid">
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <Calendar
                    inputId="paymentDate"
                    value={paymentDate}
                    onChange={e => {
                      setPaymentDate(e.target.value);
                    }}
                    dateFormat={'mm-dd-yy'}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="paymentDate">Payment Date</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <AutoComplete
                    ref={paymentCategoriesRef}
                    id="paymentCategoryId"
                    aria-describedby="paymentCategoryId-help"
                    className="w-10"
                    suggestions={paymentCategoriesSearchResults}
                    field="name"
                    completeMethod={suggestPaymentCategories}
                    value={paymentCategory}
                    onChange={e => {
                      setPaymentCategory(e.target.value);
                    }}
                    inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    onFocus={handlePaymentCategoriesFocus}
                  />
                  <label htmlFor="categoryId">Payment Category</label>
                  <Button
                    icon="pi pi-check"
                    className="p-button-primary w-2 border-noround-left height-40"
                    disabled={onDisabled(
                      paymentCategoriesSearchResults,
                      paymentCategory,
                      'name'
                    )}
                    tooltip={
                      onDisabled(
                        paymentCategoriesSearchResults,
                        paymentCategory,
                        'name'
                      )
                        ? ''
                        : 'Add Category'
                    }
                    tooltipOptions={{ position: 'top' }}
                    onClick={() => addPaymentCategory(paymentCategory)}
                  />
                </span>
              </div>
            </div>
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <Dropdown
                    id="paymentType"
                    className="w-12"
                    optionLabel="payment_type"
                    options={paymentTypeOptions}
                    value={paymentType}
                    defaultValue={paymentType}
                    onChange={e => {
                      setPaymentType(e.value);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="paymentType">Payment Type</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <Dropdown
                    id="paymentStatus"
                    className="w-12"
                    optionLabel="status"
                    options={paymentStatusOptions}
                    value={paymentStatus}
                    defaultValue={paymentStatus}
                    onChange={e => {
                      setPaymentStatus(e.value);
                    }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                  />
                  <label htmlFor="paymentStatus">Payment Status</label>
                </span>
              </div>
            </div>
            <div className="flex flex-row col-12">
              <div className="col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="paymentAmount"
                    name="paymentAmount"
                    value={paymentAmount}
                    onValueChange={e => {
                      setPaymentAmount(e.value);
                    }}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-12"
                    min={0}
                  />
                  <label htmlFor="paymentAmount">Payment Amount</label>
                </span>
              </div>
              <div className="col-6">
                <span className="p-float-label">
                  <InputText
                    id="paymentTransaction"
                    value={paymentTransaction}
                    onChange={e => {
                      setPaymentTransaction(e.target.value);
                    }}
                    className="w-12"
                  />
                  <label htmlFor="paymentTransaction">
                    Payment Transaction ID
                  </label>
                </span>
              </div>
            </div>
            <div className="flex flex-row col-12">
              <div className="col-12">
                <span className="p-float-label">
                  <InputTextarea
                    id="description"
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                    rows={5}
                    cols={30}
                    className="w-full"
                  />
                  <label htmlFor="description">Description</label>
                </span>
              </div>
            </div>
            <div className="col-12 flex align-items-center ml-2">
              <Checkbox
                id="releaseRetainage"
                name="releaseRetainage"
                type="checkbox"
                checked={releaseRetainageChecked}
                onChange={e => {
                  setReleaseRetainageChecked(e.target.checked);
                }}
              />
              <label htmlFor="releaseRetainage" className="ml-2">
                Yes, I confirm to release the retainage
                <i className="text-red-400">*</i>
              </label>
            </div>
          </div>
          <div className="col-12 md:col-12 flex justify-content-end">
            <Button
              label="Cancel"
              size="small"
              outlined
              className="mr-3"
              onClick={handleClose}
            />
            <Button label="Save" size="small" onClick={handleSubmit} />
          </div>
        </BlockUI>
      </div>
    </Dialog>
  );
};
export default UserFormRetainagePayout;
