// **** React Imports ****
import React, { useState } from 'react';

// **** External Utilities ****
import {
  Grid,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import { Close as CloseIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { formatDate } from '../../../utils/Helpers';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createNewCredential,
  editCredential,
  deleteCredential,
  getTypesOptions,
  getInsuranceTypesOptions,
} from './UserManagement.service';

// **** Styles *****
import { useStyles } from './UserManagement.styles';

const credentialsColumns = [
  {
    id: 'status',
    value: 'Status',
  },
  {
    id: 'type',
    value: 'Type',
  },
  {
    id: 'issuing_body',
    value: 'Issuing Body',
  },
  {
    id: 'expiration_date',
    value: 'Expiration Date',
  },
  {
    id: 'number',
    value: 'Phone Number',
  },
];

const Credentials = ({ credentialsData, userId, setReloadCredentialsList }) => {
  const classes = useStyles();
  const imageInputRef = React.useRef();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Credential',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [typesOptions, setTypesOptions] = React.useState([]);
  const [insuranceTypes, setInsuranceTypes] = React.useState([]);
  const [isNewFileSelected, setIsNewFileSelected] = useState(false);
  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  let credentialsInfo = [];
  React.useEffect(() => {
    getTypesOptions(setTypesOptions);
    getInsuranceTypesOptions(setInsuranceTypes);
  }, []);

  // **** Data Modification to Display *****
  if (credentialsData?.length) {
    credentialsInfo = credentialsData?.map((credential, index) => {
      var exp_date = moment(credential.expiration_date);
      var now = moment();

      return {
        id: { value: index },
        status: {
          value: (
            <Typography
              variant="subtitle2"
              className={
                now < exp_date
                  ? classes.statusColorAcknowledged
                  : classes.statusColorCancelled
              }
            >
              {now < exp_date ? 'Active' : 'Expired'}
            </Typography>
          ),
        },
        type: {
          value: typesOptions.find(
            val => credential.type === val.credential_type_id
          )?.type_name,
          id: credential.type,
        },
        insurance_type: { value: credential?.insurance_type || null },
        description: { value: credential.description },
        city: { value: credential.city },
        state: { value: credential.state },
        number: { value: credential.number },
        issuing_body: { value: credential.issuing_body },
        expiration_date: { value: formatDate(credential.expiration_date) },
        expiration_date_value: { value: credential.expiration_date },
        files: { value: credential?.files },
      };
    });
  }

  //View Emergency Contact
  const viewEditCredential = (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Credential') || 'Edit Credential',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };
  const confirmDeleteCredential = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete credential?',
      header: 'Delete Credential',
    });
  };
  const onConfirmDialog = () => {
    const remainingCredentialData = credentialsInfo
      .filter((val, index) => {
        return rowIndex !== val.id?.value;
      })
      .map(val => {
        return {
          type: val.type.id,
          insurance_type: val.insurance_type.value,
          issuing_body: val.issuing_body.id,
          description: val.description.value,
          city: val.city.value,
          state: val.state.value,
          number: val.number.value,
          expiration_date: val.expiration_date.value,
        };
      });

    deleteCredential(
      userId,
      remainingCredentialData,
      setLoading,
      setAlert,
      setReloadCredentialsList,
      setConfirmDialog
    );
  };

  //Emergency Contact ValidationSchema
  const credentialsValidationSchema = Yup.object().shape({
    type: Yup.string().trim().required('Required'),
    insurance_type: Yup.string()
      .trim()
      .nullable()
      .when('type', {
        is: 3,
        then: Yup.string().trim().required('Required'),
      }),
    issuing_body: Yup.string().trim().nullable(),
    description: Yup.string().trim().nullable(),
    city: Yup.string().trim().nullable(),
    state: Yup.string().trim().nullable(),
    number: Yup.string().trim().nullable(),
    expiration_date: Yup.date()
      .required('Required')
      .typeError('Choose a valid Date')
      .min(yesterdayDate, 'Date cannot be in the past'),
    files: Yup.object().nullable(),
  });
  // **** Formik Form Values ****
  const credentialsFormik = useFormik({
    initialValues: {
      type:
        (formAction !== 'add' && credentialsInfo[rowIndex]?.type?.id) || null,
      insurance_type:
        (formAction !== 'add' &&
          credentialsInfo[rowIndex]?.insurance_type?.value) ||
        null,
      issuing_body:
        (formAction !== 'add' &&
          credentialsInfo[rowIndex]?.issuing_body?.value) ||
        null,
      description:
        (formAction !== 'add' &&
          credentialsInfo[rowIndex]?.description?.value) ||
        null,
      city:
        (formAction !== 'add' && credentialsInfo[rowIndex]?.city?.value) ||
        null,
      state:
        (formAction !== 'add' && credentialsInfo[rowIndex]?.state?.value) ||
        null,
      number:
        (formAction !== 'add' && credentialsInfo[rowIndex]?.number?.value) ||
        null,
      expiration_date:
        (formAction !== 'add' &&
          credentialsInfo[rowIndex]?.expiration_date_value?.value) ||
        null,
      files:
        (formAction !== 'add' && credentialsInfo[rowIndex]?.files?.value) || '',
    },
    onSubmit: values => {
      if (formAction === 'add') {
        createNewCredential(
          userId,
          credentialsData,
          values,
          setLoading,
          setIsOpen,
          setReloadCredentialsList,
          setAlert
        );
      } else {
        const updatedCredentialData = credentialsInfo.map(val => {
          return rowIndex !== val.id?.value
            ? {
                type: val.type.id,
                insurance_type: val.insurance_type.value,
                issuing_body: val.issuing_body.value,
                description: val.description.value,
                city: val.city.value,
                state: val.state.value,
                number: val.number.value,
                expiration_date: val.expiration_date.value,
              }
            : {
                type: credentialsFormik.values.type,
                insurance_type: credentialsFormik.values.insurance_type,
                issuing_body: credentialsFormik.values.issuing_body,
                description: credentialsFormik.values.description,
                city: credentialsFormik.values.city,
                state: credentialsFormik.values.state,
                number: credentialsFormik.values.number,
                expiration_date: credentialsFormik.values.expiration_date,
              };
        });
        editCredential(
          userId,
          updatedCredentialData,
          values,
          setLoading,
          setIsOpen,
          setReloadCredentialsList,
          setAlert,
          isNewFileSelected,
          setIsNewFileSelected
        );
      }
    },
    validationSchema: credentialsValidationSchema,
    enableReinitialize: true,
  });

  const credentialsFilesArray = Array.from(credentialsFormik?.values?.files);

  const getCredentialsViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Autocomplete
            name="type"
            id="type"
            options={typesOptions}
            onBlur={credentialsFormik?.handleBlur}
            onChange={(event, value) => {
              credentialsFormik.setFieldValue(
                'type',
                value?.credential_type_id
              );
            }}
            getOptionLabel={option => option && option?.type_name?.toString()}
            renderInput={params => (
              <TextField
                {...params}
                label="Type"
                required={formAction !== 'view'}
                InputLabelProps={{ shrink: true }}
              />
            )}
            required={formAction !== 'view'}
            value={
              typesOptions.filter(
                item =>
                  item?.credential_type_id === credentialsFormik?.values?.type
              )[0] || ''
            }
            disabled={formAction === 'view'}
          />
        </Grid>
        {credentialsFormik?.values?.type == 3 && (
          <Grid item>
            <Autocomplete
              name="insurance_type"
              id="insurance_type"
              options={insuranceTypes}
              onBlur={credentialsFormik?.handleBlur}
              onChange={(event, value) => {
                credentialsFormik.setFieldValue(
                  'insurance_type',
                  value?.insurance_type_id
                );
              }}
              getOptionLabel={option => option && option?.type_name?.toString()}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Insurance Type"
                  required={formAction !== 'view'}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              required={formAction !== 'view'}
              value={
                insuranceTypes.filter(
                  item =>
                    item.insurance_type_id ===
                    credentialsFormik?.values?.insurance_type
                )[0] || ''
              }
              disabled={formAction === 'view'}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            label="Issuing Body"
            name="issuing_body"
            id="issuing_body"
            onChange={credentialsFormik.handleChange}
            onBlur={credentialsFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={credentialsFormik?.values?.issuing_body}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Description"
            name="description"
            id="description"
            onChange={credentialsFormik.handleChange}
            onBlur={credentialsFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={credentialsFormik?.values?.description}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="City"
            name="city"
            onChange={credentialsFormik.handleChange}
            onBlur={credentialsFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={credentialsFormik?.values?.city}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="State"
            name="state"
            id="state"
            onChange={credentialsFormik.handleChange}
            onBlur={credentialsFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={credentialsFormik?.values?.state}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <InputMask
            mask="(999) 999-9999"
            value={credentialsFormik?.values?.number || ''}
            onChange={credentialsFormik.handleChange}
            onBlur={credentialsFormik.handleBlur}
            maskChar=" "
            placeholder=""
            disabled={formAction === 'view'}
            InputProps={{
              readOnly: formAction === 'view',
            }}
          >
            {() => (
              <TextField
                name={`number`}
                label="Phone Number"
                InputLabelProps={{ shrink: true }}
                error={
                  credentialsFormik.touched.number &&
                  credentialsFormik.errors.number
                }
                helperText={
                  credentialsFormik.touched.number &&
                  credentialsFormik.errors.number
                }
              />
            )}
          </InputMask>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              name="expiration_date"
              id="expiration_date"
              label="Expiration Date"
              format="MM-dd-yyyy"
              autoOk
              value={
                (credentialsFormik?.values?.expiration_date &&
                  credentialsFormik?.values?.expiration_date?.split('T')
                    ?.length &&
                  parseISO(
                    credentialsFormik?.values?.expiration_date?.slice(0, 10)
                  )) ||
                null
              }
              onChange={(date, value) =>
                credentialsFormik?.setFieldValue(
                  'expiration_date',
                  `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                )
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
                disabled: formAction === 'view',
                style: { display: formAction === 'view' && 'none' },
              }}
              disabled={formAction === 'view'}
              required={formAction !== 'view'}
              fullWidth
              InputLabelProps={{ shrink: true }}
              minDate={yesterdayDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <InputLabel shrink id="fielupload-label" className={classes.label}>
            Photo
          </InputLabel>
          {credentialsFormik?.values?.files && formAction !== 'add' && (
            <Grid container direction="row" wrap="nowrap" spacing={0}>
              {credentialsFilesArray?.map(phot => (
                <Grid
                  item
                  container
                  key={phot.name}
                  spacing={2}
                  direction="row"
                  wrap="nowrap"
                >
                  <a
                    href={phot?.url + '#download'}
                    target="_blank"
                    rel="noreferrer"
                    download={phot?.name}
                    className={classes.photoName}
                  >
                    {phot?.name}
                  </a>
                </Grid>
              ))}
            </Grid>
          )}
          {formAction !== 'view' && (
            <>
              <Grid>
                <input
                  ref={imageInputRef}
                  type="file"
                  id="files"
                  name="files"
                  label="fielupload-label"
                  multiple
                  onBlur={credentialsFormik?.handleBlur}
                  onClick={event => {
                    setIsNewFileSelected(true);
                    event.target.value = null;
                  }}
                  onChange={(event, value) => {
                    if (event.target.files && event.target.files[0]) {
                      if (
                        ['svg', 'SVG', 'gif', 'GIF'].includes(
                          event.currentTarget.files[0].name.split('.').pop()
                        )
                      ) {
                        const imageFormat = event.currentTarget.files[0].name
                          .split('.')
                          .pop();
                        if (imageFormat === 'svg' || imageFormat === 'SVG') {
                          credentialsFormik.setErrors({
                            files: 'System does not accept SVG files.',
                          });
                          imageInputRef.current.value = '';
                        }
                        if (imageFormat === 'GIF' || imageFormat === 'gif') {
                          credentialsFormik.setErrors({
                            files: 'System does not accept GIF files.',
                          });
                          imageInputRef.current.value = '';
                        }
                      } else {
                        credentialsFormik.setFieldValue(
                          'files',
                          event.currentTarget.files[0]
                        );
                      }
                    }
                  }}
                  className={classes.inputfileupload}
                />
              </Grid>
              {credentialsFormik.errors.files && (
                <FormHelperText error>
                  {credentialsFormik.errors.files}
                </FormHelperText>
              )}
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <PFTableLoader />
          </Grid>
        </Grid>
      )) || (
        <Grid container spacing={2} direction="column">
          <Grid
            item
            style={{ maxWidth: '100%' }}
            classes={{ root: classes.tableWrapper }}
          >
            <GenericTable
              title="Credentials"
              columnData={credentialsColumns}
              rowData={credentialsInfo}
              showActions={{ view: true, edit: true, delete: true }}
              headerLinks={[
                {
                  label: 'Add Item',
                  handler: () => {
                    setFormAction('add');
                    credentialsFormik.resetForm();
                    setDialogSettings(prevState => ({
                      ...prevState,
                      showButton2: true,
                      title: 'Add Credential',
                    }));
                    setIsOpen(true);
                  },
                },
              ]}
              handleView={index => viewEditCredential('view', index)}
              handleEdit={index => viewEditCredential('edit', index)}
              handleDelete={index => confirmDeleteCredential(index)}
            />
          </Grid>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              credentialsFormik?.handleReset();
            }}
            handleSave={credentialsFormik.handleSubmit}
            dialogSettings={dialogSettings}
            disabledButton2={
              !credentialsFormik?.dirty || !credentialsFormik?.isValid
            }
            disabledButton1={false}
          >
            <form>{getCredentialsViewEditForm()}</form>
          </GenericDialog>
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />
        </Grid>
      )}
    </>
  );
};

export default Credentials;
