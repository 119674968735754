import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton, Dialog } from 'primereact';
import { Toast } from 'primereact/toast';

import AnswerComponent from '../../Admin/Questionnaire/AnswerComponent';
import PFDataTable from '../../shared/PFPrime/PFDataTable';
import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { formatDateTime } from '../../../utils/Helpers';
import { updateQuestionnaireStore } from '../../../redux/slices/questionnaire.slice';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';

const UserChecklist = () => {
  const { userId: targetId } = useSelector(state => state.userForm);
  const toast = useRef(null);
  const [moduleDetailsData, setModuleDetailsData] = useState([[], [], []]);
  const [moduleList, setModuleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isShowCheckListPopup, setIsShowCheckListPopup] = useState(false);

  const dispatch = useDispatch();
  const { refetchInspectionDetails, submittedFormDetails, hideFormPopup } =
    useSelector(state => state.questionnaire);

  const [selectedModule, setSelectedModule] = useState(null);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [selectedModuleName, setSelectedModuleName] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);

  const fetchSubmittedDetails = async () => {
    try {
      const response = await ApiService.getWithDiffBaseUrl(
        `/api/questionnaire/inspection-submit-details/${targetId}?moduleTags=USER`,
        null,
        URL_CONSTANTS.QUESTIONNAIRE.baseUrl
      );
      if (response.status && response.data?.length) {
        setModuleDetailsData(response.data);
      } else {
        setModuleDetailsData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getModuleListByType = async () => {
    const response = await ApiService.getWithDiffBaseUrl(
      `/api/questionnaire/module/type-list/USER`,
      null,
      URL_CONSTANTS.QUESTIONNAIRE.baseUrl
    );
    if (response && response.data) setModuleList(response.data);
  };
  useEffect(() => {
    fetchSubmittedDetails();
    getModuleListByType();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsShowCheckListPopup(false);
      updateQuestionnaireStore({
        isAnswerLoading: false,
      });
    }, 2000);
  }, [submittedFormDetails, hideFormPopup]);

  useEffect(() => {
    if (refetchInspectionDetails) {
      fetchSubmittedDetails();
      dispatch(
        updateQuestionnaireStore({
          refetchInspectionDetails: false,
          submittedFormDetails: null,
        })
      );
    }
  }, [refetchInspectionDetails]);

  const moduleSelectHandler = (moduleId, selectedModuleName, params) => {
    setSelectedRecord(params);
    setSelectedModule(moduleId);
    setSelectedModuleName(selectedModuleName);
    setIsShowCheckListPopup(true);
    setIsNewRecord(false);
  };

  const tableColum = [
    {
      field: 'module_name',
      header: 'Module',
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          params.module_name
        ),
    },
    {
      field: 'label',
      header: 'Checklist',
      body: params => {
        return loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          <span
            className="text-primary cursor-pointer"
            onClick={event => {
              event.preventDefault();
              moduleSelectHandler(params.module_id, params.module_name, params);
            }}
          >
            {params.label}
          </span>
        );
      },
    },
    {
      field: 'created_by',
      header: 'Submitted By',
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          params.created_by
        ),
    },
    {
      field: 'created_at',
      header: 'Submitted At',
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.created_at)
        ),
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          params.modified_by
        ),
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      body: params =>
        loading ? (
          <Skeleton className="mb-2" height="2rem" />
        ) : (
          formatDateTime(params.modified_at)
        ),
    },
  ];

  return (
    <>
      <div className="grid   w-12 my-3">
        <div className="col-6 md:col-7"></div>
        <div className="flex col-6 md:col-5 justify-content-end">
          <div className="w-6 mr-3">
            <PFDropdown
              value={moduleList.find(
                record => record.module_id == selectedModule
              )}
              onChange={(name, value) => {
                setSelectedModule(value.module_id);
                setSelectedModuleName(value.module_name);
              }}
              options={moduleList}
              className="w-full"
              optionLabel="module_name"
              placeholder="Select a Module"
              inputId="module"
              name="module"
            />
          </div>
          <div>
            <PFButton
              size="small"
              label="Create"
              severity="primary"
              icon={'pi pi-plus'}
              iconPos="left"
              onClick={() => {
                if (selectedModule) {
                  setIsShowCheckListPopup(true);
                  setIsNewRecord(true);
                } else {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please select module',
                    life: 3000,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      <PFDataTable columns={tableColum} data={moduleDetailsData} />
      <Dialog
        header={isNewRecord ? 'Create Checklist' : 'Update Checklist'}
        visible={isShowCheckListPopup}
        onHide={() => setIsShowCheckListPopup(false)}
        className="w-11 xl:w-8 w-10"
        style={{ minHeight: '550px' }}
      >
        <AnswerComponent
          moduleTagName={selectedModule}
          targetId={targetId}
          moduleId={selectedModule}
          selectedModuleName={selectedModuleName}
          isNewRecord={isNewRecord}
          selectedRecord={selectedRecord}
          isRenderConfirmationPopup={false}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default UserChecklist;
