import { makeStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
  navbarTooltip: {
    padding: '10px',

    backgroundColor: '#B3B3B3',
    color: 'white',
    fontWeight: '700',
    fontSize: '0.75rem',
  },
  breadcrumbIcon: {
    margin: '-3px 15px',
  },
  appBar: props => ({
    height: '60px',
    marginLeft: props.drawerWidth,
    width: `calc(100% - 175px)`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minWidth: '320px',
    boxShadow: 'unset',
  }),
  avatarItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      minWidth: '140px',
    },
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  boxColumn: {
    marginRight: '0',
    '& .MuiInputBase-root': {
      borderRadius: '0px',
      height: '100%',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none !important',
    },
  },
  button: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  buttonIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonIconSizeLarge: {
    fill: '#ffffff',
  },
  clientName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  collapseRoot: {
    '& .MuiListItemIcon-root': {
      paddingLeft: '10px',
    },
    '& .MuiList-root .MuiListItem-root': {
      '& .MuiListItemIcon-root .MuiSvgIcon-root': {
        width: '4px',
        height: '4px',
      },
      '& .MuiListItemText-root': {
        color: theme.palette.grey[500],
      },
      '&:hover': {
        borderLeft: `6px solid ${theme.palette.common.white}`,
        backgroundColor: theme.palette.common.white,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.common.white,
        borderLeft: `6px solid ${theme.palette.common.white}`,
        '& .MuiListItemText-root': {
          color: theme.palette.text.primary,
          '& .MuiTypography-body1': {
            fontWeight: 600,
          },
        },
        '& .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
          width: '8px',
          height: '8px',
        },
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.common.white,
        borderLeft: `6px solid ${theme.palette.common.white}`,
        '& .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
          width: '8px',
          height: '8px',
        },
      },
    },
  },
  drawerRootClose: {
    '& .MuiListItem-root': {
      padding: '9px 17px !important',
    },
  },
  drawerRoot: {
    color: theme.palette.primary.main,
    '& .MuiListItem-root': {
      alignItems: 'baseline',
      padding: '10px 20px',
      fontSize: '14px',
      borderLeft: `6px solid ${theme.palette.common.white}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeft: `6px solid ${theme.palette.primary.main}`,
        '& .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
        },
      },

      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth: '6px',
        '& .MuiListItemText-root': {
          color: theme.palette.primary.main,
          '& .MuiTypography-body1': {
            fontWeight: 600,
          },
        },
        '& .MuiSvgIcon-root': {
          fill: theme.palette.primary.main,
        },
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeftStyle: '​solid',
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth: '6px',
      },
    },
  },
  drawerPaper: props => ({
    overflow: 'hidden',
    width: props.drawerWidth,
    flexShrink: 0,
    '&:hover': {
      overflowY: 'auto',
      'scrollbar-width': 'thin',
      '&::-webkit-scrollbar': {
        // Width of vertical scroll bar
        width: '8px',
        // Height of horizontal scroll bar
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: '#c2c9d2',
      },
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  drawerPaperClose: {
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      overflowY: 'auto',
      'scrollbar-width': 'thin',
      '&::-webkit-scrollbar': {
        // Width of vertical scroll bar
        width: '8px',
        // Height of horizontal scroll bar
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: '#c2c9d2',
      },
    },

    [theme.breakpoints.up('md')]: {
      width: theme.spacing(7.25) + 1,
    },
  },
  expandIcon: {
    width: '16px',
    height: '16px',
  },
  listItemIcon: {
    minWidth: '20px',
  },
  fixedHeight: {
    height: 240,
  },
  headerText: {
    '&&': {
      '& .MuiTypography-root': {
        fontWeight: 400,
        fontSize: '14px',
      },
    },
  },
  headerLinkText: {
    fontWeight: 600,
    fontSize: '13px',
    cursor: 'pointer',
  },
  hideMenuWrapper: props => ({
    paddingLeft: !props.open ? '22px' : '35px',
    [theme.breakpoints.down('sm')]: props => ({
      paddingLeft: !props.open ? '0px' : '35px',
    }),
  }),
  hideMenuButton: {
    fontSize: '14px',
    paddingLeft: '14px',
    fontWeight: 600,
  },
  imageIconWrapper: {
    paddingLeft: '12px',
    padding: '10px 24px',
  },
  imageIconRoot: {
    width: '100%',
    height: '100%',
  },
  imageIcon: {
    height: '40px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  labelItem: {
    paddingLeft: '10px',
  },
  loginButton: {
    color: theme.palette.grey[700],
  },
  logoIcon: {
    height: '33px',
    width: '33px',
    backgroundColor: theme.palette.secondary.main,
  },
  navbarIcon: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  navbarSearch: {
    paddingLeft: '0',
    paddingRight: '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  outlinedInputRoot: {
    borderRadius: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  permDrawer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  popupIndicator: {
    marginTop: '4px',
  },
  toolBarMenuIcon: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tempDrawer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #C4CDD5',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectFilled: {
    padding: '10px 12px 10px',
    borderBottom: 'none !important',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  selectIcon: { top: 'calc(50% - 9px)' },
  searchAutocompleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-subtitle1': {
      fontWeight: 'normal',
    },
  },
  searchAutocompleteHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchAutocompletesubText: {
    opacity: '50%',
  },

  menuWrapper: {
    paddingTop: 0,
    '& .MuiList-root': {
      paddingTop: 0,
    },
  },
  listRoot: {
    width: '100%',
    minWidth: '40ch',
    maxWidth: '40ch',
    backgroundColor: '#ffffff',
    padding: 0,
  },
  listItems: {
    '& div:not(:last-child)': {
      marginRight: '10px',
    },
    '& div:first-child': {
      width: '70%',
      '& *': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  listItemRootActive: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.primary.light,
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '0px',
    paddingLeft: '12px',
    paddingRight: '12px',
    position: 'sticky',
    top: 0,
    background: '#ffffff',
    zIndex: 1,
    borderBottom: '1px solid #B3B3B3',
  },
  listItemDivider: {
    position: 'sticky',
    top: 0,
  },
  inline: {
    display: 'inline',
  },
  viewNotificationLink: {
    display: 'inline',
    cursor: 'pointer',
  },
  toolbar: props => ({
    paddingRight: 24,
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.grey[900],
    marginBottom: '-4px',
    borderBottom: props.exist && `1px solid ${theme.palette.grey[300]}`,
  }),
  smsLoader: {
    padding: '20px',
    justifyContent: 'center',
    float: 'center',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    color: theme.palette.text.primary,
    ...theme.mixins.toolbar,
  },
  toolbarContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '5px 8px',
    width: '100%',
    ...theme.mixins.toolbar,
  },
  emptyResponseText: {
    justifyContent: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  loadMoreText: {
    fontWeight: 600,
    fontSize: '13px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  marquee: {
    borderBottom: '1px solid #b3b3b3',
    backgroundColor: '#dfdfdf',
  },
}));
