import * as Yup from 'yup';
import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const clientInfoInit = {
  clientId: '',
  senderDetails: '',
  name: '',
  email: '',
  phone: '',
  customerBillingAddress: '',
  customerServiceAddress: '',
};

export const clientInfoValidation = {
  customerBillingAddress: Yup.string(),
  customerServiceAddress: Yup.string(),
};

export const handleSuggestions = async (searchString = null) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/customers?searchString=${searchString}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
