import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

const DeleteWarehouse = ({
  editableData,
  confirmationDeletion,
  onDeleteWarehouseHandler,
}) => {
  const [visible, setVisible] = useState(false);
  const accept = () => {
    confirmationDeletion();
  };

  const reject = () => {
    setVisible(false);
  };
  return (
    <>
      {editableData && (
        <>
          <ConfirmDialog
            visible={visible}
            onHide={() => setVisible(false)}
            message="Are you sure you want to delete it?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
          />
          <Button
            size="small"
            type="button"
            outlined
            severity="primary"
            onClick={() => setVisible(true)}
          >
            Delete Warehouses
          </Button>
        </>
      )}
    </>
  );
};

export default DeleteWarehouse;
