import { Button } from 'primereact';
const ButtonTabularComponent = ({ formikData, onClickHandler = () => {} }) => {
  return (
    <Button
      label={formikData.widget_name || ''}
      onClick={onClickHandler}
      severity={formikData?.custom_properties?.buttonColor?.code}
    />
  );
};
export default ButtonTabularComponent;
