import React, { useState, memo, useRef } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import {
  convertDateToLocaleString,
  getPercentageValue,
  momentTz,
  checkPermission,
  convert24HourTimeTo12,
} from '../../../utils/Helpers';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import permissions from '../../../config/permissions';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { getSystemTenants } from '../../Admin/Configuration/RestrictAccessbyRole.service';
import {
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserAction,
  COMMISSION_WORK_TYPE,
  PROJECT_TYPES,
  scheduleInfoUserCustomAction,
} from '../../../constants';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { getActiveInstallers } from '../../Admin/Stores/Stores.service';

import {
  getInstallerByProject,
  getInstallerTypeOptions,
  createInstaller,
  updateInstaller,
  deleteProjectInstaller,
  updateProject,
  verifyInstaller,
  getInstallerOptions,
} from './ScheduleInfo.service';
import InstallerOnLeaveDialog from './installerOnLeaveDialog';
import InstallerTable from './installerTable';
import InstallerForm from './installer-form';
import {
  InstallerFormikSchema,
  handleFormValidate,
} from './installer-form-validations';

const Loader = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={3}
    className="min-h-367"
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

const Installer = memo(
  ({
    historyProp,
    setReloadFormProp,
    setLoadingProp,
    setAlertProp,
    formik,
    action,
    setReloadCalender,
    reloadCalender,
    projectId,
    projectStatus,
    schedulerEditPermission,
    setReloadLaborItems,
    isSalesCommissionOpted,
    commissionedTechncianLineItem,
    setMsProjectInstaller,
    ...rest
  }) => {
    const [loading, setLoading] = useState(true);
    const [isOnLeaveDialogOpen, setIsOnLeaveDialogOpen] = useState(false);
    const [getFormData, setFormData] = useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [allInstallerOptions, setAllInstallerOptions] = useState([]);
    const [filteredInstallerOptions, setfilteredInstallerOptions] = useState(
      []
    );
    const [
      isCommissionTypeTechnicianFound,
      setIsCommissionTypeTechnicianFound,
    ] = useState(false);
    const [allInstallerTypeOptions, setAllInstallerTypeOptions] = useState([]);
    const [filteredInstallerTypeOptions, setFilteredInstallerTypeOptions] =
      useState([]);
    const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = useState();
    const [isBlockedTimeDialog, setIsBlockedTimeDialog] = useState();
    const [formAction, setFormAction] = useState();
    const [dialogSettings, setDialogSettings] = useState({
      title: 'View Technician',
      button1Text: '',
      button2Text: 'Add',
      showButton1: true,
      showButton2: true,
    });
    const [rowData, setRowData] = useState([]);
    const [rowIndex, setRowIndex] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
      header: '',
      title: '',
      subtitle: '',
      isOpen: false,
    });
    const [reloadList, setReloadList] = useState(false);
    const { setAlert } = useAlerts();
    const [allocationPermission, setAllocationPermission] = useState();
    const [projectInstaller, setProjectInstaller] = useState({
      installer_id: null,
      installer_name: null,
    });

    const [percentageAllocation, setPercentageAllocation] = useState(1);

    const toast = useRef(null);

    const fetchInitializationData = async () => {
      setLoading(true);
      let isTentativeFeatureEnabled = false;
      const clientConfiguration = await getSystemTenants();

      if (clientConfiguration) {
        const { included_permissions, allocation_percentage } =
          clientConfiguration.tenant_permissions[0];
        if (included_permissions.includes('ENABLE_TENTATIVE_SCHEDULING')) {
          isTentativeFeatureEnabled = true;
        } else {
          isTentativeFeatureEnabled = false;
        }
        const allocateItemKeys = Object.keys(scheduleInfoUserAction);

        const alreadyAllocatePermission =
          included_permissions?.filter(perm =>
            allocateItemKeys.includes(perm)
          ) || [];
        setAllocationPermission(
          scheduleInfoUserAction[alreadyAllocatePermission?.[0]]
        );
        setPercentageAllocation(allocation_percentage);
      }

      let [installersList, installerTypeList, installerTableData] =
        await Promise.all([
          getInstallerOptions(),
          getInstallerTypeOptions(),
          getInstallerByProject(projectId),
        ]);

      if (!isTentativeFeatureEnabled && installerTypeList) {
        installerTypeList =
          installerTypeList?.filter(
            type => type.worktype !== PROJECT_TYPES.TENTATIVE_SCHEDULE
          ) || [];
      }
      if (rest?.setProjectInstallers) {
        rest?.setProjectInstallers(installerTableData || []);
      }
      let isAnyCommissionTypeTechnician = false;
      const rowData =
        installerTableData?.map(installer => {
          if (installer?.worktypeid) {
            setProjectInstaller({
              installer_id: installer?.worktypeid,
              installer_detail: installer?.installer_detail,
            });
          }
          if (installer?.worktypeid === COMMISSION_WORK_TYPE.ID) {
            isAnyCommissionTypeTechnician = true;
          }
          return {
            project_installer_id: { value: installer?.project_installer_id },
            installer_id: {
              value: installer?.installer_id,
            },

            type: {
              id: installer?.worktypeid,
              value: installer?.worktypeid
                ? installerTypeList.find(
                    type => type?.worktypeid === installer?.worktypeid
                  )?.worktype
                : '',
            },
            installer: {
              value:
                installer?.installer_detail !== null
                  ? `${installer?.installer_detail?.user?.first_name} ${installer?.installer_detail?.user?.last_name}`
                  : '',
            },
            scheduleDate: {
              // for displaying in table
              value: installer?.date_scheduled_start
                ? momentTz(installer?.date_scheduled_start).format(
                    'MM-DD-YYYY hh:mm A'
                  )
                : null,
            },
            scheduleDateValue: {
              // for edit/view form
              value: installer?.date_scheduled_start
                ? convertDateToLocaleString(installer?.date_scheduled_start)
                : null,
            },
            projectEndDate: {
              // for displaying in table
              value: installer?.date_scheduled_end
                ? momentTz(installer?.date_scheduled_end).format(
                    'MM-DD-YYYY hh:mm A'
                  )
                : null,
            },
            projectEndDateValue: {
              // for edit/view form
              value: installer?.date_scheduled_end
                ? convertDateToLocaleString(installer?.date_scheduled_end)
                : null,
            },
            installer_arrival_start_time: {
              value: convert24HourTimeTo12(
                installer?.installer_arrival_start_time
              ), // This will go into table
              label: installer?.installer_arrival_start_time, // This will go in add/view/edit forms
            },
            installer_arrival_end_time: {
              value: convert24HourTimeTo12(
                installer?.installer_arrival_end_time
              ),
              label: installer?.installer_arrival_end_time,
            },
            laborAmount: {
              value: installer?.labor_amount
                ? '$' + installer?.labor_amount
                : '',
            },
            hideCloneIcon: {
              value: isAnyCommissionTypeTechnician ? 'yes' : 'no',
            },
            hideDeleteIcon: {
              value:
                installer?.installer_id ==
                commissionedTechncianLineItem?.project_item_user
                  ?.installer_details?.installer_id
                  ? 'yes'
                  : 'no',
            },
          };
        }) || [];
      setAllInstallerOptions(installersList);
      setAllInstallerTypeOptions(installerTypeList);

      let filteredInstallerTypeList = [];
      const isScheduledInstallerPresent = rowData?.some(
        item =>
          item.type.value === PROJECT_TYPES.SCHEDULE ||
          item.type.value === PROJECT_TYPES.TENTATIVE_SCHEDULE
      );
      if (isScheduledInstallerPresent) {
        setMsProjectInstaller && setMsProjectInstaller(true);
        filteredInstallerTypeList =
          installerTypeList?.filter(
            type =>
              type.worktype !== PROJECT_TYPES.SCHEDULE &&
              type.worktype !== PROJECT_TYPES.TENTATIVE_SCHEDULE
          ) || [];
      } else {
        setMsProjectInstaller && setMsProjectInstaller(false);
        filteredInstallerTypeList = installerTypeList;
      }

      setFilteredInstallerTypeOptions(filteredInstallerTypeList);
      setRowData(rowData);
      setIsCommissionTypeTechnicianFound(isAnyCommissionTypeTechnician);
      setLoading(false);
    };

    const fetchInstallerList = async () => {
      const installersList = await getActiveInstallers();
      setAllInstallerOptions(installersList);
      setfilteredInstallerOptions(installersList);
    };
    React.useEffect(() => {
      if (rest?.refereshInstallerList) {
        setReloadList(true);
        rest?.setRefereshInstallerList(false);
      }
    }, [rest?.refereshInstallerList]);

    React.useMemo(() => {
      fetchInitializationData();
      setReloadList(false);
      try {
        setReloadLaborItems(true);
        setDropTimeOffRequestIds();
      } catch (error) {}
    }, [reloadList]);

    React.useEffect(() => {
      return () => {
        setReloadList(false);
        setDropTimeOffRequestIds();
      };
    }, []);

    const handleOnLeaveDialogClose = () => {
      setIsOnLeaveDialogOpen(false);
      setIsBlockedTimeDialog(false);
      installerFormik.setSubmitting(false);
    };

    const checkIfInstallerAvailable = async values => {
      const installerData = {
        installer_id: values?.installer_id,
        worktypeid: values?.type,
        date_scheduled_start: values?.date_scheduled_start,
        date_scheduled_end: values?.date_scheduled_end,
      };
      const response = await verifyInstaller(
        formik?.values?.project_id,
        installerData
      );
      if (response?.warning) {
        const isBlockTimeCase = response?.user_time_off_request_ids?.some(
          i => i?.is_blocked_time === 1
        );
        if (isBlockTimeCase) {
          setDropTimeOffRequestIds(response?.user_time_off_request_ids);
          setIsBlockedTimeDialog(true);
          setIsOnLeaveDialogOpen(true);
        } else {
          setIsBlockedTimeDialog(false);
          setIsOnLeaveDialogOpen(true);
        }
      } else {
        await createUpdateInstaller(values);
      }
    };

    const createUpdateInstaller = async (data = null) => {
      let apiMessageResponse = null;
      let values = {};
      if (data) {
        values = data;
      } else {
        values = getFormData;
      }

      const installerData = {
        installer_id: values?.installer_id,
        worktypeid: values?.type,
        date_scheduled_start: values?.date_scheduled_start,
        date_scheduled_end: values?.date_scheduled_end,
        installer_arrival_start_time: values?.installer_arrival_start_time,
        installer_arrival_end_time: values?.installer_arrival_end_time,
      };

      if (formAction === 'add' || formAction === 'clone') {
        if (
          installerData?.date_scheduled_start &&
          installerData?.date_scheduled_end &&
          installerData?.installer_id &&
          installerData?.worktypeid
        ) {
          apiMessageResponse = 'Technician has been added successfully.';
        }
        if (
          !installerData?.date_scheduled_start &&
          !installerData?.date_scheduled_end &&
          installerData?.installer_id &&
          installerData?.worktypeid
        ) {
          apiMessageResponse = 'Technician has been added successfully.';
        }
        if (
          installerData?.date_scheduled_start &&
          installerData?.date_scheduled_end &&
          !installerData?.installer_id &&
          !installerData?.worktypeid
        ) {
          apiMessageResponse =
            'Scheduling date and time has been added successfully.';
        }
        const createInstallerResponse = await createInstaller(
          formik?.values?.project_id,
          {
            ...installerData,
            userAction: values?.userAction,
            percentage:
              values?.userAction === scheduleInfoUserCustomAction
                ? percentageAllocation
                : 0,
            date_scheduled_end: date_scheduled_end
              ? momentTz(installerData?.date_scheduled_end)
              : null,
            date_scheduled_start: date_scheduled_start
              ? momentTz(installerData?.date_scheduled_start)
              : null,
            user_time_off_request_ids:
              dropTimeOffRequestIds?.length > 0
                ? dropTimeOffRequestIds?.map(i => i?.user_time_off_request_id)
                : undefined,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen,
          handleOnLeaveDialogClose()
        );
        setPercentageAllocation(0);
        setReloadList(!reloadList);
        if (createInstallerResponse?.requestStatus === 206) {
          toast.current.show({
            severity: 'warn',
            summary: 'Warning',
            detail: createInstallerResponse?.message,
          });
        }
        if (
          createInstallerResponse?.installer_work_type &&
          createInstallerResponse?.installer_detail &&
          createInstallerResponse?.date_scheduled_start &&
          createInstallerResponse?.date_scheduled_end
        ) {
          setReloadList(!reloadList);
          setAlert(
            'success',
            'Scheduling date and time added successfully.',
            false,
            true
          );
        } else if (
          createInstallerResponse?.installer_work_type?.worktype ===
          COMMISSION_WORK_TYPE.LABEL
        ) {
          setReloadList(!reloadList);
          setAlert('success', 'Technician added successfully.', false, true);
        } else if (
          createInstallerResponse?.data == false &&
          createInstallerResponse?.message == 'Children Exists'
        ) {
          //show dialog here
          setShowDialog(true);
          return;
        } else {
          setAlert('error', 'Unable to add technician.', false, true);
        }
      } else {
        if (
          installerData?.date_scheduled_start &&
          installerData?.date_scheduled_end &&
          installerData?.installer_id &&
          installerData?.worktypeid &&
          formAction === 'edit'
        ) {
          apiMessageResponse = 'Technician has been updated successfully.';
        }
        if (
          installerData?.date_scheduled_start &&
          installerData?.date_scheduled_end &&
          !installerData?.installer_id &&
          !installerData?.worktypeid &&
          formAction === 'edit'
        ) {
          apiMessageResponse =
            'Scheduling date and time has been updated successfully.';
        }
        if (
          !installerData?.date_scheduled_start &&
          !installerData?.date_scheduled_end &&
          installerData?.installer_id &&
          installerData?.worktypeid &&
          formAction === 'edit'
        ) {
          apiMessageResponse = 'Technician updated successfully.';
        }
        await updateInstaller(
          formik?.values?.project_id,
          rowData[rowIndex]?.project_installer_id.value,
          {
            ...installerData,
            date_scheduled_end: date_scheduled_end
              ? momentTz(installerData?.date_scheduled_end)
              : null,
            date_scheduled_start: date_scheduled_start
              ? momentTz(installerData?.date_scheduled_start)
              : null,
            user_time_off_request_ids:
              dropTimeOffRequestIds?.length > 0
                ? dropTimeOffRequestIds?.map(i => i?.user_time_off_request_id)
                : undefined,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
      !!setReloadFormProp && setReloadFormProp(true);
      setReloadList(!reloadList);
      handleOnLeaveDialogClose();
    };

    const isUnassignedInstallerPresent = rowData.some(
      item =>
        item.installer.value === PROJECT_TYPES.UNASSIGNED_INSTALLER &&
        (item.type.value === PROJECT_TYPES.SCHEDULE ||
          item.type.value === PROJECT_TYPES.TENTATIVE_SCHEDULE)
    );
    const unassignedInstallerOption =
      allInstallerOptions?.find(
        item =>
          `${item.first_name} ${item.last_name}` ===
          PROJECT_TYPES.UNASSIGNED_INSTALLER
      ) || {};
    const additionalSchedule = allInstallerTypeOptions.find(item => {
      return `${item.worktype}` === PROJECT_TYPES.ADDITIONAL_SCHEDULE;
    });

    const installerFormik = useFormik({
      initialValues: {
        installer_id:
          formAction !== 'add' && formAction !== 'clone'
            ? rowData && rowData[rowIndex]?.installer_id?.value
            : isUnassignedInstallerPresent || formAction == 'clone'
              ? ''
              : unassignedInstallerOption?.installer_id,
        type:
          (formAction !== 'add' &&
            formAction !== 'clone' &&
            rowData &&
            rowData[rowIndex]?.type?.id) ||
          (formAction == 'clone' && additionalSchedule?.worktypeid) ||
          '',
        date_scheduled_start:
          (formAction !== 'add' &&
            rowData &&
            rowData[rowIndex]?.scheduleDateValue?.value) ||
          `${momentTz().format('YYYY-MM-DD')}T08:00`,
        date_scheduled_end:
          (formAction !== 'add' &&
            rowData &&
            rowData[rowIndex]?.projectEndDateValue?.value) ||
          `${momentTz().format('YYYY-MM-DD')}T17:00`,

        installer_arrival_start_time:
          (formAction !== 'add' &&
            rowData &&
            rowData[rowIndex]?.installer_arrival_start_time?.label) ||
          '', // for displaying in form
        installer_arrival_end_time:
          (formAction !== 'add' &&
            rowData &&
            rowData[rowIndex]?.installer_arrival_end_time?.label) ||
          '',

        userAction: allocationPermission || scheduleInfoUserSkipAction,
        percentage: 0,
      },
      validate: handleFormValidate,
      onSubmit: async values => {
        setFormData(values);
        const payload = {
          ...values,
          date_scheduled_start: values?.date_scheduled_start
            ? momentTz(`${values?.date_scheduled_start}:00`)
            : null, // send the UTC values to the API using the client_timezone,
          date_scheduled_end: values?.date_scheduled_end
            ? momentTz(`${values?.date_scheduled_end}:00`)
            : null,
        };
        await checkIfInstallerAvailable(payload); // source of API call
      },
      validationSchema: InstallerFormikSchema,
      enableReinitialize: true,
    });

    const viewEditInstaller = (action, index) => {
      setDialogSettings(prevState => ({
        ...prevState,
        showButton2: action === 'view' ? false : true,
        button2Text: action === 'edit' ? 'Save' : '',
        title: (action === 'view' && 'View Technician') || 'Edit Technician',
      }));
      setRowIndex(index);
      setFormAction(action);
      setIsOpen(true);
    };

    const deleteInstaller = index => {
      setRowIndex(index);
      setConfirmDialog({
        ...confirmDialog,
        isOpen: true,
        title:
          'Are you sure you want to delete Technician ? All allocations associated with this technician will be removed from the current project.',
        header: 'Delete Technician',
      });
    };

    const onConfirmDialog = async () => {
      await deleteProjectInstaller(
        formik?.values?.project_id,
        rowData[rowIndex]?.project_installer_id?.value,
        setLoading,
        setAlert,
        setConfirmDialog,
        setReloadCalender,
        reloadCalender
      );
      setReloadList(!reloadList);
    };

    const handleAddInstaller = () => {
      setDialogSettings(prevState => ({
        ...prevState,
        title: 'Add Technician',
        showButton1: true,
        showButton2: true,
      }));
      setFormAction('add');
      setIsOpen(true);
      installerFormik?.handleReset();
    };
    const viewCloneInstaller = (action, index) => {
      setDialogSettings(prevState => ({
        ...prevState,
        showButton1: true,
        showButton2: true,
        title: 'Add Technician',
      }));
      setRowIndex(index);
      setFormAction(action);
      setIsOpen(true);
      installerFormik?.handleReset();
    };

    const handleFormClose = () => {
      setIsOpen(false);
      installerFormik?.handleReset();
    };

    React.useEffect(() => {
      isOpen && fetchInstallerList();
    }, [isOpen]);

    return (
      <>
        {checkPermission(permissions?.viewEditProject?.viewInstaller) &&
        formik.values.project_id &&
        !loading ? (
          <div className="grid">
            <div className="col-12">
              <Toast ref={toast} />
              <InstallerTable
                permissions={permissions}
                rowData={rowData}
                handleAddInstaller={handleAddInstaller}
                viewEditInstaller={viewEditInstaller}
                deleteInstaller={deleteInstaller}
                handleCloneInstaller={viewCloneInstaller}
                schedulerEditPermission={schedulerEditPermission}
              />
              <InstallerForm
                isOpen={isOpen}
                handleClose={handleFormClose}
                allocationPermission={allocationPermission}
                installerFormik={installerFormik}
                dialogSettings={dialogSettings}
                installerOptions={allInstallerOptions}
                filteredInstallerOptions={filteredInstallerOptions}
                formAction={formAction}
                installerTypeOptions={allInstallerTypeOptions}
                filteredInstallerTypeOptions={filteredInstallerTypeOptions}
                project_id={formik.values.project_id}
                isCommissionTypeTechnicianFound={
                  isCommissionTypeTechnicianFound
                }
                isSalesCommissionOpted={isSalesCommissionOpted}
                setPercentageAllocation={setPercentageAllocation}
                percentageAllocation={percentageAllocation}
              />
              {/* Confirmation dialog for delete */}
              <GenericConfirmationDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                onConfirmDialog={onConfirmDialog}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <InstallerOnLeaveDialog
          isOpen={isOnLeaveDialogOpen}
          isBlockedTimeDialog={isBlockedTimeDialog}
          handleCancel={handleOnLeaveDialogClose}
          handleSubmit={createUpdateInstaller}
        />
      </>
    );
  }
);

export default Installer;
