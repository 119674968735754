import { useFormik } from 'formik';
import * as Yup from 'yup';

import apiService from '../../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../../constants/urlConstants';

const paymentConfigValidation = Yup.object().shape({
  pf_share_value: Yup.number().required('required'),
  pf_share_type: Yup.string().required('required'),
});

export const paymentConfigSubmit = (
  props,
  setBlocked,
  toast,
  history,
  fetchTenantConfiguration
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: { ...props },
    validationSchema: paymentConfigValidation,
    onSubmit: async values => {
      setBlocked(true);
      const payload = {
        client_id: values?.client_id,
        client_name: values?.client_name,
        onboarding_status_id: values?.onboarding_status_id,
        pf_share: values?.pf_share === true ? 2 : 1,
        pf_share_type: values?.pf_share_type,
        pf_share_value: values?.pf_share_value,
        stripe_share: values?.stripe_share === true ? 2 : 1,
        is_show_line_items: values?.is_show_line_items === true ? 2 : 1,
      };

      try {
        const response = await apiService.postWithDiffBaseUrl(
          `${URL_CONSTANTS.CRM.paymentConfig}`,
          payload,
          URL_CONSTANTS.CRM.baseUrl
        );

        if (response?.status === true) {
          setBlocked(false);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Successfully',
            life: 3000,
          });

          fetchTenantConfiguration();
        }
      } catch (error) {
        setBlocked(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
          life: 3000,
        });
      }
    },
  });
};
