import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  listOfWarehouses: [],
  inventoryByWarehouse: {},
  allWarehouses: [],
};

const WarehouseSlice = createSlice({
  name: 'InventoryAdjustment',
  initialState: initialState,
  reducers: {
    warehouseClearState(state) {
      state.isLoading = true;
      state.listOfWarehouses = [];
      state.inventoryByWarehouse = {};
      state.allWarehouses = [];
    },
    addWarehouse(state, { payload }) {
      state.isLoading = true;
      if (payload.hasOwnProperty('item')) {
        state.listOfWarehouses = payload?.item;
        state.isLoading = false;
      }
    },
    addInventoryByWarehouse(state, { payload }) {
      state.inventoryByWarehouse[payload.wareHouseId] =
        payload.inventoryByWarehouse;
    },
    setAllWarehouses(state, { payload }) {
      state.allWarehouses = payload;
    },
  },
});

export const {
  warehouseClearState,
  addWarehouse,
  addInventoryByWarehouse,
  setAllWarehouses,
} = WarehouseSlice.actions;

export const WarehouseReducer = WarehouseSlice.reducer;
