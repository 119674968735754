import { URL_CONSTANTS } from '../../../constants/urlConstants';
import apiService from '../../../services/api.service';

export const listCxScheduledProject = async () => {
  return await apiService.getWithDiffBaseUrl(
    URL_CONSTANTS.PROJECTS.endPoints.listCxScheduledProject,
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const acceptCxScheduledProject = async projectId => {
  return await apiService.patchWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.acceptCxScheduledProject,
      projectId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const declineCxScheduledProject = async (projectId, payload) => {
  return await apiService.patchWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.declineCxScheduledProject,
      projectId
    ),
    payload,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateBusinessWorkHourAndHoliday = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.validateBusinessWorkHourAndHoliday,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode =
  async (projectId, executionId) => {
    return await apiService.postWithDiffBaseUrl(
      apiService.formatUrl(
        URL_CONSTANTS.PROJECTS.endPoints
          .validateCxSchedulingEligibleTechniciansByTypeCategoryStoreAndZipcode,
        projectId,
        executionId
      ),
      null,
      URL_CONSTANTS.PROJECTS.baseUrl
    );
  };
export const validateTechnicianAvailableOnDate = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.validateTechnicianAvailableOnDate,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateCalculatedTechnicianServiceAvailableTime = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints
        .validateCalculatedTechnicianServiceAvailableTime,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateAvailableTechnicianWithConsecutiveBreakTime = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints
        .validateAvailableTechnicianWithConsecutiveBreakTime,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateTechnicianAfterTimeOff = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.validateTechnicianAfterTimeOff,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateTechnicianCapacity = async (projectId, executionId) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.validateTechnicianCapacity,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateCustomerPortalDefaultRotationPercentage = async (
  projectId,
  executionId
) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints
        .validateCustomerPortalDefaultRotationPercentage,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
export const validateAndAssignInstaller = async (projectId, executionId) => {
  return await apiService.postWithDiffBaseUrl(
    apiService.formatUrl(
      URL_CONSTANTS.PROJECTS.endPoints.validateAndAssignInstaller,
      projectId,
      executionId
    ),
    null,
    URL_CONSTANTS.PROJECTS.baseUrl
  );
};
