// **** React Imports ****
import React from 'react';
import { useHistory } from 'react-router-dom';

// **** Utilities ****
import { Grid, CircularProgress } from '@material-ui/core';

// **** Custom Components *****
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

import { getWidgetsList, addWidgetWithCubeData } from './Dashboards.service';

// **** Styles *****

const WidgetsList = ({
  setDialogOpen,
  dialogOpen,
  setWidgetQuery,
  setWidgetRendererData,
  widgetQuery,
  setLayout,
  layout,
  dashboardId,
  setChartType,
  dashboardData,
  cols,
  parentLoading,
  setParentLoading,
  deleteWidgetIds = [],
}) => {
  const { clearAlert } = useAlerts();
  const [loadingWidgetList, setLoadingWidgetList] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const [widgetsListData, setWidgetsListData] = React.useState();

  const history = useHistory();
  const dialogSettings = {
    title: (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          Select Widget
        </Grid>
        {loadingWidgetList && (
          <Grid container item xs={6}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
    isScrollHidden: true,
  };
  const [rowsSelectedObject, setRowsSelectedObject] = React.useState({});
  const [getCurrentPage, setCurrentPage] = React.useState(0);
  const [cubeQuery, setCubeQuery] = React.useState([]);

  React.useEffect(() => {
    clearAlert();
  }, []);
  React.useEffect(() => {
    if (dialogOpen) {
      getWidgetsList(searchQuery, setLoadingWidgetList, setWidgetsListData);
    }
  }, [searchQuery, dialogOpen]);

  const dataTableColumns = [
    {
      name: 'name',
      label: 'Name',
      options: { setCellProps: () => ({ style: { width: '40%' } }) },
    },
    {
      name: 'description',
      label: 'Description',
      options: { setCellProps: () => ({ style: { width: '50%' } }) },
    },
  ];
  let dataArray = [];
  const [addedWidgetTemplates, setAddedWidgetTemplates] = React.useState([]);
  const [disableCheckbox, setDisableCheckbox] = React.useState(false);
  React.useEffect(() => {
    if (widgetQuery?.length) {
      setAddedWidgetTemplates(
        widgetQuery.map(widget => widget?.widget_template_id)
      );
      setCubeQuery(widgetQuery.map(widget => widget));
    }
  }, [widgetQuery?.length]);
  widgetsListData?.items?.map((val, index) => {
    dataArray.push({
      name: val.widget_name,
      description: val?.widget_description,
      dataSource: val?.data_source[0],
      widgetId: val?.widget_template_id,
      chartType: val?.widget_display_type[0],
      custom_properties: val?.custom_properties,
      ...val,
    });
  });

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();
    if (!searchString) {
      setCurrentPage(0);
    } else {
      setCurrentPage();
    }

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_query: searchString,
      }));
    }, 500);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    selectableRows: 'multiple',
    viewColumns: false,
    responsive: 'standard',
    tableBodyMinHeight: '300px',
    selectableRowsHeader: true,
    serverSide: true,
    count: widgetsListData?.count,
    textLabels: {
      body: {
        noMatch:
          !setLoadingWidgetList && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setCurrentPage(currentPage);
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setDisableCheckbox(false);
      const selectedWidgetData = currentRowsSelected.map((item, index) => {
        if (
          (allRowsSelected?.filter(val => val.index === item?.index)?.length &&
            cubeQuery?.length &&
            !cubeQuery?.some(
              query =>
                query?.widget_template?.widget_template_id ===
                dataArray[item?.index]?.widgetId
            )) ||
          !cubeQuery?.length
        ) {
          return {
            index: cubeQuery?.length + index || 0,
            chart_type: dataArray[item?.index]?.chartType,
            width: null,
            x_axis_position: null,
            y_axis_position: null,
            height: null,
            name: dataArray[item?.index]?.name,
            dashboard_id: dashboardId,
            widget_template: {
              data_source: [dataArray[item?.index]?.dataSource],
              widget_template_id: dataArray[item?.index]?.widgetId,
              custom_properties: dataArray[item?.index]?.custom_properties,
              ...dataArray[item?.index],
            },
          };
        }
      });

      const selectedWidget = selectedWidgetData.filter(
        widget => widget != null
      );

      setCubeQuery([...new Set([...cubeQuery, ...selectedWidget])]);

      setRowsSelectedObject({
        ...rowsSelectedObject,
        ...{
          [getCurrentPage]: rowsSelected,
        },
      });
    },
    rowsSelected: rowsSelectedObject[getCurrentPage],
    isRowSelectable: (dataIndex, selectedRows, data) => {
      return !disableCheckbox
        ? !addedWidgetTemplates.includes(dataArray[dataIndex]?.widgetId)
        : !cubeQuery
            ?.map(query => query?.widget_template?.widget_template_id)
            .includes(dataArray[dataIndex]?.widgetId);
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    selectToolbarPlacement: 'none',
  };

  const onAddWidget = () => {
    setDisableCheckbox(true);
    setSearchQuery({ limit: 10, offset: 0 });
    addWidgetWithCubeData(
      cubeQuery,
      dashboardId,
      history,
      setWidgetRendererData,
      setWidgetQuery,
      setDialogOpen,
      setParentLoading,
      deleteWidgetIds
    );
  };

  return (
    <GenericDialog
      fullwidth
      isOpen={dialogOpen}
      handleClose={() => {
        setDialogOpen(false);
        setWidgetsListData([]);
        setSearchQuery({ limit: 10, offset: 0 });
      }}
      disabledButton2={!cubeQuery.length}
      dialogSettings={dialogSettings}
      dialogWidth="sm"
      handleSave={() => onAddWidget()}
    >
      <GenericDataTable
        columns={dataTableColumns}
        data={dataArray}
        options={dataTableOptions}
      />
    </GenericDialog>
  );
};

export default WidgetsList;
