// **** External Utilities ****
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  dataTableWrapper: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
}));
