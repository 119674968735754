// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

// **** Styles/Images/Icons ****
import { useStyles } from './Footer.styles';

const Footer = ({
  createdBy,
  createdAt,
  modifiedBy,
  modifiedAt,
  lockProjectBar,
  width = 1000,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.footerContainer}
      justifyContent="center"
      style={{
        ...(lockProjectBar && lockProjectBar()
          ? {
              position: 'relative',
              top: width < 768 ? '150px' : '70px',
            }
          : {}),
      }}
    >
      <Grid item>
        <footer>
          <Typography color="textSecondary">
            {createdBy || createdAt ? 'Created ' : null}
            {createdBy ? (
              <>
                By{' '}
                <span className={classes.textColorPrimary}>{createdBy} </span>
              </>
            ) : null}
            {createdAt ? (
              <>
                on {moment(createdAt).format('MMM DD')} at{' '}
                {moment(createdAt).format('hh:mm A')}.
              </>
            ) : null}

            {modifiedBy || modifiedAt ? 'Last updated ' : null}
            {modifiedBy ? (
              <>
                By{' '}
                <span className={classes.textColorPrimary}>{modifiedBy} </span>
              </>
            ) : null}
            {modifiedAt ? (
              <>
                on {moment(modifiedAt).format('MMM DD')} at{' '}
                {moment(modifiedAt).format('hh:mm A')}.
              </>
            ) : null}
          </Typography>
        </footer>
      </Grid>
    </Grid>
  );
};

export default Footer;
