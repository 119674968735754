import moment from 'moment';

export const getUSerTimeZone = () => {
  return moment.tz.guess();
};

export const getConvertedDate = inputDate => {
  if (!inputDate) return null;
  const resultDate = getUSerTimeZone()
    ? moment(inputDate).tz(getUSerTimeZone())
    : moment(inputDate);
  return resultDate.format('MM-DD-YYYY hh:mm A');
  try {
  } catch (error) {
    return null;
  }
};

export const UTCConvertor = (date, withTime = true) => {
  if (!date) return null;
  return withTime
    ? moment.utc(date).local().format('MM-DD-YYYY hh:mm:ss A')
    : moment.utc(date).local().format('MM-DD-YYYY');
};
