import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: [],
};

const quoteStatusMasterSlice = createSlice({
  name: 'quoteStatusMaster',
  initialState: initialState,
  reducers: {
    setQuoteStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setQuoteStatus } = quoteStatusMasterSlice.actions;

export const quoteStatusReducer = quoteStatusMasterSlice.reducer;
