export const ALERT = {
  PROJECT_CLIENT_TRANSFER_LEAD:
    'This action would transfer all the leads, quotes and projects of these selected clients to the parent record before deleting them. Would you like to continue ?',
};

export const SCHEDULER_MAP_MESSAGE = {
  DEFAULT:
    'For seeing the routes, please apply technician filters (at max 10).',
  INSTALLER_COUNT: 'Routes would be calculated only for the same day jobs',
};
