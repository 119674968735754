import React, { useState, useEffect, useRef } from 'react';
import { InputText, Toast, InputNumber } from 'primereact';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';

import ApiService from '../../../services/api.service';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { setUserDetailsForm } from '../../../redux/slices/user-form-details.slice';
import { userFormUpdateComponent } from '../../../redux/slices/user-form.slice';
import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import { updateUserStatus } from '../UserManagement/UserManagement.service';
import { isValidHttpUrl, getLoginUserId } from '../../../utils/Helpers';
import { EMPLOYMENT_TYPES, YesNoDropdown } from '../../../constants';
import { getClientData } from '../NewConfiguration/service/TenantConfig.service';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFCalendar from '../../shared/PFPrime/PFCalendar';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';

import {
  addNewUser,
  updateUserDetails,
  getUserDetailsViewModeInitialization,
  getUserDetailsAddModeInitialization,
  searchManager,
  searchCompanyName,
  searchShoeSize,
  searchShirtSize,
  searchTeam,
  createCompany,
  searchUserType,
  createUserType,
  createShirtSize,
  createShowSize,
  searchWorkRoom,
} from './services/user-form-service';
import { getUserCrewList } from './services/user-form-crew.service';

const today = new Date();
today.setHours(0, 0, 0, 0);

const userDetailsInitialValues = {
  first_name: null,
  last_name: null,
  email: null,
  phone_number: '',
  dob: null,
  role_id: null,
  team_member_id: null,
  manager_id: null,
  hire_date: null,
  termination_date: null,
  shirt_size_id: null,
  shoe_size: null,
  team_id: null,
  companyname: null,
  user_type_id: null,
  google_email_address: null,
  estimator_id: null,
  digital_badge_id: null,
  renovator_name: null,
  renovator_certification_number: null,
  floorsoft_company_id: null,
  measuring_system_id: null,
  installer_bio: null,
  sales_commission_pct: null,
  user_workroom_id: null,
  payroll_system_id: null,
  fadv_badge_id: null,
  fadv_badge_exp_date: null,
  subcontractor_id: null,
  employment_type: null,
};

const userDetailsValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required('Required')
    .matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid First Name.'),
  last_name: Yup.string()
    .trim()
    .required('Required')
    .matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid Last Name.'),
  email: Yup.string().trim().email('Invalid Email').required('Required'),
  phone_number: Yup.string()
    .trim()
    .required('Required')
    .matches(
      /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
      'Invalid Phone number'
    ),
  role_id: Yup.object().shape({
    role_id: Yup.string().trim().required('Required'),
  }),
  google_email_address: Yup.string().trim().nullable().email('Invalid Email'),
  payroll_system_id: Yup.string().trim().nullable().max(30),
  fadv_badge_id: Yup.string().trim().nullable().max(50),
  subcontractor_id: Yup.string().trim().nullable().max(20),
});

const UserFormDetails = () => {
  const companyRef = useRef(null);
  const userTypeRef = useRef(null);
  const shoeSizeRef = useRef(null);
  const shirtSizeRef = useRef(null);
  const userWorkRoomRef = useRef(null);
  const {
    isLoading,
    rolesList,
    userImage,
    newUserImage,
    userDetailsFormik,
    isUserDetailsValid,
    isUserDetailsFormDirty,
    userDetails,
  } = useSelector(state => state.userFormDetails);
  const { isFormDataChanged, mode, userId } = useSelector(
    state => state.userForm
  );
  const [managerSearchResults, setManagerSearchResults] = useState([]);
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const [userTypeSearchResults, setUserTypeSearchResults] = useState([]);
  const [userWorkroomResults, setUserWorkroomResults] = useState([]);
  const [shoeSizeSearchResults, setShoeSizeSearchResults] = useState([]);
  const [shirtSizeSearchResults, setShirtSizeSearchResults] = useState([]);
  const [teamSearchResults, setTeamSearchResults] = useState([]);
  const toast = useRef(null);
  const history = useHistory();
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [crewCount, setIsCrewCount] = useState();
  const [isSalesCommissionOpted, setIsSalesCommissionOpted] = useState(false);
  const { user } = useParams();

  const suggestManager = async event => {
    const searchManagerResponse = await searchManager(event?.query);
    setManagerSearchResults(searchManagerResponse);
  };

  const suggestCompanyName = async (event = null) => {
    const searchCompanyNameResponse = await searchCompanyName(
      event?.query || ''
    );
    setCompanySearchResults(searchCompanyNameResponse);
  };

  const suggestUserType = async (event = null) => {
    const searchUserTypeResponse = await searchUserType(event?.query || '');
    setUserTypeSearchResults(searchUserTypeResponse);
  };

  const suggestWorkRoom = async (event = null) => {
    const searchUserTypeResponse = await searchWorkRoom(event?.filter || '');

    let allWorkRoom = [];

    if (
      formik?.values?.user_workroom_id &&
      formik?.values?.user_workroom_id?.length
    ) {
      allWorkRoom = [
        ...formik?.values?.user_workroom_id,
        ...userWorkroomResults,
        ...searchUserTypeResponse,
      ];
    } else {
      allWorkRoom = [...userWorkroomResults, ...searchUserTypeResponse];
    }

    const uniqueIds = new Set();
    const filteredList = allWorkRoom?.filter(options => {
      const typeId = options?.type_id;
      if (uniqueIds.has(typeId)) {
        return false;
      }
      uniqueIds.add(typeId);
      return true;
    });
    setUserWorkroomResults(filteredList);
  };
  const displayUserTypeRef = e => {
    suggestUserType();
    if (userTypeRef.current) {
      userTypeRef.current.show();
    }
  };
  const displayUserWorkRoomRef = e => {
    suggestWorkRoom();
    if (userWorkRoomRef.current) {
      userWorkRoomRef.current.show();
    }
  };

  const displayCompanyRef = e => {
    suggestCompanyName();
    if (companyRef.current) {
      companyRef.current.show();
    }
  };
  const displayShoeSizeRef = e => {
    suggestShoeSize();
    if (shoeSizeRef.current) {
      shoeSizeRef.current.show();
    }
  };

  const displayShirtSizeRef = e => {
    suggestShirtSize();
    if (shirtSizeRef.current) {
      shirtSizeRef.current.show();
    }
  };

  const suggestShoeSize = async (event = null) => {
    const searchShoeSizeResponse = await searchShoeSize(event?.query || '');
    setShoeSizeSearchResults(searchShoeSizeResponse);
  };
  const suggestShirtSize = async (event = null) => {
    const searchShirtSizeResponse = await searchShirtSize(event?.query || '');
    setShirtSizeSearchResults(searchShirtSizeResponse);
  };

  useEffect(() => {
    suggestCompanyName();
    suggestUserType();
    suggestShoeSize();
    suggestShirtSize();
    suggestWorkRoom();
  }, []);

  useEffect(() => {
    getClientData(localStorage.getItem('client_id'))
      .then(clientData => {
        if (clientData?.data?.client)
          setIsSalesCommissionOpted(
            clientData?.data?.client?.sales_commission_opted
          );
      })
      .catch();
  }, [localStorage.getItem('client_id')]);

  const suggestTeam = async event => {
    const searchTeamResponse = await searchTeam(event?.query);
    setTeamSearchResults(searchTeamResponse);
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: userDetailsInitialValues,
    validationSchema: userDetailsValidationSchema,
    onSubmit: values => {},
    enableReinitialize: true,
  });

  const mapUserDataToForm = initiationData => {
    const {
      rolesList,
      userDetails,
      manager,
      companyName,
      userType,
      shoeSize,
      shirtSize,
      team,
      userWorkroom,
    } = initiationData;
    const formFields = {
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      email: userDetails?.email,
      phone_number: userDetails?.contact_no?.replace(/\D/g, ''),
      dob: userDetails?.dob ? new Date(userDetails?.dob) : null,
      role_id: rolesList.find(roles => roles?.role_id === userDetails?.role_id),
      team_member_id: userDetails?.userLowesRegDetails?.team_member_id,
      manager_id: manager,
      hire_date: userDetails?.hire_date
        ? new Date(userDetails?.hire_date)
        : null,
      termination_date: userDetails?.termination_date
        ? new Date(userDetails?.termination_date)
        : null,
      shirt_size_id: shirtSize,
      shoe_size: shoeSize,
      team_id: team,
      companyname: companyName,
      user_type_id: userType,
      google_email_address: userDetails?.google_email_address,
      estimator_id: userDetails?.estimator_id,
      digital_badge_id: userDetails?.digital_badge_id,
      floorsoft_company_id: userDetails?.floorsoft_company_id,
      renovator_name: userDetails?.renovator_name,
      renovator_certification_number:
        userDetails?.renovator_certification_number,
      measuring_system_id: userDetails?.measuringsystemid,
      installer_bio: userDetails?.installerbio,
      sales_commission_pct: userDetails?.sales_commission_pct,
      user_workroom_id: userWorkroom,
      payroll_system_id: userDetails?.payroll_system_id || '',
      fadv_badge_id: userDetails?.fadv_badge_id || '',
      fadv_badge_exp_date: userDetails?.fadv_badge_exp_date
        ? new Date(userDetails?.fadv_badge_exp_date)
        : null,
      employment_type: userDetails?.employment_type || '',
      subcontractor_id:
        userDetails?.userLowesRegDetails?.subcontractor_id || '',
    };
    return formFields;
  };
  const fetchInitiationData = async () => {
    try {
      if (mode === 'add') {
        const initiationData = await getUserDetailsAddModeInitialization();
        dispatch(setUserDetailsForm({ ...initiationData }));
      }
      if (!!userId && (mode === 'view' || mode === 'edit')) {
        const initiationData =
          await getUserDetailsViewModeInitialization(userId);
        const query = {
          active: true,
        };
        const response = await getUserCrewList(userId, query);
        if (response) {
          setIsCrewCount(response?.data?.count);
        }
        dispatch(setUserDetailsForm({ ...initiationData }));
        const cleanedUserFormValues = mapUserDataToForm(initiationData);
        formik.setValues(cleanedUserFormValues);
        dispatch(
          setUserDetailsForm({
            userName: `${cleanedUserFormValues.first_name} ${cleanedUserFormValues.last_name}`,
          })
        );
        if (initiationData?.userDetails?.profile_image_url) {
          dispatch(
            setUserDetailsForm({
              userImage: initiationData?.userDetails?.profile_image_url,
              userName: `${cleanedUserFormValues.first_name} ${cleanedUserFormValues.last_name}`,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserDetailsForm({ isLoading: false }));
    }
  };

  useEffect(async () => {
    await fetchInitiationData();
  }, [mode, userId]);

  const handleOnChange = async (fieldId, event = null) => {
    if (!isFormDataChanged) {
      dispatch(userFormUpdateComponent({ isFormDataChanged: true }));
    }
    await formik.setFieldValue(fieldId, event?.target?.value || '');
    dispatch(
      setUserDetailsForm({
        userDetailsFormik: {
          ...formik.values,
          [fieldId]: event?.target?.value || '',
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      setUserDetailsForm({
        isUserDetailsValid: formik.isValid,
        isUserDetailsFormDirty: formik.dirty,
      })
    );
  }, [formik.isValid, formik.dirty]);

  const handleUserImageUpload = e => {
    setIsUploaderVisible(false);
    if (e.files && e.files[0]) {
      if (e.files[0].type.split('/')[0] !== 'image') {
        toast?.current?.show({
          severity: 'error',
          summary: 'Only Image files are allowed',
          life: 2000,
        });
      } else {
        if (e?.files && e?.files?.length > 0) {
          if (!isFormDataChanged) {
            dispatch(userFormUpdateComponent({ isFormDataChanged: true }));
          }
          dispatch(setUserDetailsForm({ newUserImage: e.files[0] }));
        }
      }
    }
  };

  const handleDigitalBadge = () => {
    const digitalBadgeValue = formik?.values?.digital_badge_id;
    if (isValidHttpUrl(digitalBadgeValue)) {
      window.open(digitalBadgeValue, '_blank');
    }
  };

  const handleUserImageClick = () => {
    setIsUploaderVisible(true);
  };
  const isFormFieldInvalid = name =>
    !!(formik.touched[name] && formik.errors[name]);

  const addNewCompany = async comapnyName => {
    if (comapnyName) {
      const newConpany = await createCompany(comapnyName);
      if (newConpany?.status) {
        const newCompanyObject = {
          target: {
            value: {
              'Companies.company_name': newConpany?.data?.company_name,
              'Companies.company_id': newConpany?.data?.company_id,
            },
          },
        };

        handleOnChange('companyname', newCompanyObject);
        suggestCompanyName();
        toast?.current?.show({
          severity: 'success',
          summary: `${comapnyName} company has been created successfully`,
          life: 2000,
        });
      }
    }
  };

  const addNewUserType = async () => {
    const newUserType = formik.values?.user_type_id;
    if (newUserType) {
      const newUserTypeResponse = await createUserType(newUserType);
      if (newUserTypeResponse?.status) {
        const newUserTypeObject = {
          target: {
            value: {
              'UserTypes.label': newUserTypeResponse?.data?.label,
              'UserTypes.user_type_id': newUserTypeResponse?.data?.user_type_id,
            },
          },
        };

        handleOnChange('user_type_id', newUserTypeObject);
        suggestUserType();
        toast?.current?.show({
          severity: 'success',
          summary: `${newUserType} Type has been created successfully`,
          life: 2000,
        });
      } else {
        toast?.current?.show({
          severity: 'error',
          summary: newUserTypeResponse?.message,
          life: 2000,
        });
      }
    }
  };

  const addShirtSize = async sizeName => {
    if (sizeName) {
      const newShritSize = await createShirtSize(sizeName);

      if (newShritSize?.status) {
        const newShritSizeObject = {
          target: {
            value: {
              'ShirtSizes.shirt_size_id': newShritSize?.data?.shirt_size_id,
              'ShirtSizes.size_name': newShritSize?.data?.size_name,
            },
          },
        };

        handleOnChange('shirt_size_id', newShritSizeObject);
        suggestShirtSize();
        toast?.current?.show({
          severity: 'success',
          summary: `${sizeName} size has been created successfully`,
          life: 2000,
        });
      }
    }
  };

  const addShoeSize = async shoeSize => {
    if (shoeSize) {
      const newShoeSize = await createShowSize(shoeSize);

      if (newShoeSize?.status) {
        const newShoeObject = {
          target: {
            value: {
              'ShoeSizes.shoe_size_id': newShoeSize?.data?.shoe_size_id,
              'ShoeSizes.shoe_size': newShoeSize?.data?.shoe_size,
            },
          },
        };

        handleOnChange('shoe_size', newShoeObject);
        suggestShoeSize();
        toast?.current?.show({
          severity: 'success',
          summary: `${shoeSize} size has been created successfully`,
          life: 2000,
        });
      }
    }
  };

  const onDisabled = (searchResult, value, key) => {
    let res = true;

    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  const handleSendInvite = async userId => {
    toast.current.show({
      severity: 'info',
      summary: 'Info',
      detail: 'Sending invitation',
      life: 2000,
    });
    const response = await ApiService.post(
      '/auth/user/manage/sendEmailInvite',
      {
        users: [{ userId: userId }],
      }
    );
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Invitation has been sent to user',
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to send invite',
        life: 3000,
      });
    }
  };

  const sendEmailInviteConfirmation = (userDetailsFormik, user_id) => {
    confirmDialog({
      message: `Send an email invitation to ${userDetailsFormik?.first_name} ${userDetailsFormik?.last_name}. 
      The email contains a link directly to this application.`,
      header: 'Do you want to send email invite?',
      icon: 'pi pi-info-circle',
      accept: async () => {
        await handleSendInvite(user_id);
        history.push(`/admin/usermanagement`);
      },
      reject: () => {
        history.push(`/admin/usermanagement`);
      },
    });
  };

  const accept = async () => {
    try {
      dispatch(setUserDetailsForm({ isLoading: true }));
      if (mode === 'add') {
        const addNewUserResponse = await addNewUser(
          userDetailsFormik,
          newUserImage
        );
        if (
          addNewUserResponse?.status === 200 &&
          !!addNewUserResponse?.data?.user_id
        ) {
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'User Data saved successfully',
            life: 3000,
          });
          dispatch(
            userFormUpdateComponent({
              userId: addNewUserResponse?.data?.user_id,
              mode: 'edit',
              isFormDataChanged: false,
            })
          );
          dispatch(
            setUserDetailsForm({
              isLoading: false,
              newUserImage: null,
            })
          );
          sendEmailInviteConfirmation(
            userDetailsFormik,
            addNewUserResponse?.data?.user_id
          );
        } else if (addNewUserResponse?.status === 422) {
          toast.current.show({
            severity: 'error',
            detail: addNewUserResponse.message,
            life: 3000,
          });
        }
      }
      if (mode === 'edit') {
        const addNewUserResponse = await updateUserDetails(
          userId,
          userDetailsFormik,
          newUserImage
        );
        const loggedInUser = getLoginUserId();
        if (addNewUserResponse?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'User Data saved successfully',
            life: 3000,
          });

          dispatch(
            userFormUpdateComponent({
              userId: addNewUserResponse?.data?.user_id,
              mode: 'edit',
              isFormDataChanged: false,
            })
          );
          if (
            user === loggedInUser &&
            userDetails?.role_id !== addNewUserResponse?.data?.role_id
          ) {
            window.location.reload();
          }
          dispatch(
            setUserDetailsForm({
              userName: `${addNewUserResponse?.data?.first_name} ${addNewUserResponse?.data?.last_name}`,
            })
          );
        } else if (addNewUserResponse?.status === 422) {
          toast.current.show({
            severity: 'error',
            detail: addNewUserResponse.message,
            life: 3000,
          });
        }
      }
      dispatch(
        setUserDetailsForm({
          isLoading: false,
          newUserImage: null,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(userFormUpdateComponent({ isLoading: false }));
    }
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Cancel',
      detail: 'User data did not save',
      life: 3000,
    });
  };

  const handleSave = () => {
    if (isFormDataChanged) {
      confirmDialog({
        message: 'Are you sure you want to save this user data?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept,
        reject,
      });
    }
  };

  const selectedWorkroomLabel = values => {
    const count = values?.length;
    return `${count} Workroom${count > 1 ? 's' : ''}`;
  };

  const handleIsActiveStatus = async status => {
    try {
      dispatch(setUserDetailsForm({ isLoading: true }));
      const response = await updateUserStatus(userId, status);
      if (response.status == 422) {
        toast.current.show({
          severity: 'error',
          summary: response.message,
          life: 2000,
        });
      } else {
        await fetchInitiationData();
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      dispatch(setUserDetailsForm({ isLoading: false }));
    }
  };
  return (
    <>
      {isLoading ? (
        <SkeletonLoader columnCount={20} columnWidth="24%" mTopBottom={4} />
      ) : (
        <div
          style={mode === 'view' ? { pointerEvents: 'none' } : undefined}
          className="pt-3 pb-3"
        >
          <div className="flex justify-content-end mr-2 mb-3">
            <Button
              label={mode === 'add' ? 'Create' : 'Save'}
              size="small"
              severity="primary"
              onClick={handleSave}
              disabled={
                mode === 'add' &&
                (!isUserDetailsFormDirty || !isUserDetailsValid)
              }
            />
          </div>

          <div className="grid grid-nogutter">
            <div className="col-4 col-offset-4 lg:col-offset-0 lg:col-2 my-3">
              <Image
                src={
                  newUserImage
                    ? newUserImage?.objectURL
                    : userImage
                      ? userImage
                      : '/images/user-profile.svg'
                }
                alt="User Image"
                imageStyle={{ width: '100%' }}
                onClick={handleUserImageClick}
              />
              {(mode === 'edit' || mode === 'view') && (
                <span className="flex align-items-center p-1">
                  <span>
                    <PFInputSwitch
                      checked={userDetails?.status === 'Active' ? true : false}
                      className="mt-2"
                      onClick={e => handleIsActiveStatus(e.target.checked)}
                      disabled={mode === 'view' ? true : false}
                    />
                  </span>
                  <span className="pl-2">
                    {userDetails?.status || 'Inactive'}
                  </span>
                </span>
              )}
            </div>
            <div className="col-12 lg:col-10 pl-0 lg:pl-2 user-details">
              <div className="flex flex-wrap">
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <InputText
                      id="first_name"
                      className={
                        isFormFieldInvalid('first_name')
                          ? 'w-12 p-invalid p-inputtext-sm'
                          : 'w-12 p-inputtext-sm'
                      }
                      value={formik?.values?.first_name || ''}
                      onChange={e => handleOnChange('first_name', e)}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="first_name">First Name*</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label ">
                    <InputText
                      id="last_name"
                      className={
                        isFormFieldInvalid('last_name')
                          ? 'w-12 p-invalid p-inputtext-sm'
                          : 'w-12 p-inputtext-sm'
                      }
                      value={formik?.values?.last_name || ''}
                      onChange={e => handleOnChange('last_name', e)}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="last_name">Last Name*</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <InputText
                      id="email"
                      className={
                        isFormFieldInvalid('email')
                          ? 'w-12 p-invalid p-inputtext-sm'
                          : 'w-12 p-inputtext-sm'
                      }
                      value={formik?.values?.email || ''}
                      onChange={e => handleOnChange('email', e)}
                      onBlur={formik.handleBlur}
                      keyfilter="email"
                    />
                    <label htmlFor="email">Email*</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label ">
                    <InputMask
                      id="phone_number"
                      className={
                        isFormFieldInvalid('phone_number')
                          ? 'w-12 p-invalid p-inputtext-sm'
                          : 'w-12 p-inputtext-sm'
                      }
                      mask="(999) 999-9999"
                      value={formik?.values?.phone_number || ''}
                      onChange={e => handleOnChange('phone_number', e)}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="phone_number">Phone Number*</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label ">
                    <Calendar
                      id="dob"
                      className="w-12"
                      value={formik?.values?.dob || ''}
                      onChange={e => handleOnChange('dob', e)}
                      maxDate={today}
                      onBlur={formik.handleBlur}
                      pt={{
                        input: {
                          root: { className: 'p-inputtext-sm' },
                        },
                      }}
                    />
                    <label htmlFor="dob">Date of Birth</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <Dropdown
                      id="role_id"
                      options={rolesList}
                      optionLabel="role_name"
                      className={
                        isFormFieldInvalid('role_id')
                          ? 'w-full w-12 p-invalid'
                          : 'w-full w-12'
                      }
                      value={formik?.values?.role_id || ''}
                      onChange={e => handleOnChange('role_id', e)}
                      onBlur={formik.handleBlur}
                      pt={{
                        input: {
                          className: 'p-inputtext p-inputtext-sm',
                        },
                      }}
                    />
                    <label htmlFor="role_id">Role*</label>
                  </span>
                </div>
                {userId && (
                  <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                    <span className="p-float-label">
                      <InputText
                        id="team_member_id"
                        className="w-12 p-inputtext-sm"
                        value={formik?.values?.team_member_id || ''}
                        onChange={e => handleOnChange('team_member_id', e)}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="team_member_id">Team Member Id</label>
                    </span>
                  </div>
                )}
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <AutoComplete
                      id="manager_id"
                      className="w-12"
                      inputClassName="p-inputtext-sm w-12"
                      suggestions={managerSearchResults}
                      field="user_name"
                      completeMethod={suggestManager}
                      value={formik?.values?.manager_id || ''}
                      onChange={e => handleOnChange('manager_id', e)}
                      delay={300}
                      onBlur={formik.handleBlur}
                      forceSelection
                    />
                    <label htmlFor="manager_id">Manager</label>
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <Calendar
                      id="hire_date"
                      className="w-12"
                      value={formik?.values?.hire_date || ''}
                      onChange={e => handleOnChange('hire_date', e)}
                      onBlur={formik.handleBlur}
                      maxDate={formik?.values?.termination_date}
                      pt={{
                        input: {
                          root: { className: 'p-inputtext-sm' },
                        },
                      }}
                    />
                    <label htmlFor="hire_date">Hire Date</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <Calendar
                      id="termination_date"
                      className="w-12"
                      value={formik?.values?.termination_date || ''}
                      onChange={e => handleOnChange('termination_date', e)}
                      onBlur={formik.handleBlur}
                      minDate={formik?.values?.hire_date}
                      pt={{
                        input: {
                          root: { className: 'p-inputtext-sm' },
                        },
                      }}
                    />
                    <label htmlFor="termination_date">Termination Date</label>
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <AutoComplete
                      ref={shirtSizeRef}
                      id="shirt_size_id"
                      className="w-10"
                      suggestions={shirtSizeSearchResults}
                      field="ShirtSizes.size_name"
                      completeMethod={suggestShirtSize}
                      value={formik?.values?.shirt_size_id || ''}
                      onChange={e => handleOnChange('shirt_size_id', e)}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                      onFocus={displayShirtSizeRef}
                    />
                    <label htmlFor="shirt_size_id">Shirt Size</label>
                    <Button
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabled(
                        shirtSizeSearchResults,
                        formik?.values?.shirt_size_id,
                        'ShirtSizes.size_name'
                      )}
                      tooltip={
                        onDisabled(
                          shirtSizeSearchResults,
                          formik?.values?.shirt_size_id,
                          'ShirtSizes.size_name'
                        )
                          ? ''
                          : 'Add Shirt Size'
                      }
                      tooltipOptions={{ position: 'top' }}
                      onClick={() =>
                        addShirtSize(formik?.values?.shirt_size_id)
                      }
                    />
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label ">
                    <AutoComplete
                      ref={shoeSizeRef}
                      id="shoe_size"
                      className="w-10"
                      suggestions={shoeSizeSearchResults}
                      field="ShoeSizes.shoe_size"
                      completeMethod={suggestShoeSize}
                      value={formik?.values?.shoe_size || ''}
                      onChange={e => handleOnChange('shoe_size', e)}
                      onFocus={displayShoeSizeRef}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    />
                    <label htmlFor="shoe_size">Shoe Size</label>
                    <Button
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabled(
                        shoeSizeSearchResults,
                        formik?.values?.shoe_size,
                        'ShoeSizes.shoe_size'
                      )}
                      tooltip={
                        onDisabled(
                          shoeSizeSearchResults,
                          formik?.values?.shoe_size,
                          'ShoeSizes.shoe_size'
                        )
                          ? ''
                          : 'Add Shoe Size'
                      }
                      tooltipOptions={{ position: 'top' }}
                      onClick={() => addShoeSize(formik?.values?.shoe_size)}
                    />
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <AutoComplete
                      id="team_id"
                      className="w-12"
                      suggestions={teamSearchResults}
                      field="Teams.team_name"
                      completeMethod={suggestTeam}
                      value={formik?.values?.team_id || ''}
                      onChange={e => handleOnChange('team_id', e)}
                      onBlur={formik.handleBlur}
                      forceSelection
                      inputClassName="p-inputtext-sm w-12"
                    />
                    <label htmlFor="team_id">Team</label>
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label p-inputgroup flex-1">
                    <AutoComplete
                      ref={companyRef}
                      id="companyname"
                      className="w-10"
                      suggestions={companySearchResults}
                      field="Companies.company_name"
                      completeMethod={suggestCompanyName}
                      value={formik?.values?.companyname || ''}
                      onChange={e => handleOnChange('companyname', e)}
                      onFocus={displayCompanyRef}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    />
                    <label className="z-5" htmlFor="companyname">
                      Company Name
                    </label>

                    <Button
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabled(
                        companySearchResults,
                        formik?.values?.companyname,
                        'Companies.company_name'
                      )}
                      tooltip={
                        onDisabled(
                          companySearchResults,
                          formik?.values?.companyname,
                          'Companies.company_name'
                        )
                          ? ''
                          : 'Add Company'
                      }
                      tooltipOptions={{ position: 'top' }}
                      onClick={() => addNewCompany(formik?.values?.companyname)}
                    />
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <InputText
                      id="google_email_address"
                      className="w-12 p-inputtext-sm"
                      value={formik?.values?.google_email_address || ''}
                      onChange={e => handleOnChange('google_email_address', e)}
                      keyfilter="email"
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="google_email_address">
                      Alternate Email
                    </label>
                  </span>
                </div>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <InputText
                      id="estimator_id"
                      className="w-12 p-inputtext-sm"
                      value={formik?.values?.estimator_id || ''}
                      onChange={e => handleOnChange('estimator_id', e)}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="estimator_id">IME Estimator ID</label>
                  </span>
                </div>
                <span className="p-float-label col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <InputText
                    id="digital_badge_id"
                    className="w-12 text-underline cursor-pointer p-inputtext-sm"
                    value={formik?.values?.digital_badge_id || ''}
                    onChange={e => handleOnChange('digital_badge_id', e)}
                    onBlur={formik.handleBlur}
                    onClick={handleDigitalBadge}
                  />
                  <label htmlFor="digital_badge_id">Digital Badge ID</label>
                </span>
                <span className="p-float-label col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <InputText
                    id="measuring_system_id"
                    className="w-12 text-underline cursor-pointer p-inputtext-sm"
                    value={formik?.values?.measuring_system_id || ''}
                    onChange={e => handleOnChange('measuring_system_id', e)}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="measuring_system_id">
                    Measuring System ID
                  </label>
                </span>

                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <AutoComplete
                      ref={userTypeRef}
                      id="user_type_id"
                      className="w-10"
                      suggestions={userTypeSearchResults}
                      field="UserTypes.label"
                      completeMethod={suggestUserType}
                      value={formik?.values?.user_type_id || ''}
                      onChange={e => handleOnChange('user_type_id', e)}
                      placeholder="Search Type"
                      onFocus={displayUserTypeRef}
                      inputClassName="p-inputtext-sm w-12 border-right-none border-noround-right"
                    />
                    <label htmlFor="user_type_id">Type</label>

                    <Button
                      icon="pi pi-check"
                      className="p-button-primary w-2 border-noround-left height-40"
                      disabled={onDisabled(
                        userTypeSearchResults,
                        formik?.values?.user_type_id,
                        'UserTypes.label'
                      )}
                      tooltip={
                        onDisabled(
                          userTypeSearchResults,
                          formik?.values?.user_type_id,
                          'UserTypes.label'
                        )
                          ? ''
                          : 'Add Type'
                      }
                      tooltipOptions={{ position: 'top' }}
                      onClick={addNewUserType}
                    />
                  </span>
                </div>
                {userId && (
                  <>
                    <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                      <span className="p-float-label w-full">
                        <AutoComplete
                          id="crew_lead"
                          suggestions={YesNoDropdown}
                          field="value"
                          value={
                            crewCount ? YesNoDropdown[0] : YesNoDropdown[1]
                          }
                          disabled
                          pt={{
                            root: {
                              className: 'w-full',
                            },
                          }}
                        />
                        <label htmlFor="role_id">Crew Lead</label>
                      </span>
                    </div>
                    <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                      <span className="p-float-label">
                        <InputText
                          id="no_of_crews"
                          className="w-12 cursor-pointer p-inputtext-sm"
                          value={crewCount}
                          disabled
                        />
                        <label htmlFor="no_of_crews">Number of Crews</label>
                      </span>
                    </div>
                  </>
                )}
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik?.values?.user_workroom_id || ''}
                      options={userWorkroomResults}
                      onChange={e => {
                        handleOnChange('user_workroom_id', e);
                      }}
                      onFilter={e => {
                        suggestWorkRoom(e);
                      }}
                      optionLabel="label"
                      placeholder="Search Type"
                      maxSelectedLabels={0}
                      filter
                      className="w-12 align-items-center p-inputtext-sm"
                      onFocus={displayUserWorkRoomRef}
                      selectedItemsLabel={selectedWorkroomLabel(
                        formik?.values?.user_workroom_id || []
                      )}
                    />

                    <label htmlFor="user_workroom_id">Workroom</label>
                  </span>
                </div>
                {!!isSalesCommissionOpted && (
                  <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                    <span className="p-float-label">
                      <InputNumber
                        id="sales_commission_pct"
                        className="w-12 p-inputtext-sm"
                        size={10}
                        value={formik?.values?.sales_commission_pct || 0}
                        onValueChange={e => {
                          handleOnChange('sales_commission_pct', e);
                        }}
                        min={0}
                        max={100}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        suffix="%"
                      />
                      <label htmlFor="sales_commission_pct">
                        Sales Commission %
                      </label>
                    </span>
                  </div>
                )}
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFInputText
                      id="payroll_system_id"
                      name="payroll_system_id"
                      className="w-full p-inputtext-sm"
                      maxLength={30}
                      value={formik?.values?.payroll_system_id || ''}
                      onChange={e => handleOnChange(e.target.name, e)}
                    />
                    <label htmlFor="payroll_system_id">Payroll System ID</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFInputText
                      id="fadv_badge_id"
                      name="fadv_badge_id"
                      className="w-full p-inputtext-sm"
                      maxLength={50}
                      value={formik?.values?.fadv_badge_id || ''}
                      onChange={e => handleOnChange(e.target.name, e)}
                    />
                    <label htmlFor="fadv_badge_id">FADV Badge ID</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFCalendar
                      id="fadv_badge_exp_date"
                      name="fadv_badge_exp_date"
                      dateFormat="mm/dd/yy"
                      value={formik?.values?.fadv_badge_exp_date || null}
                      onChange={e => handleOnChange(e.target.name, e)}
                    />
                    <label htmlFor="fadv_badge_exp_date">
                      FADV Badge Exp Date
                    </label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFInputText
                      id="subcontractor_id"
                      name="subcontractor_id"
                      className="w-full p-inputtext-sm"
                      maxLength={20}
                      value={formik?.values?.subcontractor_id || ''}
                      onChange={e => handleOnChange(e.target.name, e)}
                    />
                    <label htmlFor="subcontractor_id">Sub Contractor ID</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                  <span className="p-float-label">
                    <PFDropdown
                      id="employment_type"
                      name="employment_type"
                      options={EMPLOYMENT_TYPES}
                      optionLabel="title"
                      optionValue="value"
                      value={formik?.values?.employment_type || ''}
                      onChange={(name, value) =>
                        handleOnChange(name, { target: { value: value } })
                      }
                      showClear={true}
                    />
                    <label htmlFor="employment_type">Employment Type</label>
                  </span>
                </div>

                <div className="col-12 lg:col-12 mb-3 py-0">
                  <span className="p-float-label">
                    <InputTextarea
                      value={formik?.values?.installer_bio}
                      rows={3}
                      cols={30}
                      onChange={e => handleOnChange('installer_bio', e)}
                      id="bio_details"
                      pt={{
                        root: {
                          className: 'w-full',
                        },
                      }}
                    />
                    <label htmlFor="bio_details">Bio Details</label>
                  </span>
                </div>
              </div>
              <div className="pl-2">
                <h4>Renovator Details</h4>
                <div className="flex gap-4 mt-2">
                  <span className="p-float-label">
                    <InputText
                      id="renovator_name"
                      className="w-full p-inputtext-sm"
                      value={formik?.values?.renovator_name || ''}
                      onChange={e => handleOnChange('renovator_name', e)}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="renovator_name">Name</label>
                  </span>
                  <span className="p-float-label">
                    <InputText
                      id="renovator_certification_number"
                      className="w-full p-inputtext-sm"
                      value={
                        formik?.values?.renovator_certification_number || ''
                      }
                      onChange={e =>
                        handleOnChange('renovator_certification_number', e)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="renovator_certification_number">
                      Certification Number
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            header="User Image"
            visible={isUploaderVisible}
            style={{ width: '50vw' }}
            onHide={() => setIsUploaderVisible(false)}
            className="w-11 md:w-6"
          >
            <FileUploadTemplate
              onFileUpload={handleUserImageUpload}
              acceptedFormat="image/*"
            />
          </Dialog>
        </div>
      )}
      <Toast ref={toast} />
    </>
  );
};

export default UserFormDetails;
