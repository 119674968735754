// **** React Imports ****
import React, { useCallback, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Icon,
  Typography,
  Button,
  Box,
  Checkbox,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Styles/Images/Icons ****
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import PFDialog from '../../shared/PFPrime/PFDialog';

import { useStyles } from './Clients.styles';
import { getClientsResponse } from './Clients.service';
import ChildClientList from './ChildClientList';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const Clients = () => {
  const clientsBreadcrumb = [
    {
      text: 'Project Management',
    },
    {
      text: 'Clients',
    },
  ];
  const [loading, setLoading] = React.useState(true);
  const getEditPermission = checkPermission(permissions?.clients?.editClient);

  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();
  const classes = useStyles();
  const [clientsListData, setClientsListData] = React.useState([]);
  const [count, setCount] = React.useState();
  const { alert, clearAlert, setAlert } = useAlerts();
  const [mergeClientRequest, setMergeClientRequest] = React.useState(null);
  const [mergeClientPopUpDefaultProps, setMergeClientPopUpDefaultProps] =
    React.useState({
      show: false,
      header: <span className="px-3">Clients</span>,
      hide: e => {
        setMergeClientPopUpDefaultProps(prevState => ({
          ...prevState,
          show: e,
        }));
      },
    });

  const onMergeClientPopUpHide = useCallback(() => {
    setMergeClientRequest(null);
    setSearchQuery({ limit: 10, offset: 0 });
    setMergeClientPopUpDefaultProps(prevState => ({
      ...prevState,
      show: false,
    }));
  }, []);

  const handleMergeRequest = (e, customerId) => {
    if (e.target.checked) {
      setMergeClientRequest({ parent: customerId });
    } else {
      setMergeClientRequest(null);
    }
  };

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { minWidth: '150px', maxWidth: '150px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span style={{ display: 'none' }} id={dataIndex}>
              <span>
                <IconButton classes={{ root: classes.actionIcons }}>
                  <Link
                    to={`/crm/view/customer/${dataArray && dataArray[dataIndex]?.customer_id}`}
                  >
                    <VisibilityIcon />
                  </Link>
                </IconButton>
              </span>
              {getEditPermission && (
                <span>
                  <IconButton classes={{ root: classes.actionIcons }}>
                    <Link
                      to={`/crm/edit/customer/${dataArray && dataArray[dataIndex]?.customer_id}`}
                    >
                      <EditIcon />
                    </Link>
                  </IconButton>
                </span>
              )}
              <span>
                <Checkbox
                  checked={
                    mergeClientRequest?.parent ===
                    dataArray?.[dataIndex]?.customer_id
                  }
                  onChange={e =>
                    handleMergeRequest(
                      e,
                      dataArray ? dataArray[dataIndex]?.customer_id : null
                    )
                  }
                />
              </span>
            </span>
          );
        },
      },
    },
    {
      name: 'source_name',
      label: 'Source',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '100px', maxWidth: '100px' },
        }),
      },
    },
    { name: 'customer_name', label: 'Client Name', options: { sort: true } },
    { name: 'mobile_phone', label: 'Mobile Phone', options: { sort: true } },
    { name: 'email', label: 'Email Address', options: { sort: true } },
    {
      name: 'billing_address',
      label: 'Billing Address',
      options: { sort: false },
    },
    {
      name: 'is_deleted',
      label: 'Status',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="subtitle2"
            className={
              (value === 'Active' && classes.statusColorActive) ||
              (value === 'Inactive' && classes.statusColorInactive)
            }
          >
            {value}
          </Typography>
        ),
      },
    },
  ];
  React.useEffect(() => {
    clearAlert();
  }, []);
  React.useEffect(() => {
    getClientsResponse(searchQuery, setLoading, setClientsListData, setCount);
  }, [searchQuery]);

  let dataArray = clientsListData?.map((val, index) => {
    return {
      source_name: val.source_url ? (
        <Icon fontSize="large" component="span">
          <img width="40" height="30" alt="ProjectForce" src={val.source_url} />
        </Icon>
      ) : (
        <Box className={classes.sourceName}>
          <Typography variant="subtitle2">{val.source_name}</Typography>
        </Box>
      ),
      customer_id: val.customer_id,
      customer_name: (
        <Typography className={classes.customerName}>
          {val.customer_name}
        </Typography>
      ),
      mobile_phone: val.mobile_phone,
      email: <Typography className={classes.email}>{val.email}</Typography>,
      billing_address: val.billing_address,
      is_deleted: val?.is_deleted ? 'Inactive' : 'Active',
    };
  });
  const viewPermission = checkPermission(permissions?.clients?.menu);
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    selectableRows: 'none',
    responsive: 'standard',
    tableBodyMinHeight: '300px',
    count: count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {},
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          setLoading(true);
          setSearchQuery(prevState => ({
            ...prevState,
            search: e.target.defaultValue,
            offset: 0,
          }));
        }
      },
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                history.push(
                  `/crm/view/customer/${dataArray ? dataArray[dataIndex].customer_id : ''}`
                );
              },
            }
          : {}),
      };
    },
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search: searchString,
      }));
    }, 500);
  };

  return (
    <>
      {mergeClientRequest?.parent && (
        <PFDialog
          {...mergeClientPopUpDefaultProps}
          BodyComponent={
            <ChildClientList
              mergeClientRequest={mergeClientRequest}
              setMergeClientRequest={setMergeClientRequest}
              onMergeClientPopUpHide={onMergeClientPopUpHide}
              alert={setAlert}
            />
          }
          pt={{
            root: {
              className: 'w-7',
            },
          }}
        />
      )}

      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Clients"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>
          {loading && (
            <Grid item justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
          <Grid item classes={{ root: classes.selfAlignGrid }}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={mergeClientRequest ? false : true}
                onClick={() =>
                  setMergeClientPopUpDefaultProps(preValue => ({
                    ...preValue,
                    show: true,
                  }))
                }
                className="mr-2"
              >
                Merge
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!checkPermission(permissions?.clients?.addClient)}
                onClick={() => history.push(`/crm/add/customer`)}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            columns={dataTableColumns}
            data={dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Clients;
